<template>
  <div>
    <!-- <div
      class="helpUser mt-0"
      v-if="
        currentUser.role === 'coordinateur' ||
        currentUser.role === 'formateur' ||
        currentUser.role === 'superviseur'
      "
    >
      <div class="title">Cette formation est en lecture seule</div>
      <div>
        Vous pouvez l'éditer en cliquant sur le bouton "modifier" en haut à
        droite.
      </div>
    </div> -->
    <div class="columns" id="presentationTraining">
      <div class="column">
        <div class="card_item h-100 is-flex flex-column">
          <div class="title-paragraph mt-auto">Dates de formation</div>
          <div class="mb-auto">
            <span class="subtitle-paragraph">Date de début : </span>
            {{ training.start_date | moment }} <br />
            <span class="subtitle-paragraph"> Date de fin :</span>
            {{ training.end_date | moment }}
          </div>
        </div>
      </div>
      <div
        class="column"
        v-if="
          currentUser.role === 'coordinateur' ||
          currentUser.role === 'superviseur'
        "
      >
        <div class="card_item card_home h-100 is-flex is-flex-direction-column">
          <div class="mt-auto title-paragraph mb-5">Modèle de référence</div>
          <div class="mb-auto">
            <b-button
              tag="router-link"
              v-if="
                currentUser.role === 'coordinateur' ||
                (currentUser.role === 'formateur' &&
                  training.teachers_can_edit) ||
                currentUser.role === 'superviseur'
              "
              :to="'/maquettes/' + training.legacy_training_framework"
              type="is-primary is-rounded is-link"
              class="is-inline"
              rounded
              >Voir la maquette</b-button
            >
          </div>
        </div>
      </div>
      <div
        class="column has-text-centered"
        v-if="currentUser.role === 'apprenant'"
      >
        <div
          class="card_item card_home h-100 is-flex btn"
          @click.prevent="seeStudentTraining()"
        >
          <div class="mt-auto mb-auto mr-auto ml-auto">
            <b-icon icon="training"></b-icon>
            <div class="title-paragraph mb-4">
              Voir mon parcours de formation
            </div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="card_item"
      id="statusCard"
      v-if="currentUser.role != 'apprenant'"
    >
      <div class="columns justify-content-between">
        <div class="column title-paragraph mb-3 is-flex align-items-center">
          <div class="mr-3">Statut</div>

          <span v-if="training.active === 1" class="tag is-success"
            >active</span
          >

          <span v-if="training.active === 0" class="tag inactif">inactive</span>
        </div>
      </div>
    </div>
    <!-- <div class="card_item">
      <div class="title-paragraph mb-3">Fiche technique</div>
      <div class="columns is-multiline">
        <div class="column">
          <div class="readField is-flex align-items-center">
            <span class="label">Secteur :</span>
            <span class="" v-if="training.sector">
              <b-tag type="is-primary is-outlined" outline rounded>
                {{ training.sector.name }}</b-tag
              >
            </span>
          </div>
          <div class="readField is-flex align-items-center">
            <div class="label">Qualification :</div>

            <b-tag type="is-default" rounded v-if="training.qualification == 0"
              >non qualifiante</b-tag
            >
            <b-tag
              type="is-success"
              rounded
              v-else-if="training.qualification == 1"
              >qualifiante</b-tag
            >
          </div>
          <div
            class="readField is-flex align-items-center"
            v-if="training.rncp_title"
          >
            <div class="label">n°fiche :</div>
            <div class="field" v-if="training.rncp_title">
              {{ training.rncp_title }}
            </div>
          </div>
        </div>
        <div class="column">
          <div class="readField" v-if="training.nsf_code">
            <div class="label">code NSF :</div>
            <div class="field" v-if="training.nsf_code">
              {{ training.nsf_code.code }} {{ training.nsf_code.name }}
            </div>
          </div>
          <div class="readField" v-if="training.nomenclature">
            <div class="label">Nomenclature :</div>
            <div class="field">
              {{ training.nomenclature.name }}
            </div>
          </div>
          <div v-if="training.formacodes">
            <div class="readField" v-if="training.formacodes.length">
              <div class="label">Formacodes :</div>
              <div
                class="field"
                v-for="formacode in training.formacodes"
                :key="formacode.id"
              >
                <div>{{ formacode.name }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <div class="title-paragraph">Objectifs</div>
    <div class="field ck" v-html="training.goals"></div>
    <div class="title-paragraph">Compétences</div>
    <div class="field ck" v-html="training.skills"></div>
  </div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import { mapState } from 'vuex';

export default {
  props: {
    trainingInfos: {
      type: [Object, Array],
    },
  },
  filters: {
    moment(date) {
      return moment(date).format('D MMMM YYYY');
    },
    day(date) {
      return moment(date).format('D/MM/YY');
    },
    hour(date) {
      return moment(date).format('HH:mm');
    },
  },
  computed: {
    ...mapState('auth', ['currentUser']),
  },
  data() {
    return {
      isLoading: true,
      isFullPage: false,
      isOpenPeriod: true,
      training: [],
    };
  },
  methods: {
    startLoading() {
      this.isLoading = true;
    },
    endLoading() {
      this.isLoading = false;
    },
    order(array) {
      return _.orderBy(array, 'position');
    },
  },
  created() {
    this.training = this.trainingInfos;
  },
};
</script>
