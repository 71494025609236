<template>
  <div class="modal-user-detail">
    <b-loading :is-full-page="isFullPage" v-model="isLoading"> </b-loading>
    <div class="columns pt-5 is-vcentered">
      <div class="column">
        <div class="title-paragraph mt-0 mb-0">Apprenants</div>
      </div>
    </div>
    <div class="subtitle-paragraph">
      Vous pouvez sélectionner un ou plusieurs utilisateurs pour les notifier ou
      leur envoyer un mail groupé. <br />La case à cocher en haut permet de
      sélectionner tout le groupe à la fois.
    </div>
    <b-collapse
      :open="false"
      class="smallBtnCollapse"
      animation="slide"
      aria-id="contentIdForA11y3"
    >
      <div
        slot="trigger"
        class="is-flex"
        slot-scope="props"
        role="button"
        aria-controls="visibleColumns"
      >
        <a>
          <b-icon :icon="props.open ? 'menu-down' : 'menu-up'"> </b-icon>
        </a>
        <p class="card-header-title">Options avancées</p>
      </div>

      <div class="card-content">
        <div class="content">
          <b-field grouped group-multiline>
            <div
              v-for="(column, index) in columns"
              :key="index"
              class="control"
            >
              <b-checkbox v-model="column.visible">
                {{ column.label }}
              </b-checkbox>
            </div>
          </b-field>
        </div>
      </div>
    </b-collapse>
    <b-table
      :data="studenttrainings"
      checkable
      :checkbox-position="'left'"
      :checkbox-type="'is-primary'"
      :default-sort="['name', 'asc']"
      :checked-rows.sync="checkedRows"
      @input="check"
    >
      <template v-for="column in columns">
        <b-table-column :key="column.id" v-bind="column">
          <template
            v-if="column.searchable && !column.numeric"
            slot="searchable"
            slot-scope="props"
          >
            <b-input
              v-model="props.filters[props.column.field]"
              placeholder="Rechercher..."
              icon="search"
              size="is-small"
            />
          </template>
          <template v-slot="props" v-if="column.field === 'student_profile'">
            <b
              class="is-clickable"
              @click.prevent="
                seeStudentTraining(props.row.student, props.row.id)
              "
            >
              {{ props.row.student_profile }}</b
            >
          </template>
          <template
            v-slot="props"
            v-else-if="column.field === 'tutors_profiles'"
          >
            {{ String(props.row.tutors_profiles) }}
          </template>
          <template
            v-slot="props"
            v-else-if="column.field === 'teachers_profiles'"
          >
            {{ String(props.row.teachers_profiles) }}
          </template>
          <template v-slot="props" v-else-if="column.field === 'enterprise'">
            {{ props.row.enterprise }}
          </template>
          <template v-slot="props" v-else-if="column.field === 'infos'">
            {{ props.row.i }}
            <div
              @click.prevent="
                isStudentEdit = true;
                getStudenttrainings(props.row.id);
              "
            >
              <b-icon icon="user"></b-icon>
            </div>
          </template>
          <template
            v-slot="props"
            v-else-if="column.field === 'id_student_training'"
          >
            {{ props.row.i }}
            <div
              @click.prevent="
                seeStudentTraining(props.row.student, props.row.id)
              "
            >
              <b-icon icon="see"></b-icon>
            </div>
          </template>
        </b-table-column>
      </template>
    </b-table>

    <b-button
      type="is-primary is-rounded"
      class="mr-2"
      :disabled="!checkedRows.length"
      @click="isComponentModalActive = true"
      >notifier</b-button
    >
    <b-modal
      v-model="isComponentModalActive"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-label="Example Modal"
      close-button-aria-label="Close"
      aria-modal
    >
      <template>
        <form action="">
          <div class="modal-card" style="width: auto">
            <header class="modal-card-head">
              <p class="modal-card-title">
                Envoyer un rappel ou une information aux participants de cette
                formation
              </p>
            </header>
            <section class="modal-card-body">
              <b-message type="is-info" size="is-small">
                <span v-for="(student, index) in checkedRows" :key="student.id">
                  {{ student.student_profile
                  }}<span v-if="checkedRows.length !== index + 1">,</span>
                </span>
              </b-message>
              <div class="mb-2 mt-1 subtitle-paragraph">
                <b>Rôle(s) à notifier</b>
              </div>
              <div>
                <div>
                  <b-checkbox v-model="roles" native-value="apprenant"
                    >Apprenant</b-checkbox
                  >
                </div>
                <div>
                  <b-checkbox v-model="roles" native-value="tuteur"
                    >Tuteur</b-checkbox
                  >
                </div>
              </div>
              <div class="mb-4 mt-5 subtitle-paragraph">
                <b>Les notifier par</b>
              </div>
              <div>
                <b-checkbox v-model="channels" native-value="notification"
                  >Message dans inSitu</b-checkbox
                >
              </div>
              <div>
                <b-checkbox v-model="channels" native-value="mail"
                  >Mail</b-checkbox
                >
              </div>
              <b-field
                class="mt-4"
                label="renseignez le titre de votre message"
              >
                <b-input v-model="formNotification.title"></b-input>
              </b-field>
              <div class="subtitle-paragraph has-text-left">
                Renseignez le contenu de votre message
              </div>
              <ckeditor
                v-model="formNotification.message"
                :editor="editor"
                :config="editorConfig"
                class="mb-5"
                :ref="'revisionContent'"
              ></ckeditor>
              <b-message
                type="is-danger"
                size="is-small"
                v-if="
                  channels.length === 0 ||
                  roles.length === 0 ||
                  !formNotification.title ||
                  !formNotification.message
                "
                >Attention tous les champs ne sont pas saisis</b-message
              >
            </section>
            <footer class="modal-card-foot">
              <b-button
                label="Annuler"
                @click="isComponentModalActive = false"
              />
              <!-- <b-button
                label="Envoyer"
                type="is-primary"
                @click="postNotification"
              /> -->
              <b-button
                :disabled="
                  channels.length === 0 ||
                  roles.length === 0 ||
                  !formNotification.title ||
                  !formNotification.message
                "
                label="Envoyer"
                type="is-primary"
                @click="notifyAllUsers"
              />
            </footer>
          </div>
        </form>
      </template>
    </b-modal>
    <b-modal
      v-model="isStudentEdit"
      has-modal-card
      trap-focus
      aria-role="dialog"
      aria-label="modal user"
      close-button-aria-label="Close"
      aria-modal
    >
      <template>
        <div class="modal-card pb-2" style="width: auto" id="modalWaypoint">
          <section class="modal-card-body has-text-left">
            <div class="has-text-right mb-5">
              <a
                @click="
                  isStudentEdit = false;
                  readOnly = true;
                "
                class="ml-auto"
              >
                <b-icon icon="close" type="is-primary"></b-icon>
              </a>
            </div>
            <div class="modal-user-infos has-text-left">
              <div class="has-text-small mb-2 mt-5-">
                <b class="title-paragraph has-text-centered">
                  {{ student.student_profile }}</b
                >
              </div>
              <span v-if="student_profile">
                <span v-if="student_profile" class="mr-2">
                  {{ student_profile.phone_number }}</span
                >
                <a mailto="student_profile.user.email" class="is-underlined"
                  ><span v-if="student_profile.user.email">
                    {{ student_profile.user.email }}</span
                  ></a
                >
              </span>

              <div v-if="readOnly">
                <div class="columns mt-3">
                  <div class="column is-narrow">
                    <span class="title-infos">Date de début : </span>
                    <span v-if="student.start_date">
                      {{ student.start_date | day }}
                    </span>
                    <b-message
                      type="has-text-left"
                      size="is-small"
                      class="mb-0 mt-2"
                      v-else
                      >Aucune information saisie</b-message
                    >
                  </div>
                  <span class="column is-narrow">
                    <span class="title-infos">Date de fin</span>
                    <span v-if="student.start_date">
                      {{ student.end_date | day }}
                    </span>
                    <b-message
                      type="has-text-left"
                      size="is-small"
                      class="mb-0 mt-2"
                      v-else
                      >Aucune information saisie</b-message
                    >
                  </span>
                  <span class="column is-narrow">
                    <span v-if="student.option">
                      <b-tag type="is-outlined">{{
                        student.option.title
                      }}</b-tag>
                    </span>
                    <b-message type="has-text-left" size="is-small" v-else
                      >Aucune information saisie</b-message
                    >
                  </span>
                </div>
                <div class="title-infos">Représentant légal</div>
                <div
                  v-if="student.legal_representative"
                  class="has-text-left"
                  v-html="student.legal_representative"
                ></div>
                <b-message type="has-text-left" size="is-small" v-else
                  >Aucune information saisie</b-message
                >
                <div class="title-infos">Entreprise</div>
                <div
                  v-if="student.enterprise"
                  v-html="student.enterprise"
                ></div>
                <b-message type="has-text-left" size="is-small" v-else
                  >Aucune information saisie</b-message
                >
                <div class="title-infos">
                  Coordonnées de contact de l'entreprise
                </div>
                <div
                  v-if="student.enterprise_contacts"
                  v-html="student.enterprise_contacts"
                ></div>
                <b-message type="has-text-left" size="is-small" v-else
                  >Aucune information saisie</b-message
                >
                <div class="title-infos">Coordonnées du tuteur</div>
                <div v-if="student.mentor" v-html="student.mentor"></div>
                <b-message type="has-text-left" size="is-small" v-else
                  >Aucune information saisie</b-message
                >
                <div class="title-infos">Information contrat</div>
                <div
                  v-if="student.contract_information"
                  v-html="student.contract_information"
                ></div>
                <b-message type="has-text-left" size="is-small" v-else
                  >Aucune information saisie</b-message
                >
              </div>
              <div v-else class="editInfos calendarModal">
                <div class="columns mt-3">
                  <div class="column is-narrow">
                    <b-field label="Date de début" class="mb-0">
                      <b-datepicker
                        v-model="start_date"
                        rounded
                        placeholder="Cliquer pour sélectionner..."
                        :first-day-of-week="1"
                        :datepicker="{ showWeekNumber }"
                        icon="calendar-today"
                      >
                      </b-datepicker
                    ></b-field>
                  </div>
                  <div class="column is-narrow">
                    <b-field label="Date de fin" class="mb-0">
                      <b-datepicker
                        v-model="end_date"
                        rounded
                        placeholder="Cliquer pour sélectionner..."
                        :first-day-of-week="1"
                        :datepicker="{ showWeekNumber }"
                        icon="calendar-today"
                      >
                      </b-datepicker
                    ></b-field>
                  </div>
                </div>
                <div class="title-infos">Représentant légal</div>
                <ckeditor
                  v-model="student.legal_representative"
                  :editor="editor"
                  :config="editorConfig"
                  class="mb-5"
                  :ref="'revisionContent'"
                ></ckeditor>
                <div class="title-infos">Entreprise</div>
                <b-input v-model="student.enterprise"></b-input>

                <div class="title-infos">
                  Coordonnées de contact de l'entreprise
                </div>
                <ckeditor
                  v-model="student.enterprise_contacts"
                  :editor="editor"
                  :config="editorConfig"
                  class="mb-5"
                  :ref="'revisionContent'"
                ></ckeditor>
                <div class="title-infos">Coordonnées du tuteur</div>

                <ckeditor
                  v-model="student.mentor"
                  :editor="editor"
                  :config="editorConfig"
                  class="mb-5"
                  :ref="'revisionContent'"
                ></ckeditor>
                <div class="title-infos">Information contrat</div>

                <ckeditor
                  v-model="student.contract_information"
                  :editor="editor"
                  :config="editorConfig"
                  class="mb-5"
                  :ref="'revisionContent'"
                ></ckeditor>
              </div>
              <div v-if="currentUser.role === 'coordinateur'">
                <b-button
                  v-if="readOnly && currentUser.role"
                  type="is-primary"
                  class="mt-4"
                  @click="readOnly = false"
                  >Modifier les infos</b-button
                >
                <b-button
                  v-if="!readOnly"
                  type="is-primary"
                  @click="postInfos(student.id)"
                  >Enregistrer</b-button
                >
              </div>
            </div>
          </section>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';
import CKEditor from '@ckeditor/ckeditor5-vue2';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import '@ckeditor/ckeditor5-build-classic/build/translations/fr';

export default {
  props: {
    typeoftraining: {
      type: String,
    }, // framework, training or studenttraining
    objectId: {
      type: Number,
    },
    contentWidth: {
      type: Number,
    },
  },
  filters: {
    day(date) {
      return moment(date).format('DD/MM/YYYY');
    },
  },
  computed: {
    ...mapState('auth', ['currentUser']),
    ...mapState(['user']),
    multiple_value_boolean() {
      return this.formWaypoint.type !== null
        && this.formWaypoint.start_date !== null
        && this.formWaypoint.end_date !== null;
    },
  },
  components: {
    ckeditor: CKEditor.component,
  },
  data() {
    return {
      start_date: new Date(),
      end_date: new Date(),
      locale: 'UTC',
      isLoading: false,
      isFullPage: false,
      isComponentModalActive: false,
      formProps: {
        email: 'evan@you.com',
        password: 'testing',
      },
      columns: [
        {
          field: 'student_profile',
          label: 'Nom',
          width: '150',
          searchable: true,
          sortable: true,
          visible: true,
        },
        {
          field: 'tutors_profiles',
          label: 'Tuteur',
          width: '150',
          searchable: false,
          sortable: true,
          visible: true,
        },
        {
          field: 'teachers_profiles',
          label: 'Formateur/Coach',
          width: '150',
          searchable: false,
          sortable: true,
          visible: true,
        },
        {
          field: 'enterprise',
          label: 'Entreprise',
          width: '150',
          searchable: true,
          sortable: true,
          visible: true,
        },
        {
          field: 'infos',
          label: 'Infos',
          width: '50',
          centered: true,
          searchable: false,
          sortable: true,
          visible: true,
        },
        {
          field: 'id_student_training',
          label: 'Parcours',
          width: '50',
          centered: true,
          searchable: false,
          sortable: true,
          visible: true,
        },
      ],
      checkedRows: [],
      listNotifications: [],
      channels: [],
      roles: [],
      studenttrainings: [],
      isWaypointModalActive: false,
      isStudentEdit: false,
      formWaypoint: {
        type: null,
        end_date: null,
        start_date: null,
        free_text: null,
      },
      showWeekNumber: true,
      today: new Date(),
      editor: ClassicEditor,
      editorDisabled: true,
      editorConfig: {
        language: 'fr',
        toolbar: ['heading', '|', 'bold', 'italic', 'bulletedList', 'numberedList', '|', 'outdent', 'indent', '|', 'link', '|', 'undo',
          'redo'],
        alignment: {
          options: ['left', 'right'],
        },
        heading: {
          options: [
            { model: 'paragraph', title: 'Styles de texte', class: 'ck-heading_paragraph' },
            {
              model: 'heading1',
              view: 'h1',
              title: 'Titre 1',
              class: 'ck-heading_heading1',
            },
            {
              model: 'heading2',
              view: 'h2',
              title: 'Titre 2',
              class: 'ck-heading_heading2',
            },
            {
              model: 'mark',
              view: 'mark',
              title: 'surligné',
              class: 'ck-mark',
            },
          ],
        },
        table: {
          contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells'],
        },

      },
      formNotification: {
        title: null,
        message: null,
      },
      student: [],
      readOnly: true,
      student_profile: null,
    };
  },
  methods: {
    startLoading() {
      this.isLoading = true;
    },
    endLoading() {
      this.isLoading = false;
    },
    initStudenttrainings() {
      this.$http
        .get(`/agenda/student_training/?training=${this.$route.params.idTraining}&query={id,student,teachers,student_profile,tutors,tutors_profiles,teachers_profiles,enterprise}&format=json`)
        .then((response) => {
          this.studenttrainings = response.data;
        });
    },
    getStudenttrainings(id) {
      this.$http
        .get(`/agenda/student_training/${id}/?query={id,student,student_profile,contract_information,mentor,legal_representative,enterprise,enterprise_contacts,project_description,ccf_table,start_date,end_date,option}&format=json`)
        .then((response) => {
          this.student = response.data;
          this.getUser(this.student.student);
          this.start_date = new Date(this.student.start_date);
          this.end_date = new Date(this.student.end_date);
        });
    },
    getUser(id) {
      this.$http
        .get(`/profile/${id}/?query={id,user,address,phone_number,enterprise,birth_date}&format=json`)
        .then((response) => {
          this.student_profile = response.data;
        });
    },
    notifUser() {
      // window.console.log('notif user');
    },
    gotoWaypointEdit(studentId, studentTraining, part) {
      this.$router.push({
        name: 'WaypointitiiEdit',
        params: {
          idTraining: this.objectId,
          idStudent: studentId,
          idStudentTraining: studentTraining,
          // idWaypoint: waypointId,
          simple_booklet_part_validation: part,
          from: 'DashboardManagement',
        },
      });
    },
    seeStudentTraining(studentId, studentTraining) {
      this.$router.push({
        name: 'StudentTraining',
        params: {
          idStudent: studentId,
          idTraining: this.$route.params.idTraining,
          idStudentTraining: studentTraining,
          tab: 'parcours',
        },
      });
    },
    postNotification() {
      for (let i = 0; i < this.checkedRows.length; i += 1) {
        this.$http
          .post('/notifications/send_reminder/', {
            student_training: this.checkedRows[i].student_training_id,
            // part: this.actualStep.data[0].data[0].id,
            roles: this.roles,
            channels: this.channels,
          }).then(() => {
            this.isComponentModalActive = false;
            this.$buefy.toast.open({
              message: 'Le message a bien été envoyé',
              type: 'is-success',
              duration: 1500,
            });
          }).catch((error) => {
            window.console.log(error);
            this.$buefy.dialog.alert({
              title: 'Mince...',
              message: 'Votre notification n\'a pas pu être envoyée',
              type: 'is-info',
              hasIcon: true,
              icon: 'times-circle',
              ariaRole: 'alertdialog',
              ariaModal: true,
              confirmText: 'Ok',
            });
          });
      }
    },
    check() {
      // window.console.log('check');
    },
    notifyUser(recipientId, last) {
      this.$http
        .post('/notifications/notifications/', {
          title: this.formNotification.title,
          message: this.formNotification.message,
          recipient: recipientId,
          sender: this.currentUser.id,
          status: 0,
        }).then(() => {
          if (last) {
            this.$buefy.toast.open({ message: 'Tous les messages ont été envoyés', type: 'is-success' });
            this.isComponentModalActive = false;
          }
        }).catch((error) => {
          window.console.log(error);
          this.issue = true;
          this.$buefy.dialog.alert({
            title: 'Mince...',
            message: 'Votre notification n\'a pas pu être envoyée',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
          });
        });
    },
    emailUser(listrecipients) {
      this.$http
        .post('/notifications/send_email/', {
          title: this.formNotification.title,
          message: this.formNotification.message,
          recipients: listrecipients,
          sender: this.currentUser.id,
          channels: this.channels,
        }).then(() => {
          this.$buefy.toast.open({ message: 'Tous les messages ont été envoyés', type: 'is-success' });
          this.isComponentModalActive = false;
        }).catch((error) => {
          window.console.log(error);
          this.issue = true;
          this.$buefy.dialog.alert({
            title: 'Mince...',
            message: 'Votre notification n\'a pas pu être envoyée',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
          });
        });
    },
    notifyAllUsers() {
      const students = this.checkedRows.map((student) => student.student);
      let tutors = this.checkedRows.map((student) => student.tutors);
      tutors = tutors.map((tutor) => tutor[0]);
      this.emailUser(students);
      this.emailUser(tutors);
    },
    postInfos(student) {
      this.startLoading();
      this.$http
        .patch(`agenda/student_training/${student}/`, {
          enterprise: this.student.enterprise,
          mentor: this.student.mentor,
          legal_representative: this.student.legal_representative,
          enterprise_contacts: this.student.enterprise_contacts,
          contract_information: this.student.contract_information,
          start_date: this.start_date,
          end_date: this.end_date,
        }).then(() => {
          this.$buefy.toast.open({ message: 'Toutes les informations ont été enregistrées', type: 'is-success' });
          this.isStudentEdit = false;
          this.readOnly = true;
          this.initStudenttrainings();
          this.endLoading();
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
          this.$buefy.dialog.alert({
            title: 'Mince...',
            message: 'Les infos n\'ont pu être enregistrées.',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
          });
        });
    },
  },
  created() {
    this.initStudenttrainings();
  },
  mounted() {

  },
};
</script>
<style scoped>
#modalWaypoint .title2 {
  text-align: left !important;
}

.modal-user-infos {
  padding: 2rem 2rem 4rem 2rem;
}
.title-infos {
  padding-top: 0.5rem;
  margin-bottom: 0.5rem;
  margin-top: 1rem;
  font-size: 1.1rem;
  color: #1b1824;
  font-weight: 700;
}
.editInfos .ck-content {
  min-height: 7vh !important;
}
.loading-overlay {
  z-index: 20002;
}
</style>
