<template>
  <!-- eslint-disable max-len-->
  <div>
    <b-modal v-model="isCardModalActive" scroll="keep" id="modalNude">
      <template #default="props">
        <Modalexport @close="props.close" :student.sync="studentName" :student_id.sync="studentId"></Modalexport>
      </template>
    </b-modal>
    <div class="contentActivity tableUsers pt-1">
      <section id="students">
        <b-loading :is-full-page="isFullPage" v-model="isLoading"> </b-loading>
        <div class="columns is-vcentered is-desktop is-multiline">
          <div class="column">
            <div v-if="!studentsArray.length" class="no-user">0 apprenants</div>
            <div v-else>
              <div class="userCount mb-4">
                {{ studentsArray.length }} apprenant<span v-if="studentsArray.length > 1">s</span>
              </div>
            </div>
            <div class="subtitle-paragraph mb-0">
              Suivez l'activité de chaque apprenant.e
              <span v-if="start_date && end_date">
                <b> du {{ start_date | day }} au {{ end_date | day }}</b></span>
              <span v-else>pour toute la formation</span>
            </div>
          </div>

          <div class="column columns cards-header is-mobile is-vcentered">
            <div class="column is-narrow" v-if="currentUser.role === 'coordinateur' &&
      trainingInfos.framework_type.id === 3
      ">
              <b-button size="is-small" type="is-primary" rounded @click.prevent="getCoach(start_date, end_date)">export
                recap coachs</b-button>
            </div>
            <div class="column is-narrow">
              <b-field label="Période concernée">
                <b-select placeholder="Période concernée" v-model="rangeSelected" @input="changeDate()">
                  <option value="twoweeks">Derniers 15 jours</option>
                  <option value="month">Dernier mois</option>
                  <option value="perso">Période personnalisée</option>
                  <option value="all">Toute la formation</option>
                </b-select>
              </b-field>
            </div>
          </div>
        </div>

        <div class="columns" v-if="rangeSelected == 'perso'">
          <div class="column">
            <b-datepicker v-model="start_date" :first-day-of-week="1" placeholder="Cliquer pour sélectionner...">
              <b-button label="Aujourdhui" size="is-small" type="is-primary" icon-left="calendar-today"
                @click="start_date = new Date()" />

              <b-button label="Clear" size="is-small" type="is-danger" icon-left="close" outlined
                @click="start_date = null" />
            </b-datepicker>
          </div>
          <div class="column">
            <b-datepicker v-model="end_date" :first-day-of-week="1" placeholder="Cliquer pour sélectionner...">
              <b-button label="Aujourdhui" size="is-small" type="is-primary" icon-left="calendar-today"
                @click="end_date = new Date()" />

              <b-button label="Clear" size="is-small" type="is-danger" icon-left="close" outlined
                @click="end_date = null" />
            </b-datepicker>
          </div>
          <div class="column">
            <b-button type="is-primary" @click="getActivities(start_date, end_date)">rechercher</b-button>
          </div>
        </div>
        <b-collapse :open="false" aria-id="contentIdForA11y1">
          <template #trigger="props">
            <a aria-controls="contentIdForA11y4" :aria-expanded="props.open">
              <b-icon :icon="!props.open ? 'menu-up' : 'menu-down'" class="mb-3"></b-icon>
              <b class="has-text-primary">
                {{ !props.open ? "En savoir plus" : "Fermer la légende" }}</b>
            </a>
          </template>
          <div class="notification has-background-white-ter">
            <div class="content">
              <div class="columns is-vcentered is-multiline is-centered">
                <div class="column legend-col">
                  <div class="title">Expériences programmées</div>
                  <div class="legend_item">
                    <span class="tag is-primary ml-2"> 3</span>en cours
                  </div>
                  <div class="legend_item">
                    <span class="tag is-success ml-2"> 3</span>effectuées
                  </div>
                </div>
                <div class="column legend-col">
                  <div class="title">Autres activités</div>
                  <div class="legend_item" v-if="!trainingInfos.disallow_progressions">
                    <span class="tag is-primary ml-2"> x 2</span>activité
                    refaite
                  </div>
                  <div class="legend_item" v-if="!trainingInfos.disallow_free_situations">
                    <span class="tag is-warning ml-2"> libre</span>expérience
                    libre
                  </div>
                  <div class="legend_item" v-if="trainingInfos.framework_type.id === 3">
                    <span class="tag is-warning ml-2"> note</span>notes de
                    carnet de bord
                  </div>
                </div>
                <div class="column legend-col">
                  <div class="title">Activité tuteur</div>
                  <div class="legend_item">
                    <img src="@/assets/img/elements/read.svg" width="30" />
                    <span class="tag mr-3 has-background-info-light">0</span>
                    Point lu par le tuteur
                  </div>
                  <div class="legend_item">
                    <img src="@/assets/img/elements/log.svg" width="30" />
                    <span class="tag mr-3 has-background-info-light">0</span>
                    Le tuteur s'est loggé
                  </div>
                </div>
                <div class="column legend-col">
                  <div class="title">Évaluations</div>
                  <div class="legend_item">
                    <b-icon icon="check" size="is-medium" type="is-black">
                    </b-icon>
                    <span class="tag has-background-info-light">0</span>Évaluation apprenant
                  </div>
                  <div class="legend_item">
                    <img src="@/assets/img/elements/tutor-eval.svg" width="27" class="mr-1" />
                    <span class="tag has-background-info-light">0</span>Évaluations tuteur
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-collapse>

        <div>
          <div class="columns ml-1 mb-0 mt-0 stats-header has-text-bold">
            <div class="column col-name name_student"><b>Nom</b></div>
            <div class="columns mb-0 mt-0 column col-stats">
              <div class="column col-exp"><b>Expériences enregistrées</b></div>
              <div class="column col-activity"><b>Activité tuteur</b></div>
              <div class="column col-eval"><b>Evaluations</b></div>
              <div class="column col-waypoint"><b>Point d'étapes</b></div>
            </div>
            <div class="column col-detail"><b>Détail</b></div>
          </div>
          <div v-for="(stat, index) of studentsArray" :key="stat.id" class="card_item card_student is-desktop card2">
            <div class="columns ml-1 mb-0 mt-0 has-text-primary is-multiline is-vcentered">
              <div class="column col-name name_student">
                <b class="is-clickable mr-3" @click="
      seeStudentTraining(stat.student_id, stat.student_training)
      ">
                  <u> {{ stat.eleve }}</u></b>
                <div style="font-size: 0.9rem" v-if="trainingInfos.framework_type.id === 3">
                  {{ stat.teachers }}
                </div>
              </div>
              <div class="columns mb-0 mt-0 column col-stats is-vcentered">
                <div class="column col-exp pl-0">
                  <div class="column is-flex align-items-center pl-0">
                    <b-progress size="is-medium" type="is-success" :max="stat.nb_training_sequences" class="mb-0">
                      <template #bar>
                        <b-progress-bar :show-value="stat.nb_sequences_effectuee > 0 ? true : false
      " :value="stat.nb_sequences_effectuee" type="is-success"></b-progress-bar>
                        <b-progress-bar :show-value="stat.nb_sequences_en_cours > 0 ? true : false
      " :value="stat.nb_sequences_en_cours" type="is-primary"></b-progress-bar>
                      </template>
                    </b-progress>
                    <span class="tag is-primary ml-2" v-if="!trainingInfos.disallow_progressions">
                      x {{ stat.nb_revisions_sequences }}</span>

                    <span class="tag is-warning ml-2" v-if="!trainingInfos.disallow_free_situations">
                      {{ stat.nb_free_situations_sequences }} libre<span
                        v-if="stat.nb_free_situations_sequences.length > 1">s</span></span>
                    <span class="tag is-warning ml-2" v-if="trainingInfos.framework_type.id === 3">
                      {{ stat.nb_custom_experiences }} notes<span
                        v-if="stat.nb_custom_experiences.length > 1">s</span></span>
                  </div>
                </div>
                <div class="column col-activity is-flex align-items-center">
                  <div class="is-flex align-items-center">
                    <img src="@/assets/img/elements/read.svg" width="30" />
                    <span class="tag mr-3" :class="stat.nb_read_by_tutor > 0
      ? 'is-success'
      : 'has-background-info-light'
      ">{{ stat.nb_read_by_tutor }}</span>
                  </div>
                  <div class="is-flex align-items-center">
                    <img src="@/assets/img/elements/log.svg" width="30" />
                    <span class="tag" :class="stat.logs_tuteurs > 0
      ? 'is-success'
      : 'has-background-info-light'
      ">{{ stat.logs_tuteurs }}</span>
                  </div>
                </div>
                <div class="column col-eval is-flex align-items-center">
                  <div @click.prevent="" class="icon-number is-clickable">
                    <b-icon icon="check" size="is-medium" type="is-black" class="mr-2">
                    </b-icon>
                    <span class="tag mr-4" :class="stat.nb_evaluations_apprenant > 0
      ? 'is-success'
      : 'has-background-info-light'
      ">{{ stat.nb_evaluations_apprenant }}</span>
                  </div>
                  <div @click.prevent="" class="icon-number is-clickable">
                    <img src="@/assets/img/elements/tutor-eval.svg" width="30" class="mr-3" />

                    <span class="tag" :class="stat.nb_evaluations_tuteur_ou_tuteur_apprenant > 0
      ? 'is-success'
      : 'has-background-info-light'
      ">{{
      stat.nb_evaluations_tuteur_ou_tuteur_apprenant
    }}</span>
                  </div>
                </div>
                <div class="column col-waypoint" v-if="trainingInfos.framework_type.id === 3">
                  <div class="">
                    <div class="column">
                      <span class="tag ml-2" :class="stat.nb_waypoints_coaching < 2
      ? 'status2'
      : 'is-outlined'
      ">
                        {{ stat.nb_waypoints_coaching }} coaching</span><b-tag type="" class="ml-1" :class="stat.nb_waypoints_coaching < 2
      ? 'is-danger'
      : 'is-primary'
      ">{{ stat.time_waypoints_coaching }} h</b-tag>
                    </div>
                    <div class="column">
                      <span class="tag ml-2" :class="stat.nb_waypoints_coordination < 2
      ? 'status2'
      : 'is-outlined'
      ">
                        {{ stat.nb_waypoints_coordination }} tripartite</span><b-tag type="" class="ml-1" :class="stat.nb_waypoints_coaching < 2
      ? 'is-danger'
      : 'is-primary'
      ">{{ stat.time_waypoints_coordination }} h</b-tag>
                    </div>
                    <div class="column">
                      <span class="tag is-outlined ml-2 status1">
                        {{
      stat.nb_waypoints_coaching +
      stat.nb_waypoints_coordination
    }}
                        total</span><b-tag type="is-success" class="ml-1">{{ stat.time_waypoints_total }} h</b-tag>
                    </div>
                  </div>
                </div>
                <div class="column col-waypoint" v-else>
                  <div class="">
                    <div class="column">
                      <span class="tag is-outlined ml-2 status1">
                        {{ stat.nb_waypoints_centre }} cfa</span>
                    </div>
                    <div class="column">
                      <span class="tag is-outlined ml-2 status1">
                        {{ stat.nb_waypoints_entreprise }} entreprise</span>
                    </div>
                    <div class="column">
                      <span class="tag is-outlined ml-2 status1">
                        {{ stat.nb_attitude_waypoints }} comportement</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="column col-detail is-flex align-items-center">
                <div class="mr-2 is-clickable" @click="
      (studentId = stat.student_id) &&
      (studentName = stat.eleve) &&
      (isCardModalActive = true)
      "></div>
                <b-button rounded @click="collapseBlock('d' + index)" type="is-small" class="">détails</b-button>
              </div>
            </div>
            <div class="column is-12 card-content cardClosed" :id="'d' + index">
              <div class="is-flex cards align-items-center mt-4 mb-3">
                <div class="card_item card_stat mr-2">
                  <div class="stat-cell">
                    <div class="stat_number">
                      {{ stat.nb_evaluations }}
                    </div>
                    <div class="stat_label">
                      éval.<br />
                      au total
                    </div>
                  </div>
                </div>
                <div class="is-flex align-items-center">
                  <div class="card_item card_stat mr-2">
                    <div class="stat-cell">
                      <div class="stat_number pr-2">
                        {{ stat.nb_competences_evaluees }} /
                        {{ stat.nb_competences }}
                      </div>
                      <div class="stat_label">
                        compétence<span v-if="stat.nb_competences_evaluees.length > 1">s</span><br />évaluée<span
                          v-if="stat.nb_competences_evaluees.length > 1">s</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card_item card_stat mr-2">
                  <div class="stat-cell">
                    <div class="stat_number">
                      {{ stat.moyenne_evaluations }}
                    </div>
                    <div class="stat_label">par<br />compétence</div>
                  </div>
                </div>
              </div>
              <div class="pr-5 mb-5" v-if="stat.sequences_effectuees.length">
                <div class="card_grey is-flex align-items-center mb-2">
                  Expériences effectuées
                </div>
                <div v-for="(activity, index) of order(stat.sequences_effectuees)" :key="index"
                  class="card-edit-sequence mb-1 is-flex align-items-center pointer">
                  <div class="column bloc-left is-flex mr-auto align-items-center" @click="
      gotoSequence(
        activity.id_student_sequence,
        stat.student_id,
        stat.student_training
      )
      ">
                    <div class="card-sequencetype">
                      <div class="column sequenceType situation mb-0" type="situation"
                        :class="'sequenceType' + activity.type">
                        {{ activity.type }}
                      </div>
                    </div>

                    <div class="column is-12-mobile has-text-centered-mobile">
                      <b> {{ activity.title }}</b>
                    </div>
                    <div class="ml-auto column is-narrow has-text-centered">
                      <span class="tag is-success mr-3">
                        x {{ activity.nb_revisions_sequences }}</span>
                    </div>
                    <div class="date-creation mr-2" v-if="activity.date_created">
                      <div class="small-text has-text-primary">Créée le :</div>
                      <div>{{ activity.date_created | day }}</div>
                    </div>
                    <div class="date-creation" v-if="activity.last_action">
                      <div class="small-text has-text-primary">
                        Modifiée le :
                      </div>
                      <div>{{ activity.last_action | day }}</div>
                    </div>
                  </div>
                </div>
                <div v-if="stat.sequences.length < 1" class="card_item card_stat mb-2 has-text-centered">
                  pas d'expérience effectuée
                </div>
              </div>
              <div class="pr-5" v-if="stat.situations_libres_effectuees.length">
                <div class="card_grey is-flex align-items-center mb-2">
                  Situations libres effectuées
                </div>
                <div v-for="(activity, index) of order(
      stat.situations_libres_effectuees
    )" :key="index" class="card-edit-sequence mb-1 is-flex align-items-center pointer">
                  <div class="column bloc-left is-flex mr-auto align-items-center">
                    <div class="column is-12-mobile has-text-centered-mobile ml-3">
                      <div class="date-creation" v-if="activity.start_date == activity.end_date"
                        style="font-size: 0.9rem">
                        {{ activity.start_date | day }}
                      </div>
                      <div v-else>
                        du {{ activity.start_date | day }} au
                        {{ activity.end_date | day }}
                      </div>
                      <b> {{ activity.title }}</b>
                    </div>

                    <div class="date-creation mr-2">
                      <div class="small-text has-text-primary">Créée le :</div>
                      <div>{{ activity.date_created | day }}</div>
                    </div>
                    <div class="date-creation">
                      <div class="small-text has-text-primary">
                        Modifiée le :
                      </div>
                      <div>{{ activity.date_updated | day }}</div>
                    </div>
                  </div>
                </div>
                <div v-if="stat.sequences.length < 1" class="card_item card_stat mb-2 has-text-centered">
                  pas d'expérience effectuée
                </div>
              </div>
              <div class="pr-5" v-if="stat.custom_experiences.length">
                <div class="card_grey is-flex align-items-center mb-2">
                  Notes de carnet de bord
                </div>
                <div v-for="(activity, index) of order(stat.custom_experiences)" :key="index"
                  class="card-edit-sequence mb-1 is-flex align-items-center pointer">
                  <div class="column bloc-left is-flex mr-auto align-items-center">
                    <div class="column is-12-mobile has-text-centered-mobile ml-3">
                      <div class="date-creation" v-if="activity.start_date == activity.end_date"
                        style="font-size: 0.9rem">
                        {{ activity.start_date | day }}
                      </div>
                      <div v-else>
                        du {{ activity.start_date | day }} au
                        {{ activity.end_date | day }}
                      </div>
                      <b> {{ activity.title }}</b>
                    </div>

                    <div class="date-creation mr-2">
                      <div class="small-text has-text-primary">Créée le :</div>
                      <div>{{ activity.date_created | day }}</div>
                    </div>
                    <div class="date-creation">
                      <div class="small-text has-text-primary">
                        Modifiée le :
                      </div>
                      <div>{{ activity.date_updated | day }}</div>
                    </div>
                  </div>
                </div>
                <div v-if="stat.sequences.length < 1" class="card_item card_stat mb-2 has-text-centered">
                  pas d'expérience effectuée
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';
import localization from 'moment/locale/fr';
import _ from 'lodash';
import Modalexport from '@/components/Waypoints/Modalexport.vue';

moment.updateLocale('fr', localization);

export default {
  props: {
    trainingInfos: {
      type: [Object, Array],
    },
  },
  computed: {
    ...mapState('auth', ['currentUser']),
  },
  components: {
    Modalexport,
  },
  filters: {
    day(date) {
      return moment(date).format('DD/MM/YY');
    },
    hour(date) {
      return moment(date).format('H:mm');
    },
  },
  methods: {
    gotoSkills() {
      this.$emit('messageFromChild', 7);
    },

    gotoStep() {
      window.console.log(this.step);
    },
    // function to see detailed "ruban pédagogique" by student
    seeStudentTraining(studentId, studentTraining) {
      if (this.$route.name === 'TrainingEdit') {
        this.$router.push({
          name: 'StudentTraining',
          params: {
            idStudent: studentId,
            idTraining: this.$route.params.idTraining,
            idStudentTraining: studentTraining,
            from: this.$route.name,
            tab: 'parcours',
          },
        });
      } else {
        this.$router.push({
          name: 'StudentTraining',
          params: {
            idStudent: studentId,
            idTraining: this.$route.params.idTraining,
            idStudentTraining: studentTraining,
            from: this.$route.name,
            tab: 'parcours',
          },
        });
      }
    },
    exportLivret(studentId, studentName) {
      this.$buefy.dialog.confirm({
        title: 'Exporter le livret',
        message: 'Vous pouvez exporter le livret d\'apprentissage au format PDF',
        confirmText: 'Exporter',
        cancelText: 'Annuler',
        type: 'is-info',
        onConfirm: () => {
          this.startLoading();
          this.$http
            .get(`export_livret/?training_id=${this.$route.params.idTraining}&student_id=${studentId}`, {
              responseType: 'blob',
            }).then((response) => {
              const username = (studentName).toLowerCase();
              const usernameReplaced = username.replace(' ', '_');
              const fileURL = window.URL.createObjectURL(new Blob([response.data]));
              const fileLink = document.createElement('a');
              fileLink.href = fileURL;
              fileLink.setAttribute('download', `insitu_livret_${usernameReplaced}.pdf`);
              document.body.appendChild(fileLink);
              fileLink.click();
              this.endLoading();
            });
        },
      });
    },
    getTrainingStats() {
      this.$http
        .get(`get_stats/?training=${this.$route.params.idTraining}`)
        .then((response) => {
          this.trainingArray = response.data;
        });
    },
    getStudentStats(idStudent) {
      this.$http
        .get(`get_stats/?student_training=${idStudent}`)
        .then((response) => {
          window.console.log(response.data);
        });
    },
    getActivities(start, end) {
      this.startLoading();
      const startDate = moment(new Date(start)).format('YYYY-MM-DD');
      const endDate = moment(new Date(end)).format('YYYY-MM-DD');
      const urlWs = `/get_realtime_activities/?training=${this.$route.params.idTraining}&start_date=${startDate}&end_date=${endDate}&force=1
`;
      this.$http
        .get(`${urlWs}`)
        .then((response) => {
          this.studentsArray = response.data.details;
          this.endLoading();
        });
    },
    getAllActivities() {
      let force = '';
      if (!this.first) {
        force = '&force=1';
      } else {
        this.first = false;
      }
      this.startLoading();
      const urlWs = `/get_realtime_activities/?training=${this.$route.params.idTraining}${force}`;
      this.$http
        .get(`${urlWs}`)
        .then((response) => {
          this.studentsArray = response.data.details;
          this.endLoading();
        });
    },
    order(array) {
      return _.orderBy(array, 'date_updated', 'desc');
    },
    startLoading() {
      this.isLoading = true;
    },
    endLoading() {
      this.isLoading = false;
    },
    collapseBlock(id) {
      this.listActivities = null;
      const element = document.getElementById(id);
      element.classList.toggle('blockOpen');
    },
    gotoSequence(Sequence, Student, StudentTraining) {
      this.$router.push({
        name: 'StudentSequenceDetail',
        params: {
          idSequence: Sequence,
          idTraining: this.$route.params.idTraining,
          idStudent: Student,
          idStudentTraining: StudentTraining,
          idActivity: 1,
          from: this.$route.params.name,
          stepNumber: this.step,
        },
      });
    },
    changeDate() {
      if (this.rangeSelected === 'twoweeks') {
        this.start_date = new Date(this.twoweeks);
        this.end_date = this.today;
        this.getActivities(this.start_date, this.end_date);
      }
      if (this.rangeSelected === 'month') {
        this.start_date = new Date(this.month);
        this.end_date = this.today;
        this.getActivities(this.start_date, this.end_date);
      }
      if (this.rangeSelected === 'all') {
        this.start_date = null;
        this.end_date = null;
        this.getAllActivities();
      }
    },
    getCoach1(start, end) {
      this.startLoading();
      const startDate = moment(new Date(start)).format('YYYY-MM-DD');
      const endDate = moment(new Date(end)).format('YYYY-MM-DD');
      const urlWs = `executive/get_coachs_data?start_date=${startDate}&end_date=${endDate}&training=${this.$route.params.idTraining}`;
      this.$http
        .get(`${urlWs}`)
        .then((response) => {
          window.console.log(response.data);
          this.endLoading();
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
        });
    },
    getCoach() {
      this.startLoading();
      window.console.log(this.start_date);
      let startDate = moment(new Date(this.start_date)).format('YYYY-MM-DD');
      if (!this.start_date) {
        startDate = moment(new Date(this.trainingInfos.start_date)).format('YYYY-MM-DD');
      }
      let endDate = moment(new Date(this.end_date)).format('YYYY-MM-DD');
      if (this.rangeSelected === 'all') {
        endDate = moment(new Date(this.today)).format('YYYY-MM-DD');
      }
      this.startLoading();
      this.$http
        .get(`executive/get_coachs_data?start_date=${startDate}&end_date=${endDate}&training=${this.$route.params.idTraining}`, {
          responseType: 'blob',
        }).then((response) => {
          const fileURL = window.URL.createObjectURL(new Blob([response.data]));
          const fileLink = document.createElement('a');
          fileLink.href = fileURL;
          fileLink.setAttribute('download', `insitu_coachs_${startDate}-${endDate}.xls`);
          document.body.appendChild(fileLink);
          fileLink.click();
          this.endLoading();
        });
    },
  },
  created() {
    this.twoweeks = this.twoweeks.setDate(this.twoweeks.getDate() - 15);
    this.month = this.month.setMonth(this.month.getMonth() - 1);
  },
  mounted() {
    // this.initUsers();
    this.getAllActivities();
  },
  data() {
    return {
      first: true,
      openStat: true,
      isCardModalActive: false,
      nb_activities: '',
      trainingArray: [],
      isLoading: false,
      isFullPage: false,
      studenttrainings: [],
      users: [],
      // declaration variables for users tabs
      step: 1,
      currentTab: 1,
      tabs: [
        {
          name: 'Formateurs',
          id: '1',
        },
        {
          name: 'Apprenants',
          id: '2',
        },
        {
          name: 'Managers',
          id: '3',
        },
        {
          name: 'Tuteurs',
          id: '4',
        },
      ],
      // variables for table
      isPaginated: true,
      isPaginationSimple: false,
      paginationPosition: 'bottom',
      sortIconSize: 'is-small',
      defaultSortDirection: 'asc',
      currentPage: 1,
      perPage: 10,
      // columns related to checkboxs above table
      studentsColumns: [
        {
          field: 'student_profile',
          label: 'Nom',
          width: '200',
          searchable: true,
          sortable: true,
          visible: true,
        },
        {
          field: 'status',
          label: 'Statut',
          centered: true,
          searchable: false,
          sortable: true,
          visible: true,
        },
        {
          field: 'id',
          label: 'Parcours',
          centered: true,
          searchable: false,
          sortable: true,
          visible: true,
        },
      ],
      statsColumns: [
        {
          field: 'student_profile',
          label: 'Nom',
          width: '200',
          searchable: true,
          sortable: true,
          visible: true,
        },
        {
          field: 'done',
          label: 'effectuées',
          centered: true,
          visible: true,
        },
        {
          field: 'progress',
          label: 'en cours',
          centered: true,
          visible: true,
        },
        {
          field: 'revisions',
          label: 'Progressions',
          centered: true,
          visible: true,
        },
        {
          field: 'free_situations',
          label: 'Activité libre',
          centered: true,
          visible: true,
        },
        {
          field: 'wp-cfa',
          label: 'Point CFA',
          centered: true,
          visible: true,
        },
        {
          field: 'wp-company',
          label: 'Point entreprise',
          centered: true,
          visible: true,
        },
        {
          field: 'wp-attitude',
          label: 'Point comportement',
          centered: true,
          visible: true,
        },
        {
          field: 'evaluations',
          label: 'Evaluations',
          centered: true,
          visible: true,
        },
      ],
      statsStudents: [
        {
          student_profile: 'Etienne Benech',
          done: '3/16',
          progress: '0/16',
          revisions: 5,
          free_situations: 2,
          wp_cfa: 1,
          wp_company: 2,
          wp_attitude: 0,
          evaluations: 13,
        },
      ],
      // array to collect informations from students
      studentsArray: [
      ],
      // array to clear check
      checkedRows: [],
      studentsIds: [],
      // variables to collapse selec user
      openManagers: false,
      openTutors: false,
      id_manager: 0,
      id_tutor: 0,
      studentId: 0,
      studentName: '',
      feature: [

        {
          intro: 'Qu\'est-ce qu\'une expérience ?',
          img: 'help/help-experiences',
          details: '<p>Une expérience signifie une expérience pédagogique. Elle peut être constituée de plusieurs actions à réaliser. <p>Exemple: La situation choisie comprend plusieurs actions : </p><ul><li>La situation choisie</li><li>L\'activité détaillée</li><li>L\'évaluation tuteur</li></p> <p>Vous retrouvez la liste des expériences proposées dans l\'onglet "Parcours"</p>',
        },
        {
          intro: 'Qu\'est-ce qu\'une action à faire ?',
          img: 'help/help-activites',
          details: '<p>C\'est une étape ou activité d\'une expérience. Chaque action à faire peut impliquer un type de personne différent pour une même expérience, avec des droits d\'écriture ou lecture différents.</p> <p>En cliquant sur une expérience dans le "Parcours", vous voyez la liste des actions proposées.</p>',
        },
        {
          intro: 'Qu\'est-ce qu\'une expérience libre ?',
          img: 'help/help-libre',
          details: '<p><b>C\'est une expérience qui permet une prise de note simple.</b></p><p>On retrouve la liste de ces expériences, dans l\'accordéon "expériences libres", en bas du parcours."</p>',
        },
        {
          intro: 'Qu\'est-ce qu\'un point période ?',
          img: 'help/help-points',
          details: '<p><b>Point CFA : </b> Il s\'agit d\'un point au centre de formation, avec le pilote.</p><p><b>Point entreprise : </b> Il s\'agit d\'un point réalisé en entreprise, avec le tuteur, lors de chaque période en entreprise.</p><p><b>Point savoir-être : </b> Il s\'agit d\'une évaluation du comportement de l\'apprenant, elle se fait en entreprise, moins souvent que les points entreprise</p>. <p>Vous les retrouvez dans l\'onglet "Livret de suivi"</p>',
        },
        {
          intro: 'Qu\'est-ce qu\'une évaluation ?',
          img: 'help/help-evaluations',
          details: '<p>Une évaluation correspond à l\'évaluation d\'une compétence du référentiel. </p><p><b>Une évaluation = une case cochée par questionnaire (cf image ci-dessous)</b></p><p><b>"1/5 Compétences évaluées"</b> = une compétence parmi les 5 compétences du référentiel a été évaluée</p><p><b>"3 en moyenne"</b> = une compétence est évaluée 3 fois en moyenne</p> <p>L\'évaluation des compétences du référentiel se fait pour chaque expérience pédagogique du parcours.</p>',
        },
      ],
      listActivities: [],
      isOpenActivities: null,
      rangeSelected: 'all',
      selected: new Date(),
      showWeekNumber: false,
      locale: 'fr-FR',
      start_date: null,
      end_date: null,
      twoweeks: new Date(),
      month: new Date(),
      today: new Date(),
    };
  },
};
</script>
<style lang="scss" scoped>
.card2 .column,
.stats-header .column {
  padding-top: 0px;
  padding-bottom: 0px;
}

.stats-header,
.card_student {
  padding: 0.5rem 2rem 0.5rem 2rem;
}

.sequenceType {
  padding: 0.4rem 0.5rem !important;
}

#btn-mobile {
  display: none !important;
}

.card_student {
  margin-bottom: 1rem;
  padding: 1.2rem 2rem 1.5rem 2rem;
}

.statsLine {
  padding-top: 0rem;

  .column {
    flex: none;
  }
}

.card_stat {
  padding: 0.2rem 0.2rem 0.3rem;
  margin-bottom: 0.5rem;
}

.stat_cardlabel {
  font-size: 0.75rem;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 1px;
  padding-bottom: 0.5rem;
}

.progressbar {
  max-width: 400px;
}

.stat-cell {
  display: flex;
  align-items: center;
  padding: 0 0.5rem;
  border-right: 1px solid #e3e5eb;

  .stat_label {
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 1.1;
    padding-left: 0.5rem;
  }

  .stat_number {
    font-size: 1.5rem;
  }

  &:last-child {
    border-right: 0px solid #000;
  }
}

.card_stat_header {
  height: 100px;
  padding: 0.5rem 1rem 1rem;
  margin-bottom: 0px;

  .stat_number {
    font-size: 1.7rem;
  }

  .stat_label {
    text-transform: uppercase;
    text-align: center;
    font-size: 0.75rem;
    line-height: 1.3;
  }

  .is-flex {
    height: 100%;
  }

  .stat-cell {
    margin: auto;
  }
}

.cards-header .column:first-child {
  margin-left: auto;
}

.name-column {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  width: 440px;

  b {
    font-size: 1.2rem;
  }
}

.progress-wrapper {
  min-width: 200px;
}

.progressbar {
  padding-top: 0.7rem;
  margin-left: auto;
}

.card_grey {
  padding: 0.5rem 1rem 0.5rem 2rem;
  border-radius: 0.2rem;
}

@media (min-width: 1799px) {
  .name_student {
    -webkit-box-flex: 0;
    -ms-flex: none;
    flex: none;
    width: 400px;
  }

  .name-colum,
  .statsLine {
    margin-bottom: 0px;
  }
}

.statsLine {
  flex-direction: column !important;
}

.name-column {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.name_student {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  width: 300px;
}

@media (max-width: 1024px) {
  .cards-header .column:first-child {
    margin-left: 0px;
  }

  .subtitle-paragraph {
    margin-bottom: 0px;
  }
}

@media (max-width: 1500px) {
  .name_student {
    width: 160px;

    b {
      font-size: 0.95rem;
    }
  }
}

.col-stats {
  width: auto;
}

.col-exp {
  width: 39.666667%;
  flex: none;
}

.col-activity {
  width: 14.66%;
  flex: none;
}

.col-eval {
  width: 148px;
  flex: none;
}

.col-waypoint {
  width: 15%;
  flex: none;
  min-width: 205px;
}

.col-detail {
  width: 15%;
  flex: none;
}

.stats-header {

  .col-exp,
  .col-activity,
  .col-waypoint,
  .col-eval,
  .col-detail {
    text-align: center;
  }
}

.col-exp,
.col-activity,
.col-waypoint,
.col-eval,
.col-detail {
  justify-content: center;
}

.col-detail {
  width: 135px;
}

.date-creation {
  font-size: 0.9rem;
}

.legend_item {
  margin-right: 1rem;
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
  max-width: 240px;
  display: flex;
  line-height: 1.2;
  align-items: center;

  .tag {
    margin-right: 0.5rem;
  }
}

.legend-col {
  .title {
    font-size: 0.9rem;
    margin-bottom: 0.7rem;
  }
}

.tag.is-outlined.is-success {
  border: 1px solid var(--success);
  color: var(--success) !important;
  background-color: #fff;
}

img {
  width: 30px !important;
}

@media (max-width: 1800px) {
  .col-stats {
    justify-content: space-around;
  }

  .name_student {
    width: 200px;
  }

  .stats-header,
  .card_student {
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  }

  .col-waypoint {
    width: 168px;
    flex: none;
  }
}

@media (max-width: 1600px) {
  .name_student {
    width: 140px;
  }
}

@media (max-width: 1430px) {
  .stats-header {
    display: none !important;
  }
}

@media (max-width: 1260px) {
  .col-stats {
    order: 3;
    width: 100%;
    flex: none;
  }

  .stats-header {

    .col-name,
    .col-detail {
      display: none;
    }
  }

  .col-detail {
    margin-left: auto;
  }

  .col-exp,
  .col-activity,
  .col-waypoint,
  .col-eval {
    width: auto;
  }

  .col-stats {
    justify-content: space-between;
    margin-left: 0px;
    margin-right: 0px;
  }
}

@media (max-width: 1300px) {
  .statsLine .is-multiline {
    justify-content: center;
  }

  .statsLine .column.is-narrow {
    flex: none;
  }

  #btn-mobile {
    display: flex !important;
    margin-left: auto;
  }

  #btn-widescreen {
    display: none !important;
  }

  .name-column {
    flex-direction: column !important;
    display: block;

    .progressbar {
      margin-right: auto;
      margin-left: 0px;
      max-width: unset;
    }

    .progress-wrapper {
      min-width: 200px;
    }
  }

  .name_student {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .card-edit-sequence .situation {
    padding-top: 0.7rem;
    padding-bottom: 0.7rem;
    display: block;
    margin-bottom: 0.7rem !important;
  }
}

@media (max-width: 750px) {
  .cards .card_item:first-child {
    margin-left: auto;
  }

  .cards .card_item:last-child {
    margin-right: auto !important;
  }
}
</style>
