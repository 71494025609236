import Vue from 'vue';
import VueRouter from 'vue-router';
import Cookies from 'js-cookie';
import Dashboard from '@/views/Dashboard.vue';
import Error from '@/views/Error.vue';
import Login from '@/views/Login.vue';
import ResetPassword from '@/views/ResetPassword.vue';
import ResetPasswordConfirmation from '@/views/ResetPasswordConfirmation.vue';
import Frameworks from '@/views/Frameworks.vue';
import Trainings from '@/views/Trainings.vue';
import Training from '@/views/Training.vue';
import Users from '@/views/Users.vue';
import Agenda from '@/views/Agenda.vue';
import Resources from '@/views/Resources.vue';
import FrameworkCreation from '@/views/FrameworkCreation.vue';
import FrameworkEdit from '@/views/FrameworkEdit.vue';
import Framework from '@/views/Framework.vue';
import TrainingEdit from '@/views/TrainingEdit.vue';
import UserProfile from '@/views/UserProfile.vue';
import FrameworkSequenceDetail from '@/views/FrameworkSequenceDetail.vue';
import TrainingSequenceDetail from '@/views/TrainingSequenceDetail.vue';
import FrameworkSequenceDetailEdit from '@/views/FrameworkSequenceDetailEdit.vue';
import TrainingSequenceDetailEdit from '@/views/TrainingSequenceDetailEdit.vue';
import StudentTrainingEdit from '@/views/StudentTrainingEdit.vue';
import StudentTraining from '@/views/StudentTraining.vue';
import StudentActivityDetailEdit from '@/views/StudentActivityDetailEdit.vue';
import Notifications from '@/views/Notifications.vue';
import UserCreation from '@/views/UserCreation.vue';
import store from '@/store';
import client from '@/api/client';
import Planning from '@/views/Planning.vue';
import GetHelp from '@/views/GetHelp.vue';
import SequencesWiki from '@/views/SequencesWiki.vue';
import RolesWiki from '@/views/RolesWiki.vue';
import Faq from '@/views/FaqWiki.vue';
import StartWiki from '@/views/StartWiki.vue';
import StudentFreeSituationEdit from '@/views/Activity/StudentFreeSituationEdit.vue';
import StudentFreeSituationRead from '@/views/Activity/StudentFreeSituationRead.vue';
import StudentSequenceDetail from '@/views/StudentSequenceDetail.vue';
import StudentActivity from '@/views/Activity/StudentActivity.vue';
import StudentProgressionEdit from '@/views/Activity/StudentProgressionEdit.vue';
import StudentProgressionRead from '@/views/Activity/StudentProgressionRead.vue';
import WaypointEdit from '@/views/Waypoints/WaypointEdit.vue';
import WaypointVaeEdit from '@/views/Waypoints/WaypointVaeEdit.vue';
import WaypointitiiEdit from '@/views/Waypoints/WaypointitiiEdit.vue';
import WaypointRead from '@/views/Waypoints/WaypointRead.vue';
import PeriodRead from '@/views/Waypoints/PeriodRead.vue';
import AttitudeEvaluationEdit from '@/views/Waypoints/AttitudeEvaluationEdit.vue';
import AttitudeEvaluationRead from '@/views/Waypoints/AttitudeEvaluationRead.vue';
import LtiConnect from '@/views/LtiConnect.vue';
import BookletPartEdit from '@/views/Booklet/BookletPartEdit.vue';
import BookletPartRead from '@/views/Booklet/BookletPartRead.vue';
import DashboardManagement from '@/views/DashboardManagement.vue';
// Portfolio
import PortfolioSingle from '@/views/Portfolio/PortfolioSingle.vue';
// Legal infos
import LegalInfos from '@/views/LegalPages/LegalInfos.vue';
import UserConditions from '@/views/LegalPages/UserConditions.vue';
import StudentPracticalWork from '@/views/Activity/StudentPracticalWork.vue';
import StudentPracticalWorks from '@/views/Activity/StudentPracticalWorks.vue';
import StudentFreeNoteEdit from '@/views/Activity/StudentFreeNoteEdit.vue';
import Calendar from '@/views/Calendar.vue';
import WaypointVisiteEdit from '@/views/Waypoints/WaypointVisiteEdit.vue';

Vue.use(VueRouter);

const routes = [{
  path: '/',
  name: 'Dashboard',
  component: Dashboard,
  meta: {
    title: 'Tableau de bord - inSitu',
    requiresAuth: true,
  },
},
{
  path: '/authentification',
  name: 'Login',
  component: Login,
  meta: {
    title: 'Commencez ! - inSitu',
  },
},
{
  path: '/reinitialisation',
  name: 'ResetPassword',
  component: ResetPassword,
  meta: {
    title: 'Réinitialisation  - inSitu',
  },
},
{
  path: '/modification',
  name: 'ResetPasswordConfirmation',
  component: ResetPasswordConfirmation,
  meta: {
    title: 'Confirmation  - inSitu',
  },
},
{
  path: '/maquettes',
  name: 'Frameworks',
  component: Frameworks,
  meta: {
    title: 'Maquettes - inSitu',
    header_title: 'Maquettes de formation',
    requiresAuth: true,
  },
  beforeEnter: (to, from, next) => {
    if (store.state.auth.currentUser.role === 'coordinateur' || store.state.auth.currentUser.role === 'superviseur') {
      next();
    } else {
      next({ name: 'Dashboard' });
    }
  },
},
{
  path: '/maquettes/:id/:tab',
  name: 'Maquette',
  component: Framework,
  meta: {
    title: 'Consulter une maquette - inSitu',
    header_title: 'Consulter une maquette',
    requiresAuth: true,

  },
  beforeEnter: (to, from, next) => {
    if (store.state.auth.currentUser.role === 'coordinateur' || store.state.auth.currentUser.role === 'superviseur') {
      next();
    } else {
      next({ name: 'Dashboard' });
    }
  },
},
{
  path: '/creer-une-maquette',
  name: 'FrameworkCreation',
  component: FrameworkCreation,
  meta: {
    title: 'Créer une maquette - inSitu',
    header_title: 'Créer une maquette',
    requiresAuth: true,
    Coordinator: true,
    Administrator: true,
  },
  beforeEnter: (to, from, next) => {
    if (store.state.auth.currentUser.role === 'coordinateur' || store.state.auth.currentUser.role === 'superviseur') {
      next();
    } else {
      next({ name: 'Dashboard' });
    }
  },
},
{
  path: '/modifier-une-maquette/:id/:tab',
  name: 'FrameworkEdit',
  component: FrameworkEdit,
  meta: {
    title: 'Éditer une maquette - inSitu',
    header_title: 'Éditer une maquette',
    requiresAuth: true,
    stepNumber: 4,
    Coordinator: true,
    Administrator: true,
  },
  beforeEnter: (to, from, next) => {
    if (store.state.auth.currentUser.role === 'coordinateur' || store.state.auth.currentUser.role === 'superviseur') {
      next();
    } else {
      next({ name: 'Dashboard' });
    }
  },
},
{
  path: '/maquette-sequence/:id/:idFramework',
  name: 'FrameworkSequence',
  component: FrameworkSequenceDetail,
  meta: {
    title: 'Détail d\'une expérience - inSitu',
    header_title: 'Détail d\'une expérience',
    requiresAuth: true,
  },
  beforeEnter: (to, from, next) => {
    if (store.state.auth.currentUser.role === 'coordinateur' || store.state.auth.currentUser.role === 'superviseur') {
      next();
    } else {
      next({ name: 'Dashboard' });
    }
  },
},
{
  path: '/maquette-sequence/edition/:id/:idFramework',
  name: 'FrameworkSequenceDetailEdit',
  component: FrameworkSequenceDetailEdit,
  meta: {
    title: 'Éditer une expérience - inSitu',
    header_title: 'Éditer une expérience',
    requiresAuth: true,
  },
  beforeEnter: (to, from, next) => {
    if (store.state.auth.currentUser.role === 'coordinateur' || store.state.auth.currentUser.role === 'superviseur') {
      next();
    } else {
      next({ name: 'Dashboard' });
    }
  },
},
{
  path: '/formation-sequence/:id/:idTraining',
  name: 'TrainingSequence',
  component: TrainingSequenceDetail,
  meta: {
    title: 'Détail d\'une expérience - inSitu',
    header_title: 'Détail d\'une expérience',
    requiresAuth: true,
  },
},
{
  path: '/formation-sequence/edition/:id/:idTraining',
  name: 'TrainingSequenceDetailEdit',
  component: TrainingSequenceDetailEdit,
  meta: {
    title: 'Détail d\'une expérience - inSitu',
    requiresAuth: true,
  },
},
{
  path: '/formation-apprenant-edit/:idTraining/:idStudent/:idStudentTraining',
  name: 'StudentTrainingEdit',
  component: StudentTrainingEdit,
  meta: {
    title: 'Détail d\'une parcours de formation d\'un apprenant - inSitu',
    header_title: 'Détail d\'une expérience',
    requiresAuth: true,
  },
  beforeEnter: (to, from, next) => {
    if (store.state.auth.currentUser.role === 'coordinateur' || store.state.auth.currentUser.role === 'superviseur') {
      next();
    } else {
      next({ name: 'Dashboard' });
    }
  },
},
{
  path: '/formation-apprenant/:idTraining/:idStudent/:idStudentTraining',
  name: 'StudentTrainingHome',
  component: StudentTraining,
  meta: {
    title: 'Détail d\'une parcours de formation d\'un apprenant - inSitu',
    header_title: 'Détail d\'une parcours de formation d\'un apprenant',
    requiresAuth: true,
  },
},
{
  path: '/formation-apprenant/:idTraining/:idStudent/:idStudentTraining/:tab',
  name: 'StudentTraining',
  component: StudentTraining,
  meta: {
    title: 'Détail d\'une parcours de formation d\'un apprenant - inSitu',
    header_title: 'Détail d\'une parcours de formation d\'un apprenant',
    requiresAuth: true,
  },
},
{
  path: '/formations',
  name: 'Trainings',
  component: Trainings,
  meta: {
    title: 'Formations - inSitu',
    header_title: 'Formations',
    requiresAuth: true,
  },
},
{
  path: '/formations/:idTraining',
  name: 'TrainingHome',
  component: Training,
  meta: {
    title: 'Formation - inSitu',
    header_title: 'Formation',
    requiresAuth: true,
  },
},
{
  path: '/formations/:idTraining/:tab',
  name: 'Training',
  component: Training,
  meta: {
    title: 'Formation - inSitu',
    header_title: 'Formation',
    requiresAuth: true,
  },
},
{
  path: '/editer-une-formation/:idTraining/:tab',
  name: 'TrainingEdit',
  component: TrainingEdit,
  meta: {
    title: 'Éditer une formation - inSitu',
    header_title: 'Éditer une formation',
    requiresAuth: true,
  },
  beforeEnter: (to, from, next) => {
    if (store.state.auth.currentUser.role === 'coordinateur' || store.state.auth.currentUser.role === 'formateur' || store.state.auth.currentUser.role === 'superviseur') {
      next();
    } else {
      next({ name: 'Dashboard' });
    }
  },
},
{
  path: '/agenda',
  name: 'Agenda',
  component: Agenda,
  meta: {
    title: 'Agenda - inSitu',
    header_title: 'Agenda',
    requiresAuth: true,
  },
},
{
  path: '/calendrier',
  name: 'Calendar',
  component: Calendar,
  meta: {
    title: 'Calendrier - inSitu',
    header_title: 'Calendrier',
    requiresAuth: true,
  },
},
{
  path: '/calendrier/:idTraining/:idStudent/:idStudentTraining',
  name: 'CalendarGlobal',
  component: Calendar,
  meta: {
    title: 'Calendrier - inSitu',
    header_title: 'Calendrier',
    requiresAuth: true,
  },
},
{
  path: '/planning',
  name: 'Planning',
  component: Planning,
  meta: {
    title: 'Planning - inSitu',
    header_title: 'Planning',
    requiresAuth: true,
  },
},
{
  path: '/activite-edition/:idTraining/:idSequence/:idStudentTraining/:idActivity',
  name: 'StudentActivityDetailEdit',
  component: StudentActivityDetailEdit,
  meta: {
    title: 'Détail d\'une activité - inSitu',
    header_title: 'Détail d\'une activité',
    requiresAuth: true,
  },
},
{
  path: '/sequence-edition/:idTraining/:idSequence/:idStudentTraining/',
  name: 'StudentSequenceDetail',
  component: StudentSequenceDetail,
  meta: {
    title: 'Détail d\'une expérience - inSitu',
    header_title: 'Détail d\'une expérience',
    requiresAuth: true,
  },
},
{
  path: '/activite-detail/:idTraining/:idSequence/:idStudentTraining/:idActivity',
  name: 'StudentActivity',
  component: StudentActivity,
  meta: {
    title: 'Détail d\'une activité - inSitu',
    header_title: 'Détail d\'une activité',
    requiresAuth: true,
  },
},
{
  path: '/progression/:idTraining/:idSequence/:idStudentTraining/:idRevision',
  name: 'StudentProgressionEdit',
  component: StudentProgressionEdit,
  meta: {
    title: 'Détail d\'une progression',
    header_title: 'Détail d\'une progression',
    requiresAuth: true,
  },
},
{
  path: '/progression-lecture/:idTraining/:idSequence/:idStudentTraining/:idRevision',
  name: 'StudentProgressionRead',
  component: StudentProgressionRead,
  meta: {
    title: 'Détail d\'une progression',
    header_title: 'Détail d\'une progression',
    requiresAuth: true,
  },
},
{
  path: '/nouvelle-activite-edition/:idTraining/:idStudentTraining/:idStudent/:idFree',
  name: 'StudentFreeSituationEdit',
  component: StudentFreeSituationEdit,
  meta: {
    title: 'Détail d\'une activité libre - inSitu',
    header_title: 'Détail d\'une activité libre',
    requiresAuth: true,
  },
},
{
  path: '/nouvelle-activite/:idTraining/:idStudentTraining/:idStudent/:idFree',
  name: 'StudentFreeSituationRead',
  component: StudentFreeSituationRead,
  meta: {
    title: 'Détail d\'une activité libre - inSitu',
    header_title: 'Détail d\'une activité libre',
    requiresAuth: true,
  },
},
{
  path: '/activite-pratique/:idTraining/:idStudent/:idStudentTraining/:idPracticalWork/:idSession/:idStudentPracticalWork',
  name: 'StudentPracticalWork',
  component: StudentPracticalWork,
  meta: {
    title: 'Détail d\'une activité pratique - inSitu',
    header_title: 'Détail d\'une activité',
    requiresAuth: true,
  },
},
{
  path: '/activites-pratiques/:idTraining/:idStudent',
  name: 'StudentPracticalWorks',
  component: StudentPracticalWorks,
  meta: {
    title: 'Détail d\'une activité pratique - inSitu',
    header_title: 'Détail d\'une activité',
    requiresAuth: true,
  },
},
{
  path: '/nouvelle-note-edition/:idTraining/:idStudentTraining/:idStudent/:idCustom',
  name: 'StudentFreeNoteEdit',
  component: StudentFreeNoteEdit,
  meta: {
    title: 'Détail d\'une activité libre - inSitu',
    header_title: 'Détail d\'une activité libre',
    requiresAuth: true,
  },
},
{
  path: '/point-etape-edition/:idStudentTraining/:idTraining/:idStudent/:idWaypoint',
  name: 'WaypointEdit',
  component: WaypointEdit,
  meta: {
    title: 'Détail d\'un point d\'étape - inSitu',
    header_title: 'Détail d\'un point d\'étape',
    requiresAuth: true,
  },
},
{
  path: '/point-edition/:idStudentTraining/:idTraining/:idStudent/:idWaypoint',
  name: 'WaypointVaeEdit',
  component: WaypointVaeEdit,
  meta: {
    title: 'Détail d\'un point d\'étape - inSitu',
    header_title: 'Détail d\'un point d\'étape',
    requiresAuth: true,
  },
},
{
  path: '/point-etape/:idStudentTraining/:idTraining/:idStudent/:idWaypoint',
  name: 'WaypointRead',
  component: WaypointRead,
  meta: {
    title: 'Détail d\'un point d\'étape - inSitu',
    header_title: 'Détail d\'un point d\'étape',
    requiresAuth: true,
  },
},
{
  path: '/validation-edition/:idStudentTraining/:idTraining/:idStudent/:simple_booklet_part_validation',
  name: 'WaypointitiiEdit',
  component: PeriodRead,
  meta: {
    title: 'Détail d\'un point d\'étape - inSitu',
    header_title: 'Détail d\'un point d\'étape',
    requiresAuth: true,
  },
},
{
  path: '/validation-old/:idStudentTraining/:idTraining/:idStudent/:simple_booklet_part_validation',
  name: 'WaypointitiiEditOld',
  component: WaypointitiiEdit,
  meta: {
    title: 'Détail d\'un point d\'étape - inSitu',
    header_title: 'Détail d\'un point d\'étape',
    requiresAuth: true,
  },
},
{
  path: '/periode/:idStudentTraining/:idTraining/:idStudent/:simple_booklet_part_validation',
  name: 'PeriodRead',
  component: PeriodRead,
  meta: {
    title: 'Détail d\'un point d\'étape - inSitu',
    header_title: 'Détail d\'un point d\'étape',
    requiresAuth: true,
  },
},
{
  path: '/point-comportement-edition/:idStudentTraining/:idTraining/:idStudent/:idWaypoint',
  name: 'AttitudeEvaluationEdit',
  component: AttitudeEvaluationEdit,
  meta: {
    title: 'Edition d\'une évaluation de comportement - inSitu',
    header_title: 'Edition d\'une évaluation de comportement',
    requiresAuth: true,
  },
},
{
  path: '/point-comportement/:idStudentTraining/:idTraining/:idStudent/:idWaypoint',
  name: 'AttitudeEvaluationRead',
  component: AttitudeEvaluationRead,
  meta: {
    title: 'Détail d\'une évaluation de comportement - inSitu',
    header_title: 'Détail d\'une évaluation de comportement',
    requiresAuth: true,
  },
},
{
  path: '/livret-edition/:idStudentTraining/:idTraining/:idStudent/:idPart',
  name: 'BookletPartEdit',
  component: BookletPartEdit,
  meta: {
    title: 'Détail d\'une partie d\'un livret',
    header_title: 'Détail d\'une partie d\'un livret',
    requiresAuth: true,
  },
},
{
  path: '/livret/:idStudentTraining/:idTraining/:idStudent/:idPart',
  name: 'BookletPartRead',
  component: BookletPartRead,
  meta: {
    title: 'Détail d\'une partie d\'un livret',
    header_title: 'Détail d\'une partie d\'un livret',
    requiresAuth: true,
  },
},
{
  path: '/formations/:idTraining/suivi/:tabDashboard',
  name: 'DashboardManagement',
  component: DashboardManagement,
  meta: {
    title: 'Tableau de bord - inSitu',
    header_title: 'Suivez une formation',
    requiresAuth: true,
  },
  beforeEnter: (to, from, next) => {
    if (store.state.auth.currentUser.role === 'coordinateur' || store.state.auth.currentUser.role === 'formateur' || store.state.auth.currentUser.role === 'superviseur' || store.state.auth.currentUser.role === 'manager') {
      next();
    } else {
      next({ name: 'Dashboard' });
    }
  },
},
{
  path: '/formations/:idTraining/suivi/:tabDashboard/:idStep',
  name: 'DashboardManagementStep',
  component: DashboardManagement,
  meta: {
    title: 'Tableau de bord - inSitu',
    header_title: 'Suivez une formation',
    requiresAuth: true,
  },
  beforeEnter: (to, from, next) => {
    if (store.state.auth.currentUser.role === 'coordinateur' || store.state.auth.currentUser.role === 'formateur' || store.state.auth.currentUser.role === 'superviseur' || store.state.auth.currentUser.role === 'manager') {
      next();
    } else {
      next({ name: 'Dashboard' });
    }
  },
},
{
  path: '/utilisateurs',
  name: 'Utilisateurs',
  component: Users,
  meta: {
    title: 'Utilisateurs - inSitu',
    header_title: 'Utilisateurs',
    requiresAuth: true,
  },
},
{
  path: '/utilisateurs/:id',
  name: 'Utilisateur',
  component: UserProfile,
  meta: {
    title: 'Utilisateur - inSitu',
    header_title: 'Utilisateur',
    requiresAuth: true,
  },
},
{
  path: '/utilisateurs/:id/:step',
  name: 'UtilisateurDetail',
  component: UserProfile,
  meta: {
    title: 'Utilisateur - inSitu',
    header_title: 'Utilisateur',
    requiresAuth: true,
  },
},
{
  path: '/lti-connect/:token',
  name: 'LtiConnect',
  component: LtiConnect,
  meta: {
    title: 'LTI - inSitu',
    header_title: 'LTI',
    requiresAuth: false,
  },
},
{
  path: '/creer-un-utilisateur/',
  name: 'UserCreation',
  component: UserCreation,
  meta: {
    title: 'Utilisateur - inSitu',
    header_title: 'Utilisateur',
    requiresAuth: true,

  },
  beforeEnter: (to, from, next) => {
    if (store.state.auth.currentUser.role === 'coordinateur' || store.state.auth.currentUser.role === 'superviseur') {
      next();
    } else {
      next({ name: 'Dashboard' });
    }
  },
},
{
  path: '/ressources',
  name: 'Resources',
  component: Resources,
  meta: {
    title: 'Ressources - inSitu',
    header_title: 'Ressources',
    requiresAuth: true,
  },
},
{
  path: '/mes-notifications',
  name: 'Notifications',
  component: Notifications,
  meta: {
    title: 'Mes notifications - inSitu',
    header_title: 'Notifications',
    requiresAuth: true,
  },
},
{
  path: '/aide',
  name: 'GetHelp',
  component: GetHelp,
  meta: {
    title: 'A l\'aide ! - inSitu',
    header_title: 'À l\'aide',
    requiresAuth: true,
  },
},
{
  path: '/wiki/sequences',
  name: 'SequencesWiki',
  component: SequencesWiki,
  meta: {
    title: 'Types d\'expériences - inSitu',
    header_title: 'Les types d\'expériences',
    requiresAuth: true,
  },
},
{
  path: '/wiki/roles',
  name: 'RolesWiki',
  component: RolesWiki,
  meta: {
    title: 'Rôles - inSitu',
    header_title: 'Les rôles dans inSitu',
    requiresAuth: true,
  },
},
{
  path: '/wiki/faq',
  name: 'Faq',
  component: Faq,
  meta: {
    title: 'Questions fréquentes - inSitu',
    header_title: 'Questions fréquentes',
    requiresAuth: true,
  },
},
{
  path: '/wiki/start',
  name: 'StartWiki',
  component: StartWiki,
  meta: {
    title: 'Checklist démarrage - inSitu',
    header_title: 'Démarrer une formation',
    requiresAuth: true,
  },
},
{
  path: '/conditions-utilisation',
  name: 'UserConditions',
  component: UserConditions,
  meta: {
    title: 'Conditons d\'utilisation - inSitu',
    header_title: 'Conditions d\'utilisation',
    requiresAuth: true,
  },
},
{
  path: '/politique-de-confidentialite',
  name: 'LegalInfos',
  component: LegalInfos,
  meta: {
    title: 'Politique de confidentialité - inSitu',
    header_title: 'Politique de confidentialité',
    requiresAuth: true,
  },
},
{
  path: '/portfolios/:slug',
  name: 'PortfolioSingle',
  component: PortfolioSingle,
  meta: {
    title: 'Portfolio - inSitu',
    header_title: 'Portfolio',
    requiresAuth: false,
  },
},

{
  path: '/point-visite/:idStudentTraining/:idTraining/:idStudent/:idWaypoint',
  name: 'WaypointVisiteEdit',
  component: WaypointVisiteEdit,
  meta: {
    title: 'Détail d\'un point d\'étape - inSitu',
    header_title: 'Détail d\'un point d\'étape',
    requiresAuth: true,
  },
},

{
  path: '/*',
  name: '404',
  component: Error,
  meta: {
    title: 'Oups... - inSitu',
    requiresAuth: false,
  },
},
];

const router = new VueRouter({
  routes,
  mode: 'history',
});

router.beforeEach(async (to, from, next) => {
  document.title = to.meta.title;
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // the requested route requires authentication
    if (!Cookies.get('token')) {
      // no user is logged in, we redirect to the login page
      next({
        name: 'Login',
        query: { redirect: to.fullPath }, // store the location the user was trying to access
      });
    } else if (!store.state.auth.currentUser) {
      // a user is already logged in, we can route him to the requested page
      // ...after grabbing personal information
      try {
        const response = await client.get('/whoami/', {
          headers: {
            Authorization: `Bearer ${Cookies.get('token')}`,
          },
        });
        // storing infos
        store.commit('auth/SET_CURRENT_USER', response.data);
        next();
      } catch (error) {
        window.console.log('Error:', error);
        next({
          name: 'Login',
          query: { redirect: to.fullPath }, // store the location the user was trying to access
        });
      }
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
