<template>
  <div>
    <b-loading :is-full-page="isFullPage" v-model="isLoading"> </b-loading>
    <div v-if="training.training_periods">
      <div v-if="training.training_periods.length">
        <div class="userCount">Parcours pédagogique</div>
        <div class="helpUser mt-0">
          <div class="title">
            Voici le parcours pédagogique de votre formation
          </div>
          <div>
            Il s'agit du parcours général de votre formation. Certains
            ajustements sont apportés par apprenant.e. Pour accéder au parcours
            spécifique de chaque apprenant.e, allez dans la partie
            <span @click="gotoStep(4)" class="url-simple"
              >"suivi apprenant.e.s".</span
            >
          </div>
        </div>
        <div class="mb-5">
          <b-button
            rounded
            @click="isOpenPeriod = true"
            v-if="isOpenPeriod === false"
            size="is-small"
            >ouvrir tout</b-button
          >
          <b-button
            rounded
            @click="isOpenPeriod = false"
            v-if="isOpenPeriod === true"
            size="is-small"
            >fermer tout</b-button
          >
        </div>
        <div class="simpleParcours">
          <b-collapse
            class="card"
            animation="slide"
            v-for="(period, index) of training.training_periods"
            v-bind:key="index"
            :open="isOpenPeriod"
          >
            <div
              slot="trigger"
              slot-scope="props"
              class="card-header"
              role="button"
            >
              <div
                class="bg-card is-flex bg-primary align-items-center"
                :class="'periodType' + period.type"
              >
                <a class="card-header-icon">
                  <b-icon v-bind:icon="props.open ? 'menu-down' : 'menu-up'">
                  </b-icon>
                </a>
                <p class="card-header-title">
                  {{ period.name }}
                </p>
              </div>
            </div>
            <div class="card-content">
              <div
                class="
                  pointer
                  card-edit-sequence
                  is-flex
                  align-items-center
                  w-100
                "
                v-for="sequence in order(period.training_sequences)"
                v-bind:key="sequence.id"
                @click.prevent="seeSequencedetail(sequence.id)"
              >
                <div class="bloc-left is-flex mr-auto align-items-center">
                  <div
                    class="sequenceType situation justify-content-center"
                    type="situation"
                    :class="'sequenceType' + sequence.sequence_type"
                  >
                    {{ sequence.sequence_type_label }}
                  </div>

                  <div class="card-header-title">{{ sequence.name }}</div>
                </div>
                <!-- <div
                  v-if="sequence.teachers.length"
                  class="teacherSequence pr-4 has-text-centered is-flex"
                >
                  <div>
                    <b
                      >Formateur<span v-if="sequence.teachers.length > 1"
                        >s</span
                      >
                      :</b
                    >
                  </div>
                  <div v-for="teacher in sequence.teachers" :key="teacher.id">
                    <div v-if="getUser(teacher)">
                      {{ getUser(teacher).user.first_name.substr(0, 1) }}.
                      {{ getUser(teacher).user.last_name }}
                    </div>
                  </div>
                </div>
                <div v-else></div> -->
                <div class="actions is-flex align-items-center">
                  <div class="dates-schedulded has-text-centered is-flex">
                    <div v-if="sequence.start_date" class="has-text-centered">
                      <div class="date-sequence" v-if="sequence.start_date">
                        {{ sequence.start_date | day }}
                      </div>
                    </div>
                    <div v-if="sequence.end_date" class="has-text-centered">
                      <div class="date-sequence" v-if="sequence.end_date">
                        {{ sequence.end_date | day }}
                      </div>
                    </div>
                    <div v-else class="date-sequence has-text-centered">-</div>
                  </div>
                  <div
                    class="
                      is-flex
                      align-items-center
                      justify-content-between
                      row-responsive
                    "
                  ></div>
                  <div class="actions-buttons is-flex align-items-center">
                    <a class="actions-icon">
                      <b-icon icon="see" size="is-medium" type="is-black">
                      </b-icon>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </b-collapse>
        </div>
      </div>
      <div v-else>Aucune expérience enregistrée</div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import { mapState } from 'vuex';

export default {
  props: {
    objectId: {
      type: Number,
    },
  },
  filters: {
    moment(date) {
      return moment(date).format('D MMMM YYYY');
    },
    day(date) {
      return moment(date).format('D/MM/YY');
    },
    hour(date) {
      return moment(date).format('HH:mm');
    },
  },
  computed: {
    ...mapState('auth', ['currentUser']),
  },
  data() {
    return {
      isLoading: true,
      isFullPage: false,
      isOpenPeriod: true,
      training: [],
    };
  },
  methods: {
    initTraining() {
      this.startLoading();
      this.$http
        .get(`/trainings/${this.$route.params.idTraining}/?query={training_periods}&format=json`)
        .then((response) => {
          this.training = response.data;
          // if (this.currentUser.role === 'apprenant'
          // || this.currentUser.role === 'manager' || this.currentUser.role === 'tuteur') {
          //   this.verifStatusTraining();
          // }
          this.training.training_periods = this.order(this.training.training_periods);
          this.endLoading();
        });
    },
    seeSequencedetail(param) {
      this.$router.push({
        name: 'TrainingSequence',
        params: { id: param, idTraining: this.$route.params.idTraining, stepNumber: this.step },
      }).catch((error) => {
        window.console.log(error);
        this.errored = true;
        this.$buefy.dialog.alert({
          title: 'Mince...',
          message: 'Nous ne pouvons accéder au détail de l\'expérience',
          type: 'is-info',
          hasIcon: true,
          icon: 'times-circle',
          ariaRole: 'alertdialog',
          ariaModal: true,
          confirmText: 'Ok',
        });
      });
    },
    startLoading() {
      this.isLoading = true;
    },
    endLoading() {
      this.isLoading = false;
    },
    order(array) {
      return _.orderBy(array, 'position');
    },
  },
  created() {
    this.initTraining();
  },
};
</script>
