<template>
  <div
    tabindex="-1"
    role="dialog"
    aria-label="Example Modal"
    aria-modal="true"
    class="modal is-active"
  >
    <div class="modal-background"></div>
    <div
      class="animation-content"
      style="max-width: 1260px; background-color: #fff"
    >
      <div class="modal-card" style="background-color: #fff">
        <a @click="$emit('close')" class="ml-auto">
          <b-icon icon="close" type="is-primary"></b-icon>
        </a>
        <div class="nameSequence">{{ sequence.name }}</div>
        <!-- <div class="accordion">
          <p class="card-header-title">Description</p>
        </div> -->
        <div class="content" v-html="sequence.description"></div>
        <b-collapse :open="false" aria-id="contentIdForA11y1">
          <template #trigger="props">
            <div class="accordion" role="button">
              <a class="card-header-icon">
                <b-icon :icon="props.open ? 'menu-down' : 'menu-up'"> </b-icon>
              </a>
              <p class="card-header-title">Contexte</p>
            </div>
          </template>
          <div class="content" v-html="sequence.context"></div>
        </b-collapse>
        <b-collapse :open="false" aria-id="contentIdForA11y1">
          <template #trigger="props">
            <div class="accordion" role="button">
              <a class="card-header-icon">
                <b-icon :icon="props.open ? 'menu-down' : 'menu-up'"> </b-icon>
              </a>
              <p class="card-header-title">Qui participe ?</p>
            </div>
          </template>
          <div class="content">
            <ul>
              <li v-for="right in sequence.rights" :key="right.id">
                <b>{{ right.role }}</b> :
                <span>{{ right.access }}</span>
              </li>
            </ul>
          </div>
        </b-collapse>
        <b-collapse :open="false" aria-id="contentIdForA11y1">
          <template #trigger="props">
            <div class="accordion" role="button">
              <a class="card-header-icon">
                <b-icon :icon="props.open ? 'menu-down' : 'menu-up'"> </b-icon>
              </a>
              <p class="card-header-title">Liste des activités</p>
            </div>
          </template>
          <div class="content">
            <div class="card_grey">
              <b-steps
                position="is-left"
                label-position="right"
                size="is-medium"
                vertical
                :animated="false"
              >
                <b-step-item
                  v-for="activity in sequence.activities"
                  :key="activity.id"
                  :label="activity.title"
                  icon="check"
                  :clickable="true"
                >
                  <div class="card_item">
                    <div class="title-activity">
                      <b>{{ activity.title }}</b>
                    </div>
                    <div v-html="activity.description">
                      {{ activity.description }}
                    </div>
                  </div>
                </b-step-item>
              </b-steps>
            </div>
          </div>
        </b-collapse>

        <b-collapse :open="false" aria-id="comments">
          <template #trigger="props">
            <div class="accordion" role="button">
              <a class="card-header-icon">
                <b-icon :icon="props.open ? 'menu-down' : 'menu-up'"> </b-icon>
              </a>
              <p class="card-header-title">Commentaires</p>
            </div>
          </template>

          <div class="content" v-html="sequence.comments"></div>
        </b-collapse>
      </div>
    </div>
  </div>
</template>

<script>

export default ({
  props: {
    sequence: {
      type: [Object, Array],
    },
  },
  data() {
    return {
      isOpen: null,
    };
  },
});

</script>

<style scoped>
.title-activity{
  font-size:1.4rem;
  margin-bottom:0.7rem;
}
.card_item{
  border-radius:1rem;
  height:100% !important;
  margin-top:1rem;
  margin-bottom:1rem;
}
.modal-card {
  padding: 3rem !important;
  border-radius: 0.5rem !important;
  overflow: auto;
  width: calc(100vw - 40px);
  max-width: 1200px;
}
.subtitle {
  color: #531be5;
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 0.2rem !important;
}
.card_grey {
  padding: 2rem;
  background-color: #ecedf175;
}
.content {
  padding: 1rem 1rem 3rem;
}
.nameSequence {
  font-size: 2rem;
  color: #531be5;
  font-weight: 700;
  text-align: center;
  margin-bottom: 1rem;
}
</style>
