<template>
  <div class="modalbox modalStep" id="waypointModal">
    <div
      v-if="isLoading"
      class="w-100"
      style="z-index: 30; top: 25vh; position: absolute"
    >
      <div style="max-width: 450px; z-index: 30" class="mx-auto">
        <div class="has-text-centered has-text-primary">
          <b
            >Cette opération va prendre un peu de temps, ne vous inquiétez pas
            nous compilons actuellement les données enregistrées</b
          >
        </div>
      </div>
    </div>
    <div class="is-flex bigmodal">
      <img
        class="btn-previous"
        :src="require('@/assets/img/arrows/previous.svg')"
        @click="goPreviousPage"
      />
    </div>

    <b-loading
      :is-full-page="false"
      v-model="isLoading"
      style="background: rgba(255, 255, 255, 0.8)"
    >
    </b-loading>
    <section class="w-100 container" v-if="booklet_validation.data">
      <div class="title-paragraph" v-if="booklet_validation.data">
        {{ booklet_validation.data[0].data[0].part_name }}
      </div>
      <div
        class="studentName"
        v-if="currentUser.role !== 'apprenant' && booklet_validation.data"
      >
        {{ this.booklet_validation.data[0].name }}
      </div>
      <div class="studentName" v-if="this.validation">
        {{ this.validation.name }}
      </div>

      <div class="justify-content-center is-flex align-items-center mb-5">
        du {{ waypoint.start_date | day }} au
        <span class="mr-2 ml-1"> {{ waypoint.end_date | day }}</span>
        <!-- <b-button>modifier</b-button> -->
      </div>

      <div v-if="this.validation">
        <div class="question mb-3" v-if="this.validation.helper">
          {{ this.validation.helper }}
        </div>
        <div v-else>
          Les crédits ECTS sont-ils accordés à cet apprenant pour ce semestre ?
        </div>
        <div class="has-text-centered">{{ this.validation.content }}</div>
      </div>
      <b-message
        type="is-info"
        class="mt-2 has-text-centered"
        v-if="booklet_validation.part_content"
      >
        <div v-html="booklet_validation.part_content"></div
      ></b-message>
      <div class="has-text-centered mt-2">
        <b-button
          @click="modifyTexts"
          v-if="currentUser.role == 'coordinateur' && !editHelpText"
          size="is-small"
          ><span v-if="booklet_validation.part_content">modifier</span
          ><span v-else
            >ajouter une information pour apprenant et tuteur</span
          ></b-button
        >
      </div>
      <div
        class="ck-editor-block mb-0 pb-0 mt-5"
        v-if="currentUser.role == 'coordinateur' && editHelpText"
      >
        <ckeditor
          v-model="booklet_validation.part_content"
          :editor="editor"
          :config="editorConfig"
          class="mb-5"
          :ref="'revisionContent'"
        ></ckeditor>
      </div>
      <div class="has-text-centered">
        <b-button
          @click="saveHelpTexts"
          v-if="currentUser.role == 'coordinateur' && editHelpText"
          size="is-small"
          >enregistrer</b-button
        >
      </div>
      <div class="columns is-mobile is-multiline mt-5" v-if="waypoint">
        <div class="column is-6-mobile">
          <div class="card card_item card_stat_header card_stat_v2">
            <div class="stat-cell pointer">
              <div class="stat_number">{{ waypoint.nb_experiences }}</div>
              <div class="stat_label">activités<br />enregistrées</div>
            </div>
          </div>
        </div>
        <div class="column is-6-mobile">
          <div class="card card_item card_stat_header card_stat_v2">
            <div class="stat-cell pointer">
              <div class="stat_number has-text-primary">
                {{ waypoint.nb_evals_by_skills }}
                / {{ waypoint.nb_all_skills }}
              </div>
              <div class="stat_label">compétences<br />évaluées</div>
            </div>
          </div>
        </div>
        <div class="column is-6-mobile">
          <div class="card card_item card_stat_header card_stat_v2">
            <div class="stat-cell pointer">
              <div class="stat_number">
                {{ waypoint.nb_evals_by_skills }}
              </div>
              <div class="stat_label">évaluations<br />par compétence</div>
            </div>
          </div>
        </div>
        <div class="column is-6-mobile">
          <div class="card card_item card_stat_header card_stat_v2">
            <div class="stat-cell pointer mb-3">
              <div class="stat_label">Evaluations<br />par niveau</div>
            </div>
            <b-progress
              :key="keycomponent"
              format="percent"
              :max="
                waypoint.nb_level_1 +
                waypoint.nb_level_2 +
                waypoint.nb_level_3 +
                waypoint.nb_level_4
              "
            >
              <template #bar>
                <b-progress-bar
                  :value="waypoint.nb_level_1"
                  type="is-danger"
                ></b-progress-bar>
                <b-progress-bar
                  :value="waypoint.nb_level_2"
                  type="is-warning"
                ></b-progress-bar>
                <b-progress-bar
                  :value="waypoint.nb_level_3"
                  type="is-success"
                ></b-progress-bar>
                <b-progress-bar
                  :value="waypoint.nb_level_4"
                  type="is-info"
                ></b-progress-bar>
              </template>
            </b-progress>
          </div>
        </div>
      </div>
      <div class="columns is-vcentered">
        <div class="column is-narrow subtitle-paragraph mb-0">
          Compétences évaluées par :
        </div>
        <div class="column">
          <b-select
            placeholder="Tous rôles confondus"
            expanded
            v-model="selectedRole"
            @input="changeRole"
            class="pl-0"
          >
            <option value="">Tuteur et apprenant</option>
            <option value="apprenant">Apprenant</option>
            <option value="tuteur">tuteur</option>
          </b-select>
        </div>
      </div>
      <div>
        <div class="listActivities">
          <div class="columns is-vcentered">
            <div class="column title2">
              Récapitulatif des expériences vécues en entreprise
            </div>
            <div class="column">
              <!-- eslint-disable max-len-->
              <div
                class="column is-narrow ml-auto caption is-flex align-items-center justify-content-center"
              >
                <div
                  class="caption-item"
                  v-for="(level, index) of orderPosition(skillsLevels)"
                  :key="level.id"
                >
                  <img
                    :src="require(`@/assets/img/levels/level${index + 1}.svg`)"
                    alt=""
                    class="img_status"
                  />
                  {{ level.name }}
                </div>
              </div>
            </div>
          </div>

          <div v-if="waypoint.reports">
            <div
              v-for="(experience, index) in order(waypoint.reports)"
              :key="experience.id"
              class="mb-3"
            >
              {{ experience.date_created }}
              <div class="card-header2" role="button">
                <div class="is-flex bg-primary align-items-center w-100">
                  <!-- {{ experience.sequence_id }}
                  report : {{ experience.id }} -->
                  <a
                    v-if="
                      experience.nb_skills_from_student > 0 ||
                      experience.nb_skills_from_tutor > 0
                    "
                    class="card-header-icon"
                    @click="collapseBlock('a' + index, 'icona' + index)"
                  >
                    <div class="icon-collapse" :id="'icona' + index"></div>
                  </a>
                  <a v-else class="card-header-icon" style="opacity: 0.5">
                    <div class="icon-collapse" :id="'icona' + index"></div>
                  </a>
                  <div
                    class="content-activity is-flex align-items-center w-100"
                  >
                    <span class="has-text-primary"
                      ><b> {{ experience.date_experience }}</b></span
                    >
                    <div class="w-100">
                      <div>
                        <span
                          class="tag is-outlined mb-2"
                          style="margin-left: -5px"
                          >{{ experience.type }}</span
                        >
                      </div>
                      <div>
                        <span class=""
                          ><b>
                            {{ experience.sequence_name }}
                            {{ experience.free_situation_title }}</b
                          >
                        </span>
                      </div>
                      <div>
                        <span v-if="experience.sequence_subtitle">
                          ({{ experience.sequence_subtitle }})</span
                        >
                      </div>
                      <div>
                        <span
                          class="has-text-primary"
                          v-if="experience.activity_name === 'La situation'"
                        >
                          Evaluation Apprenant</span
                        ><span v-else class="has-text-primary">
                          {{ experience.activity_name }}</span
                        >
                      </div>
                      <div
                        class="has-text-primary"
                        v-if="experience.activity_revision_id"
                      >
                        {{ experience.activity_revision_title }}
                      </div>
                    </div>
                    <div class="mr-3 ml-auto" style="min-width: 163px">
                      <span
                        class="number-primary"
                        v-if="experience.nb_skills_from_student"
                      >
                        {{ experience.nb_skills_from_student }}</span
                      ><span class="number-primary" v-else>
                        {{ experience.nb_skills_from_tutor }}</span
                      >
                      compétences
                    </div>
                    <b-button
                      outlined
                      rounded
                      type="is-primary"
                      class="ml-auto"
                      @click="
                        gotoActivity(
                          experience.activity_id,
                          experience.sequence_id,
                          experience.activity_revision_id,
                          experience.free_situation_id
                        )
                      "
                      >voir</b-button
                    >
                  </div>
                </div>
              </div>
              <div class="card-content cardClosed" :id="'a' + index">
                <div
                  class="column is-narrow ml-auto caption is-flex align-items-center justify-content-center bg-grey"
                >
                  <div
                    class="caption-item"
                    v-for="(level, index) of orderPosition(skillsLevels)"
                    :key="level.id"
                  >
                    <img
                      :src="
                        require(`@/assets/img/levels/level${index + 1}.svg`)
                      "
                      alt=""
                      class="img_status"
                    />
                    {{ level.name }}
                  </div>
                </div>
                <div v-if="experience.student_evaluations">
                  <div
                    v-for="skill in experience.student_evaluations"
                    :key="skill.id"
                    class="card_skill"
                  >
                    <div class="activity-item is-flex align-items-center w-100">
                      <div class="is-flex w-100 content-steps">
                        <img
                          :src="
                            require(`@/assets/img/levels/level${skill.level_position}.svg`)
                          "
                          alt=""
                          class="img_status mr-3"
                        />
                        <div class="titles" style="max-width: unset !important">
                          <div class="mr-3">
                            <b>{{ skill.skill_name }}</b>
                          </div>
                        </div>
                        <div
                          class="is-flex align-items-center card_buttons"
                          style="min-width: unset !important"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="experience.tutor_evaluations">
                  <div
                    v-for="skill in experience.tutor_evaluations"
                    :key="skill.id"
                    class="card_skill"
                  >
                    <div class="activity-item is-flex align-items-center w-100">
                      <div class="is-flex w-100 content-steps">
                        <img
                          :src="
                            require(`@/assets/img/levels/level${skill.level_position}.svg`)
                          "
                          alt=""
                          class="img_status mr-3"
                        />
                        <div class="titles" style="max-width: unset !important">
                          <div class="mr-3">
                            <b>{{ skill.skill_name }}</b>
                          </div>
                        </div>
                        <div
                          class="is-flex align-items-center card_buttons"
                          style="min-width: unset !important"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <b-collapse
              class="card"
              animation="slide"
              :open="isOpenActivities"
              v-for="experience in order(waypoint.reports)"
              :key="experience.id"
            >
              <div
                slot="trigger"
                slot-scope="props"
                class="card-header2"
                role="button"
              >
                <div class="is-flex bg-primary align-items-center">
                  <a
                    class="card-header-icon disabled"
                    aria-disabled="false"
                    disabled
                  >
                    <b-icon
                      v-bind:icon="props.open ? 'menu-down' : 'menu-up'"
                      type="is-primary"
                    >
                    </b-icon>
                  </a>
                  <span class="has-text-primary"
                    ><b> {{ experience.date_experience }}</b></span
                  >
                  <div class="w-100">
                    <div>
                      <span class=""
                        ><b> {{ experience.sequence_name }}</b> </span
                      ><span
                        class="has-text-primary"
                        v-if="experience.activity_name === 'La situation'"
                      >
                        Evaluation Apprenant</span
                      ><span v-else class="has-text-primary">
                        {{ experience.activity_name }}</span
                      >
                    </div>
                    <span v-if="experience.sequence_subtitle">
                      ({{ experience.sequence_subtitle }})</span
                    >
                  </div>
                  <div class="mr-3 ml-auto" style="min-width: 163px">
                    <span
                      class="number-primary"
                      v-if="experience.nb_skills_from_student"
                    >
                      {{ experience.nb_skills_from_student }}</span
                    ><span class="number-primary" v-else>
                      {{ experience.nb_skills_from_tutor }}</span
                    >
                    compétences
                  </div>
                  <b-button
                    outlined
                    rounded
                    type="is-primary"
                    class="ml-auto"
                    @click="
                      gotoActivity(
                        skill.student_activity,
                        skill.student_sequence
                      )
                    "
                    >voir</b-button
                  >
                </div>
              </div>
              <div class="card-content pt-2 mb-5">
                <div
                  class="
                    column
                    is-narrow
                    ml-auto
                    caption
                    is-flex
                    align-items-center
                    justify-content-center
                    bg-grey
                  "
                >
                  <div
                    class="caption-item"
                    v-for="(level, index) of skillsLevels"
                    :key="level.id"
                  >
                    <img
                      :src="
                        require(`@/assets/img/levels/level${index + 1}.svg`)
                      "
                      alt=""
                      class="img_status"
                    />
                    {{ level.name }}
                  </div>
                </div>
                <div v-if="experience.student_evaluations">
                  <div
                    v-for="skill in experience.student_evaluations"
                    :key="skill.id"
                    class="card_skill"
                  >
                    <div class="activity-item is-flex align-items-center w-100">
                      <div class="is-flex w-100 content-steps">
                        <img
                          :src="
                            require(`@/assets/img/levels/level${skill.level_position}.svg`)
                          "
                          alt=""
                          class="img_status mr-3"
                        />
                        <div class="titles" style="max-width: unset !important">
                          <div class="mr-3">
                            <b>{{ skill.skill_name }}</b>
                          </div>
                        </div>
                        <div
                          class="is-flex align-items-center card_buttons"
                          style="min-width: unset !important"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="experience.tutor_evaluations">
                  <div
                    v-for="skill in experience.tutor_evaluations"
                    :key="skill.id"
                    class="card_skill"
                  >
                    <div class="activity-item is-flex align-items-center w-100">
                      <div class="is-flex w-100 content-steps">
                        <img
                          :src="
                            require(`@/assets/img/levels/level${skill.level_position}.svg`)
                          "
                          alt=""
                          class="img_status mr-3"
                        />
                        <div class="titles" style="max-width: unset !important">
                          <div class="mr-3">
                            <b>{{ skill.skill_name }}</b>
                          </div>
                        </div>
                        <div
                          class="is-flex align-items-center card_buttons"
                          style="min-width: unset !important"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div></div
            ></b-collapse> -->
          </div>
          <!--- oold-->
          <div
            v-if="currentUser.role == 'tuteur' && !validation.status"
            class="mt-5 pt-5"
          >
            <div class="question">
              Selon vous, à l’issue du semestre, les compétences globales
              développées par l’élève sont-elles en accord avec le niveau
              attendu ?
            </div>
            <div class="has-text-centered mb-3">
              <b>Votre réponse conditionne l’attribution des crédits ECTS.</b>
            </div>
            <!-- <div class="has-text-centered mb-5">
              <b-radio
                v-model="validation_tuteur"
                name="name"
                native-value="oui"
              >
                oui
              </b-radio>
              <b-radio
                v-model="validation_tuteur"
                name="name"
                native-value="non"
              >
                non
              </b-radio>
            </div> -->
            <b-field
              label="Souhaitez-vous ajouter un commentaire ?"
              style="font-size: 1.2rem"
              id="textarea"
            >
              <b-input
                maxlength="200"
                type="textarea"
                v-model="formWaypoint.tutor_comment"
              >
              </b-input>
            </b-field>
          </div>
          <div v-else>
            <div v-if="waypoint.tutor_comment">
              <div class="title2">Commentaires du tuteur</div>
              {{ waypoint.tutor_comment }}
            </div>
          </div>
        </div>
      </div>
      <div class="cardValidation">
        <div class="mb-3">
          <b class="title-paragraph" style="font-size: 1.2rem"
            >Validations attendues</b
          >
        </div>
        <div
          v-if="booklet_validation.data"
          class="is-flex align-items-center justify-content-center"
        >
          <div class="b-steps is-small w-100">
            <nav class="steps is-rounded">
              <ul class="step-items">
                <li
                  class="step-item is-previous"
                  v-for="validation in booklet_validation.data[0].data[0]
                    .validations"
                  :key="validation.id"
                >
                  <a class="step-link">
                    <div class="step-marker">
                      <img
                        v-if="validation.status_label == 'Valide'"
                        :src="require(`@/assets/img/steps/status3.svg`)"
                        alt=""
                        class="img_status mx-auto"
                      />

                      <img
                        v-else-if="validation.status_label == 'A faire'"
                        :src="require(`@/assets/img/steps/status6.svg`)"
                        alt=""
                        class="img_status mx-auto"
                      />
                      <img
                        v-else-if="validation.status_label == 'En retard'"
                        :src="require(`@/assets/img/steps/status-late.svg`)"
                        alt=""
                        class="img_status mx-auto"
                      />
                      <img
                        v-else
                        :src="require(`@/assets/img/steps/status5.svg`)"
                        alt=""
                        class="img_status mx-auto"
                      />
                    </div>
                    <div class="step-details">
                      <span class="step-title"> {{ validation.name }}</span>
                    </div>
                    <div class="mt-2">
                      <b-tag
                        v-if="validation.status_label == 'Valide'"
                        type="is-success"
                      >
                        {{ validation.status_label }}</b-tag
                      >
                      <b-tag
                        v-else-if="validation.status_label == 'A faire'"
                        type="is-secondary is-outlined"
                      >
                        {{ validation.status_label }}</b-tag
                      >
                      <b-tag
                        v-else-if="validation.status_label == 'En retard'"
                        class="is-outlined"
                      >
                        {{ validation.status_label }}</b-tag
                      >
                      <b-tag v-else type="is-danger">
                        {{ validation.status_label }}</b-tag
                      >
                      <div
                        class="mt-2 datetime"
                        v-if="validation.validation_datetime"
                      >
                        {{ validation.validation_datetime | day }}
                      </div>
                      <span class="step-name">
                        {{ validation.validator_name }}</span
                      >
                    </div></a
                  >
                </li>
              </ul>
            </nav>

            <!---->
          </div>
        </div>
      </div>
    </section>
    <div>
      <div
        class="is-flex is-flex-wrap-wrap activityBtns align-items-center"
        style="z-index: 2000"
        v-if="this.validation"
      >
        <div class="w-100 is-flex">
          <div
            class="mx-auto"
            v-if="
              !this.validation.status_label ||
              this.validation.status_label === 'A faire' ||
              this.validation.status_label === 'En retard'
            "
          >
            <b-button
              @click="validateWaypoint(1)"
              class="btn-action w-auto"
              type="is-success is-rounded"
              rounded
              :disabled="validation_tuteur === false"
              >valider</b-button
            >
            <b-button
              v-if="currentUser.role !== 'apprenant'"
              @click="noValidate(2)"
              class="btn-action w-auto"
              type="is-danger is-rounded"
              rounded
              :disabled="validation_tuteur === true"
              >refuser</b-button
            >
            <b-button
              @click="validateWaypoint(3)"
              v-if="
                currentUser.role === 'coordinateur' ||
                currentUser.role === 'formateur'
              "
              class="btn-action w-auto"
              type="is-primary is-rounded"
              rounded
              outlined
              >annuler</b-button
            >
          </div>
          <div v-else class="mx-auto">
            <span
              v-if="this.validation.status_label === 'Valide'"
              class="has-text-success has-text-centered"
              ><b>Vous avez validé ce point d'étape</b>
            </span>
            <span
              v-else-if="this.validation.status_label === 'Non valide'"
              class="has-text-danger has-text-centered"
              ><b>Vous avez refusé de valider ce point d'étape</b>
            </span>
          </div>
        </div>
        <div class="help-btn">
          <AppHelpMessage
            v-if="waypoint.type === 0"
            title="Une fois validé vous ne pouvez plus modifier ce point d'étape"
            message="Vous pouvez voir si les autres personnes on validé ce point en bas de page"
            textBtn="Besoin d'aide ?"
          />
          <AppHelpMessage
            v-if="waypoint.type === 1"
            title="Rédigez et enregistrez votre point en centre de formation"
            message="La liste des modules remonte automatiquement.
             Si un module a été ajouté en cours de formation et n'ets pas listé ici,
              vous pouvez utiliser les champs commentaires pour préciser ce qui a été vu."
            textBtn="Besoin d'aide ?"
          />
        </div>
      </div>
    </div>

    <div class="container contentSequence pb-5 mb-5"></div>
  </div>
</template>

<script>
import CKEditor from '@ckeditor/ckeditor5-vue2';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import '@ckeditor/ckeditor5-build-classic/build/translations/fr';
import { mapState } from 'vuex';
import moment from 'moment';
import AppHelpMessage from '@/components/AppHelpMessage.vue';
import _ from 'lodash';

export default ({
  filters: {
    day(date) {
      return moment(date).format('D/MM/YYYY');
    },
  },
  components: {
    AppHelpMessage,
    ckeditor: CKEditor.component,
  },
  computed: {
    ...mapState('auth', ['currentUser']),
    waypoint() {
      return this.$store.state.waypoint;
    },
  },
  data() {
    return {
      editor: ClassicEditor,
      editorDisabled: true,
      editorConfig: {
        language: 'fr',
        toolbar: ['bold', 'italic', 'bulletedList', 'numberedList',
          '|', 'outdent', 'indent', '|', 'link', '|', 'undo',
          'redo'],
        alignment: {
          options: ['left', 'right'],
        },
        // heading: {
        //   options: [
        //     { model: 'paragraph', title: 'Styles de texte', class: 'ck-heading_paragraph' },
        //     {
        //       model: 'heading1',
        //       view: 'h1',
        //       title: 'Titre 1',
        //       class: 'ck-heading_heading1',
        //     },
        //     {
        //       model: 'heading2',
        //       view: 'h2',
        //       title: 'Titre 2',
        //       class: 'ck-heading_heading2',
        //     },
        //     {
        //       model: 'mark',
        //       view: 'mark',
        //       title: 'surligné',
        //       class: 'ck-mark',
        //     },
        //   ],
        // },
        table: {
          contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells'],
        },
      },
      validation_tuteur: null,
      role: 'directeur',
      isLoading: false,
      isFullPage: false,
      // waypoint: [],
      arrayReports: [],
      student_activities_report: [],
      student_revisions_report: [],
      free_situations_report: [],
      formWaypoint: {
        id: '',
        title: '',
        comment: '',
        tutor_comment: '',
        teacher_comment: '',
        start_date: '',
        end_date: '',
        reports: [],
        validated: false,
      },
      checkboxCustom: [],
      activities: [],
      isOpenActivities: false,
      isOpenRevision: false,
      isOpenFree: false,
      experiences: [
        {
          id: 1,
          date_experience: '23/02/2022',
          name: 'Suivi de l\'activité d\'une ligne de production',
          skills: [
            {
              id: 2,
              name: 'B1-1. Élaborer et mettre en œuvre les méthodes scientifiques appliquées au dimensionnement des systèmes mécaniques',
              level: 1,
            },
            {
              id: 3,
              name: 'B1-2. Mettre en œuvre et exploiter les méthodes et les outils de conception en particulier les logiciels de conception assistées par ordinateur et de calcul de structure',
              level: 1,
            },
            {
              id: 4,
              name: 'B1-4. Réaliser un choix raisonné des matières et des modes de fabrication à employer en fonction des caractéristiques et des comportements des matériaux ainsi que des normes environnementales afférentes, pour réaliser les produits conçus ',
              level: 4,
            },
            {
              id: 5,
              name: 'B1-7. Gérer des projets de conception en utilisant les outils logiciels d\'ingénierie collaborative et de gestion de cycle de vie de produits ',
              level: 3,
            },
            {
              id: 6,
              name: ' B1-3. Modéliser et simuler, à l\'aide d’outils numériques, le comportement des systèmes mécaniques ',
              level: 2,
            },

          ],
        },
        {
          id: 2,
          date_experience: '23/02/2022',
          name: 'Gestion de projet',
          skills: [
            {
              id: 2,
              name: 'B1-1. Élaborer et mettre en œuvre les méthodes scientifiques appliquées au dimensionnement des systèmes mécaniques',
              level: 1,
            },
            {
              id: 3,
              name: 'B1-2. Mettre en œuvre et exploiter les méthodes et les outils de conception en particulier les logiciels de conception assistées par ordinateur et de calcul de structure',
              level: 4,
            },
            {
              id: 4,
              name: 'B1-4. Réaliser un choix raisonné des matières et des modes de fabrication à employer en fonction des caractéristiques et des comportements des matériaux ainsi que des normes environnementales afférentes, pour réaliser les produits conçus ',
              level: 1,
            },
            {
              id: 5,
              name: 'B1-7. Gérer des projets de conception en utilisant les outils logiciels d\'ingénierie collaborative et de gestion de cycle de vie de produits ',
              level: 3,
            },
            {
              id: 6,
              name: ' B1-3. Modéliser et simuler, à l\'aide d’outils numériques, le comportement des systèmes mécaniques ',
              level: 2,
            },

          ],
        },
      ],
      booklet_validation: [],
      validation: [],
      skillsLevels: [],
      selectedRole: '',
      keycomponent: 0,
      editHelpText: false,
      isDirector: null,
    };
  },
  methods: {
    startLoading() {
      this.isLoading = true;
    },
    endLoading() {
      this.isLoading = false;
    },
    initDirector() {
      this.$http
        // .get(`/trainings/${this.$route.params.idTraining}/?format=json`)
        .get(`/trainings/${this.$route.params.idTraining}/?query={directors}&format=json`)
        .then((response) => {
          const director = response.data.directors;
          if (director.includes(this.currentUser.id)) {
            this.isDirector = true;
          }
        });
    },
    initPart() {
      this.$http
        .get(`/get_training_booklet_validations/${this.$route.params.idTraining}?step=${this.$route.params.simple_booklet_part_validation}&student_training=${this.$route.params.idStudentTraining}`)
        .then((response) => {
          this.booklet_validation = response.data;
          if (this.isDirector) {
            const indexValidation = _.findKey(this.booklet_validation.data[0].data[0].validations,
              ['role', 'directeur']);
            this.validation = this.booklet_validation.data[0].data[0].validations[indexValidation];
          } else {
            const indexValidation = _.findKey(this.booklet_validation.data[0].data[0].validations,
              ['role', this.currentUser.role]);
            this.validation = this.booklet_validation.data[0].data[0].validations[indexValidation];
          }
          const idWpt = this.booklet_validation.data[0].data[0].entry.student_training_waypoint;
          this.initWaypoint(idWpt);
        }).catch((error) => {
          window.console.log(error);
          this.$buefy.dialog.alert({
            title: 'Mince...',
            message: 'des données n\'ont pu être chargées',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
          });
        });
    },
    initWaypoint(id) {
      // this.startLoading();
      if (!this.waypoint || this.waypoint.id !== id) {
        this.startLoading();
        const urlWs = `/agenda/student_training_waypoints/${id}/?format=json`;
        this.$http
          .get(`${urlWs}`)
          .then((response) => {
            // this.waypoint = response.data;
            this.$store.commit('GET_WAYPOINT_ACTIVITIES', response.data);
            if (!this.waypoint.validated) {
              this.getActivities();
            } else { this.getWaypoint(this.waypoint.id); }
          });
      }
    },
    postValidation(statusValidation) {
      // window.console.log(this.validation_tuteur);
      this.$http
        .post('/validations/validation/', {
          role: this.currentUser.role,
          student_training: Number(this.$route.params.idStudentTraining),
          simple_booklet_part_validation: this.validation.id,
          status: statusValidation,
        }).then(() => {
          this.$buefy.toast.open({
            message: 'L\'étape a bien été validée',
            type: 'is-success',
          });
          this.validationWaypoint();
        }).catch((error) => {
          window.console.log(error);
          this.issue = true;
          this.$buefy.dialog.alert({
            title: 'Mince...',
            message: 'Votre validation n\'a pas pu être enregistrée',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
          });
        });
    },
    validateWaypoint(statusValidation) {
      this.$buefy.dialog.confirm({
        title: 'Souhaitez-vous bien valider ce semestre ?',
        message: 'Vous êtes sur le point de valider ce point et le semestre de votre apprenant•e. Vous confirmez que vous avez bien relu le point d\'étape et le validez.',
        confirmText: 'Valider',
        cancelText: 'Relire',
        type: 'is-success',
        hasIcon: true,
        onConfirm: () => {
          this.postValidation(statusValidation);
          this.goPreviousPage();
        },
      });
    },
    noValidate(statusValidation) {
      this.$buefy.dialog.confirm({
        title: 'Refusez-vous bien de valider ce semestre ?',
        message: 'Vous confirmez que votre apprenant•e n\'a pas le niveau attendu et que vous lui refusez l\'attribution des crédits ECTS pour ce semestre.',
        confirmText: 'Refuser',
        cancelText: 'Relire',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.postValidation(statusValidation);
          this.goPreviousPage();
        },
      });
    },
    validationWaypoint() {
      this.$http
        .patch(`/agenda/student_training_waypoints/${this.waypoint.id}/`, {
          validated: true,
          tutor_comment: this.formWaypoint.tutor_comment,
        }).then(() => {
          this.$buefy.toast.open({
            message: 'Point d\'étape a bien été validée',
            type: 'is-success',
          });
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
        });
    },
    goPreviousPage() {
      // if (this.$route.params.from) {
      //   this.$router.push({
      //     name: this.$route.params.from,
      //     params: {
      //       tabDashboard: 'validationStep',
      //       idTraining: this.$route.params.idTraining,
      //     },
      //   });
      // } else {
      //   this.$router.push({
      //     name: 'StudentTraining',
      //     params: {
      //       idStudent: this.$route.params.idStudent,
      //       idTraining: this.$route.params.idTraining,
      //       idStudentTraining: this.$route.params.idStudentTraining,
      //       tab: 'waypoint',
      //     },
      //   });
      // }
      this.$router.go(-1);
    },
    getWaypoint(idWaypoint) {
      // this.startLoading();
      this.$http
        .get(`/get_waypoint/${idWaypoint}?format=json`)
        .then((response) => {
          // this.waypoint = response.data;
          this.$store.commit('GET_WAYPOINT_ACTIVITIES', response.data);
          this.endLoading();
        }).catch((error) => {
          window.console.log(error);
          this.$buefy.dialog.alert({
            title: 'Mince...',
            message: 'des données n\'ont pu être chargées',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
          });
        });
    },
    changeRole() {
      // this.startLoading();
      let rolechoosen = '';
      if (this.selectedRole) {
        rolechoosen = `role=${this.selectedRole}&`;
      }
      this.startLoading();
      this.$http
        .get(`/get_waypoint/${this.waypoint.id}?${rolechoosen}format=json`)
        .then((response) => {
          // this.waypoint = response.data;
          this.$store.commit('GET_WAYPOINT_ACTIVITIES', response.data);
          this.keycomponent += 1;
          this.endLoading();
        }).catch((error) => {
          window.console.log(error);
          this.$buefy.dialog.alert({
            title: 'Mince...',
            message: 'des données n\'ont pu être chargées',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
          });
        });
    },
    order(array) {
      return _.orderBy(array, 'sequence_position');
    },
    orderPosition(array) {
      return _.orderBy(array, 'position');
    },
    initLevels() {
      this.$http
        .get(`/traininglevels/?training=${this.$route.params.idTraining}&format=json`)
        .then((response) => {
          this.skillsLevels = this.order(response.data);
        });
    },
    postWaypoint() {
      if (this.waypoint.type === 1) {
        for (let i = 0; i < this.schoolSubjectsSelected.length; i += 1) {
          if (this.schoolSubjectsSelected[i].checked === true) {
            this.arrayReports.push(
              {
                school_subject: this.schoolSubjectsSelected[i].school_subject,
                report: this.schoolSubjectsSelected[i].report,
              },
            );
          }
        }
      } else if (this.waypoint.type === 0) {
        for (let i = 0; i < this.student_activities_report.length; i += 1) {
          this.arrayReports.push(
            {
              autonomy: Number(this.student_activities_report[i].autonomy),
              report: this.student_activities_report[i].report,
              student_activity: this.student_activities_report[i].student_activity,
            },
          );
        }
        for (let i = 0; i < this.student_revisions_report.length; i += 1) {
          this.arrayReports.push(
            {
              autonomy: Number(this.student_revisions_report[i].autonomy),
              report: this.student_revisions_report[i].report,
              student_activity_revision: this.student_revisions_report[i].student_activity_revision,
            },
          );
        }
        for (let i = 0; i < this.free_situations_report.length; i += 1) {
          this.arrayReports.push(
            {
              autonomy: Number(this.free_situations_report[i].autonomy),
              report: this.free_situations_report[i].report,
              free_situation: this.free_situations_report[i].free_situation,
            },
          );
        }
      }
      this.$http
        .post('agenda/set_waypoint', {
          id: this.waypoint.id,
          type: this.waypoint.type,
          student_training: this.$route.params.idStudentTraining,
          start_date: this.formWaypoint.start_date,
          end_date: this.formWaypoint.end_date,
          role: this.currentUser.role,
          profile: Number(this.currentUser.id),
          goals: this.formWaypoint.goals,
          comment: this.formWaypoint.comment,
          tutor_comment: this.formWaypoint.tutor_comment,
          teacher_comment: this.formWaypoint.teacher_comment,
          reports: this.arrayReports,
          validated: false,
        }).then(() => {
          this.getWaypoint(this.waypoint.id);
          // this.$buefy.toast.open({ message: 'Point d\'étape enregistré', type: 'is-success' });
          // this.goPreviousPage();
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
        });
    },
    getActivities() {
      let endDate = new Date(this.waypoint.end_date);
      if (endDate < new Date()) {
        endDate = endDate.setDate(endDate.getDate() + 60);
      }
      const startDate = moment(new Date(this.waypoint.start_date)).format('YYYY-MM-DD');
      endDate = moment(new Date(endDate)).format('YYYY-MM-DD');
      const urlWs = `/agenda/get_waypoint_activities?student_training=${this.$route.params.idStudentTraining}&start_date=${startDate}&end_date=${endDate}&existing_report=false`;
      this.$http
        .get(`${urlWs}`)
        .then((response) => {
          this.activities = response.data;
          for (let i = 0; i < this.activities.student_activities.length; i += 1) {
            // const activitiesArray = this.activities.student_activities;
            this.student_activities_report.push(
              {
                autonomy: 0,
                report: null,
                student_activity: this.activities.student_activities[i].id,
                student_sequence: this.activities.student_activities[i].sequence,
                sequence_title: this.activities.student_activities[i].sequence_title,
                sequence_subtitle: this.activities.student_activities[i].sequence_subtitle,
                title: this.activities.student_activities[i].title,
              },
            );
          }
          for (let i = 0; i < this.activities.student_activity_revisions.length; i += 1) {
            const activitiesArray = this.activities.student_activity_revisions;
            this.student_revisions_report.push(
              {
                autonomy: 0,
                report: null,
                student_activity_revision: this.activities.student_activity_revisions[i].id,
                title: this.activities.student_activity_revisions[i].title,
                student_sequence_title: activitiesArray[i].student_sequence_title,
                student_sequence_subtitle: activitiesArray[i].student_sequence_subtitle,
              },
            );
          }
          for (let i = 0; i < this.activities.free_situations.length; i += 1) {
            this.free_situations_report.push(
              {
                autonomy: 0,
                report: null,
                free_situation: this.activities.free_situations[i].id,
                title: this.activities.free_situations[i].title,
              },
            );
          }
          if (this.activities.student_activities.length
            || this.activities.student_activity_revisions.length
            || this.activities.free_situations.length) {
            // this.postWaypoint();
            this.getWaypoint(this.waypoint.id);
          } else {
            this.getWaypoint(this.waypoint.id);
          }
          // this.getWaypoint(this.waypoint.id);
        });
    },
    collapseBlock(id, icon) {
      const element = document.getElementById(id);
      element.classList.toggle('blockOpen');
      const iconOpen = document.getElementById(icon);
      iconOpen.classList.toggle('iconOpen');
    },
    gotoActivity(activityId, sequenceId, revisionId, freeId) {
      if (activityId) {
        this.$router.push({
          name: 'StudentActivity',
          params: {
            idTraining: this.$route.params.idTraining,
            idStudentTraining: this.$route.params.idStudentTraining,
            idStudent: this.$route.params.idStudent,
            idActivity: activityId,
            idSequence: sequenceId,
            from: this.$route.name,
            simple_booklet_part_validation: this.$route.params.simple_booklet_part_validation,
          },
        });
      } else if (revisionId) {
        this.$router.push({
          name: 'StudentProgressionRead',
          params: {
            idTraining: this.$route.params.idTraining,
            idStudentTraining: this.$route.params.idStudentTraining,
            idStudent: this.$route.params.idStudent,
            idRevision: revisionId,
            idSequence: sequenceId,
            simple_booklet_part_validation: this.$route.params.simple_booklet_part_validation,
            from: this.$route.name,
          },
        });
      } else if (freeId) {
        this.$router.push({
          name: 'StudentFreeSituationRead',
          params: {
            idTraining: this.$route.params.idTraining,
            idStudentTraining: this.$route.params.idStudentTraining,
            idStudent: this.$route.params.idStudent,
            idFree: freeId,
            simple_booklet_part_validation: this.$route.params.simple_booklet_part_validation,
            from: this.$route.name,
          },
        });
      }
    },
    modifyTexts() {
      // window.console.log('modify');
      this.editHelpText = true;
    },
    saveHelpTexts() {
      this.startLoading();
      // window.console.log('save');
      // window.console.log(this.$route.params.simple_booklet_part_validation);
      this.$http
        .patch(`booklet_parts/${this.$route.params.simple_booklet_part_validation}/`, {
          content: this.booklet_validation.part_content,
        }).then(() => {
          this.$buefy.toast.open({ message: 'Fiche enregistrée', type: 'is-success' });
          this.editHelpText = false;
          this.endLoading();
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
          this.$buefy.dialog.alert({
            title: 'Mince...',
            message: 'L\'information n\'a pas pu être enregistrée.',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
          });
        });
    },
  },
  created() {
    this.initPart();
    this.initLevels();
    this.initDirector();
    // this.startLoading();
    // this.initWaypoint();
  },
});
</script>

<style lang="scss" scoped>
.card_buttons {
  margin-left: auto !important;
  min-width: 198px;
}
.titles {
  max-width: 460px;
  padding-right: 1rem;
}

.studentName {
  font-weight: bold;
  font-size: 1.4rem;
  text-align: center;
}
.question {
  font-size: 1.2rem;
  color: var(--primary);
  font-weight: bold;
  text-align: center;
  max-width: 700px;
  margin: auto;
}
.card-header2 {
  background: #ffffff;
  border: 1px solid #e3e5eb;
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.03);
  padding: 1rem 1rem 1rem 0.5rem;
  .date {
    color: var(--primary);
  }
  .card-header-title {
    font-size: 1rem;
  }
  .card-header-icon {
    width: 4.125rem;
  }
}
.card_skill {
  background: #ffffff;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.03);
  border-radius: 2px 2px 2px 2px 0;
  padding: 1rem;
  border: 1px solid #eff1f7;
}
.title2 {
  color: var(--primary);
}
.number-primary {
  padding: 0.3rem 0.6rem;
  border-radius: 30px;
  background-color: var(--primary);
  color: #fff;
  margin-right: 0.2rem;
}
.bg-grey {
  background-color: #f4f5f9;
  margin-bottom: 0.4rem;
}
#textarea {
  label {
    font-size: 1rem;
  }
}
.step_marker {
  background-color: #fff !important;
}
@media screen and (max-width: 768px) {
  .card_activity::after {
    content: unset;
    color: #c1bbd0cc;
  }
  .card_activity {
    border-bottom: 1px solid #ededed;
  }
  .card_activity:last-child {
    border-bottom: 0px solid #ededed;
    &::after {
      content: unset;
    }
  }
  .card-header2 .content-activity {
    flex-direction: column;
    & > div,
    & > span,
    & > button {
      margin-left: 0px !important;
      margin-right: auto !important;
    }
  }
}
@media screen and (max-width: 576px) {
  .content-steps {
    flex-direction: column;
  }
  .card_buttons {
    margin-left: 0px !important;
    padding-top: 0.5rem;
  }
  // .actions-icon,
  // .img_status {
  //   display: block;
  // }
  .titles {
    flex-direction: column;
  }
  .caption-item .img_status {
    display: block;
  }
}

.step-content {
  padding: 0 !important;
}
.bookletSteps img {
  width: 40px;
  height: 40px;
}
.img_status {
  margin: 0rem;
}
</style>
