<template>
  <div class="helpInfo" @click="infoSave">
    <b-icon icon="warning" class="pointer"></b-icon
    ><span class="text">{{ textBtn }}</span>
  </div>
</template>

<script>

export default ({
  props: {
    textBtn: String,
    title: String,
    message: String,
  },
  methods: {
    infoSave() {
      this.$buefy.dialog.alert({
        title: this.title,
        message: this.message,
        type: 'is-info',
        hasIcon: true,
        icon: 'times-circle',
        ariaRole: 'alertdialog',
        ariaModal: true,
      });
    },
  },
});
</script>
