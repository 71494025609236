<template>
  <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
    <body>
      <section class="hero is-success is-fullheight">
        <div class="hero-body">
          <div class="container has-text-centered">
            <div class="column is-4 is-offset-4">
              <p
                class="title has-text-white"
                style="font-family: 'Questrial', sans-serif"
              >
                Bienvenue dans inSitu, identifiez-vous pour commencer !
              </p>
              <b-notification
                v-if="errored === true"
                type="is-danger is-light"
                aria-close-label="Fermer la notification"
                role="alert"
              >
                <span style="font-size: 1.2rem"
                  >Oups, vos identifiants ne semblent pas fonctionner...
                  Veuillez vérifier vos informations de connexion ou
                  réinitialiser votre mot de passe.</span
                >
              </b-notification>
              <div class="box">
                <figure class="avatar">
                  <img src="../assets/img/logo/insitu_logo.png" width="100" />
                </figure>
                <form>
                  <ValidationProvider
                    rules="required"
                    vid="login"
                    name="Identifiant ou email"
                    v-slot="{ errors, valid }"
                  >
                    <b-field
                      :type="{ 'is-danger': errors[0], 'is-success': valid }"
                      :message="errors"
                    >
                      <b-input
                        value=""
                        v-model="form.username_or_email"
                        placeholder="Identifiant ou email"
                      ></b-input>
                    </b-field>
                  </ValidationProvider>
                  <ValidationProvider
                    rules="required"
                    vid="password"
                    name="Mot de passe"
                    v-slot="{ errors, valid }"
                  >
                    <b-field
                      :type="{ 'is-danger': errors[0], 'is-success': valid }"
                      :message="errors"
                    >
                      <b-input
                        placeholder="Votre mot de passe"
                        type="password"
                        v-model="form.password"
                        value=""
                        password-reveal
                      >
                      </b-input>
                    </b-field>
                  </ValidationProvider>
                  <button
                    class="button is-block is-info is-large is-fullwidth insitu"
                    @click.prevent="handleSubmit(loginUser)"
                  >
                    Se connecter
                    <i class="fa fa-sign-in" aria-hidden="true"></i>
                  </button>
                </form>
              </div>
              <p
                class="has-text-white"
                style="font-family: 'Questrial', sans-serif; font-size: 1.1rem"
              >
                <a @click.prevent="forgotLogin">Mot de passe oublié ?</a>
              </p>
            </div>
          </div>
        </div>
      </section>
    </body>
  </ValidationObserver>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { mapState, mapActions } from 'vuex';

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      errored: false,
      form: {
        username_or_email: null,
        password: null,
      },
    };
  },
  computed: {
    ...mapState('auth', ['currentUser']),
  },
  methods: {
    ...mapActions('auth', ['getUserdetails']),
    loginUser() {
      if (!this.validateForm()) {
        return;
      }

      this.$http
        .post('/api-token-auth/', {
          username_or_email: this.form.username_or_email,
          password: this.form.password,
        })
        .then((response) => {
          this.getCurrentUserDetails(response.data);
        })
        .catch((error) => {
          if (error.response.status === 401) {
            this.errorMessage = 'Incorrect login credentials';
          } else {
            this.errorMessage = 'An error occurred, please try again later';
          }
          this.errored = true;
        });
    },

    getCurrentUserDetails(token) {
      this.$http
        .get('/whoami/', {
          headers: {
            Authorization: `Bearer ${token.token}`, // Accessing token string from token object.
          },
        })
        .then((response) => {
          this.getUserdetails(response.data);
        })
        .then(() => {
          // Redirect the user to the initially requested page after successful login
          if (this.$route.query.redirect) {
            this.$router.push(this.$route.query.redirect);
          } else {
            this.$router.push({ name: 'Dashboard' });
          }
          this.form.password = null;
        })
        .catch((error) => {
          window.console.error(error);
        });
    },

    validateForm() {
      if (!this.form.username_or_email) {
        this.errorMessage = 'Veuillez entrer votre adresse e-mail ou votre nom d\'utilisateur';
        return false;
      }
      if (!this.form.password) {
        this.errorMessage = 'Veuillez entrer votre mot de passe';
        return false;
      }
      return true;
    },
    forgotLogin() {
      this.$router
        .push({
          name: 'ResetPassword',
        });
    },
  },
};
</script>

<style scoped>
html,
body {
  font-family: "Questrial", sans-serif;
  font-size: 14px;
  font-weight: 300;
}
.hero.is-success {
  background: #521be4;
}
.hero .nav,
.hero.is-success .nav {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.box {
  margin-top: 5rem;
}
.avatar {
  margin-top: -70px;
  padding-bottom: 20px;
}
.avatar img {
  padding: 5px;
  background: #fff;
  border-radius: 50%;
  -webkit-box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1),
    0 0 0 1px rgba(10, 10, 10, 0.1);
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
}
input {
  font-weight: 300;
}
p {
  font-weight: 700;
}
p.subtitle {
  padding-top: 1rem;
}

.login-hr {
  border-bottom: 1px solid black;
}

.has-text-black {
  color: black;
}

.field {
  padding-bottom: 10px;
}

.fa {
  margin-left: 5px;
}

.insitu {
  background-color: #521be4 !important;
}
</style>
