<template>
  <div class="is-flex">
    <AppSidebar />
    <b-loading :is-full-page="isFullPage" v-model="isLoading"> </b-loading>
    <div class="main-content">
      <div class="lineCrumbAndAccount ml-auto">
        <AppAccountMenu v-bind:appAccount="crumbs" />
      </div>
      <div class="header-page is-flex flex-column">
        <div class="justify-content-between columns lineHeaderTitle">
          <div class="column title-page short-title">
            {{ $route.meta.header_title }}
          </div>
        </div>
      </div>
      <div class="content-page pt-5">
        <!-- content here -->
        <!-- Policy -->
        <p>
          La présente Politique de protection des données personnelles expose la
          manière dont inSitu traite les données à caractère personnel des
          visiteurs et des utilisateurs (ci- après les « Utilisateurs ») lors de
          leur utilisation de la « Plateforme » : plateforme numérique de type
          site Web et/ou application mobile permettant l'accès au Service ainsi
          que son utilisation.&nbsp;
        </p>
        <br />
        <p>
          La Politique de protection des données personnelles fait partie
          intégrante des Conditions Générales d'Utilisation de la Plateforme.
        </p>
        <br />
        <p>
          inSitu accorde en permanence une attention aux données de ses
          Utilisateurs. Nous pouvons ainsi être amenés à modifier, compléter ou
          mettre à jour périodiquement la Politique de protection des données
          personnelles. Nous pourrons aussi apporter des modifications
          nécessaires afin de respecter les changements de la législation et
          réglementation en vigueur. Vous serez notifié et invité à accepter les
          éventuelles modifications.&nbsp;
        </p>
        <p>
          La dernière version en vigueur reste accessible en permanence sur la
          Plateforme.
        </p>
        <p><br /></p>
        <h1><strong>Données collectées</strong></h1>
        <br /><br />
        <p>
          Nous collectons des données personnelles que vous nous transmettez et
          des données que nous recueillons.
        </p>
        <p>
          Dans le cadre des traitements de données à caractère personnel, nous
          sommes amenés à collecter et à traiter les données suivantes :
        </p>
        <ul style="list-style-type: disc; margin-left: 1rem">
          <li>vos noms et prénoms ;</li>
          <li>vos coordonnées (adresse postale, numéro de téléphone) ;</li>
          <li>vos adresses courriels ;</li>
          <li>
            les coordonnées de votre représentant légal (si vous n’êtes pas
            majeur) ;
          </li>
          <li>
            les données relatives à vos contrats d’apprentissage, et/ou contrats
            professionnels et/ou conventions de stage, et votre entreprise ;
          </li>
          <li>votre avatar, genre, votre adresse IP ;</li>
          <li>une mini-biographie, ou une biographie à titre facultatif ;</li>
          <li>une photo peut également être utilisée sur la Plateforme ;</li>
          <li>
            les données relatives aux formations suivies, aux évaluations, aux
            livrets de suivi ou d’apprentissage, aux compétences évaluées ;
          </li>
          <li>
            votre profil yPareo, votre date de naissance et votre adresse
            courriel uniquement si vous vous inscrivez sur la Plateforme grâce à
            votre compte Microsoft Office ou yPareo conformément aux conditions
            générales d'utilisation de ces plateformes ;
          </li>
          <li>les données des échanges via la messagerie d’inSitu ;</li>
          <li>
            les documents nécessaires à la constitution de vos livrets. Ces
            informations justifient des prérequis pour bénéficier des livrets
            numériques ;
          </li>
          <li>
            les sessions de validation de vos expériences en situation de
            travail avec votre tuteur ainsi que les compétences validées pour
            l’obtention des crédits ECTS, aux fins de valider votre formation.
            Ces données à caractère personnel sont conservées dans votre dossier
            apprenant.
          </li>
        </ul>
        <br />
        <p>
          Nous collectons les informations que vous nous fournissez notamment
          quand :
        </p>
        <ul style="list-style-type: disc; margin-left: 1rem">
          <li>vous naviguez sur la Plateforme et applications</li>
          <li>vous créez, modifiez et accédez à votre compte personnel</li>
          <li>vous contactez notre Service Client`</li>
        </ul>
        <p><br /></p>
        <h1><strong>Finalités des traitements</strong></h1>
        <br /><br />
        <p>
          Certains traitements mis en œuvre par inSitu sont nécessaires à
          l’exécution d’un contrat auquel l’Utilisateur est partie ou à
          l’exécution des mesures précontractuelles prises à la demande de
          celui-ci. Il en est ainsi notamment des traitements qui poursuivent
          les finalités suivantes :
        </p>
        <ul style="list-style-type: disc; margin-left: 1rem">
          <li>Gestion des parcours de formation ;</li>
          <li>Gestion administrative des livrets numériques ;</li>
          <li>
            Gestion des relations entre inSitu et les Utilisateurs ayant un
            compte personnel, notamment:
            <ul style="list-style-type: disc; margin-left: 1rem">
              <li>La création d’un compte personnel ;</li>
              <li>
                L’utilisation de la Plateforme et des services qu’il propose ;
              </li>
              <li>
                La gestion des communications et du suivi des échanges avec les
                Utilisateurs.
              </li>
            </ul>
          </li>
        </ul>
        <br />
        <p>
          Certains traitements mis en œuvre par inSitu dès lors que les
          Utilisateurs concernés ont consenti au traitement de données
          personnelles :
        </p>
        <ul style="list-style-type: disc; margin-left: 1rem">
          <li>
            Gestion du consentement des Utilisateurs au dépôt des cookies soumis
            au consentement ;
          </li>
          <li>L’envoi de notifications par courriel ;</li>
        </ul>
        <p>
          Certains traitements mis en œuvre par inSitu sont nécessaires aux fins
          des intérêts légitimes d’inSitu :
        </p>
        <ul style="list-style-type: disc; margin-left: 1rem">
          <li>Gestion des cookies non soumis au consentement ;</li>
          <li>Gestion des demandes d’information et de réclamations ;</li>
          <li>Journalisation des connexions.</li>
        </ul>
        <p><br /></p>
        <h1><strong>Gestion des cookies </strong></h1>
        <br /><br />
        <p>
          Nous utilisons des cookies sur la Plateforme pour les besoins de votre
          navigation, l’optimisation et la personnalisation de nos services sur
          la Plateforme en mémorisant vos préférences.
        </p>
        <p>Vous pouvez à tout moment désactiver les cookies.</p>
        <p>
          <strong><em>Définition de « cookie » et son utilisation</em></strong
          >. Un « cookie » est un fichier texte déposé sur votre ordinateur lors
          de la visite de la Plateforme. Dans votre ordinateur, les cookies sont
          gérés par votre navigateur internet.
        </p>
        <p>
          Nous utilisons des cookies sur la Plateforme pour les besoins de votre
          navigation, l'optimisation et la personnalisation de nos services sur
          la Plateforme en mémorisant vos préférences. Les cookies nous
          permettent aussi de voir comment la Plateforme est utilisée. La
          Plateforme peut ainsi gérer votre identité et votre profil au gré des
          connexions établies entre le serveur et le navigateur web.&nbsp;
        </p>
        <p>
          Les informations fournies dans un formulaire peuvent ainsi être
          conservées.
        </p>
        <p><br /></p>
        <h1><strong>Cookies utilisés sur la Plateforme.</strong></h1>
        <br /><br />
        <p>Différents types de cookies sont utilisés sur la Plateforme :</p>
        <ul style="list-style-type: disc; margin-left: 1rem">
          <li>
            Des cookies qui sont strictement nécessaires au fonctionnement de la
            Plateforme (cookies obligatoires). Ils vous permettent d'utiliser
            les principales fonctionnalités de la Plateforme. Sans ces cookies,
            vous ne pourrez pas utiliser la Plateforme normalement.
          </li>
          <li>
            Des cookies fonctionnels, parmis lesquels des cookies dits
            "analytiques" : afin d'améliorer nos services, nous utilisons des
            cookies de mesures d'audience telles que le nombre de pages vues, le
            nombre de visites, l'activité des Utilisateurs et leur fréquence de
            retour, notamment grâce aux services de Google Analytics. Ces
            cookies permettent seulement l'établissement d'études statistiques
            sur le trafic des Utilisateurs sur la Plateforme, dont les résultats
            sont totalement anonymes pour nous permettre de connaître
            l'utilisation et les performances de la Plateforme et d'en améliorer
            le fonctionnement. Accepter ces cookies permet une utilisation
            optimale de la Plateforme, si vous les refusez, nous ne pouvons vous
            garantir une utilisation normale sur la Plateforme.<br />Cela inclut
            aussi des cookies qui nous permettent de personnaliser votre
            expérience sur la Plateforme en mémorisant vos préférences. Ces
            cookies peuvent être placés par une tierce partie pour notre compte,
            mais elle n'est pas autorisée à les utiliser à d'autres fins que
            celles décrites.
          </li>
        </ul>
        <h2><br /></h2>
        <h2>
          <strong><em>Types de cookies utilisés</em></strong
          >.
        </h2>
        <p>&nbsp;Les types de cookies suivants sont utilisés sur ce Site :</p>
        <ul style="list-style-type: disc; margin-left: 1rem">
          <li>
            Cookies "temporaires" : ce type de cookie est actif dans votre
            navigateur jusqu'à ce que vous quittiez la Plateforme et expire si
            vous n'accédez pas à la Plateforme pendant une certaine période
            donnée.
          </li>
          <li>
            Cookies "permanents" ou "traceurs" : ce type de cookie reste dans le
            fichier de cookies de votre navigateur pendant une période plus
            longue, qui dépend des paramètres de votre navigateur web. Les
            cookies permanents sont également appelés cookies traceurs.
          </li>
        </ul>
        <p><br /></p>
        <h2><strong>Gestion des cookies</strong></h2>
        <p>
          Vous pouvez à tout moment désactiver les cookies en sélectionnant les
          paramètres appropriés de votre navigateur pour désactiver les cookies
          (la rubrique d'aide du navigateur utilisé précise la marche à suivre).
          Si vous désactivez les cookies, un cookie de refus sera déposé sur
          votre ordinateur afin qu’inSitu enregistre l’information selon
          laquelle vous vous êtes opposé à l’utilisation de cookies. De la même
          manière, lorsque vous acceptez le recours à des cookies, un cookie de
          consentement est installé sur votre ordinateur.
        </p>
        <p>
          Nous attirons votre attention sur le fait que la désactivation des
          cookies peut réduire ou empêcher l'accessibilité à tout ou partie de
          certaines fonctions.
        </p>
        <p><br /></p>
        <h2><strong>Paramétrage du navigateur</strong></h2>
        <p>
          Vous pouvez configurer votre logiciel de navigation de manière à ce
          que des cookies soient enregistrés dans votre terminal ou qu’ils
          soient rejetés, soit systématiquement, soit selon leur émetteur.
        </p>
        <p>
          Vous pouvez également régulièrement supprimer les cookies de votre
          terminal via votre navigateur.
        </p>
        <p>
          N’oubliez pas cependant de paramétrer l’ensemble des navigateurs de
          vos différents terminaux.
        </p>
        <p>
          Pour la gestion des cookies et de vos choix, la configuration de
          chaque navigateur est différente. Elle est décrite dans le menu d’aide
          de votre navigateur, qui vous permettra de savoir de quelle manière
          modifier vos choix en matière de cookies. A titre d’exemples :
        </p>
        <ul style="list-style-type: disc; margin-left: 1rem">
          <li>
            pour Internet Explorer™ :
            <a
              href="https://support.microsoft.com/fr-fr/microsoft-edge/supprimer-les-cookies-dans-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09"
              >https://support.microsoft.com/fr-fr/microsoft-edge/supprimer-les-cookies-dans-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09</a
            >
            ;
          </li>
          <li>
            pour Safari™ :
            <a href="https://support.apple.com/fr-fr/guide/safari/sfri11471/mac"
              >https://support.apple.com/fr-fr/guide/safari/sfri11471/mac</a
            >
            ;
          </li>
          <li>
            pour Chrome™:
            <a
              href="https://support.google.com/chrome/answer/95647?hl=fr&amp;hlrm=en"
              >https://support.google.com/chrome/answer/95647?hl=fr&amp;hlrm=en</a
            >
            ;
          </li>
          <li>
            pour Firefox™ :
            <a
              href="https://support.mozilla.org/fr/kb/protection-renforcee-contre-pistage-firefox-ordinateur?redirectlocale=fr&amp;redirectslug=Activer+et+d%2525C3%2525A9sactiver+les+cookies"
              >https://support.mozilla.org/fr/kb/protection-renforcee-contre-pistage-firefox-ordinateur?redirectlocale=fr&amp;redirectslug=Activer+et+d%C3%A9sactiver+les+cookies</a
            >
            ;
          </li>
          <li>
            pour Opera™ :
            <a
              href="https://help.opera.com/en/latest/web-preferences/%2523cookies"
              >https://help.opera.com/en/latest/web-preferences/#cookies</a
            >.
          </li>
        </ul>
        <p>
          Pour en savoir plus sur la manière de paramétrer vos logiciels de
          navigation, nous vous invitons à consulter la Plateforme de la
          Commission nationale de l’informatique et des libertés :
          <a href="https://www.cnil.fr/fr/cookies-les-outils-pour-les-maitriser"
            >https://www.cnil.fr/fr/cookies-les-outils-pour-les-maitriser</a
          >
        </p>
        <h2><strong>Accès à vos données</strong></h2>
        <p>
          Seules les personnes habilitées, comme les responsables de traitement
          et les responsables techniques &nbsp;ont accès à vos données.
        </p>
        <h2>
          <strong>Vos données sont-elles partagées avec des tiers ? </strong>
        </h2>
        <p>
          Nous encadrons juridiquement les transferts par des engagements
          contractuels avec nos partenaires pour vous apporter un niveau élevé
          de sécurité.
        </p>
        <p>
          inSitu ne vend ni ne loue vos données personnelles à des tiers à des
          fins de marketing, en aucun cas.
        </p>
        <p>
          Nous travaillons également en étroite collaboration avec des
          entreprises tierces qui peuvent avoir accès à vos données
          personnelles, en particulier :
        </p>
        <ul style="list-style-type: disc; margin-left: 1rem">
          <li>
            Lorsqu’il s’agit de votre centre de formation ou de votre entreprise
            ;
          </li>
          <li>
            Lorsque vous en faites la demande expresse (par exemple lorsque vous
            utilisez les méthodes d'authentification fournies par des
            plateformes tierces vous permettant d'intégrer dans votre profil
            inSitu des informations issues de votre compte sur leur propre Site)
            ;
          </li>
          <li>
            Lorsque nous avons l'obligation légale de le faire ou si nous
            pensons de bonne foi que cela est nécessaire pour (i) répondre à
            toute réclamation à l'encontre d'inSitu, (ii) se conformer à toute
            demande judiciaire, (iii) faire exécuter tout contrat conclu avec
            nos membres, tel que les Conditions Générales d'Utilisation et la
            présente Politique de protection des données personnelles (iv) en
            cas d'urgence mettant en jeu la santé publique ou l'intégrité
            physique d'une personne, (v) dans le cadre d'enquêtes et
            d'investigations ou (vi) afin de garantir les droits, les biens et
            la sécurité d'inSitu, ses membres et plus généralement tout tiers ;
          </li>
          <li>
            En outre, inSitu ne divulgue pas vos données personnelles à des
            tiers, excepté si (1) vous (ou votre administrateur de compte
            agissant en votre nom) en formulez la demande ou autorisez la
            divulgation ; (2) la divulgation est requise pour traiter des
            transactions ou fournir des services que vous avez demandés ; (3)
            inSitu y est contrainte par une autorité gouvernementale ou un
            organisme de réglementation, en cas de réquisition judiciaire, de
            citation à comparaître ou de toute autre exigence gouvernementale ou
            judiciaire similaire, ou pour établir ou défendre une demande légale
            ; ou (4) le tiers agit en tant qu'agent ou de sous-traitant d'inSitu
            dans l'exécution des services (par exemple, inSitu utilise les
            services d'une société de télécommunications).
          </li>
        </ul>
        <p>Nous conservons vos données dans l'Union Européenne.</p>
        <p><br /></p>
        <h1><strong>Protection des données</strong></h1>
        <br /><br />
        <p>
          Nous prenons toutes les dispositions nécessaires pour que vos données
          soient protégées. Nous vous demandons également de veiller à la
          confidentialité des données.
        </p>
        <p>
          inSitu applique les mesures de sécurité technologiques et
          organisationnelles généralement reconnues afin que les données à
          caractère personnel recueillies ne soient, ni perdues, ni détournées,
          ni consultées, ni modifiées ni divulguées par des tiers non autorisés
          sauf si la communication de ces données est imposée par la
          réglementation en vigueur, notamment à la requête d'une autorité
          judiciaire, de police, de gendarmerie ou de toute autre autorité
          habilitée par la loi.
        </p>
        <p><br /></p>
        <p>
          La sécurité des données personnelles dépend également des
          Utilisateurs. Les Utilisateurs qui sont membres d'inSitu s'engagent à
          conserver la confidentialité de leur identifiant et de leur mot de
          passe. Les membres s'engagent également à ne pas partager leur compte
          et à déclarer à inSitu toute utilisation non autorisée dudit compte
          dès lors qu'ils en ont connaissance.
        </p>
        <p><br /></p>
        <h1>
          <strong
            >Quelle est la durée de conservation des données personnelles des
            Utilisateurs d'inSitu ?</strong
          >
        </h1>
        <br /><br />
        <p>
          Vos données sont conservées pour une durée limitée afin de vous
          assurer une sécurité optimale.
        </p>
        <p>
          Les données à caractère personnel communiquées par les Utilisateurs
          d'inSitu seront supprimées à l'issue d'une certaine période et selon
          les données traitées.
        </p>
        <p>
          Afin de permettre aux membres de récupérer leur compte en cas d'oubli
          de mot de passe après une période prolongée sans connexion à la
          Plateforme, votre e-mail est conservé par sécurité. Il est néanmoins
          possible de le supprimer en modifiant les informations de son profil
          sur la section « Mon compte » de la Plateforme.
        </p>
        <p>
          Nous conservons vos données uniquement le temps nécessaire pour les
          finalités poursuivies, conformément aux prescriptions légales, et
          notamment :
        </p>
        <p><br /></p>
        <table cellpadding="0" cellspacing="0">
          <tbody>
            <tr>
              <td valign="middle">
                <p><strong>Finalité</strong></p>
              </td>
              <td valign="middle">
                <p><strong>Durée de conservation</strong></p>
              </td>
            </tr>
            <tr>
              <td valign="middle">
                <p>Gestion des parcours de formation</p>
              </td>
              <td valign="middle">
                <p>
                  2 ans à compter de la clôture des formations auxquelles vous
                  avez participé&nbsp;
                </p>
              </td>
            </tr>
            <tr>
              <td valign="middle">
                <p>
                  Gestion de la facturation et analyse des sessions suivies par
                  les utilisateurs dans le cadre de la facturation
                </p>
              </td>
              <td valign="middle">
                <p>10 ans à compter de la clôture de l’exercice en cours</p>
              </td>
            </tr>
            <tr>
              <td valign="middle">
                <p>Gestion administrative des livrets</p>
              </td>
              <td valign="middle">
                <p>5 ans après le départ de l’apprenant</p>
              </td>
            </tr>
            <tr>
              <td valign="middle">
                <p>
                  Gestion des relations entre inSitu et les Utilisateurs ayant
                  un compte personnel
                </p>
              </td>
              <td valign="middle">
                <p>
                  Durée de la relation contractuelle 2 ans à compter de la
                  suspension dans l’hypothèse où le compte de l’Utilisateur a
                  été suspendu ou bloqué afin d’éviter tout contournement des
                  règles en vigueur sur la Plateforme
                </p>
              </td>
            </tr>
            <tr>
              <td valign="middle">
                <p>
                  Gestion des validations des compétences et évaluations&nbsp;
                </p>
              </td>
              <td valign="middle">
                <p>3 ans</p>
              </td>
            </tr>
            <tr>
              <td valign="middle">
                <p>Gestion des demandes d’exercice de droits</p>
              </td>
              <td valign="middle">
                <p>5 ans</p>
              </td>
            </tr>
            <tr>
              <td valign="middle">
                <p>
                  Prospection commerciale (promotion des formations et des
                  parcours, messages personnalisés) et réalisation d’enquêtes
                  (notamment enquêtes de motivation et de satisfaction)
                </p>
              </td>
              <td valign="middle">
                <p>
                  3 ans à compter du dernier contact ou jusqu’au retrait du
                  consentement
                </p>
              </td>
            </tr>
            <tr>
              <td valign="middle">
                <p>Gestion des cookies soumis au consentement</p>
              </td>
              <td valign="middle">
                <p>13 mois ou jusqu’au retrait du consentement</p>
              </td>
            </tr>
            <tr>
              <td valign="middle">
                <p>Gestion des cookies non soumis au consentement</p>
              </td>
              <td valign="middle">
                <p>13 mois</p>
              </td>
            </tr>
            <tr>
              <td valign="middle">
                <p>Gestion des demandes d’informations et de réclamations</p>
              </td>
              <td valign="middle">
                <p>Durée de la relation contractuelle</p>
              </td>
            </tr>
            <tr>
              <td valign="middle">
                <p>
                  Réalisation de statistiques et analyse des retours sur les
                  formations de la part des Utilisateurs
                </p>
              </td>
              <td valign="middle">
                <p>2 ans après la dernière activité du compte</p>
              </td>
            </tr>
            <tr>
              <td valign="middle">
                <p>Journalisation des connexions sur la Plateforme</p>
              </td>
              <td valign="middle">
                <p>1 an</p>
              </td>
            </tr>
            <tr>
              <td valign="middle">
                <p>Données des espaces de discussion</p>
              </td>
              <td valign="middle">
                <p>12 mois</p>
              </td>
            </tr>
          </tbody>
        </table>
        <p><br /></p>
        <h2>
          <strong
            >Que se passe-t-il en cas d'utilisation des services inSitu en
            relation avec des plateformes tierces (Mooc, LMS, etc.)</strong
          >
        </h2>
        <p>
          Nous ne sommes pas responsables de l'utilisation des données faite par
          les plateformes tierces. Vous pouvez les paramétrer sur leurs sites.
        </p>
        <p>
          Lorsque vous utilisez des plateformes tierces et des services ou
          applications d'inSitu en relation avec des plateformes tierces, cela
          est susceptible d'entraîner une collecte et un échange de certaines
          données entre les plateformes tierces et inSitu.
        </p>
        <p>
          Nous ne sommes pas responsables de l'utilisation qui est faite de vos
          données par les plateformes tierces pour leur propre compte. Vous avez
          la possibilité de paramétrer et contrôler directement sur les
          plateformes tierces l'accès et la confidentialité de vos données.
        </p>
        <p><br /></p>
        <h1><strong>Quels sont vos droits ?</strong></h1>
        <br /><br />
        <p>
          En application de la réglementation européenne en vigueur, nous avons
          mis en place des procédures et mécanismes vous permettant d’exercer
          vos droits.
        </p>
        <p>
          Conformément à la réglementation en vigueur les Utilisateurs de la
          Plateforme disposent des droits suivants :
        </p>
        <ul style="list-style-type: disc; margin-left: 1rem">
          <li>
            Le droit d’accéder aux données qu’inSitu détient à leur sujet ;
          </li>
          <li>
            Le droit d'obtenir d’inSitu, la rectification des données à
            caractère personnel les concernant qui sont inexactes ou incomplètes
            ;
          </li>
          <li>
            Le droit d'obtenir d’inSitu l'effacement des données à caractère
            personnel les concernant :
            <ul style="list-style-type: disc; margin-left: 1rem">
              <li>
                lorsqu'elles ne sont plus nécessaires au regard des finalités
                pour lesquelles elles ont été collectées ou traitées ;
              </li>
              <li>
                lorsque l’Utilisateur concerné retire son consentement pour les
                traitements ayant pour base légale le consentement ou s’oppose
                au traitement.
              </li>
            </ul>
          </li>
          <li>
            Le droit d'obtenir d’inSitu la limitation du traitement en cas de
            contestation de l’exactitude des données à caractère personnel.
            Lorsque inSitu n'a plus besoin des données à caractère personnel aux
            fins du traitement mais que celles-ci sont encore nécessaires à
            l’Utilisateur concerné pour la constatation, l'exercice ou la
            défense de ses droits en justice ;
          </li>
          <li>
            Le droit à la portabilité des données que les Utilisateurs auront
            fournies, lorsque ces données font l'objet de traitements
            automatisés fondés sur leur consentement ou un contrat ;
          </li>
          <li>
            Le droit de s’opposer, à tout moment, pour des raisons tenant à la
            situation particulière des Utilisateurs, aux traitement des données
            personnelles dont la base juridique du traitement est l’intérêt
            légitime ;
          </li>
          <li>
            Le droit de définir des directives particulières relatives à la
            conservation, à l’effacement et à la communication des données des
            Utilisateurs après leurs décès auprès des services d’inSitu selon
            les modalités ci-après définies. Ces directives particulières ne
            concernent que les traitements mis en œuvre par inSitu et sont
            limitées à ce seul périmètre.
          </li>
        </ul>
        <p>
          Si vous souhaitez exercer vos droits, vous pouvez envoyer un courriel
          à l'adresse rgpd@inSituapp.io ou adresser un courrier à l'adresse
          suivante : Seity - Données personnelles - 66 boulevard Notre Dame
          13006 Marseille France.
        </p>
        <p>
          Enfin les Utilisateurs d'inSitu peuvent déposer une réclamation auprès
          des autorités de contrôle, et notamment de la
          <a href="https://www.cnil.fr/fr/plaintes">CNIL</a>)
        </p>
        <p>
          Vos requêtes seront traitées sous un mois. En cas de doute raisonnable
          quant à votre identité nous pouvons demander que nous soient fournies
          des informations supplémentaires nécessaires pour confirmer votre
          identité.
        </p>
        <p><br /></p>
        <h1>
          <strong
            >Comment nous contacter – coordonnées du responsable des
            traitements</strong
          >
        </h1>
        <br /><br />
        <p>
          Si vous avez des questions ou des réclamations ou si vous souhaitez
          faire part à inSitu de recommandations ou des commentaires visant à
          améliorer notre Politique de protection des données personnelles vous
          pouvez envoyer un courriel à l’adresse rgpd@inSituapp.io ou adresser
          un courrier à l'adresse suivante : inSitu - Données personnelles - 66
          boulevard Notre Dame 13006 Marseille France.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import AppAccountMenu from '@/components/AppAccountMenu.vue';
import AppSidebar from '@/components/AppSidebar.vue';

export default ({
  components: {
    AppAccountMenu,
    AppSidebar,
  },
  data() {
    return {
      isLoading: false,
      isFullPage: false,
      isOpen: 0,
      crumbs: [{
        text: 'Conditions d\'utilisation',
        href: '/conditions-utilisation',
        class: 'is-active',
      },
      ],
    };
  },
  computed: {
    ...mapState('auth', ['currentUser']),
  },
});
</script>

<style scoped>
.content {
  margin-bottom: 2rem;
}
</style>
