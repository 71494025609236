<template>
  <div>
    <b-loading :is-full-page="isFullPage" v-model="isLoading"> </b-loading>
    <b-message type="is-warning">
      Pour chaque bloc de compétence, quels apprenants ont vu chaque compétence
      au moins une fois ?
    </b-message>
    <div class="columns is-desktop is-vcentered">
      <div class="column has-text-primary is-narrow"><b>Filtrer par</b></div>
      <div class="column is-narrow">
        <div class="columns is-vcentered">
          <div class="column is-narrow">
            <b-select
              placeholder="Tous rôles confondus"
              expanded
              v-model="selectedMode"
              class="pl-0"
              @input="changeRole"
            >
              <option value="best">Meilleure évaluation</option>
              <option value="last">Dernière évaluation</option>
            </b-select>
          </div>
        </div>
      </div>
      <div class="column is-narrow">
        <div class="columns is-vcentered is-desktop">
          <div class="column is-narrow">
            <b-collapse :open="false" aria-id="checkecRoles">
              <template #trigger="props">
                <b-button
                  label="Évalué par"
                  type="is-primary is-light"
                  aria-controls="checkecRoles"
                  :aria-expanded="props.open"
                />
              </template>

              <div class="card card-checked">
                <div>
                  <b-checkbox
                    v-model="selectedRole"
                    native-value="apprenant"
                    @input="changeRole"
                  >
                    Apprenant
                  </b-checkbox>
                </div>
                <div>
                  <b-checkbox
                    v-model="selectedRole"
                    native-value="tuteur"
                    @input="changeRole"
                  >
                    Tuteur
                  </b-checkbox>
                </div>
                <div>
                  <b-checkbox
                    v-model="selectedRole"
                    native-value="tuteur,apprenant"
                    @input="changeRole"
                  >
                    Tuteur, apprenant
                  </b-checkbox>
                </div>
                <div>
                  <b-checkbox
                    v-model="selectedRole"
                    native-value="formateur"
                    @input="changeRole"
                  >
                    Formateur
                  </b-checkbox>
                </div>
                <div>
                  <b-checkbox
                    v-model="selectedRole"
                    native-value="coordinateur"
                    @input="changeRole"
                  >
                    Coordinateur
                  </b-checkbox>
                </div>
              </div>
            </b-collapse>
          </div>
        </div>
      </div>
      <div class="column is-narrow">
        <div class="columns is-vcentered">
          <b-collapse :open="false" aria-id="checkecRoles">
            <template #trigger="props">
              <b-button
                label="Type d'évaluation"
                type="is-primary is-light"
                aria-controls="checkecRoles"
                :aria-expanded="props.open"
              />
            </template>

            <div class="card card-checked">
              <div>
                <b-radio
                  v-model="selectedType"
                  native-value="all"
                  @input="changeRole"
                >
                  Tout
                </b-radio>
              </div>
              <div>
                <b-radio
                  v-model="selectedType"
                  native-value="entreprise"
                  @input="changeRole"
                >
                  En entreprise
                </b-radio>
              </div>
              <div>
                <b-radio
                  v-model="selectedType"
                  native-value="tp"
                  @input="changeRole"
                >
                  Activité pratique
                </b-radio>
              </div>
              <!-- <div>
                <b-radio
                  v-model="selectedType"
                  native-value="certification"
                  @input="changeRole"
                >
                  Évaluation bilan
                </b-radio>
              </div> -->
            </div>
          </b-collapse>
        </div>
      </div>
      <div class="column ml-auto is-narrow">
        <b-collapse :open="false" aria-id="contentIdForA11y1">
          <template #trigger="props">
            <b-button
              label="Légende"
              icon-left="warning"
              type="is-ghost"
              aria-controls="contentIdForA11y1"
              :aria-expanded="props.open"
            />
          </template>
          <div class="card card-checked ml-auto" style="right: 2rem">
            <div class="caption is-flex align-items-center">
              <div class="caption-item">
                <img
                  :src="require(`@/assets/img/levels/Oval.svg`)"
                  alt=""
                  class="img_status"
                />
                Tuteur
              </div>
              <div class="caption-item">
                <img
                  :src="require(`@/assets/img/levels/Rectangle.svg`)"
                  alt=""
                  class="img_status"
                />
                Formateur
              </div>
              <div class="caption-item">
                <img
                  :src="require(`@/assets/img/levels/Triangle.svg`)"
                  alt=""
                  class="img_status"
                />
                Apprenant
              </div>
            </div>
          </div>
        </b-collapse>
      </div>
    </div>
    <div class="mb-3">
      <b-button
        rounded
        @click="toggle()"
        size="is-small"
        class="mr-2"
        v-if="!isOpen"
        >ouvrir tout
      </b-button>
      <b-button rounded @click="toggle()" size="is-small" class="mr-2" v-else
        ><span>fermer tout</span></b-button
      >
    </div>

    <b-table
      :data="studentsSkills.skills_blocks"
      ref="table"
      detailed
      hoverable
      custom-detail-row
      :default-sort="['name', 'asc']"
      detail-key="name"
      @details-open="(row, index) => $buefy.toast.open(`${row.name}`)"
      :show-detail-icon="showDetailIcon"
      :sticky-header="true"
      class="skillstable skillsstudent"
    >
      <b-table-column
        field="code"
        label="Code"
        v-slot="props"
        :sticky="true"
        width="40"
        class="th_ref"
      >
        <template v-if="showDetailIcon">
          <b>{{ props.row.reference }}</b>
        </template>
        <template v-else>
          <a @click="toggle(props.row)">
            {{ props.row.reference }}
          </a>
        </template>
      </b-table-column>
      <b-table-column field="name" label="Nom" width="300" v-slot="props">
        <b> {{ props.row.name }}</b>
      </b-table-column>
      <template v-for="(column, index) in studentsSkills.students">
        <b-table-column
          v-slot="props"
          :key="column.id + 'column' + index"
          v-bind="column"
          :visible="true"
          :label="column.name"
        >
          <template>
            <b-tooltip
              type="is-light"
              class="evaluation-icon"
              multilined
              position="is-bottom"
              v-if="selectedRole.includes(props.row.students[index].role)"
            >
              <template v-slot:content>
                <!-- <b> {{ column.name }}</b> -->
                <div>
                  <b>{{ props.row.students[index].role }}</b>
                </div>
                <div>{{ props.row.students[index].profile_name }}</div>
              </template>
              <span v-if="props.row.students[index].training_level">
                <svg
                  v-if="
                    props.row.students[index].role === 'tuteur' ||
                    props.row.students[index].role === 'tuteur,apprenant'
                  "
                  width="45px"
                  height="45px"
                  viewBox="0 0 45 45"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                  <title>Oval</title>
                  <g
                    id="Page-1"
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd"
                  >
                    <g
                      id="Oval"
                      fill="#67EB7B"
                      :class="`level${props.row.students[index].training_level}`"
                    >
                      <circle cx="22.5" cy="22.5" r="22.5"></circle>
                    </g>
                  </g>
                </svg>
                <svg
                  v-if="
                    props.row.students[index].role === 'formateur' ||
                    props.row.students[index].role === 'coordinateur'
                  "
                  width="41px"
                  height="41px"
                  viewBox="0 0 41 41"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                  <title>Rectangle</title>
                  <g
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd"
                  >
                    <g
                      id="Rectangle"
                      fill="#67EB7B"
                      :class="`level${props.row.students[index].training_level}`"
                    >
                      <rect x="0" y="0" width="41" height="41"></rect>
                    </g>
                  </g>
                </svg>
                <svg
                  v-if="props.row.students[index].role === 'apprenant'"
                  width="45.5555556px"
                  height="41px"
                  viewBox="0 0 45.5555556 41"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                  <title>Triangle</title>
                  <g
                    id="Page-1"
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd"
                  >
                    <g
                      id="Triangle"
                      fill="#67EB7B"
                      :class="`level${props.row.students[index].training_level}`"
                    >
                      <polygon
                        transform="translate(22.7778, 20.5) scale(-1, 1) translate(-22.7778, -20.5)"
                        points="22.7777778 0 45.5555556 41 0 41"
                      ></polygon>
                    </g>
                  </g></svg
              ></span>
              <!-- eslint-disable max-len-->
              <!-- <img
                v-if="props.row.students[index].training_level"
                :src="
                  require(`@/assets/img/levels/level${props.row.students[index].training_level}.svg`)
                "
                alt=""
                class="img_status" /> -->
              <img
                v-else
                :src="require(`@/assets/img/levels/levelnull.svg`)"
                alt=""
                class="img_status"
            /></b-tooltip>
          </template>
        </b-table-column>
      </template>
      <template slot="detail" slot-scope="props">
        <tr
          v-for="(item, index) in props.row.skills"
          :key="item.name + index + item.id"
        >
          <td v-if="showDetailIcon"></td>
          <td class="is-sticky td_ref">{{ item.reference }}</td>
          <td>{{ item.name }}</td>
          <td v-for="student in item.students" :key="student.name">
            <b-tooltip
              type="is-light"
              class="evaluation-icon"
              multilined
              position="is-bottom"
              v-if="selectedRole.includes(student.role)"
            >
              <template v-slot:content>
                <!-- <b> {{ column.name }}</b> -->
                <div>
                  <b>{{ student.role }}</b>
                </div>
                <div>{{ student.profile_name }}</div>
              </template>
              <span v-if="student.training_level">
                <svg
                  v-if="
                    student.role === 'tuteur' ||
                    student.role === 'tuteur,apprenant'
                  "
                  width="45px"
                  height="45px"
                  viewBox="0 0 45 45"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                  <title>Oval</title>
                  <g
                    id="Page-1"
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd"
                  >
                    <g
                      id="Oval"
                      fill="#67EB7B"
                      :class="`level${student.training_level}`"
                    >
                      <circle cx="22.5" cy="22.5" r="22.5"></circle>
                    </g>
                  </g>
                </svg>
                <svg
                  v-if="
                    student.role === 'formateur' ||
                    student.role === 'coordinateur'
                  "
                  width="41px"
                  height="41px"
                  viewBox="0 0 41 41"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                  <title>Rectangle</title>
                  <g
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd"
                  >
                    <g
                      id="Rectangle"
                      fill="#67EB7B"
                      :class="`level${student.training_level}`"
                    >
                      <rect x="0" y="0" width="41" height="41"></rect>
                    </g>
                  </g>
                </svg>
                <svg
                  v-if="student.role === 'apprenant'"
                  width="45.5555556px"
                  height="41px"
                  viewBox="0 0 45.5555556 41"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                  <title>Triangle</title>
                  <g
                    id="Page-1"
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd"
                  >
                    <g
                      id="Triangle"
                      fill="#67EB7B"
                      :class="`level${student.training_level}`"
                    >
                      <polygon
                        transform="translate(22.7778, 20.5) scale(-1, 1) translate(-22.7778, -20.5)"
                        points="22.7777778 0 45.5555556 41 0 41"
                      ></polygon>
                    </g>
                  </g></svg
              ></span>
              <!-- <img
                v-if="student.training_level"
                :src="
                  require(`@/assets/img/levels/level${student.training_level}.svg`)
                "
                alt=""
                class="img_status"
              /> -->
              <img
                v-else
                :src="require(`@/assets/img/levels/levelnull.svg`)"
                alt=""
                class="img_status"
              />
            </b-tooltip>
          </td>
        </tr>
      </template>
    </b-table>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import _ from 'lodash';
import moment from 'moment';

export default {
  props: {
    skill: {
      type: [Object, Array],
    }, // skill to display
    dates: {
      type: Boolean,
      // boolean to display dates or not
    },
  },
  components: {
  },
  filters: {
    day(date) {
      return moment(date).format('DD/MM');
    },
    year(date) {
      return moment(date).format('YYYY');
    },
  },
  data() {
    return {
      isLoading: false,
      isFullPage: false,
      students: [
        {
          id: 1,
          name: 'Kimberley Rougement',
        },
        {
          id: 2,
          name: 'Martin Dupont',
        },
        {
          id: 3,
          name: 'Kimberley Rougement',
        },
        {
          id: 4,
          name: 'Martin Dupont',
        },
        {
          id: 5,
          name: 'Kimberley Rougement',
        },
        {
          id: 6,
          name: 'Martin Dupont',
        },
        {
          id: 7,
          name: 'Kimberley Rougement',
        },
        {
          id: 8,
          name: 'Martin Dupont',
        },
        {
          id: 9,
          name: 'Kimberley Rougement',
        },
        {
          id: 10,
          name: 'Martin Dupont',
        },
        {
          id: 11,
          name: 'Kimberley Rougement',
        },
        {
          id: 12,
          name: 'Martin Dupont',
        },
        {
          id: 13,
          name: 'Kimberley Rougement',
        },
        {
          id: 14,
          name: 'Martin Dupont',
        },
        {
          id: 15,
          name: 'Kimberley Rougement',
        },
        {
          id: 16,
          name: 'Martin Dupont',
        },
      ],
      blockskills: [
        {
          id: 569,
          name: 'C1 Rechercher une information dans une documentation technique, en local ou à distance',
          reference: 'C1',
          description: '',
          position: 1,
          students: [
            {
              id: 11,
              name: 'Kimberley',
              student_training: 81,
              training_level: 4,
              training_level_label: 'niveau 2',
            },
            {
              id: 13,
              name: 'Dupont',
              student_training: 81,
              training_level: 3,
              training_level_label: 'niveau 1',
            },
            {
              id: 11,
              name: 'Kimberley',
              student_training: 81,
              training_level: 4,
              training_level_label: 'niveau 2',
            },
            {
              id: 13,
              name: 'Dupont',
              student_training: 81,
              training_level: 1,
              training_level_label: 'niveau 1',
            },
            {
              id: 11,
              name: 'Kimberley',
              student_training: 81,
              training_level: 4,
              training_level_label: 'niveau 2',
            },
            {
              id: 13,
              name: 'Dupont',
              student_training: 81,
              training_level: null,
              training_level_label: 'niveau 1',
            },
            {
              id: 11,
              name: 'Kimberley',
              student_training: 81,
              training_level: 2,
              training_level_label: 'niveau 2',
            },
            {
              id: 13,
              name: 'Dupont',
              student_training: 81,
              training_level: 3,
              training_level_label: 'niveau 1',
            },
            {
              id: 11,
              name: 'Kimberley',
              student_training: 81,
              training_level: 1,
              training_level_label: 'niveau 2',
            },
            {
              id: 13,
              name: 'Dupont',
              student_training: 81,
              training_level: 3,
              training_level_label: 'niveau 1',
            },
            {
              id: 13,
              name: 'Dupont',
              student_training: 81,
              training_level: 3,
              training_level_label: 'niveau 1',
            },
            {
              id: 11,
              name: 'Kimberley',
              student_training: 81,
              training_level: 2,
              training_level_label: 'niveau 2',
            },
            {
              id: 13,
              name: 'Dupont',
              student_training: 81,
              training_level: 3,
              training_level_label: 'niveau 1',
            },
            {
              id: 11,
              name: 'Kimberley',
              student_training: 81,
              training_level: 1,
              training_level_label: 'niveau 2',
            },
            {
              id: 13,
              name: 'Dupont',
              student_training: 81,
              training_level: 3,
              training_level_label: 'niveau 1',
            },
            {
              id: 13,
              name: 'Dupont',
              student_training: 81,
              training_level: null,
              training_level_label: 'niveau 1',
            },
          ],
          skills: [
            {
              id: 2592,
              name: 'C1.1 Mettre en œuvre une démarche de recherche d’information.',
              reference: 'C1.1',
              description: '- La fiabilité des informations est vérifiée.\n- Linformation recherchée est correctement réordonnée.\n- La démarche pour l’obtention de l’information est pertinente',
              position: 1,
              students: [
                {
                  id: 11,
                  name: 'Kimberley',
                  student_training: 81,
                  training_level: 2,
                  training_level_label: 'niveau 2',
                },
                {
                  id: 13,
                  name: 'Dupont',
                  student_training: 81,
                  training_level: 3,
                  training_level_label: 'niveau 1',
                },
                {
                  id: 13,
                  name: 'Dupont',
                  student_training: 81,
                  training_level: 1,
                  training_level_label: 'niveau 1',
                },
                {
                  id: 11,
                  name: 'Kimberley',
                  student_training: 81,
                  training_level: 4,
                  training_level_label: 'niveau 2',
                },
                {
                  id: 13,
                  name: 'Dupont',
                  student_training: 81,
                  training_level: null,
                  training_level_label: 'niveau 1',
                },
                {
                  id: 11,
                  name: 'Kimberley',
                  student_training: 81,
                  training_level: 4,
                  training_level_label: 'niveau 2',
                },
                {
                  id: 11,
                  name: 'Kimberley',
                  student_training: 81,
                  training_level: 2,
                  training_level_label: 'niveau 2',
                },
                {
                  id: 13,
                  name: 'Dupont',
                  student_training: 81,
                  training_level: 3,
                  training_level_label: 'niveau 1',
                },
                {
                  id: 11,
                  name: 'Kimberley',
                  student_training: 81,
                  training_level: 4,
                  training_level_label: 'niveau 2',
                },
                {
                  id: 13,
                  name: 'Dupont',
                  student_training: 81,
                  training_level: 3,
                  training_level_label: 'niveau 1',
                },
                {
                  id: 13,
                  name: 'Dupont',
                  student_training: 81,
                  training_level: 3,
                  training_level_label: 'niveau 1',
                },
                {
                  id: 11,
                  name: 'Kimberley',
                  student_training: 81,
                  training_level: 2,
                  training_level_label: 'niveau 2',
                },
                {
                  id: 13,
                  name: 'Dupont',
                  student_training: 81,
                  training_level: 3,
                  training_level_label: 'niveau 1',
                },
                {
                  id: 11,
                  name: 'Kimberley',
                  student_training: 81,
                  training_level: 1,
                  training_level_label: 'niveau 2',
                },
                {
                  id: 13,
                  name: 'Dupont',
                  student_training: 81,
                  training_level: 3,
                  training_level_label: 'niveau 1',
                },
                {
                  id: 13,
                  name: 'Dupont',
                  student_training: 81,
                  training_level: null,
                  training_level_label: 'niveau 1',
                },
              ],
            },
          ],
        },
        {
          id: 570,
          name: 'C2 Rechercher une information dans une documentation technique, en local ou à distance',
          reference: 'C2',
          description: '',
          position: 1,
          students: [
            {
              id: 11,
              name: 'Kimberley',
              student_training: 81,
              training_level: 2,
              training_level_label: 'niveau 2',
            },
            {
              id: 13,
              name: 'Dupont',
              student_training: 81,
              training_level: 1,
              training_level_label: 'niveau 1',
            },
          ],
          skills: [
            {
              id: 2592,
              name: 'C2.1 Mettre en œuvre une démarche de recherche d’information.',
              reference: 'C2.1',
              description: '- La fiabilité des informations est vérifiée.\n- Linformation recherchée est correctement réordonnée.\n- La démarche pour l’obtention de l’information est pertinente',
              position: 1,
              students: [
                {
                  id: 11,
                  name: 'Kimberley',
                  student_training: 81,
                  training_level: 3,
                  training_level_label: 'niveau 2',
                },
                {
                  id: 13,
                  name: 'Dupont',
                  student_training: 81,
                  training_level: 4,
                  training_level_label: 'niveau 1',
                },
              ],
            },
          ],
        },
      ],
      showDetailIcon: true,
      studentsSkills: [],
      isOpen: false,
      selectedRole: ['tuteur,apprenant', 'formateur', 'tuteur', 'coordinateur'],
      selectedType: 'all',
      selectedMode: 'best',
    };
  },
  computed: {
    ...mapState('auth', ['currentUser']),
  },
  methods: {
    startLoading() {
      this.isLoading = true;
    },
    endLoading() {
      this.isLoading = false;
    },
    order(array) {
      return _.orderBy(array, 'position');
    },
    initStudents() {
      this.startLoading();
      if (this.selectedRole.lenght === 0) {
        this.selectedRole = ['apprenant'];
      }
      let mode = '';
      if (this.selectedMode === 'last') {
        mode = '&order=last';
      }
      let roles = '';
      let context = '';
      for (let i = 0; i < this.selectedRole.length; i += 1) {
        roles = `${roles}&role=${this.selectedRole[i]}`;
      }
      if (this.selectedType !== 'all') {
        context = `&context=${this.selectedType}`;
      }
      this.$http
        .get(`agenda/get_students_skills/?training=${this.$route.params.idTraining}${roles}${mode}${context}&format=json`)
        .then((response) => {
          this.studentsSkills = response.data;
          this.endLoading();
        });
    },
    toggle() {
      for (let i = 0; i < this.studentsSkills.skills_blocks.length; i += 1) {
        this.$refs.table.toggleDetails(this.studentsSkills.skills_blocks[i]);
      }
      this.isOpen = !this.isOpen;
    },
    changeRole() {
      this.initStudents();
    },
  },
  created() {
    this.initStudents();
  },
  mounted() {
  },
};
</script>
<style scoped>
svg {
  width: 20px;
}
.level1 {
  fill: var(--danger);
}
.level2 {
  fill: var(--yellow);
}
.level3 {
  fill: var(--success);
}
.level4 {
  fill: #00c0fb;
}
.datesText {
  font-size: 12px;
  text-align: center;
  line-height: 1.1;
}
.evaluation-icon {
  width: 36px;
  text-align: center;
}
.evaluation {
  margin-right: 0.5rem;
}
.centre {
  background-color: lightsteelblue;
}
.skillstable .table-wrapper.has-sticky-header {
  height: 1500px !important;
}
</style>
