<template>
  <div class="modalbox">
    <!-- eslint-disable max-len-->
    <div class="is-flex bigmodal">
      <a @click="goPreviousPage()" class="ml-auto">
        <b-icon icon="close" type="is-primary"></b-icon>
      </a>
    </div>
    <div class="
        container
        title-modal
        is-flex
        justify-content-between
        is-max-widescreen is-align-items-center
      ">
      <div v-if="studentsequence.student" class="bloc-left-title">
        <div class="mb-2">
          <div v-if="currentUser.role === 'apprenant'">
            <router-link :to="'/formation-apprenant/' +
        $route.params.idTraining +
        '/' +
        studentsequence.student.id +
        '/' +
        $route.params.idStudentTraining
        ">
              <b-button type="is-text" class="">Voir mon parcours pédagogique</b-button>
            </router-link>
          </div>
          <div v-else>
            <router-link :to="'/formation-apprenant/' +
        $route.params.idTraining +
        '/' +
        studentsequence.student.id +
        '/' +
        $route.params.idStudentTraining
        ">
              <span class="button is-text pl-0">Parcours de l'apprenant</span> </router-link><span
              v-if="studentsequence.student">: {{ studentsequence.student.user.first_name }}
              {{ studentsequence.student.user.last_name }}</span>
          </div>
        </div>
        <span class="tag status1 mr-2" v-if="studentsequence.legacy_sequence"><span
            v-if="studentsequence.legacy_sequence.sequence_type_label">{{
        studentsequence.legacy_sequence.sequence_type_label
      }}</span>
        </span>
        <span v-if="studentsequence.status === 3" class="tag status3">
          Effectuée
        </span>
        <span v-if="studentsequence.status === 4" class="tag status3">
          Validée
        </span>
        <span v-if="studentsequence.status === 0" class="tag status0">
          À programmer
        </span>
        <span v-if="studentsequence.status === 1" class="tag status1">
          Programmée
        </span>
        <span v-if="studentsequence.status === 5" class="tag status5">
          Désactivée
        </span>
        <div class="title-page long-title mb-1">
          <span v-if="studentsequence.legacy_sequence">
            {{ studentsequence.legacy_sequence.name }}
          </span>
        </div>

        <!-- <span class="tag status1">{{ studentsequence.status }} </span> -->
      </div>
      <div class="bloc-buttons">

        <div class="mb-1">
          <b-button type="is-success is-rounded" v-if="(finished === true &&
        currentUser.role === 'coordinateur' &&
        studentsequence.legacy_sequence.sequence_type === 1) ||
        (finished === true &&
          currentUser.role === 'formateur' &&
          studentsequence.legacy_sequence.sequence_type === 1) ||
        (finished === true &&
          currentUser.role === 'superviseur' &&
          studentsequence.legacy_sequence.sequence_type === 1) ||
        (finished === true &&
          currentUser.role === 'coordinateur' &&
          studentsequence.legacy_sequence.sequence_type === 7) ||
        (finished === true &&
          currentUser.role === 'formateur' &&
          studentsequence.legacy_sequence.sequence_type === 7) ||
        (finished === true &&
          currentUser.role === 'superviseur' &&
          studentsequence.legacy_sequence.sequence_type === 7)
        " @click="patchStatusSequence(4)">valider l'expérience</b-button>
          <b-button type="is-danger is-rounded" v-if="(finished === true &&
        currentUser.role === 'coordinateur' &&
        studentsequence.legacy_sequence.sequence_type === 1) ||
        (finished === true &&
          currentUser.role === 'formateur' &&
          studentsequence.legacy_sequence.sequence_type === 1) ||
        (finished === true &&
          currentUser.role === 'superviseur' &&
          studentsequence.legacy_sequence.sequence_type === 1) ||
        (finished === true &&
          currentUser.role === 'coordinateur' &&
          studentsequence.legacy_sequence.sequence_type === 7) ||
        (finished === true &&
          currentUser.role === 'formateur' &&
          studentsequence.legacy_sequence.sequence_type === 7) ||
        (finished === true &&
          currentUser.role === 'superviseur' &&
          studentsequence.legacy_sequence.sequence_type === 7)
        " @click="duplicateSequence()">Reprogrammer</b-button>
        </div>
        <b-button icon-left="resource" type="is-outlined is-primary is-rounded" class="mr-5 ml-auto has-text-centered"
          @click="gotoResources">Ressources & documents</b-button>
      </div>
    </div>
    <section v-if="studentsequence.legacy_sequence" id="header-tabs" class="mt-auto pr-0 pl-0 tab-activity"
      :class="'typesequence' + studentsequence.legacy_sequence.sequence_type">
      <!-- :class="'typesequence' + studentsequence.legacy_sequence.sequence_type" -->
      <div class="b-tabs container is-max-widescreen">
        <nav class="tabs">
          <ul>
            <li :class="currentTab == 1 ? 'is-active' : ''">
              <a @click="gotoStep(1)"><span>L'expérience</span></a>
            </li>
            <li v-for="(
                activity, indexActivity
              ) in trainingsequence.training_activities" :key="indexActivity" :class="currentTab ==
        studentsequence.student_activities[indexActivity].id
        ? 'is-active'
        : ''
        ">
              <a @click="
        gotoActivity(
          studentsequence.student_activities[indexActivity].id,
          indexActivity,
          0
        )
        " v-if="activity.rights.includes('r_' + currentUser.role) ||
        activity.rights.includes('w_' + currentUser.role) ||
        currentUser.role === 'manager' ||
        currentUser.role === 'coordinateur' ||
        currentUser.role === 'formateur' ||
        currentUser.role === 'superviseur' ||
        (currentUser.role === 'tuteur' &&
          activity.rights.includes('w_tuteur') &&
          studentsequence.tutor) ||
        (currentUser.role === 'tuteur' &&
          activity.rights.includes('r_tuteur') &&
          studentsequence.tutor)
        ">
                <b-icon icon="calendar" type="is-primary"></b-icon>
                <span v-if="activity.legacy_activity">{{
        activity.legacy_activity.name
      }}</span>
                <span v-else>{{ activity.name_override }} </span>
              </a>
            </li>

          </ul>
        </nav>
      </div>
    </section>
    <b-loading :is-full-page="isFullPage" v-model="isLoading"> </b-loading>

    <div class="container contentSequence pb-5 mb-5" v-if="step === 1">
      <div>

        <!-- fiche seuqence read only -->
        <div v-if="editSequence === false">
          <div class="bloc-paragraph card_item mt-5">
            <div class="title-paragraph" v-if="studentsequence.subtitle" v-html="studentsequence.subtitle">
            </div>
            <div v-if="studentsequence.comment" class="subtitle-paragraph" v-html="studentsequence.comment">
            </div>
            <div class="mb-1 mt-4">
              <span class="subtitle-paragraph">Temps estimé : </span>
              <span v-if="studentsequence.estimated_duration">{{ studentsequence.estimated_duration }}}
              </span>
              <span v-else>non précisé</span>
            </div>

            <div v-if="studentsequence.legacy_sequence">
              <div class="is-flex align-items-center">
                <span class="subtitle-paragraph mb-0 mr-1">Tuteur : </span>
                <span v-if="tutorName"> {{ tutorName }} </span><span v-else>non renseigné</span>
              </div>
              <!-- Todo Add teachers name -->
              <!-- <div v-if="trainingsequence.teachers">
                <span class="subtitle-paragraph mb-0 mr-1"
                  >Formateur<span v-if="trainingsequence.teachers.length > 1"
                    >s</span
                  >
                  :
                </span>
                <span
                  v-if="trainingsequence.teachers.length > 0"
                  class="addCommas"
                >
                  <span
                    v-for="teacher in trainingsequence.teachers"
                    :key="teacher.id"
                  >
                    <span v-if="getUser(teacher)">
                      {{ getUser(teacher).user.first_name }}
                      {{ getUser(teacher).user.last_name }}</span
                    ></span
                  >
                </span>
                <span v-else>aucun formateur renseigné</span>
              </div> -->
              <div v-if="!tutorName">
                <b-message class="mt-3" type="is-danger" v-if="studentsequence.legacy_sequence.sequence_type === 1 ||
        studentsequence.legacy_sequence.sequence_type === 2 ||
        studentsequence.legacy_sequence.sequence_type === 7 ||
        studentsequence.legacy_sequence.sequence_type === 10
        ">
                  <b-icon icon="warning" type="is-danger"></b-icon>
                  Attention un tuteur est obligatoire pour ce type d'expérience
                </b-message>
              </div>
            </div>
            <div class="card_event mb-5 mt-5">
              <div class="title-paragraph">Dates estimées</div>
              <div v-if="studentsequence.start_date">
                <span v-if="studentsequence.end_date">entre le</span>
                {{ studentsequence.start_date | day }}
                <span v-if="studentsequence.end_date">
                  et le {{ studentsequence.end_date | day }}</span>
              </div>
              <div v-else>aucune date renseignée</div>
              <div v-if="currentUser.role === 'coordinateur' ||
        currentUser.role === 'superviseur'
        " class="mb-0">
                <div>
                  <b-button v-if="editSequence === false" class="mb-2 mt-4" @click="editSequence = !editSequence"
                    type="is-primary is-rounded mr-3" rounded>modifier</b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- patch to teacher -->
        <div v-if="editSequence === true">
          <div class="helpUser contentActivity" v-if="currentUser.role === 'coordinateur' ||
        currentUser.role === 'superviseur'
        ">
            <div class="title">
              Précisez la période estimée pour cette expérience
            </div>
            <div>
              Attention, il ne s'agit pas des dates définitives. Pour programmer
              à proprement parlé une activité, vous devez spécifier la date dans
              l'onglet de cette activité, et vous assurer que chaque utilisateur
              a bien confirmé la date.
            </div>
          </div>
          <div class="card_item">
            <div class="columns">
              <div class="column is-narrow">
                <b-field label="Date de début">
                  <b-datepicker v-model="studentsequence.start_date" :show-week-number="showWeekNumber" :locale="locale"
                    icon="calendar-today" trap-focus :first-day-of-week="1">
                  </b-datepicker>
                </b-field>
              </div>
              <div class="column is-narrow">
                <b-field label="Date de fin">
                  <b-datepicker v-model="studentsequence.end_date" :show-week-number="showWeekNumber" :locale="locale"
                    icon="calendar-today" trap-focus :first-day-of-week="1">
                  </b-datepicker>
                </b-field>
              </div>
            </div>
            <div class="title-paragraph">
              Infos personnalisées pour votre apprenant
            </div>
            <span class="subtitle-paragraph">Vous pouvez ajouter un sous-titre personnalisé pour cet apprenant
            </span>
            <b-input v-model="studentsequence.subtitle" class="mb-5 mt-3"></b-input>
            <span class="subtitle-paragraph">Vous pouvez ajouter un commentaire personnalisé pour cet
              apprenant
            </span>
            <div>
              <b-input type="textarea" v-model="studentsequence.comment" class="mb-5 mt-3"></b-input>
            </div>
            <div>
              <b-button v-if="editSequence === true" @click="editSequence = !editSequence"
                type="is-primary is-rounded mr-3 is-outlined" rounded>annuler</b-button>
              <b-button @click="patchSequence()" type="is-primary is-rounded mr-3" rounded>enregistrer</b-button>
            </div>
          </div>
        </div>
        <!-- info sequence not editable -->
        <div class="mb-5">
          <div class="bloc-paragraph" v-if="studentsequence.legacy_sequence">
            <div class="title-paragraph">
              Présentation, principaux thèmes abordés
            </div>
            <span class="ck" v-if="studentsequence.legacy_sequence.goals"
              v-html="studentsequence.legacy_sequence.goals">
            </span>
            <span v-else>pas de contenu</span>
          </div>
          <div class="bloc-paragraph" v-if="studentsequence.legacy_sequence">
            <div class="title-paragraph">Commentaires</div>
            <span class="ck" v-if="studentsequence.legacy_sequence.comment"
              v-html="studentsequence.legacy_sequence.comment">
            </span>
            <span v-else>pas de commentaires</span>
          </div>
        </div>
        <SkillsSequence :typeoftraining="'student'" :sequenceId="Number(this.$route.params.idSequence)"
          :trainingId="Number(this.$route.params.idStudentTraining)"
          :textTitle="'Compétences associées à cette expérience'" />
      </div>
    </div>
    <div v-else>
      <div class="is-flex column" v-if="step === idStudentActivity">
        <div v-if="studentactivity &&
        studentsequence.legacy_sequence &&
        trainingsequence.training_activities
        " class="w-100">
          <ActivityDetail v-bind:studentactivity="studentactivity"
            :sequencetype="studentsequence.legacy_sequence.sequence_type" :trainingsequence="trainingsequence.training_activities[studentactivity.position - 1]
        " :key="componentKey" @messageFromActivity="initActivity(studentactivity.id)" />
        </div>
      </div>
    </div>
    <div v-if="step === 'resources'" class="container pt-5 mt-5" id="StudentActivityResources">
      <div class="
          w-100
          columns
          is-vcentered is-tablet
          mb-4
          justify-content-between
        ">
        <div class="column">
          <h1 class="title-paragraph mb-0">
            Ressources associées à cette expérience
          </h1>
        </div>
        <div class="column is-narrow">
          <AppHelpMessage v-if="currentUser.role === 'apprenant'" title="Ressources et documents pour vous"
            message="Retrouvez ici les documents déposés pour vous par l'équipe pédagogique."
            textBtn="Besoin d'aide ?" />
          <AppHelpMessage v-else title="Ressources et documents pour l'apprenant•e" message="Retrouvez ici les documents déposés par
             l'équipe pédagogique pour l'apprenant•e." textBtn="Besoin d'aide ?" />
        </div>
      </div>
      <div class="helpUser mt-0" v-if="currentUser.role === 'apprenant'">
        <div>Retrouvez les ressources que l'on vous a attribué.</div>
      </div>
      <div v-if="currentUser.role === 'coordinateur' ||
        currentUser.role === 'formateur' ||
        currentUser.role === 'superviseur' ||
        currentUser.role === 'tuteur'
        ">
        <div class="card_simple is-primary w-100 has-text-centered btn" @click="isOpen = !isOpen">
          <div>
            <b-icon icon="resource" type="is-primary" class="mr-3"></b-icon>
            <b style="cursor: pointer">Ajoutez une ressource</b>
          </div>
        </div>
        <b-collapse v-model="isOpen">
          <ResourceCreation :isOpen.sync="isOpen" :sequence="studentsequence" :typeoftraining="'studenttraining'"
            :idTraining="$route.params.idStudentTraining" :action="'post'" @messageFromChild="initResources"
            @messageFromResource="forceRerenderResource" :key="resourceKey"></ResourceCreation>
        </b-collapse>
      </div>
      <div v-if="resources">
        <div v-if="resources.length">
          <div v-for="resource in resources" :key="resource.id">
            <ResourceCard v-if="resource.owner != studentsequence.student.id" :resource="resource" :action="'edit'"
              @messageFromChild="initResources">
            </ResourceCard>
          </div>
        </div>
      </div>
      <!-----------part 2 width documents uploaded by student ------->
      <div class="mt-5 pt-5">
        <div v-if="currentUser.role === 'apprenant'">
          <div class="
              w-100
              columns
              is-vcentered is-tablet
              mb-4
              justify-content-between
            ">
            <div class="column">
              <h1 class="title-paragraph mb-0">Ajoutez des documents</h1>
            </div>
            <div class="column is-narrow">
              <AppHelpMessage title="Ajoutez des documents" message="Ici vous pouvez déposer des documents qui vous semblent utiles ou
              nécessaires pour mieux comprendre ce que vous avez réalisé dans
              cette expérience ou ce que vous en avez retenu." textBtn="Besoin d'aide ?" />
            </div>
          </div>
          <div class="card_simple is-primary w-100 has-text-centered" @click="isOpen = !isOpen">
            <div>
              <b-icon icon="resource" type="is-primary" class="mr-3"></b-icon>
              <b style="cursor: pointer">Ajoutez une ressource</b>
            </div>
          </div>
          <b-collapse v-model="isOpen">
            <ResourceCreation :isOpen.sync="isOpen" :sequence="studentsequence" :typeoftraining="'studenttraining'"
              :idTraining="$route.params.idStudentTraining" :action="'post'" @messageFromChild="initResources"
              @messageFromResource="forceRerenderResource" :key="resourceKey"></ResourceCreation>
          </b-collapse>
        </div>
        <div v-else>
          <h1 class="title-paragraph">
            Documents téléchargés par l'apprenant•e
          </h1>
        </div>
        <div v-if="resources">
          <div v-if="resources.length">
            <div v-for="resource in resources" :key="resource.id">
              <ResourceCard v-if="resource.owner === studentsequence.student.id" :resource="resource" :action="'edit'"
                :isOpen.sync="isOpen" @messageFromChild="initResources"></ResourceCard>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';
import localization from 'moment/locale/fr';
import _ from 'lodash';
import ActivityDetail from '@/components/Trainings/ActivityDetail.vue';
import ResourceCreation from '@/components/Resources/ResourceCreation.vue';
import ResourceCard from '@/components/Resources/ResourceCard.vue';
import AppHelpMessage from '@/components/AppHelpMessage.vue';
import SkillsSequence from '@/components/Skills/SkillsSequence.vue';

moment.updateLocale('fr', localization);

export default {
  components: {
    ActivityDetail,
    ResourceCard,
    ResourceCreation,
    AppHelpMessage,
    SkillsSequence,
  },
  filters: {
    day(date) {
      return moment(date).format('D MMMM YYYY');
    },
    hour(date) {
      return moment(date).format('h:mm');
    },
  },
  data() {
    return {
      wsSequenceLoaded: false,
      studentsequence: [],
      trainingsequence: [],
      studentactivity: [],
      isLoading: true,
      isFullPage: false,
      currentTab: 1,
      tabActivity: null,
      numberStep: 1,
      step: 1,
      // declaration variables for tabs
      NumberActivity: null,
      idStudentActivity: null,
      showWeekNumber: false,
      locale: 'fr-FR',
      componentKey: 0, // variable to force render
      resources: null,
      resourceKey: 0,
      finished: false,
      editSequence: false,
      tutorName: null,
      teacherName: null,
      trainingActivity: 0,
      isOpen: false,
      editCfa: true,
      idActivityFromChild: null,
      firstStep: true,
    };
  },
  computed: {
    users() {
      return this.$store.state.users;
    },
    ...mapState('auth', ['currentUser']),
  },
  methods: {

    initStudentSequence() {
      this.$http
        .get(`/agenda/student_sequence/${this.$route.params.idSequence}/?query={*,student_activities{id,position,status}}&format=json`)
        .then((response) => {
          this.studentsequence = response.data;
          const activites = this.order(this.studentsequence.student_activities);
          const legacy = this.studentsequence.legacy_sequence;
          this.initLegacySequence(legacy.id);
          this.studentsequence.student_activities = activites;
          this.verifStatus();
          this.setdata();
        });
    },
    // have to load legacy sequence beacuse name, intro of activities are missing
    initLegacySequence(idLegacySequence) {
      this.startLoading();
      this.$http
        .get(`/training_sequences/${idLegacySequence}/?query={training_activities,teachers}&format=json`)
        .then((response) => {
          this.trainingsequence = response.data;
          const activites = this.order(this.trainingsequence.training_activities);
          this.trainingsequence.training_activities = activites;
          if (this.studentsequence.tutor) { this.gettutorName(this.studentsequence.tutor); }
          this.wsSequenceLoaded = true;
          this.endLoading();
        });
    },
    gotoStep(numberStep) {
      this.currentTab = numberStep;
      this.step = numberStep;
      this.$router.push({
        name: 'StudentActivityDetailEdit',
        params: {
          stepNumber: this.step,
          idTraining: this.$route.params.idTraining,
          idStudentTraining: this.$route.params.idStudentTraining,
          idActivity: 1,
        },
      });
    },
    gotoActivity(idActivity, indexActivity1, first) {
      this.tabActivity = 0;
      this.step = idActivity;
      this.idStudentActivity = idActivity;
      this.currentTab = idActivity;
      this.trainingActivity = indexActivity1;
      this.startLoading();
      this.initActivity(idActivity, first);
    },

    initActivity(idStudentActivity, first) {
      if (idStudentActivity !== '1') {
        this.$http
          .get(`/agenda/student_activity/${idStudentActivity}/?format=json`)
          .then((response) => {
            this.studentactivity = response.data;
            if (first === 0) {
              // first is 1 when page is updated
              // is 0 when user click on a tab to goto an activity
              if (this.$route.path !== `/activite-edition/ ${this.$route.params.idTraining}/${this.$route.params.idSequence}/${this.$route.params.idStudentTraining}/${idStudentActivity}`) {
                this.$router.push({
                  name: 'StudentActivityDetailEdit',
                  params: {
                    stepNumber: this.step,
                    idTraining: this.$route.params.idTraining,
                    idStudentTraining: this.$route.params.idStudentTraining,
                    idActivity: idStudentActivity,
                  },
                });
              }
            }
            this.forceRerender();
            if (this.wsSequenceLoaded) {
              this.endLoading();
            } else {
              this.startLoading();
            }
            // this.endLoading();
          }).catch((error) => {
            window.console.log(error);
            this.errored = true;
          });
      }
    },
    // go to a specific tab in page with stepNumber param
    goPreviousPage() {
      if (this.$route.params.from) {
        this.$router.push({
          name: this.$route.params.from,
          params: {
            idStudent: this.studentsequence.student.id, // need check id profile
            idTraining: this.$route.params.idTraining,
            idStudentTraining: this.studentsequence.student_training,
          },
        });
      } else {
        this.$router.push({
          name: 'StudentTraining',
          params: {
            idStudent: this.studentsequence.student.id, // need check id profile
            idTraining: this.$route.params.idTraining,
            idStudentTraining: this.studentsequence.student_training,
            tab: 'parcours',
          },
        });
      }
    },
    gettutorName(id) {
      this.$http
        .get(`/whois/${id}`)
        .then((response) => {
          this.tutorName = response.data.name;
        });
    },
    getUserName(id) {
      this.$http
        .get(`/whois/${id}`)
        .then((response) => {
          this.teacherName = response.data.name;
        });
    },
    gotoResources() {
      this.currentTab = 'resources';
      this.step = this.currentTab;
      this.initResources();
    },
    startLoading() {
      this.isLoading = true;
    },
    endLoading() {
      this.isLoading = false;
    },
    order(array) {
      return _.orderBy(array, 'position');
    },
    forceRerender() {
      // Every time that forceRerender is called, our prop componentKey will change.
      // and Vue knows that it has to destroy the component and create a new one.
      // to not have searching values in others tables
      this.componentKey += 1;
    },
    initResources() {
      this.startLoading();
      let sequencetypeResource = '';
      if (this.studentsequence.sequence_type) {
        sequencetypeResource = `&sequence_type=${this.studentsequence.sequence_type}`;
      }
      this.$http.get(`/get_resources/?student_sequence=${this.$route.params.idSequence}${sequencetypeResource}&format=json`).then((response) => {
        this.resources = response.data.resources;
        this.endLoading();
      });
    },
    verifStatus() {
      // verif if all actvities have a status "effectuée"
      const nbActivities = this.studentsequence.student_activities.length;
      let verifFinished = 0;
      for (let i = 0; i < nbActivities; i += 1) {
        if (this.studentsequence.student_activities[i].status !== 3) {
          this.finished = false;
          verifFinished = 0;
        } else {
          // at each yes, verif finished is incremented
          verifFinished += 1;
          if (verifFinished === nbActivities) {
            this.finished = true;
            // if it's ok we'll patch student sequence with status 3
            this.patchStatusSequence(3);
          }
        }
      }
    },
    patchStatusSequence(statusSequence) {
      this.$http
        .patch(`/agenda/student_sequence/${this.$route.params.idSequence}/`, {
          status: statusSequence,
        }).then(() => {
          if (statusSequence === 4) { this.$buefy.toast.open({ message: 'Expérience validée !', type: 'is-success' }); }
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
          this.$buefy.dialog.alert({
            title: 'Mince...',
            message: 'Une erreur s\'est produite, veuillez réessayer. Si l\'erreur persiste vous pouvez contacter le support technique.',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
          });
        });
    },
    patchSequence() {
      this.$http
        .patch(`/agenda/student_sequence/${this.$route.params.idSequence}/`, {
          start_date: this.studentsequence.start_date,
          end_date: this.studentsequence.end_date,
          comment: this.studentsequence.comment,
          subtitle: this.studentsequence.subtitle,
        }).then(() => {
          this.$buefy.toast.open({ message: 'Expérience modifiée !', type: 'is-success' });
          this.editSequence = false;
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
          this.$buefy.dialog.alert({
            title: 'Mince...',
            message: 'Une erreur s\'est produite, veuillez réessayer. Si l\'erreur persiste vous pouvez contacter le support technique.',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
          });
        });
    },
    setdata() {
      if (this.studentsequence.start_date) {
        const startDate = new Date(this.studentsequence.start_date);
        this.studentsequence.start_date = startDate;
      }
      if (this.studentsequence.end_date) {
        const endDate = new Date(this.studentsequence.end_date);
        this.studentsequence.end_date = endDate;
      }
    },
    forceRerenderResource() {
      // Every time that forceRerender is called, our prop componentKey will change.
      this.resourceKey += 1;
    },
    duplicateSequence() {
      this.$http
        .get(`/duplicate_student_sequence/?sequence=${this.$route.params.idSequence}`)
        .then(() => {
          this.$buefy.toast.open({ message: 'Expérience dupliquée !', type: 'is-success' });
          this.$router.push({
            name: 'StudentTrainingEdit',
            params: {
              idStudent: this.studentsequence.student.id, // need check id profile
              idTraining: this.$route.params.idTraining,
              idStudentTraining: this.studentsequence.student_training,
            },
          });
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
          this.$buefy.dialog.alert({
            title: 'Mince...',
            message: 'Une erreur s\'est produite, veuillez réessayer. Si l\'erreur persiste vous pouvez contacter le support technique.',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
          });
        });
    },
    handleHistoryChange() {
      // Your logic
      if (this.$route.params.idActivity === '1') {
        this.currentTab = 1;
        this.step = 1;
      } else {
        this.gotoActivity(this.$route.params.idActivity, null, 1);
      }
    },
    logActivity() {
      const typeAction = 'lecture';
      this.$http
        .post('agenda/logs/', {
          action: typeAction,
          student_sequence: this.studentsequence.id,
        })
        .then(() => {
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
        });
    },
  },
  created() {
    this.startLoading();
    this.initStudentSequence();
    this.idStudentActivity = this.$route.params.idActivity;
    this.currentTab = this.$route.params.idActivity;
    if (this.$route.params.idActivity > 1) {
      this.gotoActivity(this.$route.params.idActivity, null, 1);
    } else { this.currentTab = 1; }
    window.addEventListener('popstate', this.handleHistoryChange);
  },
  mounted() {
  },
  destroyed() {
    window.removeEventListener('popstate', this.handleHistoryChange);
  },
};
</script>

<style lang="scss">
.blockClosed {
  display: none;
}

.blockOpen {
  display: block;
}
</style>
