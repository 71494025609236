<template>
  <div class="modal-card" style="background-color: #fff">
    <b-loading :is-full-page="isFullPage" v-model="isLoading"> </b-loading>
    <a @click="$emit('close')" class="ml-auto">
      <b-icon icon="close" type="is-primary"></b-icon>
    </a>
    <div class="nameSequence">Exporter votre contenu</div>
    <div class="content has-text-centered">
      <div class="mb-3">
        <b-button type="is-primary is-rounded mr-3" rounded @click="exportSuivi"
          >Export le livret de suivi</b-button
        >
      </div>
      <div class="mb-3">
        <b-button
          type="is-primary is-rounded mr-3"
          rounded
          @click="exportLivret"
          >Exporter le livret complet</b-button
        >
      </div>
      <!-- <div class="mb-3">
        <b-button type="is-primary is-rounded mr-3" disabled rounded
          >Exporter les compétences</b-button
        >
      </div> -->
    </div>
  </div>
</template>

<script>

export default ({
  props: {
    student: {
      type: [String, Number],
    },
    student_id: {
      type: [String, Number],
    },
  },
  data() {
    return {
      isLoading: false,
      isFullPage: false,
    };
  },
  methods: {
    startLoading() {
      this.isLoading = true;
    },
    endLoading() {
      this.isLoading = false;
    },
    exportLivret() {
      this.startLoading();
      this.$http
        .get(`export_livret/?training_id=${this.$route.params.idTraining}&student_id=${this.student_id}&done=1`, {
          responseType: 'blob',
        }).then((response) => {
          const username = this.student;
          const usernameReplaced = username.replace(' ', '_');
          const fileURL = window.URL.createObjectURL(new Blob([response.data]));
          const fileLink = document.createElement('a');
          fileLink.href = fileURL;
          fileLink.setAttribute('download', `insitu_livret_${usernameReplaced}.pdf`);
          document.body.appendChild(fileLink);
          fileLink.click();
          this.endLoading();
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
          this.$buefy.dialog.confirm({
            title: 'Mince...',
            message: 'Le livret n\'a pu être téléchargé',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
            onConfirm: () => document.location.reload(),
          });
        });
    },
    exportSuivi() {
      this.startLoading();
      this.$http
        .get(`export_livret/?training_id=${this.$route.params.idTraining}&student_id=${this.student_id}&booklet=1&waypoints=1`, {
          responseType: 'blob',
        }).then((response) => {
          const username = this.student;
          const usernameReplaced = username.replace(' ', '_');
          const fileURL = window.URL.createObjectURL(new Blob([response.data]));
          const fileLink = document.createElement('a');
          fileLink.href = fileURL;
          fileLink.setAttribute('download', `insitu_livret_${usernameReplaced}.pdf`);
          document.body.appendChild(fileLink);
          fileLink.click();
          this.endLoading();
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
          this.$buefy.dialog.confirm({
            title: 'Mince...',
            message: 'Le livret n\'a pu être téléchargé',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
            onConfirm: () => document.location.reload(),
          });
        });
    },
  },
  created() {

  },
});

</script>

<style scoped>
.modal-card {
  border-radius: 5px;
  padding: 3rem;
  margin: auto !important;
}
.nameSequence {
  font-size: 2rem;
  color: #531be5;
  font-weight: 700;
  text-align: center;
  margin-bottom: 3rem;
  line-height: 1.3;
}
@media (max-width: 768px) {
  .nameSequence {
    font-size: 1.5rem;
  }
  .modal-card {
    padding: 1rem;
  }
}
@media (max-width: 576px) {
  .nameSequence {
    font-size: 1.3rem;
  }
}
</style>
