<template>
  <div class="mb-5" v-if="studentTrainings">
    <div class="mb-4">
      <b-tag type="is-primary is-light mr-2">{{
        studentTrainings.length
      }}</b-tag>
      <b class="has-text-primary is-size-6">Parcours suivis</b>
    </div>
    <div v-for="training of order(studentTrainings)" :key="training.id">
      <router-link
        :to="
          '/formation-apprenant/' +
          training.training.id +
          '/' +
          training.student +
          '/' +
          training.id +
          '/parcours'
        "
      >
        <div class="card_item mb-1" v-if="!training.finished">
          <div>
            <b class="has-text-primary"> {{ training.student_profile }} </b>
          </div>
          <div class="title-card">
            <b class="has-text-black">{{ training.training.name }} </b
            ><b-tag type="is-outlined" class="ml-auto" v-if="training.option">
              {{ training.option.title }}
            </b-tag>
          </div>
          <div></div>

          <div
            class="is-clickable is-tutor"
            v-for="(tutor, index) in training.tutors_profiles"
            :key="index"
          >
            <div class="is-underlined has-text-primary" type="is-text">
              tuteur : {{ tutor }}
            </div>
            <span v-if="training.start_date" style="font-size: 0.9rem"
              ><span class="is-underlined">début</span> :
              {{ training.start_date | day }} /
              <span class="is-underlined">fin</span> :
              {{ training.end_date | day }}</span
            >
          </div>
        </div></router-link
      >
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import localization from 'moment/locale/fr';
import _ from 'lodash';

moment.updateLocale('fr', localization);

export default ({
  props: {
  },
  filters: {
    day(date) {
      return moment(date).format('DD/MM/YY');
    },
  },
  data() {
    return {
      isLoading: false,
      isFullPage: false,
      studentTrainings: null,
    };
  },
  methods: {
    order(array) {
      return _.orderBy(array, 'id', 'desc');
    },
    startLoading() {
      this.isLoading = true;
    },
    endLoading() {
      this.isLoading = false;
    },
    getTrainings() {
      const urlWs = '/agenda/student_training/?query={id,student,organization,student_profile,tutors,tutors_profiles,training{id,name},deleted,finished,active,start_date,end_date,option}&format=json';
      this.$http
        .get(`${urlWs}`)
        .then((response) => {
          this.studentTrainings = response.data;
        });
    },
  },
  created() {
    this.getTrainings();
  },
});

</script>

<style lang="scss" scoped>
.card_item {
  padding: 1rem;
  a {
    color: unset;
  }
}
.is-tutor {
  font-size: 0.85rem;
}
.title-card {
  display: flex;
  align-items: center;
}
</style>
