<template>
  <div>
    <div class="is-flex align-items-center eval-icons">
      <div
        v-for="evaluation in skill.evaluations"
        :key="evaluation.id"
        class="evaluation"
        :class="evaluation.context ? 'entreprise' : ''"
      >
        <!-- <b-tooltip
          :label="
            new Date(evaluation.date_created).toLocaleDateString() +
            ' / ' +
            evaluation.role +
            ' / ' +
            evaluation.training_level_label
          "
          position="is-bottom"
          multilined
          type="is-primary is-light"
        > -->
        <b-tooltip type="is-light" class="evaluation-icon">
          <template v-slot:content>
            <div>
              {{ new Date(evaluation.date_created).toLocaleDateString() }}
            </div>
            <div>
              <b> {{ evaluation.role }}</b>
            </div>
            <div>{{ evaluation.training_level_label }}</div>
          </template>
          <div class="has-text-centered" style="height: 37px">
            <svg
              v-if="
                evaluation.role === 'tuteur' ||
                evaluation.role === 'tuteur,apprenant'
              "
              width="45px"
              height="45px"
              viewBox="0 0 45 45"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
            >
              <title>Oval</title>
              <g
                id="Page-1"
                stroke="none"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd"
              >
                <g
                  id="Oval"
                  fill="#67EB7B"
                  :class="`level${evaluation.training_level}`"
                >
                  <circle cx="22.5" cy="22.5" r="22.5"></circle>
                </g>
              </g>
            </svg>
            <svg
              v-if="
                evaluation.role === 'formateur' ||
                evaluation.role === 'coordinateur'
              "
              width="41px"
              height="41px"
              viewBox="0 0 41 41"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
            >
              <title>Rectangle</title>
              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g
                  id="Rectangle"
                  fill="#67EB7B"
                  :class="`level${evaluation.training_level}`"
                >
                  <rect x="0" y="0" width="41" height="41"></rect>
                </g>
              </g>
            </svg>
            <svg
              v-if="evaluation.role === 'certif'"
              width="59.9165605px"
              height="56.9840353px"
              viewBox="0 0 59.9165605 56.9840353"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
            >
              <title>Star</title>
              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g
                  id="Star"
                  transform="translate(-1.5417, 0)"
                  fill="#FBBA43"
                  :class="`level${evaluation.training_level}`"
                >
                  <!-- eslint-disable max-len-->
                  <polygon
                    points="31.5 47.25 12.9847646 56.9840353 16.5208599 36.3670177 1.54171974 21.7659647 22.2423823 18.7579823 31.5 0 40.7576177 18.7579823 61.4582803 21.7659647 46.4791401 36.3670177 50.0152354 56.9840353"
                  ></polygon>
                </g>
              </g>
            </svg>
            <svg
              v-if="evaluation.role === 'apprenant'"
              width="45.5555556px"
              height="41px"
              viewBox="0 0 45.5555556 41"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
            >
              <title>Triangle</title>
              <g
                id="Page-1"
                stroke="none"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd"
              >
                <g
                  id="Triangle"
                  fill="#67EB7B"
                  :class="`level${evaluation.training_level}`"
                >
                  <polygon
                    transform="translate(22.7778, 20.5) scale(-1, 1) translate(-22.7778, -20.5)"
                    points="22.7777778 0 45.5555556 41 0 41"
                  ></polygon>
                </g>
              </g>
            </svg>
          </div>
          <div v-if="dates" class="has-text-black datesText">
            {{ evaluation.date_created | day }}
            {{ evaluation.date_created | year }}
          </div>
        </b-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import _ from 'lodash';
import moment from 'moment';

export default {
  props: {
    skill: {
      type: [Object, Array],
    }, // skill to display
    dates: {
      type: Boolean,
      // boolean to display dates or not
    },
  },
  components: {
  },
  filters: {
    day(date) {
      return moment(date).format('DD/MM');
    },
    year(date) {
      return moment(date).format('YYYY');
    },
  },
  data() {
    return {
      isLoading: false,
      isFullPage: false,
    };
  },
  computed: {
    ...mapState('auth', ['currentUser']),
  },
  methods: {
    startLoading() {
      this.isLoading = true;
    },
    endLoading() {
      this.isLoading = false;
    },
    order(array) {
      return _.orderBy(array, 'position');
    },
    initSkills() {
      this.startLoading();
      this.$http
        // .get(`/get_trainingskillsblock/?training=${this.objectId}&evaluations=1`)
        .get(`/agenda/get_skills_history/?training=${this.objectId}&format=json`)
        .then((response) => {
          this.skillsList = this.order(response.data);
          this.evaluations = response.data.skill_most_rated;
          window.console.log(response.data);
          // if (this.typeoftraining === 'training') {
          //   this.skillsList = this.skillsList.map(item => {
          //  return { tskills: item.tskills, name: item.name,
          // reference: item.reference,id: item.id,
          // training: item.training, description: item.description, position: item.position };
          //   });
          //   window.console.log(this.skillsList);
          // }

          // for (let i = 0; i < this.skillsList.length; i += 1) {
          //   const myBlocks = this.skillsList;
          //   this.skillsList[i].tskills = this.order(myBlocks[i].tskills);
          // }
          this.endLoading();
        });
    },
    initLevels() {
      this.$http
        .get(`/traininglevels/?training=${this.objectId}&format=json`)
        .then((response) => {
          this.skillsLevels = this.order(response.data);
        });
    },
    openEdit(index) {
      if (this.isOpen !== index) {
        this.isOpen = index;
      } else {
        this.isOpen = false;
      }
    },
    seeSkilldetail(skill, newValue) {
      this.isSkillModalActive = true;
      this.formSkill.id = skill.id;
      this.formSkill.name = skill.name;
      this.formSkill.description = skill.description;
      this.formSkill.reference = skill.reference;
      this.formSkill.block = skill.block;
      this.isNew = newValue;
    },
    seeBlockdetail(block) {
      this.isBlockModalActive = true;
      this.formBlock.id = block.id;
      this.formBlock.name = block.name;
      this.formBlock.description = block.description;
      this.formBlock.reference = block.reference;
      this.isNew = false;
    },
  },
  created() {
    // this.skillsList = this.skills;
    // this.initLevels();
  },
  mounted() {
  },
};
</script>
<style scoped>
svg {
  width: 20px;
}
.level1 {
  fill: var(--danger);
}
.level2 {
  fill: var(--yellow);
}
.level3 {
  fill: var(--success);
}
.level4 {
  fill: #00c0fb;
}
.datesText {
  font-size: 12px;
  text-align: center;
  line-height: 1.1;
}
.evaluation-icon {
  width: 36px;
}
.evaluation {
  margin-right: 0.5rem;
}
.centre {
  background-color: lightsteelblue;
}
</style>
