<template>
  <div>
    <b-table
      :data="bigSteps.data"
      checkable
      :checkbox-position="'left'"
      :checkbox-type="'is-primary'"
      :default-sort="['name', 'asc']"
    >
      <template v-for="column in columns">
        <b-table-column :key="column.id" v-bind="column">
          <template
            v-if="column.searchable && !column.numeric"
            slot="searchable"
            slot-scope="props"
          >
            <b-input
              v-model="props.filters[props.column.field]"
              placeholder="Rechercher..."
              icon="search"
              size="is-small"
            />
          </template>
          <template v-slot="props" v-if="column.field === 'name'">
            <b class="is-clickable"> {{ props.row[column.field] }}</b>
          </template>
          <template v-slot="props" v-else-if="column.field === 'tutors'">
            {{ props.row[column.field] }}
          </template>
          <template v-slot="props" v-else-if="column.field === 'steps'">
            <!-- {{ props.row["data"][0].section_name }} -->
            <div class="is-flex justify-content-center">
              <div v-for="step in props.row['data']" :key="step.id">
                <b-tooltip
                  :label="step.part_short_name + ' / ' + step.user_status_label"
                  position="is-bottom"
                  multilined
                  type="is-primary is-light"
                >
                  <img
                    v-if="step.user_status_label === 'Effectué'"
                    :src="require(`@/assets/img/steps/status3.svg`)"
                    alt=""
                    class="img_status"
                    width="18"
                  />
                  <img
                    v-else-if="step.user_status_label === 'Refusé'"
                    :src="require(`@/assets/img/steps/status7.svg`)"
                    alt=""
                    class="img_status"
                    width="18"
                  />
                  <img
                    v-else-if="step.user_status_label === 'En cours'"
                    :src="require(`@/assets/img/steps/status6.svg`)"
                    alt=""
                    class="img_status"
                    width="18"
                  />
                  <img
                    v-else-if="step.user_status_label === 'En retard'"
                    :src="require(`@/assets/img/steps/status-late.svg`)"
                    alt=""
                    class="img_status"
                    width="18"
                  />
                  <img
                    v-else-if="step.user_status_label === 'Litige'"
                    :src="require(`@/assets/img/steps/status2.svg`)"
                    alt=""
                    class="img_status"
                    width="18"
                  />
                  <div
                    v-else-if="step.user_status_label === 'A faire'"
                    class="later ml-2"
                  ></div>
                </b-tooltip>
              </div>
            </div>
          </template>
          <template v-slot="props" v-else-if="column.field === 'step'">
            <span v-if="props.row['step']">
              {{ props.row["step"][column.step].status }}</span
            >
          </template>
          <template
            v-slot="props"
            v-else-if="column.field === 'id_student_training'"
          >
            <div
              @click.prevent="
                seeStudentTraining(
                  props.row.student_id,
                  props.row.student_training_id
                )
              "
            >
              <b-icon icon="see"></b-icon>
            </div>
          </template>
        </b-table-column>
      </template>
    </b-table>
  </div>
</template>

<script>

export default {
  props: {
    typeoftraining: {
      type: String,
    }, // framework, training or studenttraining
    objectId: {
      type: Number,
    },
    contentWidth: {
      type: Number,
    },
  },
  components: {
  },
  data() {
    return {
      activeTab: 'all',
      columns: [
        {
          field: 'name',
          label: 'Nom',
          width: '150',
          searchable: true,
          sortable: true,
          visible: true,
        },
        {
          field: 'tutors',
          label: 'Nom du tuteur',
          width: '150',
          searchable: true,
          sortable: true,
          visible: true,
        },
        {
          field: 'steps',
          label: 'Semestres',
          width: '80',
          centered: true,
          searchable: false,
          sortable: true,
          visible: true,
        },
        // {
        //   field: 'step',
        //   label: 'Sem 1',
        //   width: '80',
        //   step: 1,
        //   centered: true,
        //   searchable: false,
        //   sortable: true,
        //   visible: true,
        // },
        // {
        //   field: 'step',
        //   label: 'Sem 2',
        //   width: '80',
        //   step: 2,
        //   centered: true,
        //   searchable: false,
        //   sortable: true,
        //   visible: true,
        // },
        {
          field: 'id_student_training',
          label: 'Parcours',
          width: '50',
          centered: true,
          searchable: false,
          sortable: true,
          visible: true,
        },
      ],
      data: [
        {
          name: 'Bitoune Julien ',
          tutor: 'Marc Portelli',
          evaluated: 'refusé',
          validated: 'refusé',
          id_student_training: '1',
          steps: [
            { id: 1, status: 'validé' },
            { id: 2, status: 'validé' },
            { id: 3, status: 'refusé' },
            { id: 4, status: 'validé' },
            { id: 5, status: 'null' },
            { id: 6, status: 'null' },
          ],
          step: [
            { id: 1, status: 'validé' },
            { id: 2, status: 'validé' },
            { id: 3, status: 'refusé' },
            { id: 4, status: 'validé' },
            { id: 5, status: 'null' },
            { id: 6, status: 'null' },
          ],
        },
        {
          name: 'Bornée Elisabeth ',
          tutor: 'Gerald Armealamain',
          evaluated: 'validé',
          validated: 'validé',
          id_student_training: '1',
          steps: [
            { id: 1, status: 'refusé' },
            { id: 2, status: 'validé' },
            { id: 3, status: 'refusé' },
            { id: 4, status: 'validé' },
            { id: 5, status: 'null' },
            { id: 6, status: 'null' },
          ],
        },
        {
          name: 'TraoréAboubakar',
          tutor: 'Jean-Marc Apathique',
          evaluated: 'validé',
          validated: 'à vérifier',
          id_student_training: '1',
        },
        {
          name: 'Amoitie Jean-Michel',
          tutor: 'Jean-Michel Blanquette',
          evaluated: 'validé',
          validated: 'refusé',
          id_student_training: '1',
        },
      ],
      nowPosition: 'hello',
      actualStep: 20,
      heightLine: 0,
      bigSteps: [],
    };
  },
  computed: {
  },
  methods: {
    initBigSteps() {
      this.$http
        .get(`/get_training_booklet_validations/${this.$route.params.idTraining}?type=waypoint`)
        .then((response) => {
          this.bigSteps = response.data;
        });
    },
    seeStudentTraining(studentId, studentTraining) {
      this.$router.push({
        name: 'StudentTraining',
        params: {
          idStudent: studentId,
          idTraining: this.$route.params.idTraining,
          idStudentTraining: studentTraining,
          tab: 'waypoint',
        },
      });
    },
  },
  created() {
    this.initBigSteps();
  },
  mounted() {
  },
};
</script>
