<template>
  <div>
    <!-- eslint-disable max-len-->
    <b-modal v-model="displayTermsAgreement" has-modal-card>
      <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
          <p v-if="termsAgreementStep == 1" class="modal-card-title">
            Conditions générales d'utilisation
          </p>
          <p v-else class="modal-card-title">
            Politique de protection des données personnelles
          </p>
        </header>
        <section class="modal-card-body" v-if="termsAgreementStep == 1">
          <!-- GGU -->
          <h1>A propos</h1>
          <br /><br />
          <p>
            Le Logiciel dénommé “inSitu” est édité par Seity, société à
            responsabilité limitée au capital de 667 €, immatriculée au Registre
            du Commerce et des Sociétés de Marseille sous le numéro 823 545 819,
            dont le siège social est situé 66 Boulevard Notre Dame 13006
            Marseille, France (« Seity »).
          </p>
          <br />
          <h1>Objet</h1>
          <br /><br />
          <p>
            L’utilisation du Logiciel, qu’il soit gratuit (avec un compte de
            démonstration fourni) ou payant (lorsque l’Utilisateur dispose d’un
            abonnement au Logiciel) est soumise au respect des présentes CGU par
            l’Utilisateur
          </p>
          <br />
          <h1>Définitions</h1>
          <br /><br />
          <p>
            Les Parties conviennent et acceptent que les termes suivants
            utilisés avec une majuscule, au singulier et/ou au pluriel, auront,
            dans le cadre des présentes Conditions Générales d'Utilisation, la
            signification définie ci-après :
          </p>
          <ul style="list-style-type: disc; margin-left: 1rem">
            <li>
              « Contrat » : désigne les présentes Conditions Générales
              d'Utilisation ainsi que la Politique de protection des données
              personnelles ;
            </li>
            <li>
              « Plateforme » : plateforme numérique de type site Web et/ou
              application mobile permettant l'accès au Service ainsi que son
              utilisation ;&nbsp;
            </li>
            <li>
              « Service » : désigne l’ensemble des fonctionnalités de la
              Plateforme permettant la facilitation de la formation en
              apprentissage ou en situation de travail ;
            </li>
            <li>
              « inSitu » désigne le logiciel en mode Saas, ainsi que son
              propriétaire Seity
            </li>
            <li>
              « Utilisateur » : désigne toute personne qui utilise la
              Plateforme, quel que soit son rôle (apprenant, formateur, tuteur,
              manager ou coordinateur pédagogique et visiteur) ;
            </li>
          </ul>
          <p>
            Les présentes Conditions Générales d'Utilisation (ci-après les
            "CGU") régissent nos rapports avec vous, personne accédant à la
            Plateforme, applicables durant votre utilisation de la Plateforme
            et, si vous êtes un Utilisateur jusqu'à désactivation de votre
            compte. Si vous n'êtes pas d'accord avec les termes des CGU il vous
            est vivement recommandé de ne pas utiliser notre Plateforme et nos
            services.
          </p>
          <p>
            Vous, Utilisateur, confirmez avoir lu, compris et accepté les
            présentes CGU ainsi que notre politique de Protection des données
            personnelles, que vous êtes invité à lire et accepter lors de la
            première utilisation, en cochant la case prévue à cet effet.
          </p>
          <p>
            Nous vous encourageons par ailleurs à consulter les « Conditions
            Générales d'Utilisation et la Politique de protection des données
            personnelles » régulièrement lors de leurs mises à jour. En effet,
            Seity se réserve le droit de modifier ou d’adapter les présentes CGU
            à tout moment, en notifiant toute nouvelle version à l’Utilisateur,
            qui s’engage à prendre connaissance de ces modifications. Vous serez
            informé des modifications par courriel ou via notre Plateforme, et
            ce pour vous permettre d’en prendre connaissance avant leur mise en
            effet. Si vous continuez à utiliser notre Plateforme après la
            publication ou l'envoi d'un avis concernant les modifications
            apportées aux présentes conditions, cela signifie que vous acceptez
            les mises à jour. Les CGU qui vous seront opposables seront celles
            en vigueur lors de votre utilisation de la Plateforme.
          </p>
          <p><br /></p>
          <h2>Article 1 — Accès au Logiciel</h2>
          <p><br /></p>
          <h3>1.1 Conditions d'inscription à la Plateforme</h3>
          <p>
            Certaines fonctionnalités de la Plateforme nécessitent d'être
            inscrit et d'obtenir un compte.&nbsp;
          </p>
          <p>
            Vous déclarez avoir la capacité d'accepter les présentes conditions
            générales d'utilisation, c'est-à-dire avoir plus de 16 ans et ne pas
            faire l'objet d'une mesure de protection juridique des majeurs (mise
            sous sauvegarde de justice, sous tutelle ou sous curatelle).
          </p>
          <p>
            Avant d'accéder à notre Plateforme, le consentement des mineurs de
            moins de 16 ans doit être donné par le titulaire de l'autorité
            parentale.
          </p>
          <p><br /></p>
          <h3>1.2 Création de compte</h3>
          <p>L’inscription à la Plateforme se déroule de la façon suivante :</p>
          <ul style="list-style-type: disc; margin-left: 1rem">
            <li>
              Les équipes de votre Centre de Formation demandent une création de
              compte, à partir des données fournies, vous êtes seuls
              responsables de la licéité, de la sincérité et de l’exactitude des
              informations communiquées.
            </li>
            <li>
              Vous recevrez ensuite un courriel avec un mot de passe temporaire
              que vous devrez modifier lors de votre première connexion à notre
              Plateforme.
            </li>
            <li>
              Seity offre la possibilité de s’identifier en utilisant un compte
              existant sur une plateforme tierce (notamment via Microsoft Office
              ou yPareo), en utilisant la fonction prévue à cet effet. En
              utilisant une telle fonctionnalité, nous aurons accès, publierons
              sur notre Plateforme et conserverons certaines informations de
              votre compte Microsoft Office ou NetYPareo. Vous pouvez à tout
              moment supprimer le lien entre votre compte sur la Plateforme
              inSitu et votre compte Microsoft Office ou NetYPareo en effectuant
              la demande auprès de votre Centre de Formation. Si vous souhaitez
              en savoir plus sur l'utilisation de vos données dans le cadre de
              votre compte Microsoft Office ou NetYPareo, consultez notre
              Politique de protection des données personnelles et celles de
              Microsoft Office ou NetYPareo.
            </li>
          </ul>
          <p>
            A l'occasion de la création de votre compte, et ce quelle que soit
            la méthode choisie pour ce faire, vous vous engagez à fournir des
            informations personnelles vraies, exactes, complètes et à les mettre
            à jour par l'intermédiaire de votre profil ou en en avertissant
            votre Centre de Formation et inSitu, afin d'en garantir la
            pertinence et l'exactitude tout au long de l’utilisation d’inSitu.
          </p>
          <p>
            Les informations que vous avez fournies pendant l'inscription
            peuvent être corrigées en contactant votre Centre de Formation et
            inSitu. Vous acceptez de respecter les lois applicables lorsque vous
            utilisez les services, et vous ne pouvez utiliser les services qu'à
            des fins légales. Le contenu présent sur la Plateforme doit
            uniquement être utilisé pour un usage privé.
          </p>
          <p>
            Lorsque vous choisissez votre mot de passe, vous ne devez pas
            utiliser de mot de passe simpliste (par exemple : 123456).
          </p>
          <p>
            L'identifiant et le mot de passe seront strictement personnels et
            confidentiels et vous devrez les conserver et les utiliser de
            manière à en préserver la stricte confidentialité.
          </p>
          <p>
            Vous vous engagez à garder secret le mot de passe choisi lors de la
            création de votre compte et à ne le communiquer à personne. En cas
            de perte ou divulgation de votre mot de passe, vous vous engagez à
            en informer sans délai inSitu. Vous êtes seul responsable de
            l'utilisation faite de votre compte par un tiers, tant que vous
            n'avez pas expressément notifié inSitu de la perte, l'utilisation
            frauduleuse ou la divulgation de votre mot de passe à un tiers.
          </p>
          <p>
            Vous vous engagez à ne pas créer ou utiliser, sous votre propre
            identité ou celle d'un tiers, d'autres comptes que celui
            initialement créé. Vous ne pouvez pas autoriser des tiers à utiliser
            votre compte. Vous ne pouvez pas céder ou, quoi qu'il en soit,
            transférer votre compte à toute autre personne ou entité.
          </p>
          <p>
            L’Utilisateur sera seul autorisé à accéder au Service à l'aide de
            son identifiant et son mot de passe. Toute utilisation de la
            Plateforme au moyen de ses identifiants et mot de passe est réputée
            avoir été faite par l’Utilisateur lui-même. En cas d'utilisation par
            un tiers de ses identifiant et mot de passe, l’Utilisateur devra en
            avertir immédiatement inSitu en lui adressant un courriel à
            l'adresse suivante : hello@insituapp.io.
          </p>
          <p>
            L’Utilisateur qui a perdu son mot de passe devra se connecter sur la
            plateforme inSitu et suivre la procédure en cliquant sur le lien «
            accès oubliés ? ».
          </p>
          <p>
            L’Utilisateur est responsable de l'utilisation de la Plateforme et
            de toutes les actions réalisées au sein de la Plateforme avec son
            identifiant et son mot de passe, sauf si l'utilisation de son compte
            a été faite après sa désinscription, ou après notification à inSitu
            d'une utilisation abusive de son compte.
          </p>
          <p><br /></p>
          <h2>1.3. Conditions d'accès à la Plateforme</h2>
          <p><br /></p>
          <h3>1.3.1 Vous avez un compte Utilisateur&nbsp;</h3>
          <p>
            Si vous bénéficiez d’un compte avec abonnement vous pouvez accéder à
            la Plateforme et à l’ensemble des fonctionnalités. Suite à la
            création de votre compte, vous pourrez accéder à votre page de
            profil où vous renseignez certaines informations qui sont
            obligatoires et d'autres qui sont optionnelles.
          </p>
          <p>
            Vous pouvez accéder aux données des parcours de formations
            effectués. Vous avez la possibilité d’effectuer des évaluations ou
            d’échanger avec l’équipe pédagogique. Si votre profil le permet,
            d’accéder aux statistiques et aux données des parcours de formations
            de groupes d’apprenants.&nbsp;
          </p>
          <p><br /></p>
          <h3>1.3.2 Si vous n'avez pas de compte Utilisateur</h3>
          <p>
            Si vous n'avez pas créé de compte Utilisateur sur notre Plateforme,
            vous pouvez tout de même accéder à la Plateforme mais vous ne
            pourrez pas bénéficier de toutes les fonctionnalités et vous n'aurez
            pas de profil personnel. En effet certains accès ne vous seront pas
            autorisés, comme l'accès intégral aux parcours de formation mise en
            ligne par les équipes pédagogiques et les fonctionnalités
            d’évaluation. Les présentes CGU vous sont opposables car elles
            régissent les conditions d'utilisation du Service, vous devez en
            prendre connaissance et les accepter avant de poursuivre votre
            navigation sur la Plateforme.
          </p>
          <p><br /></p>
          <h3>1.3.3 Si votre compte Utilisateur est limité</h3>
          <p>
            Si vous avez bénéficié d’un compte avec abonnement vous pouvez
            accéder à la Plateforme et à certaines fonctionnalités. Suite à la
            création de votre compte, vous pourrez accéder à votre page de
            profil où vous renseignez certaines mentions qui sont obligatoires
            et d'autres qui sont optionnelles.
          </p>
          <p>
            Vous pouvez accéder aux données des parcours de formations
            effectués. Vous n’aurez en revanche pas la possibilité d’effectuer
            des évaluations ou d’échanger avec l’équipe pédagogique. Les
            présentes CGU vous sont opposables car elles régissent les
            conditions d'utilisation du Service, vous devez en prendre
            connaissance et les accepter avant de poursuivre votre navigation
            sur la Plateforme.
          </p>
          <p><br /></p>
          <h2>Article 2. Informations fournies par les utilisateurs</h2>
          <p>
            Chaque Utilisateur garantit à inSitu que les informations qu'il
            fournit quant à son identité et ses coordonnées dans le cadre du
            Service sont vraies, exactes, complètes et à jour. Vous êtes seul
            responsable de la sincérité et de l'exactitude de ces informations.
            Vous vous engagez à mettre à jour régulièrement l'ensemble des
            informations afin de préserver leur exactitude et à effectuer les
            nécessaires demandes de mise à jour en contactant votre Centre de
            Formation et inSitu.
          </p>
          <p><br /></p>
          <p>
            inSitu ne pourra en aucun cas être tenue responsable des erreurs,
            omissions, imprécisions pouvant être relevées dans les informations
            que vous nous avez fournies ou qui ont été fournies par votre Centre
            de Formation, ni du préjudice pouvant éventuellement en découler
            pour les autres Utilisateurs ou pour des tiers.
          </p>
          <p>
            Vous êtes responsable de toute l'activité qui se déroule sur votre
            compte, et vous acceptez de préserver à tout moment la sécurité et
            le secret de votre identifiant et de votre mot de passe. Vous ne
            pouvez posséder qu'un seul compte.
          </p>
          <p><br /></p>
          <h2>Article 3. Fonctionnalités du service</h2>
          <p><br /></p>
          <h3>3.1. Les contenus pédagogiques en ligne</h3>
          <p>
            Tout contenu pédagogique publié au sein du Service demeure la
            propriété de son auteur.
          </p>
          <h3><br /></h3>
          <h3>3.2. Espaces de discussion</h3>
          <p>
            Les Utilisateurs ont la faculté d'accéder à des espaces de
            discussion où ils peuvent consulter notamment les fils de discussion
            entre Utilisateurs, et échanger avec ces derniers sur une question
            donnée.
          </p>
          <p>
            Vous vous engagez à ne pas diffuser de correspondance en dehors de
            la Plateforme, et/ou sur un espace public.
          </p>
          <p>
            Lorsque vous publiez un message, vous vous obligez à respecter en
            particulier les dispositions de l'article 7.2 ci-après et celles de
            la « Charte de Bonne Conduite »(article 7).
          </p>
          <p>
            Les Utilisateurs et les Abonnés peuvent visualiser les messages
            d’autres Utilisateurs sur les espaces de discussion et les signaler,
            conformément à l'article 5 ci-après.
          </p>
          <h2><br /></h2>
          <h2>Article 4. Propriété intellectuelle</h2>
          <h3><br /></h3>
          <h3>
            4.1. Propriété intellectuelle afférente au Service, à notre
            Plateforme et aux éléments qui les composent
          </h3>
          <p>
            A l'exception des contenus pédagogiques et du contenu généré par la
            complétion des expériences liées aux situations de travail par les
            Utilisateurs et/ou par les Partenaires d'inSitu, l'ensemble des
            éléments techniques, graphiques, textuels ou autres constituant le
            Service et/ou notre Plateforme (textes, graphismes, logiciels,
            fichiers multimédias, photographies, images, vidéos, sons, plans,
            charte graphique, technologie(s), codes sources, noms, marques,
            logos, visuels, bases de données, etc.) ainsi que notre Plateforme
            et le Service eux-mêmes sont la propriété exclusive d’inSitu.
          </p>
          <p>
            L'Utilisateur reconnaît qu'aucune propriété ne lui est transmise, et
            qu'aucun droit ou licence ne lui est accordé(e), en dehors d'un
            droit d'utiliser le Service conformément aux présentes pendant la
            durée du Contrat, et des droits d'utilisation des contenus
            pédagogiques qui lui sont concédés dans le cadre de l’utilisation
            d’inSitu par le Centre de Formation.
          </p>
          <p>
            En conséquence, sauf autorisation expresse et préalable d'inSitu
            et/ou sauf licence Creative Commons l'y autorisant, l'Utilisateur
            s'engage à ne pas :
          </p>
          <ul style="list-style-type: disc; margin-left: 1rem">
            <li>
              Reproduire, à des fins commerciales ou non, des contenus présents
              au sein du Service (à l'exception de ses propres contenus) et/ou
              les éléments techniques, graphiques, textuels ou autres
              constituant le Service et/ou notre Plateforme ;
            </li>
            <li>
              Intégrer tout ou partie du contenu du Service et/ou de notre
              Plateforme dans un site tiers, à des fins commerciales ou non
              commerciales ;
            </li>
            <li>
              Utiliser un robot, notamment d'exploration (spider), une
              application de recherche ou de récupération de sites Web ou tout
              autre moyen permettant d'extraire, réutiliser ou d'indexer tout ou
              partie du contenu du Service et/ou de notre Plateforme ;
            </li>
            <li>
              Collecter les informations sur les Utilisateurs pour leur envoyer
              des messages non sollicités et/ou les intégrer au sein d'un
              service de référencement ou équivalent, gratuit ou payant ;
            </li>
            <li>
              Copier les contenus pédagogiques présents au sein du Service (à
              l'exception de ses propres contenus) et/ou les éléments
              techniques, graphiques, textuels ou autres constituant le service
              et/ou notre Plateforme sur des supports de toute nature permettant
              de reconstituer tout ou partie des fichiers d'origine.
            </li>
          </ul>
          <p>
            Toute utilisation non expressément autorisée d'éléments du Service
            et/ou de notre Plateforme engage la responsabilité civile et/ou
            pénale de son auteur et sera susceptible d'entraîner des poursuites
            judiciaires à son encontre.
          </p>
          <h3><br /></h3>
          <h3>
            4.2. Propriété intellectuelle afférente aux contenus publiés par
            l'Utilisateur au sein du Service
          </h3>
          <p>
            4.2.1. Dans le cadre de l’utilisation du Service, et quelle que soit
            la licence à laquelle un contenu pédagogique est soumis par
            ailleurs, les Utilisateurs accordent à inSitu une licence mondiale,
            non-exclusive, transférable et pouvant donner lieu à l'octroi d'une
            sous-licence, conférant à inSitu le droit de copier, stocker,
            corriger, adapter et diffuser l'ensemble des contenus (en ce
            compris, les contenus pédagogiques, les expériences de formation en
            situation de travail et toute autre activité réalisée par les
            Utilisateurs au sein du Service) fournis par l’Utilisateur au sein
            du Service. Cette licence est concédée au fur et à mesure de la
            publication des éléments concernés, aux seules fins du bon
            fonctionnement du Service.
          </p>
          <p>
            4.2.2. Les Utilisateurs susmentionnés garantissent qu'ils disposent
            des droits de propriété intellectuelle et des droits de la
            personnalité (et en particulier, le droit à l'image) nécessaires à
            la publication des contenus (en ce compris, les contenus
            pédagogiques et les contenus produits dans le cadre de la complétion
            des expériences en situation de travail) qu'ils publient au sein du
            Service.
          </p>
          <p>
            Ils garantissent également que les contenus qu'ils publient au sein
            du Service ne contiennent rien qui soit contraire aux droits des
            tiers et aux lois en vigueur, et notamment aux dispositions
            relatives à la diffamation, à l'injure, à la vie privée, au droit à
            l'image, à l'atteinte aux bonnes mœurs ou à la contrefaçon.
          </p>
          <p>
            Vous garantissez ainsi inSitu contre tout recours éventuel d'un
            tiers concernant la publication desdits contenus (en ce compris, les
            cours) au sein du Service.
          </p>
          <p><br /></p>
          <h2>Article 5. Signalement - modération a posteriori</h2>
          <p><br /></p>
          <p>
            Tout Utilisateur a la possibilité de signaler à inSitu tout message
            ou commentaire et plus généralement tout contenu publié au sein du
            Service qui serait contraire au Contrat ou autrement illicite, et
            notamment outrageant, injurieux, diffamatoire, abusif, violent,
            obscène ou pornographique, ou comprenant une provocation à la
            discrimination ou à la haine fondée sur la race, la religion, le
            sexe, ou autre, une provocation aux crimes et délits, ou une
            apologie de crime, ou de nature à porter atteinte aux droit de
            propriété intellectuelle ou aux droits de la personnalité des tiers,
            ou encore de nature à altérer le fonctionnement du Service et/ou de
            notre Plateforme, de quelque manière que ce soit.
          </p>
          <p>
            Pour cela, vous devrez contacter inSitu, soit par courriel à
            l'adresse : hello@insituapp.io soit à l'aide du formulaire
            accessible au sein du Service en cliquant sur l'onglet « Nous
            contacter », soit par courrier postal à l'adresse : inSitu / SEITY,
            66 boulevard Notre Dame 13006 MARSEILLE, France, et procéder comme
            suit :
          </p>
          <ul style="list-style-type: disc; margin-left: 1rem">
            <li>Décliner votre identité ;</li>
            <li>
              Décrire le contenu litigieux de manière précise et détaillée ainsi
              que sa localisation sur notre Plateforme ;
            </li>
            <li>
              Décrire les motifs de fait et droit pour lesquels le contenu
              litigieux doit être considéré comme manifestement illicite et, par
              suite, retiré de notre Plateforme ;
            </li>
            <li>
              Adresser la copie de la correspondance que vous aurez
              préalablement envoyée à l'auteur de ce contenu pour en demander la
              modification ou le retrait et/ou la justification que vous n'avez
              pas pu contacter cet auteur.
            </li>
          </ul>
          <p>
            Nous nous réservons le droit de supprimer tout contenu illicite ou
            non conforme au Contrat qui aura été préalablement signalé. Tout
            signalement manifestement abusif pourra être sanctionné par inSitu.
          </p>
          <p>
            Pour plus d'informations sur vos obligations, vous êtes invité à
            prendre connaissance de la « Charte de Bonne Conduite »(article 7).
          </p>
          <p><br /></p>
          <h2>Article 6. Protection des données personnelles</h2>
          <p><br /></p>
          <p>
            Seity recueille et traite les données que les Utilisateurs
            fournissent volontairement afin d’accéder à la Plateforme,
            d’utiliser la Plateforme, ainsi que les données relatives aux
            préférences des Utilisateurs et au trafic, conformément à sa
            politique de confidentialité et à sa politique de cookies.
          </p>
          <p>
            En accord avec cette politique de confidentialité, les informations
            recueillies par Seity font l’objet d’un traitement informatique
            destiné notamment à gérer l’accès au Logiciel, les abonnements, les
            prestations de services et de support, l’élaboration d’offres
            commerciales et marketing, ainsi que pour renforcer et améliorer les
            produits et contenus ainsi que l’expérience Utilisateur.
          </p>
          <p>
            Conformément à la réglementation applicable en matière de protection
            des données personnelles, l’Utilisateur dispose des droits d’accès,
            de rectification, d’effacement, de limitation, de portabilité des
            données et le cas échéant d’opposition au traitement. Il peut
            exercer ces droits en s’adressant à l’adresse&nbsp;rgpd@seity.io,
            sous réserve de justifier de son identité.
          </p>
          <p><br /></p>
          <h2>
            Article 7. Obligations des Utilisateurs et Charte de Bonne Conduite
          </h2>
          <p><br /></p>
          <p>
            Dans le cadre de l'utilisation de la Plateforme, nous vous demandons
            de vous engager à :
          </p>
          <ul style="list-style-type: disc; margin-left: 1rem">
            <li>
              Garantir l'exactitude, l'intégrité et la légalité des informations
              que vous fournissez sur la Plateforme quant à votre identité et
              vos coordonnées ;
            </li>
            <li>Garantir le bon usage de la Plateforme ;</li>
            <li>Ne créer qu'un seul compte au sein de la Plateforme ;</li>
            <li>
              S'abstenir de saisir des informations et/ou messages, commentaires
              et autres contenus malveillants, dénigrants, diffamatoires,
              injurieux, obscènes, pornographiques, violents, à caractère
              raciste, xénophobe, discriminatoire, volontairement trompeurs,
              illicites et/ou contraires à l'ordre public ou aux bonnes mœurs ;
            </li>
            <li>
              Respecter les droits des tiers, et notamment le droit de chacun au
              respect de sa vie privée, de son image et de ses autres droits de
              la personnalité, ainsi que les droits de propriété intellectuelle
              (droit d’auteur, droits voisins, droit sur les bases de données,
              droit des marques, droit des brevets, dessins ou modèles, secret
              de fabrique, etc.) ;
            </li>
            <li>
              Ne pas usurper la qualité, l'attribut ou l'identifiant d'un autre
              Utilisateur ou d'un tiers de nature à induire en erreur ou à créer
              une confusion quelconque quant à son identité, à la provenance des
              informations, contenus (cours, messages, commentaires, etc.) qu'il
              diffuse ou transmet au sein de la Plateforme ;
            </li>
            <li>
              Ne pas altérer ou perturber l'intégrité de la Plateforme et/ou des
              données qui y sont contenues ;
            </li>
            <li>
              Ne pas tenter d'obtenir un accès non autorisé à la Plateforme ou
              aux systèmes ou réseaux qui lui sont associés ou d'intercepter des
              données ;
            </li>
            <li>
              Utiliser la Plateforme dans le respect des législations et
              réglementations nationales et/ou internationales applicables.
            </li>
          </ul>
          <p>
            En cas de manquement à l'une de ces obligations, nous nous réservons
            le droit de suspendre temporairement ou définitivement le compte de
            l’Utilisateur.
          </p>
          <p>
            inSitu est une plateforme basée sur le travail collectif induit par
            la formation professionnelle et le partage dont les espaces de
            discussion sont ouverts à tous les Utilisateurs d’un parcours de
            formation.&nbsp;
          </p>
          <p>
            Les échanges sur inSitu sont modérés a posteriori, c'est-à-dire
            qu'aucun message ne sera relu avant sa publication. Les messages
            sont donc publiés immédiatement, mais sont susceptibles d'être
            contrôlés par l'équipe de modération et les administrateurs de notre
            Plateforme, après publication par l'Utilisateur.
          </p>
          <p>
            Le rôle de l'équipe de modération et des administrateurs est de
            veiller au bon fonctionnement des forums, en écartant tout message
            qui par leur caractère indigne, attentatoire aux personnes,
            destructeur ou manifestement hors sujet, nuisent aux échanges. Les
            modérateurs et les administrateurs excluent également tous messages
            contraires aux lois en vigueur.
          </p>
          <p>Sont ainsi susceptibles de modération (liste non exhaustive) :</p>
          <ul style="list-style-type: disc; margin-left: 1rem">
            <li>
              les messages à caractère racistes, haineux, homophobes, sexistes
              ou diffamatoires
            </li>
            <li>les messages à caractère publicitaires</li>
            <li>
              les messages obscènes, pornographiques ou relevant du harcèlement
            </li>
            <li>
              les messages mentionnant des coordonnées précises comme un numéro
              de téléphone, une adresse postale et dont l'origine et
              l'exactitude sont invérifiables par les modérateurs ou pourraient
              engendrer un préjudice à des personnes
            </li>
            <li>les messages publiés en plusieurs exemplaires</li>
            <li>les messages hors sujet ou incitant à la polémique</li>
            <li>
              les messages en langage abrégé ou dont l'orthographe est trop
              approximative
            </li>
            <li>les messages rédigés entièrement en majuscules</li>
          </ul>
          <p>
            En cas de non-respect de ces règles et consignes, l'équipe d'inSitu
            appliquera certaines sanctions aux Utilisateurs concernés. La
            sanction la plus élevée est le bannissement et consiste à bloquer
            entièrement l'accès à la Plateforme à un Utilisateur.
          </p>
          <p><br /></p>
          <h2>Article 8. Responsabilités</h2>
          <p><br /></p>
          <h3>
            8.1. A l'égard des informations et/ou contenus publiés au sein de la
            Plateforme par les Utilisateurs
          </h3>
          <p>
            Les contenus pédagogiques et les contenus produits à l’occasion des
            formations en situation de travail, les informations et contenus
            (messages, commentaires) publiés par les Utilisateurs au sein de la
            Plateforme ne sont pas contrôlés a priori par inSitu avant leur
            publication au sein de la Plateforme. En revanche, ils seront
            susceptibles d'être contrôlés a posteriori par inSitu.
          </p>
          <p><br /></p>
          <p>
            En notre qualité d'hébergeur de ces informations et/ou contenus
            (hors cours) mis en ligne par l'Utilisateur par l'intermédiaire de
            notre Plateforme, nous sommes soumis au régime de responsabilité
            atténuée prévu aux articles 6.I.2 et suivants de la loi nº2004-575
            du 21 juin 2004 pour la Confiance dans l'Économie Numérique. Nous
            pourrons, dans ce cadre, supprimer les Informations et/ou contenus
            manifestement illicites qui nous seront notifiés.
          </p>
          <p><br /></p>
          <h3>8.2. En cas d'inexécution de nos obligations</h3>
          <p>
            inSitu ne sera responsable que des dommages directs subis par les
            Utilisateurs, dont il sera établi qu'ils résultent de l'inexécution
            de nos obligations.
          </p>
          <p>
            En revanche, nous ne saurions en aucun cas être tenus responsables :
          </p>
          <ul style="list-style-type: disc; margin-left: 1rem">
            <li>
              Des dommages qui résulteraient du fait de l'Utilisateur, de
              difficultés inhérentes au fonctionnement du réseau Internet et
              plus généralement des réseaux de télécommunication, quelle que
              soit leur nature, du fait d'un tiers ou d'un cas de force majeure
              ;
            </li>
            <li>
              Des dommages indirects résultant de l'utilisation de la
              Plateforme, ceux-ci étant définis de façon non limitative comme
              les pertes d'exploitation (chiffre d'affaires, revenus ou
              bénéfices), les pertes d'opportunités, les préjudices d'image ou
              de réputation, préjudice commercial ou économique ;
            </li>
            <li>
              De toute perte de données subie par l'utilisateur, même si elle
              est du fait d’inSitu.
            </li>
          </ul>
          <p><br /></p>
          <h2>
            Article 9. Interruption du service pour maintenance ou amélioration
          </h2>
          <p><br /></p>
          <p>
            Nous nous engageons à mettre tout en œuvre pour assurer le bon
            fonctionnement de la Plateforme et son accessibilité mais nous ne
            sommes tenus qu'à une obligation de moyens concernant la continuité
            de l'accès au Service.&nbsp;
          </p>
          <p>
            Nous ne pouvons garantir la pérennité ou les performances de la
            Plateforme.
          </p>
          <p>
            En cas de mise à jour de la Plateforme pour une évolution technique,
            notre Plateforme sera inaccessible temporairement.
          </p>
          <p>
            L'accès au Service pourra être interrompu pour des raisons notamment
            de maintenance, de mise à jour et en cas d'urgence.
          </p>
          <p>
            Plus généralement, l'interruption du Service quelle que soit la
            cause, la durée ou la fréquence, n'engagera pas notre responsabilité
            et ne donnera droit à aucune indemnité à l'Utilisateur.
          </p>
          <p>
            En conséquence, nous ne pouvons être tenus pour responsables de la
            perte d'argent, ou de réputation, ni des dommages spéciaux,
            indirects ou induits résultant de l'interruption du Service. De
            même, nous ne pourrons être tenus pour responsables d’éventuelles
            dégradations de matériel, logiciel ou données (exemple :
            contamination virale) subies par l'Utilisateur du fait de son
            utilisation du Service.
          </p>
          <p>
            Pour éviter autant que possible des désagréments, vous devez vous
            assurer de la réalisation régulière de sauvegardes concernant vos
            informations, contenus et logiciels.
          </p>
          <p>
            Vous reconnaissez utiliser le Service, mais un "bogue" de notre
            Plateforme peut vous faire perdre certaines de vos données, les
            altérer ou les exposer. Nous nous engageons cependant à tout mettre
            en œuvre pour les restaurer dans la mesure du possible et à vous
            garantir un accès à la Plateforme le plus rapidement possible.
          </p>
          <p><br /></p>
          <h2>Article 10. Inscription et droits sur le compte Utilisateur</h2>
          <p><br /></p>
          <h3>10.1. Par un Utilisateur</h3>
          <p>
            Tous les Utilisateurs de notre Plateforme disposent d'un droit
            d'accès, de modification ou de suppression de leur compte.
          </p>
          <p>
            Si vous avez complété un livret numérique ou validé des compétences
            nous ne pourrons pas faire droit à votre demande de suppression de
            compte. En raison de nos obligations réglementaires liées à la
            nature de notre activité, nous devons conserver votre dossier
            apprenant comprenant votre fiche identité, et l'ensemble de votre
            parcours suivi avec inSitu. Vos données seront archivées tout en
            veillant à une exigence maximale de sécurité en n'étant accessibles
            qu'à un nombre limité de personnes au sein de nos services. Nous
            pourrons, si vous le souhaitez, vous adresser votre dossier
            apprenant.
          </p>
          <p>
            Si vous vous êtes engagés avec nous avec un Abonnement mais que vous
            avez décidé d'arrêter votre Parcours, nous traiterons votre demande
            selon que vous avez décidé d'arrêter définitivement votre Abonnement
            ou de faire une suspension. Dans le premier cas, nous supprimerons
            toutes vos données dans un délai de trente (30) jours, mais dans le
            second cas, nous ne pourrons pas faire droit à votre demande car
            nous devons conserver votre dossier lorsque vous serez diplômé pour
            des contraintes réglementaires liées à la nature de notre activité.
          </p>
          <p>
            Les Utilisateurs peuvent à tout moment modifier et obtenir un droit
            d'accès à leur compte personnel en nous contactant par courriel à
            l'adresse hello@insituapp.io. Si vous souhaitez vous désinscrire de
            notre Plateforme, veuillez nous contacter par courriel à l'adresse
            précitée. Votre demande de suppression de compte entraînera la
            suppression de vos données personnelles de nos bases de données. La
            désinscription entraînera la résiliation du Contrat. Cette
            résiliation prendra effet dans un délai de trente (30) jours ouvrés
            à compter de la réception de la demande de désinscription par
            inSitu.
          </p>
          <p><br /></p>
          <h3>10.2. Par inSitu</h3>
          <p>
            Si une demande d'inscription à nos services est effectuée par un
            mineur sans le consentement préalable de son responsable légal, nous
            pourrons bloquer l'accès au Compte jusqu'à ce que le consentement
            soit obtenu.
          </p>
          <p>
            Lorsque nous remarquons qu'un Utilisateur ne respecte pas les
            présentes CGU, nous prendrons contact avec lui pour l'avertir qu'une
            sanction à son égard peut être prise, notamment la suspension de son
            compte. La Charte de bonne conduite énoncée dans ces CGU doit être
            respectée par chacun pour le bon fonctionnement de la Plateforme et
            la bonne entente entre les personnes.
          </p>
          <p>
            Si aucune solution ne parvient à être trouvée et que les règles de
            bonne conduite ne sont pas respectées, nous serons dans l'obligation
            de désactiver votre compte pour une durée temporaire ou définitive
            et nous prendrons contact avec vous au préalable par courriel.
          </p>
          <p><br /></p>
          <h2>Article 11. Force majeure</h2>
          <p><br /></p>
          <p>
            Aucune des parties ne sera responsable vis-à-vis de l'autre partie
            d'un retard d'exécution ou d'une inexécution en raison de survenance
            d'un événement en dehors du contrôle des parties qui ne pouvait être
            raisonnablement prévu lors de l'acceptation des CGU et dont les
            effets ne peuvent pas être évités par des mesures appropriées.
          </p>
          <p>
            Le cas de force majeure suspend les obligations de la partie
            concernée pendant le temps où jouera la force majeure si cet
            événement est temporaire. Néanmoins, les parties s'efforceront d'en
            minimiser dans toute la mesure du possible les conséquences. A
            défaut, si l'empêchement est définitif, les parties seront libérées
            de leurs obligations dans les conditions prévues aux articles 1351
            et 1351-1 du Code civil.
          </p>
          <p><br /></p>
          <h2>Article 12. Résolution des différends</h2>
          <p><br /></p>
          <p>
            Les CGU sont soumises à la loi française. Toutes difficultés
            relatives à la validité, l'application ou à l'interprétation des CGU
            seront soumises, à défaut d'accord amiable, au Tribunal de Grande
            Instance de Marseille, auquel les Parties attribuent compétence
            territoriale, quel que soit le lieu d'exécution ou le domicile du
            défendeur. Cette attribution de compétence s'applique également en
            cas de procédure en référé, de pluralité de défendeurs ou d'appel en
            garantie.
          </p>
          <!-- CGU ends -->
        </section>
        <section class="modal-card-body" v-else>
          <!-- Policy -->
          <p>
            La présente Politique de protection des données personnelles expose
            la manière dont inSitu traite les données à caractère personnel des
            visiteurs et des utilisateurs (ci- après les « Utilisateurs ») lors
            de leur utilisation de la « Plateforme » : plateforme numérique de
            type site Web et/ou application mobile permettant l'accès au Service
            ainsi que son utilisation.&nbsp;
          </p>
          <br />
          <p>
            La Politique de protection des données personnelles fait partie
            intégrante des Conditions Générales d'Utilisation de la Plateforme.
          </p>
          <br />
          <p>
            inSitu accorde en permanence une attention aux données de ses
            Utilisateurs. Nous pouvons ainsi être amenés à modifier, compléter
            ou mettre à jour périodiquement la Politique de protection des
            données personnelles. Nous pourrons aussi apporter des modifications
            nécessaires afin de respecter les changements de la législation et
            réglementation en vigueur. Vous serez notifié et invité à accepter
            les éventuelles modifications.&nbsp;
          </p>
          <p>
            La dernière version en vigueur reste accessible en permanence sur la
            Plateforme.
          </p>
          <p><br /></p>
          <h1><strong>Données collectées</strong></h1>
          <br /><br />
          <p>
            Nous collectons des données personnelles que vous nous transmettez
            et des données que nous recueillons.
          </p>
          <p>
            Dans le cadre des traitements de données à caractère personnel, nous
            sommes amenés à collecter et à traiter les données suivantes :
          </p>
          <ul style="list-style-type: disc; margin-left: 1rem">
            <li>vos noms et prénoms ;</li>
            <li>vos coordonnées (adresse postale, numéro de téléphone) ;</li>
            <li>vos adresses courriels ;</li>
            <li>
              les coordonnées de votre représentant légal (si vous n’êtes pas
              majeur) ;
            </li>
            <li>
              les données relatives à vos contrats d’apprentissage, et/ou
              contrats professionnels et/ou conventions de stage, et votre
              entreprise ;
            </li>
            <li>votre avatar, genre, votre adresse IP ;</li>
            <li>une mini-biographie, ou une biographie à titre facultatif ;</li>
            <li>une photo peut également être utilisée sur la Plateforme ;</li>
            <li>
              les données relatives aux formations suivies, aux évaluations, aux
              livrets de suivi ou d’apprentissage, aux compétences évaluées ;
            </li>
            <li>
              votre profil yPareo, votre date de naissance et votre adresse
              courriel uniquement si vous vous inscrivez sur la Plateforme grâce
              à votre compte Microsoft Office ou yPareo conformément aux
              conditions générales d'utilisation de ces plateformes ;
            </li>
            <li>les données des échanges via la messagerie d’inSitu ;</li>
            <li>
              les documents nécessaires à la constitution de vos livrets. Ces
              informations justifient des prérequis pour bénéficier des livrets
              numériques ;
            </li>
            <li>
              les sessions de validation de vos expériences en situation de
              travail avec votre tuteur ainsi que les compétences validées pour
              l’obtention des crédits ECTS, aux fins de valider votre formation.
              Ces données à caractère personnel sont conservées dans votre
              dossier apprenant.
            </li>
          </ul>
          <br />
          <p>
            Nous collectons les informations que vous nous fournissez notamment
            quand :
          </p>
          <ul style="list-style-type: disc; margin-left: 1rem">
            <li>vous naviguez sur la Plateforme et applications</li>
            <li>vous créez, modifiez et accédez à votre compte personnel</li>
            <li>vous contactez notre Service Client`</li>
          </ul>
          <p><br /></p>
          <h1><strong>Finalités des traitements</strong></h1>
          <br /><br />
          <p>
            Certains traitements mis en œuvre par inSitu sont nécessaires à
            l’exécution d’un contrat auquel l’Utilisateur est partie ou à
            l’exécution des mesures précontractuelles prises à la demande de
            celui-ci. Il en est ainsi notamment des traitements qui poursuivent
            les finalités suivantes :
          </p>
          <ul style="list-style-type: disc; margin-left: 1rem">
            <li>Gestion des parcours de formation ;</li>
            <li>Gestion administrative des livrets numériques ;</li>
            <li>
              Gestion des relations entre inSitu et les Utilisateurs ayant un
              compte personnel, notamment:
              <ul style="list-style-type: disc; margin-left: 1rem">
                <li>La création d’un compte personnel ;</li>
                <li>
                  L’utilisation de la Plateforme et des services qu’il propose ;
                </li>
                <li>
                  La gestion des communications et du suivi des échanges avec
                  les Utilisateurs.
                </li>
              </ul>
            </li>
          </ul>
          <br />
          <p>
            Certains traitements mis en œuvre par inSitu dès lors que les
            Utilisateurs concernés ont consenti au traitement de données
            personnelles :
          </p>
          <ul style="list-style-type: disc; margin-left: 1rem">
            <li>
              Gestion du consentement des Utilisateurs au dépôt des cookies
              soumis au consentement ;
            </li>
            <li>L’envoi de notifications par courriel ;</li>
          </ul>
          <p>
            Certains traitements mis en œuvre par inSitu sont nécessaires aux
            fins des intérêts légitimes d’inSitu :
          </p>
          <ul style="list-style-type: disc; margin-left: 1rem">
            <li>Gestion des cookies non soumis au consentement ;</li>
            <li>Gestion des demandes d’information et de réclamations ;</li>
            <li>Journalisation des connexions.</li>
          </ul>
          <p><br /></p>
          <h1><strong>Gestion des cookies </strong></h1>
          <br /><br />
          <p>
            Nous utilisons des cookies sur la Plateforme pour les besoins de
            votre navigation, l’optimisation et la personnalisation de nos
            services sur la Plateforme en mémorisant vos préférences.
          </p>
          <p>Vous pouvez à tout moment désactiver les cookies.</p>
          <p>
            <strong><em>Définition de « cookie » et son utilisation</em></strong>. Un « cookie » est un fichier texte
            déposé sur votre ordinateur
            lors de la visite de la Plateforme. Dans votre ordinateur, les
            cookies sont gérés par votre navigateur internet.
          </p>
          <p>
            Nous utilisons des cookies sur la Plateforme pour les besoins de
            votre navigation, l'optimisation et la personnalisation de nos
            services sur la Plateforme en mémorisant vos préférences. Les
            cookies nous permettent aussi de voir comment la Plateforme est
            utilisée. La Plateforme peut ainsi gérer votre identité et votre
            profil au gré des connexions établies entre le serveur et le
            navigateur web.&nbsp;
          </p>
          <p>
            Les informations fournies dans un formulaire peuvent ainsi être
            conservées.
          </p>
          <p><br /></p>
          <h1><strong>Cookies utilisés sur la Plateforme.</strong></h1>
          <br /><br />
          <p>Différents types de cookies sont utilisés sur la Plateforme :</p>
          <ul style="list-style-type: disc; margin-left: 1rem">
            <li>
              Des cookies qui sont strictement nécessaires au fonctionnement de
              la Plateforme (cookies obligatoires). Ils vous permettent
              d'utiliser les principales fonctionnalités de la Plateforme. Sans
              ces cookies, vous ne pourrez pas utiliser la Plateforme
              normalement.
            </li>
            <li>
              Des cookies fonctionnels, parmis lesquels des cookies dits
              "analytiques" : afin d'améliorer nos services, nous utilisons des
              cookies de mesures d'audience telles que le nombre de pages vues,
              le nombre de visites, l'activité des Utilisateurs et leur
              fréquence de retour, notamment grâce aux services de Google
              Analytics. Ces cookies permettent seulement l'établissement
              d'études statistiques sur le trafic des Utilisateurs sur la
              Plateforme, dont les résultats sont totalement anonymes pour nous
              permettre de connaître l'utilisation et les performances de la
              Plateforme et d'en améliorer le fonctionnement. Accepter ces
              cookies permet une utilisation optimale de la Plateforme, si vous
              les refusez, nous ne pouvons vous garantir une utilisation normale
              sur la Plateforme.<br />Cela inclut aussi des cookies qui nous
              permettent de personnaliser votre expérience sur la Plateforme en
              mémorisant vos préférences. Ces cookies peuvent être placés par
              une tierce partie pour notre compte, mais elle n'est pas autorisée
              à les utiliser à d'autres fins que celles décrites.
            </li>
          </ul>
          <h2><br /></h2>
          <h2>
            <strong><em>Types de cookies utilisés</em></strong>.
          </h2>
          <p>&nbsp;Les types de cookies suivants sont utilisés sur ce Site :</p>
          <ul style="list-style-type: disc; margin-left: 1rem">
            <li>
              Cookies "temporaires" : ce type de cookie est actif dans votre
              navigateur jusqu'à ce que vous quittiez la Plateforme et expire si
              vous n'accédez pas à la Plateforme pendant une certaine période
              donnée.
            </li>
            <li>
              Cookies "permanents" ou "traceurs" : ce type de cookie reste dans
              le fichier de cookies de votre navigateur pendant une période plus
              longue, qui dépend des paramètres de votre navigateur web. Les
              cookies permanents sont également appelés cookies traceurs.
            </li>
          </ul>
          <p><br /></p>
          <h2><strong>Gestion des cookies</strong></h2>
          <p>
            Vous pouvez à tout moment désactiver les cookies en sélectionnant
            les paramètres appropriés de votre navigateur pour désactiver les
            cookies (la rubrique d'aide du navigateur utilisé précise la marche
            à suivre). Si vous désactivez les cookies, un cookie de refus sera
            déposé sur votre ordinateur afin qu’inSitu enregistre l’information
            selon laquelle vous vous êtes opposé à l’utilisation de cookies. De
            la même manière, lorsque vous acceptez le recours à des cookies, un
            cookie de consentement est installé sur votre ordinateur.
          </p>
          <p>
            Nous attirons votre attention sur le fait que la désactivation des
            cookies peut réduire ou empêcher l'accessibilité à tout ou partie de
            certaines fonctions.
          </p>
          <p><br /></p>
          <h2><strong>Paramétrage du navigateur</strong></h2>
          <p>
            Vous pouvez configurer votre logiciel de navigation de manière à ce
            que des cookies soient enregistrés dans votre terminal ou qu’ils
            soient rejetés, soit systématiquement, soit selon leur émetteur.
          </p>
          <p>
            Vous pouvez également régulièrement supprimer les cookies de votre
            terminal via votre navigateur.
          </p>
          <p>
            N’oubliez pas cependant de paramétrer l’ensemble des navigateurs de
            vos différents terminaux.
          </p>
          <p>
            Pour la gestion des cookies et de vos choix, la configuration de
            chaque navigateur est différente. Elle est décrite dans le menu
            d’aide de votre navigateur, qui vous permettra de savoir de quelle
            manière modifier vos choix en matière de cookies. A titre d’exemples
            :
          </p>
          <ul style="list-style-type: disc; margin-left: 1rem">
            <li>
              pour Internet Explorer™ :
              <a
                href="https://support.microsoft.com/fr-fr/microsoft-edge/supprimer-les-cookies-dans-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09">https://support.microsoft.com/fr-fr/microsoft-edge/supprimer-les-cookies-dans-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09</a>
              ;
            </li>
            <li>
              pour Safari™ :
              <a
                href="https://support.apple.com/fr-fr/guide/safari/sfri11471/mac">https://support.apple.com/fr-fr/guide/safari/sfri11471/mac</a>
              ;
            </li>
            <li>
              pour Chrome™:
              <a
                href="https://support.google.com/chrome/answer/95647?hl=fr&amp;hlrm=en">https://support.google.com/chrome/answer/95647?hl=fr&amp;hlrm=en</a>
              ;
            </li>
            <li>
              pour Firefox™ :
              <a
                href="https://support.mozilla.org/fr/kb/protection-renforcee-contre-pistage-firefox-ordinateur?redirectlocale=fr&amp;redirectslug=Activer+et+d%2525C3%2525A9sactiver+les+cookies">https://support.mozilla.org/fr/kb/protection-renforcee-contre-pistage-firefox-ordinateur?redirectlocale=fr&amp;redirectslug=Activer+et+d%C3%A9sactiver+les+cookies</a>
              ;
            </li>
            <li>
              pour Opera™ :
              <a
                href="https://help.opera.com/en/latest/web-preferences/%2523cookies">https://help.opera.com/en/latest/web-preferences/#cookies</a>.
            </li>
          </ul>
          <p>
            Pour en savoir plus sur la manière de paramétrer vos logiciels de
            navigation, nous vous invitons à consulter la Plateforme de la
            Commission nationale de l’informatique et des libertés :
            <a
              href="https://www.cnil.fr/fr/cookies-les-outils-pour-les-maitriser">https://www.cnil.fr/fr/cookies-les-outils-pour-les-maitriser</a>
          </p>
          <h2><strong>Accès à vos données</strong></h2>
          <p>
            Seules les personnes habilitées, comme les responsables de
            traitement et les responsables techniques &nbsp;ont accès à vos
            données.
          </p>
          <h2>
            <strong>Vos données sont-elles partagées avec des tiers ? </strong>
          </h2>
          <p>
            Nous encadrons juridiquement les transferts par des engagements
            contractuels avec nos partenaires pour vous apporter un niveau élevé
            de sécurité.
          </p>
          <p>
            inSitu ne vend ni ne loue vos données personnelles à des tiers à des
            fins de marketing, en aucun cas.
          </p>
          <p>
            Nous travaillons également en étroite collaboration avec des
            entreprises tierces qui peuvent avoir accès à vos données
            personnelles, en particulier :
          </p>
          <ul style="list-style-type: disc; margin-left: 1rem">
            <li>
              Lorsqu’il s’agit de votre centre de formation ou de votre
              entreprise ;
            </li>
            <li>
              Lorsque vous en faites la demande expresse (par exemple lorsque
              vous utilisez les méthodes d'authentification fournies par des
              plateformes tierces vous permettant d'intégrer dans votre profil
              inSitu des informations issues de votre compte sur leur propre
              Site) ;
            </li>
            <li>
              Lorsque nous avons l'obligation légale de le faire ou si nous
              pensons de bonne foi que cela est nécessaire pour (i) répondre à
              toute réclamation à l'encontre d'inSitu, (ii) se conformer à toute
              demande judiciaire, (iii) faire exécuter tout contrat conclu avec
              nos membres, tel que les Conditions Générales d'Utilisation et la
              présente Politique de protection des données personnelles (iv) en
              cas d'urgence mettant en jeu la santé publique ou l'intégrité
              physique d'une personne, (v) dans le cadre d'enquêtes et
              d'investigations ou (vi) afin de garantir les droits, les biens et
              la sécurité d'inSitu, ses membres et plus généralement tout tiers
              ;
            </li>
            <li>
              En outre, inSitu ne divulgue pas vos données personnelles à des
              tiers, excepté si (1) vous (ou votre administrateur de compte
              agissant en votre nom) en formulez la demande ou autorisez la
              divulgation ; (2) la divulgation est requise pour traiter des
              transactions ou fournir des services que vous avez demandés ; (3)
              inSitu y est contrainte par une autorité gouvernementale ou un
              organisme de réglementation, en cas de réquisition judiciaire, de
              citation à comparaître ou de toute autre exigence gouvernementale
              ou judiciaire similaire, ou pour établir ou défendre une demande
              légale ; ou (4) le tiers agit en tant qu'agent ou de sous-traitant
              d'inSitu dans l'exécution des services (par exemple, inSitu
              utilise les services d'une société de télécommunications).
            </li>
          </ul>
          <p>Nous conservons vos données dans l'Union Européenne.</p>
          <p><br /></p>
          <h1><strong>Protection des données</strong></h1>
          <br /><br />
          <p>
            Nous prenons toutes les dispositions nécessaires pour que vos
            données soient protégées. Nous vous demandons également de veiller à
            la confidentialité des données.
          </p>
          <p>
            inSitu applique les mesures de sécurité technologiques et
            organisationnelles généralement reconnues afin que les données à
            caractère personnel recueillies ne soient, ni perdues, ni
            détournées, ni consultées, ni modifiées ni divulguées par des tiers
            non autorisés sauf si la communication de ces données est imposée
            par la réglementation en vigueur, notamment à la requête d'une
            autorité judiciaire, de police, de gendarmerie ou de toute autre
            autorité habilitée par la loi.
          </p>
          <p><br /></p>
          <p>
            La sécurité des données personnelles dépend également des
            Utilisateurs. Les Utilisateurs qui sont membres d'inSitu s'engagent
            à conserver la confidentialité de leur identifiant et de leur mot de
            passe. Les membres s'engagent également à ne pas partager leur
            compte et à déclarer à inSitu toute utilisation non autorisée dudit
            compte dès lors qu'ils en ont connaissance.
          </p>
          <p><br /></p>
          <h1>
            <strong>Quelle est la durée de conservation des données personnelles des
              Utilisateurs d'inSitu ?</strong>
          </h1>
          <br /><br />
          <p>
            Vos données sont conservées pour une durée limitée afin de vous
            assurer une sécurité optimale.
          </p>
          <p>
            Les données à caractère personnel communiquées par les Utilisateurs
            d'inSitu seront supprimées à l'issue d'une certaine période et selon
            les données traitées.
          </p>
          <p>
            Afin de permettre aux membres de récupérer leur compte en cas
            d'oubli de mot de passe après une période prolongée sans connexion à
            la Plateforme, votre e-mail est conservé par sécurité. Il est
            néanmoins possible de le supprimer en modifiant les informations de
            son profil sur la section « Mon compte » de la Plateforme.
          </p>
          <p>
            Nous conservons vos données uniquement le temps nécessaire pour les
            finalités poursuivies, conformément aux prescriptions légales, et
            notamment :
          </p>
          <p><br /></p>
          <table cellpadding="0" cellspacing="0">
            <tbody>
              <tr>
                <td valign="middle">
                  <p><strong>Finalité</strong></p>
                </td>
                <td valign="middle">
                  <p><strong>Durée de conservation</strong></p>
                </td>
              </tr>
              <tr>
                <td valign="middle">
                  <p>Gestion des parcours de formation</p>
                </td>
                <td valign="middle">
                  <p>
                    2 ans à compter de la clôture des formations auxquelles vous
                    avez participé&nbsp;
                  </p>
                </td>
              </tr>
              <tr>
                <td valign="middle">
                  <p>
                    Gestion de la facturation et analyse des sessions suivies
                    par les utilisateurs dans le cadre de la facturation
                  </p>
                </td>
                <td valign="middle">
                  <p>10 ans à compter de la clôture de l’exercice en cours</p>
                </td>
              </tr>
              <tr>
                <td valign="middle">
                  <p>Gestion administrative des livrets</p>
                </td>
                <td valign="middle">
                  <p>5 ans après le départ de l’apprenant</p>
                </td>
              </tr>
              <tr>
                <td valign="middle">
                  <p>
                    Gestion des relations entre inSitu et les Utilisateurs ayant
                    un compte personnel
                  </p>
                </td>
                <td valign="middle">
                  <p>
                    Durée de la relation contractuelle 2 ans à compter de la
                    suspension dans l’hypothèse où le compte de l’Utilisateur a
                    été suspendu ou bloqué afin d’éviter tout contournement des
                    règles en vigueur sur la Plateforme
                  </p>
                </td>
              </tr>
              <tr>
                <td valign="middle">
                  <p>
                    Gestion des validations des compétences et évaluations&nbsp;
                  </p>
                </td>
                <td valign="middle">
                  <p>3 ans</p>
                </td>
              </tr>
              <tr>
                <td valign="middle">
                  <p>Gestion des demandes d’exercice de droits</p>
                </td>
                <td valign="middle">
                  <p>5 ans</p>
                </td>
              </tr>
              <tr>
                <td valign="middle">
                  <p>
                    Prospection commerciale (promotion des formations et des
                    parcours, messages personnalisés) et réalisation d’enquêtes
                    (notamment enquêtes de motivation et de satisfaction)
                  </p>
                </td>
                <td valign="middle">
                  <p>
                    3 ans à compter du dernier contact ou jusqu’au retrait du
                    consentement
                  </p>
                </td>
              </tr>
              <tr>
                <td valign="middle">
                  <p>Gestion des cookies soumis au consentement</p>
                </td>
                <td valign="middle">
                  <p>13 mois ou jusqu’au retrait du consentement</p>
                </td>
              </tr>
              <tr>
                <td valign="middle">
                  <p>Gestion des cookies non soumis au consentement</p>
                </td>
                <td valign="middle">
                  <p>13 mois</p>
                </td>
              </tr>
              <tr>
                <td valign="middle">
                  <p>Gestion des demandes d’informations et de réclamations</p>
                </td>
                <td valign="middle">
                  <p>Durée de la relation contractuelle</p>
                </td>
              </tr>
              <tr>
                <td valign="middle">
                  <p>
                    Réalisation de statistiques et analyse des retours sur les
                    formations de la part des Utilisateurs
                  </p>
                </td>
                <td valign="middle">
                  <p>2 ans après la dernière activité du compte</p>
                </td>
              </tr>
              <tr>
                <td valign="middle">
                  <p>Journalisation des connexions sur la Plateforme</p>
                </td>
                <td valign="middle">
                  <p>1 an</p>
                </td>
              </tr>
              <tr>
                <td valign="middle">
                  <p>Données des espaces de discussion</p>
                </td>
                <td valign="middle">
                  <p>12 mois</p>
                </td>
              </tr>
            </tbody>
          </table>
          <p><br /></p>
          <h2>
            <strong>Que se passe-t-il en cas d'utilisation des services inSitu en
              relation avec des plateformes tierces (Mooc, LMS, etc.)</strong>
          </h2>
          <p>
            Nous ne sommes pas responsables de l'utilisation des données faite
            par les plateformes tierces. Vous pouvez les paramétrer sur leurs
            sites.
          </p>
          <p>
            Lorsque vous utilisez des plateformes tierces et des services ou
            applications d'inSitu en relation avec des plateformes tierces, cela
            est susceptible d'entraîner une collecte et un échange de certaines
            données entre les plateformes tierces et inSitu.
          </p>
          <p>
            Nous ne sommes pas responsables de l'utilisation qui est faite de
            vos données par les plateformes tierces pour leur propre compte.
            Vous avez la possibilité de paramétrer et contrôler directement sur
            les plateformes tierces l'accès et la confidentialité de vos
            données.
          </p>
          <p><br /></p>
          <h1><strong>Quels sont vos droits ?</strong></h1>
          <br /><br />
          <p>
            En application de la réglementation européenne en vigueur, nous
            avons mis en place des procédures et mécanismes vous permettant
            d’exercer vos droits.
          </p>
          <p>
            Conformément à la réglementation en vigueur les Utilisateurs de la
            Plateforme disposent des droits suivants :
          </p>
          <ul style="list-style-type: disc; margin-left: 1rem">
            <li>
              Le droit d’accéder aux données qu’inSitu détient à leur sujet ;
            </li>
            <li>
              Le droit d'obtenir d’inSitu, la rectification des données à
              caractère personnel les concernant qui sont inexactes ou
              incomplètes ;
            </li>
            <li>
              Le droit d'obtenir d’inSitu l'effacement des données à caractère
              personnel les concernant :
              <ul style="list-style-type: disc; margin-left: 1rem">
                <li>
                  lorsqu'elles ne sont plus nécessaires au regard des finalités
                  pour lesquelles elles ont été collectées ou traitées ;
                </li>
                <li>
                  lorsque l’Utilisateur concerné retire son consentement pour
                  les traitements ayant pour base légale le consentement ou
                  s’oppose au traitement.
                </li>
              </ul>
            </li>
            <li>
              Le droit d'obtenir d’inSitu la limitation du traitement en cas de
              contestation de l’exactitude des données à caractère personnel.
              Lorsque inSitu n'a plus besoin des données à caractère personnel
              aux fins du traitement mais que celles-ci sont encore nécessaires
              à l’Utilisateur concerné pour la constatation, l'exercice ou la
              défense de ses droits en justice ;
            </li>
            <li>
              Le droit à la portabilité des données que les Utilisateurs auront
              fournies, lorsque ces données font l'objet de traitements
              automatisés fondés sur leur consentement ou un contrat ;
            </li>
            <li>
              Le droit de s’opposer, à tout moment, pour des raisons tenant à la
              situation particulière des Utilisateurs, aux traitement des
              données personnelles dont la base juridique du traitement est
              l’intérêt légitime ;
            </li>
            <li>
              Le droit de définir des directives particulières relatives à la
              conservation, à l’effacement et à la communication des données des
              Utilisateurs après leurs décès auprès des services d’inSitu selon
              les modalités ci-après définies. Ces directives particulières ne
              concernent que les traitements mis en œuvre par inSitu et sont
              limitées à ce seul périmètre.
            </li>
          </ul>
          <p>
            Si vous souhaitez exercer vos droits, vous pouvez envoyer un
            courriel à l'adresse rgpd@inSituapp.io ou adresser un courrier à
            l'adresse suivante : Seity - Données personnelles - 66 boulevard
            Notre Dame 13006 Marseille France.
          </p>
          <p>
            Enfin les Utilisateurs d'inSitu peuvent déposer une réclamation
            auprès des autorités de contrôle, et notamment de la
            <a href="https://www.cnil.fr/fr/plaintes">CNIL</a>)
          </p>
          <p>
            Vos requêtes seront traitées sous un mois. En cas de doute
            raisonnable quant à votre identité nous pouvons demander que nous
            soient fournies des informations supplémentaires nécessaires pour
            confirmer votre identité.
          </p>
          <p><br /></p>
          <h1>
            <strong>Comment nous contacter – coordonnées du responsable des
              traitements</strong>
          </h1>
          <br /><br />
          <p>
            Si vous avez des questions ou des réclamations ou si vous souhaitez
            faire part à inSitu de recommandations ou des commentaires visant à
            améliorer notre Politique de protection des données personnelles
            vous pouvez envoyer un courriel à l’adresse rgpd@inSituapp.io ou
            adresser un courrier à l'adresse suivante : inSitu - Données
            personnelles - 66 boulevard Notre Dame 13006 Marseille France.
          </p>
          <!-- Policy ends -->
        </section>
        <footer class="modal-card-foot">
          <b-button label="Refuser" @click="kickOut" />
          <b-button v-if="termsAgreementStep == 1" label="Lire la politique de protection des données personnelles"
            @click="goStepTwo" />
          <b-button v-if="termsAgreementStep == 2" label="Accepter" type="is-primary" @click="acceptTerms" />
        </footer>
      </div>
    </b-modal>
    <div class="sidebar-page">
      <div class="btn-sidebar" @click="openNavbar">
        <img src="@/assets/img/elements/expand.svg" alt="Déplier le menu" />
      </div>
      <div class="btn-menu-mobile">
        <AppAccountMenu />
        <div class="btn-menu ml-4 mr-2" @click="openNavbar" style="padding-top: 2px">
          <span class="navbar-burger burger"><span></span><span></span><span></span></span>
        </div>
      </div>
      <section class="sidebar-layout">
        <b-sidebar position="fixed" v-bind:mobile="mobile" v-bind:expand-on-hover="expandOnHover"
          v-bind:reduce="this.$store.state.isOpen" type="is-light" fullheight :can-cancel="false" open>
          <router-link to="/" class="has-text-centered"><img src="@/assets/img/elements/logo.svg" alt="inSitu"
              width="80%" /></router-link>
          <div class="p-1 mt-auto mb-auto menu-main">
            <div class="block"></div>
            <b-menu class="is-custom-mobile" v-if="currentUser">
              <router-link @click.native="closeAtClick" to="/">
                <b-menu-list>
                  <b-menu-item icon="home" label="accueil"> </b-menu-item>
                </b-menu-list>
              </router-link>
              <router-link id="maquettes" to="/maquettes" v-if="
                currentUser.role === 'coordinateur' ||
                currentUser.role === 'superviseur'
              " @click.native="closeAtClick">
                <b-menu-list>
                  <b-menu-item label="maquettes" icon="framework"></b-menu-item>
                </b-menu-list>
              </router-link>
              <!-- eslint-disable max-len-->
              <router-link id="trainings"
                :to="`/formation-apprenant/${trainingId}/${studentId}/${studenttraining}/parcours`"
                @click.native="closeAtClick" v-if="oneTraining">
                <b-menu-list>
                  <b-menu-item label="mon parcours" icon="check" v-if="currentUser.role === 'apprenant'"></b-menu-item>
                  <b-menu-item label="parcours" icon="check" v-if="currentUser.role === 'tuteur'"></b-menu-item>
                </b-menu-list>
              </router-link>
              <router-link id="trainings" to="/formations" @click.native="closeAtClick" v-if="
                !oneTraining ||
                (oneTraining && currentUser.role === 'coordinateur') ||
                (oneTraining && currentUser.role === 'formateur') ||
                (oneTraining && currentUser.role === 'superviseur')
              ">
                <b-menu-list>
                  <b-menu-item label="formations" icon="training"></b-menu-item>
                </b-menu-list>
              </router-link>
              <router-link id="calendar" to="/calendrier" v-if="
                currentUser.role === 'formateur' && user.edit_rights === 4
              " @click.native="closeAtClick">
                <b-menu-list>
                  <b-menu-item label="calendrier" icon="calendar"></b-menu-item>
                </b-menu-list>
              </router-link>
              <router-link id="users" to="/utilisateurs" v-if="
                currentUser.role === 'formateur' ||
                currentUser.role === 'coordinateur' ||
                currentUser.role === 'superviseur' ||
                currentUser.role === 'manager'
              " @click.native="closeAtClick">
                <b-menu-list>
                  <b-menu-item label="utilisateurs" icon="user"></b-menu-item>
                </b-menu-list>
              </router-link>
              <router-link to="/mes-notifications" @click.native="closeAtClick">
                <b-menu-list>
                  <b-menu-item label="notifications" icon="bell"></b-menu-item>
                </b-menu-list>
              </router-link>
              <router-link to="/aide" @click.native="closeAtClick">
                <b-menu-list>
                  <b-menu-item label="support" icon="question"></b-menu-item>
                </b-menu-list>
              </router-link>
            </b-menu>
          </div>
          <div class="sidebar-footer">
            <div class="wiki" v-if="displayWiki">
              <div v-if="currentUser.role === 'coordinateur'" class="mt-auto">
                <div class="documentation primary has-text-left pl-5 ml-1 mb-3">
                  <span>Documentation</span>
                </div>
                <router-link to="/wiki/sequences" @click.native="closeAtClick" class="wikilink">
                  <b-menu-list>
                    <b-menu-item label="expériences" icon="check" to="/wiki/sequences"></b-menu-item>
                  </b-menu-list>
                </router-link>
                <router-link to="/wiki/roles" @click.native="closeAtClick" class="wikilink">
                  <b-menu-list>
                    <b-menu-item label="rôles" icon="user" to="/wiki/roles"></b-menu-item>
                  </b-menu-list>
                </router-link>
                <router-link to="/wiki/faq" @click.native="closeAtClick" class="wikilink">
                  <b-menu-list>
                    <b-menu-item label="FAQ" icon="help" to="/wiki/faq"></b-menu-item> </b-menu-list></router-link>
              </div>
            </div>
            <div class="has-text-centered mb-5 mt-4" v-if="currentUser.cfa_logo">
              <div class="w-100 is-flex">
                <figure class="image is-128x128 is-inline-block mx-auto px-2">
                  <img :src="currentUser.cfa_logo" />
                </figure>
              </div>
            </div>
            <div>
              Seity, <br />
              tous droits réservés <br />{{ new Date().getFullYear() }} <br /><a href="/conditions-utilisation">CGU</a>
              | <a href="/politique-de-confidentialite">Confidentialité</a>
            </div>
          </div>
        </b-sidebar>
      </section>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import AppAccountMenu from '@/components/AppAccountMenu.vue';
import Cookies from 'js-cookie';
import store from '@/store';

export default {
  components: {
    AppAccountMenu,
  },
  data() {
    return {
      oneTraining: '',
      expandOnHover: false,
      mobile: 'reduce',
      reduce: false,
      window: {
        width: 0,
        height: 0,
      },
      studenttraining: '',
      userAccount: '',
      studentId: '',
      trainingId: '',
      displayTermsAgreement: false,
      termsAgreementStep: 1,
      displayWiki: false,
    };
  },
  computed: {
    ...mapState('auth', ['currentUser']),
    ...mapState(['user']),
    oneTrue() {
      return this.$route.path.includes('apprenant')
        && this.oneTraining === true;
    },
    user() {
      return this.$store.state.user;
    },
  },
  methods: {
    openNavbar() {
      this.$store.commit('OPEN_SIDEBAR', this.$store.state.isOpen);
      const el = document.body;
      el.classList.toggle('open');
    },
    ...mapActions('auth', ['logout']),
    sayGoodbye() {
      this.$buefy.dialog.confirm({
        title: 'À bientôt !',
        message: 'Vous êtes sur le point de fermer votre session. vous serez redirigé.e sur la page d\'authentification.',
        confirmText: 'Me déconnecter',
        cancelText: 'Annuler',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.logout();
          this.$router
            .push({
              name: 'Login',
            });
        },
      });
    },
    goStepTwo() {
      this.termsAgreementStep = 2;
    },
    kickOut() {
      this.displayTermsAgreement = false;
      this.sayGoodbye();
    },
    acceptTerms() {
      this.$http
        .post('set_cgu', {
          cgu: 1,
        }).then(() => {
          this.displayTermsAgreement = false;
        }).then(() => {
          const response = this.$http.get('/whoami/', {
            Authorization: `Bearer ${Cookies.get('token')}`,
          });
          // storing infos
          store.commit('auth/SET_CURRENT_USER', response.data);
          this.$router.go();
        }).then(() => {
          this.$buefy.toast.open({ message: 'Merci pour votre accord !', type: 'is-success' });
        })
        .catch((error) => {
          window.console.log(error);
        });
    },
    closeAtClick() {
      if (this.window.width <= 990) {
        this.openNavbar();
      }
    },
    goto(page) {
      this.$router.push({
        path: `/${page}`,
      });
      this.closeAtClick();
    },
    screen() {
      this.width = window.screen.width;
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    initUser() {
      this.$http
        .get(`/profile/${this.currentUser.id}/?format=json`)
        .then((response) => {
          // this.user = response.data;
          this.$store.commit('GET_USER', response.data);
          if (this.currentUser.role === 'formateur') {
            if (this.user.related_trainings_as_teacher.length === 1) {
              this.oneTraining = true;
            }
          } else if (this.currentUser.role === 'apprenant') {
            if (this.user.related_trainings_as_student.length === 1) {
              this.oneTraining = true;
              this.studentId = this.currentUser.id;
              this.trainingId = this.user.related_trainings_as_student;
              this.$http
                .get(`/agenda/student_training/?training=${this.user.related_trainings_as_student}&student=${this.currentUser.id}&query={id}&format=json`)
                .then((resp) => {
                  this.studenttraining = resp.data[0].id;
                  this.changeExactLink();
                });
            }
          } else if (this.currentUser.role === 'tuteur') {
            if (this.user.related_trainings_as_tutor.length === 1) {
              this.oneTraining = true;
              this.trainingId = this.user.related_trainings_as_tutor;
              /* eslint-disable */
              this.studenttraining = this.user.related_student_trainings_as_tutor[0];
              /* eslint-enable */
              this.searchStudent(this.studenttraining);
              this.changeExactLink();
            }
          } else {
            this.oneTraining = false;
          }
        });
    },
    searchStudent(studentTraining) {
      this.$http
        // .get(`/agenda/student_training_detail/?training_id=${
        // this.user.related_trainings_as_student}&student_id=${
        // this.currentUser.id}&all=0&format=json`)
        .get(`/agenda/student_training/${studentTraining}/?query={student}&format=json`)
        .then((response) => {
          this.studentId = response.data.student;
        });
    },
    changeExactLink() {
      // if (this.$route.path.includes('apprenant') && this.oneTraining === true) {
      //   const tr = document.getElementById('trainings');
      //   tr.classList.remove('router-link-exact-active');
      // }
    },
  },
  created() {
    this.handleResize();
    this.initUser();
    if (this.window.width <= 990) {
      this.$store.state.isOpen = true;
      this.openNavbar();
      const el = document.body;
      el.classList.remove('open');
    }
  },
  mounted() {
    if (this.$route.path.includes('maquette')) {
      const el = document.getElementById('maquettes');
      el.classList.add('router-link-exact-active');
    } else if (this.$route.path.includes('formation')) {
      const el = document.getElementById('trainings');
      el.classList.add('router-link-exact-active');
    } else if (this.$route.path.includes('utilisateur')) {
      const el = document.getElementById('users');
      el.classList.add('router-link-exact-active');
    }
    if (this.currentUser.cgu === false) {
      this.displayTermsAgreement = true;
    }
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>
<style scoped>
.documentation {
  color: #531be4;
  margin-top: 2rem;
}

.wiki {
  font-size: 1rem;
  padding-bottom: 1rem;
}

h1 {
  font-size: 1.5rem;
  font-weight: bold;
}

h2 {
  font-size: 1.1rem;
  font-weight: bold;
}
</style>
