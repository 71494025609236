import axios from 'axios';
import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';

const baseURL = process.env.VUE_APP_API_BASE_URL; // Use the correct environment variable

const client = axios.create({
  baseURL,
  headers: { 'X-Requested-With': 'XMLHttpRequest' },
  timeout: 30000,
});

// return a promise that wraps the token cookie setter
function setAccessTokenCookie(value) {
  return new Promise((resolve) => {
    Cookies.set('token', value, {
      expires: new Date(jwtDecode(value).exp * 1000),
      // domain: `.${process.env.PLATFORM_NAME}`
    });
    resolve();
  });
}

client.interceptors.request.use(
  (config) => {
    const newConfig = config;
    const accessToken = Cookies.get('token');
    if (accessToken) {
      newConfig.headers.common.Authorization = `Bearer ${accessToken}`;
    }
    return newConfig;
  },
  (error) => Promise.reject(error),
);

client.interceptors.response.use(
  (response) => {
    if (response.data && Object.prototype.hasOwnProperty.call(response.data, 'token')) {
      setAccessTokenCookie(response.data.token).then(() => response);
    }
    return response;
  },
  (error) => Promise.reject(error),
);

export default client;
