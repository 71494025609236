<template>
  <div class="modalbox modalStep">
    <div class="is-flex bigmodal">
      <!-- <b-icon icon="close" type="is-primary" @click="$emit('close')"
          >retour</b-icon
        > -->
      <router-link
        class="mr-auto btn-previous"
        v-if="$route.params.idActivity != 's'"
        :to="
          '/activite-detail/' +
          $route.params.idTraining +
          '/' +
          $route.params.idSequence +
          '/' +
          $route.params.idStudentTraining +
          '/' +
          $route.params.idActivity
        "
      >
        <img :src="require('@/assets/img/arrows/previous.svg')"
      /></router-link>
      <router-link
        class="mr-auto btn-previous"
        v-else
        :to="
          '/sequence-edition/' +
          $route.params.idTraining +
          '/' +
          $route.params.idSequence +
          '/' +
          $route.params.idStudentTraining
        "
      >
        <img :src="require('@/assets/img/arrows/previous.svg')"
      /></router-link>
    </div>
    <section class="w-100">
      <b-carousel
        v-model="carousel"
        :animated="animated"
        :has-drag="drag"
        :autoplay="autoPlay"
        :pause-hover="pauseHover"
        :pause-info="pauseInfo"
        :pause-info-type="pauseType"
        :interval="interval"
        :repeat="repeat"
        :arrow-hover="false"
        :indicator="false"
      >
        <b-carousel-item>
          <section class="hero is-medium is-bold">
            <div class="">
              <div class="w-100 has-text-centered"></div>
              <div class="bloc-title">
                <div class="title-paragraph">
                  <div class="stepsCount">1/2</div>
                  Ajoutez votre progression
                </div>

                <div class="subtitle-paragraph mb-4">
                  Vous avez refait la même situation de travail ou une situation
                  similaire. Vous pouvez préciser ici ce que vous avez appris ou
                  compris de nouveau, décrire ce que vous avez fait, prendre des
                  photos ou des notes.
                </div>
              </div>
              <div class="w-100 container-editor">
                <div
                  class="
                    has-text-primary
                    columns
                    is-vcentered is-multiline
                    align-items-center
                    mx-0
                    mt-5
                  "
                >
                  <div class="is-flex align-items-center mb-2">
                    <b class="mr-3" v-if="newItem"
                      >L'activité a eu lieu aujourd'hui ?</b
                    >
                    <b v-else class="mr-1">L'activité a eu lieu </b>
                    <!-- <b-button
                      type="is-rounded mr-2"
                      @click="editDates = !editDates"
                      ><span v-if="formRevision.start_date < today">non</span
                      ><span v-else>oui</span></b-button
                    > -->

                    <b-switch
                      v-if="newItem"
                      v-model="activityToday"
                      true-value="oui"
                      false-value="non"
                      native-value="oui"
                      @input="changeDate"
                      >{{ activityToday }}
                    </b-switch>
                  </div>
                  <!-- v-if="formRevision.start_date < today" -->
                  <div
                    class="is-half-desktop is-flex align-items-center mb-2"
                    v-if="activityToday !== 'oui'"
                  >
                    du {{ formRevision.start_date | day }} au
                    {{ formRevision.end_date | day }}
                    <b-button
                      v-if="!editDates"
                      type="is-rounded mr-2 ml-2"
                      size="is-small"
                      @click="editDates = !editDates"
                      ><span>modifier</span></b-button
                    >
                  </div>
                </div>
                <div v-show="editDates">
                  <div class="mb-4">
                    <b-button
                      type="is-rounded mr-2"
                      @click="editDates = !editDates"
                      >Refermer</b-button
                    >
                  </div>
                  <div class="columns is-tablet is-vcentered mt-2">
                    <div class="column is-narrow">
                      <b-field label="Date de début">
                        <b-datetimepicker
                          v-model="formRevision.start_date"
                          :locale="locale"
                          :datepicker="{
                            firstDayOfWeek: 1,
                            showWeekNumber: true,
                          }"
                          icon="calendar-today"
                          trap-focus
                          inline
                          @input="verifToday"
                        >
                        </b-datetimepicker>
                      </b-field>
                    </div>
                    <div class="column is-narrow">
                      <b-field label="Date de fin">
                        <b-datetimepicker
                          v-model="formRevision.end_date"
                          :show-week-number="showWeekNumber"
                          :locale="locale"
                          icon="calendar-today"
                          trap-focus
                          inline
                          :datepicker="{
                            firstDayOfWeek: 1,
                            showWeekNumber: true,
                          }"
                          @input="verifToday"
                        >
                        </b-datetimepicker>
                      </b-field>
                    </div>
                  </div>
                </div>
                <div class="w-100">
                  <ModalImage
                    v-if="isCardModalActive"
                    :fileImage.sync="imageUploaded"
                    :imageResized.sync="imageResized"
                    @close="closeModal"
                    @loaded="addImageinSteps(imageResized)"
                  />
                  <b-field label="Nom">
                    <b-input
                      v-model="formRevision.title"
                      placeholder="précisez un titre"
                    ></b-input>
                  </b-field>
                  <div class="imgBottom fade">
                    <b-upload
                      v-model="file"
                      class="file-label"
                      @input="uploadImageModal(i)"
                    >
                      <span class="file-cta">
                        <b-icon class="file-icon" icon="photo"></b-icon>
                      </span>
                    </b-upload>
                  </div>
                  <div class="is-flex">
                    <b-field
                      class="file is-primary mb-1 addImage"
                      :class="{ 'has-name': !!file }"
                    >
                      <b-upload
                        v-model="file"
                        class="file-label"
                        @input="uploadImageModal"
                      >
                        <span class="file-cta">
                          <b-icon class="file-icon" icon="photo"></b-icon>
                          <span class="file-label">Ajouter une image</span>
                        </span>
                      </b-upload>
                    </b-field>
                  </div>
                  <div class="ck-editor-block">
                    <ckeditor
                      v-model="formRevision.entry"
                      :editor="editor"
                      :config="editorConfig"
                      class="mb-5"
                      :ref="'revisionContent'"
                    ></ckeditor>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </b-carousel-item>
        <b-carousel-item v-if="this.$route.params.idRevision !== 'new'">
          <section class="hero is-medium is-bold">
            <div class="bloc-title">
              <div class="title-paragraph">
                <div class="stepsCount">2/2</div>
                Avez-vous acquis de nouvelles compétences ?
              </div>
            </div>
            <div class="w-100 container-editor">
              <div class="subtitle-paragraph mb-4">
                Vous pouvez indiquer ici quelles compétences vous avez
                travaillé, si vous avez progressé, gagné en autonomie.
              </div>

              <SkillEvaluationSelect type="progression" />
            </div></section
        ></b-carousel-item>
      </b-carousel>
    </section>
    <div class="is-flex is-flex-wrap-wrap activityBtns align-items-center">
      <div class="w-100 is-flex">
        <b-button
          v-if="$route.params.idRevision === 'new'"
          @click="postRevision"
          class="mx-auto btn-action"
          type="is-primary is-rounded"
          rounded
          :disabled="conditions === false"
          >créer</b-button
        >
        <b-button
          v-else
          class="mx-auto btn-action"
          type="is-primary is-rounded"
          rounded
          @click="patchRevision"
          >enregistrer</b-button
        >
      </div>
      <div class="help-btn">
        <AppHelpMessage
          title="Vous refaites le même type de situation de travail"
          message="Ajoutez ici vos notes quand vous refaites une activité
           et que vous avez appris de nouvelles choses. Vous pouvez créer autant de 'progressions'
            que vous le souhaitez. <br><br>N'hésitez pas dans
               l'étape 2 à désigner quelles compétences vous avez travaillé
                dans cette activité et comment vous avez progressé."
          textBtn="Besoin d'aide ?"
        />
      </div>
    </div>
    <b-loading :is-full-page="isFullPage" v-model="isLoading"> </b-loading>

    <div class="container contentSequence pb-5 mb-5"></div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import moment from 'moment';
import localization from 'moment/locale/fr';
import CKEditor from '@ckeditor/ckeditor5-vue2';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import '@ckeditor/ckeditor5-build-classic/build/translations/fr';
import _ from 'lodash';
import ModalImage from '@/components/Resources/ModalImage.vue';
import SkillEvaluationSelect from '@/components/Skills/SkillEvaluationSelect.vue';
import AppHelpMessage from '@/components/AppHelpMessage.vue';

moment.updateLocale('fr', localization);

export default {
  props: {
    studentactivity: [Object, Array],
    slide: Number,
  },
  components: {
    ckeditor: CKEditor.component,
    ModalImage,
    SkillEvaluationSelect,
    AppHelpMessage,
  },
  filters: {
    day(date) {
      return moment(date).format('D/MM/YYYY');
    },
    hour(date) {
      return moment(date).format('h:mm');
    },
  },
  data() {
    return {
      sequencetype: '5',
      isLoading: true,
      isFullPage: false,
      carousel: 0,
      animated: 'slide',
      drag: false,
      autoPlay: false,
      pauseHover: false,
      pauseInfo: false,
      repeat: false,
      pauseType: 'is-primary',
      interval: 3000,
      editDates: false,
      editor: ClassicEditor,
      editorDisabled: true,
      editorConfig: {
        language: 'fr',
        toolbar: ['heading', '|', 'bold', 'italic', 'bulletedList', 'numberedList', '|', 'outdent', 'indent', '|', 'link', '|', 'undo',
          'redo'],
        alignment: {
          options: ['left', 'right'],
        },
        heading: {
          options: [
            { model: 'paragraph', title: 'Styles de texte', class: 'ck-heading_paragraph' },
            {
              model: 'heading1',
              view: 'h1',
              title: 'Titre 1',
              class: 'ck-heading_heading1',
            },
            {
              model: 'heading2',
              view: 'h2',
              title: 'Titre 2',
              class: 'ck-heading_heading2',
            },
            {
              model: 'mark',
              view: 'mark',
              title: 'surligné',
              class: 'ck-mark',
            },
          ],
        },
        table: {
          contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells'],
        },
      },
      imageUploaded: {},
      modalKey: 0,
      imageResized: null,
      file: null,
      started: true,
      formRevision: {
        id: '',
        title: '',
        entry: '',
        start_date: new Date(),
        end_date: new Date(),
      },
      studentrevision: '',
      isCardModalActive: false,
      showWeekNumber: true,
      locale: 'UTC',
      today: new Date(),
      activityToday: 'oui',
      newItem: true,
      // trainingsequence: [],
      // studentsequence: [],
      // studentactivity: [],
    };
  },
  computed: {
    ...mapGetters(['getUserById']),
    ...mapState('auth', ['currentUser']),
    conditions() {
      return this.formRevision.title.length > 0 && this.formRevision.entry.length > 0;
    },
  },
  methods: {
    startLoading() {
      this.isLoading = true;
    },
    endLoading() {
      this.isLoading = false;
    },
    initActivity(idStudentActivity) {
      if (idStudentActivity !== '1') {
        this.$http
          .get(`/agenda/student_activity/${this.$route.params.idActivity}/?format=json`)
          .then((response) => {
            this.studentactivity = response.data;
            // this.endLoading();
          }).catch((error) => {
            window.console.log(error);
            this.errored = true;
          });
      }
    },
    initRevision() {
      this.$http
        .get(`/agenda/student_activity_revision/${this.$route.params.idRevision}/?format=json`)
        .then((response) => {
          this.studentrevision = response.data;
          this.setdata();
          // this.endLoading();
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
        });
    },
    setdata() {
      this.formRevision.title = this.studentrevision.title;
      this.formRevision.entry = this.studentrevision.entry;
      this.formRevision.start_date = new Date(this.studentrevision.start_date);
      this.formRevision.end_date = new Date(this.studentrevision.end_date);
      this.verifToday();
    },
    order(array) {
      return _.orderBy(array, 'position');
    },
    uploadImageModal() {
      this.checkFile();
      if (this.fileValidity === true) {
        // const image = this.file.name;
        this.imageUploaded = this.file;
        this.modalKey += 1;
        this.isCardModalActive = true;
      }
    },
    closeModal() {
      this.isCardModalActive = false;
      this.file = null;
    },
    addImageinSteps(url) {
      this.formRevision.entry = `${this.formRevision.entry} <div class="imageUploaded"><img src="${url}"/></div>`;
      // this.$http
      //   .patch(`/agenda/student_activity_revision/${this.$route.params.idRevision}/`, {
      //     entry: entryContent,
      //   }).then(() => {
      //     this.initRevision();
      //   }).catch((error) => {
      //     window.console.log(error);
      //     this.errored = true;
      //     this.endLoading();
      //     this.$buefy.dialog.alert({
      //       title: 'Mince...',
      //       message: 'L\'image n\'a pas pu être ajoutée au champ de texte.',
      //       type: 'is-info',
      //       hasIcon: true,
      //       icon: 'times-circle',
      //       ariaRole: 'alertdialog',
      //       ariaModal: true,
      //       confirmText: 'Ok',
      //     });
      //   });
    },
    checkFile() {
      const extension = this.file.name.split('.').pop();
      // this.resources_type = this.file.substr((this.file.lastIndexOf('.') + 1));
      if (!/(jpeg|png|jpg)$/ig.test(extension)) {
        this.$buefy.dialog.alert({
          title: 'Ce type de fichier n\'est pas accepté',
          message: 'Vous pouvez utiliser les formats suivants :  jpeg, jpg, png.',
          type: 'is-info',
          hasIcon: true,
          icon: 'times-circle',
          ariaRole: 'alertdialog',
          ariaModal: true,
          confirmText: 'Ok',
        });
      } else if (this.file.size > 10000000) {
        this.$buefy.dialog.alert({
          title: 'Cette image est trop volumineuse',
          message: 'Vous pouvez télécharger une image dont la taille ne dépasse pas 10 Mo. Vous pouvez utiliser un service externe comme squoosh.app ou utiliser les options de votre périphérique (téléphone ou tablette) pour réduire une image.',
          type: 'is-info',
          hasIcon: true,
          icon: 'times-circle',
          ariaRole: 'alertdialog',
          ariaModal: true,
          confirmText: 'Ok',
        });
      } else if (this.file.name.length > 116) {
        this.$buefy.dialog.alert({
          title: 'Le nom de votre fichier est trop long',
          message: 'le nom ne doit pas dépasser 117 cractères',
          type: 'is-info',
          hasIcon: true,
          icon: 'times-circle',
          ariaRole: 'alertdialog',
          ariaModal: true,
          confirmText: 'Ok',
        });
      } else { this.nameFile = this.file.name; this.fileValidity = true; }
      // file.files[0].size
    },
    patchRevision() {
      this.$http
        .patch(`/agenda/student_activity_revision/${this.$route.params.idRevision}/`, {
          entry: this.formRevision.entry,
          title: this.formRevision.title,
          start_date: this.formRevision.start_date,
          end_date: this.formRevision.end_date,
        }).then(() => {
          this.$buefy.toast.open({ message: 'Activité enregistrée', type: 'is-success' });
          this.endLoading();
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
          this.$buefy.dialog.alert({
            title: 'Mince...',
            message: 'L\'activité n\'a pas pu être enregistrée.',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
          });
        });
    },
    postRevision() {
      let activity = null;
      if (this.$route.params.idActivity !== 's') {
        activity = this.$route.params.idActivity;
      }
      this.$http
        .post('agenda/student_activity_revision/', {
          student_activity: activity,
          student_sequence: this.$route.params.idSequence,
          profile: this.currentUser.id,
          role: this.currentUser.role,
          title: this.formRevision.title,
          entry: this.formRevision.entry,
          start_date: this.formRevision.start_date,
          end_date: this.formRevision.end_date,
        }).then((response) => {
          this.$buefy.toast.open({ message: 'Activité enregistrée', type: 'is-success' });
          this.endLoading();
          this.$router.push({
            name: 'StudentProgressionEdit',
            params: {
              idTraining: this.$route.params.idTraining,
              idStudent: '',
              idSequence: this.$route.params.idSequence,
              idActivity: this.$route.params.idActivity,
              idRevision: response.data.id,
            },
          });
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
          this.$buefy.dialog.alert({
            title: 'Mince...',
            message: 'L\'activité n\'a pas pu être enregistrée.',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
          });
        });
    },
    logActivity() {
      const typeAction = 'écriture progression';
      this.$http
        .post('agenda/logs/', {
          action: typeAction,
          student_training: this.$route.params.idStudentTraining,
          training: this.$route.params.idTraining,
          student_activity: null,
          student_sequence: this.$route.params.idSequence,
        })
        .then(() => {
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
        });
    },
    verifToday() {
      if (this.formRevision.start_date !== this.today) {
        this.activityToday = 'non';
      } else if (this.formRevision.start_date === this.today) {
        this.activityToday = 'oui';
      }
    },
    changeDate() {
      if (this.activityToday === 'oui') {
        this.formRevision.start_date = this.today;
        this.formRevision.end_date = this.today;
        this.editDates = false;
      } else {
        this.editDates = true;
      }
    },
  },
  created() {
    this.endLoading();
    if (this.$route.params.idRevision !== 'new') {
      this.initRevision();
      this.newItem = false;
    }
    this.carousel = 0;
    if (this.currentUser.role === 'apprenant' || this.currentUser.role === 'tuteur') { this.logActivity(); }
    // this.initActivity();
    // window.console.log(this.studentactivity);
  },
  mounted() {
  },
  destroyed() {
  },
};
</script>

<style lang="scss" scoped>
@media (max-width: 576px) {
  .modalStep .bigmodal {
    margin-bottom: 0.1rem;
  }
}
// .ck-content {
//   min-height: 10vh;
// }
// .modalStep .ck-content {
//   min-height: 60vh;
// }
// .hero.is-medium .hero-body {
//   padding: 3rem 1.5rem !important;
// }
// .carousel-arrow .icon.has-icons-left,
// .carousel-arrow .icon.has-icons-right {
//   top: 65px !important;
// }
// .carousel-arrow i {
//   font-size: 3rem;
// }
// .modalStep .title-paragraph {
//   text-align: center;
// }
// .modalStep {
//   position: absolute;
//   z-index: 1000;
//   top: 0;
//   left: 0;
//   width: 100%;
//   background-color: #fff;
// }
</style>
