<template>
  <div>
    <div class="modalbox modalStep" id="activityEdit">
      <div class="is-flex bigmodal">
        <div class="mr-auto">
          <img
            class="btn-previous"
            :src="require('@/assets/img/arrows/previous.svg')"
            @click="$router.go(-1)"
          />
        </div>
      </div>
      <b-loading :is-full-page="isFullPage" v-model="isLoading"> </b-loading>
      <div v-if="practicalWork.sessions">
        <div class="title-paragraph mb-2">
          Activité pratique : {{ practicalWork.title }}
        </div>
        <div v-if="session" class="has-text-centered mb-4">
          <span class="subtitle-paragraph mb-4 test">
            {{ session.title }}
          </span>
          <b-tag class="has-text-centered" type="is-info">
            {{ session.date | day }}
          </b-tag>
          <span class="mr-2">
            <b-tag type="is-primary is-light" v-if="!session.is_control"
              >Entraînement</b-tag
            ><b-tag type="is-success is-light" v-else>Évaluation</b-tag></span
          >
          <span class="mr-2"
            ><b-tag type="is-info is-light" v-if="session.status === 0"
              >Brouillon</b-tag
            >
            <b-tag type="is-primary" v-if="session.status === 1">En cours</b-tag
            ><b-tag type="is-success" v-if="session.status === 2"
              >Terminé</b-tag
            ></span
          >
        </div>
        <b-tabs position="is-centered" class="block" v-model="activeTab">
          <b-tab-item
            label="Activité"
            @click="activity = true"
            class="content-page"
          >
            <div
              class="subtitle-paragraph has-text-left"
              style="font-size: 1.2rem"
            >
              <h3><b>Présentation de l'activité</b></h3>
            </div>
            <div v-html="practicalWork.description" class="mb-5"></div>
            <div v-if="practicalWork.steps" class="pb-5 mb-5">
              <div v-for="(step, index) in practicalWork.steps" :key="step.id">
                <div class="subtitle-paragraph has-text-left mb-1">
                  <b>Étape {{ index + 1 }} : {{ step.title }}</b>
                </div>
                <div v-html="step.description" class="mb-4"></div>
              </div>
            </div>
            <b-collapse
              v-if="practicalWork.resources.length"
              aria-id="resourcesList"
              class="mb-3"
              animation="slide"
              v-model="isOpen"
            >
              <template #trigger="props">
                <div
                  class="card card_simple mb-3"
                  role="button"
                  aria-controls="resourcesList"
                  :aria-expanded="isOpen"
                >
                  <div class="is-flex align-items-center">
                    <a class="card-header-icon">
                      <b-icon :icon="props.open ? 'menu-down' : 'menu-up'">
                      </b-icon>
                    </a>
                    <strong>Resources</strong>
                  </div>
                </div>
              </template>
              <div class="mt-5 pt-5">
                <div v-if="practicalWork.resources.length">
                  <div
                    v-for="resource in practicalWork.resources"
                    :key="resource.id"
                  >
                    <ResourceCard :resource="resource"></ResourceCard>
                  </div>
                </div>
              </div>
            </b-collapse>

            <b-message type="is-info"
              >Vous pouvez ajouter ici quelques notes, ou photo de ce que vous
              allez ou avez vu en activité pratique</b-message
            >
            <div
              v-if="
                this.currentUser.role === 'apprenant' &&
                (new Date() > new Date(this.session.date) ||
                  new Date() === new Date(this.session.date))
              "
            >
              <ModalImage
                v-if="isCardModalActive"
                :fileImage.sync="imageUploaded"
                :imageResized.sync="imageResized"
                @close="closeModal"
                @loaded="
                  addImageinSteps(
                    practicalWork.sessions[0].students[0].student_comment,
                    imageResized
                  )
                "
              />
              <div class="imgBottom fade is-flex" v-else>
                <b-upload
                  v-model="file"
                  class="file-label"
                  @input="uploadImageModal()"
                >
                  <span class="file-cta">
                    <b-icon class="file-icon" icon="photo"></b-icon>
                  </span>
                </b-upload>
              </div>
              <div class="is-flex blockEditContent">
                <b-field
                  class="file is-primary mb-1 addImage"
                  :class="{ 'has-name': !!file }"
                >
                  <b-upload
                    v-model="file"
                    class="file-label"
                    @input="uploadImageModal()"
                  >
                    <span class="file-cta">
                      <b-icon class="file-icon" icon="photo"></b-icon>
                      <span class="file-label">Ajouter une image</span>
                    </span>
                  </b-upload>
                </b-field>
              </div>
              <div
                class="ck-editor-block"
                :class="fullscreen ? 'fullscreen' : ''"
              >
                <ckeditor
                  v-model="session.students[0].student_comment"
                  :editor="editor"
                  :config="editorConfig"
                  class="mb-5"
                  :ref="'s1'"
                ></ckeditor>
              </div>
            </div>
            <div v-else>
              <b class="has-text-primary mb-4"
                ><span v-if="currentUser.role === 'aprenant'"
                  >Votre commentaire</span
                ><span v-else>Commentaire apprenant•e</span></b
              >
              <div
                v-if="session.students[0].student_comment"
                class="mt-4"
                v-html="session.students[0].student_comment"
              ></div>
              <b-message v-else size="is-small" class="mt-2"
                >Pas de commentaire saisi</b-message
              >
            </div>
          </b-tab-item>
          <b-tab-item
            label="Évaluation formateur"
            @click="seeEval"
            class="content-page"
          >
            <div>
              <b-message type="is-info" v-if="session.status === 1"
                >Vous trouverez ci-dessous l'évaluation de votre
                formateur•rice</b-message
              >
              <b-message type="is-success" v-if="session.status === 2">
                Voici l'évaluation de votre formateur•rice</b-message
              >
              <div
                v-for="skill in practicalWork.skills"
                :key="skill.id"
                class="mb-5"
              >
                <div
                  class="columns is-vcentered justify-space-between mb-5 pb-2 skillsRead"
                >
                  <div class="column name-skill is-6 is-6-desktop pb-1">
                    <b>{{ skill.name }}</b>
                  </div>
                  <div class="column pb-1 is-flex align-items-center">
                    <div
                      class="columns is-desktop levelsList is-vcentered pl-2"
                    >
                      <div
                        v-for="level in order(practicalWork.levels)"
                        :key="level.id"
                        class="column is-narrow"
                      >
                        <div class="level">
                          <b-icon
                            size="is-small"
                            icon="check"
                            type="is-success"
                            v-if="
                              level.id ===
                                studentSkills[$route.params.idStudent][
                                  skill.id
                                ] && session.status === 2
                            "
                          ></b-icon>
                          <b-icon icon="close" class="disabled" v-else></b-icon>

                          <div class="level-label">{{ level.name }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="session.status === 2">
                <div class="subtitle-paragraph mt-5 pt-5 has-text-left">
                  <b> Commentaire de votre formateur·rice</b>
                </div>
                <div
                  v-if="session.students[0].teacher_comment"
                  class="pb-5 mb-5 is-height-auto"
                  v-html="session.students[0].teacher_comment"
                ></div>
                <b-message v-else size="is-small" class="mt-2"
                  >Pas de commentaire saisi</b-message
                >
              </div>
            </div>
          </b-tab-item>
        </b-tabs>
        <section></section>

        <div>
          <div
            class="is-flex is-flex-wrap-wrap activityBtns align-items-center"
            style="z-index: 2000"
          >
            <div class="w-100 is-flex">
              <div class="mx-auto">
                <b-button
                  class="btn-action w-auto mr-1"
                  type="is-primary is-rounded"
                  rounded
                  @click="
                    patchComment(
                      practicalWork.sessions[0].students[0].student_comment
                    )
                  "
                  >enregistrer</b-button
                >
                <!-- <b-button
              @click="validateActivity"
              class="btn-action w-auto"
              type="is-success is-rounded"
              rounded
              >valider</b-button
            > -->
              </div>
            </div>
            <div class="help-btn">
              <AppHelpMessage
                title="Rédigez et enregistrez votre activité"
                message="<b>Vous pouvez enregistrer vos notes autant de fois que
                     vous le souhaitez.</b> Tant que vous ne validez pas votre travail,
                      l'activité n'est pas considérée comme effectuée.</b>
            <br/>Une fois validée, vous pourrez corriger des fautes ou reprendre le contenu.
               <b>Toutefois si vous refaites cette situation, il vaut mieux utiliser le bouton
                  'Vous avez progressé' sur la vue précédente pour ajouter de nouvelles notes.</b>"
                textBtn="Besoin d'aide ?"
              />
            </div>
          </div>
        </div>
        <div class="container contentSequence pb-5 mb-5"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';
import localization from 'moment/locale/fr';
import _ from 'lodash';
import CKEditor from '@ckeditor/ckeditor5-vue2';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import '@ckeditor/ckeditor5-build-classic/build/translations/fr';
import ModalImage from '@/components/Resources/ModalImage.vue';
import AppHelpMessage from '@/components/AppHelpMessage.vue';
import ResourceCard from '@/components/Resources/ResourceCard.vue';

moment.updateLocale('fr', localization);

export default {
  components: {
    ckeditor: CKEditor.component,
    ModalImage,
    AppHelpMessage,
    ResourceCard,
  },
  filters: {
    day(date) {
      return moment(date).format('D MMMM YYYY');
    },
    hour(date) {
      return moment(date).format('h:mm');
    },
  },
  data() {
    return {
      fullscreen: false,
      displayck: false,
      isLoading: false,
      isFullPage: false,
      practicalWork: [],
      practicalWork2: [],
      editor: ClassicEditor,
      editorDisabled: true,
      editorConfig: {
        language: 'fr',
        toolbar: ['heading', '|', 'bold', 'italic', 'bulletedList', 'numberedList', '|', 'outdent', 'indent', '|', 'link', '|', 'undo',
          'redo'],
        alignment: {
          options: ['left', 'right'],
        },
        heading: {
          options: [
            { model: 'paragraph', title: 'Styles de texte', class: 'ck-heading_paragraph' },
            {
              model: 'heading1',
              view: 'h1',
              title: 'Titre 1',
              class: 'ck-heading_heading1',
            },
            {
              model: 'heading2',
              view: 'h2',
              title: 'Titre 2',
              class: 'ck-heading_heading2',
            },
            {
              model: 'mark',
              view: 'mark',
              title: 'surligné',
              class: 'ck-mark',
            },
          ],
        },
        table: {
          contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells'],
        },
      },
      isCardModalActive: null,
      imageUploaded: {},
      modalKey: 0,
      imageResized: null,
      file: null,
      started: true,
      activeTab: 0,
      isOpen: false,
      studentSkills: {},
    };
  },
  computed: {
    ...mapState('auth', ['currentUser']),
  },
  methods: {
    startLoading() {
      this.isLoading = true;
    },
    endLoading() {
      this.isLoading = false;
    },
    order(array) {
      return _.orderBy(array, 'position');
    },
    async getPracticalWork() {
      try {
        const response = await this.$http.get(`/practical_work/get_practical_works/?training=${this.$route.params.idTraining}&student=${this.$route.params.idStudent}&format=json`);
        this.practicalWorks = response.data;
        this.practicalWorks.forEach((tp) => {
          if (tp.id === Number(this.$route.params.idPracticalWork)) {
            this.practicalWork = tp;
            this.practicalWork.sessions.forEach((session) => {
              if (session.id === Number(this.$route.params.idSession)) {
                this.session = session;
                this.studentSkills = this.initializeStudentSkills();
              }
            });
          }
        });
        this.endLoading();
      } catch (error) {
        window.console.error(error);
        // handle the error as needed
      }
    },
    patchComment(entryContent) {
      const id = this.practicalWork.sessions[0].students[0].student_training_practical_work_id;
      // /practical_work/student_training_practical_work/199/
      // practicalWork[1].sessions[0].students[0].student_training_practical_work_id
      this.$http
        .patch(`/practical_work/student_training_practical_work/${id}/`, {
          entry: entryContent,
        }).then(() => {
          this.$buefy.toast.open({ message: 'Activité enregistrée', type: 'is-success' });
          this.endLoading();
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
          this.$buefy.dialog.alert({
            title: 'Mince...',
            message: 'L\'activité n\'a pas pu être enregistrée.',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
          });
        });
    },
    uploadImageModal() {
      this.checkFile();
      if (this.fileValidity === true) {
        // const image = this.file.name;
        this.imageUploaded = this.file;
        this.modalKey += 1;
        this.isCardModalActive = true;
      }
    },
    closeModal() {
      this.isCardModalActive = false;
      this.file = null;
    },
    checkFile() {
      const extension = this.file.name.split('.').pop();
      // this.resources_type = this.file.substr((this.file.lastIndexOf('.') + 1));
      if (!/(jpeg|png|jpg)$/ig.test(extension)) {
        this.$buefy.dialog.alert({
          title: 'Ce type de fichier n\'est pas accepté',
          message: 'Vous pouvez utiliser les formats suivants :  jpeg, jpg, png.',
          type: 'is-info',
          hasIcon: true,
          icon: 'times-circle',
          ariaRole: 'alertdialog',
          ariaModal: true,
          confirmText: 'Ok',
        });
      } else if (this.file.size > 10000000) {
        this.$buefy.dialog.alert({
          title: 'Cette image est trop volumineuse',
          message: 'Vous pouvez télécharger une image dont la taille ne dépasse pas 10 Mo. Vous pouvez utiliser un service externe comme squoosh.app ou utiliser les options de votre périphérique (téléphone ou tablette) pour réduire une image.',
          type: 'is-info',
          hasIcon: true,
          icon: 'times-circle',
          ariaRole: 'alertdialog',
          ariaModal: true,
          confirmText: 'Ok',
        });
      } else if (this.file.name.length > 116) {
        this.$buefy.dialog.alert({
          title: 'Le nom de votre fichier est trop long',
          message: 'le nom ne doit pas dépasser 117 cractères',
          type: 'is-info',
          hasIcon: true,
          icon: 'times-circle',
          ariaRole: 'alertdialog',
          ariaModal: true,
          confirmText: 'Ok',
        });
      } else { this.nameFile = this.file.name; this.fileValidity = true; }
      // file.files[0].size
    },
    addImageinSteps(stepEntry, url) {
      const id = this.practicalWork.sessions[0].students[0].student_training_practical_work_id;
      let entryContent = '';
      if (stepEntry === null) {
        entryContent = `<div class="imageUploaded"><img src="${url}"/></div>`;
      } else {
        entryContent = `${stepEntry} <div class="imageUploaded"><img src="${url}"/></div>`;
      }
      this.$http
        .patch(`/practical_work/student_training_practical_work/${id}/`, {
          entry: entryContent,
        }).then(() => {
          window.console.log('modifie');
          this.getPracticalWork();
          // this.initActivity();
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
          this.endLoading();
          this.$buefy.dialog.alert({
            title: 'Mince...',
            message: 'L\'image n\'a pas pu être ajoutée au champ de texte.',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
          });
        });
    },
    seeEval() {
      this.evaluation = true;
    },
    getSkillValue(studentId, skillId) {
      return this.studentSkills[studentId] ? this.studentSkills[studentId][skillId] : null;
    },
    setSkillValue(studentId, skillId, value) {
      if (!this.studentSkills[studentId]) {
        this.$set(this.studentSkills, studentId, {});
      }
      this.$set(this.studentSkills[studentId], skillId, value);
    },
    initializeStudentSkills() {
      const studentSkills = {};
      this.session.students.forEach((student) => {
        studentSkills[student.id] = {};
        this.practicalWork.skills.forEach((skill) => {
          // If the student has evaluations, initialize with the existing level, otherwise null
          const existingSkill = student.skills.find((s) => s.id === skill.id);
          // eslint-disable-next-line
          studentSkills[student.id][skill.id] = existingSkill && existingSkill.student_evals && existingSkill.student_evals[0]
            ? existingSkill.student_evals[0].level_id
            : null;
        });
      });
      return studentSkills;
    },
  },
  created() {
    this.startLoading();
    this.getPracticalWork();
  },
  mounted() {
  },
  destroyed() {
  },
};
</script>

<style lang="scss" scoped>
.card_grey {
  min-height: unset;
}
.level {
  font-size: 0.9rem;
}
.level .icon {
  font-size: unset !important;
  margin-right: 0.3rem;
  width: 1.5rem;
}
</style>
