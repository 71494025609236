<template>
  <div class="modal-card" style="width: auto">
    <header class="modal-card-head">
      <div>
        <p class="modal-card-title" v-if="isNew === true">
          Créer une séance pour l'activité pratique
        </p>
        <p class="modal-card-title" v-else>
          Modifier la séance pour l'activité pratique
        </p>
      </div>

      <div class="has-text-primary ml-1" v-if="!isNew">
        <b> "{{ practicalWork.title }}"</b>
      </div>
    </header>
    <section class="modal-card-body">
      <div class="container">
        <b-field label="Titre" custom-class="is-size-5">
          <b-input v-model="practicalWorkSession.title" />
        </b-field>
        <div v-if="isEditMode === true" class="columns">
          <div class="column">
            <b-field label="Type de séance" custom-class="is-size-5">
              <b-radio
                v-model="practicalWorkSession.is_control"
                native-value="false"
              >
                Entraînement
              </b-radio>
              <b-radio
                v-model="practicalWorkSession.is_control"
                native-value="true"
              >
                Évaluation
              </b-radio>
            </b-field>
            <b-field label="Date" custom-class="is-size-5">
              <b-datepicker
                v-model="practicalWorkSession.date"
                placeholder="Sélectionnez une date"
              />
            </b-field>
          </div>
          <div class="column">
            <b-select
              class="mt-5"
              placeholder="Changer de statut"
              v-if="isEditMode"
              v-model="practicalWorkSession.status"
            >
              <option value="0">Brouillon</option>
              <option value="1">Actif</option>
              <option value="2">Terminé</option>
            </b-select>
          </div>
        </div>
        <div v-else>
          <div class="is-flex is-vcentered mb-2">
            <h2 class="label title is-size-5 mr-2">Statut</h2>
            <b-tag
              type="is-info is-light"
              v-if="practicalWorkSession.status === 0"
              >Brouillon</b-tag
            >
            <b-tag type="is-primary" v-if="practicalWorkSession.status === 1"
              >Actif</b-tag
            ><b-tag type="is-success" v-if="practicalWorkSession.status === 2"
              >Terminé</b-tag
            >
          </div>
          <div class="is-flex is-vcentered mb-2">
            <h2 class="label title is-size-5 mr-2">Type de séance</h2>
            <b-tag
              type="is-primary is-light"
              v-if="!practicalWorkSession.is_control"
              >Entraînement</b-tag
            ><b-tag type="is-success is-light" v-else>Évaluation</b-tag>
          </div>
          <div class="is-flex is-vcentered mb-4">
            <h2 class="label title is-size-5 mr-2">Date</h2>
            {{ practicalWorkSession.date | day }}
          </div>
        </div>
        <!-- Candidates -->
        <section>
          <b-field
            label="Liste des participant•e•s:"
            v-if="isEditMode === true"
          >
            <b-input
              v-model="selectedCandidate"
              placeholder="Tapez quelques lettres pour rechercher par prénom ou nom"
              :disabled="isLoadingCandidates"
              @input="debouncedSearchCandidates"
              type="text"
              expanded
              :clearable="!!selectedCandidate"
              @clear="selectedCandidate = '' & searchCandidates"
            ></b-input>
          </b-field>

          <h2 class="label title is-size-5" v-if="isEditMode === false">
            Apprenant•es associé•es
          </h2>
          <b-table
            v-if="isEditMode"
            ref="table"
            :data="relevantCandidates"
            :columns="columns"
            :checked-rows.sync="selectedCandidates"
            :checkable="true"
            :checkbox-position="checkboxPosition"
            :checkbox-type="checkboxType"
          >
            <template #bottom-right>
              <b-button
                label="Effacer la sélection"
                type="is-danger"
                icon-left="close"
                :disabled="selectedCandidates.length === 0"
                @click="selectedCandidates = []"
              ></b-button>
            </template>
          </b-table>
          <b-table
            v-else
            ref="table"
            :data="selectedCandidates"
            :columns="columns"
            :checked-rows.sync="selectedCandidates"
            :checkable="isEditMode"
            :checkbox-position="checkboxPosition"
            :checkbox-type="checkboxType"
          >
            <template #bottom-right>
              <b-button
                label="Effacer la sélection"
                type="is-danger"
                icon-left="close"
                :disabled="selectedCandidates.length === 0"
                @click="selectedCandidates = []"
              ></b-button>
            </template>
          </b-table>
        </section>
        <span v-if="selectedCandidates.length > 0 && isEditMode"
          ><b
            >{{ selectedCandidates.length }} candidat•es sélectionné•es</b
          ></span
        >
        <div class="pt-6">
          <h2 class="label title is-size-5" v-if="isEditMode === true">
            Sélectionnez les formateurs
          </h2>
          <h2 class="label title is-size-5" v-else>Formateurs associés</h2>
          <b-table
            v-if="isEditMode"
            :data="relevantTeachers"
            :columns="teachersColumns"
            :checked-rows.sync="selectedTeachers"
            :checkable="isEditMode"
            :checkbox-position="checkboxPosition"
            :checkbox-type="checkboxType"
          >
            <template #bottom-left>
              <b>{{ selectedTeachers.length }} formateurs sélectionnés</b>
            </template>
          </b-table>
          <b-table
            v-else
            :data="selectedTeachers"
            :columns="teachersColumns"
            :checked-rows.sync="selectedTeachers"
            :checkable="false"
            :checkbox-position="checkboxPosition"
            :checkbox-type="checkboxType"
          >
            <template #bottom-left>
              <b>{{ selectedTeachers.length }} formateurs sélectionnés</b>
            </template>
          </b-table>
        </div>
      </div>
    </section>
    <footer class="modal-card-foot">
      <b-button label="Fermer" @click="$emit('close')" />
      <b-button
        v-if="isNew === true"
        label="Créer cette séance"
        type="is-primary"
        @click="createSession"
        :disabled="practicalWorkSession.title === ''"
      />
      <b-button
        v-if="!isNew && isEditMode === true"
        label="Modifier cette séance"
        type="is-primary"
        @click="updateSession"
      />
      <b-button
        v-if="!isNew && isEditMode === false"
        label="Modifier"
        type="is-primary"
        @click="isEditMode = true"
      />
      <b-button
        v-if="currentUser.role === 'coordinateur' && !isNew"
        label="Supprimer la séance"
        type="is-danger"
        @click="deleteSession"
      />
    </footer>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import debounce from 'lodash/debounce';
import moment from 'moment';
import 'moment/locale/fr';

export default {
  filters: {
    day(date) {
      return moment(date).format('D MMMM YYYY');
    },
  },
  data() {
    return {
      practicalWorkSession: {
        title: '',
        is_control: false,
        date: null,
        teachers: [],
        status: 1,
      },
      columns: [
        {
          field: 'first_name',
          label: 'Prénom',
          sortable: true,
          width: '150px',
        },
        {
          field: 'last_name',
          label: 'Nom',
          sortable: true,
          width: '150px',
        },
        // {
        //   field: 'role',
        //   label: 'Rôle',
        //   width: '150px',
        // },
      ],
      checkboxPosition: 'left',
      checkboxType: 'is-primary',
      selectedCandidate: '',
      relevantCandidates: [],
      selectedCandidates: [],
      isLoadingCandidates: false,
      relevantTeachers: [],
      selectedTeachers: [],
      teachersColumns: [
        {
          field: 'first_name',
          label: 'Prénom',
          sortable: true,
          width: '150px',
        },
        {
          field: 'last_name',
          label: 'Nom',
          sortable: true,
          width: '150px',
        },
      ],
      checkable: false,
      isEditMode: false,
    };
  },
  computed: {
    ...mapState('auth', ['currentUser']),
  },
  props: {
    isNew: Boolean,
    practicalWork: [],
    sessionDisplayed: Object,
  },

  created() {
    this.searchTeachers();
    if (!this.isNew) {
      this.practicalWorkSession.title = this.sessionDisplayed.title;
      this.practicalWorkSession.is_control = this.sessionDisplayed.is_control;
      this.practicalWorkSession.date = new Date(this.sessionDisplayed.date);
      this.practicalWorkSession.teachers = this.sessionDisplayed.teachers;
      this.practicalWorkSession.status = this.sessionDisplayed.status;
      if (this.sessionDisplayed.status === 2) {
        this.isEditMode = false;
        this.cheackable = false;
      }
      this.setCandidates();
      this.selectedCandidates = this.sessionDisplayed.students;
    } else {
      this.isEditMode = true;
      this.checkable = true;
      moment.locale('fr');
      this.practicalWorkSession.title = `Séance ${this.practicalWork.sessions.length + 1}`;
      this.practicalWorkSession.date = moment().toDate();
      this.searchCandidates();
    }
  },
  mounted() {
  },
  watch: {
    sessionDisplayed: {
      handler(newVal) {
        if (newVal && newVal.students) {
          this.selectedCandidates = newVal.students;
        }
      },
      deep: true,
    },
  },
  methods: {
    async createSession() {
      // Validate props before proceeding
      if (!(this.practicalWorkSession.date instanceof Date)) {
        this.practicalWorkSession.date = new Date(this.practicalWorkSession.date);
      }
      if (!Array.isArray(this.selectedCandidates)) {
        this.selectedCandidates = [];
      }

      try {
        const response = await this.$http.post('/practical_work/training_practical_work_session/', {
          title: this.practicalWorkSession.title,
          date: this.practicalWorkSession.date,
          is_control: this.practicalWorkSession.is_control,
          training_practical_work: this.practicalWork.id,
          teachers: this.selectedTeachers.map((teacher) => teacher.id),
        });

        const sessionId = response.data.id;

        if (this.selectedCandidates.length > 0) {
          // eslint-disable-next-line max-len
          await this.$http.post(`/practical_work/set_practical_work_session_attendees/${sessionId}`, this.selectedCandidates);
          // window.console.log(response2.data);
        }

        // window.console.log(response.data);
        this.practicalWorkSession.title = '';
        this.practicalWorkSession.is_control = false;
        this.selectedCandidates = [];
        this.$emit('success');
      } catch (error) {
        window.console.error(error);
        this.$buefy.toast.open({
          message: 'Mince, une erreur s\'est produite !',
          type: 'is-danger',
          position: 'is-top-right',
          duration: 5000,
          queue: false,
          pauseOnHover: true,
          closeOnClick: true,
          onDismiss: null,
          indefinite: false,
        });
      }
    },

    async updateSession() {
      try {
        const response = await this.$http.patch(`/practical_work/training_practical_work_session/${this.sessionDisplayed.id}/`, {
          title: this.practicalWorkSession.title,
          date: this.practicalWorkSession.date,
          is_control: this.practicalWorkSession.is_control,
          teachers: this.selectedTeachers.map((teacher) => teacher.id),
          status: this.practicalWorkSession.status,
        });

        if (this.selectedCandidates.length > 0) {
          // eslint-disable-next-line max-len
          const response2 = await this.$http.post(`/practical_work/set_practical_work_session_attendees/${this.sessionDisplayed.id}`, this.selectedCandidates);
          window.console.log(response2.data);
        }

        window.console.log(response.data);
        this.selectedCandidate = '';
        this.relevantCandidates = [];
        this.selectedCandidates = [];
        this.practicalWorkSession = {
          title: '',
          is_control: false,
          date: null,
          teachers: [],
        };
        this.$emit('updatedSuccess');
      } catch (error) {
        window.console.error(error);
        this.$buefy.toast.open({
          message: 'Mince, une erreur s\'est produite !',
          type: 'is-danger',
          position: 'is-top-right',
          duration: 5000,
          queue: false,
          pauseOnHover: true,
          closeOnClick: true,
          onDismiss: null,
          indefinite: false,
        });
      }
    },

    async setCandidates() {
      this.isLoadingCandidates = true;
      try {
        const response = await this.$http.get(`/get_profiles/?search=${this.selectedCandidate}&training=${this.$route.params.idTraining}&role=apprenant&items=4000&format=json`);
        const { results } = response.data;
        this.relevantCandidates = results;
      } catch (error) {
        window.console.error(error);
      } finally {
        this.isLoadingCandidates = false;
        // eslint-disable-next-line max-len
        this.selectedCandidates = this.relevantCandidates.filter((candidate) => this.sessionDisplayed.students.some((student) => student.id === candidate.id));
      }
    },

    async searchCandidates() {
      this.isLoadingCandidates = true;
      try {
        const response = await this.$http.get(`/get_profiles/?search=${this.selectedCandidate}&training=${this.$route.params.idTraining}&role=apprenant&items=4000&format=json`);
        const { results } = response.data;
        const existingCandidatesIds = this.selectedCandidates.map((candidate) => candidate.id);
        // eslint-disable-next-line max-len
        const relevantCandidates = results.filter((candidate) => !existingCandidatesIds.includes(candidate.id));
        this.relevantCandidates = this.relevantCandidates
          .filter((candidate) => existingCandidatesIds.includes(candidate.id))
          .concat(relevantCandidates.map((candidate) => ({
            ...candidate,
            _checked: existingCandidatesIds.includes(candidate.id),
          })));
      } catch (error) {
        window.console.error(error);
      } finally {
        this.isLoadingCandidates = false;
      }
    },
    async searchTeachers() {
      try {
        const response = await this.$http.get(`/get_profiles/?training=${this.$route.params.idTraining}&role=formateur&items=4000&format=json`);
        const relevantTeachers = response.data.results;
        this.relevantTeachers = relevantTeachers;
        if (this.practicalWorkSession) {
          this.verifTeacher();
        }
      } catch (error) {
        window.console.error(error);
      }
    },
    verifTeacher() {
      this.practicalWorkSession.teachers.forEach((teacher) => {
        const result = this.relevantTeachers.filter((el) => `${el.last_name} ${el.first_name}` === teacher);
        this.selectedTeachers.push(result[0]);
      });
    },
    deleteSession() {
      let message = '';
      const result = this.sessionDisplayed.students.filter((el) => el.evaluated === true);
      if (result.length > 0) {
        message = `<div class="has-text-danger"><b>Attention, ${result.length} étudiants ont été évalués</b></div> Voulez-vous quand même supprimer cette séance ?`;
        this.$buefy.dialog.confirm({
          title: `Supprimer la session "${this.practicalWork.title}"`,
          message: `${message}`,
          confirmText: 'Supprimer',
          cancelText: 'Annuler',
          type: 'is-danger',
          hasIcon: true,
          onConfirm: () => {
            this.$http
              .delete(`/practical_work/training_practical_work_session/${this.sessionDisplayed.id}/`)
              .then(() => {
                this.$buefy.toast.open({ message: 'Séance supprimée', type: 'is-success' });
                this.$emit('updatedSuccess');
              }).catch((error) => {
                window.console.log(error);
                this.errored = true;
                this.$buefy.dialog.alert({
                  title: 'Mince...',
                  message: 'La séance n\'a pas pu être supprimée.',
                  type: 'is-grey',
                  hasIcon: true,
                  icon: 'times-circle',
                  ariaRole: 'alertdialog',
                  ariaModal: true,
                  confirmText: 'Ok',
                });
              });
          },
        });
      } else {
        this.$http
          .delete(`/practical_work/training_practical_work_session/${this.sessionDisplayed.id}/`)
          .then(() => {
            this.$buefy.toast.open({ message: 'Séance supprimée', type: 'is-success' });
            this.$emit('updatedSuccess');
          }).catch((error) => {
            window.console.log(error);
            this.errored = true;
            this.$buefy.dialog.alert({
              title: 'Mince...',
              message: 'La séance n\'a pas pu être supprimée.',
              type: 'is-grey',
              hasIcon: true,
              icon: 'times-circle',
              ariaRole: 'alertdialog',
              ariaModal: true,
              confirmText: 'Ok',
            });
          });
      }
    },
    // eslint-disable-next-line
    debouncedSearchCandidates: debounce(function () {
      this.searchCandidates();
    }, 500),
  },
};
</script>
<style scoped>
.label {
  font-size: 1.25rem !important;
}
</style>
