 <template>
  <div class="container-eval">
    <div v-if="skills.length">
      <div class="columns is-hidden-mobile">
        <div class="column is-6 is-6-desktop has-text-primary head-skills">
          <b>Compétences </b>
        </div>
        <div class="column has-text-primary head-skills">
          <b>Niveau d'acquisition</b>
        </div>
      </div>
      <div
        class="columns is-vcentered justify-space-between mb-5 pb-2 skillsEdit"
        v-for="(skill, indexSkill) in order(skills)"
        :key="skill.name"
        :name="'question' + indexSkill"
      >
        <div class="name-skill column is-6 is-6-desktop pb-1">
          <b>{{ skill.name }}</b>
        </div>
        <div class="column is-flex align-items-center pb-1" v-if="skill.levels">
          <!-- @input="postEvaluation(level.id, skill.id)" -->
          <div class="columns is-desktop levelsList is-vcentered">
            <b-radio
              v-for="(level, index) in order(skill.levels)"
              :key="level.id"
              v-model="skill.level_checked"
              :name="'question' + index + indexSkill"
              :native-value="index"
              class="column level"
            >
              {{ level.name }}
            </b-radio>
          </div>
        </div>
        <div class="column is-narrow is-flex align-items-center ml-auto">
          <b-button @click="skill.level_checked = null" type="is-small" rounded
            >effacer</b-button
          >
        </div>
      </div>
    </div>
    <div v-if="skills.length">
      <div
        class="is-flex is-flex-wrap-wrap activityBtns align-items-center"
        style="z-index: 3000"
      >
        <div class="w-100 is-flex">
          <b-button
            class="mx-auto btn-action"
            type="is-primary is-rounded"
            rounded
            @click="postAllEvaluation"
            >enregistrer l'évaluation</b-button
          >
        </div>
        <div class="help-btn"></div>
      </div>
    </div>
    <div v-else>
      <b-message type="is-info" v-if="type === 'progression'">
        Aucune compétence n'est associée à cette expérience, vous n'avez donc
        aucune compétence à évaluer ici.
      </b-message>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import _ from 'lodash';
// import SkillEvaluationProgression from '@/components/Skills/SkillEvaluationProgression.vue';

export default {
  props: {
    // skills: {
    //   type: [Object, Array],
    // },
    role: {
      type: Array,
    }, // framework, training or studenttraining
    skillsLevels: {
      type: [Object, Array],
    },
    studentTraining: {
      type: Number,
    },
    studentSequence: {
      type: Number,
    },
    type: {
      type: String,
    },
  },
  components: {
    // SkillEvaluationProgression,
  },
  filters: {
  },
  data() {
    return {
      isLoading: false,
      isFullPage: false,
      formSkills: {},
      skills: [],
      // skillsLevelsTraining: [],
      checkedLevel: [],
      skillsChecked: [],
      roleEvaluation: '',
      roleArray: [],
    };
  },
  computed: {
    ...mapState('auth', ['currentUser']),
  },
  methods: {
    startLoading() {
      this.isLoading = true;
    },
    endLoading() {
      this.isLoading = false;
    },
    order(array) {
      return _.orderBy(array, 'position');
    },
    initSkills() {
      let urlWs = '';
      if (this.type === 'progression') {
        // urlWs = `&revision=${this.$route.params.idRevision}&role=${this.roleEvaluation}`;
        urlWs = `/agenda/get_student_training_skills/?student_training=${this.$route.params.idStudentTraining}&student_sequence=${this.$route.params.idSequence}&revision=${this.$route.params.idRevision}&role=${this.roleEvaluation}`;
      } else if (this.type === 'sequence') {
        urlWs = `/agenda/get_student_training_skills/?student_training=${this.$route.params.idStudentTraining}&student_sequence=${this.$route.params.idSequence}&role=${this.roleEvaluation}`;
      }
      this.$http
        .get(`${urlWs}`)
        .then((response) => {
          this.skills = response.data;
          // this.$http
          //   .get(`/traininglevels/?training=${this.$route.params.idTraining}`)
          //   .then((resp) => {
          //     this.skillsLevelsTraining = resp.data;
          //     this.endLoading();
          //   }).catch((error) => {
          //     window.console.log(error);
          //     this.errored = true;
          //   });
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
        });
    },
    postEvaluation(levelId, skillId) {
      this.$http
        .post('agenda/student_training_skills_levels/', {
          role: this.roleEvaluation,
          profile: this.currentUser.id,
          training_skill: skillId,
          training_level: levelId,
          student_training: Number(this.$route.params.idStudentTraining),
          student_sequence: Number(this.$route.params.idSequence),
          student_activity_revision: Number(this.$route.params.idRevision),
        }).then(() => {
          this.$buefy.toast.open({ message: 'Compétence enregistrée', type: 'is-success' });
          this.initSkills();
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
        });
    },
    postAllEvaluation() {
      for (let e = 0; e < this.skills.length; e += 1) {
        if (Number.isInteger(this.skills[e].level_checked)) {
          const level = this.skills[e].level_checked;
          this.checkedLevel.push(
            {
              training_skill: this.skills[e].id,
              training_level: this.skills[e].levels[level].id,
            },
          );
        }
        if (e === (this.skills.length - 1)) {
          this.$http
            .post('agenda/set_evaluations', {
              role: this.roleEvaluation,
              profile: this.currentUser.id,
              student_training: Number(this.$route.params.idStudentTraining),
              student_sequence: Number(this.$route.params.idSequence),
              free_situation: this.$route.params.idFree,
              student_activity_revision: Number(this.$route.params.idRevision),
              student_activity: Number(this.$route.params.idActivity),
              evaluations: this.checkedLevel,
            }).then(() => {
              this.$emit('saveActivity');
              this.$buefy.toast.open({ message: 'Compétence enregistrée', type: 'is-success' });
              this.initSkills();
              this.checkedLevel = [];
            }).catch((error) => {
              window.console.log(error);
              this.errored = true;
            });
        }
      }
    },
    verifRole() {
      for (let i = 0; i < this.role.length; i += 1) {
        let writer = '';
        if (this.role[i].includes('w_')) {
          writer = this.role[i].replace('w_', '');
          this.roleArray.push(writer);
        }
        if (this.roleArray.includes('apprenant') && this.roleArray.includes('tuteur')) {
          this.roleEvaluation = 'tuteur,apprenant';
          this.initSkills();
        } else if (i === (this.role.length - 1)) {
          const role = this.roleArray[0];
          this.roleEvaluation = role;
          this.initSkills();
        }
      }
    },
  },
  created() {
    this.$emit('displayBtn');
    if (this.role) {
      this.verifRole();
    } else {
      this.roleEvaluation = this.currentUser.role;
      this.initSkills();
    }
  },
  mounted() {
  },
};
</script>

<style scoped>
.disabled {
  color: #dfdfeb;
}
</style>
