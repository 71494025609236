<template>
  <div class="">
    <b-loading :is-full-page="isFullPage" v-model="isLoading"> </b-loading>
    <div>
      <h1 class="title-paragraph mb-3">
        Suivi des compétences pour
        <span v-if="skillsList.length"> {{ students }} apprenant(s)</span>
      </h1>
    </div>
    <div class="columns">
      <div class="column">
        <div class="levels mb-5">
          <div class="subtitle-paragraph mb-2 pt-5">
            Niveaux d'acquisition des compétences
          </div>
          <div class="caption">
            <div
              class="caption-item mb-2"
              v-for="level in skillsLevels"
              :key="level.id"
            >
              <img
                :src="require(`@/assets/img/levels/level${level.position}.svg`)"
                alt=""
                class="img_status"
              />
              {{ level.name }}
            </div>
          </div>
          <b-message type="is-info" v-if="!skillsLevels.length"
            >Aucun niveau n'a été renseigné</b-message
          >
        </div>
      </div>
      <div class="column is-narrow mt-5">
        <b-button
          @click="type = 'byskills'"
          :class="type === 'byskills' ? 'is-primary' : ''"
          >Par compétences</b-button
        >
        <b-button
          @click="type = 'bystudents'"
          :class="type === 'bystudents' ? 'is-primary' : ''"
          >Par apprenants</b-button
        >
        <b-button
          @click="type = 'thrice'"
          :class="type === 'thrice' ? 'is-primary' : ''"
          >Vue globale</b-button
        >
      </div>
    </div>

    <div v-if="skillsList.length">
      <!-- <div class="helpUser mt-5">
        <div>Voici la liste des compétences associées à cette formation</div>
      </div> -->
      <div v-if="type === 'byskills'"><SkillsStudentsTable /></div>
      <div v-else-if="type === 'bystudents'">
        <SkillsStudentsTableByStudents />
      </div>
      <div v-else-if="type === 'thrice'">
        <b-message type="is-warning">
          Combien d'apprenants ont vu la compétence au moins 1 fois (quel que
          soit le rôle qui évalue) ?
        </b-message>
        <div class="mb-3 is-flex">
          <b-button
            rounded
            @click="collapseAll(true)"
            size="is-small"
            class="mr-2"
            >ouvrir tout</b-button
          >
          <b-button rounded size="is-small" @click="collapseAll(false)"
            >fermer tout</b-button
          >
          <div class="help-btn ml-auto">
            <AppHelpMessage
              title="Combien d'apprenants on eu au moins 3
          évaluations"
              message="<div class='subtitle-paragraph mb-2'><b>
Nombre d'apprenants ayant au moins 3 évaluations quel que soit le rôle</b></div><div
             class='progress-wrapper mb-1 mt-3'><progress max='10' class='progress
             is-small is-primary is-more-than-half' value='3'>3</progress></div>

<div class='subtitle-paragraph mb-2 mt-4'><b>Au moins 3 évaluations</b></div>
          <b>A : 2</b> -> 2 apprenants ont au moins réalisé 3 auto évaluations apprenant
          <br />
           <b>T : 2</b> -> 2 apprenants ont au moins réalisé 3 évaluations tuteur <br />
           <b>F : 2</b> -> 2 apprenants ont au moins réalisé 3 évaluations Formateur <br />"
              textBtn="Légende"
            />
          </div>
        </div>
        <div v-for="(block, indexBlock) of order(skillsList)" :key="indexBlock">
          <div class="card-header" role="button">
            <div class="bg-card is-flex bg-primary align-items-center">
              <a
                class="card-header-icon"
                @click="collapseBlock('s' + indexBlock, 'icon' + indexBlock)"
              >
                <div class="icon-collapse" :id="'icon' + indexBlock"></div>
              </a>
              <p class="card-header-title pr-3" v-if="block.cool_name">
                {{ block.cool_name }}
              </p>
              <p class="card-header-title pr-3" v-else>
                {{ block.name }}
              </p>
              <div class="actions is-flex align-items-center">
                <b-button
                  v-if="block.description"
                  type="is-primary is-shadowed is-small mr-1"
                  size=""
                  rounded
                  @click.prevent="seeBlockdetail(block)"
                  >voir</b-button
                >
              </div>
            </div>
          </div>
          <div
            class="card-content cardClosed"
            :id="'s' + indexBlock"
            :ref="'s' + indexBlock"
          >
            <div
              :list="block.tskills"
              :group="{ name: 'block' }"
              class="card-padding"
              :id="'content' + indexBlock"
            >
              <div
                class="columns is-vcentered justify-space-between ml-0 mr-0 card_simple mb-4"
                v-for="(skill, index) of block.tskills"
                :key="index"
                :keyblock="skill.block"
                style="min-height: 48px"
              >
                <div
                  class="column bloc-left is-flex mr-auto align-items-center w-100 pr-3"
                >
                  <div
                    class="w-100 is-clickable is-flex align-items-center"
                    @click.prevent="seeSkilldetail(skill, false)"
                  >
                    <span class="has-text-grey mr-2 is-hidden-mobile"
                      ><b-icon icon="check"></b-icon
                    ></span>
                    <span> {{ skill.name }}</span>
                  </div>
                </div>
                <div class="column is-4 pl-0 is-flex align-items-center">
                  <div class="icon-number is-clickable mr-2">
                    <b-icon icon="user" size="is-medium" type="is-black">
                    </b-icon>
                    <b-tag rounded
                      >{{ skill.students_evaluations.total_students }} /{{
                        students
                      }}</b-tag
                    >
                  </div>
                  <b-progress
                    format="percent"
                    :max="evaluations.total_evaluations"
                  >
                    <template #bar>
                      <b-progress-bar
                        :value="skill.students_evaluations.level1"
                        type="is-danger"
                      ></b-progress-bar>
                      <b-progress-bar
                        :value="skill.students_evaluations.level2"
                        type="is-warning"
                      ></b-progress-bar>
                      <b-progress-bar
                        :value="skill.students_evaluations.level3"
                        type="is-success"
                      ></b-progress-bar>
                      <b-progress-bar
                        :value="skill.students_evaluations.level4"
                        type="is-info"
                      ></b-progress-bar>
                    </template>
                  </b-progress>
                </div>
              </div>
            </div>
            <b-modal
              v-model="isSkillModalActive"
              has-modal-card
              trap-focus
              :destroy-on-hide="false"
              aria-role="dialog"
              aria-modal
            >
              <template>
                <div class="modal-card modal-users" style="width: auto">
                  <section class="modal-card-body">
                    <div class="has-text-right mb-3">
                      <a @click="isSkillModalActive = false" class="ml-auto">
                        <b-icon
                          class="closeButton"
                          icon="close"
                          type="is-primary"
                        ></b-icon>
                      </a>
                    </div>
                    <div class="has-text-small mb-2 mt-5">
                      <b>La compétence en détails</b>
                    </div>
                    <b-field label="Nom">
                      {{ formSkill.name }}
                    </b-field>
                    <b-field label="Description" v-if="formSkill.description">
                      {{ formSkill.description }}
                    </b-field>
                  </section>
                </div>
              </template>
            </b-modal>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <b-message type="is-info">
        Aucune compétence n'est associée à cette formation.
      </b-message>
    </div>
    <b-modal
      v-model="isBlockModalActive"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-modal
    >
      <template>
        <div class="modal-card modal-users" style="width: auto">
          <section class="modal-card-body">
            <div class="has-text-right mb-3">
              <a @click="isBlockModalActive = false" class="ml-auto">
                <b-icon
                  class="closeButton"
                  icon="close"
                  type="is-primary"
                ></b-icon>
              </a>
            </div>
            <div class="has-text-small mb-2 mt-5">
              <b>Le bloc de compétence en détails</b>
            </div>

            <b-field label="Nom">
              {{ formBlock.name }}
            </b-field>
            <b-field label="Référence">
              {{ formBlock.reference }}
            </b-field>
            <b-field label="Description">
              {{ formBlock.description }}
            </b-field>
          </section>
        </div>
      </template>
    </b-modal>
    <div id="endSkills">&nbsp;</div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import _ from 'lodash';
import AppHelpMessage from '@/components/AppHelpMessage.vue';
import SkillsStudentsTable from './SkillsStudentsTable.vue';
import SkillsStudentsTableByStudents from './SkillsStudentsTableByStudents.vue';

export default {
  props: {
    typeoftraining: {
      type: String,
    }, // framework, training or studenttraining
    objectId: {
      type: Number,
    },
  },
  components: {
    AppHelpMessage,
    SkillsStudentsTable,
    SkillsStudentsTableByStudents,
  },
  filters: {
  },
  data() {
    return {
      isLoading: false,
      isFullPage: false,
      formSkill: {
        id: '',
        name: '',
        description: '',
        block: '',
        reference: '',
      },
      formBlock: {
        id: '',
        name: '',
        description: '',
        block: '',
        reference: '',
      },
      skillsList: [],
      isOpenSkill: false,
      isOpen: false,
      isOpenAdd: false,
      isOpenBlock: true,
      isSkillModalActive: false,
      isBlockModalActive: false,
      isNew: true,
      myskillsList: [],
      skillsLevels: [],
      evaluations: [],
      type: 'byskills',
      // type: 'bystudents',
      students: null,
    };
  },
  computed: {
    ...mapState('auth', ['currentUser']),
  },
  methods: {
    startLoading() {
      this.isLoading = true;
    },
    endLoading() {
      this.isLoading = false;
    },
    order(array) {
      return _.orderBy(array, 'position');
    },
    initStudents() {
      this.startLoading();
      this.$http
        .get(`/trainings/${this.$route.params.idTraining}/?query={students}&format=json`)
        .then((response) => {
          this.students = response.data.students.length;
          this.endLoading();
        });
    },
    initSkills() {
      this.startLoading();
      this.$http
        .get(`/get_trainingskillsblock/?training=${this.objectId}&evaluations=1`)
        .then((response) => {
          this.skillsList = this.order(response.data.blocks);
          this.evaluations = response.data.skill_most_rated;
          if (this.typeoftraining === 'training') {
            /* eslint-disable */
            this.skillsList = this.skillsList.map(item => {
              return { tskills: item.tskills, name: item.name, reference: item.reference, id: item.id, training: item.training, description: item.description, position: item.position };
            });
            /* eslint-enable */
          }

          for (let i = 0; i < this.skillsList.length; i += 1) {
            const myBlocks = this.skillsList;
            // eslint-disable-next-line
            this.skillsList[i].tskills = this.order(myBlocks[i].tskills);
          }
          this.endLoading();
          this.initStudents();
        });
    },
    initLevels() {
      this.$http
        .get(`/traininglevels/?training=${this.objectId}&format=json`)
        .then((response) => {
          this.skillsLevels = this.order(response.data);
        });
    },
    openEdit(index) {
      if (this.isOpen !== index) {
        this.isOpen = index;
      } else {
        this.isOpen = false;
      }
    },
    seeSkilldetail(skill, newValue) {
      this.isSkillModalActive = true;
      this.formSkill.id = skill.id;
      this.formSkill.name = skill.name;
      this.formSkill.description = skill.description;
      this.formSkill.reference = skill.reference;
      this.formSkill.block = skill.block;
      this.isNew = newValue;
    },
    seeBlockdetail(block) {
      this.isBlockModalActive = true;
      this.formBlock.id = block.id;
      this.formBlock.name = block.name;
      this.formBlock.description = block.description;
      this.formBlock.reference = block.reference;
      this.isNew = false;
    },
    collapseBlock(id, icon) {
      const element = document.getElementById(id);
      element.classList.toggle('blockOpen');
      const iconOpen = document.getElementById(icon);
      iconOpen.classList.toggle('iconOpen');
    },
    collapseAll(allOpen) {
      for (let i = 0; i < this.skillsList.length; i += 1) {
        const element = document.getElementById(`s${i}`);
        const iconOpen = document.getElementById(`icon${i}`);
        if (allOpen) {
          if (!element.classList.contains('blockOpen')) { // jouvretout
            element.classList.add('blockOpen');
            iconOpen.classList.add('iconOpen');
          }
        } else if (!allOpen) {
          if (element.classList.contains('blockOpen')) {
            element.classList.remove('blockOpen');
            iconOpen.classList.remove('iconOpen');
          }
        }
      }
    },
    gotoSequence(check) {
      window.console.log(check);
      // if (check.free_situation) {
      //   window.console.log('free');
      // } else if (check.student_activity_revision) {
      //   window.console.log('revision');
      // } else {
      //   window.console.log('sequence');
      //   window.console.log(check);
      // }
    },
  },
  created() {
    // this.skillsList = this.skills;
    this.initSkills();
    this.initLevels();
  },
  mounted() {
  },
};
</script>

<style scoped>
.disabled {
  color: #dfdfeb;
}
.card-header-icon {
  width: auto;
  padding: 0px 0px 0px 0.5rem;
}
.card-header {
  margin-bottom: 0.5rem;
  transition: 0.2s;
}
/* .card-padding {
  padding: 1rem 0px;
} */
.closeButton {
  font-size: 1.5rem !important;
}
.modal-card {
  min-width: 627px;
}
/* .font-size:2rem */
.level-point {
  font-size: 2rem;
}
.has-text-warning {
  color: #ffc505 !important;
}
.card-skill {
  border-bottom: 1px solid #ededed;
  padding: 0.5rem;
}
.card-skill:last-child {
  border-bottom: 0px solid #ededed;
}

.icon {
  cursor: unset !important;
}
@media (max-width: 640px) {
  .modal-card {
    min-width: unset;
    width: 100%;
  }
}
</style>
