<template>
  <div>
    <b-modal v-model="isActive" scroll="keep" class="modalSimple" full-screen>
      <template #default="props">
        <UpdateDetail
          :feature.sync="featureSelected"
          @close="props.close"
        ></UpdateDetail>
      </template>
    </b-modal>
    <div class="helpInfo" @click="isActive = true">
      <b-icon icon="warning" class="pointer"></b-icon>
    </div>
  </div>
</template>

<script>
import UpdateDetail from '@/components/Dashboard/UpdateDetail.vue';

export default ({
  props: {
    featureSelected: {
      type: [Object, Array],
    },
  },
  components: {
    UpdateDetail,
  },
  data() {
    return {
      isOpen: null,
      isActive: false,
    };
  },
});

</script>

<style scoped>
.stat_cardlabel .modalSimple {
  text-transform: initial !important;
  letter-spacing: 0px;
  padding-bottom: 0px;
  font-size: 1rem;
  text-align: left;
  color: #000;
}
</style>
