<template>
  <div>
    <b-message v-if="!studenttrainings.length" type="is-info">
      Pour le moment, vous n'avez ajouté aucun apprenant pour cette formation,
      n'hésitez pas à en ajouter en revenant à l'onglet précédent
    </b-message>
    <div class="contentActivitytableUsers pt-1">
      <b-loading
        :is-full-page="false"
        v-model="isLoading"
        :can-cancel="true"
      ></b-loading>
      <section id="students">
        <div v-if="!studenttrainings.length" class="no-user">0 apprenants</div>
        <div v-else>
          <div class="userCount mb-4">
            {{ studenttrainings.length }} apprenant<span
              v-if="studenttrainings.length > 1"
              >s</span
            >
          </div>
          <div class="frowho mt-0">
            <b-icon icon="user" type="is-primary"></b-icon>
            <div
              class="is-flex justify-content-between align-items-center w-100"
            >
              <div>Associez des managers et des tuteurs à des apprenants</div>
              <div></div>
            </div>
          </div>

          <div class="is-flex justify-content-between align-items-center">
            <b-collapse
              :open="false"
              animation="slide"
              aria-id="contentIdForA11y3"
            >
              <div
                slot="trigger"
                class="is-flex"
                slot-scope="props"
                role="button"
                aria-controls="visibleColumns"
              >
                <a>
                  <b-icon :icon="props.open ? 'menu-down' : 'menu-up'">
                  </b-icon>
                </a>
                <p class="card-header-title">Options d'affichage</p>
              </div>
              <div class="card-content">
                <div class="content">
                  <b-field grouped group-multiline>
                    <div
                      v-for="(column, index) in studentsColumns"
                      :key="index"
                      class="control"
                    >
                      <b-checkbox v-model="column.visible">
                        {{ column.label }}
                      </b-checkbox>
                    </div>
                  </b-field>
                </div>
              </div>
            </b-collapse>
          </div>
          <b-table
            v-if="training.framework_type"
            :data="studenttrainings"
            ref="table"
            :paginated="isPaginated"
            :per-page="perPage"
            :current-page="currentPage"
            :pagination-simple="isPaginationSimple"
            :pagination-position="paginationPosition"
            :sort-icon-size="sortIconSize"
            :default-sort-direction="defaultSortDirection"
            :debounce-search="1000"
            checkable
            :checked-rows.sync="checkedRows"
            default-sort="name"
          >
            <template v-for="column in studentsColumns">
              <b-table-column
                :key="column.label"
                v-bind="column"
                :label="
                  training.framework_type.id === 3 &&
                  column.field === 'teachers_profiles'
                    ? column.alias
                    : column.label
                "
              >
                <template
                  v-if="column.searchable && !column.numeric"
                  slot="searchable"
                  slot-scope="props"
                >
                  <b-input
                    v-model="props.filters[props.column.field]"
                    placeholder="Rechercher..."
                    icon="search"
                    size="is-small"
                  />
                </template>
                <template
                  v-slot="props"
                  v-if="column.field === 'student_profile'"
                >
                  <b> {{ props.row.student_profile }}</b>
                </template>
                <template
                  v-slot="props"
                  v-else-if="column.field === 'organization'"
                >
                  <b v-if="getUser(props.row.student)">
                    <span v-if="getUser(props.row.student).organization">
                      {{ getUser(props.row.student).organization.name }}
                    </span>
                  </b>
                  <b v-else></b>
                </template>
                <template
                  v-slot="props"
                  v-else-if="column.field === 'managers_profiles'"
                >
                  <span v-for="manager in props.row.managers" :key="manager">
                    {{ getUser(manager).user.first_name }}
                    {{ getUser(manager).user.last_name }}
                    <a
                      @click="
                        removeOneManager([props.row.id], manager, props.index)
                      "
                    >
                      <b-icon icon="close"></b-icon
                    ></a>
                  </span>
                </template>

                <template
                  v-slot="props"
                  v-else-if="column.field === 'tutors_profiles'"
                >
                  {{ String(props.row.tutors_profiles) }}
                </template>
                <template
                  v-slot="props"
                  v-else-if="column.field === 'teachers_profiles'"
                >
                  {{ String(props.row.teachers_profiles) }}
                </template>
              </b-table-column>
            </template>
          </b-table>
        </div>
        <button
          class="button is-primary mr-3 ml-auto"
          @click="isManagerModalActive = true"
          :disabled="!checkedRows.length"
        >
          associer un manager
        </button>
        <button
          class="button is-primary mr-3"
          @click="isTutorModalActive = true"
          :disabled="!checkedRows.length"
        >
          associer un tuteur
        </button>
        <button
          v-if="training.framework_type"
          class="button is-primary mr-3 ml-auto"
          @click="isTeacherModalActive = true"
          :disabled="!checkedRows.length"
        >
          associer un
          <span v-if="training.framework_type.id !== 3">formateur</span
          ><span>coach</span>
        </button>
        <div class="mt-4">
          <button
            class="button is-danger mr-3 ml-auto"
            @click="isManagerRemoveActive = true"
            :disabled="!checkedRows.length"
          >
            supprimer un manager
          </button>
        </div>

        <b-modal
          v-model="isManagerModalActive"
          has-modal-card
          trap-focus
          :destroy-on-hide="false"
          aria-role="dialog"
          aria-modal
        >
          <template>
            <div
              class="modal-card modal-users has-text-centered"
              style="width: auto"
            >
              <section class="modal-card-body" v-if="training.managers">
                <div class="has-text-right mb-3">
                  <a @click="isManagerModalActive = false" class="ml-auto">
                    <b-icon icon="close" type="is-primary"></b-icon>
                  </a>
                </div>
                <div v-if="training.managers.length">
                  <span class="mr-3 mb-4"
                    ><b>Leur associer un manager : </b></span
                  >
                  <b-select
                    v-model="id_manager"
                    class="w-100 mt-5 mb-5"
                    placeholder="Choisissez un manager"
                  >
                    <option
                      v-for="manager in this.training.managers"
                      :value="manager"
                      :key="manager"
                      class="w-100"
                    >
                      <span v-if="getUser(manager)">
                        {{ getUser(manager).user.first_name }}
                        {{ getUser(manager).user.last_name }}
                      </span>
                    </option>
                  </b-select>
                  <button
                    class="button is-success mt-4 ml-auto mr-auto mb-6"
                    @click="linkManager2Students(checkedRows, id_manager)"
                  >
                    associer le manager
                  </button>
                </div>
                <div v-else>
                  <div>
                    <b>Vous n'avez associé aucun manager à cette formation</b>
                  </div>
                  Ajoutez d'abord un manager dans la liste des utilisateurs pour
                  l'associer à un apprenant.
                </div>
              </section>
            </div>
          </template>
        </b-modal>
        <b-modal
          v-model="isManagerRemoveActive"
          has-modal-card
          trap-focus
          :destroy-on-hide="false"
          aria-role="dialog"
          aria-modal
          :can-cancel="false"
        >
          <template>
            <div
              class="modal-card modal-users has-text-centered"
              style="width: auto"
            >
              <section class="modal-card-body">
                <div class="has-text-right mb-3">
                  <a @click="isManagerRemoveActive = false" class="ml-auto">
                    <b-icon icon="close" type="is-primary"></b-icon>
                  </a>
                </div>
                <span class="mr-3 mb-4"
                  ><b
                    >Supprimer un manager pour les apprenants sélectionnés :
                  </b></span
                >
                <b-select
                  v-model="id_manager"
                  class="w-100 mt-5 mb-5"
                  placeholder="Choisissez un manager"
                >
                  <option
                    v-for="manager in this.training.managers"
                    :value="manager"
                    :key="manager"
                    class="w-100"
                  >
                    <span v-if="getUser(manager)">
                      {{ getUser(manager).user.first_name }}
                      {{ getUser(manager).user.last_name }}
                    </span>
                  </option>
                </b-select>
                <button
                  class="button is-success mt-4 ml-auto mr-auto mb-6"
                  @click="removeManager2Students(checkedRows, id_manager)"
                >
                  {{ this.id_manager }}
                  supprimer le manager
                </button>
              </section>
            </div>
          </template>
        </b-modal>
        <b-modal
          v-model="isTutorModalActive"
          has-modal-card
          :can-cancel="false"
          full-screen
        >
          <ModalTutors
            v-bind:studenttrainingsObject="studenttrainings"
            v-bind:training="training"
            v-bind:usersObject="users"
            v-bind:checkedRows.sync="checkedRows"
            @messageFromChild="initStudenttrainings"
          ></ModalTutors>
        </b-modal>
        <b-modal
          v-model="isTeacherModalActive"
          has-modal-card
          trap-focus
          :destroy-on-hide="false"
          aria-role="dialog"
          aria-modal
        >
          <template>
            <div
              class="modal-card modal-users has-text-centered"
              style="width: auto"
            >
              <section class="modal-card-body" v-if="training.teachers">
                <div class="has-text-right mb-3">
                  <a @click="isTeacherModalActive = false" class="ml-auto">
                    <b-icon icon="close" type="is-primary"></b-icon>
                  </a>
                </div>
                <div v-if="training.teachers.length">
                  <span class="mr-3 mb-4"
                    ><b>Leur associer un {{ teacherAlias }} : </b></span
                  >
                  <b-select
                    v-model="id_teacher"
                    class="w-100 mt-5 mb-5"
                    :placeholder="'Choisissez un ' + teacherAlias"
                  >
                    <option
                      v-for="teacher in this.training.teachers"
                      :value="teacher"
                      :key="teacher"
                      class="w-100"
                    >
                      <span v-if="getUser(teacher)">
                        {{ getUser(teacher).user.first_name }}
                        {{ getUser(teacher).user.last_name }}
                      </span>
                    </option>
                  </b-select>
                  <button
                    class="button is-success mt-4 ml-auto mr-auto mb-6"
                    @click="linkTeacher2Students(checkedRows, id_teacher)"
                  >
                    associer le {{ teacherAlias }}
                  </button>
                </div>
                <div v-else>
                  <div>
                    <b
                      >Vous n'avez associé aucun {{ teacherAlias }} à cette
                      formation</b
                    >
                  </div>
                  Ajoutez d'abord un {{ teacherAlias }} dans la liste des
                  utilisateurs pour l'associer à un apprenant.
                </div>
              </section>
            </div>
          </template>
        </b-modal>
      </section>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import _ from 'lodash';
import ModalTutors from '@/components/Trainings/ModalTutors.vue';

export default {
  props: {
    trainingObject: {
      type: Object,
    },
  },
  components: {
    ModalTutors,
  },
  computed: {
    users() {
      return this.$store.state.users;
    },
    studenttrainings() {
      return this.$store.state.studenttrainings;
    },
    training() {
      return this.$store.state.training;
    },
    ...mapGetters(['getUserById']),
  },
  methods: {
    order(array) {
      return _.orderBy(array, 'position');
    },
    gotoStep(numberStep) {
      this.currentTab = numberStep;
      this.step = numberStep;
    },

    // cet appel au training sert pour l'attribution des tuteurs
    // à des expériences quand on appele le composant ModalTutors

    initTraining() {
      this.$http
        .get(`/trainings/${this.$route.params.idTraining}/?query={*,training_periods{*,training_sequences{-training_activities}}}&format=json`)
        .then((response) => {
          this.$store.commit('GET_TRAINING', response.data);
          this.training.training_periods = this.order(this.training.training_periods);
          if (this.training.framework_type.id === 3) {
            this.teacherAlias = 'coach';
          }
          // this.editTrainingSteps();
          for (let i = 0; i < this.training.training_periods.length; i += 1) {
            const myPeriods = this.training.training_periods;
            // eslint-disable-next-line
            this.training.training_periods[i].training_sequences = this.order(myPeriods[i].training_sequences);
          }
        });
    },
    // function users
    initUsers() {
      this.$http
        .get('/profile/?query={id,organization{name},favorite_role,user{first_name,last_name}}&format=json')
        .then((response) => {
          this.$store.commit('GET_USERS', response.data);
          this.endLoading();
        });
    },
    initStudenttrainings() {
      this.checkedRows = [];
      this.$http
        .get(`/agenda/student_training/?training=${this.$route.params.idTraining}&query={id,student,organization,student_profile,managers,managers_profiles,tutors_profiles,teachers,teachers_profiles}&format=json`)
        .then((response) => {
          this.$store.commit('GET_STUDENTTRAININGS', response.data);
        });
    },

    getUser(id) {
      // getter to get all informations on a user by id
      return this.$store.getters.getUserById(id);
    },
    // function to see detailed "ruban pédagogique" by student
    seeStudentTraining(studentId) {
      this.$router.push({
        name: 'StudentTraining',
        params: { idStudent: studentId, idTraining: this.$route.params.idTraining, tab: 'parcours' },
      });
    },
    linkManager2Students(ids, idManager) {
      // link a manager to various students
      const stIds = [];
      for (let i = 0; i < this.checkedRows.length; i += 1) {
        stIds.push(
          ids[i].id,
        );
      }
      this.$http
        .post('/agenda/manager2studenttraining/?format=json', {
          manager_id: idManager,
          student_training_ids: stIds,
        }).then(() => {
          this.checkedRows = [];
          this.$buefy.notification.open('Managers ajoutés');
          this.initStudenttrainings();
        });
    },
    linkTeacher2Students(ids, idTeacher) {
      window.console.log(ids);
      window.console.log(idTeacher);
      // link a manager to various students
      const stIds = [];
      for (let i = 0; i < this.checkedRows.length; i += 1) {
        stIds.push(
          ids[i].id,
        );
        this.$http.patch(`/agenda/student_training/${this.checkedRows[i].id}/`, {
          teachers: [idTeacher],
        }).then(() => {
          this.checkedRows = [];
          this.$buefy.notification.open('Profils ajoutés');
          this.initStudenttrainings();
        });
      }
      /* this.$http
        .post('/agenda/teacher2studenttraining/?format=json', {
          teacher_id: idTeacher,
          student_training_ids: stIds,
        }).then(() => {
          this.checkedRows = [];
          this.$buefy.notification.open('Profils ajoutés');
          this.initStudenttrainings();
        }); */
    },
    removeManager2Students(ids, idManager) {
      // link a manager to various students
      for (let i = 0; i < ids.length; i += 1) {
        const indextoremove = this.checkedRows[i].managers.indexOf(idManager);
        this.checkedRows[i].managers.splice(indextoremove, 1);
        this.$http
          .patch(`/agenda/student_training/${this.checkedRows[i].id}/`, {
            managers: this.checkedRows[i].managers,
          }).then(() => {
            this.$buefy.notification.open('Managers supprimés');
            this.initStudenttrainings();
          });
      }
    },
    removeOneManager(id, idManager, index) {
      this.studentsIds = [];
      this.startLoading();
      const indextoremove = this.studenttrainings[index].managers.indexOf(idManager);
      // this.studentsIds = this.studenttrainings;
      this.studenttrainings[index].managers.splice(indextoremove, 1);
      this.$http
        .patch(`/agenda/student_training/${id}/`, {
          managers: this.studenttrainings[index].managers,
        }).then(() => {
          this.$buefy.notification.open('Managers supprimés');
          this.initStudenttrainings();
          this.endLoading();
        });
    },
    startLoading() {
      this.isLoading = true;
    },
    endLoading() {
      this.isLoading = false;
    },
  },
  created() {
    this.initUsers();
  },
  mounted() {
    // this.training = this.trainingObject;
    this.initTraining();
    this.initStudenttrainings();
    this.startLoading();
  },
  data() {
    return {
      // training: [Object, Array],
      isLoading: false,
      isFullPage: false,
      // declaration variables for users tabs
      step: 1,
      currentTab: 1,
      tabs: [
        {
          name: 'Formateurs',
          id: '1',
        },
        {
          name: 'Apprenants',
          id: '2',
        },
        {
          name: 'Managers',
          id: '3',
        },
        {
          name: 'Tuteurs',
          id: '4',
        },
        {
          name: 'Formateurs',
          id: '5',
        },
      ],
      // variables for table
      isPaginated: true,
      isPaginationSimple: false,
      paginationPosition: 'bottom',
      sortIconSize: 'is-small',
      defaultSortDirection: 'asc',
      currentPage: 1,
      perPage: 25,
      // columns related to checkboxs above table
      studentsColumns: [
        {
          field: 'student_profile',
          label: 'Nom',
          width: '200',
          searchable: true,
          sortable: true,
          visible: true,
        },
        {
          field: 'organization',
          label: 'Organisation',
          centered: true,
          searchable: true,
          sortable: true,
          visible: true,
        },
        {
          field: 'managers_profiles',
          label: 'Manager(s)',
          searchable: true,
          sortable: true,
          visible: true,
        },
        {
          field: 'tutors_profiles',
          label: 'Tuteur(s)',
          searchable: true,
          sortable: true,
          visible: true,
        },
        {
          field: 'teachers_profiles',
          label: 'Formateur(s)',
          alias: 'Coach(s)',
          searchable: true,
          sortable: true,
          visible: true,
        },
      ],
      // array to collect informations from students
      studentsArray: [
      ],
      // array to clear check
      checkedRows: [],
      studentsIds: [],
      // variables to collapse selec user
      openManagers: false,
      openTutors: false,
      id_manager: 0,
      id_tutor: 0,
      id_teacher: 0,
      isManagerModalActive: false,
      isManagerRemoveActive: false,
      isTutorModalActive: false,
      isTeacherModalActive: false,
      managerstoRemove: [],
      teacherAlias: 'formateur',
    };
  },
};
</script>
