 <template>
  <div>
    <b-loading :is-full-page="isFullPage" v-model="isLoading"> </b-loading>
    <div class="caption is-flex align-items-center">
      <!-- <div class="caption-item">
        <img
          :src="require(`@/assets/img/steps/status3.svg`)"
          alt=""
          class="img_status"
        />
        validé
      </div> -->
      <div class="caption-item is-danger"></div>
      <span class="mr-3 is-flex align-items-center"
        ><span class="tag is-warning mr-1 puce"></span>créé</span
      >
      <span class="mr-3 is-flex align-items-center"
        ><span class="tag is-primary mr-1 puce"></span>terminé</span
      >
      <span class="is-flex align-items-center"
        ><span class="tag is-success mr-1 puce"></span>lu par le tuteur</span
      >
    </div>
    <div
      ref="stepsContent"
      id="stepsContent"
      v-if="waypoints"
      class="listWaypoints"
    >
      <div
        @click="gotoNow()"
        class="line-now is-clickable"
        :style="'left:' + nowPosition + 'px; height :' + heightLine + 'px;'"
      ></div>
      <b-table
        :data="studentsArray"
        checkable
        :checkbox-position="'left'"
        :checkbox-type="'is-primary'"
        :default-sort="['name', 'asc']"
        :checked-rows.sync="checkedRows"
        @input="check"
      >
        <template v-for="column in columns">
          <b-table-column :key="column.id" v-bind="column">
            <template
              v-if="column.searchable && !column.numeric"
              slot="searchable"
              slot-scope="props"
            >
              <b-input
                v-model="props.filters[props.column.field]"
                placeholder="Rechercher..."
                icon="search"
                size="is-small"
              />
            </template>
            <template
              v-slot="props"
              v-if="column.field === 'name'"
              style="max-width: 161px"
            >
              <b
                class="is-clickable"
                @click.prevent="
                  seeStudentTraining(
                    props.row.student_id,
                    props.row.student_training
                  )
                "
              >
                {{ props.row[column.field] }}</b
              >
            </template>
            <template v-slot="props" v-else-if="column.field === 'booklet'">
              <div class="bookletSteps is-flex align-items-center">
                <div class="waypoint_type">CFA</div>
                <div
                  v-if="props.row.waypoint_cfa.length"
                  class="is-flex align-items-center"
                >
                  <div
                    v-for="(waypoint, index) in props.row.waypoint_cfa"
                    :key="waypoint.id"
                    class="bookletStep is-clickable"
                    @click="
                      gotoWaypoint(
                        waypoint.id,
                        props.row.student_id,
                        props.row.student_training
                      )
                    "
                  >
                    <b-tooltip
                      :label="waypoint.title"
                      position="is-bottom"
                      multilined
                      animated
                      type="is-primary is-light"
                      size="is-small"
                    >
                      <span
                        class="tag is-primary"
                        v-if="waypoint.validated && !waypoint.read_by_tutor"
                        >{{ index + 1 }}</span
                      >
                      <span
                        class="tag is-success"
                        v-else-if="waypoint.validated && waypoint.read_by_tutor"
                        >{{ index + 1 }}</span
                      >
                      <span
                        v-else-if="
                          !waypoint.validated ||
                          (!waypoint.validated && !waypoint.read_by_tutor)
                        "
                        class="tag is-warning"
                        >{{ index + 1 }}</span
                      ></b-tooltip
                    >
                  </div>
                </div>
                <div v-else>aucun point cfa</div>
              </div>
              <div class="bookletSteps is-flex align-items-center">
                <div class="waypoint_type">Entreprise</div>
                <div
                  v-if="props.row.waypoint_atwork.length"
                  class="is-flex align-items-center"
                >
                  <div
                    v-for="(waypointatwork, index) in props.row.waypoint_atwork"
                    :key="waypointatwork.id"
                    class="bookletStep is-clickable"
                    @click="
                      gotoWaypoint(
                        waypointatwork.id,
                        props.row.student_id,
                        props.row.student_training
                      )
                    "
                  >
                    <!-- <span
                      class="tag atwork is-success"
                      v-if="waypointatwork.validated"
                      >{{ index + 1 }}</span
                    >
                    <span v-else class="tag atwork is-primary">{{
                      index + 1
                    }}</span> -->
                    <b-tooltip
                      :label="
                        new Date(
                          waypointatwork.start_date
                        ).toLocaleDateString() +
                        ' - ' +
                        new Date(waypointatwork.end_date).toLocaleDateString()
                      "
                      position="is-bottom"
                      multilined
                      type="is-primary is-light"
                    >
                      <span
                        class="tag atwork is-primary"
                        v-if="
                          waypointatwork.validated &&
                          !waypointatwork.read_by_tutor
                        "
                        >{{ index + 1 }}</span
                      >
                      <span
                        class="tag atwork is-success"
                        v-else-if="
                          waypointatwork.validated &&
                          waypointatwork.read_by_tutor
                        "
                        >{{ index + 1 }}</span
                      >
                      <span
                        v-else-if="
                          !waypointatwork.validated ||
                          (!waypointatwork.validated && !waypoint.read_by_tutor)
                        "
                        class="tag atwork is-warning"
                        >{{ index + 1 }}</span
                      ></b-tooltip
                    >
                  </div>
                </div>
                <div v-else>aucun point entreprise</div>
              </div>
              <div class="bookletSteps is-flex align-items-center">
                <div class="waypoint_type">Comportement</div>
                <div
                  v-if="props.row.waypoint_attitude.length"
                  class="is-flex align-items-center"
                >
                  <div
                    v-for="(waypoint_attitude, index) in props.row
                      .waypoint_attitude"
                    :key="waypoint_attitude.id"
                    class="bookletStep is-clickable"
                    @click="
                      gotoAttitudeWaypoint(
                        waypoint_attitude.id,
                        props.row.student_id,
                        props.row.student_training
                      )
                    "
                  >
                    <!-- <span
                      class="tag atwork is-success"
                      v-if="waypointatwork.validated"
                      >{{ index + 1 }}</span
                    >
                    <span v-else class="tag atwork is-primary">{{
                      index + 1
                    }}</span> -->
                    <span
                      class="tag atwork is-primary"
                      v-if="waypoint_attitude.validated"
                      >{{ index + 1 }}</span
                    >
                    <span
                      class="tag atwork is-success"
                      v-else-if="waypoint_attitude.read_by_tutor"
                      >{{ index + 1 }}</span
                    >
                    <span
                      v-else-if="
                        !waypoint_attitude.validated ||
                        (!waypoint_attitude.validated &&
                          !waypoint_attitude.read_by_tutor)
                      "
                      class="tag atwork is-warning"
                      >{{ index + 1 }}</span
                    >
                  </div>
                </div>
                <div v-else>aucun point comportement</div>
              </div>
            </template>
            <template
              v-slot="props"
              v-else-if="column.field === 'id_student_training'"
            >
              <div
                @click.prevent="
                  seeStudentTraining(
                    props.row.student_id,
                    props.row.student_training
                  )
                "
              >
                <b-icon icon="see"></b-icon>
              </div>
            </template>
          </b-table-column>
        </template>
      </b-table>
    </div>
    <b-button
      :disabled="!checkedRows.length"
      class="button btn btn-secondary is-primary mr-3 is-rounded mb-5"
      @click="isWaypointModalActive = true"
    >
      Ajouter un point d'étape</b-button
    >
    <b-modal
      v-model="isWaypointModalActive"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-modal
      id="modalNude"
      full-screen
    >
      <template>
        <div
          class="modal-card modal-users has-text-centered pb-2"
          style="width: auto"
          id="modalWaypoint"
        >
          <section class="modal-card-body">
            <div class="has-text-right">
              <a
                @click="
                  isWaypointModalActive = false;
                  clearForm();
                "
                class="ml-auto"
              >
                <b-icon icon="close" type="is-primary"></b-icon>
              </a>
            </div>
            <div class="has-text-small mb-2-">
              <b class="title-paragraph"
                >Définissez le point d'étape à ajouter un ou plusieurs
                apprenants</b
              >
            </div>

            <b-field class="column is-narrow mr-3 mb-0">
              <b-select
                placeholder="Type de situation"
                icon="earth"
                v-model="formWaypoint.type"
              >
                <option value="1" v-if="currentUser.role !== 'tuteur'">
                  Point en centre de formation
                </option>
                <option value="0">Point en entreprise</option>
                <option value="2">Point comportement</option>
              </b-select>
            </b-field>
            <span v-if="formWaypoint.start_date">
              du {{ formWaypoint.start_date | day }}</span
            >
            <span v-if="formWaypoint.end_date">
              au {{ formWaypoint.end_date | day }}</span
            >
            <b-button
              v-if="!editDates"
              type="is-rounded mr-2 ml-2"
              size="is-small"
              @click="editDates = !editDates"
              ><span>modifier</span></b-button
            >
            <div
              class="columns is-tablet is-vcentered is-centered mb-0"
              v-if="editDates"
            >
              <div class="column is-narrow">
                <b-field label="Date de début de période">
                  <b-datepicker
                    v-model="formWaypoint.start_date"
                    :locale="locale"
                    :show-week-number="showWeekNumber"
                    :first-day-of-week="1"
                    icon="calendar-today"
                    trap-focus
                    inline
                    @input="verifDatepicker"
                  >
                  </b-datepicker>
                </b-field>
              </div>
              <div class="column is-narrow">
                <b-field label="Date de fin de période">
                  <b-datepicker
                    v-model="formWaypoint.end_date"
                    :show-week-number="showWeekNumber"
                    :locale="locale"
                    icon="calendar-today"
                    trap-focus
                    inline
                    :first-day-of-week="1"
                    @input="verifDatepicker"
                  >
                  </b-datepicker>
                </b-field>
              </div>
            </div>
            <div class="container mt-3">
              <b-message type="is-danger">
                <div
                  v-if="
                    formWaypoint.start_date > formWaypoint.end_date &&
                    !editDates
                  "
                >
                  Attention, il semble y a une incohérence entre la date de
                  début et la date de fin. La date de début est postérieure à la
                  date de fin.
                </div>
              </b-message>
              <div v-if="formWaypoint.start_date < new Date() && !editDates">
                <b-message type="is-warning">
                  Attention, la date de début est déjà passée. Vérifiez si c'est
                  bien normal.</b-message
                >
              </div>
            </div>
            <div class="container mt-4">
              <div class="title2">Destinataires</div>
              <div class="card_grey has-text-left">
                <span v-for="(student, index) in checkedRows" :key="student.id"
                  >{{ student.name
                  }}<span v-if="index < checkedRows.length - 1">, </span></span
                >
              </div>
            </div>
            <div class="container">
              <div class="title2">Titre du point (facultatif)</div>
              <b-input v-model="formWaypoint.title"></b-input>
              <div v-if="formWaypoint.type === '1'">
                <div class="title2">Objectifs de la période</div>
                <ckeditor
                  v-model="formWaypoint.goals"
                  :editor="editor"
                  :config="editorConfig"
                  class="mb-5"
                  :ref="'revisionContent'"
                ></ckeditor>
                <div class="title2">
                  Liste des modules travaillés pendant cette période en
                  formation
                </div>

                <div
                  class="listActivities"
                  v-if="schoolSubjectsSelected.length"
                >
                  <div class="subtitle-paragraph has-text-left">
                    <div>
                      Vous pouvez sélectionner parmi la liste complète des
                      modules de votre formation ou utilisez le champ de texte
                      libre ci-dessous. Précisez ensuite, pour chacun des
                      modules, ce que vous avez travaillé plus précisément. Vous
                      pouvez aussi laisser les apprenants les saisir eux-même
                      plus tard.
                    </div>
                  </div>
                  <b-collapse
                    class="card"
                    animation="slide"
                    :open="isOpenActivities"
                  >
                    <div
                      slot="trigger"
                      slot-scope="props"
                      class="card-header"
                      role="button"
                    >
                      <div
                        class="bg-card is-flex bg-primary align-items-center"
                      >
                        <a class="card-header-icon">
                          <b-icon
                            v-bind:icon="props.open ? 'menu-down' : 'menu-up'"
                          >
                          </b-icon>
                        </a>
                        <p class="card-header-title">
                          Liste des modules de votre formation
                        </p>
                      </div>
                    </div>
                    <div class="card-content">
                      <div
                        class="card_item pointer w-100"
                        v-for="subject in schoolSubjectsSelected"
                        :key="subject.id"
                      >
                        <div class="is-flex align-items-center">
                          <b-checkbox
                            v-model="subject.checked"
                            :native-value="false"
                          >
                          </b-checkbox>

                          <div>
                            <div class="type-sequence">
                              {{ subject.name }}
                            </div>
                          </div>
                        </div>
                        <div
                          class="ck-editor-block mt-3 mb-0"
                          v-if="subject.checked"
                        >
                          <ckeditor
                            v-model="subject.report"
                            :editor="editor"
                            :config="editorConfig"
                            class="mb-5"
                            :ref="'revisionContent'"
                          ></ckeditor>
                        </div>
                      </div>
                    </div>
                  </b-collapse>
                </div>
                <div v-else>
                  <div class="subtitle-paragraph has-text-left">
                    Vous pouvez saisir la liste des modules vus pendant la
                    période en formation et le détail ce que vous avez travaillé
                    plus précisément pour chacun ou laisser les apprenants les
                    saisir eux-même plus tard.
                  </div>
                </div>
                <!-- <b-field
                  class="has-text-left"
                  label="Si vous avez un document à joindre à
           ce point d'étape, vous pouvez saisir ici son url"
                >
                  <b-input v-model="formWaypoint.url"></b-input>
                </b-field> -->

                <ckeditor
                  v-model="formWaypoint.free_text"
                  :editor="editor"
                  :config="editorConfig"
                  class="mb-5"
                  :ref="'revisionContent'"
                ></ckeditor>
                <div class="title2">Commentaire du pilote</div>
                <div class="subtitle-paragraph has-text-left">
                  Il sera visible pour chaque apprenant. Vous pourrez cependant
                  ajouter un commentaire personnalisé par apprenant par la
                  suite.
                </div>
                <ckeditor
                  v-model="formWaypoint.teacher_comment"
                  :editor="editor"
                  :config="editorConfig"
                  class="mb-5"
                  :ref="'revisionContent'"
                ></ckeditor>
              </div>
              <div v-if="formWaypoint.type === '0'">
                <div class="title2">Objectifs de la période</div>
                <ckeditor
                  v-model="formWaypoint.goals"
                  :editor="editor"
                  :config="editorConfig"
                  class="mb-5"
                  :ref="'revisionContent'"
                ></ckeditor>
              </div>
              <div v-if="formWaypoint.type === '2'">
                <div class="title2">Objectifs de ce point</div>
                <ckeditor
                  v-model="formWaypoint.goals"
                  :editor="editor"
                  :config="editorConfig"
                  class="mb-5"
                  :ref="'revisionContent'"
                ></ckeditor>
              </div>
            </div>
            <b-button
              type="is-primary"
              @click="postAllWaypoint()"
              v-if="formWaypoint.type === '0' || formWaypoint.type === '1'"
              :disabled="multiple_value_boolean === false"
              >Ajouter le point d'étape</b-button
            >
            <b-button
              type="is-primary"
              @click="postAllWaypoint()"
              v-if="formWaypoint.type === '2'"
              :disabled="multiple_value_boolean === false"
              >Ajouter le point comportement</b-button
            >
          </section>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';
import CKEditor from '@ckeditor/ckeditor5-vue2';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import '@ckeditor/ckeditor5-build-classic/build/translations/fr';

export default {
  props: {
    typeoftraining: {
      type: String,
    }, // framework, training or studenttraining
    objectId: {
      type: Number,
    },
    contentWidth: {
      type: Number,
    },
  },
  components: {
    ckeditor: CKEditor.component,
  },
  filters: {
    day(date) {
      return moment(date).format('DD/MM/YYYY');
    },
  },
  computed: {
    ...mapState('auth', ['currentUser']),
    multiple_value_boolean() {
      return this.formWaypoint.type !== null
        && this.formWaypoint.start_date !== null
        && this.formWaypoint.end_date !== null
        && this.formWaypoint.start_date < this.formWaypoint.end_date;
    },
  },
  data() {
    return {
      today: new Date(),
      locale: 'UTC',
      checkedRows: [],
      isLoading: false,
      isFullPage: false,
      trainingSteps: [],
      activeTab: 'all',
      editDates: true,
      columns: [
        {
          field: 'name',
          label: 'Nom',
          width: '161',
          searchable: false,
          sortable: true,
          visible: true,
        },
        {
          field: 'booklet',
          label: 'Livret',
          width: 'auto',
          searchable: false,
          sortable: true,
          visible: true,
        },
        {
          field: 'id_student_training',
          label: 'Détail',
          width: '50',
          centered: true,
          searchable: false,
          sortable: true,
          visible: true,
        },
        {
          field: 'clear',
          label: '',
          width: '10',
          centered: true,
          searchable: false,
          sortable: true,
          visible: true,
        },

      ],
      waypoints: [
        {
          name: 'Bitoune Julien ',
          id_student_training: '1',
          waypoint_cfa: [
            {
              id: 1,
              validated: true,
            },
            {
              id: 2,
              validated: true,
            },
            {
              id: 3,
              validated: true,
            },
            {
              id: 4,
              validated: true,
            }, {
              id: 5,
              validated: true,
            }, {
              id: 6,
              validated: true,
            }, {
              id: 7,
              validated: true,
            }, {
              id: 8,
              validated: true,
            }, {
              id: 9,
              validated: true,
            }, {
              id: 10,
              validated: true,
            }, {
              id: 11,
              validated: false,
            }, {
              id: 12,
              validated: true,
            }, {
              id: 13,
              validated: false,
            },
          ],
          waypoint_atwork: [
            {
              id: 1,
              validated: true,
            },
            {
              id: 2,
              validated: true,
            },
          ],
        },
        {
          name: 'Bornée Elisabeth ',
          tutor: 'Gerald Armealamain',
          id_student_training: '1',
          waypoint_cfa: [
            {
              id: 1,
              validated: true,
            },
            {
              id: 2,
              validated: true,
            },
            {
              id: 3,
              validated: true,
            },
            {
              id: 4,
              validated: true,
            }, {
              id: 5,
              validated: true,
            }, {
              id: 6,
              validated: true,
            }, {
              id: 7,
              validated: true,
            }, {
              id: 8,
              validated: true,
            }, {
              id: 9,
              validated: true,
            }, {
              id: 10,
              validated: true,
            }, {
              id: 11,
              validated: true,
            }, {
              id: 12,
              validated: true,
            }, {
              id: 13,
              validated: false,
            },
          ],
          waypoint_atwork: [
            {
              id: 1,
              validated: true,
            },
            {
              id: 2,
              validated: true,
            },
            {
              id: 2,
              validated: true,
            },
            {
              id: 13,
              validated: false,
            },
          ],
        },
        {
          name: 'TraoréAboubakar',
          id_student_training: '1',
          waypoint_cfa: [
            {
              id: 1,
              validated: true,
            },
            {
              id: 2,
              validated: true,
            },
            {
              id: 3,
              validated: true,
            },
            {
              id: 4,
              validated: true,
            }, {
              id: 5,
              validated: true,
            }, {
              id: 6,
              validated: true,
            }, {
              id: 7,
              validated: true,
            }, {
              id: 8,
              validated: true,
            }, {
              id: 9,
              validated: true,
            }, {
              id: 10,
              validated: true,
            }, {
              id: 11,
              validated: true,
            }, {
              id: 12,
              validated: true,
            }, {
              id: 13,
              validated: false,
            },
          ],
          waypoint_atwork: [
            {
              id: 1,
              validated: true,
            },
            {
              id: 2,
              validated: true,
            },
            {
              id: 13,
              validated: false,
            },
          ],
        },
        {
          name: 'Amoitie Jean-Michel',
          tutor: 'Jean-Michel Blanquette',
          evaluated: 'validé',
          validated: 'refusé',
          id_student_training: '1',
          waypoint_cfa: [
            {
              id: 1,
              validated: true,
            },
            {
              id: 2,
              validated: true,
            },
            {
              id: 3,
              validated: true,
            },
            {
              id: 4,
              validated: true,
            }, {
              id: 5,
              validated: true,
            }, {
              id: 6,
              validated: true,
            }, {
              id: 7,
              validated: true,
            }, {
              id: 8,
              validated: true,
            }, {
              id: 9,
              validated: true,
            }, {
              id: 10,
              validated: true,
            }, {
              id: 11,
              validated: true,
            }, {
              id: 12,
              validated: true,
            }, {
              id: 13,
              validated: false,
            },
          ],
          waypoint_atwork: [
            {
              id: 13,
              validated: false,
            },
          ],
        },
      ],
      isWaypointModalActive: false,
      isWaypointAttitudeModalActive: false,
      heightLine: 0,
      studentsArray: [],
      studenttrainings: [],
      formWaypoint: {
        type: null,
        end_date: null,
        start_date: null,
        free_text: null,
      },
      nowPosition: 'hello',
      showWeekNumber: true,
      editor: ClassicEditor,
      editorDisabled: true,
      editorConfig: {
        language: 'fr',
        toolbar: ['heading', '|', 'bold', 'italic', 'bulletedList', 'numberedList', '|', 'outdent', 'indent', '|', 'link', '|', 'undo',
          'redo'],
        alignment: {
          options: ['left', 'right'],
        },
        heading: {
          options: [
            { model: 'paragraph', title: 'Styles de texte', class: 'ck-heading_paragraph' },
            {
              model: 'heading1',
              view: 'h1',
              title: 'Titre 1',
              class: 'ck-heading_heading1',
            },
            {
              model: 'heading2',
              view: 'h2',
              title: 'Titre 2',
              class: 'ck-heading_heading2',
            },
            {
              model: 'mark',
              view: 'mark',
              title: 'surligné',
              class: 'ck-mark',
            },
          ],
        },
        table: {
          contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells'],
        },

      },
      channels: [],
      roles: [],
      schoolSubjects: [],
      schoolSubjectsSelected: [],
      arrayReports: [],
      isOpenActivities: false,
    };
  },
  methods: {
    startLoading() {
      this.isLoading = true;
    },
    endLoading() {
      this.isLoading = false;
    },
    initStudenttrainings() {
      this.$http
        .get(`/agenda/student_training/?training=${this.$route.params.idTraining}&query={id,student,organization,student_profile,managers_profiles,tutors_profiles}&format=json`)
        .then((response) => {
          this.studenttrainings = response.data;
          this.createStudentsWaypointsArray();
          this.getSchoolSubjects();
        });
    },
    matchHeight() {
      const stepsContent = document.getElementById('stepsContent');
      this.heightLine = stepsContent.clientHeight + 100;
      this.endLoading();
    },
    gotoNow() {
      this.$emit('tabChange', 'validationStep');
      // if (this.actualStep === 'validation_direction') {
      //   this.activeTab = 'validationStep';
      //   this.$emit('tabChange');
      // }
    },
    seeStudentTraining(studentId, studentTraining) {
      this.$router.push({
        name: 'StudentTraining',
        params: {
          idStudent: studentId,
          idTraining: this.$route.params.idTraining,
          idStudentTraining: studentTraining,
          tab: 'waypoint',
        },
      });
    },
    check() {

    },
    postWaypoint(studentTraining, last) {
      this.$http
        .post('agenda/student_training_waypoints/', {
          start_date: this.formWaypoint.start_date,
          end_date: this.formWaypoint.end_date,
          type: this.formWaypoint.type,
          student_training: studentTraining,
          title: this.formWaypoint.title,
          free_text: this.formWaypoint.free_text,
          goals: this.formWaypoint.goals,
          teacher_comment: this.formWaypoint.teacher_comment,
        }).then((response) => {
          if (this.formWaypoint.type === '1') {
            for (let i = 0; i < this.schoolSubjectsSelected.length; i += 1) {
              if (this.schoolSubjectsSelected[i].checked === true) {
                this.arrayReports.push(
                  {
                    school_subject: this.schoolSubjectsSelected[i].school_subject,
                    report: this.schoolSubjectsSelected[i].report,
                  },
                );
              }
            }
            this.$http
              .post('agenda/set_waypoint', {
                id: response.data.id,
                start_date: this.formWaypoint.start_date,
                end_date: this.formWaypoint.end_date,
                student_training: studentTraining,
                type: this.formWaypoint.type,
                reports: this.arrayReports,
                role: this.currentUser.role,
                profile: this.currentUser.id,
              }).then(() => {
                // this.$buefy.toast.open({ message:
                // 'Point d\'étape enregistré', type: 'is-success' });
              }).catch((error) => {
                window.console.log(error);
                this.errored = true;
              });
          }
          if (last) {
            this.$buefy.toast.open({
              message: 'Tous les points d\'étape ont été créés', type: 'is-success',
            });
            this.isWaypointModalActive = false;
            this.checkedRows = [];
            this.clearForm();
            this.initStudenttrainings();
          }
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
          this.$buefy.dialog.alert({
            title: 'Mince...',
            message: 'Le point n\'a pas pu être enregistré.',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
          });
        });
    },
    postAttitudeWaypoint(studentTraining, last) {
      this.$http
        .post('agenda/set_attitude_waypoint', {
          start_date: this.formWaypoint.start_date,
          end_date: this.formWaypoint.end_date,
          student_training: studentTraining,
          role: this.currentUser.role,
          profile: this.currentUser.id,
          title: this.formWaypoint.title,
          goals: this.formWaypoint.goals,
        }).then(() => {
          if (last) {
            this.$buefy.toast.open({
              message: 'Tous les points d\'étape ont été créés', type: 'is-success',
            });
            this.isWaypointModalActive = false;
            this.initStudenttrainings();
          }
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
        });
    },
    postAllWaypoint() {
      window.console.log(this.formWaypoint.type);
      if (this.formWaypoint.type === '2') {
        for (let i = 0; i < this.checkedRows.length; i += 1) {
          //  postWaypoint(studentTraining)
          let last = '';
          if (i === this.checkedRows.length - 1) {
            last = true;
          }
          this.postAttitudeWaypoint(this.checkedRows[i].student_training, last);
        }
      } else {
        for (let i = 0; i < this.checkedRows.length; i += 1) {
          //  postWaypoint(studentTraining)
          let last = '';
          if (i === this.checkedRows.length - 1) {
            last = true;
          }
          this.postWaypoint(this.checkedRows[i].student_training, last);
        }
      }
    },
    gotoWaypoint(id, studentId, studentTrainingId) {
      this.$router.push({
        name: 'WaypointRead',
        params: {
          idTraining: this.$route.params.idTraining,
          idStudent: studentId,
          idStudentTraining: studentTrainingId,
          idWaypoint: id,
        },
      });
    },
    createStudentsWaypointsArray() {
      this.startLoading();
      this.studentsArray = [];
      for (let i = 0; i < this.studenttrainings.length; i += 1) {
        this.studentsArray.push(
          {
            waypoint_atwork: [],
            waypoint_cfa: [],
            waypoint_attitude: [],
            name: this.studenttrainings[i].student_profile,
            student_id: this.studenttrainings[i].student,
            student_training: this.studenttrainings[i].id,
          },
        );
        this.$http
          .get(`agenda/student_training_waypoints/?student_training=${this.studenttrainings[i].id}&format=json`)
          .then((response) => {
            const waypoint = response.data;
            if (waypoint.length) {
              for (let e = 0; e < waypoint.length; e += 1) {
                if (waypoint[e].type === 0) {
                  this.studentsArray[i].waypoint_atwork.push({
                    id: waypoint[e].id,
                    date_created: waypoint[e].date_created,
                    validated: waypoint[e].validated,
                    start_date: waypoint[e].start_date,
                    end_date: waypoint[e].end_date,
                    read_by_tutor: waypoint[e].read_by_tutor,
                  });
                } else if (waypoint[e].type === 1) {
                  this.studentsArray[i].waypoint_cfa.push({
                    id: waypoint[e].id,
                    date_created: waypoint[e].date_created,
                    validated: waypoint[e].validated,
                    start_date: waypoint[e].start_date,
                    end_date: waypoint[e].end_date,
                    read_by_tutor: waypoint[e].read_by_tutor,
                    title: waypoint[e].title,
                  });
                }
              }
            }
            this.$http
              .get(`/agenda/student_training_attitude_waypoints/?student_training=${this.studenttrainings[i].id}&format=json`)
              .then((respattitude) => {
                const waypointAttitude = respattitude.data;
                for (let f = 0; f < waypointAttitude.length; f += 1) {
                  this.studentsArray[i].waypoint_attitude.push({
                    id: waypointAttitude[f].id,
                    date_created: waypointAttitude[f].date_created,
                    validated: waypointAttitude[f].validated,
                    start_date: waypointAttitude[f].start_date,
                    end_date: waypointAttitude[f].end_date,
                    read_by_tutor: waypointAttitude[f].read_by_tutor,
                  });
                }
                this.checkedRows = this.studentsArray;
              }).catch((error) => {
                window.console.log(error);
                this.errored = true;
              });
            this.endLoading();
          }).catch((error) => {
            window.console.log(error);
            this.errored = true;
          });
      }
      if (this.studenttrainings.length < 1) {
        this.endLoading();
      }
    },
    verifDatepicker() {
      if (this.formWaypoint.start_date && this.formWaypoint.end_date) {
        this.editDates = false;
      }
    },
    getSchoolSubjects() {
      const urlWs = `/trainings/${this.$route.params.idTraining}/?query={school_subjects}&format=json`;
      this.$http
        .get(`${urlWs}`)
        .then((response) => {
          this.schoolSubjects = response.data.school_subjects;
          this.schoolSubjectsTraining = response.data.school_subjects;
          // this.schoolSubjects = this.schoolSubjectsTraining ;
          // window.console.log(this.schoolSubjects);
          for (let i = 0; i < this.schoolSubjectsTraining.length; i += 1) {
            this.schoolSubjectsSelected.push(
              {
                school_subject: this.schoolSubjectsTraining[i].id,
                name: this.schoolSubjectsTraining[i].name,
                report: null,
                checked: false,
              },
            );
          }
          this.endLoading();
        });
    },
    gotoAttitudeWaypoint(id, studentId, studentTrainingId) {
      this.$router.push({
        name: 'AttitudeEvaluationRead',
        params: {
          idTraining: this.$route.params.idTraining,
          idStudent: studentId,
          idStudentTraining: studentTrainingId,
          idWaypoint: id,
        },
      });
    },
    clearForm() {
      this.formWaypoint.type = null;
      this.formWaypoint.start_date = null;
      this.formWaypoint.end_date = null;
      this.formWaypoint.title = null;
      this.formWaypoint.goals = null;
      this.formWaypoint.free_text = null;
      this.formWaypoint.teacher_comment = null;
      this.editDates = true;
    },
  },
  created() {
    this.initStudenttrainings();
    this.today = moment().format();
  },
  mounted() {

  },
};

</script>
<style scoped lang="scss">
#stepsContent .table td {
  padding-bottom: 0px !important;
}
.step-content {
  padding: 0 !important;
}
.bookletSteps img {
  width: 20px;
  height: 20px;
}
.puce {
  width: 20px;
  height: 20px;
}
.bookletStep {
  display: flex;
  align-items: center;
}
.bookletStep::after {
  content: "-";
  font-size: 1.5rem;
  color: var(--primary);
}
.img_status {
  margin: 0rem;
}
.bookletStep:last-child::after {
  content: unset;
}
#stepsContent {
  overflow: auto;
  position: relative;
  z-index: 1;
}
#stepsContent .table-wrapper {
  overflow: unset !important;
}
.waypoint_type {
  width: 6rem;
}
// .tag.is-success {
//   &.is-success {
//     color: #531be4;
//   }
// }
// .tag.atwork {
//   font-weight: bold;
//   background-color: #fff;
//   &.is-success {
//     border: 2px solid var(--success);
//     color: var(--success);
//   }
//   &.is-primary {
//     border: 2px solid var(--primary);
//     color: #531be4;
//   }
//   &.is-warning {
//     border: 2px solid #e6aa06;
//     color: #e6aa06;
//   }
// }
.waypoint_type {
  font-weight: bold;
  // color: #531be4;
  text-align: right;
  font-size: 0.8rem;
  margin-right: 1rem;
  // text-transform: uppercase;
}
.title2 {
  text-align: left !important;
}
.border-success {
  border: 2px solid var(--succes);
}
.border-warning {
  border: 2px solid #ffe08a;
}
</style>
