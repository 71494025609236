<template>
  <div class="is-flex" v-if="currentUser">
    <AppSidebar />
    <b-loading :is-full-page="isFullPage" v-model="isLoading"> </b-loading>
    <div class="main-content">
      <div class="lineCrumbAndAccount">
        <AppBreadcrumb
          :crumbs="crumbs"
          v-if="
            currentUser.role !== 'apprenant' && currentUser.role !== 'tuteur'
          "
        />
        <nav
          class="breadcrumb"
          aria-label="breadcrumbs"
          v-if="
            currentUser.role !== 'apprenant' && currentUser.role !== 'tuteur'
          "
        >
          <ul>
            <li @click="goPreviousPage('suivi')">
              / <a class="ml-2">Suivi apprenants</a>
            </li>
          </ul>
        </nav>
        <AppAccountMenu />
      </div>
      <UserDetails
        :user_id="idUser"
        @close="isUserModalActive = !isUserModalActive"
        :key="userKey"
        v-if="isUserModalActive"
      />
      <div
        class="is-flex justify-content-between align-items-center header-page"
      >
        <div class="w-100">
          <div v-if="currentUser.role === 'apprenant'">
            <div class="title-page short-title">Mon parcours pédagogique</div>
          </div>
          <div v-else>
            <div class="title-page short-title">
              {{ studenttrainingdetail.student_name }}
            </div>
          </div>
          <div v-if="studenttrainingdetail.training_tutors">
            <div v-if="studenttrainingdetail.training_tutors.length > 0">
              <b>Tutorat : </b>
              <span
                v-for="(tutor, index) in studenttrainingdetail.training_tutors"
                :key="tutor"
                class="is-clickable is-underlined"
                @click="
                  idUser = studenttrainingdetail.training_tutors_ids[index];
                  isUserModalActive = true;
                  userKey = userKey + 1;
                "
                >{{ tutor
                }}<span
                  v-if="
                    index < studenttrainingdetail.training_tutors.length - 1
                  "
                  >,
                </span></span
              >
            </div>
          </div>
          <div v-if="student_training">
            <div v-if="student_training.teachers_profiles">
              <b>Coaching : </b>
              <span
                v-for="(teacher, index) in student_training.teachers_profiles"
                :key="teacher"
                class="is-clickable is-underlined"
                @click="
                  idUser = student_training.teachers[index];
                  isUserModalActive = true;
                  userKey = userKey + 1;
                "
                >{{ teacher
                }}<span
                  v-if="index < student_training.teachers_profiles.length - 1"
                  >,
                </span></span
              >
            </div>
          </div>
          <router-link
            :to="'/formations/' + $route.params.idTraining + '/suivi/realtime'"
            v-if="
              currentUser.role === 'formateur' ||
              currentUser.role === 'coordinateur' ||
              currentUser.role === 'superviseur'
            "
          >
            <b-button type="is-text has-text-left" class="pl-0"
              ><span>{{ studenttrainingdetail.training_name }}</span></b-button
            ></router-link
          >
          <div v-else>
            <span class="has-text-primary">{{
              studenttrainingdetail.training_name
            }}</span>
          </div>
          <span v-if="studenttrainingdetail.student_training_option">
            ( {{ studenttrainingdetail.student_training_option }})</span
          ><span v-if="student_training" class="ml-2"
            ><b-tag v-if="student_training.finished" type="is-success">{{
              terminée
            }}</b-tag
            ><b-tag v-else type="is-success is-light"> en cours </b-tag
            ><b-tag v-if="student_training.deleted" type="is-danger is-light"
              >supprimée</b-tag
            ></span
          >
        </div>
        <div class="bloc-buttons">
          <div
            v-if="
              studenttrainingdetail.training_framework_type === 1 ||
              studenttrainingdetail.training_framework_type === 3
            "
          >
            <b-button
              v-if="
                currentUser.role === 'coordinateur' ||
                (currentUser.role === 'formateur' && teacherEdit) ||
                currentUser.role === 'superviseur'
              "
              @click.prevent="editStudentTraining()"
              type="is-primary is-rounded mr-3"
              rounded
              >modifier</b-button
            >
          </div>
        </div>
      </div>
      <section class="mt-auto" id="header-tabs">
        <div v-if="studenttrainingdetail.training_finished">
          <b-message type="is-danger" class="mb-0"
            >Attention, cette période de formation est indiquée comme
            terminée</b-message
          >
        </div>
        <div class="b-tabs">
          <nav class="tabs">
            <ul v-if="without_dates">
              <li
                v-for="(tab, index) of tabsWhitoutDates"
                :key="index"
                :id="tab.id"
                :class="currentTab === tab.slug ? 'is-active' : ''"
              >
                <a @click="gotoStep(tab.slug)" v-if="tab.visible"
                  ><span>{{ tab.name }}</span></a
                >
              </li>
            </ul>
            <ul v-else>
              <li
                v-for="(tab, index) of tabs"
                :key="index"
                :id="tab.id"
                :class="currentTab === tab.slug ? 'is-active' : ''"
              >
                <a @click="gotoStep(tab.slug)" v-if="tab.visible"
                  ><span>{{ tab.name }}</span></a
                >
              </li>
            </ul>
          </nav>
        </div>
      </section>
      <div v-if="step === 'parcours'" class="pb-5 mb-6 content-page pt-0">
        <div class="">
          <div
            class="helpUser contentParcours"
            v-if="
              currentUser.role === 'coordinateur' ||
              currentUser.role === 'formateur' ||
              currentUser.role === 'superviseur' ||
              currentUser.role === 'manager'
            "
          >
            <div class="title">Voici le parcours de votre apprenant</div>
            <div>
              Vous trouverez ici les expériences qui lui sont proposées lors de
              sa formation, en particulier en entreprise.
            </div>
          </div>
          <div
            class="helpUser contentParcours is-hidden-mobile"
            v-if="currentUser.role === 'apprenant'"
          >
            <div class="title">Voici votre parcours de formation</div>
            <div>
              <span v-if="currentUser.cfa === 'itii'"
                >Votre parcours de formation en entreprise : Vous allez décrire
                ici les situations de travail qui vont permettre votre montée en
                compétences tout au long de la formation. Vous devez en
                présenter a minima 3 par semestre. Leur évaluation, si elle est
                favorable, vous permet d’acquérir les crédits ECTS affectés à
                votre activité professionnelle (65 au total pour un apprenti, 80
                au total pour un salarié en formation continue). </span
              ><span v-else>
                Vous trouverez ici les expériences qui vous sont proposées lors
                de votre formation, en particulier en entreprise.</span
              >
            </div>
          </div>
          <div
            class="helpUser contentParcours is-hidden-mobile"
            v-if="currentUser.role === 'tuteur'"
          >
            <div class="title">
              Voici le parcours de formation de votre apprenant
            </div>
            <div>
              Vous trouverez ici les expériences qui lui sont proposées lors de
              sa formation, en particulier en entreprise.
            </div>
          </div>
        </div>
        <div v-if="studenttrainingdetail.periods" class="contentActivity">
          <div v-if="currentUser.role !== 'tuteur'">
          <b-collapse
            v-if="this.frameworktype === 3"
            class="card"
            animation="slide"
            :open="isOpenFreeSituations"
          >
            <div
              slot="trigger"
              slot-scope="props"
              class="card-header"
              role="button"
            >
              <div class="bg-card is-flex bg-primary align-items-center">
                <a class="card-header-icon">
                  <b-icon v-bind:icon="props.open ? 'menu-down' : 'menu-up'">
                  </b-icon>
                </a>
                <p class="card-header-title">Notes de carnet de bord</p>
              </div>
            </div>
            <div
              class="card-content card-student-training"
              v-if="studenttrainingdetail.custom_experiences"
            >
              <div
                id="card-mobile"
                class="pointer is-flex align-items-center w-100"
                @click.prevent="seeFreeNoteDetail(situation.id)"
                v-for="situation in studenttrainingdetail.custom_experiences"
                v-bind:key="'custom' + situation.id"
              >
                <div class="is-flex align-items-center">
                  <img
                    :src="require(`@/assets/img/steps/status${3}.svg`)"
                    alt=""
                    class="img_status mr-3"
                  />
                  <div>
                    <div class="type-sequence">note</div>
                    <div class="title-sequence">
                      <span v-if="situation.title"> {{ situation.title }}</span
                      ><span v-else>{{ situation.start_date | day }}</span>
                    </div>
                  </div>
                  <b-icon icon="menu-up" type="is-primary" class="ml-auto pl-2">
                  </b-icon>
                </div>
              </div>
              <div
                id="card-desktop"
                class="pointer card-edit-sequence is-flex align-items-center w-100"
                @click.prevent="seeFreeNoteDetail(situation.id)"
                v-for="(
                  situation, index
                ) in studenttrainingdetail.custom_experiences"
                v-bind:key="index + 'custom'"
              >
                <div
                  class="bloc-left is-flex mr-auto align-items-center w-100"
                  style="width: 100%"
                >
                  <div class="card-sequencetype is-flex">
                    <img
                      :src="require(`@/assets/img/steps/status${3}.svg`)"
                      alt=""
                      class="img_status mr-3"
                    />
                    <div class="sequenceType situation" type="situation">
                      Note de bord
                    </div>
                  </div>
                  <div
                    class="w-100 is-flex align-items-center justify-content-between"
                  >
                    <div class="card-header-title w-90">
                      <span v-if="situation.title"> {{ situation.title }}</span
                      ><span v-else>{{ situation.start_date | day }}</span>
                    </div>
                    <div
                      class="ml-auto actions-buttons is-flex align-items-center"
                    >
                      <b-icon
                        icon="see"
                        size="is-medium"
                        type="is-black"
                        class="pr-3"
                      >
                      </b-icon>
                    </div>
                  </div>
                </div>
              </div>
              <b-message
                type="is-warning"
                size="is-small"
                v-if="!studenttrainingdetail.custom_experiences.length"
                >Il n'y pas encore de note de carnet enregistrée. Cliquez sur le
                bouton "nouvelle note de carnet de bord" pour saisir une
                note.</b-message
              >
            </div>
            <b-message v-else type="is-warning" class="mt-5" size="is-small"
              >Il n'y pas encore de note de carnet enregistrée. Cliquez sur le
              bouton "nouvelle note de carnet de bord" pour saisir une
              note.</b-message
            >
          </b-collapse>
          <div class="w-100 has-text-centered" v-if="this.frameworktype === 3">
            <b-button
              class="mt-3 is-primary"
              @click="newNote"
              v-if="currentUser.role === 'apprenant'"
              >nouvelle note de carnet de bord</b-button
            ></div>
          </div>
          <div
            class="mb-5 mt-5"
            v-if="studenttrainingdetail.periods.length > 0"
          >
            <b-button
              rounded
              @click="isOpenPeriod = true"
              v-if="isOpenPeriod === false"
              size="is-small"
              >ouvrir tout</b-button
            >
            <b-button
              rounded
              @click="isOpenPeriod = false"
              v-if="isOpenPeriod === true"
              size="is-small"
              >fermer tout</b-button
            >
          </div>
          <div
            v-if="studenttrainingdetail.periods.length"
            class="studentParcours readBloc"
          >
            <b-collapse
              class="card"
              animation="slide"
              v-for="(period, index) of studenttrainingdetail.periods"
              v-bind:key="index"
              :open="isOpenPeriod"
            >
              <div
                slot="trigger"
                slot-scope="props"
                class="card-header"
                role="button"
              >
                <div
                  class="bg-card is-flex bg-primary align-items-center"
                  :class="'periodType' + period.type"
                >
                  <a class="card-header-icon">
                    <b-icon v-bind:icon="props.open ? 'menu-down' : 'menu-up'">
                    </b-icon>
                  </a>
                  <p class="card-header-title">{{ period.name }}</p>
                </div>
              </div>
              <div class="card-content card-student-training">
                <!-- eslint-disable max-len-->
                <router-link
                  :to="
                    '/sequence-edition/' +
                    $route.params.idTraining +
                    '/' +
                    sequence.student_sequence_id +
                    '/' +
                    $route.params.idStudentTraining
                  "
                  v-for="sequence in period.sequences"
                  v-bind:key="sequence.id"
                >
                  <div
                    id="card-mobile"
                    class="pointer is-flex align-items-center w-100"
                    @click.prevent="
                      seeSequenceNewdetail(
                        sequence.student_sequence_id,
                        sequence.student_sequence_status
                      )
                    "
                  >
                    <div class="is-flex align-items-center">
                      <div
                        v-if="
                          without_dates &&
                          sequence.student_sequence_status === 0
                        "
                      >
                        <img
                          :src="require('@/assets/img/steps/status1.svg')"
                          alt=""
                          class="img_status mr-3"
                        />
                      </div>
                      <img
                        v-else
                        :src="
                          require(`@/assets/img/steps/status${sequence.student_sequence_status}.svg`)
                        "
                        alt=""
                        class="img_status mr-3"
                      />
                      <div>
                        <div class="type-sequence">
                          {{ sequence.sequence_type_label }}
                        </div>
                        <div class="title-sequence">
                          {{ sequence.sequence_name }}
                        </div>
                        <div
                          class="title-sequence parenthesis"
                          v-if="sequence.student_sequence_subtitle"
                        >
                          <span
                            v-html="
                              sequence.student_sequence_subtitle.substr(0, 144)
                            "
                            class="subtitleSequence"
                          ></span>
                        </div>
                        <div
                          v-if="!without_dates"
                          class="status-date align-items-center mt-3"
                        >
                          <div
                            v-if="
                              sequence.student_sequence_start_date < today &&
                              sequence.student_sequence_end_date > today &&
                              sequence.student_sequence_status === 1
                            "
                          >
                            <span class="tag mr-2 is-narrow statusInProgress">
                              A faire
                            </span>
                          </div>
                          <div
                            v-else-if="
                              sequence.student_sequence_end_date < today &&
                              sequence.student_sequence_status === 1
                            "
                          >
                            <span class="tag mr-2 is-narrow statusLate">
                              En retard
                            </span>
                          </div>
                          <div v-else>
                            <span
                              class="tag mr-2 is-narrow"
                              :class="
                                'status' + sequence.student_sequence_status
                              "
                              >{{
                                sequence.student_sequence_status_label
                              }}</span
                            >
                          </div>
                          <div class="dates-schedulded" v-if="!without_dates">
                            <div v-if="sequence.student_sequence_start_date">
                              <div
                                class="date-period"
                                v-if="sequence.student_sequence_start_date"
                              >
                                {{ sequence.student_sequence_start_date | day }}
                              </div>
                            </div>
                            <div v-if="sequence.student_sequence_end_date">
                              <div
                                class="date-period"
                                v-if="sequence.student_sequence_end_date"
                              >
                                &nbsp;-
                                {{ sequence.student_sequence_end_date | day }}
                              </div>
                            </div>
                            <div v-else>-</div>
                          </div>
                        </div>
                        <div v-else>
                          <div
                            v-if="
                              sequence.student_sequence_status === 1 ||
                              sequence.student_sequence_status === 0
                            "
                          >
                            <span class="tag mr-2 is-narrow statusInProgress">
                              A faire
                            </span>
                          </div>
                          <div v-else>
                            <span
                              class="tag mr-2 is-narrow"
                              :class="
                                'status' + sequence.student_sequence_status
                              "
                              >{{
                                sequence.student_sequence_status_label
                              }}</span
                            >
                          </div>
                        </div>
                      </div>
                      <b-icon
                        icon="menu-up"
                        type="is-primary"
                        class="ml-auto pl-2"
                      >
                      </b-icon>
                    </div>
                  </div>
                </router-link>
                <router-link
                  :to="
                    '/sequence-edition/' +
                    $route.params.idTraining +
                    '/' +
                    sequence.student_sequence_id +
                    '/' +
                    $route.params.idStudentTraining
                  "
                  v-for="sequence in period.sequences"
                  v-bind:key="sequence.student_sequence_id"
                >
                  <div
                    id="card-desktop"
                    class="pointer card-edit-sequence is-flex align-items-center w-100"
                    :class="'activated' + sequence.student_sequence_status"
                  >
                    <div
                      class="column bloc-left is-flex mr-auto align-items-center pr-3"
                    >
                      <div class="card-sequencetype is-flex">
                        <!-- eslint-disable max-len-->
                        <img
                          :src="
                            require(`@/assets/img/steps/status${sequence.student_sequence_status}.svg`)
                          "
                          alt=""
                          class="img_status"
                        />
                        <div
                          class="sequenceType situation justify-content-center"
                          type="situation"
                          :class="'sequenceType' + sequence.sequence_type"
                        >
                          {{ sequence.sequence_type_label }}
                        </div>
                      </div>
                      <div class="card-header-title">
                        <span> {{ sequence.sequence_name }}</span>
                        <div
                          class="title-sequence parenthesis"
                          style="font-weight: normal"
                          v-if="sequence.student_sequence_subtitle"
                        >
                          <span
                            v-html="
                              sequence.student_sequence_subtitle.substr(0, 144)
                            "
                            class="subtitleSequence"
                          ></span>
                        </div>
                      </div>
                    </div>
                    <div
                      class="column is-narrow actions is-flex align-items-center justify-content-end"
                      :class="without_dates ? '' : 'actions-parcours'"
                    >
                      <div
                        class="is-flex align-items-center mr-3 tutor-select tutor-bloc"
                        v-if="
                          currentUser.role !== 'apprenant' &&
                          currentUser.role !== 'tuteur'
                        "
                      >
                        <b-icon
                          icon="check"
                          type="is-success"
                          class="mr-1"
                          v-if="
                            sequence.student_sequence_tutor ||
                            studenttrainingdetail.training_tutors
                          "
                        ></b-icon>
                        <div v-else>
                          <div
                            class="is-flex align-items-center"
                            v-if="
                              sequence.sequence_type === 1 ||
                              sequence.sequence_type === 2 ||
                              sequence.sequence_type === 7 ||
                              sequence.sequence_type === 10
                            "
                          >
                            <b-icon
                              icon="warning"
                              type="is-danger"
                              style="margin-right: 3px"
                            ></b-icon>
                          </div>
                          <div v-else>
                            <b-icon
                              icon="user"
                              class="mr-1"
                              type="is-primary"
                            ></b-icon>
                          </div>
                        </div>
                        <span class="st-tutor"
                          ><b>tuteur: </b
                          ><span v-if="sequence.student_sequence_tutor">{{
                            sequence.student_sequence_tutor
                          }}</span>
                          <span
                            v-else-if="studenttrainingdetail.training_tutors"
                            >{{
                              studenttrainingdetail.training_tutors[0]
                            }}</span
                          >
                          <span v-else
                            ><span
                              v-if="
                                sequence.sequence_type === 1 ||
                                sequence.sequence_type === 2 ||
                                sequence.sequence_type === 7 ||
                                sequence.sequence_type === 10
                              "
                              >obligatoire</span
                            ><span v-else>facultatif</span></span
                          >
                        </span>
                      </div>
                      <div
                        class="ml-auto is-flex dates-bloc is-align-items-center"
                      >
                        <div v-if="!without_dates" class="status-date">
                          <div
                            class="has-text-centered"
                            v-if="
                              sequence.student_sequence_start_date < today &&
                              sequence.student_sequence_end_date > today &&
                              sequence.student_sequence_status === 1
                            "
                          >
                            <span class="tag mr-2 is-narrow statusInProgress">
                              A faire
                            </span>
                          </div>
                          <div
                            class="has-text-centered"
                            v-else-if="
                              sequence.student_sequence_end_date < today &&
                              sequence.student_sequence_status === 1
                            "
                          >
                            <span class="tag mr-2 is-narrow statusLate">
                              En retard
                            </span>
                          </div>
                          <div class="has-text-centered" v-else>
                            <span
                              class="tag mr-2 is-narrow"
                              :class="
                                'status' + sequence.student_sequence_status
                              "
                              >{{
                                sequence.student_sequence_status_label
                              }}</span
                            >
                          </div>
                          <div
                            class="mx-auto has-text-centered dates-schedulded"
                            v-if="!without_dates"
                          >
                            <div v-if="sequence.student_sequence_start_date">
                              <div
                                class="date-period"
                                v-if="sequence.student_sequence_start_date"
                              >
                                {{ sequence.student_sequence_start_date | day }}
                              </div>
                            </div>
                            <div v-if="sequence.student_sequence_end_date">
                              <div
                                class="date-period"
                                v-if="sequence.student_sequence_end_date"
                              >
                                {{ sequence.student_sequence_end_date | day }}
                              </div>
                            </div>
                            <div v-else>-</div>
                          </div>
                        </div>
                        <div v-else>
                          <div
                            class="has-text-centered"
                            v-if="
                              sequence.student_sequence_status === 1 ||
                              sequence.student_sequence_status === 0
                            "
                          >
                            <span class="tag mr-2 is-narrow statusInProgress">
                              A faire
                            </span>
                          </div>
                          <div class="has-text-centered" v-else>
                            <span
                              class="tag mr-2 is-narrow"
                              :class="
                                'status' + sequence.student_sequence_status
                              "
                              >{{
                                sequence.student_sequence_status_label
                              }}</span
                            >
                          </div>
                        </div>
                        <div class="actions-buttons is-flex align-items-center">
                          <b-icon
                            v-if="sequence.student_sequence_status !== 5"
                            icon="see"
                            size="is-medium"
                            type="is-black"
                            class="pr-3"
                          >
                          </b-icon>
                        </div>
                      </div>
                    </div>
                  </div>
                </router-link>
              </div>
            </b-collapse>
          </div>
          <b-collapse
            v-if="!disallow_free_situations"
            class="card"
            animation="slide"
            :open="isOpenFreeSituations"
          >
            <div
              slot="trigger"
              slot-scope="props"
              class="card-header"
              role="button"
            >
              <div class="bg-card is-flex bg-primary align-items-center">
                <a class="card-header-icon">
                  <b-icon v-bind:icon="props.open ? 'menu-down' : 'menu-up'">
                  </b-icon>
                </a>
                <p class="card-header-title">
                  Expériences ou situations de travail libres
                </p>
              </div>
            </div>
            <div
              class="card-content card-student-training"
              v-if="studenttrainingdetail.free_situations"
            >
              <div
                id="card-mobile"
                class="pointer is-flex align-items-center w-100"
                @click.prevent="seeFreeSituationDetail(situation.id)"
                v-for="situation in studenttrainingdetail.free_situations"
                v-bind:key="situation.id"
              >
                <div class="is-flex align-items-center">
                  <img
                    :src="require(`@/assets/img/steps/status${3}.svg`)"
                    alt=""
                    class="img_status mr-3"
                  />
                  <div>
                    <div class="type-sequence">expérience libre</div>
                    <div class="title-sequence">
                      {{ situation.title }}
                    </div>
                  </div>
                  <b-icon icon="menu-up" type="is-primary" class="ml-auto pl-2">
                  </b-icon>
                </div>
              </div>
              <div
                id="card-desktop"
                class="pointer card-edit-sequence is-flex align-items-center w-100"
                @click.prevent="seeFreeSituationDetail(situation.id)"
                v-for="(
                  situation, index
                ) in studenttrainingdetail.free_situations"
                v-bind:key="index"
              >
                <div
                  class="bloc-left is-flex mr-auto align-items-center w-100"
                  style="width: 100%"
                >
                  <div class="card-sequencetype is-flex">
                    <img
                      :src="require(`@/assets/img/steps/status${3}.svg`)"
                      alt=""
                      class="img_status mr-3"
                    />
                    <div class="sequenceType situation" type="situation">
                      Expérience libre
                    </div>
                  </div>
                  <div
                    class="w-100 is-flex align-items-center justify-content-between"
                  >
                    <div class="card-header-title w-90">
                      <span> {{ situation.title }}</span>
                    </div>
                    <div
                      class="ml-auto actions-buttons is-flex align-items-center"
                    >
                      <b-icon
                        icon="see"
                        size="is-medium"
                        type="is-black"
                        class="pr-3"
                      >
                      </b-icon>
                    </div>
                  </div>
                </div>
              </div>
              <b-message
                type="is-warning"
                size="is-small"
                v-if="!studenttrainingdetail.free_situations.length"
                >Il n'y pas encore d'expérience libre enregistrée. Cliquez sur
                le bouton "nouvelle expérience libre" pour saisir une activité
                que vous rencontrez en entreprise ou centre de fomation et qui
                n'est pas indiquée dans le parcours ci-dessus.</b-message
              >
            </div>
            <b-message v-else type="is-warning" class="mt-5" size="is-small"
              >Il n'y pas encore d'expérience libre enregistrée. Cliquez sur le
              bouton "nouvelle expérience libre" pour saisir une activité que
              vous rencontrez en entreprise ou centre de fomation et qui n'est
              pas indiquée dans le parcours ci-dessus.</b-message
            >
          </b-collapse>
          <!-- free situations -->
        </div>
        <div class="mb-5 pb-5 mt-5" v-if="!disallow_free_situations">
          <b-button
            class="w-100 is-primary"
            @click="newSequence"
            v-if="currentUser.role === 'apprenant'"
            >nouvelle expérience libre</b-button
          >
        </div>
      </div>
      <div v-if="step === 'practicalwork'" class="pb-5 mb-6 content-page pt-0">
        <StudentPracticalWorks />
      </div>

      <div
        class="content-page mt-5 pt-5 pb-5 mb-6"
        v-if="step === 'infos' && training"
      >
        <TrainingInfosForStudent
          :studentTraining="this.student_training"
          :startDate="studenttrainingdetail.student_training_start_date"
          :endDate="studenttrainingdetail.student_training_end_date"
        />
      </div>
      <div class="content-page mt-5 pt-5 pb-5 mb-6" v-if="step === 'calendar'">
        <CalendarStudent
          :dates="dates"
          :student="Number($route.params.idStudent)"
          :studentTraining="Number($route.params.idStudentTraining)"
          :typeView="'student'"
          :key="planningKey"
          @refreshCalendar="initDates('calendar')"
        ></CalendarStudent>
      </div>
      <div class="content-page mt-5 pt-5 pb-5 mb-6" v-if="step === 'agenda'">
        <AgendaStudent
          :dates="dates"
          :student="Number($route.params.idStudent)"
          :studentTraining="Number($route.params.idStudentTraining)"
          :typeView="'student'"
          :key="planningKey"
        ></AgendaStudent>
      </div>
      <div
        v-if="step === 'todo'"
        class="container content-page mt-5 pt-5 pb-5 mb-6"
      >
        <PlanningUser
          :dates="dates"
          :student="Number($route.params.idStudent)"
          :studentTraining="Number($route.params.idStudentTraining)"
          :typePlanning="'todo'"
          :typeView="'student'"
          :key="planningKey"
        ></PlanningUser>
      </div>
      <div
        v-if="step === 'late'"
        class="container content-page mt-5 pt-5 pb-5 mb-6"
      >
        <PlanningUser
          :dates="dates"
          :student="Number($route.params.idStudent)"
          :studentTraining="Number($route.params.idStudentTraining)"
          :typePlanning="'late'"
          :typeView="'student'"
          :key="planningKey"
        ></PlanningUser>
      </div>
      <div
        v-if="step === 'done'"
        class="container content-page mt-5 pt-5 pb-5 mb-6"
      >
        <PlanningUser
          :dates="dates"
          :student="Number($route.params.idStudent)"
          :studentTraining="Number($route.params.idStudentTraining)"
          :typePlanning="'done'"
          :typeView="'student'"
          :key="planningKey"
        ></PlanningUser>
      </div>
      <div
        v-if="step === 'next'"
        class="container content-page mt-5 pt-5 pb-5 mb-6"
      >
        <PlanningUser
          :dates="dates"
          :student="Number($route.params.idStudent)"
          :studentTraining="Number($route.params.idStudentTraining)"
          :typePlanning="'next'"
          :typeView="'student'"
          :key="planningKey"
        ></PlanningUser>
      </div>
      <section v-if="step == 'skills'" class="content-page mt-5 pt-5 pb-5 mb-6">
        <SkillsStudent
          :typeoftraining="'student'"
          :objectId="Number($route.params.idStudentTraining)"
        />
      </section>
      <section v-if="step == 'waypoint'" class="content-page pt-4 pb-5 mb-6">
        <div v-if="frameworktype === 3">
          <WaypointsListVae
            v-if="studenttrainingdetail.student_name"
            :student="studenttrainingdetail.student_name.toLowerCase()"
            @refresh="gotoStep('calendar')"
          />
        </div>
        <div v-else>
          <div v-if="bookletValidationSteps && bookletWaypointList">
            <WaypointsListMix
              v-if="studenttrainingdetail.student_name"
              :student="studenttrainingdetail.student_name.toLowerCase()"
            />
          </div>
          <div v-else-if="!bookletValidationSteps">
            <WaypointsList
              v-if="studenttrainingdetail.student_name"
              :student="studenttrainingdetail.student_name.toLowerCase()"
            />
          </div>
          <div v-else>
            <div v-if="booklet">
              <WaypointsListITII
                v-if="studenttrainingdetail.student_name"
                :student="studenttrainingdetail.student_name.toLowerCase()"
                :bookletId="booklet"
              />
            </div>
          </div>
        </div>
      </section>
      <div class="content-page mt-5 pt-4 pb-5 mb-6" v-if="step === 'activity'">
        <!-- <StudentActivitiesDone /> -->
        <StudentActivitiesDoneInRealtime
          :student_training="this.$route.params.idStudentTraining"
          :student_id="this.$route.params.idStudent"
          :training="Number(this.$route.params.idTraining)"
          :isOpenActivities="true"
        />
      </div>
      <div v-if="step === 'resources'" class="content-page mt-5 pt-5">
        <h1 class="title-paragraph">
          <span v-if="currentUser.role === 'apprenant'">
            Ressources pédagogiques</span
          >
          <span v-else
            >Ressources associées au parcours de cet apprenant•e</span
          >
        </h1>
        <div class="subtitle-paragraph mt-4">
          <div v-if="currentUser.role === 'apprenant'">
            Retrouvez ici la liste des ressources qui vous ont été données par
            votre centre de formation ou votre tuteur.
          </div>
          <div v-else>
            Retrouvez ici la liste des ressources adressées à cet apprenant.
            Vous pouvez en ajouter de nouvelles.
          </div>
        </div>
        <div
          v-if="
            currentUser.role === 'tuteur' ||
            currentUser.role === 'manager' ||
            currentUser.role === 'formateur' ||
            currentUser.role === 'coordinateur'
          "
        >
          <div
            class="card_simple is-primary w-100 has-text-centered mb-2"
            @click="isOpen = !isOpen"
          >
            <div>
              <b-icon icon="resource" type="is-primary" class="mr-3"></b-icon>
              <b style="cursor: pointer">Ajoutez une ressource</b>
            </div>
          </div>
          <b-collapse v-model="isOpen">
            <ResourceCreation
              :isOpen.sync="isOpen"
              :sequence="null"
              :typeoftraining="'studenttraining'"
              :idTraining="$route.params.idStudentTraining"
              :action="'post'"
              @messageFromChild="initResources"
            ></ResourceCreation>
          </b-collapse>
        </div>
        <div v-if="resources">
          <div v-if="resources.length">
            <div v-for="resource in resources" :key="resource.id">
              <ResourceCard
                :resource="resource"
                @messageFromChild="initResources"
                v-if="resource.owner !== studenttrainingdetail.student_id"
              ></ResourceCard>
            </div>
          </div>
          <b-message type="is-info" v-else
            ><span>Aucun document n'a été téléchargé</span></b-message
          >
        </div>
        <div class="mt-5 pt-5">
          <h1 class="title-paragraph">
            <span v-if="currentUser.role !== 'apprenant'"
              >Documents téléchargés par l'apprenant•e</span
            >
            <span v-else>Vos documents</span>
          </h1>
          <div class="subtitle-paragraph">
            <span v-if="currentUser.role === 'apprenant'"
              >Retrouvez ici l'ensemble des documents que vous avez
              téléchargé</span
            >
            <span v-else
              >Retrouvez ici l'ensemble des documents téléchargé par
              l'apprenant</span
            >
          </div>

          <div v-if="currentUser.role === 'apprenant'">
            <div
              class="card_simple is-primary w-100 has-text-centered mb-2"
              @click="isOpen = !isOpen"
            >
              <div>
                <b-icon icon="resource" type="is-primary" class="mr-3"></b-icon>
                <b style="cursor: pointer">Ajoutez une ressource</b>
              </div>
            </div>
            <b-collapse v-model="isOpen">
              <ResourceCreation
                :isOpen.sync="isOpen"
                :sequence="null"
                :typeoftraining="'studenttraining'"
                :idTraining="$route.params.idStudentTraining"
                :action="'post'"
                @messageFromChild="initResources"
              ></ResourceCreation>
            </b-collapse>
          </div>
          <div v-if="resources" class="mt-4">
            <div v-if="resources.length">
              <div v-for="(resource, index) in resources" :key="resource.id">
                <ResourceCard
                  v-if="resource.owner === studenttrainingdetail.student_id"
                  :resource="resource"
                  :action="'edit'"
                  :isOpen.sync="isOpen"
                  @messageFromChild="initResources"
                ></ResourceCard>
                <div v-else>
                  {{ noresourceOnce() }}
                  <b-message
                    type="is-info"
                    v-if="
                      noresource &&
                      resource.owner !== studenttrainingdetail.student_id &&
                      index < 1
                    "
                    ><span v-if="currentUser === 'apprenant'"
                      >Vous n'avez téléchargé aucun document</span
                    ><span v-else
                      >Aucun document n'a été téléchargé</span
                    ></b-message
                  >
                </div>
              </div>
            </div>
            <b-message type="is-info" v-else
              ><span v-if="currentUser === 'apprenant'"
                >Vous n'avez téléchargé aucun document</span
              ><span v-else>Aucun document n'a été téléchargé</span></b-message
            >
          </div>
        </div>
      </div>
      <!-- Porfolios  -->
      <div v-if="step == 'portfolios' || step == 'creation-portfolio'" class="">
        <!-- Portfolios list -->
        <div
          class="content-page mt-5 pt-5"
          v-if="portfolio_step == 'portfolio-list'"
        >
          <div class="columns" v-if="currentUser.role == 'apprenant'">
            <div class="column is-12-mobile is-two-third">
              <h1 class="title is-3 is-inline-block mb-4">
                Vos portfolios d'expériences
              </h1>
              <br />
              <h2 class="subtitle has-text-primary is-5">
                Présentez vos compétences et réalisations
              </h2>
              <p class="copy">
                Avoir un portfolio est un excellent moyen de montrer vos
                compétences et projets à des employeurs potentiels.
              </p>
            </div>
            <div class="column is-12-mobile is-one-third has-text-center">
              <div class="center-button">
                <b-button
                  type="is-primary"
                  size="is-medium"
                  rounded
                  @click="portfolio_step = 'portfolio-creation'"
                  >Créer un portfolio</b-button
                >
              </div>
            </div>
          </div>
          <div class="columns" v-else>
            <div class="column is-12-mobile is-two-third">
              <h1 class="title is-3 is-inline-block mb-4">
                Portfolios d'expériences
              </h1>
              <br />
              <h2 class="subtitle has-text-primary is-5">
                Présentation des compétences et réalisations
              </h2>
              <p class="copy">
                Retrouvez ci-dessous la liste des portfolios enregistrés pour
                cet•te apprenant•e.
              </p>
            </div>
          </div>
          <div class="columns is-multiline mt-6" v-if="myPortfolios.length">
            <div
              class="column column-portfolio-card is-12-mobile is-6-tablet is-6-desktop is-4-fullhd"
              v-for="(portfolio, index) in myPortfolios"
              :key="index"
            >
              <div class="portfolio-card portfolio-item">
                <div class="card-content">
                  <p class="title is-4">
                    {{ portfolio.name }}
                  </p>
                  <p class="subtitle is-6 has-text-primary mb-3">
                    {{ portfolio.date_updated | formattedDate }}
                  </p>
                  <div
                    class="content"
                    style="text-overflow: ellipsis"
                    v-html="portfolio.description.substring(0, 150)"
                  ></div>
                  <!-- <p class="position m-4">
                      Position: {{ portfolio.position }}
                    </p> -->
                </div>
                <footer class="card-footer">
                  <b-button
                    class="card-footer-item"
                    variant="outline-primary"
                    color="info"
                    @click="showConfirmationModal(portfolio)"
                  >
                    <b-icon
                      class="mr-1"
                      icon="close"
                      size="is-small"
                      type="is-danger"
                    ></b-icon>
                    <span class="">Supprimer</span>
                  </b-button>
                  <b-button
                    class="card-footer-item"
                    color="info"
                    @click="openPortfolio(portfolio.slug)"
                  >
                    <b-icon
                      class="mr-1"
                      icon="see"
                      size="is-small"
                      type="is-primary"
                    ></b-icon>
                    <b class="has-text-primary">Voir</b>
                  </b-button>
                  <b-button
                    class="card-footer-item"
                    color="info"
                    @click="exportText(portfolio.id, portfolio.name)"
                  >
                    <b-icon
                      class="mr-1"
                      icon="download"
                      size="is-small"
                      type="is-primary"
                    ></b-icon>
                    <b class="has-text-primary">Exporter</b>
                  </b-button>
                </footer>
              </div>
            </div>

            <b-modal
              :active.sync="isConfirmationModalActive"
              title="Confirmation"
              has-modal-card
            >
              <div class="modal-card" style="">
                <header class="modal-card-head">
                  <p class="modal-card-title">
                    Êtes-vous sûr•e de vouloir supprimer ce portfolio ?
                  </p>
                </header>
                <section class="modal-card-body">
                  <div class="content">
                    <p>Cette action est irréversible.</p>
                  </div>
                </section>
                <footer class="modal-card-foot">
                  <div class="buttons">
                    <b-button @click="isConfirmationModalActive = false"
                      >Annuler</b-button
                    >
                    <b-button
                      type="is-danger"
                      @click="deletePortfolio(selectedPortfolio.id)"
                      >Confirmer</b-button
                    >
                  </div>
                </footer>
              </div>
            </b-modal>
          </div>
          <b-message v-else type="is-info"
            >Aucun portfolio n'a été enregistré pour le moment.</b-message
          >
        </div>
        <div
          v-if="portfolio_step == 'portfolio-creation'"
          class="content-page mt-5 pt-5"
        >
          <div v-if="currentPortfolioStep == 1">
            <div class="portfolio-form-block">
              <div class="columns">
                <div class="column">
                  <div class="mb-2">
                    <h2 class="title-paragraph is-2 has-text-centered">
                      Nouveau portfolio
                    </h2>
                  </div>
                  <div class="mb-5 pb-5">
                    <h3 class="subtitle-paragraph is-5 has-text-centered mb-5">
                      Veuillez remplir les champs suivants :
                    </h3>
                  </div>
                </div>
                <div class="column is-narrow">
                  <b-button
                    @click="portfolio_step = 'portfolio-list'"
                    rounded
                    type="is-warning"
                    >annuler</b-button
                  >
                </div>
              </div>

              <div class="portfolio-form-block-inner">
                <b-field label="Titre">
                  <b-input
                    type="text"
                    v-model="portfolioForm.name"
                    placeholder="Titre"
                    class="form-input"
                  ></b-input>
                </b-field>
                <div class="columns is-multiline mb-0 pb-0">
                  <!-- {{ this.studenttrainingdetail.student_name }} -->
                  <!-- <div class="column is-half">

                    <b-field label="Nom" class="mb-0">
                      <b-input
                        type="text"
                        v-model="portfolioForm.nom"
                        placeholder="Nom"
                        class="form-input"
                      ></b-input>
                    </b-field>
                  </div> -->
                  <div class="column is-half mb-0">
                    <b-field label="Téléphone de contact" class="mb-0">
                      <b-input
                        type="tel"
                        v-model="portfolioForm.telephone"
                        placeholder="Téléphone"
                        class="form-input"
                      ></b-input>
                    </b-field>
                  </div>
                  <div class="column is-half mb_0">
                    <b-field label="Email de contact" class="mb-0">
                      <b-input
                        type="email"
                        v-model="portfolioForm.email"
                        placeholder="Email"
                        class="form-input"
                        :rules="[
                          {
                            validate: (value) =>
                              /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value),
                            message: 'Adresse email invalide',
                          },
                        ]"
                      ></b-input>
                    </b-field>
                  </div>
                </div>
                <div class="columns is-vcentered mb-5">
                  <div class="column is-narrow mb-0">
                    <div class="">
                      <b-field class="mb-0">
                        <b-upload
                          v-model="portfolioForm.avatar"
                          name="avatar"
                          accept="image/*"
                          :max-size="5000000"
                          @input="updateAvatar"
                        >
                          <a class="button is-primary">
                            <span class="icon">
                              <i class="fas fa-upload"></i>
                            </span>
                            <span v-if="currentUser.avatar.length > 0"
                              >Modifiez votre avatar</span
                            >
                            <span v-else>Téléchargez votre avatar</span>
                          </a>
                        </b-upload>
                      </b-field>
                    </div>
                  </div>
                  <div class="column mb-0" v-if="currentUser.avatar">
                    <div class="avatar-container">
                      <img :src="currentUser.avatar" alt="Avatar" />
                    </div>
                  </div>
                  <div class="column mb-0" v-else-if="portfolioForm.avatar">
                    <div class="avatar-container">
                      <img :src="URL.createObjectURL(portfolioForm.avatar)" />
                    </div>
                  </div>
                  <div class="column mb-0" v-else>
                    {{ currentUser.name }}
                  </div>
                </div>

                <div class="">
                  <div
                    class="ck-editor-block mt-0"
                    :class="fullscreen ? 'fullscreen' : ''"
                  >
                    <ckeditor
                      v-model="portfolioForm.description"
                      :editor="editor"
                      :config="editorConfig"
                      class="mb-5"
                    ></ckeditor>
                  </div>
                </div>
              </div>
            </div>

            <!-- Button -->
            <div style="display: flex; justify-content: center">
              <b-button
                @click="currentPortfolioStep = 2"
                type="is-primary"
                rounded
                fullwidth
                class="mt-3"
              >
                Étape suivante
              </b-button>
            </div>
            <!-- Button ends -->
          </div>
          <div v-if="currentPortfolioStep == 2">
            <div class="columns">
              <div class="column">
                <h1 class="title-paragraph">
                  Sélectionnez les situations de travail que vous voulez
                  valoriser
                  <br />
                  dans le portfolio lié à cette formation.
                </h1>
              </div>
              <div class="column is-narrow">
                <b-button
                  @click="portfolio_step = 'portfolio-list'"
                  rounded
                  type="is-warning"
                  >annuler</b-button
                >
              </div>
            </div>
            <div
              class="columns is-multiline mt-6"
              v-if="experiences.length > 0"
            >
              <div
                class="column is-12-mobile is-6-tablet is-4-desktop is-3-fullhd"
                v-for="(experience, index) in experiences"
                :key="index"
              >
                <div
                  class="portfolio-card portfolio-experience"
                  :class="{ selected: selectedCards.includes(experience) }"
                  @click="toggleSelection(experience)"
                >
                  <div class="card-content">
                    <b-tag type="is-primary" class="badge">{{
                      experience.type
                    }}</b-tag>
                    <!-- <p class="skill-level ml-4 mb-2" style="color: grey">
                        Niveau de compétences
                      </p> -->
                    <p class="title is-5">{{ experience.title }}</p>
                    <div class="content" v-if="experience.subtitle">
                      {{ experience.subtitle }}
                    </div>
                  </div>
                  <div class="card-footer">
                    <p class="subtitle is-6">
                      {{ experience.date_updated | formattedDate }}
                    </p>
                  </div>
                </div>
                <!-- code for work experiences here -->
              </div>
            </div>
            <!-- experiences choice -->
            <div class="columns is-multiline mt-6" v-else>
              <b-message type="is-info">
                Vous devez avoir complété des expériences de travail dans votre
                parcours de formation avant de pouvoir sélectionner des
                expériences pour créer votre portfolio.
              </b-message>
            </div>

            <!-- experiences choice ends -->
            <!-- Button -->
            <div
              style="margin-top: 60px; display: flex; justify-content: center"
            >
              <b-button
                @click="currentPortfolioStep = 1"
                type="is-success"
                rounded
                fullwidth
              >
                Revenir à la fiche
              </b-button>
              <div style="margin: 10px"></div>
              <b-button
                @click="currentPortfolioStep = 3"
                type="is-primary"
                rounded
                :disabled="selectedCards.length === 0"
                fullwidth
              >
                Continuer avec les ({{ selectedCards.length }}) expériences
                sélectionnées
              </b-button>
            </div>
            <!-- Button ends -->
          </div>
          <div v-if="currentPortfolioStep == 3">
            <div class="columns">
              <div class="column">
                <h1 class="title-paragraph">
                  Placez les situations dans l'ordre que vous voulez, <br />
                  en les faisant glisser.
                </h1>
              </div>
              <div class="column is-narrow">
                <b-button
                  @click="portfolio_step = 'portfolio-list'"
                  rounded
                  type="is-warning"
                  >annuler</b-button
                >
              </div>
            </div>

            <!-- Organize cards -->
            <draggable
              v-model="selectedCards"
              @end="updateCardOrder"
              :move="checkMove"
              class="mt-6"
            >
              <div
                class="column is-12-mobile is-6-tablet is-4-desktop is-3-fullhd"
                v-for="(experience, index) in selectedCards"
                :key="index"
                style="display: inline-flex"
              >
                <div
                  class="portfolio-card portfolio-experience selected-portfolio-card"
                >
                  <div class="card-content">
                    <b-tag type="is-primary" class="badge">{{
                      experience.type
                    }}</b-tag>
                    <p class="title is-5">{{ experience.title }}</p>

                    <div class="content" v-if="experience.subtitle">
                      {{ experience.subtitle }}
                    </div>
                  </div>
                  <div class="card-footer">
                    <p class="subtitle is-6">
                      {{ experience.date_updated | formattedDate }}
                    </p>
                  </div>
                </div>
              </div>
            </draggable>

            <!-- Button -->
            <div
              style="margin-top: 60px; display: flex; justify-content: center"
            >
              <b-button
                @click="currentPortfolioStep = 2"
                type="is-success"
                rounded
                fullwidth
              >
                Revenir à la sélection
              </b-button>
              <div style="margin: 10px"></div>
              <b-button
                @click="getSkills()"
                type="is-primary"
                rounded
                fullwidth
              >
                Passer aux compétences
              </b-button>
            </div>
          </div>
          <div v-if="currentPortfolioStep == 4">
            <div class="columns">
              <div class="column">
                <div class="title-paragraph">Compétences</div>
                <div class="subtitle-paragraph">
                  Voici la liste des compétences qu'inSitu vous propose de
                  valoriser
                </div>
              </div>
              <div class="column is-narrow">
                <b-button
                  @click="portfolio_step = 'portfolio-list'"
                  rounded
                  type="is-warning"
                  >annuler</b-button
                >
              </div>
            </div>

            <div class="portfolioskills-card-container">
              <div class="columns is-multiline">
                <div
                  v-for="item in portfolioSkills"
                  :key="item.id"
                  class="column is-12-mobile is-6-tablet is-6-desktop is-4-widescreen"
                >
                  <div class="portfolioskills-card">
                    <p class="portfolioskills-card-title has-text-primary">
                      {{ item.name }}
                    </p>
                    <ul class="portfolioskills-card-ul">
                      <li
                        class="portfolioskills-card-li"
                        v-for="skill in item.skills"
                        :key="skill.id"
                      >
                        <div class="">
                          <div class="is-narrow">
                            <b-rate
                              class="rate-portfolio"
                              v-model="skill.evaluation"
                              icon-pack="fas"
                              :max="skill.evaluation_max"
                              :spaced="true"
                              :disabled="true"
                            ></b-rate>
                          </div>
                          <div class="">{{ skill.name }}</div>
                        </div>

                        <!-- <b-progress
                    :value="skill.evaluation"
                    :max="100"
                    style="width: 100%; margin-top: 1rem; margin-bottom: 1rem"
                  ></b-progress> -->
                        <!-- <b-icon icon="star"></b-icon> -->
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <!-- Button -->
            <div
              style="margin-top: 60px; display: flex; justify-content: center"
            >
              <b-button
                @click="currentPortfolioStep = 2"
                type="is-success"
                rounded
                fullwidth
              >
                Revenir à l'étape précédente
              </b-button>
              <div style="margin: 10px"></div>
              <b-button
                @click="portfolioPublish"
                type="is-primary"
                rounded
                :disabled="selectedCards.length === 0"
                fullwidth
              >
                Créer et publier ce portfolio
              </b-button>
            </div>
            <!-- Button ends -->
          </div>
        </div>
      </div>
      <!-- Portfolios creation -->
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';
import localization from 'moment/locale/fr';
import _ from 'lodash';
import ResourceCard from '@/components/Resources/ResourceCard.vue';
import ResourceCreation from '@/components/Resources/ResourceCreation.vue';
import AgendaStudent from '@/components/Agenda/AgendaStudent.vue';
import PlanningUser from '@/components/Agenda/PlanningUser.vue';
import SkillsStudent from '@/components/Skills/SkillsStudent.vue';
import WaypointsListITII from '@/components/Waypoints/WaypointsListITII.vue';
import WaypointsList from '@/components/Waypoints/WaypointsList.vue';
import WaypointsListMix from '@/components/Waypoints/WaypointsListMix.vue';
import WaypointsListVae from '@/components/Waypoints/WaypointsListVae.vue';
// import Modalexport from '@/components/Waypoints/Modalexport.vue';
import AppSidebar from '@/components/AppSidebar.vue';
import AppBreadcrumb from '@/components/AppBreadcrumb.vue';
import AppAccountMenu from '@/components/AppAccountMenu.vue';
// import StudentActivitiesDone from '@/components/Activity/StudentActivitiesDone.vue';
import StudentActivitiesDoneInRealtime from '@/components/Activity/StudentActivitiesDoneInRealtime.vue';
import TrainingInfosForStudent from '@/components/Trainings/TrainingInfosForStudent.vue';
import draggable from 'vuedraggable';
import CKEditor from '@ckeditor/ckeditor5-vue2';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import '@ckeditor/ckeditor5-build-classic/build/translations/fr';
import StudentPracticalWorks from '@/components/Activity/StudentPracticalWorks.vue';
import CalendarStudent from '@/components/Agenda/CalendarStudent.vue';
import UserDetails from '@/components/Users/UserDetails.vue';

moment.updateLocale('fr', localization);

export default {
  components: {
    ResourceCard,
    ResourceCreation,
    AgendaStudent,
    PlanningUser,
    SkillsStudent,
    WaypointsListITII,
    WaypointsList,
    WaypointsListMix,
    WaypointsListVae,
    // Modalexport,
    AppSidebar,
    AppBreadcrumb,
    AppAccountMenu,
    // StudentActivitiesDone,
    StudentActivitiesDoneInRealtime,
    TrainingInfosForStudent,
    draggable,
    ckeditor: CKEditor.component,
    StudentPracticalWorks,
    CalendarStudent,
    UserDetails,
  },
  data() {
    return {
      student_training: null,
      userKey: 0,
      idUser: null,
      isUserModalActive: false,
      isCardModalActive: false,
      teacherEdit: null,
      today: new Date(),
      studenttrainingdetail: [],
      isLoading: true,
      isFullPage: false,
      // variable for b-collapse
      isOpen: false,
      isOpenPeriod: false,
      isOpenFreeSituations: false,
      experiences: [],
      // declaration variables for tabs
      currentTab: 1,
      numberStep: 1,
      step: 1,
      tabs: [
        {
          name: 'Parcours',
          slug: 'parcours',
          id: '1',
          visible: true,
        },
        {
          name: 'Activités pratiques',
          slug: 'practicalwork',
          id: '2',
          visible: true,
        },
        {
          name: 'Livret de suivi',
          slug: 'waypoint',
          id: '8',
          visible: true,
        },
        {
          name: 'Compétences',
          slug: 'skills',
          id: '7',
          visible: true,
        },
        {
          name: 'Agenda',
          slug: 'agenda',
          id: '2',
          visible: true,
        },
        {
          name: 'A faire',
          slug: 'todo',
          id: '3',
          visible: true,
        },
        {
          name: 'En retard',
          slug: 'late',
          id: '4',
          visible: true,
        },
        {
          name: 'Effectué',
          slug: 'done',
          id: '5',
          visible: true,
        },
        {
          name: 'A venir',
          slug: 'next',
          id: '9',
          visible: true,
        },
        {
          name: 'Portfolios',
          slug: 'portfolios',
          id: '10',
          visible: true,
        },
      ],
      tabsWhitoutDates: [
        {
          name: 'Parcours',
          slug: 'parcours',
          id: '1',
          visible: true,
        },
        {
          name: 'Activités pratiques',
          slug: 'practicalwork',
          id: '2',
          visible: true,
        },
        {
          name: 'Infos',
          slug: 'infos',
          id: '2',
          visible: true,
        },
        {
          name: 'Compétences',
          slug: 'skills',
          id: '2',
          visible: true,
        },
        {
          name: 'Activité',
          slug: 'activity',
          id: '4',
          visible: true,
        },
        {
          name: 'Livret de suivi',
          slug: 'waypoint',
          id: '3',
          visible: true,
        },
        {
          name: 'Agenda',
          slug: 'calendar',
          id: '5',
          visible: false,
        },
        {
          name: 'Ressources',
          slug: 'resources',
          id: '6',
          visible: true,
        },
        {
          name: 'Portfolios',
          slug: 'portfolios',
          id: '7',
          visible: true,
        },
        // {
        //   name: 'Créer un portfolio',
        //   slug: 'portfolio-creation',
        //   id: '8',
        // },
      ],
      tabsTeacher: [
        {
          name: 'Parcours',
          slug: 'parcours',
          id: '1',
        },
        {
          name: 'Activités pratiques',
          slug: 'practicalwork',
          id: '2',
        },
        {
          name: 'Compétences',
          slug: 'skills',
          id: '2',
        },
        {
          name: 'Activité',
          slug: 'activity',
          id: '4',
        },
        {
          name: 'Livret de suivi',
          slug: 'waypoint',
          id: '3',
        },
        {
          name: 'Portfolios',
          slug: 'portfolios',
          id: '7',
        },
      ],
      resources: null,
      dates: [],
      planningKey: 0,
      trainingKey: 0,
      countRoute: 0,
      without_dates: '',
      crumbs: [
        {
          text: 'Formations',
          href: '/formations',
        },
      ],

      noresource: false,
      booklet: '',
      training: [],
      // Portolio data
      selectedCards: [],
      portfolioCreation: String,
      currentPortfolioStep: 1,
      portfolioSkills: [],
      portfolioForm: {
        name: '',
        description: '',
        email: '',
        telephone: '',
      },
      isConfirmationModalActive: false,
      selectedPortfolio: null,
      myPortfolios: [],
      editor: ClassicEditor,
      editorDisabled: true,
      fullscreen: false,
      editorConfig: {
        language: 'fr',
        toolbar: ['heading', '|', 'bold', 'italic', 'bulletedList', 'numberedList', '|', 'outdent', 'indent', '|', 'link', '|', 'undo',
          'redo'],
        alignment: {
          options: ['left', 'right'],
        },
        heading: {
          options: [
            { model: 'paragraph', title: 'Styles de texte', class: 'ck-heading_paragraph' },
            {
              model: 'heading1',
              view: 'h1',
              title: 'Titre 1',
              class: 'ck-heading_heading1',
            },
            {
              model: 'heading2',
              view: 'h2',
              title: 'Titre 2',
              class: 'ck-heading_heading2',
            },
            {
              model: 'mark',
              view: 'mark',
              title: 'surligné',
              class: 'ck-mark',
            },
          ],
        },
        table: {
          contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells'],
        },
      },
      portfolio_step: 'portfolio-list',
      trainingOptions: [],
      bookletValidationSteps: false,
      disallow_free_situations: false,
      bookletWaypointList: false,
      no_practical_work: false,
      frameworktype: null,
    };
  },
  filters: {
    day(date) {
      return moment(date).format('D/MM/YY');
    },
    hour(date) {
      return moment(date).format('HH:mm');
    },
    formattedDate(date) {
      moment.locale('fr');
      return moment(date).format('LLL');
    },
  },
  computed: {
    ...mapState('auth', ['currentUser']),
    formattedDate: {
      get() {
        moment.locale('fr');
        return moment(this.date_updated).format('LLL');
      },
      set(newValue) {
        this.date_updated = newValue;
      },
    },
  },
  methods: {
    getTrainingRights() {
      this.$http
        .get(`/trainings/${this.$route.params.idTraining}/?query={framework_type,teachers_can_edit,editable_without_dates,booklet,tab_options,disallow_free_situations}&format=json`)
        .then((response) => {
          this.teacherEdit = response.data.teachers_can_edit;
          this.without_dates = response.data.editable_without_dates;
          this.booklet = response.data.booklet;
          this.disallow_free_situations = response.data.disallow_free_situations;
          this.frameworktype = response.data.framework_type.id;
          this.trainingOptions = response.data.tab_options;
          if (this.trainingOptions.find((option) => option.code === 'validationSteps')) {
            this.bookletValidationSteps = true;
          } else {
            this.bookletValidationSteps = false;
          }
          if (this.trainingOptions.find((option) => option.code === 'waypointList')) {
            this.bookletWaypointList = true;
          } else {
            this.bookletWaypointList = false;
          }
          if (this.trainingOptions.find((option) => option.code === 'no_practicalWork')) {
            const tabindex = _.findKey(this.tabsWhitoutDates, { slug: 'practicalwork' });
            this.tabsWhitoutDates[tabindex].visible = false;
            const tabindexdates = _.findKey(this.tabs, { slug: 'practicalwork' });
            this.tabs[tabindexdates].visible = false;
          }
          if (this.trainingOptions.find((option) => option.code === 'no_portfolio')) {
            const tabindex = _.findKey(this.tabsWhitoutDates, { slug: 'portfolios' });
            this.tabsWhitoutDates[tabindex].visible = false;
            const tabindexdates = _.findKey(this.tabs, { slug: 'portfolios' });
            this.tabs[tabindexdates].visible = false;
          }
          if (this.frameworktype === 3) {
            const tabindex = _.findKey(this.tabsWhitoutDates, { slug: 'calendar' });
            this.tabsWhitoutDates[tabindex].visible = true;
          }
        }).catch((error) => {
          window.console.log(error);
        });
    },
    initStudentTrainingDetail() {
      this.$http
        .get(`/agenda/student_training_detail/?training_id=${this.$route.params.idTraining}&student_id=${this.$route.params.idStudent}&format=json`)
        .then((response) => {
          if (response.data[0] === 'Vous n\'avez pas le droit d\'accéder aux données demandées') {
            this.$buefy.dialog.alert({
              title: 'Attention...',
              message: response.data,
              type: 'is-info',
              hasIcon: true,
              icon: 'times-circle',
              ariaRole: 'alertdialog',
              ariaModal: true,
              confirmText: 'Ok',
              onConfirm: () => this.$router.push({ path: '/formations' }),
            });
          } else if (response.data[0] === 'Cet apprenant n\'est pas inscrit à cette formation') {
            this.$buefy.dialog.alert({
              title: 'Attention...',
              message: response.data,
              type: 'is-info',
              hasIcon: true,
              icon: 'times-circle',
              ariaRole: 'alertdialog',
              ariaModal: true,
              confirmText: 'Ok',
              onConfirm: () => this.$router.push({ path: `/formations/${this.$route.params.idTraining}` }),
            });
          } else {
            this.studenttrainingdetail = response.data;
            this.endLoading();
            for (let i = 0; i < this.studenttrainingdetail.periods.length; i += 1) {
              const myPeriods = this.studenttrainingdetail.periods;
              if (myPeriods[i].sequences.length) {
                if (myPeriods[i].sequences[0].student_sequence_position === 0) {
                  myPeriods[i].sequences = this.order(myPeriods[i].sequences);
                } else {
                  myPeriods[i].sequences = this.order2(myPeriods[i].sequences);
                }
              }
            }
            this.endLoading();
            if (this.studenttrainingdetail.training_framework_type === 3) {
              this.getStudentTraining();
            }
          }
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
          this.$buefy.dialog.confirm({
            title: 'Mince...',
            message: 'Une information n\'a pas pu être totalement chargée, nous vous invitons à recharger la page.',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
            onConfirm: () => document.location.reload(),
          });
        });
    },
    getStudentTraining() {
      this.$http
        .get(`/agenda/student_training/${this.studenttrainingdetail.student_training_id}/?query={id,teachers,teachers_profiles,deleted,finished,active}&format=json`)
        .then((response) => {
          this.student_training = response.data;
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
        });
    },
    gettutorsTraining() {
      this.$http
        .get(`/trainings/${this.$route.params.idTraining}/?query={tutors}&format=json`)
        .then(() => {
        });
    },
    gotoStep(numberStep) {
      this.currentTab = numberStep;
      this.step = numberStep;
      if (numberStep == null) {
        this.step = this.tabs[0].slug;
        this.currentTab = this.step;
      }
      if (numberStep === 'agenda' || numberStep === 'todo' || numberStep === 'late' || numberStep === 'done' || numberStep === 'next' || numberStep === 'calendar') {
        this.dates = [];
        this.initDates(numberStep);
      } else if (numberStep === 'resources') {
        this.gotoResources();
      }
      if (numberStep === 'portfolios') {
        this.checkPortfolioExistence();
        this.getExperiences();
      }
      this.gotoTab(numberStep);
    },
    gotoTab(slug) {
      if ((this.$route.path !== `/formation-apprenant/${this.$route.params.idTraining}/${this.$route.params.idStudent}/${this.$route.params.idStudentTraining}/${slug}`)) {
        this.$router.push({
          name: 'StudentTraining',
          params: {
            tab: slug,
            idTraining: this.$route.params.idTraining,
            idStudent: this.$route.params.idStudent,
            idStudentTraining: this.$route.params.idStudentTraining,
          },
        }).catch((error) => {
          window.console.log(error);
        });
      }
    },
    handleHistoryChange() {
      this.gotoStep(this.$route.params.tab);
    },
    gotoResources() {
      this.currentTab = 'resources';
      this.step = this.currentTab;
      this.initResources();
    },
    goPreviousPage(step) {
      if (this.$route.params.from === 'UtilisateurDetail') {
        this.$router.push({
          name: this.$route.params.from,
          params: {
            idTraining: this.$route.params.idTraining,
            stepNumber: step,
            id: this.$route.params.idStudent,
            step: 'infos',
          },
        });
      } else if (this.$route.params.from && this.$route.params.from !== 'UtilisateurDetail') {
        this.$router.push({
          name: this.$route.params.from,
          params: {
            idTraining: this.$route.params.idTraining,
            stepNumber: step,
          },
        });
      } else {
        this.$router.push({
          name: 'Training',
          params: { idTraining: this.$route.params.idTraining, tab: step },
        });
      }
    },
    seeSequenceNewdetail(param, status) {
      // function to see the sequence page
      // condition countRoute is to not go twice on the same page
      if (status !== 5) {
        this.$router.push({
          name: 'StudentSequenceDetail',
          params: {
            idSequence: param,
            idTraining: this.$route.params.idTraining,
            idStudent: this.$route.params.idStudent,
            idStudentTraining: this.$route.params.idStudentTraining,
            idActivity: 1,
            from: this.$route.params.name,
          },
        });
        this.countRoute += 1;
      }
    },
    seeSequencedetail(param) {
      // function to see the sequence page
      // condition countRoute is to not go twice on the same page
      if (this.countRoute === 0) {
        this.$router.push({
          name: 'StudentActivityDetailEdit',
          params: {
            idSequence: param,
            idTraining: this.$route.params.idTraining,
            idStudent: this.$route.params.idStudent,
            idStudentTraining: this.$route.params.idStudentTraining,
            idActivity: 1,
            from: this.$route.params.name,
          },
        });
        this.countRoute += 1;
      }
    },
    // order periods
    orderPeriod(array) {
      return _.orderBy(array, 'position');
    },
    // order sequences
    order(array) {
      return _.orderBy(array, 'sequence_position');
    },
    // order student sequences
    order2(array) {
      return _.orderBy(array, 'student_sequence_position');
    },
    editStudentTraining() {
      this.$router.push({
        name: 'StudentTrainingEdit',
        params: {
          stepNumber: this.step,
          idStudent: this.$route.params.idStudent,
          idTraining: this.$route.params.idTraining,
          idStudentTraining: this.$route.params.idStudentTraining,
        },
      });
    },
    startLoading() {
      this.isLoading = true;
    },
    endLoading() {
      this.isLoading = false;
    },
    initResources() {
      this.startLoading();
      this.$http.get(`/get_resources/?student_training=${this.$route.params.idStudentTraining}&format=json`).then((response) => {
        this.resources = response.data.resources;
        this.endLoading();
      });
    },
    initDates(step) {
      let wsUrl = '';
      if (step === 'agenda') { // agenda
        wsUrl = `/agenda/planning/?prev=true&student_training=${this.$route.params.idStudentTraining}&format=json`;
      } else if (step === 'todo') { // to do even if it's passed
        wsUrl = `/agenda/planning/?status=1&state=inprogress&student_training=${this.$route.params.idStudentTraining}&format=json`;
      } else if (step === 'late') { // activities late
        wsUrl = `/agenda/planning/?prev=1&state=late&student_training=${this.$route.params.idStudentTraining}&format=json`;
      } else if (step === 'done') { // activities done
        wsUrl = `/agenda/planning/?prev=true&status=3&student_training=${this.$route.params.idStudentTraining}&format=json`;
      } else if (step === 'next') { // activities done
        wsUrl = `/agenda/planning/?state=future&student_training=${this.$route.params.idStudentTraining}&format=json`;
      }
      if (step === 'calendar') { // agenda
        wsUrl = `/agenda/calendar/?student_training=${this.$route.params.idStudentTraining}&format=json`;
      }
      this.startLoading();
      this.$http
        .get(wsUrl)
        .then((response) => {
          // this.endLoading();
          this.dates = response.data;
          this.forceRerenderPlanning();
          this.endLoading();
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
          this.$buefy.dialog.confirm({
            title: 'Mince...',
            message: 'Une information n\'a pas pu être totalement chargée, nous vous invitons à recharger la page.',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
            onConfirm: () => document.location.reload(),
          });
        });
    },
    forceRerenderPlanning() {
      this.planningKey += 1;
    },
    forceRerenderPlanningLate() {
      this.trainingKey += 1;
    },
    exportLivret() {
      this.$buefy.dialog.confirm({
        title: 'Exporter le livret',
        message: 'Vous pouvez exporter le livret d\'apprentissage au format PDF',
        confirmText: 'Exporter',
        cancelText: 'Annuler',
        type: 'is-info',
        onConfirm: () => {
          this.startLoading();
          this.$http
            .get(`export_livret/?training_id=${this.$route.params.idTraining}&student_id=${this.$route.params.idStudent}`, {
              responseType: 'blob',
            }).then((response) => {
              const username = (this.studenttrainingdetail.student_name).toLowerCase();
              const usernameReplaced = username.replace(' ', '_');
              const fileURL = window.URL.createObjectURL(new Blob([response.data]));
              const fileLink = document.createElement('a');
              fileLink.href = fileURL;
              fileLink.setAttribute('download', `insitu_livret_${usernameReplaced}.pdf`);
              document.body.appendChild(fileLink);
              fileLink.click();
              this.endLoading();
            });
        },
      });
    },
    newSequence() {
      this.modalFreeOpen = true;
      this.$router.push({
        name: 'StudentFreeSituationEdit',
        params: {
          idTraining: this.$route.params.idTraining,
          idStudent: this.$route.params.idStudent,
          idFree: 'new',
          from: this.$route.name,
        },
      });
    },
    seeFreeSituationDetail(id) {
      // function to see the sequence page
      // condition countRoute is to not go twice on the same page
      this.$router.push({
        name: 'StudentFreeSituationRead',
        params: {
          idTraining: this.$route.params.idTraining,
          idStudent: this.$route.params.idStudent,
          idFree: id,
        },
      });
    },
    seeFreeNoteDetail(id) {
      this.$router.push({
        name: 'StudentFreeNoteEdit',
        params: {
          idTraining: this.$route.params.idTraining,
          idStudent: this.$route.params.idStudent,
          idCustom: id,
          editMode: false,
        },
      });
    },
    newNote() {
      this.modalFreeOpen = true;
      this.$router.push({
        name: 'StudentFreeNoteEdit',
        params: {
          idTraining: this.$route.params.idTraining,
          idStudent: this.$route.params.idStudent,
          idCustom: 'new',
          from: this.$route.name,
          editMode: true,
        },
      });
    },
    noresourceOnce() {
      this.noresource = true;
    },
    checkPortfolioExistence() {
      this.$http
        .get(`/portfolio/portfolios/?student_training=${this.$route.params.idStudentTraining}&format=json`)
        .then((response) => {
          if (response.data.length === 0) {
            this.portfolioCreation = true;
          } else {
            this.portfolioCreation = false;
            this.myPortfolios = response.data;
          }
        }).catch((error) => {
          window.console.log(error.response);
          if (error.response.status === 403) {
            this.unauthorized = true;
          } else {
            this.errored = true;
          }
        });
    },
    getExperiences() {
      this.$http
        .get(`/portfolio/get_experiences/?student_training=${this.$route.params.idStudentTraining}&format=json`)
        .then((response) => {
          this.experiences = response.data.experiences;
        }).catch((error) => {
          window.console.log(error.response);
          if (error.response.status === 403) {
            this.unauthorized = true;
          } else {
            this.errored = true;
          }
        });
    },
    toggleSelection(experience) {
      const index = this.selectedCards.indexOf(experience);
      if (index === -1) {
        this.selectedCards.push(experience);
        this.$buefy.toast.open({
          message: `${experience.title} sélectionnée !`,
          type: 'is-success',
          duration: 1000,
        });
      } else {
        this.selectedCards.splice(index, 1);
        this.$buefy.toast.open({
          message: `${experience.title} désélectionnée !`,
          type: 'is-danger',
          duration: 1000,
        });
      }
    },
    checkMove() {
      this.$buefy.toast.open({
        message: 'Portfolio réordonné !',
        type: 'is-primary',
        position: 'is-top',
        duration: 3000,
      });
    },
    getSkills() {
      this.currentPortfolioStep = 4;
      this.$http
        .get(`/portfolio/get_skills/?student_training=${this.$route.params.idStudentTraining}&format=json`)
        .then((response) => {
          this.portfolioSkills = response.data;
        }).catch((error) => {
          window.console.log(error.response);
          if (error.response.status === 403) {
            this.unauthorized = true;
          } else {
            this.errored = true;
          }
        });
    },
    portfolioPublish() {
      try {
        this.$http.post('/portfolio/portfolios/', {
          name: this.portfolioForm.name,
          description: this.portfolioForm.description,
          student_training: this.$route.params.idStudentTraining,
          email: this.portfolioForm.email,
          telephone: this.portfolioForm.telephone,
        }).then((response) => {
          this.$http.post(`/portfolio/set_portfolio_parts/${response.data.id}`, this.selectedCards).then(() => {
            this.checkPortfolioExistence();
            this.portfolioForm.name = '';
            this.portfolioForm.description = '';
            this.portfolioForm.email = '';
            this.portfolioForm.telephone = '';
            this.selectedCards = [];
            this.currentPortfolioStep = 1;
            this.gotoStep('portfolios');
            this.$buefy.toast.open({
              message: 'Votre portfolio a bien été créé',
              type: 'is-success',
              queue: false,
              position: 'is-top',
              duration: 5000,
            });
            this.portfolio_step = 'portfolio-list';
          }).catch((error) => {
            window.console.error(error);
            this.$buefy.toast.open({
              message: 'Erreur lors de l\'ajout des epériences sélectionnées',
              type: 'is-danger',
              queue: false,
              position: 'is-top',
              duration: 5000,
            });
          });
        }).catch((error) => {
          window.console.error(error);
          this.$buefy.toast.open({
            message: "Le portfolio n'a pas pu être créé, veuillez essayer de nouveau",
            type: 'is-danger',
            queue: false,
            position: 'is-top',
            duration: 5000,
          });
        });
      } catch (error) {
        window.console.error(error);
      }
    },
    showConfirmationModal(portfolio) {
      this.selectedPortfolio = portfolio;
      this.isConfirmationModalActive = true;
    },
    async deletePortfolio(portfolioId) {
      try {
        await this.$http.delete(`/portfolio/portfolios/${portfolioId}/`);
        this.isConfirmationModalActive = false;
        this.$buefy.toast.open({
          message: 'Le portoflio a bien été effacé',
          type: 'is-success',
          queue: false,
          position: 'is-top',
          duration: 5000,
        });
        this.checkPortfolioExistence();
      } catch (error) {
        window.console.error(error);
        this.$buefy.toast.open({
          message: "Le portfolio n'a pas été supprimé, veuillez réessayer",
          type: 'is-danger',
          queue: false,
          position: 'is-top',
          duration: 5000,
        });
      }
    },
    openPortfolio(slug) {
      window.open(`/portfolios/${slug}`, '_blank');
    },
    updateAvatar() {
      const file = this.portfolioForm.avatar;
      const blob = new Blob([file], { type: file.type });

      // Extract the file extension from the file name
      const lastDotIndex = file.name.lastIndexOf('.');
      const extension = file.name.substring(lastDotIndex + 1);

      // Set the filename and add a file extension to the blob object
      const filename = `avatar_${Date.now()}.${extension}`;
      const data = new FormData();
      data.append('avatar', blob, filename);

      this.$http.patch(`/profile/${this.currentUser.id}/`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then((response) => {
          // The avatar update was successful
          window.console.log(response.data);

          // Update the currentUser model with the new avatar URL
          this.currentUser.avatar = response.data.avatar;

          // Show a success toast
          this.$buefy.toast.open({
            message: 'Avatar téléchargé avec succès.',
            type: 'is-success',
            position: 'is-top',
            duration: 5000,
            queue: false,
          });
        })
        .catch((error) => {
          // An error occurred while updating the avatar
          window.console.log(error);

          // Show an error toast
          this.$buefy.toast.open({
            message: 'Une erreur s\'est produite lors du téléchargement de l\'avatar.',
            type: 'is-danger',
            position: 'is-top',
            duration: 5000,
            queue: false,
          });
        });
    },
    exportText(id, name) {
      this.startLoading();
      this.$http
        .get(`/portfolio/export_experiences/${id}-${this.$route.params.idStudentTraining}/`, {
          responseType: 'blob',
        }).then((response) => {
          const portfolioname = (name).toLowerCase();
          const portfolionameReplaced = portfolioname.replace(' ', '_');
          const fileURL = window.URL.createObjectURL(new Blob([response.data]));
          const fileLink = document.createElement('a');
          fileLink.href = fileURL;
          fileLink.setAttribute('download', `insitu_portfolio_${portfolionameReplaced}.docx`);
          document.body.appendChild(fileLink);
          fileLink.click();
          this.endLoading();
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
          this.$buefy.dialog.confirm({
            title: 'Mince...',
            message: 'Le portfolio n\'a pu être téléchargé',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
            onConfirm: () => document.location.reload(),
          });
        });
    },
  },
  created() {
    if (this.$route.params.tab) {
      this.gotoStep(this.$route.params.tab);
    } else {
      this.gotoStep('parcours');
    }
    this.getTrainingRights();
    this.initStudentTrainingDetail();
    this.today = moment().format();
  },
  mounted() {
    window.addEventListener('popstate', this.handleHistoryChange);
  },
  destroyed() {
    window.removeEventListener('popstate', this.handleHistoryChange);
  },
};
</script>

<style lang="scss" scoped>
#card-mobile {
  display: none !important;
}
.dateItem {
  font-size: 0.8rem;
}
@media (max-width: 768px) {
  #card-desktop {
    display: none !important;
  }
  .portfolio-card-header-icon {
    width: 0.125rem;
  }
  #card-mobile {
    display: block !important;
    border-radius: 5px;
    margin-bottom: 0.7rem;
    padding: 1rem 0.5rem;
    border: 1px solid #e3e5eb;
    box-shadow: 0px 2px 9px 0 #00000008;
    .actions-icon,
    .img_status {
      display: block;
    }
    .title-sequence {
      font-size: 0.9rem;
      color: #707784;
      line-height: 1.2;
    }
    .type-sequence {
      color: #282f3c;
      font-weight: bold;
      font-size: 0.9rem;
    }
  }
}
@media (max-width: 576px) {
}
.selected {
  transform: scale(1.02);
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.19), 0px 6px 6px rgba(0, 0, 0, 0.23);
  background-color: #531be4 !important;
  color: white;
}

.portfolio-card:hover {
  cursor: pointer;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.19), 0px 6px 6px rgba(0, 0, 0, 0.23);
}

.title,
.subtitle {
  color: black;
}

.selected .title,
.selected .subtitle {
  color: white;
}

.selected .badge {
  background-color: white !important;
  color: #531be4;
}

.selected .skill-level {
  color: white !important;
}

.skill-level {
  font-size: 0.9em;
  margin-right: 1rem;
  float: right;
}

.selected-portfolio-card {
  height: 243px;
  border-radius: 20px;
}
.selected-portfolio-card:hover {
  cursor: move;
}

// Skills styles
.portfolioskills-card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.portfolioskills-card {
  width: 100%;
  height: 100%;
  padding: 1rem;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: left;
  margin-bottom: 2rem;
  margin-right: 1rem;
}

.portfolioskills-card:nth-child(3n) {
  margin-right: 0;
}

.portfolioskills-card-title {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.portfolioskills-card-ul {
  padding-left: 1.5rem;
  list-style-type: none;
}

.portfolioskills-card-li {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 1rem;
}

.portfolioskills-card-bullet {
  font-size: 0.9rem;
  margin-right: 0.5rem;
}

.portfolioskills-card-skill-name {
  word-wrap: break-word;
}

.b-progress-bar {
  background: linear-gradient(to right, #63b8b3, #3e9991) !important;
}

.action-block {
  border-radius: 0.5rem;
  border: 1px solid #e5e5e5;
  padding: 1.5rem;
}

.copy {
  padding-top: 1rem;
  font-size: 1.1rem;
  color: #3d3d3d;
}

.center-button {
  display: flex;
  align-items: center;
  height: 3.5rem;
  width: 20rem;
  margin: auto;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

@media (max-width: 768px) {
  .center-button {
    width: 15rem;
    height: 2.5rem;
  }
}

.portfolio-form-block {
  background: #fff;
  border-radius: 15px;
}

.form-input {
  border-radius: 20px;
}

// Porfolios list
.myportfolios-card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 20px;
}

.myportfolios-card {
  width: 300px;
  height: 400px;
  margin: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 2px 2px 8px #ccc;
  transition: all 0.2s ease-in-out;
  text-align: left;
}

.myportfolios-card:hover {
  transform: translateY(-10px);
  box-shadow: 2px 12px 20px #ccc;
  border: 2px solid #3498db;
}

.myportfolios-card-header {
  padding: 20px;
  border-bottom: 1px solid #ccc;
}

.myportfolios-card-title {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 10px;
}

.myportfolios-card-body {
  padding: 20px;
  overflow: auto;
  max-height: 200px;
}

.myportfolios-card-footer {
  display: flex;
  justify-content: flex-end;
  padding: 20px;
}

.myportfolios-card-button {
  margin-left: 20px;
}
.portfolio-card {
  width: 100%;

  display: flex;
  background-color: #f7f8fa;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 20px;
  transition: all 0.3s ease;
  border: 1px solid #dbdbdb;
  box-shadow: 2px 2px 5px #ccc;
  border-radius: 5px;
  .title {
    padding: 0;
  }
  .card-content {
    padding: 2rem 1.5rem 0;
    max-height: 215px;
    overflow: hidden;
  }
  button {
    background-color: unset;
    border-radius: 0px;
  }
  .card-footer {
    padding: 0rem;
    border-top: 1px solid #7957d594;
  }
  .card-footer-item:not(:last-child) {
    border-right: 1px solid #7957d594;
  }
  .card-footer-item:last-child {
    border-left: 1px solid #7957d594;
  }
}
.portfolio-item {
  height: 280px;
}
.portfolio-experience {
  height: 300px;
  .title {
    line-height: 1.2;
    font-size: 1.2rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .card-footer {
    padding: 1rem 1.5rem;
  }
}
.card-columns {
  column-count: 4;
  column-gap: 0.5rem;
}
.card-columns .column {
  display: inline-block;
  width: 100%;
}
.dates_training {
  font-size: 0.8rem;
}
@media screen and (min-width: 1700px) {
  .column-portfolio-card,
  .is-4-fullhd {
    -webkit-box-flex: 0;
    -ms-flex: none;
    flex: none;
    width: 25% !important;
  }
}
@media screen and (max-width: 1200px) {
  .portfolio-item {
    height: 300px;
  }
}

@media screen and (max-width: 767px) {
  .portfolio-card {
    height: auto !important;
    .card-content {
      padding: 2rem 1.5rem 2rem;
    }
  }
  .portfolioskills-card-container .column {
    margin-bottom: 0px;
  }
  .portfolioskills-card {
    height: auto;
    margin-bottom: 0px;
  }
}

.avatar-container {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  overflow: hidden;
}

.avatar-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.bg-yellow {
  background-color: var(--yellow-light);
}
</style>
