<template>
  <div
    class="listActivities"
    v-if="
      activities.student_activities ||
      activities.student_activity_revisions ||
      activities.free_situations ||
      activities.custom_experiences
    "
  >
    <b-collapse
      class="card"
      animation="slide"
      :open="isOpenActivities"
      v-if="
        activities.student_activities.length ||
        activities.student_activity_revisions.length ||
        activities.free_situations.length ||
        (activities.custom_experiences.length &&
          currentUser.role !== 'tuteur')
      "
    >
      <div slot="trigger" slot-scope="props" class="card-header" role="button">
        <div class="bg-card is-flex bg-primary align-items-center">
          <a class="card-header-icon">
            <b-icon v-bind:icon="props.open ? 'menu-down' : 'menu-up'">
            </b-icon>
          </a>
          <p class="card-header-title">
            Votre apprenant a récemment rédigé ou commencé à rédiger les
            activités suivantes
          </p>
        </div>
      </div>
      <div class="card-content pt-0">
        <!-- {{ activities }} -->
        <div v-if="activities.student_activities">
          <div v-if="activities.student_activities.length > 0">
            <p class="title-activity has-text-primary">
              Les expériences programmées
            </p>
            <b-message
              v-if="activities.student_activities.length"
              type="is-warning"
              class="messagebox"
            >
              Avez-vous bien fait l'évaluation avec votre apprenant des
              compétences associées à ces situations de travail ?
            </b-message>
            <div
              v-for="(activity, index) in order(activities.student_activities)"
              :key="activity.id + 'activity'"
            >
              <div
                v-if="index < 6"
                class="card-edit-sequence mb-1 is-flex align-items-center pointer card-dashboard"
              >
                <div
                  class="column bloc-left is-flex mr-auto align-items-center"
                  @click="gotoActivity(activity.sequence)"
                >
                  <div
                    class="ml-auto column is-narrow has-text-centered pl-2 pr-2"
                  >
                    {{ activity.date_updated | day }}
                  </div>
                  <div class="column is-12-mobile has-text-centered-mobile">
                    <div class="titles">
                      <div>
                        <b>{{ activity.sequence_title }}</b>
                      </div>
                      <div v-if="activity.sequence_subtitle">
                        ({{ activity.sequence_subtitle }})
                      </div>
                      <div class="has-text-primary">{{ activity.title }}</div>
                    </div>
                  </div>
                  <b-button
                    outlined
                    rounded
                    type="is-primary is-small"
                    class="ml-auto"
                    >voir</b-button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <!---  revision -->
        <div v-if="activities.student_activity_revisions">
          <div v-if="activities.student_activity_revisions.length > 0">
            <p class="title-activity has-text-primary">
              Les expériences refaites
            </p>
            <b-message
              v-if="activities.student_activity_revisions.length"
              type="is-info"
              class="messagebox"
            >
              Vous pouvez relire ce qu'a rédigé votre apprenant
            </b-message>
            <div
              v-for="(revision, index) in order(
                activities.student_activity_revisions
              )"
              :key="revision.id + 'revision'"
            >
              <div
                v-if="index < 6"
                class="card-edit-sequence mb-1 is-flex align-items-center pointer"
              >
                <div
                  class="column bloc-left is-flex mr-auto align-items-center"
                  @click="gotoRevision(revision.id, revision.student_sequence)"
                >
                  <div
                    class="ml-auto column is-narrow has-text-centered pl-2 pr-2"
                  >
                    {{ revision.date_updated | day }}
                  </div>
                  <div class="column is-12-mobile has-text-centered-mobile">
                    <div class="titles">
                      <div class="mr-3">
                        <b> {{ revision.student_sequence_title }}</b>
                      </div>
                      <div v-if="revision.student_sequence_subtitle">
                        ({{ revision.student_sequence_subtitle }})
                      </div>
                      <div class="has-text-primary">
                        {{ revision.title }}
                      </div>
                    </div>
                  </div>
                  <b-button
                    outlined
                    rounded
                    type="is-primary is-small"
                    class="ml-auto"
                    >voir</b-button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- free situation -->
        <div v-if="activities.free_situations">
          <div v-if="activities.free_situations.length > 0">
            <p class="title-activity has-text-primary">
              Les expériences libres, non programmées
            </p>
            <b-message
              v-if="activities.free_situations.length"
              type="is-info"
              class="messagebox"
            >
              Vous pouvez relire ce qu'a rédigé votre apprenant
            </b-message>
            <div
              v-for="(free, index) in order(activities.free_situations)"
              :key="free.id + 'free'"
            >
              <div
                v-if="index < 6"
                class="card-edit-sequence mb-1 is-flex align-items-center pointer"
              >
                <div
                  class="column bloc-left is-flex mr-auto align-items-center"
                  @click="gotoFreeSituation(free.id)"
                >
                  <div
                    class="ml-auto column is-narrow has-text-centered pl-2 pr-2"
                  >
                    {{ free.date_updated | day }}
                  </div>
                  <div class="column is-12-mobile has-text-centered-mobile">
                    <div class="titles">
                      <div class="mr-3">
                        <b>{{ free.title }}</b>
                      </div>
                    </div>
                  </div>
                  <b-button
                    outlined
                    rounded
                    type="is-primary is-small"
                    class="ml-auto"
                    >voir</b-button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--custom experience -->
        <div v-if="activities.custom_experiences">
          <div v-if="activities.custom_experiences.length > 0 &&
               currentUser.role !== 'tuteur'">
            <p class="title-activity has-text-primary">Les notes de carnet</p>
            <b-message
              v-if="activities.custom_experiences.length"
              type="is-info"
              class="messagebox"
            >
              Vous pouvez relire ce qu'a rédigé votre apprenant
            </b-message>
            <div
              v-for="(custom, index) in order(activities.custom_experiences)"
              :key="custom.id + 'custom'"
            >
              <div
                v-if="index < 6"
                class="card-edit-sequence mb-1 is-flex align-items-center pointer"
              >
                <div
                  class="column bloc-left is-flex mr-auto align-items-center"
                  @click="gotoNote(custom.id)"
                >
                  <div class="column is-12-mobile has-text-centered-mobile">
                    <div class="titles">
                      <div class="mr-3 ml-2">
                        <b v-if="custom.title"
                          >{{ custom.title }} ({{ custom.start_date | day }})</b
                        >
                        <b v-else> Note du {{ custom.start_date | day }}</b>
                      </div>
                    </div>
                  </div>
                  <b-button
                    outlined
                    rounded
                    type="is-primary is-small"
                    class="ml-auto"
                    >voir</b-button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-collapse>
    <b-message type="is-info" class="messagebox mb-0" v-else
      >Votre apprenant n'a enregistré aucune nouvelle activité dans inSitu
      depuis 15 jours.
    </b-message>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';
import localization from 'moment/locale/fr';
import _ from 'lodash';

moment.updateLocale('fr', localization);

export default ({
  props: {
    training: {
      type: [String, Number],
    },
    student_id: {
      type: [String, Number],
    },
    student_training: {
      type: [String, Number],
    },
    isOpenActivities: {
      type: [Boolean],
    },
  },
  filters: {
    day(date) {
      return moment(date).format('DD/MM/YY');
    },
  },
  computed: {
    ...mapState('auth', ['currentUser']),
  },
  data() {
    return {
      isLoading: false,
      isFullPage: false,
      activities: [],
      student_activities_report: [],
      student_revisions_report: [],
      free_situations_report: [],
      isOpenRevision: false,
      isOpenFree: false,
      totalActivities: 0,
      totalRevisions: 0,
      totalFree: 0,
      total: 0,
      twoweeks: new Date(),
    };
  },
  methods: {
    order(array) {
      return _.orderBy(array, 'date_updated', 'desc');
    },
    startLoading() {
      this.isLoading = true;
    },
    endLoading() {
      this.isLoading = false;
    },
    getActivities() {
      this.twoweeks = this.twoweeks.setDate(this.twoweeks.getDate() - 15);
      const startDate = moment(new Date(this.twoweeks)).format('YYYY-MM-DD');
      const endDate = moment(new Date()).format('YYYY-MM-DD');
      const urlWs = `/agenda/get_waypoint_activities?student_training=${this.student_training}&start_date=${startDate}&end_date=${endDate}&format=json`;
      this.$http
        .get(`${urlWs}`)
        .then((response) => {
          this.activities = response.data;
          this.totalActivities = this.activities.student_activities.length;
          this.totalRevisions = this.activities.student_activity_revisions.length;
          this.totalFree = this.activities.free_situations.length;
          this.total = this.totalActivities + this.totalRevisions + this.totalFree;
          this.endLoading();
        });
    },
    gotoActivity(sequenceId) {
      this.$router.push({
        name: 'StudentSequenceDetail',
        params: {
          idTraining: this.training,
          idStudentTraining: this.student_training,
          idSequence: sequenceId,
          from: this.$route.name,
          idStudent: this.student_id,
        },
      });
    },
    gotoRevision(id, studentActivity) {
      const sequence = '1';
      this.$router.push({
        name: 'StudentProgressionRead',
        params: {
          idTraining: this.training,
          idStudentTraining: this.student_training,
          idSequence: sequence,
          idActivity: studentActivity,
          idRevision: id,
          from: this.$route.name,
          idStudent: this.student_id,
        },
      });
    },
    gotoFreeSituation(id) {
      this.$router.push({
        name: 'StudentFreeSituationRead',
        params: {
          idTraining: this.training,
          idStudentTraining: this.student_training,
          idFree: id,
          from: this.$route.name,
          idStudent: this.student_id,
        },
      });
    },
    gotoNote(id) {
      this.$router.push({
        name: 'StudentFreeNoteEdit',
        params: {
          idTraining: this.training,
          idStudentTraining: this.student_training,
          idCustom: id,
          from: this.$route.name,
          idStudent: this.student_id,
        },
      });
    },
  },
  created() {
    this.getActivities();
  },
});

</script>

<style scoped>
.bg-card {
  padding: 0.2rem;
}
.title-activity {
  margin-top: 2rem;
  margin-bottom: 1rem;
  font-size: 1.1rem;
  font-weight: bold;
}
.card-edit-sequence {
  padding: 0.5rem;
}
.messagebox {
  font-size: 0.9rem !important;
}
@media (max-width: 768px) {
  .card-edit-sequence {
    min-height: 48px;
    background-color: #fff;
    margin-bottom: 0.7rem;
    padding: 0.1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border: 1px solid #e3e5eb;
  }
  .card-edit-sequence button {
    display: none;
  }
  .title-activity {
    text-align: center;
  }
  .titles {
    font-size: 0.9rem;
  }
}
@media (max-width: 390px) {
  .card-dashboard {
    padding: 0.5rem !important;
  }
  .card-header-icon {
    padding: 0px;
  }
}
</style>
