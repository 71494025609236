<template>
  <div>
    <b-loading :is-full-page="isFullPage" v-model="isLoading"> </b-loading>
    <div class="columns" id="presentationTraining">
      <div class="column">
        <div class="card_item h-100 is-flex flex-column">
          <div class="title-paragraph mt-auto">Dates de formation</div>
          <div class="mb-auto">
            <span class="subtitle-paragraph">Date de début : </span>
            <span v-if="startDate">{{ startDate | moment }}</span
            ><span v-else>{{ training.start_date | moment }}</span
            ><br />
            <span class="subtitle-paragraph"> Date de fin : </span>
            <span v-if="endDate">{{ endDate | moment }}</span
            ><span v-else>{{ training.end_date | moment }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="title-paragraph">Objectifs</div>
    <div class="field ck" v-html="training.goals" v-if="training.goals"></div>
    <b-message v-else>Aucun information n'a été renseigné</b-message>
    <div class="title-paragraph">Compétences</div>
    <div class="field ck" v-html="training.skills" v-if="training.skills"></div>
    <b-message v-else>Aucune information n'a été renseigné</b-message>
    <div v-if="training.description">
      <div class="title-paragraph">Agenda</div>
      <div class="field ck" v-html="training.description"></div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import { mapState } from 'vuex';

export default {
  props: {
    trainingInfos: {
      type: [Object, Array],
    },
    studentTraining: {
      type: [Object, Array],
    },
    startDate: {
      type: [String],
    },
    endDate: {
      type: [String],
    },
  },
  filters: {
    moment(date) {
      return moment(date).format('D MMMM YYYY');
    },
    day(date) {
      return moment(date).format('D/MM/YY');
    },
    hour(date) {
      return moment(date).format('HH:mm');
    },
  },
  computed: {
    ...mapState('auth', ['currentUser']),
  },
  data() {
    return {
      isLoading: true,
      isFullPage: false,
      isOpenPeriod: true,
      training: [],
    };
  },
  methods: {
    startLoading() {
      this.isLoading = true;
    },
    endLoading() {
      this.isLoading = false;
    },
    order(array) {
      return _.orderBy(array, 'position');
    },
    initTraining() {
      this.startLoading();
      this.$http
        .get(`/trainings/${this.$route.params.idTraining}/?query={name,goals,description,skills,start_date, end_date,legacy_training_framework}&format=json`)
        .then((response) => {
          this.startLoading();
          this.training = response.data;
          this.endLoading();
        }).catch((error) => {
          window.console.log(error);
        });
    },
  },
  created() {
    this.initTraining();
  },
};
</script>
