<template>
  <div class="modalbox modalStep modalRead">
    <div class="is-flex bigmodal">
      <router-link
        v-if="!$route.params.from"
        class="mr-auto"
        :to="
          '/formation-apprenant/' +
          $route.params.idTraining +
          '/' +
          $route.params.idStudent +
          '/' +
          $route.params.idStudentTraining
        "
        ><img
          class="btn-previous"
          :src="require('@/assets/img/arrows/previous.svg')"
      /></router-link>
      <div v-else-if="$route.params.from == 'WaypointEdit'">
        <router-link
          class="mr-auto btn-previous"
          :to="{
            name: 'WaypointEdit',
            params: {
              idTraining: $route.params.idTraining,
              idStudentTraining: $route.params.idStudentTraining,
              idStudent: $route.params.idStudent,
              from: $route.name,
            },
          }"
        >
          <img :src="require('@/assets/img/arrows/previous.svg')" />
        </router-link>
      </div>
      <div v-else-if="$route.params.from == 'WaypointRead'">
        <router-link
          class="mr-auto btn-previous"
          :to="{
            name: 'WaypointRead',
            params: {
              idTraining: $route.params.idTraining,
              idStudentTraining: $route.params.idStudentTraining,
              idStudent: $route.params.idStudent,
              from: $route.name,
            },
          }"
        >
          <img :src="require('@/assets/img/arrows/previous.svg')" />
        </router-link>
      </div>
      <div v-else>
        <router-link
          class="mr-auto btn-previous"
          :to="{ name: $route.params.from }"
        >
          <img :src="require('@/assets/img/arrows/previous.svg')" />
        </router-link>
      </div>
    </div>
    <section class="w-100">
      <b-carousel
        v-model="carousel"
        :animated="animated"
        :has-drag="drag"
        :autoplay="autoPlay"
        :pause-hover="pauseHover"
        :pause-info="pauseInfo"
        :pause-info-type="pauseType"
        :interval="interval"
        :repeat="repeat"
        :arrow-hover="false"
        :indicator="false"
      >
        <b-carousel-item>
          <section class="hero is-medium is-bold">
            <div class="bloc-title">
              <div class="title-paragraph">
                <div class="stepsCount">1/2</div>
                {{ studentrevision.title }}
              </div>
            </div>
            <div class="">
              <div class="container">
                <div class="columns is-tablet mb-4 is-centered">
                  <div class="column dates is-6-tablet is-5-desktop">
                    <b-field
                      label="Période durant laquelle a eu lieu cette activité"
                      class="mb-0"
                      >{{ studentrevision.start_date | day }} au
                      {{ studentrevision.end_date | day }}</b-field
                    >
                  </div>
                  <b-field
                    label="Type de situation"
                    class="column mb-0 is-6-tablet is-5-desktop"
                  >
                    <span v-if="studentrevision.type === 1">En entreprise</span>
                    <span v-else>En centre de formation</span>
                  </b-field>
                </div>
              </div>
              <div class="w-100">
                <div
                  class="card_grey card_edit ck"
                  v-html="studentrevision.entry"
                ></div>
              </div>
            </div>
          </section>
        </b-carousel-item>
        <b-carousel-item v-if="this.$route.params.idFree !== 'new'">
          <section class="hero is-medium is-bold">
            <div class="w-100 bloc-title">
              <div class="title-paragraph">
                <div class="stepsCount">2/2</div>
                Avez-vous acquis de nouvelles compétences ?
              </div>
            </div>
            <div class="w-100 container-editor">
              <div class="subtitle-paragraph mb-4">
                Cette évaluation est facultative. Vous pouvez indiquer les
                compétences que vous avez travaillé lors de cette activité.
              </div>
              <SkillsLevelsBlocks type="free" />
            </div></section
        ></b-carousel-item>
      </b-carousel>
      <div>
        <div
          class="is-flex is-flex-wrap-wrap activityBtns align-items-center"
          style="z-index: 2000"
          v-if="currentUser.role === 'apprenant'"
        >
          <div class="w-100 is-flex">
            <!-- <b-button class="mx-auto btn-action" @click="goNext" rounded
              >voir l'activité</b-button
            > -->
            <b-button @click="modifyStatus" class="mx-auto btn-action" rounded
              >modifier</b-button
            >
          </div>
          <div class="help-btn">
            <AppHelpMessage
              title="Modifier une expérience libre"
              message="<b>Vous pouvez ici rendre compte d'une activité
               qui n'était pas prévue dans votre parcours ou que vous avez du mal à
               associer à une situation de travail proposée.</b> <br><br>N'hésitez pas dans
                l'étape 2 à désigner quelles compétences vous avez travaillé dans cette activité."
              textBtn="Besoin d'aide ?"
            />
          </div>
        </div>
      </div>
    </section>

    <b-loading :is-full-page="isFullPage" v-model="isLoading"> </b-loading>

    <div class="container contentSequence pb-5 mb-5"></div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import moment from 'moment';
import localization from 'moment/locale/fr';
import AppHelpMessage from '@/components/AppHelpMessage.vue';
import SkillsLevelsBlocks from '@/components/Skills/SkillsLevelsBlocks.vue';

moment.updateLocale('fr', localization);

export default {
  components: {
    AppHelpMessage,
    SkillsLevelsBlocks,
  },
  filters: {
    day(date) {
      return moment(date).format('D/MM/YYYY');
    },
    hour(date) {
      return moment(date).format('h:mm');
    },
  },
  data() {
    return {
      carousel: 0,
      animated: 'slide',
      drag: false,
      autoPlay: false,
      pauseHover: false,
      pauseInfo: false,
      repeat: false,
      pauseType: 'is-primary',
      interval: 3000,
      isLoading: true,
      isFullPage: false,
      studentrevision: [],
    };
  },
  computed: {
    ...mapGetters(['getUserById']),
    ...mapState('auth', ['currentUser']),
  },
  methods: {
    startLoading() {
      this.isLoading = true;
    },
    endLoading() {
      this.isLoading = false;
    },
    initRevision() {
      this.$http
        .get(`/agenda/free_situation/${this.$route.params.idFree}/?format=json`)
        .then((response) => {
          this.studentrevision = response.data;
          // this.setdata();
          // this.endLoading();
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
        });
    },
    modifyStatus() {
      this.$buefy.dialog.confirm({
        title: 'Souhaitez-vous modifier le contenu de cette activité ?',
        message: 'Vous pouvez corriger des fautes d\'orthographe ou ajouter du contenu.',
        confirmText: 'Modifier',
        cancelText: 'Annuler',
        type: 'is-info',

        onConfirm: () => {
          this.$router.push({
            name: 'StudentFreeSituationEdit',
            params: {
              idTraining: this.$route.params.idTraining,
              idStudent: this.$route.params.idStudent,
              idSequence: this.$route.params.idSequence,
              idActivity: 's',
              idRevision: this.$route.params.idRevision,
            },
          });
        },
      });
    },
    goNext() {
      if (this.$route.params.idActivity === 's') {
        this.$router.push({
          name: 'StudentSequenceDetail',
          params: {
            idSequence: this.$route.params.idSequence,
            idTraining: this.$route.params.idTraining,
            idStudent: this.$route.params.idStudent,
            idStudentTraining: this.$route.params.idStudentTraining,
          },
        });
      } else {
        this.$router.push({
          name: 'StudentActivity',
          params: {
            idTraining: this.$route.params.idTraining,
            idStudentTraining: this.$route.params.idStudentTraining,
            idSequence: this.$route.params.idSequence,
            idActivity: this.$route.params.idActivity,
          },
        });
      }
    },
    logActivity() {
      const typeAction = 'lecture free_situation';
      this.$http
        .post('agenda/logs/', {
          action: typeAction,
          student_training: this.$route.params.idStudentTraining,
          training: this.$route.params.idTraining,
          student_activity: null,
          student_sequence: null,
        })
        .then(() => {
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
        });
    },
  },
  created() {
    this.endLoading();
    this.initRevision();
    if (this.currentUser.role === 'apprenant' || this.currentUser.role === 'tuteur') { this.logActivity(); }
  },
  mounted() {
  },
  destroyed() {
  },
};
</script>

<style lang="scss" scoped>
.label:not(:last-child) {
  margin-bottom: 0rem;
}
@media (max-width: 576px) {
  .modalRead .bigmodal {
    margin-bottom: 0.1rem;
  }
}
</style>
