<template>
  <div class="is-flex">
    <AppSidebar />
    <b-loading :is-full-page="isFullPage" v-model="isLoading"> </b-loading>
    <div class="main-content">
      <div class="lineCrumbAndAccount ml-auto">
        <AppAccountMenu v-bind:appAccount="crumbs" />
      </div>
      <div class="header-page is-flex flex-column">
        <div class="justify-content-between columns lineHeaderTitle">
          <div class="column title-page short-title">
            {{ $route.meta.header_title }}
          </div>
          <div class="column is-narrow actions">
            <router-link to="/wiki"
              ><b-button type="is-primary is-rounded"
                >Revenir à la documentation</b-button
              >
            </router-link>
          </div>
        </div>
      </div>
      <div class="content-page pt-5">
        <section>
          <b-collapse
            class="card"
            animation="slide"
            v-for="(collapse, index) of collapses"
            :key="index"
            :open="isOpen == index"
            @open="isOpen = index"
          >
            <template #trigger="props">
              <div class="card-header" role="button">
                <p class="card-header-title">
                  {{ collapse.title }}
                </p>
                <a class="card-header-icon">
                  <b-icon :icon="props.open ? 'menu-down' : 'menu-up'">
                  </b-icon>
                </a>
              </div>
            </template>
            <div class="card-content">
              <div class="content pl-3">
                <b-field
                  v-for="collapse in collapse.steps"
                  :key="collapse.title"
                >
                  <b-checkbox>{{ collapse.name }}</b-checkbox>
                </b-field>
              </div>
            </div>
          </b-collapse>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import AppAccountMenu from '@/components/AppAccountMenu.vue';
import AppSidebar from '@/components/AppSidebar.vue';

export default ({
  components: {
    AppAccountMenu,
    AppSidebar,
  },
  data() {
    return {
      isLoading: false,
      isFullPage: false,
      isOpen: 0,
      collapses: [
        {
          title: 'Création de la Maquette',
          text: 'I\'m baby green juice swag heirloom selvage, vaporware cronut asymmetrical.Skateboard truffaut artisan kogi godard health goth photo booth.Distillery cronut try-hard, meh kale chips 90\'s gochujang slow- carb brooklyn tilde fashion axe vinyl small batch green juice fam.Occupy polaroid tbh, quinoa church - key vape crucifix blue bottle pitchfork kickstarter blog kogi post - ironic squid retro.Art party shoreditch cray actually knausgaard vegan paleo chillwave iPhone chia kitsch asymmetrical godard.VHS schlitz hell of taxidermy, YOLO vape affogato flannel shaman drinking vinegar lo - fi live - edge scenester.',
          steps: [
            {
              name: 'importer le référentiel dans docquality',
            },
            {
              name: 'créer les blocs de compétences',
            },
            {
              name: 'trasnformer en une maquette dans insitu',
            },
          ],
        },
        {
          title: 'Création d\'une formation',
          steps: [
            {
              name: 'ouvrir la formation depuis une maquette',
            },
            {
              name: 'ajuster le parcours si besoin',
            },
            {
              name: 'attribuer des utilisateurs',
            },
            {
              name: 'associer les tuteurs et managers aux apprenants',
            },
          ],
          text: 'Text 2',
        },
        {
          title: 'Lancement de la formation',
          text: 'Text 3',
          steps: [
            {
              name: 'renseigner les dates des expériences',
            },
            {
              name: 'renseigner les dates des activtés (notamment pour une expérience afest qui se déroule sur plusieurs jours différents',
            },
            {
              name: 'attribuer les dates à tous les apprenants',
            },
            {
              name: 'si besoin de modifier, vous pouvez forcer l\'écrasement des dates',
            },
            {
              name: 'former les formateurs à l\'utilisation d\'insitu',
            },
          ],
        },
        {
          title: 'Pendant la formation',
          text: 'Text 3',
          steps: [
            {
              name: 'vérifier que le sapprenants renseignent bien les activités',
            },
            {
              name: 'vérifier que tout se passe bien',
            },
          ],
        },
        {
          title: 'Pendant des temps en entreprise',
          text: 'Text 3',
          steps: [
            {
              name: 'suivre les expériences présentation période',
            },
            {
              name: 'vérifier que les activités ont bien lieu, et sont validées',
            },
          ],
        },
        {
          title: 'Fin de formation',
          text: 'Text 3',
          steps: [
            {
              name: 'débroief sur l\'outil',
            },
            {
              name: 'propositions d\'amélioration',
            },
          ],
        },
      ],
      crumbs: [{
        text: 'Maquettes',
        href: '/maquettes',
        class: 'is-active',
      },
      ],
    };
  },
});
</script>

<style scoped>
.content {
  margin-bottom: 2rem;
}
.field {
  margin-bottom: 1rem !important;
}
</style>
