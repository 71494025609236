<template>
  <div class="">
    <b-loading :is-full-page="isFullPage" v-model="isLoading"> </b-loading>
    <h1 class="title-paragraph mb-3">Compétences</h1>
    <div class="columns is-vcentered">
      <div class="column">
        <div class="levels mb-5">
          <div class="subtitle-paragraph mb-2 pt-5">
            Niveaux d'acquisition des compétences
          </div>
          <div
            v-if="skillsLevels[0]"
            :class="
              !skillsLevels[0].description
                ? 'is-flex align-items-center levels-name'
                : ''
            "
          >
            <div
              v-for="(level, index) of skillsLevels"
              :key="level.id"
              style="max-width: 900px"
            >
              <div class="is-flex align-items-center">
                <img
                  :src="require(`@/assets/img/levels/level${index + 1}.svg`)"
                  alt=""
                  class="img_status mr-0"
                />
                <span class="ml-2 mr-3"
                  ><b>{{ level.name }}</b></span
                >
              </div>
              <div
                style="font-size: 0.8rem"
                class="mb-3 ml-4 description-level"
              >
                {{ level.description }}
              </div>
            </div>
          </div>
          <b-message type="is-info" v-if="!skillsLevels.length"
            >Aucun niveau n'a été renseigné</b-message
          >
        </div>
      </div>
      <!-- <div class="column is-narrow ml-auto mt-4">
        <div class="caption is-flex align-items-center">
          <div class="caption-item">
            <img
              :src="require(`@/assets/img/levels/Oval.svg`)"
              alt=""
              class="img_status"
            />
            Tuteur
          </div>
          <div class="caption-item">
            <img
              :src="require(`@/assets/img/levels/Rectangle.svg`)"
              alt=""
              class="img_status"
            />
            Formateur
          </div>
          <div class="caption-item">
            <img
              :src="require(`@/assets/img/levels/Triangle.svg`)"
              alt=""
              class="img_status"
            />
            Apprenant
          </div>
        </div>
      </div>-->
    </div>

    <div class="columns is-fullhd is-vcentered">
      <div class="column is-narrow subtitle-paragraph mb-0">
        Compétences évaluées par :
      </div>
      <div class="column">
        <b-select
          placeholder="Tous rôles confondus"
          expanded
          v-model="selectedRole"
          @input="changeRole"
          class="pl-0"
        >
          <option value="">Tous rôles confondus</option>
          <option value="apprenant">Apprenant</option>
          <option value="tuteur,apprenant">Tuteur, apprenant</option>
          <option value="tuteur">Tuteur seul</option>
          <option value="teacher">Formateur</option>
        </b-select>
      </div>
      <div class="column is-narrow ml-auto mt-4">
        <div class="caption is-flex align-items-center roles-name">
          <div class="caption-item">
            <img
              :src="require(`@/assets/img/levels/Oval.svg`)"
              alt=""
              class="img_status"
            />
            Tuteur
          </div>
          <div class="caption-item">
            <img
              :src="require(`@/assets/img/levels/Rectangle.svg`)"
              alt=""
              class="img_status"
            />
            Formateur
          </div>
          <div class="caption-item">
            <img
              :src="require(`@/assets/img/levels/Triangle.svg`)"
              alt=""
              class="img_status"
            />
            Apprenant
          </div>
        </div>
      </div>
    </div>
    <b-checkbox v-model="datesSelected" class="mb-5">
      Afficher les dates
    </b-checkbox>
    <div v-if="skillsList.length">
      <!-- <div class="helpUser mt-5">
        <div>Voici la liste des compétences associées à cette formation</div>
      </div> -->
      <div class="mb-3">
        <b-button
          rounded
          @click="toggle()"
          size="is-small"
          class="mr-2"
          v-if="!isOpen"
          >ouvrir tout</b-button
        >
        <b-button rounded @click="toggle()" size="is-small" class="mr-2" v-else
          ><span>fermer tout</span></b-button
        >
      </div>
      <b-table
        :data="order(skillsList)"
        ref="table"
        detailed
        custom-detail-row
        :default-sort="['name', 'asc']"
        detail-key="name"
        @details-open="
          (row, index) => $buefy.toast.open(`Détail de ${row.name}`)
        "
        :sticky-header="true"
        class="skillstable skillsstudent skillsmobile"
      >
        <b-table-column
          field="code"
          label="Code"
          v-slot="props"
          :sticky="true"
          width="40"
        >
          <template>
            <b>{{ props.row.reference }}</b>
          </template>
        </b-table-column>
        <b-table-column field="name" label="Nom" v-slot="props">
          <b @click="toggle()"> {{ props.row.name }}</b>
        </b-table-column>
        <b-table-column field="name" label="Évaluations"> </b-table-column>
        <template slot="detail" slot-scope="props">
          <tr
            v-for="(item, index) in props.row.skills"
            :key="item.name + props.row.reference + index"
          >
            <td></td>
            <td class="is-sticky">{{ item.reference }}</td>
            <td class="is-name">{{ item.name }}</td>
            <div>
              <SkillHistoryByStudent
                :skill="item"
                :dates.sync="datesSelected"
              />
            </div>
          </tr>
        </template>
      </b-table>
    </div>
    <div v-else>
      <b-message type="is-info">
        Aucune compétence n'est associée à cette formation.
      </b-message>
    </div>
    <b-modal
      v-model="isBlockModalActive"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-modal
    >
      <template>
        <div class="modal-card modal-users" style="width: auto">
          <section class="modal-card-body">
            <div class="has-text-right mb-3">
              <a @click="isBlockModalActive = false" class="ml-auto">
                <b-icon
                  class="closeButton"
                  icon="close"
                  type="is-primary"
                ></b-icon>
              </a>
            </div>
            <div class="has-text-small mb-2 mt-5">
              <b>Le bloc de compétence en détails</b>
            </div>

            <b-field label="Nom">
              {{ formBlock.name }}
            </b-field>
            <b-field label="Référence">
              {{ formBlock.reference }}
            </b-field>
            <b-field label="Description">
              {{ formBlock.description }}
            </b-field>
          </section>
        </div>
      </template>
    </b-modal>
    <div id="endSkills">&nbsp;</div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import _ from 'lodash';
import moment from 'moment';
import localization from 'moment/locale/fr';
import SkillHistoryByStudent from './SkillHistoryByStudent.vue';

moment.updateLocale('fr', localization);

export default {
  props: {
    typeoftraining: {
      type: String,
    }, // framework, training or studenttraining
    objectId: {
      type: Number,
    },
  },
  components: {
    SkillHistoryByStudent,
  },
  filters: {
    day(date) {
      return moment(date).format('DD/MM');
    },
    year(date) {
      return moment(date).format('YYYY');
    },
  },
  data() {
    return {
      isLoading: false,
      isFullPage: false,
      formSkill: {
        id: '',
        name: '',
        description: '',
        block: '',
        reference: '',
      },
      formBlock: {
        id: '',
        name: '',
        description: '',
        block: '',
        reference: '',
      },
      skillsList: [],
      isOpenSkill: false,
      isOpen: false,
      isOpenAdd: false,
      isOpenBlock: true,
      isSkillModalActive: false,
      isBlockModalActive: false,
      isNew: true,
      myskillsList: [],
      skillsLevels: [],
      selectedRole: null,
      datesSelected: false,
    };
  },
  computed: {
    ...mapState('auth', ['currentUser']),
  },
  methods: {
    startLoading() {
      this.isLoading = true;
    },
    endLoading() {
      this.isLoading = false;
    },
    order(array) {
      return _.orderBy(array, 'position');
    },
    orderByDate(array) {
      return _.orderBy(array, 'date_created', 'asc');
    },
    initSkills(role) {
      this.startLoading();
      let roleSelected = '';
      if (role) {
        roleSelected = `&role=${role}`;
      }
      this.$http
        .get(`/agenda/get_skills_history/?student_training=${this.objectId}${roleSelected}&format=json`)
        .then((response) => {
          this.skillsList = this.order(response.data);
          if (this.typeoftraining === 'training') {
            /* eslint-disable */
            this.skillsList = this.skillsList.map(item => {
              return { skills: item.tskills, name: item.name, reference: item.reference, id: item.id, training: item.training, description: item.description, position: item.position };
            });
            /* eslint-enable */
          }

          for (let i = 0; i < this.skillsList.length; i += 1) {
            const myBlocks = this.skillsList;
            // eslint-disable-next-line
            this.skillsList[i].skills = this.order(myBlocks[i].skills);
          }
          this.endLoading();
        });
    },
    initLevels() {
      this.$http
        .get(`/traininglevels/?training=${this.$route.params.idTraining}&format=json`)
        .then((response) => {
          this.skillsLevels = this.order(response.data);
        });
    },
    openEdit(index) {
      if (this.isOpen !== index) {
        this.isOpen = index;
      } else {
        this.isOpen = false;
      }
    },
    // collapseBlock(id) {
    //   window.console.log(id);
    //   const element = document.getElementById(id);
    //   element.classList.toggle('blockOpen');
    //   this.formSkill.name = null;
    // },
    seeSkilldetail(skill, newValue) {
      // this.isSkillModalActive = true;
      this.formSkill.id = skill.id;
      this.formSkill.name = skill.name;
      this.formSkill.description = skill.description;
      this.formSkill.reference = skill.reference;
      this.formSkill.block = skill.block;
      this.isNew = newValue;
      if (this.skill.description) { this.isSkillModalActive = true; }
    },
    seeBlockdetail(block) {
      this.isBlockModalActive = true;
      this.formBlock.id = block.id;
      this.formBlock.name = block.name;
      this.formBlock.description = block.description;
      this.formBlock.reference = block.reference;
      this.isNew = false;
    },
    collapseBlock(id, icon) {
      const element = document.getElementById(id);
      // const elementContent = document.getElementById(idContent);
      element.classList.toggle('blockOpen');
      // if (element.style.height) {
      //   element.style.height = null;
      // } else {
      //   element.style.height = `${elementContent.offsetHeight + 70}px`;
      // }
      const iconOpen = document.getElementById(icon);
      iconOpen.classList.toggle('iconOpen');
    },
    collapseAll(allOpen) {
      for (let i = 0; i < this.skillsList.length; i += 1) {
        const element = document.getElementById(`s${i}`);
        const iconOpen = document.getElementById(`icon${i}`);
        if (allOpen) {
          if (!element.classList.contains('blockOpen')) { // jouvretout
            element.classList.add('blockOpen');
            iconOpen.classList.add('iconOpen');
          }
        } else if (!allOpen) {
          if (element.classList.contains('blockOpen')) {
            element.classList.remove('blockOpen');
            iconOpen.classList.remove('iconOpen');
            // window.console.log('fermer tout');
          }
        }
      }
    },
    gotoSequence(check) {
      window.console.log(check);
      // if (check.free_situation) {
      //   window.console.log('free');
      // } else if (check.student_activity_revision) {
      //   window.console.log('revision');
      // } else {
      //   window.console.log('sequence');
      //   window.console.log(check);
      // }
    },
    toggle() {
      for (let i = 0; i < this.skillsList.length; i += 1) {
        this.$refs.table.toggleDetails(this.skillsList[i]);
      }
      this.isOpen = !this.isOpen;
    },
    changeRole() {
      this.initSkills(this.selectedRole);
    },
  },
  created() {
    this.initSkills();
    this.initLevels();
  },
  mounted() {
  },
};
</script>

<style scoped>
.disabled {
  color: #dfdfeb;
}
.card-header-icon {
  width: auto;
  padding: 0px 0px 0px 0.5rem;
}
.card-header {
  margin-bottom: 0.5rem;
  transition: 0.2s;
}
/* .card-padding {
  padding: 1rem 0px;
} */
.card_skill {
  padding: 1rem !important;
}
.icon-level {
  width: 39px;
}
.closeButton {
  font-size: 1.5rem !important;
}
.modal-card {
  min-width: 627px;
}
/* .font-size:2rem */
.level-point {
  font-size: 2rem;
}
.has-text-warning {
  color: #ffc505 !important;
}
.card-skill {
  border-bottom: 1px solid #ededed;
  padding: 0.5rem;
}
.card-skill:last-child {
  border-bottom: 0px solid #ededed;
}

.icon {
  cursor: unset !important;
}
.description-level {
  padding-left: 1.4rem;
}
@media (max-width: 640px) {
  .modal-card {
    min-width: unset;
    width: 100%;
  }
}
.datesText {
  font-size: 12px;
}
.bloc-left {
  position: absolute;
  background-color: #fff;
  width: 400px;
  z-index: 1;
}
.bloc-right {
  margin-left: 400px;
  z-index: 0;
}
.card-content {
  width: 100%;
  overflow: auto;
}
.is-name {
  width: 400px;
}
</style>
