<template>
  <div v-if="dates.dates">
    <!-- <h1 class="title-paragraph">
      <span v-if="dates.dates.length === 1"
        >{{ dates.dates.length }} activité</span
      >
      <span v-if="dates.dates.length > 1"
        >{{ dates.dates.length }} activités</span
      >

      <span v-if="typePlanning === 'late'"> en retard</span>
      <span v-if="typePlanning === 'done'"> effectuées</span>
    </h1> -->
    <div>
      <b-message
        type="is-info"
        v-if="dates.dates.length === 0 && typePlanning === 'late'"
      >
        Aucune activité n'est attendue pour le moment et en retard
      </b-message>
      <b-message
        type="is-info"
        v-if="dates.dates.length === 0 && typePlanning === 'todo'"
      >
        Pas de nouvelles activités à faire pour le moment
      </b-message>
      <b-message
        type="is-info"
        v-if="dates.dates.length === 0 && typePlanning === 'done'"
      >
        Pas d'activités effectuées
      </b-message>
      <b-message
        type="is-info"
        v-if="dates.dates.length === 0 && typePlanning === 'next'"
      >
        Pas de nouvelles activités à venir pour le moment
      </b-message>
    </div>
    <div>
      <b-message type="is-success" v-if="typePlanning === 'done'">
        <div v-if="dates.dates.length === 1">
          {{ dates.dates.length }} activité a bien été réalisée.
        </div>
        <div v-if="dates.dates.length > 1">
          {{ dates.dates.length }} activités ont bien été réalisées.
        </div>
      </b-message>
      <b-message type="is-info" v-if="typePlanning === 'todo'">
        <div v-if="dates.dates.length > 0">
          Vous avez {{ dates.dates.length }}
          <span v-if="dates.dates.length > 1">activités</span
          ><span v-else>activité</span> à effectuer en ce moment.
        </div>
      </b-message>
      <b-message type="is-danger" v-if="typePlanning === 'late'">
        <div v-if="dates.dates.length === 1">
          Attention, {{ dates.dates.length }} activité n'a pas été effectuée ou
          validée.
        </div>
        <div v-if="dates.dates.length > 1">
          Attention, {{ dates.dates.length }} activités n'ont pas été effectuées
          ou validées.
        </div>
        <!-- Vous avez {{ dates.dates.length }}
        <span v-if="dates.dates.length > 1">activités</span
        ><span v-else>activité</span>
        à effectuer. -->
      </b-message>
    </div>
    <section v-if="errored">
      <b-message type="is-danger">
        Nous sommes sincèrement désolés, nous ne sommes pas en mesure d'afficher
        le planning pour le moment. Veuillez réessayer ultérieurement ou nous
        contacter via le support.
      </b-message>
    </section>
    <b-loading :is-full-page="isFullPage" v-model="isLoading"> </b-loading>

    <div v-if="dates.dates">
      <div v-if="dates.dates.length">
        <b-button @click="orderDates">Trier {{ textOrder }}</b-button>
        <b-button @click="orderStartDates">par date de {{ textDate }}</b-button>
      </div>
      <div
        class="events is-tablet is-multiline ml-0 mr-0"
        v-if="dates.dates.length"
      >
        <div
          class="
            card_planning-list card_item
            columns
            is-vcentered
            justify-content-between
          "
          v-for="date in dates.dates"
          :key="date.id"
        >
          <div class="column is-narrow pr-5">
            <div class="day" v-if="start === 'start_date'">
              {{ date.start_date | day }}
            </div>
            <div class="day" v-else>{{ date.end_date | day }}</div>
            <div class="hours">
              <div>
                du {{ date.start_date | day }} -
                {{ date.start_date | hour }}
              </div>
              <div>
                au {{ date.end_date | day }} - {{ date.end_date | hour }}
              </div>
            </div>
          </div>
          <div class="column has-text-left is-desktop-third">
            <div>
              <div class="type">{{ date.sequence_type_label }}</div>
              <div class="name"><b> nom de l'expérience : </b></div>
              <div class="name_sequence">{{ date.sequence_name }}</div>
            </div>
          </div>
          <div class="column has-text-left">
            <div class="name">Activité : {{ date.name }}</div>
            <b-icon icon="user" size="is-medium" type="is-primary"> </b-icon
            ><b class="user">Qui rédige ? : </b>{{ date.context }}
            <div class="user" v-if="currentUser.role != 'apprenant'">
              <span v-if="typeView != 'student'">
                <b-icon icon="user" size="is-medium" type="is-primary">
                </b-icon>
                <b>Apprenant :</b> {{ date.student_name }}</span
              >
            </div>
            <!-- <div class="user" v-if="currentUser.role != 'apprenant'">
                    {{ date.context }}
                  </div> -->
          </div>
          <div
            class="column is-narrow"
            @click="
              gotoStudentActivity(
                date.student,
                date.student_sequence,
                date.student_activity,
                date.student_training,
                date.training
              )
            "
          >
            <b-button type="is-primary" rounded>Voir l'activité</b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import localization from 'moment/locale/fr';
import { mapState } from 'vuex';
import _ from 'lodash';

moment.updateLocale('fr', localization);

export default {
  props: {
    student: {
      type: Number,
    },
    studentTraining: {
      type: Number,
    },
    typePlanning: {
      type: String,
      // todo = à venir - late = en retard
    },
    typeView: {
      type: String,
      // student
      // tutor
      // others
    },
    dates: [Object, Array],
  },
  filters: {
    moment(date) {
      return moment(date).format('D MMMM YYYY');
    },
    day(date) {
      return moment(date).format('D/MM/YYYY');
    },
    hour(date) {
      return moment(date).format('H:mm');
    },
  },
  components: {
  },
  computed: {
    // dates() {
    //   return this.$store.state.dates;
    // },
    ...mapState('auth', ['currentUser']),
  },
  data() {
    return {
      isLoading: true,
      isFullPage: false,
      errored: false,
      datesLate: [],
      filter: 'desc',
      start: 'start_date',
      textOrder: 'du + récent au plus ancien',
      textDate: 'début',
    };
  },
  methods: {
    messageUpdated() {
      this.$emit('messageFromChild');
    },
    gotoStudentActivity(studentId, studentSequence, studentActivity, studenttraining, training) {
      this.$router.push({
        name: 'StudentActivity',
        params: {
          idStudent: studentId,
          idSequence: studentSequence,
          idActivity: studentActivity,
          idStudentTraining: studenttraining,
          idTraining: training,
          from: 'Planning',
        },
      });
    },
    startLoading() {
      this.isLoading = true;
    },
    endLoading() {
      this.isLoading = false;
    },
    order(array, start, order) {
      return _.orderBy(array, start, order);
    },
    orderDates() {
      window.console.log(this.filter);
      if (this.filter === 'desc') {
        this.filter = 'asc';
        this.textOrder = 'du + ancien au plus récent';
      } else {
        this.filter = 'desc';
        this.textOrder = 'du + récent au plus ancien';
      }
      const mydates = this.order(this.dates.dates, this.start, this.filter);
      this.dates.dates = mydates;
    },
    orderStartDates() {
      if (this.start === 'start_date') {
        this.start = 'end_date';
        this.textDate = 'fin';
      } else {
        this.start = 'start_date';
        this.textDate = 'début';
      }
      const mydates = this.order(this.dates.dates, this.start, this.filter);
      this.dates.dates = mydates;
    },
  },
  mounted() {
    const mydates = this.order(this.dates.dates, 'start_date', 'asc');
    // const mydates = this.dates.dates;
    this.dates.dates = mydates;
  },
  created() {
    this.endLoading();
  },
};
</script>
<style lang="scss">
</style>
