<template>
  <div id="activities">
    <div v-if="studentprogressions.length">
      <div
        v-for="progression in studentprogressions"
        :key="progression.id"
        class="card_activity"
        @click="readProgression(progression.id, null)"
      >
        <div class="activity-item is-flex align-items-center">
          <div>
            <img
              :src="require(`@/assets/img/steps/status3.svg`)"
              alt=""
              class="img_status mr-3"
            />
          </div>
          <div class="columns is-vcentered mb-0">
            <div class="column dates mr-3 is-narrow pt-0 pb-0 pr-0">
              {{ progression.date_created | day }}
            </div>
            <div class="column title_activity pt-0 pb-0 pr-5 mr-0">
              {{ progression.title }}
            </div>
          </div>
          <b-button outlined rounded type="is-primary" class="ml-auto"
            >voir</b-button
          >
        </div>
      </div>
    </div>
    <b-message type="is-info" v-else class="pb-4"
      >Pour le moment, il n'y a pas encore de progression enregistrée. Cliquez
      sur le bouton "vous avez progressé" pour ajouter des notes quand vous
      refaites cette activité et apprenez de nouvelles choses.
    </b-message>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';
import localization from 'moment/locale/fr';

moment.updateLocale('fr', localization);

export default {
  props: {
    sequence: {
      type: Number,
    }, // object sequence
  },
  filters: {
    day(date) {
      return moment(date).format('D/MM/YYYY');
    },
    hour(date) {
      return moment(date).format('h:mm');
    },
  },
  components: {
  },
  data() {
    return {
      studentprogressions: [],
    };
  },
  computed: {

    ...mapState('auth', ['currentUser']),
  },
  methods: {
    initProgression() {
      this.$http
        .get(`/agenda/student_activity_revision/?student_sequence=${this.sequence}&format=json`)
        .then((response) => {
          this.studentprogressions = response.data;
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
        });
    },
    readProgression(idProgression, idActivity) {
      let activity = idActivity;
      if (!idActivity) {
        activity = 's';
      }
      this.$router.push({
        name: 'StudentProgressionRead',
        params: {
          idTraining: this.$route.params.idTraining,
          idStudentTraining: this.$route.params.idStudentTraining,
          idSequence: this.$route.params.idSequence,
          idActivity: activity,
          idRevision: idProgression,
          from: this.$route.name,
        },
      });
    },

    /* -----------------------Ressources functions------------------------- */
  },
  created() {
    this.initProgression();
  },
};
</script>

<style scoped>
</style>
