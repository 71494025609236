<template>
  <div class="mb-6 card_item">
    <b-message type="is-info" size="is-small">
      Vérifiez, tout d'abord, que vous êtes bien autorisé à diffuser cette
      ressource. Sinon, vous pouvez anonymiser les données.</b-message
    >
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <form>
        <div class="mb-3" v-if="action === 'post'">
          <b-radio v-model="formResource.type" name="name" native-value="1">
            Fichier
          </b-radio>
          <b-radio v-model="formResource.type" name="name" native-value="2">
            Lien externe (site web, application tierce, etc.)
          </b-radio>
        </div>
        <div v-if="formResource.type === '1'">
          <ValidationProvider
            rules="required"
            v-slot="{ errors, valid }"
            v-if="action === 'post'"
          >
            <b-field
              :type="{ 'is-danger': errors[0], 'is-success': valid }"
              :message="errors"
            >
              <b-upload v-model="file" drag-drop @input="checkFile">
                <section class="section">
                  <div class="content has-text-centered">
                    <p>
                      <b-icon icon="upload" size="is-large"> </b-icon>
                    </p>
                    <p>Glissez, déposez votre ressource ici</p>
                  </div>
                </section>
              </b-upload>
            </b-field>
          </ValidationProvider>
          <div v-if="file" class="card_grey_light">{{ nameFile }}</div>
        </div>
        <div v-if="formResource.type === '2'">
          <ValidationProvider
            rules="required"
            v-slot="{ errors, valid }"
            v-if="action === 'post'"
          >
            <b-field
              label="Lien externe"
              :type="{ 'is-danger': errors[0], 'is-success': valid }"
              :message="errors"
            >
              <b-input
                type="url"
                v-model="formResource.typeUrl"
                placeholder="https://acme.org"
                max-length="140"
              >
              </b-input>
            </b-field>
          </ValidationProvider>
        </div>

        <ValidationProvider rules="required" v-slot="{ errors, valid }">
          <b-field
            label="Titre de la ressource"
            :type="{ 'is-danger': errors[0], 'is-success': valid }"
            :message="errors"
          >
            <b-input
              type="title"
              v-model="formResource.resourceName"
              placeholder="Quel est le nom de la ressource ?"
            >
            </b-input>
          </b-field>
        </ValidationProvider>
        <ValidationProvider>
          <b-field label="Description courte">
            <b-input
              type="title"
              v-model="formResource.resourceShort"
              placeholder="Ajoutez une description rapide de la ressource"
            >
            </b-input>
          </b-field>
        </ValidationProvider>

        <b-field label="Description longue (facultative)">
          <b-input
            v-model="formResource.resourceLong"
            type="textarea"
            placeholder="Décrivez succinctement la ressource"
          >
          </b-input>
        </b-field>
        <!-- disabled for now -->

        <!-- <ValidationProvider
          rules="required"
          v-slot="{ errors, valid }"
          v-if="sequence"
        >
          <b-field
            label="Associer à"
            :type="{ 'is-danger': errors[0], 'is-success': valid }"
          >
            <b-select
              placeholder="Choisissez le type"
              expanded
              v-model="selectedAttachedType"
              @input="changeType"
              class="pl-0"
            >
              <option value="1">Toutes les expériences du même type</option>
              <option value="2">Cette expérience seulement</option>
              <option value="3">Une activité précise de cette expérience</option>
            </b-select>
          </b-field>
        </ValidationProvider> -->
        <b-field
          v-if="selectedAttachedType === '3' && sequence"
          label="Associer à une activité"
        >
          <b-select
            placeholder="Associer à une activité"
            expanded
            v-model="formResource.selectedActivity"
          >
            <option
              aria-role="listitem"
              v-for="activity in sequence.activities"
              :key="activity.id"
              :value="activity.id"
            >
              {{ activity.name }}
            </option>
          </b-select>
        </b-field>
        <ValidationProvider
          rules="required"
          v-slot="{ errors, valid }"
          v-if="currentUser.role != 'apprenant'"
        >
          <b-field
            label="À destination de (vous pouvez sélectionner plusieurs rôles)"
            :type="{ 'is-danger': errors[0], 'is-success': valid }"
            :message="errors"
          >
            <div class="mt-5">
              <b-checkbox
                v-model="formResource.selectedRoles"
                native-value="apprenant"
              >
                Apprenant
              </b-checkbox>
              <b-checkbox
                v-model="formResource.selectedRoles"
                native-value="tuteur"
              >
                Tuteur
              </b-checkbox>
              <b-checkbox
                v-model="formResource.selectedRoles"
                native-value="manager"
              >
                Manager
              </b-checkbox>
            </div>
          </b-field>
          <div class="field">
            <b-switch
              v-model="formResource.importance"
              true-value="important"
              false-value="normal"
              native-value="normal"
            >
              {{ formResource.importance }}
            </b-switch>
          </div>
          <!-- Attachements associés -->
          <!-- <div v-if="resourceItem.attached_sequence_type">
            <b-field
              label="Ressource attachée à un type d'expérience'"
              v-if="resourceItem.attached_sequence_type.length"
            >
              <div>
                {{ resourceItem.attached_sequence_type }}
              </div>
              <b-button
                @click="removeSequenceType(resourceItem.attached_sequence_type)"
                >Détacher</b-button
              >
              <b-button
                @click="gotoFramework(resourceItem.attached_sequence_type)"
                >voir</b-button
              >
            </b-field>
          </div>
          <div v-if="resourceItem.attached_frameworks">
            <b-field
              label="Ressource attachée à une maquette"
              v-if="resourceItem.attached_frameworks.length"
            >
              <div>{{ resourceItem.attached_frameworks }}</div>
              <b-button
                @click="removeSequenceType(resourceItem.attached_sequence_type)"
                >Détacher</b-button
              >
              <b-button
                @click="removeSequenceType(resourceItem.attached_sequence_type)"
                >voir</b-button
              ></b-field
            >
          </div>
          <div v-if="resourceItem.attached_trainings">
            <b-field
              label="Ressource attachée à une formation"
              v-if="resourceItem.attached_trainings.length"
              >{{ resourceItem.attached_trainings }}</b-field
            >
          </div>
          <div v-if="resourceItem.attached_student_trainings">
            <b-field
              label="Ressource attachée à un apprenant"
              v-if="resourceItem.attached_student_trainings.length"
              >{{ resourceItem.attached_student_trainings }}</b-field
            >
          </div>
          <div v-if="resourceItem.attached_student_training_sequences">
            <b-field
              label="Ressource attachée à l'expérience d'un apprenant"
              v-if="resourceItem.attached_student_training_sequenceslength"
              >{{ resourceItem.attached_student_training_sequences }}</b-field
            >
          </div>
          <div v-if="resourceItem.attached_student_training_activities">
            <b-field
              label="Ressource attachée à l'activité d'un apprenant"
              v-if="resourceItem.attached_student_training_activities.length"
              >{{ resourceItem.attached_student_training_activities }}</b-field
            >
          </div> -->
        </ValidationProvider>
      </form>
      <b-button
        type="is-primary"
        @click="handleSubmit(postResource)"
        v-if="action === 'post'"
        :disabled="fileValidity === false && formResource.type === '1'"
        >Associer une ressource</b-button
      >
      <b-button
        type="is-primary"
        @click="handleSubmit(patchResource)"
        v-if="action === 'patch' && formResource.type === '1'"
        >Modifier la ressource</b-button
      >
    </ValidationObserver>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { mapState } from 'vuex';
// import ResourceCard from '@/components/Resources/ResourceCard.vue';

export default {
  props: {
    sequence: {
      type: [Object, Array],
    }, // object sequence
    typeoftraining: {
      type: String,
    }, // framework, training or studenttraining
    action: {
      type: String,
    }, // post or patch
    idTraining: {
      type: [Number, String],
    }, // id framework, id training, or id student training
    idResource: {
      type: Number,
    }, // id resource to patch
    isOpen: {
      type: Boolean,
      // variable to close parent component collapse
    },
    resource: [Object, Array],
    // resource object to control resource parent
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    // ResourceCard,
  },
  data() {
    return {
      formResource: {
        resourceName: '',
        selectedRoles: [],
        selectedActivity: [],
        selectedSequence: [],
        selectedType: [],
        active: 1,
        resourceShort: '',
        resourceLong: '',
        type: '1',
        importance: 'normal',
        typeUrl: null,
      },
      selectedAttachedType: null,
      file: null,
      importantSwitch: {
        1: 'Importante',
        0: 'Non importante',
      },
      nameFile: null,
      resourceItem: {},
      opencard: this.isOpen,
      fileValidity: false,
      roles: [],
      importance: null,
    };
  },
  computed: {

    ...mapState('auth', ['currentUser']),
  },
  methods: {
    verifFields() {
      // function to rewrite some fields to send to ws
      if (this.selectedAttachedType === '1') {
        this.formResource.selectedType = this.sequence.sequence_type;
      }
      if (this.selectedAttachedType === '2' || this.sequence) {
        this.formResource.selectedSequence = [this.sequence.id];
      }
      if (this.selectedAttachedType === '3') {
        this.formResource.selectedActivity = [this.formResource.selectedActivity];
      }
      if (this.formResource.importance === '1') {
        this.importance = 'important';
      }
      if (this.formResource.importance === '0') {
        this.importance = 'normal';
      }
    },
    postResource() {
      // function to post resource
      this.verifFields();
      if (this.currentUser.role === 'apprenant') {
        this.formResource.selectedRoles = [
          'apprenant',
          'tuteur',
          'manager',
        ];
        this.formResource.importance = 'normal';
      }
      const formData = new FormData();
      // const array = Object(['manager', 'apprenant']);
      // const array = this.formResource.selectedRoles;
      // const array = JSON.parse(JSON.stringify(this.formResource.selectedRoles));
      // window.console.log(array);
      if (this.formResource.type === '1') { formData.append('file', this.file); }
      if (this.formResource.type === '2') {
        formData.append('url', this.formResource.typeUrl);
      }
      formData.append('name', this.formResource.resourceName);
      formData.append('short_description', this.formResource.resourceShort);
      formData.append('long_description', this.formResource.resourceLong);
      formData.append('attached_cfa', this.currentUser.cfa_id);
      formData.append('attached_organization', this.currentUser.organization_id);
      formData.append('active', 1);
      formData.append('importance', this.formResource.importance);
      formData.append('owner', this.currentUser.id);

      for (let i = 0; i < this.formResource.selectedRoles.length; i += 1) {
        formData.append('attached_roles', this.formResource.selectedRoles[i]);
      }

      /// all these next lines are here if multiple options are displayed
      // (sequence type, this particular sequence, this activity)
      // for now these options are disabled

      // if it's a "framework" select field sequences and activities related
      if (this.typeoftraining === 'framework') {
        if (this.selectedAttachedType === '1') {
          formData.append('attached_sequence_type', this.formResource.selectedType);
        }
        if (!this.selectedAttachedType) { formData.append('attached_frameworks', this.idTraining); }
        if (this.selectedAttachedType === '2') {
          formData.append('attached_framework_sequences', this.formResource.selectedSequence);
        }
        if (this.selectedAttachedType === '3') {
          formData.append('attached_framework_activities', this.formResource.selectedActivity);
        }
      }
      // if it's a "training" select field sequences and activities related
      if (this.typeoftraining === 'training') {
        if (this.selectedAttachedType === '1') {
          formData.append('attached_sequence_type', this.formResource.selectedType);
        }
        if (!this.selectedAttachedType) { formData.append('attached_trainings', this.idTraining); }
        if (this.selectedAttachedType === '2') {
          formData.append('attached_training_sequences', this.formResource.selectedSequence);
        }
        if (this.selectedAttachedType === '3') {
          formData.append('attached_training_activities', this.formResource.selectedActivity);
        }
      }
      // if it's a "studenttraining" select field sequences and activities related
      if (this.typeoftraining === 'studenttraining') {
        if (!this.selectedAttachedType) { formData.append('attached_student_trainings', this.idTraining); }
        if (this.selectedAttachedType === '1' && !this.formResource.selectedType) {
          this.formResource.selectedType = this.sequence.legacy_sequence.sequence_type;
        }
        if (this.selectedAttachedType === '1') {
          formData.append('attached_sequence_type', this.formResource.selectedType);
        }
        if (this.selectedAttachedType === '2') {
          formData.append('attached_student_training_sequences', this.formResource.selectedSequence);
        }
        if (this.selectedAttachedType === '3') {
          formData.append('attached_student_training_activities', this.formResource.selectedActivity);
        }
      }

      if (this.typeoftraining === 'practicalWork') {
        if (!this.selectedAttachedType) { formData.append('attached_practical_works', this.idTraining); }
      }
      if (this.typeoftraining === 'custom_experience') {
        formData.append('related_experience_id', this.idTraining);
        formData.append('related_experience_type', 'custom_experience');
      }
      // window.console.log(...formData.entries());
      this.$http
        .post('/resources/', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            // "Access-Control-Allow-Origin": "*",
            // Accept: "*",
          },
        })
        .then(() => {
          this.$buefy.toast.open({ message: 'Ressource ajoutée', type: 'is-success' });
          this.$emit('update:isOpen', false);
          this.$emit('messageFromChild');
          this.$emit('messageFromResource');
        })
        .catch((err) => {
          window.console.log(('error', err));
          this.errored = true;
          this.$buefy.dialog.alert({
            title: 'Mince...',
            message: 'La ressource n\'a pas pu être ajoutée. ',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
          });
        });
    },
    patchResource() {
      this.verifFields();
      // function to patch resource
      this.$http
        .patch(`/resources/${this.resourceItem.id}/`, {
          name: this.formResource.resourceName,
          short_description: this.formResource.resourceShort,
          long_description: this.formResource.resourceLong,
          active: 1,
          importance: this.formResource.importance,
          attached_roles: this.formResource.selectedRoles,
          // attached_sequence_type
          // attached_framework_sequences: this.formResource.selectedSequence;
          // attached_training_sequences
          // attached_student_training_sequences
        })
        .then(() => {
          this.$buefy.toast.open({ message: 'Ressource modifiée', type: 'is-success' });
          this.initResource();
          this.$emit('update:isOpen', false);
          this.$emit('message');
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
          this.$buefy.dialog.alert({
            title: 'Mince...',
            message: 'La ressource n\'a pas pu être modifiée. ',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
          });
        });
    },
    checkFile() {
      const extension = this.file.name.split('.').pop();
      // this.resources_type = this.file.substr((this.file.lastIndexOf('.') + 1));
      if (!/(pdf|doc|docx|odt|ods|page|ppt|pptx|key|xls|xlsx|csv|xml|png|jpg)$/ig.test(extension)) {
        this.$buefy.dialog.alert({
          title: 'Ce type de fichier n\'est pas accepté',
          message: 'Vous pouvez utiliser les formats suivants :  pdf, doc, docx, odt, ods, page, ppt, pptx, key, xls, xlsx,csv, xml, jpg, png',
          type: 'is-info',
          hasIcon: true,
          icon: 'times-circle',
          ariaRole: 'alertdialog',
          ariaModal: true,
          confirmText: 'Ok',
        });
      } else if (this.file.size > 1000000) {
        this.$buefy.dialog.alert({
          title: 'Ce fichier est trop volumineux',
          message: 'Vous pouvez télécharger un fichier dont la taille ne dépasse pas 1 Mo.',
          type: 'is-info',
          hasIcon: true,
          icon: 'times-circle',
          ariaRole: 'alertdialog',
          ariaModal: true,
          confirmText: 'Ok',
        });
      } else { this.nameFile = this.file.name; this.fileValidity = true; }
      // file.files[0].size
    },
    changeType() {
      // window.console.log('change');
    },
    selectRole() {
      // window.console.log(`role ${this.selectedRoles}`);
    },
    initResource() {
      this.$http.get(`/resources/${this.idResource}/?format=json`).then((response) => {
        this.resourceItem = response.data;
        this.setData();
      });
    },
    setData() {
      this.formResource.resourceName = this.resourceItem.name;
      this.formResource.resourceShort = this.resourceItem.short_description;
      this.formResource.resourceLong = this.resourceItem.long_description;
      this.formResource.selectedRoles = this.resourceItem.attached_roles;
      this.formResource.importance = this.resourceItem.importance;
    },
    /* -----------------------Ressources functions------------------------- */
  },
  created() {
    if (this.idResource) { this.initResource(); }
    if (this.sequence) {
      this.selectedAttachedType = '2';
    }
  },
};
</script>
