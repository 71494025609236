<template>
  <div>
    <b-loading :is-full-page="isFullPage" v-model="isLoading"> </b-loading>
    <b-modal
      v-model="displayDetail"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-label="Example Modal"
      close-button-aria-label="Fermer"
      aria-modal
      scroll="keep"
      class="modal-portfolio-experience"
    >
      <ExperienceDetail :chosenExperience.sync="experienceDetailed" />
    </b-modal>
    <b-navbar style="background-color: unset" id="menu-mobile-portfolio">
      <template #brand>
        <div class="logo">
          <img
            src="@/assets/img/logo/insitu_logo.png"
            alt="inSitu, application de gestion de fromation en situation de travail"
            width="90"
            height="auto"
          />
        </div>
      </template>
      <template #start>
        <div class="tabs-nav">
          <ul>
            <li v-for="(tab, index) in tabs" :key="index">
              <b-navbar-item
                :href="'#' + tab.slug"
                class="p-0"
                :class="currentTab === tab.slug ? 'is-active' : ''"
                style="display: inline-block"
              >
                {{ tab.title }}
              </b-navbar-item>
            </li>
          </ul>
        </div>
      </template>
    </b-navbar>

    <div>
      <section id="student-name">
        <div class="container title-portfolio">
          <div class="columns is-centered">
            <div class="column is-6 has-text-centered">
              <div class="title has-text-primary mb-5">
                {{ currentPortfolio.student_first_name }}
                {{ currentPortfolio.student_last_name }}
              </div>
              <!-- <b-title type="is-1 is-bold" class="name has-text-weight-bold"
                >{{ currentPortfolio.student_first_name }}
                {{ currentPortfolio.student_last_name }}</b-title
              > -->
            </div>
          </div>
        </div>
      </section>
      <nav class="tabs-nav" id="menu-portfolio">
        <ul>
          <li v-for="(tab, index) in tabs" :key="index">
            <a
              :href="'#' + tab.slug"
              :class="currentTab === tab.slug ? 'is-active' : ''"
              @click="currentTab = tab.slug"
            >
              {{ tab.title }}
            </a>
          </li>
        </ul>
      </nav>

      <!-- Add the corresponding content for each tab -->
      <section class="column" id="presentation">
        <!-- Presentation section -->
        <section class="section">
          <div class="container pl-5 pr-5">
            <div class="columns is-vcentered">
              <div class="column is-4" style="max-width: 240px">
                <b-image
                  :src="currentPortfolio.student_avatar"
                  rounded
                  ratio="1by1"
                ></b-image>
              </div>
              <div class="column is-8">
                <div>
                  <!-- <h1 class="title">My Name</h1>
    <h2 class="subtitle">My Job Title</h2> -->
                  <div v-html="currentPortfolio.portfolio_description"></div>
                  <div class="columns is-vcentered mt-5">
                    <div
                      class="column is-narrow"
                      v-if="currentPortfolio.portfolio_telephone"
                    >
                      <i
                        class="fas fa-phone has-text-primary"
                        style="font-size: 24px"
                      ></i>
                      <span class="ml-2" style="font-size: 24px">{{
                        currentPortfolio.portfolio_telephone
                      }}</span>
                    </div>
                    <div class="column" v-if="currentPortfolio.portfolio_email">
                      <i
                        class="fas fa-envelope has-text-primary"
                        style="font-size: 24px"
                      ></i>
                      <a
                        :href="'mailto:' + currentPortfolio.portfolio_email"
                        class="has-text-primary ml-2"
                        style="font-size: 24px"
                      >
                        {{ currentPortfolio.portfolio_email }}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>

      <section id="formation" class="section">
        <div class="container">
          <div class="content">
            <h2 class="title mb-5 has-text-primary">Formation</h2>
            <div class="columns is-vcentered mt-5 container-section">
              <div class="column is-narrow has-text-centered">
                <div class="circle">
                  <div>
                    <div class="year">
                      {{ currentPortfolio.training_start_date | year }}
                    </div>
                    <div class="year">
                      {{ currentPortfolio.training_end_date | year }}
                    </div>
                  </div>
                  <div class="spacer"></div>
                </div>
              </div>
              <div class="column">
                <div class="content">
                  <p class="title is-4 mb-5 pb-2">
                    {{ currentPortfolio.training }}
                  </p>
                  <p class="subtitle is-6 has-text-primary">
                    {{ currentPortfolio.training_start_date | day }} -{{
                      currentPortfolio.training_end_date | day
                    }}
                  </p>
                  <!-- <p class="subtitle is-6">
                    <span class="icon">
                      <i class="fas fa-map-marker-alt"></i>
                    </span>
                    <span class="ml-2">Location</span>
                  </p> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="competences" class="section">
        <div class="container">
          <h2
            class="title is-3 mb-5 has-text-primary pb-5"
            style="display: inline-block"
          >
            Compétences
          </h2>
          <div class="container-section">
            <div
              v-for="competence in currentPortfolio.skills"
              :key="competence.id"
            >
              <div class="mb-5">
                <div class="content">
                  <p class="title is-5 mb-0 mt-5 pt-5 has-text-primary">
                    {{ competence.name }}
                  </p>
                  <!-- <p class="subtitle is-6">{{ competence.reference }}</p> -->
                </div>
                <div class="">
                  <div class="content">
                    <div
                      class="columns"
                      v-for="skill in competence.skills"
                      :key="skill.id"
                    >
                      <div class="column is-narrow">
                        <b-rate
                          class="rate-portfolio"
                          v-model="skill.evaluation"
                          icon-pack="fas"
                          :max="skill.evaluation_max"
                          :spaced="true"
                          :disabled="true"
                        ></b-rate>
                      </div>
                      <div class="column">{{ skill.name }}</div>
                    </div>

                    <!-- <b-progress
                        :value="skill.evaluation"
                        max="100"
                        size="is-small"
                      ></b-progress> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="experiences" class="section pb-5 mb-5">
        <div class="container">
          <h2 class="title is-3 has-text-primary">Expériences</h2>
          <div class="container-section mt-5 pt-5">
            <div
              v-for="experience in experiences"
              :key="experience.id"
              class="experience"
            >
              <a @click.prevent="displayExperienceDetail(experience)">
                <div class="columns is-vcentered">
                  <div class="column is-narrow">
                    <div class="circle has-background-primary has-text-white">
                      <p class="month" style="line-height: 1">
                        {{ experience.experience_start_date | month }}
                      </p>
                      <p class="year" style="line-height: 1">
                        {{ experience.experience_start_date | year }}
                      </p>
                    </div>
                  </div>

                  <div class="column">
                    <div class="content">
                      <h4 class="subtitle is-6 has-text-primary mb-2">
                        {{ experience.experience_type }}
                      </h4>
                      <h3 class="title is-4 mt-0">
                        {{ experience.experience_title }}
                      </h3>
                      <div class="mt-1">
                        <b>{{ experience.experience_subtitle }}</b>
                      </div>
                      <br />

                      <!-- <div
                        v-if="experience.experience_type === 'Situation libre'"
                      >
                        <p
                          class="is-size-6"
                          v-html="experience.experience_content"
                        ></p>
                      </div>

                      <div v-else>
                        <p class="is-size-6">
                          {{ experience.experience_content.title }}
                        </p>
                        <ul>
                          <li
                            v-for="activity in experience.experience_content
                              .activities"
                            :key="activity.index"
                          >
                            {{ activity.title }}
                            <ul>
                              <li
                                v-for="step in activity.steps"
                                :key="step.index"
                              >
                                {{ step.title }}
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div> -->
                      <p class="is-size-6">
                        <span class="icon"
                          ><i class="fas fa-calendar-alt"></i
                        ></span>
                        <span class="ml-2">
                          {{ experience.experience_start_date | day }}
                          -
                          {{ experience.experience_end_date | day }}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </section>
      <div class="section mt-5 mb-5"></div>
      <!-- Footer -->
      <footer class="footer">
        <div class="content">
          <div class="columns is-vcentered">
            <div class="column is-narrow">
              <img
                :src="currentPortfolio.cfa_logo"
                alt="School Logo"
                width="80px"
                height="auto"
              />
            </div>
            <div class="column">
              <p><a href="#">CGU</a> | <a href="#">Mentions légales</a></p>
            </div>
          </div>
        </div>
      </footer>

      <!-- Floating buttons -->
      <div class="buttons-container">
        <b-button
          class="fab btn-copy"
          @click="copyPortfolioUrl"
          title="Copy URL"
        >
          <i class="fas fa-copy"></i>
        </b-button>
        <b-button
          class="fab btn-email"
          @click="sendPortfolioByEmail"
          title="Send by email"
        >
          <i class="fas fa-envelope"></i>
        </b-button>
        <b-button class="fab btn-text" title="Export Text" @click="exportText">
          <i class="fas fa-file-alt"></i>
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import ExperienceDetail from '@/components/Portfolios/ExperienceDetail.vue';
import moment from 'moment';
import localization from 'moment/locale/fr';

moment.updateLocale('fr', localization);

export default ({
  components: {
    ExperienceDetail,
  },
  filters: {
    day(date) {
      return moment(date).format('DD/MM/YY');
    },
    month(date) {
      return moment(date).format('MMMM');
    },
    year(date) {
      return moment(date).format('YYYY');
    },
  },
  data() {
    return {
      isLoading: false,
      isFullPage: false,
      currentTab: 'presentation',
      currentPortfolio: [],
      experiences: [],
      displayDetail: false,
      experienceDetailed: '',
      showToast: false,
      tabs: [
        {
          title: 'Présentation',
          slug: 'presentation',
        },
        {
          title: 'Formation',
          slug: 'formation',
        },
        {
          title: 'Compétences',
          slug: 'competences',
        },
        {
          title: 'Expériences',
          slug: 'experiences',
        },
      ],
    };
  },
  created() {
    this.checkPortfolioExistence();
  },
  methods: {
    startLoading() {
      this.isLoading = true;
    },
    endLoading() {
      this.isLoading = false;
    },
    checkPortfolioExistence() {
      this.$http
        .get(`/portfolio/get_portfolio/${this.$route.params.slug}/`)
        .then((response) => {
          this.currentPortfolio = response.data;
          this.experiences = response.data.parts;
        }).catch((error) => {
          window.console.log(error.response);
          if (error.response.status === 403) {
            this.unauthorized = true;
          } else {
            this.errored = true;
          }
        });
    },
    displayExperienceDetail(experience) {
      this.displayDetail = true;
      this.experienceDetailed = experience;
    },
    copyPortfolioUrl() {
      const url = window.location.href;
      navigator.clipboard.writeText(url).then(() => {
        this.$buefy.toast.open({
          message: "L'adresse de ce portfolio est dans votre presse-papier",
          type: 'is-success',
          duration: 5000,
          position: 'is-bottom-right',
        });
      }, () => {
        // Optional: show an error message to the user
        window.console.error('Failed to copy portfolio URL to clipboard!');
      });
    },
    sendPortfolioByEmail() {
      const subject = 'Je viens de parcourir ce portfolio';
      const body = `Bonjour,\n\nJe viens de parcourir ce portfolio, qui me semble intéressant :\n\n${window.location.href}\n\nBonne journée`;

      const mailtoUrl = `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
      window.open(mailtoUrl);
    },
    round(number) {
      const neweval = (number * 4) / 100;
      return neweval;
    },
    exportText() {
      this.startLoading();
      this.$http
        .get(`/portfolio/export_experiences/${this.currentPortfolio.id}-${this.currentPortfolio.student_training_id}/`, {
          responseType: 'blob',
        }).then((response) => {
          const portfolioname = (this.currentPortfolio.portfolio_name).toLowerCase();
          const portfolionameReplaced = portfolioname.replace(' ', '_');
          const fileURL = window.URL.createObjectURL(new Blob([response.data]));
          const fileLink = document.createElement('a');
          fileLink.href = fileURL;
          fileLink.setAttribute('download', `insitu_portfolio_${portfolionameReplaced}.docx`);
          document.body.appendChild(fileLink);
          fileLink.click();
          this.endLoading();
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
          this.$buefy.dialog.confirm({
            title: 'Mince...',
            message: 'Le portfolio n\'a pu être téléchargé',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
            onConfirm: () => document.location.reload(),
          });
        });
    },
  },
});
</script>

<style scoped lang="scss">
// #student-name {
//   order: 1;
// }
// #menu-portfolio {
//   width: 100%;
//   order: 2;
// }
.title-portfolio {
  margin-top: -2rem;
}
.logo {
  margin-bottom: 50px;
}
.name {
  font-size: 4rem;
}

.tabs-nav {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  margin-bottom: 20px;
}

.tabs-nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.tabs-nav li {
  margin: 0 10px;
}

.tabs-nav a {
  color: #333;
  transition: color 0.3s;
  font-size: 1.3rem;
  text-decoration: none;
}

.tabs-nav a:hover {
  color: var(--primary);
}

.tabs-nav a.is-active {
  color: var(--button-primary);
  border-bottom: 0.2rem solid var(--primary) !important;
}

/* Add hover effect */
.tabs-nav a:hover {
  transition: background-color 0.3s;
  padding-bottom: 1rem;
  border-bottom: 0.2rem solid #5e72e4;
}

.tabs-nav ul li a.is-active {
  padding-bottom: 1rem;
  border-bottom: 0.2rem solid #3273dc;
}

.circle {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
  background-color: var(--primary);
  color: #fff;
  padding: 0.5rem;
}

.circle .month {
  font-size: 2.5rem;
  line-height: 1.1;
  margin-bottom: 0.3rem;
  width: 84px;
  overflow: hidden;
}

.circle .year {
  font-size: 1.9rem;
}

.fab {
  position: fixed;
  bottom: 4rem;
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  background-color: var(--primary);
  color: white;
  font-size: 2.4rem;
  line-height: 6rem;
  text-align: center;
  transition: all 0.2s ease-in-out;
  box-shadow: 0px 0.4rem 0.8rem rgba(0, 0, 0, 0.3);
}

.fab:hover {
  transform: scale(1.1);
  background-color: #5e72e4;
  cursor: pointer;
}

.fab i {
  font-size: 2.3rem;
}
.btn-email {
  right: 4rem;
}
.btn-copy {
  right: 10rem;
}
.btn-text {
  right: 16rem;
}
.container-section {
  margin-left: 5rem;
}
.experience {
  margin-bottom: 3rem;
}
#competences .container-section {
  margin-left: 7rem;
}
.logo {
  margin: 2rem 0 2rem 2rem;
}
.section {
  padding: 3rem 3rem;
}
.footer {
  padding: 2rem 1.5rem;
  position: fixed;
  bottom: 0;
  width: 100%;
}

.footer p {
  font-size: 1.2rem;
  margin: 0;
}

.footer a {
  color: #5e72e4;
  text-decoration: none;
}

.footer a:hover {
  text-decoration: underline;
}

.footer img {
  display: block;
  margin: 0 auto;
}
.rate-portfolio .icon {
  color: var(--primary) !important;
  color: #000 !important;
}
@media screen and (max-width: 1400px) {
  #competences {
    margin-left: unset;
  }
  .container-section {
    margin-left: 2rem;
  }
}
@media screen and (max-width: 1200px) {
  .container-section,
  #competences .container-section {
    margin-left: 0rem;
  }
}
@media screen and (max-width: 990px) {
  .logo .container-section,
  #competences {
    margin-left: 0px;
  }
  img {
    max-height: 72px;
  }
  .logo {
    margin: 1rem 0 1rem 1rem;
  }
  #competences {
    .column {
      padding: 0.1rem 0.75rem;
    }
    .columns {
      margin-bottom: 1.5rem;
    }
  }
}
@media screen and (max-width: 768px) {
  .container-section {
    margin-left: unset;
  }
  .fab {
    bottom: 2rem;
    width: 4rem;
    height: 4rem;
    line-height: 1;
    font-size: 2rem;
  }

  .fab i {
    font-size: 2rem;
    margin-top: -3px;
  }

  .btn-email {
    right: 1rem;
  }
  .btn-copy {
    right: 6rem;
  }
  #competences {
    margin-left: unset;
  }
  .circle {
    width: 6rem;
    height: 6rem;
    .month {
      font-size: 1.5rem;
      margin-bottom: 0.2rem;
      width: 51px;
    }

    .year {
      font-size: 1.5rem;
    }
  }
  .section {
    padding: 3rem 2rem;
  }
}
@media screen and (min-width: 470px) {
  .experience .columns,
  #formation .columns {
    display: flex;
  }
  .experience .column.is-narrow,
  .experience .column.is-narrow-tablet,
  #formation .column.is-narrow,
  #formation .column.is-narrow-tablet {
    -webkit-box-flex: 0;
    -ms-flex: none;
    flex: none;
    width: unset;
  }
}
@media screen and (max-width: 570px) {
  .title-portfolio {
    margin-top: 0px;
  }
  .tabs-nav {
    display: block;
    li {
      margin: 0 8px 0.4rem;
    }
    // a {
    //   font-size: 1.1rem;
    // }
    ul {
      flex-direction: column;
      flex-wrap: wrap;
      padding-left: 0.8rem;
      li a.is-active {
        padding-bottom: 0.1rem;
      }
    }
  }
  #presentation {
    margin-top: 3rem;
  }
  #competences .title {
    margin-bottom: 0px !important;
    padding-bottom: 0px !important;
  }
  #menu-portfolio {
    display: none;
  }
  #presentation {
    margin-top: 0px;
    padding-top: 0px;
    .section {
      padding-top: 1rem;
    }
  }
  // #student-name {
  //   order: 2;
  // }
  // #menu-portfolio {
  //   order: 1;
  // }
}
@media screen and (max-width: 500px) {
  .section {
    padding: 3rem 1.5rem;
  }
}
</style>
