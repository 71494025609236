<template>
  <div class="bloc-notifications">
    <div class="" @click="isOpen = !isOpen">
      <div class="icon-number">
        <b-icon icon="bell" size="is-medium" type="is-primary" class="icon-big">
        </b-icon>
        <b-tag rounded> {{ notifications.length }}</b-tag>
      </div>
    </div>
    <b-collapse
      aria-id="contentIdForA11y2"
      class="panel"
      animation="slide"
      v-model="isOpen"
    >
      <ul class="card_item notification_panel" v-if="notifications.length">
        <li class="has-text-centered mb-3">
          <b>Vos dernières notifications</b>
        </li>
        <li
          v-for="(notification, index) in notifications"
          :key="notification.id"
          class="card_notification"
        >
          <article class="notification blockOpen" :id="'notification' + index">
            <!-- <button
              type="button"
              aria-label="Close notification"
              class="delete"
              @click="markasRead(notification.id, 'notification' + index)"
            ></button> -->
            <div class="media">
              <div class="media-content">
                {{ notification.title }}
                <div class="subtitle-paragraph mb-0">
                  de {{ notification.sender_name }}
                </div>
                <div>
                  <a
                    v-if="notification.file"
                    :href="notification.file"
                    target="_blank"
                    class="is-small"
                    >Voir le fichier ici</a
                  >
                </div>
              </div>
            </div>
          </article>
        </li>
        <li class="message is-info" v-if="!notifications.length">
          <div class="message-body">Vous avez lu toutes vos notifications</div>
        </li>
        <li class="has-text-centered">
          <b-button
            @click="isOpen = !isOpen"
            type="is-default is-rounded is-small outlined"
            class="mr-2"
            >Fermer</b-button
          >
          <router-link to="/mes-notifications">
            <b-button
              type="is-primary is-rounded is-small outlined"
              class="ml-auto mr-auto"
              >Voir tout</b-button
            ></router-link
          >
        </li>
      </ul>
    </b-collapse>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    notifications() {
      return this.$store.state.notifications;
    },
    ...mapState('auth', ['currentUser']),
  },
  data() {
    return {
      // notifications: [],
      markasReadswitch: { 1: 'lue', 0: 'non lue' },
      isOpen: false,
    };
  },
  methods: {
    getNotifications() {
      if (!this.notifications.length) {
        this.$http
          .get(`/notifications/notifications/?status=0&recipient=${this.currentUser.id}&format=json`)
          .then((response) => {
            this.$store.commit('GET_NOTIFICATIONS', response.data);
          })
          .catch((error) => {
            window.console.log(error);
            this.errored = true;
          });
      }
    },
    markasRead(id, div) {
      this.collapseBlock(div);
      this.$http
        .patch(`/notifications/notifications/${id}/`, {
          status: 1,
        }).then(() => {
          this.getNotifications();
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
          this.$buefy.dialog.alert({
            title: 'Mince...',
            message: 'Le statut "non lue" de la notification n\'a pas pu être modiifé',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
          });
        });
    },
    collapseBlock(id) {
      const element = document.getElementById(id);
      element.classList.toggle('blockOpen');
      element.classList.toggle('cardClosed');
    },
  },
  created() {
    this.getNotifications();
  },
};
</script>

<style scoped>
.icon-number {
  cursor: pointer;
}
</style>
