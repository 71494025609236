import Vue from 'vue';
import Buefy from 'buefy';
import client from './api/client';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import 'buefy/dist/buefy.css';
import '@/assets/sass/app.scss';
import '@/assets/css/icons.css';
import './vee-validate';

Vue.prototype.$http = client;

Vue.use(Buefy);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
