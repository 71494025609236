<template>
  <section class="hero is-fullheight has-background">
    <img
      alt="Fill Murray"
      class="hero-background is-transparent"
      src="@/assets/img/error/404.jpg"
    />
    <div class="hero-body">
      <div class="container has-text-centered has-background">
        <div class="column is-6 is-offset-3">
          <h1 class="title mb-6">Oups...</h1>
          <h2 class="subtitle">
            Cette page n'existe pas dans inSitu. Nous vous recommandons de
            revenir à la page d'accueil.
          </h2>
          <b-button @click="goHome" type="is-primary" rounded
            >Revenir à la page d'accueil</b-button
          >
        </div>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  components: {
  },
  methods: {
    goHome() {
      this.$router.push({
        path: '/',
      });
    },
  },
};
</script>

<style scoped>
.button {
  margin-top: 2rem;
}
.hero.has-background {
  position: relative;
  overflow: hidden;
}
.hero-background {
  position: absolute;
  object-fit: cover;
  object-position: center center;
  width: 100%;
  height: 100%;
}
.hero-background.is-transparent {
  opacity: 0.3;
}
</style>
