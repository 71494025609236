<template>
  <div class="modal-card modal-card-steps" style="width: auto">
    <header class="modal-card-head">
      <p class="modal-card-title is-vcentered">
        Évaluer la session
        <span class="has-text-weight-bold">{{ sessionDisplayed.title }}</span>
        <span class="ml-1 mr-2">du {{ sessionDisplayed.date | day }}</span>
        <span class="mr-2">
          <b-tag type="is-primary is-light" v-if="!sessionDisplayed.is_control"
            >Entraînement</b-tag
          ><b-tag type="is-success is-light" v-else>Évaluation</b-tag></span
        >
        <span class="mr-2"
          ><b-tag type="is-info is-light" v-if="sessionDisplayed.status === 0"
            >Brouillon</b-tag
          >
          <b-tag type="is-primary" v-if="sessionDisplayed.status === 1"
            >En cours</b-tag
          ><b-tag type="is-success" v-if="sessionDisplayed.status === 2"
            >Terminé</b-tag
          ></span
        >
      </p>
    </header>
    <section class="modal-card-body">
      <div v-if="!checkActive">
        <b-message
          type="is-info"
          size="is-small"
          v-if="Object.keys(studentSkills).length"
        >
          Remplissez les évaluations pour chaque apprenant.e. Une fois
          complétées, enregistrez les en cliquant sur le bouton "Enregistrer les
          évaluations". Vous n'êtes pas obligé d'évaluer tout le monde pour
          enregistrer.
        </b-message>
        <b-message type="is-danger" v-else>
          Vous devez d'abord associer des compétences à ce TP pour pouvoir
          évaluer les apprenant•e•s.
        </b-message>
      </div>
      <div v-else>
        <b-message v-if="checkActive" type="is-warning"
          >Sélectionnez les apprenant•e•s dont l'évaluation sera
          identique</b-message
        >
      </div>
      <div class="is-flex align-items-center mb-5">
        <b-button
          rounded
          @click="isOpenPeriod = true"
          v-if="isOpenPeriod === false"
          size="is-small"
          >ouvrir tout</b-button
        >
        <b-button
          rounded
          @click="isOpenPeriod = false"
          v-if="isOpenPeriod === true"
          size="is-small"
          >fermer tout</b-button
        >
        <b-button
          rounded
          @click="isCommentOpen = false"
          v-if="isCommentOpen === true"
          size="is-small"
          >masquer les commentaires</b-button
        >
        <b-button
          rounded
          @click="isCommentOpen = true"
          v-if="isCommentOpen === false"
          size="is-small"
          >afficher les commentaires</b-button
        >
      </div>
      <div
        v-for="(student, index) of sessionDisplayed.students"
        :key="index"
        class="is-flex"
      >
        <div class="pt-5" v-if="checkActive === true">
          <b-checkbox
            v-if="student.id !== studentReferent"
            v-model="selectedStudents"
            :native-value="student.id"
            :name="student.last_name"
          >
          </b-checkbox>
        </div>
        <b-collapse
          class="card w-100"
          animation="slide"
          :open="isOpenPeriod"
          :aria-id="'contentIdForA11y5-' + index"
        >
          <template #trigger="props">
            <div
              class="card-header"
              role="button"
              :aria-controls="'contentIdForA11y5-' + index"
              :aria-expanded="props.open"
              :class="studentReferent === student.id ? 'selected' : ''"
            >
              <p
                class="card-header-title p-4 has-text-primary"
                style="font-size: 1.1rem"
              >
                {{ student.first_name }} {{ student.last_name }}
              </p>
              <b-button
                class="mr-2"
                v-if="studentReferent !== student.id"
                @click="(studentReferent = student.id) && (checkActive = true)"
                size="is-small"
                :class="studentReferent === student.id ? 'is-success' : ''"
                rounded
                >Utiliser comme modèle</b-button
              >
              <b-button
                rounded
                v-else
                class="mr-2"
                @click="deselectStudents()"
                size="is-small"
                :class="studentReferent === student.id ? 'is-primary' : ''"
                >déselectionner</b-button
              >
              <b-tag
                v-if="isAllEvaluated[student.id]"
                rounded
                type="is-success is-light"
                >Toutes les compétences sont évaluées</b-tag
              >
              <b-tag v-else rounded type="is-warning is-light"
                >Évaluation incomplète</b-tag
              >
              <a class="card-header-icon">
                <b-icon :icon="props.open ? 'menu-down' : 'menu-up'"> </b-icon>
              </a>
            </div>
          </template>
          <div class="card-content">
            <div class="content">
              <div
                v-for="skill in practicalWork.skills"
                :key="skill.id"
                class="skill"
              >
                <div class="columns is-vcentered">
                  <div class="column name">
                    <b>{{ skill.name }}</b>

                    <div style="font-size: 0.8rem">
                      {{ skill.block_name }}
                      <!-- <b-tag type="is-primary is-light">{{
                        skill.block_name
                      }}</b-tag> -->
                    </div>
                  </div>
                  <div class="column is-narrow">
                    <div class="columns is-tablet column-levels">
                      <div
                        v-for="level in order(practicalWork.levels)"
                        :key="level.id"
                        class="column is-narrow"
                      >
                        <b-radio
                          v-if="isEditMode === true"
                          size="is-small"
                          :native-value="level.id"
                          :name="'skill-' + skill.id + student.id"
                          v-model="studentSkills[student.id][skill.id]"
                          @input="
                            (value) =>
                              setSkillValue(student.id, skill.id, value)
                          "
                        >
                          {{ level.name }}
                        </b-radio>
                        <div v-else class="level">
                          <b-icon
                            size="is-small"
                            icon="check"
                            type="is-success"
                            v-if="
                              level.id === studentSkills[student.id][skill.id]
                            "
                          ></b-icon>
                          <b-icon icon="close" class="disabled" v-else></b-icon>
                          <div class="level-label">{{ level.name }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-5 mb-5" v-if="isCommentOpen">
                <div class="column">
                  <p class="mb-2 has-text-primary">Commentaire du formateur</p>
                  <ckeditor
                    v-if="isEditMode === true"
                    :editor="editor"
                    v-model="student.teacher_comment"
                    :config="editorConfig"
                    class="mb-5"
                  ></ckeditor>
                  <div v-else v-html="student.teacher_comment"></div>
                </div>
                <!-- <div class="column">
                <p class="mb-2 subtitle-paragraph">
                  Commentaire de l'apprenant.e
                </p>
                <ckeditor
                  :editor="editor"
                  v-model="student.student_comment"
                  :config="editorConfig"
                  class="mb-5"
                ></ckeditor>
              </div> -->
              </div>
            </div>
          </div>
        </b-collapse>
      </div>
    </section>
    <footer class="modal-card-foot">
      <b-button label="Fermer" @click="$emit('close')" />
      <span v-if="!checkActive && isEditMode === true">
        <b-button
          class="button is-primary mr-1"
          label="Enregistrer les évaluations"
          @click="submitEvaluations('save')"
          :disabled="!Object.keys(studentSkills).length"
        />
      </span>
      <span v-if="!checkActive && isEditMode === true">
        <b-button
          class="button is-success"
          label="Valider"
          @click="submitEvaluations('validate')"
          :disabled="!Object.keys(studentSkills).length"
        />
      </span>
      <b-button
        v-else-if="checkActive && isEditMode === true"
        class="button is-primary"
        @click="duplicateEval(selectedStudents, studentReferent)"
        :disabled="!selectedStudents.length"
        >Appliquer aux apprenant•e•s sélectionné•e•s</b-button
      >
    </footer>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import CKEditor from '@ckeditor/ckeditor5-vue2';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import '@ckeditor/ckeditor5-build-classic/build/translations/fr';
import moment from 'moment';
import 'moment/locale/fr';
import _ from 'lodash';

export default {
  props: {
    practicalWork: Object,
    sessionDisplayed: Object,
    isEditMode: Boolean,
  },
  components: {
    ckeditor: CKEditor.component,
  },
  filters: {
    day(date) {
      return moment(date).format('D MMMM YYYY');
    },
  },
  computed: {
    ...mapState('auth', ['currentUser']),

    isEvaluated() {
      const result = {};
      this.sessionDisplayed.students.forEach((student) => {
        /* eslint-disable max-len */
        result[student.id] = this.practicalWork.skills.every((skill) => student.skills && student.skills[skill.id] && student.skills[skill.id].level != null);
      });
      return result;
    },
    isAllEvaluated() {
      const result = {};
      if (this.practicalWork.skills.length > 0) {
        this.sessionDisplayed.students.forEach((student) => {
          /* eslint-disable max-len */
          const value = Object.values(this.studentSkills[student.id]);
          const found = value.find((element) => element === null);
          if (found === null) {
            result[student.id] = false;
          } else {
            result[student.id] = true;
          }
        });
      }
      return result;
    },
  },
  data() {
    return {
      isOpen: 0,
      studentSkills: {},
      // CKEditor
      editor: ClassicEditor,
      editorDisabled: true,
      editorConfig: {
        language: 'fr',
        toolbar: ['heading', '|', 'bold', 'italic', 'bulletedList', 'numberedList', '|', 'outdent', 'indent', '|', 'link', '|', 'undo',
          'redo'],
        alignment: {
          options: ['left', 'right'],
        },
        heading: {
          options: [
            { model: 'paragraph', title: 'Styles de texte', class: 'ck-heading_paragraph' },
            {
              model: 'heading1',
              view: 'h1',
              title: 'Titre 1',
              class: 'ck-heading_heading1',
            },
            {
              model: 'heading2',
              view: 'h2',
              title: 'Titre 2',
              class: 'ck-heading_heading2',
            },
            {
              model: 'mark',
              view: 'mark',
              title: 'surligné',
              class: 'ck-mark',
            },
          ],
        },
        table: {
          contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells'],
        },

      },
      selectedStudents: [],
      checkActive: false,
      studentReferent: null,
      isOpenPeriod: false,
      isCommentOpen: true,
    };
  },
  created() {
    if (this.practicalWork.skills.length > 0) {
      this.studentSkills = this.initializeStudentSkills();
    }
  },
  methods: {
    getSkillValue(studentId, skillId) {
      return this.studentSkills[studentId] ? this.studentSkills[studentId][skillId] : null;
    },
    setSkillValue(studentId, skillId, value) {
      if (!this.studentSkills[studentId]) {
        this.$set(this.studentSkills, studentId, {});
      }
      this.$set(this.studentSkills[studentId], skillId, value);
    },
    initializeStudentSkills() {
      const studentSkills = {};
      this.sessionDisplayed.students.forEach((student) => {
        studentSkills[student.id] = {};
        this.practicalWork.skills.forEach((skill) => {
          // If the student has evaluations, initialize with the existing level, otherwise null
          const existingSkill = student.skills.find((s) => s.id === skill.id);
          studentSkills[student.id][skill.id] = existingSkill && existingSkill.student_evals && existingSkill.student_evals[0]
            ? existingSkill.student_evals[0].level_id
            : null;
        });
      });
      return studentSkills;
    },
    async submitEvaluations(type) {
      // Create the array of evaluations
      /* eslint-disable */
      const evaluations = this.sessionDisplayed.students.map((student) => ({
        student_training: student.student_training,
        skills_levels: Object.entries(this.studentSkills[student.id] || {})
          .filter(([skill, level]) => level !== null)
          .map(([skill, level]) => ({
            skill,
            level,
          })),
        // student_comment: student.student_comment,
        teacher_comment: student.teacher_comment,
      }));
      /* eslint-enable */
      // Send the POST request
      const response = await this.$http.post(
        `/practical_work/set_practical_work_session_evaluations/${this.sessionDisplayed.id}`,
        evaluations,
      );

      if (response.status === 200) {
        this.$buefy.toast.open({ message: 'Évaluations enregistrées', type: 'is-success' });
        if (type === 'validate') {
          let canValidate = null;
          for (let i = 0; i < this.sessionDisplayed.students.length; i += 1) {
            const value = Object.values(this.studentSkills[this.sessionDisplayed.students[i].id]);
            const found = value.find((element) => element === null);
            if (found === null) {
              canValidate = false;
              this.$buefy.dialog.confirm({
                title: 'Voulez-vous marquer cette séance comme terminé ?',
                message: '<div class="has-text-danger"><b>Attention, toutes les compétences n\'ont pas été évaluées. S\'agit-il bien de compétences qui n\'ont pas pu être évaluées lors de cette séance ?</b></div> Êtes-vous certain de vouloir valider ?',
                confirmText: 'Valider',
                cancelText: 'Annuler',
                type: 'is-primary',
                onConfirm: () => {
                  this.$http.patch(`/practical_work/training_practical_work_session/${this.sessionDisplayed.id}/`, {
                    status: 2,
                  }).catch((error) => {
                    window.console.log(error);
                  });
                  this.$emit('next');
                },
                // onCancel: () => {
                //   this.$emit('next');
                // },
              });
              break;
            }
            if (found !== null && i === (this.sessionDisplayed.students.length - 1)) {
              if (canValidate === null) {
                this.$buefy.dialog.confirm({
                  title: 'Voulez-vous marquer cette séance comme terminée ?',
                  message: 'Si vous avez fini les évaluations pour l\'ensemble du groupe, vous pouvez indiquer cette session comme terminée.',
                  confirmText: 'Valider',
                  cancelText: 'Annuler',
                  type: 'is-success',
                  hasIcon: true,
                  onConfirm: () => {
                    this.$http.patch(`/practical_work/training_practical_work_session/${this.sessionDisplayed.id}/`, {
                      status: 2,
                    }).catch((error) => {
                      window.console.log(error);
                    });
                    this.$emit('next');
                  },
                  // onCancel: () => {
                  //   this.$emit('next');
                  // },
                });
              }
            }
          }
        } else (this.$emit('next'));
      } else {
        // Handle error
        this.$buefy.notification.open({
          message: 'Une erreur est survenue lors de l\'envoi des évaluations.',
          type: 'is-danger',
        });
      }
    },
    duplicateEval(target, source) {
      target.forEach((student) => {
        Object.assign(this.studentSkills[student], this.studentSkills[source]);
      });
      this.deselectStudents();
    },
    deselectStudents() {
      this.checkActive = false;
      this.studentReferent = null;
      this.isOpen = null;
    },
    order(array) {
      return _.orderBy(array, 'position');
    },
  },
};
</script>
<style scoped lang="scss">
.skill {
  border-bottom: 1px solid #d1d1d180;
  padding-bottom: 0.5rem;
  margin: 0.5rem 1rem;
  .name {
    font-size: 0.9rem;
  }
}
.card-header {
  align-items: center;
}
.card-header-icon .icon {
  font-size: unset;
}
.b-radio {
  margin-right: unset !important;
}
.selected {
  background-color: var(--turquoise-light);
}
.level {
  font-size: 0.9rem;
}
.level .icon {
  font-size: unset !important;
  margin-right: 0.3rem;
  width: 1.5rem;
}
// .legend-levels {
//   .tag {
//     margin-right: 0.2rem;
//   }
// }
// .b-radio.radio.radio1 input[type="radio"] + .check:before {
//   background-color: #e41b5b !important;
// }
// .b-radio.radio.radio2 input[type="radio"] + .check:before {
//   background-color: #531be4;
// }
// .field-level {
//   padding-right: 0.99rem;
// }
@media (max-width: 768px) {
  .column-levels {
    padding: 0.1rem 0 1rem;
    margin-top: -1rem;
    .column {
      padding: 0 0.75rem !important;
    }
  }
}
</style>
