<template>
  <div class="mt-5 pt-5">
    <div class="bloc-paragraph">
      <div class="title-paragraph">
        Compétences associées à cette expérience
      </div>
    </div>
    <div v-if="!skillsSequenceList.length">
      <b-message type="is-info">
        Cette
        <span
          v-if="
            typeoftraining === 'training' ||
            typeoftraining === 'studenttraining'
          "
          >formation</span
        ><span v-if="typeoftraining === 'framework'">maquette</span
        ><span v-if="typeoftraining === 'sequence'">expérience</span> n'a pas de
        compétences enregistrées
      </b-message>
    </div>
    <div
      class="card-edit-sequence is-flex align-items-center w-100"
      v-for="skillsequence in skillsSequenceList"
      :key="skillsequence.name"
    >
      <div
        class="
          bloc-left
          is-flex
          mr-auto
          align-items-center
          w-100
          pr-3
          is-clickable
        "
      >
        <div class="is-flex">
          <b-icon icon="check" type="is-primary" class="mr-3 ml-3"></b-icon>
          {{ skillsequence.skill_name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import _ from 'lodash';

export default {
  props: {
    typeoftraining: {
      type: String,
    }, // framework, training or studenttraining
    sequenceId: {
      type: Number,
    },
    trainingId: {
      type: Number,
    },
  },
  components: {
  },
  filters: {
  },
  data() {
    return {
      isLoading: false,
      isFullPage: false,
      isOpenSkill: false,
      skillsSequenceList: [],
    };
  },
  computed: {
    ...mapState('auth', ['currentUser']),
  },
  methods: {
    startLoading() {
      this.isLoading = true;
    },
    endLoading() {
      this.isLoading = false;
    },
    order(array) {
      return _.orderBy(array, 'position');
    },
    initSkills() {
      this.startLoading();
      let urlWs = '';
      if (this.typeoftraining === 'framework') {
        urlWs = `/frameworkskillsblock2/?training_framework=${this.trainingId}&format=json`;
      } else if (this.typeoftraining === 'training') {
        urlWs = `/trainingskillsblock/?training=${this.trainingId}&format=json`;
      }
      this.$http
        .get(`${urlWs}`)
        .then((response) => {
          this.skillsList = this.order(response.data);
          if (this.typeoftraining === 'training') {
            /* eslint-disable */
            this.skillsList = this.skillsList.map(item => {
              return { skills: item.tskills, name: item.name, reference: item.reference, id: item.id, training: item.training, description: item.description, position: item.position };
            });
            /* eslint-enable */
          }

          for (let i = 0; i < this.skillsList.length; i += 1) {
            const myBlocks = this.skillsList;
            // eslint-disable-next-line
            this.skillsList[i].skills = this.order(myBlocks[i].skills);
          }
          this.endLoading();
        });
    },
    initSequenceSkills() {
      this.startLoading();
      let urlWs = '';
      if (this.typeoftraining === 'framework') {
        urlWs = `/sequenceskills/?sequence=${this.sequenceId}&format=json`;
      } else if (this.typeoftraining === 'training') {
        urlWs = `/trainingsequenceskills/?sequence=${this.sequenceId}&format=json`;
      }
      this.$http
        .get(`${urlWs}`)
        .then((response) => {
          this.skillsSequenceList = response.data;
          this.endLoading();
        });
    },
  },
  created() {
    // this.initSkills();
    this.initSequenceSkills();
  },
  mounted() {
  },
};
</script>

<style scoped>
.disabled {
  color: #dfdfeb;
}
</style>
