<template>
  <div class="modalbox modalStep" id="waypointModal">
    <b-loading :is-full-page="isFullPage" v-model="isLoading"> </b-loading>
    <div class="is-flex bigmodal">
      <router-link
        v-if="
          $route.params.from === 'WaypointRead' ||
          $route.params.from === 'WaypointEdit'
        "
        :to="
          '/formation-apprenant/' +
          $route.params.idTraining +
          '/' +
          $route.params.idStudent +
          '/' +
          $route.params.idStudentTraining +
          '/waypoint'
        "
      >
        <img
          class="btn-previous"
          :src="require('@/assets/img/arrows/previous.svg')"
        />
      </router-link>
      <img
        v-else
        class="btn-previous"
        :src="require('@/assets/img/arrows/previous.svg')"
        @click="goPreviousPage"
      />
      <router-link
        v-if="
          currentUser.role === 'formateur' ||
          currentUser.role === 'coordinateur'
        "
        class="ml-auto mr-2"
        :to="'/formations/' + $route.params.idTraining + '/suivi/waypointList'"
      >
        <b-button type="is-outlined" rounded size="is-small"
          >Formation</b-button
        >
      </router-link>
      <router-link
        class="mr-2"
        :to="
          '/formation-apprenant/' +
          $route.params.idTraining +
          '/' +
          $route.params.idStudent +
          '/' +
          $route.params.idStudentTraining +
          '/parcours'
        "
      >
        <b-button type="is-outlined" rounded size="is-small">Parcours</b-button>
      </router-link>
      <router-link
        :to="
          '/formation-apprenant/' +
          $route.params.idTraining +
          '/' +
          $route.params.idStudent +
          '/' +
          $route.params.idStudentTraining +
          '/waypoint'
        "
      >
        <b-button type="is-outlined" rounded size="is-small"
          >Livret de suivi</b-button
        ></router-link
      >
    </div>

    <section class="w-100 container">
      <div class="has-text-centered">
        <span class="tag status1 mr-1 w-auto" v-if="waypoint.type === 1"
          >point CFA</span
        >
        <span class="tag status2 mr-1 w-auto" v-else>point entreprise</span>

        <span class="tag is-success mr-0 w-auto" v-if="waypoint.validated"
          >validé</span
        >
        <span>
          <span
            class="tag is-success mr-0 w-auto ml-1"
            v-if="waypoint.read_by_tutor"
            >lu par le tuteur</span
          ><span v-else class="ml-1 tag is-warning mr-0 w-auto"
            >non lu</span
          ></span
        >
      </div>

      <div class="title-paragraph">
        <b-button
          type="is-outlined"
          rounded
          size="is-small"
          class="mr-2"
          @click="previousWaypoint"
          ><b-icon icon="chevron-left"></b-icon
        ></b-button>
        <span v-if="waypoint.title">{{ waypoint.title }}</span>
        <span class="title-paragraph" v-else>
          Point d'étape en<span v-if="waypoint.type === 1">
            centre de formation</span
          ><span v-else> entreprise</span>
        </span>
        <b-button
          type="is-outlined"
          rounded
          size="is-small"
          class="ml-2"
          @click="nextWaypoint"
          ><b-icon icon="chevron-right"></b-icon
        ></b-button>
      </div>
      <div
        v-if="currentUser.role !== 'apprenant'"
        class="has-text-centered mt-2 mb-2"
        style="font-size: 1.2rem"
      >
        <b>{{ waypoint.student }}</b>
      </div>
      <div class="has-text-centered dates">
        du {{ waypoint.start_date | day }} au
        {{ waypoint.end_date | day }}
        <span
          v-if="
            currentUser.role == 'coordinateur' ||
            currentUser.role == 'formateur'
          "
        >
          <b-button
            v-if="!modifyDates"
            @click="modifyDates = true"
            rounded
            size="is-small"
            >modifier</b-button
          >
          <span v-else class="mt-3">
            <b-button rounded size="is-small" @click="modifyDates = false"
              >fermer</b-button
            >
            <b-button
              rounded
              type="is-primary"
              size="is-small"
              @click="patchDates"
              >Enregistrer</b-button
            >
          </span></span
        >
        <div
          class="columns mt-3"
          v-if="
            modifyDates &&
            (currentUser.role == 'coordinateur' ||
              currentUser.role == 'formateur')
          "
        >
          <div class="column">
            <b-field
              class="mb-0 has-text-left"
              label="Date de début"
              :type="start_date > end_date ? 'is-danger' : ''"
            >
              <b-datepicker
                v-if="start_date"
                v-model="start_date"
                :first-day-of-week="1"
                placeholder="Cliquez pour sélectionner..."
                trap-focus
                @input="
                  verifdate(
                    new Date(waypoint.start_date),
                    new Date(waypoint.end_date)
                  )
                "
              >
                <b-button
                  label="Aujourd'hui"
                  type="is-primary"
                  icon-left="calendar-today"
                  @click="start_date = new Date()" /></b-datepicker
            ></b-field>
          </div>
          <div class="column">
            <b-field
              class="mb-0 has-text-left"
              label="Date de fin"
              :type="start_date > end_date ? 'is-danger' : ''"
            >
              <b-datepicker
                v-model="end_date"
                :first-day-of-week="1"
                placeholder="Cliquez pour sélectionner..."
                @input="verifdate(waypoint.start_date, waypoint.end_date)"
              >
                <b-button
                  label="Aujourd'hui"
                  type="is-primary"
                  icon-left="calendar-today"
                  @click="end_date = new Date()"
                /> </b-datepicker
            ></b-field>
          </div>
        </div>
        <b-message type="is-danger">
          <div v-if="start_date > end_date">
            Attention, il semble y a une incohérence entre la date de début et
            la date de fin. La date de début est postérieure à la date de fin.
          </div>
        </b-message>
        <!-- <b-message type="is-warning">
          <div v-if="start_date > new Date()">
            Attention, la date de début est dans le futur.
          </div>
          <div v-if="end_date > new Date()">
            La date de fin n'est pas encore passée. Vérifiez si c'est bien
            normal.
          </div></b-message
        > -->
        <span
          v-if="studentTrainings.length > 1"
          class="is-flex align-items-center justify-content-center"
        >
          <b-button
            v-if="
              currentUser.role === 'formateur' ||
              currentUser.role === 'coordinateur'
            "
            type="is-outlined"
            rounded
            size="is-small"
            class="mr-2 ml-2"
            @click="nextStudent($route.params.idStudentTraining)"
            ><b-icon icon="chevron-right" class="mr-0"> </b-icon>apprenant
            suivant</b-button
          ><AppHelpMessage
            v-if="
              currentUser.role === 'formateur' ||
              currentUser.role === 'coordinateur'
            "
            title="Faites défiler les points d'étapes pour les autres apprenants"
            message="Vous retrouverez ici les points d'étape ayant une même date de
               début de période."
            textBtn=""
        /></span>
      </div>

      <hr />
      <div v-if="waypoint.type === 1">
        <div class="title2">Objectif(s) de la période</div>

        <div class="card_edit card_item" v-if="waypoint.goals">
          <div v-html="waypoint.goals"></div>
        </div>
        <b-message type="is-info" v-else
          >Pas de commentaires enregistré
        </b-message>
        <!-- <hr /> -->
        <div class="title2">Liste des modules vus en centre de formation</div>
        <div
          class="card_item pointer w-100 card_edit card_item"
          v-for="subject in waypoint.school_subject_reports"
          v-bind:key="subject.school_subject"
        >
          <div class="title-subject">
            <b>{{ subject.school_subject_title }}</b>
          </div>
          <div v-html="subject.report"></div>
        </div>
        <div class="card_edit card_item" v-if="waypoint.free_text">
          <div v-html="waypoint.free_text"></div>
        </div>
        <a :href="waypoint.url" target="_blank" v-if="waypoint.url" class="mb-5"
          ><b-button type="is-primary is-outlined"
            >voir la ressource</b-button
          ></a
        >
        <!-- <hr /> -->
        <div class="title2">Commentaires de l'apprenant</div>
        <div class="card_edit card_item" v-if="waypoint.comment">
          <div v-html="waypoint.comment"></div>
        </div>
        <b-message type="is-info" v-else
          >Pas de commentaires enregistré
        </b-message>
        <!-- <hr /> -->
        <div class="title2">Commentaires du pilote</div>
        <div class="card_edit card_item" v-if="waypoint.teacher_comment">
          <div v-html="waypoint.teacher_comment"></div>
        </div>
        <b-message type="is-info" v-else
          >Pas de commentaires enregistré
        </b-message>
      </div>
      <div v-else-if="waypoint.type === 0">
        <div class="title2">Objectif(s) de la période</div>
        <div v-html="waypoint.goals" v-if="waypoint.goals"></div>
        <b-message type="is-info" v-else
          >Pas de commentaires enregistré
        </b-message>
        <!-- <hr /> -->
        <div class="listActivities">
          <div class="title2">
            Liste des expériences enregistrées en entreprise
          </div>
          <div
            v-for="activity in waypoint.activity_reports"
            :key="activity.date_created"
          >
            <div
              id="card_mobile"
              class="card_item is-flex align-items-center is-clickable"
              @click="
                gotoActivity(
                  activity.student_activity,
                  activity.student_training_sequence_id
                )
              "
              v-if="activity.student_activity"
            >
              <div class="is-flex align-items-center name-label">
                <span class="tag is-success">expérience</span>
                <div>
                  <div>
                    <b>{{ activity.student_sequence_title }}</b>
                  </div>
                  <div v-if="activity.student_sequence_subtitle">
                    ({{ activity.student_sequence_subtitle }})
                  </div>
                  <div
                    v-if="activity.student_activity_title"
                    class="has-text-primary"
                  >
                    {{ activity.student_activity_title }}
                  </div>
                  <div v-else class="has-text-primary">
                    {{ activity.student_activity_name }}
                  </div>
                </div>
              </div>
              <div class="autonomy">
                <span
                  class="tag is-success tag-autonomy"
                  v-if="activity.autonomy === 1"
                >
                  {{ activity.autonomy_title }}</span
                ><span v-else class="tag is-primary is-light tag-autonomy">
                  {{ activity.autonomy_title }}</span
                >
              </div>
            </div>
            <div
              id="card_mobile"
              @click="
                gotoRevision(
                  activity.student_activity_revision,
                  activity.student_activity,
                  activity.student_training_sequence_id
                )
              "
              class="card_item is-flex align-items-center is-clickable"
              v-if="activity.student_activity_revision"
            >
              <span class="tag status6">progression</span>
              <div>
                <div>
                  <b>{{ activity.student_sequence_title }}</b>
                </div>
                <div v-if="activity.student_sequence_subtitle">
                  ({{ activity.student_sequence_subtitle }})
                </div>
                <div class="has-text-primary">
                  {{ activity.student_activity_revision_title }}
                </div>
              </div>
              <div class="ml-auto">
                <span
                  class="tag is-success tag-autonomy"
                  v-if="activity.autonomy === 1"
                >
                  {{ activity.autonomy_title }}</span
                ><span v-else class="tag is-primary is-light tag-autonomy">
                  {{ activity.autonomy_title }}</span
                >
              </div>
            </div>
            <div
              id="card_mobile"
              class="card_item is-flex align-items-center is-clickable"
              @click="gotoFreeSituation(activity.free_situation)"
              v-if="activity.free_situation"
            >
              <span class="tag status1">expérience libre</span
              ><b>{{ activity.free_situation_title }}</b>
              <div class="ml-auto">
                <span
                  class="tag tag-autonomy is-success"
                  v-if="activity.autonomy === 1"
                >
                  {{ activity.autonomy_title }}</span
                ><span v-else class="tag tag-autonomy is-primary is-light">
                  {{ activity.autonomy_title }}</span
                >
              </div>
            </div>
          </div>
          <div class="has-text-primary mb-3 pt-3">
            Présentation libre des activités
          </div>
          <div class="" v-if="waypoint.free_text">
            <div v-html="waypoint.free_text"></div>
          </div>
        </div>
        <hr />
        <div class="title2">Commentaires de l'apprenant</div>
        <div v-html="waypoint.comment" v-if="waypoint.comment"></div>
        <b-message type="is-info" v-else
          >Pas de commentaires enregistré
        </b-message>
        <hr />
        <div class="title2">Commentaires du tuteur</div>
        <div
          v-html="waypoint.tutor_comment"
          v-if="waypoint.tutor_comment"
        ></div>

        <b-message type="is-info" v-else
          >Pas de commentaires enregistré
        </b-message>
      </div>
    </section>
    <div>
      <div
        class="is-flex is-flex-wrap-wrap activityBtns align-items-center"
        style="z-index: 2000"
        v-if="
          (waypoint.type === 0 && currentUser.role === 'tuteur') ||
          (waypoint.type === 0 &&
            currentUser.role === 'apprenant' &&
            !waypoint.validated) ||
          currentUser.role === 'coordinateur' ||
          (waypoint.type === 1 &&
            currentUser.role === 'apprenant' &&
            !waypoint.validated) ||
          (waypoint.type === 1 && currentUser.role === 'formateur') ||
          (waypoint.type === 1 && currentUser.role === 'tuteur')
        "
      >
        <!-- <b-button @click="$emit('close')" class="mr-auto" rounded
            ><b-icon icon="left" class="mr-3"></b-icon>retour</b-button
          > -->
        <div class="w-100 is-flex align-items-center">
          <div class="mx-auto is-flex align-items-center">
            <b-button
              @click="gotoWaypointEdit"
              class="btn-action w-auto mr-1"
              type="is-primary is-rounded is-outlined"
              rounded
              v-if="
                (waypoint.type === 1 && currentUser.role !== 'tuteur') ||
                (waypoint.type === 0 && currentUser.role !== 'coordinateur') ||
                (waypoint.type === 0 && currentUser.role !== 'formateur')
              "
              >modifier</b-button
            ><b-button
              @click="markasread"
              class="btn-action w-auto mr-1"
              type="is-success is-rounded"
              rounded
              v-if="currentUser.role === 'tuteur' && !waypoint.read_by_tutor"
              >marquer comme lu</b-button
            ><span
              class="has-text-success"
              v-if="
                waypoint.type === 1 &&
                currentUser.role === 'tuteur' &&
                waypoint.read_by_tutor
              "
              ><b
                ><b-icon type="is-success" icon="check"></b-icon> Déjà marqué
                comme lu</b
              ></span
            >
            <!-- <b-button
              @click="removeWaypoint(waypoint.id)"
              class="btn-action w-auto mr-2"
              type="is-danger is-rounded"
              rounded
              v-if="currentUser.role == 'coordinateur'"
              >supprimer</b-button
            > -->
          </div>
        </div>
        <div class="help-btn is-flex align-items-center">
          <b-button
            @click="removeWaypoint(waypoint.id)"
            class="btn-action w-auto mr-2"
            type="is-danger is-rounded"
            size="is-small"
            rounded
            v-if="currentUser.role == 'coordinateur'"
            >supprimer</b-button
          >
          <AppHelpMessage
            title="Modifiez votre point d'étape"
            message="Vous pouvez corriger une faute, ajouter un commentaire mais
             vous ne pouvez plus toucher aux expériences enregistrées une
              fois le point d'étape validé"
            textBtn="Besoin d'aide ?"
          />
        </div>
      </div>
    </div>

    <div class="container contentSequence pb-5 mb-5"></div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';
import AppHelpMessage from '@/components/AppHelpMessage.vue';
import _ from 'lodash';

export default ({
  filters: {
    day(date) {
      return moment(date).format('D/MM/YYYY');
    },
  },
  components: {
    AppHelpMessage,
  },
  computed: {
    ...mapState('auth', ['currentUser']),
  },
  data() {
    return {
      isLoading: false,
      isFullPage: false,
      schoolSubjectsTraining: [],
      schoolSubjects: [],
      schoolSubjectsSelected: [],
      waypoint: [],
      arrayReports: [],
      student_activities_report: [],
      student_revisions_report: [],
      free_situations_report: [],
      formWaypoint: {
        id: '',
        title: '',
        comment: '',
        tutor_comment: '',
        teacher_comment: '',
        start_date: '',
        end_date: '',
        reports: [],
        validated: false,
      },
      checkboxCustom: [],
      activities: [],
      isOpenActivities: false,
      isOpenRevision: false,
      isOpenFree: false,
      modifyDates: false,
      start_date: null,
      end_date: null,
      waypointList: [],
      previousEnabled: true,
      students: [],
      studentTrainings: [],
    };
  },
  methods: {
    startLoading() {
      this.isLoading = true;
    },
    endLoading() {
      this.isLoading = false;
    },
    initWaypoint() {
      // this.startLoading();
      const urlWs = `/agenda/student_training_waypoints/${this.$route.params.idWaypoint}/?format=json`;
      this.$http
        .get(`${urlWs}`)
        .then((response) => {
          this.waypoint = response.data;
          this.start_date = new Date(this.waypoint.start_date);
          this.end_date = new Date(this.waypoint.end_date);
          this.endLoading();
        });
    },
    goPreviousPage() {
      // if (this.$route.params.from === 'StudentActivity' ||
      // this.$route.params.from === 'StudentProgressionRead'
      // || this.$route.params.from === 'StudentFreeSituationRead') {
      //   this.$router.push({
      //     name: 'StudentTraining',
      //     params: {
      //       idStudent: this.$route.params.idStudent,
      //       idTraining: this.$route.params.idTraining,
      //       idStudentTraining: this.$route.params.idStudentTraining,
      //       tab: 'waypoint',
      //     },
      //   });
      // } else if (window.history.length > 1) {
      //   this.$router.go(-1);
      // } else {
      //   this.$router.push({
      //     name: 'StudentTraining',
      //     params: {
      //       idStudent: this.$route.params.idStudent,
      //       idTraining: this.$route.params.idTraining,
      //       idStudentTraining: this.$route.params.idStudentTraining,
      //       tab: 'waypoint',
      //     },
      //   });
      // }
      this.$router.go(-1);
    },
    gotoActivity(id, sequence) {
      this.$router.push({
        name: 'StudentActivity',
        params: {
          idTraining: this.$route.params.idTraining,
          idStudentTraining: this.$route.params.idStudentTraining,
          idSequence: sequence,
          idActivity: id,
          from: this.$route.name,
          idStudent: this.$route.params.idStudent,
          idWaypoint: this.$route.params.idWaypoint,
        },
      });
    },
    gotoRevision(id, studentActivity, sequence) {
      this.$router.push({
        name: 'StudentProgressionRead',
        params: {
          idTraining: this.$route.params.idTraining,
          idStudentTraining: this.$route.params.idStudentTraining,
          idSequence: sequence,
          idActivity: studentActivity,
          idRevision: id,
          from: this.$route.name,
          idStudent: this.$route.params.idStudent,
          idWaypoint: this.$route.params.idWaypoint,
        },
      });
    },
    gotoFreeSituation(id) {
      this.$router.push({
        name: 'StudentFreeSituationRead',
        params: {
          idTraining: this.$route.params.idTraining,
          idStudentTraining: this.$route.params.idStudentTraining,
          idFree: id,
          from: this.$route.name,
          idStudent: this.$route.params.idStudent,
          idWaypoint: this.$route.params.idWaypoint,
        },
      });
    },
    gotoWaypointEdit() {
      this.$router.push({
        name: 'WaypointEdit',
        params: {
          idTraining: this.$route.params.idTraining,
          idStudent: this.$route.params.idStudent,
          idStudentTraining: this.$route.params.idStudentTraining,
          idWaypoint: this.$route.params.idWaypoint,
        },
      });
    },
    markasread() {
      this.$http
        .patch(`/agenda/student_training_waypoints/${this.$route.params.idWaypoint}/`, {
          read_by_tutor: true,
        }).then(() => {
          this.$buefy.toast.open({
            message: 'Le point a été enregistré', type: 'is-success',
          });
          this.goPreviousPage();
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
          this.$buefy.dialog.alert({
            title: 'Mince...',
            message: 'Le point n\'a pas pu être enregistré.',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
          });
        });
    },
    patchDates() {
      this.$http
        .patch(`/agenda/student_training_waypoints/${this.$route.params.idWaypoint}/`, {
          start_date: this.start_date,
          end_date: this.end_date,
        }).then(() => {
          this.$buefy.toast.open({
            message: 'Dates modifiées', type: 'is-success',
          });
          this.initWaypoint();
          this.modifyDates = false;
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
          this.$buefy.dialog.alert({
            title: 'Mince...',
            message: 'Les dates n\'ont pu être modifiées.',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
          });
        });
    },
    verifdate(start, end) {
      if (this.start_date > this.end_date) {
        this.$buefy.dialog.confirm({
          title: 'Attention, il semble y avoir un problème de dates',
          message: 'La date de début est incohérente avec la date de fin',
          confirmText: 'Annuler',
          cancelText: 'Valider',
          type: 'is-danger',
          hasIcon: true,
          onConfirm: () => {
            this.start_date = start;
            this.end_date = end;
          },
        });
      } else if (this.start_date > new Date()) {
        this.$buefy.dialog.confirm({
          title: 'Attention, la date de début ne peut pas être postérieure à aujourd\'hui',
          message: 'Voulez-vous bien changer cette date ?',
          confirmText: 'Annuler',
          cancelText: 'Valider',
          type: 'is-danger',
          hasIcon: true,
          onConfirm: () => {
            this.start_date = start;
            this.end_date = end;
          },
        });
      }
    },
    removeWaypoint(id) {
      const urlWs = `/agenda/student_training_waypoints/${id}/`;
      this.$buefy.dialog.confirm({
        title: 'Supprimer un point d\'étape',
        message: 'Êtes-vous sûr de vouloir supprimer ce point ? Attention, vous perdrez tout le contenu que vous avez pu rédiger pour ce point.',
        confirmText: 'Supprimer',
        cancelText: 'Annuler',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.$http
            .delete(`${urlWs}`)
            .then(() => {
              this.$buefy.toast.open({ message: 'Point supprimé', type: 'is-success' });
              this.$router.push({
                name: 'StudentTraining',
                params: {
                  idStudent: this.$route.params.idStudent, // need check id profile
                  idTraining: this.$route.params.idTraining,
                  idStudentTraining: this.$route.params.idStudentTraining,
                  tab: 'waypoint',
                },
              });
            }).catch((error) => {
              window.console.log(error);
              this.errored = true;
              this.$buefy.dialog.alert({
                title: 'Mince...',
                message: 'Le point n\'a pas pu être supprimé.',
                type: 'is-info',
                hasIcon: true,
                icon: 'times-circle',
                ariaRole: 'alertdialog',
                ariaModal: true,
                confirmText: 'Ok',
              });
            });
        },
      });
    },
    nextWaypoint() {
      const urlWs = `/agenda/student_training_waypoints/?student_training=${this.$route.params.idStudentTraining}&type=${this.waypoint.type}&format=json`;
      this.$http
        .get(`${urlWs}`)
        .then((response) => {
          this.waypointList = this.order(response.data, 'asc');
          this.endLoading();
          for (let i = 0; i < this.waypointList.length; i += 1) {
            if (this.waypointList[i].start_date > this.waypoint.start_date) {
              this.$router.push({
                name: 'WaypointRead',
                params: {
                  idTraining: this.$route.params.idTraining,
                  idStudent: this.$route.params.idStudent,
                  idStudentTraining: this.$route.params.idStudentTraining,
                  idWaypoint: this.waypointList[i].id,
                  from: 'WaypointRead',
                },
              });
              this.initWaypoint();
              break;
            }
          }
        });
    },
    previousWaypoint() {
      const urlWs = `/agenda/student_training_waypoints/?student_training=${this.$route.params.idStudentTraining}&type=${this.waypoint.type}&format=json`;
      this.$http
        .get(`${urlWs}`)
        .then((response) => {
          this.waypointList = this.order(response.data, 'desc');
          this.endLoading();
          for (let i = 0; i < this.waypointList.length; i += 1) {
            if (this.waypointList[i].start_date < this.waypoint.start_date) {
              this.$router.push({
                name: 'WaypointRead',
                params: {
                  idTraining: this.$route.params.idTraining,
                  idStudent: this.$route.params.idStudent,
                  idStudentTraining: this.$route.params.idStudentTraining,
                  idWaypoint: this.waypointList[i].id,
                  from: 'WaypointRead',
                },
              });
              this.initWaypoint();
              break;
            }
          }
        });
    },
    nextStudent(idStudentTraining) {
      this.startLoading();
      let result = this.studentTrainings.findIndex((element) => element === idStudentTraining);
      result = Number(result);
      let nexStudentTraining = null;
      if (this.studentTrainings[result + 1]) {
        nexStudentTraining = this.studentTrainings[result + 1];
      } else {
        const startArray = this.studentTrainings[0];
        nexStudentTraining = startArray;
      }
      if (nexStudentTraining) {
        this.$http
          .get(`/agenda/student_training_waypoints/?student_training=${nexStudentTraining}&format=json`)
          .then((response) => {
            const indexNextWaypoint = _.findKey(response.data,
              (e) => e.start_date === this.waypoint.start_date && e.type === this.waypoint.type);
            const nextWaypoint = response.data[indexNextWaypoint];
            if (nextWaypoint) {
              this.$router.push({
                name: 'WaypointRead',
                params: {
                  idTraining: this.$route.params.idTraining,
                  idStudent: this.students[result + 1],
                  idStudentTraining: this.studentTrainings[result + 1],
                  idWaypoint: nextWaypoint.id,
                  from: 'WaypointRead',
                },
              });
              this.endLoading();
              this.initWaypoint();
            } else {
              this.nextStudent(nexStudentTraining);
            }
          });
      }
    },
    previousStudent(idStudentTraining) {
      let result = this.studentTrainings.findIndex((element) => element === idStudentTraining);
      result = Number(result);
      let nexStudentTraining = null;
      if (this.studentTrainings[result - 1]) {
        nexStudentTraining = this.studentTrainings[result - 1];
      } else {
        const endArray = this.studentTrainings[this.studentTrainings.length - 1];
        nexStudentTraining = endArray;
      }
      if (nexStudentTraining) {
        this.$http
          .get(`/agenda/student_training_waypoints/?student_training=${nexStudentTraining}&format=json`)
          .then((response) => {
            const indexNextWaypoint = _.findKey(response.data,
              (e) => e.start_date === this.waypoint.start_date && e.type === this.waypoint.type);
            const nextWaypoint = response.data[indexNextWaypoint];
            if (nextWaypoint) {
              this.$router.push({
                name: 'WaypointRead',
                params: {
                  idTraining: this.$route.params.idTraining,
                  idStudent: this.students[result - 1],
                  idStudentTraining: this.studentTrainings[result - 1],
                  idWaypoint: nextWaypoint.id,
                  from: 'WaypointRead',
                },
              });
              this.initWaypoint();
            } else {
              this.nextStudent(nexStudentTraining);
              this.$buefy.toast.open({ message: 'Pas de point aux mêmes dates pour cet apprenant', type: 'is-warning' });
            }
          });
      }
    },
    order(array, order) {
      return _.orderBy(array, 'start_date', order);
    },
    logActivity() {
      const typeAction = 'lecture waypoint';
      this.$http
        .post('agenda/logs/', {
          action: typeAction,
          student_training: this.$route.params.idStudentTraining,
          training: this.$route.params.idTraining,
          student_activity: null,
          student_sequence: null,
        })
        .then(() => {
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
        });
    },
    initStudentTrainings() {
      this.$http
        .get(`/trainings/${this.$route.params.idTraining}/?query={students,related_student_trainings_from_this_training}&format=json`)
        .then((response) => {
          this.studentTrainings = response.data.related_student_trainings_from_this_training;
          this.students = response.data.students;
        });
    },
    handleHistoryChange() {
      window.console.log('history');
      this.initWaypoint();
    },
  },
  created() {
    this.startLoading();
    this.initWaypoint();
    if (this.currentUser.role === 'apprenant' || this.currentUser.role === 'tuteur') { this.logActivity(); }
    this.initStudentTrainings();
  },
  mounted() {
    window.addEventListener('popstate', this.handleHistoryChange);
  },
  destroyed() {
    window.removeEventListener('popstate', this.handleHistoryChange);
  },
});
</script>

<style lang="scss" scoped>
.autonomy {
  margin-left: auto;
}

.tag {
  width: 105px;
  margin-right: 1.5rem;
  margin-bottom: 0.5rem;
}
.tag-autonomy {
  margin-right: 0px;
  border-radius: 0px;
  margin-bottom: 0px;
  width: 80px;
}
.w-auto {
  width: auto;
}
@media (max-width: 990px) {
}
@media (max-width: 768px) {
  .name-label {
    flex-direction: column;
    align-items: flex-start;
    .tag {
      width: 90px;
    }
  }
  #card_mobile {
    flex-direction: column;
    align-items: flex-start;
  }
  // .autonomy {
  //   margin-left: 0px;
  // }
}
</style>
