<template>
  <div class="is-flex">
    <AppSidebar />
    <div class="main-content">
      <div class="lineCrumbAndAccount">
        <AppBreadcrumb @click="gotoStep(crumbs.id)" v-bind:crumbs="crumbs" />
        <AppAccountMenu v-bind:appAccount="crumbs" />
      </div>
      <div class="header-page is-flex flex-column">
        <div class="justify-content-between columns lineHeaderTitle">
          <div v-if="user.user" class="column title-page short-title">
            <span class="tag is-inline" :class="user.role">{{
              user.role
            }}</span>
            <div class="username">
              {{ user.user.first_name }} {{ user.user.last_name }}
            </div>
          </div>

          <div class="column is-narrow actions">
            <router-link to="/utilisateurs">
              <b-button type="is-primary is-rounded mr-3" outlined
                >Revenir à la liste des utilisateurs</b-button
              >
            </router-link>
          </div>
        </div>
      </div>
      <section class="mt-auto" id="header-tabs">
        <div class="b-tabs">
          <nav class="tabs">
            <ul>
              <li
                v-for="(tab, index) of tabs"
                v-bind:key="index"
                v-bind:id="tab.id"
                v-bind:class="currentTab === tab.slug ? 'is-active' : ''"
              >
                <a @click="gotoStep(tab.slug)"
                  ><span>{{ tab.name }}</span></a
                >
              </li>
            </ul>
          </nav>
        </div>
      </section>
      <div class="content-page list-table">
        <section v-if="step === 'infos'">
          <figure
            class="image is-128x128"
            style="margin-bottom: 3rem"
            v-if="user.avatar"
          >
            <img class="is-rounded" :src="user.avatar" />
          </figure>

          <div
            class="card_item"
            v-if="user.role === 'apprenant' && user && lastStudentTraining"
          >
            <router-link
              :to="
                '/formation-apprenant/' +
                user.training_info.training_id +
                '/' +
                user.id +
                '/' +
                lastStudentTraining.id +
                '/parcours'
              "
            >
              <b class="has-text-black">{{ user.training_info.training }}</b
              ><b-tag
                type="is-outlined"
                class="ml-2"
                v-if="lastStudentTraining.option"
                >{{ lastStudentTraining.option.title }}</b-tag
              ><span class="ml-2 mr-1" v-if="lastStudentTraining">
                <b-tag v-if="lastStudentTraining.finished" type="is-success"
                  >terminé</b-tag
                ><b-tag v-else type="is-light is-primary">en cours</b-tag></span
              >
              <span class="mr-1" v-if="lastStudentTraining">
                <b-tag v-if="lastStudentTraining.deleted" type="is-danger"
                  >supprimé</b-tag
                ><b-tag
                  v-if="
                    !lastStudentTraining.finished &&
                    !lastStudentTraining.deleted
                  "
                  type="is-success is-light"
                  >actif</b-tag
                ></span
              ></router-link
            >
            <div v-if="lastStudentTraining">
              <b
                class="has-text-primary"
                v-if="lastStudentTraining.tutors_profiles.length > 0"
                >tutorat :
              </b>
              <span
                class="ml-2"
                v-for="(tutor, index) in lastStudentTraining.tutors_profiles"
                :key="index"
              >
                <span
                  class="is-clickable is-underlined has-text-primary"
                  type="is-text"
                  @click="seeUserprofil(lastStudentTraining.tutors[index])"
                >
                  {{ tutor }}
                </span>
              </span>
            </div>
            <div v-if="lastStudentTraining.training.framework_type">
              <span v-if="lastStudentTraining.training.framework_type.id === 3">
                <span v-if="lastStudentTraining.teachers_profiles.length > 0"
                  ><b class="has-text-primary">coaching</b> :</span
                >
                <span
                  class="ml-2"
                  v-for="(
                    teacher, index
                  ) in lastStudentTraining.teachers_profiles"
                  :key="index + 'formateur'"
                >
                  <span
                    class="is-clickable is-underlined has-text-primary"
                    type="is-text"
                    @click="seeUserprofil(lastStudentTraining.teachers[index])"
                    >{{ teacher }}
                  </span>
                </span>
              </span>
            </div>

            <div
              v-if="lastStudentTraining.start_date"
              style="font-size: 0.9rem"
            >
              <span class="is-underlined">début</span> :
              {{ lastStudentTraining.start_date | day }} /
              <span class="is-underlined">fin</span> :
              {{ lastStudentTraining.end_date | day }}
            </div>
          </div>

          <ul v-if="user.user">
            <!--<li class="readField" v-if="user.user">
              <b>Nom :</b> {{ user.user.first_name }} {{ user.user.last_name }}
            </li>
            <li class="readField" v-if="user.organization">
              <b>Organisation :</b> {{ user.organization.name }}
            </li>-->
            <li class="readField" v-if="user.birth_date">
              <b>Date de naissance :</b> {{ user.birth_date | moment }}
            </li>
            <li class="readField" v-if="user.phone_number">
              <b>Téléphone :</b> {{ user.phone_number }}
            </li>
            <li class="readField" v-if="user.user.email">
              <b>Courriel :</b> {{ user.user.email
              }}<span v-if="currentUser.role === 'coordinateur'">
                <b-button
                  v-if="!modify"
                  @click="modify = true"
                  size="is-small"
                  class="ml-2"
                  rounded
                >
                  modifier</b-button
                ></span
              >
              <div v-if="currentUser.role === 'coordinateur'">
                <b-input
                  v-if="modify"
                  class="mt-3"
                  v-model="form.email"
                  type="email"
                  placeholder="Inscrivez l'email ici'"
                >
                </b-input>
                <div v-if="modify" class="mt-3">
                  <b-button @click="modify = false" rounded>annuler</b-button>
                  <b-button
                    @click="patchUser"
                    type="is-primary"
                    class="ml-2"
                    rounded
                    >enregistrer</b-button
                  >
                </div>
              </div>
            </li>
            <li class="readField" v-if="user.address">
              <b>Adresse :</b> {{ user.address }}
            </li>
            <div v-if="user.role === 'formateur'">
              <li class="readField">
                <div><b>Droits d'écriture :</b></div>
                <div>
                  <span
                    v-if="!modifyRights"
                    class="is-flex align-items-center mt-3"
                  >
                    <b-tag type="is-success" v-if="user.edit_rights === 0"
                      >éditeur complet</b-tag
                    >
                    <b-tag type="is-primary" v-if="user.edit_rights === 1"
                      >éditeur avancé</b-tag
                    >
                    <b-tag type="is-warning" v-if="user.edit_rights === 2"
                      >éditeur simple</b-tag
                    >
                    <b-tag type="is-outlined" v-if="user.edit_rights === 3"
                      >lecture seule</b-tag
                    >
                    <b-tag type="is-outlined" v-if="user.edit_rights === 4"
                      >coach</b-tag
                    >
                    <b-button
                      v-if="
                        currentUser.role === 'coordinateur' &&
                        user.edit_rights !== 4
                      "
                      @click="modifyRights = true"
                      size="is-small"
                      class="ml-3"
                      rounded
                    >
                      modifier</b-button
                    >
                  </span>
                  <span v-else class="is-flex align-items-center">
                    <b-select
                      placeholder="Choisissez les droits d'éditions associés"
                      v-model="form.rights"
                    >
                      <option value="0">éditeur complet</option>
                      <option value="1">éditeur avancé</option>
                      <option value="2">éditeur simple</option>
                      <option value="3">lecture seule</option>
                    </b-select>
                    <b-button
                      @click="patchRole"
                      type="is-primary"
                      class="ml-2"
                      rounded
                      >enregistrer</b-button
                    >
                  </span>
                </div>
              </li>
            </div>
          </ul>

          <ul v-if="user.training_info && user.role === 'apprenant'">
            <li
              class="readField"
              v-if="user.training_info.legal_representative"
            >
              <b>Représentant légal :</b>
              {{ user.training_info.legal_representative }}
            </li>
            <li class="readField" v-if="user.training_info.enterprise">
              <b>Entreprise :</b> {{ user.training_info.enterprise }}
            </li>
            <li
              class="readField"
              v-if="user.training_info.contract_information"
            >
              <b>Contrat :</b>
              <span v-html="user.training_info.contract_information"></span>
            </li>
            <li class="readField" v-if="user.training_info.enterprise_contacts">
              <b>Contacts entreprise :</b>
              <span v-html="user.training_info.enterprise_contacts"></span>
            </li>
            <li class="readField" v-if="user.training_info.mentor">
              <b>Tuteur :</b>
              <span v-html="user.training_info.mentor"></span>
            </li>
          </ul>
        </section>
        <section v-if="step === 'parcours'" class="table_trainings">
          <div class="userCount">Formations associées à cet utilisateur</div>
          <b-message type="is-info" v-if="user.role === 'coordinateur'">
            Un coordinateur peut voir toutes les formations du CFA
          </b-message>
          <!----------------------------for student User---------------------------->
          <b-table
            v-if="user.role === 'apprenant' && trainingsAsStudent.length > 0"
            :data="trainingsAsStudent"
            :key="componentKey"
            ref="table"
            :paginated="isPaginated"
            :per-page="perPage"
            :current-page="currentPage"
            :pagination-simple="isPaginationSimple"
            :pagination-position="paginationPosition"
            :sort-icon-size="sortIconSize"
            :default-sort-direction="defaultSortDirection"
            :debounce-search="1000"
            default-sort="name"
          >
            <!-- :selected.sync="trainingsAsStudent[lastId]"-->
            <template v-for="column in trainingColumns" class="active">
              <b-table-column :key="column.slug" v-bind="column">
                <template
                  v-if="column.searchable && !column.numeric"
                  slot="searchable"
                  slot-scope="props"
                >
                  <b-input
                    v-model="props.filters[props.column.field]"
                    placeholder="Rechercher..."
                    icon="search"
                    size="is-small"
                  />
                </template>
                <template v-slot="props" v-if="column.field === 'training'">
                  <router-link
                    :to="
                      '/formations/' + props.row.training.id + '/suivi/realtime'
                    "
                    class="has-text-black"
                  >
                    <b class="is-clickable"> {{ props.row.training.name }}</b
                    ><b-tag
                      type="is-outlined"
                      class="ml-2"
                      v-if="props.row.option"
                      >{{ props.row.option.title }}</b-tag
                    ></router-link
                  ><span class="ml-2 mr-1">
                    <b-tag v-if="props.row.finished" type="is-success"
                      >terminé</b-tag
                    ><b-tag v-else type="is-light is-primary"
                      >en cours</b-tag
                    ></span
                  >
                  <span class="mr-1">
                    <b-tag v-if="props.row.deleted" type="is-danger"
                      >supprimé</b-tag
                    ><b-tag
                      v-if="!props.row.finished && !props.row.deleted"
                      type="is-success is-light"
                      >actif</b-tag
                    ></span
                  >
                  <!-- <div
                    class="is-clickable"
                    v-for="(tutor, index) in props.row.tutors_profiles"
                    :key="index"
                  >
                    <div
                      class="is-underlined has-text-primary"
                      type="is-text"
                      @click="seeUserprofil(props.row.tutors[index])"
                    >
                      tuteur : {{ tutor }}
                    </div>
                  </div> -->
                  <div v-if="lastStudentTraining">
                    <b
                      class="has-text-primary"
                      v-if="props.row.tutors_profiles.length > 0"
                      >tutorat :
                    </b>
                    <span
                      class="ml-2"
                      v-for="(tutor, index) in props.row.tutors_profiles"
                      :key="index"
                    >
                      <span
                        class="is-clickable is-underlined has-text-primary"
                        type="is-text"
                        @click="seeUserprofil(props.row.tutors[index])"
                      >
                        {{ tutor }}
                      </span>
                    </span>
                  </div>
                  <div v-if="props.row.training.framework_type">
                    <span v-if="props.row.training.framework_type.id === 3">
                      <span v-if="props.row.teachers_profiles.length > 0"
                        ><b class="has-text-primary">coaching</b> :</span
                      >
                      <span
                        class="ml-2"
                        v-for="(teacher, index) in props.row.teachers_profiles"
                        :key="index + 'formateur'"
                      >
                        <span
                          class="is-clickable is-underlined has-text-primary"
                          type="is-text"
                          @click="seeUserprofil(props.row.teachers[index])"
                          >{{ teacher }}
                        </span>
                      </span>
                    </span>
                  </div>

                  <span v-if="props.row.start_date" style="font-size: 0.9rem"
                    ><span class="is-underlined">début</span> :
                    {{ props.row.start_date | day }} /
                    <span class="is-underlined">fin</span> :
                    {{ props.row.end_date | day }}</span
                  >
                </template>
                <template
                  v-slot="props"
                  v-else-if="column.field === 'trainingId'"
                >
                  <div
                    @click.prevent="seeTraining(props.row.training.id)"
                    class="pl-0"
                  >
                    <b-icon icon="see"></b-icon>
                  </div>
                </template>
                <template
                  v-slot="props"
                  v-else-if="column.field === 'studentTrainingId'"
                >
                  <div
                    @click.prevent="
                      seeStudentTraining(
                        props.row.training.id,
                        $route.params.id,
                        props.row.id
                      )
                    "
                  >
                    <b-icon icon="see"></b-icon>
                  </div>
                </template>
              </b-table-column>
            </template>
          </b-table>
          <!----------------------------for teacher user ---------------------------->
          <b-table
            v-else-if="
              user.role === 'formateur' && trainingsAsTeacher.length > 0
            "
            :data="trainingsAsTeacher"
            :key="componentKey"
            ref="table"
            :paginated="isPaginated"
            :per-page="perPage"
            :current-page="currentPage"
            :pagination-simple="isPaginationSimple"
            :pagination-position="paginationPosition"
            :sort-icon-size="sortIconSize"
            :default-sort-direction="defaultSortDirection"
            :debounce-search="1000"
            default-sort="name"
          >
            <template v-for="column in trainingTeacherColumns">
              <b-table-column :key="column.slug" v-bind="column">
                <template
                  v-if="column.searchable && !column.numeric"
                  slot="searchable"
                  slot-scope="props"
                >
                  <b-input
                    v-model="props.filters[props.column.field]"
                    placeholder="Rechercher..."
                    icon="search"
                    size="is-small"
                  />
                </template>
                <template v-slot="props" v-if="column.field === 'training'">
                  <router-link
                    :to="'/formations/' + props.row.id + '/suivi/realtime'"
                    class="has-text-black"
                  >
                    <b class="is-clickable">
                      {{ props.row.training }}</b
                    ></router-link
                  ><b-tag
                    v-if="
                      props.row.related_student_trainings_from_this_training
                    "
                  >
                    {{
                      props.row.related_student_trainings_from_this_training
                    }}apprenants</b-tag
                  >
                </template>
                <template
                  v-slot="props"
                  v-else-if="column.field === 'trainingId'"
                >
                  <router-link
                    :to="'/formations/' + props.row.id + '/suivi/realtime'"
                    class="has-text-black"
                  >
                    <div>
                      <b-icon icon="see"></b-icon></div
                  ></router-link>
                </template>
              </b-table-column>
            </template>
          </b-table>
          <!----------------------------for manager user ---------------------------->
          <b-table
            v-else-if="user.role === 'manager' && trainingsAsManager.length > 0"
            :data="trainingsAsManager"
            :key="componentKey"
            ref="table"
            :paginated="isPaginated"
            :per-page="perPage"
            :current-page="currentPage"
            :pagination-simple="isPaginationSimple"
            :pagination-position="paginationPosition"
            :sort-icon-size="sortIconSize"
            :default-sort-direction="defaultSortDirection"
            :debounce-search="1000"
            default-sort="name"
          >
            <template v-for="column in trainingManagerColumns">
              <b-table-column :key="column.slug" v-bind="column">
                <template
                  v-if="column.searchable && !column.numeric"
                  slot="searchable"
                  slot-scope="props"
                >
                  <b-input
                    v-model="props.filters[props.column.field]"
                    placeholder="Rechercher..."
                    icon="search"
                    size="is-small"
                  />
                </template>
                <template v-slot="props" v-if="column.field === 'training'">
                  <router-link
                    :to="'/formations/' + props.row.id + '/suivi/realtime'"
                    class="has-text-black"
                  >
                    <b class="is-clickable">
                      {{ props.row.training }}</b
                    ></router-link
                  >
                </template>
                <template
                  v-slot="props"
                  v-else-if="column.field === 'trainingId'"
                >
                  <div @click.prevent="seeTraining(props.row.id)">
                    <b-icon icon="see"></b-icon>
                  </div>
                </template>
              </b-table-column>
            </template>
          </b-table>
          <!----------------------------for tutor user ---------------------------->
          <b-table
            v-else-if="user.role === 'tuteur' && trainingsAsTutor.length > 0"
            :data="trainingsAsTutor"
            :key="componentKey"
            ref="table"
            :paginated="isPaginated"
            :per-page="perPage"
            :current-page="currentPage"
            :pagination-simple="isPaginationSimple"
            :pagination-position="paginationPosition"
            :sort-icon-size="sortIconSize"
            :default-sort-direction="defaultSortDirection"
            :debounce-search="1000"
            default-sort="name"
          >
            <template v-for="column in trainingColumns">
              <b-table-column :key="column.slug" v-bind="column">
                <template
                  v-if="column.searchable && !column.numeric"
                  slot="searchable"
                  slot-scope="props"
                >
                  <b-input
                    v-model="props.filters[props.column.field]"
                    placeholder="Rechercher..."
                    icon="search"
                    size="is-small"
                  />
                </template>
                <template v-slot="props" v-if="column.field === 'training'">
                  <router-link
                    :to="
                      '/formations/' + props.row.training.id + '/suivi/realtime'
                    "
                    class="has-text-black"
                  >
                    <b class="is-clickable">
                      {{ props.row.training.name }}</b
                    ></router-link
                  ><span class="ml-2 mr-1">
                    <b-tag v-if="props.row.finished" type="is-success"
                      >terminé</b-tag
                    ><b-tag v-else type="is-light is-primary"
                      >en cours</b-tag
                    ></span
                  >
                  <span class="mr-1">
                    <b-tag v-if="props.row.deleted" type="is-danger"
                      >supprimé</b-tag
                    ><b-tag
                      v-if="!props.row.finished && !props.row.deleted"
                      type="is-success is-light"
                      >actif</b-tag
                    ></span
                  >
                  <div
                    class="is-underlined has-text-primary is-clickable"
                    type="is-text"
                    @click="seeUserprofil(props.row.student)"
                  >
                    Apprenant : {{ props.row.student_profile }}
                  </div>
                  <div class="mt-2">
                    <span v-if="props.row.start_date" style="font-size: 0.9rem"
                      ><span class="is-underlined">début</span> :
                      {{ props.row.start_date | day }} /
                      <span class="is-underlined">fin</span> :
                      {{ props.row.end_date | day }}</span
                    ><b-tag
                      type="is-outlined"
                      class="ml-2"
                      v-if="props.row.option"
                      >{{ props.row.option.title }}</b-tag
                    >
                  </div>
                </template>
                <template
                  v-slot="props"
                  v-else-if="column.field === 'trainingId'"
                >
                  <div @click.prevent="seeTraining(props.row.training.id)">
                    <b-icon icon="see"></b-icon>
                  </div>
                </template>
                <template
                  v-slot="props"
                  v-else-if="column.field === 'studentTrainingId'"
                >
                  <div
                    @click.prevent="
                      seeStudentTraining(
                        props.row.training.id,
                        props.row.student,
                        props.row.id
                      )
                    "
                  >
                    <b-icon icon="see"></b-icon>
                  </div>
                </template>
              </b-table-column>
            </template>
          </b-table>
          <!----------------------------for coordinateur---------------------------->
          <b-table
            v-else-if="user.role === 'coordinateur' && trainings.length > 0"
            :data="trainings"
            :key="componentKey"
            ref="table"
            :paginated="isPaginated"
            :per-page="perPage"
            :current-page="currentPage"
            :pagination-simple="isPaginationSimple"
            :pagination-position="paginationPosition"
            :sort-icon-size="sortIconSize"
            :default-sort-direction="defaultSortDirection"
            :debounce-search="1000"
            default-sort="name"
          >
            <template v-for="column in trainingAllColumns">
              <b-table-column :key="column.slug" v-bind="column">
                <template
                  v-if="column.searchable && !column.numeric"
                  slot="searchable"
                  slot-scope="props"
                >
                  <b-input
                    v-model="props.filters[props.column.field]"
                    placeholder="Rechercher..."
                    icon="search"
                    size="is-small"
                  />
                </template>
                <template v-slot="props" v-if="column.field === 'name'">
                  <router-link
                    :to="'/formations/' + props.row.id + '/suivi/realtime'"
                    class="has-text-black"
                  >
                    <b> {{ props.row.name }}</b></router-link
                  >
                </template>
                <template
                  v-slot="props"
                  v-else-if="column.field === 'trainingId'"
                >
                  <router-link
                    :to="'/formations/' + props.row.id + '/suivi/realtime'"
                    class="has-text-black"
                  >
                    <b-icon icon="see"></b-icon>
                  </router-link>
                </template>
              </b-table-column>
            </template>
          </b-table>
          <b-message v-else type="is-info">
            Cet utilisateur n'est attaché à aucun parcours pour le moment...
          </b-message>
        </section>
        <section v-if="step === 'users'">Contacts</section>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import localization from 'moment/locale/fr';
import { mapState, mapGetters } from 'vuex';
import AppSidebar from '@/components/AppSidebar.vue';
import AppBreadcrumb from '@/components/AppBreadcrumb.vue';
import AppAccountMenu from '@/components/AppAccountMenu.vue';

moment.updateLocale('fr', localization);

export default {
  components: {
    AppSidebar,
    AppBreadcrumb,
    AppAccountMenu,
  },
  computed: {
    training() {
      return this.$store.state.training;
    },
    trainings() {
      return this.$store.state.trainings;
    },
    ...mapGetters(['getTrainingById']),
    ...mapState('auth', ['currentUser']),
  },
  filters: {
    day(date) {
      return moment(date).format('D/MM/YYYY');
    },
    moment(date) {
      return moment(date).format('D MMMM YYYY');
    },
  },
  methods: {
    initTrainings() {
      this.$http
        .get('/trainings/?query={id,name,related_student_trainings_from_this_training}&format=json')
        .then((response) => {
          // need store trainings to use getter TrainingById
          this.$store.commit('GET_TRAININGS', response.data);
          if (this.user.role === 'apprenant') { this.createTrainingsAsStudentArray(); }
          if (this.user.role === 'formateur') { this.createTrainingsAsTeacherArray(); }
          if (this.user.role === 'manager') { this.createTrainingsAsManagerArray(); }
          if (this.user.role === 'tuteur') { this.createTrainingsAsTutorArray(); }
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
        });
    },
    getTraining(id) {
      return this.$store.getters.getTrainingById(id);
    },
    initUser() {
      this.$http
        .get(`/profile/${this.$route.params.id}/?format=json`)
        .then((response) => {
          this.user = response.data;
          this.initTrainings();
        });
    },
    gotoStep(numberStep) {
      // to change the tab and the content
      if (this.$route.path !== `/utilisateurs/${this.$route.params.id}/${numberStep}`) {
        this.$router.push({
          name: 'UtilisateurDetail',
          params: {
            id: this.$route.params.id,
            step: numberStep,
          },
        });
      }
      this.currentTab = numberStep;
      this.step = numberStep;
    },
    handleHistoryChange() {
      this.initUser();
      // Your logic
      if (this.$route.params.step !== this.currentTab) {
        this.gotoStep(this.$route.params.step);
      } else if (!this.$route.params.step) {
        this.gotoStep(this.tabs[0].slug);
      }
    },
    createTrainingsAsStudentArray() {
      this.$http
        .get(`/agenda/student_training/?student=${this.user.id}&query={id,teachers,teachers_profiles,tutors,tutors_profiles,training{id,name,framework_type},deleted,finished,active,start_date,end_date,option}&format=json`)
        .then((response) => {
          // need store trainings to use getter TrainingById
          this.trainingsAsStudent = response.data;
          this.findLastTraining(this.user.training_info.training);
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
        });
    },
    createTrainingsAsTeacherArray() {
      for (let i = 0; i < this.user.related_trainings_as_teacher.length; i += 1) {
        if (this.getTraining(this.user.related_trainings_as_teacher[i])) {
          this.trainingsAsTeacher.push(
            {
              id: this.user.related_trainings_as_teacher[i],
              training: this.getTraining(this.user.related_trainings_as_teacher[i]).name,
            },
          );
        }
      }
    },
    createTrainingsAsManagerArray() {
      for (let i = 0; i < this.user.related_trainings_as_manager.length; i += 1) {
        if (this.getTraining(this.user.related_trainings_as_manager[i])) {
          this.trainingsAsManager.push(
            {
              id: this.user.related_trainings_as_manager[i],
              training: this.getTraining(this.user.related_trainings_as_manager[i]).name,
            },
          );
        }
      }
    },
    createTrainingsAsTutorArray() {
      this.$http
        .get(`/agenda/student_training/?tutors=${this.user.id}&query={id,student,student_profile,teachers,teachers_profiles,tutors,tutors_profiles,training{id,name},deleted,finished,start_date,end_date,option}&format=json`)
        .then((response) => {
          // need store trainings to use getter TrainingById
          this.trainingsAsTutor = response.data;
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
        });
    },
    forceRerender() {
      // Every time that forceRerender is called, our prop componentKey will change.
      // and Vue knows that it has to destroy the component and create a new one.
      // to not have searching values in others tables
      this.componentKey += 1;
    },
    seeTraining(trainingId) {
      this.$router.push({
        name: 'Training',
        params: {
          idTraining: trainingId,
          tab: 'parcours',
        },
      });
    },
    seeStudentTraining(trainingId, studentId, studentTraining) {
      this.$router.push({
        name: 'StudentTraining',
        params: {
          idStudent: studentId,
          idTraining: trainingId,
          idStudentTraining: studentTraining,
          from: this.$route.name,
          tab: 'parcours',
        },
      });
    },
    patchUser() {
      this.$http
        .patch(`/user/${this.user.user.id}/`, {
          email: this.form.email,
        }).then(() => {
          this.$buefy.toast.open({ message: 'Email modifié', type: 'is-success' });
          this.modify = false;
          this.initUser();
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
          this.endLoading();
          this.$buefy.dialog.alert({
            title: 'Mince...',
            message: 'Le mail n\'a pas pu être modifié.',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
          });
        });
    },
    patchRole() {
      this.$http
        .patch(`/profile/${this.user.id}/`, {
          edit_rights: this.form.rights,
        }).then(() => {
          this.$buefy.toast.open({ message: 'Droits modifiés', type: 'is-success' });
          this.modifyRights = false;
          this.initUser();
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
          this.endLoading();
          this.$buefy.dialog.alert({
            title: 'Mince...',
            message: 'Le rôle n\'a pas pu être modifié.',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
          });
        });
    },
    seeUserprofil(idtutor) {
      this.$router.push({
        name: 'UtilisateurDetail',
        params: {
          id: idtutor,
          step: 'infos',
        },
      });
      this.initUser();
    },
    findLastTraining(name) {
      const index = this.trainingsAsStudent.findIndex((element) => element.training.name === name);
      this.lastId = index;
      this.lastStudentTraining = this.trainingsAsStudent[index];
    },
  },
  created() {
    this.initUser();
  },
  mounted() {
    // this.initUser();
    if (this.$route.params.step) {
      this.gotoStep(this.$route.params.step);
    } else { this.gotoStep(this.tabs[0].slug); }
    window.addEventListener('popstate', this.handleHistoryChange);
  },
  destroyed() {
    window.removeEventListener('popstate', this.handleHistoryChange);
  },
  data() {
    return {
      componentKey: 0,
      trainingsAsStudent: [],
      trainingsAsTeacher: [],
      trainingsAsManager: [],
      trainingsAsTutor: [],
      user: [],
      step: 1,
      selected: new Date(),
      locale: 'fr-FR',
      currentTab: 1,
      crumbs: [
        {
          text: 'Utilisateurs',
          href: '/utilisateurs',
        },
      ],
      tabs: [
        {
          name: 'Infos',
          slug: 'infos',
          id: 1,
        },
        {
          name: 'Formations',
          slug: 'parcours',
          id: 2,
        },

      ],
      // variables for table
      isPaginated: true,
      isPaginationSimple: false,
      paginationPosition: 'bottom',
      sortIconSize: 'is-small',
      defaultSortDirection: 'asc',
      currentPage: 1,
      perPage: 25,
      trainingColumns: [
        {
          field: 'training',
          label: 'Nom',
          searchable: false,
          sortable: true,
          visible: true,
        },
        // {
        //   field: 'trainingId',
        //   label: 'Formation',
        //   width: '20',
        //   numeric: true,
        //   centered: true,
        //   searchable: false,
        //   sortable: true,
        //   visible: true,
        // },
        {
          field: 'studentTrainingId',
          label: 'Parcours apprenant',
          width: '200',
          numeric: true,
          centered: true,
          searchable: false,
          sortable: true,
          visible: true,
        },
      ],
      trainingTeacherColumns: [
        {
          field: 'training',
          label: 'Nom',
          searchable: true,
          sortable: true,
          visible: true,
        },
        {
          field: 'trainingId',
          label: 'Formation',
          width: '20',
          numeric: true,
          centered: true,
          searchable: false,
          sortable: true,
          visible: true,
        },
      ],
      trainingManagerColumns: [
        {
          field: 'training',
          label: 'Nom',
          searchable: true,
          sortable: true,
          visible: true,
        },
        {
          field: 'trainingId',
          label: 'Formation',
          width: '20',
          numeric: true,
          centered: true,
          searchable: false,
          sortable: true,
          visible: true,
        },
      ],
      trainingTutorColumns: [
        {
          field: 'training',
          label: 'Nom',
          searchable: true,
          sortable: true,
          visible: true,
        },
        {
          field: 'trainingId',
          label: 'Formation',
          width: '20',
          numeric: true,
          centered: true,
          searchable: false,
          sortable: true,
          visible: true,
        },
      ],
      trainingAllColumns: [
        {
          field: 'name',
          label: 'Nom',
          searchable: true,
          sortable: true,
          visible: true,
        },
        {
          field: 'trainingId',
          label: 'Formation',
          width: '20',
          numeric: true,
          centered: true,
          searchable: false,
          sortable: true,
          visible: true,
        },
      ],
      modify: false,
      form: {
        email: null,
      },
      modifyRights: false,
      lastStudentTraining: null,
      lastId: 0,
    };
  },
};
</script>

<style  lang="scss" scoped>
.icon {
  cursor: pointer;
}
.tag {
  padding: 5px 10px 5px 10px;
}
.username {
  margin-top: 20px;
}
.card_item {
  padding: 1rem;
}
.table tr.is-selected {
  color: #000 !important;
}
</style>
