<template>
  <div
    class="modal-card modal-users modal-card-body w-100"
    style="min-width: 700px"
  >
    <b-loading :is-full-page="isFullPage" v-model="isLoading"> </b-loading>
    <div class="columns h-100">
      <div
        class="
          column
          colStudentsSelected
          is-flex
          flex-column
          align-items-center
        "
      >
        <div class="is-flex align-items-center h-100">
          <div>
            <div class="has-text-centered">
              <b-icon icon="user" type="is-primary"></b-icon>
            </div>
            <div class="title-paragraph">Apprenants<br />concernés</div>
            <div v-for="student in checkedRows" :key="student.name">
              {{ student.student_profile }}
            </div>
            <b-button
              type="is-rounded is-outlined is-primary mt-5"
              @click="$parent.close()"
              >Modifier</b-button
            >
          </div>
        </div>
      </div>
      <div class="column col-right">
        <div class="has-text-right mb-3 w-100">
          <a @click="$parent.close()" class="ml-auto">
            <b-icon icon="close" type="is-primary"></b-icon>
          </a>
        </div>
        <div class="is-flex align-items-center first-line">
          <div class="title-paragraph mb-0">1. Sélectionnez le tuteur</div>
          <div>
            <b-select
              class="w-100 mt-5 mb-5 ml-6"
              placeholder="Choisissez un tuteur"
              v-model="tutorSelected"
            >
              <option
                v-for="tutor in this.training.tutors"
                :value="tutor"
                :key="tutor"
                class="w-100"
              >
                {{ getUser(tutor).user.first_name }}
                {{ getUser(tutor).user.last_name }}
                <!-- {{ tutor }} -->
              </option>
            </b-select>
          </div>
        </div>
        <section v-if="errored">
          <b-message type="is-danger">
            Nous sommes sincèrement désolés, nous nn'avons pu associer le(s)
            tuteur(s).
          </b-message>
        </section>
        <div class="w-100">
          <div class="title-paragraph">
            2. Sélectionnez les expériences auxquelles vous souhaiter l'associer
          </div>
          <div class="columns">
            <div class="column">
              <div
                class="card_item card_home btn"
                :class="choiceAll ? 'active' : ''"
                @click="(choiceAll = true) && (choiceSequences = false)"
              >
                Tout le parcours pédagogique
              </div>
            </div>
            <div class="column">
              <div
                class="card_item card_home btn"
                :class="choiceSequences ? 'active' : ''"
                @click="(choiceSequences = true) && (choiceAll = false)"
              >
                Certaines expériences uniquement
              </div>
            </div>
          </div>
          <div>
            <div v-if="choiceAll" class="w-100 has-text-centered">
              <button
                class="button is-success mt-4 ml-auto mr-auto mb-6"
                @click.prevent="linkTutor2Students"
                :disabled="!tutorSelected"
              >
                associer à toutes les expériences
              </button>
            </div>
            <div v-if="choiceSequences">
              <div class="is-flex justify-content-between">
                <div class="field has-text-left is-flex align-items-center">
                  Afficher

                  <b-switch
                    v-model="typesSelected"
                    :true-value="trueType"
                    :false-value="falseType"
                    class="ml-3"
                  >
                    {{ typesSelected }}
                  </b-switch>
                </div>
                <div>
                  <b-button
                    @click="selectAll"
                    v-if="selectAllSequences === false"
                    >tout sélectionner</b-button
                  >
                  <b-button @click="clearAll" v-if="selectAllSequences === true"
                    >tout désélectionner</b-button
                  >
                </div>
              </div>
              <div v-if="typesSelected === falseType">
                <div
                  animation="slide"
                  v-for="(period, index) of training.training_periods"
                  v-bind:key="index"
                >
                  <div class="card-header mb-2" role="button">
                    <div
                      class="bg-card is-flex bg-primary align-items-center"
                      :class="'periodType' + period.type"
                    >
                      <div
                        class="card-header-icon"
                        @click="collapseBlock('s' + index, 'icon' + index)"
                      >
                        <div class="icon-collapse" :id="'icon' + index"></div>
                      </div>
                      <p class="card-header-title">
                        <b-checkbox
                          v-model="selectedPeriod"
                          :native-value="period.id"
                          @input="selectPeriod(period.id)"
                          >{{ period.name }}
                        </b-checkbox>
                      </p>
                    </div>
                  </div>
                  <div class="card-content cardClosed" :id="'s' + index">
                    <div
                      v-for="sequence in order(period.training_sequences)"
                      v-bind:key="sequence.id"
                    >
                      <div
                        class="
                          card-edit-sequence
                          is-flex
                          align-items-center
                          w-100
                        "
                        v-if="
                          sequence.sequence_type === 1 ||
                          sequence.sequence_type === 2 ||
                          sequence.sequence_type === 5
                        "
                      >
                        <div
                          class="bloc-left is-flex mr-auto align-items-center"
                        >
                          <div
                            class="sequenceType situation"
                            type="situation"
                            :class="'sequenceType' + sequence.sequence_type"
                          >
                            <!-- {{  getSequenceType(sequence.sequence_type) }} -->
                            <b-checkbox
                              v-model="selectedSequences"
                              :native-value="sequence.id"
                            >
                              {{ sequence.sequence_type_label }}
                            </b-checkbox>
                          </div>
                          <div class="is-flex">
                            <span class="card-header-title">
                              {{ sequence.name }} {{ sequence.id }}</span
                            >
                          </div>
                        </div>
                        <div class="actions is-flex align-items-center">
                          <div
                            class="
                              is-flex
                              align-items-center
                              justify-content-between
                              row-responsive
                            "
                          >
                            <span class="date-period"> texte date </span>
                          </div>
                          <div
                            class="actions-buttons is-flex align-items-center"
                          >
                            <a
                              class="actions-icon"
                              @click.prevent="seeSequencedetail(sequence.id)"
                            >
                              <b-icon
                                icon="see"
                                size="is-medium"
                                type="is-black"
                              >
                              </b-icon>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="typesSelected === trueType">
                <b-collapse
                  class="card"
                  animation="slide"
                  v-for="(period, index) of training.training_periods"
                  v-bind:key="index"
                  v-bind:open="isOpen == index"
                  @open="isOpen = index"
                >
                  <div
                    slot="trigger"
                    slot-scope="props"
                    class="card-header"
                    role="button"
                  >
                    <div
                      class="bg-card is-flex bg-primary align-items-center"
                      :class="'periodType' + period.type"
                    >
                      <a class="card-header-icon">
                        <b-icon
                          v-bind:icon="props.open ? 'menu-down' : 'menu-up'"
                        >
                        </b-icon>
                      </a>

                      <p class="card-header-title">
                        <b-checkbox
                          v-model="selectedPeriod"
                          :native-value="period.id"
                          @input="selectPeriod(period.id)"
                          >{{ period.name }}
                        </b-checkbox>
                      </p>
                    </div>
                  </div>

                  <div class="card-content">
                    <div
                      v-for="sequence in period.training_sequences"
                      v-bind:key="sequence.id"
                    >
                      <div
                        class="
                          card-edit-sequence
                          is-flex
                          align-items-center
                          w-100
                        "
                      >
                        <div
                          class="bloc-left is-flex mr-auto align-items-center"
                        >
                          <div
                            class="sequenceType situation"
                            type="situation"
                            :class="'sequenceType' + sequence.sequence_type"
                          >
                            <!-- {{  getSequenceType(sequence.sequence_type) }} -->
                            <b-checkbox
                              v-model="selectedSequences"
                              :native-value="sequence.id"
                            >
                              {{ sequence.sequence_type_label }}
                            </b-checkbox>
                          </div>
                          <div class="is-flex">
                            <span class="card-header-title">
                              {{ sequence.name }}</span
                            >
                          </div>
                        </div>
                        <div class="actions is-flex align-items-center">
                          <div
                            class="
                              is-flex
                              align-items-center
                              justify-content-between
                              row-responsive
                            "
                          >
                            <span class="date-period"> texte date </span>
                          </div>
                          <div
                            class="actions-buttons is-flex align-items-center"
                          >
                            <a
                              class="actions-icon"
                              @click.prevent="seeSequencedetail(sequence.id)"
                            >
                              <b-icon
                                icon="see"
                                size="is-medium"
                                type="is-black"
                              >
                              </b-icon>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-collapse>
              </div>
              <div class="w-100 has-text-centered">
                <b-button
                  class="mt-5"
                  type="is-primary"
                  @click.prevent="addTutorToSequences"
                  :disabled="!tutorSelected || !selectedSequences.length"
                  >associer aux expériences
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';

export default {
  props: {
    training: {
      type: Object,
    },
    usersObject: {
      type: [Object, Array],
    },
    studenttrainingsObject: {
      type: [Object, Array],
    },
    checkedRows: {
      type: Array,
    },
  },
  computed: {
    ...mapGetters(['getUserById']),
  },
  methods: {
    gotoStep(numberStep) {
      this.step = numberStep;
    },
    order(array) {
      return _.orderBy(array, 'position');
    },
    clearAll() {
      this.selectedSequences = [];
      this.selectedPeriod = [];
      this.selectAllSequences = false;
    },
    // function to create student training if there not
    getUser(id) {
      return this.$store.getters.getUserById(id);
    },
    addTutorToSequences() {
      this.startLoading();
      this.studentsIds = [];
      for (let i = 0; i < this.checkedRows.length; i += 1) {
        this.studentsIds.push(
          this.checkedRows[i].id,
        );
      }
      this.$http
        .post('/agenda/tutor2studenttrainings/', {
          training_id: this.training.id,
          student_training_ids: this.studentsIds,
          tutor_id: this.tutorSelected,
          all_sequences: 0,
          sequences: this.selectedSequences,
        }).then(() => {
          // this.linkTutor2Students();
          this.$emit('messageFromChild');
          this.$parent.close();
          this.$buefy.notification.open('Les tuteurs ont bien été associés aux expériences');
          this.endLoading();
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
          this.endLoading();
        });
    },
    linkTutor2Students() {
      // this.ids.student_id
      this.startLoading();
      this.studentsIds = [];
      for (let i = 0; i < this.checkedRows.length; i += 1) {
        this.studentsIds.push(
          this.checkedRows[i].id,
        );
      }
      this.$http
        .post('/agenda/tutor2studenttrainings/', {
          training_id: this.training.id,
          tutor_id: this.tutorSelected,
          student_training_ids: this.studentsIds,
          all_sequences: 1,
          sequences: [],
        }).then(() => {
          this.$emit('messageFromChild');
          this.$parent.close();
          this.$buefy.notification.open('Les tuteurs ont bien été associés aux parcours apprenant');
          this.endLoading();
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
          this.endLoading();
        });
    },
    selectPeriod(idPeriod) {
      const indexPeriod = _.filter(this.training.training_periods, {
        id: idPeriod,
      });
      for (let i = 0; i < indexPeriod[0].training_sequences.length; i += 1) {
        if (this.typesSelected === this.falseType) {
          const seqType = indexPeriod[0].training_sequences[i].sequence_type;
          if (seqType === 1 || seqType === 2 || seqType === 5) {
            this.selectedSequences.push(
              indexPeriod[0].training_sequences[i].id,
            );
          }
        } else {
          this.selectedSequences.push(
            indexPeriod[0].training_sequences[i].id,
          );
        }
      }
    },
    selectAll() {
      if (this.selectAllSequences === false) {
        for (let i = 0; i < this.training.training_periods.length; i += 1) {
          this.selectPeriod(this.training.training_periods[i].id);
          this.selectedPeriod.push(
            this.training.training_periods[i].id,
          );
        }
        this.selectAllSequences = true;
      } else { this.clearAll(); }
      /// _.without([2, 1, 2, 3], 1, 2);
    },
    collapseBlock(id, icon) {
      const element = document.getElementById(id);
      element.classList.toggle('blockOpen');
      // if (this.contentVisible === false) {
      //   this.contentVisible = id;
      // } else { this.contentVisible = false; }
      const iconOpen = document.getElementById(icon);
      iconOpen.classList.toggle('iconOpen');
    },
    startLoading() {
      this.isLoading = true;
    },
    endLoading() {
      this.isLoading = false;
    },
  },
  mounted() {
    // this.training = this.trainingObject;
    this.studentrainings = this.studentrainingsObject;
    this.users = this.usersObject;
  },
  data() {
    return {
      isLoading: false,
      isFullPage: false,
      // training: [Object, Array],
      studenttraining: [Object, Array],
      users: [Object, Array],
      step: 1,
      isSwitched: true,
      isSwitchedCustom: 'Non',
      isOpen: 1,
      selectedSequences: [],
      selectedPeriod: [],
      tutorSelected: null,
      studentsIds: [],
      selectAllSequences: false,
      trueType: 'toutes les expériences',
      falseType: 'les expériences en entreprise seulement',
      typesSelected: 'les expériences en entreprise seulement',
      contentVisible: false,
      errored: false,
      selection: false,
      choiceSequences: false,
      choiceAll: false,
    };
  },
};
</script>

<style lang="scss">
.cardClosed {
  display: none;
  opacity: 0;
}
.blockOpen {
  display: block;
  opacity: 1;
  transition: all 2s linear;
}
.iconOpen {
}
</style>
