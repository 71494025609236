 <template>
  <div>
    <!-- <b-loading
      :is-full-page="isFullPage"
      v-model="isLoading"
      :can-cancel="true"
    >
    </b-loading> -->
    <div class="columns mt-5">
      <div class="column">
        <div
          class="todo card card_item is-flex mb-0 is-clickable"
          @click="gotoNow()"
        >
          <b-loading
            v-model="isSmallLoading"
            :can-cancel="true"
            :is-full-page="false"
          ></b-loading>
          <div class="is-flex w-100">
            <div class="head-card is-flex">
              <div class="m-auto has-text-centered">
                <div class="title mb-0">A faire</div>
                <span class="has-text-primary"> en ce moment</span>
              </div>
            </div>
            <div class="content-card has-text-centered">
              <div class="m-auto w-100">
                <!-- <div class="total mt-auto">{{ actualStep[0].done }}</div> -->
                <div class="name">{{ this.currentStep.part_name }}</div>
              </div>

              <div class="b-steps is-small w-100 mt-4">
                <nav class="steps is-rounded">
                  <ul class="step-items">
                    <li
                      class="step-item is-previous"
                      v-for="validation in currentStep.validations"
                      :key="validation.id"
                    >
                      <a class="step-link is-clickable">
                        <div class="step-marker mb-3">
                          <img
                            :src="require(`@/assets/img/steps/status6.svg`)"
                            alt=""
                            class="img_status mx-auto"
                          />
                        </div>
                        <div class="step-details">
                          <span class="step-title"> {{ validation.name }}</span>
                        </div>
                      </a>
                    </li>
                  </ul>
                </nav>

                <!---->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="caption is-flex mb-2">
      <div class="me mr-2"></div>
      Etapes dans lesquelles je dois intervenir
    </div>
    <div class="caption is-flex align-items-center">
      <div class="caption-item">
        <img
          :src="require(`@/assets/img/steps/status3.svg`)"
          alt=""
          class="img_status"
        />
        effectué
      </div>
      <div class="caption-item">
        <img
          :src="require(`@/assets/img/steps/status-late.svg`)"
          alt=""
          class="img_status"
        />
        retard
      </div>
      <div class="caption-item">
        <img
          :src="require(`@/assets/img/steps/status6.svg`)"
          alt=""
          class="img_status"
        />
        en cours
      </div>
      <div class="caption-item">
        <div class="later">...</div>
        à faire plus tard
      </div>
      <div class="caption-item">
        <img
          :src="require(`@/assets/img/steps/status7.svg`)"
          alt=""
          class="img_status"
        />
        litige
      </div>
    </div>
    <div v-if="trainingSteps.data">
      <div
        ref="stepsContent"
        id="stepsContent"
        v-if="trainingSteps.data[0]"
        class="table-stepsbooklet"
      >
        <div
          class="line-now is-clickable"
          :style="
            'left:' +
            nowPosition +
            'px; height :' +
            heightLine +
            'px; z-index:0;'
          "
        ></div>
        <div class="table-wrapper has-mobile-cards">
          <table class="table w-100">
            <!---->
            <tbody>
              <!----><!---->
              <tr draggable="false" class="">
                <!---->
                <td
                  class="checkbox-cell"
                  style="width: 213px; min-width: 236px"
                >
                  <b class="is-clickable"> Etapes du livret</b>
                </td>
                <td data-label="Livret" class="">
                  <div class="bookletSteps is-flex">
                    <div
                      v-for="(waypoint, index) in trainingSteps.data[0].data"
                      :key="waypoint.id"
                      class="bookletStep"
                    >
                      <router-link
                        :to="
                          '/formations/' +
                          $route.params.idTraining +
                          '/suivi/stepToValidate' +
                          '/' +
                          waypoint.id
                        "
                        @click.native="gotoNow()"
                      >
                        <b-tooltip
                          :label="waypoint.part_name"
                          position="is-bottom"
                          multilined
                          type="is-primary is-light"
                        >
                          <div v-if="currentUser.role">
                            <div
                              v-if="waypoint.status === 2"
                              alt=""
                              class="done"
                              :class="
                                waypoint.editable.includes(currentUser.role)
                                  ? 'me'
                                  : ''
                              "
                            >
                              {{ index + 1 }}
                            </div>
                            <div
                              v-else-if="waypoint.status === 1"
                              alt=""
                              class="now"
                              :class="
                                waypoint.editable.includes(currentUser.role)
                                  ? 'me'
                                  : ''
                              "
                            >
                              {{ index + 1 }}
                            </div>
                            <div
                              v-else-if="waypoint.status === 0"
                              class="later"
                              :class="
                                waypoint.editable.includes(currentUser.role)
                                  ? 'me'
                                  : ''
                              "
                            >
                              {{ index + 1 }}
                            </div>
                          </div>
                        </b-tooltip>
                      </router-link>
                    </div>
                  </div>
                </td>

                <!---->
              </tr>
              <!----><!----><!---->
            </tbody>
            <!---->
          </table>
          <!---->
        </div>
        <b-table
          class="tableStepsbooklet"
          :data="trainingSteps.data"
          checkable
          :checkbox-position="'left'"
          :checkbox-type="'is-primary'"
          :default-sort="['name', 'asc']"
          :checked-rows.sync="checkedRows"
          @input="check"
        >
          <template v-for="column in columns">
            <b-table-column :key="column.id" v-bind="column">
              <template
                v-if="column.searchable && !column.numeric"
                slot="searchable"
                slot-scope="props"
              >
                <b-input
                  v-model="props.filters[props.column.field]"
                  placeholder="Rechercher..."
                  icon="search"
                  size="is-small"
                />
              </template>
              <template
                v-slot="props"
                v-if="column.field === 'name'"
                style="min-width: 161px"
                class="col-name"
              >
                <router-link
                  :to="
                    '/formation-apprenant/' +
                    $route.params.idTraining +
                    '/' +
                    props.row.student_id +
                    '/' +
                    props.row.student_training_id +
                    '/waypoint'
                  "
                >
                  <b class="is-clickable">
                    {{ props.row[column.field] }}</b
                  ></router-link
                >
              </template>
              <template v-slot="props" v-else-if="column.field === 'booklet'">
                <div class="bookletSteps is-flex">
                  <div
                    v-for="waypoint in props.row.data"
                    :key="waypoint.id"
                    class="bookletStep is-clickable"
                  >
                    <router-link
                      v-if="waypoint.type === 'waypoint'"
                      :to="
                        '/validation-edition/' +
                        props.row.student_training_id +
                        '/' +
                        $route.params.idTraining +
                        '/' +
                        props.row.student_id +
                        '/' +
                        waypoint.id
                      "
                    >
                      <b-tooltip
                        :label="waypoint.part_name"
                        position="is-bottom"
                        multilined
                        type="is-primary is-light"
                      >
                        <img
                          v-if="waypoint.user_status_label == 'Effectué'"
                          :src="require(`@/assets/img/steps/status3.svg`)"
                          alt=""
                          class="img_status"
                        />
                        <img
                          v-else-if="waypoint.user_status_label === 'En retard'"
                          :src="require(`@/assets/img/steps/status-late.svg`)"
                          alt=""
                          class="img_status"
                        />
                        <img
                          v-else-if="waypoint.user_status_label === 'En cours'"
                          :src="require(`@/assets/img/steps/status6.svg`)"
                          alt=""
                          class="img_status"
                        />
                        <img
                          v-else-if="waypoint.user_status_label === 'Litige'"
                          :src="require(`@/assets/img/steps/status7.svg`)"
                          alt=""
                          class="img_status"
                        />
                        <div
                          class="later"
                          v-else-if="waypoint.user_status_label === 'A faire'"
                        ></div>
                        <div class="later" v-else></div>
                      </b-tooltip>
                    </router-link>
                    <router-link
                      v-else
                      :to="
                        '/livret/' +
                        props.row.student_training_id +
                        '/' +
                        $route.params.idTraining +
                        '/' +
                        props.row.student_id +
                        '/' +
                        waypoint.id
                      "
                    >
                      <b-tooltip
                        :label="waypoint.part_name"
                        position="is-bottom"
                        multilined
                        type="is-primary is-light"
                      >
                        <img
                          v-if="waypoint.user_status_label == 'Effectué'"
                          :src="require(`@/assets/img/steps/status3.svg`)"
                          alt=""
                          class="img_status"
                        />
                        <img
                          v-else-if="waypoint.user_status_label === 'En retard'"
                          :src="require(`@/assets/img/steps/status-late.svg`)"
                          alt=""
                          class="img_status"
                        />
                        <img
                          v-else-if="waypoint.user_status_label === 'En cours'"
                          :src="require(`@/assets/img/steps/status6.svg`)"
                          alt=""
                          class="img_status"
                        />
                        <div
                          class="later"
                          v-else-if="waypoint.user_status_label === 'A faire'"
                        ></div>
                      </b-tooltip>
                    </router-link>
                  </div>
                </div>
              </template>
              <template
                v-slot="props"
                v-else-if="column.field === 'id_student_training'"
              >
                <div>
                  <router-link
                    :to="
                      '/formation-apprenant/' +
                      $route.params.idTraining +
                      '/' +
                      props.row.student_id +
                      '/' +
                      props.row.student_training_id +
                      '/waypoint'
                    "
                  >
                    <b-icon icon="see"></b-icon
                  ></router-link>
                </div>
              </template>
            </b-table-column>
          </template>
        </b-table>
      </div>
      <div v-else class="mt-4">
        <b-message type="is-warning"
          >Il n'y a pas encore de données ou d'utilisateur
          enregistré.</b-message
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';

export default {
  props: {
    typeoftraining: {
      type: String,
    }, // framework, training or studenttraining
    objectId: {
      type: Number,
    },
    contentWidth: {
      type: Number,
    },
  },
  components: {
  },
  filters: {
    day(date) {
      return moment(date).format('DD/MM/YYYY');
    },
  },
  data() {
    return {
      today: new Date(),
      checkedRows: [],
      isLoading: false,
      isFullPage: false,
      // trainingSteps: [],
      activeTab: 'all',
      columns: [
        {
          field: 'name',
          label: 'Nom',
          width: '185',
          searchable: false,
          sortable: true,
          visible: true,
        },
        {
          field: 'booklet',
          label: 'Livret',
          width: 'auto',
          searchable: false,
          sortable: false,
          visible: true,
        },
        {
          field: 'id_student_training',
          label: 'Détail',
          width: '50',
          centered: true,
          searchable: false,
          sortable: false,
          visible: true,
        },
        {
          field: 'clear',
          label: '',
          width: '10',
          centered: true,
          searchable: false,
          sortable: false,
          visible: true,
        },

      ],
      currentStep: [],
      nowPosition: 'hello',
      heightLine: 0,
      isSmallLoading: false,
      // trainingSteps: [],
    };
  },
  computed: {
    ...mapState('auth', ['currentUser']),
    trainingSteps() {
      return this.$store.state.trainingSteps;
    },
  },
  methods: {
    startLoading() {
      this.isLoading = true;
    },
    startSmallLoading() {
      this.isSmallLoading = true;
    },
    endSmallLoading() {
      this.isSmallLoading = false;
    },
    endLoading() {
      this.isLoading = false;
    },
    initSteps() {
      this.startLoading();
      this.startSmallLoading();
      this.$http
        .get(`/trainings/${this.$route.params.idTraining}/?query={students}&format=json`)
        .then((response) => {
          if (this.trainingSteps.length > 0) {
            if (this.trainingSteps.data[0].data.length === response.data.length) {
              this.endLoading();
            }
          }
        });
      this.$http
        .get(`/get_training_booklet_validations/${this.$route.params.idTraining}`)
        .then((response) => {
          // this.trainingSteps = response.data;
          this.$store.commit('GET_STEPS', response.data);
          this.endSmallLoading();
          // if (this.trainingSteps) {
          //   this.endLoading();
          // }
          this.initCurrentStep();
        });
    },
    initCurrentStep() {
      // this.endLoading();
      this.$http
        .get(`/get_training_booklet_validations/${this.$route.params.idTraining}?step=current`)
        .then((response) => {
          this.currentStep = response.data;
          const position = (step) => step.id === this.currentStep.data[0].data[0].id;
          const StepPosition = this.trainingSteps.data[0].data.findIndex(position);
          this.nowPosition = (StepPosition + 1) * 31 + 217;
          this.matchHeight();
          this.endLoading();
        });
    },
    matchHeight() {
      const stepsContent = document.getElementById('stepsContent');
      if (stepsContent) { this.heightLine = stepsContent.clientHeight + 100; }
      this.endLoading();
    },
    gotoNow() {
      this.$emit('tabChange', 'stepToValidate');
    },
    seeStudentTraining(studentId, studentTraining) {
      this.$router.push({
        name: 'StudentTraining',
        params: {
          idStudent: studentId,
          idTraining: this.$route.params.idTraining,
          idStudentTraining: studentTraining,
          tab: 'waypoint',
        },
      });
    },
    check() {

    },
    gotoPart(partId, studentId, studentTraining, type) {
      if (type === 'waypoint') {
        this.$router.push({
          name: 'WaypointitiiEdit',
          params: {
            idTraining: this.$route.params.idTraining,
            idStudent: studentId,
            idStudentTraining: studentTraining,
            simple_booklet_part_validation: partId,
          },
        });
      } else {
        this.$router.push({
          name: 'BookletPartRead',
          params: {
            idTraining: this.$route.params.idTraining,
            idStudent: studentId,
            idStudentTraining: studentTraining,
            idPart: partId,
          },
        });
      }
    },
  },
  created() {
    this.initSteps();
    this.today = moment().format();
  },
};

</script>
<style scoped lang="scss">
.step-content {
  padding: 0 !important;
}
.bookletSteps img {
  width: 20px;
  height: 20px;
}
.bookletStep {
  display: flex;
  align-items: center;
}
.bookletStep::after {
  content: "-";
  font-size: 1.5rem;
  color: #21c697;
}
.img_status {
  margin: 0rem;
}
.bookletStep:last-child::after {
  content: unset;
}
#stepsContent {
  overflow: auto;
  position: relative;
  z-index: 1;
}
#stepsContent .table-wrapper {
  overflow: unset !important;
}
td[data-label="Nom"] {
  width: 185px;
}
.loading-overlay {
  position: absolute;
}
</style>
