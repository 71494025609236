<template>
  <div class="modalbox modalActivity modalStep">
    <b-loading :is-full-page="isFullPage" v-model="isLoading"> </b-loading>
    <div class="is-flex bigmodal">
      <div v-if="!$route.params.from">
        <router-link
          v-if="studentactivity.sequence"
          class="mr-auto btn-previous"
          :to="
            '/sequence-edition/' +
            $route.params.idTraining +
            '/' +
            studentactivity.sequence +
            '/' +
            $route.params.idStudentTraining
          "
        >
          <img :src="require('@/assets/img/arrows/previous.svg')" />
        </router-link>
      </div>
      <div v-else-if="$route.params.from == 'WaypointEdit'">
        <router-link
          class="mr-auto btn-previous"
          :to="{
            name: 'WaypointEdit',
            params: {
              idTraining: $route.params.idTraining,
              idStudentTraining: $route.params.idStudentTraining,
              idStudent: $route.params.idStudent,
              from: $route.name,
            },
          }"
        >
          <img :src="require('@/assets/img/arrows/previous.svg')" />
        </router-link>
      </div>
      <div v-else-if="$route.params.from == 'WaypointRead'">
        <router-link
          class="mr-auto btn-previous"
          :to="{
            name: 'WaypointRead',
            params: {
              idTraining: $route.params.idTraining,
              idStudentTraining: $route.params.idStudentTraining,
              idStudent: $route.params.idStudent,
              from: $route.name,
            },
          }"
        >
          <img :src="require('@/assets/img/arrows/previous.svg')" />
        </router-link>
      </div>
      <div v-else-if="$route.params.from == 'Dashboard'">
        <router-link class="mr-auto btn-previous" :to="'/'">
          <img :src="require('@/assets/img/arrows/previous.svg')" />
        </router-link>
      </div>
      <div v-else-if="$route.params.from == 'Planning'">
        <router-link class="mr-auto btn-previous" :to="'/planning/'">
          <img :src="require('@/assets/img/arrows/previous.svg')" />
        </router-link>
      </div>
      <div v-else>
        <router-link
          class="mr-auto btn-previous"
          :to="{ name: $route.params.from }"
        >
          <img :src="require('@/assets/img/arrows/previous.svg')" />
        </router-link>
      </div>
    </div>
    <!-- eslint-disable max-len-->
    <div
      class="container title-modal is-flex justify-content-between is-max-widescreen is-align-items-center"
    >
      <div class="bloc-left-title">
        <div class="mb-2">
          <div v-if="currentUser.role === 'apprenant'">
            <router-link
              v-if="studentactivity.student_id"
              :to="
                '/formation-apprenant/' +
                $route.params.idTraining +
                '/' +
                studentactivity.student_id +
                '/' +
                $route.params.idStudentTraining
              "
            >
              <b-button type="is-text" class=""
                >Mon parcours</b-button
              > </router-link
            >/
            <router-link
              class="button is-text"
              :to="
                '/sequence-edition/' +
                $route.params.idTraining +
                '/' +
                $route.params.idSequence +
                '/' +
                $route.params.idStudentTraining
              "
              >{{ sequenceName }}</router-link
            >
          </div>
          <div v-else>
            <router-link
              :to="
                '/formation-apprenant/' +
                $route.params.idTraining +
                '/' +
                studentactivity.student_id +
                '/' +
                $route.params.idStudentTraining
              "
            >
              <!-- <span class="button is-text pl-0">Parcours :</span> -->
              <span class="button is-text pl-0" v-if="studentactivity.student"
                >Parcours {{ studentactivity.student }}</span
              ></router-link
            >

            <router-link
              class="button is-text"
              :to="
                '/sequence-edition/' +
                $route.params.idTraining +
                '/' +
                $route.params.idSequence +
                '/' +
                $route.params.idStudentTraining
              "
              >/ {{ sequenceName }}</router-link
            >
          </div>
        </div>
        <div
          class="title-page long-title mb-1"
          v-if="studentactivity.legacy_activity"
        >
          <span v-if="studentactivity.legacy_activity.legacy_activity">{{
            studentactivity.legacy_activity.legacy_activity.name
          }}</span>
          <span v-else-if="studentactivity.legacy_activity.name_override">{{
            studentactivity.legacy_activity.name_override
          }}</span>
        </div>
        <div class="title-page long-title mb-1" v-else>Activité</div>
        <div v-if="sequenceSubtitle" class="mb-2">
          <span
            v-html="sequenceSubtitle.substr(0, 144)"
            class="parenthesis subtitleSequence"
          ></span>
        </div>
        <div class="">
          <span v-if="studentactivity.status === 6" class="tag">
            <b-tag type="is-primary">En cours</b-tag>
          </span>
          <span v-if="studentactivity.status === 3" class="tag status3">
            Effectuée
          </span>
          <span v-if="studentactivity.status === 4" class="tag status3">
            Validée
          </span>
          <span v-if="studentactivity.status === 5" class="tag status5">
            Désactivée
          </span>
          <span v-if="studentactivity.status === 7" class="tag status7">
            Litige
          </span>
          <div v-if="!without_dates">
            <span v-if="studentactivity.status === 0" class="tag status0">
              À programmer
            </span>
            <span v-if="studentactivity.status === 1" class="tag status1">
              Programmée
            </span>
            <span v-if="studentactivity.status === 5" class="tag status5">
              Désactivée
            </span>
          </div>
          <span
            v-if="studentactivity.status === 0 && without_dates"
            class="tag statusInProgress"
          >
            À faire
          </span>
        </div>
      </div>

      <!-- <div class="bloc-buttons">
        <div class="mb-1">
          <b-button
            type="is-success is-rounded"
            :disabled="studentactivity.status != 3"
            @click="addProgression"
            >Vous avez progressé ?</b-button
          >
        </div>
      </div> -->
    </div>
    <hr />
    <div id="activities" class="container contentSequence">
      <!-- help button -->
      <div id="helpTextActivity">
        <div
          class="help-btn"
          v-if="
            currentUser.role === 'apprenant' &&
            studentactivity.presentation_apprenant
          "
        >
          <div v-if="studentactivity.legacy_activity">
            <AppHelpMessage
              v-if="
                studentactivity.legacy_activity.presentation_apprenant_override
              "
              title="Comment rédiger ?"
              :message="
                studentactivity.legacy_activity.presentation_apprenant_override
              "
              textBtn="Comment rédiger  ?"
            />
            <AppHelpMessage
              v-else
              title="Comment rédiger  ?"
              :message="studentactivity.presentation_apprenant"
              textBtn="Comment rédiger  ?"
            />
          </div>
        </div>
        <div
          class="help-btn"
          v-else-if="
            currentUser.role === 'tuteur' && studentactivity.presentation_tuteur
          "
        >
          <div v-if="studentactivity.legacy_activity">
            <AppHelpMessage
              v-if="
                studentactivity.legacy_activity.presentation_tuteur_override
              "
              title="Comment rédiger  ?"
              :message="
                studentactivity.legacy_activity.presentation_tuteur_override
              "
              textBtn="Comment rédiger ?"
            />
            <AppHelpMessage
              v-else
              title="Comment rédiger ?"
              :message="studentactivity.presentation_tuteur"
              textBtn="Comment rédiger ?"
            />
          </div>
        </div>
        <div
          class="help-btn"
          v-else-if="
            (currentUser.role === 'manager' ||
              currentUser.role === 'coordinateur' ||
              currentUser.role === 'formateur') &&
            studentactivity.presentation_manager
          "
        >
          <div v-if="studentactivity.legacy_activity">
            <AppHelpMessage
              v-if="
                studentactivity.legacy_activity.presentation_manager_override
              "
              title="Comment rédiger votre activité ?"
              :message="
                studentactivity.legacy_activity.presentation_manager_override
              "
              textBtn="Comment rédiger votre activité ?"
            />
            <AppHelpMessage
              v-else
              title="Comment rédiger votre activité ?"
              :message="studentactivity.presentation_manager"
              textBtn="Comment rédiger votre activité ?"
            />
          </div>
        </div>
      </div>
      <!-- end help button -->
      <b-message type="is-warning" v-if="!studentactivity.editable"
        >Cette activité n'est pas encore éditable. Pour pouvoir l'éditer, la
        précédente activité doit avoir été effectuée.</b-message
      >
      <!-- dates message-->
      <div v-if="!without_dates">
        <div class="card_event mt-5 is-flex align-items-center pb-5 pt-3 mb-2">
          <!---------------------- dates read for all users -------------------- -->
          <div class="w-100">
            <div
              class="columns is-desktop is-vcentered mt-0"
              v-if="editDates === false"
            >
              <div class="column is-narrow">
                <h1 class="title-paragraph mb-0">
                  Dates de l'activité programmée
                </h1>
                <div v-if="studentactivity.start_date">
                  <div>
                    du {{ studentactivity.start_date | day }} à
                    {{ studentactivity.start_date | hour }}
                  </div>
                  <div>
                    au {{ studentactivity.end_date | day }} à
                    {{ studentactivity.end_date | hour }}
                  </div>
                </div>
                <div v-else>non définies</div>
              </div>
              <div class="column is-narrow clear"></div>
              <div class="column is-narrow">
                <div class="subtitle-paragraph mb-0 has-text-left">
                  lieu de rendez-vous
                </div>
                <span v-if="studentactivity.location">{{
                  studentactivity.location
                }}</span>
                <span v-else>indéfini</span>
              </div>
              <div class="column is-narrow is-flex align-items-center ml-auto">
                <div v-if="studentactivity.status === 1">
                  <b-button
                    v-if="
                      (currentUser.role === 'manager' && sequenceType === 1) ||
                      (currentUser.role === 'manager' && sequenceType === 7) ||
                      currentUser.role === 'superviseur' ||
                      currentUser.role === 'coordinateur'
                    "
                    type="is-primary"
                    @click="editDates = !editDates"
                    class="mr-5 ml-2"
                    >replanifier</b-button
                  >
                </div>
                <div v-else-if="studentactivity.status === 0">
                  <b-button
                    v-if="
                      (currentUser.role === 'manager' && sequenceType === 1) ||
                      (currentUser.role === 'manager' && sequenceType === 7) ||
                      currentUser.role === 'superviseur' ||
                      currentUser.role === 'coordinateur'
                    "
                    type="is-primary"
                    @click="editDates = !editDates"
                    class="mr-5 ml-2"
                    >planifier</b-button
                  >
                </div>
                <div v-else class="ml-auto"></div>
                <!-- <div class="mr-3">
                  <span class="tag status1" v-if="studentactivity.status === 0"
                    >À programmer</span
                  >
                  <span class="tag status1" v-if="studentactivity.status === 1"
                    ><span v-if="studentactivity.start_date < today"
                      >en cours</span
                    ><span v-else>Programmée</span></span
                  >
                  <span class="tag status3" v-if="studentactivity.status === 3"
                    >Effectuée</span
                  >
                </div> -->
                <!-- // manager can change dates on afest sequence
                // manager can change dates on "mise en situation tutorée" sequence -->
              </div>

              <!---------------------- modification dates and status --------------------
           button modify only for manager, techers, coordinateur,, superviseur -->
              <div class="column is-narrow" v-if="studentactivity.status === 1">
                <!-- studentactivity.status != 3 need to verify if activity is made -->
                <div class="is-flex align-items-center">
                  <div
                    v-if="
                      currentUser.role === 'superviseur' ||
                      currentUser.role === 'coordinateur'
                    "
                  >
                    <b-switch
                      v-model="form.newstatus"
                      @input="changeStatus"
                      true-value="3"
                      false-value="1"
                      type="is-success"
                    >
                      Activité effectuée
                    </b-switch>
                  </div>
                </div>
              </div>
            </div>
            <!---------------------- dates edition for manager or CFA profiles ------------------>
            <div class="w-100 pt-5" v-else>
              <h1 class="title-paragraph">Dates de l'activité</h1>
              <div class="subittle-paragraph">
                Renseignez les dates réelles de cette activité, en accord avec
                tous les intervenants
              </div>
              <div class="columns is-desktop is-vcentered mt-2">
                <div class="column is-narrow">
                  <b-field label="Date de début">
                    <b-datetimepicker
                      v-model="studentactivity.start_date"
                      :locale="locale"
                      :datepicker="{ firstDayOfWeek: 1, showWeekNumber: true }"
                      icon="calendar-today"
                      trap-focus
                      inline
                    >
                    </b-datetimepicker>
                  </b-field>
                </div>
                <div class="column is-narrow">
                  <b-field label="Date de fin">
                    <b-datetimepicker
                      v-model="studentactivity.end_date"
                      :show-week-number="showWeekNumber"
                      :locale="locale"
                      icon="calendar-today"
                      trap-focus
                      inline
                      :datepicker="{ firstDayOfWeek: 1, showWeekNumber: true }"
                    >
                    </b-datetimepicker>
                  </b-field>
                </div>
                <div class="column">
                  <b-field
                    label="lieu de rendez-vous"
                    type="textarea"
                    class="mb-0"
                  >
                    <b-input
                      style="min-height: 4em"
                      type="textarea"
                      v-model="studentactivity.location"
                    ></b-input
                  ></b-field>
                </div>
              </div>
              <div class="mb-4">
                <b-button @click="setdata" type="is-rounded mr-2"
                  >Annuler</b-button
                >
                <b-button
                  :disabled="
                    !studentactivity.start_date || !studentactivity.end_date
                  "
                  @click="patchOneActivity(studentactivity.id)"
                  type="is-primary is-rounded mr-3"
                  rounded
                  >enregistrer</b-button
                >
              </div>
            </div>
          </div>
        </div>

        <!--end dates-->
        <b-message
          type="is-info"
          v-if="
            started === false && late === false && studentactivity.status === 0
          "
        >
          <span v-if="studentactivity.rights.includes('w_' + currentUser.role)"
            ><b>Cette activité n'a pas encore été programmée</b>, vous pourrez
            l'éditer prochainement. Ci-dessous, voici ce que vous aurez à
            rédiger.</span
          >
          <span
            v-else-if="studentactivity.rights.includes('a_' + currentUser.role)"
            ><b>Cette activité n'a pas encore été programmée, </b> vous n'aurez
            pas le droit de lire son contenu. Cette activité reste
            confidentielle.</span
          >
          <span v-else
            ><b>Cette activité n'a pas encore été programmée</b>, vous pourrez
            la consulter prochainement.
          </span></b-message
        >
        <b-message
          type="is-info"
          v-if="
            started === false && late === false && studentactivity.status === 1
          "
        >
          <span v-if="studentactivity.rights.includes('w_' + currentUser.role)"
            ><b>Cette activité n'a pas encore commencé</b>, vous pourrez
            l'éditer prochainement. Ci-dessous, voici ce que vous aurez à
            rédiger.</span
          >
          <span
            v-else-if="studentactivity.rights.includes('a_' + currentUser.role)"
            ><b>Cette activité n'a pas encore commencé, </b> vous n'aurez pas le
            droit de lire son contenu. Cette activité reste
            confidentielle.</span
          >
          <span v-else
            ><b>Cette activité n'a pas encore commencé</b>, vous pourrez la
            consulter prochainement.
          </span></b-message
        >
        <b-message
          type="is-info"
          v-if="
            started === true &&
            edit === true &&
            studentactivity.status != 3 &&
            late === false
          "
          ><b
            ><span
              v-if="studentactivity.rights.includes('w_' + currentUser.role)"
            >
              Cette activité a commencé, vous pouvez l'éditer</span
            >
            <span
              v-else-if="
                studentactivity.rights.includes('a_' + currentUser.role)
              "
              ><b>Cette activité a commencé, </b> mais vous n'avez pas le droit
              de lire son contenu. Cette activité reste confidentielle.</span
            >
            <span v-else
              ><b>Cette activité a commencé</b>, vous pouvez la consulter.
            </span></b
          ><br
        /></b-message>
        <div v-if="studentactivity.status === 3">
          <b-message
            type="is-success"
            v-if="studentactivity.rights.includes('w_' + currentUser.role)"
          >
            <b>Vous avez bien effectué cette activité, </b>vous pouvez consulter
            son contenu
          </b-message>
          <b-message
            type="is-success"
            v-else-if="studentactivity.rights.includes('a_' + currentUser.role)"
          >
            <span
              ><b>Cette activité a bien été effectuée, </b> mais vous n'avez pas
              le droit de lire son contenu. Cette activité reste
              confidentielle.</span
            >
          </b-message>
          <b-message type="is-success" v-else
            ><b
              >Cette activité a bien été effectuée, vous pouvez consulter son
              contenu</b
            ><br />Cette activité est en lecture seule pour vous.
          </b-message>
        </div>
        <b-message
          type="is-info"
          v-if="
            started === true && edit === false && studentactivity.status != 3
          "
          ><b>Cette activité a commencé mais n'a pas encore été effectuée.</b
          ><br />Cette activité est en lecture seule pour vous.
        </b-message>
        <b-message
          type="is-danger"
          v-if="
            late === true &&
            studentactivity.status != 3 &&
            studentactivity.rights.includes('w_' + currentUser.role)
          "
          ><b
            >Attention, vous êtes en retard, vous auriez déjà dû effectuer cette
            activité.</b
          >
        </b-message>
        <b-message
          type="is-danger"
          v-if="
            late === true &&
            studentactivity.status != 3 &&
            studentactivity.rights.includes('r_' + currentUser.role)
          "
          ><b
            >Attention, cette activité est en retard, elle aurait déjà dû être
            effectuée.</b
          >
        </b-message>
        <b-message
          type="is-warning"
          v-if="
            late === true &&
            studentactivity.status != 3 &&
            studentactivity.rights.includes('a_' + currentUser.role)
          "
        >
          <b>Cette activité a bien été effectuée, </b> mais vous n'avez pas le
          droit de lire son contenu. Cette activité reste confidentielle.
        </b-message>
      </div>
      <div v-else>
        <div v-if="studentactivity.rights">
          <b-message
            type="is-info"
            v-if="
              studentactivity.rights.includes('r_' + currentUser.role) ||
              (currentUser.role === 'formateur' &&
                studentactivity.rights.includes('w_teacher') === false) ||
              (currentUser.role === 'coordinateur' &&
                studentactivity.rights.includes('w_teacher') === false)
            "
            >Cette activité est en lecture seule pour vous.
          </b-message>
        </div>
      </div>

      <div
        class="w-100 has-text-centered mb-4"
        v-if="sequenceType === 3 && sequenceUrl"
      >
        <a :href="sequenceUrl" target="_blank">
          <div
            class="w-100 mb-5 is-clickable card_item card_home btn externalLink"
          >
            <div class="title-paragraph">Voir le cours</div>
          </div></a
        >
      </div>
      <div v-if="studentactivity.rights">
        <div
          class="content"
          v-if="!studentactivity.rights.includes('a_' + currentUser.role)"
        >
          <div>
            <div
              class="card_activity"
              v-for="(stepActivity, i) in order(
                studentactivity.student_activity_steps
              )"
              :key="i"
            >
              <div class="is-flex align-items-center activity-item">
                <div v-if="stepActivity.step_entry">
                  <img
                    :src="require(`@/assets/img/steps/done.svg`)"
                    alt=""
                    class="img_status"
                  />
                </div>
                <div v-else>
                  <img
                    :src="require(`@/assets/img/steps/edit.svg`)"
                    alt=""
                    class="img_status"
                  />
                </div>

                <div class="title_activity mr-2">
                  <span class="has-text-primary">{{ i + 1 }}.</span>
                  {{ stepActivity.legacy_activity_step.name }}
                </div>
                <b-button
                  outlined
                  rounded
                  v-if="
                    studentactivity.status === 3 ||
                    studentactivity.status === 4 ||
                    studentactivity.status === 7
                  "
                  type="is-primary"
                  @click="gotoStepRead(i)"
                  class="ml-auto"
                  >lire</b-button
                >
                <span
                  class="ml-auto"
                  v-else-if="
                    edit === true &&
                    studentactivity.status !== 3 &&
                    (studentactivity.rights.includes('w_' + currentUser.role) ||
                      (currentUser.role === 'formateur' &&
                        studentactivity.rights.includes('w_teacher')) ||
                      (currentUser.role === 'coordinateur' &&
                        studentactivity.rights.includes('w_teacher')) ||
                      (currentUser.role === 'coordinateur' &&
                        studentactivity.rights.includes('w_formateur')))
                  "
                >
                  <b-button
                    v-if="studentactivity.editable"
                    outlined
                    rounded
                    type="is-primary"
                    @click="gotoStepEdit(i)"
                    >rédiger</b-button
                  ><b-button
                    type="is-primary"
                    outlined
                    rounded
                    v-else
                    @click="gotoStepRead(i)"
                    >lire</b-button
                  >
                </span>
                <b-button
                  outlined
                  rounded
                  v-else
                  type="is-primary"
                  @click="gotoStepRead(i)"
                  class="ml-auto"
                  >lire</b-button
                >
              </div>
            </div>
          </div>
          <div v-if="studentactivity.student_activity_steps">
            <div
              class="is-flex is-flex-wrap-wrap activityBtns align-items-center"
            >
              <div
                class="w-100 is-flex"
                v-if="
                  studentactivity.rights.includes('w_' + currentUser.role) ||
                  (currentUser.role === 'formateur' &&
                    studentactivity.rights.includes('w_teacher')) ||
                  (currentUser.role === 'coordinateur' &&
                    studentactivity.rights.includes('w_teacher')) ||
                  (currentUser.role === 'coordinateur' &&
                    studentactivity.rights.includes('w_formateur'))
                "
              >
                <div v-if="studentactivity.editable" class="w-100 is-flex">
                  <b-button
                    v-if="this.studentactivity.status === 0"
                    @click="gotoStepEdit(0)"
                    type="is-success is-rounded mr-3"
                    rounded
                    class="mx-auto btn-action"
                    >commencer</b-button
                  >

                  <b-button
                    v-else-if="edit === true && studentactivity.status === 6"
                    @click="gotoStepEdit(0)"
                    type="is-success is-rounded mr-3"
                    rounded
                    class="mx-auto btn-action"
                    >relire et valider</b-button
                  >
                  <b-button
                    v-else-if="
                      studentactivity.status === 3 ||
                      studentactivity.status === 4 ||
                      studentactivity.status === 7
                    "
                    @click="gotoStepRead(0)"
                    type="is-primary is-rounded mr-3"
                    rounded
                    class="mx-auto btn-action"
                    >relire</b-button
                  >
                </div>
                <div v-else class="w-100 is-flex">
                  <b-button
                    @click="gotoStepRead(0)"
                    type="is-primary is-rounded mr-3"
                    rounded
                    class="mx-auto btn-action"
                    >Lire tout</b-button
                  >
                </div>
              </div>
              <div v-else class="w-100 is-flex">
                <b-button
                  @click="gotoStepRead(0)"
                  type="is-primary is-rounded mr-3"
                  rounded
                  class="mx-auto btn-action"
                  >Lire tout</b-button
                >
              </div>
              <div class="help-btn">
                <AppHelpMessage
                  title="Rédigez et enregistrez votre activité"
                  message="<b>Vous pouvez enregistrer vos notes autant de fois que
                     vous le souhaitez.</b> Tant que vous ne validez pas votre travail,
                      l'activité n'est pas considérée comme effectuée.</b>
            <br/>Une fois validée, vous pourrez corriger des fautes ou reprendre le contenu.
               <b>Toutefois si vous refaites cette situation, il vaut mieux utiliser le bouton
                  'Vous avez progressé' sur la vue précédente pour ajouter de nouvelles notes.</b>"
                  textBtn="Besoin d'aide ?"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <StudentActivityStepEdit
      v-if="isStepModalActive === true"
      :studentactivity="studentactivity"
      @close="closeModal"
      @endloader="endLoading"
      :key="componentKey"
      :slide.sync="stepId"
      :isStepModalReadActive.sync="isStepModalReadActive"
      :isStepModalActive.sync="isStepModalActive"
      @messageFromActivity="initActivity(studentactivity.id)"
    />
    <StudentActivityStepRead
      v-if="isStepModalReadActive === true"
      :studentactivity="studentactivity"
      @close="closeReadModal"
      :key="componentKey"
      :slide.sync="stepId"
      :isStepModalReadActive.sync="isStepModalReadActive"
      :isStepModalActive.sync="isStepModalActive"
      @messageFromActivity="initActivity(studentactivity.id)"
      :tutorCanReadEvaluation="tutor_can_read_evaluation"
    />
    <!-- <StudentProgressionEdit
      v-if="isProgressionActive === true"
      :studentactivity="studentactivity"
      @close="closeProgressionModal"
      :key="componentKey"
    /> -->
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import moment from 'moment';
import localization from 'moment/locale/fr';
import _ from 'lodash';
import StudentActivityStepEdit from '@/components/Activity/StudentActivityStepEdit.vue';
import StudentActivityStepRead from '@/components/Activity/StudentActivityStepRead.vue';
import AppHelpMessage from '@/components/AppHelpMessage.vue';
// import StudentProgressionEdit from '@/components/Activity/StudentProgressionEdit.vue';

moment.updateLocale('fr', localization);

export default {
  components: {
    StudentActivityStepEdit,
    StudentActivityStepRead,
    // StudentProgressionEdit,
    AppHelpMessage,
  },
  filters: {
    day(date) {
      return moment(date).format('D MMMM YYYY');
    },
    hour(date) {
      return moment(date).format('h:mm');
    },
  },
  data() {
    return {
      today: new Date(),
      isLoading: true,
      isFullPage: true,
      started: true,
      studentactivity: [],
      edit: true,
      late: false,
      trainingsequence: [],
      isStepModalActive: false,
      isStepModalReadActive: false,
      isProgressionActive: false,
      stepId: '',
      componentKey: 0,
      status: 0,
      without_dates: '',
      newActivity: true,
      sequenceSubtitle: '',
      sequenceName: '',
      sequenceType: '',
      sequenceUrl: '',
      editDates: false,
      showWeekNumber: true,
      locale: 'UTC',
      form: {
        start_date: new Date(),
        end_date: new Date(),
        location: null,
        newstatus: null,
      },
      tutor_can_read_evaluation: true,
    };
  },
  computed: {
    ...mapGetters(['getUserById']),
    ...mapState('auth', ['currentUser']),
  },
  methods: {
    startLoading() {
      this.isLoading = true;
    },
    endLoading() {
      this.isLoading = false;
    },
    order(array) {
      return _.orderBy(array, 'position');
    },
    forceRerender() {
      // Every time that forceRerender is called, our prop componentKey will change.
      // and Vue knows that it has to destroy the component and create a new one.
      // to not have searching values in others tables
      this.componentKey += 1;
    },
    initActivity(idStudentActivity) {
      this.$http
        .get(`/agenda/student_activity/${idStudentActivity}/?format=json`)
        .then((response) => {
          this.studentactivity = response.data;
          this.getTrainingRights();
          this.currentDateTime();
          this.endLoading();
          this.checkIfNew();
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
        });
    },
    initStudentSequence() {
      this.$http
        .get(`/agenda/student_sequence/${this.$route.params.idSequence}/?query={subtitle,legacy_sequence,sequence_type,tutor_can_read_evaluation}&format=json`)
        .then((response) => {
          this.sequenceSubtitle = response.data.subtitle;
          this.sequenceName = response.data.legacy_sequence.name;
          this.sequenceType = response.data.sequence_type;
          this.sequenceUrl = response.data.legacy_sequence.url;
          this.tutor_can_read_evaluation = response.data.tutor_can_read_evaluation;
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
        });
    },
    gotoStepEdit(id) {
      this.startLoading();
      this.isStepModalActive = true;
      this.stepId = id;
      // this.endLoading();
    },
    gotoStepRead(id) {
      this.startLoading();
      this.isStepModalReadActive = true;
      this.stepId = id;
      this.endLoading();
    },
    addProgression() {
      // this.isProgressionActive = true;
      this.$router.push({
        name: 'StudentProgressionEdit',
        params: {
          idTraining: this.$route.params.idTraining,
          idStudent: this.studentactivity.student_id,
          idSequence: this.$route.params.idSequence,
          idActivity: this.$route.params.idActivity,
          idRevision: 'new',
        },
      });
    },
    closeModal() {
      this.isStepModalActive = false;
      this.endLoading();
      this.initStudentSequence();
    },
    closeReadModal() {
      this.isStepModalReadActive = false;
    },
    closeProgressionModal() {
      this.isProgressionActive = false;
    },
    getTrainingRights() {
      this.$http
        .get(`/trainings/${this.$route.params.idTraining}/?query={editable_without_dates}&format=json`)
        .then((response) => {
          this.without_dates = response.data.editable_without_dates;
        });
    },
    checkIfNew() {
      window.console.log(this.studentactivity.status);
      const steps = this.studentactivity.student_activity_steps;
      for (let i = 0; i < steps.length; i += 1) {
        if (steps[i].step_entry) {
          this.newActivity = false;
        }
      }
    },
    currentDateTime() {
      // const today = moment().format();
      const dateActivity = this.studentactivity.start_date;
      const endActivity = this.studentactivity.end_date;
      if (this.today > dateActivity) {
        this.started = true;
        if (this.studentactivity.rights.includes(`w_${this.currentUser.role}`)) {
          this.edit = true;
        }
      } else { this.started = false; }
      if (this.studentactivity.status === 4) {
        this.started = false;
      }
      if (endActivity < this.today) {
        this.late = true;
      }
    },
    setdata() {
      this.editDates = false;
      if (this.studentactivity.start_date) {
        const startDate = new Date(this.studentactivity.start_date);
        this.studentactivity.start_date = startDate;
      }
      //   function to load values in form for the 3 first steps
      // const dateEnd = new Date(this.studentactivity.start_date);
      if (this.studentactivity.end_date) {
        const endDate = new Date(this.studentactivity.end_date);
        this.studentactivity.end_date = endDate;
      }
      if (this.sequenceType === 10 && this.studentactivity.position === 1) {
        const stepEntry = this.studentactivity.student_activity_steps[0];
        if (stepEntry.step_entry === null) {
          stepEntry.step_entry = stepEntry.legacy_activity_step.content;
        }
      }
      // this.logActivity(this.studentactivity.id);
      this.form.newstatus = this.studentactivity.status;
      this.endLoading();
    },
    patchOneActivity(idActivity) {
      this.editDates = false;
      this.studentactivity.status = 1;
      this.$http
        .patch(`/agenda/student_activity/${idActivity}/`, {
          start_date: this.studentactivity.start_date,
          end_date: this.studentactivity.end_date,
          location: this.studentactivity.location,
          status: this.studentactivity.status,
        }).then(() => {
          this.$buefy.toast.open({ message: 'Dates modifiées !', type: 'is-success' });
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
          this.$buefy.dialog.alert({
            title: 'Mince...',
            message: 'Une erreur s\'est produite, veuillez réessayer. Si l\'erreur persiste vous pouvez contacter le support technique.',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
          });
        });
    },
    changeStatus() {
      this.$buefy.dialog.confirm({
        title: 'Souhaitez-vous changer le statut de cette activité ?',
        message: 'Vous êtes sur le point de changer le statut cette activité.',
        confirmText: 'Valider',
        cancelText: 'Annuler',
        type: 'is-info',
        onConfirm: () => {
          if (this.studentactivity.status === '0') {
            const startDate = null;
            const endDate = null;
            this.studentactivity.start_date = startDate;
            this.studentactivity.end_date = endDate;
          }
          this.$http
            .patch(`/agenda/student_activity/${this.studentactivity.id}/`, {
              status: this.form.newstatus,
              start_date: this.studentactivity.start_date,
              end_date: this.studentactivity.end_date,
            })
            .then(() => {
              this.$buefy.toast.open({ message: 'Statut de l\'activité modifiée', type: 'is-success' });
            }).catch((error) => {
              window.console.log(error);
              this.errored = true;
              this.$buefy.dialog.alert({
                title: 'Mince...',
                message: 'Le statut de l\'activité n\'a pas pu être modifié.',
                type: 'is-info',
                hasIcon: true,
                icon: 'times-circle',
                ariaRole: 'alertdialog',
                ariaModal: true,
                confirmText: 'Ok',
              });
            }).then(() => {
              this.$emit('messageFromActivity', this.studentactivity.id);
            });
        },
      });
    },
  },
  created() {
    // this.studentactivity = this.$route.params.idActivity;
    this.today = moment().format();
    this.initStudentSequence();
  },
  mounted() {
    this.startLoading();
    this.initActivity(this.$route.params.idActivity);
  },
  destroyed() {
  },
};
</script>

<style lang="scss" scoped>
// .card_item {
//   padding: 0.7rem !important;
//   margin-bottom: 0.7rem !important;
#helpTextActivity .help-btn {
  margin: 0 0 2rem 0;
}
// }
.loading-overlay.is-full-page {
  z-index: 3000;
}
</style>
