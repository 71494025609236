<template>
  <div class="pt-5">
    <div class="bloc-paragraph mt-5 pt-5" v-if="referencial">
      <div class="title-paragraph">
        Activité - Tâches associées à cette expérience
      </div>
      <b-message type="is-info" v-if="!skillsTasksList.length" class="mb-0">
        Aucune tâche n'est associée à cette expérience.
      </b-message>
      <div
        v-for="taskRelated in skillsTasksList"
        :key="taskRelated.id"
        class="card-edit-sequence is-flex align-items-center w-100"
      >
        <div
          class="
            bloc-left
            is-flex
            mr-auto
            align-items-center
            w-100
            pr-3
            is-clickable
          "
        >
          <div @click="removeTask(taskRelated.id)">
            <b-icon icon="close" type="is-primary" class="mr-3 ml-4"></b-icon>
          </div>
          T{{ taskRelated.position }}. {{ taskRelated.task_name }}
        </div>
      </div>
    </div>
    <div class="bloc-paragraph mt-5 pt-5" v-if="referencial">
      <div class="title-paragraph">
        Associez de nouvelles tâches et compétences
      </div>
      <div class="subtitle-paragraph">
        <p>
          <b
            >Cette formation est associée au référentiel "{{
              tasksList.name
            }}".</b
          >
        </p>
        <p>
          Sélectionnez une ou plusieurs tâches et importez les compétences
          associées automatiquement en cliquant sur le bouton "importez" en bas
          de la liste.
        </p>
      </div>
      <div
        v-for="activity in order(tasksList.referencial_activities)"
        :key="activity.id"
        class="mb-5"
      >
        <b-collapse class="card" animation="slide" :open="false">
          <div
            slot="trigger"
            slot-scope="props"
            class="card-header"
            role="button"
          >
            <div class="bg-card is-flex bg-primary align-items-center">
              <a class="card-header-icon">
                <b-icon v-bind:icon="props.open ? 'menu-down' : 'menu-up'">
                </b-icon>
              </a>
              <p class="card-header-title">
                {{ activity.code }}. {{ activity.name }}
              </p>
            </div>
          </div>
          <div class="content pl-3 pb-4">
            <div class="mt-3">
              <b-field
                v-for="task in order(activity.referencial_tasks)"
                :key="task.id"
              >
                {{ changeTask() }}
                <b-checkbox
                  v-model="checkedTask"
                  :native-value="task.id"
                  @input="postSequenceTask(task.id, task.position)"
                >
                  {{ task.code }}. {{ task.name }}
                </b-checkbox>
              </b-field>
            </div>
          </div>
        </b-collapse>
      </div>
      <!-- <b-button @click="listSkills()" type="is-primary" rounded>
        afficher les compétences associées</b-button
      > -->
      <b-button @click="importSkills" type="is-primary" rounded
        >associer les tâches à cette séquence</b-button
      >
    </div>
    <div class="bloc-paragraph mt-5">
      <div class="title-paragraph">
        Compétences associées à cette expérience
      </div>
      <div class="subtitle-paragraph" v-if="skillsTasksList.length > 0">
        La liste des compétences ci-dessous a été actualisée et correspond aux
        compétences associées aux tâches sélectionnées
      </div>
    </div>
    <div class="card_simple" v-if="skillsListReferencial.length">
      <div class="">
        <div class="">
          <b-field label="Rechercher un bloc de compétences" class="pl-4">
            <b-select
              placeholder="choisissez un bloc"
              v-model="blockSelected"
              @input="changeList()"
              class="pl-0"
            >
              <option
                v-for="(blockSkill, index) in skillsListReferencial"
                :value="index"
                :key="blockSkill.id"
              >
                {{ blockSkill.name }}
              </option>
            </b-select>
          </b-field>
        </div>
        <div
          class="column is-flex align-items-center ml-2 pt-4"
          v-if="displayList"
        >
          <div v-if="skillsListReferencial[blockSelected].skills">
            <b-switch
              class="mb-5"
              v-if="skillsListReferencial[blockSelected].skills.length"
              v-model="checkedAllSkills"
              @input="
                selectAllSkills(skillsListReferencial[blockSelected].skills)
              "
            >
              Sélectionner tout
            </b-switch>
            <div v-else>
              <b-message type="is-info"
                >Toutes les compétences de ce bloc sont déjà associées à cette
                expérience</b-message
              >
            </div>
            <b-field
              v-for="skill in skillsListReferencial[blockSelected].skills"
              :key="skill.id"
            >
              <div v-if="skill.block !== 'disabled'">
                <b-checkbox
                  v-model="checkedSkill"
                  :native-value="skill.id"
                  @input="postSequenceSkill(skill.id, skill.position)"
                >
                  {{ skill.name }}
                </b-checkbox>
              </div>
              <div v-else class="has-text-grey-light">
                <b-icon icon="check" type="is-primary" class="mr-3"></b-icon
                >{{ skill.name }}
              </div>
            </b-field>
            <b-button type="is-primary" @click="postAllSkills2()" rounded
              >associer les compétences</b-button
            >
          </div>
          <div v-else>ce bloc ne contient aucune compétence</div>
        </div>
        <div v-else>{{ skillsListReferencial[0].skills }}</div>
      </div>
    </div>
    <b-message type="is-info" v-if="!skillsSequenceList.length">
      Aucune compétence n'est associée à cette expérience.
    </b-message>
    <div>
      <b-button type="is-primary" @click="deleteAllSkills" class="mb-4" rounded
        >supprimer toutes les compétences</b-button
      >
    </div>
    <div
      class="card-edit-sequence is-flex align-items-center w-100"
      v-for="skillsequence in skillsSequenceList"
      :key="skillsequence.name"
    >
      <div
        class="
          bloc-left
          is-flex
          mr-auto
          align-items-center
          w-100
          pr-3
          is-clickable
        "
      >
        <div class="is-flex">
          <div @click="removeSkill(skillsequence.id)">
            <b-icon icon="close" type="is-primary" class="mr-3 ml-4"></b-icon>
          </div>
          {{ skillsequence.skill_name }}
        </div>
      </div>
    </div>

    <!-- referentiel : {{ referencial }} selectSkills : {{ selectSkills }} -->
    <div id="selectSkills" v-if="!referencial">
      <div class="bloc-paragraph mt-5 pt-5">
        <div class="title-paragraph">Associez de nouvelles compétences</div>
      </div>
      <div class="card_simple" v-if="skillsList.length">
        <div class="">
          <div class="">
            <b-field label="Rechercher un bloc de compétences" class="pl-4">
              <b-select
                placeholder="choisissez un bloc"
                v-model="blockSelected"
                @input="changeList()"
                class="pl-0"
              >
                <option
                  v-for="(blockSkill, index) in skillsList"
                  :value="index"
                  :key="blockSkill.id"
                >
                  {{ blockSkill.name }}
                </option>
              </b-select>
            </b-field>
          </div>
          <div
            class="column is-flex align-items-center ml-2 pt-4"
            v-if="displayList"
          >
            <div v-if="skillsList[blockSelected].skills">
              {{ changeList() }}
              <b-switch
                class="mb-5"
                v-if="skillsList[blockSelected].skills.length"
                v-model="checkedAllSkills"
                @input="selectAllSkills(skillsList[blockSelected].skills)"
              >
                Sélectionner tout
              </b-switch>
              <div v-else>
                <b-message type="is-info"
                  >Toutes les compétences de ce bloc sont déjà associées à cette
                  expérience</b-message
                >
              </div>
              <b-field
                v-for="skill in skillsList[blockSelected].skills"
                :key="skill.id"
              >
                <!-- {{ skill.id }} --><!--@input="postSequenceSkills(skill.id, skill.position)"-->
                <b-checkbox
                  v-model="checkedSkill"
                  :native-value="skill.id"
                  @input="postSequenceSkill(skill.id, skill.position)"
                >
                  {{ skill.name }}
                </b-checkbox>
              </b-field>
              <b-button type="is-primary" @click="postAllSkills()" rounded
                >associer les compétences</b-button
              >
            </div>
            <div v-else>ce bloc ne contient aucune compétence</div>
          </div>
          <div v-else>{{ skillsList[0].skills }}</div>
        </div>
      </div>
      <b-message type="is-info" v-else>
        Aucune compétence n'est associée à cette expérience.
      </b-message>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import _ from 'lodash';
// import { ValidationProvider, ValidationObserver } from 'vee-validate';

export default {
  props: {
    typeoftraining: {
      type: String,
    }, // framework, training or studenttraining
    sequenceId: {
      type: Number,
    },
    trainingId: {
      type: Number,
    },
    // referencial: [Number, String],
  },
  components: {
  },
  filters: {
  },
  data() {
    return {
      isLoading: false,
      isFullPage: false,
      skillsList: [],
      blockSelected: 0,
      skillSelected: [],
      skillsSequenceList: [],
      displayList: false,
      tasksList: [],
      taskSelected: [],
      selectSkills: false,
      checkedTask: [],
      skillsTasksList: [],
      checkedSkill: [],
      checkedAllSkills: false,
      referencial: null,
      displayListReferential: false,
      skillsListReferencial: [],
    };
  },
  computed: {
    ...mapState('auth', ['currentUser']),
  },
  methods: {
    startLoading() {
      this.isLoading = true;
    },
    endLoading() {
      this.isLoading = false;
    },
    order(array) {
      return _.orderBy(array, 'position');
    },
    initFrameworkSequenceReferencial() {
      let urlWs = '';
      if (this.typeoftraining === 'framework') {
        urlWs = 'sequences';
      } else if (this.typeoftraining === 'training') {
        urlWs = 'training_sequences';
      }
      this.$http
        .get(`/${urlWs}/${this.sequenceId}/?query={referencial}&format=json`)
        .then((response) => {
          this.referencial = response.data.referencial;
          if (this.referencial) {
            this.initSequenceTask();
            this.initTaskList();
          }
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
        });
    },
    initSkills() {
      this.startLoading();
      let urlWs = '';
      if (this.typeoftraining === 'framework') {
        urlWs = `/frameworkskillsblock/?training_framework=${this.trainingId}&format=json`;
      } else if (this.typeoftraining === 'training') {
        urlWs = `/trainingskillsblock/?training=${this.trainingId}&format=json`;
      }
      this.$http
        .get(`${urlWs}`)
        .then((response) => {
          this.skillsList = this.order(response.data);
          if (this.typeoftraining === 'training') {
            /* eslint-disable */
            this.skillsList = this.skillsList.map(item => {
              return { skills: item.tskills, name: item.name, reference: item.reference, id: item.id, training: item.training, description: item.description, position: item.position };
            });
            /* eslint-enable */
          }

          for (let i = 0; i < this.skillsList.length; i += 1) {
            this.changeList();
            const myBlocks = this.skillsList;
            // eslint-disable-next-line
            this.skillsList[i].skills = this.order(myBlocks[i].skills);
          }
          this.endLoading();
        });
    },
    initSequenceSkills() {
      this.startLoading();
      let urlWs = '';
      if (this.typeoftraining === 'framework') {
        urlWs = `/sequenceskills/?sequence=${this.sequenceId}&format=json`;
      } else if (this.typeoftraining === 'training') {
        urlWs = `/trainingsequenceskills/?sequence=${this.sequenceId}&format=json`;
      }
      this.$http
        .get(`${urlWs}`)
        .then((response) => {
          this.skillsSequenceList = response.data;
          this.endLoading();
        });
    },
    postSequenceSkills(idSkill, positionSkill) {
      let urlWs = '';
      if (this.typeoftraining === 'framework') {
        urlWs = '/sequenceskills/';
      } else if (this.typeoftraining === 'training') {
        urlWs = '/trainingsequenceskills/';
      }
      this.$http
        .post(`${urlWs}`, {
          position: positionSkill,
          skill: idSkill,
          sequence: this.sequenceId,
        }).then(() => {
          // this.$buefy.toast.open({ message: 'compétence ajoutée', type: 'is-success' });
          this.initSkills();
          this.initSequenceSkills();
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
        });
    },
    changeList() {
      this.displayList = true;
      for (let i = 0; i < this.skillsSequenceList.length; i += 1) {
        if (this.skillsList[this.blockSelected]) {
          const skilltoremove = _.findKey(this.skillsList[this.blockSelected].skills,
            (e) => e.id === this.skillsSequenceList[i].skill);
          if (skilltoremove) {
            this.skillsList[this.blockSelected].skills.splice(skilltoremove, 1);
          }
        }
      }
    },
    changeTask() {
      const activities = this.tasksList.referencial_activities;
      for (let f = 0; f < activities.length; f += 1) {
        for (let i = 0; i < this.skillsTasksList.length; i += 1) {
          const skilltoremove = _.findKey(activities[f].referencial_tasks,
            (e) => e.id === this.skillsTasksList[i].task);
          if (skilltoremove) {
            activities[f].referencial_tasks.splice(skilltoremove, 1);
          }
        }
      }
    },
    removeSkill(idSkill) {
      let urlWs = '';
      if (this.typeoftraining === 'framework') {
        urlWs = `/sequenceskills/${idSkill}/`;
      } else if (this.typeoftraining === 'training') {
        urlWs = `/trainingsequenceskills/${idSkill}/`;
      }
      this.$buefy.dialog.confirm({
        title: 'Supprimer une compétence',
        message: 'Êtes-vous sûr de vouloir supprimer cette compétence ? Attention, si la formation a commencé, vous perdrez tout le contenu que des évaluations associiées',
        confirmText: 'Supprimer',
        cancelText: 'Annuler',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.$http
            .delete(`${urlWs}?format=json`)
            .then(() => {
              this.initSkills();
              this.initSequenceSkills();
              this.$buefy.toast.open({ message: 'Compétence supprimée', type: 'is-success' });
            }).catch((error) => {
              window.console.log(error);
              this.errored = true;
              this.$buefy.dialog.alert({
                title: 'Mince...',
                message: 'La compétecne n\'a pas pu être supprimée.',
                type: 'is-info',
                hasIcon: true,
                icon: 'times-circle',
                ariaRole: 'alertdialog',
                ariaModal: true,
                confirmText: 'Ok',
              });
            });
        },
      });
    },
    removeTask(idTask) {
      let urlWs = '';
      if (this.typeoftraining === 'framework') {
        urlWs = `sequencetasks/${idTask}/`;
      } else if (this.typeoftraining === 'training') {
        urlWs = `/trainingsequencetasks/${idTask}/`;
      }
      this.$buefy.dialog.confirm({
        title: 'Supprimer une tâche',
        message: 'Êtes-vous sûr de vouloir supprimer cette tâche ?',
        confirmText: 'Supprimer',
        cancelText: 'Annuler',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.$http
            .delete(`${urlWs}?format=json`)
            .then(() => {
              this.initTaskList();
              this.initSequenceTask();
              this.$buefy.toast.open({ message: 'Tâche supprimée', type: 'is-success' });
            }).catch((error) => {
              window.console.log(error);
              this.errored = true;
              this.$buefy.dialog.alert({
                title: 'Mince...',
                message: 'La tâche n\'a pas pu être supprimée.',
                type: 'is-info',
                hasIcon: true,
                icon: 'times-circle',
                ariaRole: 'alertdialog',
                ariaModal: true,
                confirmText: 'Ok',
              });
            });
        },
      });
    },
    initTaskList() {
      this.$http
        .get(`referencials/referencial/${this.referencial}/?format=json`)
        .then((response) => {
          this.tasksList = response.data;
          this.changeTask();
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
        });
    },
    importSkills() {
      let urlWs = '';
      if (this.typeoftraining === 'framework') {
        urlWs = '/sequencetasks/';
      } else if (this.typeoftraining === 'training') {
        urlWs = '/trainingsequencetasks/';
      }
      for (let i = 0; i < this.taskSelected.length; i += 1) {
        this.$http
          .post(`${urlWs}`, {
            sequence: this.sequenceId,
            position: this.taskSelected[i].position,
            task: this.taskSelected[i].task,
          }).then(() => {
            this.selectSkills = true;
            this.initSequenceTask();
            this.initSequenceSkills();
            this.initTaskList();
            this.listSkills();
            this.$buefy.toast.open({ message: 'Compétences associées', type: 'is-success' });
          }).catch((error) => {
            window.console.log(error);
            this.errored = true;
            this.$buefy.dialog.alert({
              title: 'Mince...',
              message: 'Les compétences n\'ont pu être chargées',
              type: 'is-info',
              hasIcon: true,
              icon: 'times-circle',
              ariaRole: 'alertdialog',
              ariaModal: true,
              confirmText: 'Ok',
            });
          });
      }
    },
    listSkills() {
      let urlWs = '';
      if (this.typeoftraining === 'framework') {
        urlWs = `/get_skills_from_tasks/?sequence=${this.sequenceId}&framework=${this.trainingId}`;
      } else if (this.typeoftraining === 'training') {
        urlWs = `/get_skills_from_tasks/?sequence=${this.sequenceId}&training=${this.trainingId}`;
      }
      this.$http
        .get(`${urlWs}&format=json`)
        .then((response) => {
          this.displayListReferential = true;
          this.skillsListReferencial = response.data;
          this.verifyReferentialSkillsDisabled();
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
          this.$buefy.dialog.alert({
            title: 'Mince...',
            message: 'Les compétences n\'ont pu être chargées',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
          });
        });
    },
    verifyReferentialSkillsDisabled() {
      for (let f = 0; f < this.skillsListReferencial.length; f += 1) {
        for (let i = 0; i < this.skillsSequenceList.length; i += 1) {
          const skilltoremove = _.findKey(this.skillsListReferencial[f].skills,
            (e) => e.id === this.skillsSequenceList[i].skill);
          if (skilltoremove) {
            this.skillsListReferencial[f].skills.splice(skilltoremove, 1);
            // this.skillsListReferencial[f].skills[skilltoremove].block = 'disabled';
          }
        }
      }
    },
    postSequenceTask(id, taskPosition) {
      const tasktoremove = _.findKey(this.taskSelected,
        ['task', id]);
      if (!tasktoremove) {
        this.taskSelected.push({
          task: id,
          position: taskPosition,
        });
      } else {
        this.taskSelected.splice(tasktoremove, 1);
      }
    },
    initSequenceTask() {
      this.startLoading();
      let urlWs = '';
      if (this.typeoftraining === 'framework') {
        urlWs = `sequencetasks/?sequence=${this.sequenceId}&format=json`;
      } else if (this.typeoftraining === 'training') {
        urlWs = `trainingsequencetasks/?sequence=${this.sequenceId}&format=json`;
      }
      this.$http
        .get(`${urlWs}`)
        .then((response) => {
          this.skillsTasksList = this.order(response.data);
          this.listSkills();
          this.endLoading();
        });
    },
    // postAllSkills(allskills) {
    //   if (this.checkedAllSkills) {
    //     for (let i = 0; i < allskills.length; i += 1) {
    //       this.postSequenceSkills(allskills[i].id, allskills[i].position);
    //     }
    //   } else {
    //     window.console.log(this.checkedSkill);
    //   }
    // },
    postAllSkills() {
      let urlWs = '';
      if (this.typeoftraining === 'framework') {
        urlWs = '/sequenceskills/';
      } else if (this.typeoftraining === 'training') {
        urlWs = '/trainingsequenceskills/';
      }
      for (let i = 0; i < this.skillSelected.length; i += 1) {
        this.$http
          .post(`${urlWs}`, {
            sequence: this.sequenceId,
            position: this.skillSelected[i].position,
            skill: this.skillSelected[i].skill,
          }).then(() => {
            this.selectSkills = true;
            if (i + 1 === this.skillSelected.length) {
              this.skillSelected = [];
              this.checkedSkill = [];
              this.checkedAllSkills = [];
              this.initSkills();
              this.initSequenceSkills();
              this.$buefy.toast.open({ message: 'Compétences associées', type: 'is-success' });
            }
          }).catch((error) => {
            window.console.log(error);
            this.errored = true;
            this.$buefy.dialog.alert({
              title: 'Mince...',
              message: 'Les compétences n\'ont pu être associées',
              type: 'is-info',
              hasIcon: true,
              icon: 'times-circle',
              ariaRole: 'alertdialog',
              ariaModal: true,
              confirmText: 'Ok',
            });
          });
      }
    },
    postAllSkills2() {
      let urlWs = '';
      if (this.typeoftraining === 'framework') {
        urlWs = '/sequenceskills/';
      } else if (this.typeoftraining === 'training') {
        urlWs = '/trainingsequenceskills/';
      }
      for (let i = 0; i < this.skillSelected.length; i += 1) {
        this.$http
          .post(`${urlWs}`, {
            sequence: this.sequenceId,
            position: this.skillSelected[i].position,
            skill: this.skillSelected[i].skill,
          }).then(() => {
            this.selectSkills = true;
            if (i + 1 === this.skillSelected.length) {
              this.skillSelected = [];
              this.checkedSkill = [];
              this.checkedAllSkills = [];
              this.initSkills();
              this.initSequenceSkills();
              this.verifyReferentialSkillsDisabled();
              this.listSkills();
              this.$buefy.toast.open({ message: 'Compétences associées', type: 'is-success' });
            }
          }).catch((error) => {
            window.console.log(error);
            this.errored = true;
            this.$buefy.dialog.alert({
              title: 'Mince...',
              message: 'Les compétences n\'ont pu être associées',
              type: 'is-info',
              hasIcon: true,
              icon: 'times-circle',
              ariaRole: 'alertdialog',
              ariaModal: true,
              confirmText: 'Ok',
            });
          });
      }
    },
    selectAllSkills(allskills) {
      this.skillSelected = [];
      if (this.checkedAllSkills) {
        this.checkedSkill = [];
        for (let i = 0; i < allskills.length; i += 1) {
          this.checkedSkill.push(allskills[i].id);
          this.skillSelected.push({
            skill: allskills[i].id,
            position: allskills[i].position,
          });
        }
      } else {
        this.checkedSkill = [];
      }
    },
    postSequenceSkill(id, skillPosition) {
      const skilltoremove = _.findKey(this.skillSelected,
        ['skill', id]);
      if (!skilltoremove) {
        this.skillSelected.push({
          skill: id,
          position: skillPosition,
        });
      } else {
        this.skillSelected.splice(skilltoremove, 1);
      }
    },
    deleteAllSkills() {
      let urlWs = '';
      if (this.typeoftraining === 'framework') {
        urlWs = '/sequenceskills';
      } else if (this.typeoftraining === 'training') {
        urlWs = '/trainingsequenceskills';
      }
      this.$buefy.dialog.confirm({
        title: 'Supprimer toutes les compétences',
        message: 'Êtes-vous sûr de vouloir supprimer toutes les compétences de cette expérience ? Attention, si la formation a commencé, vous perdrez tout le contenu que des évaluations associiées',
        confirmText: 'Supprimer',
        cancelText: 'Annuler',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          for (let i = 0; i < this.skillsSequenceList.length; i += 1) {
            this.$http
              .delete(`${urlWs}/${this.skillsSequenceList[i].id}/?format=json`)
              .then(() => {
                this.initSkills();
                this.initSequenceSkills();
              }).catch((error) => {
                window.console.log(error);
                this.errored = true;
              });
            if (i + 1 === this.skillsSequenceList.length) {
              this.$buefy.toast.open({ message: 'Compétences supprimées', type: 'is-success' });
            }
          }
          if (this.errored) {
            this.$buefy.dialog.alert({
              title: 'Mince...',
              message: 'La compétecne n\'a pas pu être supprimée.',
              type: 'is-info',
              hasIcon: true,
              icon: 'times-circle',
              ariaRole: 'alertdialog',
              ariaModal: true,
              confirmText: 'Ok',
            });
          }
        },
      });
    },
  },
  created() {

  },
  mounted() {
    this.initSequenceSkills();
    this.initSkills();
    this.initFrameworkSequenceReferencial();
  },
};
</script>

<style scoped>
.field:last-child {
  margin-bottom: 0px !important;
}
.field {
  margin-bottom: 1rem !important;
  font-size: 0.95rem;
}
</style>
