<template>
  <div class="modalbox">
    <!-- eslint-disable max-len-->
    <b-loading :is-full-page="isFullPage" v-model="isLoading"> </b-loading>
    <div class="is-flex bigmodal">
      <div class="ml-auto">
        <b-button @click="saveAll()" type="is-success is-rounded" class="mr-2">enregistrer tout</b-button>
        <b-button @click="goPreviousPage('parcours')" type="is-primary" rounded outlined>Revenir au parcours</b-button>
      </div>
    </div>

    <div class="container title-modal">
      <div class="subtitle-paragraph mb-0">Détail d'une expérience</div>
      <div class="title-page long-title">
        {{ trainingsequence.name }}
      </div>
    </div>
    <section class="mt-auto pr-0 pl-0" id="header-tabs">
      <div class="b-tabs container">
        <nav class="tabs">
          <ul>
            <li :class="currentTab === 1 ? 'is-active' : ''">
              <a @click="gotoStep(1)"><span>L'expérience</span></a>
            </li>
            <li v-for="(
                activity, indexActivity
              ) in trainingsequence.training_activities" :key="indexActivity"
              :class="currentTab === indexActivity + 2 ? 'is-active' : ''">
              <a @click="gotoActivity(indexActivity)"><b-icon icon="calendar" type="is-primary"></b-icon><span
                  v-if="activity.legacy_activity">{{
      activity.legacy_activity.name
    }}</span>
                <span v-else>{{ activity.name_override }} </span>
              </a>
            </li>
            <li :class="currentTab === 'resources' ? 'is-active' : ''">
              <a @click="gotoResources"><span>Ressources</span></a>
            </li>
          </ul>
        </nav>
      </div>
    </section>
    <div class="container contentActivite mb-6" v-if="step == 1">
      <div class="bloc-paragraph mt-5 pt-5">
        <div class="title-paragraph">Présentation de l'expérience</div>
        <b-field label="Titre de l'expérience">
          <b-input v-model="form.name"></b-input>
        </b-field>
        <div class="mb-6">
          <span class="subtitle-paragraph">Type d'expérience : </span>
          <span v-if="trainingsequence.sequence_type_label">
            {{ trainingsequence.sequence_type_label }}</span>
        </div>
        <div>
          <b-field label="Durée">
            <b-input v-model="form.estimated_duration"></b-input>
          </b-field>
        </div>
        <div>
          <b-field label="Url" v-if="trainingsequence.sequence_type === 3">
            <b-input v-model="form.url"></b-input>
          </b-field>
        </div>
        <div class="card_event" v-if="!training.editable_without_dates">
          <div class="title-paragraph mb-0">Dates de l'expérience</div>
          <div class="helpUser">
            <div class="title">
              Précisez la période estimée pour cette expérience
            </div>
            <div>
              Attention, il ne s'agit pas des dates définitives. Pour programmer
              à proprement parlé une activité, vous devez spécifier la date dans
              l'onglet de cette activité, et, si besoin, vous assurer que chaque
              utilisateur a bien confirmé la date (notamment pour les
              expériences en entreprise).
            </div>
          </div>
          <div class="columns is-tablet is-vcentered mt-2">
            <div class="column is-narrow">
              <b-field label="Date de début" v-if="isOpen === false">
                <span v-if="trainingsequence.start_date">{{ trainingsequence.start_date | day }}
                  à
                  {{ trainingsequence.start_date | hour }} </span><span v-else>-- </span>
              </b-field>
              <b-collapse aria-id="contentIdForA11y2" animation="slide" v-model="isOpen">
                <b-field label="Date de début">
                  <b-datetimepicker v-model="form.start_date" :show-week-number="false" :locale="locale"
                    :datepicker="{ firstDayOfWeek: 1, showWeekNumber: true }" icon="calendar-today" trap-focus inline>
                  </b-datetimepicker> </b-field></b-collapse>
            </div>
            <div class="column is-narrow">
              <b-field label="Date de fin" v-if="isOpen === false">
                <span v-if="trainingsequence.end_date">{{ trainingsequence.end_date | day }}
                  à
                  {{ trainingsequence.end_date | hour }} </span><span v-else>-- </span>
              </b-field>
              <b-collapse aria-id="contentIdForA11y2" animation="slide" v-model="isOpen">
                <b-field label="Date de fin">
                  <b-datetimepicker v-model="form.end_date" :show-week-number="false" :locale="locale"
                    icon="calendar-today" trap-focus :datepicker="{ firstDayOfWeek: 1, showWeekNumber: true }" inline>
                  </b-datetimepicker>
                </b-field>
              </b-collapse>
            </div>
            <div class="column is-narrow">
              <div v-if="isOpen === true" class="is-flex is-flex-direction-column">
                <b-button label="enregistrer les dates" type="is-primary" @click.prevent="updateSequence"
                  aria-controls="contentIdForA11y2" class="btn-margins" rounded />

                <b-button label="fermer" type="is-primary" size="is-small" class="btn-margins"
                  aria-controls="contentIdForA11y2" outlined rounded @click="isOpen = false" />
              </div>
              <div v-if="isOpen === false">
                <b-button v-if="trainingsequence.start_date || trainingsequence.end_date
      " label="modifier" class="btn-margins" type="is-primary" @click="
      setDate(
        trainingsequence.start_date,
        trainingsequence.end_date
      )
      " aria-controls="contentIdForA11y2" rounded />
                <b-button v-if="trainingsequence.start_date || trainingsequence.end_date
      " label="effacer" class="btn-margins" type="is-outlined" @click="clearDatesSequence()"
                  aria-controls="contentIdForA11y2" rounded />
                <b-button v-if="!trainingsequence.start_date && !trainingsequence.end_date
      " label="renseigner des dates" class="btn-margins" type="is-primary" @click="
      setDate(
        trainingsequence.start_date,
        trainingsequence.end_date
      )
      " aria-controls="contentIdForA11y2" rounded />
                <b-button v-if="trainingsequence.start_date || trainingsequence.end_date
      " label="modifier pour tous les apprenants" type="is-primary" class="btn-margins"
                  @click="saveDateSequenceForStudents" aria-controls="contentIdForA11y2" rounded outlined />
              </div>
            </div>
          </div>
        </div>
        <!-- Todo: add teachers name -->
        <!-- <div class="card_event">
          <div class="title-paragraph">Formateur(s)</div>
          <div class="columns is-vcentered">
            <div v-if="!teachersOpen" class="column addCommas is-narrow">
              <span v-for="teacher in trainingsequence.teachers" :key="teacher.id">
                <span >
                teacher name
                </span>
              </span>
            </div>
            <b-collapse v-if="teachersOpen" class="column is-narrow">
              <div v-for="teacher in trainingTeachers.teachers" :key="teacher.id">
                <b-checkbox :native-value="teacher" v-model="trainingsequence.teachers">
                teacher name</b-checkbox>
              </div>
            </b-collapse>
            <b-button v-if="teachersOpen" class="mt-2 btn-margins" type="is-primary" rounded
              @click="patchTeacher">enregistrer</b-button>
            <b-button :label="!teachersOpen ? 'modifier' : 'fermer'" class="mt-2 btn-margins" type="is-primary"
              @click="teachersOpen = !teachersOpen" aria-controls="contentIdForA11y2" rounded outlined />
          </div>
        </div> -->
      </div>
      <div class="bloc-paragraph">
        <div class="title-paragraph">
          Présentation, principaux thèmes abordés
        </div>
        <div class="subtitle-paragraph" v-html="sequencetype.goals"></div>
        <ckeditor :editor="editor" v-model="form.goals" :config="editorConfig" class="mb-5"></ckeditor>
      </div>
      <div class="bloc-paragraph">
        <div class="title-paragraph">Commentaires</div>
        <div class="subtitle-paragraph" v-html="sequencetype.comments"></div>
        <ckeditor :editor="editor" v-model="form.comment" :config="editorConfig" class="mb-5"></ckeditor>
      </div>
      <b-button @click.prevent="updateSequence" type="is-primary" rounded class="mb-6">Enregistrer
        l'expérience</b-button>
      <div class="mt-5">
        <SkillsSequenceAdd v-if="currentUser.role === 'coordinateur' ||
      (currentUser.role === 'formateur' && user.edit_rights < 2)
      " :typeoftraining="'training'" :sequenceId="Number(this.$route.params.id)"
          :trainingId="Number(this.$route.params.idTraining)" />
        <SkillsSequence v-else :typeoftraining="'training'" :sequenceId="Number(this.$route.params.id)"
          :trainingId="Number(this.$route.params.idTraining)" textTitle="Compétences cibles" />
      </div>
    </div>
    <div class="container contentActivite mb-6 pb-5" v-if="step == NumberActivity + 2">
      <div class="frowho">
        <b-icon icon="user" type="is-primary"></b-icon>
        <div v-if="trainingsequence.training_activities[NumberActivity].legacy_activity
      ">
          {{
      trainingsequence.training_activities[NumberActivity].legacy_activity
        .intro
    }}
        </div>
        <div v-else>
          {{
        trainingsequence.training_activities[NumberActivity].intro_override
      }}
        </div>
      </div>
      <div class="card_event" v-if="!training.editable_without_dates">
        <div class="title-paragraph mb-0">Dates de l'activité</div>
        <div class="columns is-tablet is-vcentered mt-2">
          <div class="column is-narrow">
            <b-field label="Date de début" v-if="isOpen === false">
              <span v-if="trainingsequence.training_activities[NumberActivity]
      .start_date
      ">{{
      trainingsequence.training_activities[NumberActivity]
        .start_date | day
    }}
                à
                {{
        trainingsequence.training_activities[NumberActivity]
          .start_date | hour
      }} </span><span v-else>-- </span>
            </b-field>
            <b-collapse aria-id="contentIdForA11y2" animation="slide" v-model="isOpen">
              <b-field label="Date de début">
                <b-datetimepicker v-model="formActivity.start_date" :show-week-number="false" :locale="locale"
                  :datepicker="{ firstDayOfWeek: 1, showWeekNumber: true }" icon="calendar-today" trap-focus inline>
                </b-datetimepicker> </b-field></b-collapse>
          </div>
          <div class="column is-narrow">
            <b-field label="Date de fin" v-if="isOpen === false">
              <span v-if="trainingsequence.training_activities[NumberActivity].end_date
      ">{{
      trainingsequence.training_activities[NumberActivity].end_date
      | day
    }}
                à
                {{
        trainingsequence.training_activities[NumberActivity].end_date
        | hour
      }} </span><span v-else>-- </span>
            </b-field>
            <b-collapse aria-id="contentIdForA11y2" animation="slide" v-model="isOpen">
              <b-field label="Date de fin">
                <b-datetimepicker v-model="formActivity.end_date" :show-week-number="false" :locale="locale"
                  icon="calendar-today" trap-focus :datepicker="{ firstDayOfWeek: 1, showWeekNumber: true }" inline>
                </b-datetimepicker>
              </b-field>
            </b-collapse>
          </div>
          <div class="column is-narrow">
            <div v-if="isOpen === true" class="is-flex is-flex-direction-column">
              <b-button label="enregistrer les dates" type="is-primary" @click="
      saveDatesActivity(
        trainingsequence.training_activities[NumberActivity].id
      )
      " aria-controls="contentIdForA11y2" class="mb-4" rounded />

              <b-button label="fermer" type="is-primary" size="is-small" aria-controls="contentIdForA11y2" outlined
                rounded @click="isOpen = false" />
            </div>
          </div>
          <b-button v-if="trainingsequence.training_activities[NumberActivity].start_date &&
      trainingsequence.training_activities[NumberActivity].end_date
      " label="effacer" type="is-primary" class="btn-margins" @click="
      clearActivityDates(
        trainingsequence.training_activities[NumberActivity].id
      )
      " aria-controls="contentIdForA11y2" rounded outlined />
        </div>
        <div v-if="isOpen === false">
          <b-button v-if="trainingsequence.training_activities[NumberActivity].start_date ||
      trainingsequence.training_activities[NumberActivity].end_date
      " label="modifier les dates" type="is-primary" @click="
      setDateActivity(
        trainingsequence.training_activities[NumberActivity].start_date,
        trainingsequence.training_activities[NumberActivity].end_date
      )
      " aria-controls="contentIdForA11y2" rounded class="mr-3" />

          <b-button v-if="!trainingsequence.training_activities[NumberActivity]
      .start_date &&
      !trainingsequence.training_activities[NumberActivity].end_date
      " label="renseigner des dates" type="is-primary" class="mr-3" @click="
      setDateActivity(
        trainingsequence.training_activities[NumberActivity].start_date,
        trainingsequence.training_activities[NumberActivity].end_date
      )
      " aria-controls="contentIdForA11y2" rounded />
          <b-button label="ajouter les même dates que l'expérience" type="is-primary" class="btn-margins" @click="
      saveDateActivityFromSequence(
        trainingsequence.training_activities[NumberActivity].id
      )
      " aria-controls="contentIdForA11y2" rounded outlined />
          <b-button label="modifier pour tous" type="is-primary" class="btn-margins" @click="
      saveDateActivityForStudents(
        trainingsequence.training_activities[NumberActivity].id
      )
      " aria-controls="contentIdForA11y2" rounded outlined />
        </div>
      </div>
      <div class="head_activity">L'activité</div>
      <div class="helpUser">
        <div class="title" v-if="trainingsequence.training_activities[NumberActivity].legacy_activity
      ">
          {{
      trainingsequence.training_activities[NumberActivity].legacy_activity
        .title
    }}
        </div>
        <div v-else class="title">
          {{
        trainingsequence.training_activities[NumberActivity].title_override
      }}
        </div>
        <div v-if="trainingsequence.training_activities[NumberActivity].legacy_activity
      ">
          {{
      trainingsequence.training_activities[NumberActivity].legacy_activity
        .description
    }}
        </div>
        <div v-else>
          {{
        trainingsequence.training_activities[NumberActivity]
          .description_override
      }}
        </div>
      </div>
      <b-collapse :open="false" aria-id="helpTextUsers">
        <template #trigger>
          <b-button label="Modifier les textes de présentation de l'activité" type="is-primary"
            aria-controls="helpTextUsers" rounded class="mb-5" />
        </template>
        <div class="content">
          <div class="bloc-paragraph" v-if="trainingsequence.training_activities[NumberActivity]">
            <div class="title-paragraph">
              Présentation de l'activité à destination de l'apprenant
            </div>
            <ckeditor :editor="editor" v-model="trainingsequence.training_activities[NumberActivity]
      .presentation_apprenant_override
      " :config="editorConfig" class="mb-5"></ckeditor>
            <b-button @click="
      defaultTextPresentation(
        trainingsequence.training_activities[NumberActivity]
          .presentation_apprenant,
        trainingsequence.training_activities[NumberActivity],
        'apprenant'
      )
      " type="is-text" class="mb-6">
              remplir avec le texte par défaut
            </b-button>
          </div>
          <div class="bloc-paragraph" v-if="trainingsequence.training_activities[NumberActivity]">
            <div class="title-paragraph">
              Présentation de l'activité à destination du tuteur
            </div>
            <ckeditor :editor="editor" v-model="trainingsequence.training_activities[NumberActivity]
      .presentation_tuteur_override
      " :config="editorConfig" class="mb-5"></ckeditor>
            <b-button @click="
      defaultTextPresentation(
        trainingsequence.training_activities[NumberActivity]
          .presentation_tuteur,
        trainingsequence.training_activities[NumberActivity],
        'tuteur'
      )
      " type="is-text" class="mb-6">
              remplir avec le texte par défaut
            </b-button>
          </div>
          <div class="bloc-paragraph" v-if="trainingsequence.training_activities[NumberActivity]">
            <div class="title-paragraph">
              Présentation de l'activité à destination du manager
            </div>
            <ckeditor :editor="editor" v-model="trainingsequence.training_activities[NumberActivity]
      .presentation_manager_override
      " :config="editorConfig" class="mb-5"></ckeditor>
            <b-button @click="
      defaultTextPresentation(
        trainingsequence.training_activities[NumberActivity]
          .presentation_manager,
        trainingsequence.training_activities[NumberActivity],
        'manager'
      )
      " type="is-text" class="mb-6">
              remplir avec le texte par défaut
            </b-button>
          </div>
        </div>
      </b-collapse>
      <div class="card_item cardpage_activity pb-5 mb-6">
        <div v-for="(stepActivity, i) in order(
      trainingsequence.training_activities[NumberActivity]
        .training_activity_steps
    )" :key="i">
          <div class="bloc-paragraph">
            <div class="is-flex">
              <div class="title-paragraph mr-3">{{ stepActivity.name }}</div>
              <div><span class="tag status6" v-if="stepActivity.content_type == 0">Texte libre</span>
                <span class="tag status3" v-if="stepActivity.content_type == 1">Evaluation</span>
                <span class="tag status1" v-if="stepActivity.content_type == 2">
                  Texte en lecture seule</span>
              </div>
            </div>
            <div class="subtitle-paragraph" v-html="stepActivity.description_override"></div>
            <ckeditor :editor="editor" v-model="stepActivity.content" :config="editorConfig" class="mb-5"></ckeditor>
            <b-button @click="
      defaultText(stepActivity.placeholder_override, stepActivity)
      " type="is-text" class="mb-6" v-if="stepActivity.placeholder_override">
              remplir avec le texte par défaut
            </b-button>
          </div>
        </div>
        <b-button class="mt-2" type="is-primary" rounded @click.prevent="
      saveActivity(
        trainingsequence.training_activities[NumberActivity].id,
        NumberActivity
      )
      ">enregistrer</b-button>
      </div>
    </div>
    <div class="content-page container mt-5 pt-5" v-if="step === 'resources'">
      <h1 class="title-paragraph">Ressources associées à cette expérience</h1>
      <div class="helpUser mt-0">
        <div class="title">Ajoutez des ressources</div>
        <div>
          Ici vous pouvez ajouter des documents à cette expérience. Dans
          l'édition du parcours vous pouvez également ajouter des ressources à
          certaines expériences ou activités. Pour des questions écologiques, le
          poids des fichiers est limité à 1 Mo. Si votre fichier est trop lourd,
          vous pouvez l'optimiser sur un site tel que
          <a href="https://www.wecompress.com/fr/" target="_blank"><strong>wecompress</strong></a>.
        </div>
        <div>Vous pouvez ajouter des ressources</div>
      </div>
      <div class="card_simple is-primary w-100 has-text-centered btn" @click="isOpen = !isOpen">
        <div>
          <b-icon icon="resource" type="is-primary" class="mr-3"></b-icon>
          <b style="cursor: pointer">Ajoutez une ressource</b>
        </div>
      </div>
      <b-collapse v-model="isOpen">
        <ResourceCreation :sequence="trainingsequence" :typeoftraining="'training'"
          :idTraining="this.$route.params.idTraining" :action="'post'" v-if="trainingsequence" :isOpen.sync="isOpen"
          @messageFromChild="initResources" @messageFromResource="forceRerender" :key="resourceKey"></ResourceCreation>
      </b-collapse>
      <div v-if="resources">
        <div v-if="resources.length">
          <div v-for="resource in resources.slice(0, 5)" :key="resource.id">
            <ResourceCard :resource="resource" :action="'edit'" @messageFromChild="initResources"></ResourceCard>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import _ from 'lodash';
import CKEditor from '@ckeditor/ckeditor5-vue2';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import '@ckeditor/ckeditor5-build-classic/build/translations/fr';
import moment from 'moment';
import { mapState } from 'vuex';
import ResourceCard from '@/components/Resources/ResourceCard.vue';
import ResourceCreation from '@/components/Resources/ResourceCreation.vue';
import SkillsSequenceAdd from '@/components/Skills/SkillsSequenceAdd.vue';
import SkillsSequence from '@/components/Skills/SkillsSequence.vue';

export default {
  components: {
    ResourceCreation,
    ResourceCard,
    ckeditor: CKEditor.component,
    SkillsSequenceAdd,
    SkillsSequence,
  },
  computed: {
    ...mapState('auth', ['currentUser']),
    ...mapState(['user']),
    users() {
      return this.$store.state.users;
    },
  },
  filters: {
    day(date) {
      return moment(date).format('DD/MM/YYYY');
    },
    hour(date) {
      return moment(date).format('HH:mm');
    },
  },
  data() {
    return {
      teachersOpen: false,
      trainingTeachers: [],
      sequencetype: [],
      trainingsequence: [],
      isLoading: true,
      isFullPage: false,
      currentTab: 1,
      numberStep: 1,
      step: 1,
      form: {
        name: null,
        duration: null,
        date: null,
        sequence_type: null,
        comment: null,
        goals: null,
        start_date: null,
        end_date: null,
        url: null,
      },
      formActivity: {
        start_date: null,
        end_date: null,
      },
      NumberActivity: 0,
      isOpen: false,
      resourceKey: 0,
      editor: ClassicEditor,
      editorDisabled: true,
      editorConfig: {
        language: 'fr',
        toolbar: ['heading', '|', 'bold', 'italic', 'bulletedList', 'numberedList', '|', 'outdent', 'indent', '|', 'link', '|', 'undo',
          'redo'],
        alignment: {
          options: ['left', 'right'],
        },
        heading: {
          options: [
            { model: 'paragraph', title: 'Styles de texte', class: 'ck-heading_paragraph' },
            {
              model: 'heading1',
              view: 'h1',
              title: 'Titre 1',
              class: 'ck-heading_heading1',
            },
            {
              model: 'heading2',
              view: 'h2',
              title: 'Titre 2',
              class: 'ck-heading_heading2',
            },
            {
              model: 'mark',
              view: 'mark',
              title: 'surligné',
              class: 'ck-mark',
            },
          ],
        },
        table: {
          contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells'],
        },
      },
      locale: 'fr-FR',
      resources: [],
      training: [],
    };
  },
  methods: {
    getTrainingRights() {
      this.$http
        .get(`/trainings/${this.$route.params.idTraining}/?query={teachers_can_edit,editable_without_dates}&format=json`)
        .then((response) => {
          this.training = response.data;
          if (!response.data.teachers_can_edit) {
            this.$router.push({
              name: '404',
            }).catch((error) => {
              window.console.log(error);
              this.errored = true;
            });
          }
        });
    },
    initUser() {
      this.$http
        .get(`/profile/${this.currentUser.id}/?format=json`)
        .then((response) => {
          this.$store.commit('GET_USER', response.data);
        });
    },
    initTrainingSequence() {
      this.$http
        .get(`/training_sequences/${this.$route.params.id}/?format=json`)
        .then((response) => {
          this.trainingsequence = response.data;
          const activites = this.order(this.trainingsequence.training_activities);
          this.trainingsequence.training_activities = activites;
          this.initSequencetype(this.trainingsequence.sequence_type);
          this.setdata();
          this.endLoading();
          this.initUser();
          this.initResources();
          this.initTrainingTeachers();
          if (this.currentUser.role === 'formateur') { this.getTrainingRights(); }
        });
    },
    initSequencetype(id) {
      this.$http
        .get(`/sequencetypes/${id}/?query={-activities}&format=json`)
        .then((response) => {
          this.sequencetype = response.data;
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
          this.$buefy.dialog.alert({
            title: 'Mince...',
            message: 'Une information n\'a pas pu être totalement chargée, nous vous invitons à recharger la page.',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
          });
        });
    },
    initTrainingTeachers() {
      this.$http
        .get(`/trainings/${this.$route.params.idTraining}/?query={teachers}&format=json`)
        .then((response) => {
          this.trainingTeachers = response.data;
        });
    },
    setdata() {
      //   function to load values in form for the 3 first steps
      this.form.name = this.trainingsequence.name;
      this.form.estimated_duration = this.trainingsequence.estimated_duration;
      this.form.comment = this.trainingsequence.comment;
      this.form.goals = this.trainingsequence.goals;
      this.form.start_date = new Date(this.trainingsequence.start_date);
      this.form.end_date = new Date(this.trainingsequence.end_date);
      this.form.url = this.trainingsequence.url;
    },
    updateSequence() {
      const verifStartDate = this.form.start_date;
      const verifEndDate = this.form.end_date;
      if (verifStartDate.getFullYear() === 1970) {
        this.form.start_date = null;
      }
      if (verifEndDate.getFullYear() === 1970) {
        this.form.end_date = null;
      }
      this.$http
        .patch(`/training_sequences/${this.$route.params.id}/?format=json`, {
          name: this.form.name,
          estimated_duration: this.form.estimated_duration,
          comment: this.form.comment,
          goals: this.form.goals,
          start_date: this.form.start_date,
          end_date: this.form.end_date,
          teachers: this.trainingsequence.teachers,
          url: this.form.url,
        }).then(() => {
          this.$buefy.toast.open({ message: 'Expérience mise à jour', type: 'is-success' });
          this.isOpen = false;
          this.initTrainingSequence();
        });
    },
    saveActivity(idActivity, index) {
      const arrayActivity = this.trainingsequence.training_activities[index];
      this.$http
        .patch(`/training_activities/${idActivity}/`, arrayActivity)
        .then(() => {
          this.$buefy.toast.open({ message: 'Activité mise à jour', type: 'is-success' });
        });
    },
    saveAll() {
      this.updateSequence();
      for (let i = 0; i < this.trainingsequence.training_activities.length; i += 1) {
        this.$http
          .patch(`/training_activities/${this.trainingsequence.training_activities[i].id}/`, this.trainingsequence.training_activities[i])
          .then(() => {
            // this.$buefy.toast.open({ message: 'Activité mise à jour', type: 'is-success' });
          }).catch((error) => {
            window.console.log(error);
            this.errored = true;
          });
      }
    },
    gotoStep(numberStep) {
      this.isOpen = false;
      this.currentTab = numberStep;
      this.step = numberStep;
    },
    gotoActivity(numberStep) {
      this.isOpen = false;
      this.currentTab = numberStep + 2;
      this.step = numberStep + 2;
      this.NumberActivity = numberStep;
    },
    gotoResources() {
      this.currentTab = 'resources';
      this.step = this.currentTab;
    },
    // go to a specific tab in page with stepNumber param
    goPreviousPage(step) {
      this.$buefy.dialog.confirm({
        title: 'Attention, vous êtes sur le point de fermer cette expérience',
        message: 'Vous perdrez toutes vos modifications non enregistrées.',
        confirmText: 'Fermer',
        cancelText: 'Annuler',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.$router.push({
            name: 'TrainingEdit',
            params: { idTraining: this.$route.params.idTraining, tab: step },
          });
        },
      });
    },
    order(array) {
      return _.orderBy(array, 'position');
    },
    startLoading() {
      this.isLoading = true;
    },
    endLoading() {
      this.isLoading = false;
    },
    initResources() {
      this.startLoading();
      this.$http.get(`/get_resources/?training_sequence=${this.trainingsequence.id}&sequence_type=${this.trainingsequence.sequence_type}&format=json`).then((response) => {
        this.resources = response.data.resources;
        this.endLoading();
      });
    },
    defaultTextPresentation(stepContent, field, who) {
      const textField = field;
      if (who === 'apprenant') {
        textField.presentation_apprenant_override = stepContent;
      } else if (who === 'tuteur') {
        textField.presentation_tuteur_override = stepContent;
      } else if (who === 'manager') {
        textField.presentation_manager_override = stepContent;
      }
    },
    defaultText(stepContent, ActivityStep) {
      const activity = ActivityStep;
      activity.content = stepContent;
    },
    forceRerender() {
      // Every time that forceRerender is called, our prop componentKey will change.
      this.resourceKey += 1;
    },
    clearActivityDates(idActivity) {
      this.formActivity.start_date = null;
      this.formActivity.end_date = null;
      this.saveDatesActivity(idActivity);
    },
    setDateActivity(startDate, endDate) {
      if (this.isOpen) {
        this.isOpen = false;
      } else {
        this.isOpen = true;
      }
      if (startDate == null) {
        this.formActivity.start_date = new Date();
        this.formActivity.end_date = new Date();
      } else {
        this.formActivity.start_date = new Date(startDate);
        this.formActivity.end_date = new Date(endDate);
      }
    },
    setDate(startDate, endDate) {
      if (this.isOpen) {
        this.isOpen = false;
      } else {
        this.isOpen = true;
      }
      if (startDate == null) {
        this.form.start_date = new Date();
      } else {
        this.form.start_date = new Date(startDate);
      }
      if (startDate == null) {
        this.form.end_date = new Date();
      } else {
        this.form.end_date = new Date(endDate);
      }
    },
    clearDatesSequence() {
      this.$http
        .patch(`/training_sequences/${this.$route.params.id}/?format=json`, {
          start_date: null,
          end_date: null,
        })
        .then(() => {
          this.$buefy.toast.open({ message: 'dates de l\'activité effacées', type: 'is-success' });
          this.isOpen = false;
          this.initTrainingSequence();
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
          this.$buefy.dialog.alert({
            title: 'Mince...',
            message: 'Nous n\'avons pu effacer les dates de cette activité',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
          });
        });
    },
    saveDatesActivity(idActivity) {
      this.$http
        .patch(`/training_activities/${idActivity}/`, {
          start_date: this.formActivity.start_date,
          end_date: this.formActivity.end_date,
        })
        .then(() => {
          this.$buefy.toast.open({ message: 'dates de l\'activité enregistrées', type: 'is-success' });
          this.isOpen = false;
          this.initTrainingSequence();
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
          this.$buefy.dialog.alert({
            title: 'Mince...',
            message: 'Nous n\'avons pu enregistrer les dates de cette activité',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
          });
        });
    },
    saveDateActivityFromSequence(idActivity) {
      this.$http
        .patch(`/training_activities/${idActivity}/`, {
          start_date: this.trainingsequence.start_date,
          end_date: this.trainingsequence.end_date,
        })
        .then(() => {
          this.$buefy.toast.open({ message: 'dates de l\'activité enregistrées', type: 'is-success' });
          this.isOpen = false;
          this.initTrainingSequence();
        })
        .catch((error) => {
          window.console.log(error);
          this.errored = true;
          this.$buefy.dialog.alert({
            title: 'Mince...',
            message: 'Nous n\'avons pu enregistrer les dates de cette activité',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
          });
        });
    },
    saveDateActivityForStudents(idActivity) {
      this.$buefy.dialog.confirm({
        title: 'Affecter les dates de cette activité à tous les apprenants',
        message: 'Vous allez attribuer les dates de cette activité à tous les apprenants. <b class="has-text-primary">Attention, si un apprenant a déjà une date mentionnée dans son agenda elle sera écrasée.</b>',
        confirmText: 'Attribuer',
        cancelText: 'Annuler',
        type: 'is-success',
        onConfirm: () => {
          this.$http
            .get(`/update_activity_dates/?activity_id=${idActivity}&force=1`).then(() => {
              this.$buefy.toast.open({ message: 'Les dates ont bien été attribuées', type: 'is-success' });
            }).catch((error) => {
              window.console.log(error);
              this.errored = true;
              this.$buefy.dialog.alert({
                title: 'Mince...',
                message: 'Nous n\'avons pu attribuer les dates de cette activité aux apprenants',
                type: 'is-info',
                hasIcon: true,
                icon: 'times-circle',
                ariaRole: 'alertdialog',
                ariaModal: true,
                confirmText: 'Ok',
              });
            });
        },
      });
    },
    saveDateSequenceForStudents() {
      this.$buefy.dialog.confirm({
        title: 'Affecter les dates de cette expérience à tous les apprenants',
        message: 'Vous allez attribuer les dates de cette expérience à tous les apprenants. <b class="has-text-primary">Attention, si vous avez des dates d\'activité différentes elles seront écrasées par les dates de début et fin de l\'expérience.</b> Dans ce cas, vous pouvez aller dans chaque onglet activité pour assigner des dates différentes et les attribuer à tous. <b>Par ailleurs, si un apprenant a déjà une date mentionnée dans son agenda, elle sera écrasée.</b>',
        confirmText: 'Attribuer',
        cancelText: 'Annuler',
        type: 'is-success',
        onConfirm: () => {
          this.$http
            .get(`/update_sequence_dates/?sequence_id=${this.trainingsequence.id}&force=1`).then(() => {
              this.$buefy.toast.open({ message: 'Les dates ont bien été attribuées', type: 'is-success' });
            }).catch((error) => {
              window.console.log(error);
              this.errored = true;
              this.$buefy.dialog.alert({
                title: 'Mince...',
                message: 'Nous n\'avons pu attribuer les dates de cette expérience aux apprenants',
                type: 'is-info',
                hasIcon: true,
                icon: 'times-circle',
                ariaRole: 'alertdialog',
                ariaModal: true,
                confirmText: 'Ok',
              });
            });
        },
      });
    },
    patchTeacher() {
      this.$http
        .patch(`/training_sequences/${this.$route.params.id}/`, {
          teachers: this.trainingsequence.teachers,
        })
        .then(() => {
          this.$buefy.toast.open({ message: 'formateurs enregistrés', type: 'is-success' });
          this.isOpen = false;
          this.initTrainingSequence();
        })
        .catch((error) => {
          window.console.log(error);
          this.errored = true;
          this.$buefy.dialog.alert({
            title: 'Mince...',
            message: 'Nous n\'avons pu enregistrer de formateur(s) pour cette expérience',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
          });
        });
    },
  },
  created() {
  },
  mounted() {
    this.initTrainingSequence();
  },
};
</script>
