var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"columns",attrs:{"id":"presentationTraining"}},[_c('div',{staticClass:"column"},[_c('div',{staticClass:"card_item h-100 is-flex flex-column"},[_c('div',{staticClass:"title-paragraph mt-auto"},[_vm._v("Dates de formation")]),_c('div',{staticClass:"mb-auto"},[_c('span',{staticClass:"subtitle-paragraph"},[_vm._v("Date de début : ")]),_vm._v(" "+_vm._s(_vm._f("moment")(_vm.training.start_date))+" "),_c('br'),_c('span',{staticClass:"subtitle-paragraph"},[_vm._v(" Date de fin :")]),_vm._v(" "+_vm._s(_vm._f("moment")(_vm.training.end_date))+" ")])])]),(
        _vm.currentUser.role === 'coordinateur' ||
        _vm.currentUser.role === 'superviseur'
      )?_c('div',{staticClass:"column"},[_c('div',{staticClass:"card_item card_home h-100 is-flex is-flex-direction-column"},[_c('div',{staticClass:"mt-auto title-paragraph mb-5"},[_vm._v("Modèle de référence")]),_c('div',{staticClass:"mb-auto"},[(
              _vm.currentUser.role === 'coordinateur' ||
              (_vm.currentUser.role === 'formateur' &&
                _vm.training.teachers_can_edit) ||
              _vm.currentUser.role === 'superviseur'
            )?_c('b-button',{staticClass:"is-inline",attrs:{"tag":"router-link","to":'/maquettes/' + _vm.training.legacy_training_framework,"type":"is-primary is-rounded is-link","rounded":""}},[_vm._v("Voir la maquette")]):_vm._e()],1)])]):_vm._e(),(_vm.currentUser.role === 'apprenant')?_c('div',{staticClass:"column has-text-centered"},[_c('div',{staticClass:"card_item card_home h-100 is-flex btn",on:{"click":function($event){$event.preventDefault();return _vm.seeStudentTraining()}}},[_c('div',{staticClass:"mt-auto mb-auto mr-auto ml-auto"},[_c('b-icon',{attrs:{"icon":"training"}}),_c('div',{staticClass:"title-paragraph mb-4"},[_vm._v(" Voir mon parcours de formation ")]),_c('div')],1)])]):_vm._e()]),(_vm.currentUser.role != 'apprenant')?_c('div',{staticClass:"card_item",attrs:{"id":"statusCard"}},[_c('div',{staticClass:"columns justify-content-between"},[_c('div',{staticClass:"column title-paragraph mb-3 is-flex align-items-center"},[_c('div',{staticClass:"mr-3"},[_vm._v("Statut")]),(_vm.training.active === 1)?_c('span',{staticClass:"tag is-success"},[_vm._v("active")]):_vm._e(),(_vm.training.active === 0)?_c('span',{staticClass:"tag inactif"},[_vm._v("inactive")]):_vm._e()])])]):_vm._e(),_c('div',{staticClass:"title-paragraph"},[_vm._v("Objectifs")]),_c('div',{staticClass:"field ck",domProps:{"innerHTML":_vm._s(_vm.training.goals)}}),_c('div',{staticClass:"title-paragraph"},[_vm._v("Compétences")]),_c('div',{staticClass:"field ck",domProps:{"innerHTML":_vm._s(_vm.training.skills)}})])
}
var staticRenderFns = []

export { render, staticRenderFns }