<template>
  <div class="modalbox modalStep" id="waypointModal">
    <!-- eslint-disable max-len-->
    <div class="is-flex bigmodal">
      <img class="btn-previous" :src="require('@/assets/img/arrows/previous.svg')" @click="goPreviousPage" />
      <router-link v-if="currentUser.role === 'formateur' ||
        currentUser.role === 'coordinateur'
        " class="ml-auto mr-2" :to="'/formations/' + $route.params.idTraining + '/suivi/waypointList'">
        <b-button type="is-outlined" rounded size="is-small">Formation</b-button>
      </router-link>
      <router-link class="mr-2" :to="'/formation-apprenant/' +
        $route.params.idTraining +
        '/' +
        $route.params.idStudent +
        '/' +
        $route.params.idStudentTraining +
        '/parcours'
        ">
        <b-button type="is-outlined" rounded size="is-small">Parcours</b-button>
      </router-link>
      <router-link :to="'/formation-apprenant/' +
        $route.params.idTraining +
        '/' +
        $route.params.idStudent +
        '/' +
        $route.params.idStudentTraining +
        '/waypoint'
        ">
        <b-button type="is-outlined" rounded size="is-small">Livret de suivi</b-button></router-link>
    </div>

    <section class="w-100 container">
      <b-loading :is-full-page="isFullPage" v-model="isLoading"> </b-loading>
      <div class="has-text-centered">
        <span class="tag status1 mr-1 w-auto" v-if="waypoint.type === 1">point CFA</span>
        <span class="tag status2 mr-1 w-auto" v-else>point entreprise</span>

        <span class="tag is-success mr-0 w-auto" v-if="waypoint.validated">validé</span>
        <span>
          <span class="tag is-success mr-0 w-auto ml-1" v-if="waypoint.read_by_tutor">lu par le tuteur</span><span
            v-else class="ml-1 tag is-warning mr-0 w-auto">non lu</span></span>
      </div>
      <div class="title-paragraph">
        Point d'étape
        <span v-if="waypoint.type === 1"> en centre de formation</span><span v-else-if="waypoint.type === 0"> en
          entreprise</span>
      </div>
      <div v-if="currentUser.role !== 'apprenant'" class="has-text-centered mb-4">
        <b>{{ waypoint.student }}</b>
        <div>
          <span v-if="studentTrainings.length > 1" class="is-flex align-items-center justify-content-center">
            <b-button v-if="currentUser.role === 'formateur' ||
        currentUser.role === 'coordinateur'
        " type="is-outlined" rounded size="is-small" class="mr-2 ml-2 mt-3 mb-1"
              @click="nextStudent($route.params.idStudentTraining)"><b-icon icon="chevron-right" class="mr-0">
              </b-icon>apprenant
              suivant</b-button>
            <AppHelpMessage v-if="currentUser.role === 'formateur' ||
        currentUser.role === 'coordinateur'
        " title="Faites défiler les points d'une même période pour les autres apprenants" message="Vous retrouverez ici les points d'étape ayant une même date de
               début de période." textBtn="" />
          </span>
        </div>
      </div>
      <div></div>
      <div class="columns mt-3">
        <div class="column">
          <b-field class="mb-0" label="Date de début" :type="start_date > end_date ? 'is-danger' : ''">
            <b-datepicker v-if="start_date" v-model="start_date" :first-day-of-week="1"
              placeholder="Cliquez pour sélectionner..." trap-focus @input="
        verifdate(
          new Date(waypoint.start_date),
          new Date(waypoint.end_date)
        )
        ">
              <b-button label="Aujourd'hui" type="is-primary" icon-left="calendar-today"
                @click="start_date = new Date()" /></b-datepicker></b-field>
        </div>
        <div class="column">
          <b-field class="mb-0" label="Date de fin" :type="start_date > end_date ? 'is-danger' : ''">
            <b-datepicker v-model="end_date" :first-day-of-week="1" placeholder="Cliquez pour sélectionner..."
              @input="verifdate(waypoint.start_date, waypoint.end_date)">
              <b-button label="Aujourd'hui" type="is-primary" icon-left="calendar-today"
                @click="end_date = new Date()" /> </b-datepicker></b-field>
        </div>
      </div>
      <b-message type="is-danger">
        <div v-if="start_date > end_date">
          Attention, il semble y a une incohérence entre la date de début et la
          date de fin. La date de début est postérieure à la date de fin.
        </div>
      </b-message>
      <b-message type="is-warning">
        <div v-if="start_date > new Date()">
          Attention, la date de début est dans le futur.
        </div>
        <div v-if="end_date > new Date()">
          La date de fin n'est pas encore passée. Vérifiez si c'est bien normal.
        </div>
      </b-message>
      <div class="title2">Titre du point (facultatif)</div>
      <b-input v-model="formWaypoint.title"></b-input>
      <div v-if="waypoint.type === 1">
        <div class="title2">Objectif(s) de la période</div>
        <ckeditor v-model="formWaypoint.goals" :editor="editor" :config="editorConfig" class="mb-5"></ckeditor>

        <div class="title2">
          Liste des modules travaillés pendant cette période en formation
        </div>
        <div class="listActivities" v-if="schoolSubjectsSelected.length">
          <div class="subtitle-paragraph has-text-left">
            <div>
              Vous pouvez sélectionner parmi la liste complète des modules de
              votre formation ou utilisez le champ de texte libre ci-dessous.
              Précisez ensuite, pour chacun des modules, ce que vous avez
              travaillé plus précisément.
            </div>
          </div>
          <b-collapse class="card" animation="slide" :open="isOpenActivities">
            <div slot="trigger" slot-scope="props" class="card-header" role="button">
              <div class="bg-card is-flex bg-primary align-items-center">
                <a class="card-header-icon">
                  <b-icon v-bind:icon="props.open ? 'menu-down' : 'menu-up'">
                  </b-icon>
                </a>
                <p class="card-header-title">
                  Liste des modules de votre formation
                </p>
              </div>
            </div>
            <div class="card-content">
              <div class="card_item pointer w-100" v-for="subject in schoolSubjectsSelected" :key="subject.id">
                <div class="is-flex align-items-center">
                  <b-checkbox v-model="subject.checked" :native-value="false">
                  </b-checkbox>

                  <div>
                    <div class="type-sequence">
                      {{ subject.name }}
                    </div>
                  </div>
                </div>
                <div class="ck-editor-block mt-3 mb-0" v-if="subject.checked">
                  <ckeditor v-model="subject.report" :editor="editor" :config="editorConfig" class="mb-5"
                    :ref="'revisionContent'"></ckeditor>
                </div>
              </div>
            </div>
          </b-collapse>
        </div>
        <div v-else>
          <div class="subtitle-paragraph has-text-left">
            Saisissez la liste des modules vus pendant la période en formation
            et ce que vous avez travaillé plus précisément pour chacun.
          </div>
        </div>
        <ckeditor v-model="formWaypoint.free_text" :editor="editor" :config="editorConfig" class="mb-5"
          :ref="'revisionContent'"></ckeditor>

        <b-field label="Si vous avez un document à joindre à
           ce point d'étape, vous pouvez saisir ici son url">
          <b-input v-model="formWaypoint.url"></b-input>
        </b-field>
        <div class="title2">Commentaires de l'apprenant</div>
        <div class="ck-editor-block mb-0 pb-0" v-if="currentUser.role === 'apprenant' ||
        currentUser.role === 'coordinateur'
        ">
          <ckeditor v-model="formWaypoint.comment" :editor="editor" :config="editorConfig" class="mb-5"
            :ref="'revisionContent'"></ckeditor>
        </div>
        <div v-else>
          <div v-if="waypoint.comment" v-html="waypoint.comment"></div>
          <div v-else>
            <b-message type="is-danger" size="is-small">Commentaire manquant</b-message>
          </div>
        </div>
        <div class="title2">Commentaires du pilote</div>
        <div class="ck-editor-block mb-0 pb-0" v-if="currentUser.role === 'formateur' ||
        currentUser.role === 'coordinateur'
        ">
          <ckeditor v-model="formWaypoint.teacher_comment" :editor="editor" :config="editorConfig" class="mb-5"
            :ref="'revisionContent'"></ckeditor>
        </div>
        <div class="ck-editor-block mb-0 pb-0" v-else-if="currentUser.role === 'apprenant' && studentWaypointValidate
        ">
          <ckeditor v-model="formWaypoint.teacher_comment" :editor="editor" :config="editorConfig" class="mb-5"
            :ref="'revisionContent'"></ckeditor>
        </div>
        <div v-else class="mb-5">
          <div v-html="waypoint.teacher_comment"></div>
        </div>
      </div>
      <div v-else-if="waypoint.type === 0">
        <div class="title2">Objectif(s) de la période</div>
        <ckeditor v-model="formWaypoint.goals" :editor="editor" :config="editorConfig" class="mb-5"></ckeditor>
        <div class="listActivities">
          <div class="title2">Liste des expériences vécues en entreprise</div>
          <div v-if="!this.formWaypoint.validated">
            <div class="helpUser" v-if="student_activities_report.length ||
        student_revisions_report.length ||
        free_situations_report.length
        ">
              <div>
                Vous trouverez ci-dessous la liste des expériences enregistrées
                dans inSitu dans la période que couvre ce point d'étape. Pour
                chacune, vous pouvez préciser si cela a été fait seul ou avec
                l'aide de quelqu'un. Si vous avez saisi une activité après la
                fin de ce point d'étape, utilisez le bouton "Afficher toutes les
                expériences"
              </div>
            </div>
            <b-message type="is-info" v-else size="is-small">Vous n'avez enregistré aucune nouvelle activité en
              situation de
              travail dans inSitu dans la période que couvre ce point d'étape.
              Vous pouvez ajouter une expérience libre ci-dessous, ou en ajouter
              dans le parcours. Si vous avez saisi une activité après la fin de
              ce point d'étape, utilisez le bouton "Afficher toutes les
              expériences"
            </b-message>
            <!-- {{ activities }} -->
            <div class="subtitle-paragraph has-text-left">
              <span v-if="!isSwitchedActivities"><b>Expériences enregistrées du
                  {{ waypoint.start_date | day }} au
                  {{ waypoint.end_date | day }}</b>
              </span>
              <div v-else>
                <div>
                  <b>Expériences enregistrées depuis le début de la formation</b>
                </div>
                <div>(encore non associées à un point d'étape)</div>
              </div>
            </div>
            <b-switch v-model="isSwitchedActivities" @input="changeActivities" class="mb-4"><span>Afficher toutes les
                expériences quelle que soit la date de
                saisie</span></b-switch>
            <b-collapse class="card" animation="slide" :open="isOpenActivities" v-if="student_activities_report.length">
              <div slot="trigger" slot-scope="props" class="card-header" role="button">
                <div class="bg-card is-flex bg-primary align-items-center">
                  <a class="card-header-icon">
                    <b-icon v-bind:icon="props.open ? 'menu-down' : 'menu-up'">
                    </b-icon>
                  </a>
                  <p class="card-header-title">Les expériences</p>
                </div>
              </div>

              <div class="card-content">
                <div v-for="activity in student_activities_report" :key="activity.id" class="card_activity">
                  <div class="activity-item is-flex align-items-center w-100">
                    <img :src="require(`@/assets/img/steps/status3.svg`)" alt="" class="img_status mr-3" />
                    <div class="is-flex w-100 content-steps">
                      <div class="titles">
                        <div class="mr-3">
                          <b>{{ activity.sequence_title }}</b>
                        </div>
                        <div v-if="activity.sequence_subtitle">
                          ({{ activity.sequence_subtitle }})
                        </div>
                        <div class="has-text-primary">{{ activity.title }}</div>
                      </div>
                      <div class="is-flex align-items-center card_buttons">
                        <b-field class="mb-0">
                          <b-switch v-model="activity.autonomy" type="is-success" true-value="1" false-value="0">
                            {{ activity.autonomy ? "Sans aide" : "Avec aide" }}
                          </b-switch>
                        </b-field>
                        <b-button outlined rounded type="is-primary" class="ml-auto mr-2" @click="
        gotoActivity(
          activity.student_activity,
          activity.student_sequence
        )
        ">voir</b-button>
                        <b-button outlined rounded type="is-danger" size="is-small" class="ml-auto" @click="
        removeActivity(
          activity.student_activity,
          'activity'
        )
        ">retirer</b-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </b-collapse>
            <b-collapse class="card" animation="slide" :open="isOpenActivities" v-if="student_revisions_report.length">
              <div slot="trigger" slot-scope="props" class="card-header" role="button">
                <div class="bg-card is-flex bg-primary align-items-center">
                  <a class="card-header-icon">
                    <b-icon v-bind:icon="props.open ? 'menu-down' : 'menu-up'">
                    </b-icon>
                  </a>
                  <p class="card-header-title">
                    Les expériences pour lesquelles vous avez enregistré une
                    progression
                  </p>
                </div>
              </div>
              <div class="card-content">
                <div v-for="revision in student_revisions_report" :key="revision.id" class="card_activity">
                  <div class="activity-item is-flex align-items-center w-100">
                    <div class="is-flex align-items-center w-100">
                      <img :src="require(`@/assets/img/steps/status3.svg`)" alt="" class="img_status mr-3" />
                      <div class="is-flex w-100 content-steps">
                        <div class="titles">
                          <div class="mr-3">
                            <b> {{ revision.student_sequence_title }}</b>
                          </div>
                          <div v-if="revision.sequence_subtitle">
                            ({{ revision.student_sequence_subtitle }})
                          </div>
                          <div class="has-text-primary">
                            {{ revision.title }}
                          </div>
                        </div>
                        <div class="is-flex align-items-center card_buttons">
                          <b-button outlined rounded type="is-primary" class="ml-auto mr-2" @click="
        gotoRevision(
          revision.student_activity_revision,
          revision.student_training_sequence_id,
          revision.student_sequence
        )
        ">voir</b-button><b-button outlined rounded type="is-danger" size="is-small" @click="
        removeActivity(
          revision.student_activity_revision,
          'revision'
        )
        ">retirer</b-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </b-collapse>
            <b-collapse class="card" animation="slide" :open="isOpenActivities" v-if="free_situations_report.length">
              <div slot="trigger" slot-scope="props" class="card-header" role="button">
                <div class="bg-card is-flex bg-primary align-items-center">
                  <a class="card-header-icon">
                    <b-icon v-bind:icon="props.open ? 'menu-down' : 'menu-up'">
                    </b-icon>
                  </a>
                  <p class="card-header-title">Les expériences libres</p>
                </div>
              </div>
              <div class="card-content">
                <div v-for="free in free_situations_report" :key="free.id" class="card_activity">
                  <div class="activity-item is-flex align-items-center w-100">
                    <div class="is-flex align-items-center w-100">
                      <img :src="require(`@/assets/img/steps/status3.svg`)" alt="" class="img_status mr-3" />
                      <div class="is-flex w-100 content-steps">
                        <div class="titles">
                          <div class="mr-3">
                            <b>{{ free.title }}</b>
                          </div>
                        </div>
                        <div class="is-flex align-items-center card_buttons">
                          <b-field class="mb-0">
                            <b-switch v-model="free.autonomy" type="is-success" true-value="1" false-value="0">
                              {{ free.autonomy ? "Sans aide" : "Avec aide" }}
                            </b-switch>
                          </b-field>
                          <b-button outlined rounded type="is-primary" class="ml-auto mr-2"
                            @click="gotoFreeSituation(free.free_situation)">voir</b-button><b-button outlined rounded
                            type="is-danger" class="ml-auto" size="is-small"
                            @click="removeActivity(free.free_situation, 'free')">retirer</b-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </b-collapse>

            <b-button v-if="currentUser.role === 'apprenant'" rounded type="is-primary" @click="newSequence"
              class="mt-4">Ajouter une expérience libre</b-button>
          </div>
          <div v-else>
            <div v-for="activity in waypoint.activity_reports" :key="activity.date_created">
              <div id="card_mobile" class="card_item is-flex align-items-center is-clickable" @click="
        gotoActivity(
          activity.student_activity,
          activity.student_training_sequence_id
        )
        " v-if="activity.student_activity">
                <span class="tag is-success mr-2">activité</span>
                <div>
                  <div>
                    <b>{{ activity.student_sequence_title }}</b>
                  </div>
                  <div v-if="activity.student_activity_title">
                    {{ activity.student_activity_title }}
                  </div>
                  <div v-else>
                    {{ activity.student_activity_name }}
                  </div>
                </div>
                <div class="ml-auto">
                  <span class="tag is-success" v-if="activity.autonomy === 1">
                    {{ activity.autonomy_title }}</span><span v-else class="tag is-primary is-light">
                    {{ activity.autonomy_title }}</span>
                </div>
              </div>
              <div id="card_mobile" class="card_item is-flex align-items-center is-clickable"
                v-if="activity.student_activity_revision" @click="
        gotoRevision(
          activity.student_activity_revision,
          activity.student_activity,
          activity.student_training_sequence_id
        )
        ">
                <span class="tag status6 mr-2">progression</span>
                <div>
                  <div>
                    <b>{{ activity.student_sequence_title }}</b>
                  </div>
                  <div>{{ activity.student_activity_revision_title }}</div>
                </div>
                <div class="ml-auto">
                  <span class="tag is-success" v-if="activity.autonomy === 1">
                    {{ activity.autonomy_title }}</span><span v-else class="tag is-primary is-light">
                    {{ activity.autonomy_title }}</span>
                </div>
              </div>
              <div id="card_mobile" class="card_item is-flex align-items-center is-clickable"
                @click="gotoFreeSituation(activity.free_situation)" v-if="activity.free_situation_title">
                <span class="tag status1 mr-2">activité libre</span>{{ activity.free_situation_title }}
                <div class="ml-auto">
                  <span class="tag is-success" v-if="activity.autonomy === 1">
                    {{ activity.autonomy_title }}</span><span v-else class="tag is-primary is-light">
                    {{ activity.autonomy_title }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="title2">
          Texte libre de présentation des activités en entreprise
        </div>
        <div class="subtitle-paragraph has-text-left">
          Si aucune experience ne remonte ci-dessus, que vous n'avez rien
          enregistré dans inSitu depuis le dernier point, vous pouvez aussi
          lister ce que vous avez vu en entreprise ici.
        </div>
        <ckeditor v-model="formWaypoint.free_text" :editor="editor" :config="editorConfig" class="mb-5"
          :ref="'freeText'"></ckeditor>
        <div class="title2">Commentaires de l'apprenant</div>
        <div class="ck-editor-block mb-0 pb-0">
          <ckeditor v-model="formWaypoint.comment" :editor="editor" :config="editorConfig" class="mb-5"
            :ref="'appComment'"></ckeditor>
        </div>
        <div v-if="!isStudentOnly">
          <div class="title2">Commentaires du tuteur</div>
          <div class="ck-editor-block mb-0 pb-0">
            <ckeditor v-model="formWaypoint.tutor_comment" :editor="editor" :config="editorConfig" class="mb-5"
              :ref="'tutorComment'"></ckeditor>
          </div>
        </div>
      </div>
      <div v-if="waypoint.type === 0">
        <div v-if="currentUser.role === 'tuteur'">
          <b-message type="is-success" v-if="formWaypoint.tutor_comment !== null &&
        formWaypoint.tutor_comment !== '' &&
        formWaypoint.free_text !== null &&
        formWaypoint.free_text !== '' &&
        start_date < end_date
        ">Si vous avez tout bien relu, vous pouvez valider votre point
            entreprise</b-message>
          <b-message type="is-danger" v-else>
            <span v-if="start_date > end_date">Veuillez modifier les dates</span>
            <span v-else>
              Pour valider, vous devez avoir renseigné tous les champs.</span>
          </b-message>
        </div>
        <div v-if="currentUser.role === 'apprenant' && isStudentOnly">
          <b-message type="is-success" v-if="formWaypoint.comment !== null &&
        formWaypoint.comment !== '' &&
        formWaypoint.free_text !== null &&
        formWaypoint.free_text !== '' &&
        start_date < end_date
        ">Si vous avez tout bien relu, vous pouvez enregistrer votre point
            entreprise</b-message>
          <b-message type="is-danger" v-else>
            <span v-if="start_date > end_date">Veuillez modifier les dates</span>
            <span v-else>
              Pour valider, vous devez avoir renseigné tous les champs.</span>
          </b-message>
        </div>
        <div v-if="currentUser.role === 'apprenant' && !isStudentOnly">
          <b-message type="is-success" v-if="formWaypoint.tutor_comment !== null &&
        formWaypoint.tutor_comment !== '' &&
        formWaypoint.comment !== null &&
        formWaypoint.comment !== '' &&
        formWaypoint.free_text !== null &&
        formWaypoint.free_text !== '' &&
        start_date < end_date
        ">Si vous avez tout bien relu, vous pouvez valider votre point
            entreprise</b-message>
          <b-message type="is-danger" v-else>
            <span v-if="start_date > end_date">Veuillez modifier les dates</span>
            <span v-else>
              Pour valider, vous devez avoir renseigné tous les champs.</span>
          </b-message>
        </div>
      </div>
      <div v-if="waypoint.type === 1">
        <div v-if="currentUser.role === 'apprenant' && !studentWaypointValidate">
          <b-message type="is-success" v-if="formWaypoint.comment !== null &&
        formWaypoint.comment !== '' &&
        formWaypoint.free_text !== null &&
        formWaypoint.free_text !== ''
        ">Si vous avez tout bien relu, vous pouvez enregistrer votre point
            CFA.</b-message>
          <b-message type="is-danger" v-else>
            il manque des informations
          </b-message>
        </div>
        <div v-else-if="currentUser.role === 'apprenant' && studentWaypointValidate
        ">
          <b-message type="is-success" v-if="formWaypoint.teacher_comment !== null &&
        formWaypoint.teacher_comment !== '' &&
        formWaypoint.comment !== null &&
        formWaypoint.comment !== '' &&
        formWaypoint.free_text !== null &&
        formWaypoint.free_text !== ''
        ">Si vous avez tout bien relu, vous pouvez enregistrer votre point
            CFA.</b-message>
          <b-message type="is-danger" v-else>
            Pour valider, tous les champs doivent être renseignés.
          </b-message>
        </div>
        <div v-else-if="currentUser.role === 'formateur' ||
        currentUser.role === 'coordinateur'
        ">
          <b-message type="is-success" v-if="formWaypoint.teacher_comment !== null &&
        formWaypoint.teacher_comment !== '' &&
        formWaypoint.free_text !== null &&
        formWaypoint.free_text !== ''
        ">Si vous avez tout bien relu, vous pouvez valider ce point
            CFA</b-message>
          <b-message type="is-danger" v-else>
            Pour valider, tous les champs doivent être renseignés.
          </b-message>
        </div>
      </div>
    </section>
    <div>
      <div class="is-flex is-flex-wrap-wrap activityBtns align-items-center" style="z-index: 2000">
        <div class="w-100 is-flex">
          <div class="mx-auto">
            <b-button @click="postWaypoint(false)" class="btn-action w-auto mr-1" type="is-primary is-rounded"
              rounded>enregistrer</b-button>
            <span>
              <!-- if waypoint type = 'entreprise' -->
              <b-button v-if="waypoint.type === 0 && currentUser.role === 'tuteur' &&
        formWaypoint.tutor_comment !== null &&
        formWaypoint.tutor_comment !== '' &&
        formWaypoint.free_text !== null &&
        formWaypoint.free_text !== '' &&
        start_date < end_date &&
        start_date < new Date()
        " class="btn-action w-auto" type="is-success is-rounded" rounded @click="validateWaypoint">valider</b-button>
              <b-button v-if="waypoint.type === 0 && currentUser.role === 'apprenant' &&
        !waypoint.validated && !isStudentOnly &&
        formWaypoint.tutor_comment !== null &&
        formWaypoint.tutor_comment !== '' &&
        formWaypoint.comment !== null &&
        formWaypoint.comment !== '' &&
        formWaypoint.free_text !== null &&
        formWaypoint.free_text !== '' &&
        start_date < end_date &&
        start_date < new Date()
        " class="btn-action w-auto" type="is-success is-rounded" rounded @click="validateWaypoint">valider</b-button>

              <!-- if waypoint cfa -->
              <!-- if waypoint cfa and role = 'apprenant'-->
              <b-button v-else-if="currentUser.role === 'apprenant' &&
        studentWaypointValidate &&
        waypoint.type === 1 &&
        !waypoint.validated &&
        formWaypoint.teacher_comment !== null &&
        formWaypoint.teacher_comment !== '' &&
        formWaypoint.free_text !== null &&
        formWaypoint.free_text !== '' &&
        start_date < end_date &&
        start_date < new Date()
        " class="btn-action w-auto" type="is-success is-rounded" rounded @click="validateWaypoint">valider</b-button>
              <b-button v-else-if="currentUser.role === 'formateur' &&
        waypoint.type === 1 &&
        !waypoint.validated &&
        formWaypoint.teacher_comment !== null &&
        formWaypoint.teacher_comment !== '' &&
        formWaypoint.free_text !== null &&
        formWaypoint.free_text !== '' &&
        start_date < end_date &&
        start_date < new Date()
        " class="btn-action w-auto" type="is-success is-rounded" rounded @click="validateWaypoint">valider</b-button>
              <b-button v-else-if="currentUser.role === 'coordinateur' &&
        waypoint.type === 1 &&
        !waypoint.validated &&
        formWaypoint.teacher_comment !== null &&
        formWaypoint.teacher_comment !== '' &&
        formWaypoint.free_text !== null &&
        formWaypoint.free_text !== '' &&
        start_date < end_date &&
        start_date < new Date()
        " class="btn-action w-auto" type="is-success is-rounded" rounded @click="validateWaypoint">valider</b-button>
              <span v-else><b-button v-if="waypoint.type === 0 && currentUser.role === 'apprenant' && !isStudentOnly"
                  class="btn-action w-auto" type="is-success is-rounded" rounded disabled>valider</b-button>
                <b-button v-if="currentUser.role !== 'apprenant' && waypoint.type === 1" class="btn-action w-auto"
                  type="is-success is-rounded" rounded disabled>valider</b-button><b-button v-if="currentUser.role === 'apprenant' &&
        studentWaypointValidate &&
        waypoint.type === 1
        " class="btn-action w-auto" type="is-success is-rounded" rounded disabled>valider</b-button>
              </span>
            </span>
          </div>
        </div>
        <div class="help-btn is-flex align-items-center">
          <b-button @click="removeWaypoint(waypoint.id)" class="btn-action w-auto mr-2" type="is-danger is-rounded"
            size="is-small" rounded v-if="currentUser.role == 'coordinateur' ||
        (currentUser.role == 'formateur' && !waypoint.validated)
        ">supprimer</b-button>
          <AppHelpMessage v-if="waypoint.type === 0" title="Rédigez et enregistrez votre point en entreprise" message="La liste des expériences en entreprise remonte automatiquement.
             Si rien n'a été enregistré dans inSitu, vous pouvez revenir dans l'onglet 'Parcours'
              et enregistrer des expériences. Vous pouvez aussi utiliser les champs
               commentaires pour ajouter des éléments. <b> Une fois validé, vous pourrez corriger
                  une faute, ajouter un commentaire mais vous ne pourrez plus toucher
                   aux actviités vécues." textBtn="Besoin d'aide ?" />
          <AppHelpMessage v-if="waypoint.type === 1" title="Rédigez et enregistrez votre point en centre de formation"
            message="La liste des modules remonte automatiquement.
             Si un module a été ajouté en cours de formation et n'ets pas listé ici,
              vous pouvez utiliser les champs commentaires pour préciser ce qui a été vu." textBtn="Besoin d'aide ?" />
        </div>
      </div>
    </div>

    <div class="container contentSequence pb-5 mb-5"></div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';
import CKEditor from '@ckeditor/ckeditor5-vue2';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import '@ckeditor/ckeditor5-build-classic/build/translations/fr';
import AppHelpMessage from '@/components/AppHelpMessage.vue';
import _ from 'lodash';

export default ({
  filters: {
    day(date) {
      return moment(date).format('D/MM/YYYY');
    },
  },
  components: {
    AppHelpMessage,
    ckeditor: CKEditor.component,
  },
  computed: {
    ...mapState('auth', ['currentUser']),
  },
  data() {
    return {
      isLoading: false,
      isFullPage: false,
      schoolSubjectsTraining: [],
      schoolSubjects: [],
      schoolSubjectsSelected: [],
      waypoint: [],
      waypointdeux: {
        start_date: null,
      },
      arrayReports: [],
      student_activities_report: [],
      student_revisions_report: [],
      free_situations_report: [],
      formWaypoint: {
        id: '',
        title: '',
        comment: '',
        tutor_comment: '',
        teacher_comment: '',
        start_date: null,
        end_date: null,
        reports: [],
        validated: false,
      },
      checkboxCustom: [],
      activities: [],
      isOpenActivities: false,
      isOpenRevision: false,
      isOpenFree: false,
      editor: ClassicEditor,
      editorDisabled: true,
      editorConfig: {
        language: 'fr',
        toolbar: ['heading', '|', 'bold', 'italic', 'bulletedList', 'numberedList', '|', 'outdent', 'indent', '|', 'link', '|', 'undo',
          'redo'],
        alignment: {
          options: ['left', 'right'],
        },
        heading: {
          options: [
            { model: 'paragraph', title: 'Styles de texte', class: 'ck-heading_paragraph' },
            {
              model: 'heading1',
              view: 'h1',
              title: 'Titre 1',
              class: 'ck-heading_heading1',
            },
            {
              model: 'heading2',
              view: 'h2',
              title: 'Titre 2',
              class: 'ck-heading_heading2',
            },
            {
              model: 'mark',
              view: 'mark',
              title: 'surligné',
              class: 'ck-mark',
            },
          ],
        },
        table: {
          contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells'],
        },
      },
      trainingname: '',
      start_date: null,
      end_date: null,
      studentWaypointValidate: false,
      studentValidateTutorComment: true,
      isSwitchedActivities: false,
      students: [],
      studentTrainings: [],
      isStudentOnly: false,
    };
  },
  methods: {
    startLoading() {
      this.isLoading = true;
    },
    endLoading() {
      this.isLoading = false;
    },
    initWaypoint() {
      // this.startLoading();
      const urlWs = `/agenda/student_training_waypoints/${this.$route.params.idWaypoint}/?format=json`;
      this.$http
        .get(`${urlWs}`)
        .then((response) => {
          this.formWaypoint = response.data;
          this.waypoint = response.data;
          this.start_date = new Date(this.formWaypoint.start_date);
          this.end_date = new Date(this.formWaypoint.end_date);
          if (this.waypoint.type === 1) {
            this.getSchoolSubjects();
          } else if (this.waypoint.type === 0) {
            if (!this.formWaypoint.validated) {
              this.getActivities();
            } else {
              this.endLoading();
            }
            if (this.currentUser.role === 'apprenant') {
              this.$buefy.dialog.confirm({
                title: 'Votre tuteur est-il bien avec vous pour rédiger cette activité ?',
                message: 'En cliquant sur "oui" vous certifiez être en présence de votre tuteur. Sachez que votre tuteur sera notifié et que votre responsabilité seule sera engagée si ce n\'était pas vrai.',
                hasIcon: true,
                cancelText: 'Non',
                confirmText: 'OUI, je le certifie',
                type: 'is-success',
                onConfirm: () => this.$buefy.toast.open('Vous pouvez rédiger !'),
                onCancel: () => this.setStudentOnly(),
              });
            }
          }
        });
    },
    setStudentOnly() {
      this.isStudentOnly = true;
    },
    getActivities() {
      this.startLoading();
      this.student_activities_report = [];
      this.student_revisions_report = [];
      this.free_situations_report = [];
      const startDate = moment(new Date(this.start_date)).format('YYYY-MM-DD');
      const endDate = moment(new Date(this.end_date)).format('YYYY-MM-DD');
      const urlWs = `/agenda/get_waypoint_activities?student_training=${this.$route.params.idStudentTraining}&start_date=${startDate}&end_date=${endDate}`;
      this.$http
        .get(`${urlWs}`)
        .then((response) => {
          this.activities = response.data;
          for (let i = 0; i < this.activities.student_activities.length; i += 1) {
            // const activitiesArray = this.activities.student_activities;
            this.student_activities_report.push(
              {
                autonomy: 0,
                report: null,
                student_activity: this.activities.student_activities[i].id,
                student_sequence: this.activities.student_activities[i].sequence,
                sequence_title: this.activities.student_activities[i].sequence_title,
                sequence_subtitle: this.activities.student_activities[i].sequence_subtitle,
                title: this.activities.student_activities[i].title,
              },
            );
          }
          for (let i = 0; i < this.activities.student_activity_revisions.length; i += 1) {
            const activitiesArray = this.activities.student_activity_revisions;
            this.student_revisions_report.push(
              {
                autonomy: 0,
                report: null,
                student_activity_revision: this.activities.student_activity_revisions[i].id,
                title: this.activities.student_activity_revisions[i].title,
                student_sequence: activitiesArray[i].student_sequence,
                student_sequence_title: activitiesArray[i].student_sequence_title,
                student_sequence_subtitle: activitiesArray[i].student_sequence_subtitle,
              },
            );
          }
          for (let i = 0; i < this.activities.free_situations.length; i += 1) {
            this.free_situations_report.push(
              {
                autonomy: 0,
                report: null,
                free_situation: this.activities.free_situations[i].id,
                title: this.activities.free_situations[i].title,
              },
            );
          }
          this.endLoading();
          this.isSwitchedActivities = false;
        });
    },
    refreshActivities() {
      this.startLoading();
      this.student_activities_report = [];
      this.student_revisions_report = [];
      this.free_situations_report = [];
      const urlWs = `/agenda/get_waypoint_activities?student_training=${this.$route.params.idStudentTraining}&existing_report=false`;
      this.$http
        .get(`${urlWs}`)
        .then((response) => {
          this.activities = response.data;
          for (let i = 0; i < this.activities.student_activities.length; i += 1) {
            this.student_activities_report.push(
              {
                autonomy: 0,
                report: null,
                student_activity: this.activities.student_activities[i].id,
                student_sequence: this.activities.student_activities[i].sequence,
                sequence_title: this.activities.student_activities[i].sequence_title,
                sequence_subtitle: this.activities.student_activities[i].sequence_subtitle,
                title: this.activities.student_activities[i].title,
              },
            );
          }
          for (let i = 0; i < this.activities.student_activity_revisions.length; i += 1) {
            const activitiesArray = this.activities.student_activity_revisions;
            this.student_revisions_report.push(
              {
                autonomy: 0,
                report: null,
                student_activity_revision: this.activities.student_activity_revisions[i].id,
                title: this.activities.student_activity_revisions[i].title,
                student_sequence: activitiesArray[i].student_sequence,
                student_sequence_title: activitiesArray[i].student_sequence_title,
                student_sequence_subtitle: activitiesArray[i].student_sequence_subtitle,
              },
            );
          }
          for (let i = 0; i < this.activities.free_situations.length; i += 1) {
            this.free_situations_report.push(
              {
                autonomy: 0,
                report: null,
                free_situation: this.activities.free_situations[i].id,
                title: this.activities.free_situations[i].title,
              },
            );
          }
          this.endLoading();
          this.isSwitchedActivities = true;
        });
    },
    removeActivity(id, type) {
      if (type === 'activity') {
        // eslint-disable-next-line
        this.student_activities_report = this.student_activities_report.filter((activity) => activity.student_activity !== id);
      }
      if (type === 'revision') {
        // eslint-disable-next-line
        this.student_revisions_report = this.student_revisions_report.filter((revision) => revision.student_activity_revision
          !== id);
      }
      if (type === 'free') {
        // eslint-disable-next-line
        this.free_situations_report = this.free_situations_report.filter((free) => free.free_situation !== id);
      }
    },
    getSchoolSubjects() {
      const urlWs = `/trainings/${this.$route.params.idTraining}/?query={school_subjects,name}&format=json`;
      this.$http
        .get(`${urlWs}`)
        .then((response) => {
          this.schoolSubjects = response.data.school_subjects;
          this.schoolSubjectsTraining = response.data.school_subjects;
          for (let i = 0; i < this.schoolSubjectsTraining.length; i += 1) {
            this.schoolSubjectsSelected.push(
              {
                school_subject: this.schoolSubjectsTraining[i].id,
                name: this.schoolSubjectsTraining[i].name,
                report: null,
                checked: false,
              },
            );
          }
          this.endLoading();
        });
    },
    postWaypoint(validate) {
      if (this.waypoint.type === 1) {
        for (let i = 0; i < this.schoolSubjectsSelected.length; i += 1) {
          if (this.schoolSubjectsSelected[i].checked === true) {
            this.arrayReports.push(
              {
                school_subject: this.schoolSubjectsSelected[i].school_subject,
                report: this.schoolSubjectsSelected[i].report,
              },
            );
          }
        }
      } else if (this.waypoint.type === 0) {
        for (let i = 0; i < this.student_activities_report.length; i += 1) {
          this.arrayReports.push(
            {
              autonomy: Number(this.student_activities_report[i].autonomy),
              report: this.student_activities_report[i].report,
              student_activity: this.student_activities_report[i].student_activity,
            },
          );
        }
        for (let i = 0; i < this.student_revisions_report.length; i += 1) {
          this.arrayReports.push(
            {
              autonomy: Number(this.student_revisions_report[i].autonomy),
              report: this.student_revisions_report[i].report,
              student_activity_revision: this.student_revisions_report[i].student_activity_revision,
            },
          );
        }
        for (let i = 0; i < this.free_situations_report.length; i += 1) {
          this.arrayReports.push(
            {
              autonomy: Number(this.free_situations_report[i].autonomy),
              report: this.free_situations_report[i].report,
              free_situation: this.free_situations_report[i].free_situation,
            },
          );
        }
      }
      let reportList = [];
      let read = false;
      if (validate) {
        reportList = this.arrayReports;
        if (this.waypoint.type === 0 && this.currentUser.role === 'tuteur') {
          read = true;
        }
      }
      this.$http
        .post('agenda/set_waypoint', {
          id: this.$route.params.idWaypoint,
          title: this.formWaypoint.title,
          type: this.formWaypoint.type,
          student_training: this.$route.params.idStudentTraining,
          start_date: this.start_date,
          end_date: this.end_date,
          role: this.currentUser.role,
          profile: Number(this.currentUser.id),
          goals: this.formWaypoint.goals,
          comment: this.formWaypoint.comment,
          tutor_comment: this.formWaypoint.tutor_comment,
          teacher_comment: this.formWaypoint.teacher_comment,
          validated: this.formWaypoint.validated,
          reports: reportList,
          free_text: this.formWaypoint.free_text,
          url: this.formWaypoint.url,
          read_by_tutor: read,
        }).then(() => {
          this.$buefy.toast.open({ message: 'Point d\'étape enregistré', type: 'is-success' });
          if (validate) {
            if (this.currentUser.role === 'apprenant' && this.waypoint.type === 0) {
              this.gettutor();
            } else {
              this.goPreviousPage();
            }
          }
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
        });
    },
    validateWaypoint() {
      let messageValidation = '';
      if (this.waypoint.type === 1) {
        messageValidation = 'Pilote et apprenant';
      } else if (this.waypoint.type === 0) {
        messageValidation = 'Tuteur et apprenant';
      }
      this.$buefy.dialog.confirm({
        title: 'Souhaitez-vous valider votre point d\'étape ?',
        message: `Vous êtes sur le point de valider ce point d'étape. ${messageValidation} confirment qu'ils ont bien relus le point d'étape`,
        confirmText: 'Valider',
        cancelText: 'Relire',
        type: 'is-success',
        hasIcon: true,
        onConfirm: () => {
          this.formWaypoint.validated = true;
          this.postWaypoint(true);
        },
      });
    },
    gettutor() {
      this.$http
        .get(`agenda/student_training/${this.$route.params.idStudentTraining}/?format=json`)
        .then((response) => {
          let tutors = response.data;
          tutors = tutors.tutors;
          const titlemessage = 'Relisez le point d\'étape rédigé avec votre apprenant•e';
          const textmessage = `Vous venez d'enregistrer un point d'étape avec ${this.currentUser.name}. Pouvez-vous le relire et le valider depuis votre compte inSitu ?
             <a href="${window.location.origin}/point-etape/${this.$route.params.idStudentTraining}/${this.$route.params.idTraining}/${this.$route.params.idStudent}/${this.$route.params.idWaypoint}/">Voir le point d'étape</a>`;
          const toast = 'email envoyé au tuteur';
          this.emailUser(tutors, titlemessage, textmessage, toast);
          this.endLoading();
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
        });
    },
    emailUser(listrecipients, titletext, messagetext, toast) {
      this.$http
        .post('/notifications/send_email/', {
          title: titletext,
          message: messagetext,
          recipients: listrecipients,
          sender: this.currentUser.id,
        }).then(() => {
          this.$buefy.toast.open({ message: `${toast}`, type: 'is-success' });
          this.isComponentModalActive = false;
          this.goPreviousPage();
        }).catch((error) => {
          window.console.log(error);
          this.issue = true;
          this.$buefy.dialog.alert({
            title: 'Mince...',
            message: 'Votre notification n\'a pas pu être envoyée',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
          });
        });
    },
    goPreviousPage() {
      if (this.$route.params.from === 'StudentActivity' || this.$route.params.from === 'StudentProgressionRead' || this.$route.params.from === 'StudentFreeSituationRead') {
        this.$router.push({
          name: 'StudentTraining',
          params: {
            idStudent: this.$route.params.idStudent, // need check id profile
            idTraining: this.$route.params.idTraining,
            idStudentTraining: this.$route.params.idStudentTraining,
            tab: 'waypoint',
          },
        });
      } else if (window.history.length > 1) {
        this.$router.go(-1);
      } else {
        this.$router.push({
          name: 'StudentTraining',
          params: {
            idStudent: this.$route.params.idStudent, // need check id profile
            idTraining: this.$route.params.idTraining,
            idStudentTraining: this.$route.params.idStudentTraining,
            tab: 'waypoint',
          },
        });
      }
    },
    gotoActivity(id, sequenceId) {
      this.$router.push({
        name: 'StudentActivity',
        params: {
          idTraining: this.$route.params.idTraining,
          idStudentTraining: this.$route.params.idStudentTraining,
          idActivity: id,
          idSequence: sequenceId,
          from: this.$route.name,
          idStudent: this.$route.params.idStudent,
          idWaypoint: this.$route.params.idWaypoint,
        },
      });
    },
    gotoRevision(id, studentActivity, sequence) {
      // const sequence = '1';
      this.$router.push({
        name: 'StudentProgressionRead',
        params: {
          idTraining: this.$route.params.idTraining,
          idStudentTraining: this.$route.params.idStudentTraining,
          idSequence: sequence,
          idActivity: studentActivity,
          idRevision: id,
          from: this.$route.name,
          idStudent: this.$route.params.idStudent,
          idWaypoint: this.$route.params.idWaypoint,
        },
      });
    },
    gotoFreeSituation(id) {
      this.$router.push({
        name: 'StudentFreeSituationRead',
        params: {
          idTraining: this.$route.params.idTraining,
          idStudentTraining: this.$route.params.idStudentTraining,
          idFree: id,
          from: this.$route.name,
          idStudent: this.$route.params.idStudent,
          idWaypoint: this.$route.params.idWaypoint,
        },
      });
    },
    gotoWaypointEdit(id) {
      this.$router.push({
        name: 'WaypointEdit',
        params: {
          idTraining: this.$route.params.idTraining,
          idStudent: this.$route.params.idStudent,
          idStudentTraining: this.$route.params.idStudentTraining,
          idWaypoint: id,
        },
      });
    },
    logActivity() {
      const typeAction = 'écriture waypoint';
      this.$http
        .post('agenda/logs/', {
          action: typeAction,
          student_training: this.$route.params.idStudentTraining,
          training: this.$route.params.idTraining,
          student_activity: null,
          student_sequence: null,
        })
        .then(() => {
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
        });
    },
    verifdate(start, end) {
      if (this.start_date > this.end_date) {
        this.$buefy.dialog.confirm({
          title: 'Attention, il semble y avoir un problème de dates',
          message: 'La date de début est incohérente avec la date de fin',
          confirmText: 'Annuler',
          cancelText: 'Valider',
          type: 'is-danger',
          hasIcon: true,
          onConfirm: () => {
            this.start_date = start;
            this.end_date = end;
          },
        });
      } else if (this.start_date > new Date()) {
        this.$buefy.dialog.confirm({
          title: 'Attention, la date de début ne peut pas être postérieure à aujourd\'hui',
          message: 'Voulez-vous bien changer cette date ?',
          confirmText: 'Annuler',
          cancelText: 'Valider',
          type: 'is-danger',
          hasIcon: true,
          onConfirm: () => {
            this.start_date = start;
            this.end_date = end;
          },
        });
      }
      if (this.formWaypoint.type === 0) {
        this.getActivities();
      }
    },
    removeWaypoint(id) {
      const urlWs = `/agenda/student_training_waypoints/${id}/`;
      this.$buefy.dialog.confirm({
        title: 'Supprimer un point d\'étape',
        message: 'Êtes-vous sûr de vouloir supprimer ce point ? Attention, vous perdrez tout le contenu que vous avez pu rédiger pour ce point.',
        confirmText: 'Supprimer',
        cancelText: 'Annuler',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.$http
            .delete(`${urlWs}`)
            .then(() => {
              this.$buefy.toast.open({ message: 'Point supprimé', type: 'is-success' });
              this.$router.push({
                name: 'StudentTraining',
                params: {
                  idStudent: this.$route.params.idStudent, // need check id profile
                  idTraining: this.$route.params.idTraining,
                  idStudentTraining: this.$route.params.idStudentTraining,
                  tab: 'waypoint',
                },
              });
            }).catch((error) => {
              window.console.log(error);
              this.errored = true;
              this.$buefy.dialog.alert({
                title: 'Mince...',
                message: 'Le point n\'a pas pu être supprimé.',
                type: 'is-info',
                hasIcon: true,
                icon: 'times-circle',
                ariaRole: 'alertdialog',
                ariaModal: true,
                confirmText: 'Ok',
              });
            });
        },
      });
    },
    newSequence() {
      // this.modalFreeOpen = true;
      this.$router.push({
        name: 'StudentFreeSituationEdit',
        params: {
          idTraining: this.$route.params.idTraining,
          idStudent: this.$route.params.idStudent,
          idFree: 'new',
          from: this.$route.name,
          idWaypoint: this.$route.params.idWaypoint,
        },
      });
    },
    initOptions() {
      this.startLoading();
      this.$http
        .get(`/trainings/${this.$route.params.idTraining}/?query={tab_options,related_student_trainings_from_this_training,students}&format=json`)
        .then((response) => {
          this.trainingOptions = response.data.tab_options;
          this.studentTrainings = response.data.related_student_trainings_from_this_training;
          this.students = response.data.students;
          if (this.trainingOptions.find((option) => option.code === 'studentWaypointCFAValidate')) {
            this.studentWaypointValidate = true;
          }
        }).catch((error) => {
          window.console.log(error);
        });
    },
    changeActivities() {
      window.console.log('change');
      if (this.isSwitchedActivities) {
        this.refreshActivities();
      } else {
        this.getActivities();
      }
    },
    nextStudent(idStudentTraining) {
      let result = this.studentTrainings.findIndex((element) => element === idStudentTraining);
      result = Number(result);
      let nexStudentTraining = null;
      if (this.studentTrainings[result + 1]) {
        nexStudentTraining = this.studentTrainings[result + 1];
      } else {
        const startArray = this.studentTrainings[0];
        nexStudentTraining = startArray;
      }
      if (nexStudentTraining) {
        this.$http
          .get(`/agenda/student_training_waypoints/?student_training=${nexStudentTraining}&format=json`)
          .then((response) => {
            const indexNextWaypoint = _.findKey(response.data,
              (e) => e.start_date === this.waypoint.start_date && e.type === this.waypoint.type);
            const nextWaypoint = response.data[indexNextWaypoint];
            if (nextWaypoint) {
              this.$router.push({
                name: 'WaypointEdit',
                params: {
                  idTraining: this.$route.params.idTraining,
                  idStudent: this.students[result + 1],
                  idStudentTraining: this.studentTrainings[result + 1],
                  idWaypoint: nextWaypoint.id,
                  from: 'WaypointEdit',
                },
              });
              this.initWaypoint();
            } else {
              this.nextStudent(nexStudentTraining);
            }
          });
      }
    },

    handleHistoryChange() {
      window.console.log('history');
      this.initWaypoint();
    },
  },
  created() {
    if (this.currentUser.role === 'apprenant' || this.currentUser.role === 'tuteur') { this.logActivity(); }
    this.startLoading();
    this.initOptions();
    this.initWaypoint();
  },
  mounted() {
    window.addEventListener('popstate', this.handleHistoryChange);
  },
  destroyed() {
    window.removeEventListener('popstate', this.handleHistoryChange);
  },
});
</script>

<style lang="scss" scoped>
.card_buttons {
  margin-left: auto !important;
  min-width: 198px;
}

.titles {
  max-width: 460px;
  padding-right: 1rem;
}

@media screen and (max-width: 768px) {
  .card_activity::after {
    content: unset;
    color: #c1bbd0cc;
  }

  .card_activity {
    border-bottom: 1px solid #ededed;
  }

  .card_activity:last-child {
    border-bottom: 0px solid #ededed;

    &::after {
      content: unset;
    }
  }
}

@media screen and (max-width: 576px) {
  .content-steps {
    flex-direction: column;
  }

  .card_buttons {
    margin-left: 0px !important;
    padding-top: 0.5rem;
  }

  .titles {
    flex-direction: column;
  }
}
</style>
