<template>
  <div class="is-flex">
    <AppSidebar />
    <div class="main-content">
      <div class="lineCrumbAndAccount">
        <AppBreadcrumb v-bind:crumbs="crumbs" />
        <AppAccountMenu v-bind:appAccount="crumbs" />
      </div>
      <div class="header-page is-flex flex-column">
        <div class="justify-content-between columns lineHeaderTitle">
          <div class="column">
            <div class="title-page long-title">
              {{ trainingframework.name }}
            </div>
            <div class="subtitle-infos">
              <span class="created"> Maquette créée le :</span>
              {{ trainingframework.date_created | moment }}
              <!-- par {{ trainingframework.owner }} -->
              <div>
                <span class="created">Modifiée le :</span>
                {{ trainingframework.date_updated | moment }}
              </div>
              <div class="mt-2">
                <b-tag
                  type="is-default"
                  rounded
                  v-if="trainingframework.active == 0"
                  >inactive</b-tag
                >
                <b-tag
                  type="is-success"
                  rounded
                  v-else-if="trainingframework.active == 1"
                  >active</b-tag
                ><b-tag class="ml-1 is-outlined is-rounded" outlined>{{
                  trainingframework.framework_type.name
                }}</b-tag>
              </div>
            </div>
          </div>
          <div class="column is-narrow actions">
            <b-button
              @click.prevent="saveFrameworkSteps"
              type="is-success is-rounded mr-3"
              >Enregistrer</b-button
            >
            <b-button
              @click.prevent="seeFramework(trainingframework.id)"
              type="is-primary is-rounded is-outlined"
              >Terminer l'édition</b-button
            >
          </div>
        </div>
      </div>
      <section class="mt-auto" id="header-tabs">
        <div class="b-tabs">
          <nav class="tabs">
            <ul>
              <li
                v-for="(tab, index) of tabs"
                :key="index"
                :id="tab.id"
                :class="currentTab === tab.slug ? 'is-active' : ''"
              >
                <a @click="gotoStep(tab.slug)"
                  ><span>{{ tab.name }}</span></a
                >
              </li>
              <li :class="currentTab === 'resources' ? 'is-active' : ''">
                <a @click="gotoResources"><span>Ressources</span></a>
              </li>
            </ul>
          </nav>
        </div>
      </section>
      <div class="content-page">
        <b-loading :is-full-page="isFullPage" v-model="isLoading"> </b-loading>
        <!--content page-->
        <div>
          <form>
            <section id="stepone" v-if="step == 'infos'">
              <b-field label="Titre de la maquette">
                <b-input v-model="form.name"></b-input>
              </b-field>
              <div class="columns">
                <div class="column">
                  <b-field label="Secteur" v-model="form.sector">
                    <b-select v-model="form.sector" expanded>
                      <option
                        aria-role="listitem"
                        v-for="sector in sectors"
                        :value="sector.name"
                        :key="sector.id"
                      >
                        <div v-if="sector.name">{{ sector.name }}</div>
                      </option>
                    </b-select>
                  </b-field>
                  <div class="field">
                    <b-switch
                      v-model="form.qualification"
                      true-value="1"
                      false-value="0"
                    >
                      {{ qualificationSwitch[form.qualification] }}
                    </b-switch>
                  </div>
                  <b-field label="N° de fiche">
                    <b-input v-model="form.rncp_title"></b-input>
                  </b-field>
                  <b-field label="Code NSF" v-model="form.nsfcode">
                    <b-select v-model="form.nsfcode" expanded>
                      <option
                        aria-role="listitem"
                        v-for="nsfcode in nsfcodes"
                        :value="nsfcode.name"
                        :key="nsfcode.id"
                      >
                        {{ nsfcode.name }}
                      </option>
                    </b-select>
                    {{ form.nsfcode }}
                  </b-field>
                  <b-field label="Nomenclature" v-model="form.nomenclature">
                    <b-select :value="form.nomenclature" expanded>
                      <option
                        aria-role="listitem"
                        v-for="nomenclature in nomenclatures"
                        :key="nomenclature.id"
                      >
                        {{ nomenclature.name }}
                      </option>
                    </b-select>
                  </b-field>
                </div>
                <div class="column">
                  <div class="label">Formacodes</div>
                  <div>
                    <b-message
                      type="is-info"
                      style="font-size: 0.7rem"
                      class="mb-4"
                    >
                      Vous pouvez utiliser la touche CTRL + click (Windows) ou
                      CMD + click (MacOS) pour sélectionner plusieurs
                      formacodes. Les formacodes sélectionnés sont :
                      <li
                        v-for="formacode in form.formacodes"
                        :key="formacode.id"
                      >
                        {{ formacode.name }}
                      </li>
                    </b-message>
                  </div>
                  <b-field>
                    <!-- <b-message type="is-info" style="font-size: 0.7rem">
                      Vous pouvez utiliser la touche CTRL + click (Windows) ou
                      CMD + click (MacOS) pour sélectionner plusieurs
                      formacodes. <br />
                      <br />
                    </b-message> -->
                    <b-select multiple v-model="form.formacodes">
                      <option
                        aria-role="listitem"
                        v-for="formacode in formacodes"
                        :key="formacode.id"
                        :value="formacode.id"
                      >
                        {{ formacode.name }}
                      </option>
                    </b-select>
                  </b-field>
                </div>
              </div>
              <!-- <b-button @click="nextStep" type="is-primary" rounded
                >étape suivante</b-button
              > -->
            </section>
            <section id="steptwo" v-if="step == 'situations'">
              <h1 class="title-paragraph">
                Situations professionnelles significatives
              </h1>
              <p class="subtitle-paragraph">
                Indiquez ici quelques situations professionnelles significatives
                des compétences visées. Les situations professionnelles
                significatives sont des situations qui, si elles sont toutes
                maîtrisées, suffisent à rendre compte de l’ensemble des
                compétences visées par la formation.
              </p>
              <!-- <b-field>
                <b-input type="textarea" v-model="form.goals"></b-input>
              </b-field> -->
              <div class="mb-5">
                <ckeditor
                  :editor="editor"
                  v-model="form.goals"
                  :config="editorConfig"
                  class="mb-5"
                ></ckeditor>
              </div>
              <!-- <b-button
                @click="previousStep"
                type="is-primary"
                class="mr-2"
                rounded
                >étape précédente</b-button
              >
              <b-button @click="nextStep" type="is-primary" rounded
                >étape suivante</b-button
              > -->
            </section>
            <section id="stepthree" v-if="step == 'skills'">
              <h1 class="title-paragraph">Compétences globales</h1>
              <p class="subtitle-paragraph">
                Résumez ici les principaux champs de compétences et de
                connaissances à maitriser du référentiel de cette formation pour
                les managers, tuteur et apprenants. Vous pouvez aussi ajouter
                des liens externes ( référentiel par exemple).
              </p>
              <!-- <b-field>
                <b-input
                  type="textarea"
                  v-model="form.skills"
                  :placeholder="trainingframework.skills"
                ></b-input>
              </b-field> -->
              <div class="mb-5">
                <ckeditor
                  :editor="editor"
                  v-model="form.skills"
                  :config="editorConfig"
                  class="mb-5"
                ></ckeditor>
              </div>
              <!-- <h1 class="title-paragraph">Liste des compétences</h1> -->
              <SkillsEdit
                :typeoftraining="'framework'"
                :objectId="Number(this.$route.params.id)"
              />
              <!-- <b-button
                @click="previousStep"
                type="is-primary"
                class="mr-2"
                rounded
                >étape précédente</b-button
              >
              <b-button @click="nextStep" type="is-primary" rounded
                >étape suivante</b-button
              > -->
            </section>
          </form>
          <section id="stepfour" v-if="step == 'parcours'">
            <h1 class="title-paragraph">Parcours</h1>
            <div class="helpUser mt-0">
              <div class="title">
                Créez le parcours pédagogique de votre formation
              </div>
              <div>
                Ici vous créez votre maquette de parcours pédagogique. Pas de
                notion de date ou d'utilisateurs à ce stade, seulement la trame
                des formations. Vous pouvez créer, déplacer ou supprimer des
                blocs ou des ensembles de blocs pour créer les parcours que vous
                souhaitez. Les contenus de chaque type de blocs sont détaillé
                dans l'aide.
              </div>
            </div>
            <div class="is-flex justify-content-between w-100">
              <div class="mb-5">
                <a href="#endParcours" @click="addPeriod"
                  ><button
                    class="button btn btn-secondary is-primary mr-3 is-rounded"
                  >
                    Ajouter un bloc d'expériences
                  </button></a
                >
                <b-button
                  @click.prevent="saveFrameworkSteps"
                  type="is-success is-rounded"
                  >Enregistrer</b-button
                >
              </div>
            </div>
            <div class="mb-5">
              <b-button
                rounded
                @click="isOpenPeriod = true"
                v-if="isOpenPeriod === false"
                size="is-small"
                >ouvrir tout</b-button
              >
              <b-button
                rounded
                @click="isOpenPeriod = false"
                v-if="isOpenPeriod === true"
                size="is-small"
                >fermer tout</b-button
              >
            </div>
            <div class="simpleParcours editBloc">
              <draggable
                v-model="trainingframework.periods"
                class="w-100"
                :value="trainingframework.periods"
                @end="onEndDrag"
                handle=".handle"
              >
                <b-collapse
                  class="card"
                  animation="slide"
                  v-for="(period, indexPeriod) of trainingframework.periods"
                  :key="indexPeriod"
                  :open="isOpenPeriod"
                >
                  <div
                    slot="trigger"
                    slot-scope="props"
                    class="card-header"
                    role="button"
                  >
                    <div
                      class="bg-card is-flex bg-primary align-items-center"
                      :class="'periodType' + period.type"
                    >
                      <a class="card-header-icon">
                        <b-icon :icon="props.open ? 'menu-down' : 'menu-up'">
                        </b-icon>
                      </a>
                      <b-icon
                        icon="drag"
                        size="is-medium"
                        type="is-black"
                        class="handle"
                      >
                      </b-icon>
                      <p class="card-header-title pr-3">
                        <!-- <b-input
                        type="textarea"
                        class="form-control w-100"
                        v-model="period.name"
                        placeholder="Inscrivez le nom du bloc"
                        @input="onChangeinputPeriod(indexPeriod, period.id)"
                      /> -->
                        <textarea
                          class="textarea is-small"
                          placeholder="Inscrivez le nom du bloc"
                          rows="1"
                          v-model="period.name"
                          @input="onChangeinputPeriod(indexPeriod, period.id)"
                        ></textarea>
                      </p>
                      <div class="actions is-flex align-items-center">
                        <a
                          class="actions-icon"
                          @click="
                            duplicatePeriod(
                              period.type,
                              period.name,
                              period.sequences,
                              period.id
                            )
                          "
                        >
                          <b-icon icon="copy" size="is-medium" type="is-black">
                          </b-icon>
                        </a>
                        <a
                          class="actions-icon"
                          @click="removePeriod(period.id)"
                        >
                          <b-icon
                            icon="close"
                            size="is-medium"
                            type="is-danger"
                          >
                          </b-icon>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="card-content">
                    <draggable
                      :list="period.sequences"
                      :group="{ name: 'period' }"
                      handle=".handle"
                      @end="onEndDrag"
                    >
                      <div
                        class="card-edit-sequence is-flex align-items-center w-100"
                        v-for="(sequence, index) of period.sequences"
                        :key="index"
                        :keyperiod="sequence.period"
                      >
                        <div
                          class="bloc-left is-flex mr-auto align-items-center w-100 pr-3"
                        >
                          <div class="is-flex bloc-handle">
                            <b-icon
                              icon="drag"
                              size="is-medium"
                              type="is-primary"
                              class="handle"
                            >
                            </b-icon>
                            <!-- eslint-disable max-len-->
                            <div
                              class="sequenceType situation has-text-centered justify-content-center"
                              type="situation"
                              :class="'sequenceType' + sequence.sequence_type"
                            >
                              {{ sequence.sequence_type_label }}
                            </div>
                          </div>
                          <div class="w-100">
                            <!-- <b-input
                            type="textarea"
                            size="is-small"
                            class="w-100 form-control"
                            v-model="sequence.name"
                            placeholder="Inscrivez le nom de l'expérience"
                            @input="
                              onChangeinput(index, sequence.id, indexPeriod)
                            "
                          /> -->
                            <textarea
                              class="textarea is-small"
                              placeholder="Inscrivez le nom de l'expérience'"
                              rows="1"
                              v-model="sequence.name"
                              @input="
                                onChangeinput(index, sequence.id, indexPeriod)
                              "
                            ></textarea>
                            <!-- <span class="card-header-title"
                            >{{ sequence.id }}
                          </span> -->
                          </div>
                        </div>
                        <div class="actions is-flex align-items-center">
                          <!-- <div class="is-flex align-items-center row-responsive">
                          <span class="date-period"> texte date </span>
                        </div> -->
                          <div
                            class="actions-buttons is-flex align-items-center"
                          >
                            <b-button
                              type="is-primary is-shadowed is-small mr-1"
                              size=""
                              rounded
                              @click.prevent="seeSequencedetail(sequence.id)"
                              >rédiger</b-button
                            >
                            <a
                              class="actions-icon"
                              @click.prevent="
                                duplicateSequence(indexPeriod, sequence.id)
                              "
                            >
                              <b-icon
                                icon="copy"
                                size="is-medium"
                                type="is-black"
                              >
                              </b-icon>
                            </a>
                            <a
                              class="actions-icon"
                              @click.prevent="removeSequence(sequence.id)"
                            >
                              <b-icon
                                icon="close"
                                size="is-medium"
                                type="is-danger"
                              >
                              </b-icon>
                            </a>
                          </div>
                        </div>
                      </div>
                    </draggable>

                    <div class="has-text-centered">
                      <div
                        :open="false"
                        position="is-bottom"
                        aria-id="contentIdForA11y1"
                        :id="'s' + indexPeriod"
                        class="cardClosed"
                      >
                        <div class="has-text-small mb-2 mt-5">
                          <b>Saisissez l'expérience à ajouter</b>
                        </div>
                        <div class="card_item card_addsequence mb-4">
                          <div class="columns is-vcentered">
                            <div class="column is-3">
                              <b-select
                                placeholder="type d'expérience"
                                expanded
                                v-model="formSequence.type"
                              >
                                <option
                                  v-for="sequencetype in sequencetypes"
                                  :key="sequencetype.id"
                                  :value="sequencetype.id"
                                >
                                  {{ sequencetype.name }}
                                </option>
                              </b-select>
                            </div>
                            <div class="column">
                              <b-input
                                type="textarea"
                                class="form-control w-100"
                                maxlength="217"
                                v-model="formSequence.name"
                              />
                            </div>
                            <div class="column is-narrow">
                              <b-button
                                class="is-primary"
                                @click.prevent="
                                  addSequence(
                                    indexPeriod,
                                    period.id,
                                    's' + indexPeriod
                                  )
                                "
                                :disabled="
                                  !formSequence.name || !formSequence.type
                                "
                                >Valider</b-button
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                      <button
                        class="button is-primary mb-4"
                        aria-controls="contentIdForA11y1"
                        @click="collapseBlock('s' + indexPeriod)"
                      >
                        + Ajouter une expérience
                      </button>
                    </div>
                  </div>
                </b-collapse>
              </draggable>
            </div>
            <div id="endParcours">&nbsp;</div>
          </section>
          <section id="stepfive" v-if="step == 'resources'">
            <h1 class="title-paragraph">
              Ressources associées à cette maquette
            </h1>
            <div class="helpUser mt-0">
              <div class="title">Ajoutez des ressources</div>
              <div>
                Ici vous pouvez ajouter des documents à cette maquette. Dans
                l'édition du parcours vous pouvez également ajouter des
                ressources à certaines expériences ou activités. Pour des
                questions écologiques, le poids des fichiers est limité à 1 Mo.
                Si votre fichier est trop lourd, vous pouvez l'optimiser sur un
                site tel que
                <a href="https://www.wecompress.com/fr/" target="_blank"
                  ><strong>wecompress</strong></a
                >.
              </div>
              <div>Vous pouvez ajouter des ressources</div>
            </div>
            <div
              class="card_simple is-primary w-100 has-text-centered btn"
              @click="isOpen = !isOpen"
            >
              <div>
                <b-icon icon="resource" type="is-primary" class="mr-3"></b-icon>
                <b style="cursor: pointer">Ajoutez une ressource</b>
              </div>
            </div>
            <b-collapse v-model="isOpen">
              <ResourceCreation
                :sequence="null"
                :typeoftraining="'framework'"
                :idTraining="trainingframework.id"
                :idResource="null"
                :action="'post'"
                :isOpen.sync="isOpen"
                @messageFromChild="initResources"
                @messageFromResource="forceRerender"
                :key="resourceKey"
              ></ResourceCreation>
            </b-collapse>
            <div v-if="resources">
              <div v-if="resources.length">
                <div
                  v-for="resource in resources.slice(0, 5)"
                  :key="resource.id"
                >
                  <ResourceCard
                    :resource="resource"
                    :action="'edit'"
                    :isOpen.sync="isOpen"
                    @messageFromChild="initResources"
                  ></ResourceCard>
                </div>
              </div>
            </div>
          </section>
        </div>
        <!--content-->
      </div>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import _ from 'lodash';
import moment from 'moment';
import localization from 'moment/locale/fr';
import CKEditor from '@ckeditor/ckeditor5-vue2';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import '@ckeditor/ckeditor5-build-classic/build/translations/fr';
import { mapState } from 'vuex';
import ResourceCreation from '@/components/Resources/ResourceCreation.vue';
import ResourceCard from '@/components/Resources/ResourceCard.vue';
import AppAccountMenu from '@/components/AppAccountMenu.vue';
import AppBreadcrumb from '@/components/AppBreadcrumb.vue';
import AppSidebar from '@/components/AppSidebar.vue';
import SkillsEdit from '@/components/Skills/SkillsEdit.vue';

moment.updateLocale('fr', localization);

export default {
  components: {
    AppSidebar,
    AppBreadcrumb,
    AppAccountMenu,
    draggable,
    ResourceCard,
    ResourceCreation,
    ckeditor: CKEditor.component,
    SkillsEdit,
  },
  computed: {
    ...mapState('auth', ['currentUser']),
  },
  filters: {
    moment(date) {
      return moment(date).format('D MMMM YYYY');
    },
  },
  data() {
    return {
      trainingframework: [],
      sequencetypes: [],
      isOpenPeriod: true,
      isLoading: true,
      isFullPage: false,
      currentTab: 1,
      numberStep: 1,
      qualificationSwitch: {
        1: 'Formation certifiante',
        0: 'Formation non certifiante',
      },
      step: 1,
      totalsteps: 5,
      numberFormation: 14,
      numberCompany: 14,
      numberPlaceholder: undefined,
      nomenclatures: [],
      nsfcodes: [],
      frameworktypes: [],
      formacodes: [],
      sectors: [],
      form: {
        name: null,
        sector: null,
        rncp_title: null,
        nomenclature: null,
        nsf_code: null,
        formacodes: [],
        frameworktype: null,
        description: null,
        goals: null,
        skills: null,
      },
      frameworktypevalue: 1,
      crumbs: [
        {
          text: 'Maquettes',
          href: '/maquettes',
        },
        {
          text: 'Editer une maquette',
          class: 'is-active',
        },
      ],
      tabs: [
        {
          name: 'Fiche technique',
          slug: 'infos',
          class: 'is-active',
        },
        {
          name: 'Situations',
          slug: 'situations',
        },
        {
          name: 'Compétences',
          slug: 'skills',
        },
        {
          name: 'Parcours',
          slug: 'parcours',
        },
      ],
      // variables for 'parcours'
      key: 1,
      keyperiod: 0,
      keysequence: 1,
      positionPeriod: 1,
      // this is the array which keep trainingframework
      periods: [
        {
          id: 1,
          name: '',
          training_framework: 1,
          position: 1,
          sequences: [
            {
              id: 1,
              period: 1,
              sequence: 1,
              name: '',
              sequence_type: '',
              position: 1,
            },
          ],
        },
      ],
      // variables for first "parcours"
      nb_periods_enterprise: null,
      nb_periods_cfa: null,
      sequenceType: null,
      // form to add sequences
      formSequence: {
        name: '',
        type: null,
      },
      resources: null,
      resourceKey: 0,
      isOpen: false,
      editor: ClassicEditor,
      editorDisabled: true,
      editorConfig: {
        language: 'fr',
        toolbar: ['heading', '|', 'bold', 'italic', 'bulletedList', 'numberedList', '|', 'outdent', 'indent', '|', 'link', '|', 'undo',
          'redo'],
        alignment: {
          options: ['left', 'right'],
        },
        heading: {
          options: [
            { model: 'paragraph', title: 'Styles de texte', class: 'ck-heading_paragraph' },
            {
              model: 'heading1',
              view: 'h1',
              title: 'Titre 1',
              class: 'ck-heading_heading1',
            },
            {
              model: 'heading2',
              view: 'h2',
              title: 'Titre 2',
              class: 'ck-heading_heading2',
            },
            {
              model: 'mark',
              view: 'mark',
              title: 'surligné',
              class: 'ck-mark',
            },
          ],
        },
        table: {
          contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells'],
        },
      },
    };
  },
  // computed: {
  //   goals() {
  //     return this.form.goals;
  //   },
  // },
  methods: {
    gotoStep(numberStep) {
      // to change the tab and the content
      this.currentTab = numberStep;
      this.step = numberStep;
      if (numberStep == null) {
        this.step = 1;
        this.currentTab = 1;
      }
      if (numberStep === 'resources') {
        this.gotoResources();
      }
      this.gotoTab(numberStep);
    },
    gotoTab(slug) {
      if (this.$route.path !== `/modifier-une-maquette/${this.$route.params.id}/${slug}`) {
        this.$router.push({
          name: 'FrameworkEdit',
          params: {
            tab: slug,
            id: this.$route.params.id,
          },
        }).catch((error) => {
          window.console.log(error);
        });
      }
    },
    handleHistoryChange() {
      this.gotoStep(this.$route.params.tab);
    },
    nextStep() {
      this.step += 1;
      this.currentTab = this.step;
    },
    previousStep() {
      this.step -= 1;
      this.currentTab = this.step;
    },
    initTrainingframework() {
      // to load trainingframework
      this.$http
        .get(`/training_frameworks/${this.$route.params.id}/?query={*,periods{*,sequences}}&format=json`)
        .then((response) => {
          this.trainingframework = response.data;
          this.setdata();
          this.trainingframework.periods = this.order(this.trainingframework.periods);
          for (let i = 0; i < this.trainingframework.periods.length; i += 1) {
            const myPeriods = this.trainingframework.periods;
            // eslint-disable-next-line
            this.trainingframework.periods[i].sequences = this.order(myPeriods[i].sequences);
          }
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
          this.$buefy.dialog.alert({
            title: 'Mince...',
            message: 'Nous rencontrons un problème pour afficher la maquette',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
          });
        });
    },
    initFormacodes() {
      this.$http
        .get('/formacodes/?format=json')
        .then((response) => {
          this.formacodes = response.data;
        })
        .catch((error) => {
          window.console.log(error);
          this.errored = true;
          this.$buefy.dialog.alert({
            title: 'Mince...',
            message: 'Les formacodes ne peuvent être affichées pour le moment. Tenez de rafraîchir la page.',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
          });
        });
    },
    initSectors() {
      this.$http
        .get('/sectors/?format=json')
        .then((response) => {
          this.sectors = response.data;
        })
        .catch((error) => {
          window.console.log(error);
          this.errored = true;
          this.$buefy.dialog.alert({
            title: 'Mince...',
            message: 'Les secteurs ne peuvent être affichées pour le moment. Tenez de rafraîchir la page.',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
          });
        });
    },
    initNomenclatures() {
      this.$http
        .get('/nomenclatures/?format=json')
        .then((response) => {
          this.nomenclatures = response.data;
        })
        .catch((error) => {
          window.console.log(error);
          this.errored = true;
          this.$buefy.dialog.alert({
            title: 'Mince...',
            message: 'Les nomenclatures ne peuvent être affichées pour le moment. Tenez de rafraîchir la page.',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
          });
        });
    },
    initNsfcodes() {
      this.$http
        .get('/nsfcodes/?format=json')
        .then((response) => {
          this.nsfcodes = response.data;
        })
        .catch((error) => {
          window.console.log(error);
          this.errored = true;
          this.$buefy.dialog.alert({
            title: 'Mince...',
            message: 'Les codes NSF ne peuvent être affichées pour le moment. Tenez de rafraîchir la page.',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
          });
        });
    },
    initFrameworktypes() {
      this.$http
        .get('/frameworktypes/?format=json')
        .then((response) => {
          this.frameworktypes = response.data;
        })
        .catch((error) => {
          window.console.log(error);
          this.errored = true;
          this.$buefy.dialog.alert({
            title: 'Mince...',
            message: 'Les codes NSF ne peuvent être affichées pour le moment. Tenez de rafraîchir la page.',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
          });
        });
    },
    initSequencetypes() {
      this.$http
        .get('/sequencetypes/?format=json')
        .then((response) => {
          this.sequencetypes = response.data;
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
        });
    },
    editFramework() {
      this.$http
        .patch(`/training_frameworks/${this.$route.params.id}/`, this.form)
        .then(() => {
          this.$buefy.toast.open({ message: 'Maquette modifiée', type: 'is-success' });
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
          this.$buefy.dialog.alert({
            title: 'Mince...',
            message: 'Une erreur s\'est produite dans l\'enregistrement de la maquette',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
          });
        });
    },
    // methodes édition parcours
    editFrameworkSteps() {
      // to patch all the periods displayed
      this.refreshOrder();
      for (let i = 0; i < this.periods.length; i += 1) {
        this.$http
          .patch(`/period/${this.periods[i].id}/`, {
            position: this.periods[i].position,
            name: this.periods[i].name,
          })
          .then(() => {
            // window.console.log('patch periode');
            // window.console.log(response.data);
            for (let j = 0; j < this.periods[i].sequences.length; j += 1) {
              this.$http
                .patch(`/sequences/${this.periods[i].sequences[j].id}/`, {
                  period: this.periods[i].id,
                  position: this.periods[i].sequences[j].position,
                  name: this.periods[i].sequences[j].name,
                }).then(() => {
                  // window.console.log('patch sequence');
                  // window.console.log(response2.data);
                });
            }
          }).catch((error) => {
            window.console.log(error);
            this.errored = true;
            this.$buefy.dialog.alert({
              title: 'Mince...',
              message: 'Une erreur s\'est produite',
              type: 'is-info',
              hasIcon: true,
              icon: 'times-circle',
              ariaRole: 'alertdialog',
              ariaModal: true,
              confirmText: 'Ok',
            });
          });
      }
      this.$buefy.toast.open({ message: 'Parcours enregistré', type: 'is-success' });
    },
    saveFrameworkSteps() {
      this.editFramework();
      this.editFrameworkSteps();
      // this.$buefy.dialog.confirm({
      //   title: 'Transformer la maquette en formation ?',
      //   message: 'Voulez-vous transformer cette maquette
      // en formation ou préférez-vous continuer à la peaufiner ?',
      //   confirmText: 'Ouvrir une formation',
      //   cancelText: 'Continuer',
      //   type: 'is-success',
      //   hasIcon: false,
      //   onConfirm: () => {
      //     this.openTraining();
      //   },
      // });
    },
    populatePeriods() {
      // to populate the array "periods"
      this.periods = this.trainingframework.periods;
    },
    removePeriod(idperiod) {
      // this.periods.splice(index, 1);
      // this line removes period in array periods
      this.$buefy.dialog.confirm({
        title: 'Supprimer un bloc',
        message: 'Êtes-vous sûr de vouloir supprimer ce bloc ? Attention, vous perdrez tout le contenu que vous avez pu rédiger pour ce bloc et toutes les expériences associées.',
        confirmText: 'Supprimer',
        cancelText: 'Annuler',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.$http
            .delete(`/period/${idperiod}/?format=json`)
            .then(() => {
              this.initTrainingframework();
              this.populatePeriods();
              this.$buefy.toast.open({ message: 'Bloc supprimé', type: 'is-success' });
            }).catch((error) => {
              window.console.log(error);
              this.errored = true;
              this.$buefy.dialog.alert({
                title: 'Mince...',
                message: 'Le bloc n\'a pas pu être supprimé.',
                type: 'is-info',
                hasIcon: true,
                icon: 'times-circle',
                ariaRole: 'alertdialog',
                ariaModal: true,
                confirmText: 'Ok',
              });
            });
        },
      });
    },
    removeSequence(idsequence) {
      // this line removes sequence (inde) in its period (keyperiod)
      this.$buefy.dialog.confirm({
        title: 'Supprimer une expérience',
        message: 'Êtes-vous sûr de vouloir supprimer cette expérience ? Attention, vous perdrez tout le contenu que vous avez pu rédiger pour cette expérience et ses activités.',
        confirmText: 'Supprimer',
        cancelText: 'Annuler',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.$http
            .delete(`/sequences/${idsequence}/?format=json`)
            .then(() => {
              this.initTrainingframework();
              this.refreshOrder();
              this.populatePeriods();
              this.$buefy.toast.open({ message: 'Expérience supprimée', type: 'is-success' });
            }).catch((error) => {
              window.console.log(error);
              this.errored = true;
              this.$buefy.dialog.alert({
                title: 'Mince...',
                message: 'L\'expérience n\'a pas pu être supprimée.',
                type: 'is-info',
                hasIcon: true,
                icon: 'times-circle',
                ariaRole: 'alertdialog',
                ariaModal: true,
                confirmText: 'Ok',
              });
            });
        },
      });
    },
    duplicatePeriod(typePeriod, nameperiod, sequences, id) {
      this.$http
        .post('/period/', {
          prev: id,
          name: `${nameperiod} copie`,
          training_framework: this.$route.params.id,
          position: this.periods.length + 1,
          sequences: [],
          type: typePeriod,
        }).then(() => {
          this.initTrainingframework();
          this.populatePeriods();
          this.$buefy.toast.open({ message: 'Bloc dupliqué', type: 'is-success' });
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
          this.$buefy.dialog.alert({
            title: 'Mince...',
            message: 'Le bloc n\'a pas pu être dupliqué.',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
          });
        });
    },
    duplicateSequence(keyperiod, idSequence) {
      window.console.log('duplicate');
      this.populatePeriods();
      this.$http
        .get(`/sequences/${idSequence}/?format=json`)
        .then((response) => {
          const sequencetocopy = response.data;
          this.$http
            .post('/sequences/', {
              prev: sequencetocopy.id,
              name: `${sequencetocopy.name} copie`,
              position: this.periods[keyperiod].sequences.length + 1,
              short_description: sequencetocopy.short_description,
              long_description: sequencetocopy.long_description,
              comment: sequencetocopy.comment,
              estimated_duration: sequencetocopy.estimated_duration,
              goals: sequencetocopy.goals,
              pre_requisites: sequencetocopy.pre_requisites,
              active: sequencetocopy.active,
              sequence_type: sequencetocopy.sequence_type,
              formacode: sequencetocopy.formacode,
              resources: sequencetocopy.resources,
              sequence_type_label: sequencetocopy.sequence_type_label,
              period: sequencetocopy.period,
              skills: [],
              levels: [],
            })
            .then(() => {
              this.initTrainingframework();
              this.populatePeriods();
              this.$buefy.toast.open({ message: 'expérience ajoutée', type: 'is-success' });
            }).catch((error) => {
              window.console.log(error);
              this.errored = true;
              this.$buefy.dialog.alert({
                title: 'Mince...',
                message: 'L\'expérience n\'a pas pu être dupliquée.',
                type: 'is-info',
                hasIcon: true,
                icon: 'times-circle',
                ariaRole: 'alertdialog',
                ariaModal: true,
                confirmText: 'Ok',
              });
            });
        });
    },
    refreshOrder() {
      // to calculate position of periods and all sequences inside
      this.periods = this.trainingframework.periods;
      const arrayPeriods = this.periods;
      let newIndex = 1; // takes the name of sequence pasted
      let newIndex2 = 2;
      let keySequence = 9;
      // eslint-disable-next-line
      Object.keys(this.periods).forEach(function (key) {
        newIndex = Number(key) + 1; // forces the key value to become a number
        arrayPeriods[key].position = newIndex;
        // takes the position from the position of the block
        // arrayPeriods[key].period = newIndex;
        // takes the number period from the position of the block
        keySequence = Number(key);
        // eslint-disable-next-line
        Object.keys(arrayPeriods[keySequence].sequences).forEach(function (key) {
          // this loop takes the position of each sequence block
          newIndex2 = Number(key) + 1;
          arrayPeriods[keySequence].sequences[key].position = newIndex2;
          arrayPeriods[keySequence].sequences[key].period = arrayPeriods[keySequence].id;
        });
      });
      // this return good periods array
      // return JSON.stringify(this.value, null, 2);
    },
    addPeriod() {
      //   function to add period in the front and the back,
      this.$http
        .post('/period/', {
          name: 'Nom du bloc',
          training_framework: this.$route.params.id,
          period: this.periods.length + 1,
          position: this.periods.length + 1,
          sequences: [
          ],
        })
        .then(() => {
          this.initTrainingframework();
          this.populatePeriods();
          this.$buefy.toast.open({ message: 'Bloc ajouté', type: 'is-success' });
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
          this.$buefy.dialog.alert({
            title: 'Mince...',
            message: 'Le bloc n\'a pas pu être ajouté.',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
          });
        });
    },
    addSequence(index, idperiod, idBlock) {
      //   function to add a sequence in the front and the back,
      this.$http
        .post('/sequences/', {
          name: this.formSequence.name,
          period: idperiod,
          position: this.periods[index].sequences.length + 1,
          sequence_type: this.formSequence.type,
        })
        .then(() => {
          this.initTrainingframework();
          this.populatePeriods();
          this.$buefy.toast.open({ message: 'expérience ajoutée', type: 'is-success' });
          this.collapseBlock(idBlock);
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
          this.$buefy.dialog.alert({
            title: 'Mince...',
            message: 'L\'expérience n\'a pas pu être ajoutée. Son titre est peut-être trop long. Vous pouvez écrire un texte de maximum 217 caractères.',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
          });
        });
    },
    order(array) {
      return _.orderBy(array, 'position');
    },
    setdata() {
      //   function to load values in form for the 3 first steps
      this.form.name = this.trainingframework.name;
      this.form.sector = this.trainingframework.sector.name;
      this.form.nsfcode = this.trainingframework.nsf_code.name;
      this.form.nomenclature = this.trainingframework.nomenclature.name;
      this.form.formacodes = this.trainingframework.formacodes;
      this.form.goals = this.trainingframework.goals;
      this.form.skills = this.trainingframework.skills;
      this.form.rncp_title = this.trainingframework.rncp_title;
      this.periods = this.trainingframework.periods;
      this.endLoading();
    },
    onEndDrag() {
      // when the drag is ended it save on backend position changes
      this.editFrameworkSteps();
    },
    onChangeType() {
      this.editFrameworkSteps();
    },
    onChangeinput(index, idSequence, keyperiod) {
      this.populatePeriods();
      // when input field is changed - need to verify this
      // window.console.log(`index${index}idSequence${idSequence}keyperiod${keyperiod}`);
      this.$http
        .patch(`/sequences/${idSequence}/`, {
          name: this.periods[keyperiod].sequences[index].name,
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
          this.$buefy.dialog.alert({
            title: 'Mince...',
            message: 'Le titre ne peut être nul ou supérieur à 217 caractères.',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
          });
        });
    },
    onChangeinputPeriod(index, idperiod) {
      this.populatePeriods();
      // when input field is changed - need to verify this
      this.$http
        .patch(`/period/${idperiod}/`, {
          name: this.periods[index].name,
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
          this.$buefy.dialog.alert({
            title: 'Mince...',
            message: 'Le titre ne peut être nul ou supérieur à 217 caractères.',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
          });
        });
    },
    seeFramework(param) {
      // to go to page framework.vue
      this.$router.push({
        name: 'Maquette',
        params: { id: param, tab: this.step },
      });
    },
    seeSequencedetail(param) {
      // function to see the sequence page
      this.$router.push({
        name: 'FrameworkSequenceDetailEdit',
        params: { id: param, idFramework: this.$route.params.id },
      });
    },
    collapseBlock(id) {
      const element = document.getElementById(id);
      element.classList.toggle('blockOpen');
      this.formSequence.name = null;
      this.formSequence.type = null;
    },
    startLoading() {
      this.isLoading = true;
    },
    endLoading() {
      this.isLoading = false;
    },
    openTraining() {
      this.$http
        .post('/trainings/', {
          name: this.trainingframework.name,
          legacy_training_framework: this.trainingframework.id,
          skills: this.trainingframework.skills,
          goals: this.trainingframework.goals,
          cfa: this.currentUser.cfa_id,
          organization: null,
          students: [],
          teachers: [],
          tutors: [],
          managers: [],
          rncp_title: this.trainingframework.rncp_title,
          qualification: this.trainingframework.qualification,
          description: this.trainingframework.description,
          start_date: new Date(),
          end_date: new Date(),
          active: 1,
        })
        .then((response) => {
          this.$buefy.toast.open({ message: 'Formation créée', type: 'is-success' });
          this.$router.push({
            name: 'TrainingEdit',
            params: { idTraining: response.data.id, tab: 'session' },
          });
        });
    },
    initResources() {
      this.startLoading();
      this.$http.get(`/get_resources/?framework=${this.$route.params.id}&format=json`).then((response) => {
        this.resources = response.data.resources;
        this.endLoading();
      });
    },
    gotoResources() {
      this.currentTab = 'resources';
      this.step = this.currentTab;
      this.initResources();
    },
    forceRerender() {
      // Every time that forceRerender is called, our prop componentKey will change.
      this.resourceKey += 1;
    },
  },
  created() {
    this.initFormacodes();
    this.initSectors();
    this.initNomenclatures();
    this.initNsfcodes();
    this.initFrameworktypes();
    this.initTrainingframework();
    this.initSequencetypes();
    // this.gotoStep(this.$route.params.stepNumber);
    // if (this.$route.params.stepNumber === 'resources') {
    //   this.gotoResources();
    // }
    if (this.$route.params.tab) {
      this.gotoStep(this.$route.params.tab);
    } else {
      this.gotoStep('session');
    }
  },
  mounted() {
    window.addEventListener('popstate', this.handleHistoryChange);
  },
  destroyed() {
    window.removeEventListener('popstate', this.handleHistoryChange);
  },
};
</script>

<style lang="scss">
.cardClosed {
  display: none;
}
.blockOpen {
  display: block;
}
</style>
