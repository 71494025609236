import {
  required, confirmed, email,
} from 'vee-validate/dist/rules';
import { extend } from 'vee-validate';

extend('required', {
  ...required,
  message: 'Ce champ est obligatoire',
});

extend('email', {
  ...email,
  message: 'Vérifiez l\'adresse email',
});

extend('confirmed', {
  ...confirmed,
  message: 'La confirmation du champ n\'est pas valable',
});
