<template>
  <div class="is-flex">
    <!-- eslint-disable max-len-->
    <AppSidebar />
    <div class="main-content">
      <div class="lineCrumbAndAccount">
        <AppBreadcrumb v-bind:crumbs="crumbs" />
        <AppAccountMenu v-bind:appAccount="crumbs" />
      </div>
      <div class="header-page is-flex flex-column">
        <div class="justify-content-between columns lineHeaderTitle">
          <div class="column">
            <div class="title-page">{{ training.name }}</div>
            <div class="subtitle-infos">
              <div class="">
                <span class="created"> Formation créée le : </span>
                {{ training.date_created | moment }}
              </div>
              <div>
                <span class="created">Modifiée le :</span>
                {{ training.date_updated | moment }}
              </div>
            </div>
            <div class="mt-3">
              <span v-if="training.active === 1" class="tag is-success">active</span>
              <span v-if="training.active === 0" class="tag inactif">inactive</span>
              <b-tag class="ml-1 is-outlined is-rounded" outlined v-if="training.framework_type">{{
          training.framework_type.name }}</b-tag>
            </div>
          </div>
          <div class="column is-narrow actions">
            <span>
              <b-button @click="editTraining()" type="is-success is-rounded mr-3" v-if="currentUser.role === 'coordinateur' ||
          currentUser.role === 'formateur' ||
          currentUser.role === 'superviseur'
          ">Enregistrer</b-button>
              <b-button @click="seeTraining" type="is-primary is-rounded mr-3" outlined v-if="currentUser.role === 'coordinateur' ||
          currentUser.role === 'formateur' ||
          currentUser.role === 'superviseur'
          ">Terminer l'édition</b-button>
            </span>
          </div>
        </div>
        <div v-if="training">
          <b-message type="is-danger" v-if="training.finished" class="mb-0">Attention, cette période de formation est
            indiquée comme
            terminée</b-message>
        </div>
      </div>
      <section class="mt-auto" id="header-tabs">
        <div class="b-tabs">
          <nav class="tabs">
            <ul>
              <li v-for="(tab, index) of tabs" :key="index" :id="tab.id"
                :class="currentTab === tab.slug ? 'is-active' : ''">
                <a @click="gotoStep(tab.slug)" v-if="tab.visible"><span>{{ tab.name }}</span></a>
              </li>
              <li :class="currentTab === 'resources' ? 'is-active' : ''">
                <a @click="gotoResources"><span>Ressources</span></a>
              </li>
            </ul>
          </nav>
        </div>
      </section>
      <b-loading :is-full-page="isFullPage" v-model="isLoading"> </b-loading>
      <div class="">
        <!--content page-->
        <form>
          <section id="stepone" class="content-page" v-if="step == 'session'">
            <div class="">
              <h1 class="title-paragraph">Nom de la formation</h1>
              <p class="subtitle-paragraph">
                Précisez le nom de la formation, type, session. Exemple :
                "bachelor maintenance - alternance - 2020/2021"
              </p>
              <b-field>
                <b-input v-model="form.name"></b-input>
              </b-field>
            </div>
            <div class="mb-6">
              <h1 class="title-paragraph">Dates de la formation</h1>
              <p class="subtitle-paragraph">
                Renseignez les dates de cette session de formation
              </p>
              <div class="columns is-desktop align-items-start">
                <div class="column is-narrow">
                  <b-field label="Date de début">
                    <div role="button" aria-haspopup="true" class="dropdown-trigger mb-5">
                      <div class="control has-icons-left is-clearfix" style="min-width: 12rem">
                        <input @click="dateStartOpen = !dateStartOpen" type="text" autocomplete="off"
                          readonly="readonly" class="input" :value="form.start_date | moment" /><span
                          class="icon is-left"><i class="mdi mdi-calendar-today mdi-24px"></i></span><!----><!---->
                      </div>
                    </div>
                    <div v-if="dateStartOpen">
                      <b-datepicker v-model="form.start_date" :show-week-number="showWeekNumber" :locale="locale"
                        icon="calendar-today" :first-day-of-week="1" inline>
                      </b-datepicker>
                    </div>
                  </b-field>
                </div>
                <div class="column is-narrow">
                  <b-field label="Date de fin">
                    <div role="button" aria-haspopup="true" class="dropdown-trigger mb-5">
                      <div class="control has-icons-left is-clearfix" style="min-width: 12rem">
                        <input @click="dateEndOpen = !dateEndOpen" type="text" autocomplete="off" readonly="readonly"
                          class="input" :value="form.end_date | moment" /><span class="icon is-left"><i
                            class="mdi mdi-calendar-today mdi-24px"></i></span><!----><!---->
                      </div>
                    </div>
                    <div v-if="dateEndOpen">
                      <b-datepicker v-model="form.end_date" :show-week-number="showWeekNumber" :locale="locale"
                        icon="calendar-today" :first-day-of-week="1" inline>
                      </b-datepicker>
                    </div>
                  </b-field>
                </div>

                <div>
                  <b-button @click.prevent="editTraining" @click="
          (dateStartOpen = !dateStartOpen) &&
          (dateEndOpen = !dateEndOpen)
          " style="margin-top: 2.4rem" type="is-primary" rounded>enregistrer</b-button>
                </div>
              </div>
            </div>
            <div class="card_item">
              <div class="columns justify-content-between">
                <div class="column title-paragraph mb-3 is-flex align-items-center">
                  <div class="mr-3">Statut</div>

                  <span v-if="training.active === 1" class="tag is-success">active</span>

                  <span v-if="training.active === 0" class="tag inactif">inactive</span>
                </div>
                <div class="column is-narrow">
                  <span v-if="training.active === 1">
                    <b-button @click="closeTraining()" type="is-danger is-rounded mr-3" outlined v-if="currentUser.role === 'coordinateur' ||
          currentUser.role === 'formateur' ||
          currentUser.role === 'superviseur'
          ">désactiver la formation</b-button><b-button type="is-success is-rounded mr-3" @click="finishTraining" v-if="currentUser.role === 'coordinateur' ||
          currentUser.role === 'formateur' ||
          currentUser.role === 'superviseur'
          ">Marquer comme terminée</b-button>
                  </span>
                  <span v-if="training.active === 0">
                    <b-button @click="reopenTraining()" type="is-primary is-rounded mr-3" outlined v-if="currentUser.role === 'coordinateur' ||
          currentUser.role === 'formateur' ||
          currentUser.role === 'superviseur'
          ">réactiver la formation</b-button><b-button type="is-success is-rounded mr-3" @click="finishTraining" v-if="currentUser.role === 'coordinateur' ||
          currentUser.role === 'formateur' ||
          currentUser.role === 'superviseur'
          ">Marquer comme terminée</b-button>
                  </span>
                </div>
              </div>
            </div>

            <div class="title-paragraph">Objectifs</div>
            <ckeditor :editor="editor" v-model="form.goals" :config="editorConfig" class="mb-5"></ckeditor>
            <div class="title-paragraph mt-5">Compétences</div>
            <ckeditor :editor="editor" v-model="form.skills" :config="editorConfig"></ckeditor>
            <b-button @click.prevent="editTraining" class="mt-5" type="is-primary" rounded>enregistrer</b-button>
          </section>
        </form>

        <section id="steptwo" class="content-page" v-if="step == 'parcours'">
          <div v-if="user.edit_rights < 3">
            <TrainingParcoursEdit />
          </div>
          <div v-else>
            <b-message type="is-info">Vous n'avez pas les droits pour éditer les compétences de la
              formation</b-message>
          </div>
        </section>
        <section id="stepthree" v-if="step == 'users'">
          <div v-if="user.edit_rights === 0">
            <TrainingsUsersEdit v-bind:trainingObject="training" />
          </div>
          <div v-else class="content-page">
            <b-message type="is-info">Vous n'avez pas les droits pour éditer les
              utilisateurs</b-message>
          </div>
        </section>
        <section id="stepfour" class="content-page" v-if="step == 'supervision'">
          <UsersToStudents v-bind:training="training" />
        </section>
        <section id="stepfive" class="content-page" v-if="step == 'suivi'">
          <StudentsTrainings @messageFromChild="gotoStep(7)" />
        </section>
        <section id="agenda" class="content-page" v-if="step == 'agenda'">
          <div v-if="training.editable_without_dates">
            <div class="userCount mb-4">Agenda</div>
            <div class="subtitle-paragraph">
              Vous pouvez noter les dates clés de la formation
            </div>
            <ckeditor :editor="editor" v-model="form.description" :config="editorConfig" class="mb-5"></ckeditor>
          </div>
          <AgendaStudent v-else :dates="dates" :global="true" :step="step" :key="planningKey" />
        </section>
        <section id="stepseven" class="content-page" v-if="step == 'skills'">
          <div v-if="user.edit_rights < 2">
            <SkillsEdit :typeoftraining="'training'" :objectId="this.$route.params.idTraining" />
          </div>
          <div v-else>
            <b-message type="is-info">Vous n'avez pas les droits pour éditer les compétences de la
              formation</b-message>
          </div>
        </section>
        <section id="stepsix" class="content-page" v-if="step == 'resources'">
          <h1 class="title-paragraph">
            Ressources associées à cette formation
          </h1>
          <div class="helpUser mt-0">
            <div class="title">Ajoutez des ressources</div>
            <div>
              Ici vous pouvez ajouter des documents à cette formation. Dans
              l'édition du parcours vous pouvez également ajouter des ressources
              à certaines expériences ou activités.
            </div>
          </div>

          <div class="card_simple is-primary w-100 has-text-centered" @click="isOpen = !isOpen">
            <div>
              <b-icon icon="resource" type="is-primary" class="mr-3"></b-icon>
              <b style="cursor: pointer">Ajoutez une ressource</b>
            </div>
          </div>
          <b-collapse v-model="isOpen">
            <ResourceCreation :sequence="null" :typeoftraining="'training'" :idTraining="training.id" :idResource="null"
              :action="'post'" :isOpen.sync="isOpen" @messageFromChild="initResources"
              @messageFromResource="forceRerender" :key="resourceKey"></ResourceCreation>
          </b-collapse>
          <div v-if="resources">
            <div v-if="resources.length">
              <div v-for="resource in resources" :key="resource.id">
                <ResourceCard :resource="resource" :action="'edit'" @messageFromChild="initResources"></ResourceCard>
              </div>
            </div>
          </div>
        </section>
        <section id="steptwo" class="content-page" v-if="step == 'options'">
          <div v-if="user.edit_rights < 3">
            <TrainingOptions :typeoftraining="'training'" :trainingInfos="this.training" />
          </div>
          <div v-else>
            <b-message type="is-info">Vous n'avez pas les droits pour éditer les options de la
              formation</b-message>
          </div>
        </section>
        <!--content-->
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import localization from 'moment/locale/fr';
import { mapState } from 'vuex';
import CKEditor from '@ckeditor/ckeditor5-vue2';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import '@ckeditor/ckeditor5-build-classic/build/translations/fr';
import AppSidebar from '@/components/AppSidebar.vue';
import AppBreadcrumb from '@/components/AppBreadcrumb.vue';
import AppAccountMenu from '@/components/AppAccountMenu.vue';
import TrainingsUsersEdit from '@/components/Trainings/TrainingsUsersEdit.vue';
import StudentsTrainings from '@/components/Trainings/StudentsTrainings.vue';
import UsersToStudents from '@/components/Trainings/UsersToStudents.vue';
import ResourceCard from '@/components/Resources/ResourceCard.vue';
import ResourceCreation from '@/components/Resources/ResourceCreation.vue';
import AgendaStudent from '@/components/Agenda/AgendaStudent.vue';
import SkillsEdit from '@/components/Skills/SkillsEdit.vue';
import TrainingParcoursEdit from '@/components/Trainings/TrainingParcoursEdit.vue';
import TrainingOptions from '@/components/Trainings/TrainingOptions.vue';

moment.updateLocale('fr', localization);

export default {
  components: {
    AppSidebar,
    AppBreadcrumb,
    AppAccountMenu,
    TrainingsUsersEdit,
    StudentsTrainings,
    UsersToStudents,
    ResourceCard,
    ResourceCreation,
    AgendaStudent,
    ckeditor: CKEditor.component,
    SkillsEdit,
    TrainingParcoursEdit,
    TrainingOptions,
  },
  filters: {
    moment(date) {
      return moment(date).format('D MMMM YYYY');
    },
    day(date) {
      return moment(date).format('D/MM/YY');
    },
    hour(date) {
      return moment(date).format('HH:mm');
    },
  },
  computed: {
    users() {
      return this.$store.state.users;
    },
    ...mapState('auth', ['currentUser']),
    ...mapState(['user']),
  },
  methods: {
    getTrainingRights() {
      this.$http
        .get(`/trainings/${this.$route.params.idTraining}/?query={teachers_can_edit}&format=json`)
        .then((response) => {
          if (!response.data.teachers_can_edit) {
            this.$router.push({
              name: '404',
            }).catch((error) => {
              window.console.log(error);
              this.errored = true;
            });
          }
        });
      this.verifyOptions();
    },
    verifyOptions() {
      if (this.user.edit_rights > 0) {
        const tabindex = _.findKey(this.tabs, { slug: 'users' });
        this.tabs[tabindex].visible = false;
        const tabindex2 = _.findKey(this.tabs, { slug: 'supervision' });
        this.tabs[tabindex2].visible = false;
      }
      if (this.user.edit_rights > 1) {
        const tabindex = _.findKey(this.tabs, { slug: 'skills' });
        this.tabs[tabindex].visible = false;
      }
    },
    initTraining() {
      this.startLoading();
      this.$http
        // .get(`/trainings/${this.$route.params.idTraining}/?format=json`)
        .get(`/trainings/${this.$route.params.idTraining}/?query={*,-training_periods}&format=json`)
        .then((response) => {
          this.training = response.data;
          this.setdata();
          if (this.currentUser.role === 'formateur') { this.getTrainingRights(); }
          this.endLoading();
        });
    },
    closeTraining() {
      this.$buefy.dialog.confirm({
        title: 'Attention, vous êtes sur le point de désactiver cette formation',
        message: 'Nous vous invitons à vérifier cette action, car elle rendra définitivement inactive la formation, et rendra impossibles toutes les actions en cours des utilisateurs qui sont liées à des formations. Êtes vous sûr.e de vouloir clore la formation ?',
        confirmText: 'Désactiver',
        cancelText: 'Annuler',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.$http
            .patch(`/trainings/${this.$route.params.idTraining}/`, {
              active: 0,
            }).then(() => {
              this.$buefy.toast.open({ message: 'La formation est désactivée', type: 'is-success' });
              this.$router.push({
                name: 'Trainings',
              });
            }).catch((error) => {
              window.console.log(error);
              this.errored = true;
              this.$buefy.dialog.alert({
                title: 'Mince...',
                message: 'Nous n\'avons pas pu désactiver la formation',
                type: 'is-info',
                hasIcon: true,
                icon: 'times-circle',
                ariaRole: 'alertdialog',
                ariaModal: true,
                confirmText: 'Ok',
              });
            });
        },
      });
    },
    finishTraining() {
      this.$buefy.dialog.confirm({
        title: 'Attention, vous êtes sur le point de marquer cette formation comme terminée',
        message: 'Nous vous invitons à vérifier cette action, car elle rendra définitivement inactive la formation, et rendra impossibles toutes les actions en cours des utilisateurs qui sont liées à des formations. Êtes vous sûr.e de vouloir marquer la formation comme terminée ?',
        confirmText: 'Marquer comme terminée',
        cancelText: 'Annuler',
        type: 'is-success',
        hasIcon: true,
        onConfirm: () => {
          this.$http
            .patch(`/trainings/${this.$route.params.idTraining}/`, {
              finished: true,
            }).then(() => {
              this.$buefy.toast.open({ message: 'La formation est indiquée comme terminée', type: 'is-success' });
              this.$router.push({
                name: 'Trainings',
              });
            }).catch((error) => {
              window.console.log(error);
              this.errored = true;
              this.$buefy.dialog.alert({
                title: 'Mince...',
                message: 'Nous n\'avons pas pu marquer la formation comme terminée ',
                type: 'is-info',
                hasIcon: true,
                icon: 'times-circle',
                ariaRole: 'alertdialog',
                ariaModal: true,
                confirmText: 'Ok',
              });
            });
        },
      });
    },
    initSequencetypes() {
      this.$http
        .get('/sequencetypes/?format=json')
        .then((response) => {
          this.sequencetypes = response.data;
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
        });
    },
    editTraining() {
      this.$http
        .patch(`/trainings/${this.$route.params.idTraining}/`, this.form)
        .then(() => {
          this.$buefy.toast.open({ message: 'Formation modifiée', type: 'is-success' });
          this.initTraining();
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
          this.$buefy.dialog.alert({
            title: 'Mince...',
            message: 'Nous n\'avons pas pu modifier la formation.',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
          });
        });
    },
    setdata() {
      //   function to load values in form for the 3 first steps
      this.form.name = this.training.name;
      this.form.start_date = new Date(this.training.start_date);
      this.form.end_date = new Date(this.training.end_date);
      this.form.description = this.training.description;
      this.form.goals = this.training.goals;
      this.form.skills = this.training.skills;
      this.endLoading();
    },
    gotoStep(numberStep) {
      // to change the tab and the content
      this.currentTab = numberStep;
      this.step = numberStep;
      if (numberStep === 'agenda') {
        this.initDates();
      }
      this.gotoTab(numberStep);
    },
    gotoTab(slug) {
      if (this.$route.path !== `/editer-une-formation/${this.$route.params.idTraining}/${slug}`) {
        this.$router.push({
          name: 'TrainingEdit',
          params: {
            tab: slug,
            idTraining: this.$route.params.idTraining,
          },
        }).catch((error) => {
          window.console.log(error);
        });
      }
    },
    handleHistoryChange() {
      this.gotoStep(this.$route.params.tab);
    },
    gotoResources() {
      this.currentTab = 'resources';
      this.step = this.currentTab;
      this.initResources();
    },
    nextStep() {
      this.step += 1;
      this.currentTab = this.step;
    },
    previousStep() {
      this.step -= 1;
      this.currentTab = this.step;
    },
    reopenTraining() {
      this.$buefy.dialog.confirm({
        title: 'Attention, vous êtes sur le point de résactiver cette formation',
        message: 'Elle sera de nouveau accessible aux formateurs.',
        confirmText: 'Réactiver',
        cancelText: 'Annuler',
        type: 'is-success',
        onConfirm: () => {
          this.$http
            .patch(`/trainings/${this.$route.params.idTraining}/`, {
              active: 1,
            }).then(() => {
              this.initTraining();
              this.$buefy.toast.open({ message: 'La formation est réactivée', type: 'is-success' });
            }).catch((error) => {
              window.console.log(error);
              this.errored = true;
              this.$buefy.dialog.alert({
                title: 'Mince...',
                message: 'Nous n\'avons pu réactiver la formation',
                type: 'is-info',
                hasIcon: true,
                icon: 'times-circle',
                ariaRole: 'alertdialog',
                ariaModal: true,
                confirmText: 'Ok',
              });
            });
        },
      });
    },
    startLoading() {
      this.isLoading = true;
    },
    endLoading() {
      this.isLoading = false;
    },
    collapseBlock(id) {
      const element = document.getElementById(id);
      element.classList.toggle('blockOpen');
      this.formSequence.name = null;
      this.formSequence.type = null;
    },
    seeTraining() {
      this.$router
        .push({
          name: 'DashboardManagement',
          params: {
            idTraining: this.$route.params.idTraining,
            tabDashboard: this.$route.params.tab,
          },
        });
    },
    initResources() {
      this.startLoading();
      this.$http.get(`/get_resources/?training=${this.$route.params.idTraining}&format=json`).then((response) => {
        this.resources = response.data.resources;
        this.endLoading();
      });
    },
    forceRerender() {
      // Every time that forceRerender is called, our prop componentKey will change.
      this.resourceKey += 1;
    },
    forceAllDatesStudents() {
      this.$buefy.dialog.confirm({
        title: 'Forcez l\'attribution des dates de ce parcours aux apprenants',
        message: 'Attention, vous allez écraser toutes les dates déjà attribuées',
        confirmText: 'Ecraser avec les nouvelles dates',
        cancelText: 'Annuler',
        type: 'is-success',
        onConfirm: () => {
          this.$http
            .get(`/update_training_dates/?training_id=${this.$route.params.idTraining}&force=1`).then(() => {
              this.$buefy.toast.open({ message: 'Les dates ont bien été attribuées', type: 'is-success' });
            }).catch((error) => {
              window.console.log(error);
              this.errored = true;
              this.$buefy.dialog.alert({
                title: 'Mince...',
                message: 'Nous n\'avons pu attribuer les dates de cette formation',
                type: 'is-info',
                hasIcon: true,
                icon: 'times-circle',
                ariaRole: 'alertdialog',
                ariaModal: true,
                confirmText: 'Ok',
              });
            });
        },
      });
    },
    populateAllDatesStudents() {
      this.$buefy.dialog.confirm({
        title: 'Attribuez les dates de ce parcours aux apprenants',
        message: 'Vous allez attribuer à tous les apprenants de cette formation toutes les dates de ce parcours (expériences et activités)',
        confirmText: 'Attribuer',
        cancelText: 'Annuler',
        type: 'is-success',
        onConfirm: () => {
          this.$http
            .get(`/update_training_dates/?training_id=${this.$route.params.idTraining}`).then(() => {
              this.$buefy.toast.open({ message: 'Les dates ont bien été attribuées', type: 'is-success' });
            }).catch((error) => {
              window.console.log(error);
              this.errored = true;
              this.$buefy.dialog.alert({
                title: 'Mince...',
                message: 'Nous n\'avons pu attribuer les dates de cette formation',
                type: 'is-info',
                hasIcon: true,
                icon: 'times-circle',
                ariaRole: 'alertdialog',
                ariaModal: true,
                confirmText: 'Ok',
              });
            });
        },
      });
    },
    populateActivitiesDatesStudents() {
      this.$buefy.dialog.confirm({
        title: 'Attribuez les dates des activités de ce parcours aux apprenants',
        message: 'Vous allez attribuer à tous les apprenants de cette formation toutes les dates des activités uniquement de ce parcours (pas les expériences)',
        confirmText: 'Attribuer',
        cancelText: 'Annuler',
        type: 'is-success',
        onConfirm: () => {
          this.$http
            .post(`/update_activities_dates?training_id=${this.$route.params.idTraining}`).then(() => {
              this.$buefy.toast.open({ message: 'Les dates ont bien été attribuées', type: 'is-success' });
            }).catch((error) => {
              window.console.log(error);
              this.errored = true;
              this.$buefy.dialog.alert({
                title: 'Mince...',
                message: 'Nous n\'avons pu attribuer les dates de cette formation',
                type: 'is-info',
                hasIcon: true,
                icon: 'times-circle',
                ariaRole: 'alertdialog',
                ariaModal: true,
                confirmText: 'Ok',
              });
            });
        },
      });
    },
    populateSequencesDatesStudents() {
      this.$buefy.dialog.confirm({
        title: 'Attribuez les dates des expériences de ce parcours aux apprenants',
        message: 'Vous allez attribuer à tous les apprenants de cette formation toutes les dates des expériences (pas les activités) de ce parcours)',
        confirmText: 'Attribuer',
        cancelText: 'Annuler',
        type: 'is-success',
        onConfirm: () => {
          this.$http
            .post('/update_sequences_dates', { training_id: this.$route.params.idTraining }).then(() => {
              this.$buefy.toast.open({ message: 'Les dates ont bien été attribuées', type: 'is-success' });
            }).catch((error) => {
              window.console.log(error);
              this.errored = true;
              this.$buefy.dialog.alert({
                title: 'Mince...',
                message: 'Nous n\'avons pu attribuer les dates de cette formation',
                type: 'is-info',
                hasIcon: true,
                icon: 'times-circle',
                ariaRole: 'alertdialog',
                ariaModal: true,
                confirmText: 'Ok',
              });
            });
        },
      });
    },
    forceRerenderPlanning() {
      this.planningKey += 1;
    },
    initDates() {
      const wsUrl = `/agenda/planning_global/?noactivities=1&sequences=1prev=true&training=${this.$route.params.idTraining}&format=json`;
      this.$http
        .get(wsUrl)
        .then((response) => {
          // this.endLoading();
          this.dates = response.data;
          this.startLoading();
          this.forceRerenderPlanning();
          this.endLoading();
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
          this.$buefy.dialog.confirm({
            title: 'Mince...',
            message: 'Une information n\'a pas pu être totalement chargée, nous vous invitons à recharger la page.',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
            onConfirm: () => document.location.reload(),
          });
        });
    },
    initSkills() {
      this.$http
        .get(`/trainingskills/?training=${this.$route.params.idTraining}&format=json`)
        .then((response) => {
          this.skillsTraining = response.data;
          this.$http
            .get(`/traininglevels/?training=${this.$route.params.idTraining}&format=json`)
            .then((resp) => {
              this.skillsLevelsTraining = resp.data;
              this.endLoading();
            });
        });
    },
    dateMandatory() {
      this.$http
        .patch(`/trainings/${this.$route.params.idTraining}/`, {
          editable_without_dates: this.training.editable_without_dates,
        })
        .then(() => {
          this.$buefy.toast.open({ message: 'Paramètre de dates modifié', type: 'is-success' });
        })
        .catch((error) => {
          window.console.log(error);
          this.errored = true;
        });
    },

  },
  created() {
    // verification if user come from an another page and had to go to a specific tab
    const isTabAvailable = _.findKey(this.tabs,
      (e) => e.slug === this.$route.params.tab);
    if (this.$route.params.tab && isTabAvailable) {
      this.gotoStep(this.$route.params.tab);
    } else {
      this.gotoStep('session');
    }
  },
  mounted() {
    this.initTraining();
    this.initSequencetypes();
    window.addEventListener('popstate', this.handleHistoryChange);
  },
  destroyed() {
    window.removeEventListener('popstate', this.handleHistoryChange);
  },
  data() {
    return {
      sequencetocopy: [],
      dates: [],
      planningKey: 0,
      dateStartOpen: false,
      dateEndOpen: false,
      training: [],
      sequencetypes: [],
      studenttraining: [],
      isLoading: true,
      isFullPage: false,
      currentTab: 1,
      numberStep: 1,
      step: 1,
      totalsteps: 5,
      showWeekNumber: true,
      locale: 'fr-FR',
      crumbs: [
        {
          text: 'Formations',
          href: '/formations',
        },
        {
          text: 'Détail d\'une  formation',
          class: 'is-active',
        },
      ],
      tabs: [
        {
          name: 'Session',
          slug: 'session',
          visible: true,
        },
        {
          name: 'Parcours',
          slug: 'parcours',
          id: '2',
          visible: true,
        },
        {
          name: 'Utilisateurs',
          slug: 'users',
          href: '/maquettes',
          visible: true,
        },
        {
          name: 'Tutorat',
          slug: 'supervision',
          href: '/maquettes',
          visible: true,
        },
        {
          name: 'Suivi apprenant',
          slug: 'suivi',
          href: '/maquettes',
          visible: true,
        },
        {
          name: 'Agenda',
          href: '/maquettes',
          slug: 'agenda',
          visible: true,
        },
        {
          name: 'Compétences',
          slug: 'skills',
          rights: ['manager', 'tuteur', 'apprenant'],
          visible: true,
        },
        {
          name: 'Options',
          slug: 'options',
          rights: ['formateur', 'coordinateur'],
          visible: true,
        },
      ],
      isOpenPeriod: true,
      isOpen: false,
      form: {
        name: '',
        start_date: new Date(),
        end_date: new Date(),
      },
      // form to add sequences
      formSequence: {
        name: '',
        type: null,
      },
      // variables for 'parcours'
      keyperiod: 0,
      keysequence: 1,
      // this is the array which keep trainingframework
      periodsArray: [
        {
          id: 1,
          name: '',
          training: 1,
          training_framework: 1,
          position: 1,
          training_sequences: [
            {
              id: 1,
              period: 1,
              legacy_sequence: 1,
              name: '',
              sequence_type: '',
              position: 1,
            },
          ],
        },
      ],
      resources: null,
      resourceKey: 0,
      editor: ClassicEditor,
      editorDisabled: true,
      editorConfig: {
        language: 'fr',
        toolbar: ['heading', '|', 'bold', 'italic', 'bulletedList', 'numberedList', '|', 'outdent', 'indent', '|', 'link', '|', 'undo',
          'redo'],
        alignment: {
          options: ['left', 'right'],
        },
        heading: {
          options: [
            { model: 'paragraph', title: 'Styles de texte', class: 'ck-heading_paragraph' },
            {
              model: 'heading1',
              view: 'h1',
              title: 'Titre 1',
              class: 'ck-heading_heading1',
            },
            {
              model: 'heading2',
              view: 'h2',
              title: 'Titre 2',
              class: 'ck-heading_heading2',
            },
            {
              model: 'mark',
              view: 'mark',
              title: 'surligné',
              class: 'ck-mark',
            },
          ],
        },
        table: {
          contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells'],
        },
      },
      skillsTraining: [],
      skillsLevelsTraining: [],
    };
  },
};
</script>

<style lang="scss">
.cardClosed {
  display: none;
}

.blockOpen {
  display: block;
}
</style>
