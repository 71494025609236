<template>
  <div>
    <b-modal
      has-modal-card
      trap-focus
      aria-role="dialog"
      aria-label="modale utilisateur"
      close-button-aria-label="Fermer"
      aria-modal
      v-model="isUserInfos"
      :destroy-on-hide="false"
    >
      <template>
        <div class="modal-card pb-2" id="modalWaypoint">
          <section
            class="modal-card-body has-text-left"
            v-if="user_profile"
            style="border-radius: 0.2rem; padding: 2rem 2rem 4rem 2rem"
          >
            <div class="has-text-right mb-5">
              <a @click="$emit('close')" class="ml-auto">
                <b-icon icon="close" type="is-primary"></b-icon>
              </a>
            </div>
            <div class="modal-user-infos has-text-centered">
              <div class="has-text-centered mb-2 mt-5-">
                <b class="title-paragraph has-text-centered mb-5">
                  {{ user_profile.user.first_name }}
                  {{ user_profile.user.last_name }}</b
                >
              </div>
              <span v-if="user_profile">
                <div v-if="user_profile" class="mr-2 mb-4">
                  {{ user_profile.phone_number }}
                </div>
                <a
                  :href="'mailto:' + user_profile.user.email"
                  class="is-underlined mt-5"
                  ><span v-if="user_profile.user.email">
                    <b-button rounded type="is-primary"
                      >écrire un mail</b-button
                    ></span
                  ></a
                >
              </span>
            </div>
          </section>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    user_id: {
      type: Number,
    },
  },
  filters: {
  },
  computed: {
    ...mapState('auth', ['currentUser']),
  },
  data() {
    return {
      user_profile: null,
      isUserInfos: true,
    };
  },
  methods: {
    getUser() {
      this.$http
        .get(`/profile/${this.user_id}/?query={id,user,address,phone_number,enterprise,birth_date}&format=json`)
        .then((response) => {
          this.user_profile = response.data;
        }).catch((error) => {
          window.console.log(error);
        });
    },
  },
  created() {
    this.getUser();
  },
};
</script>
