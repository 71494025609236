<template>
  <div>
    <b-loading :is-full-page="isFullPage" v-model="isLoading"> </b-loading>
    <nav class="submenu">
      <ul>
        <li
          v-for="(tab, index) of tabs"
          :key="index"
          :id="tab.id"
          :class="currentTab === index + 1 ? 'is-active' : ''"
        >
          <a @click="gotoStep(index + 1)"
            ><b>{{ tab.name }}</b></a
          >
        </li>
      </ul>
    </nav>
    <div class="content-page pt-6" v-if="users.length">
      <section id="teacher" v-if="step == 1">
        <div class="frowho mt-0 mb-6">
          <b-icon icon="user" type="is-primary"></b-icon>
          <div class="is-flex justify-content-between align-items-center w-100">
            <div>
              <div class="title">Associez des formateurs à cette formation</div>
              <div class="subtitle">
                Vous pouvez sélectionner et ajouter plusieurs utilisateurs dans
                le menu déroulant.
              </div>
            </div>
          </div>
        </div>
        <div class="columns is-tablet">
          <div class="column is-4">
            <b-field label="Rechercher par mot clé (nom, prénom, email, rôle)">
              <b-input
                placeholder="Rechercher..."
                type="search"
                icon="magnify"
                icon-clickable
                @icon-click="setAvailableTeachers()"
                v-model="search"
                @keydown.enter.native="setAvailableTeachers()"
                @input="setAvailableTeachers()"
              >
              </b-input>
            </b-field>
            <b-message type="is-warning"
              >{{ availableteachers.length }} formateur(s)
              disponibles</b-message
            >
            <b-table
              v-if="availableteachers.length"
              class="table_simple table_users"
              :data="availableteachers"
              ref="table"
              :debounce-search="1000"
              default-sort="name"
              :checked-rows.sync="teachers"
              checkable
              paginated
              :per-page="perPage"
            >
              <b-table-column
                field="user"
                label="Nom"
                width="40"
                v-slot="props"
              >
                <b>
                  {{ props.row.first_name }}
                  {{ props.row.last_name }}</b
                >
              </b-table-column>
            </b-table>
            <b-button
              class="mt-4"
              @click.prevent="addTeachers"
              v-if="availableteachers.length"
              type="is-primary"
              rounded
              :disabled="!teachers.length"
              >Ajouter des formateurs</b-button
            >
          </div>
          <div class="column" v-if="training.teachers">
            <b class="mb-3"> Formateurs associés à la formation</b>
            <div class="is-flex mt-2">
              <div class="has-text-primary mr-2">
                Ont-ils le droit de modifier la formation ?
              </div>
              <b-field>
                <b-switch
                  v-model="training.teachers_can_edit"
                  type="is-success"
                  @input="teacherEdition"
                >
                  {{
                    training.teachers_can_edit
                      ? "Droits de modification"
                      : "Droits de lecture"
                  }}
                </b-switch>
              </b-field>
            </div>
            <b-table
              v-if="training.teachers.length"
              class="table_simple table_users"
              :data="training.teachers"
              ref="table"
              :debounce-search="1000"
              default-sort="name"
            >
              <template v-for="column in usersColumns">
                <b-table-column :key="column.id" v-bind="column">
                  <template v-slot="props" v-if="column.field === 'user'">
                    <div class="is-flex align-items-center">
                      <b>
                        {{ getUser(props.row).user.first_name }}
                        {{ getUser(props.row).user.last_name }}</b
                      >
                    </div>
                  </template>
                  <!-- <template
                    v-slot="props"
                    v-else-if="column.field === 'organization'"
                  >
                    <span v-if="getUser(props.row).organization">
                      {{ getUser(props.row).organization.name }}</span
                    >
                  </template> -->
                  <template
                    v-slot="props"
                    v-else-if="column.field === 'rights'"
                  >
                    <span v-if="getUser(props.row).edit_rights">
                      {{ getUser(props.row).edit_rights }}</span
                    >
                  </template>
                  <template
                    v-slot="props"
                    v-else-if="column.field === 'delete'"
                  >
                    {{ props.row.student_id }}
                    <div @click.prevent="removeTeacher(props.row)">
                      <b-icon icon="close" type="is-danger"></b-icon>
                    </div>
                  </template>
                </b-table-column>
              </template>
            </b-table>
            <b-message v-else type="is-info" size="is-small"
              >Aucune personne n'a été associée comme formateur.</b-message
            >
          </div>
        </div>
      </section>
      <section id="students" v-if="step == 2">
        <div class="frowho mt-0 mb-6">
          <b-icon icon="user" type="is-primary"></b-icon>
          <div class="is-flex justify-content-between align-items-center w-100">
            <div>
              <div class="title">Associez des apprenants à cette formation</div>
              <div class="subtitle">
                Vous pouvez sélectionner et ajouter plusieurs utilisateurs dans
                le menu déroulant. Vous leur attribuerez des mangers et tuteurs
                plus tard.
              </div>
            </div>
          </div>
        </div>
        <div class="columns is-tablet">
          <div class="column is-4">
            <b-field label="Rechercher par mot clé (nom, prénom, email, rôle)">
              <b-input
                placeholder="Rechercher..."
                type="search"
                icon="magnify"
                icon-clickable
                @icon-click="setAvailableStudents()"
                v-model="search"
                @keydown.enter.native="setAvailableStudents()"
              >
              </b-input>
            </b-field>
            <b-message type="is-warning"
              >{{ availablestudents.length }} apprenant(s)
              disponibles</b-message
            >
            <b-table
              v-if="availablestudents.length"
              class="table_simple table_users"
              :data="availablestudents"
              ref="table"
              :debounce-search="1000"
              default-sort="name"
              :checked-rows.sync="students"
              checkable
              paginated
              :per-page="perPage"
            >
              <b-table-column
                field="user"
                label="Nom"
                width="40"
                v-slot="props"
              >
                <b>
                  {{ props.row.first_name }}
                  {{ props.row.last_name }}</b
                >
              </b-table-column>
            </b-table>
            <b-button
              class="mt-4"
              @click.prevent="addStudents"
              v-if="availablestudents.length"
              type="is-primary"
              rounded
              :disabled="!students.length"
              >Ajouter des apprenants</b-button
            >
          </div>
          <div class="column">
            <b class="mb-3"> Apprenants associés à la formation</b>
            <b-table
              v-if="training.students.length"
              class="table_simple table_users"
              :data="training.students"
              ref="table"
              :debounce-search="1000"
              default-sort="name"
              paginated
              :per-page="perPage"
            >
              <template v-for="column in usersColumns">
                <b-table-column :key="column.id" v-bind="column">
                  <template v-slot="props" v-if="column.field === 'user'">
                    <b v-if="getUser(props.row)">
                      {{ getUser(props.row).user.first_name }}
                      {{ getUser(props.row).user.last_name }}
                    </b>
                  </template>
                  <template
                    v-slot="props"
                    v-else-if="column.field === 'organization'"
                  >
                    <span v-if="getUser(props.row)">
                      <span v-if="getUser(props.row).organization">
                        {{ getUser(props.row).organization.name }}</span
                      ></span
                    >
                  </template>
                  <template
                    v-slot="props"
                    v-else-if="column.field === 'delete'"
                  >
                    {{ props.row.student_id }}
                    <div @click.prevent="removeStudent(props.row)">
                      <b-icon icon="close" type="is-danger"></b-icon>
                    </div>
                  </template>
                </b-table-column>
              </template>
            </b-table>
            <b-message class="mt-3" v-else type="is-info" size="is-small"
              >Aucune personne n'a été associée comme apprenant.</b-message
            >
          </div>
        </div>
      </section>
      <section id="managers" v-if="step == 3">
        <div class="frowho mt-0 mb-6">
          <b-icon icon="user" type="is-primary"></b-icon>
          <div class="is-flex justify-content-between align-items-center w-100">
            <div>
              <div class="title">Associez des managers à cette formation</div>
              <div class="subtitle">
                Vous pouvez sélectionner et ajouter plusieurs utilisateurs dans
                le menu déroulant.
              </div>
            </div>
          </div>
        </div>
        <div class="columns is-tablet">
          <div class="column is-4">
            <b-field label="Rechercher par mot clé (nom, prénom, email, rôle)">
              <b-input
                placeholder="Rechercher..."
                type="search"
                icon="magnify"
                icon-clickable
                @icon-click="setAvailableManagers()"
                v-model="search"
                @keydown.enter.native="setAvailableManagers()"
                @input="setAvailableManagers()"
              >
              </b-input>
            </b-field>
            <b-message type="is-warning"
              >{{ availablemanagers.length }} manager(s) disponibles</b-message
            >
            <b-table
              v-if="availablemanagers.length"
              class="table_simple table_users"
              :data="availablemanagers"
              ref="table"
              :debounce-search="1000"
              default-sort="name"
              :checked-rows.sync="managers"
              checkable
            >
              <b-table-column
                field="user"
                label="Nom"
                width="40"
                v-slot="props"
              >
                <b>
                  {{ props.row.first_name }}
                  {{ props.row.last_name }}</b
                >
              </b-table-column>
            </b-table>
            <b-button
              class="mt-4"
              @click.prevent="addManagers"
              v-if="availablemanagers.length"
              type="is-primary"
              rounded
              :disabled="!managers.length"
              >Ajouter des managers</b-button
            >
          </div>
          <div class="column">
            <b class="mb-3"> Managers associés à la formation</b>
            <b-table
              v-if="training.managers.length"
              class="table_simple table_users"
              :data="training.managers"
              ref="table"
              :debounce-search="1000"
              default-sort="name"
            >
              <template v-for="column in usersColumns">
                <b-table-column :key="column.id" v-bind="column">
                  <template v-slot="props" v-if="column.field === 'user'">
                    <b v-if="getUser(props.row)">
                      {{ getUser(props.row).user.first_name }}
                      {{ getUser(props.row).user.last_name }}
                    </b>
                  </template>
                  <template
                    v-slot="props"
                    v-else-if="column.field === 'organization'"
                  >
                    <span v-if="getUser(props.row)">
                      <span v-if="getUser(props.row).organization">
                        {{ getUser(props.row).organization.name }}</span
                      ></span
                    >
                  </template>
                  <template
                    v-slot="props"
                    v-else-if="column.field === 'delete'"
                  >
                    {{ props.row.student_id }}
                    <div @click.prevent="removeManager(props.row)">
                      <b-icon icon="close" type="is-danger"></b-icon>
                    </div>
                  </template>
                </b-table-column>
              </template>
            </b-table>
            <b-message class="mt-3" v-else type="is-info" size="is-small"
              >Aucune personne n'a été associée comme manager.</b-message
            >
          </div>
        </div>
      </section>
      <section id="tutors" v-if="step == 4">
        <div class="frowho mt-0 mb-6">
          <b-icon icon="user" type="is-primary"></b-icon>
          <div class="is-flex justify-content-between align-items-center w-100">
            <div>
              <div class="title">Associez des tuteurs à cette formation</div>
              <div class="subtitle">
                Vous pouvez sélectionner et ajouter plusieurs utilisateurs dans
                le menu déroulant.
              </div>
            </div>
          </div>
        </div>
        <div class="columns is-tablet">
          <div class="column is-4">
            <b-field label="Rechercher par mot clé (nom, prénom, email, rôle)">
              <b-input
                placeholder="Rechercher..."
                type="search"
                icon="magnify"
                icon-clickable
                @icon-click="setAvailableTutors()"
                v-model="search"
                @keydown.enter.native="setAvailableTutors()"
                @input="setAvailableTutors()"
              >
              </b-input>
            </b-field>
            <b-message type="is-warning"
              >{{ availabletutors.length }} tuteur(s) disponibles</b-message
            >
            <b-table
              v-if="availabletutors.length"
              class="table_simple table_users"
              :data="availabletutors"
              ref="table"
              :debounce-search="1000"
              default-sort="name"
              :checked-rows.sync="tutors"
              checkable
            >
              <b-table-column
                field="user"
                label="Nom"
                width="40"
                v-slot="props"
              >
                <b>
                  {{ props.row.first_name }}
                  {{ props.row.last_name }}</b
                >
              </b-table-column>
            </b-table>
            <b-button
              class="mt-4"
              @click.prevent="addTutors"
              v-if="availabletutors.length"
              type="is-primary"
              rounded
              :disabled="!tutors.length"
              >Ajouter des tuteurs</b-button
            >
          </div>
          <div class="column">
            <b class="mb-3"> Tuteurs associés à la formation</b>
            <b-table
              v-if="training.tutors.length"
              class="table_simple table_users"
              :data="training.tutors"
              ref="table"
              :debounce-search="1000"
              default-sort="name"
            >
              <template v-for="column in usersColumns">
                <b-table-column :key="column.id" v-bind="column">
                  <template v-slot="props" v-if="column.field === 'user'">
                    <b v-if="getUser(props.row)">
                      {{ getUser(props.row).user.first_name }}
                      {{ getUser(props.row).user.last_name }}</b
                    >
                  </template>
                  <template
                    v-slot="props"
                    v-else-if="column.field === 'organization'"
                    ><span v-if="getUser(props.row)">
                      <span v-if="getUser(props.row).organization">
                        {{ getUser(props.row).organization.name }}</span
                      ></span
                    >
                  </template>
                  <template
                    v-slot="props"
                    v-else-if="column.field === 'delete'"
                  >
                    {{ props.row.student_id }}
                    <div @click.prevent="removeTutor(props.row)">
                      <b-icon icon="close" type="is-danger"></b-icon>
                    </div>
                  </template>
                </b-table-column>
              </template>
            </b-table>
            <b-message class="mt-3" v-else type="is-info" size="is-small"
              >Aucune personne n'a été associée comme tuteur.</b-message
            >
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import _ from 'lodash';

export default {
  computed: {
    users() {
      return this.$store.state.users;
    },
    ...mapGetters(['getUserById']),
  },
  methods: {
    gotoStep(numberStep) {
      this.currentTab = numberStep;
      this.step = numberStep;
      if (this.currentTab === 1) {
        window.console.log('hi');
      }
    },
    getUser(id) {
      return this.$store.getters.getUserById(id);
    },
    initTraining() {
      this.$http
        .get(`/trainings/${this.$route.params.idTraining}/?query={*,training_periods{*,training_sequences{-training_activities}}}&format=json`)
        .then((response) => {
          this.training = response.data;
          this.teachersEdit = this.training.teachers_can_edit;
          this.endLoading();
          this.initUsers();
          this.setAvailableTeachers();
          this.setAvailableStudents();
          this.setAvailableTutors();
          this.setAvailableManagers();
        });
    },
    initUsers() {
      this.$http
        .get('/profile/?format=json')
        .then((response) => {
          this.$store.commit('GET_USERS', response.data);
          this.endLoading();
        });
    },
    forceRerender() {
      this.componentKey += 1;
    },
    setAvailableTeachers() {
      // get available teachers
      // will need to add an organization limitation
      this.$http
        .get(`/get_profiles/?search=${this.search}&role=formateur&items=4000&format=json`)
        .then((response) => {
          this.totalTeachers = response.data.count;
          const teachersToDisplay = response.data.results;
          // for each item of avalable teachers remove teachers already selected
          for (let i = 0; i < this.training.teachers.length; i += 1) {
            const alreadyAdded = _.findKey(teachersToDisplay,
              (e) => e.id === this.training.teachers[i]);
            if (alreadyAdded) {
              teachersToDisplay.splice(alreadyAdded, 1);
            }
          }
          this.availableteachers = teachersToDisplay;
          this.endLoading();
        });
    },
    setAvailableStudents() {
      // get available teachers
      this.$http
        .get(`/get_profiles/?search=${this.search}&role=apprenant&items=4000&format=json`)
        .then((response) => {
          const studentsToDisplay = response.data.results;
          this.totalStudents = response.data.count;

          // for each item of avalable teachers remove teachers already selected
          for (let i = 0; i < this.training.students.length; i += 1) {
            const alreadyAdded = _.findKey(response.data.results,
              (e) => e.id === this.training.students[i]);
            if (alreadyAdded) {
              studentsToDisplay.splice(alreadyAdded, 1);
            }
          }
          this.availablestudents = studentsToDisplay;
          this.endLoading();
        });
    },
    setAvailableManagers() {
      // get available managers
      // will need to add an organization limitation
      this.$http
        .get(`/get_profiles/?search=${this.search}&role=manager&items=4000&format=json`)
        .then((response) => {
          const managersToDisplay = response.data.results;
          this.totalManagers = response.data.count;
          this.availablemanagers = response.data.results;
          // for each item of avalable teachers remove teachers already selected
          for (let i = 0; i < this.training.managers.length; i += 1) {
            const alreadyAdded = _.findKey(response.data.results,
              (e) => e.id === this.training.managers[i]);
            if (alreadyAdded) {
              managersToDisplay.splice(alreadyAdded, 1);
            }
          }
          this.availablemanagers = managersToDisplay;
          this.endLoading();
        });
    },
    setAvailableTutors() {
      // get available teachers
      // will need to add an organization limitation
      this.$http
        .get(`/get_profiles/?search=${this.search}&role=tuteur&items=4000&format=json`)
        .then((response) => {
          const tutorsToDisplay = response.data.results;
          this.totalTutors = response.data.count;
          this.availabletutors = response.data.results;

          // for each item of avalable teachers remove teachers already selected
          for (let i = 0; i < this.training.tutors.length; i += 1) {
            const alreadyAdded = _.findKey(response.data.results,
              (e) => e.id === this.training.tutors[i]);
            if (alreadyAdded) {
              tutorsToDisplay.splice(alreadyAdded, 1);
            }
          }
          this.availabletutors = tutorsToDisplay;
          this.endLoading();
        });
    },
    addTeachers() {
      this.startLoading();
      const teacherToPatch = [];
      // patch training to relate users
      // add in teachers array items in training teachers
      this.teachers.forEach((teacher) => {
        teacherToPatch.push(teacher.id);
      });
      for (let i = 0; i < this.training.teachers.length; i += 1) {
        teacherToPatch.push(this.training.teachers[i]);
      }
      this.$http
        .patch(`/trainings/${this.$route.params.idTraining}/`, {
          teachers: teacherToPatch,
        })
        .then((response) => {
          this.teachers = [];
          this.training = response.data;
          this.endLoading();
          this.setAvailableTeachers();
        }).then(() => {
          this.$buefy.notification.open({ message: 'Formateurs ajoutés', type: 'is-success' });
        });
    },
    removeTeacher(teacher) {
      this.startLoading();
      // patch training to relate users
      // add in teachers array items in training teachers
      const removedteacher = this.training.teachers.indexOf(teacher);
      this.training.teachers.splice(removedteacher, 1);
      this.$http
        .patch(`/trainings/${this.$route.params.idTraining}/`, {
          teachers: this.training.teachers,
        })
        .then((response) => {
          this.training = response.data;
          this.setAvailableTeachers();
          this.endloading();
        })
        .then(() => {
          this.$buefy.notification.open({ message: 'Formateurs supprimés', type: 'is-success' });
        });
    },
    addStudents() {
      this.startLoading();
      const studentsToPatch = [];
      // patch training to relate users
      this.students.forEach((student) => {
        studentsToPatch.push(student.id);
      });
      this.training.students.forEach((student) => {
        studentsToPatch.push(student);
      });
      this.$http
        .patch(`/trainings/${this.$route.params.idTraining}/`, {
          students: studentsToPatch,
        })
        .then((response) => {
          this.students = [];
          this.training = response.data;
          this.setAvailableStudents();
          this.endLoading();
        }).then(() => {
          this.$buefy.notification.open({ message: 'Étudiants ajoutés', type: 'is-success' });
          this.createStudentTraining();
        });
    },
    removeStudent(student) {
      let studentTainingDeleted = null;
      this.$http
        .get(`/agenda/student_training/?training=${this.training.id}&student=${student}&query={id}&format=json`)
        .then((response1) => {
          studentTainingDeleted = response1.data[0].id;
          const removedStudent = this.training.students.indexOf(student);
          this.training.students.splice(removedStudent, 1);
          const studentTrainings = this.training.related_student_trainings_from_this_training;
          const removedStudentTraining = studentTrainings.indexOf(studentTainingDeleted);
          if (removedStudentTraining > -1) {
            this.training.related_student_trainings_from_this_training
              .splice(removedStudentTraining, 1);
          }
          this.$http
            .patch(`/trainings/${this.$route.params.idTraining}/`, {
              students: this.training.students,
              related_student_trainings_from_this_training:
                this.training.related_student_trainings_from_this_training,
            })
            .then((response) => {
              this.training = response.data;
              this.setAvailableStudents();
            })
            .then(() => {
              this.$buefy.notification.open({ message: 'Apprenants supprimés', type: 'is-success' });
            });
          this.$http
            .patch(`/agenda/student_training/${studentTainingDeleted}/`, {
              deleted: true,
            });
        })
        .catch((error) => {
          window.console.log(error);
          this.errored = true;
        });
    },
    addManagers() {
      this.startLoading();
      const managersToPatch = [];
      // patch training to relate users
      this.managers.forEach((manager) => {
        managersToPatch.push(manager.id);
      });
      this.training.managers.forEach((manager) => {
        managersToPatch.push(manager);
      });
      // patch training to relate users
      this.$http
        .patch(`/trainings/${this.$route.params.idTraining}/`, {
          managers: managersToPatch,
        })
        .then((response) => {
          this.managers = [];
          this.training = response.data;
          this.setAvailableManagers();
          this.endLoading();
        }).then(() => {
          this.$buefy.notification.open({ message: 'Managers ajoutés', type: 'is-success' });
        });
    },
    removeManager(manager) {
      // patch training to relate users
      // add in teachers array items in training teachers
      const removedmanager = this.training.managers.indexOf(manager);
      this.training.managers.splice(removedmanager, 1);
      this.$http
        .patch(`trainings/${this.$route.params.idTraining}/`, {
          managers: this.training.managers,
        })
        .then((response) => {
          this.training = response.data;
          this.setAvailableManagers();
        })
        .then(() => {
          this.$buefy.notification.open({ message: 'Managers supprimés', type: 'is-success' });
        });
    },
    addTutors() {
      this.startLoading();
      const tutorsToPatch = [];
      this.tutors.forEach((tutor) => {
        tutorsToPatch.push(tutor.id);
      });
      this.training.tutors.forEach((tutor) => {
        tutorsToPatch.push(tutor);
      });
      // patch training to relate users
      this.$http
        .patch(`/trainings/${this.$route.params.idTraining}/`, {
          tutors: tutorsToPatch,
        })
        .then((response) => {
          this.tutors = [];
          this.training = response.data;
          this.setAvailableTutors();
          this.endLoading();
        }).then(() => {
          this.$buefy.notification.open({ message: 'Tuteurs ajoutés', type: 'is-success' });
        });
    },
    removeTutor(tutor) {
      // patch training to relate users
      // add in teachers array items in training teachers
      const removedtutor = this.training.tutors.indexOf(tutor);
      this.training.tutors.splice(removedtutor, 1);
      this.$http
        .patch(`/trainings/${this.$route.params.idTraining}/`, {
          tutors: this.training.tutors,
        })
        .then((response) => {
          this.training = response.data;
          this.setAvailableTutors();
        })
        .then(() => {
          this.$buefy.notification.open({ message: 'Tuteurs supprimés', type: 'is-success' });
        });
    },
    createStudentTraining() {
      this.$http
        .post('/agenda/students2studenttrainings/?format=json', {
          training_id: this.$route.params.idTraining,
          student_ids: this.training.students,
        });
    },
    startLoading() {
      this.isLoading = true;
    },
    endLoading() {
      this.isLoading = false;
    },
    teacherEdition() {
      this.$http
        .patch(`/trainings/${this.$route.params.idTraining}/`, {
          teachers_can_edit: this.training.teachers_can_edit,
        })
        .then(() => {
          this.$buefy.notification.open({ message: 'Droits modifiés', type: 'is-success' });
        })
        .catch((error) => {
          window.console.log(error);
          this.errored = true;
        });
    },
    onPageTeacherChange(page) {
      this.pageTeacher = page;
      this.setAvailableTeachers();
    },
  },
  created() {
    this.initTraining();
  },
  mounted() {
  },
  props: {
    trainingObject: {
      type: [Object, Array],
    },
  },
  data() {
    return {
      // training: [],
      totalTeachers: [],
      totalTutors: [],
      totalStudents: [],
      totalManagers: [],
      isLoading: true,
      isFullPage: false,
      componentKey: 0,
      step: 1,
      currentTab: 1,
      students: [],
      managers: [],
      tutors: [],
      teachers: [],
      teachersArray: [],
      teachersEdit: false,
      tabs: [
        {
          name: 'Formateurs',
          id: '1',
        },
        {
          name: 'Apprenants',
          id: '2',
        },
        {
          name: 'Managers',
          id: '3',
        },
        {
          name: 'Tuteurs',
          id: '4',
        },
      ],
      usersColumns: [
        {
          field: 'user',
          label: 'Nom',
          width: '200',
          sortable: true,
          visible: true,
        },
        // {
        //   field: 'organization',
        //   label: 'Organisation',
        //   centered: true,
        //   sortable: true,
        //   visible: true,
        // },
        // {
        //   field: 'rights',
        //   label: 'Droits',
        //   centered: true,
        //   sortable: true,
        //   visible: true,
        // },
        {
          field: 'delete',
          label: 'Supprimer',
          numeric: true,
          centered: true,
          sortable: true,
          visible: true,
        },
      ],
      vailableUsersColumns: [
        {
          field: 'user',
          label: 'Nom',
          width: '200',
          sortable: true,
          visible: true,
        },
        // {
        //   field: 'organization',
        //   label: 'Organisation',
        //   centered: true,
        //   sortable: true,
        //   visible: true,
        // },
        // {
        //   field: 'delete',
        //   label: 'Supprimer',
        //   numeric: true,
        //   centered: true,
        //   sortable: true,
        //   visible: true,
        // },
      ],
      search: '',
      availableteachers: [],
      availablestudents: [],
      availabletutors: [],
      availablemanagers: [],
      perPage: 10,
      training: [],
    };
  },
};
</script>
<style scoped>
.table td {
  padding: 0.5em 0.7em !important;
}
</style>
