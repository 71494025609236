<template>
  <div class="modalbox">
    <!-- eslint-disable max-len-->
    <div class="is-flex bigmodal">
      <router-link v-if="studentsequence.student && $route.params.stepNumber != 'activity'" class="ml-auto" :to="'/formation-apprenant/' +
        $route.params.idTraining +
        '/' +
        studentsequence.student.id +
        '/' +
        $route.params.idStudentTraining
        ">
        <b-icon icon="close" type="is-primary"></b-icon>
      </router-link>
      <span class="ml-auto" v-if="studentsequence.student && $route.params.stepNumber == 'activity'">
        <div v-if="studentsequence.student.id" @click="gotoStats">
          <b-icon icon="close" type="is-primary"></b-icon>
        </div>
      </span>
    </div>
    <div class="container title-modal is-flex justify-content-between is-max-widescreen is-align-items-center">
      <div class="bloc-left-title">
        <div class="mb-2">
          <div v-if="currentUser.role === 'apprenant'">
            <router-link v-if="studentsequence.student" :to="'/formation-apprenant/' +
        $route.params.idTraining +
        '/' +
        studentsequence.student.id +
        '/' +
        $route.params.idStudentTraining
        ">
              <b-button type="is-text" class="">Mon parcours</b-button>
            </router-link>
          </div>
          <div v-else>
            <router-link v-if="studentsequence.student" :to="'/formation-apprenant/' +
        $route.params.idTraining +
        '/' +
        studentsequence.student.id +
        '/' +
        $route.params.idStudentTraining
        ">
              <span class="button is-text pl-0">Parcours de l'apprenant</span> </router-link><span
              v-if="studentsequence.student">: {{ studentsequence.student.user.first_name }}
              {{ studentsequence.student.user.last_name }}</span>
          </div>
        </div>
        <div class="has-text-primary" style="font-size: 0.9rem; line-height: 1">
          {{ studentsequence.period_name }}
        </div>
        <div class="title-page long-title mb-1">
          <span v-if="studentsequence.legacy_sequence">
            {{ studentsequence.legacy_sequence.name }}
          </span>
        </div>
        <div v-if="studentsequence.subtitle" class="mb-3 parenthesis">
          <span v-html="studentsequence.subtitle.substr(0, 144)" class="subtitleSequence"></span>
        </div>
        <div v-if="currentUser.role != 'apprenant' || currentUser.role != 'tuteur'">
          <span class="tag status1 mr-2" v-if="studentsequence.legacy_sequence"><span
              v-if="studentsequence.legacy_sequence.sequence_type_label">{{
        studentsequence.legacy_sequence.sequence_type_label
      }}</span>
          </span>
          <span v-if="studentsequence.status === 3" class="tag status3">
            Effectuée
          </span>
          <span v-if="studentsequence.status === 4" class="tag status3">
            Validée
          </span>
          <span v-if="!without_dates">
            <span v-if="studentsequence.status === 0" class="tag status0">
              À programmer
            </span>
            <span v-if="studentsequence.status === 1" class="tag status1">
              Programmée
            </span>
          </span>
          <span v-if="studentsequence.status === 5" class="tag status5">
            Désactivée
          </span>
          <span v-if="studentsequence.status === 0 && without_dates" class="tag statusInProgress">
            À faire
          </span>
          <span v-if="studentsequence.status === 7" class="tag status5">
            Litige
          </span>
          {{ studentsequence.status_label }}
        </div>
      </div>
      <div v-if="studentsequence.legacy_sequence" class="ml-auto">
        <div class="bloc-buttons is-flex align-items-center ml-auto" v-if="currentUser.role === 'apprenant' &&
        (studentsequence.legacy_sequence.sequence_type == 5 ||
          studentsequence.legacy_sequence.sequence_type == 8 ||
          studentsequence.legacy_sequence.sequence_type == 13 ||
          studentsequence.legacy_sequence.sequence_type == 15 ||
          studentsequence.legacy_sequence.sequence_type == 16)
        ">
          <b-button v-if="!disallow_progressions" type="is-success is-rounded" @click="addProgression"
            class="has-text-centered pl-3 pr-3">Vous avez progressé&nbsp;?</b-button>
          <AppHelpMessage v-if="!disallow_progressions"
            title="Vous avez refait la même activité et vous avez appris de nouvelles choses" message="<b>Si vous refaites régulièrement la même activité ou une activité similaire,
           vous pouvez ajouter quelques notes pour la garder en mémoire</b>. Vous pouvez
            préciser ce que vous avez appris ou compris de nouveau,
            décrire ce que vous avez refait différemment, ajouter des photos.
             Nous appelons cela une 'progression'." textBtn="" />
        </div>
      </div>
      <div class="bloc-buttons is-flex align-items-center">
        <div class="" v-if="studentsequence.status === 3 &&
        (currentUser.role === 'coordinateur' ||
          currentUser.role === 'formateur') &&
        (studentsequence.legacy_sequence.sequence_type === 1 ||
          studentsequence.legacy_sequence.sequence_type === 7)
        ">
          <b-button type="is-success is-rounded" class="">Valider</b-button>
        </div>
        <b-button type="is-danger is-rounded" v-if="studentsequence.status === 3 &&
        (currentUser.role === 'coordinateur' ||
          currentUser.role === 'formateur') &&
        (studentsequence.legacy_sequence.sequence_type === 1 ||
          studentsequence.legacy_sequence.sequence_type === 7)
        " @click="duplicateSequence()">Reprogrammer</b-button>
      </div>
    </div>
    <div v-if="studentsequence.legacy_sequence" class="container is-max-widescreen">
      <b-collapse :open="false" class="mt-5" position="is-top" aria-id="intro" v-if="studentsequence.legacy_sequence.goals ||
        studentsequence.legacy_sequence.comment
        ">
        <template #trigger="props">
          <a aria-controls="contentIdForA11y1">
            <b-icon :icon="!props.open ? 'menu-up' : 'menu-down'"></b-icon>
            {{
        !props.open ? "Lire l'introduction" : "Refermer l'introduction"
      }}
          </a>
        </template>
        <div class="">
          <div class="content">
            <div class="bloc-intro" v-if="studentsequence.legacy_sequence.goals">
              <div class="title-paragraph">Présentation</div>
              <span class="ck" v-html="studentsequence.legacy_sequence.goals">
              </span>
            </div>
            <div class="bloc-intro" v-if="studentsequence.legacy_sequence.comment">
              <div class="title-paragraph">Commentaires</div>
              <span class="ck" v-html="studentsequence.legacy_sequence.comment">
              </span>
            </div>
          </div>
        </div>
      </b-collapse>
    </div>
    <b-loading :is-full-page="isFullPage" v-model="isLoading"> </b-loading>
    <div id="bloc-activities">
      <div class="container content-activities contentSequence">
        <p class="card-header-title has-text-primary">A faire :</p>
        <div v-for="(
            activity, indexActivity
          ) in trainingsequence.training_activities" :key="indexActivity" class="boxActivity">
          <div v-if="activity.rights.includes('r_' + currentUser.role) ||
        activity.rights.includes('w_' + currentUser.role) ||
        currentUser.role === 'manager' ||
        currentUser.role === 'coordinateur' ||
        currentUser.role === 'formateur' ||
        currentUser.role === 'superviseur' ||
        (currentUser.role === 'tuteur' &&
          activity.rights.includes('w_tuteur') &&
          studentsequence.tutor) ||
        (currentUser.role === 'tuteur' &&
          activity.rights.includes('r_tuteur') &&
          studentsequence.tutor)
        " class="card_activity">
            <div v-if="indexActivity + 1 ===
        trainingsequence.training_activities.length
        ">
              {{ endLoading() }}
            </div>
            <div class="activity-item is-flex align-items-center">
              <div v-if="studentsequence.student_activities[indexActivity].status ===
        0 ||
        studentsequence.student_activities[indexActivity].status === 1
        ">
                <img :src="require(`@/assets/img/steps/status1.svg`)" alt="" class="img_status mr-3" />
              </div>
              <div v-else-if="studentsequence.student_activities[indexActivity].status ===
        3 ||
        studentsequence.student_activities[indexActivity].status === 4
        ">
                <img :src="require(`@/assets/img/steps/status3.svg`)" alt="" class="img_status mr-3" />
              </div>
              <div v-else-if="studentsequence.student_activities[indexActivity].status === 6
        ">
                <img :src="require(`@/assets/img/steps/status6.svg`)" alt="" class="img_status mr-3" />
              </div>
              <div v-else-if="studentsequence.student_activities[indexActivity].status === 7
        ">
                <img :src="require(`@/assets/img/steps/status7.svg`)" alt="" class="img_status mr-3" />
              </div>
              <div>
                <span v-if="activity.legacy_activity" class="title_activity">{{
        activity.legacy_activity.name
      }}</span>
                <span v-else class="title_activity">{{ activity.name_override }}
                </span>
                <div v-if="!without_dates">
                  <div v-if="studentsequence.student_activities[indexActivity]
        .start_date
        ">
                    <div>
                      <span>
                        du
                        {{
        studentsequence.student_activities[indexActivity]
          .start_date | day
      }}
                        à
                        {{
          studentsequence.student_activities[indexActivity]
            .start_date | hour
        }}
                        au

                        {{
          studentsequence.student_activities[indexActivity]
            .end_date | day
        }}
                        à
                        {{
          studentsequence.student_activities[indexActivity]
            .end_date | hour
        }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <b-button v-if="without_dates" outlined rounded type="is-primary" @click="
        gotoActivity(
          studentsequence.student_activities[indexActivity].id
        )
        " class="ml-auto">
                <span v-if="studentsequence.student_activities[indexActivity].status ===
        3 ||
        studentsequence.student_activities[indexActivity].status ===
        4 ||
        studentsequence.student_activities[indexActivity].status ===
        7 ||
        studentsequence.student_activities[
          indexActivity
        ].rights.includes('r_' + currentUser.role) ||
        studentsequence.student_activities[
          indexActivity
        ].rights.includes('a_' + currentUser.role) ||
        (currentUser.role === 'coordinateur' &&
          studentsequence.student_activities[
            indexActivity
          ].rights.includes('w_teacher') === false) ||
        currentUser.role === 'superviseur' ||
        (currentUser.role === 'formateur' &&
          studentsequence.student_activities[
            indexActivity
          ].rights.includes('w_teacher') === false)
        ">voir</span>
                <span v-else>rédiger</span></b-button>
              <div v-else class="mt-2 ml-auto">
                <b-button rounded type="is-primary" class="mt-2 ml-auto" v-if="(currentUser.role === 'coordinateur' ||
        currentUser.role === 'formateur') &&
        !studentsequence.student_activities[indexActivity]
          .start_date
        " @click="
        gotoActivity(
          studentsequence.student_activities[indexActivity].id
        )
        ">
                  planifier
                </b-button>
                <b-button rounded type="is-primary is-outlined" class="mt-2 ml-auto" v-else-if="currentUser.role === 'apprenant' &&
        studentsequence.student_activities[
          indexActivity
        ].rights.includes('w_apprenant')
        " @click="
        gotoActivity(
          studentsequence.student_activities[indexActivity].id
        )
        ">
                  rédiger
                </b-button>
                <b-button outlined rounded type="is-primary" v-else @click="
        gotoActivity(
          studentsequence.student_activities[indexActivity].id
        )
        ">voir</b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="studentsequence.legacy_sequence">
      <div id="progressions" v-if="!disallow_progressions">
        <div v-if="studentsequence.legacy_sequence.sequence_type == 5 ||
        studentsequence.legacy_sequence.sequence_type == 8 ||
        studentsequence.legacy_sequence.sequence_type == 13 ||
        studentsequence.legacy_sequence.sequence_type == 15 ||
        studentsequence.legacy_sequence.sequence_type == 16
        ">
          <div class="container contentSequence">
            <b-collapse :open="false" animation="slide" aria-id="progressionslist">
              <template #trigger="props">
                <div class="accordion" role="button">
                  <a class="card-header-icon">
                    <b-icon :icon="props.open ? 'menu-down' : 'menu-up'">
                    </b-icon>
                  </a>
                  <p class="card-header-title">Les progressions enregistrées</p>
                </div>
              </template>
              <StudentListProgression :sequence="Number($route.params.idSequence)" />
            </b-collapse>
          </div>
        </div>
      </div>
      <div class="mb-4" v-else></div>
    </div>
    <div class="container contentSequence pb-5 mb-5">
      <b-collapse :open="false" animation="slide" aria-id="presentation" v-if="studentsequence.estimated_duration ||
        studentsequence.start_date ||
        studentsequence.end_date ||
        !without_dates
        ">
        <template #trigger="props">
          <div class="accordion" role="button">
            <a class="card-header-icon">
              <b-icon :icon="props.open ? 'menu-down' : 'menu-up'"> </b-icon>
            </a>
            <p class="card-header-title">Présentation de l'expérience</p>
          </div>
        </template>
        <div id="collapseResources" class="content pb-5 pt-2">
          <div v-if="editSequence === false">
            <div class="bloc-paragraph card_item mt-5" v-if="studentsequence.estimated_duration ||
        studentsequence.start_date ||
        studentsequence.end_date ||
        !without_dates
        ">
              <div class="mb-1 mt-4" v-if="studentsequence.estimated_duration">
                <span class="subtitle-paragraph">Temps estimé : </span>
                <span v-if="studentsequence.estimated_duration">{{ studentsequence.estimated_duration }}}
                </span>
                <span v-else>non précisé</span>
              </div>

              <div v-if="studentsequence.legacy_sequence && trainingsequence.teachers
        ">
                <div class="is-flex align-items-center" v-if="tutorName">
                  <span class="subtitle-paragraph mb-0 mr-1">Tuteur : </span>
                  <span v-if="tutorName"> {{ tutorName }} </span>
                </div>
                <!-- Todo Add teachers name -->
                <!-- <div v-if="trainingsequence.teachers">
                  <span class="subtitle-paragraph mb-0 mr-1"
                    >Formateur<span v-if="trainingsequence.teachers.length > 1"
                      >s</span
                    >
                    :
                  </span>
                  <span
                    v-if="trainingsequence.teachers.length > 0"
                    class="addCommas"
                  >
                    <span
                      v-for="teacher in trainingsequence.teachers"
                      :key="teacher.id"
                    >
                      <span v-if="getUser(teacher)">
                        {{ getUser(teacher).user.first_name }}
                        {{ getUser(teacher).user.last_name }}</span
                      ></span
                    >
                  </span>
                  <span v-else>aucun formateur renseigné</span>
                </div> -->
                <div v-if="!tutorName">
                  <b-message class="mt-3" type="is-danger" v-if="studentsequence.legacy_sequence.sequence_type === 1 ||
        studentsequence.legacy_sequence.sequence_type === 2 ||
        studentsequence.legacy_sequence.sequence_type === 7 ||
        studentsequence.legacy_sequence.sequence_type === 10
        ">
                    <b-icon icon="warning" type="is-danger"></b-icon>
                    Attention un tuteur est obligatoire pour ce type
                    d'expérience
                  </b-message>
                </div>
              </div>
              <div class="card_event mb-5 mt-5" v-if="studentsequence.start_date ||
        studentsequence.end_date ||
        !without_dates
        ">
                <div class="title-paragraph">Dates estimées</div>
                <div v-if="studentsequence.start_date">
                  <span v-if="studentsequence.end_date">entre le</span>
                  {{ studentsequence.start_date | day }}
                  <span v-if="studentsequence.end_date">
                    et le {{ studentsequence.end_date | day }}</span>
                </div>
                <div v-else>aucune date renseignée</div>
                <div v-if="currentUser.role === 'coordinateur' ||
        currentUser.role === 'superviseur'
        " class="mb-0">
                  <div>
                    <b-button v-if="editSequence === false" class="mb-2 mt-4" @click="editSequence = !editSequence"
                      type="is-primary is-rounded mr-3" rounded>modifier</b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- patch to teacher -->
          <div v-if="editSequence === true">
            <div class="helpUser contentActivity" v-if="currentUser.role === 'coordinateur' ||
        currentUser.role === 'superviseur'
        ">
              <div class="title">
                Précisez la période estimée pour cette expérience
              </div>
              <div>
                Attention, il ne s'agit pas des dates définitives. Pour
                programmer à proprement parlé une activité, vous devez spécifier
                la date dans l'onglet de cette activité, et vous assurer que
                chaque utilisateur a bien confirmé la date.
              </div>
            </div>
            <div class="card_item">
              <div class="columns">
                <div class="column is-narrow">
                  <b-field label="Date de début">
                    <b-datepicker v-model="studentsequence.start_date" :show-week-number="showWeekNumber"
                      :locale="locale" icon="calendar-today" trap-focus :first-day-of-week="1">
                    </b-datepicker>
                  </b-field>
                </div>
                <div class="column is-narrow">
                  <b-field label="Date de fin">
                    <b-datepicker v-model="studentsequence.end_date" :show-week-number="showWeekNumber" :locale="locale"
                      icon="calendar-today" trap-focus :first-day-of-week="1">
                    </b-datepicker>
                  </b-field>
                </div>
              </div>
              <div class="title-paragraph">
                Infos personnalisées pour votre apprenant
              </div>
              <span class="subtitle-paragraph">Vous pouvez ajouter un sous-titre personnalisé pour cet
                apprenant
              </span>
              <b-input v-model="studentsequence.subtitle" class="mb-5 mt-3"></b-input>
              <span class="subtitle-paragraph">Vous pouvez ajouter un commentaire personnalisé pour cet
                apprenant
              </span>
              <div>
                <b-input type="textarea" v-model="studentsequence.comment" class="mb-5 mt-3"></b-input>
              </div>
              <div>
                <b-button v-if="editSequence === true" @click="editSequence = !editSequence"
                  type="is-primary is-rounded mr-3 is-outlined" rounded>annuler</b-button>
                <b-button @click="patchSequence()" type="is-primary is-rounded mr-3" rounded>enregistrer</b-button>
              </div>
            </div>
          </div>
        </div>
      </b-collapse>
      <b-collapse :open="false" animation="slide" aria-id="ressources">
        <template #trigger="props">
          <div class="accordion" role="button">
            <a class="card-header-icon">
              <b-icon :icon="props.open ? 'menu-down' : 'menu-up'"> </b-icon>
            </a>
            <p class="card-header-title">Les ressources</p>
          </div>
        </template>
        <div class="" id="collapseResources">
          <div class="w-100 columns is-vcentered is-tablet mb-4 justify-content-between">
            <div class="column">
              <h1 class="title-paragraph mb-0">
                Ressources associées à cette expérience
              </h1>
              <div class="subtitle-paragraph mt-0 mb-0" v-if="currentUser.role === 'apprenant'">
                <div>
                  Retrouvez les ressources que l'on a déposé pour vous aider.
                </div>
              </div>
            </div>
            <div class="column is-narrow">
              <AppHelpMessage v-if="currentUser.role === 'apprenant'" title="Ressources et documents pour vous"
                message="Retrouvez ici les documents déposés pour vous par l'équipe pédagogique."
                textBtn="Besoin d'aide ?" />
              <AppHelpMessage v-else title="Ressources et documents pour l'apprenant•e" message="Retrouvez ici les documents déposés par
             l'équipe pédagogique pour l'apprenant•e." textBtn="Besoin d'aide ?" />
            </div>
          </div>
          <div v-if="currentUser.role === 'coordinateur' ||
        currentUser.role === 'formateur' ||
        currentUser.role === 'superviseur' ||
        currentUser.role === 'tuteur'
        ">
            <div class="card_simple is-primary w-100 has-text-centered btn" @click="isOpen = !isOpen">
              <div>
                <b-icon icon="resource" type="is-primary" class="mr-3"></b-icon>
                <b style="cursor: pointer">Ajoutez une ressource</b>
              </div>
            </div>
            <b-collapse v-model="isOpen">
              <ResourceCreation :isOpen.sync="isOpen" :sequence="studentsequence" :typeoftraining="'studenttraining'"
                :idTraining="$route.params.idStudentTraining" :action="'post'" @messageFromChild="initResources"
                @messageFromResource="forceRerenderResource" :key="resourceKey"></ResourceCreation>
            </b-collapse>
          </div>
          <div v-if="resources">
            <div v-if="resources.length">
              <div v-for="resource in resources" :key="resource.id">
                <ResourceCard v-if="resource.owner != studentsequence.student.id" :resource="resource" :action="'edit'"
                  @messageFromChild="initResources">
                </ResourceCard>
              </div>
            </div>
            <div v-else>
              <b-message type="is-info" size="is-small">
                Il n'y a pas de ressources associées à cette expérience pour le
                moment.
              </b-message>
            </div>
          </div>
          <!-----------part 2 width documents uploaded by student ------->
          <div class="mt-5 pt-5">
            <div v-if="currentUser.role === 'apprenant'">
              <div class="w-100 columns is-vcentered is-tablet mb-4 justify-content-between">
                <div class="column">
                  <h1 class="title-paragraph">Ajoutez des documents</h1>
                  <div class="subtitle-paragraph mt-3 mb-0">
                    Toutes les preuves de compétences acquises en situation de
                    travail ou en atelier sont ici bonnes à déposer.
                  </div>
                </div>
                <div class="column is-narrow">
                  <AppHelpMessage title="Ajoutez des documents" message="Ici vous pouvez déposer des documents qui vous semblent utiles ou
              nécessaires pour mieux comprendre ce que vous avez réalisé dans
              cette expérience ou ce que vous en avez retenu." textBtn="Besoin d'aide ?" />
                </div>
              </div>
              <div class="card_simple is-primary w-100 has-text-centered" @click="isOpen = !isOpen">
                <div>
                  <b-icon icon="resource" type="is-primary" class="mr-3"></b-icon>
                  <b style="cursor: pointer">Ajoutez une ressource</b>
                </div>
              </div>
              <b-collapse v-model="isOpen">
                <ResourceCreation :isOpen.sync="isOpen" :sequence="studentsequence" :typeoftraining="'studenttraining'"
                  :idTraining="$route.params.idStudentTraining" :action="'post'" @messageFromChild="initResources"
                  @messageFromResource="forceRerenderResource" :key="resourceKey"></ResourceCreation>
              </b-collapse>
            </div>
            <div v-else class="mb-3">
              <h1 class="title-paragraph">
                Documents téléchargés par l'apprenant•e
              </h1>
            </div>
            <div v-if="resources">
              <div v-if="resources.length">
                <div v-for="resource in resources" :key="resource.id">
                  <ResourceCard v-if="resource.owner === studentsequence.student.id" :resource="resource"
                    :action="'edit'" :isOpen.sync="isOpen" @messageFromChild="initResources"></ResourceCard>
                </div>
              </div>
              <div v-if="!resources.length">
                <b-message type="is-info" size="is-small">
                  Il n'y a pas de ressources déposées par l'apprenant pour le
                  moment.
                </b-message>
              </div>
            </div>
          </div>
        </div>
      </b-collapse>
      <b-collapse :open="false" animation="slide" aria-id="skills">
        <template #trigger="props">
          <div class="accordion" role="button">
            <a class="card-header-icon">
              <b-icon :icon="props.open ? 'menu-down' : 'menu-up'"> </b-icon>
            </a>
            <p class="card-header-title">Compétences cibles</p>
          </div>
        </template>
        <div id="collapseSkills">
          <SkillsSequence class="pb-5" :typeoftraining="'student'" :sequenceId="Number(this.$route.params.idSequence)"
            :trainingId="Number(this.$route.params.idStudentTraining)" />
        </div>
      </b-collapse>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';
import localization from 'moment/locale/fr';
import _ from 'lodash';
import SkillsSequence from '@/components/Skills/SkillsSequence.vue';
import ResourceCreation from '@/components/Resources/ResourceCreation.vue';
import ResourceCard from '@/components/Resources/ResourceCard.vue';
import AppHelpMessage from '@/components/AppHelpMessage.vue';
import StudentListProgression from '@/components/Activity/StudentListProgression.vue';

moment.updateLocale('fr', localization);

export default {
  components: {
    SkillsSequence,
    ResourceCard,
    ResourceCreation,
    AppHelpMessage,
    StudentListProgression,
  },
  filters: {
    day(date) {
      return moment(date).format('D/MM/YY');
    },
    hour(date) {
      return moment(date).format('h:mm');
    },
  },
  data() {
    return {
      isLoading: true,
      isFullPage: false,
      studentsequence: [],
      trainingsequence: [],
      editSequence: false,
      tutorName: null,
      teacherName: null,
      resources: [],
      isOpen: false,
      resourceKey: 0,
      without_dates: '',
      showWeekNumber: false,
      locale: 'fr-FR',
      disallow_progressions: true,
    };
  },
  computed: {
    ...mapState('auth', ['currentUser']),
  },
  methods: {
    startLoading() {
      this.isLoading = true;
    },
    endLoading() {
      this.isLoading = false;
    },
    order(array) {
      return _.orderBy(array, 'position');
    },
    initStudentSequence() {
      this.$http
        .get(`/agenda/student_sequence/${this.$route.params.idSequence}/?query={*,student_activities{id,position,status,rights,start_date,end_date,mandatory}}&format=json`)
        .then((response) => {
          this.studentsequence = response.data;
          const activites = this.order(this.studentsequence.student_activities);
          const legacy = this.studentsequence.legacy_sequence;
          this.initLegacySequence(legacy.id);
          this.getTrainingRights();
          this.studentsequence.student_activities = activites;
          this.setdata();
          this.initResources();
          this.endLoading();
        });
    },
    // have to load legacy sequence beacuse name, intro of activities are missing
    initLegacySequence(idLegacySequence) {
      this.startLoading();
      this.$http
        .get(`/training_sequences/${idLegacySequence}/?query={training_activities,teachers}&format=json`)
        .then((response) => {
          this.trainingsequence = response.data;
          const activites = this.order(this.trainingsequence.training_activities);
          this.trainingsequence.training_activities = activites;
          if (this.studentsequence.tutor) { this.gettutorName(this.studentsequence.tutor); }
          this.wsSequenceLoaded = true;
          // this.endLoading();
        });
    },
    verifStatus() {
      // verif if all actvities have a status "effectuée"
      const nbActivities = this.studentsequence.student_activities.length;
      let verifFinished = 0;
      for (let i = 0; i < nbActivities; i += 1) {
        if (this.studentsequence.student_activities[i].status !== 3) {
          this.finished = false;
          verifFinished = 0;
        } else {
          // at each yes, verif finished is incremented
          verifFinished += 1;
          if (verifFinished === nbActivities) {
            this.finished = true;
            // if it's ok we'll patch student sequence with status 3
            this.patchStatusSequence(3);
          }
        }
      }
    },
    verifStatusSimple() {
      const nbActivities = this.studentsequence.student_activities.length;
      // let verifFinished = 0;
      for (let i = 0; i < nbActivities; i += 1) {
        if (this.studentsequence.student_activities[i].status === 3) {
          // verifFinished = 0;
          this.patchStatusSequence(3);
        }
      }
    },
    setdata() {
      if (this.studentsequence.start_date) {
        const startDate = new Date(this.studentsequence.start_date);
        this.studentsequence.start_date = startDate;
      }
      if (this.studentsequence.end_date) {
        const endDate = new Date(this.studentsequence.end_date);
        this.studentsequence.end_date = endDate;
      }
    },
    gotoActivity(id) {
      this.startLoading();
      this.$router.push({
        name: 'StudentActivity',
        params: {
          idTraining: this.$route.params.idTraining,
          idStudentTraining: this.$route.params.idStudentTraining,
          idSequence: this.studentsequence.id,
          idActivity: id,
        },
      });
    },
    patchStatusSequence(statusSequence) {
      this.$http
        .patch(`/agenda/student_sequence/${this.$route.params.idSequence}/`, {
          status: statusSequence,
        }).then(() => {
          if (statusSequence === 4) { this.$buefy.toast.open({ message: 'Expérience validée !', type: 'is-success' }); }
          this.initStudentSequence();
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
          this.$buefy.dialog.alert({
            title: 'Mince...',
            message: 'Une erreur s\'est produite, veuillez réessayer. Si l\'erreur persiste vous pouvez contacter le support technique.',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
          });
        });
    },
    gettutorName(id) {
      this.$http
        .get(`/whois/${id}`)
        .then((response) => {
          this.tutorName = response.data.name;
        });
    },
    addProgression() {
      // this.isProgressionActive = true;
      this.$router.push({
        name: 'StudentProgressionEdit',
        params: {
          idTraining: this.$route.params.idTraining,
          idStudent: this.studentsequence.student_id,
          idSequence: this.$route.params.idSequence,
          idActivity: 's',
          idRevision: 'new',
          from: this.$route.name,
        },
      });
    },
    initResources() {
      this.startLoading();
      const sequencetypeResource = '';
      this.$http.get(`/get_resources/?student_sequence=${this.$route.params.idSequence}${sequencetypeResource}&format=json`).then((response) => {
        this.resources = response.data.resources;
        this.endLoading();
      });
    },
    forceRerenderResource() {
      // Every time that forceRerender is called, our prop componentKey will change.
      this.resourceKey += 1;
    },
    getTrainingRights() {
      this.$http
        .get(`/trainings/${this.$route.params.idTraining}/?query={editable_without_dates,disallow_progressions}&format=json`)
        .then((response) => {
          this.without_dates = response.data.editable_without_dates;
          this.disallow_progressions = response.data.disallow_progressions;
        });
    },
    gotoStats() {
      this.$router.push({
        name: 'StudentTraining',
        params: {
          idStudent: this.$route.params.idStudent,
          idTraining: this.$route.params.idTraining,
          idStudentTraining: this.$route.params.idStudentTraining,
          from: this.$route.name,
          tab: 'activite',
        },
      });
    },
    patchSequence() {
      this.$http
        .patch(`/agenda/student_sequence/${this.$route.params.idSequence}/`, {
          start_date: this.studentsequence.start_date,
          end_date: this.studentsequence.end_date,
          comment: this.studentsequence.comment,
          subtitle: this.studentsequence.subtitle,
        }).then(() => {
          this.$buefy.toast.open({ message: 'Expérience modifiée !', type: 'is-success' });
          this.editSequence = false;
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
          this.$buefy.dialog.alert({
            title: 'Mince...',
            message: 'Une erreur s\'est produite, veuillez réessayer. Si l\'erreur persiste vous pouvez contacter le support technique.',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
          });
        });
    },
  },
  created() {
    this.startLoading();
  },
  mounted() {
    this.initStudentSequence();
  },
  destroyed() {
  },
};
</script>

<style lang="scss" scoped>
.boxActivity:last-child .card_activity::after {
  content: "";
}

.boxActivity .card_activity:last-child::after {
  content: "|";
}

.bloc-intro {
  margin-top: 2rem;

  .title-paragraph {
    font-size: 1.2rem;
  }
}

@media (max-width: 768px) {

  .accordion .card-header-title,
  #bloc-activities .card-header-title {
    font-size: 1.1rem;
  }

  .card-header-icon {
    width: 0rem;
  }

  .title_activity {
    font-size: 1.1rem;
  }
}
</style>
