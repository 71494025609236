<template>
  <nav class="breadcrumb" aria-label="breadcrumbs">
    <ul>
      <li v-for="(crumb, x) in crumbs" :key="x" v-bind:class="crumb.class">
        <!-- <a :href="crumb.href">{{ crumb.text }}</a> -->
        <router-link :to="{path:crumb.href, params:{stepNumber : crumb.step}}">{{
          crumb.text
        }}</router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  props: {
    crumbs: {
      type: Array,
    },
  },
};
</script>

<style scoped>
</style>
