<template>
  <div class="is-flex">
    <AppSidebar />
    <b-loading :is-full-page="isFullPage" v-model="isLoading"> </b-loading>
    <div class="main-content">
      <div class="lineCrumbAndAccount ml-auto">
        <AppAccountMenu v-bind:appAccount="crumbs" />
      </div>
      <div class="header-page is-flex flex-column">
        <div class="justify-content-between columns lineHeaderTitle">
          <div class="column title-page short-title">
            {{ $route.meta.header_title }}
          </div>
        </div>
      </div>
      <div class="content-page pt-5">
        <!-- content here -->
        <h1>A propos</h1>
        <br /><br />
        <p>
          Le Logiciel dénommé “inSitu” est édité par Seity, société à
          responsabilité limitée au capital de 667 €, immatriculée au Registre
          du Commerce et des Sociétés de Marseille sous le numéro 823 545 819,
          dont le siège social est situé 66 Boulevard Notre Dame 13006
          Marseille, France (« Seity »).
        </p>
        <br />
        <h1>Objet</h1>
        <br /><br />
        <p>
          L’utilisation du Logiciel, qu’il soit gratuit (avec un compte de
          démonstration fourni) ou payant (lorsque l’Utilisateur dispose d’un
          abonnement au Logiciel) est soumise au respect des présentes CGU par
          l’Utilisateur
        </p>
        <br />
        <h1>Définitions</h1>
        <br /><br />
        <p>
          Les Parties conviennent et acceptent que les termes suivants utilisés
          avec une majuscule, au singulier et/ou au pluriel, auront, dans le
          cadre des présentes Conditions Générales d'Utilisation, la
          signification définie ci-après :
        </p>
        <ul style="list-style-type: disc; margin-left: 1rem">
          <li>
            « Contrat » : désigne les présentes Conditions Générales
            d'Utilisation ainsi que la Politique de protection des données
            personnelles ;
          </li>
          <li>
            « Plateforme » : plateforme numérique de type site Web et/ou
            application mobile permettant l'accès au Service ainsi que son
            utilisation ;&nbsp;
          </li>
          <li>
            « Service » : désigne l’ensemble des fonctionnalités de la
            Plateforme permettant la facilitation de la formation en
            apprentissage ou en situation de travail ;
          </li>
          <li>
            « inSitu » désigne le logiciel en mode Saas, ainsi que son
            propriétaire Seity
          </li>
          <li>
            « Utilisateur » : désigne toute personne qui utilise la Plateforme,
            quel que soit son rôle (apprenant, formateur, tuteur, manager ou
            coordinateur pédagogique et visiteur) ;
          </li>
        </ul>
        <p>
          Les présentes Conditions Générales d'Utilisation (ci-après les "CGU")
          régissent nos rapports avec vous, personne accédant à la Plateforme,
          applicables durant votre utilisation de la Plateforme et, si vous êtes
          un Utilisateur jusqu'à désactivation de votre compte. Si vous n'êtes
          pas d'accord avec les termes des CGU il vous est vivement recommandé
          de ne pas utiliser notre Plateforme et nos services.
        </p>
        <p>
          Vous, Utilisateur, confirmez avoir lu, compris et accepté les
          présentes CGU ainsi que notre politique de Protection des données
          personnelles, que vous êtes invité à lire et accepter lors de la
          première utilisation, en cochant la case prévue à cet effet.
        </p>
        <p>
          Nous vous encourageons par ailleurs à consulter les « Conditions
          Générales d'Utilisation et la Politique de protection des données
          personnelles » régulièrement lors de leurs mises à jour. En effet,
          Seity se réserve le droit de modifier ou d’adapter les présentes CGU à
          tout moment, en notifiant toute nouvelle version à l’Utilisateur, qui
          s’engage à prendre connaissance de ces modifications. Vous serez
          informé des modifications par courriel ou via notre Plateforme, et ce
          pour vous permettre d’en prendre connaissance avant leur mise en
          effet. Si vous continuez à utiliser notre Plateforme après la
          publication ou l'envoi d'un avis concernant les modifications
          apportées aux présentes conditions, cela signifie que vous acceptez
          les mises à jour. Les CGU qui vous seront opposables seront celles en
          vigueur lors de votre utilisation de la Plateforme.
        </p>
        <p><br /></p>
        <h2>Article 1 — Accès au Logiciel</h2>
        <p><br /></p>
        <h3>1.1 Conditions d'inscription à la Plateforme</h3>
        <p>
          Certaines fonctionnalités de la Plateforme nécessitent d'être inscrit
          et d'obtenir un compte.&nbsp;
        </p>
        <p>
          Vous déclarez avoir la capacité d'accepter les présentes conditions
          générales d'utilisation, c'est-à-dire avoir plus de 16 ans et ne pas
          faire l'objet d'une mesure de protection juridique des majeurs (mise
          sous sauvegarde de justice, sous tutelle ou sous curatelle).
        </p>
        <p>
          Avant d'accéder à notre Plateforme, le consentement des mineurs de
          moins de 16 ans doit être donné par le titulaire de l'autorité
          parentale.
        </p>
        <p><br /></p>
        <h3>1.2 Création de compte</h3>
        <p>L’inscription à la Plateforme se déroule de la façon suivante :</p>
        <ul style="list-style-type: disc; margin-left: 1rem">
          <li>
            Les équipes de votre Centre de Formation demandent une création de
            compte, à partir des données fournies, vous êtes seuls responsables
            de la licéité, de la sincérité et de l’exactitude des informations
            communiquées.
          </li>
          <li>
            Vous recevrez ensuite un courriel avec un mot de passe temporaire
            que vous devrez modifier lors de votre première connexion à notre
            Plateforme.
          </li>
          <li>
            Seity offre la possibilité de s’identifier en utilisant un compte
            existant sur une plateforme tierce (notamment via Microsoft Office
            ou yPareo), en utilisant la fonction prévue à cet effet. En
            utilisant une telle fonctionnalité, nous aurons accès, publierons
            sur notre Plateforme et conserverons certaines informations de votre
            compte Microsoft Office ou NetYPareo. Vous pouvez à tout moment
            supprimer le lien entre votre compte sur la Plateforme inSitu et
            votre compte Microsoft Office ou NetYPareo en effectuant la demande
            auprès de votre Centre de Formation. Si vous souhaitez en savoir
            plus sur l'utilisation de vos données dans le cadre de votre compte
            Microsoft Office ou NetYPareo, consultez notre Politique de
            protection des données personnelles et celles de Microsoft Office ou
            NetYPareo.
          </li>
        </ul>
        <p>
          A l'occasion de la création de votre compte, et ce quelle que soit la
          méthode choisie pour ce faire, vous vous engagez à fournir des
          informations personnelles vraies, exactes, complètes et à les mettre à
          jour par l'intermédiaire de votre profil ou en en avertissant votre
          Centre de Formation et inSitu, afin d'en garantir la pertinence et
          l'exactitude tout au long de l’utilisation d’inSitu.
        </p>
        <p>
          Les informations que vous avez fournies pendant l'inscription peuvent
          être corrigées en contactant votre Centre de Formation et inSitu. Vous
          acceptez de respecter les lois applicables lorsque vous utilisez les
          services, et vous ne pouvez utiliser les services qu'à des fins
          légales. Le contenu présent sur la Plateforme doit uniquement être
          utilisé pour un usage privé.
        </p>
        <p>
          Lorsque vous choisissez votre mot de passe, vous ne devez pas utiliser
          de mot de passe simpliste (par exemple : 123456).
        </p>
        <p>
          L'identifiant et le mot de passe seront strictement personnels et
          confidentiels et vous devrez les conserver et les utiliser de manière
          à en préserver la stricte confidentialité.
        </p>
        <p>
          Vous vous engagez à garder secret le mot de passe choisi lors de la
          création de votre compte et à ne le communiquer à personne. En cas de
          perte ou divulgation de votre mot de passe, vous vous engagez à en
          informer sans délai inSitu. Vous êtes seul responsable de
          l'utilisation faite de votre compte par un tiers, tant que vous n'avez
          pas expressément notifié inSitu de la perte, l'utilisation frauduleuse
          ou la divulgation de votre mot de passe à un tiers.
        </p>
        <p>
          Vous vous engagez à ne pas créer ou utiliser, sous votre propre
          identité ou celle d'un tiers, d'autres comptes que celui initialement
          créé. Vous ne pouvez pas autoriser des tiers à utiliser votre compte.
          Vous ne pouvez pas céder ou, quoi qu'il en soit, transférer votre
          compte à toute autre personne ou entité.
        </p>
        <p>
          L’Utilisateur sera seul autorisé à accéder au Service à l'aide de son
          identifiant et son mot de passe. Toute utilisation de la Plateforme au
          moyen de ses identifiants et mot de passe est réputée avoir été faite
          par l’Utilisateur lui-même. En cas d'utilisation par un tiers de ses
          identifiant et mot de passe, l’Utilisateur devra en avertir
          immédiatement inSitu en lui adressant un courriel à l'adresse suivante
          : hello@insituapp.io.
        </p>
        <p>
          L’Utilisateur qui a perdu son mot de passe devra se connecter sur la
          plateforme inSitu et suivre la procédure en cliquant sur le lien «
          accès oubliés ? ».
        </p>
        <p>
          L’Utilisateur est responsable de l'utilisation de la Plateforme et de
          toutes les actions réalisées au sein de la Plateforme avec son
          identifiant et son mot de passe, sauf si l'utilisation de son compte a
          été faite après sa désinscription, ou après notification à inSitu
          d'une utilisation abusive de son compte.
        </p>
        <p><br /></p>
        <h2>1.3. Conditions d'accès à la Plateforme</h2>
        <p><br /></p>
        <h3>1.3.1 Vous avez un compte Utilisateur&nbsp;</h3>
        <p>
          Si vous bénéficiez d’un compte avec abonnement vous pouvez accéder à
          la Plateforme et à l’ensemble des fonctionnalités. Suite à la création
          de votre compte, vous pourrez accéder à votre page de profil où vous
          renseignez certaines informations qui sont obligatoires et d'autres
          qui sont optionnelles.
        </p>
        <p>
          Vous pouvez accéder aux données des parcours de formations effectués.
          Vous avez la possibilité d’effectuer des évaluations ou d’échanger
          avec l’équipe pédagogique. Si votre profil le permet, d’accéder aux
          statistiques et aux données des parcours de formations de groupes
          d’apprenants.&nbsp;
        </p>
        <p><br /></p>
        <h3>1.3.2 Si vous n'avez pas de compte Utilisateur</h3>
        <p>
          Si vous n'avez pas créé de compte Utilisateur sur notre Plateforme,
          vous pouvez tout de même accéder à la Plateforme mais vous ne pourrez
          pas bénéficier de toutes les fonctionnalités et vous n'aurez pas de
          profil personnel. En effet certains accès ne vous seront pas
          autorisés, comme l'accès intégral aux parcours de formation mise en
          ligne par les équipes pédagogiques et les fonctionnalités
          d’évaluation. Les présentes CGU vous sont opposables car elles
          régissent les conditions d'utilisation du Service, vous devez en
          prendre connaissance et les accepter avant de poursuivre votre
          navigation sur la Plateforme.
        </p>
        <p><br /></p>
        <h3>1.3.3 Si votre compte Utilisateur est limité</h3>
        <p>
          Si vous avez bénéficié d’un compte avec abonnement vous pouvez accéder
          à la Plateforme et à certaines fonctionnalités. Suite à la création de
          votre compte, vous pourrez accéder à votre page de profil où vous
          renseignez certaines mentions qui sont obligatoires et d'autres qui
          sont optionnelles.
        </p>
        <p>
          Vous pouvez accéder aux données des parcours de formations effectués.
          Vous n’aurez en revanche pas la possibilité d’effectuer des
          évaluations ou d’échanger avec l’équipe pédagogique. Les présentes CGU
          vous sont opposables car elles régissent les conditions d'utilisation
          du Service, vous devez en prendre connaissance et les accepter avant
          de poursuivre votre navigation sur la Plateforme.
        </p>
        <p><br /></p>
        <h2>Article 2. Informations fournies par les utilisateurs</h2>
        <p>
          Chaque Utilisateur garantit à inSitu que les informations qu'il
          fournit quant à son identité et ses coordonnées dans le cadre du
          Service sont vraies, exactes, complètes et à jour. Vous êtes seul
          responsable de la sincérité et de l'exactitude de ces informations.
          Vous vous engagez à mettre à jour régulièrement l'ensemble des
          informations afin de préserver leur exactitude et à effectuer les
          nécessaires demandes de mise à jour en contactant votre Centre de
          Formation et inSitu.
        </p>
        <p><br /></p>
        <p>
          inSitu ne pourra en aucun cas être tenue responsable des erreurs,
          omissions, imprécisions pouvant être relevées dans les informations
          que vous nous avez fournies ou qui ont été fournies par votre Centre
          de Formation, ni du préjudice pouvant éventuellement en découler pour
          les autres Utilisateurs ou pour des tiers.
        </p>
        <p>
          Vous êtes responsable de toute l'activité qui se déroule sur votre
          compte, et vous acceptez de préserver à tout moment la sécurité et le
          secret de votre identifiant et de votre mot de passe. Vous ne pouvez
          posséder qu'un seul compte.
        </p>
        <p><br /></p>
        <h2>Article 3. Fonctionnalités du service</h2>
        <p><br /></p>
        <h3>3.1. Les contenus pédagogiques en ligne</h3>
        <p>
          Tout contenu pédagogique publié au sein du Service demeure la
          propriété de son auteur.
        </p>
        <h3><br /></h3>
        <h3>3.2. Espaces de discussion</h3>
        <p>
          Les Utilisateurs ont la faculté d'accéder à des espaces de discussion
          où ils peuvent consulter notamment les fils de discussion entre
          Utilisateurs, et échanger avec ces derniers sur une question donnée.
        </p>
        <p>
          Vous vous engagez à ne pas diffuser de correspondance en dehors de la
          Plateforme, et/ou sur un espace public.
        </p>
        <p>
          Lorsque vous publiez un message, vous vous obligez à respecter en
          particulier les dispositions de l'article 7.2 ci-après et celles de la
          « Charte de Bonne Conduite »(article 7).
        </p>
        <p>
          Les Utilisateurs et les Abonnés peuvent visualiser les messages
          d’autres Utilisateurs sur les espaces de discussion et les signaler,
          conformément à l'article 5 ci-après.
        </p>
        <h2><br /></h2>
        <h2>Article 4. Propriété intellectuelle</h2>
        <h3><br /></h3>
        <h3>
          4.1. Propriété intellectuelle afférente au Service, à notre Plateforme
          et aux éléments qui les composent
        </h3>
        <p>
          A l'exception des contenus pédagogiques et du contenu généré par la
          complétion des expériences liées aux situations de travail par les
          Utilisateurs et/ou par les Partenaires d'inSitu, l'ensemble des
          éléments techniques, graphiques, textuels ou autres constituant le
          Service et/ou notre Plateforme (textes, graphismes, logiciels,
          fichiers multimédias, photographies, images, vidéos, sons, plans,
          charte graphique, technologie(s), codes sources, noms, marques, logos,
          visuels, bases de données, etc.) ainsi que notre Plateforme et le
          Service eux-mêmes sont la propriété exclusive d’inSitu.
        </p>
        <p>
          L'Utilisateur reconnaît qu'aucune propriété ne lui est transmise, et
          qu'aucun droit ou licence ne lui est accordé(e), en dehors d'un droit
          d'utiliser le Service conformément aux présentes pendant la durée du
          Contrat, et des droits d'utilisation des contenus pédagogiques qui lui
          sont concédés dans le cadre de l’utilisation d’inSitu par le Centre de
          Formation.
        </p>
        <p>
          En conséquence, sauf autorisation expresse et préalable d'inSitu et/ou
          sauf licence Creative Commons l'y autorisant, l'Utilisateur s'engage à
          ne pas :
        </p>
        <ul style="list-style-type: disc; margin-left: 1rem">
          <li>
            Reproduire, à des fins commerciales ou non, des contenus présents au
            sein du Service (à l'exception de ses propres contenus) et/ou les
            éléments techniques, graphiques, textuels ou autres constituant le
            Service et/ou notre Plateforme ;
          </li>
          <li>
            Intégrer tout ou partie du contenu du Service et/ou de notre
            Plateforme dans un site tiers, à des fins commerciales ou non
            commerciales ;
          </li>
          <li>
            Utiliser un robot, notamment d'exploration (spider), une application
            de recherche ou de récupération de sites Web ou tout autre moyen
            permettant d'extraire, réutiliser ou d'indexer tout ou partie du
            contenu du Service et/ou de notre Plateforme ;
          </li>
          <li>
            Collecter les informations sur les Utilisateurs pour leur envoyer
            des messages non sollicités et/ou les intégrer au sein d'un service
            de référencement ou équivalent, gratuit ou payant ;
          </li>
          <li>
            Copier les contenus pédagogiques présents au sein du Service (à
            l'exception de ses propres contenus) et/ou les éléments techniques,
            graphiques, textuels ou autres constituant le service et/ou notre
            Plateforme sur des supports de toute nature permettant de
            reconstituer tout ou partie des fichiers d'origine.
          </li>
        </ul>
        <p>
          Toute utilisation non expressément autorisée d'éléments du Service
          et/ou de notre Plateforme engage la responsabilité civile et/ou pénale
          de son auteur et sera susceptible d'entraîner des poursuites
          judiciaires à son encontre.
        </p>
        <h3><br /></h3>
        <h3>
          4.2. Propriété intellectuelle afférente aux contenus publiés par
          l'Utilisateur au sein du Service
        </h3>
        <p>
          4.2.1. Dans le cadre de l’utilisation du Service, et quelle que soit
          la licence à laquelle un contenu pédagogique est soumis par ailleurs,
          les Utilisateurs accordent à inSitu une licence mondiale,
          non-exclusive, transférable et pouvant donner lieu à l'octroi d'une
          sous-licence, conférant à inSitu le droit de copier, stocker,
          corriger, adapter et diffuser l'ensemble des contenus (en ce compris,
          les contenus pédagogiques, les expériences de formation en situation
          de travail et toute autre activité réalisée par les Utilisateurs au
          sein du Service) fournis par l’Utilisateur au sein du Service. Cette
          licence est concédée au fur et à mesure de la publication des éléments
          concernés, aux seules fins du bon fonctionnement du Service.
        </p>
        <p>
          4.2.2. Les Utilisateurs susmentionnés garantissent qu'ils disposent
          des droits de propriété intellectuelle et des droits de la
          personnalité (et en particulier, le droit à l'image) nécessaires à la
          publication des contenus (en ce compris, les contenus pédagogiques et
          les contenus produits dans le cadre de la complétion des expériences
          en situation de travail) qu'ils publient au sein du Service.
        </p>
        <p>
          Ils garantissent également que les contenus qu'ils publient au sein du
          Service ne contiennent rien qui soit contraire aux droits des tiers et
          aux lois en vigueur, et notamment aux dispositions relatives à la
          diffamation, à l'injure, à la vie privée, au droit à l'image, à
          l'atteinte aux bonnes mœurs ou à la contrefaçon.
        </p>
        <p>
          Vous garantissez ainsi inSitu contre tout recours éventuel d'un tiers
          concernant la publication desdits contenus (en ce compris, les cours)
          au sein du Service.
        </p>
        <p><br /></p>
        <h2>Article 5. Signalement - modération a posteriori</h2>
        <p><br /></p>
        <p>
          Tout Utilisateur a la possibilité de signaler à inSitu tout message ou
          commentaire et plus généralement tout contenu publié au sein du
          Service qui serait contraire au Contrat ou autrement illicite, et
          notamment outrageant, injurieux, diffamatoire, abusif, violent,
          obscène ou pornographique, ou comprenant une provocation à la
          discrimination ou à la haine fondée sur la race, la religion, le sexe,
          ou autre, une provocation aux crimes et délits, ou une apologie de
          crime, ou de nature à porter atteinte aux droit de propriété
          intellectuelle ou aux droits de la personnalité des tiers, ou encore
          de nature à altérer le fonctionnement du Service et/ou de notre
          Plateforme, de quelque manière que ce soit.
        </p>
        <p>
          Pour cela, vous devrez contacter inSitu, soit par courriel à l'adresse
          : hello@insituapp.io soit à l'aide du formulaire accessible au sein du
          Service en cliquant sur l'onglet « Nous contacter », soit par courrier
          postal à l'adresse : inSitu / SEITY, 66 boulevard Notre Dame 13006
          MARSEILLE, France, et procéder comme suit :
        </p>
        <ul style="list-style-type: disc; margin-left: 1rem">
          <li>Décliner votre identité ;</li>
          <li>
            Décrire le contenu litigieux de manière précise et détaillée ainsi
            que sa localisation sur notre Plateforme ;
          </li>
          <li>
            Décrire les motifs de fait et droit pour lesquels le contenu
            litigieux doit être considéré comme manifestement illicite et, par
            suite, retiré de notre Plateforme ;
          </li>
          <li>
            Adresser la copie de la correspondance que vous aurez préalablement
            envoyée à l'auteur de ce contenu pour en demander la modification ou
            le retrait et/ou la justification que vous n'avez pas pu contacter
            cet auteur.
          </li>
        </ul>
        <p>
          Nous nous réservons le droit de supprimer tout contenu illicite ou non
          conforme au Contrat qui aura été préalablement signalé. Tout
          signalement manifestement abusif pourra être sanctionné par inSitu.
        </p>
        <p>
          Pour plus d'informations sur vos obligations, vous êtes invité à
          prendre connaissance de la « Charte de Bonne Conduite »(article 7).
        </p>
        <p><br /></p>
        <h2>Article 6. Protection des données personnelles</h2>
        <p><br /></p>
        <p>
          Seity recueille et traite les données que les Utilisateurs fournissent
          volontairement afin d’accéder à la Plateforme, d’utiliser la
          Plateforme, ainsi que les données relatives aux préférences des
          Utilisateurs et au trafic, conformément à sa politique de
          confidentialité et à sa politique de cookies.
        </p>
        <p>
          En accord avec cette politique de confidentialité, les informations
          recueillies par Seity font l’objet d’un traitement informatique
          destiné notamment à gérer l’accès au Logiciel, les abonnements, les
          prestations de services et de support, l’élaboration d’offres
          commerciales et marketing, ainsi que pour renforcer et améliorer les
          produits et contenus ainsi que l’expérience Utilisateur.
        </p>
        <p>
          Conformément à la réglementation applicable en matière de protection
          des données personnelles, l’Utilisateur dispose des droits d’accès, de
          rectification, d’effacement, de limitation, de portabilité des données
          et le cas échéant d’opposition au traitement. Il peut exercer ces
          droits en s’adressant à l’adresse&nbsp;rgpd@seity.io, sous réserve de
          justifier de son identité.
        </p>
        <p><br /></p>
        <h2>
          Article 7. Obligations des Utilisateurs et Charte de Bonne Conduite
        </h2>
        <p><br /></p>
        <p>
          Dans le cadre de l'utilisation de la Plateforme, nous vous demandons
          de vous engager à :
        </p>
        <ul style="list-style-type: disc; margin-left: 1rem">
          <li>
            Garantir l'exactitude, l'intégrité et la légalité des informations
            que vous fournissez sur la Plateforme quant à votre identité et vos
            coordonnées ;
          </li>
          <li>Garantir le bon usage de la Plateforme ;</li>
          <li>Ne créer qu'un seul compte au sein de la Plateforme ;</li>
          <li>
            S'abstenir de saisir des informations et/ou messages, commentaires
            et autres contenus malveillants, dénigrants, diffamatoires,
            injurieux, obscènes, pornographiques, violents, à caractère raciste,
            xénophobe, discriminatoire, volontairement trompeurs, illicites
            et/ou contraires à l'ordre public ou aux bonnes mœurs ;
          </li>
          <li>
            Respecter les droits des tiers, et notamment le droit de chacun au
            respect de sa vie privée, de son image et de ses autres droits de la
            personnalité, ainsi que les droits de propriété intellectuelle
            (droit d’auteur, droits voisins, droit sur les bases de données,
            droit des marques, droit des brevets, dessins ou modèles, secret de
            fabrique, etc.) ;
          </li>
          <li>
            Ne pas usurper la qualité, l'attribut ou l'identifiant d'un autre
            Utilisateur ou d'un tiers de nature à induire en erreur ou à créer
            une confusion quelconque quant à son identité, à la provenance des
            informations, contenus (cours, messages, commentaires, etc.) qu'il
            diffuse ou transmet au sein de la Plateforme ;
          </li>
          <li>
            Ne pas altérer ou perturber l'intégrité de la Plateforme et/ou des
            données qui y sont contenues ;
          </li>
          <li>
            Ne pas tenter d'obtenir un accès non autorisé à la Plateforme ou aux
            systèmes ou réseaux qui lui sont associés ou d'intercepter des
            données ;
          </li>
          <li>
            Utiliser la Plateforme dans le respect des législations et
            réglementations nationales et/ou internationales applicables.
          </li>
        </ul>
        <p>
          En cas de manquement à l'une de ces obligations, nous nous réservons
          le droit de suspendre temporairement ou définitivement le compte de
          l’Utilisateur.
        </p>
        <p>
          inSitu est une plateforme basée sur le travail collectif induit par la
          formation professionnelle et le partage dont les espaces de discussion
          sont ouverts à tous les Utilisateurs d’un parcours de formation.&nbsp;
        </p>
        <p>
          Les échanges sur inSitu sont modérés a posteriori, c'est-à-dire
          qu'aucun message ne sera relu avant sa publication. Les messages sont
          donc publiés immédiatement, mais sont susceptibles d'être contrôlés
          par l'équipe de modération et les administrateurs de notre Plateforme,
          après publication par l'Utilisateur.
        </p>
        <p>
          Le rôle de l'équipe de modération et des administrateurs est de
          veiller au bon fonctionnement des forums, en écartant tout message qui
          par leur caractère indigne, attentatoire aux personnes, destructeur ou
          manifestement hors sujet, nuisent aux échanges. Les modérateurs et les
          administrateurs excluent également tous messages contraires aux lois
          en vigueur.
        </p>
        <p>Sont ainsi susceptibles de modération (liste non exhaustive) :</p>
        <ul style="list-style-type: disc; margin-left: 1rem">
          <li>
            les messages à caractère racistes, haineux, homophobes, sexistes ou
            diffamatoires
          </li>
          <li>les messages à caractère publicitaires</li>
          <li>
            les messages obscènes, pornographiques ou relevant du harcèlement
          </li>
          <li>
            les messages mentionnant des coordonnées précises comme un numéro de
            téléphone, une adresse postale et dont l'origine et l'exactitude
            sont invérifiables par les modérateurs ou pourraient engendrer un
            préjudice à des personnes
          </li>
          <li>les messages publiés en plusieurs exemplaires</li>
          <li>les messages hors sujet ou incitant à la polémique</li>
          <li>
            les messages en langage abrégé ou dont l'orthographe est trop
            approximative
          </li>
          <li>les messages rédigés entièrement en majuscules</li>
        </ul>
        <p>
          En cas de non-respect de ces règles et consignes, l'équipe d'inSitu
          appliquera certaines sanctions aux Utilisateurs concernés. La sanction
          la plus élevée est le bannissement et consiste à bloquer entièrement
          l'accès à la Plateforme à un Utilisateur.
        </p>
        <p><br /></p>
        <h2>Article 8. Responsabilités</h2>
        <p><br /></p>
        <h3>
          8.1. A l'égard des informations et/ou contenus publiés au sein de la
          Plateforme par les Utilisateurs
        </h3>
        <p>
          Les contenus pédagogiques et les contenus produits à l’occasion des
          formations en situation de travail, les informations et contenus
          (messages, commentaires) publiés par les Utilisateurs au sein de la
          Plateforme ne sont pas contrôlés a priori par inSitu avant leur
          publication au sein de la Plateforme. En revanche, ils seront
          susceptibles d'être contrôlés a posteriori par inSitu.
        </p>
        <p><br /></p>
        <p>
          En notre qualité d'hébergeur de ces informations et/ou contenus (hors
          cours) mis en ligne par l'Utilisateur par l'intermédiaire de notre
          Plateforme, nous sommes soumis au régime de responsabilité atténuée
          prévu aux articles 6.I.2 et suivants de la loi nº2004-575 du 21 juin
          2004 pour la Confiance dans l'Économie Numérique. Nous pourrons, dans
          ce cadre, supprimer les Informations et/ou contenus manifestement
          illicites qui nous seront notifiés.
        </p>
        <p><br /></p>
        <h3>8.2. En cas d'inexécution de nos obligations</h3>
        <p>
          inSitu ne sera responsable que des dommages directs subis par les
          Utilisateurs, dont il sera établi qu'ils résultent de l'inexécution de
          nos obligations.
        </p>
        <p>
          En revanche, nous ne saurions en aucun cas être tenus responsables :
        </p>
        <ul style="list-style-type: disc; margin-left: 1rem">
          <li>
            Des dommages qui résulteraient du fait de l'Utilisateur, de
            difficultés inhérentes au fonctionnement du réseau Internet et plus
            généralement des réseaux de télécommunication, quelle que soit leur
            nature, du fait d'un tiers ou d'un cas de force majeure ;
          </li>
          <li>
            Des dommages indirects résultant de l'utilisation de la Plateforme,
            ceux-ci étant définis de façon non limitative comme les pertes
            d'exploitation (chiffre d'affaires, revenus ou bénéfices), les
            pertes d'opportunités, les préjudices d'image ou de réputation,
            préjudice commercial ou économique ;
          </li>
          <li>
            De toute perte de données subie par l'utilisateur, même si elle est
            du fait d’inSitu.
          </li>
        </ul>
        <p><br /></p>
        <h2>
          Article 9. Interruption du service pour maintenance ou amélioration
        </h2>
        <p><br /></p>
        <p>
          Nous nous engageons à mettre tout en œuvre pour assurer le bon
          fonctionnement de la Plateforme et son accessibilité mais nous ne
          sommes tenus qu'à une obligation de moyens concernant la continuité de
          l'accès au Service.&nbsp;
        </p>
        <p>
          Nous ne pouvons garantir la pérennité ou les performances de la
          Plateforme.
        </p>
        <p>
          En cas de mise à jour de la Plateforme pour une évolution technique,
          notre Plateforme sera inaccessible temporairement.
        </p>
        <p>
          L'accès au Service pourra être interrompu pour des raisons notamment
          de maintenance, de mise à jour et en cas d'urgence.
        </p>
        <p>
          Plus généralement, l'interruption du Service quelle que soit la cause,
          la durée ou la fréquence, n'engagera pas notre responsabilité et ne
          donnera droit à aucune indemnité à l'Utilisateur.
        </p>
        <p>
          En conséquence, nous ne pouvons être tenus pour responsables de la
          perte d'argent, ou de réputation, ni des dommages spéciaux, indirects
          ou induits résultant de l'interruption du Service. De même, nous ne
          pourrons être tenus pour responsables d’éventuelles dégradations de
          matériel, logiciel ou données (exemple : contamination virale) subies
          par l'Utilisateur du fait de son utilisation du Service.
        </p>
        <p>
          Pour éviter autant que possible des désagréments, vous devez vous
          assurer de la réalisation régulière de sauvegardes concernant vos
          informations, contenus et logiciels.
        </p>
        <p>
          Vous reconnaissez utiliser le Service, mais un "bogue" de notre
          Plateforme peut vous faire perdre certaines de vos données, les
          altérer ou les exposer. Nous nous engageons cependant à tout mettre en
          œuvre pour les restaurer dans la mesure du possible et à vous garantir
          un accès à la Plateforme le plus rapidement possible.
        </p>
        <p><br /></p>
        <h2>Article 10. Inscription et droits sur le compte Utilisateur</h2>
        <p><br /></p>
        <h3>10.1. Par un Utilisateur</h3>
        <p>
          Tous les Utilisateurs de notre Plateforme disposent d'un droit
          d'accès, de modification ou de suppression de leur compte.
        </p>
        <p>
          Si vous avez complété un livret numérique ou validé des compétences
          nous ne pourrons pas faire droit à votre demande de suppression de
          compte. En raison de nos obligations réglementaires liées à la nature
          de notre activité, nous devons conserver votre dossier apprenant
          comprenant votre fiche identité, et l'ensemble de votre parcours suivi
          avec inSitu. Vos données seront archivées tout en veillant à une
          exigence maximale de sécurité en n'étant accessibles qu'à un nombre
          limité de personnes au sein de nos services. Nous pourrons, si vous le
          souhaitez, vous adresser votre dossier apprenant.
        </p>
        <p>
          Si vous vous êtes engagés avec nous avec un Abonnement mais que vous
          avez décidé d'arrêter votre Parcours, nous traiterons votre demande
          selon que vous avez décidé d'arrêter définitivement votre Abonnement
          ou de faire une suspension. Dans le premier cas, nous supprimerons
          toutes vos données dans un délai de trente (30) jours, mais dans le
          second cas, nous ne pourrons pas faire droit à votre demande car nous
          devons conserver votre dossier lorsque vous serez diplômé pour des
          contraintes réglementaires liées à la nature de notre activité.
        </p>
        <p>
          Les Utilisateurs peuvent à tout moment modifier et obtenir un droit
          d'accès à leur compte personnel en nous contactant par courriel à
          l'adresse hello@insituapp.io. Si vous souhaitez vous désinscrire de
          notre Plateforme, veuillez nous contacter par courriel à l'adresse
          précitée. Votre demande de suppression de compte entraînera la
          suppression de vos données personnelles de nos bases de données. La
          désinscription entraînera la résiliation du Contrat. Cette résiliation
          prendra effet dans un délai de trente (30) jours ouvrés à compter de
          la réception de la demande de désinscription par inSitu.
        </p>
        <p><br /></p>
        <h3>10.2. Par inSitu</h3>
        <p>
          Si une demande d'inscription à nos services est effectuée par un
          mineur sans le consentement préalable de son responsable légal, nous
          pourrons bloquer l'accès au Compte jusqu'à ce que le consentement soit
          obtenu.
        </p>
        <p>
          Lorsque nous remarquons qu'un Utilisateur ne respecte pas les
          présentes CGU, nous prendrons contact avec lui pour l'avertir qu'une
          sanction à son égard peut être prise, notamment la suspension de son
          compte. La Charte de bonne conduite énoncée dans ces CGU doit être
          respectée par chacun pour le bon fonctionnement de la Plateforme et la
          bonne entente entre les personnes.
        </p>
        <p>
          Si aucune solution ne parvient à être trouvée et que les règles de
          bonne conduite ne sont pas respectées, nous serons dans l'obligation
          de désactiver votre compte pour une durée temporaire ou définitive et
          nous prendrons contact avec vous au préalable par courriel.
        </p>
        <p><br /></p>
        <h2>Article 11. Force majeure</h2>
        <p><br /></p>
        <p>
          Aucune des parties ne sera responsable vis-à-vis de l'autre partie
          d'un retard d'exécution ou d'une inexécution en raison de survenance
          d'un événement en dehors du contrôle des parties qui ne pouvait être
          raisonnablement prévu lors de l'acceptation des CGU et dont les effets
          ne peuvent pas être évités par des mesures appropriées.
        </p>
        <p>
          Le cas de force majeure suspend les obligations de la partie concernée
          pendant le temps où jouera la force majeure si cet événement est
          temporaire. Néanmoins, les parties s'efforceront d'en minimiser dans
          toute la mesure du possible les conséquences. A défaut, si
          l'empêchement est définitif, les parties seront libérées de leurs
          obligations dans les conditions prévues aux articles 1351 et 1351-1 du
          Code civil.
        </p>
        <p><br /></p>
        <h2>Article 12. Résolution des différends</h2>
        <p><br /></p>
        <p>
          Les CGU sont soumises à la loi française. Toutes difficultés relatives
          à la validité, l'application ou à l'interprétation des CGU seront
          soumises, à défaut d'accord amiable, au Tribunal de Grande Instance de
          Marseille, auquel les Parties attribuent compétence territoriale, quel
          que soit le lieu d'exécution ou le domicile du défendeur. Cette
          attribution de compétence s'applique également en cas de procédure en
          référé, de pluralité de défendeurs ou d'appel en garantie.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import AppAccountMenu from '@/components/AppAccountMenu.vue';
import AppSidebar from '@/components/AppSidebar.vue';

export default ({
  components: {
    AppAccountMenu,
    AppSidebar,
  },
  data() {
    return {
      isLoading: false,
      isFullPage: false,
      isOpen: 0,
      crumbs: [{
        text: 'Conditions d\'utilisation',
        href: '/conditions-utilisation',
        class: 'is-active',
      },
      ],
    };
  },
  computed: {
    ...mapState('auth', ['currentUser']),
  },
});
</script>

<style scoped>
.content {
  margin-bottom: 2rem;
}
</style>
