<template>
  <div></div>
</template>

<script>
import Cookies from 'js-cookie';

export default ({
  components: {
  },
  data() {
    return {
      token: '',
    };
  },
  created() {
    this.setToken();
  },
  methods: {
    setToken() {
      this.token = this.$route.params.token;
      Cookies.set('token', this.token);
      this.$router.push({ path: '/' });
    },
  },
});
</script>
