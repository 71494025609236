<template>
  <div class="card card-experience-detail">
    <div class="card-content">
      <div class="media">
        <div class="media-content">
          <p class="title is-3 pr-5" style="text-transform: capitalize">
            {{ chosenExperience.experience_title }}
          </p>
          <p class="subtitle is-6">{{ chosenExperience.experience_type }}</p>
        </div>
      </div>
      <div class="container-experience">
        <div v-if="chosenExperience.experience_detail.activities">
          <div
            v-for="(activity, index) in chosenExperience.experience_detail
              .activities"
            :key="index"
            class="mb-5 pb-5"
          >
            <div class="title is-4 has-text-primary mb-5 mt-5 pb-2">
              {{ activity.title }}
              <hr class="has-background-primary" />
            </div>
            <div
              v-for="step in activity.steps"
              :key="step.title"
              class="activity-step"
            >
              <div
                class="title is-5 has-text-primary mb-2"
                v-if="step.entry && step.title"
              >
                {{ step.title }}
              </div>
              <div v-html="step.entry"></div>
            </div>
          </div>
        </div>
        <div v-else>
          <div v-html="chosenExperience.experience_content"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    chosenExperience: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
.media {
  box-shadow: 0px 8px 9px 0 #00000014;
  padding: 0 2rem 2.5rem 4rem;
}
.card {
  margin-bottom: 1rem;
  border-radius: 0.25rem;
  box-shadow: 0px 2px 3px rgba(10, 10, 10, 0.1);
  transition: box-shadow 0.3s ease-out;
}

.card:hover {
  box-shadow: 0px 4px 6px rgba(10, 10, 10, 0.2);
}

.card-image img {
  border-radius: 0.25rem 0.25rem 0 0;
}
.container-experience {
  height: 78vh;
  max-width: 1200px;
  margin: 0 auto;
  overflow: auto;
  padding: 2rem 5rem;
  .activity-step {
    margin-bottom: 3rem;
    padding-left: 2rem;
  }
  figure {
    display: inline-block;
    margin-right: 1rem;
  }
}
.card-experience-detail {
  padding: 1.1rem 0;
  border-radius: 0px;
}
hr {
  margin-top: 12px;
}
@media (max-width: 1400px) {
  .container-experience {
    padding: 2rem;
    .activity-step {
      padding-left: 0rem;
    }
  }
}
@media (max-width: 990px) {
  .container-experience {
    padding: 2rem;
    .activity-step {
      padding-left: 0rem;
    }
  }
  .media {
    padding: 0 2rem 2.5rem 2rem;
    .title.is-3 {
      font-size: 1.5rem;
    }
  }
}
</style>
