<template>
  <div class="modalbox modalStep modalRead">
    <div class="is-flex bigmodal">
      <!-- <div @click="goback">
        <img :src="require('@/assets/img/arrows/previous.svg')" />
      </div> -->
      <div class="mr-auto btn-previous" v-if="!$route.params.from">
        <img
          :src="require('@/assets/img/arrows/previous.svg')"
          @click="goback"
        />
      </div>
      <div v-else-if="$route.params.from == 'WaypointEdit'">
        <router-link
          class="mr-auto btn-previous"
          :to="{
            name: 'WaypointEdit',
            params: {
              idTraining: $route.params.idTraining,
              idStudentTraining: $route.params.idStudentTraining,
              idStudent: $route.params.idStudent,
              from: $route.name,
            },
          }"
        >
          <img :src="require('@/assets/img/arrows/previous.svg')" />
        </router-link>
      </div>
      <div v-else-if="$route.params.from == 'WaypointRead'">
        <router-link
          class="mr-auto btn-previous"
          :to="{
            name: 'WaypointRead',
            params: {
              idTraining: $route.params.idTraining,
              idStudentTraining: $route.params.idStudentTraining,
              idStudent: $route.params.idStudent,
              from: $route.name,
            },
          }"
        >
          <img :src="require('@/assets/img/arrows/previous.svg')" />
        </router-link>
      </div>
      <div v-else>
        <router-link
          class="mr-auto btn-previous"
          :to="{ name: $route.params.from }"
        >
          <img :src="require('@/assets/img/arrows/previous.svg')" />
        </router-link>
      </div>
    </div>
    <section class="w-100">
      <b-carousel
        v-model="carousel"
        :animated="animated"
        :has-drag="drag"
        :autoplay="autoPlay"
        :pause-hover="pauseHover"
        :pause-info="pauseInfo"
        :pause-info-type="pauseType"
        :interval="interval"
        :repeat="repeat"
        :arrow-hover="false"
        :indicator="false"
      >
        <b-carousel-item>
          <section class="hero is-medium is-bold">
            <div class="">
              <div class="bloc-title">
                <div class="title-paragraph">
                  <div class="stepsCount">1/2</div>
                  {{ studentrevision.title }}
                </div>
              </div>
              <div class="container-editor">
                <div class="dates mr-3 mb-4 has-text-centered">
                  <b-field
                    label="Période durant laquelle a eu lieu cette activité"
                    >{{ studentrevision.start_date | day }} au
                    {{ studentrevision.end_date | day }}</b-field
                  >
                </div>
              </div>
              <div class="w-100">
                <div
                  class="card_grey card_edit ck"
                  v-html="studentrevision.entry"
                ></div>
              </div>
            </div>
          </section>
        </b-carousel-item>
        <b-carousel-item v-if="this.$route.params.idRevision !== 'new'">
          <section class="hero is-medium is-bold">
            <div class="w-100 bloc-title">
              <div class="title-paragraph">
                <div class="stepsCount">2/2</div>
                Avez-vous acquis de nouvelles compétences ?
              </div>
            </div>
            <div class="w-100 container-editor">
              <div class="subtitle-paragraph mb-4">
                Cette évaluation est facultative mais vous pouvez indiquer si
                vous avez gagné en autonomie
              </div>
              <SkillsLevelsBlocks type="progression" />
            </div></section
        ></b-carousel-item>
      </b-carousel>
      <div v-if="currentUser.role === 'apprenant'">
        <div
          class="is-flex is-flex-wrap-wrap activityBtns align-items-center"
          style="z-index: 2000"
        >
          <div class="w-100 is-flex">
            <!-- <b-button class="mx-auto btn-action" @click="goNext" rounded
              >voir l'activité</b-button
            > -->
            <b-button @click="modifyStatus" class="mx-auto btn-action" rounded
              >modifier</b-button
            >
          </div>
          <div class="help-btn">
            <AppHelpMessage
              title="Rédigez et enregistrez votre activité"
              message="Vous pouvez modifier cette activité pour corriger des fautes
                 ou reprendre le contenu.
               <b>Toutefois si vous refaites cette situation, il vaut mieux réutiliser le bouton
                  'Vous avez progressé' sur la vue précédente pour ajouter de nouvelles notes
                   et votre nouvelle progression.</b>"
              textBtn="Besoin d'aide ?"
            />
          </div>
        </div>
      </div>
    </section>

    <b-loading :is-full-page="isFullPage" v-model="isLoading"> </b-loading>

    <div class="container contentSequence pb-5 mb-5"></div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import moment from 'moment';
import localization from 'moment/locale/fr';
import AppHelpMessage from '@/components/AppHelpMessage.vue';
import SkillsLevelsBlocks from '@/components/Skills/SkillsLevelsBlocks.vue';

moment.updateLocale('fr', localization);

export default {
  components: {
    AppHelpMessage,
    SkillsLevelsBlocks,
  },
  filters: {
    day(date) {
      return moment(date).format('D/MM/YYYY');
    },
    hour(date) {
      return moment(date).format('h:mm');
    },
  },
  data() {
    return {
      carousel: 0,
      animated: 'slide',
      drag: false,
      autoPlay: false,
      pauseHover: false,
      pauseInfo: false,
      repeat: false,
      pauseType: 'is-primary',
      interval: 3000,
      isLoading: true,
      isFullPage: false,
      studentrevision: [],
    };
  },
  computed: {
    ...mapGetters(['getUserById']),
    ...mapState('auth', ['currentUser']),
  },
  methods: {
    startLoading() {
      this.isLoading = true;
    },
    endLoading() {
      this.isLoading = false;
    },
    initRevision() {
      this.$http
        .get(`/agenda/student_activity_revision/${this.$route.params.idRevision}/?format=json`)
        .then((response) => {
          this.studentrevision = response.data;
          // this.endLoading();
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
        });
    },
    modifyStatus() {
      this.$buefy.dialog.confirm({
        title: 'Souhaitez-vous modifier le contenu de cette activité ?',
        message: 'Vous pouvez modifier cette activité pour corriger des fautes ou reprendre le contenu. <b>Toutefois si vous refaites cette situation, il vaut mieux réutiliser le bouton "Vous avez progressé" sur la vue précédente pour ajouter de nouvelles notes et votre nouvelle progression.</b>',
        confirmText: 'Modifier',
        cancelText: 'Annuler',
        type: 'is-info',

        onConfirm: () => {
          this.$router.push({
            name: 'StudentProgressionEdit',
            params: {
              idTraining: this.$route.params.idTraining,
              idStudent: this.$route.params.idStudent,
              idSequence: this.$route.params.idSequence,
              idActivity: 's',
              idRevision: this.$route.params.idRevision,
            },
          });
        },
      });
    },
    goNext() {
      if (this.$route.params.idActivity === 's') {
        this.$router.push({
          name: 'StudentSequenceDetail',
          params: {
            idSequence: this.$route.params.idSequence,
            idTraining: this.$route.params.idTraining,
            idStudent: this.$route.params.idStudent,
            idStudentTraining: this.$route.params.idStudentTraining,
          },
        });
      } else {
        this.$router.push({
          name: 'StudentActivity',
          params: {
            idTraining: this.$route.params.idTraining,
            idStudentTraining: this.$route.params.idStudentTraining,
            idSequence: this.$route.params.idSequence,
            idActivity: this.$route.params.idActivity,
          },
        });
      }
    },
    goback() {
      if (this.$route.params.from) {
        this.$router.go(-1);
      } else {
        this.$router.push({
          name: 'StudentTraining',
          params: {
            idStudent: this.studentrevision.profile,
            idTraining: this.$route.params.idTraining,
            idStudentTraining: this.$route.params.idStudentTraining,
            from: this.$route.name,
            tab: 'parcours',
          },
        });
      }
    },
    logActivity() {
      const typeAction = 'lecture progression';
      this.$http
        .post('agenda/logs/', {
          action: typeAction,
          student_training: this.$route.params.idStudentTraining,
          training: this.$route.params.idTraining,
          student_activity: null,
          student_sequence: this.$route.params.idSequence,
        })
        .then(() => {
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
        });
    },
  },
  created() {
    this.endLoading();
    this.initRevision();
    if (this.currentUser.role === 'apprenant' || this.currentUser.role === 'tuteur') { this.logActivity(); }
  },
  mounted() {
  },
  destroyed() {
  },
};
</script>

<style lang="scss">
</style>
