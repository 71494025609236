<template>
  <div class="is-flex">
    <div ref="sidebarWidth"><AppSidebar /></div>
    <div class="main-content">
      <div class="lineCrumbAndAccount">
        <AppAccountMenu v-bind:appAccount="crumbs" />
      </div>
      <div class="header-page is-flex flex-column">
        <div
          class="justify-content-between columns lineHeaderTitle align-items-center"
          v-if="training"
        >
          <div class="column">
            <div class="title-page long-title">
              {{ training.name }}
            </div>

            <div
              class="subtitle-infos"
              v-if="
                currentUser.role !== 'apprenant' &&
                currentUser.role !== 'tuteur'
              "
            >
              <div>
                <span class="created">Formation créée le : </span>
                {{ training.date_created | moment }}
              </div>
              <div>
                <span class="created">Modifiée le :</span>
                {{ training.date_updated | moment }}
              </div>
              <b-tag class="ml-1 is-outlined is-rounded" outlined>{{
                training.framework_type.name
              }}</b-tag>
            </div>
          </div>
          <div class="column is-narrow actions">
            <router-link
              :to="
                '/editer-une-formation/' +
                $route.params.idTraining +
                '/' +
                $route.params.tabDashboard
              "
              v-if="
                currentUser.role === 'coordinateur' ||
                (training.teachers_can_edit &&
                  currentUser.role === 'formateur' &&
                  user.edit_rights < 3)
              "
            >
              <b-button type="is-rounded" class="mr-2"
                ><span>modifier la formation</span></b-button
              ></router-link
            ><b-button
              type="is-rounded"
              @click="duplicateTraining"
              v-if="
                currentUser.role === 'coordinateur' ||
                (training.teachers_can_edit &&
                  currentUser.role === 'formateur' &&
                  user.edit_rights < 3)
              "
              >dupliquer</b-button
            >
          </div>
        </div>
        <div v-if="training">
          <b-message type="is-danger" v-if="training.finished" class="mb-0"
            >Attention, cette période de formation est indiquée comme
            terminée</b-message
          >
        </div>
      </div>
      <div class="content-page pt-0" ref="contentWidth">
        <div class="w-100">
          <nav class="tabs" v-if="tabsloaded">
            <ul>
              <li
                v-for="(tab, index) of tabs"
                :key="index"
                :id="tab.id"
                :class="activeTab === tab.slug ? 'is-active' : ''"
              >
                <router-link
                  v-if="tab.visible"
                  :to="
                    '/formations/' +
                    $route.params.idTraining +
                    '/suivi/stepToValidate'
                  "
                  @click.native="gotoTab(tab.slug, 'menu')"
                  >{{ tab.name }}</router-link
                >
              </li>
            </ul>
          </nav>
          <div v-if="activeTab == 'validationSteps'">
            <StepsBooklet
              v-if="tableOk"
              :objectId="Number($route.params.idTraining)"
              :contentWidth.sync="widthContent"
              :key="componentKey"
              @tabChange="gotoSelectedTab($event)"
            />
          </div>
          <div v-else-if="activeTab == 'session'">
            <TrainingSessionInfos
              v-if="training"
              :trainingInfos="training"
              :key="sessionComponent"
              @tabChange="forceRerenderComponent()"
            />
          </div>
          <div v-else-if="activeTab == 'realtime'">
            <div>
              <RealtimeActivity
                v-if="training"
                :contentWidth.sync="widthContent"
                :key="componentKey"
                :trainingInfos="training"
                @tabChange="gotoSelectedTab($event)"
              />
            </div>
          </div>
          <div v-else-if="activeTab == 'waypointList'">
            <StepsWaypoints :objectId="Number($route.params.idTraining)" />
          </div>
          <div v-else-if="activeTab == 'stepToValidate'" ref="contentWidth">
            <StepToValidate
              :objectId="Number($route.params.idTraining)"
              @tabChange="gotoSelectedTab($event)"
              :key="stepComponent"
            />
          </div>
          <div
            v-else-if="activeTab == 'skills' || activeTab == 'default'"
            class="pt-5"
          >
            <SkillsTraining
              :typeoftraining="'training'"
              :objectId="Number($route.params.idTraining)"
            />
          </div>
          <div v-else-if="activeTab == 'users'">
            <UsersTrainingList
              :objectId="Number($route.params.idTraining)"
              :key="componentKey"
              @tabChange="gotoSelectedTab($event)"
            />
          </div>
          <div v-else-if="activeTab == 'parcours'">
            <ParcoursRead :training="Number(this.$route.params.idTraining)" />
          </div>
          <div v-else-if="activeTab == 'activites-pratiques'">
            <PracticalWorks />
          </div>
          <div v-else-if="activeTab == 'bigSteps'">
            <StudentsBigSteps :objectId="Number($route.params.idTraining)" />
          </div>
          <div v-else-if="activeTab == 'stats'">
            <StudentsTrainings />
          </div>
          <div v-else-if="activeTab == 'resources'">
            <section id="stepsix" class="pt-5">
              <div class="mb-5">
                <h1 class="title-paragraph">
                  Ressources associées à cette formation
                </h1>
              </div>
              <div
                v-if="
                  currentUser.role === 'coordinateur' ||
                  (currentUser.role === 'formateur' && user.edit_rights !== 4)
                "
              >
                <div class="helpUser mt-5">
                  <div class="title">Ajoutez des ressources</div>
                  <div>
                    Ici vous pouvez ajouter des documents à cette formation.
                    Dans l'édition du parcours vous pouvez également ajouter des
                    ressources à certaines expériences ou activités.
                  </div>
                </div>
                <div
                  class="card_simple is-primary w-100 has-text-centered"
                  @click="isOpen = !isOpen"
                >
                  <div>
                    <b-icon
                      icon="resource"
                      type="is-primary"
                      class="mr-3"
                    ></b-icon>
                    <b style="cursor: pointer">Ajoutez une ressource</b>
                  </div>
                </div>
                <b-collapse v-model="isOpen">
                  <ResourceCreation
                    :sequence="null"
                    :typeoftraining="'training'"
                    :idTraining="training.id"
                    :idResource="null"
                    :action="'post'"
                    :isOpen.sync="isOpen"
                    @messageFromChild="initResources"
                    @messageFromResource="forceRerender"
                    :key="resourceKey"
                  ></ResourceCreation>
                </b-collapse>
              </div>
              <div v-if="resources">
                <div v-if="resources.length">
                  <div v-for="resource in resources" :key="resource.id">
                    <ResourceCard
                      :resource="resource"
                      :action="'edit'"
                      @messageFromChild="initResources"
                    ></ResourceCard>
                  </div>
                </div>
                <b-message v-else type="is-info"
                  >Aucun document n'est associé à cette formation</b-message
                >
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import localization from 'moment/locale/fr';
import _ from 'lodash';
import AppSidebar from '@/components/AppSidebar.vue';
import AppAccountMenu from '@/components/AppAccountMenu.vue';
import SkillsTraining from '@/components/Skills/SkillsTraining.vue';
import StepsBooklet from '@/components/DashboardManagement/StepsBooklet.vue';
import StudentsBigSteps from '@/components/DashboardManagement/StudentsBigSteps.vue';
import StepToValidate from '@/components/DashboardManagement/StepToValidate.vue';
import { mapState } from 'vuex';
import ResourceCard from '@/components/Resources/ResourceCard.vue';
import ResourceCreation from '@/components/Resources/ResourceCreation.vue';
import RealtimeActivity from '@/components/DashboardManagement/RealtimeActivity.vue';
import ParcoursRead from '@/components/Trainings/ParcoursRead.vue';
import TrainingSessionInfos from '@/components/Trainings/TrainingSessionInfos.vue';
import StudentsTrainings from '@/components/Trainings/StudentsTrainings.vue';
import StepsWaypoints from '@/components/DashboardManagement/StepsWaypoints.vue';
import UsersTrainingList from '@/components/DashboardManagement/UsersTrainingList.vue';
import PracticalWorks from '@/components/Trainings/PracticalWorks.vue';

moment.updateLocale('fr', localization);

export default {
  components: {
    AppSidebar,
    AppAccountMenu,
    SkillsTraining,
    StepsBooklet,
    StudentsBigSteps,
    StepToValidate,
    ResourceCard,
    ResourceCreation,
    RealtimeActivity,
    UsersTrainingList,
    ParcoursRead,
    TrainingSessionInfos,
    StudentsTrainings,
    StepsWaypoints,
    PracticalWorks,
  },
  filters: {
    moment(date) {
      return moment(date).format('D MMMM YYYY');
    },
    day(date) {
      return moment(date).format('D/MM/YY');
    },
    hour(date) {
      return moment(date).format('HH:mm');
    },
  },
  computed: {
    ...mapState('auth', ['currentUser']),
    ...mapState(['user']),
  },
  data() {
    return {
      resources: null,
      training: null,
      trainingId: 72,
      crumbs: [
        {
          text: 'Formations',
          href: '/maquettes',
          class: 'is-active',
        },
      ],
      activeTab: 'all',
      customKey: null,
      sortingPriority: [],
      tabs: [
        {
          name: 'Tableau de bord',
          slug: 'validationSteps',
          visible: null,
        },
        {
          name: 'Activité en cours',
          slug: 'realtime',
          visible: null,
        },
        {
          name: 'Stats apprenants',
          slug: 'stats',
          visible: null,
        },
        {
          name: 'Etape en cours',
          slug: 'stepToValidate',
          visible: null,
        },
        {
          name: 'Compétences',
          slug: 'skills',
          visible: true,
        },
        {
          name: 'Points d\'étapes',
          slug: 'waypointList',
          visible: null,
        },
        {
          name: 'Utilisateurs',
          slug: 'users',
          visible: true,
        },
        {
          name: 'Vue globale',
          slug: 'bigSteps',
          visible: null,
        },
        {
          name: 'Parcours',
          slug: 'parcours',
          visible: true,
        },
        {
          name: 'Activités pratiques',
          slug: 'activites-pratiques',
          visible: null,
        },
        {
          name: 'Infos',
          slug: 'session',
          visible: null,
        },
        {
          name: 'Ressources',
          slug: 'resources',
          visible: true,
        },
      ],
      training_options: {
        stepsBooklet: false,
        realtime_activity: true,
        bigSteps: false,
        waypoint_on_demand: true,
        waypointlist: true,
        infos: true,
        allStats: true,
        users: true,
      },
      training_options2: [
        { code: 'realtime' },
        { code: 'waypoint_on_demand' },
        { code: 'waypointList' },
        { code: 'session' },
        { code: 'parcours' },
        { code: 'stats' },
      ],
      widthContent: null,
      lastwidth: null,
      contentWidthDiv: null,
      screenWidth: null,
      window: {
        width: 0,
        height: 0,
      },
      tableOk: false,
      componentKey: 0,
      isOpen: false,
      resourceKey: 0,
      sessionComponent: 0,
      stepComponent: 0,
      tabsloaded: false,
    };
  },
  methods: {
    initResources() {
      this.startLoading();
      this.$http.get(`/get_resources/?training=${this.$route.params.idTraining}&format=json`).then((response) => {
        this.resources = response.data.resources;
        this.endLoading();
      }).catch((error) => {
        window.console.log(error);
      });
    },
    forceRerender() {
      // Every time that forceRerender is called, our prop componentKey will change.
      this.resourceKey += 1;
    },
    startLoading() {
      this.isLoading = true;
    },
    endLoading() {
      this.isLoading = false;
    },
    initTraining() {
      this.startLoading();
      this.$http
        .get(`/trainings/${this.$route.params.idTraining}/?query={*,-training_periods}&format=json`)
        .then((response) => {
          this.training = response.data;
          this.verifTabOptions();
          this.endLoading();
          this.initResources();
        }).catch((error) => {
          this.$buefy.toast.open({ message: `${error.response.data.message}`, type: 'is-danger' });
          this.$router.push({
            name: 'Dashboard',
          });
        });
    },
    handleHistoryChange() {
      this.gotoTab(this.$route.params.tabDashboard);
    },
    verifTabOptions() {
      if (this.training.tab_options.length) {
        this.training_options2 = this.training.tab_options;
        this.verifyOptionsTwo();
      } else {
        this.verifyOptionsTwo();
      }
    },
    gotoSelectedTab(tab) {
      this.activeTab = tab;
      if (this.activeTab === 'stepToValidate' && this.$route.params.idStep === 'undefined') {
        this.$route.params.idStep = 'current';
      }
      this.gotoTab(tab);
    },
    gotoTab(slug, menu) {
      if (slug === 'stepToValidate' && menu === 'menu') {
        this.$route.params.idStep = 'current';
      }
      this.activeTab = slug;
      if (this.$route.path !== `/formations/${this.$route.params.idTraining}/suivi/${slug}`) {
        if (this.$route.params.idStep) {
          if (this.$route.params.idStep === 'current') {
            this.$router.push({
              name: 'DashboardManagement',
              params: {
                tabDashboard: slug,
                idTraining: this.$route.params.idTraining,
                idStep: 'current',
              },
            }).catch((error) => {
              window.console.log(error);
            });
          }
        } else {
          this.$router.push({
            name: 'DashboardManagement',
            params: {
              tabDashboard: slug,
              idTraining: this.$route.params.idTraining,
              idStep: 'current',
            },
          }).catch((error) => {
            window.console.log(error);
          });
        }
      } else {
        this.forceRerenderStep();
      }
    },
    order(array) {
      return _.orderBy(array, 'position');
    },
    handleResize() {
      // try to get width size to put max-width on 'content-page'
      this.widthContent = this.$refs.contentWidth.clientWidth;
      this.contentWidthDiv = this.$refs.contentWidth.clientWidth;
      this.screenWidth = window.innerWidth;
      this.tableOk = true;
      if (this.lastwidth && (this.screenWidth > this.lastwidth)) {
        this.widthContent += (this.screenWidth - this.lastwidth);
        this.lastwidth = this.screenWidth;
      } else if (!this.lastwidth) {
        this.widthContent = this.contentWidthDiv;
        this.lastwidth = window.innerWidth;
      } else if (this.lastwidth && (this.screenWidth < this.lastwidth)) {
        this.widthContent = this.contentWidthDiv;
        this.lastwidth = window.innerWidth;
      }
    },
    verifyOptionsTwo() {
      if (this.training_options2.find((option) => option.code === 'validationSteps')) {
        const tabindex = _.findKey(this.tabs, { slug: 'validationSteps' });
        this.tabs[tabindex].visible = true;
      }
      if (this.training_options2.find((option) => option.code === 'stepToValidate')) {
        const tabindex = _.findKey(this.tabs, { slug: 'stepToValidate' });
        this.tabs[tabindex].visible = true;
      }
      if (this.training_options2.find((option) => option.code === 'bigSteps')) {
        const tabindex = _.findKey(this.tabs, { slug: 'bigSteps' });
        this.tabs[tabindex].visible = true;
      }
      if (this.training_options2.find((option) => option.code === 'realtime')) {
        const tabindex = _.findKey(this.tabs, { slug: 'realtime' });
        this.tabs[tabindex].visible = true;
      }
      if (this.training_options2.find((option) => option.code === 'waypointList')) {
        const tabindex = _.findKey(this.tabs, { slug: 'waypointList' });
        this.tabs[tabindex].visible = true;
      }
      if (this.training_options2.find((option) => option.code === 'stats')) {
        const tabindex = _.findKey(this.tabs, { slug: 'stats' });
        this.tabs[tabindex].visible = true;
      }
      if (this.training_options2.find((option) => option.code === 'users')) {
        const tabindex = _.findKey(this.tabs, { slug: 'users' });
        this.tabs[tabindex].visible = true;
      }
      if (this.training_options2.find((option) => option.code === 'session')) {
        const tabindex = _.findKey(this.tabs, { slug: 'session' });
        this.tabs[tabindex].visible = true;
      }
      if (this.training_options2.find((option) => option.code === 'no_practicalWork')) {
        const tabindex = _.findKey(this.tabs, { slug: 'activites-pratiques' });
        this.tabs[tabindex].visible = false;
      } else {
        const tabindex = _.findKey(this.tabs, { slug: 'activites-pratiques' });
        this.tabs[tabindex].visible = true;
      }
      if (this.$route.params.tabDashboard === 'default') {
        if (this.training_options2.find((option) => option.code === 'validationSteps')) {
          this.gotoTab('validationSteps');
        } else if (!this.training_options2.find((option) => option.code === 'validationSteps')
          && this.training_options2.find((option) => option.code === 'realtime')) {
          this.gotoTab('realtime');
        } else if (!this.training_options2.find((option) => option.code === 'realtime')) {
          this.gotoTab('skills');
        }
      }
      this.verifActiveTab();
    },
    verifActiveTab() {
      const tabindex = _.findKey(this.tabs, { slug: this.$route.params.tabDashboard });
      if (tabindex) {
        if (this.tabs[tabindex].visible) {
          this.gotoTab(this.$route.params.tabDashboard);
        } else {
          this.gotoTab('skills');
        }
      } else {
        this.gotoTab('skills');
      }
      this.tabsloaded = true;
    },
    forceRerenderComponent() {
      this.sessionComponent += 1;
    },
    forceRerenderStep() {
      this.stepComponent += 1;
    },
    duplicateTraining() {
      this.$buefy.dialog.confirm({
        title: 'Souhaitez-vous dupliquer cette formation ?',
        message: 'Vous êtes sur le point de dupliquer toute la formation. Est-ce bien ce que vous souhaitez faire ?',
        confirmText: 'Dupliquer',
        cancelText: 'Annuler',
        type: 'is-info',
        hasIcon: false,
        onConfirm: () => {
          this.$http
            .get(`/duplicate_training/?training=${this.$route.params.idTraining}`)
            .then(() => {
              this.$buefy.toast.open({ message: 'Formation dupliquée', type: 'is-success' });
              this.$router.push({
                name: 'Trainings',
              });
            }).catch((error) => {
              this.$buefy.toast.open({ message: 'Mince, une erreur s\'est produite. Veuillez contacter le support', type: 'is-danger' });
              this.$router.push({
                name: 'Trainings',
              });
              window.console.log(error);
            });
        },
      });
    },
  },
  created() {
    if (this.activeTab === 'validationSteps') {
      window.addEventListener('resize', this.handleResize);
    }
  },
  mounted() {
    this.handleResize();
    this.initTraining();
    window.addEventListener('popstate', this.handleHistoryChange);
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
    window.removeEventListener('popstate', this.handleHistoryChange);
  },
};

</script>
<style scoped>
.title-page {
  font-size: 1.6rem;
}
.th-wrap,
.th-wrap .control,
.th-wrap .control input {
  width: 100%;
}
.th-wrap .control input {
  margin-bottom: 0.5rem;
  margin-top: -0.5rem;
}
.th-wrap .control:after {
  content: unset !important;
  display: none !important;
}
</style>
