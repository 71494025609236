var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._v(" activités "),(_vm.practicalWorks.length > 0)?_c('b-table',{ref:"table",attrs:{"data":_vm.practicalWorks,"paginated":"","per-page":"10","opened-detailed":_vm.defaultOpenedDetails,"detailed":"","detail-key":"id","show-detail-icon":_vm.showDetailIcon,"aria-next-label":"Next page","aria-previous-label":"Previous page","aria-page-label":"Page","aria-current-label":"Current page"},on:{"details-open":(row) => _vm.$buefy.toast.open(`Détail de ${row.title}`)},scopedSlots:_vm._u([{key:"detail",fn:function(props){return [_c('div',{staticClass:"mt-2"},[_c('div',{staticClass:"columns is-vcentered is-mobile mb-4"},[_c('div',{staticClass:"column"},[_c('h3',{staticClass:"subtitle is-5 has-text-weight-semibold mt-2"},[_vm._v(" "+_vm._s(props.row.title)+" ")])])])]),(props.row.sessions.length > 0)?_c('table',{staticClass:"table is-fullwidth",attrs:{"responsive":""}},[_c('thead',[_c('tr',[_c('th',{staticStyle:{"width":"30%"}},[_vm._v("Titre")]),_c('th',[_vm._v("Apprenants")]),_c('th',[_vm._v("Date")])])]),_c('tbody',_vm._l((props.row.sessions),function(session){return _c('tr',{key:session.id},[_c('td',[_c('b',[_vm._v(_vm._s(session.title))])]),_c('td',[_c('ul',_vm._l((session.students),function(student){return _c('li',{key:student.id},[_c('div',{staticClass:"is-inline-flex",staticStyle:{"align-items":"center"}},[_c('span',{class:student.evaluated
                          ? 'has-text-success'
                          : 'has-text-danger',staticStyle:{"font-size":"0.5em","margin-right":"0.5em"}},[_vm._v("⬤")]),_vm._v(" "+_vm._s(student.first_name)+" "),_c('span',{staticClass:"has-text-weight-bold"},[_vm._v(_vm._s(student.last_name))])])])}),0)]),_c('td',[(session.teachers.length > 0)?_c('div',[_c('ul',_vm._l((session.teachers),function(teacher){return _c('li',{key:teacher.id},[_vm._v(" "+_vm._s(teacher.first_name)+" "),_c('span',{staticClass:"has-text-weight-bold"},[_vm._v(_vm._s(teacher.last_name))])])}),0)]):_c('div',[_c('b-tag',{attrs:{"type":"is-info is-light"}},[_vm._v(" Aucun formateur ")])],1)]),_c('td',[_vm._v(_vm._s(_vm.formattedDatetime(session.date)))]),_c('td',[_c('a',{staticClass:"button is-outlined is-rounded has-text-grey is-small mr-4 mb-1",on:{"click":function($event){_vm.isEditMode = true;
                  _vm.isPracticalWorkSessionPlanningActive = true;
                  _vm.chosenPracticalWork = props.row;
                  _vm.chosenSessionDisplayed = session;}}},[_vm._v(" Détail ")]),_c('a',{staticClass:"button is-rounded has-text-white is-primary is-small mb-1",on:{"click":function($event){_vm.isPracticalWorkEvaluationActive = true;
                  _vm.chosenPracticalWork = props.row;
                  _vm.chosenSessionDisplayed = session;}}},[_vm._v(" Évaluations ")])])])}),0)]):_c('div',[_c('p',[_vm._v(" La liste des sessions apparaîtra ici lorsqu'elle seront (ou auront été) planifiées ")])])]}}],null,false,1342695590)},[_c('b-table-column',{attrs:{"label":"Titre","width":"500"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(props.row.title)+" ")]}}],null,false,1662046397)}),_c('b-table-column',{attrs:{"label":"Détail"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('div',{on:{"click":function($event){$event.preventDefault();_vm.displayPracticalWorkDetail(props.row);
          _vm.isPracticalWorkCreation = true;
          _vm.isEditMode = true;
          _vm.chosenPracticalWork = props.row;}}},[_c('b-icon',{attrs:{"icon":"see"}})],1)]}}],null,false,306352946)})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }