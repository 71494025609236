/* eslint-disable */
import Vue from 'vue';
import Vuex from 'vuex';
import auth from './modules/auth';
import Cookies from 'js-cookie';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
  },
  state: {
    trainingframeworks: [],
    trainingframework: [],
    frameworksequence: [],
    trainings: [],
    training: [],
    trainingsequence: [],
    sequencetypes: [],
    sequencetype: [],
    resources: [],
    dates: [],
    users: [],
    user: [],
    availableteachers: [],
    availablestudents: [],
    availabletutors: [],
    availablemanagers: [],
    studentactivity: [],
    studentactivities: [],
    studenttrainings: [],
    studenttraining: [],
    studentsequence: [],
    studenttrainingdetail: [],
    agendastudentsequence: [],
    isOpen: false,
    notifications: [],
    waypoint: [],
    trainingSteps: [],
    commentsEvaluations: [],
  },
  getters: {
    isOpen: (state) => (state.isOpen),
    getUserById: (state) => (id) => {
      return state.users.find(user => user.id === id) || null
    },
    getRoles: (state) => () => {
      return state.roles.find(role => role.id === id)
    },
    getTypeSequenceById: (state) => (id) => {
      return state.sequencetypes.find(sequencetype => sequencetype.id === id)
    },
    getTrainingById: (state) => (id) => {
      return state.trainings.find(training => training.id === id)
    },
  },
  mutations: {
    GET_TRAININGS: (state, trainings) => {
      state.trainings = trainings;
    },
    GET_TRAINING: (state, training) => {
      state.training = training;
    },
    GET_FORMACODES: (state, formacodes) => {
      state.formacodes = formacodes;
    },
    GET_AVAILABLETEACHERS: (state, availableteachers) => {
      state.availableteachers = availableteachers;
    },
    GET_AVAILABLEMANAGERS: (state, availablemanagers) => {
      state.availablemanagers = availablemanagers;
    },
    GET_AVAILABLETUTORS: (state, availabletutors) => {
      state.availabletutors = availabletutors;
    },
    GET_AVAILABLESTUDENTS: (state, availablestudents) => {
      state.availablestudents = availablestudents;
    },
    GET_FRAMEWORKTYPES: (state, frameworktypes) => {
      state.frameworktypes = frameworktypes;
    },
    GET_TRAININGFRAMEWORKS: (state, trainingframeworks) => {
      state.trainingframeworks = trainingframeworks;
    },
    GET_TRAININGFRAMEWORK: (state, trainingframework) => {
      state.trainingframework = trainingframework;
    },
    GET_FRAMEWORKSEQUENCE: (state, frameworksequence) => {
      state.frameworksequence = frameworksequence;
    },
    GET_TRAININGSEQUENCE: (state, trainingsequence) => {
      state.trainingsequence = trainingsequence;
    },
    GET_SEQUENCETYPES: (state, sequencetypes) => {
      state.sequencetypes = sequencetypes;
    },
    GET_SEQUENCETYPE: (state, sequencetype) => {
      state.sequencetype = sequencetype;
    },
    GET_STUDENTTRAININGS: (state, studenttrainings) => {
      state.studenttrainings = studenttrainings;
    },
    GET_STUDENTTRAINING: (state, studenttraining) => {
      state.studenttraining = studenttraining;
    },
    GET_STUDENTTRAININGDETAIL: (state, studenttrainingdetail) => {
      state.studenttrainingdetail = studenttrainingdetail;
    },
    GET_STUDENTACTIVITY: (state, studentactivity) => {
      state.studentactivity = studentactivity;
    },
    GET_STUDENTACTIVITIES: (state, studentactivities) => {
      state.studentactivities = studentactivities;
    },
    GET_STUDENTSEQUENCE: (state, studentsequence) => {
      state.studentsequence = studentsequence;
    },
    GET_AGENDASTUDENTSEQUENCE: (state, agendastudentsequence) => {
      state.agendastudentsequence = agendastudentsequence;
    },
    GET_USERS: (state, users) => {
      state.users = users;
    },
    GET_USER: (state, user) => {
      state.user = user;
    },
    GET_RESOURCES: (state, resources) => {
      state.resources = resources;
    },
    GET_DATES: (state, dates) => {
      state.dates = dates;
    },
    OPEN_SIDEBAR: (state, bool) => {
      state.isOpen = !bool;
    },
    GET_NOTIFICATIONS: (state, notifications) => {
      state.notifications = notifications;
    },
    GET_WAYPOINT_ACTIVITIES: (state, waypoint) => {
      state.waypoint = waypoint;
    },
    GET_STEPS: (state, trainingSteps) => {
      state.trainingSteps = trainingSteps;
    },
    GET_COMMENTS: (state, commentsEvaluations) => {
      state.commentsEvaluations = commentsEvaluations;
    },
  },
});
