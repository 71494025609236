<template>
  <div class="modalbox modalStep" id="waypointModal">
    <!-- eslint-disable max-len-->
    <div class="is-flex bigmodal">
      <router-link :to="'/formation-apprenant/' +
        $route.params.idTraining +
        '/' +
        $route.params.idStudent +
        '/' +
        $route.params.idStudentTraining +
        '/waypoint'
        " class="btn-previous"> <img :src="require('@/assets/img/arrows/previous.svg')" /></router-link>
      <router-link v-if="
        currentUser.role === 'formateur' ||
        currentUser.role === 'coordinateur'
      " class="ml-auto mr-2" :to="'/formations/' + $route.params.idTraining + '/suivi/validationSteps'
        ">
        <b-button type="is-outlined" rounded size="is-small">Formation</b-button>
      </router-link>
      <router-link class="mr-2" :to="'/formation-apprenant/' +
        $route.params.idTraining +
        '/' +
        $route.params.idStudent +
        '/' +
        $route.params.idStudentTraining +
        '/parcours'
        ">
        <b-button type="is-outlined" rounded size="is-small">Parcours</b-button>
      </router-link>
      <router-link :to="'/formation-apprenant/' +
        $route.params.idTraining +
        '/' +
        $route.params.idStudent +
        '/' +
        $route.params.idStudentTraining +
        '/waypoint'
        ">
        <b-button type="is-outlined" rounded size="is-small">Livret de suivi</b-button></router-link>
    </div>
    <b-loading :is-full-page="isFullPage" v-model="isLoading"> </b-loading>
    <section class="w-100 container">
      <div class="title-paragraph">
        {{ waypoint.name }}
      </div>
      <div class="studentName" v-if="currentUser.role !== 'apprenant' && booklet_validation">
        <span v-if="this.booklet_validation.data">{{
          this.booklet_validation.data[0].name
          }}</span>
      </div>
      <div class="has-text-centered mb-2">
        <b-tag type="is-success" v-if="this.validated === 'Effectué'">{{
          this.validated
          }}</b-tag>
        <b-tag type="is-primary" v-else-if="this.validated === 'En cours'">{{
          this.validated
          }}</b-tag>
        <b-tag type="is-danger is-light" v-else-if="this.validated === 'Litige'">{{ this.validated }}</b-tag>
        <b-tag type="is-outlined" v-else-if="this.validated === 'A faire'">{{
          this.validated
          }}</b-tag>
        <b-tag type="is-info" v-else>{{ this.validated }}</b-tag>
      </div>
      <div class="justify-content-center is-flex align-items-center">
        A remplir entre le {{ waypoint.start_date | day }} et le
        {{ waypoint.due_datetime | day }}

      </div>
      <div v-html="waypoint.helper" class="has-text-centered mb-4 mt-3" v-if="waypoint.helper"></div>
      <div v-if="json_form != null">
        <Survey :survey="survey" />
      </div>
      <div v-if="waypoint.resources" class="mt-4">
        <div v-if="waypoint.resources.length">
          <div v-for="resource in waypoint.resources" :key="resource.id">
            <ResourceCardSimple :resource="resource" :action="'read'"></ResourceCardSimple>
          </div>
        </div>
      </div>
      <div>
        <div v-if="this.json_form == null">
          <div class="ck-editor-block mb-0 pb-0" v-if="!idPatch">
            <ckeditor v-model="formWaypoint.content" :editor="editor" :config="editorConfig" class="mb-5"
              :ref="'revisionContent'"></ckeditor>
          </div>
          <div class="ck-editor-block mb-0 pb-0" v-else>
            <ckeditor v-model="formWaypoint.entry" :editor="editor" :config="editorConfig" class="mb-5"
              :ref="'revisionContent'"></ckeditor>
          </div>
        </div>
        <div v-if="booklet_validation.data">
          <div class="cardValidation" v-if="booklet_validation.data[0].data[0].validations.length">
            <div class="mb-3" v-if="booklet_validation.data[0].data[0].validations.length">
              <b class="title-paragraph" style="font-size: 1.2rem">Validations attendues</b>
            </div>
            <div class="b-steps is-small w-100">
              <nav class="steps is-rounded">
                <ul class="step-items" v-if="booklet_validation.data">
                  <li class="step-item is-previous" v-for="validation in booklet_validation.data[0].data[0]
                    .validations" :key="validation.id">
                    <a class="step-link">
                      <div class="step-marker">
                        <img v-if="validation.status_label == 'Valide'" :src="require(`@/assets/img/steps/status3.svg`)"
                          alt="" class="img_status mx-auto" />

                        <img v-else-if="validation.status_label == 'A faire'"
                          :src="require(`@/assets/img/steps/status6.svg`)" alt="" class="img_status mx-auto" />
                        <img v-else :src="require(`@/assets/img/steps/status5.svg`)" alt=""
                          class="img_status mx-auto" />
                      </div>
                      <div class="step-details">
                        <span class="step-title"> {{ validation.name }}</span>
                      </div>
                      <div class="mt-2">
                        <b-tag v-if="validation.status_label == 'Valide'" type="is-success">
                          {{ validation.status_label }}</b-tag>
                        <b-tag v-else-if="validation.status_label == 'A faire'" type="is-secondary is-outlined">
                          {{ validation.status_label }}</b-tag>
                        <b-tag v-else type="is-danger">
                          {{ validation.status_label }}</b-tag>

                        <div class="mt-2 datetime" v-if="validation.validation_datetime">
                          {{ validation.validation_datetime | day }}
                        </div>
                        <span class="step-name" v-if="validation.validator_name">
                          {{ validation.validator_name }}</span>
                      </div>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div>
      <div class="is-flex is-flex-wrap-wrap activityBtns align-items-center" style="z-index: 2000">
        <div class="w-100 is-flex">
          <div class="mx-auto">
            <div class="is-flex">
              <div v-if="
                waypoint.editable &&
                new Date(waypoint.start_date) < new Date()
              ">

                <span v-if="waypoint.editable.includes(currentUser.role)">
                  <span v-if="json_form != null">
                    <b-button v-if="
                      !this.validation ||
                      this.validation.status_label === 'A faire' ||
                      this.validation.status_label === 'En retard'
                    " @click="survey.completeLastPage()" class="btn-action w-auto mr-1" type="is-primary is-rounded"
                      rounded>enregistrer</b-button>
                  </span>
                  <span v-else>
                    <b-button v-if="
                      !this.validation ||
                      this.validation.status_label === 'A faire' ||
                      this.validation.status_label === 'En retard'
                    " @click="patchBookletPart(false)" class="btn-action w-auto mr-1" type="is-primary is-rounded"
                      rounded>enregistrer</b-button>
                  </span>
                </span>
                <span v-if="this.validation">
                  <span v-if="
                    !this.validation.status_label ||
                    this.validation.status_label === 'A faire' ||
                    this.validation.status_label === 'En retard'">
                    <b-button v-if="json_form != null" class="btn-action w-auto" type="is-success is-rounded" rounded
                      :disabled="!idPatch" @click="validateBookletPart(1)">valider</b-button>
                    <b-button v-else @click="validateBookletPart(1)" class="btn-action w-auto"
                      type="is-success is-rounded" rounded>valider</b-button>
                  </span>
                </span>
              </div>

              <div v-if="
                this.validation && this.validation.status_label !== 'A faire'
              " class="mx-auto is-flex align-items-center">
                <span v-if="this.validation.status_label === 'Valide'"
                  class="has-text-success has-text-centered"><b>Vous avez validé cette fiche</b>
                  <span v-if="json_form != null"> <b-button v-if="this.validated !== 'Effectué'"
                      @click="survey.completeLastPage()" class="btn-action w-auto mr-1"
                      type="is-primary is-rounded is-outlined is-small" rounded>enregistrer
                    </b-button></span> <span v-else><b-button v-if="this.validated !== 'Effectué'"
                      @click="patchBookletPart(false)" class="btn-action w-auto mr-1"
                      type="is-primary is-rounded is-outlined is-small" rounded>enregistrer
                    </b-button></span>
                </span>
                <span v-else-if="
                  this.validation.status_label === 'Non valide' &&
                  this.validation
                " class="has-text-danger has-text-centered"><b>Vous avez refusé de valider cette fiche</b>
                </span>
                <b-button v-if="
                  this.validation &&
                  this.validation.status_label == 'Valide' &&
                  waypoint.editable.includes('currentUser.role')
                " @click="patchBookletPart(false)" class="btn-action w-auto mr-1"
                  type="is-primary is-rounded is-outlined is-small" rounded>enregistrer les modifications
                </b-button>
              </div>
            </div>
          </div>
        </div>
        <div class="help-btn">
          <AppHelpMessage v-if="waypoint.type === 0" title="Rédigez et enregistrez votre point en entreprise" message="La liste des expériences en entreprise remonte automatiquement.
             Si rien n'a été enregistré dans inSitu, vous pouvez revenir dans l'onglet 'Parcours'
              et enregistrer des expériences. Vous pouvez aussi utiliser les champs
               commentaires pour ajouter des éléments. <b> Une fois validé, vous pourrez corriger
                  une faute, ajouter un commentaire mais vous ne pourrez plus toucher
                   aux actviités vécues." textBtn="Besoin d'aide ?" />
          <AppHelpMessage v-if="waypoint.type === 1" title="Rédigez et enregistrez votre point en centre de formation"
            message="La liste des modules remonte automatiquement.
             Si un module a été ajouté en cours de formation et n'ets pas listé ici,
              vous pouvez utiliser les champs commentaires pour préciser ce qui a été vu." textBtn="Besoin d'aide ?" />
        </div>
      </div>
    </div>

    <div class="container contentSequence pb-5 mb-5"></div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';
import CKEditor from '@ckeditor/ckeditor5-vue2';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import '@ckeditor/ckeditor5-build-classic/build/translations/fr';
import AppHelpMessage from '@/components/AppHelpMessage.vue';
import booklet from '@/components/Waypoints/booklet.json';
import _ from 'lodash';
import ResourceCardSimple from '@/components/Resources/ResourceCardSimple.vue';

// SurveyJs
import { Model } from 'survey-core';
import '@/assets/css/survey-core.defaultV2.css';
import { Survey } from 'survey-vue-ui';
import 'survey-core/i18n/french';

export default ({
  filters: {
    day(date) {
      return moment(date).format('DD/MM/YYYY');
    },
  },
  components: {
    AppHelpMessage,
    ckeditor: CKEditor.component,
    ResourceCardSimple,
    Survey,
  },
  computed: {
    ...mapState('auth', ['currentUser']),
  },
  data() {
    return {
      isLoading: false,
      isFullPage: false,
      schoolSubjectsTraining: [],
      schoolSubjects: [],
      schoolSubjectsSelected: [],
      waypoint: [],
      arrayReports: [],
      student_activities_report: [],
      student_revisions_report: [],
      free_situations_report: [],
      formWaypoint: {
        id: '',
        title: '',
        comment: '',
        tutor_comment: '',
        teacher_comment: '',
        start_date: '',
        end_date: '',
        reports: [],
        validated: false,
        entry: '',
      },
      checkboxCustom: [],
      activities: [],
      isOpenActivities: false,
      isOpenRevision: false,
      isOpenFree: false,
      editor: ClassicEditor,
      editorDisabled: true,
      editorConfig: {
        language: 'fr',
        toolbar: ['bold', 'italic', 'bulletedList', 'numberedList', '|', 'outdent', 'indent', '|', 'link', '|', 'undo',
          'redo'],
        alignment: {
          options: ['left', 'right'],
        },
        heading: {
          options: [
            { model: 'paragraph', title: 'Styles de texte', class: 'ck-heading_paragraph' },
            {
              model: 'heading1',
              view: 'h1',
              title: 'Titre 1',
              class: 'ck-heading_heading1',
            },
            {
              model: 'heading2',
              view: 'h2',
              title: 'Titre 2',
              class: 'ck-heading_heading2',
            },
            {
              model: 'mark',
              view: 'mark',
              title: 'surligné',
              class: 'ck-mark',
            },
          ],
        },
        table: {
          contentToolbar: null,
        },
      },
      bookletJson: booklet,
      patchPart: false,
      idPatch: null,
      booklet_validation: [],
      validation: [],
      isDirector: false,
      validated: '',
      survey: new Model(),
      json_form: null,
    };
  },
  methods: {
    startLoading() {
      this.isLoading = true;
    },
    endLoading() {
      this.isLoading = false;
    },
    initDirector() {
      this.$http
        .get(`/trainings/${this.$route.params.idTraining}/?query={directors}&format=json`)
        .then((response) => {
          const director = response.data.directors;
          if (director.includes(this.currentUser.id)) {
            this.isDirector = true;
          }
          const part = this.waypoint;
          if (!part.editable.includes(this.currentUser.role)) {
            this.$router.push({
              name: 'BookletPartRead',
              params: {
                idTraining: this.$route.params.idTraining,
                idStudent: this.$route.params.idStudent,
                idStudentTraining: this.$route.params.idStudentTraining,
                idPart: this.$route.params.idPart,
              },
            });
          }
        });
    },
    initBookletPart() {
      this.startLoading();
      const urlWs = `/get_booklet_part/${this.$route.params.idPart}?student_training=${this.$route.params.idStudentTraining}&format=json`;
      this.$http
        .get(`${urlWs}`)
        .then((response) => {
          this.waypoint = response.data;
          this.initDirector();
          this.formWaypoint = this.waypoint;
          const entry = _.findIndex(this.waypoint.entries, ['student_training', Number(this.$route.params.idStudentTraining)]);
          if (entry !== -1) {
            this.formWaypoint.entry = this.waypoint.entries[entry].content;
            this.idPatch = this.waypoint.entries[entry].id;
          }
          this.endLoading();

          // SurveyJs
          this.json_form = response.data.form_structure;
          window.console.log(JSON.parse(this.json_form));
          if (this.json_form !== null) {
            this.survey = new Model(JSON.parse(this.json_form));
            this.survey.locale = 'fr';
            this.survey.mode = 'edit';

            this.survey.onComplete.add((sender) => {
              window.console.log(JSON.stringify(sender.data, null, 3));
              this.formWaypoint.entry = JSON.stringify(sender.data);
              this.formWaypoint.content = JSON.stringify(sender.data);
              this.patchBookletPart(false);
            });
            // Fin SurveyJs
          }
        });
    },
    initPart() {
      this.startLoading();
      this.$http
        .get(`/get_training_booklet_validations/${this.$route.params.idTraining}?step=${this.$route.params.idPart}&student_training=${this.$route.params.idStudentTraining}`)
        .then((response) => {
          this.booklet_validation = response.data;
          this.validated = this.booklet_validation.data[0].data[0].user_status_label;
          if (this.isDirector) {
            const indexValidation = _.findKey(this.booklet_validation.data[0].data[0].validations,
              ['role', 'directeur']);
            this.validation = this.booklet_validation.data[0].data[0].validations[indexValidation];
          } else {
            const indexValidation = _.findKey(this.booklet_validation.data[0].data[0].validations,
              ['role', this.currentUser.role]);
            this.validation = this.booklet_validation.data[0].data[0].validations[indexValidation];
          }
          this.endLoading();
        });
    },
    postBookletPart(statusValidation) {
      this.$http
        .post('booklet_parts_entries/', {
          simple_booklet_part: this.$route.params.idPart,
          student_training: this.$route.params.idStudentTraining,
          content: this.formWaypoint.content,
        }).then((response) => {
          this.$buefy.toast.open({ message: 'Fiche enregistrée', type: 'is-success' });
          if (statusValidation) {
            this.postValidation(statusValidation, response.data.id);
          } else {
            this.initBookletPart();
          }
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
          this.$buefy.dialog.alert({
            title: 'Mince...',
            message: 'L\'activité n\'a pas pu être enregistrée.',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
          });
        });
    },
    patchBookletPart(statusValidation) {
      if (this.idPatch) {
        this.$http
          .patch(`booklet_parts_entries/${this.idPatch}/`, {
            content: this.formWaypoint.entry,
          }).then(() => {
            this.$buefy.toast.open({ message: 'Fiche enregistrée', type: 'is-success' });
            if (statusValidation) {
              this.postValidation(statusValidation);
            } else {
              this.initBookletPart();
            }
          }).catch((error) => {
            window.console.log(error);
            this.errored = true;
            this.$buefy.dialog.alert({
              title: 'Mince...',
              message: 'L\'activité n\'a pas pu être enregistrée.',
              type: 'is-info',
              hasIcon: true,
              icon: 'times-circle',
              ariaRole: 'alertdialog',
              ariaModal: true,
              confirmText: 'Ok',
            });
          });
      } else {
        this.postBookletPart();
      }
    },
    validateBookletPart(statusValidation) {
      this.$buefy.dialog.confirm({
        title: 'Souhaitez-vous valider cette fiche ?',
        message: 'Vous êtes sur le point de valider cette fiche, vous confirmez que vous l\'avez bien relue. Toute modification sera ensuite impossible.',
        confirmText: 'Valider',
        cancelText: 'Relire',
        type: 'is-success',
        hasIcon: true,
        onConfirm: () => {
          if (this.idPatch) {
            if (this.json_form != null) {
              this.survey.completeLastPage();
              this.postValidation(statusValidation);
            } else {
              window.console.log('patch old form');
              this.$http
                .patch(`booklet_parts_entries/${this.idPatch}/`, {
                  content: this.formWaypoint.entry,
                }).then(() => {
                  this.$buefy.toast.open({ message: 'Fiche enregistrée', type: 'is-success' });
                  this.postValidation(statusValidation);
                }).catch((error) => {
                  window.console.log(error);
                  this.errored = true;
                  this.$buefy.dialog.alert({
                    title: 'Mince...',
                    message: 'L\'activité n\'a pas pu être enregistrée.',
                    type: 'is-info',
                    hasIcon: true,
                    icon: 'times-circle',
                    ariaRole: 'alertdialog',
                    ariaModal: true,
                    confirmText: 'Ok',
                  });
                });
            }
          } else {
            this.postBookletPart(statusValidation);
          }
          this.goPreviousPage();
        },
      });
    },
    postValidation(statusValidation, identry) {
      let roleValidator = '';
      if (this.isDirector) {
        roleValidator = 'directeur';
      } else {
        roleValidator = this.currentUser.role;
      }
      if (!this.idPatch) {
        this.idPatch = identry;
      }
      this.$http
        .post('/validations/validation/', {
          role: roleValidator,
          student_training: Number(this.$route.params.idStudentTraining),
          simple_booklet_part_validation: this.validation.id,
          status: statusValidation,
        }).then(() => {
          this.$buefy.toast.open({
            message: 'La validation a bien été enregistrée',
            type: 'is-success',
          });
          this.goPreviousPage();
        }).catch((error) => {
          window.console.log(error);
          this.issue = true;
          this.$buefy.dialog.alert({
            title: 'Mince...',
            message: 'Votre validation n\'a pas pu être enregistrée',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
          });
        });
    },
    goPreviousPage() {
      this.$router.go(-1);
    },

    gotoActivity(id, sequenceId) {
      this.$router.push({
        name: 'StudentActivity',
        params: {
          idTraining: this.$route.params.idTraining,
          idStudentTraining: this.$route.params.idStudentTraining,
          idActivity: id,
          idSequence: sequenceId,
          from: this.$route.name,
          idStudent: this.$route.params.idStudent,
          idWaypoint: this.$route.params.idWaypoint,
        },
      });
    },
  },
  created() {
    this.startLoading();
    this.initBookletPart();
    this.initPart();
  },
});
</script>

<style lang="scss" scoped>
.card_buttons {
  margin-left: auto !important;
  min-width: 198px;
}

.titles {
  max-width: 460px;
  padding-right: 1rem;
}

.studentName {
  font-weight: bold;
  font-size: 1.4rem;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .card_activity::after {
    content: unset;
    color: #c1bbd0cc;
  }

  .card_activity {
    border-bottom: 1px solid #ededed;
  }

  .card_activity:last-child {
    border-bottom: 0px solid #ededed;

    &::after {
      content: unset;
    }
  }
}

@media screen and (max-width: 576px) {
  .content-steps {
    flex-direction: column;
  }

  .card_buttons {
    margin-left: 0px !important;
    padding-top: 0.5rem;
  }

  .titles {
    flex-direction: column;
  }
}

.sd-btn--action.sd-navigation__complete-btn {
  display: none !important;
}

.sd-body__navigation .sd-btn,
.sd-navigation__prev-btn,
.sd-navigation__next-btn {
  border-radius: 9999px !important;
  padding-left: calc(1em + 0.25em) !important;
  padding-right: calc(1em + 0.25em) !important;
}
</style>
