import { render, staticRenderFns } from "./RolesWiki.vue?vue&type=template&id=77057ad2&scoped=true"
import script from "./RolesWiki.vue?vue&type=script&lang=js"
export * from "./RolesWiki.vue?vue&type=script&lang=js"
import style0 from "./RolesWiki.vue?vue&type=style&index=0&id=77057ad2&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77057ad2",
  null
  
)

export default component.exports