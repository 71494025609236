<template>
  <div class="modalbox modalStep" id="waypointModal">
    <!-- eslint-disable max-len-->
    <div class="is-flex bigmodal">
      <img class="btn-previous" :src="require('@/assets/img/arrows/previous.svg')" @click="goPreviousPage" />
      <router-link v-if="currentUser.role === 'formateur' ||
        currentUser.role === 'coordinateur'
      " class="ml-auto mr-2" :to="'/formations/' + $route.params.idTraining + '/suivi/waypointList'">
        <b-button type="is-outlined" rounded size="is-small">Formation</b-button>
      </router-link>
      <router-link class="mr-2" :to="'/formation-apprenant/' +
        $route.params.idTraining +
        '/' +
        $route.params.idStudent +
        '/' +
        $route.params.idStudentTraining +
        '/parcours'
        ">
        <b-button type="is-outlined" rounded size="is-small">Parcours</b-button>
      </router-link>
      <router-link :to="'/formation-apprenant/' +
        $route.params.idTraining +
        '/' +
        $route.params.idStudent +
        '/' +
        $route.params.idStudentTraining +
        '/waypoint'
        ">
        <b-button type="is-outlined" rounded size="is-small">Livret de suivi</b-button></router-link>
    </div>
    <section class="w-100 container">
      <b-loading :is-full-page="isFullPage" v-model="isLoading"> </b-loading>
      <div class="has-text-centered">
        <span class="tag status2 mr-1 w-auto" v-if="waypoint.type === 6">Visite en entreprise</span>
        <span class="tag is-success mr-0 w-auto" v-if="waypoint.validated">validé</span>
        <span>
          <span class="tag is-success mr-0 w-auto ml-1" v-if="waypoint.tutor_validation">lu par le tuteur</span><span
            v-else-if="waypoint.student_validation" class="ml-1 tag is-warning mr-0 w-auto">lu par
            l'apprenant</span></span>
      </div>
      <div class="title-paragraph">
        <span v-if="waypoint.type === 6"> Visite en entreprise</span>
      </div>
      <div v-if="currentUser.role === 'tuteur' && waypoint.type === 2" style="margin-top:60px;"><b-message
          type="is-warning" class="messagebox mt-5" style="margin-top:60px;"> Ce point est confidentiel. Vous ne pouvrez
          pas lire le
          contenu.
        </b-message></div>
      <div v-else>
        <div v-if="currentUser.role !== 'apprenant'" class="has-text-centered mb-4">
          <b>{{ waypoint.student }}</b>
        </div>
        <div></div>

        <div v-if="edit">
          <div class="columns mt-3">
            <div class="column">
              <!--
              <b-field class="mb-0" label="Date de début" :type="start_date > end_date ? 'is-danger' : ''">
                <b-datetimepicker v-if="start_date" v-model="start_date" :first-day-of-week="1"
                  placeholder="Cliquez pour sélectionner..." trap-focus @input="
        verifdate(
          new Date(waypoint.start_date),
          new Date(waypoint.end_date)
        )
        ">
                  <b-button label="Aujourd'hui" type="is-primary" icon-left="calendar-today"
                    @click="start_date = new Date()" /></b-datetimepicker></b-field>
              -->
            </div>
            <div class="column">
              <!--
              <b-field class="mb-0" label="Date de fin" :type="start_date > end_date ? 'is-danger' : ''">
                <b-datetimepicker v-model="end_date" :first-day-of-week="1" placeholder="Cliquez pour sélectionner..."
                  @input="verifdate(waypoint.start_date, waypoint.end_date)">
                  <b-button label="Aujourd'hui" type="is-primary" icon-left="calendar-today"
                    @click="end_date = new Date()" /> </b-datetimepicker></b-field>
                    -->
            </div>
          </div>
          <b-message type="is-danger">
            <div v-if="start_date > end_date">
              Attention, il semble y a une incohérence entre la date de début et
              la date de fin. La date de début est postérieure à la date de fin.
            </div>
          </b-message>
          <b-message type="is-warning">
            <div v-if="start_date > new Date()">
              Attention, le point est programmé pour plus tard, voulez-vous
              vraiment éditer ?
            </div>
          </b-message>
          <div class="title2" v-if="waypoint.type === 6">Visite en entreprise</div>
          <div v-if="waypoint.type === 6">
            <Survey :survey="survey" />
          </div>

          <div v-if="waypoint.type === 6">
            <div class="title2 mb-4">Évaluations</div>
            <SkillEvaluationWaypoint type="waypoint" @refresh="initEvalComments"
              :formCommentsRecap.sync="commentsEvaluations" :key="keycomponent" :edit.sync="edit"
              :role="['w_formateur']" />
          </div>

        </div>
        <div v-else>
          <div class="columns mt-3">
            <div class="column">
              <!--
              <b-field class="mb-0" label="Date de début">{{ waypoint.start_date | day }} -
                {{ waypoint.start_date | hour }}</b-field>
                 -->
            </div>
            <div class="column">
              <!--
              <b-field class="mb-0" label="Date de fin">
                {{ waypoint.end_date | day }}-
                {{ waypoint.end_date | hour }}</b-field>
                 -->
            </div>
          </div>
          <b-message type="is-danger">
            <div v-if="start_date > end_date">
              Attention, il semble y a une incohérence entre la date de début et
              la date de fin. La date de début est postérieure à la date de fin.
            </div>
          </b-message>
          <b-message type="is-warning">
            <div v-if="start_date > new Date()">
              Le point n'a pas encore commencé..
            </div>
          </b-message>
          <div v-if="waypoint.type === 6">
            <Survey :survey="survey" />
          </div>

          <div v-if="waypoint.type === 6">
            <div class="title2 mb-4">Évaluations</div>
            <SkillEvaluationWaypoint type="waypoint" @refresh="initEvalComments"
              :formCommentsRecap.sync="formWaypoint.free_text" :edit.sync="edit" :role="['w_formateur']" />
          </div>
          <div v-if="waypoint.type === 0">
            <b-message type="is-success" v-if="formWaypoint.teacher_comment !== null &&
              formWaypoint.teacher_comment !== '' &&
              start_date < end_date
            ">Si vous avez tout bien relu, vous pouvez valider votre
              point</b-message>
            <b-message type="is-danger" v-else>
              <span v-if="start_date > end_date">Veuillez modifier les dates</span>
              <span v-else>
                Pour valider, vous devez avoir renseigné tous les champs.</span>
            </b-message>
          </div>
        </div>
      </div>
    </section>
    <div v-if="edit">
      <div class="is-flex is-flex-wrap-wrap activityBtns align-items-center" style="z-index: 2000">
        <div class="w-100 is-flex">
          <div class="mx-auto">
            <b-button @click="patchWaypoint(false, 'save')" class="btn-action w-auto mr-1" type="is-primary is-rounded"
              rounded>enregistrer</b-button>
            <span>
              <!-- if waypoint type = 'entreprise' -->
              <b-button v-if="!waypoint.validated
              " class="btn-action w-auto" type="is-success is-rounded" rounded @click="
                patchWaypoint(true, '');
              patchValidate();
              ">valider</b-button>
              <!-- if waypoint cfa -->

              <b-button v-else-if="currentUser.role === 'coordinateur' &&
                waypoint.type === 6 &&
                !waypoint.validated
              " class="btn-action w-auto" type="is-success is-rounded" rounded @click="
                patchWaypoint(true, '');
              patchValidate();
              ">valider</b-button>
              <span v-else><b-button class="btn-action w-auto" type="is-success is-rounded" rounded
                  disabled>valider</b-button>
              </span>
            </span>
          </div>
        </div>
        <div class="help-btn is-flex align-items-center">
          <b-button @click="removeWaypoint(waypoint.id)" class="btn-action w-auto mr-2 is-hidden-touch"
            type="is-danger is-rounded" size="is-small" rounded
            v-if="currentUser.role == 'formateur' && !waypoint.validated">supprimer</b-button>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="is-flex is-flex-wrap-wrap activityBtns align-items-center" style="z-index: 2000">
        <div class="w-100 is-flex">
          <div class="mx-auto">
            <b-button v-if="!waypoint.validated && currentUser.role === 'formateur'" @click="
              edit = true;
            survey.mode = 'edit';
            keycomponent += 1;
            " class="btn-action w-auto mr-1" type="is-primary is-rounded" rounded>rédiger</b-button>
            <b-button v-if="currentUser.role === 'formateur' &&
              !waypoint.validated &&
              formWaypoint.teacher_comment !== null &&
              formWaypoint.teacher_comment !== '' &&
              start_date < end_date &&
              start_date < new Date()
            " class="btn-action w-auto" type="is-success is-rounded" rounded @click="
              patchWaypoint(true, '');
            patchValidate();
            ">valider</b-button>
            <b-button v-if="(!waypoint.student_validation &&
              currentUser.role === 'apprenant') ||
              (!waypoint.tutor_validation && currentUser.role === 'tuteur' && waypoint.type !== 2)
            " @click="patchValidate()" class="btn-action w-auto mr-1" type="is-success is-rounded" rounded>marquer
              comme lu</b-button>
            <span class="has-text-success" v-if="(waypoint.student_validation &&
              currentUser.role === 'apprenant') ||
              (waypoint.tutor_validation && currentUser.role === 'tuteur')
            "><b>Vouz avez marqué cette fiche comme lu</b></span>
            <span class="has-text-success" v-else-if="waypoint.validated && currentUser.role === 'formateur'"><b>Vouz
                avez validé cette fiche</b></span>
          </div>
        </div>
        <div class="help-btn is-flex align-items-center">
          <b-button @click="removeWaypoint(waypoint.id)" class="btn-action w-auto mr-2 is-hidden-touch"
            type="is-danger is-rounded" size="is-small" rounded
            v-if="currentUser.role == 'formateur' && !waypoint.validated">supprimer</b-button>
        </div>
      </div>
    </div>

    <div class="container contentSequence pb-5 mb-5"></div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';
import localization from 'moment/locale/fr';
import '@ckeditor/ckeditor5-build-classic/build/translations/fr';
import SkillEvaluationWaypoint from '@/components/Skills/SkillEvaluationWaypoint.vue';
import { Model } from 'survey-core';
import '@/assets/css/survey-core.defaultV2.css';
import { Survey } from 'survey-vue-ui';

moment.updateLocale('fr', localization);

export default ({
  filters: {
    day(date) {
      return moment(date).format('D/MM/YYYY');
    },
    hour(date) {
      return moment(date).format('H:mm');
    },
  },
  components: {
    SkillEvaluationWaypoint,
    Survey,
  },
  computed: {
    ...mapState('auth', ['currentUser']),
    commentsEvaluations() {
      return this.$store.state.commentsEvaluations;
    },

  },
  data() {
    return {
      keycomponent: 0,
      isLoading: false,
      isFullPage: false,
      schoolSubjectsTraining: [],
      schoolSubjects: [],
      schoolSubjectsSelected: [],
      waypoint: [],
      waypointdeux: {
        start_date: null,
      },
      arrayReports: [],
      student_activities_report: [],
      student_revisions_report: [],
      free_situations_report: [],
      formWaypoint: {
        id: '',
        title: '',
        comment: '',
        tutor_comment: '',
        teacher_comment: '',
        start_date: null,
        end_date: null,
        reports: [],
        validated: false,
      },
      checkboxCustom: [],
      activities: [],
      isOpenActivities: false,
      isOpenRevision: false,
      isOpenFree: false,
      trainingname: '',
      start_date: null,
      end_date: null,
      studentWaypointValidate: false,
      isSwitchedActivities: false,
      students: [],
      studentTrainings: [],
      formComments: [],
      skillsComments: null,
      edit: false,
      survey: new Model(),
      json_form: {},
    };
  },
  methods: {
    startLoading() {
      this.isLoading = true;
    },
    endLoading() {
      this.isLoading = false;
    },
    initWaypoint() {
      /* eslint-disable */
      // this.startLoading();
      const urlWs = `/agenda/student_training_waypoints/${this.$route.params.idWaypoint}/?format=json`;
      this.$http
        .get(`${urlWs}`)
        .then((response) => {
          this.formWaypoint = response.data;
          this.waypoint = response.data;
          this.start_date = new Date(this.formWaypoint.start_date);
          this.end_date = new Date(this.formWaypoint.end_date);
          //if (response.data.form_entry == "") {this.json_form = response.data.form_structure} else {this.json_form = response.data.form_entry}
          this.json_form = response.data.form_structure;
          console.log(JSON.parse(this.json_form));
          //console.log(JSON.parse(response.data.form_entry));
          this.survey = new Model(JSON.parse(this.json_form));
          if (this.edit === false) {
            this.survey.mode = "display";
          } else {
            this.survey.mode = "edit";
            this.survey.clear();
            this.survey.render();
          }

          this.survey.onComplete.add((sender, options) => {
            console.log(JSON.stringify(sender.data, null, 3));
            this.postForm(JSON.stringify(sender.data));

            //this.survey.clear();
            //this.survey.render();

            //document.querySelector('#surveyResult').innerHTML = "result: " + JSON.stringify(result.data);
            //document.querySelector("#clearBtn").style.display = 'inline-block';

          });
          if (this.waypoint.validated) {
            this.edit = false;
          }
          this.endLoading();
        });

      this.survey.onComplete.add((sender, options) => {
        console.log(JSON.stringify(sender.data, null, 3));
      });
    },

    postForm(post_form) {
      this.$http
        .patch(`/agenda/student_training_waypoints/${this.$route.params.idWaypoint}/`, {
          form_entry: post_form,
        }).then(() => {
          this.$buefy.dialog.alert({
            title: 'Super !',
            message: 'Le formulaire a été soumis.',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
          });
        });

    },

    goPreviousPage() {
      if (this.$route.params.from === 'StudentActivity' || this.$route.params.from === 'StudentProgressionRead' || this.$route.params.from === 'StudentFreeSituationRead') {
        this.$router.push({
          name: 'StudentTraining',
          params: {
            idStudent: this.$route.params.idStudent, // need check id profile
            idTraining: this.$route.params.idTraining,
            idStudentTraining: this.$route.params.idStudentTraining,
            tab: 'waypoint',
          },
        });
      } else if (window.history.length > 1) {
        this.$router.go(-1);
      } else {
        this.$router.push({
          name: 'StudentTraining',
          params: {
            idStudent: this.$route.params.idStudent, // need check id profile
            idTraining: this.$route.params.idTraining,
            idStudentTraining: this.$route.params.idStudentTraining,
            tab: 'waypoint',
          },
        });
      }
    },
    logActivity() {
      const typeAction = 'écriture waypoint';
      this.$http
        .post('agenda/logs/', {
          action: typeAction,
          student_training: this.$route.params.idStudentTraining,
          training: this.$route.params.idTraining,
          student_activity: null,
          student_sequence: null,
        })
        .then(() => {
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
        });
    },
    verifdate(start, end) {
      if (this.start_date > this.end_date) {
        this.$buefy.dialog.confirm({
          title: 'Attention, il semble y avoir un problème de dates',
          message: 'La date de début est incohérente avec la date de fin',
          confirmText: 'Annuler',
          cancelText: 'Valider',
          type: 'is-danger',
          hasIcon: true,
          onConfirm: () => {
            this.start_date = start;
            this.end_date = end;
          },
        });
      } else if (this.start_date > new Date()) {
        this.$buefy.dialog.confirm({
          title: 'Attention, la date de début ne peut pas être postérieure à aujourd\'hui',
          message: 'Voulez-vous bien changer cette date ?',
          confirmText: 'Annuler',
          cancelText: 'Valider',
          type: 'is-danger',
          hasIcon: true,
          onConfirm: () => {
            this.start_date = start;
            this.end_date = end;
          },
        });
      }
      if (this.formWaypoint.type === 0) {
        this.getActivities();
      }
    },
    removeWaypoint(id) {
      const urlWs = `/agenda/student_training_waypoints/${id}/`;
      this.$buefy.dialog.confirm({
        title: 'Supprimer un point d\'étape',
        message: 'Êtes-vous sûr de vouloir supprimer ce point ? Attention, vous perdrez tout le contenu que vous avez pu rédiger pour ce point.',
        confirmText: 'Supprimer',
        cancelText: 'Annuler',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.$http
            .delete(`${urlWs}`)
            .then(() => {
              this.$buefy.toast.open({ message: 'Point supprimé', type: 'is-success' });
              this.$router.push({
                name: 'StudentTraining',
                params: {
                  idStudent: this.$route.params.idStudent, // need check id profile
                  idTraining: this.$route.params.idTraining,
                  idStudentTraining: this.$route.params.idStudentTraining,
                  tab: 'waypoint',
                },
              });
            }).catch((error) => {
              window.console.log(error);
              this.errored = true;
              this.$buefy.dialog.alert({
                title: 'Mince...',
                message: 'Le point n\'a pas pu être supprimé.',
                type: 'is-info',
                hasIcon: true,
                icon: 'times-circle',
                ariaRole: 'alertdialog',
                ariaModal: true,
                confirmText: 'Ok',
              });
            });
        },
      });
    },
    initOptions() {
      this.startLoading();
      this.$http
        .get(`/trainings/${this.$route.params.idTraining}/?query={tab_options,related_student_trainings_from_this_training,students}&format=json`)
        .then((response) => {
          this.trainingOptions = response.data.tab_options;
          this.studentTrainings = response.data.related_student_trainings_from_this_training;
          this.students = response.data.students;
          if (this.trainingOptions.find((option) => option.code === 'studentWaypointCFAValidate')) {
            this.studentWaypointValidate = true;
          }
        }).catch((error) => {
          window.console.log(error);
        });
    },
    handleHistoryChange() {
      window.console.log('history');
      this.initWaypoint();
    },
    initEvalComments(text) {
      this.skillsComments = text;
      // this.patchWaypoint(false, '');
      this.$store.commit('GET_COMMENTS', JSON.stringify(this.skillsComments));
    },
    skillComments() {
      this.formWaypoint.free_text = '';
      this.skillsComments.forEach((block) => {
        let skillsEntries = 0;
        if (block.skills) {
          this.formWaypoint.free_text += `<p class="has-text-primary" style="font-size:1.1rem;"><b> ${block.name} </b></p>`;
          block.skills.forEach((skill) => {
            if (skill.entry) {
              skillsEntries += 1;
              this.formWaypoint.free_text += `<p><b> ${skill.name} </b></p>`;
              this.formWaypoint.free_text += `<p> ${skill.entry} </p>`;
              this.formWaypoint.free_text += '<p>&nbsp;</p>';
            }
          });
          if (skillsEntries === 0) {
            this.formWaypoint.free_text += '<p>pas de commentaires</p>';
          }
          this.formWaypoint.free_text += '<p>&nbsp;</p>';
        }
      });
    },
    patchWaypoint(validate, mode) {
      let validationStatus = validate;
      if (this.formWaypoint.validated) {
        validationStatus = true;
      }
      let freeText = null;
      if (this.waypoint.type === 6) {
        freeText = this.commentsEvaluations;
      } else {
        freeText = this.formWaypoint.free_text;
      }
      this.$http
        .patch(`agenda/student_training_waypoints/${this.$route.params.idWaypoint}/`, {
          title: this.formWaypoint.title,
          start_date: this.start_date,
          end_date: this.end_date,
          type: this.formWaypoint.type,
          student_training: this.$route.params.idStudentTraining,
          teacher_comment: this.formWaypoint.teacher_comment,
          validated: validationStatus,
          free_text: freeText,
        }).then(() => {
          if (validate) {
            this.$buefy.toast.open({ message: 'Point d\'étape validé', type: 'is-success' });
            this.edit = false;
          } else if (mode === 'save') { this.$buefy.toast.open({ message: 'Point d\'étape enregistré', type: 'is-success' }); this.initWaypoint(); }
          this.initWaypoint();
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
          this.$buefy.dialog.alert({
            title: 'Mince...',
            message: 'Le point n\'a pas pu être enregistré.',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
          });
        });
    },
    patchValidate() {
      let validationStudent = null;
      let validationTutor = null;
      /* let validationTeacher = null; */
      if (this.currentUser.role === 'apprenant') {
        validationStudent = new Date();
      } else if (this.currentUser.role === 'tuteur') {
        validationTutor = new Date();
      }
      /* else if (this.currentUser.role === 'formateur') {
        validationTeacher = new Date();
      } */
      this.$http
        .patch(`agenda/student_training_waypoints/${this.$route.params.idWaypoint}/`, {
          student_validation: validationStudent,
          tutor_validation: validationTutor,
          /* teacher_validation: validationTeacher, */
        }).then(() => {
          this.$buefy.toast.open({ message: 'Point d\'étape validé', type: 'is-success' });
          this.initWaypoint();
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
          this.$buefy.dialog.alert({
            title: 'Mince...',
            message: 'Le point n\'a pas pu être enregistré.',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
          });
        });
    },
  },
  created() {
    if (this.$route.params.edit === true) {
      this.edit = true;
      this.survey.mode = "edit";
    }
    if (this.currentUser.role === 'apprenant' || this.currentUser.role === 'tuteur') { this.logActivity(); }
    this.startLoading();
    this.initOptions();
    this.initWaypoint();
  },
  mounted() {
    window.addEventListener('popstate', this.handleHistoryChange);
  },
  destroyed() {
    window.removeEventListener('popstate', this.handleHistoryChange);
  },
});
/*
document.querySelector("#clearBtn").onclick = function() {
  survey.clear();
  survey.render();
  
  document.querySelector('#surveyResult').innerHTML = "";
  document.querySelector("#clearBtn").style.display = 'none';
}
  */
</script>

<style lang="scss" scoped>
.card_buttons {
  margin-left: auto !important;
  min-width: 198px;
}

.titles {
  max-width: 460px;
  padding-right: 1rem;
}

@media screen and (max-width: 768px) {
  .card_activity::after {
    content: unset;
    color: #c1bbd0cc;
  }

  .card_activity {
    border-bottom: 1px solid #ededed;
  }

  .card_activity:last-child {
    border-bottom: 0px solid #ededed;

    &::after {
      content: unset;
    }
  }
}

@media screen and (max-width: 576px) {
  .content-steps {
    flex-direction: column;
  }

  .card_buttons {
    margin-left: 0px !important;
    padding-top: 0.5rem;
  }

  // .actions-icon,
  // .img_status {
  //   display: block;
  // }
  .titles {
    flex-direction: column;
  }
}

.btn-previous {
  margin-left: 0px;
}
</style>
