<template>
  <div>
    <!-- eslint-disable max-len-->
    <nav class="submenu">
      <ul>
        <li v-for="(tab, index) of tabs" :key="index" :id="tab.id" :class="currentTab === index + 1 ? 'is-active' : ''">
          <a @click="gotoStep(index + 1)">{{ tab.name }}</a>
        </li>
      </ul>
    </nav>
    <div class="content-page tableUsers" v-if="availableteachers">
      <section id="availableteacher" v-if="step == 1">
        <div v-if="!availableteachers.length" class="no-user">0 formateurs</div>
        <div v-else>
          <div class="userCount">
            {{ availableteachers.length }} formateur<span v-if="availableteachers.length > 1">s</span>
          </div>
          <b-collapse :open="false" class="" animation="slide" aria-id="contentIdForA11y3">
            <div slot="trigger" class="is-flex" slot-scope="props" role="button" aria-controls="visibleColumns">
              <a>
                <b-icon :icon="props.open ? 'menu-down' : 'menu-up'"> </b-icon>
              </a>
              <p class="card-header-title">Options d'affichage</p>
            </div>
            <div class="card-content">
              <div class="content">
                <b-field grouped group-multiline>
                  <div v-for="(column, index) in teachersColumns" :key="index" class="control">
                    <b-checkbox v-model="column.visible">
                      {{ column.label }}
                    </b-checkbox>
                  </div>
                </b-field>
              </div>
            </div>
          </b-collapse>
          <b-table :key="componentKey" :data="teachersArray" :paginated="isPaginated" :per-page="perPage"
            :current-page="currentPage" :pagination-simple="isPaginationSimple"
            :pagination-position="paginationPosition" :sort-icon-size="sortIconSize"
            :default-sort-direction="defaultSortDirection" :debounce-search="1000" default-sort="name">
            <template v-for="column in teachersColumns">
              <b-table-column :key="column.id" v-bind="column">
                <template v-if="column.searchable && !column.numeric" slot="searchable" slot-scope="props">
                  <b-input v-model="props.filters[props.column.field]" placeholder="Rechercher..." icon="search"
                    size="is-small" />
                </template>
                <template v-slot="props" v-if="column.field === 'user'">
                  <b> {{ props.row.user }}</b>
                </template>
                <template v-slot="props" v-else-if="column.field === 'organization'">
                  <b v-if="props.row.organization">
                    {{ props.row.organization }}</b>
                </template>
                <template v-slot="props" v-else-if="column.field === 'id'">
                  <div @click.prevent="seeUserprofile(props.row.id)">
                    <b-icon icon="see"></b-icon>
                  </div>
                </template>
              </b-table-column>
            </template>
          </b-table>
        </div>
      </section>
      <section id="students" v-if="step == 2">
        <div v-if="!studentsUsersArray.length" class="no-user">
          0 apprenants
        </div>
        <div v-else>
          <div class="userCount">
            {{ studentsUsersArray.length }} apprenant<span v-if="studentsUsersArray.length > 1">s</span>
          </div>
          <b-collapse :open="false" class="" animation="slide" aria-id="contentIdForA11y3">
            <div slot="trigger" class="is-flex" slot-scope="props" role="button" aria-controls="visibleColumns">
              <a>
                <b-icon :icon="props.open ? 'menu-down' : 'menu-up'"> </b-icon>
              </a>
              <p class="card-header-title">Options d'affichage</p>
            </div>
            <div class="card-content">
              <div class="content">
                <b-field grouped group-multiline>
                  <div v-for="(column, index) in studentUsersColumns" :key="index" class="control">
                    <b-checkbox v-model="column.visible">
                      {{ column.label }}
                    </b-checkbox>
                  </div>
                </b-field>
              </div>
            </div>
          </b-collapse>
          <b-table :key="componentKey" :data="studentsUsersArray" ref="table" :paginated="isPaginated"
            :per-page="perPage" :current-page="currentPage" :pagination-simple="isPaginationSimple"
            :pagination-position="paginationPosition" :sort-icon-size="sortIconSize"
            :default-sort-direction="defaultSortDirection" :debounce-search="1000" default-sort="name">
            <template v-for="column in studentUsersColumns">
              <b-table-column :key="column.id" v-bind="column">
                <template v-if="column.searchable && !column.numeric" slot="searchable" slot-scope="props">
                  <b-input v-model="props.filters[props.column.field]" placeholder="Rechercher..." icon="search"
                    size="is-small" />
                </template>
                <template v-slot="props" v-if="column.field === 'user'">
                  <b> {{ props.row.user }}</b>
                </template>
                <template v-slot="props" v-else-if="column.field === 'managers_profiles'">
                  {{ String(props.row.managers_profiles) }}
                </template>
                <template v-slot="props" v-else-if="column.field === 'tutors_profiles'">
                  {{ String(props.row.tutors_profiles) }}
                </template>
                <template v-slot="props" v-else-if="column.field === 'id'">
                  <div @click.prevent="seeUserprofile(props.row.id)">
                    <b-icon icon="see"></b-icon>
                  </div>
                </template>
              </b-table-column>
            </template>
          </b-table>
        </div>
      </section>

      <section id="managers" v-if="step == 3">
        <div v-if="!managersArray.length" class="no-user">0 managers</div>
        <div v-else>
          <div class="userCount">
            {{ managersArray.length }} manager<span v-if="managersArray.length > 1">s</span>
          </div>
          <b-collapse :open="false" class="" animation="slide" aria-id="contentIdForA11y3">
            <div slot="trigger" class="is-flex" slot-scope="props" role="button" aria-controls="visibleColumns">
              <a>
                <b-icon :icon="props.open ? 'menu-down' : 'menu-up'"> </b-icon>
              </a>
              <p class="card-header-title">Options d'affichage</p>
            </div>
            <div class="card-content">
              <div class="content">
                <b-field grouped group-multiline>
                  <div v-for="(column, index) in managersColumns" :key="index" class="control">
                    <b-checkbox v-model="column.visible">
                      {{ column.label }}
                    </b-checkbox>
                  </div>
                </b-field>
              </div>
            </div>
          </b-collapse>
          <b-table :data="managersArray" :key="componentKey" ref="table" :paginated="isPaginated" :per-page="perPage"
            :current-page="currentPage" :pagination-simple="isPaginationSimple"
            :pagination-position="paginationPosition" :sort-icon-size="sortIconSize"
            :default-sort-direction="defaultSortDirection" :debounce-search="1000" default-sort="name">
            <template v-for="column in managersColumns">
              <b-table-column :key="column.id" v-bind="column">
                <template v-if="column.searchable && !column.numeric" slot="searchable" slot-scope="props">
                  <b-input v-model="props.filters[props.column.field]" placeholder="Rechercher..." icon="search"
                    size="is-small" />
                </template>
                <template v-slot="props" v-if="column.field === 'user'">
                  <b> {{ props.row.user }}</b>
                </template>
                <template v-slot="props" v-else-if="column.field === 'organization'">
                  <b> {{ props.row.organization }}</b>
                </template>
                <template v-slot="props" v-else-if="column.field === 'student'">
                  <b> {{ props.row.manager }}</b>
                </template>
                <template v-slot="props" v-else-if="column.field === 'tutor'">
                  <b> {{ props.row.tutor }}</b>
                </template>
                <template v-slot="props" v-else-if="column.field === 'id'">
                  <div @click.prevent="seeUserprofile(props.row.id)">
                    <b-icon icon="see"></b-icon>
                  </div>
                </template>
              </b-table-column>
            </template>
          </b-table>
        </div>
      </section>
      <section id="tutors" v-if="step == 4">
        <div v-if="!tutorsArray.length" class="no-user">0 tuteurs</div>
        <div v-else>
          <div class="userCount">
            {{ tutorsArray.length }} tuteur<span v-if="tutorsArray.length > 1">s</span>
          </div>
          <b-collapse :open="false" class="" animation="slide" aria-id="contentIdForA11y3">
            <div slot="trigger" class="is-flex" slot-scope="props" role="button" aria-controls="visibleColumns">
              <a>
                <b-icon :icon="props.open ? 'menu-down' : 'menu-up'"> </b-icon>
              </a>
              <p class="card-header-title">Options d'affichage</p>
            </div>
            <div class="card-content">
              <div class="content">
                <b-field grouped group-multiline>
                  <div v-for="(column, index) in tutorsColumns" :key="index" class="control">
                    <b-checkbox v-model="column.visible">
                      {{ column.label }}
                    </b-checkbox>
                  </div>
                </b-field>
              </div>
            </div>
          </b-collapse>
          <b-table :data="tutorsArray" :key="componentKey" ref="table" :paginated="isPaginated" :per-page="perPage"
            :current-page="currentPage" :pagination-simple="isPaginationSimple"
            :pagination-position="paginationPosition" :sort-icon-size="sortIconSize"
            :default-sort-direction="defaultSortDirection" :debounce-search="1000" default-sort="name">
            <template v-for="column in tutorsColumns">
              <b-table-column :key="column.id" v-bind="column">
                <template v-if="column.searchable && !column.numeric" slot="searchable" slot-scope="props">
                  <b-input v-model="props.filters[props.column.field]" placeholder="Rechercher..." icon="search"
                    size="is-small" />
                </template>
                <template v-slot="props" v-if="column.field === 'user'">
                  <b> {{ props.row.user }}</b>
                </template>
                <template v-slot="props" v-else-if="column.field === 'organization'">
                  <b> {{ props.row.organization }}</b>
                </template>
                <template v-slot="props" v-else-if="column.field === 'student'">
                  <b> {{ props.row.manager }}</b>
                </template>
                <template v-slot="props" v-else-if="column.field === 'tutor'">
                  <b> {{ props.row.tutor }}</b>
                </template>
                <template v-slot="props" v-else-if="column.field === 'id'">
                  <div @click.prevent="seeUserprofile(props.row.id)">
                    <b-icon icon="see"></b-icon>
                  </div>
                </template>
              </b-table-column>
            </template>
          </b-table>
        </div>
      </section>
    </div>
  </div>
</template>

<script>

export default {
  methods: {
    gotoStep(numberStep) {
      this.currentTab = numberStep;
      this.step = numberStep;
      this.forceRerender(); // to force render of serchable fields
    },
    setTeachers() {
      // get teachers related to this training
      this.$http
        .get(`/profile/?related_trainings_as_teacher=${this.$route.params.idTraining}&query={id,user{first_name,last_name},organization{name}}&format=json`)
        .then((response) => {
          this.availableteachers = response.data;
          this.createTeachersArray();
        });
    },
    setStudents() {
      // get teachers related to this training
      this.$http
        .get(`/profile/?related_trainings_as_student=${this.$route.params.idTraining}&query={id,user{first_name,last_name},organization{name}}&format=json`)
        .then((response) => {
          this.availablestudents = response.data;
          this.createStudentsArray();
        });
    },
    setManagers() {
      // get teachers related to this training
      this.$http
        .get(`/profile/?related_trainings_as_manager=${this.$route.params.idTraining}&query={id,user{first_name,last_name},organization{name}}&format=json`)
        .then((response) => {
          this.availablemanagers = response.data;
          this.createManagersArray();
        });
    },
    setTutors() {
      // get teachers related to this training
      this.$http
        .get(`/profile/?related_trainings_as_tutor=${this.$route.params.idTraining}&query={id,user{first_name,last_name},organization{name}}&format=json`)
        .then((response) => {
          this.availabletutors = response.data;
          this.createTutorsArray();
        });
    },
    initStudenttrainings() {
      this.$http
        .get(`/agenda/student_training/?training=${this.$route.params.idTraining}&query={id,student,organization,student_profile,managers,managers_profiles,tutors_profiles}&format=json`)
        .then((response) => {
          this.studenttrainings = response.data;
          this.createStudentsUsersArray();
        });
    },
    createTeachersArray() {
      for (let i = 0; i < this.availableteachers.length; i += 1) {
        let orga = '';
        if (!this.availableteachers[i].organization) {
          orga = '';
        } else { orga = this.availableteachers[i].organization.name; }
        this.teachersArray.push(
          {
            user: `${this.availableteachers[i].user.last_name} ${this.availableteachers[i].user.first_name}`,
            organization: orga,
            id: this.availableteachers[i].id,
          },
        );
      }
    },
    createStudentsArray() {
      for (let i = 0; i < this.availablestudents.length; i += 1) {
        let orga = '';
        if (!this.availablestudents[i].organization) {
          orga = '';
        } else { orga = this.availablestudents[i].organization.name; }
        this.studentsArray.push(
          {
            user: `${this.availablestudents[i].user.last_name} ${this.availablestudents[i].user.first_name}`,
            organization: orga,
            id: this.availablestudents[i].id,
          },
        );
      }
    },
    createStudentsUsersArray() {
      for (let i = 0; i < this.studenttrainings.length; i += 1) {
        // let orga = '';
        // if (!this.availablestudents[i].organization) {
        //   orga = '';
        // } else { orga = this.availablestudents[i].organization.name; }
        this.studentsUsersArray.push(
          {
            user: this.studenttrainings[i].student_profile,
            // organization: orga,
            id: this.studenttrainings[i].student,
            managers_profiles: this.studenttrainings[i].managers_profiles,
            tutors_profiles: this.studenttrainings[i].tutors_profiles,
          },
        );
      }
    },
    createManagersArray() {
      for (let i = 0; i < this.availablemanagers.length; i += 1) {
        let orga = '';
        if (!this.availablemanagers[i].organization) {
          orga = '';
        } else { orga = this.availablemanagers[i].organization.name; }
        this.managersArray.push(
          {
            user: `${this.availablemanagers[i].user.last_name} ${this.availablemanagers[i].user.first_name}`,
            organization: orga,
            id: this.availablemanagers[i].id,
          },
        );
      }
    },
    createTutorsArray() {
      for (let i = 0; i < this.availabletutors.length; i += 1) {
        let orga = '';
        if (!this.availabletutors[i].organization) {
          orga = '';
        } else { orga = this.availabletutors[i].organization.name; }
        this.tutorsArray.push(
          {
            user: `${this.availabletutors[i].user.last_name} ${this.availabletutors[i].user.first_name}`,
            organization: orga,
            id: this.availabletutors[i].id,
          },
        );
      }
    },
    forceRerender() {
      // Every time that forceRerender is called, our prop componentKey will change.
      // and Vue knows that it has to destroy the component and create a new one.
      // to not have searching values in others tables
      this.componentKey += 1;
    },
    seeUserprofile(param) {
      this.$router.push({
        name: 'UtilisateurDetail',
        params: {
          id: param,
          step: 'infos',
        },
      });
    },
  },
  mounted() {
  },
  created() {
    this.setTeachers();
    this.setStudents();
    this.setManagers();
    this.setTutors();
    this.initStudenttrainings();
  },
  data() {
    return {
      availableteachers: [],
      availablestudents: [],
      availabletutors: [],
      availablemanagers: [],
      studenttrainings: [],
      componentKey: 0, // variable to force render
      step: 1,
      currentTab: 1,
      tabs: [
        {
          name: 'Formateurs',
          id: '1',
        },
        {
          name: 'Apprenants',
          id: '2',
        },
        {
          name: 'Managers',
          id: '3',
        },
        {
          name: 'Tuteurs',
          id: '4',
        },
      ],
      // variables for table
      isPaginated: true,
      isPaginationSimple: false,
      paginationPosition: 'bottom',
      sortIconSize: 'is-small',
      defaultSortDirection: 'asc',
      currentPage: 1,
      perPage: 25,
      // columns related to checkboxs above table
      studentsColumns: [
        {
          field: 'user',
          label: 'Nom',
          width: '200',
          searchable: true,
          sortable: true,
          visible: true,
        },
        {
          field: 'organization',
          label: 'Organisation',
          centered: true,
          searchable: true,
          sortable: true,
          visible: true,
        },
        // {
        //   field: 'manager',
        //   label: 'Manager(s)',
        //   centered: true,
        //   searchable: true,
        //   sortable: true,
        //   visible: true,
        // },
        // {
        //   field: 'tutor',
        //   label: 'Tuteur(s)',
        //   centered: true,
        //   searchable: true,
        //   sortable: true,
        //   visible: true,
        // },
        {
          field: 'id',
          label: 'Infos',
          numeric: true,
          centered: true,
          searchable: false,
          sortable: true,
          visible: true,
        },
      ],
      managersColumns: [
        {
          field: 'user',
          label: 'Nom',
          width: '200',
          searchable: true,
          sortable: true,
          visible: true,
        },
        {
          field: 'organization',
          label: 'Organisation',
          centered: true,
          searchable: true,
          sortable: true,
          visible: true,
        },
        // {
        //   field: 'student',
        //   label: 'Apprenant(s)',
        //   centered: true,
        //   searchable: true,
        //   sortable: true,
        //   visible: true,
        // },
        // {
        //   field: 'tutor',
        //   label: 'Tuteur(s)',
        //   centered: true,
        //   searchable: true,
        //   sortable: true,
        //   visible: true,
        // },
        {
          field: 'id',
          label: 'Infos',
          numeric: true,
          centered: true,
          searchable: false,
          sortable: true,
          visible: true,
        },
      ],
      tutorsColumns: [
        {
          field: 'user',
          label: 'Nom',
          width: '200',
          searchable: true,
          sortable: true,
          visible: true,
        },
        {
          field: 'organization',
          label: 'Organisation',
          centered: true,
          searchable: true,
          sortable: true,
          visible: true,
        },
        // {
        //   field: 'student',
        //   label: 'Apprenant(s)',
        //   centered: true,
        //   searchable: true,
        //   sortable: true,
        //   visible: true,
        // },
        // {
        //   field: 'tutor',
        //   label: 'Tuteur(s)',
        //   centered: true,
        //   searchable: true,
        //   sortable: true,
        //   visible: true,
        // },
        {
          field: 'id',
          label: 'Infos',
          numeric: true,
          centered: true,
          searchable: false,
          sortable: true,
          visible: true,
        },
      ],
      teachersColumns: [
        {
          field: 'user',
          label: 'Nom',
          searchable: true,
          sortable: true,
          visible: true,
        },
        {
          field: 'organization',
          label: 'Organisation',
          centered: true,
          searchable: true,
          sortable: true,
          visible: true,
        },
        {
          field: 'id',
          label: 'Infos',
          numeric: true,
          centered: true,
          searchable: false,
          sortable: true,
          visible: true,
        },
      ],
      studentUsersColumns: [
        {
          field: 'user',
          label: 'Nom',
          searchable: true,
          sortable: true,
          visible: true,
        },
        {
          field: 'managers_profiles',
          label: 'Managers',
          centered: true,
          searchable: true,
          sortable: true,
          visible: true,
        },
        {
          field: 'tutors_profiles',
          label: 'Tuteurs',
          centered: true,
          searchable: true,
          sortable: true,
          visible: true,
        },
        {
          field: 'id',
          label: 'Infos',
          numeric: true,
          centered: true,
          searchable: false,
          sortable: true,
          visible: true,
        },
      ],
      // array to collect informations from students
      teachersArray: [
      ],
      studentsArray: [
      ],
      managersArray: [
      ],
      tutorsArray: [
      ],
      studentsUsersArray: [
      ],
    };
  },
};
</script>
