import { render, staticRenderFns } from "./ModalImage.vue?vue&type=template&id=645141db&scoped=true"
import script from "./ModalImage.vue?vue&type=script&lang=js"
export * from "./ModalImage.vue?vue&type=script&lang=js"
import style0 from "./ModalImage.vue?vue&type=style&index=0&id=645141db&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "645141db",
  null
  
)

export default component.exports