<template>
  <section class="hero is-success is-fullheight">
    <div class="hero-body">
      <div class="container has-text-centered">
        <div class="column is-4 is-offset-4">
          <h3 class="title has-text-black">Mot de passe oublié ?</h3>
          <hr class="login-hr" />
          <p class="subtitle has-text-black">
            Rien de grave, entrez votre identifiant ou adresse email pour le
            réinitialiser
          </p>
          <b-notification
            v-if="invalidemail === true"
            type="is-danger"
            aria-close-label="Fermer"
            role="alert"
          >
            Êtes-vous sûr·e de votre adresse email ou de votre identifiant ?
            Nous ne le retrouvons pas dans notre base...
          </b-notification>
          <b-notification
            type="is-success"
            aria-close-label="Fermer"
            v-if="passwordreset === true"
          >
            Votre adresse email est reconnue, vous allez recevoir, par email, un
            lien pour réinitialiser votre mot de passe.
          </b-notification>
          <div class="box">
            <figure class="avatar">
              <img src="../assets/img/logo/insitu_logo.png" width="100" />
            </figure>
            <form>
              <div class="field">
                <div class="control">
                  <b-field type="is-success">
                    <b-input
                      v-model="form.email"
                      placeholder="Adresse email"
                    ></b-input>
                  </b-field>
                </div>
              </div>
              <button
                class="button is-block is-info is-large is-fullwidth insitu"
                @click.prevent="resetPassword"
              >
                Réinitialiser <i class="fa fa-sign-in" aria-hidden="true"></i>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      errored: false,
      invalidemail: false,
      passwordreset: false,
      form: {
        email: null,
      },
    };
  },
  methods: {
    resetPassword() {
      this.$http
        .post('/accounts/password-reset/', {
          email: this.form.email,
        })
        .then((response) => {
          if (response.status === 200) {
            this.passwordreset = true;
            setTimeout(() => {
              this.$router.push({
                name: 'Login',
              });
            }, 5000);
          }
        })
        .catch((error) => {
          if (error.response.status === 400) {
            this.invalidemail = true;
          } else {
            this.errored = true;
          }
        });
    },
  },
};
</script>

<style scoped>
html,
body {
  font-family: "Questrial", sans-serif;
  font-size: 14px;
  font-weight: 300;
}
.hero.is-success {
  background: #f2f6fa;
}
.hero .nav,
.hero.is-success .nav {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.box {
  margin-top: 5rem;
}
.avatar {
  margin-top: -70px;
  padding-bottom: 20px;
}
.avatar img {
  padding: 5px;
  background: #fff;
  border-radius: 50%;
  -webkit-box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1),
    0 0 0 1px rgba(10, 10, 10, 0.1);
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
}
input {
  font-weight: 300;
}
p {
  font-weight: 700;
}
p.subtitle {
  padding-top: 1rem;
}

.login-hr {
  border-bottom: 1px solid black;
}

.has-text-black {
  color: black;
}

.field {
  padding-bottom: 10px;
}

.fa {
  margin-left: 5px;
}

.insitu {
  background-color: #521be4 !important;
}
</style>
