 <template>
  <div class="modalbox modalStep AttitudeWaypoint" id="waypointModal">
    <b-loading :is-full-page="isFullPage" v-model="isLoading"> </b-loading>
    <div class="is-flex bigmodal">
      <img
        class="btn-previous"
        :src="require('@/assets/img/arrows/previous.svg')"
        @click="goPreviousPage"
      />
    </div>
    <b-loading :is-full-page="isFullPage" v-model="isLoading"> </b-loading>
    <section class="w-100 container" v-if="waypoint.id">
      <div class="title-paragraph">Point d'étape comportement</div>
      <!-- <div class="justify-content-center is-flex align-items-center mb-5">
        du {{ waypoint.start_date | day }} au
        <span class="mr-2 ml-1"> {{ waypoint.end_date | day }}</span>
      </div> -->
      <div class="columns mt-3">
        <div class="column">
          <b-field
            class="mb-0"
            label="Date de début"
            :type="start_date > end_date ? 'is-danger' : ''"
          >
            <b-datepicker
              v-if="start_date"
              v-model="start_date"
              :first-day-of-week="1"
              placeholder="Cliquez pour sélectionner..."
              trap-focus
              @input="
                verifdate(
                  new Date(waypoint.start_date),
                  new Date(waypoint.end_date)
                )
              "
            >
              <b-button
                label="Aujourd'hui"
                type="is-primary"
                icon-left="calendar-today"
                @click="start_date = new Date()" /></b-datepicker
          ></b-field>
        </div>
        <div class="column">
          <b-field
            class="mb-0"
            label="Date de fin"
            :type="start_date > end_date ? 'is-danger' : ''"
          >
            <b-datepicker
              v-model="end_date"
              :first-day-of-week="1"
              placeholder="Cliquez pour sélectionner..."
              @input="verifdate(waypoint.start_date, waypoint.end_date)"
            >
              <b-button
                label="Aujourd'hui"
                type="is-primary"
                icon-left="calendar-today"
                @click="end_date = new Date()"
              /> </b-datepicker
          ></b-field>
        </div>
      </div>
      <b-message type="is-danger">
        <div v-if="start_date > end_date">
          Attention, il semble y a une incohérence entre la date de début et la
          date de fin. La date de début est postérieure à la date de fin.
        </div>
      </b-message>
      <b-message type="is-warning">
        <div v-if="start_date > new Date()">
          Attention, la date de début est dans le futur.
        </div>
        <div v-if="end_date > new Date()">
          La date de fin n'est pas encore passée. Vérifiez si c'est bien normal.
        </div></b-message
      >
      <div class="title2">Titre du point (facultatif)</div>
      <b-input v-model="formWaypoint.title"></b-input>
      <div class="title2">Objectif(s) de ce point</div>
      <ckeditor
        v-model="formWaypoint.goals"
        :editor="editor"
        :config="editorConfig"
        ref="goals"
      ></ckeditor>
      <div v-if="skills.length" class="has-text-centered">
        <div
          class="mb-5 reportAttitude"
          v-for="(skill, indexSkill) in order(skills)"
          :key="skill.name"
          :name="'question' + indexSkill"
        >
          <div class="is-vcentered justify-space-between skillsEdit">
            <div class="pb-1 pr-5">
              <div style="font-size: 1.2rem" class="mb-2">
                <b>{{ skill.name }}</b>
              </div>
              <div v-html="skill.description"></div>
            </div>
            <div class="pb-1">
              <!-- @input="postEvaluation(level.id, skill.id)" -->
              <div
                class="
                  columns
                  is-mobile
                  align-items-center
                  justify-content-center
                  mt-4
                  mb-4
                  is-multiline
                "
              >
                <div
                  class="
                    column
                    is-narrow
                    columns
                    is-desktop
                    levelsList
                    is-vcentered is-centered
                  "
                  v-for="(level, index) in order(skillsLevelsTraining)"
                  :key="level.id"
                >
                  <b-radio
                    v-model="checkedLevel[indexSkill].attitude_level"
                    :name="'question' + index + indexSkill"
                    :native-value="level.id"
                    class="column mr-3 ml-3"
                  >
                    {{ level.position }}
                  </b-radio>
                </div>
              </div>
            </div>
            <b-collapse
              :open="false"
              :aria-id="'comment' + indexSkill"
              animation="slide"
              class="pb-4"
            >
              <template #trigger="props">
                <a :aria-controls="'comment' + indexSkill">
                  <b-icon
                    :icon="!props.open ? 'menu-up' : 'menu-down'"
                  ></b-icon>
                  {{ !props.open ? "Ajouter un commentaire" : "Fermer" }}
                </a>
              </template>
              <ckeditor
                v-model="checkedLevel[indexSkill].report"
                :editor="editor"
                :config="editorConfig"
                class="mb-5"
                style="min-height: unset"
                :ref="'report'"
              ></ckeditor>
            </b-collapse>
          </div>
        </div>
      </div>
      <div class="title2 pt-5">Commentaires</div>
      <div class="subtitle-paragraph has-text-left">
        Vous pouvez ajouter un commentaire général si vous le souhaitez.
      </div>
      <ckeditor
        v-model="formWaypoint.comment"
        :editor="editor"
        :config="editorConfig"
        :ref="'revisionContent'"
      ></ckeditor>
    </section>
    <!-- <div v-if="skills.length">
      <div
        class="is-flex is-flex-wrap-wrap activityBtns align-items-center"
        style="z-index: 3000"
      >
        <div class="w-100 is-flex">
          <b-button
            class="mx-auto btn-action"
            type="is-success is-rounded"
            rounded
            @click="postAllEvaluation"
            >valider l'évaluation</b-button
          >
        </div>
      </div>
    </div>
    <div v-else>
      <b-message type="is-info">
        Aucune compétence n'est associée à cette expérience, vous n'avez donc
        aucune compétence à évaluer ici.
      </b-message>
    </div> -->
    <div>
      <div
        class="is-flex is-flex-wrap-wrap activityBtns align-items-center"
        style="z-index: 2000"
        v-if="
          currentUser.role === 'tuteur' ||
          currentUser.role === 'formateur' ||
          currentUser.role === 'coordinateur'
        "
      >
        <!-- <b-button @click="$emit('close')" class="mr-auto" rounded
            ><b-icon icon="left" class="mr-3"></b-icon>retour</b-button
          > -->
        <div class="w-100 is-flex">
          <div class="mx-auto">
            <b-button
              @click="patchWaypoint()"
              class="btn-action w-auto mr-1"
              type="is-primary is-rounded"
              rounded
              >enregistrer</b-button
            >
            <!-- @click="validateWaypoint" -->
            <b-button
              v-if="!waypoint.validated"
              @click="validateWaypoint"
              class="btn-action w-auto"
              type="is-success is-rounded"
              rounded
              >valider</b-button
            >
          </div>
        </div>
        <div class="help-btn is-flex align-items-center">
          <b-button
            @click="removeWaypoint(waypoint.id)"
            class="btn-action w-auto mr-2"
            type="is-danger is-rounded"
            size="is-small"
            rounded
            v-if="
              currentUser.role == 'coordinateur' ||
              (currentUser.role == 'formateur' && !waypoint.validated)
            "
            >supprimer</b-button
          >
          <AppHelpMessage
            title="Rédigez et enregistrez votre point comportement"
            message="Vous pouvez renseigner la liste des savoir-être proposés et
             ajouter un commentaire général et/ou un commentaire pour chaque savoir être."
            textBtn="Besoin d'aide ?"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import _ from 'lodash';
import CKEditor from '@ckeditor/ckeditor5-vue2';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import '@ckeditor/ckeditor5-build-classic/build/translations/fr';
import moment from 'moment';
import AppHelpMessage from '@/components/AppHelpMessage.vue';

export default {
  props: {
    // skills: {
    //   type: [Object, Array],
    // },
    role: {
      type: Array,
    }, // framework, training or studenttraining
    skillsLevels: {
      type: [Object, Array],
    },
    studentTraining: {
      type: Number,
    },
    studentSequence: {
      type: Number,
    },
    type: {
      type: String,
    },
  },
  filters: {
    day(date) {
      return moment(date).format('D/MM/YYYY');
    },
  },
  components: {
    ckeditor: CKEditor.component,
    AppHelpMessage,
  },
  data() {
    return {
      start_date: null,
      end_date: null,
      today: new Date(),
      waypoint: [],
      formWaypoint: {
        id: '',
        title: '',
        comment: '',
        start_date: '',
        end_date: '',
        reports: [],
        validated: false,
      },
      isLoading: false,
      isFullPage: false,
      skills: [],
      skillsLevelsTraining: [],
      checkedLevel: [],
      skillsChecked: [],
      editor: ClassicEditor,
      editorDisabled: true,
      editorConfig: {
        language: 'fr',
        toolbar: ['bold', 'italic', 'bulletedList', 'numberedList', '|', 'outdent', 'indent', '|', 'link', '|', 'undo',
          'redo'],
        alignment: {
          options: ['left', 'right'],
        },
        heading: {
          options: [
            { model: 'paragraph', title: 'Styles de texte', class: 'ck-heading_paragraph' },
            {
              model: 'heading1',
              view: 'h1',
              title: 'Titre 1',
              class: 'ck-heading_heading1',
            },
            {
              model: 'heading2',
              view: 'h2',
              title: 'Titre 2',
              class: 'ck-heading_heading2',
            },
            {
              model: 'mark',
              view: 'mark',
              title: 'surligné',
              class: 'ck-mark',
            },
          ],
        },
        table: {
          contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells'],
        },
      },
    };
  },
  computed: {
    ...mapState('auth', ['currentUser']),
  },
  methods: {
    startLoading() {
      this.isLoading = true;
    },
    endLoading() {
      this.isLoading = false;
    },
    order(array) {
      return _.orderBy(array, 'attitude');
    },
    initSkills() {
      const urlWs = `/trainingattitude/?training=${this.$route.params.idTraining}&format=json`;
      this.$http
        .get(`${urlWs}`)
        .then((response) => {
          this.skills = response.data;
          for (let e = 0; e < this.skills.length; e += 1) {
            this.checkedLevel.push(
              {
                attitude: this.skills[e].id,
                attitude_level: '',
                report: '',
              },
            );
          }
          this.$http
            .get(`/trainingattitude_level/?training=${this.$route.params.idTraining}&format=json`)
            .then((resp) => {
              this.skillsLevelsTraining = resp.data;
              this.endLoading();
            }).catch((error) => {
              window.console.log(error);
              this.errored = true;
            });
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
        });
    },
    initWaypoint() {
      const urlWs = `/agenda/student_training_attitude_waypoints/${this.$route.params.idWaypoint}/?format=json`;
      if (this.$route.params.idWaypoint !== 1) {
        this.$http
          .get(`${urlWs}`)
          .then((response) => {
            this.waypoint = response.data;
            this.formWaypoint = this.waypoint;
            this.start_date = new Date(this.formWaypoint.start_date);
            this.end_date = new Date(this.formWaypoint.end_date);
          }).catch((error) => {
            window.console.log(error);
            this.errored = true;
          });
      }
    },
    postAllEvaluation() {
      let read = false;
      if (this.currentUser.role === 'tuteur') {
        read = true;
      }
      this.$http
        .patch(`/agenda/student_training_attitude_waypoints/${this.$route.params.idWaypoint}/`, {
          role: this.roleEvaluation,
          profile: Number(this.currentUser.id),
          student_training: Number(this.$route.params.idStudentTraining),
          reports: this.checkedLevel,
          comment: this.formWaypoint.comment,
          goals: this.formWaypoint.goals,
          title: this.formWaypoint.title,
          validated: true,
          start_date: this.start_date,
          end_date: this.end_date,
          read_by_tutor: read,
        }).then(() => {
          this.$http
            .post('agenda/set_attitude_waypoint', {
              role: this.roleEvaluation,
              // profile: Number(this.currentUser.id),
              student_training: Number(this.$route.params.idStudentTraining),
              reports: this.checkedLevel,
              id: this.$route.params.idWaypoint,
            }).then(() => {
              this.$emit('saveActivity');
              this.$buefy.toast.open({ message: 'Évaluation enregistrée', type: 'is-success' });
              this.goPreviousPage();
              // this.goback();
            }).catch((error) => {
              window.console.log(error);
              this.errored = true;
            });
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
        });
    },
    patchWaypoint() {
      this.$http
        .patch(`/agenda/student_training_attitude_waypoints/${this.$route.params.idWaypoint}/`, {
          role: this.roleEvaluation,
          profile: Number(this.currentUser.id),
          student_training: Number(this.$route.params.idStudentTraining),
          reports: this.checkedLevel,
          comment: this.formWaypoint.comment,
          goals: this.formWaypoint.goals,
          title: this.formWaypoint.title,
          start_date: this.start_date,
          end_date: this.end_date,
        }).then(() => {
          this.$buefy.toast.open({ message: 'Évaluation enregistrée', type: 'is-success' });
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
        });
    },
    goback() {
      this.$router.go(-1);
    },
    goPreviousPage() {
      this.$router.push({
        name: 'StudentTraining',
        params: {
          idStudent: this.$route.params.idStudent, // need check id profile
          idTraining: this.$route.params.idTraining,
          idStudentTraining: this.$route.params.idStudentTraining,
          tab: 'waypoint',
        },
      });
    },
    validateWaypoint() {
      const messageValidation = 'Tuteur et apprenant';
      this.$buefy.dialog.confirm({
        title: 'Souhaitez-vous valider votre point comportement ?',
        message: `Vous êtes sur le point de valider ce point d'étape.
         ${messageValidation} confirment qu'ils ont bien relus le point d'étape`,
        confirmText: 'Valider',
        cancelText: 'Relire',
        type: 'is-success',
        hasIcon: true,
        onConfirm: () => {
          this.postAllEvaluation();
        },
      });
    },
    removeWaypoint(id) {
      const urlWs = `/agenda/student_training_attitude_waypoints/${id}/`;
      this.$buefy.dialog.confirm({
        title: 'Supprimer un point d\'étape',
        message: 'Êtes-vous sûr de vouloir supprimer ce point ? Attention, vous perdrez tout le contenu que vous avez pu rédiger pour ce point.',
        confirmText: 'Supprimer',
        cancelText: 'Annuler',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.$http
            .delete(`${urlWs}`)
            .then(() => {
              this.$buefy.toast.open({ message: 'Point supprimé', type: 'is-success' });
              this.$router.push({
                name: 'StudentTraining',
                params: {
                  idStudent: this.$route.params.idStudent, // need check id profile
                  idTraining: this.$route.params.idTraining,
                  idStudentTraining: this.$route.params.idStudentTraining,
                  tab: 'waypoint',
                },
              });
            }).catch((error) => {
              window.console.log(error);
              this.errored = true;
              this.$buefy.dialog.alert({
                title: 'Mince...',
                message: 'Le point n\'a pas pu être supprimé.',
                type: 'is-info',
                hasIcon: true,
                icon: 'times-circle',
                ariaRole: 'alertdialog',
                ariaModal: true,
                confirmText: 'Ok',
              });
            });
        },
      });
    },
    logActivity() {
      const typeAction = 'écriture waypoint attitude';
      this.$http
        .post('agenda/logs/', {
          action: typeAction,
          student_training: this.$route.params.idStudentTraining,
          training: this.$route.params.idTraining,
          student_activity: null,
          student_sequence: null,
        })
        .then(() => {
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
        });
    },
  },
  created() {
    this.initWaypoint();
    this.startLoading();
    this.initSkills();
    if (this.currentUser.role === 'apprenant' || this.currentUser.role === 'tuteur') { this.logActivity(); }
  },
  mounted() {
  },
};
</script>

<style scoped>
.disabled {
  color: #dfdfeb;
}
#waypointModal.AttitudeWaypoint .ck-content {
  min-height: unset !important;
}
</style>
