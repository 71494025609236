<template>
  <div class="is-flex">
    <!-- eslint-disable max-len-->
    <AppSidebar />
    <div class="main-content">
      <div class="lineCrumbAndAccount">
        <AppBreadcrumb v-bind:crumbs="crumbs" />
        <AppAccountMenu v-bind:appAccount="crumbs" />
      </div>
      <div class="header-page is-flex flex-column">
        <div class="justify-content-between columns is-desktop is-vcentered">
          <div class="column title-page short-title  is_narrow">
            {{ $route.meta.header_title }}
          </div>
          <div class="column is-narrow">
            <div class="columns is-vcentered"
              v-if="currentUser.role === 'coordinateur' && currentUser.cfa === 'École de la convivialité'">
              <div class="column is-narrow">
                <b-datepicker v-model="start_date" :first-day-of-week="1" placeholder="date de début...">
                  <b-button label="Aujourdhui" size="is-small" type="is-primary" icon-left="calendar-today"
                    @click="start_date = new Date()" />

                  <b-button label="Effacer" size="is-small" type="is-danger" icon-left="close" outlined
                    @click="start_date = null" />
                </b-datepicker>
              </div>
              <div class="column is-narrow">
                <b-datepicker v-model="end_date" :first-day-of-week="1" placeholder="date de fin...">
                  <b-button label="Aujourdhui" size="is-small" type="is-primary" icon-left="calendar-today"
                    @click="end_date = new Date()" />

                  <b-button label="Effacer" size="is-small" type="is-danger" icon-left="close" outlined
                    @click="end_date = null" />
                </b-datepicker>
              </div>
              <div class="column is-narrow">
                <b-button class="is-primary" @click="getCoachData(start_date, end_date)"
                  :disabled="!start_date || !end_date">Export
                  global</b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="content-page list-table">
        <!--content page-->
        <section>
          <b-loading :is-full-page="false" v-model="isLoading" :can-cancel="true"></b-loading>
          <section v-if="errored">
            <b-message title="Mince... Une erreur est survenue pendant le chargement des formations" type="is-danger"
              aria-close-label="Close message" v-if="errored">
              Nous sommes sincèrement désolés, nous ne sommes pas en mesure
              d'afficher ces formations pour le moment. Veuillez réessayer
              ultérieurement ou nous contacter via le support.
            </b-message>
          </section>
          <b-collapse v-if="
            currentUser.role !== 'apprenant' && currentUser.role !== 'tuteur'
          " :open="false" class="smallBtnCollapse" animation="slide" aria-id="contentIdForA11y3">
            <div slot="trigger" class="is-flex" slot-scope="props" role="button" aria-controls="visibleColumns"
              v-if="trainings.length">
              <a>
                <b-icon :icon="props.open ? 'menu-down' : 'menu-up'"> </b-icon>
              </a>
              <p class="card-header-title">Options avancées</p>
            </div>
            <div class="card-content">
              <div class="content">
                <b-field grouped group-multiline>
                  <div v-for="(column, index) in columns" :key="index" class="control">
                    <b-checkbox v-model="column.visible">
                      {{ column.label }}
                    </b-checkbox>
                  </div>
                </b-field>
              </div>
            </div>
          </b-collapse>
          <b-table :data="trainings" v-if="trainings.length" :paginated="isPaginated" :per-page="perPage"
            :current-page="currentPage" :pagination-simple="isPaginationSimple"
            :pagination-position="paginationPosition" :sort-icon-size="sortIconSize"
            :default-sort-direction="defaultSortDirection" :debounce-search="1000" default-sort="start_date"
            :hoverable="true" :focusable="true">
            <template v-for="column in columns">
              <b-table-column :key="column.id" v-bind="column">
                <template v-if="column.searchable && !column.numeric" slot="searchable" slot-scope="props">
                  <b-input v-model="props.filters[props.column.field]" placeholder="Rechercher..." icon="search"
                    size="is-small" />
                </template>
                <template v-else-if="column.selectable">
                  <b-select placeholder="Select a name">
                    <option v-for="option in data" :value="option.id" :key="option.id">
                      {{ option.user.first_name }}
                    </option>
                  </b-select>
                </template>
                <template v-slot="props" v-if="column.field === 'sector'">
                  <span v-if="props.row.sector" @click.prevent="
                    verifIfFinished(props.row.id, props.row.finished)
                    " class="is-clickable sector-name"><b-tag size="is-small" type="is-outlined" style="
                        padding: 0.2rem 0.5rem 0.2rem;
                        white-space: normal;
                        height: unset;
                      ">{{ props.row[column.field].name }}</b-tag></span>
                </template>
                <template v-slot="props" v-else-if="column.field === 'name'">
                  <b @click.prevent="
                    verifIfFinished(props.row.id, props.row.finished)
                    " class="is-clickable">
                    {{ props.row[column.field] }}</b>
                </template>
                <template v-slot="props" v-else-if="column.field === 'owner'">
                  {{ props.row[column.field] }}
                </template>

                <template v-slot="props" v-else-if="column.field === 'students'">
                  <div @click.prevent="
                    verifIfFinished(props.row.id, props.row.finished)
                    " class="icon-number is-clickable">
                    <b-icon icon="user" size="is-medium" type="is-black">
                    </b-icon>
                    <b-tag rounded> {{ props.row.students.length }}</b-tag>
                  </div>
                </template>
                <template v-slot="props" v-else-if="column.field === 'start_date'">
                  <span @click.prevent="
                    verifIfFinished(props.row.id, props.row.finished)
                    " class="is-clickable">{{ props.row[column.field] | moment }}</span>
                </template>
                <template v-slot="props" v-else-if="column.field === 'end_date'">
                  <span @click.prevent="
                    verifIfFinished(props.row.id, props.row.finished)
                    " class="is-clickable">{{ props.row[column.field] | moment }}</span>
                </template>
                <template v-slot="props" v-else-if="column.field === 'active'">
                  <div @click.prevent="
                    verifIfFinished(props.row.id, props.row.finished)
                    " class="is-clickable">
                    <span v-if="props.row.active === 1" class="tag is-success">active</span>
                    <span v-if="props.row.active === 0" class="tag inactif">inactive</span>
                  </div>
                </template>
                <template v-slot="props" v-else-if="column.field === 'finished'">
                  <b-tag size="is-small" v-if="props.row.finished" type="is-danger is-light">terminée</b-tag>
                  <b-tag size="is-small" v-else type="is-success is-light">en cours</b-tag>
                </template>
                <template v-slot="props" v-else-if="column.field === 'id'">
                  <div @click.prevent="
                    verifIfFinished(props.row.id, props.row.finished)
                    ">
                    <b-icon icon="see"></b-icon>
                  </div>
                </template>
              </b-table-column>
            </template>
          </b-table>
          <b-message v-else class="mt-5" type="is-info">
            Il n'y a pas de formations disponibles. Contactez le ou la
            responsable de votre organisme pour ajouter de nouvelles formations
            ou vous associer à des formations existantes.
          </b-message>
        </section>
        <!--content-->
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';
import localization from 'moment/locale/fr';
import AppSidebar from '@/components/AppSidebar.vue';
import AppBreadcrumb from '@/components/AppBreadcrumb.vue';
import AppAccountMenu from '@/components/AppAccountMenu.vue';
import _ from 'lodash';

moment.updateLocale('fr', localization);

export default {
  components: {
    AppSidebar,
    AppBreadcrumb,
    AppAccountMenu,
  },
  filters: {
    moment(date) {
      return moment(date).format('D MMMM YYYY');
    },
  },
  computed: {
    ...mapState('auth', ['currentUser']),
    ...mapState(['user']),
    user() {
      return this.$store.state.user;
    },
    users() {
      return this.$store.state.users;
    },
  },
  methods: {
    initTrainings() {
      // this.startLoading();
      this.$http
        .get('/trainings/?query={id,sector,name,students,editable_without_dates,start_date,end_date,finished,active}&format=json')
        .then((response) => {
          this.trainings = response.data;
          if (this.trainings.length === 1 && this.currentUser.role !== 'apprenant') {
            this.$router
              .push({
                name: 'DashboardManagement',
                params: {
                  idTraining: this.trainings[0].id,
                  tabDashboard: 'default',
                },
              });
          }
          if ((this.currentUser.role === 'formateur' && this.user.edit_rights === 4) || this.currentUser.role === 'tuteur' || this.currentUser.role === 'apprenant') {
            this.maskTabs();
          }
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
        });
    },
    verifIfFinished(param, finished) {
      if (finished) {
        this.$buefy.dialog.confirm({
          title: 'Attention, vous vous apprêtez à modifier une période de formation indiquée comme terminée',
          message: 'Est-ce bien ce que vous souhaitez faire ?',
          hasIcon: true,
          cancelText: 'Annuler',
          confirmText: 'Confirmer',
          type: 'is-danger',
          onConfirm: () => {
            this.seeTrainingdetail(param);
          },
        });
      } else {
        this.seeTrainingdetail(param);
      }
    },
    seeTrainingdetail(param) {
      if ((this.currentUser.cfa === 'itii' && this.currentUser.role !== 'apprenant') && (this.currentUser.cfa === 'itii' && this.currentUser.role !== 'tuteur')) {
        this.$router
          .push({
            name: 'DashboardManagement',
            params: {
              idTraining: param,
              tabDashboard: 'validationSteps',
            },
          });
      } else if (this.currentUser.role === 'apprenant') {
        this.$http
          .get(`/agenda/student_training/?training=${param}&student=${this.currentUser.id}&query={id}&format=json`)
          .then((response) => {
            this.studenttraining = response.data[0].id;
            this.$router
              .push({
                name: 'StudentTraining',
                params: {
                  idTraining: param,
                  idStudent: this.currentUser.id,
                  idStudentTraining: this.studenttraining,
                  tab: 'parcours',
                },
              });
          });
      } else if (this.currentUser.role === 'tuteur') {
        this.$router
          .push({
            name: 'Training',
            params: {
              idTraining: param,
              tab: 'suivi',
            },
          });
      } else {
        this.$router
          .push({
            name: 'DashboardManagement',
            params: {
              idTraining: param,
              tabDashboard: 'realtime',
            },
          });
      }
    },
    openLoading() {
      this.isLoading = true;
      if (this.trainings.length) {
        this.isLoading = false;
      }
    },
    startLoading() {
      this.isLoading = true;
    },
    endLoading() {
      this.isLoading = false;
    },
    iniStudentTraining() {
    },
    maskTabs() {
      let tabindex = _.findKey(this.columns, { field: 'students' });
      this.columns[tabindex].visible = false;
      tabindex = _.findKey(this.columns, { field: 'start_date' });
      this.columns[tabindex].visible = false;
      tabindex = _.findKey(this.columns, { field: 'end_date' });
      this.columns[tabindex].visible = false;
      tabindex = _.findKey(this.columns, { field: 'active' });
      this.columns[tabindex].visible = false;
      tabindex = _.findKey(this.columns, { field: 'finished' });
      this.columns[tabindex].visible = false;
    },
    getCoachData(startDate, endDate) {
      this.startLoading();
      const startDateExport = moment(startDate).format('YYYY-MM-DD');
      const endDateExport = moment(endDate).format('YYYY-MM-DD');
      this.$http
        .get(`/executive/get_coachs_data?start_date=${startDateExport}&end_date=${endDateExport}`, {
          responseType: 'blob',
        }).then((response) => {
          const fileURL = window.URL.createObjectURL(new Blob([response.data]));
          const fileLink = document.createElement('a');
          fileLink.href = fileURL;
          fileLink.setAttribute('download', `insitu_stats_${startDateExport}-${endDateExport}.xls`);
          document.body.appendChild(fileLink);
          fileLink.click();
          this.endLoading();
        }).catch(() => {
          this.$buefy.dialog.confirm({
            title: 'Mince...',
            message: 'L\'export n\'a pu être généré',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
            onConfirm: () => document.location.reload(),
          });
        });
    },
  },
  created() {
    this.initTrainings();
  },
  mounted() {
  },
  data() {
    return {
      trainings: [],
      isLoading: false,
      errored: false,
      open: false,
      isPaginated: true,
      isPaginationSimple: false,
      paginationPosition: 'bottom',
      sortIconSize: 'is-small',
      defaultSortDirection: 'desc',
      currentPage: 1,
      perPage: 20,
      crumbs: [
        {
          text: 'Formations',
          href: '/maquettes',
          class: 'is-active',
        },
      ],
      columns: [
        {
          field: 'sector',
          label: 'Secteur',
          selectable: true,
          searchable: true,
          sortable: true,
          visible: true,
        },
        {
          field: 'name',
          label: 'Nom',
          searchable: true,
          sortable: true,
          visible: true,
        },
        {
          field: 'students',
          label: 'Apprenants',
          searchable: false,
          sortable: true,
          visible: true,
          centered: true,
        },
        {
          field: 'start_date',
          label: 'Début',
          centered: true,
          searchable: false,
          sortable: true,
          visible: true,
        },
        {
          field: 'end_date',
          label: 'Fin',
          centered: true,
          searchable: false,
          sortable: true,
          visible: true,
        },
        {
          field: 'active',
          label: 'Statut',
          centered: true,
          searchable: false,
          sortable: true,
          visible: true,
        },
        {
          field: 'finished',
          label: 'Terminé',
          width: '10',
          searchable: false,
          sortable: false,
          visible: true,
        },
        {
          field: 'id',
          label: 'Actions',
          numeric: true,
          centered: true,
          searchable: true,
          visible: true,
        },
      ],
      start_date: null,
      end_date: null,
    };
  },
};
</script>

<style scoped>
.icon {
  cursor: pointer;
}

.loading-overlay {
  background: rgb(255, 255, 255);
}

.sector-name {
  font-size: 0.8rem;
}
</style>
