<template>
  <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
    <div class="is-flex">
      <AppSidebar />
      <div class="main-content">
        <div class="lineCrumbAndAccount">
          <AppBreadcrumb v-bind:crumbs="crumbs" />
          <AppAccountMenu v-bind:appAccount="crumbs" />
        </div>
        <div class="header-page is-flex flex-column">
          <div class="justify-content-between columns lineHeaderTitle">
            <div class="column title-page long-title">
              {{ $route.meta.header_title }}
            </div>
            <div class="column is-narrow actions"></div>
          </div>
        </div>
        <div class="content-page">
          <!--content page-->
          <section v-if="errored">
            <b-message type="is-danger">
              Nous sommes sincèrement désolés, nous ne sommes pas en mesure de
              créer cet utilisateur pour le moment. Veuillez réessayer
              ultérieurement ou nous contacter via le support.
            </b-message>
          </section>
          <div>
            <form>
              <section>
                <ValidationProvider rules="required" v-slot="{ errors, valid }">
                  <b-field
                    label="Prénom de l'utilisateur"
                    :type="{ 'is-danger': errors[0], 'is-success': valid }"
                    :message="errors"
                  >
                    <b-input v-model="form.name"></b-input>
                  </b-field>
                </ValidationProvider>
                <div class="columns">
                  <div class="column">
                    <ValidationProvider
                      rules="required"
                      v-slot="{ errors, valid }"
                    >
                      <b-field
                        label="N° de fiche"
                        :type="{ 'is-danger': errors[0], 'is-success': valid }"
                        :message="errors"
                      >
                        <b-input
                          v-model="form.rncp"
                          placeholder="contenu à déterminer"
                        ></b-input>
                      </b-field>
                    </ValidationProvider>
                  </div>
                </div>
              </section>
              <section>
                <div class="mt-5">
                  <router-link to="/utilisateurs">
                    <b-button type="is-primary" class="mr-2" rounded
                      >Annuler</b-button
                    >
                  </router-link>
                  <b-button
                    v-if="form.frameworktype !== null && form.name !== null"
                    @click="handleSubmit(createUser)"
                    type="is-primary"
                    rounded
                    >Créer l'utilisateur</b-button
                  >
                </div>
              </section>
            </form>
          </div>
          <!--content-->
        </div>
      </div>
    </div>
  </ValidationObserver>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import AppSidebar from '@/components/AppSidebar.vue';
import AppBreadcrumb from '@/components/AppBreadcrumb.vue';
import AppAccountMenu from '@/components/AppAccountMenu.vue';

export default {
  components: {
    AppSidebar,
    AppBreadcrumb,
    AppAccountMenu,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      errored: false,
      form: {
        // name: null,
        // sector: null,
        // rncp_title: null,
        // nomenclature: null,
        // nsf_code: null,
        // qualification: 0,
        // description: null,
        // goals: null,
        // skills: null,
        // frameworktype: null,
        // frameworktypename: null,
        // nb_periods: null,
        // nb_periods_enterprise: null,
        // nb_periods_cfa: null,
      },
      crumbs: [
        {
          text: 'Utilisateurs',
          href: '/utilisateurs',
        },
        {
          text: 'Créer un utilisateur',
          class: 'is-active',
        },
      ],
    };
  },
  methods: {
    clickMe() {
      this.$buefy.notification.open('Validé');
    },
    createUser() {
      this.$http
        .post('/profile/', {
          user: {
            username: this.form.username,
            first_name: this.form.first_name,
            last_name: this.form.last_name,
            email: this.form.email,
          },
          // sector_id: this.form.sector,
          // rncp_title: this.form.rncp,
          // nomenclature_id: this.form.nomenclature,
          // nsf_code_id: this.form.nsfcode,
          // formacodes_id: this.selectedOptions,
          // framework_type_id: this.form.frameworktype,
          // qualification: this.form.qualification,
          // description: this.form.description,
          // goals: this.form.goals,
          // skills: this.form.skills,
          // active: 1,
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
          this.$buefy.dialog.alert({
            title: 'Mince...',
            message: 'Une erreur s\'est produite',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
          });
        });
    },
    // funtion to verify value sent to from
  },
};
</script>

<style scoped>
.tag {
  cursor: pointer;
}
</style>
