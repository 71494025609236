<template>
  <div class="modalbox">
    <div class="is-flex bigmodal">
      <div class="ml-auto">
        <b-button
          @click="goPreviousPage('parcours')"
          type="is-primary"
          rounded
          outlined
          >Revenir au parcours</b-button
        >
      </div>
      <b-button
        class="btn-margins ml-3"
        @click="gotoFrameworkSequenceEdit()"
        type="is-primary"
        rounded
        >modifier</b-button
      >
    </div>

    <div class="container title-modal">
      <div class="subtitle-paragraph mb-0">Détail d'une expérience</div>
      <div class="title-page long-title">
        {{ frameworksequence.name }}
      </div>
    </div>

    <section class="mt-auto pr-0 pl-0" id="header-tabs">
      <div class="b-tabs container">
        <nav class="tabs">
          <ul>
            <li :class="currentTab === 1 ? 'is-active' : ''">
              <a @click="gotoStep(1)"><span>L'expérience</span></a>
            </li>
            <li
              v-for="(activity, indexActivity) in frameworksequence.activities"
              :key="indexActivity"
              :class="currentTab === indexActivity + 2 ? 'is-active' : ''"
            >
              <a @click="gotoActivity(indexActivity)">{{ activity.name }} </a>
            </li>
            <li :class="currentTab === 'resources' ? 'is-active' : ''">
              <a @click="gotoResources"><span>Ressources</span></a>
            </li>
          </ul>
        </nav>
      </div>
    </section>

    <div class="container contentActivite pb-5 mb-5">
      <div v-if="step === 1">
        <!-- <div class="frowho">
          <b-icon icon="user" type="is-primary"></b-icon>
          <div>
            Page à destination de tous les utilisateurs, en lecture seule pour
            l’apprenant, le tuteur et le manager
          </div>
        </div> -->
        <div class="bloc-paragraph mt-5 pt-5">
          <div class="title-paragraph">Présentation de l'expérience</div>
          <div>
            <span class="subtitle-paragraph">Type d'expérience : </span>
            {{ frameworksequence.sequence_type_label }}
          </div>
          <div>
            <span class="subtitle-paragraph">Temps estimé : </span
            >{{ frameworksequence.estimated_duration }}
          </div>
        </div>
        <div class="bloc-paragraph">
          <div class="title-paragraph">
            Présentation, principaux thèmes abordés
          </div>
          <div class="subtitle-paragraph" v-html="sequencetypes.goals"></div>
          <div
            class="card_grey ck"
            v-if="frameworksequence.goals"
            v-html="frameworksequence.goals"
          ></div>
          <b-message type="is-info" v-else> Aucun texte saisi </b-message>
        </div>
        <div class="bloc-paragraph">
          <div class="title-paragraph">Commentaires</div>
          <div class="subtitle-paragraph" v-html="sequencetypes.comments"></div>
          <div
            class="card_grey ck"
            v-if="frameworksequence.comment"
            v-html="frameworksequence.comment"
          ></div>
          <b-message type="is-info" v-else> Aucun texte saisi </b-message>
        </div>
        <Skills
          :typeoftraining="'framework'"
          :sequenceId="Number(this.$route.params.id)"
          :trainingId="Number(this.$route.params.idFramework)"
        />
      </div>
      <div v-if="step == NumberActivity + 2">
        <div class="frowho" v-if="frameworksequence.activities[NumberActivity]">
          <b-icon icon="user" type="is-primary"></b-icon>
          <div>
            {{ frameworksequence.activities[NumberActivity].intro }}
          </div>
        </div>
        <div class="head_activity">L'activité</div>
        <!-- green block with helpful informations-->
        <div
          class="helpUser"
          v-if="frameworksequence.activities[NumberActivity]"
        >
          <div class="title">
            {{ frameworksequence.activities[NumberActivity].title }}
          </div>
          <div>
            {{ frameworksequence.activities[NumberActivity].description }}
          </div>
        </div>
        <!-- activity texte and steps-->
        <div class="card_item cardpage_activity">
          <div
            v-for="(stepActivity, i) in order(
              frameworksequence.activities[NumberActivity].activity_steps
            )"
            :key="i"
            class="mb-5"
          >
            <div class="title-paragraph">{{ stepActivity.name }}</div>
            <div class="subtitle-paragraph">
              <span
                v-if="stepActivity.content"
                v-html="stepActivity.content"
              ></span>
              <span v-else>{{ stepActivity.placeholder }}</span>
            </div>
            <!-- step rights --->
            <div
              class="card_grey"
              v-if="
                frameworksequence.sequence_type != 2 ||
                (frameworksequence.sequence_type === 2 &&
                  stepActivity.position === 3)
              "
            >
              <b-icon icon="user" type="is-primary"></b-icon>
              Ce texte sera à rédiger par
              <span
                v-if="
                  frameworksequence.activities[NumberActivity].context ===
                  'apprenant'
                "
                >l'apprenant</span
              >
              <span v-else>
                le
                {{ frameworksequence.activities[NumberActivity].context }}</span
              >
            </div>
            <div
              class="card_grey"
              v-if="
                (frameworksequence.sequence_type === 2 &&
                  stepActivity.position === 1) ||
                (frameworksequence.sequence_type === 2 &&
                  stepActivity.position === 2)
              "
            >
              <b-icon icon="user" type="is-primary"></b-icon> Ce texte sera à
              rédiger par le formateur
            </div>
            <!-- step rights --->
          </div>
        </div>
      </div>
      <div v-if="step === 'resources'" class="pt-5 mt-5">
        <h1 class="title-paragraph">Ressources associées à cette expérience</h1>
        <div class="helpUser mt-0">
          <div>
            Retrouvez ici la liste des ressources associées à cette expérience.
          </div>
        </div>
        <div v-if="resources.length">
          <div v-for="resource in resources" :key="resource.id">
            <ResourceCard :resource="resource"></ResourceCard>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapState } from 'vuex';
import ResourceCard from '@/components/Resources/ResourceCard.vue';
import Skills from '@/components/Skills/Skills.vue';

export default {
  components: {
    ResourceCard,
    Skills,
  },
  computed: {
    ...mapState('auth', ['currentUser']),
  },
  data() {
    return {
      frameworksequence: [],
      sequencetypes: [],
      currentTab: 1,
      numberStep: 1,
      step: 1,
      tabs: [
        {
          name: 'Fiche technique',
          id: '1',
        },
        {
          name: 'Objectifs',
          id: '2',
        },
        {
          name: 'Compétences',
          id: '3',
        },
        {
          name: 'Parcours',
          id: '4',
        },
        {
          name: 'Préparation des expériences',
          id: '5',
        },
      ],
      NumberActivity: 0,
      ressources: null,
    };
  },
  methods: {
    initFrameworkSequence() {
      // load frameworksequence to get name and activities
      this.$http
        .get(`/sequences/${this.$route.params.id}/?format=json`)
        .then((response) => {
          this.frameworksequence = response.data;
          this.initResources();
          const activites = this.order(this.frameworksequence.activities);
          this.frameworksequence.activities = activites;
          this.initSequencetypes(this.frameworksequence.sequence_type);
          this.endLoading();
        });
    },
    initSequencetypes(id) {
      // load sequences type to get name and infos
      this.$http
        .get(`/sequencetypes/${id}/?query={-activities}&format=json`)
        .then((response) => {
          this.sequencetypes = response.data;
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
          this.$buefy.dialog.alert({
            title: 'Mince...',
            message: 'Une information n\'a pas pu être totalement chargée, nous vous invitons à recharger la page.',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
          });
        });
    },
    gotoFrameworkSequenceEdit() {
      // function to see the sequence page
      this.$router.push({
        name: 'FrameworkSequenceDetailEdit',
        params: { id: this.$route.params.id, idFramework: this.idFramework },
      });
    },
    gotoStep(numberStep) {
      this.currentTab = numberStep;
      this.step = numberStep;
    },
    gotoResources() {
      this.currentTab = 'resources';
      this.step = this.currentTab;
    },
    // go to a specific tab in page with stepNumber param
    goPreviousPage(step) {
      this.$router.push({
        name: 'Maquette',
        params: { id: this.idFramework, tab: step },
      });
    },
    gotoActivity(numberStep) {
      this.currentTab = numberStep + 2;
      this.step = numberStep + 2;
      this.NumberActivity = numberStep;
    },
    order(array) {
      return _.orderBy(array, 'position');
    },
    startLoading() {
      this.isLoading = true;
    },
    endLoading() {
      this.isLoading = false;
    },
    initResources() {
      this.startLoading();
      this.$http.get(`/get_resources/?framework_sequence=${this.frameworksequence.id}&sequence_type=${this.frameworksequence.sequence_type}&format=json`).then((response) => {
        this.resources = response.data.resources;
        this.endLoading();
      });
    },
  },
  created() {
    this.initFrameworkSequence();
    // verification if user come from an another paeg and had to go to a specific tab
    if (this.$route.params.idFramework) {
      this.idFramework = this.$route.params.idFramework;
    }
  },
};
</script>
