<template>
  <div>
    <!-- eslint-disable max-len-->
    <b-modal v-model="isCardModalActive" scroll="keep" id="modalNude">
      <template #default="props">
        <Modalexport @close="props.close" :student.sync="studentName" :student_id.sync="studentId"></Modalexport>
      </template>
    </b-modal>
    <div class="contentActivity tableUsers pt-1">
      <section id="students">
        <b-loading :is-full-page="isFullPage" v-model="isLoading"> </b-loading>
        <div class="columns is-vcentered is-desktop is-multiline">
          <div class="column">
            <div v-if="!studenttrainings.length" class="no-user">
              0 apprenants
            </div>
            <div v-else>
              <div class="userCount mb-4">
                {{ studenttrainings.length }} apprenant<span v-if="studenttrainings.length > 1">s</span>
              </div>
            </div>
            <div class="subtitle-paragraph">
              Suivez l'activité de chaque apprenant.e pour toute la formation
            </div>
          </div>
          <div class="column columns cards-header is-mobil align-items-center">
            <div class="column is-narrow">
              <div class="card_item card_stat_header">
                <div class="is-flex">
                  <div class="stat-cell">
                    <div class="stat_number has-text-primary">
                      {{ this.trainingArray.nb_training_sequences }}
                    </div>
                    <div class="stat_label has-text-primary">
                      expériences<br />proposées
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="column is-narrow">
              <div class="card_item card_stat_header">
                <div class="is-flex">
                  <div class="stat-cell pointer" @click="gotoSkills">
                    <div class="stat_number">
                      {{ this.trainingArray.nb_competences }}
                    </div>
                    <div class="stat_label">compétences<br />à évaluer</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <b-button @click="openStat = !openStat" class="is-small is-rounded mb-3"><span
            v-if="!openStat">afficher</span><span v-else>masquer</span> stats</b-button>
        <div>
          <div v-for="(stat, index) of studentsArray" :key="stat.id" class="card_item card_student is-desktop">
            <div class="columns is-multiline is-vcentered">
              <div class="column name-column columns is-vcenterd">
                <div class="column name_student is-flex align-items-center">
                  <b class="is-clickable mr-3" @click="
      seeStudentTraining(stat.student_id, stat.student_training)
      ">
                    <u> {{ stat.student_profile }}</u></b>
                  <div id="btn-mobile" class="is-flex align-items-center">
                    <div class="mr-2 is-clickable ml-auto" @click="
      (studentId = stat.student_id) &&
      (studentName = stat.student_profile) &&
      (isCardModalActive = true)
      ">
                      <b-icon icon="upload"></b-icon>
                    </div>
                    <b-button @click="
      seeStudentTraining(
        stat.student_id,
        stat.student_training
      )
      " class="btn mr-3" type="is-primary is-small" rounded>
                      Voir
                    </b-button>
                    <b-button rounded @click="
      collapseBlock(
        'd' + index,
        'icon' + index,
        stat.student_training
      )
      " type="is-small">détails</b-button>
                  </div>
                </div>
                <div class="column">
                  <div class="is-flex align-items-center progressbar">
                    <b-progress size="is-medium" type="is-success" :max="stat.sequence_total" class="mb-0">
                      <template #bar>
                        <b-progress-bar :show-value="stat.sequence_done > 0 ? true : false" :value="stat.sequence_done"
                          type="is-success"></b-progress-bar>
                        <b-progress-bar :show-value="stat.sequence_progress > 0 ? true : false
      " :value="stat.sequence_progress" type="is-primary"></b-progress-bar>
                        <b-progress-bar :show-value="stat.sequence_disabled > 0 ? true : false
      " :value="stat.sequence_disabled" type="is-danger"></b-progress-bar>
                      </template>
                    </b-progress>
                    <div id="btn-widescreen" class="is-flex align-items-center ml-auto" style="min-width: 159px">
                      <div class="mr-2 is-clickable ml-auto" @click="
      (studentId = stat.student_id) &&
      (studentName = stat.student_profile) &&
      (isCardModalActive = true)
      ">
                        <b-icon icon="upload"></b-icon>
                      </div>
                      <b-button rounded @click="
      seeStudentTraining(
        stat.student_id,
        stat.student_training
      )
      " type="is-small is-primary" class="ml-3">voir</b-button>
                      <b-button rounded @click="
      collapseBlock(
        'd' + index,
        'icon' + index,
        stat.student_training
      )
      " type="is-small" class="ml-3">détails</b-button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="column columns is-multiline statsLine">
                <div class="is-flex column columns is-vcentered is-multiline">
                  <div class="column is-narrow" v-if="openStat">
                    <div class="
                        stat_cardlabel
                        has-text-primary
                        mb-0
                        is-flex
                        align-items-center
                        justify-content-center
                      ">
                      expériences proposées
                      <AppBigHelpMessage :featureSelected="feature[0]" />
                    </div>
                    <div class="is-flex cards">
                      <div class="card_item card_stat mr-2">
                        <div class="stat-cell has-text-success">
                          <div class="stat_number">
                            {{ stat.sequence_done }}
                            /{{ trainingArray.nb_training_sequences }}
                          </div>
                          <div class="stat_label has-text-success">
                            effectuée<span v-if="stat.sequence_done.length > 1">s</span>
                          </div>
                        </div>
                      </div>
                      <div class="card_item card_stat" style="min-width: 75px">
                        <div class="stat-cell has-text-primary">
                          <div class="stat_number">
                            {{ stat.sequence_progress }}
                          </div>
                          <div class="stat_label">en cours</div>
                        </div>
                      </div>

                      <div class="card_item card_stat" v-if="stat.sequence_disabled">
                        <div class="stat-cell has-text-danger">
                          <div class="stat_number">
                            {{ stat.sequence_disabled }}
                          </div>
                          <div class="stat_label">
                            annulée<span v-if="stat.sequence_disabled.length > 1">s</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="column is-narrow" v-if="openStat">
                    <div class="
                        stat_cardlabel
                        has-text-primary
                        mb-0
                        is-flex
                        align-items-center
                        justify-content-center
                      ">
                      Expérience libre
                      <AppBigHelpMessage :featureSelected="feature[2]" />
                    </div>
                    <div class="is-flex cards">
                      <div class="card_item card_stat mr-2">
                        <div class="stat-cell">
                          <div class="stat_number">
                            {{ stat.free_situations }}
                          </div>
                          <div class="stat_label">
                            ajoutée<span v-if="stat.free_situations.length > 1">s</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="column is-narrow" v-if="openStat">
                    <div class="
                        stat_cardlabel
                        has-text-primary
                        mb-0
                        is-flex
                        align-items-center
                        justify-content-center
                      " style="max-width: 214px">
                      Points périodes
                      <AppBigHelpMessage :featureSelected="feature[3]" />
                    </div>
                    <div class="card_item card_stat mr-2" style="max-width: 214px">
                      <div class="is-flex">
                        <div class="stat-cell">
                          <div class="stat_number">{{ stat.wp_cfa }}</div>
                          <div class="stat_label">CFA</div>
                        </div>
                        <div class="stat-cell">
                          <div class="stat_number">{{ stat.wp_company }}</div>
                          <div class="stat_label">Entreprise</div>
                        </div>
                        <div class="stat-cell">
                          <div class="stat_number">
                            {{ stat.wp_attitude }}
                          </div>
                          <div class="stat_label">Savoir-être</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="column is-narrow" v-if="openStat">
                    <div class="
                        stat_cardlabel
                        has-text-primary
                        mb-0
                        is-flex
                        align-items-center
                        justify-content-center
                      ">
                      Evaluations
                      <AppBigHelpMessage :featureSelected="feature[4]" />
                    </div>
                    <div class="mr-2">
                      <div class="is-flex cards">
                        <div class="card_item card_stat mr-2">
                          <div class="stat-cell">
                            <div class="stat_number">
                              {{ stat.evaluations }}
                            </div>
                            <div class="stat_label">
                              éval.<br />
                              au total
                            </div>
                          </div>
                        </div>
                        <div class="card_item card_stat">
                          <div class="stat-cell">
                            <div class="stat_number pr-2">
                              {{ stat.evaluated_skills }} / {{ stat.skills }}
                            </div>
                            <div class="stat_label">
                              compétence<span v-if="stat.evaluated_skills.length > 1">s</span><br />évaluée<span
                                v-if="stat.evaluated_skills.length > 1">s</span>
                            </div>
                          </div>
                        </div>
                        <div class="card_item card_stat mr-2">
                          <div class="stat-cell">
                            <div class="stat_number">
                              {{ stat.average }}
                            </div>
                            <div class="stat_label">par<br />compétence</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="column is-12 card-content cardClosed" :id="'d' + index">
                <div class="pr-5">
                  <div class="card_grey is-flex align-items-center">
                    Expériences effectuées
                    <div class="icon-collapse ml-auto" :id="'icon' + index" @click="
      collapseBlock(
        'd' + index,
        'icon' + index,
        stat.student_training
      )
      "></div>
                  </div>

                  <div v-for="(activity, index) of order(stat.sequences)" :key="index" class="
                      card-edit-sequence
                      mb-1
                      is-flex
                      align-items-center
                      pointer
                    ">
                    <div class="
                        column
                        bloc-left
                        is-flex
                        mr-auto
                        align-items-center
                      " @click="
      gotoSequence(
        activity.id_student_sequence,
        stat.student_id,
        stat.student_training
      )
      ">
                      <div class="card-sequencetype">
                        <div class="column sequenceType situation mb-0" type="situation"
                          :class="'sequenceType' + activity.type">
                          {{ activity.type }}
                        </div>
                      </div>

                      <div class="column is-12-mobile has-text-centered-mobile">
                        <b> {{ activity.title }}</b>
                      </div>
                      <div class="ml-auto column is-narrow has-text-centered">
                        <span class="tag is-success mr-3">
                          x {{ activity.nb_revisions_sequences }}</span>
                        {{ activity.date_updated | day }}
                      </div>
                    </div>
                  </div>
                  <div v-if="stat.sequences.length < 1" class="card_item card_stat mb-2 has-text-centered">
                    pas d'expérience effectuée
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';
import localization from 'moment/locale/fr';
import _ from 'lodash';
import Modalexport from '@/components/Waypoints/Modalexport.vue';
import AppBigHelpMessage from '@/components/AppBigHelpMessage.vue';

moment.updateLocale('fr', localization);

export default {
  computed: {
    ...mapState('auth', ['currentUser']),
  },
  components: {
    Modalexport,
    AppBigHelpMessage,
  },
  filters: {
    day(date) {
      return moment(date).format('DD/MM/YY');
    },
    hour(date) {
      return moment(date).format('H:mm');
    },
  },
  methods: {
    gotoSkills() {
      this.$emit('messageFromChild', 7);
    },

    gotoStep() {
      window.console.log(this.step);
    },
    initStudenttrainings() {
      this.$http
        .get(`/agenda/student_training/?training=${this.$route.params.idTraining}&query={id,student,organization,student_profile,managers_profiles,tutors_profiles}&format=json`)
        .then((response) => {
          this.studenttrainings = response.data;
          this.getTrainingStats();
          this.createStudentsArray();
        });
    },
    // function to see detailed "ruban pédagogique" by student
    seeStudentTraining(studentId, studentTraining) {
      if (this.$route.name === 'TrainingEdit') {
        this.$router.push({
          name: 'StudentTraining',
          params: {
            idStudent: studentId,
            idTraining: this.$route.params.idTraining,
            idStudentTraining: studentTraining,
            from: this.$route.name,
            tab: 'parcours',
          },
        });
      } else {
        this.$router.push({
          name: 'StudentTraining',
          params: {
            idStudent: studentId,
            idTraining: this.$route.params.idTraining,
            idStudentTraining: studentTraining,
            from: this.$route.name,
            tab: 'parcours',
          },
        });
      }
    },
    getTrainingStats() {
      this.$http
        .get(`get_stats/?training=${this.$route.params.idTraining}`)
        .then((response) => {
          this.trainingArray = response.data;
        });
    },
    getStudentStats(idStudent) {
      this.$http
        .get(`get_stats/?student_training=${idStudent}`)
        .then((response) => {
          window.console.log(response.data);
        });
    },
    createStudentsArray() {
      this.startLoading();
      for (let i = 0; i < this.studenttrainings.length; i += 1) {
        this.$http
          .get(`get_stats/?student_training=${this.studenttrainings[i].id}`)
          .then((response) => {
            const stat = response.data;
            this.nb_activities = stat.nb_activities;
            this.studentsArray.push(
              {
                student_profile: this.studenttrainings[i].student_profile,
                student_id: this.studenttrainings[i].student,
                student_training: this.studenttrainings[i].id,
                activities: stat.nb_activities,
                done: stat.nb_activities_effectuee,
                // progress: stat.nb_activities_en_cours,
                // disabled: stat.nb_activities_desactivee,
                // validated: stat.nb_activities_validee,
                revisions: stat.nb_revisions_sequences,
                free_situations: stat.nb_free_situations_sequences,
                wp_cfa: stat.nb_waypoints_centre,
                wp_company: stat.nb_waypoints_entreprise,
                wp_attitude: stat.nb_attitude_waypoints,
                evaluations: stat.nb_evaluations,
                skills: stat.nb_competences,
                evaluated_skills: stat.nb_competences_evaluees,
                sequence_done: stat.nb_sequences_effectuee,
                average: stat.moyenne_evaluations,
                sequence_total: stat.nb_sequences,
                sequence_progress: stat.nb_sequences_en_cours,
                sequence_disabled: stat.nb_sequences_desactivee,
                sequences: stat.sequences_effectuees,
              },
            ); this.endLoading();
          });
      }
      if (this.studenttrainings.length < 1) {
        this.endLoading();
      }
    },
    order(array) {
      return _.orderBy(array, 'date_updated', 'desc');
    },
    startLoading() {
      this.isLoading = true;
    },
    endLoading() {
      this.isLoading = false;
    },
    collapseBlock(id, icon) {
      this.listActivities = null;
      const element = document.getElementById(id);
      element.classList.toggle('blockOpen');
      const iconOpen = document.getElementById(icon);
      iconOpen.classList.toggle('iconOpen');
      // this.getStudentStats(studentTraining);
    },
    gotoSequence(Sequence, Student, StudentTraining) {
      this.$router.push({
        name: 'StudentSequenceDetail',
        params: {
          idSequence: Sequence,
          idTraining: this.$route.params.idTraining,
          idStudent: Student,
          idStudentTraining: StudentTraining,
          idActivity: 1,
          from: this.$route.params.name,
          stepNumber: this.step,
        },
      });
    },
    exportAll() {
      for (let i = 0; i < this.studenttrainings.length; i += 1) {
        const studentId = this.studenttrainings[i].student;
        const studentName = this.studenttrainings[i].student_profile;
        this.exportLivret(studentId, studentName);
      }
    },
    exportLivret(studentId, studentName) {
      this.$http
        .get(`export_livret/?training_id=${this.$route.params.idTraining}&student_id=${studentId}&done=1`, {
          responseType: 'blob',
        }).then((response) => {
          const username = (studentName).toLowerCase();
          const usernameReplaced = username.replace(' ', '_');
          const fileURL = window.URL.createObjectURL(new Blob([response.data]));
          const fileLink = document.createElement('a');
          fileLink.href = fileURL;
          fileLink.setAttribute('download', `insitu_livret_${usernameReplaced}.pdf`);
          document.body.appendChild(fileLink);
          fileLink.click();
          this.endLoading();
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
          this.$buefy.dialog.confirm({
            title: 'Mince...',
            message: 'Le livret n\'a pu être téléchargé',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
            onConfirm: () => document.location.reload(),
          });
        });
    },
  },
  created() {
    this.initStudenttrainings();
  },
  mounted() {
  },
  data() {
    return {
      openStat: true,
      isCardModalActive: false,
      nb_activities: '',
      trainingArray: [],
      isLoading: false,
      isFullPage: false,
      studenttrainings: [],
      users: [],
      // declaration variables for users tabs
      step: 1,
      currentTab: 1,
      tabs: [
        {
          name: 'Formateurs',
          id: '1',
        },
        {
          name: 'Apprenants',
          id: '2',
        },
        {
          name: 'Managers',
          id: '3',
        },
        {
          name: 'Tuteurs',
          id: '4',
        },
      ],
      // variables for table
      isPaginated: true,
      isPaginationSimple: false,
      paginationPosition: 'bottom',
      sortIconSize: 'is-small',
      defaultSortDirection: 'asc',
      currentPage: 1,
      perPage: 10,
      // columns related to checkboxs above table
      studentsColumns: [
        {
          field: 'student_profile',
          label: 'Nom',
          width: '200',
          searchable: true,
          sortable: true,
          visible: true,
        },
        {
          field: 'status',
          label: 'Statut',
          centered: true,
          searchable: false,
          sortable: true,
          visible: true,
        },
        {
          field: 'id',
          label: 'Parcours',
          centered: true,
          searchable: false,
          sortable: true,
          visible: true,
        },
      ],
      statsColumns: [
        {
          field: 'student_profile',
          label: 'Nom',
          width: '200',
          searchable: true,
          sortable: true,
          visible: true,
        },
        {
          field: 'done',
          label: 'effectuées',
          centered: true,
          visible: true,
        },
        {
          field: 'progress',
          label: 'en cours',
          centered: true,
          visible: true,
        },
        {
          field: 'revisions',
          label: 'Progressions',
          centered: true,
          visible: true,
        },
        {
          field: 'free_situations',
          label: 'Activité libre',
          centered: true,
          visible: true,
        },
        {
          field: 'wp-cfa',
          label: 'Point CFA',
          centered: true,
          visible: true,
        },
        {
          field: 'wp-company',
          label: 'Point entreprise',
          centered: true,
          visible: true,
        },
        {
          field: 'wp-attitude',
          label: 'Point comportement',
          centered: true,
          visible: true,
        },
        {
          field: 'evaluations',
          label: 'Evaluations',
          centered: true,
          visible: true,
        },
      ],
      statsStudents: [
        {
          student_profile: 'Etienne Benech',
          done: '3/16',
          progress: '0/16',
          revisions: 5,
          free_situations: 2,
          wp_cfa: 1,
          wp_company: 2,
          wp_attitude: 0,
          evaluations: 13,
        },
      ],
      // array to collect informations from students
      studentsArray: [
      ],
      // array to clear check
      checkedRows: [],
      studentsIds: [],
      // variables to collapse selec user
      openManagers: false,
      openTutors: false,
      id_manager: 0,
      id_tutor: 0,
      studentId: 0,
      studentName: '',
      feature: [

        {
          intro: 'Qu\'est-ce qu\'une expérience ?',
          img: 'help/help-experiences',
          details: '<p>Une expérience signifie une expérience pédagogique. Elle peut être constituée de plusieurs actions à réaliser. <p>Exemple: La situation choisie comprend plusieurs actions : </p><ul><li>La situation choisie</li><li>L\'activité détaillée</li><li>L\'évaluation tuteur</li></p> <p>Vous retrouvez la liste des expériences proposées dans l\'onglet "Parcours"</p>',
        },
        {
          intro: 'Qu\'est-ce qu\'une action à faire ?',
          img: 'help/help-activites',
          details: '<p>C\'est une étape ou activité d\'une expérience. Chaque action à faire peut impliquer un type de personne différent pour une même expérience, avec des droits d\'écriture ou lecture différents.</p> <p>En cliquant sur une expérience dans le "Parcours", vous voyez la liste des actions proposées.</p>',
        },
        {
          intro: 'Qu\'est-ce qu\'une expérience libre ?',
          img: 'help/help-libre',
          details: '<p><b>C\'est une expérience qui permet une prise de note simple.</b></p><p>On retrouve la liste de ces expériences, dans l\'accordéon "expériences libres", en bas du parcours."</p>',
        },
        {
          intro: 'Qu\'est-ce qu\'un point période ?',
          img: 'help/help-points',
          details: '<p><b>Point CFA : </b> Il s\'agit d\'un point au centre de formation, avec le pilote.</p><p><b>Point entreprise : </b> Il s\'agit d\'un point réalisé en entreprise, avec le tuteur, lors de chaque période en entreprise.</p><p><b>Point savoir-être : </b> Il s\'agit d\'une évaluation du comportement de l\'apprenant, elle se fait en entreprise, moins souvent que les points entreprise</p>. <p>Vous les retrouvez dans l\'onglet "Livret de suivi"</p>',
        },
        {
          intro: 'Qu\'est-ce qu\'une évaluation ?',
          img: 'help/help-evaluations',
          details: '<p>Une évaluation correspond à l\'évaluation d\'une compétence du référentiel. </p><p><b>Une évaluation = une case cochée par questionnaire (cf image ci-dessous)</b></p><p><b>"1/5 Compétences évaluées"</b> = une compétence parmi les 5 compétences du référentiel a été évaluée</p><p><b>"3 en moyenne"</b> = une compétence est évaluée 3 fois en moyenne</p> <p>L\'évaluation des compétences du référentiel se fait pour chaque expérience pédagogique du parcours.</p>',
        },
      ],
      listActivities: [],
      isOpenActivities: null,
    };
  },
};
</script>
<style lang="scss" scoped>
.sequenceType {
  padding: 0.4rem 0.5rem !important;
}

#btn-mobile {
  display: none !important;
}

.card_student {
  padding: 1rem 0.5rem 1rem 2rem;
  margin-bottom: 1rem;
}

.statsLine {
  padding-top: 0rem;

  .column {
    flex: none;
  }
}

.card_stat {
  padding: 0.5rem 0.2rem 0.7rem;
  margin-bottom: 0rem;
}

.stat_cardlabel {
  font-size: 0.75rem;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 1px;
  padding-bottom: 0.5rem;
}

.progressbar {
  max-width: 400px;
}

.name_student {
  padding-bottom: 0px;
}

.stat-cell {
  text-align: center;
  padding: 0 0.5rem;
  border-right: 1px solid #e3e5eb;

  .stat_label {
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 1.1;
  }

  .stat_number {
    font-size: 1.5rem;
  }

  &:last-child {
    border-right: 0px solid #000;
  }
}

.card_stat_header {
  height: 100px;
  padding: 0.5rem 1rem 1rem;
  margin-bottom: 0px;

  .stat_number {
    font-size: 1.7rem;
  }

  .stat_label {
    text-transform: uppercase;
    text-align: center;
    font-size: 0.75rem;
    line-height: 1.3;
  }

  .is-flex {
    height: 100%;
  }

  .stat-cell {
    margin: auto;
  }
}

.cards-header .column:first-child {
  margin-left: auto;
}

.name-column {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  width: 440px;

  b {
    font-size: 1.2rem;
  }
}

.progress-wrapper {
  min-width: 200px;
}

.progressbar {
  padding-top: 0.7rem;
  margin-left: auto;
}

.card_grey {
  padding: 0.5rem 1rem 0.5rem 2rem;
  border-radius: 0.2rem;
}

@media (min-width: 1799px) {
  .name-column {
    flex-direction: column !important;
  }

  .name_student {
    -webkit-box-flex: 0;
    -ms-flex: none;
    flex: none;
    width: 400px;
  }

  .name-colum,
  .statsLine {
    margin-bottom: 0px;
  }

  .card_student {
    padding: 1.5rem 0.5rem 0rem 2rem;
  }
}

@media (max-width: 1800px) {
  .statsLine {
    flex-direction: column !important;
  }

  .name-column {
    -webkit-box-flex: 0;
    -ms-flex: none;
    flex: none;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
  }

  .name_student {
    -webkit-box-flex: 0;
    -ms-flex: none;
    flex: none;
    width: 300px;
  }
}

@media (max-width: 1024px) {
  .cards-header .column:first-child {
    margin-left: 0px;
  }

  .subtitle-paragraph {
    margin-bottom: 0px;
  }
}

@media (max-width: 768px) {
  .statsLine .is-multiline {
    justify-content: center;
  }

  .statsLine .column.is-narrow {
    flex: none;
  }

  #btn-mobile {
    display: flex !important;
    margin-left: auto;
  }

  #btn-widescreen {
    display: none !important;
  }

  .name_student {
    -webkit-box-flex: 0;
    -ms-flex: none;
    flex: none;
    width: 100%;
  }

  .name-column {
    flex-direction: column !important;
    display: block;

    .progressbar {
      margin-right: auto;
      margin-left: 0px;
      max-width: unset;
    }

    .progress-wrapper {
      min-width: 200px;
    }
  }
}

@media screen and (max-width: 768px) {
  .card-edit-sequence .situation {
    padding-top: 0.7rem;
    padding-bottom: 0.7rem;
    display: block;
    margin-bottom: 0.7rem !important;
  }
}

@media (max-width: 750px) {
  .cards .card_item:first-child {
    margin-left: auto;
  }

  .cards .card_item:last-child {
    margin-right: auto !important;
  }
}
</style>
