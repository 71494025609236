<template>
  <div>
    <b-loading :is-full-page="isFullPage" v-model="isLoading"> </b-loading>
    <div
      class="listActivities"
      v-if="
        activities.student_activities ||
        activities.student_activity_revisions ||
        activities.free_situations
      "
    >
      <div class="columns mb-0">
        <div class="column">
          <h1 class="title-paragraph">
            Liste des activités enregistrées dans inSitu
          </h1>
          <div class="subtitle-paragraph mb-0">
            remontent ici toutes les activités créees ou modifiées pendant la
            période sélectionnée
          </div>
        </div>
        <div class="column is-narrow columns cards-header is-mobile">
          <div class="column is-narrow">
            <b-field label="Période concernée" class="mb-0">
              <b-select
                placeholder="Période concernée"
                v-model="rangeSelected"
                @input="changeDate()"
              >
                <option value="twoweeks">Derniers 15 jours</option>
                <option value="month">Dernier mois</option>
                <!-- <option value="all">Toute la formation</option> -->
                <option value="perso">Période personnalisée</option>
              </b-select>
            </b-field>
          </div>
        </div>
      </div>
      <div class="mb-3">
        du <span v-if="start_date">{{ start_date | day }}</span
        ><span v-else>...</span> au
        <span v-if="end_date">{{ end_date | day }}</span
        ><span v-else>...</span>
      </div>
      <div class="columns" v-if="rangeSelected == 'perso'">
        <div class="column">
          <b-datepicker
            v-model="start_date"
            :first-day-of-week="1"
            placeholder="Cliquez pour sélectionner..."
          >
            <b-button
              label="Aujourdhui"
              size="is-small"
              type="is-primary"
              icon-left="calendar-today"
              @click="start_date = new Date()"
            />

            <b-button
              label="Effacer"
              size="is-small"
              type="is-danger"
              icon-left="close"
              outlined
              @click="start_date = null"
            />
          </b-datepicker>
        </div>
        <div class="column">
          <b-datepicker
            v-model="end_date"
            :first-day-of-week="1"
            placeholder="Cliquez pour sélectionner..."
          >
            <b-button
              label="Aujourdhui"
              size="is-small"
              type="is-primary"
              icon-left="calendar-today"
              @click="end_date = new Date()"
            />

            <b-button
              label="Effacer"
              size="is-small"
              type="is-danger"
              icon-left="close"
              outlined
              @click="end_date = null"
            />
          </b-datepicker>
        </div>
        <div class="column">
          <b-button
            type="is-primary"
            @click="getActivities(start_date, end_date)"
            >rechercher</b-button
          >
        </div>
      </div>
      <div class="card-content pt-0">
        <div v-if="activities.student_activities">
          <div v-if="activities.student_activities.length > 0">
            <p class="title-activity has-text-primary">
              Les expériences programmées
            </p>
            <div
              v-for="activity in orderCreated(activities.student_activities)"
              :key="activity.id"
            >
              <div
                class="card-edit-sequence mb-1 is-flex align-items-center pointer card-dashboard"
              >
                <div
                  class="column bloc-left is-flex mr-auto align-items-center"
                  @click="gotoActivity(activity.id, activity.sequence)"
                >
                  <div
                    class="ml-auto column is-narrow has-text-centered pr-2 dates"
                  >
                    <div class="date-creation">
                      <div class="small-text has-text-primary">Créée le :</div>
                      <div>{{ activity.date_created | day }}</div>
                    </div>
                    <div class="date-creation">
                      <div class="small-text has-text-primary">
                        Modifiée le :
                      </div>
                      <div>{{ activity.date_updated | day }}</div>
                    </div>
                  </div>
                  <div class="column is-12-mobile">
                    <div class="titles">
                      <div>
                        <b>{{ activity.sequence_title }}</b>
                      </div>
                      <div v-if="activity.sequence_subtitle">
                        ({{ activity.sequence_subtitle }})
                      </div>
                      <div class="has-text-primary activity-type tag status1">
                        {{ activity.title }}
                      </div>
                    </div>
                  </div>
                  <b-button
                    outlined
                    rounded
                    type="is-primary is-small"
                    class="ml-auto"
                    >voir</b-button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <!---  revision -->
        <div v-if="activities.student_activity_revisions">
          <div v-if="activities.student_activity_revisions.length > 0">
            <p class="title-activity has-text-primary">
              Les expériences refaites
            </p>
            <div
              v-for="revision in orderCreated(
                activities.student_activity_revisions
              )"
              :key="revision.id"
            >
              <div
                class="card-edit-sequence mb-1 is-flex align-items-center pointer"
              >
                <div
                  class="column bloc-left is-flex mr-auto align-items-center"
                  @click="gotoRevision(revision.id, revision.student_sequence)"
                >
                  <div
                    class="ml-auto column is-narrow has-text-centered pr-2 dates"
                  >
                    <div class="date-creation">
                      <div class="small-text has-text-primary">Créée le :</div>
                      <div>{{ revision.date_created | day }}</div>
                    </div>
                    <div class="date-creation">
                      <div class="small-text has-text-primary">
                        Modifiée le :
                      </div>
                      <div>{{ revision.date_updated | day }}</div>
                    </div>
                  </div>
                  <div class="column is-12-mobile">
                    <div class="titles">
                      <div class="mr-3">
                        <b> {{ revision.student_sequence_title }}</b>
                      </div>
                      <div v-if="revision.student_sequence_subtitle">
                        ({{ revision.student_sequence_subtitle }})
                      </div>
                      <div class="has-text-primary activity-type">
                        {{ revision.title }}
                      </div>
                    </div>
                  </div>
                  <b-button
                    outlined
                    rounded
                    type="is-primary is-small"
                    class="ml-auto"
                    >voir</b-button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- free situation -->
        <div v-if="activities.free_situations">
          <div v-if="activities.free_situations.length > 0">
            <p class="title-activity has-text-primary">
              Les expériences libres, non programmées
            </p>
            <div
              v-for="free in orderCreated(activities.free_situations)"
              :key="free.id"
            >
              <div
                class="card-edit-sequence mb-1 is-flex align-items-center pointer"
              >
                <div
                  class="column bloc-left is-flex mr-auto align-items-center"
                  @click="gotoFreeSituation(free.id)"
                >
                  <div
                    class="ml-auto column is-narrow has-text-centered pr-2 dates"
                  >
                    <div class="date-creation">
                      <div class="small-text has-text-primary">Créée le :</div>
                      <div>{{ free.date_created | day }}</div>
                    </div>
                    <div class="date-creation">
                      <div class="small-text has-text-primary">
                        Modifiée le :
                      </div>
                      <div>{{ free.date_updated | day }}</div>
                    </div>
                  </div>
                  <div class="column is-12-mobile">
                    <div class="titles">
                      <div class="mr-3">
                        <b>{{ free.title }}</b>
                      </div>
                    </div>
                  </div>
                  <b-button
                    outlined
                    rounded
                    type="is-primary is-small"
                    class="ml-auto"
                    >voir</b-button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- custom experiences -->
        <div v-if="activities.custom_experiences">
          <div v-if="activities.custom_experiences.length > 0  &&
               currentUser.role !== 'tuteur'">
            <p class="title-activity has-text-primary">
              Notes de carnet de bord
            </p>
            <div
              v-for="custom in orderCreated(activities.custom_experiences)"
              :key="custom.id"
            >
              <router-link
                :to="
                  '/nouvelle-note-edition/' +
                  $route.params.idTraining +
                  '/' +
                  $route.params.idStudentTraining +
                  '/' +
                  $route.params.idStudent +
                  '/' +
                  custom.id
                "
              >
                <div
                  class="card-edit-sequence mb-1 is-flex align-items-center pointer"
                >
                  <div
                    class="column bloc-left is-flex mr-auto align-items-center"
                  >
                    <div
                      class="ml-auto column is-narrow has-text-centered pr-2 dates"
                    ></div>
                    <div class="column is-12-mobile">
                      <div
                        class="titles columns is-tablet align-items-center"
                        style="margin: 0px"
                      >
                        <div class="mr-3">
                          <b class="has-text-black" v-if="custom.title"
                            >{{ custom.title }} ({{
                              custom.start_date | day
                            }})</b
                          >
                          <b class="has-text-black" v-else
                            >Note du {{ custom.start_date | day }}</b
                          >
                        </div>
                        <div class="small-text has-text-primary">
                          Modifiée le : {{ custom.date_updated | day }}
                        </div>
                      </div>
                    </div>

                    <b-button
                      outlined
                      rounded
                      type="is-primary is-small"
                      class="ml-auto"
                      >voir</b-button
                    >
                  </div>
                </div></router-link
              >
            </div>
          </div>
        </div>
      </div>
      <b-message
        v-if="
          !activities.student_activities.length > 0 &&
          !activities.student_activity_revisions.length > 0 &&
          !activities.free_situations.length > 0 &&
          (!activities.custom_experiences.length > 0 ||
          (activities.custom_experiences.length &&
          currentUser.role === 'tuteur'))
        "
        type="is-info"
        >Aucune activité n'a été enregistrée pour la période
        concernée</b-message
      >
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';
import localization from 'moment/locale/fr';
import _ from 'lodash';

moment.updateLocale('fr', localization);

export default ({
  props: {
    training: {
      type: [String, Number],
    },
    student_id: {
      type: [String, Number],
    },
    student_training: {
      type: [String, Number],
    },
    isOpenActivities: {
      type: [Boolean],
    },
  },
  filters: {
    day(date) {
      return moment(date).format('DD/MM/YY');
    },
  },
  data() {
    return {
      isLoading: false,
      isFullPage: false,
      activities: [],
      student_activities_report: [],
      student_revisions_report: [],
      free_situations_report: [],
      isOpenRevision: false,
      isOpenFree: false,
      totalActivities: 0,
      totalRevisions: 0,
      totalFree: 0,
      total: 0,
      rangeSelected: 'twoweeks',
      selected: new Date(),
      showWeekNumber: false,
      locale: 'fr-FR', // Browser locale,
      start_date: new Date(),
      end_date: new Date(),
      twoweeks: new Date(),
      month: new Date(),
      today: new Date(),
    };
  },
  computed: {
    ...mapState('auth', ['currentUser']),
  },
  methods: {
    orderCreated(array) {
      return _.orderBy(array, 'date_created', 'asc');
    },
    orderUpdated(array) {
      return _.orderBy(array, 'date_updated', 'desc');
    },
    startLoading() {
      this.isLoading = true;
    },
    endLoading() {
      this.isLoading = false;
    },
    getActivities(start, end) {
      this.startLoading();
      const startDate = moment(new Date(start)).format('YYYY-MM-DD');
      const endDate = moment(new Date(end)).format('YYYY-MM-DD');
      const urlWs = `/agenda/get_waypoint_activities?student_training=${this.student_training}&start_date=${startDate}&end_date=${endDate}
`;
      this.$http
        .get(`${urlWs}`)
        .then((response) => {
          this.activities = response.data;
          this.totalActivities = this.activities.student_activities.length;
          this.totalRevisions = this.activities.student_activity_revisions.length;
          this.totalFree = this.activities.free_situations.length;
          this.total = this.totalActivities + this.totalRevisions + this.totalFree;
          this.endLoading();
        });
    },
    gotoActivity(id, sequenceId) {
      this.$router.push({
        name: 'StudentSequenceDetail',
        params: {
          idTraining: this.training,
          idStudentTraining: this.student_training,
          // idActivity: id,
          idSequence: sequenceId,
          from: this.$route.name,
          idStudent: this.student_id,
          // idWaypoint: this.$route.params.idWaypoint,
        },
      });
    },
    gotoRevision(id, studentActivity) {
      const sequence = '1';
      this.$router.push({
        name: 'StudentProgressionRead',
        params: {
          idTraining: this.training,
          idStudentTraining: this.student_training,
          idSequence: sequence,
          idActivity: studentActivity,
          idRevision: id,
          from: this.$route.name,
          idStudent: this.student_id,
          // idWaypoint: this.$route.params.idWaypoint,
        },
      });
    },
    gotoFreeSituation(id) {
      this.$router.push({
        name: 'StudentFreeSituationRead',
        params: {
          idTraining: this.training,
          idStudentTraining: this.student_training,
          idFree: id,
          from: this.$route.name,
          idStudent: this.student_id,
          // idWaypoint: this.$route.params.idWaypoint,
        },
      });
    },
    clearDate() {
      this.start_date = null;
    },
    changeDate() {
      if (this.rangeSelected === 'twoweeks') {
        this.start_date = new Date(this.twoweeks);
        this.end_date = this.today;
        this.getActivities(this.start_date, this.end_date);
      }
      if (this.rangeSelected === 'month') {
        this.start_date = new Date(this.month);
        this.end_date = this.today;
        this.getActivities(this.start_date, this.end_date);
      }
    },
    gotoNote(id) {
      this.$router.push({
        name: 'StudentFreeNoteEdit',
        params: {
          idTraining: this.training,
          idStudentTraining: this.student_training,
          idCustom: id,
          from: this.$route.name,
          idStudent: this.student_id,
          // idWaypoint: this.$route.params.idWaypoint,
        },
      });
    },
    logActivity() {
      const typeAction = 'suivi activite';
      this.$http
        .post('agenda/logs/', {
          action: typeAction,
          student_sequence: this.$route.params.idSequence,
          student_activity: this.$route.params.idActivity,
          training: this.$route.params.idTraining,
          student_training: this.$route.params.idStudentTraining,
        })
        .then(() => {
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
        });
    },
  },
  created() {
    // date.setDate(date.getDate() + 7);
    this.twoweeks = this.twoweeks.setDate(this.twoweeks.getDate() - 15);
    this.month = this.month.setMonth(this.month.getMonth() - 1);
    this.start_date = new Date(this.twoweeks);
    this.end_date = this.today;
    this.getActivities(this.twoweeks, this.today);
    if (this.currentUser.role === 'apprenant' || this.currentUser.role === 'tuteur') { this.logActivity(); }
  },
});

</script>

<style scoped lang="scss">
.bg-card {
  padding: 0.2rem;
}
.title-activity {
  margin-top: 2rem;
  margin-bottom: 1rem;
  font-size: 1.1rem;
  font-weight: bold;
}
.card-edit-sequence {
  padding: 1rem;
}
.messagebox {
  font-size: 0.9rem !important;
}
.small-text {
  font-size: 0.8rem;
  margin-right: 0.2rem;
  text-align: left !important;
}
.activity-type {
  font-size: 0.9rem;
}
.date-creation {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 1rem;
}
.status1 {
  font-size: 0.7rem;
  padding: 0rem 0.5rem;
}
@media (max-width: 768px) {
  .card-edit-sequence {
    min-height: 48px;
    background-color: #fff;
    margin-bottom: 0.7rem;
    padding: 1rem;
    border: 1px solid #e3e5eb;
  }
  .card-edit-sequence button {
    display: none;
  }
  .titles {
    font-size: 0.9rem;
    line-height: 1.2;
  }
  .date-creation {
    flex-direction: row;
  }
  .dates {
    display: flex;
    flex-direction: row;
    font-size: 0.85rem;
  }
  .tag {
    margin-bottom: 0.3rem;
    margin-top: 0.5rem;
  }
}
@media (max-width: 390px) {
  .card-dashboard {
    padding: 0.5rem !important;
  }
  .card-header-icon {
    padding: 0px;
  }
  .dates {
    flex-direction: column;
    margin-top: 0.5rem;
    padding-top: 0.5rem !important;
    border-top: 1px solid #d7d7d7;
    order: 4;
    .date-creation {
      flex-direction: row;
      font-size: 0.8rem;
    }
  }
}
@media (max-width: 370px) {
}
</style>
