<template>
  <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
    <section class="hero is-success is-fullheight">
      <div class="hero-body">
        <div class="container has-text-centered">
          <div class="column is-4 is-offset-4">
            <p
              class="title has-text-white"
              style="font-family: 'Questrial', sans-serif"
            >
              Changer le mot de passe
            </p>
            <section v-if="errored">
              <b-notification
                type="is-danger is-light"
                aria-close-label="Fermer la notification"
                role="alert"
              >
                <span style="font-size: 1.2rem">
                  Il semblerait que votre code d'autorisation a expiré. Vous
                  pouvez
                  <strong @click.prevent="forgotLogin"
                    >réinitialiser votre mot de passe.</strong
                  >
                  une nouvelle fois.
                </span>
              </b-notification>
            </section>
            <div class="box">
              <figure class="avatar">
                <img src="../assets/img/logo/insitu_logo.png" width="100" />
              </figure>
              <form v-on:keyup.enter="handleSubmit(changePassword)">
                <ValidationProvider
                  rules="required"
                  vid="password"
                  name="Mot de passe"
                  v-slot="{ errors, valid }"
                >
                  <b-field
                    :type="{ 'is-danger': errors[0], 'is-success': valid }"
                    :message="errors"
                  >
                    <b-input
                      placeholder="Votre nouveau mot de passe"
                      type="password"
                      v-model="form.password"
                      value=""
                      password-reveal
                    >
                    </b-input>
                  </b-field>
                </ValidationProvider>
                <ValidationProvider
                  rules="required|confirmed:password"
                  vid="passwordconfirmation"
                  name="Confirmation du mot de passe"
                  v-slot="{ errors, valid }"
                >
                  <b-field
                    :type="{ 'is-danger': errors[0], 'is-success': valid }"
                    :message="errors"
                  >
                    <b-input
                      placeholder="Confirmer le nouveau mot de passe"
                      type="password"
                      v-model="form.passwordconfirmation"
                      value=""
                      password-reveal
                    >
                    </b-input>
                  </b-field>
                </ValidationProvider>
                <div class="is-flex justify-content-between align-items-center">
                  <router-link to="/authentification">
                    <b-button type="is-primary">Annuler</b-button>
                  </router-link>
                  <b-button
                    v-if="
                      form.password !== null &&
                      form.passwordconfirmation == form.password
                    "
                    type="is-primary"
                    @click.prevent="handleSubmit(changePassword)"
                  >
                    Changer le mot de passe
                    <i class="fa fa-sign-in" aria-hidden="true"></i>
                  </b-button>
                </div>
              </form>
            </div>
            <div class="password-requirements">
              <p class="password-requirement">
                Votre mot de passe doit respecter les critères suivants :
              </p>
              <ul>
                <li class="bullet-custom">
                  Votre mot de passe doit contenir au moins 8 caractères.
                </li>
                <li class="bullet-custom">
                  Votre mot de passe ne peut pas être trop similaire à vos
                  autres informations personnelles.
                </li>
                <li class="bullet-custom">
                  Votre mot de passe ne peut pas être un mot de passe couramment
                  utilisé.
                </li>
                <li class="bullet-custom">
                  Votre mot de passe ne peut pas être entièrement numérique.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  </ValidationObserver>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { mapState, mapActions } from 'vuex';

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      errored: false,
      form: {
        token: null,
        password: null,
      },
    };
  },
  computed: {
    ...mapState('auth', ['currentUser']),
  },
  methods: {
    ...mapActions('auth', ['getUserdetails']),
    changePassword() {
      const urlParams = new URLSearchParams(window.location.search);
      const resetToken = urlParams.get('token');
      this.$http
        .post('/accounts/password-reset/confirm/', {
          token: resetToken,
          password: this.form.password,
        })
        .then(() => {
          this.$router.push({
            name: 'Login',
          });
        })
        .catch((error) => {
          this.errored = true;
          this.errorMessage = error.response.data.password;
          this.form.password = null;
          this.form.token = null;
          this.form.password.confirmation = null;
        });
    },
    forgotLogin() {
      this.$router
        .push({
          name: 'ResetPassword',
        });
    },
  },
};
</script>

<style scoped>
html,
body {
  font-family: "Questrial", sans-serif;
  font-size: 14px;
  font-weight: 300;
}
.hero.is-success {
  background: #521be4;
}
.hero .nav,
.hero.is-success .nav {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.box {
  margin-top: 5rem;
}
.avatar {
  margin-top: -70px;
  padding-bottom: 20px;
}
.avatar img {
  padding: 5px;
  background: #fff;
  border-radius: 50%;
  -webkit-box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1),
    0 0 0 1px rgba(10, 10, 10, 0.1);
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
}
input {
  font-weight: 300;
}
p {
  font-weight: 700;
}
p.subtitle {
  padding-top: 1rem;
}

.login-hr {
  border-bottom: 1px solid black;
}

.has-text-black {
  color: black;
}

.field {
  padding-bottom: 10px;
}

.fa {
  margin-left: 5px;
}

.insitu {
  background-color: #521be4 !important;
}

.password-requirements {
  text-align: left;
}

.bullet-custom {
  list-style-type: disc;
  margin-bottom: 10px; /* Espacement entre chaque élément de la liste */
}

.password-requirement {
  font-size: 1.2rem;
  margin-bottom: 1.5rem;
}
</style>
