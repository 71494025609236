<template>
  <div class="is-flex">
    <AppSidebar />
    <b-loading :is-full-page="isFullPage" v-model="isLoading"> </b-loading>
    <div class="main-content">
      <div class="lineCrumbAndAccount ml-auto">
        <AppAccountMenu v-bind:appAccount="crumbs" />
      </div>
      <div class="content-page pt-5 pl-0 fixed">
        <div class="columns is-desktop">
          <div class="column is-3 col-questions">
            <div class="">
              <div>
                <!-- <h2 class="faq-category">{{ category.name }}</h2> -->
                <b-collapse
                  class=""
                  animation="slide"
                  v-for="(category, index) in categories"
                  :key="index"
                  :open="isOpen == index"
                  @open="isOpen = index"
                >
                  <template #trigger="props">
                    <div class="is-flex align-items-center" role="button">
                      <a class="icon">
                        <b-icon :icon="props.open ? 'menu-down' : 'menu-up'">
                        </b-icon>
                      </a>
                      <p v-if="category" class="faq-category">
                        {{ category.name }}
                      </p>
                    </div>
                  </template>
                  <div class="card-content">
                    <div
                      v-for="question in category.questions"
                      @click="questionOpen = question"
                      :key="question.id"
                      class="mb-5 link pointer"
                    >
                      {{ question.question }}
                    </div>
                  </div>
                </b-collapse>
              </div>
            </div>
          </div>
          <div class="column col-detail">
            <div class="col-content">
              <div class="title-page short-title">
                {{ $route.meta.header_title }}
              </div>
              <div v-if="questionOpen.question">
                <div class="title">{{ questionOpen.question }}</div>
                <div class="content" v-html="questionOpen.answer"></div>
              </div>
              <div v-else>
                <div class="title" v-if="categories[0]">
                  {{ categories[0].questions[0].question }}
                </div>
                <div
                  class="content"
                  v-if="categories[0]"
                  v-html="categories[0].questions[0].answer"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import AppAccountMenu from '@/components/AppAccountMenu.vue';
import AppSidebar from '@/components/AppSidebar.vue';

export default ({
  components: {
    AppAccountMenu,
    AppSidebar,
  },
  data() {
    return {
      categories: [],
      isLoading: false,
      isFullPage: false,
      isOpen: 250,
      crumbs: [{
        text: 'Maquettes',
        href: '/maquettes',
        class: 'is-active',
      },
      ],
      questionOpen: [],
    };
  },
  mounted() {
    axios.get('https://insituapp.io/api/wiki/faq')
      .then((response) => {
        this.categories = response.data;
      })
      .catch((error) => {
        window.console.log(error);
        this.errored = true;
      });
  },
});
</script>

<style scoped>
.short-title.title-page {
  font-size: 2rem;
  margin-bottom: 2.4rem;
}
.content {
  margin-bottom: 2rem;
}
.faq-category {
  font-size: 1.2rem;
  font-weight: 700;
  margin-bottom: 0.2rem;
  margin-top: 1rem;
  color: #531be4;
  line-height: 1;
}
.title {
  font-weight: 700;
  color: #531be4;
  margin-bottom: 3rem;
  font-size: 1.6rem;
}
.col-questions {
  /* border-right: 1px solid #c0bfbf; */
  padding-right: 2rem;
  width: 20vw !important;
  /* background-color: #fafafa; */
  font-size: 0.95rem;
  padding-left: 2rem;
  line-height: 1.3;
  color: #4a4a4a;
  overflow: auto;
  height: 100vh;
}
.card-content {
  padding-top: 0.5rem;
  padding-left: 1.5rem;
}
.col-detail {
  /* max-width: 990px; */
  width: 100%;
  padding-left: 3rem;
  padding-right: 3rem;
  overflow: auto;
  border-left: 1px solid #c0bfbf;
  height: 100vh;
  color: #4a4a4a;
}
.fixed {
  position: fixed;
  /* overflow: auto; */
  /* width: 15vw; */
  /* max-height: 100%; */
}
.col-detail .col-content {
  max-width: 990px;
}
.icon {
  margin-top: 0.3rem;
}
.link:hover {
  color: #531be4;
}
@media (max-width: 1110px) {
  .faq-category {
    font-size: 1rem;
    margin-bottom: 0.3rem;
  }
  .icon {
    margin-top: 0.3rem;
  }
  .col-questions {
    font-size: 0.9rem;
    padding: 1rem;
  }
  .card-content {
    padding-top: 0.5rem;
  }
}
@media (max-width: 990px) {
  .columns {
    flex-direction: column;
  }
  .col-detail,
  .col-questions {
    width: 100% !important;
    position: relative;
    height: auto;
  }
  .col-questions {
    padding-left: 3rem;
  }
  .col-detail {
    padding-right: 1rem;
  }
}
</style>
