<template>
  <div>
    <!-- <b-loading :is-full-page="isFullPage" v-model="isLoading"> </b-loading> -->
    <div class="table-wrapper has-mobile-cards" v-if="trainingSteps.data">
      <table class="table w-100">
        <tbody>
          <tr draggable="false" class="">
            <td class="checkbox-cell" style="width: 213px; min-width: 213px">
              <b class="is-clickable"> Etapes du livret</b>
            </td>
            <td data-label="Livret" class="">
              <div class="bookletSteps is-flex">
                <div
                  v-for="(waypoint, index) in trainingSteps.data[0].data"
                  :key="waypoint.id"
                  class="bookletStep"
                >
                  <router-link
                    :to="
                      '/formations/' +
                      $route.params.idTraining +
                      '/suivi/stepToValidate' +
                      '/' +
                      waypoint.id
                    "
                  >
                    <div
                      @click="initStep(waypoint.id)"
                      :class="
                        waypoint.id === Number($route.params.idStep)
                          ? 'selected'
                          : ''
                      "
                      class="is-clickable"
                    >
                      <b-tooltip
                        :label="waypoint.part_name"
                        position="is-bottom"
                        multilined
                        type="is-primary is-light"
                      >
                        <div v-if="currentUser.role">
                          <div
                            v-if="waypoint.status === 2"
                            alt=""
                            class="steppoint done"
                            :class="
                              waypoint.editable.includes(currentUser.role)
                                ? 'me'
                                : ''
                            "
                          >
                            {{ index + 1 }}
                          </div>
                          <div
                            v-else-if="waypoint.status === 1"
                            alt=""
                            class="steppoint now"
                            :class="
                              waypoint.editable.includes(currentUser.role)
                                ? 'me'
                                : ''
                            "
                          >
                            {{ index + 1 }}
                          </div>
                          <div
                            v-else-if="waypoint.status === 0"
                            class="steppoint later"
                            :class="
                              waypoint.editable.includes(currentUser.role)
                                ? 'me'
                                : ''
                            "
                          >
                            {{ index + 1 }}
                          </div>
                        </div>
                      </b-tooltip>
                    </div></router-link
                  >
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="columns is-vcentered">
      <div class="column">
        <div class="title-paragraph">
          {{ actualStep.part_name }}
        </div>
      </div>
    </div>
    <b-collapse
      :open="false"
      class="smallBtnCollapse"
      animation="slide"
      aria-id="contentIdForA11y3"
      v-if="actualStep.data"
    >
      <div
        slot="trigger"
        class="is-flex"
        slot-scope="props"
        role="button"
        aria-controls="visibleColumns"
      >
        <a>
          <b-icon :icon="props.open ? 'menu-down' : 'menu-up'"> </b-icon>
        </a>
        <p class="card-header-title">Options avancées</p>
      </div>
      <div class="card-content">
        <div class="content">
          <b-field grouped group-multiline>
            <div
              v-for="(column, index) in actualStep.columns"
              :key="index"
              class="control"
            >
              <b-checkbox v-model="column.visible">
                {{ column.label }}
              </b-checkbox>
            </div>
          </b-field>
        </div>
      </div>
    </b-collapse>
    <div class="contentTable">
      <b-table
        :data="actualStep.data"
        checkable
        :checkbox-position="'left'"
        :checkbox-type="'is-primary'"
        :default-sort="['name', 'asc']"
        :checked-rows.sync="checkedRows"
        :key="componentKey"
        :loading="isLoading"
        class="pb-5"
        focusable
      >
        <template v-for="(column, index) in actualStep.columns">
          <b-table-column :key="column.id" v-bind="column">
            <template
              v-if="column.searchable && !column.numeric"
              slot="searchable"
              slot-scope="props"
            >
              <b-input
                v-model="props.filters[props.column.field]"
                placeholder="Rechercher..."
                icon="search"
                size="is-small"
              />
            </template>
            <template v-slot="props" v-if="column.field === 'name'">
              <router-link
                v-if="actualStep.data[0].data[0].type === 'waypoint'"
                :to="
                  '/validation-edition/' +
                  props.row.student_training_id +
                  '/' +
                  $route.params.idTraining +
                  '/' +
                  props.row.student_id +
                  '/' +
                  actualStep.data[0].data[0].id
                "
              >
                <b class="is-clickable">
                  {{ props.row[column.field] }}</b
                ></router-link
              >
              <router-link
                v-else
                :to="
                  '/livret/' +
                  props.row.student_training_id +
                  '/' +
                  $route.params.idTraining +
                  '/' +
                  props.row.student_id +
                  '/' +
                  actualStep.data[0].data[0].id
                "
              >
                <b class="is-clickable">
                  {{ props.row[column.field] }}</b
                ></router-link
              >
            </template>
            <template v-slot="props" v-else-if="column.field === 'teachers'">
              <!-- {{ props.row[column.field] }} -->
              {{ props.row[column.field].replace("Directeur Etudes", "") }}
            </template>
            <template v-slot="props" v-else-if="column.field === 'tutors'">
              {{ props.row[column.field] }}
            </template>

            <template v-slot="props" v-else-if="column.field == 'validations'">
              <router-link
                v-if="actualStep.data[0].data[0].type === 'waypoint'"
                :to="
                  '/validation-edition/' +
                  props.row.student_training_id +
                  '/' +
                  $route.params.idTraining +
                  '/' +
                  props.row.student_id +
                  '/' +
                  actualStep.data[0].data[0].id
                "
              >
                <div>
                  <div v-if="props.row['data'][0].status !== 7">
                    <span v-if="props.row['data'][0]['validations'][index - 3]">
                      <b-tag
                        rounded
                        class="is-success is-clickable"
                        v-if="
                          props.row['data'][0]['validations'][index - 3]
                            .status_label === 'Valide'
                        "
                      >
                        {{
                          props.row["data"][0]["validations"][index - 3]
                            .status_label
                        }}</b-tag
                      >
                      <b-tag
                        rounded
                        class="is-danger"
                        v-else-if="
                          props.row['data'][0]['validations'][index - 3]
                            .status_label === 'Non valide'
                        "
                        >Refusé</b-tag
                      >
                      <b-tag
                        rounded
                        class="is-danger is-light"
                        v-else-if="
                          props.row['data'][0]['validations'][index - 3]
                            .status_label === 'Annulé'
                        "
                        >Suspendu</b-tag
                      >
                      <b-tag v-else class="is-secondary is-outlined">{{
                        props.row["data"][0]["validations"][index - 3]
                          .status_label
                      }}</b-tag></span
                    >
                  </div>
                  <div
                    v-else
                    class="is-flex align-items-center justify-content-center"
                  >
                    <img
                      :src="require(`@/assets/img/steps/status7.svg`)"
                      alt=""
                      class="img_status mr-1"
                    /><b-tag type="is-danger is-light">Litige</b-tag>
                  </div>
                </div>
              </router-link>
              <router-link
                v-else
                :to="
                  '/livret/' +
                  props.row.student_training_id +
                  '/' +
                  $route.params.idTraining +
                  '/' +
                  props.row.student_id +
                  '/' +
                  actualStep.data[0].data[0].id
                "
              >
                <div>
                  <div v-if="props.row['data'][0].status !== 7">
                    <span v-if="props.row['data'][0]['validations'][index - 3]">
                      <b-tag
                        rounded
                        class="is-success is-clickable"
                        v-if="
                          props.row['data'][0]['validations'][index - 3]
                            .status_label === 'Valide'
                        "
                      >
                        {{
                          props.row["data"][0]["validations"][index - 3]
                            .status_label
                        }}</b-tag
                      >
                      <b-tag
                        rounded
                        class="is-danger"
                        v-else-if="
                          props.row['data'][0]['validations'][index - 3]
                            .status_label === 'Non valide'
                        "
                        >Refusé</b-tag
                      >
                      <b-tag v-else class="is-secondary is-outlined">{{
                        props.row["data"][0]["validations"][index - 3]
                          .status_label
                      }}</b-tag></span
                    >
                  </div>
                  <div
                    v-else
                    class="is-flex align-items-center justify-content-center"
                  >
                    <img
                      :src="require(`@/assets/img/steps/status7.svg`)"
                      alt=""
                      class="img_status mr-1"
                    /><b-tag type="is-danger is-light">Litige</b-tag>
                  </div>
                </div>
              </router-link>
            </template>
            <template
              v-slot="props"
              v-else-if="column.field === 'id_student_training'"
            >
              {{ props.row.i }}

              <div>
                <router-link
                  v-if="actualStep.data[0].data[0].type === 'waypoint'"
                  :to="
                    '/validation-edition/' +
                    props.row.student_training_id +
                    '/' +
                    $route.params.idTraining +
                    '/' +
                    props.row.student_id +
                    '/' +
                    actualStep.data[0].data[0].id
                  "
                >
                  <b-icon icon="see"></b-icon
                ></router-link>
                <router-link
                  v-else
                  :to="
                    '/livret/' +
                    props.row.student_training_id +
                    '/' +
                    $route.params.idTraining +
                    '/' +
                    props.row.student_id +
                    '/' +
                    actualStep.data[0].data[0].id
                  "
                >
                  <b-icon icon="see"></b-icon
                ></router-link>
              </div>
            </template>
          </b-table-column>
        </template>
      </b-table>
    </div>
    <b-button
      type="is-primary"
      :disabled="!checkedRows.length"
      @click="isComponentModalActive = true"
      >notifier</b-button
    >
    <b-modal
      v-model="isComponentModalActive"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-label="Example Modal"
      close-button-aria-label="Close"
      aria-modal
    >
      <template>
        <form action="">
          <div class="modal-card" style="width: auto">
            <header class="modal-card-head">
              <p class="modal-card-title">
                Rappeler aux utilisateurs de faire leur activité
              </p>
            </header>
            <section class="modal-card-body">
              <div class="mb-0 mt-1 subtitle-paragraph">
                <b>Rôle à notifier</b>
              </div>
              <div class="subtitle-paragraph mb-4">
                (seuls les rôles dont l'activité n'a pas été effectuée seront
                contactés)
              </div>
              <div>
                <div>
                  <b-checkbox v-model="roles" native-value="apprenant"
                    >Apprenant</b-checkbox
                  >
                </div>
                <div>
                  <b-checkbox v-model="roles" native-value="tuteur"
                    >Tuteur</b-checkbox
                  >
                </div>
                <!-- <div><b-checkbox>Directeur des études</b-checkbox></div> -->
              </div>
              <!-- <b-field label="Email">
                <b-input
                  type="email"
                  :value="formProps.email"
                  placeholder="Your email"
                  required
                >
                </b-input>
              </b-field>

              <b-field label="Password">
                <b-input
                  type="password"
                  :value="formProps.password"
                  password-reveal
                  placeholder="Your password"
                  required
                >
                </b-input>
              </b-field> -->
              <div class="mb-4 mt-5 subtitle-paragraph">
                <b>Les notifier par</b>
              </div>
              <div>
                <b-checkbox v-model="channels" native-value="notification"
                  >Message dans l'appli</b-checkbox
                >
              </div>
              <div>
                <b-checkbox v-model="channels" native-value="mail"
                  >Mail</b-checkbox
                >
              </div>
            </section>
            <footer class="modal-card-foot">
              <b-button
                label="Annuler"
                @click="isComponentModalActive = false"
              />
              <b-button
                label="Envoyer"
                type="is-primary"
                @click="postNotification"
              />
            </footer>
          </div>
        </form>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    typeoftraining: {
      type: String,
    }, // framework, training or studenttraining
    objectId: {
      type: Number,
    },
    contentWidth: {
      type: Number,
    },
  },
  computed: {
    ...mapState('auth', ['currentUser']),
    trainingSteps() {
      return this.$store.state.trainingSteps;
    },
  },
  components: {
  },
  data() {
    return {
      isLoading: false,
      isFullPage: false,
      isComponentModalActive: false,
      formProps: {
        email: 'evan@you.com',
        password: 'testing',
      },
      actualStep: [],
      checkedRows: [],
      listNotifications: [],
      channels: [],
      roles: [],
      componentKey: 0,
      tableisOpen: true,
    };
  },
  methods: {
    startLoading() {
      this.isLoading = true;
    },
    endLoading() {
      this.isLoading = false;
    },
    gotoWaypointEdit(studentId, studentTraining, part) {
      this.$router.push({
        name: 'WaypointitiiEdit',
        params: {
          idTraining: this.objectId,
          idStudent: studentId,
          idStudentTraining: studentTraining,
          // idWaypoint: waypointId,
          simple_booklet_part_validation: part,
          from: 'DashboardManagement',
        },
      });
    },
    seeStudentTraining(studentId, studentTraining) {
      this.$router.push({
        name: 'StudentTraining',
        params: {
          idStudent: studentId,
          idTraining: this.$route.params.idTraining,
          idStudentTraining: studentTraining,
          tab: 'waypoint',
        },
      });
    },
    initActualStep() {
      this.startLoading();
      this.$http
        .get(`/get_training_booklet_validations/${this.$route.params.idTraining}?step=current`)
        .then((response) => {
          this.actualStep = response.data;
          // this.gotoStep(this.actualStep.part_id);
          const actions = this.actualStep.columns.find((column) => column.label === 'Actions');
          actions.visible = false;
          this.endLoading();
        }).catch((error) => {
          window.console.log(error);
        });
    },
    gotoStep(stepId) {
      this.$router.push({
        name: 'DashboardManagement',
        params: {
          tabDashboard: 'stepToValidate',
          idTraining: this.$route.params.idTraining,
          idStep: stepId,
        },
      }).catch((error) => {
        window.console.log(error);
      });
      this.$emit('tabChange', 'stepToValidate');
    },
    initStep(idStep) {
      this.startLoading();
      this.$http
        .get(`/get_training_booklet_validations/${this.$route.params.idTraining}?step=${idStep}`)
        .then((response) => {
          this.actualStep = response.data;
          const actions = this.actualStep.columns.find((column) => column.label === 'Actions');
          actions.visible = false;
          this.endLoading();
          this.forceRerender();
          // setTimeout(() => {
          //   this.forceRerender();
          // }, 100);
        }).catch((error) => {
          window.console.log(error);
        });
    },
    forceRerender() {
      this.componentKey += 1;
    },
    postNotification() {
      const studenttrainings = this.checkedRows.map((st) => st.student_training_id);
      this.$http
        .post('/notifications/send_reminder/', {
          student_trainings: studenttrainings,
          part: this.actualStep.data[0].data[0].id,
          roles: this.roles,
          channels: this.channels,
        }).then(() => {
          this.$buefy.toast.open({
            message: 'Le message a bien été envoyé',
            type: 'is-success',
            duration: 1500,
          });
          this.isComponentModalActive = false;
        }).catch((error) => {
          window.console.log(error);
          this.$buefy.dialog.alert({
            title: 'Mince...',
            message: 'Votre notification n\'a pas pu être envoyée',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
          });
        });
    },
    initSteps() {
      this.tableisOpen = false;
      this.startLoading();
      this.$http
        .get(`/get_training_booklet_validations/${this.$route.params.idTraining}`)
        .then((response) => {
          // this.trainingSteps = response.data;
          this.$store.commit('GET_STEPS', response.data);
          if (this.trainingSteps) {
            this.endLoading();
            this.tableisOpen = true;
          }
        });
    },
  },
  created() {
    if (this.$route.params.idStep) {
      if (this.$route.params.idStep === 'current') {
        this.initActualStep();
      } else {
        this.initStep(this.$route.params.idStep);
      }
    } else { window.console.log('actual'); this.initActualStep(); }
    if (this.trainingSteps.length < 1) {
      this.initSteps();
    }
  },
  mounted() {
  },
};
</script>

<style scoped>
.step-content {
  padding: 0 !important;
}
.bookletSteps img {
  width: 20px;
  height: 20px;
}
.bookletStep {
  display: flex;
  align-items: center;
}
.bookletStep::after {
  content: "-";
  font-size: 1.5rem;
  color: #21c697;
}
.img_status {
  margin: 0rem;
}
.bookletStep:last-child::after {
  content: unset;
}
#stepsContent {
  overflow: auto;
  position: relative;
  z-index: 1;
}
#stepsContent .table-wrapper {
  overflow: unset !important;
}
td[data-label="Nom"] {
  min-width: 162px;
}
.actual {
  background-color: var(--primary-light);
  padding: 0.5rem;
}
.selected {
  background-color: var(--primary-light);
  padding: 0.5rem;
  border-radius: 5px;
  border: 1px solid var(--grey-light);
}
</style>
