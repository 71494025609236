<template>
  <div v-if="training">
    <div class="mb-5 pb-5">
      <h1 class="title-paragraph">Options</h1>
    </div>
    <b-field>
      <b-switch
        disabled
        v-model="training.editable_without_dates"
        @input="
          training.editable_without_dates = !training.editable_without_dates
        "
      >
        Parcours éditable sans programmer les dates des activités
      </b-switch>
    </b-field>
    <b-field>
      <b-switch
        disabled
        v-model="training.disallow_free_situations"
        @input="
          training.disallow_free_situations = !training.disallow_free_situations
        "
      >
        Masquer les situations libres
      </b-switch>
    </b-field>
    <b-field>
      <b-switch
        disabled
        v-model="training.disallow_progressions"
        @input="
          training.disallow_progressions = !training.disallow_progressions
        "
      >
        Afficher les progressions pour une situation choisie
      </b-switch>
    </b-field>
    <b-field>
      <b-switch
        disabled
        v-model="training.disallow_free_situations"
        @input="
          training.disallow_progressions = !training.disallow_progressions
        "
      >
        Masquer les activités pratiques
      </b-switch>
    </b-field>
    <!-- <div v-if="options">
      <div v-for="option of options" :key="option.name">
        <b-field>
          <b-switch
            disabled
            v-model="option.value"
            @input="changeOption(option, option.value)"
          >
            {{ option.name }} - {{ option.code }}
          </b-switch>
        </b-field>
      </div>
    </div>-->
  </div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import { mapState } from 'vuex';

export default {
  props: {
    typeoftraining: {
      type: String,
    }, // framework, training or studenttraining
    objectId: {
      type: [Number, String],
    },
    trainingInfos: {
      type: [Object, Array],
    },
  },
  filters: {
    moment(date) {
      return moment(date).format('D MMMM YYYY');
    },
    day(date) {
      return moment(date).format('D/MM/YY');
    },
    hour(date) {
      return moment(date).format('HH:mm');
    },
  },
  computed: {
    ...mapState('auth', ['currentUser']),
  },
  data() {
    return {
      isLoading: true,
      isFullPage: false,
      isOpenPeriod: true,
      training: [],
      options: null,
      isSwitchedCustom: true,
      portfolio: false,
      practicalWork: false,

    };
  },
  methods: {
    startLoading() {
      this.isLoading = true;
    },
    endLoading() {
      this.isLoading = false;
    },
    order(array) {
      return _.orderBy(array, 'position');
    },
    initOptions() {
      this.$http
        .get('/tab_options/')
        .then((response) => {
          this.options = response.data;
          if (this.training.tab_options.find((option) => option.code === 'no_portfolio')) {
            this.portfolio = true;
          } else {
            this.portfolio = false;
          }
          if (this.training.tab_options.find((option) => option.code === 'no_practicalWorks')) {
            this.practicalWork = true;
          } else {
            this.practicalWork = false;
          }
          /* this.portfolio = ;
          this.practicalWork = ; */
          /*  this.training.tab_options.forEach((option) => {
              const optionIndex = this.options.findIndex((el) => el.code === option.code);
              if (optionIndex !== -1) {
                this.options[optionIndex].value = true;
              }
            }); */
        });
    },
    changeOption(option, created) {
      window.console.log('chage');
      window.console.log(created);
      const options = this.training.tab_options;
      window.console.log(options);
      if (created) {
        options.push(option);
      } else {
        const optionIndex = this.training.tab_options.findIndex((el) => el.code === option.code);
        options.splice(optionIndex, 1);
      }

      this.$http
        .patch(`/trainings/${this.$route.params.idTraining}/`, {
          tab_options: options,
        }).then(() => {
          this.$buefy.toast.open({ message: 'Option modifiée', type: 'is-success' });
        });
    },
  },
  created() {
    this.training = this.trainingInfos;
    this.initOptions();
  },
};
</script>
