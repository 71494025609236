<template>
  <div class="is-flex">
    <b-loading :is-full-page="isFullPage" v-model="isLoading"> </b-loading>
    <AppSidebar />
    <div class="main-content">
      <div class="lineCrumbAndAccount">
        <AppBreadcrumb v-bind:crumbs="crumbs" />
        <AppAccountMenu v-bind:appAccount="crumbs" />
      </div>
      <div class="header-page is-flex flex-column">
        <div class="justify-content-between columns lineHeaderTitle">
          <div class="column">
            <div class="title-page short-title">Ressources disponibles</div>
          </div>
        </div>
      </div>
      <div class="content-page pt-5">
        <!-- <h2 class="home-title">Ressources disponibles</h2> -->
        <section v-if="errored">
          <b-message
            title="Mince... Une erreur est survenue pendant le chargement des ressources"
            type="is-danger"
            aria-close-label="Close message"
            v-if="errored"
          >
            Nous sommes sincèrement désolés, nous ne sommes pas en mesure
            d'afficher ces ressouces pour le moment. Veuillez réessayer
            ultérieurement ou nous contacter via le support.
          </b-message>
        </section>

        <div>
          <div
            v-if="
              currentUser.role === 'formateur' ||
              currentUser.role === 'superviseur' ||
              currentUser.role === 'coordinateur'
            "
          >
            <div class="helpUser mt-0">
              <div class="title">Ajoutez des ressources</div>
              <div>
                Ici vous pouvez ajouter des documents généraux, communs à toutes
                les formations. Pour des questions écologiques, le poids des
                fichiers est limité à 1 Mo. Si votre fichier est trop lourd,
                vous pouvez l'optimiser sur un site tel que
                <a href="https://www.wecompress.com/fr/" target="_blank"
                  ><strong>wecompress</strong></a
                >.
              </div>
            </div>
            <div
              class="card_simple is-primary w-100 has-text-centered btn"
              @click="isOpen = !isOpen"
            >
              <div>
                <b-icon icon="resource" type="is-primary" class="mr-3"></b-icon>
                <b style="cursor: pointer">Ajoutez une ressource</b>
              </div>
            </div>
            <b-collapse v-model="isOpen">
              <ResourceCreation
                :sequence="null"
                :typeoftraining="null"
                :idTraining="null"
                :action="'post'"
                :isOpen.sync="isOpen"
                @messageFromChild="initResources"
                @messageFromResource="forceRerender"
                :key="resourceKey"
              ></ResourceCreation>
            </b-collapse>
            <div v-if="resources">
              <div v-if="resources.length">
                <div v-for="resource in resources" :key="resource.id">
                  <ResourceCard
                    :resource="resource"
                    :action="'edit'"
                    :isOpen.sync="isOpen"
                    @messageFromChild="initResources"
                  ></ResourceCard>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="helpUser mt-0">
              <div>
                Ici vous pouvez trouver des documents généraux, communs à toutes
                les formations.
              </div>
            </div>
            <div
              class="ml-0 mr-0 columns events is-tablet is-multiline"
              v-if="resources"
            >
              <div v-if="resources.length" class="container">
                <div v-for="resource in resources" :key="resource.id">
                  <ResourceCard
                    :resource="resource"
                    :action="'edit'"
                    @messageFromChild="initResources"
                  ></ResourceCard>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import AppSidebar from '@/components/AppSidebar.vue';
// import AppHeader from '@/components/AppHeader.vue';
// import AppTabs from '@/components/AppTabs.vue';
import AppBreadcrumb from '@/components/AppBreadcrumb.vue';
import AppAccountMenu from '@/components/AppAccountMenu.vue';
import ResourceCreation from '@/components/Resources/ResourceCreation.vue';
import ResourceCard from '@/components/Resources/ResourceCard.vue';

export default {
  components: {
    AppSidebar,
    // AppHeader,
    // AppTabs,
    AppBreadcrumb,
    AppAccountMenu,
    ResourceCard,
    ResourceCreation,
  },
  computed: {
    resources() {
      return this.$store.state.resources.resources;
    },
    ...mapState('auth', ['currentUser']),
  },
  methods: {
    initResources() {
      this.$http.get('/get_resources/?other=1&format=json').then((response) => {
        this.$store.commit('GET_RESOURCES', response.data);
        this.endLoading();
      }).catch((error) => {
        window.console.log(error);
        this.errored = true;
        this.$buefy.dialog.alert({
          title: 'Mince...',
          message: 'Une erreur s\'est produite lors du chargement des ressources',
          type: 'is-info',
          hasIcon: true,
          icon: 'times-circle',
          ariaRole: 'alertdialog',
          ariaModal: true,
          confirmText: 'Ok',
        });
      });
    },
    endLoading() {
      this.isLoading = false;
    },
    forceRerender() {
      // Every time that forceRerender is called, our prop componentKey will change.
      this.resourceKey += 1;
    },
  },
  created() {
    this.initResources();
  },
  data() {
    return {
      isLoading: true,
      isFullPage: false,
      errored: false,
      name: 'sameName',
      crumbs: [
        {
          text: 'Ressources',
          href: '/ressources',
          class: 'is-active',
        },
      ],
      tabs: [
        {
          name: 'toutes',
          href: '/ressources',
        },
        {
          name: 'actives',
          href: '/maquettes',
        },
        {
          name: 'inactives',
          href: '/maquettes',
        },
      ],
      contentVisible: false,
      isActive: false,
      isOpen: false,
      resourceKey: 0,
    };
  },
};
</script>
<style lang="scss">
isActive {
  display: none;
}
</style>
