<template>
  <div>
    <b-modal
      v-model="isWaypointModalActive"
      has-modal-card
      trap-focus
      full-screen
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-modal
      id="modalNude"
      :key="modalEdition"
      class="calendarModal"
    >
      <template>
        <div
          v-if="edit"
          class="modal-card modal-users pb-2"
          style="width: auto"
          id="modalWaypoint"
        >
          <section class="modal-card-body">
            <div class="has-text-right">
              <a @click="isWaypointModalActive = false" class="ml-auto">
                <b-icon icon="close" type="is-primary"></b-icon>
              </a>
            </div>
            <div
              class="is-flex align-items-center h-100"
              style="margin-top: -2rem"
            >
              <div style="margin: auto">
                <div class="title-paragraph has-text-centered">
                  <b
                    ><span v-if="newEvent"
                      >Définissez le type de point d'étape à ajouter</span
                    ><span v-else>Modifier un point d'étape</span></b
                  >
                </div>
                <div
                  class="has-text-centered"
                  v-if="currentUser.role !== 'apprenant'"
                >
                  <b>{{ formWaypoint.extendedProps.student_name }}</b>
                </div>
                <div class="columns is-vcentered is-centered mt-2">
                  <b-field
                    class="column is-narrow mr-3 mb-0 has-text-centered"
                    v-if="
                      typeView === 'global' &&
                      newEvent &&
                      !$route.params.idStudentTraining
                    "
                  >
                    <b-select
                      placeholder="Nom de l'apprenant"
                      icon="earth"
                      v-model="studentId"
                      @input="initTraining(studentId)"
                    >
                      <option
                        v-for="student in users"
                        :value="student.id"
                        :key="student.id"
                      >
                        {{ student.first_name }} {{ student.last_name }}
                      </option>
                    </b-select>
                  </b-field>
                  <div v-else class="w-100 has-text-centered">
                    <b>{{ student_profile }}</b>
                  </div>
                  <div v-if="typeView === 'global' && studentId">
                    <b-field
                      class="column is-narrow mr-3 mb-0 has-text-centered"
                      v-if="studentTrainings"
                    >
                      <b-select
                        placeholder="Formation"
                        icon="earth"
                        v-if="studentTrainings.length > 1"
                        v-model="studentTrainingSelected"
                        @input="getTraining(studentTrainingSelected)"
                      >
                        <option
                          v-for="studentTraining in studentTrainings"
                          :value="studentTraining"
                          :key="studentTraining.id"
                        >
                          {{ studentTraining.training.name }}
                        </option>
                      </b-select>
                      <b-tag class="is-outlined" v-else>{{
                        studentTrainings[0].training.name
                      }}</b-tag>
                    </b-field>
                  </div>
                </div>
                <b-field class="column is-narrow mr-3 mb-0 has-text-centered">
                  <b-select
                    placeholder="Type de point d'étape"
                    icon="earth"
                    v-model="type"
                  >
                    <option value="2">Coaching individuel</option>
                    <option value="3">Point tripartite</option>
                  </b-select>
                </b-field>
                <!-- <div v-else>Point en entreprise {{ (formWaypoint.type = 0) }}</div> -->
                <div class="columns is-tablet is-centered is-vcentered mb-0">
                  <div class="column is-narrow">
                    <b-field label="Début">
                      <b-datetimepicker
                        v-model="start_date"
                        rounded
                        placeholder="Cliquer pour sélectionner..."
                        :first-day-of-week="1"
                        :datepicker="{ showWeekNumber }"
                        :timepicker="{ enableSeconds, hourFormat }"
                        icon="calendar-today"
                      >
                      </b-datetimepicker>
                    </b-field>
                  </div>
                  <div class="column is-narrow">
                    <b-field label="Fin">
                      <b-datetimepicker
                        v-model="end_date"
                        rounded
                        placeholder="Cliquer pour sélectionner..."
                        :first-day-of-week="1"
                        :datepicker="{ showWeekNumber }"
                        :timepicker="{ enableSeconds, hourFormat }"
                        icon="calendar-today"
                      >
                      </b-datetimepicker>
                    </b-field>
                  </div>
                </div>
                <b-field label="Titre (facultatif)">
                  <b-input
                    v-model="title"
                    placeholder="Vous pouvez indiquer ou non une précision sur l'objet du point"
                  ></b-input>
                </b-field>
                <b-field label="Lieu de rendez-vous">
                  <b-input
                    v-model="location"
                    placeholder="ex: téléphone, whatsapp, google meet..."
                  ></b-input>
                </b-field>
                <div class="has-text-centered">
                  <b-button
                    v-if="newEvent"
                    :disabled="!type || !end_date || !start_date"
                    rounded
                    type="is-primary"
                    style="margin-top: -10px"
                    @click="postWaypoint()"
                    >Ajouter le point d'étape</b-button
                  >
                  <b-button
                    v-if="!newEvent"
                    rounded
                    type="is-primary"
                    style="margin-top: -10px"
                    @click="patchWaypoint()"
                    >modifier le point d'étape</b-button
                  >
                </div>
              </div>
            </div>
          </section>
        </div>
        <div
          v-else
          class="modal-card modal-users pb-2"
          style="width: auto"
          id="modalNude"
        >
          <section class="modal-card-body">
            <div class="has-text-right">
              <a @click="isWaypointModalActive = false" class="ml-auto">
                <b-icon icon="close" type="is-primary"></b-icon>
              </a>
            </div>
            <div
              class="is-flex align-items-center h-100"
              style="margin-top: -2rem"
            >
              <div style="margin: auto" v-if="event">
                <div class="title-paragraph has-text-centered">
                  <b>Point d'étape programmé</b>
                </div>
                <div
                  class="has-text-centered"
                  v-if="currentUser.role !== 'apprenant'"
                >
                  <b>{{ event.extendedProps.student_name }}</b>
                </div>
                <div class="has-text-centered mt-3 mb-5">
                  <!--<b-tag type="is-primary is-light">{{
                    event.extendedProps.type_label
                  }}</b-tag>-->
                  <b-tag
                    type="is-primary is-light"
                    v-if="event.extendedProps.type === 2"
                    >Coaching individuel</b-tag
                  >
                  <b-tag
                    type="is-primary is-light "
                    v-if="event.extendedProps.type === 3"
                    >Point tripartite</b-tag
                  >
                </div>
                <div class="columns is-tablet is-centered is-vcentered mb-0">
                  <div class="column">
                    <b-field label="Début">
                      {{ event.start | day }}
                      -
                      {{ event.start | hour }}
                    </b-field>
                  </div>
                  <div class="column">
                    <b-field label="Fin">
                      {{ event.end | day }} - {{ event.end | hour }}
                    </b-field>
                  </div>
                </div>
                <div>
                  <b-field label="Titre" v-if="event.title"
                    ><span>{{ event.title }}</span>
                  </b-field>
                </div>
                <b-field label="Lieu de rendez-vous">
                  <span v-if="event.extendedProps.location"
                    >{{ event.extendedProps.location }}
                  </span>
                  <span v-else
                    ><b-message size="is-small"
                      >Aucun texte renseigné</b-message
                    ></span
                  >
                </b-field>
                <div class="has-text-centered">
                  <a :href="event.extendedProps.ics">
                    <b-button
                      @click="event.extendedProps.ics"
                      rounded
                      class="mr-2"
                      type="is-primary is-outlined"
                      style="margin-top: -10px"
                      >Ajouter à mon agenda</b-button
                    ></a
                  >
                  <b-button
                    rounded
                    v-if="currentUser.role === 'formateur'"
                    class="mr-2"
                    type="is-primary is-outlined"
                    style="margin-top: -10px"
                    @click="
                      edit = true;
                      formWaypoint = event;
                      start_date = new Date(event.start);
                      end_date = new Date(event.end);
                      location = event.extendedProps.location;
                      newEvent = false;
                      title = event.title;
                      type = event.extendedProps.type;
                      forceRenderEventModal();
                    "
                    >modifier</b-button
                  >
                  <!-- <span
                    @click="gotoWaypoint(event.id)"
                    v-if="currentUser.role === 'formateur'"
                  >
                    <b-button
                      rounded
                      type="is-primary"
                      style="margin-top: -10px"
                      >Rédiger le compte rendu</b-button
                    >
                  </span> -->
                  <router-link
                    :to="
                      '/point-edition/' +
                      studentTrainingSelectedId +
                      '/' +
                      trainingSelected +
                      '/' +
                      studentSelected +
                      '/' +
                      event.id
                    "
                    ><b-button
                      rounded
                      type="is-primary"
                      style="margin-top: -10px"
                      >Voir le compte rendu</b-button
                    >
                  </router-link>
                </div>
              </div>
            </div>
          </section>
        </div>
      </template>
    </b-modal>
    <b-message
      type="is-success"
      size="is-small"
      v-if="currentUser.role === 'formateur' && student_profile"
      ><div class="columns is-tablet is-vcentered">
        <div class="column is-flex align-items-center">
          Apprenant sélectionné :
          <router-link
            class="ml-1"
            :to="
              '/formation-apprenant/' +
              $route.params.idTraining +
              '/' +
              $route.params.idStudent +
              '/' +
              $route.params.idStudentTraining +
              '/parcours'
            "
            ><b>{{ student_profile }}</b>
          </router-link>
        </div>
        <div class="column is-narrow" @click="clearData()">
          <router-link to="/calendrier">
            <b-button
              class="ml-auto"
              type="is-rounded is-success"
              size="is-small"
              >déselectionner</b-button
            >
          </router-link>
        </div>
      </div>
    </b-message>
    <b-message
      type="is-warning"
      size="is-small"
      v-if="currentUser.role === 'formateur'"
      >Pour ajouter un événement, cliquez sur le jour ou le créneau
      souhaité</b-message
    >
    <div v-if="dates.length > 0">
      <b-message type="is-info" v-if="dates.length === 0">
        Vous n'avez pas de dates programmées pour le moment.
        {{ endLoading() }}
      </b-message>
    </div>
    <section v-if="errored">
      <b-message type="is-danger">
        Nous sommes sincèrement désolés, nous ne sommes pas en mesure d'afficher
        l'agenda pour le moment. Veuillez réessayer ultérieurement ou nous
        contacter via le support.
      </b-message>
    </section>
    <b-loading :is-full-page="isFullPage" v-model="isLoading"> </b-loading>
    <FullCalendar class="demo-app-calendar" :options="calendarOptions">
      <template v-slot:eventContent="arg">
        <div :class="'date' + arg.event.extendedProps.type" class="dateCustom">
          <b>{{ arg.timeText }} </b>
          <span v-if="typeView === 'global'">
            <!--<span class="titleEvent"
              >{{ arg.event.extendedProps.type_label
              }}{{ arg.event.extendedProps.type }}</span
            >-->
            <span class="titleEvent" v-if="arg.event.extendedProps.type === 2"
              >Coaching individuel</span
            ><span class="titleEvent" v-if="arg.event.extendedProps.type === 3"
              >Point tripartite</span
            >
            <span class="subtitleEvent" v-if="arg.event.title">
              {{ arg.event.title }}
            </span>
            <div>
              <b>{{ arg.event.extendedProps.student_name }}</b>
            </div></span
          >
          <span v-else>
            <!--<span class="titleEvent"
              >{{ arg.event.extendedProps.type_label
              }}{{ arg.event.extendedProps.type }}</span
            >-->
            <span class="titleEvent" v-if="arg.event.extendedProps.type === 2"
              >Coaching individuel</span
            ><span class="titleEvent" v-if="arg.event.extendedProps.type === 3"
              >Point tripartite</span
            >
            <span class="subtitleEvent"> {{ arg.event.title }}</span>
          </span>
        </div>
      </template>
    </FullCalendar>
  </div>
</template>

<script>
import moment from 'moment';
import localization from 'moment/locale/fr';
import FullCalendar from '@fullcalendar/vue';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import frLocale from '@fullcalendar/core/locales/fr';
import { mapState } from 'vuex';

moment.updateLocale('fr', localization);

export default {
  props: {
    student: {
      type: Number,
    },
    studentTraining: {
      type: Number,
    },
    typeView: {
      type: String,
      // student
      // tutor
      // others
    },
    dates: [Object, Array],
    global: {
      type: Boolean,
    },
    step: [Number, String],
  },
  filters: {
    day(date) {
      return moment(date).format('DD/MM/YY');
    },
    hour(date) {
      return moment(date).format('H:mm');
    },
  },
  components: {
    FullCalendar,
  },
  computed: {
    ...mapState('auth', ['currentUser']),
  },
  methods: {

    // function to change field "color" to add styles to event
    // not wroking for now difficult to get type id of the parent sequence
    changeColor(classColor) {
      this.color = classColor;
    },
    // function at click
    handleEventClick(clickInfo) {
      this.isWaypointModalActive = true;
      this.event = clickInfo.event;
      this.edit = false;
      this.studentSelected = this.event.extendedProps.student_id;
      // this.trainingSelected = this.event.extendedProps.student_training;
      //  this.studentTrainingSelectedId = this.event.extendedProps.training_id;
      this.studentTrainingSelectedId = this.event.extendedProps.student_training;
      this.trainingSelected = this.event.extendedProps.training_id;
      // this.isComponentModalActive = true;
      // this.$emit(AgendaActivityDetail, this.idActivity);
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
      if (this.window.width < 576 && this.viewScreen === 'screen') {
        this.calendarOptions.initialView = 'listWeek';
        this.viewScreen = 'mobile';
        this.forceRenderCalendar();
      } else if (this.window.width > 576 && this.viewScreen === 'mobile') {
        this.calendarOptions.initialView = 'dayGridMonth';
        this.viewScreen = 'screen';
        this.forceRenderCalendar();
      }
    },
    resize() {
      this.calendarOptions.initialView = 'listWeek';
      this.forceRenderCalendar();
    },
    dateClick(info) {
      this.newEvent = true;
      this.event = null;
      this.edit = true;
      if (this.currentUser.role === 'formateur') {
        this.start_date = new Date(info.dateStr);
        this.end_date = new Date(info.dateStr);
        this.end_date.setMinutes(this.end_date.getMinutes() + 30);
        this.isWaypointModalActive = true;
      }
    },
    endLoading() {
      this.isLoading = false;
    },
    forceRenderCalendar() {
      this.keyCalendar += 1;
    },
    forceRenderEventModal() {
      this.modalEdition += 1;
    },
    postWaypoint() {
      let studentTraining = null;
      if (this.$route.params.idStudentTraining) {
        studentTraining = this.$route.params.idStudentTraining;
      } else {
        studentTraining = this.studentTrainingSelectedId;
      }
      this.$http
        .post('agenda/student_training_waypoints/', {
          start_date: this.start_date,
          end_date: this.end_date,
          type: this.type,
          student_training: studentTraining,
          location: this.location,
          title: this.title,
          profile: this.currentUser.id,
        }).then((response) => {
          this.$buefy.toast.open({ message: 'Point d\'étape enregistré', type: 'is-success' });
          this.formWaypoint.id = response.data.id;
          this.$emit('refreshCalendar');
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
          this.$buefy.dialog.alert({
            title: 'Mince...',
            message: 'Le point n\'a pas pu être enregistré.',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
          });
        });
    },
    patchWaypoint(validate) {
      this.$http
        .patch(`agenda/student_training_waypoints/${this.formWaypoint.id}/`, {
          title: this.title,
          start_date: this.start_date,
          end_date: this.end_date,
          type: this.type,
          location: this.location,
        }).then(() => {
          if (!validate) {
            this.$buefy.toast.open({ message: 'Point d\'étape enregistré', type: 'is-success' });
          } else { this.$buefy.toast.open({ message: 'Point d\'étape validé', type: 'is-success' }); }
          this.$emit('refreshCalendar');
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
          this.$buefy.dialog.alert({
            title: 'Mince...',
            message: 'Le point n\'a pas pu être enregistré.',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
          });
        });
    },
    initUsers() {
      this.$http
        .get('/get_profiles/?role=apprenant&format=json')
        .then((response) => {
          this.users = response.data.results;
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
        });
    },
    initTraining(student) {
      this.$http
        .get(`/agenda/student_training/?student=${student}&query={id,student_profile,training{name}}&format=json`)
        .then((response) => {
          this.studentTrainings = response.data;
          if (this.studentTrainings.length === 1) {
            this.studentTrainingSelectedId = this.studentTrainings[0].id;
            this.student_profile = this.studentTrainings[0].student_profile;
          }
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
        });
    },
    getTraining(studentTraining) {
      this.trainingSelected = studentTraining.training.name;
      this.studentTrainingSelectedId = studentTraining.id;
    },
    gotoWaypoint(id) {
      this.$router.push({
        name: 'WaypointVaeEdit',
        params: {
          idTraining: this.trainingSelected,
          idStudentTraining: this.studentTrainingSelectedId,
          from: this.$route.name,
          idStudent: this.studentSelected,
          idWaypoint: id,
          edit: true,
        },
      });
    },
    clearData() {
      this.student_profile = null;
      this.trainingSelected = null;
      this.studentSelected = null;
      this.studentTrainingSelected = null;
      this.studentTrainingSelectedId = null;
      this.studentTrainings = null;
      this.studentId = null;
    },
  },
  data() {
    return {
      student_profile: null,
      trainingSelected: null,
      studentSelected: null,
      studentTrainingSelected: null,
      studentTrainingSelectedId: null,
      studentTrainings: null,
      users: null,
      isLoading: true,
      isFullPage: false,
      errored: false,
      calendarOptions: {
        plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin],
        weekends: true,
        initialView: 'dayGridMonth',
        height: 'auto',
        expandRows: false,
        locales: [frLocale],
        locale: 'fr',
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridDay,listWeek',
        },
        events: [],
        dateClick: this.dateClick,
        eventClick: this.handleEventClick,
        windowResize: this.handleResize,
      },
      viewScreen: 'screen',
      window: {
        width: 0,
        height: 0,
      },
      keyCalendar: 0,
      isWaypointModalActive: false,
      isWaypointModalReadActive: false,
      formWaypoint: {
        type: null,
        end: null,
        start: null,
        title: null,
        extendedProps: [
          {
            type: null,
          },
        ],
      },
      showWeekNumber: true,
      locale: 'GTM',
      today: new Date(),
      typeSelected: 0,
      enableSeconds: false,
      hourFormat: undefined, // Browser locale
      selected: new Date(),
      timepicker: {
        incrementMinutes: 5,
        incrementHours: 1,
        enableSeconds: false,
        hourFormat: undefined,
      },
      event: null,
      modalEdition: 0,
      edit: true,
      start_date: new Date(),
      end_date: new Date(),
      location: null,
      newEvent: true,
      studentId: null,
      title: '',
      type: null,
    };
  },
  mounted() {
  },
  created() {
    if (this.$route.params.idStudentTraining) {
      this.studentId = this.$route.params.idStudent;
      this.trainingSelected = this.$route.params.idTraining;
      this.studentTrainingSelectedId = this.$route.params.idStudentTraining;
      this.initTraining(this.$route.params.idStudent);
    }
    if (this.dates.length > 0) {
      this.calendarOptions.events = this.dates;
      this.endLoading();
    }
    if (this.typeView === 'global') {
      this.initUsers();
    }
    this.endLoading();
  },
};
</script>
<style lang="scss" scoped>
.control.has-icons-left .icon.is-left {
  left: 6px !important;
}
.control.has-icons-left .icon,
.control.has-icons-right .icon {
  top: 3px !important;
}
.fc-v-event .dateCustom {
  height: 100%;
  border: 0px solid #fff;
  padding: 3px;
}
</style>
