import { render, staticRenderFns } from "./UsersToStudents.vue?vue&type=template&id=9e1f09ae"
import script from "./UsersToStudents.vue?vue&type=script&lang=js"
export * from "./UsersToStudents.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports