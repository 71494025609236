<template>
  <div class="modalbox">
    <!-- eslint-disable max-len-->
    <div class="is-flex bigmodal">
      <div class="ml-auto">
        <b-button v-if="$route.params.from != 'Agenda'" class="btn-margins" @click="goback()" type="is-primary" rounded
          outlined>Revenir au parcours</b-button>
        <b-button class="btn-margins" @click="gotoTrainingSequenceEdit(trainingsequence.id)" v-if="currentUser.role === 'coordinateur' ||
          (currentUser.role === 'formateur' && teacherEdit) ||
          currentUser.role === 'superviseur'
          " type="is-primary" rounded>modifier</b-button>
        <a @click="goPreviousPage()" class="ml-auto" v-if="$route.params.from === 'Agenda'">
          <b-icon icon="close" type="is-primary"></b-icon>
        </a>
      </div>
    </div>
    <div class="container title-modal">
      <div class="subtitle-paragraph mb-0">Détail d'une expérience</div>
      <div class="title-page long-title">
        {{ trainingsequence.name }}
      </div>
    </div>
    <section class="mt-auto" id="header-tabs">
      <div class="b-tabs container">
        <nav class="tabs">
          <ul>
            <li :class="currentTab === 1 ? 'is-active' : ''">
              <a @click="gotoStep(1)"><span>L'expérience</span></a>
            </li>
            <li v-for="(
                activity, indexActivity
              ) in trainingsequence.training_activities" :key="indexActivity"
              :class="currentTab === indexActivity + 2 ? 'is-active' : ''">
              <a @click="gotoActivity(indexActivity)"><b-icon icon="calendar" type="is-primary"></b-icon><span
                  v-if="activity.legacy_activity">{{
          activity.legacy_activity.name
        }}</span>
                <span v-else>{{ activity.name_override }} </span>
              </a>
            </li>
            <li :class="currentTab === 'resources' ? 'is-active' : ''">
              <a @click="gotoResources"><span>Ressources</span></a>
            </li>
          </ul>
        </nav>
      </div>
    </section>
    <div class="container contentActivite">
      <div v-if="step === 1">
        <div class="bloc-paragraph mt-5 pt-5">
          <div class="title-paragraph">Présentation de l'expérience</div>
          <div>
            <span class="subtitle-paragraph">Type d'expérience : </span>
            <span v-if="trainingsequence.sequence_type_label">
              {{ trainingsequence.sequence_type_label }}</span>
          </div>
          <div>
            <span class="subtitle-paragraph">Temps estimé : </span>{{ trainingsequence.estimated_duration }}
          </div>
          <!-- Todo: add training sequence teachers -->
          <!-- <div>
            <span class="subtitle-paragraph">Formateur(s) : </span>
            <span v-if="trainingsequence.teachers" class="addCommas">
              <span
                v-for="teacher in trainingsequence.teachers"
                :key="teacher.id"
              >
                <span v-if="getUser(teacher)">
                  {{ getUser(teacher).user.first_name }}
                  {{ getUser(teacher).user.last_name }}</span
                >
              </span></span
            >
          </div> -->
        </div>
        <div class="card_event">
          <div class="title-paragraph mb-0">Dates de l'expérience</div>
          <div class="columns is-tablet is-vcentered mt-2">
            <div class="column is-narrow">
              <b-field label="Date de début" class="mb-0">
                <span v-if="trainingsequence.start_date">{{ trainingsequence.start_date | day }}
                  à
                  {{ trainingsequence.start_date | hour }} </span><span v-else>-- </span>
              </b-field>
            </div>
            <div class="column is-narrow">
              <b-field label="Date de fin" class="mb-0">
                <span v-if="trainingsequence.end_date">{{ trainingsequence.end_date | day }}
                  à
                  {{ trainingsequence.end_date | hour }} </span><span v-else>-- </span>
              </b-field>
            </div>
          </div>
        </div>
        <div class="bloc-paragraph">
          <div class="title-paragraph">Principaux thèmes abordés</div>
          <div class="subtitle-paragraph" v-html="sequencetype.goals"></div>
          <div class="card_grey ck" v-if="trainingsequence.goals" v-html="trainingsequence.goals"></div>
          <b-message type="is-info" v-else> Aucun texte saisi </b-message>
        </div>
        <div class="bloc-paragraph">
          <div class="title-paragraph">Commentaires</div>
          <div class="subtitle-paragraph" v-html="sequencetype.comments"></div>
          <div class="card_grey ck" v-if="trainingsequence.comment" v-html="trainingsequence.comment"></div>
          <b-message type="is-info" v-else> Aucun texte saisi </b-message>
        </div>
        <SkillsSequence :typeoftraining="'training'" :sequenceId="Number(this.$route.params.id)"
          :trainingId="Number(this.$route.params.idTraining)" textTitle="Compétences cibles" />
      </div>
    </div>
    <div class="container contentActivite mb-6 pb-5" v-if="step == NumberActivity + 2">
      <div class="frowho">
        <b-icon icon="user" type="is-primary"></b-icon>
        <div v-if="trainingsequence.training_activities[NumberActivity].legacy_activity
          ">
          {{
          trainingsequence.training_activities[NumberActivity].legacy_activity
            .intro
        }}
        </div>
        <div v-else>
          {{
            trainingsequence.training_activities[NumberActivity].intro_override
          }}
        </div>
      </div>
      <div class="helpUser">
        <div class="title" v-if="trainingsequence.training_activities[NumberActivity].legacy_activity
          ">
          {{
          trainingsequence.training_activities[NumberActivity].legacy_activity
            .title
        }}
        </div>
        <div v-else class="title">
          {{
            trainingsequence.training_activities[NumberActivity].title_override
          }}
        </div>
        <div v-if="trainingsequence.training_activities[NumberActivity].legacy_activity
          ">
          {{
          trainingsequence.training_activities[NumberActivity].legacy_activity
            .description
        }}
        </div>
        <div v-else>
          {{
            trainingsequence.training_activities[NumberActivity]
              .description_override
          }}
        </div>
      </div>
      <div class="card_event">
        <div class="title-paragraph mb-0">Dates de l'activité</div>
        <div class="columns is-tablet is-vcentered mt-2">
          <div class="column is-narrow">
            <b-field label="Date de début" class="mb-0">
              <span v-if="trainingsequence.training_activities[NumberActivity]
            .start_date
          ">{{
          trainingsequence.training_activities[NumberActivity]
            .start_date | day
        }}
                à
                {{
            trainingsequence.training_activities[NumberActivity]
              .start_date | hour
          }} </span><span v-else>-- </span>
            </b-field>
          </div>
          <div class="column is-narrow">
            <b-field label="Date de fin" class="mb-0">
              <span v-if="trainingsequence.training_activities[NumberActivity].end_date
          ">{{
          trainingsequence.training_activities[NumberActivity].end_date
          | day
        }}
                à
                {{
            trainingsequence.training_activities[NumberActivity].end_date
            | hour
          }} </span><span v-else>-- </span>
            </b-field>
          </div>
        </div>
      </div>
      <div class="head_activity">L'activité</div>
      <div class="card_item cardpage_activity">
        <div v-for="(stepActivity, i) in order(
          trainingsequence.training_activities[NumberActivity]
            .training_activity_steps
        )" :key="i" class="mb-6">
          <div class="title-paragraph">{{ stepActivity.name }}</div>
          <div class="subtitle-paragraph">
            <span v-if="stepActivity.content" v-html="stepActivity.content"></span>
            <span v-else v-html="stepActivity.placeholder_override"></span>
          </div>
          <!-- step rights --->
          <div class="card_grey" v-if="trainingsequence.sequence_type != 2 ||
          (trainingsequence.sequence_type === 2 &&
            stepActivity.position === 3)
          ">
            <b-icon icon="user" type="is-primary"></b-icon>
            Ce texte sera à rédiger par
            <span v-if="trainingsequence.training_activities[NumberActivity].context ===
          'apprenant'
          ">l'apprenant
              <span v-if="sequencetype.id === 10">accompagné de tous les acteurs</span></span>
            <span v-else>
              le
              {{
          trainingsequence.training_activities[NumberActivity].context
        }}</span>
          </div>
          <div class="card_grey" v-if="(trainingsequence.sequence_type === 2 &&
            stepActivity.position === 1) ||
          (trainingsequence.sequence_type === 2 &&
            stepActivity.position === 2)
          ">
            <b-icon icon="user" type="is-primary"></b-icon> Ce texte sera à
            rédiger par le formateur
          </div>
          <!-- step rights --->
        </div>
      </div>
    </div>
    <div class="content-page container mt-5 pt-5" v-if="step === 'resources'">
      <h1 class="title-paragraph">Ressources associées à cette expérience</h1>
      <div class="helpUser mt-0">
        <div class="title">Ajoutez des ressources</div>
        <div>
          Ici vous pouvez ajouter des documents à cette expérience. Dans
          l'édition du parcours vous pouvez également ajouter des ressources à
          certaines expériences ou activités. Pour des questions écologiques, le
          poids des fichiers est limité à 1 Mo. Si votre fichier est trop lourd,
          vous pouvez l'optimiser sur un site tel que
          <a href="https://www.wecompress.com/fr/" target="_blank"><strong>wecompress</strong></a>.
        </div>
        <div>Vous pouvez ajouter des ressources</div>
      </div>
      <div class="card_simple is-primary w-100 has-text-centered btn" @click="isOpen = !isOpen">
        <div>
          <b-icon icon="resource" type="is-primary" class="mr-3"></b-icon>
          <b style="cursor: pointer">Ajoutez une ressource</b>
        </div>
      </div>
      <b-collapse v-model="isOpen">
        <ResourceCreation :sequence="trainingsequence" :typeoftraining="'training'"
          :idTraining="this.$route.params.idTraining" :action="'post'" v-if="trainingsequence" :isOpen.sync="isOpen"
          @messageFromChild="initResources" @messageFromResource="forceRerender" :key="resourceKey"></ResourceCreation>
      </b-collapse>
      <div v-if="resources">
        <div v-if="resources.length">
          <div v-for="resource in resources.slice(0, 5)" :key="resource.id">
            <ResourceCard :resource="resource" :action="'edit'" @messageFromChild="initResources"></ResourceCard>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import { mapState } from 'vuex';
import ResourceCard from '@/components/Resources/ResourceCard.vue';
import ResourceCreation from '@/components/Resources/ResourceCreation.vue';
import SkillsSequence from '@/components/Skills/SkillsSequence.vue';

export default {
  components: {
    ResourceCard,
    ResourceCreation,
    SkillsSequence,
  },
  filters: {
    day(date) {
      return moment(date).format('DD/MM/YYYY');
    },
    hour(date) {
      return moment(date).format('HH:mm');
    },
  },
  computed: {
    ...mapState('auth', ['currentUser']),
    users() {
      return this.$store.state.users;
    },
  },
  data() {
    return {
      resourceKey: 0,
      isOpen: false,
      teacherEdit: null,
      trainingsequence: [],
      sequencetypes: [],
      sequencetype: [],
      currentTab: 1,
      numberStep: 1,
      step: 1,
      stepfrom: '',
      // declaration variables for tabs
      tabs: [
        {
          name: 'La situation',
          id: '1',
        },
        {
          name: 'Ressources',
          id: '2',
        },
      ],
      NumberActivity: 0,
      resources: null,
      skills: [{
        name: 'être capable d\'initialiser la diagbox', level: 1,
      },
      {
        name: 'être capable d\'accéder aux paramètres de la diagbox', level: 2,
      },
      {
        name: 'être capable de faire un test simple', level: 2,
      },
      ],
      formSkills: {},
      skillsLevels: ['seul', 'accompagné', 'à revoir'],
    };
  },
  methods: {
    forceRerender() {
      // Every time that forceRerender is called, our prop componentKey will change.
      this.resourceKey += 1;
    },
    getTrainingRights() {
      this.$http
        .get(`/trainings/${this.$route.params.idTraining}/?query={teachers_can_edit}&format=json`)
        .then((response) => {
          this.teacherEdit = response.data.teachers_can_edit;
        });
    },
    initTrainingSequence() {
      this.$http
        .get(`/training_sequences/${this.$route.params.id}/?format=json`)
        .then((response) => {
          this.trainingsequence = response.data;
          const activites = this.order(this.trainingsequence.training_activities);
          this.trainingsequence.training_activities = activites;
          this.initSequencetype(this.trainingsequence.sequence_type);
          this.endLoading();
          this.initResources();
        });
    },
    initSequencetype(id) {
      this.$http
        .get(`/sequencetypes/${id}/?query={-activities}&format=json`)
        .then((response) => {
          this.sequencetype = response.data;
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
          this.$buefy.dialog.alert({
            title: 'Mince...',
            message: 'Une information n\'a pas pu être totalement chargée, nous vous invitons à recharger la page.',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
          });
        });
    },
    gotoStep(numberStep) {
      this.currentTab = numberStep;
      this.step = numberStep;
    },
    gotoActivity(numberStep) {
      this.currentTab = numberStep + 2;
      this.step = numberStep + 2;
      this.NumberActivity = numberStep;
    },
    gotoResources() {
      this.currentTab = 'resources';
      this.step = this.currentTab;
    },
    gotoTrainingSequenceEdit(param) {
      this.$router.push({
        name: 'TrainingSequenceDetailEdit',
        params: { id: param, idTraining: this.$route.params.idTraining },
      }).catch((error) => {
        window.console.log(error);
        this.errored = true;
        this.$buefy.dialog.alert({
          title: 'Mince...',
          message: 'Nous ne pouvons accéder au détail de l\'expérience',
          type: 'is-info',
          hasIcon: true,
          icon: 'times-circle',
          ariaRole: 'alertdialog',
          ariaModal: true,
          confirmText: 'Ok',
        });
      });
    },
    order(array) {
      return _.orderBy(array, 'position');
    },
    startLoading() {
      this.isLoading = true;
    },
    endLoading() {
      this.isLoading = false;
    },
    // go to a specific tab in page with stepNumber param
    goPreviousPage() {
      if (this.$route.params.from === 'Agenda') {
        this.$router.push({
          name: this.$route.params.from,
        });
      } else {
        this.$router.push({
          name: 'Training',
          params: { idTraining: this.$route.params.idTraining, tab: this.stepFrom },
        });
      }
    },
    initResources() {
      this.startLoading();
      this.$http.get(`/get_resources/?training_sequence=${this.trainingsequence.id}&sequence_type=${this.trainingsequence.sequence_type}&format=json`).then((response) => {
        this.resources = response.data.resources;
        this.endLoading();
      });
    },
    goback() {
      this.$router.go(-1);
    },
  },
  created() {
    this.stepFrom = this.$route.params.stepNumber;
    this.initTrainingSequence();
  },
};
</script>
