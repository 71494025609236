<template>
  <div class="modalbox modalStep">
    <div class="is-flex bigmodal">
      <router-link
        v-if="route_from == 'WaypointEdit'"
        class="mr-auto"
        :to="
          '/point-etape-edition/' +
          $route.params.idStudentTraining +
          '/' +
          $route.params.idTraining +
          '/' +
          $route.params.idStudent +
          '/' +
          $route.params.idWaypoint
        "
        ><img
          class="btn-previous"
          :src="require('@/assets/img/arrows/previous.svg')"
      /></router-link>
      <router-link
        v-else
        class="mr-auto"
        :to="
          '/formation-apprenant/' +
          $route.params.idTraining +
          '/' +
          $route.params.idStudent +
          '/' +
          $route.params.idStudentTraining
        "
        ><img
          class="btn-previous"
          :src="require('@/assets/img/arrows/previous.svg')"
      /></router-link>
    </div>
    <section class="w-100" id="freeCarousel">
      <b-carousel
        v-if="edit"
        v-model="carousel"
        :animated="animated"
        :has-drag="drag"
        :autoplay="autoPlay"
        :pause-hover="pauseHover"
        :pause-info="pauseInfo"
        :pause-info-type="pauseType"
        :interval="interval"
        :repeat="repeat"
        :arrow-hover="false"
        :indicator="false"
        :key="componentKey"
      >
        <b-carousel-item>
          <section class="hero is-medium is-bold">
            <div class="">
              <div v-for="question in questions.sections" :key="question.name">
                <div class="w-100 bloc-title">
                  <div class="title-paragraph">
                    <div class="stepsCount">1/2</div>
                    Enregistrez une nouvelle note dans votre carnet de bord
                  </div>
                  <div class="subtitle-paragraph mb-4 has-text-left">
                    <p>{{ questions.description }}</p>
                  </div>
                  <div class="subtitle-paragraph mb-4 has-text-left">
                    <p>
                      {{ question.description }}
                    </p>
                    <p>&nbsp;</p>
                  </div>
                </div>
                <div class="w-100 container-editor">
                  <ModalImage
                    v-if="isCardModalActive"
                    :fileImage.sync="imageUploaded"
                    :imageResized.sync="imageResized"
                    @close="closeModal"
                    @loaded="addImageinSteps(imageResized)"
                  />
                  <div class="columns">
                    <div class="column is-one-third">
                      <b-field label="Date">
                        <b-datepicker
                          v-model="formRevision.start_date"
                          :first-day-of-week="1"
                          placeholder="Cliquez pour sélectionner..."
                          trap-focus
                        >
                          <b-button
                            label="Aujourd'hui"
                            type="is-primary"
                            icon-left="calendar-today"
                            @click="start_date = new Date()"
                          />
                        </b-datepicker>
                      </b-field>
                    </div>
                    <div class="column">
                      <b-field label="Vous pouvez donner un titre (facultatif)">
                        <b-input
                          v-model="formRevision.title"
                          placeholder="précisez un titre"
                        ></b-input>
                      </b-field>
                    </div>
                  </div>

                  <!-- <div class="imgBottom fade">
                    <b-upload
                      v-model="file"
                      class="file-label"
                      @input="uploadImageModal(i)"
                    >
                      <span class="file-cta">
                        <b-icon class="file-icon" icon="photo"></b-icon>
                      </span>
                    </b-upload>
                  </div> -->

                  <div
                    v-for="(field, index) in question.fields"
                    :key="field.label"
                  >
                    <div class="has-text-primary mb-3">
                      <b>{{ field.label }}</b>
                    </div>
                    <div
                      class="is-flex"
                      v-if="field.field_type === 'text-image'"
                    >
                      <b-field
                        class="file is-primary mb-1 addImage"
                        :class="{ 'has-name': !!file }"
                      >
                        <b-upload
                          v-model="file"
                          class="file-label"
                          @input="uploadImageModal"
                        >
                          <span class="file-cta">
                            <b-icon class="file-icon" icon="photo"></b-icon>
                            <span class="file-label">Ajouter une image</span>
                          </span>
                        </b-upload>
                      </b-field>
                    </div>
                    <div
                      class="ck-editor-block"
                      :class="field.field_type !== 'text-image' ? 'mt-0' : ''"
                    >
                      <ckeditor
                        v-model="field.value"
                        :editor="editor"
                        :config="editorConfig"
                        class="mb-5"
                        :ref="'question' + index"
                      ></ckeditor>
                    </div>
                  </div>
                  <div class="has-text-primary mb-3">
                    <b>Associez un document</b>
                  </div>
                  <div class="mb-5 pb-5">
                    <div class="mb-5 pb-5">
                      <div v-if="currentUser.role === 'apprenant'">
                        <div
                          class="card_simple is-primary w-100 has-text-centered mb-2"
                          @click="
                            isOpen = !isOpen;
                            if (!formRevision.id) {
                              postRevision('resource');
                            }
                          "
                        >
                          <div>
                            <b-icon
                              icon="resource"
                              type="is-primary"
                              class="mr-3"
                            ></b-icon>
                            <b style="cursor: pointer">Ajoutez une ressource</b>
                          </div>
                        </div>
                        <b-collapse v-model="isOpen">
                          <ResourceCreation
                            :isOpen.sync="isOpen"
                            :sequence="null"
                            :typeoftraining="'custom_experience'"
                            :idTraining="$route.params.idCustom"
                            :action="'post'"
                            @messageFromChild="getCustom"
                          ></ResourceCreation>
                        </b-collapse>
                      </div>
                      <div v-if="formRevision.resources">
                        <div v-if="formRevision.resources.length">
                          <div
                            v-for="resource in formRevision.resources"
                            :key="resource.id"
                          >
                            <ResourceCard
                              :resource="resource"
                              @messageFromChild="getCustom"
                            ></ResourceCard>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </b-carousel-item>
      </b-carousel>
      <div v-else>
        <b-carousel
          v-model="carousel"
          :animated="animated"
          :has-drag="drag"
          :autoplay="autoPlay"
          :pause-hover="pauseHover"
          :pause-info="pauseInfo"
          :pause-info-type="pauseType"
          :interval="interval"
          :repeat="repeat"
          :arrow-hover="false"
          :indicator="false"
          :key="componentKey"
        >
          <b-carousel-item>
            <section class="hero is-medium is-bold">
              <div v-for="question in questions.sections" :key="question.name">
                <div class="w-100 bloc-title">
                  <div class="title-paragraph">
                    <div class="stepsCount">1/2</div>
                    Enregistrez une nouvelle note dans votre carnet de bord
                  </div>
                  <div class="subtitle-paragraph mb-4 has-text-left">
                    <p>{{ questions.description }}</p>
                  </div>
                  <div class="subtitle-paragraph mb-4 has-text-left">
                    <p>
                      {{ question.description }}
                    </p>
                    <p>&nbsp;</p>
                  </div>
                </div>
                <div class="w-100 container-editor">
                  <div class="columns">
                    <div class="column is-one-third">
                      <b-field label="Date">
                        {{ formRevision.start_date | day }}
                      </b-field>
                    </div>
                    <div class="column">
                      <b-field label="Titre (facultatif)">
                        <div v-if="formRevision.title">
                          {{ formRevision.title }}
                        </div>
                        <b-message v-else size="is-small"
                          >Aucun texte n'a encore été saisi</b-message
                        >
                      </b-field>
                    </div>
                  </div>

                  <div v-for="field in question.fields" :key="field.label">
                    <div class="has-text-primary mb-3">
                      <b>{{ field.label }}</b>
                    </div>

                    <div
                      v-html="field.value"
                      v-if="field.value"
                      class="mb-5 pb-5"
                    ></div>
                    <b-message v-else size="is-small"
                      >Aucun texte n'a encore été saisi</b-message
                    >
                  </div>
                  <div class="has-text-primary mb-3">
                    <b>Documents</b>
                  </div>

                  <div v-if="formRevision.resources" class="mb-6">
                    <div v-if="formRevision.resources.length">
                      <div
                        v-for="resource in formRevision.resources"
                        :key="resource.id"
                      >
                        <ResourceCard
                          :resource="resource"
                          @messageFromChild="getCustom"
                        ></ResourceCard>
                      </div>
                    </div>
                  </div>
                  <b-message v-else size="is-small"
                    >Aucun document n'a encore été ajouté</b-message
                  >

                  <div
                    class="has-text-primary mb-3"
                    v-if="
                      currentUser.role === 'formateur' ||
                      formRevision.teacher_comment
                    "
                  >
                    <b>Commentaire du coach</b>
                  </div>
                  <div class="mb-5 pb-5">
                    <div
                      v-html="formRevision.teacher_comment"
                      v-if="formRevision.teacher_comment && !editCoach"
                    ></div>
                    <ckeditor
                      v-if="currentUser.role === 'formateur' && editCoach"
                      v-model="formRevision.teacher_comment"
                      :editor="editor"
                      :config="editorConfig"
                      class="mb-5"
                      :ref="'questioncoach'"
                    ></ckeditor>
                  </div>
                  <div class="mb-5 pb-5"></div>
                </div>
              </div>
            </section>
          </b-carousel-item>
        </b-carousel>
      </div>
    </section>
    <div
      v-if="currentUser.role == 'apprenant' || currentUser.role === 'formateur'"
    >
      <div
        class="is-flex is-flex-wrap-wrap activityBtns align-items-center"
        style="z-index: 2000"
      >
        <div class="w-100 is-flex">
          <b-button
            v-if="$route.params.idCustom === 'new'"
            @click="postRevision"
            class="mx-auto btn-action"
            type="is-primary is-rounde"
            rounded
            >créer</b-button
          >
          <span v-else class="mx-auto btn-action">
            <b-button
              v-if="
                (edit === true && currentUser.role === 'apprenant') ||
                (currentUser.role === 'formateur' && editCoach)
              "
              class="mx-auto btn-action"
              type="is-primary is-rounded"
              rounded
              @click="patchRevision"
              >enregistrer</b-button
            >
            <b-button
              v-else-if="!edit && currentUser.role === 'apprenant'"
              class="mx-auto btn-action"
              type="is-primary is-outlined"
              rounded
              @click="edit = true"
              >modifier</b-button
            >
            <b-button
              v-else-if="currentUser.role === 'formateur' && !editCoach"
              class="mx-auto btn-action"
              type="is-primary is-outlined"
              rounded
              @click="editCoach = true"
              >modifier</b-button
            >
          </span>
        </div>
        <div class="help-btn">
          <AppHelpMessage
            title="Enregistrez une note de carnet"
            message="<b>Vous pouvez ici rendre compte d'une activité
               qui n'était pas prévue dans votre parcours ou que vous avez du mal à
               associer à une situation de travail proposée.</b> <br><br>N'hésitez pas dans
               l'étape 2 à désigner quelles compétences vous avez travaillé dans cette activité. "
            textBtn="Besoin d'aide ?"
          />
        </div>
      </div>
    </div>
    <b-loading :is-full-page="isFullPage" v-model="isLoading"> </b-loading>

    <div class="container contentSequence pb-5 mb-5"></div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import moment from 'moment';
import localization from 'moment/locale/fr';
import CKEditor from '@ckeditor/ckeditor5-vue2';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import '@ckeditor/ckeditor5-build-classic/build/translations/fr';
import _ from 'lodash';
import ModalImage from '@/components/Resources/ModalImage.vue';
import AppHelpMessage from '@/components/AppHelpMessage.vue';
import ResourceCard from '@/components/Resources/ResourceCard.vue';
import ResourceCreation from '@/components/Resources/ResourceCreation.vue';

moment.updateLocale('fr', localization);

export default {
  props: {
    studentactivity: [Object, Array],
    slide: Number,
  },
  components: {
    ckeditor: CKEditor.component,
    ModalImage,
    AppHelpMessage,
    ResourceCard,
    ResourceCreation,
  },
  filters: {
    day(date) {
      return moment(date).format('D/MM/YYYY');
    },
    hour(date) {
      return moment(date).format('h:mm');
    },
  },
  data() {
    return {
      sequencetype: '5',
      isLoading: true,
      isFullPage: false,
      carousel: 0,
      animated: 'slide',
      drag: false,
      autoPlay: false,
      pauseHover: false,
      pauseInfo: false,
      repeat: false,
      pauseType: 'is-primary',
      interval: 3000,
      editDates: false,
      editor: ClassicEditor,
      editorDisabled: true,
      editorConfig: {
        language: 'fr',
        toolbar: ['heading', '|', 'bold', 'italic', 'bulletedList', 'numberedList', '|', 'outdent', 'indent', '|', 'link', '|', 'undo',
          'redo'],
        alignment: {
          options: ['left', 'right'],
        },
        heading: {
          options: [
            { model: 'paragraph', title: 'Styles de texte', class: 'ck-heading_paragraph' },
            {
              model: 'heading1',
              view: 'h1',
              title: 'Titre 1',
              class: 'ck-heading_heading1',
            },
            {
              model: 'heading2',
              view: 'h2',
              title: 'Titre 2',
              class: 'ck-heading_heading2',
            },
            {
              model: 'mark',
              view: 'mark',
              title: 'surligné',
              class: 'ck-mark',
            },
          ],
        },
        table: {
          contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells'],
        },
      },
      imageUploaded: {},
      modalKey: 0,
      imageResized: null,
      file: null,
      started: true,
      formRevision: {
        id: '',
        title: '',
        entry: '',
        start_date: new Date(),
        end_date: new Date(),
      },
      studentrevision: '',
      isCardModalActive: false,
      showWeekNumber: true,
      locale: 'UTC',
      today: new Date(),
      activityToday: 'oui',
      newItem: true,
      componentKey: 0,
      route_from: '',
      isOpen: false,
      /* questions: [] */
      questions:
      {
        name: 'Carnet de bord - Ecole de la convivialité',
        description: 'Prenez le temps chaque jour d\'écrire les activités que vous avez réalisées. Petit à petit, vous obtiendrez une liste de toutes les activités de votre métier et aurez un meilleur recul sur votre pratique. Ajoutez autant de lignes que nécessaire !',
        sections: [
          {
            name: 'Questionnaire carnet de bord quotidien',
            description: 'Pour vous aiguiller dans votre récit, posez-vous des questions ! \r\nComment je me suis senti ? Qu’est-ce que j’ai bien réussi ? Qu’est-ce que j’ai moins bien réussi ? Que pourrais-je améliorer et comment ? Quels sont les moments forts de la journée ? Comment s’est passée chronologiquement ma journée ? En un mot, comment récapituler ma journée ? Quelle est la phrase qui m’a marqué ? Qu’ai-je fait de convivial ? Comment ? A quel moment ?...',
            position: 1,
            fields: [
              {
                label: 'Le récit de ma journée',
                placeholder: 'Le récit de ma journée',
                field_type: 'textarea',
                position: 1,
                required: false,
                choices: [],
                value: '',
              },
              {
                label: 'Comment je me sens ?',
                placeholder: 'Comment je me sens ?',
                field_type: 'textarea',
                position: 2,
                required: false,
                choices: [],
                value: '',
              },
              {
                label: 'Ce que j’ai raté',
                placeholder: 'Ce que j’ai raté',
                field_type: 'textarea',
                position: 4,
                required: false,
                choices: [],
                value: '',
              },
              {
                label: 'Mes réussites du jour',
                placeholder: 'Mes réussites du jour',
                field_type: 'textarea',
                position: 5,
                required: false,
                choices: [],
                value: '',
              },
            ],
          },
        ],
      },
      edit: false,
      steptosen: '',
      editCoach: false,
    };
  },
  computed: {
    ...mapGetters(['getUserById']),
    ...mapState('auth', ['currentUser']),
    conditions() {
      return this.formRevision.title.length > 0 && this.formRevision.entry.length > 0;
    },
  },
  methods: {
    startLoading() {
      this.isLoading = true;
    },
    endLoading() {
      this.isLoading = false;
    },
    getCustom() {
      this.$http
        .get(`/agenda/custom_experiences/${this.$route.params.idCustom}/?format=json`)
        .then((response) => {
          this.studentrevision = response.data;
          this.formRevision = response.data;
          if (!this.formRevision.start_date) {
            this.formRevision.start_date = new Date();
          }
          if (this.formRevision.form_entry) {
            this.questions = JSON.parse(this.formRevision.form_entry);
          }
          this.setdata();
          this.edit = false;
          this.forceRerender();
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
        });
    },
    setdata() {
      this.formRevision.title = this.studentrevision.title;
      this.formRevision.entry = this.studentrevision.entry;
      this.formRevision.start_date = new Date(this.studentrevision.start_date);
      this.formRevision.end_date = new Date(this.studentrevision.end_date);
      this.formRevision.type = this.studentrevision.type;
      this.verifToday();
    },
    order(array) {
      return _.orderBy(array, 'position');
    },
    uploadImageModal() {
      this.checkFile();
      if (this.fileValidity === true) {
        this.imageUploaded = this.file;
        this.modalKey += 1;
        this.isCardModalActive = true;
      }
    },
    closeModal() {
      this.isCardModalActive = false;
      this.file = null;
    },
    addImageinSteps(url) {
      this.formRevision.entry = `${this.formRevision.entry} <div class="imageUploaded"><img src="${url}"/></div>`;
    },
    checkFile() {
      const extension = this.file.name.split('.').pop();
      // this.resources_type = this.file.substr((this.file.lastIndexOf('.') + 1));
      if (!/(jpeg|png|jpg)$/ig.test(extension)) {
        this.$buefy.dialog.alert({
          title: 'Ce type de fichier n\'est pas accepté',
          message: 'Vous pouvez utiliser les formats suivants :  jpeg, jpg, png.',
          type: 'is-info',
          hasIcon: true,
          icon: 'times-circle',
          ariaRole: 'alertdialog',
          ariaModal: true,
          confirmText: 'Ok',
        });
      } else if (this.file.size > 10000000) {
        this.$buefy.dialog.alert({
          title: 'Cette image est trop volumineuse',
          message: 'Vous pouvez télécharger une image dont la taille ne dépasse pas 10 Mo. Vous pouvez utiliser un service externe comme squoosh.app ou utiliser les options de votre périphérique (téléphone ou tablette) pour réduire une image.',
          type: 'is-info',
          hasIcon: true,
          icon: 'times-circle',
          ariaRole: 'alertdialog',
          ariaModal: true,
          confirmText: 'Ok',
        });
      } else if (this.file.name.length > 116) {
        this.$buefy.dialog.alert({
          title: 'Le nom de votre fichier est trop long',
          message: 'le nom ne doit pas dépasser 117 cractères',
          type: 'is-info',
          hasIcon: true,
          icon: 'times-circle',
          ariaRole: 'alertdialog',
          ariaModal: true,
          confirmText: 'Ok',
        });
      } else { this.nameFile = this.file.name; this.fileValidity = true; }
    },
    patchRevision() {
      const entry = JSON.stringify(this.questions);
      this.$http
        .patch(`/agenda/custom_experiences/${this.$route.params.idCustom}/`, {
          title: this.formRevision.title,
          form_entry: entry,
          start_date: this.formRevision.start_date,
          end_date: this.formRevision.start_date,
          teacher_comment: this.formRevision.teacher_comment,
        }).then(() => {
          this.$buefy.toast.open({ message: 'Activité enregistrée', type: 'is-success' });
          this.edit = false;
          this.editCoach = false;
          this.forceRerender();
          this.endLoading();
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
          this.$buefy.dialog.alert({
            title: 'Mince...',
            message: 'L\'activité n\'a pas pu être enregistrée.',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
          });
        });
    },
    postRevision(mode) {
      let editionMode = false;
      if (mode === 'resource') {
        editionMode = true;
      }
      const entry = JSON.stringify(this.questions);
      this.$http
        .post('agenda/custom_experiences/', {
          profile: this.currentUser.id,
          role: this.currentUser.role,
          student_training: this.$route.params.idStudentTraining,
          start_date: this.formRevision.start_date,
          end_date: this.formRevision.start_date,
          form_entry: entry,
          title: this.formRevision.title,
        }).then((response) => {
          this.startLoading();
          this.$buefy.toast.open({ message: 'Activité crée', type: 'is-success' });
          if (mode !== 'resource') {
            this.$router.push({
              name: 'StudentTraining',
              params: {
                idStudent: this.$route.params.idStudent,
                idTraining: this.$route.params.idTraining,
                idStudentTraining: this.$route.params.idStudentTraining,
                from: this.$route.name,
                tab: 'parcours',
              },
            });
            this.forceRerender();
            this.endLoading();
          } else {
            this.formRevision.id = response.data.id;
            this.$router.push({
              name: 'StudentFreeNoteEdit',
              params: {
                idTraining: this.$route.params.idTraining,
                idStudent: this.$route.params.idStudent,
                idSequence: this.$route.params.idSequence,
                idActivity: this.$route.params.idActivity,
                idWaypoint: this.$route.params.idWaypoint,
                idCustom: response.data.id,
                editMode: editionMode,
                slide: 1,
              },
            });
            this.endLoading();
          }
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
          this.$buefy.dialog.alert({
            title: 'Mince...',
            message: 'L\'activité n\'a pas pu être enregistrée.',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
          });
        });
    },
    verifToday() {
      if (this.formRevision.start_date !== this.today) {
        this.activityToday = 'non';
      } else if (this.formRevision.start_date === this.today) {
        this.activityToday = 'oui';
      }
    },
    changeDate() {
      if (this.activityToday === 'oui') {
        this.formRevision.start_date = this.today;
        this.formRevision.end_date = this.today;
        this.editDates = false;
      } else {
        this.editDates = true;
      }
    },
    logActivity() {
      const typeAction = 'écriture custom experience';
      this.$http
        .post('agenda/logs/', {
          action: typeAction,
          student_training: this.$route.params.idStudentTraining,
          training: this.$route.params.idTraining,
          student_activity: null,
          student_sequence: null,
        })
        .then(() => {
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
        });
    },
    forceRerender() {
      this.componentKey += 1;
      if (this.$route.params.slide) {
        this.carousel = this.$route.params.slide;
      }
    },
    changeSlide() {
      this.$router.push({
        name: 'StudentFreeNoteEdit',
        params: {
          idTraining: this.$route.params.idTraining,
          idStudent: this.$route.params.idStudent,
          idSequence: this.$route.params.idSequence,
          idActivity: this.$route.params.idActivity,
          idWaypoint: this.$route.params.idWaypoint,
          idCustom: 118,
          slide: 2,
          from: this.$route.params.from,
        },
      });
    },
    addslashes() {
      const strtosend = JSON.stringify(this.formRevision.form_entry);
      // eslint-disable-next-line
      this.steptosend = strtosend.replace(/"/g, '\\"');
    },
  },
  created() {
    this.route_from = this.$route.params.from;
    this.endLoading();
    if (this.$route.params.idCustom !== 'new') {
      this.getCustom();
      this.newItem = false;
    } else {
      this.edit = true;
    }
    if (this.$route.params.slide) {
      this.carousel = this.$route.params.slide;
    }
    if (this.currentUser.role === 'apprenant' || this.currentUser.role === 'tuteur') { this.logActivity(); }
    if (this.$route.params.editMode) {
      this.edit = true;
    }
  },
  mounted() {
  },
  destroyed() {
  },
};
</script>

<style lang="scss" scoped>
.modalStep .ck-editor-block {
  padding-bottom: 0;
}
.modalStep .ck-content {
  min-height: 159px !important;
}
@media (max-width: 576px) {
  .modalStep .bigmodal {
    margin-bottom: 0.1rem;
  }
}
</style>
