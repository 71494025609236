<template>
  <div class="is-flex">
    <AppSidebar />
    <b-loading :is-full-page="isFullPage" v-model="isLoading"> </b-loading>
    <div class="main-content">
      <div class="lineCrumbAndAccount ml-auto">
        <AppAccountMenu v-bind:appAccount="crumbs" />
      </div>
      <div class="header-page is-flex flex-column">
        <div class="justify-content-between columns lineHeaderTitle">
          <div class="column title-page short-title">
            {{ $route.meta.header_title }}
          </div>
          <div class="column is-narrow actions">
            <router-link to="/wiki"
              ><b-button type="is-primary is-rounded"
                >Revenir à la documentation</b-button
              >
            </router-link>
          </div>
        </div>
      </div>
      <div class="content-page pt-5">
        <ModalSequence
          v-if="isCardModalActive"
          :sequence.sync="numberSequence"
          @close="closeModal"
        />
        <section class="columns is-multiline">
          <div
            class="column is-full is-half-mobile is-one-third-tablet is-4-desktop"
            v-for="(sequence, index) of sequences"
            :key="index"
            @click="(numberSequence = sequence) && (isCardModalActive = true)"
          >
            <div
              class="
                card_item
                button
                is-block is-flex
                justify-content-center
                align-items-center
              "
              animation="slide"
              :open="isOpen == index"
              @open="isOpen = index"
            >
              <div @click="isActive = !isActive" class="">
                <div role="button">
                  <div class="is-flex is-flex-direction-column">
                    <img v-if="sequence.icon"
                      :src="sequence.icon.path"
                      alt=""
                      width="90"
                      class="mx-auto mb-4"
                    />
                    <div class="title">{{ sequence.name }}</div>
                  </div>
                  <div class="">
                    {{ sequence.activities.length }} activité<span
                      v-if="sequence.activities.length > 1"
                      >s</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import AppAccountMenu from '@/components/AppAccountMenu.vue';
import AppSidebar from '@/components/AppSidebar.vue';
import ModalSequence from '@/components/Wiki/ModalSequence.vue';

export default ({
  components: {
    AppAccountMenu,
    AppSidebar,
    ModalSequence,
  },
  data() {
    return {
      isActive: false,
      isLoading: false,
      isFullPage: false,
      isOpen: null,
      sequences: [],
      crumbs: [{
        text: 'Maquettes',
        href: '/maquettes',
        class: 'is-active',
      },
      ],
      isCardModalActive: false,
      numberSequence: Object,
    };
  },
  methods: {
    cardModal() {
      this.$buefy.modal.open({
        parent: this,
        component: this.ModalSequence,
        hasModalCard: true,
        customClass: 'custom-class custom-class-2',
        trapFocus: true,
      });
    },
    closeModal() {
      this.isCardModalActive = false;
    },
  },

  mounted() {
    axios.get('https://insituapp.io/api/wiki/sequences')
      .then((response) => {
        this.sequences = response.data;
      })
      .catch((error) => {
        window.console.log(error);
        this.errored = true;
      });
  },
});
</script>

<style scoped lang="scss">
.content {
  margin-bottom: 2rem;
}
.card_item {
  margin-bottom: 0rem;
  text-align: center;
  padding: 1rem;
  box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.08);
  transition: all 0.3s cubic-bezier(0, 0, 0.5, 1);
  border-radius: 18px;
  // height:150px;
  height: 250px;
  border-width: 0px;
  .title {
    // color: #531be5;
    font-size: 1.3rem;
    text-align: center;
    margin-bottom: 0.4rem;
    &:hover {
      color: #531be5;
    }
  }

  ul {
    list-style-type: disc;
    list-style-position: outside;
    margin-left: 2rem;
  }
}
.active {
  width: 100% !important;
}
.inactive {
  width: unset;
}
</style>
