var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.studentTrainings)?_c('div',{staticClass:"mb-5"},[_c('div',{staticClass:"mb-4"},[_c('b-tag',{attrs:{"type":"is-primary is-light mr-2"}},[_vm._v(_vm._s(_vm.studentTrainings.length))]),_c('b',{staticClass:"has-text-primary is-size-6"},[_vm._v("Parcours suivis")])],1),_vm._l((_vm.order(_vm.studentTrainings)),function(training){return _c('div',{key:training.id},[_c('router-link',{attrs:{"to":'/formation-apprenant/' +
        training.training.id +
        '/' +
        training.student +
        '/' +
        training.id +
        '/parcours'}},[(!training.finished)?_c('div',{staticClass:"card_item mb-1"},[_c('div',[_c('b',{staticClass:"has-text-primary"},[_vm._v(" "+_vm._s(training.student_profile)+" ")])]),_c('div',{staticClass:"title-card"},[_c('b',{staticClass:"has-text-black"},[_vm._v(_vm._s(training.training.name)+" ")]),(training.option)?_c('b-tag',{staticClass:"ml-auto",attrs:{"type":"is-outlined"}},[_vm._v(" "+_vm._s(training.option.title)+" ")]):_vm._e()],1),_c('div'),_vm._l((training.tutors_profiles),function(tutor,index){return _c('div',{key:index,staticClass:"is-clickable is-tutor"},[_c('div',{staticClass:"is-underlined has-text-primary",attrs:{"type":"is-text"}},[_vm._v(" tuteur : "+_vm._s(tutor)+" ")]),(training.start_date)?_c('span',{staticStyle:{"font-size":"0.9rem"}},[_c('span',{staticClass:"is-underlined"},[_vm._v("début")]),_vm._v(" : "+_vm._s(_vm._f("day")(training.start_date))+" / "),_c('span',{staticClass:"is-underlined"},[_vm._v("fin")]),_vm._v(" : "+_vm._s(_vm._f("day")(training.end_date)))]):_vm._e()])})],2):_vm._e()])],1)})],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }