<template>
  <div class="is-flex">
    <!-- eslint-disable max-len-->
    <AppSidebar />
    <div class="main-content">
      <b-loading :is-full-page="isFullPage" v-model="isLoading"> </b-loading>
      <div class="lineCrumbAndAccount">
        <AppBreadcrumb v-bind:crumbs="crumbs" />
        <AppAccountMenu v-bind:appAccount="crumbs" />
      </div>
      <div class="header-page is-flex flex-column">
        <div class="justify-content-between columns lineHeaderTitle is-vcentered">
          <div class="column">
            <div class="title-page long-title">{{ training.name }}</div>
            <div class="subtitle-infos" v-if="currentUser.role !== 'apprenant' &&
        currentUser.role !== 'tuteur'
        ">
              <div>
                <span class="created">Formation créée le :</span>
                {{ training.date_created | moment }}
              </div>
              <div>
                <span class="created">Modifiée le :</span>
                {{ training.date_updated | moment }}
              </div>
            </div>
            <div class="mt-3">
              <span v-if="training.active === 1" class="tag is-success">active</span>
            </div>
            <span v-if="training.active === 0" class="tag inactif">inactive</span>
          </div>
          <div class="column is-narrow actions" v-if="currentUser.role !== ('apprenant' || 'tuteur')">
            <b-button @click.prevent="seeStudentTraining()" v-if="currentUser.role === 'apprenant'"
              type="is-primary is-rounded mr-3" rounded>voir mon parcours de formation
            </b-button>
            <b-button @click.prevent="editTraining()" v-if="currentUser.role === 'coordinateur' ||
        (currentUser.role === 'formateur' &&
          training.teachers_can_edit) ||
        currentUser.role === 'superviseur'
        " type="is-primary is-rounded mr-3" rounded>modifier</b-button>
          </div>
        </div>
        <div v-if="training">
          <b-message type="is-danger" v-if="training.finished" class="mb-0">Attention, cette période de formation est
            indiquée comme
            terminée</b-message>
        </div>
      </div>
      <section class="mt-auto" id="header-tabs">
        <div class="b-tabs">
          <nav class="tabs">
            <ul>
              <li v-for="(tab, index) of tabs" v-bind:key="index" v-bind:id="tab.id"
                :class="currentTab === tab.slug ? 'is-active' : ''">
                <a @click="gotoStep(tab.slug)" v-if="tab.rights.includes(currentUser.role) ||
        currentUser.role === 'coordinateur' ||
        currentUser.role === 'formateur' ||
        currentUser.role === 'superviseur'
        "><span>{{ tab.name }}</span></a>
              </li>
              <li :class="currentTab === 'resources' ? 'is-active' : ''">
                <a @click="gotoResources"><span>Ressources</span></a>
              </li>
            </ul>
          </nav>
        </div>
      </section>
      <div v-if="training.id">
        <!-- session -->
        <section id="stepone" v-if="step == 'session'" class="content-page">
          <div class="helpUser mt-0" v-if="currentUser.role === 'coordinateur' ||
        currentUser.role === 'formateur' ||
        currentUser.role === 'superviseur'
        ">
            <div class="title">Cette formation est en lecture seule</div>
            <div>
              Vous pouvez l'éditer en cliquant sur le bouton "modifier" en haut
              à droite.
            </div>
          </div>
          <div class="columns" id="presentationTraining">
            <div class="column">
              <div class="card_item h-100 is-flex flex-column">
                <div class="title-paragraph mt-auto">Dates de formation</div>
                <div class="mb-auto">
                  <span class="subtitle-paragraph">Date de début : </span>
                  {{ training.start_date | moment }} <br />
                  <span class="subtitle-paragraph"> Date de fin :</span>
                  {{ training.end_date | moment }}
                </div>
              </div>
            </div>
            <div class="column" v-if="currentUser.role === 'coordinateur' ||
        currentUser.role === 'superviseur'
        ">
              <div class="card_item card_home h-100 is-flex is-flex-direction-column">
                <div class="mt-auto title-paragraph mb-5">
                  Modèle de référence
                </div>
                <div class="mb-auto">
                  <b-button tag="router-link" v-if="currentUser.role === 'coordinateur' ||
        (currentUser.role === 'formateur' &&
          training.teachers_can_edit) ||
        currentUser.role === 'superviseur'
        " :to="'/maquettes/' + training.legacy_training_framework" type="is-primary is-rounded is-link"
                    class="is-inline" rounded>Voir la maquette</b-button>
                </div>
              </div>
            </div>
            <div class="column has-text-centered" v-if="currentUser.role === 'apprenant'">
              <div class="card_item card_home h-100 is-flex btn" @click.prevent="seeStudentTraining()">
                <div class="mt-auto mb-auto mr-auto ml-auto">
                  <b-icon icon="training"></b-icon>
                  <div class="title-paragraph mb-4">
                    Voir mon parcours de formation
                  </div>
                  <div></div>
                </div>
              </div>
            </div>
          </div>
          <div class="card_item" id="statusCard" v-if="currentUser.role != 'apprenant'">
            <div class="columns justify-content-between">
              <div class="column title-paragraph mb-3 is-flex align-items-center">
                <div class="mr-3">Statut</div>

                <span v-if="training.active === 1" class="tag is-success">active</span>

                <span v-if="training.active === 0" class="tag inactif">inactive</span>
              </div>
              <div class="column is-narrow">
                <span v-if="training.active === 1">
                  <b-button @click="closeTraining()" type="is-danger is-rounded mr-3" outlined v-if="currentUser.role === 'coordinateur' ||
        currentUser.role === 'formateur' ||
        currentUser.role === 'superviseur'
        ">clore la formation</b-button>
                </span>
                <span v-if="training.active === 0">
                  <b-button @click="reopenTraining()" type="is-primary is-rounded mr-3" outlined v-if="currentUser.role === 'coordinateur' ||
        currentUser.role === 'formateur' ||
        currentUser.role === 'superviseur'
        ">réactiver la formation</b-button>
                </span>
              </div>
            </div>
          </div>
          <div class="title-paragraph">Objectifs</div>
          <div class="field ck" v-html="training.goals"></div>
          <div class="title-paragraph">Compétences</div>
          <div class="field ck" v-html="training.skills"></div>
        </section>
        <!-- parcours -->
        <section id="steptwo" v-if="step == 'parcours'" class="content-page">
          <div v-if="!training.training_periods.length" class="no-user">
            Aucune expérience enregistrée
          </div>
          <div v-if="training.training_periods.length">
            <div class="userCount">Parcours pédagogique</div>
            <div class="helpUser mt-0">
              <div class="title">
                Voici le parcours pédagogique de votre formation
              </div>
              <div>
                Il s'agit du parcours général de votre formation. Certains
                ajustements sont apportés par apprenant.e. Pour accéder au
                parcours spécifique de chaque apprenant.e, allez dans la partie
                <span @click="gotoStep(4)" class="url-simple">"suivi apprenant.e.s".</span>
              </div>
            </div>
            <div class="mb-5">
              <b-button rounded @click="isOpenPeriod = true" v-if="isOpenPeriod === false" size="is-small">ouvrir
                tout</b-button>
              <b-button rounded @click="isOpenPeriod = false" v-if="isOpenPeriod === true" size="is-small">fermer
                tout</b-button>
            </div>
            <div class="simpleParcours">
              <b-collapse class="card" animation="slide" v-for="(period, index) of training.training_periods"
                v-bind:key="index" :open="isOpenPeriod">
                <div slot="trigger" slot-scope="props" class="card-header" role="button">
                  <div class="bg-card is-flex bg-primary align-items-center" :class="'periodType' + period.type">
                    <a class="card-header-icon">
                      <b-icon v-bind:icon="props.open ? 'menu-down' : 'menu-up'">
                      </b-icon>
                    </a>
                    <p class="card-header-title">
                      {{ period.name }}
                    </p>
                  </div>
                </div>
                <div class="card-content">
                  <div class="pointer card-edit-sequence is-flex align-items-center w-100"
                    v-for="sequence in order(period.training_sequences)" v-bind:key="sequence.id"
                    @click.prevent="seeSequencedetail(sequence.id)">
                    <div class="bloc-left is-flex mr-auto align-items-center">
                      <div class="sequenceType situation justify-content-center" type="situation"
                        :class="'sequenceType' + sequence.sequence_type">
                        {{ sequence.sequence_type_label }}
                      </div>

                      <div class="card-header-title">{{ sequence.name }}</div>
                    </div>
                    <div v-if="sequence.teachers.length" class="teacherSequence pr-4 has-text-centered is-flex">
                      <div>
                        <b>Formateur<span v-if="sequence.teachers.length > 1">s</span>
                          :</b>
                      </div>
                      <div v-for="teacher in sequence.teachers" :key="teacher.id">
                        <!-- Todo: add teachers sequence name-->
                      </div>
                    </div>
                    <div v-else></div>
                    <div class="actions is-flex align-items-center">
                      <div class="dates-schedulded has-text-centered is-flex">
                        <div v-if="sequence.start_date" class="has-text-centered">
                          <div class="date-sequence" v-if="sequence.start_date">
                            {{ sequence.start_date | day }}
                          </div>
                        </div>
                        <div v-if="sequence.end_date" class="has-text-centered">
                          <div class="date-sequence" v-if="sequence.end_date">
                            {{ sequence.end_date | day }}
                          </div>
                        </div>
                        <div v-else class="date-sequence has-text-centered">
                          -
                        </div>
                      </div>
                      <div class="is-flex align-items-center justify-content-between row-responsive"></div>
                      <div class="actions-buttons is-flex align-items-center">
                        <a class="actions-icon">
                          <b-icon icon="see" size="is-medium" type="is-black">
                          </b-icon>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </b-collapse>
            </div>
          </div>
        </section>
        <!-- users -->
        <section id="stepthree" v-if="step == 'users'">
          <TrainingsUsersRead />
        </section>
        <!-- by student -->
        <section id="stepfour" class="content-page" v-if="step == 'suivi'">
          <StudentsTrainings @messageFromChild="gotoStep('skills')" />
        </section>
        <section id="agenda" class="content-page" v-if="step == 'agenda'">
          <div v-if="training.editable_without_dates">
            <div class="userCount mb-4">Agenda</div>
            <div class="subtitle-paragraph">
              Retrouvez ici les dates clés de la formation
            </div>
            <div v-html="training.description" v-if="training.description"></div>
            <b-message type="is-info" v-else>Aucune date n'a été saisie.</b-message>
          </div>
          <AgendaStudent v-else :dates="dates" :global="true" :step="step" :key="planningKey" />
        </section>
        <section id="stepfour" class="content-page" v-if="step == 'skills'">
          <SkillsRead :typeoftraining="'training'" :objectId="training.id" />
        </section>
        <section id="stepsix" class="content-page" v-if="step == 'resources'">
          <div class="mb-5">
            <h1 class="title-paragraph">
              Ressources associées à cette formation
            </h1>
          </div>
          <div v-if="currentUser.role !== 'apprenant'">
            <div class="helpUser mt-5">
              <div class="title">Ajoutez des ressources</div>
              <div>
                Ici vous pouvez ajouter des documents à cette formation. Dans
                l'édition du parcours vous pouvez également ajouter des
                ressources à certaines expériences ou activités.
              </div>
            </div>
            <div class="card_simple is-primary w-100 has-text-centered" @click="isOpen = !isOpen">
              <div>
                <b-icon icon="resource" type="is-primary" class="mr-3"></b-icon>
                <b style="cursor: pointer">Ajoutez une ressource</b>
              </div>
            </div>
            <b-collapse v-model="isOpen">
              <ResourceCreation :sequence="null" :typeoftraining="'training'" :idTraining="training.id"
                :idResource="null" :action="'post'" :isOpen.sync="isOpen" @messageFromChild="initResources"
                @messageFromResource="forceRerender" :key="resourceKey"></ResourceCreation>
            </b-collapse>
          </div>
          <div v-if="resources">
            <div v-if="resources.length">
              <div v-for="resource in resources" :key="resource.id">
                <ResourceCard :resource="resource" :action="'edit'" @messageFromChild="initResources"></ResourceCard>
              </div>
            </div>
            <b-message v-else type="is-info">Aucun document n'est associé à cette formation</b-message>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import localization from 'moment/locale/fr';
import _ from 'lodash';
import { mapState } from 'vuex';
import AppSidebar from '@/components/AppSidebar.vue';
import AppBreadcrumb from '@/components/AppBreadcrumb.vue';
import AppAccountMenu from '@/components/AppAccountMenu.vue';
import TrainingsUsersRead from '@/components/Trainings/TrainingsUsersRead.vue';
import StudentsTrainings from '@/components/Trainings/StudentsTrainings.vue';
import ResourceCard from '@/components/Resources/ResourceCard.vue';
import AgendaStudent from '@/components/Agenda/AgendaStudent.vue';
import ResourceCreation from '@/components/Resources/ResourceCreation.vue';
import SkillsRead from '@/components/Skills/SkillsRead.vue';

moment.updateLocale('fr', localization);

export default {
  components: {
    AppSidebar,
    AppBreadcrumb,
    AppAccountMenu,
    TrainingsUsersRead,
    StudentsTrainings,
    ResourceCard,
    AgendaStudent,
    ResourceCreation,
    SkillsRead,
  },
  filters: {
    moment(date) {
      return moment(date).format('D MMMM YYYY');
    },
    day(date) {
      return moment(date).format('D/MM/YY');
    },
    hour(date) {
      return moment(date).format('HH:mm');
    },
  },
  data() {
    return {
      dates: [],
      training: [],
      sequencetypes: [],
      studenttraining: [],
      isLoading: true,
      isFullPage: false,
      // variable for b-collapse
      isOpenPeriod: true,
      needConfirmation: false,
      areYouSure: false,
      // declaration variables for breadcrumb
      crumbs: [
        {
          text: 'Formations',
          href: '/formations',
        },
        {
          text: 'Consulter une formation',
          class: 'is-active',
        },
      ],
      // declaration variables for tabs
      step: 1,
      currentTab: 1,
      tabs: [
        {
          name: 'Session',
          slug: 'session',
          id: '1',
          rights: ['manager', 'tuteur', 'apprenant'],
        },
        {
          name: 'Parcours',
          slug: 'parcours',
          id: '2',
          rights: [],
        },
        {
          name: 'Utilisateurs',
          slug: 'users',
          id: '3',
          rights: ['manager'],
        },
        {
          name: 'Suivi apprenants',
          href: '/maquettes',
          slug: 'suivi',
          rights: ['manager', 'tuteur'],
        },
        {
          name: 'Agenda',
          slug: 'agenda',
          rights: ['manager', 'tuteur'],
        },
        {
          name: 'Compétences',
          slug: 'skills',
          rights: ['manager', 'tuteur', 'apprenant'],
        },
      ],
      resources: null,
      planningKey: 0,
      resourceKey: 0,
      isOpen: false,
      skillsTraining: [],
      skillsLevelsTraining: [],
    };
  },
  computed: {
    users() {
      return this.$store.state.users;
    },
    ...mapState('auth', ['currentUser']),
  },
  methods: {
    // to load the trainig for all users
    verifStatusTraining() {
      if (this.training.active === 0) {
        this.$buefy.dialog.alert({
          title: 'Désolé, cette formation a été désactivée pour le moment',
          message: 'Cette formation à laquelle vous avez été inscrit•e est pour le moment désactivée. <br/>S\'il s\'agit d\'une formation que vous suivez actuellement, <b>un email ou une personne de votre CFA, vous informera quand elle sera de nouveau accessible.</b>',
          confirmText: 'Fermer',
          type: 'is-danger',
          hasIcon: true,
          onConfirm: () => {
            this.$router.push({
              name: 'Dashboard',
            });
          },
        });
      }
    },
    initTraining() {
      this.$http
        .get(`/trainings/${this.$route.params.idTraining}/?query={*,training_periods{*,training_sequences{-training_activities}}}&format=json`)
        .then((response) => {
          this.training = response.data;
          if (this.currentUser.role === 'apprenant' || this.currentUser.role === 'manager' || this.currentUser.role === 'tuteur') {
            this.verifStatusTraining();
          }
          this.training.training_periods = this.order(this.training.training_periods);
          this.endLoading();
        });
    },
    // to get student training of a student - for a student only
    getStudentTraining() {
      this.$http
        .get(`/trainings/${this.$route.params.idTraining}/?student=${this.currentUser.role.id}&query={id}&format=json`)
        .then((response) => {
          this.studenttraining = response.data;
        });
    },
    // to go to page edition
    editTraining() {
      this.$router.push({
        name: 'TrainingEdit',
        params: { id: this.$route.params.id, tab: this.step },
      });
    },
    // to change status and put training inactive
    closeTraining() {
      this.$buefy.dialog.confirm({
        title: 'Attention, vous êtes sur le point de désactiver cette formation',
        message: 'Nous vous invitons à vérifier cette action, car elle rendra définitivement inactive la formation, et rendra impossibles toutes les actions en cours des utilisateurs qui sont liées à des formations. Êtes vous sûr.e de vouloir clore la formation ?',
        confirmText: 'Désactiver',
        cancelText: 'Annuler',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.$http
            .patch(`/trainings/${this.$route.params.idTraining}/`, {
              active: 0,
            }).then(() => {
              this.$buefy.toast.open({ message: 'La formation est désactivée', type: 'is-success' });
              this.$router.push({
                name: 'Trainings',
              });
            }).catch((error) => {
              window.console.log(error);
              this.errored = true;
            });
        },
      });
    },
    reopenTraining() {
      this.$buefy.dialog.confirm({
        title: 'Attention, vous êtes sur le point de résactiver cette formation',
        message: 'Elle sera de nouveau accessible aux formateurs.',
        confirmText: 'Réactiver',
        cancelText: 'Annuler',
        type: 'is-success',
        onConfirm: () => {
          this.$http
            .patch(`/trainings/${this.$route.params.idTraining}/`, {
              active: 1,
            }).then(() => {
              this.initTraining();
              this.$buefy.toast.open({ message: 'La formation est réactivée', type: 'is-success' });
            }).catch((error) => {
              window.console.log(error);
              this.errored = true;
            });
        },
      });
    },
    gotoStep(numberStep) {
      this.currentTab = numberStep;
      this.step = numberStep;
      if (numberStep === 'agenda') {
        this.initDates();
      }
      this.gotoTab(numberStep);
    },
    gotoTab(slug) {
      if (this.$route.path !== `/formations/${this.$route.params.idTraining}/${slug}`) {
        this.$router.push({
          name: 'Training',
          params: {
            tab: slug,
            idTraining: this.$route.params.idTraining,
          },
        }).catch((error) => {
          window.console.log(error);
        });
      }
    },
    handleHistoryChange() {
      this.gotoStep(this.$route.params.tab);
    },
    initSequencetypes() {
      // load sequences type to get name and infos
      this.$http
        .get('/sequencetypes/?format=json')
        .then((response) => {
          this.sequencetypes = response.data;
        });
    },
    order(array) {
      return _.orderBy(array, 'position');
    },
    // function to redirect to sequence details page and pass id Training to component
    seeSequencedetail(param) {
      this.$router.push({
        name: 'TrainingSequence',
        params: { id: param, idTraining: this.$route.params.idTraining, stepNumber: this.step },
      }).catch((error) => {
        window.console.log(error);
        this.errored = true;
        this.$buefy.dialog.alert({
          title: 'Mince...',
          message: 'Nous ne pouvons accéder au détail de l\'expérience',
          type: 'is-info',
          hasIcon: true,
          icon: 'times-circle',
          ariaRole: 'alertdialog',
          ariaModal: true,
          confirmText: 'Ok',
        });
      });
    },
    // function to redirect to student training
    seeStudentTraining() {
      this.$http
        .get(`/agenda/student_training/?training=${this.$route.params.idTraining}&student=${this.currentUser.id}&query={id}&format=json`)
        .then((response) => {
          const idstudentTraining = response.data[0].id;
          this.$router.push({
            name: 'StudentTraining',
            params: {
              idTraining: this.$route.params.idTraining,
              idStudent: this.currentUser.id,
              idStudentTraining: idstudentTraining,
              tab: 'parcours',
            },
          });
        });
    },
    initResources() {
      this.startLoading();
      this.$http.get(`/get_resources/?training=${this.$route.params.idTraining}&format=json`).then((response) => {
        this.resources = response.data.resources;
        this.endLoading();
      });
    },
    startLoading() {
      this.isLoading = true;
    },
    endLoading() {
      this.isLoading = false;
    },
    gotoResources() {
      this.currentTab = 'resources';
      this.step = this.currentTab;
      this.initResources();
    },
    exportLivret() {
      this.$buefy.dialog.confirm({
        title: 'Exporter le livret',
        message: 'Vous pouvez exporter le livret d\'apprentissage au format PDF',
        confirmText: 'Exporter',
        cancelText: 'Annuler',
        type: 'is-info',
        onConfirm: () => {
          this.$http
            .get(`export_livret/?training_id=${this.$route.params.idTraining}&student_id=${this.currentUser.id}`, {
              responseType: 'blob',
            }).then((response) => {
              const username = (this.currentUser.name).toLowerCase();
              const usernameReplaced = username.replace(' ', '_');
              const fileURL = window.URL.createObjectURL(new Blob([response.data]));
              const fileLink = document.createElement('a');
              fileLink.href = fileURL;
              fileLink.setAttribute('download', `insitu_livret_${usernameReplaced}.pdf`);
              document.body.appendChild(fileLink);
              fileLink.click();
            });
        },
      });
    },
    forceRerenderPlanning() {
      this.planningKey += 1;
    },
    initDates() {
      const wsUrl = `/agenda/planning_global/?noactivities=1&sequences=1prev=true&training=${this.$route.params.idTraining}&format=json`;
      this.$http
        .get(wsUrl)
        .then((response) => {
          // this.endLoading();
          this.dates = response.data;
          this.startLoading();
          this.forceRerenderPlanning();
          this.endLoading();
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
          this.$buefy.dialog.confirm({
            title: 'Mince...',
            message: 'Une information n\'a pas pu être totalement chargée, nous vous invitons à recharger la page.',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
            onConfirm: () => document.location.reload(),
          });
        });
    },
    forceRerender() {
      // Every time that forceRerender is called, our prop componentKey will change.
      this.resourceKey += 1;
    },
    initSkills() {
      this.$http
        .get(`/trainingskills/?training=${this.training.id}`)
        .then((response) => {
          this.skillsTraining = response.data;
          this.$http
            .get(`/traininglevels/?training=${this.training.id}`)
            .then((resp) => {
              this.skillsLevelsTraining = resp.data;
              this.endLoading();
            });
        });
    },
  },
  created() {
    if (this.$route.params.tab) {
      this.gotoStep(this.$route.params.tab);
    } else {
      this.gotoStep('session');
    }
    this.initTraining();
  },
  mounted() {
    window.addEventListener('popstate', this.handleHistoryChange);
  },
  destroyed() {
    window.removeEventListener('popstate', this.handleHistoryChange);
  },
};

</script>
<style scoped>
.card_item.card_home {
  background-color: #eff4ff;
}
</style>
