<template>
  <div class="is-flex">
    <AppSidebar />
    <div class="main-content">
      <div class="lineCrumbAndAccount">
        <AppAccountMenu v-bind:appAccount="crumbs" />
      </div>
      <div class="header-page is-flex flex-column">
        <div class="justify-content-between columns lineHeaderTitle">
          <div class="column title-page short-title">
            {{ $route.meta.header_title }}
          </div>
          <div class="column is-narrow actions"></div>
        </div>
      </div>
      <div class="content-page pt-0" id="contentCalendar">
        <div
          class="columns is-desktop mb-3"
          v-if="currentUser.role != 'apprenant'"
        >
          <div class="column is-narrow">
            <b-select
              v-model="training_id"
              class="w-100"
              placeholder="Choisissez une formation"
              @input="initAgendaTraining(training_id)"
            >
              <option :value="'all'">Toutes les formations</option>
              <option
                v-for="training in trainings"
                :key="training.id"
                :value="training.id"
              >
                {{ training.name }}
              </option>
            </b-select>
          </div>
          <div
            class="column"
            v-if="training_id > 0 && currentUser.role != 'formateur'"
          >
            <b-select
              v-model="student_id"
              class="w-100"
              placeholder="Choisissez un étudiant attaché"
              @input="initAgendaStudent(student_id)"
            >
              <option
                v-for="student in availableStudents"
                :key="student.id"
                :value="student.id"
              >
                {{ student.name }}
              </option>
            </b-select>
          </div>
          <!-- <div class="column" v-if="training_id > 0">
            <b-select
              v-model="student_id"
              class="w-100"
              placeholder="Choisissez un tuteur attaché"
              @input="initAgendaUrl()"
            >
              <option
                v-for="student in availableTutors"
                :key="student.id"
                :value="student.id"
              >
                {{ student.name }}
              </option>
            </b-select>
          </div> -->
        </div>
        <!-- {{ dates.dates.length }} -->
        <div v-if="dates.dates">
          <b-message type="is-info" v-if="dates.dates.length === 0">
            Vous n'avez pas de dates programmées pour le moment.
            {{ endLoading() }}
          </b-message>
        </div>
        <section v-if="errored">
          <b-message type="is-danger">
            Nous sommes sincèrement désolés, nous ne sommes pas en mesure
            d'afficher l'agenda pour le moment. Veuillez réessayer
            ultérieurement ou nous contacter via le support.
          </b-message>
        </section>
        <b-loading :is-full-page="isFullPage" v-model="isLoading"> </b-loading>
        <FullCalendar
          class="demo-app-calendar"
          :options="calendarOptions"
          v-if="dates"
          :key="keyCalendar"
        >
          <template v-slot:eventContent="arg">
            <div :class="'date' + arg.event.extendedProps.type">
              <b>{{ arg.timeText }} </b>
              <span v-if="global === false">
                <span class="titleEvent">{{ arg.event.title }}</span>
                <span class="subtitleEvent">
                  {{ arg.event.extendedProps.sequence_name }}</span
                ></span
              >
              <span v-else>
                <span class="titleEvent">{{
                  arg.event.extendedProps.typelabel
                }}</span>
                <span class="subtitleEvent"> {{ arg.event.title }}</span>
              </span>
              <span v-if="typeview != 'student' && global === false">
                ({{ arg.event.extendedProps.student }})</span
              >
            </div>
          </template>
        </FullCalendar>
      </div>
    </div>
  </div>
</template>

<script>
import FullCalendar from '@fullcalendar/vue';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import frLocale from '@fullcalendar/core/locales/fr';
import { mapState } from 'vuex';
import AppAccountMenu from '@/components/AppAccountMenu.vue';
import AppSidebar from '@/components/AppSidebar.vue';

export default {
  components: {
    AppSidebar,
    AppAccountMenu,
    FullCalendar,
    // AgendaActivityDetail,
  },
  data() {
    return {
      viewScreen: 'screen',
      window: {
        width: 0,
        height: 0,
      },
      trainings: [],
      isLoading: false,
      isFullPage: false,
      errored: false,
      newevents: [
      ],
      calendarOptions: {
        plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin],
        weekends: false,
        initialView: 'dayGridMonth',
        // initialView: 'listWeek',
        height: 'auto',
        expandRows: false,
        locales: [frLocale],
        locale: 'fr',
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridDay,listWeek',
        },
        events: [],
        eventClick: this.handleEventClick,
        windowResize: this.handleResize,
      },
      crumbs: [
        {
          text: 'Formations',
          href: '/maquettes',
          class: 'is-active',
        },
      ],
      // variable to open modal on chnage false by true
      isComponentModalActive: false,
      idActivity: null,
      idStudentActivity: null,
      idStudentTraining: null,
      idStudentSequence: null,
      idTraining: null,
      SequenceType: 'SituationTypeDefault',
      color: 'default',
      nextDates: null,
      nameStudent: '',
      typeview: '',
      global: true,
      training_id: null,
      student_id: null,
      tutor_id: null,
      manager_id: null,
      teacher_id: null,
      keyCalendar: 0,
      availableStudents: [],
      availableTutors: [],
      availableManagers: [],
      usersArray: [],
      wsUrl: '',
    };
  },
  computed: {
    dates() {
      return this.$store.state.dates;
    },
    ...mapState('auth', ['currentUser']),
  },
  methods: {
    // function to load training agenda
    initAgendaTraining(idTraining) {
      this.global = true;
      let wsUrl = '';
      if (idTraining !== 'all') {
        wsUrl = `/agenda/planning_global/?noactivities=1&sequences=1prev=true&training=${idTraining}&format=json`;
        // if (this.currentUser.role === 'formateur') {
        //   wsUrl = `/agenda/planning_global/?prev=true&training=${idTraining}&format=json`;
        // } else {
        //   wsUrl = `/agenda/planning/?prev=true&training=${idTraining}&format=json`;
        // }
        this.createUsersArray(idTraining);
        this.student_id = null;
        this.tutor_id = null;
      } else {
        // wsUrl = '/agenda/planning/?prev=true&format=json';
        wsUrl = '/agenda/planning_global/?noactivities=1&sequences=1prev=true&format=json';
      }
      this.initAgenda(wsUrl);
    },
    // function to load a student agenda in a training
    initAgendaStudent(idStudent) {
      this.global = false;
      const wsUrl = `/agenda/planning/?prev=true&training=${this.training_id}&student=${idStudent}&format=json`;
      this.initAgenda(wsUrl);
    },
    // function to laod agenda
    initAgenda(url) {
      // verify if url param if not set default values
      let wsUrl = '';
      if (url === 0 && this.global === true) {
        wsUrl = '/agenda/planning_global/?prev=true&format=json';
      } else if (url === 0 && this.global === false) {
        wsUrl = '/agenda/planning/?prev=true&format=json';
      } else {
        wsUrl = url;
      }
      this.calendarOptions.events = [];
      this.startLoading();
      this.$http
        .get(wsUrl)
        .then((response) => {
          this.startLoading();
          this.$store.commit('GET_DATES', response.data);
          // this.nextDates = response.data;
          this.createEventsArray();
          this.forceRenderCalendar();
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
          this.$buefy.dialog.confirm({
            title: 'Mince...',
            message: 'Une information n\'a pas pu être totalement chargée, nous vous invitons à recharger la page.',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
            onConfirm: () => document.location.reload(),
          });
        });
    },
    createEventsArray() {
      // create an array to put together informations collected from different ways
      // for each item of students in training we push an line in array
      for (let i = 0; i < this.dates.dates.length; i += 1) {
        if (this.dates.dates[i].sequence_type != null) {
          if (Number(this.dates.dates[i].sequence_type) === 1
            || this.dates.dates[i].sequence_type === 2
            || this.dates.dates[i].sequence_type === 5) {
            this.SequenceType = '#eff4ff';
          } else if (Number(this.dates.dates[i].sequence_type) === 3
            || this.dates.dates[i].sequence_type === 6) {
            this.SequenceType = '#e5feff';
          } else if (Number(this.dates.dates[i].sequence_type) === 2) {
            this.SequenceType = '#fffce5';
          } else {
            this.SequenceType = '#eff4ff';
          }
        }
        this.calendarOptions.events.push(
          {
            title: this.dates.dates[i].name,
            start: this.dates.dates[i].start_date,
            end: this.dates.dates[i].end_date,
            color: this.SequenceType,
            type: this.dates.dates[i].sequence_type,
            typelabel: this.dates.dates[i].sequence_type_label,
            student: this.dates.dates[i].student_name,
            context: this.dates.dates[i].context,
            idactivity: this.dates.dates[i].student_activity,
            idsequence: this.dates.dates[i].student_sequence,
            sequence: this.dates.dates[i].sequence,
            idstudenttraining: this.dates.dates[i].student_training,
            idtraining: this.dates.dates[i].training,
            sequence_name: this.dates.dates[i].sequence_name,
          },
        );
        this.endLoading();
      }
    },
    createUsersArray(idTraining) {
      this.$http
        .get(`/trainings/${idTraining}/?query={students,teachers,managers,tutors}&format=json`)
        .then((response) => {
          this.usersArray = response.data;
          this.createStudentsArray();
          this.createTutorsArray();
        })
        .catch((error) => {
          window.console.log(error);
          this.errored = true;
          this.$buefy.dialog.alert({
            title: 'Mince...',
            message: 'Il y a pas un problème dans le chargement des utilisateurs associés',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
          });
        });
    },
    createStudentsArray() {
      this.availableStudents = [];
      for (let i = 0; i < this.usersArray.students.length; i += 1) {
        let username = '';
        this.$http
          .get(`/whois/${this.usersArray.students[i]}`)
          .then((response) => {
            username = response.data;
            this.availableStudents.push(
              {
                id: this.usersArray.students[i],
                name: username.name,
              },
            );
          })
          .catch((error) => {
            window.console.log(error);
            this.errored = true;
          });
      }
    },
    createTutorsArray() {
      this.availableTutors = [];
      for (let i = 0; i < this.usersArray.tutors.length; i += 1) {
        let username = '';
        this.$http
          .get(`/whois/${this.usersArray.tutors[i]}/&format=json`)
          .then((response) => {
            username = response.data;
            this.availableTutors.push(
              {
                id: this.usersArray.tutors[i],
                name: username.name,
              },
            );
          })
          .catch((error) => {
            window.console.log(error);
            this.errored = true;
          });
      }
    },

    // function to change field "color" to add styles to event
    // not wroking for now difficult to get type id of the parent sequence
    changeColor(classColor) {
      this.color = classColor;
    },
    // function at click
    handleEventClick(clickInfo) {
      if (!this.global) {
        this.$router.push({
          name: 'StudentActivityDetailEdit',
          params: {
            idActivity: Number(clickInfo.event.extendedProps.idactivity),
            idSequence: Number(clickInfo.event.extendedProps.idsequence),
            idTraining: Number(clickInfo.event.extendedProps.idtraining),
            idStudentTraining: Number(clickInfo.event.extendedProps.idstudenttraining),
            from: 'Agenda',
          },
        });
      } else {
        this.$router.push({
          name: 'TrainingSequence',
          params: {
            id: Number(clickInfo.event.extendedProps.sequence),
            idTraining: Number(clickInfo.event.extendedProps.idtraining),
            from: 'Agenda',
          },
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
          this.$buefy.dialog.alert({
            title: 'Mince...',
            message: 'Nous ne pouvons accéder au détail de l\'expérience',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
          });
        });
      }
      // this.isComponentModalActive = true;
      // this.$emit(AgendaActivityDetail, this.idActivity);
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
      if (this.window.width < 576 && this.viewScreen === 'screen') {
        this.calendarOptions.initialView = 'listWeek';
        this.viewScreen = 'mobile';
        this.forceRenderCalendar();
      } else if (this.window.width > 576 && this.viewScreen === 'mobile') {
        this.calendarOptions.initialView = 'dayGridMonth';
        this.viewScreen = 'screen';
        this.forceRenderCalendar();
      }
    },
    resize() {
      this.calendarOptions.initialView = 'listWeek';
      this.forceRenderCalendar();
    },
    endLoading() {
      this.isLoading = false;
    },
    startLoading() {
      this.isLoading = true;
    },
    initTrainings() {
      // this.startLoading();
      this.$http
        .get('/trainings/?query={id,name}&format=json')
        .then((response) => {
          // this.$store.commit('GET_TRAININGS', response.data);
          this.trainings = response.data;
          // this.endLoading();
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
        });
    },
    forceRenderCalendar() {
      this.keyCalendar += 1;
    },
  },
  created() {
    // this.initDates();
    if (this.dates.dates) {
      this.createEventsArray();
    } else {
      // this.initDates();
    }
    // list sequences only gloabl agenda of a trianig, or student activities
    if (this.currentUser.role === 'apprenant' || this.currentUser.role === 'tuteur' || this.currentUser.role === 'manager') {
      this.global = false;
    }
    // typeview student, to mask student name for a student
    if (this.currentUser.role === 'apprenant') {
      this.typeview = 'student';
      this.initAgenda(0);
    } else {
      this.initTrainings();
    }
  },
  mounted() {
  },
};

</script>
