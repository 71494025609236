<template>
  <div class="mt-5 pt-5">
    <b-loading :is-full-page="isFullPage" v-model="isLoading"> </b-loading>
    <b-field class="w-100" v-if="skillsBlock">
      <b-input
        placeholder="Rechercher..."
        type="search"
        icon="magnify"
        expanded
        v-model="search"
        @input="initSkills"
      >
      </b-input>
      <p class="control">
        <b-button
          type="is-primary"
          label="Rechercher"
          @click.prevent="initSkills"
        />
      </p>
    </b-field>
    <div v-if="skills.length">
      <div v-if="skillsBlock" class="pb-5">
        <div class="columns align-items-center mb-5 is-tablet">
          <div>
            <b-button
              rounded
              @click="isOpenPeriod = true"
              v-if="isOpenPeriod === false"
              size="is-small"
              >ouvrir tout</b-button
            >
            <b-button
              rounded
              @click="isOpenPeriod = false"
              v-if="isOpenPeriod === true"
              size="is-small"
              >fermer tout</b-button
            >
          </div>
          <div class="ml-auto">
            <b-button @click="displayAll" rounded>
              <span v-if="!allskills">Afficher toutes les compétences</span
              ><span v-else
                >Afficher les compétences évaluées uniquement</span
              ></b-button
            >
          </div>
        </div>

        <div>
          <b-collapse
            class="card"
            animation="slide"
            v-for="skillBlock in order(skills)"
            :key="skillBlock.name"
            :open="isOpenPeriod"
          >
            <div
              slot="trigger"
              slot-scope="props"
              class="card-header"
              role="button"
              v-if="skillBlock.skills"
            >
              <div class="bg-card is-flex bg-primary align-items-center">
                <a class="card-header-icon">
                  <b-icon v-bind:icon="props.open ? 'menu-down' : 'menu-up'">
                  </b-icon>
                </a>
                <p class="card-header-title">{{ skillBlock.name }}</p>
              </div>
            </div>
            <div class="card-content container-eval">
              <div
                v-for="(skill, indexSkill) in order(skillBlock.skills)"
                :key="skill.name"
                :name="'question' + indexSkill"
              >
                <div
                  class="columns is-6 is-6-desktop is-hidden-mobile"
                  v-if="indexSkill === 0 && skill.level_checked !== ''"
                >
                  <div
                    class="column has-text-primary head-skills is-6 is-6-desktop"
                  >
                    <b>Compétences</b>
                  </div>
                  <div class="column has-text-primary head-skills">
                    <b>Evaluation apprenant</b>
                  </div>
                </div>
                <div
                  v-if="allskills || skill.level_checked !== ''"
                  class="columns is-vcentered justify-space-between mb-5 pb-2 skillsRead"
                >
                  <div class="column name-skill is-6 is-6-desktop pb-1">
                    <b>{{ skill.name }}</b>
                    <b-collapse
                      v-if="skill.description"
                      :open="false"
                      position="is-bottom"
                      :aria-id="'content' + indexSkill"
                      animation="slide"
                      class="collapse-description"
                    >
                      <template #trigger="props">
                        <a
                          aria-controls="contentIdForA11y4"
                          :aria-expanded="props.open"
                        >
                          <b-icon
                            class="mr-1"
                            :icon="!props.open ? 'menu-down' : 'menu-up'"
                          ></b-icon>
                          {{ !props.open ? "Critères observables" : "Fermer" }}
                        </a>
                      </template>
                      <p style="white-space: break-spaces">
                        {{ skill.description }}
                      </p>
                    </b-collapse>
                  </div>
                  <div class="column pb-1 is-flex align-items-center">
                    <div
                      class="columns is-desktop levelsList is-vcentered pl-2"
                    >
                      <div
                        v-for="level in order(skill.levels)"
                        :key="level.id"
                        class="column level mr-5"
                      >
                        <b-icon
                          icon="check"
                          type="is-success"
                          v-if="level.evaluation.length"
                        ></b-icon>
                        <b-icon icon="close" class="disabled" v-else></b-icon>
                        <div class="level-label">{{ level.name }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-collapse>
        </div>
      </div>
      <div v-else>
        <div
          v-for="(skill, indexSkill) in order(skills)"
          :key="skill.name"
          :name="'question' + indexSkill"
        >
          <div
            class="columns is-6 is-6-desktop is-hidden-mobile"
            v-if="indexSkill === 0 && skill.level_checked !== ''"
          >
            <div class="column has-text-primary head-skills is-6 is-6-desktop">
              <b>Compétences</b>
            </div>
            <div class="column has-text-primary head-skills">
              <b>Evaluation apprenant</b>
            </div>
          </div>
          <div
            v-if="allskills || skill.level_checked !== ''"
            class="columns is-vcentered justify-space-between mb-5 pb-2 skillsRead"
          >
            <div class="column name-skill is-6 is-6-desktop pb-1">
              <b>{{ skill.name }}</b>
              <b-collapse
                v-if="skill.description"
                :open="false"
                position="is-bottom"
                :aria-id="'content' + indexSkill"
                animation="slide"
                class="collapse-description"
              >
                <template #trigger="props">
                  <a
                    aria-controls="contentIdForA11y4"
                    :aria-expanded="props.open"
                  >
                    <b-icon
                      class="mr-1"
                      :icon="!props.open ? 'menu-down' : 'menu-up'"
                    ></b-icon>
                    {{ !props.open ? "Critères observables" : "Fermer" }}
                  </a>
                </template>
                <p style="white-space: break-spaces">
                  {{ skill.description }}
                </p>
              </b-collapse>
            </div>
            <div class="column pb-1 is-flex align-items-center">
              <div class="columns is-desktop levelsList is-vcentered pl-2">
                <div
                  v-for="level in order(skill.levels)"
                  :key="level.id"
                  class="column level mr-5"
                >
                  <b-icon
                    icon="check"
                    type="is-success"
                    v-if="level.evaluation.length"
                  ></b-icon>
                  <b-icon icon="close" class="disabled" v-else></b-icon>
                  <div class="level-label">{{ level.name }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <b-message type="is-info" v-if="search"> Aucun résultat </b-message>
      <b-message type="is-info" v-else>Aucune compétence évaluée</b-message>
    </div>
    <!-- <div v-else>
      <b-message type="is-info">
        Aucune compétence n'est associée à cette formation, il n'y a donc aucune
        compétence à évaluer.
      </b-message>
    </div> -->
  </div>
</template>

<script>
import { mapState } from 'vuex';
import _ from 'lodash';

export default {
  props: {
    role: {
      type: Array,
    },
    skillsLevels: {
      type: [Object, Array],
    },
    studentTraining: {
      type: Number,
    },
    studentSequence: {
      type: Number,
    },
    type: {
      type: String,
    },
  },
  components: {
  },
  filters: {
  },
  data() {
    return {
      isLoading: false,
      isFullPage: false,
      formSkills: {},
      skills: [],
      roleEvaluation: '',
      roleArray: [],
      isOpenPeriod: true,
      skillsTutor: [],
      editSkill: false,
      search: null,
      allskills: false,
      skillsBlock: null,
    };
  },
  computed: {
    ...mapState('auth', ['currentUser']),
  },
  methods: {
    startLoading() {
      this.isLoading = true;
    },
    endLoading() {
      this.isLoading = false;
    },
    order(array) {
      return _.orderBy(array, 'position');
    },
    initSkills() {
      this.startLoading();
      let searchEntry = '';
      let block = '';
      let urlWs = '';
      let evaluations = '';
      if (this.search) {
        searchEntry = `&search=${this.search}`;
      }
      if (this.type === 'free') {
        this.skillsBlock = true;
      }
      if (this.skillsBlock === true) {
        block = '&block';
      }
      if (!this.allskills) {
        evaluations = '&evaluations';
      }
      if (this.type === 'progression') {
        urlWs = `/agenda/get_student_training_skills/?student_training=${this.$route.params.idStudentTraining}&role=${this.roleEvaluation}&revision=${this.$route.params.idRevision}&student_sequence=${this.$route.params.idSequence}${block}${searchEntry}${evaluations}`;
      } else if (this.type === 'free') {
        urlWs = `/agenda/get_student_training_skills/?student_training=${this.$route.params.idStudentTraining}&role=apprenant&free_situation=${this.$route.params.idFree}${block}${searchEntry}${evaluations}`;
      } else if (this.type === 'sequence') {
        urlWs = `/agenda/get_student_training_skills/?student_training=${this.$route.params.idStudentTraining}&role=${this.roleEvaluation}&student_sequence=${this.$route.params.idSequence}${block}${searchEntry}${evaluations}`;
      } else if (this.type === 'custom') {
        urlWs = `/agenda/get_student_training_skills/?student_training=${this.$route.params.idStudentTraining}&role=${this.roleEvaluation}&custom_experience=${this.$route.params.idCustom}&block=true${searchEntry}`;
        this.skillsBlock = true;
      }
      this.$http
        .get(`${urlWs}`)
        .then((response) => {
          this.skills = response.data;
          if (!this.skillsBlock) {
            if (this.skills.length) {
              this.skillsBlock = false;
            } else {
              this.skillsBlock = true;
              this.initSkills();
            }
          }
          this.endLoading();
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
        });
    },

    verifRole() {
      for (let i = 0; i < this.role.length; i += 1) {
        let writer = '';
        if (this.role[i].includes('w_')) {
          writer = this.role[i].replace('w_', '');
          this.roleArray.push(writer);
        }
        if (this.roleArray.includes('apprenant') && this.roleArray.includes('tuteur')) {
          this.roleEvaluation = 'tuteur,apprenant';
          this.initSkills();
        } else if (i === (this.role.length - 1)) {
          const role = this.roleArray[0];
          this.roleEvaluation = role;
          this.initSkills();
        }
      }
    },
    displayAll() {
      this.allskills = !this.allskills;
      this.initSkills();
    },
  },
  created() {
    if (this.role) {
      this.verifRole();
    } else {
      this.roleEvaluation = this.currentUser.role;
      this.initSkills();
    }
  },
  mounted() {
  },
};
</script>

<style scoped>
.disabled {
  color: #dfdfeb;
}
</style>
