<template>
  <div>
    <b-loading :is-full-page="isFullPage" v-model="isLoading"> </b-loading>
    <b-table
      v-if="practicalWorks.length > 0"
      :data="practicalWorks"
      ref="table"
      paginated
      per-page="10"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
      :mobile-cards="false"
    >
      <b-table-column label="Titre" v-slot="props" width="500">
        <b> {{ props.row.title }}</b>
        <div
          v-for="session in props.row.sessions"
          :key="session.id"
          @click="
            displayPracticalWorkDetail(
              props.row.id,
              session.id,
              session.students[0].student_training_practical_work_id
            )
          "
        >
          <div class="columns" v-if="session.status !== 0">
            <div class="column">
              <b-tag
                size="is-small"
                class="mr-3"
                :type="session.status === 2 ? 'is-success' : 'is-primary'"
              >
                {{ session.date | day }}</b-tag
              >

              <span class="has-text-primary">{{ session.title }} </span>/
            </div>
            <div class="column is-narrow">
              <div class="is-flex align-items-center">
                <span
                  :class="
                    session.students[0].evaluated && session.status === 2
                      ? 'has-text-success'
                      : 'has-text-danger'
                  "
                  style="font-size: 0.5em; margin-right: 0.5em"
                  >&#11044;</span
                >
                <span
                  v-if="session.students[0].evaluated && session.status === 2"
                >
                  évalué</span
                ><span v-else> non évalué</span>
                <b-icon icon="see" class="ml-5"></b-icon>
              </div>
            </div>
          </div>
        </div>
      </b-table-column>
    </b-table>
    <b-message v-else class="mt-5" type="is-warning"
      >Vous n'avez pas d'activité pratique programmée pour le moment</b-message
    >
  </div>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';
import localization from 'moment/locale/fr';
import _ from 'lodash';

moment.updateLocale('fr', localization);

export default {
  components: {
  },
  filters: {
    day(date) {
      return moment(date).format('D MMMM YYYY');
    },
    hour(date) {
      return moment(date).format('h:mm');
    },
  },
  data() {
    return {
      practicalWorks: [],
      openDetails: [], // array to store the opened rows
      defaultOpenedDetails: [],
      locale: 'UTC',
      isLoading: false,
      isFullPage: false,
      checkedRows: [],
      showDetailIcon: true,
      chosenPracticalWork: [],
      isPracticalWorkActive: false,
    };
  },
  computed: {
    ...mapState('auth', ['currentUser']),
    formattedDatetime() {
      return function formattedDatetime(date) {
        return moment.utc(date).local().locale('fr', localization).format('LL');
      };
    },
  },
  methods: {
    startLoading() {
      this.isLoading = true;
    },
    endLoading() {
      this.isLoading = false;
    },
    order(array) {
      return _.orderBy(array, 'position');
    },
    async getPracticalWorks() {
      this.startLoading();
      try {
        const response = await this.$http.get(`/practical_work/get_practical_works/?training=${this.$route.params.idTraining}&student=${this.$route.params.idStudent}&format=json`);
        this.practicalWorks = response.data;
        this.endLoading();
      } catch (error) {
        window.console.error(error);
        // handle the error as needed
      }
    },
    displayPracticalWorkDetail(tp, session, id) {
      this.$router.push({
        name: 'StudentPracticalWork',
        params: {
          idTraining: this.$route.params.idTraining,
          idStudent: this.$route.params.idStudent,
          idStudentTraining: this.$route.params.idStudentTraining,
          idPracticalWork: Number(tp),
          idStudentPracticalWork: Number(id),
          idSession: Number(session),
          from: this.$route.name,
        },
      });
    },
  },
  created() {
    // window.console.log(this.$route.params.idTraining);
  },
  mounted() {
    this.getPracticalWorks();
  },
  destroyed() {
  },
};
</script>

<style lang="scss" scoped>
</style>
