<template>
  <div>
    <div v-if="dates.dates">
      <b-message type="is-info" v-if="dates.dates.length === 0">
        Vous n'avez pas de dates programmées pour le moment.
        {{ endLoading() }}
      </b-message>
    </div>
    <section v-if="errored">
      <b-message type="is-danger">
        Nous sommes sincèrement désolés, nous ne sommes pas en mesure d'afficher
        l'agenda pour le moment. Veuillez réessayer ultérieurement ou nous
        contacter via le support.
      </b-message>
    </section>
    <b-loading :is-full-page="isFullPage" v-model="isLoading"> </b-loading>
    <FullCalendar
      class="demo-app-calendar"
      :options="calendarOptions"
      v-if="sequencetypes || studentsequence || dates"
    >
      <template v-slot:eventContent="arg">
        <!-- {{ changeColor(arg.event.extendedProps.type) }} -->
        <div :class="'date' + arg.event.extendedProps.type">
          <b>{{ arg.timeText }} </b>
          <span v-if="global === false">
            <span class="titleEvent">{{ arg.event.title }}</span>
            <span class="subtitleEvent">
              {{ arg.event.extendedProps.sequence_name }}</span
            ></span
          >
          <span v-else>
            <span class="titleEvent">{{
              arg.event.extendedProps.typelabel
            }}</span>
            <span class="subtitleEvent"> {{ arg.event.title }}</span>
          </span>
        </div>
      </template>
    </FullCalendar>
  </div>
</template>

<script>
import moment from 'moment';
import localization from 'moment/locale/fr';
import FullCalendar from '@fullcalendar/vue';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import frLocale from '@fullcalendar/core/locales/fr';
import { mapState } from 'vuex';

moment.updateLocale('fr', localization);

export default {
  props: {
    student: {
      type: Number,
    },
    studentTraining: {
      type: Number,
    },
    typeView: {
      type: String,
      // student
      // tutor
      // others
    },
    dates: [Object, Array],
    global: {
      type: Boolean,
    },
    step: [Number, String],
  },
  filters: {
    moment(date) {
      return moment(date).format('D MMMM YYYY');
    },
  },
  components: {
    FullCalendar,
  },
  computed: {
    // dates() {
    //   return this.$store.state.dates;
    // },
    sequencetypes() {
      return this.$store.state.sequencetypes;
    },
    studentsequence() {
      return this.$store.state.studentsequence;
    },
    ...mapState('auth', ['currentUser']),
  },
  methods: {
    createEventsArray() {
      // create an array to put together informations collected from different ways
      // for each item of students in training we push an line in array
      for (let i = 0; i < this.dates.dates.length; i += 1) {
        if (this.dates.dates[i].sequence_type != null) {
          if (Number(this.dates.dates[i].sequence_type) === 1
            || this.dates.dates[i].sequence_type === 2
            || this.dates.dates[i].sequence_type === 5) {
            this.SequenceType = '#eff4ff';
          } else if (Number(this.dates.dates[i].sequence_type) === 3
            || this.dates.dates[i].sequence_type === 6) {
            this.SequenceType = '#e5feff';
          } else if (Number(this.dates.dates[i].sequence_type) === 2) {
            this.SequenceType = '#fffce5';
          } else {
            this.SequenceType = '#eff4ff';
          }
        }
        // if (this.currentUser.role === 'apprenant') {
        //   this.nameStudent = '';
        // } else { this.nameStudent = this.dates.dates[i].student_name; }
        this.calendarOptions.events.push(
          {
            title: this.dates.dates[i].name,
            start: this.dates.dates[i].start_date,
            end: this.dates.dates[i].end_date,
            color: this.SequenceType,
            type: this.dates.dates[i].sequence_type,
            typelabel: this.dates.dates[i].sequence_type_label,
            student: this.dates.dates[i].student_name,
            context: this.dates.dates[i].context,
            idactivity: this.dates.dates[i].student_activity,
            idstudentsequence: this.dates.dates[i].student_sequence,
            sequence: this.dates.dates[i].sequence,
            idstudenttraining: this.dates.dates[i].student_training,
            idtraining: this.dates.dates[i].training,
            sequence_name: this.dates.dates[i].sequence_name,
          },
        );
        this.endLoading();
      }
    },
    // function to change field "color" to add styles to event
    // not wroking for now difficult to get type id of the parent sequence
    changeColor(classColor) {
      this.color = classColor;
    },
    // function at click
    handleEventClick(clickInfo) {
      if (!this.global) {
        this.$router.push({
          name: 'StudentActivityDetailEdit',
          params: {
            idActivity: Number(clickInfo.event.extendedProps.idactivity),
            idSequence: Number(clickInfo.event.extendedProps.idstudentsequence),
            idTraining: Number(clickInfo.event.extendedProps.idtraining),
            idStudentTraining: Number(clickInfo.event.extendedProps.idstudenttraining),
            from: this.$route.params.name,
          },
        });
      } else {
        this.$router.push({
          name: 'TrainingSequence',
          params: {
            id: Number(clickInfo.event.extendedProps.sequence),
            idTraining: Number(clickInfo.event.extendedProps.idtraining),
            from: this.$route.params.name,
            stepNumber: this.step,
          },
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
          this.$buefy.dialog.alert({
            title: 'Mince...',
            message: 'Nous ne pouvons accéder au détail de l\'expérience',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
          });
        });
      }
      // this.isComponentModalActive = true;
      // this.$emit(AgendaActivityDetail, this.idActivity);
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
      if (this.window.width < 576 && this.viewScreen === 'screen') {
        this.calendarOptions.initialView = 'listWeek';
        this.viewScreen = 'mobile';
        this.forceRenderCalendar();
      } else if (this.window.width > 576 && this.viewScreen === 'mobile') {
        this.calendarOptions.initialView = 'dayGridMonth';
        this.viewScreen = 'screen';
        this.forceRenderCalendar();
      }
    },
    resize() {
      this.calendarOptions.initialView = 'listWeek';
      this.forceRenderCalendar();
    },
    endLoading() {
      this.isLoading = false;
    },
    forceRenderCalendar() {
      this.keyCalendar += 1;
    },
  },
  data() {
    return {
      isLoading: true,
      isFullPage: false,
      errored: false,
      calendarOptions: {
        plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin],
        weekends: false,
        initialView: 'dayGridMonth',
        height: 'auto',
        expandRows: false,
        locales: [frLocale],
        locale: 'fr',
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridDay,listWeek',
        },
        events: [],
        eventClick: this.handleEventClick,
        windowResize: this.handleResize,
      },
      viewScreen: 'screen',
      window: {
        width: 0,
        height: 0,
      },
      keyCalendar: 0,
    };
  },
  mounted() {
  },
  created() {
    // this.createEventsArray();
    if (this.dates.dates) {
      this.createEventsArray();
    }
  },
};
</script>
<style lang="scss">
</style>
