<template>
  <div v-if="currentUser" class="account is-flex align-items-center ml-auto">
    <div class="user-block">
      <template v-if="currentUser.avatar">
        <img
          :src="currentUser.avatar"
          alt="Avatar"
          class="user-avatar mr-2"
          style="border-radius: 50%"
        />
      </template>
      <template v-else>
        <b-icon icon="user"></b-icon>
      </template>
      <span>
        {{ currentUser.name
        }}<span v-if="!isStudent">{{ getRoleLabelShort() }}</span>
      </span>
    </div>
    <AppNotifications />
    <button
      @click="logoutUser"
      class="button is-light is-outlined ml-3"
      style="background-color: transparent"
    >
      <b-icon
        icon="off"
        size="is-medium"
        type="is-danger"
        style="font-size: 1.3rem"
      ></b-icon>
    </button>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import AppNotifications from '@/components/AppNotifications.vue';

export default {
  name: 'AccountMenu',
  components: {
    AppNotifications,
  },
  props: {
    appAccount: {
      type: Array,
    },
  },
  computed: {
    ...mapState('auth', ['currentUser']),
    isStudent() {
      return this.currentUser.role === 'apprenant';
    },
  },
  methods: {
    ...mapActions('auth', ['logout']),
    logoutUser() {
      this.$buefy.dialog.confirm({
        title: 'Déconnexion',
        message: 'Vous êtes sur le point de vous déconnecter. Êtes-vous sûr(e) de vouloir continuer ?',
        confirmText: 'Me déconnecter',
        cancelText: 'Annuler',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.logout();
          this.$router.push({ name: 'Login' });
        },
      });
    },
    getRoleLabelShort() {
      return `, ${this.currentUser.role_label.substr(0, 12)}`;
    },
  },
};
</script>

<style scoped>
.user-block {
  text-align: right;
  margin-right: 0.5rem;
  line-height: 1.2;
  display: flex;
  align-items: center;
}

@media (max-width: 990px) {
  .user-block {
    font-size: 14px;
  }
}

@media (max-width: 576px) {
  .user-block {
    display: none;
  }
}

.button {
  cursor: pointer;
}

.user-avatar {
  width: 30px;
  height: 30px;
}
</style>
