<template>
  <div class="modalbox modalStep" id="waypointModal">
    <b-loading :is-full-page="isFullPage" v-model="isLoading"> </b-loading>
    <div class="is-flex bigmodal">
      <img
        class="btn-previous"
        :src="require('@/assets/img/arrows/previous.svg')"
        @click="goback"
      />
    </div>
    <section class="w-100 container">
      <div class="has-text-centered">
        <span class="tag is-success mr-0 w-auto" v-if="waypoint.validated"
          >validé</span
        >
        <span>
          <span
            class="tag is-success mr-0 w-auto ml-1"
            v-if="waypoint.read_by_tutor"
            >lu par le tuteur</span
          ><span v-else class="ml-1 tag is-warning mr-0 w-auto"
            >non lu</span
          ></span
        >
      </div>
      <div class="title-paragraph" v-if="waypoint.title">
        {{ waypoint.title }}
      </div>
      <div class="title-paragraph" v-else>Point comportement</div>

      <div class="has-text-centered dates mb-4">
        du {{ waypoint.start_date | day }} au {{ waypoint.end_date | day }}
        <span
          v-if="
            currentUser.role == 'coordinateur' ||
            currentUser.role == 'formateur'
          "
        >
          <b-button
            v-if="!modifyDates"
            @click="modifyDates = true"
            rounded
            size="is-small"
            >modifier</b-button
          >
          <span v-else class="mt-3">
            <b-button rounded size="is-small" @click="modifyDates = false"
              >fermer</b-button
            >
            <b-button
              rounded
              type="is-primary"
              size="is-small"
              @click="patchDates"
              >Enregistrer</b-button
            >
          </span></span
        >
        <div
          class="columns mt-3"
          v-if="
            modifyDates &&
            (currentUser.role == 'coordinateur' ||
              currentUser.role == 'formateur')
          "
        >
          <div class="column">
            <b-field
              class="mb-0 has-text-left"
              label="Date de début"
              :type="start_date > end_date ? 'is-danger' : ''"
            >
              <b-datepicker
                v-if="start_date"
                v-model="start_date"
                :first-day-of-week="1"
                placeholder="Cliquez pour sélectionner..."
                trap-focus
                @input="
                  verifdate(
                    new Date(waypoint.start_date),
                    new Date(waypoint.end_date)
                  )
                "
              >
                <b-button
                  label="Aujourd'hui"
                  type="is-primary"
                  icon-left="calendar-today"
                  @click="start_date = new Date()" /></b-datepicker
            ></b-field>
          </div>
          <div class="column">
            <b-field
              class="mb-0 has-text-left"
              label="Date de fin"
              :type="start_date > end_date ? 'is-danger' : ''"
            >
              <b-datepicker
                v-model="end_date"
                :first-day-of-week="1"
                placeholder="Cliquez pour sélectionner..."
                @input="verifdate(waypoint.start_date, waypoint.end_date)"
              >
                <b-button
                  label="Aujourd'hui"
                  type="is-primary"
                  icon-left="calendar-today"
                  @click="end_date = new Date()"
                /> </b-datepicker
            ></b-field>
          </div>
        </div>
        <b-message type="is-danger">
          <div v-if="start_date > end_date">
            Attention, il semble y a une incohérence entre la date de début et
            la date de fin. La date de début est postérieure à la date de fin.
          </div>
        </b-message>
        <b-message type="is-warning">
          <div v-if="start_date > new Date()">
            Attention, la date de début est dans le futur.
          </div>
          <div v-if="end_date > new Date()">
            La date de fin n'est pas encore passée. Vérifiez si c'est bien
            normal.
          </div></b-message
        >
      </div>
      <div class="title2">Objectif(s) du point</div>

      <div class="card_edit card_item" v-if="waypoint.goals">
        <div v-html="waypoint.goals"></div>
      </div>
      <b-message v-else>Aucun commentaire n'a été enregsitré</b-message>
      <div v-if="skills.length" class="has-text-centered reportAttitude">
        <div v-for="skill in order(skills)" :key="skill.id">
          <div class="is-vcentered justify-space-between skillsEdit pb-5 pt-5">
            <div class="">
              <div style="font-size: 1.2rem" class="mb-2">
                <b>{{ skill.attitude_name }}</b>
              </div>
              <div v-html="skill.attitude_description"></div>
            </div>
            <div class="pb-1">
              <!-- @input="postEvaluation(level.id, skill.id)" -->
              <div
                class="
                  columns
                  is-mobile
                  align-items-center
                  justify-content-center
                "
              >
                <div
                  class="
                    column
                    is-narrow
                    columns
                    is-mobile
                    levelsList
                    is-vcentered is-centered
                    pl-3
                    pr-3
                    mt-4
                  "
                  v-for="(level, index) in order(skillsLevelsTraining)"
                  :key="level.id"
                >
                  <b-icon
                    icon="check"
                    type="is-success"
                    v-if="skill.attitude_level === level.id"
                  ></b-icon>
                  <b-icon icon="close" class="disabled" v-else></b-icon>
                  <div
                    class="level-label ml-0"
                    :class="skill.attitude_level === level.id ? 'bold' : ''"
                  >
                    {{ index + 1 }}
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="skill.report"
              v-html="skill.report"
              class="card_edit card_item has-text-left mb-4 mt-4"
              style="min-height: 0px"
            ></div>
          </div>
        </div>
      </div>
      <!-- <div v-else>
        <b-message
          >Aucune évaluation de savoir-être n'a été enregistrée</b-message
        >
       </div> -->
      <div>
        <div class="title2 pt-5">
          Commentaires du tuteur ou maître d'apprentissage
        </div>
        <div
          v-if="waypoint.comment"
          v-html="waypoint.comment"
          class="card_edit card_item has-text-left mb-4 mt-4"
          style="min-height: 0px"
        ></div>
        <b-message v-else>Aucun commentaire n'a été enregistré</b-message>
      </div>
    </section>
    <div>
      <div
        class="is-flex is-flex-wrap-wrap activityBtns align-items-center"
        style="z-index: 2000"
        v-if="
          currentUser.role === 'tuteur' ||
          currentUser.role === 'formateur' ||
          currentUser.role === 'coordinateur'
        "
      >
        <!-- <b-button @click="$emit('close')" class="mr-auto" rounded
            ><b-icon icon="left" class="mr-3"></b-icon>retour</b-button
          > -->
        <div class="w-100 is-flex">
          <div class="mx-auto">
            <b-button
              @click="gotoWaypointEdit"
              class="btn-action w-auto mr-1"
              type="is-primary is-rounded is-outlined"
              rounded
              >modifier</b-button
            >
            <!-- <b-button
              @click="markasread"
              class="btn-action w-auto mr-1"
              type="is-success is-rounded"
              rounded
              v-if="currentUser.role === 'tuteur' && !waypoint.read_by_tutor"
              >marquer comme lu</b-button
            ><span
              class="has-text-success"
              v-if="currentUser.role === 'tuteur' && waypoint.read_by_tutor"
              ><b
                ><b-icon type="is-success" icon="check"></b-icon> Déjà marqué
                comme lu</b
              ></span
            > -->
          </div>
        </div>
        <div class="help-btn is-flex align-items-center">
          <b-button
            @click="removeWaypoint(waypoint.id)"
            class="btn-action w-auto mr-2"
            type="is-danger is-rounded"
            size="is-small"
            rounded
            v-if="
              currentUser.role == 'coordinateur' ||
              (currentUser.role == 'formateur' && !waypoint.validated)
            "
            >supprimer</b-button
          >
          <AppHelpMessage
            title="Vous ne pouvez pas modifier le point comportement"
            message="En effte, une fois enregistré, il n'est plus modifiable.
             Cependant, n'hésitez pas à nous contacter si cela vous pose problème."
            textBtn="Besoin d'aide ?"
          />
        </div>
      </div>
    </div>

    <div class="container contentSequence pb-5 mb-5"></div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';
import _ from 'lodash';
import AppHelpMessage from '@/components/AppHelpMessage.vue';

export default ({
  filters: {
    day(date) {
      return moment(date).format('D/MM/YYYY');
    },
  },
  components: {
    AppHelpMessage,
  },
  computed: {
    ...mapState('auth', ['currentUser']),
  },
  data() {
    return {
      isLoading: false,
      isFullPage: false,
      waypoint: [],
      arrayReports: [],
      skills: [],
      skillsLevelsTraining: [],
      modifyDates: false,
    };
  },
  methods: {
    startLoading() {
      this.isLoading = true;
    },
    endLoading() {
      this.isLoading = false;
    },
    order(array) {
      return _.orderBy(array, 'attitude_position');
    },
    initWaypoint() {
      // this.startLoading();
      const urlWs = `/agenda/student_training_attitude_waypoints/${this.$route.params.idWaypoint}/?format=json`;
      this.$http
        .get(`${urlWs}`)
        .then((response) => {
          this.waypoint = response.data;
          this.start_date = new Date(this.waypoint.start_date);
          this.end_date = new Date(this.waypoint.end_date);
          this.initSkills();
          this.endLoading();
        });
    },
    initSkills() {
      const urlWs = `/agenda/attitude_reports/?student_training_attitude_waypoint=${this.$route.params.idWaypoint}&format=json`;
      this.$http
        .get(`${urlWs}`)
        .then((response) => {
          this.skills = response.data;
          this.endLoading();
          this.$http
            .get(`/trainingattitude_level/?training=${this.$route.params.idTraining}&format=json`)
            .then((resp) => {
              this.skillsLevelsTraining = resp.data;
              this.endLoading();
            }).catch((error) => {
              window.console.log(error);
              this.errored = true;
            });
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
        });
    },
    goPreviousPage() {
      if (this.$route.params.from) {
        this.$router.push({
          name: this.$route.params.from,
          params: {
            idStudent: this.$route.params.idStudent, // need check id profile
            idTraining: this.$route.params.idTraining,
            idStudentTraining: this.$route.params.idStudentTraining,
          },
        });
      } else {
        this.$router.push({
          name: 'StudentTraining',
          params: {
            idStudent: this.$route.params.idStudent, // need check id profile
            idTraining: this.$route.params.idTraining,
            idStudentTraining: this.$route.params.idStudentTraining,
            tab: 'waypoint',
          },
        });
      }
    },
    goback() {
      this.$router.go(-1);
    },
    gotoWaypointAttitudeEdit() {
      this.$router.push({
        name: 'AttitudeEvaluationEdit',
        params: {
          idTraining: this.$route.params.idTraining,
          idStudent: this.$route.params.idStudent,
          idStudentTraining: this.$route.params.idStudentTraining,
          idWaypoint: this.$route.params.idWaypoint,
        },
      });
    },
    markasread() {
      this.$http
        .patch(`/agenda/student_training_attitude_waypoints/${this.$route.params.idWaypoint}/`, {
          read_by_tutor: true,
        }).then(() => {
          this.$buefy.toast.open({
            message: 'Le point a été enregistré', type: 'is-success',
          });
          this.goPreviousPage();
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
          this.$buefy.dialog.alert({
            title: 'Mince...',
            message: 'Le point n\'a pas pu être enregistré.',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
          });
        });
    },
    gotoWaypointEdit() {
      this.$router.push({
        name: 'AttitudeEvaluationEdit',
        params: {
          idTraining: this.$route.params.idTraining,
          idStudent: this.$route.params.idStudent,
          idStudentTraining: this.$route.params.idStudentTraining,
          idWaypoint: this.$route.params.idWaypoint,
        },
      });
    },
    removeWaypoint(id) {
      const urlWs = `/agenda/student_training_attitude_waypoints/${id}/`;
      this.$buefy.dialog.confirm({
        title: 'Supprimer un point d\'étape',
        message: 'Êtes-vous sûr de vouloir supprimer ce point ? Attention, vous perdrez tout le contenu que vous avez pu rédiger pour ce point.',
        confirmText: 'Supprimer',
        cancelText: 'Annuler',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.$http
            .delete(`${urlWs}`)
            .then(() => {
              this.$buefy.toast.open({ message: 'Point supprimé', type: 'is-success' });
              this.$router.push({
                name: 'StudentTraining',
                params: {
                  idStudent: this.$route.params.idStudent, // need check id profile
                  idTraining: this.$route.params.idTraining,
                  idStudentTraining: this.$route.params.idStudentTraining,
                  tab: 'waypoint',
                },
              });
            }).catch((error) => {
              window.console.log(error);
              this.errored = true;
              this.$buefy.dialog.alert({
                title: 'Mince...',
                message: 'Le point n\'a pas pu être supprimé.',
                type: 'is-info',
                hasIcon: true,
                icon: 'times-circle',
                ariaRole: 'alertdialog',
                ariaModal: true,
                confirmText: 'Ok',
              });
            });
        },
      });
    },
    verifdate(start, end) {
      if (this.start_date > this.end_date) {
        this.$buefy.dialog.confirm({
          title: 'Attention, il semble y avoir un problème de dates',
          message: 'La date de début est incohérente avec la date de fin',
          confirmText: 'Annuler',
          cancelText: 'Valider',
          type: 'is-danger',
          hasIcon: true,
          onConfirm: () => {
            this.start_date = start;
            this.end_date = end;
          },
        });
      } else if (this.start_date > new Date()) {
        this.$buefy.dialog.confirm({
          title: 'Attention, la date de début ne peut pas être postérieure à aujourd\'hui',
          message: 'Voulez-vous bien changer cette date ?',
          confirmText: 'Annuler',
          cancelText: 'Valider',
          type: 'is-danger',
          hasIcon: true,
          onConfirm: () => {
            this.start_date = start;
            this.end_date = end;
          },
        });
      }
    },
    patchDates() {
      this.$http
        .patch(`/agenda/student_training_attitude_waypoints/${this.$route.params.idWaypoint}/`, {
          start_date: this.start_date,
          end_date: this.end_date,
        }).then(() => {
          this.$buefy.toast.open({ message: 'Évaluation enregistrée', type: 'is-success' });
          this.initWaypoint();
          this.modifyDates = false;
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
        });
    },
    logActivity() {
      const typeAction = 'lecture waypoint attitude';
      this.$http
        .post('agenda/logs/', {
          action: typeAction,
          student_training: this.$route.params.idStudentTraining,
          training: this.$route.params.idTraining,
          student_activity: null,
          student_sequence: null,
        })
        .then(() => {
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
        });
    },
  },
  created() {
    this.startLoading();
    this.initWaypoint();
    if (this.currentUser.role === 'apprenant' || this.currentUser.role === 'tuteur') { this.logActivity(); }
  },
});
</script>

<style lang="scss" scoped>
</style>
