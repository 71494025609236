<template>
  <div class="is-flex">
    <AppSidebar />
    <div class="main-content">
      <div class="lineCrumbAndAccount">
        <AppAccountMenu v-bind:appAccount="crumbs" />
      </div>
      <div class="header-page is-flex flex-column">
        <div class="justify-content-between columns lineHeaderTitle">
          <div class="column title-page short-title">
            {{ $route.meta.header_title }}
          </div>
          <div class="column is-narrow actions"></div>
        </div>
      </div>
      <div class="content-page pt-0" id="contentCalendar">
        <div
          class="columns is-desktop mb-3"
          v-if="currentUser.role != 'apprenant'"
        >
          <div class="column is-narrow">
            <b-select
              v-model="training_id"
              class="w-100"
              placeholder="Choisissez une formation"
              @input="initAgendaTraining(training_id)"
            >
              <option :value="'all'">Toutes les formations</option>
              <option
                v-for="training in trainings"
                :key="training.id"
                :value="training.id"
              >
                {{ training.name }}
              </option>
            </b-select>
          </div>
          <div
            class="column"
            v-if="training_id > 0 && currentUser.role != 'formateur'"
          >
            <b-select
              v-model="student_id"
              class="w-100"
              placeholder="Choisissez un étudiant attaché"
              @input="initAgendaStudent(student_id)"
            >
              <option
                v-for="student in availableStudents"
                :key="student.id"
                :value="student.id"
              >
                {{ student.name }}
              </option>
            </b-select>
          </div>
        </div>
        <CalendarStudent
          :dates="dates"
          :typeView="'global'"
          :key="planningKey"
          @refreshCalendar="initAgendaTraining('all')"
        ></CalendarStudent>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import AppAccountMenu from '@/components/AppAccountMenu.vue';
import AppSidebar from '@/components/AppSidebar.vue';
import CalendarStudent from '@/components/Agenda/CalendarStudent.vue';

export default {
  components: {
    AppSidebar,
    AppAccountMenu,
    CalendarStudent,
    // AgendaActivityDetail,
  },
  data() {
    return {
      viewScreen: 'screen',
      window: {
        width: 0,
        height: 0,
      },
      trainings: [],
      isLoading: false,
      isFullPage: false,
      errored: false,
      newevents: [
      ],
      crumbs: [
        {
          text: 'Formations',
          href: '/maquettes',
          class: 'is-active',
        },
      ],
      // variable to open modal on chnage false by true
      isComponentModalActive: false,
      idActivity: null,
      idStudentActivity: null,
      idStudentTraining: null,
      idStudentSequence: null,
      idTraining: 'all',
      SequenceType: 'SituationTypeDefault',
      color: 'default',
      nextDates: null,
      nameStudent: '',
      typeview: '',
      global: true,
      training_id: null,
      student_id: null,
      tutor_id: null,
      manager_id: null,
      teacher_id: null,
      keyCalendar: 0,
      availableStudents: [],
      availableTutors: [],
      availableManagers: [],
      usersArray: [],
      wsUrl: '',
      planningKey: 0,
    };
  },
  computed: {
    dates() {
      return this.$store.state.dates;
    },
    ...mapState('auth', ['currentUser']),
  },
  methods: {
    // function to load training agenda
    initAgendaTraining(idTraining) {
      this.global = true;
      let wsUrl = '';
      if (idTraining !== 'all') {
        wsUrl = `/agenda/calendar/?training=${idTraining}&format=json`;
      } else {
        wsUrl = '/agenda/calendar/?format=json';
      }
      this.initAgenda(wsUrl);
    },
    // function to load a student agenda in a training
    initAgendaStudent(idStudent) {
      this.global = false;
      const wsUrl = `/agenda/calendar/?prev=true&training=${this.training_id}&student=${idStudent}&format=json`;
      this.initAgenda(wsUrl);
    },
    // function to laod agenda
    initAgenda(url) {
      // verify if url param if not set default values
      this.startLoading();
      this.$http
        .get(url)
        .then((response) => {
          this.$store.commit('GET_DATES', response.data);
          this.endLoading();
          if (this.dates.length > 0) {
            this.endLoading();
            this.forceRenderCalendar();
          }
          this.forceRenderCalendar();
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
          this.$buefy.dialog.confirm({
            title: 'Mince...',
            message: 'Une information n\'a pas pu être totalement chargée, nous vous invitons à recharger la page.',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
            onConfirm: () => document.location.reload(),
          });
        });
    },
    // function to change field "color" to add styles to event
    // not wroking for now difficult to get type id of the parent sequence
    changeColor(classColor) {
      this.color = classColor;
    },
    // function at click
    handleEventClick(clickInfo) {
      this.isWaypointModalActive = true;
      this.event = clickInfo.event;
      this.edit = false;
      // this.isComponentModalActive = true;
      // this.$emit(AgendaActivityDetail, this.idActivity);
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
      if (this.window.width < 576 && this.viewScreen === 'screen') {
        this.calendarOptions.initialView = 'listWeek';
        this.viewScreen = 'mobile';
        this.forceRenderCalendar();
      } else if (this.window.width > 576 && this.viewScreen === 'mobile') {
        this.calendarOptions.initialView = 'dayGridMonth';
        this.viewScreen = 'screen';
        this.forceRenderCalendar();
      }
    },
    resize() {
      this.calendarOptions.initialView = 'listWeek';
      this.forceRenderCalendar();
    },
    endLoading() {
      this.isLoading = false;
    },
    startLoading() {
      this.isLoading = true;
    },
    initTrainings() {
      // this.startLoading();
      this.$http
        .get('/trainings/?query={id,name}&format=json')
        .then((response) => {
          // this.$store.commit('GET_TRAININGS', response.data);
          this.trainings = response.data;
          // this.endLoading();
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
        });
    },
    forceRenderCalendar() {
      this.planningKey += 1;
    },
    dateClick(info) {
      this.newEvent = true;
      this.event = null;
      if (this.currentUser.role === 'formateur') {
        this.start_date = new Date(info.dateStr);
        this.end_date = new Date(info.dateStr);
        this.isWaypointModalActive = true;
      }
    },
  },
  created() {
    this.initAgendaTraining('all');
    this.initTrainings();
  },
  mounted() {
  },
};

</script>
