<template>
  <div id="">
    <b-loading :is-full-page="isFullPage" v-model="isLoading"> </b-loading>
    <b-modal v-model="isCardModalActive" scroll="keep" id="modalNude">
      <template #default="props">
        <Modalexport
          @close="props.close"
          :student="student"
          :student_id="$route.params.idStudent"
        ></Modalexport>
      </template>
    </b-modal>
    <div v-if="bookletTodo">
      <div v-if="waypointList.length">
        <div
          v-for="waypoint in waypointList"
          :key="waypoint.id"
          class="card_activity"
        >
          <div
            class="activity-item is-flex align-items-center"
            :class="waypoint.start_date > later ? 'step-later' : ''"
          >
            <!-- v-if="
                  waypoint.user_status_label === 'Effectué' ||
                  waypoint.user_status_label === 'Terminé' ||
                  waypoint.user_status_label === 'Valide'
                " -->
            <div>
              <img
                v-if="waypoint.user_status === 2"
                :src="require(`@/assets/img/steps/status3.svg`)"
                alt=""
                class="img_status mr-3"
              />
              <img
                v-else-if="waypoint.user_status === 1"
                :src="require(`@/assets/img/steps/status6.svg`)"
                alt=""
                class="img_status mr-3"
              />
              <img
                v-else-if="waypoint.user_status === 4"
                :src="require(`@/assets/img/steps/status-late.svg`)"
                alt=""
                class="img_status mr-3"
              />
              <img
                v-else
                :src="require(`@/assets/img/steps/status1.svg`)"
                alt=""
                class="img_status mr-3"
              />
            </div>
            <div class="columns is-tablet mb-0 ml-1 is-vcentered">
              <div>
                <div class="is-flex align-items-center mb-2">
                  <span
                    class="tag mr-2 is-narrow status3"
                    v-if="waypoint.user_status === 2"
                  >
                    {{ waypoint.user_status_label }}
                  </span>
                  <span
                    class="tag mr-2 is-narrow statusLate"
                    v-if="waypoint.user_status === 4"
                  >
                    En retard
                  </span>
                  <span
                    class="tag mr-2 is-narrow is-primary"
                    v-if="waypoint.user_status === 1"
                  >
                    En cours
                  </span>
                  <span
                    class="tag mr-2 is-narrow is-secondary is-outlined"
                    v-if="
                      waypoint.user_status === 0 ||
                      today > waypoint.start_datetime
                    "
                  >
                    A faire
                  </span>
                  <span class="tag is-outlined mr-2">
                    {{ waypoint.user_status_part_validated }}</span
                  >
                  <span class="dateItem"
                    >{{ waypoint.start_date | day }} -
                    {{ waypoint.due_datetime | day }}</span
                  >
                </div>

                <!-- <div class="title_activity pr-5">{{ waypoint.name }}</div> -->
                <div class="title_activity pr-5 mt-2">{{ waypoint.name }}</div>
                <div v-for="resource in waypoint.resources" :key="resource.id">
                  <div
                    v-if="
                      resource.attached_roles.includes(currentUser.role) ||
                      currentUser.role === 'formateur' ||
                      currentUser.role === 'coordinateur'
                    "
                  >
                    <a
                      :href="resource.file"
                      v-if="resource.file"
                      target="_blank"
                      class="has-text-primary"
                    >
                      <b-icon icon="link"></b-icon>{{ resource.name }}
                    </a>

                    <a
                      :href="resource.url"
                      v-if="resource.url"
                      target="_blank"
                      class="has-text-primary"
                    >
                      <b-icon icon="link"></b-icon> {{ resource.name }}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="waypoint.type == 'waypoint'" class="ml-auto">
              <!-- -->
              <b-button
                outlined
                rounded
                type="is-primary"
                v-if="waypoint.entry"
                @click="
                  gotoWaypointEdit(
                    waypoint.id,
                    waypoint.entry.student_training_waypoint
                  )
                "
                >voir</b-button
              >
              <b-button
                outlined
                rounded
                type="is-primary"
                v-else-if="!waypoint.entry && today > waypoint.start_date"
                @click="
                  newWaypoint(
                    waypoint.id,
                    waypoint.start_date,
                    waypoint.end_date
                  )
                "
                >voir</b-button
              >
              <b-button
                outlined
                rounded
                type="is-primary"
                v-else-if="!waypoint.entry && today < waypoint.start_date"
                disabled
                >voir</b-button
              >
            </div>
            <div
              v-else-if="waypoint.type == 'attitude_waypoint'"
              class="ml-auto"
            >
              <b-button
                outlined
                rounded
                type="is-primary"
                class="ml-auto"
                v-if="
                  waypoint.user_status_label === 'Effectué' ||
                  waypoint.user_status_label === 'Terminé'
                "
                @click="gotoEval(waypoint.id)"
                >voir</b-button
              >

              <b-button
                outlined
                rounded
                type="is-primary"
                class="ml-auto"
                v-else-if="
                  (waypoint.user_status_label == 'A faire' &&
                    today > waypoint.start_date) ||
                  (waypoint.user_status_label == 'En retard' &&
                    today > waypoint.start_date) ||
                  (waypoint.user_status_label == 'En cours' &&
                    today > waypoint.start_date)
                "
                @click="gotoEval(waypoint.id)"
                >évaluer</b-button
              >
              <!-- no to today --->
              <b-button
                outlined
                rounded
                type="is-primary"
                class="ml-auto"
                v-else-if="
                  waypoint.user_status_label == 'A faire' &&
                  today < waypoint.start_date
                "
                @click="gotoPartRead(waypoint.id)"
                style="opacity: 0.5"
                >voir</b-button
              >
            </div>
            <div v-else class="ml-auto">
              <b-button
                outlined
                rounded
                type="is-primary"
                class="ml-auto"
                v-if="
                  waypoint.user_status_label === 'Effectué' ||
                  waypoint.user_status_label === 'Terminé'
                "
                @click="gotoPartRead(waypoint.id)"
                >voir</b-button
              >

              <b-button
                outlined
                rounded
                type="is-primary"
                class="ml-auto"
                v-else-if="
                  (waypoint.user_status_label == 'A faire' &&
                    today > waypoint.start_date) ||
                  (waypoint.user_status_label == 'En retard' &&
                    today > waypoint.start_date) ||
                  (waypoint.user_status_label == 'En cours' &&
                    today > waypoint.start_date)
                "
                @click="gotoPartRead(waypoint.id)"
                >voir</b-button
              >
              <!-- no to today --->
              <b-button
                outlined
                rounded
                type="is-primary"
                class="ml-auto"
                v-else-if="
                  waypoint.user_status_label == 'A faire' &&
                  today < waypoint.start_date
                "
                @click="gotoPartRead(waypoint.id)"
                style="opacity: 0.5"
                >voir</b-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      v-model="isWaypointModalActive"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-modal
      id="modalNude"
    >
      <template>
        <div
          class="modal-card modal-users has-text-centered pb-2"
          style="width: auto"
          id="modalWaypoint"
        >
          <section class="modal-card-body">
            <div class="has-text-right">
              <a @click="isWaypointModalActive = false" class="ml-auto">
                <b-icon icon="close" type="is-primary"></b-icon>
              </a>
            </div>
            <div class="has-text-small mb-2-">
              <b class="title-paragraph"
                >Définissez le point d'étape à ajouter</b
              >
            </div>
            <b-field class="column is-narrow mr-3 mb-0">
              <b-select
                placeholder="Type de situation"
                icon="earth"
                v-model="formWaypoint.type"
              >
                <option value="1" v-if="currentUser.role !== 'tuteur'">
                  Point en centre de formation
                </option>
                <option value="0">Point en entreprise</option>
              </b-select>
            </b-field>
            <div class="columns is-tablet is-vcentered mb-0">
              <div class="column is-narrow">
                <b-field label="Date de début de période">
                  <b-datepicker
                    v-model="formWaypoint.start_date"
                    :locale="locale"
                    :show-week-number="showWeekNumber"
                    :first-day-of-week="1"
                    icon="calendar-today"
                    trap-focus
                    inline
                  >
                  </b-datepicker>
                </b-field>
              </div>
              <div class="column is-narrow">
                <b-field label="Date de fin de période">
                  <b-datepicker
                    v-model="formWaypoint.end_date"
                    :show-week-number="showWeekNumber"
                    :locale="locale"
                    icon="calendar-today"
                    trap-focus
                    inline
                    :first-day-of-week="1"
                  >
                  </b-datepicker>
                </b-field>
              </div>
            </div>
            <b-button
              type="is-primary"
              style="margin-top: -10px"
              @click="postWaypoint()"
              :disabled="multiple_value_boolean === false"
              >Ajouter le point d'étape</b-button
            >
          </section>
        </div>
      </template>
    </b-modal>
    <b-modal
      v-model="isDetailtModalActive"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-modal
      id="modalNude"
      class="modalStep"
      style="background-color: unset"
    >
      <template>
        <div
          class="modal-card modal-users pb-2"
          style="width: auto"
          id="modalWaypoint"
        >
          <section class="modal-card-body">
            <div class="has-text-right">
              <a @click="isDetailtModalActive = false" class="ml-auto">
                <b-icon icon="close" type="is-primary"></b-icon>
              </a>
            </div>
            <div v-html="this.content"></div>
            <b-button type="is-primary" class="mt-5">modifier</b-button>
          </section>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import _ from 'lodash';
import moment from 'moment';
import Modalexport from '@/components/Waypoints/Modalexport.vue';
import booklet from '@/components/Waypoints/booklet.json';
import bookletPart from '@/components/Waypoints/bookletPart.json';
// import localization from 'moment/locale/fr';

export default ({
  props: {
    student: {
      type: [String],
    },
  },
  components: {
    Modalexport,
  },
  filters: {
    day(date) {
      return moment(date).format('DD/MM/YYYY');
    },
  },
  computed: {
    ...mapState('auth', ['currentUser']),
    multiple_value_boolean() {
      return this.formWaypoint.type !== null
        && this.formWaypoint.start_date !== null
        && this.formWaypoint.end_date !== null;
    },
  },
  data() {
    return {
      later: new Date(),
      isLoading: false,
      isFullPage: false,
      isCardModalActive: false,
      isDetailtModalActive: false,
      schoolSubjects: [],
      waypointList: [],
      AttitudeWaypointList: [],
      isWaypointModalActive: false,
      formWaypoint: {
        type: null,
        end_date: null,
        start_date: null,
      },
      showWeekNumber: true,
      locale: 'UTC',
      today: new Date(),
      bookletPDF: false,
      bookletTodo: true,
      todolist: [
        {
          id: 1,
          title: 'Fiche élève',
          date: 'Semestre 6',
          write: 'apprenant',
          read: 'tuteur',
          start_date: '11/04/22',
          validated: true,
          position: 1,
        },
        {
          id: 2,
          title: 'Fiche d’auto-évaluation des compétences',
          date: 'Semestre 6',
          write: 'apprenant',
          read: 'tuteur',
          start_date: '11/04/22',
          validated: true,
          position: 1,
        },
        {
          id: 2,
          title: 'Fiche d’auto-évaluation des compétences',
          date: 'Semestre 6',
          write: 'apprenant',
          read: 'tuteur',
          start_date: '11/04/22',
          validated: true,
          position: 1,
        },
        {
          id: 2,
          title: 'Fiche d’auto-évaluation des compétences',
          date: 'Semestre 6',
          write: 'apprenant',
          read: 'tuteur',
          start_date: '11/04/22',
          validated: true,
          position: 1,
        },
        {
          id: 2,
          title: 'Fiche d’auto-évaluation des compétences',
          date: 'Semestre 6',
          write: 'apprenant',
          read: 'tuteur',
          start_date: '11/04/22',
          validated: true,
          position: 1,
        },
        {
          id: 2,
          title: 'Fiche d’auto-évaluation des compétences',
          date: 'Semestre 6',
          write: 'apprenant',
          read: 'tuteur',
          start_date: '11/04/22',
          validated: true,
          position: 1,
        },
      ],
      waypointList2: [
        {
          id: 1,
          title: 'Contacts',
          date: 'Semestre 6',
          write: 'apprenant',
          read: 'tuteur',
          start_date: '11/04/22',
          validated: true,
          position: 1,
          content: '<table align="center" border="1" cellpadding="3" cellspacing="0" style="width:800px">\r\n\t<tbody>\r\n\t\t<tr>\r\n\t\t\t<td style="text-align:center; vertical-align:middle; width:200px">&nbsp;\r\n\t\t\t<p><img id="Image 26" src="/assets/ckeditor/2022/03/14/logo-arts-et-metiers-2019.png" style="height:75px; width:358px" /></p>\r\n\t\t\t</td>\r\n\t\t\t<td style="text-align:center; width:300px">&nbsp;\r\n\t\t\t<p><strong>Cahier de Suivi</strong></p>\r\n\t\t\t</td>\r\n\t\t\t<td style="text-align:center; width:300px"><img id="Image 26" src="/assets/ckeditor/2022/03/14/logo_itii.png" style="height:150px; width:185px" /></td>\r\n\t\t</tr>\r\n\t</tbody>\r\n</table>\r\n\r\n<p>&nbsp;</p>\r\n\r\n<p style="margin-left:0px!important">&nbsp;</p>',
        },
        {
          id: 2,
          title: 'Informations à caractère général',
          date: 'Semestre 6',
          write: 'apprenant',
          read: 'tuteur',
          start_date: '11/04/22',
          validated: true,
          position: 2,
        },
        {
          id: 3,
          title: 'Informations à caractère pédagogique',
          date: 'Semestre 6',
          write: 'apprenant',
          read: 'tuteur',
          start_date: '11/04/22',
          validated: true,
          position: 3,
        },
        {
          id: 4,
          title: 'Calendrier d’alternance',
          date: 'Semestre 6',
          write: 'apprenant',
          read: 'tuteur',
          start_date: '11/04/22',
          validated: true,
          position: 3,
        },
        {
          id: 5,
          title: 'Projet professionnel défini avec l’entreprise',
          date: 'Semestre 6',
          write: 'apprenant',
          read: 'tuteur',
          start_date: '11/04/22',
          validated: true,
          position: 3,
        },

      ],
      bookletJson: booklet,
      content: '',
      bookletParts: bookletPart,
      bookletList: [],
      teacherEdit: '',
      bookletId: '',
    };
  },
  methods: {
    startLoading() {
      this.isLoading = true;
    },
    endLoading() {
      this.isLoading = false;
    },
    order(array) {
      return _.orderBy(array, 'due_datetime');
    },
    initWaypoints() {
      this.startLoading();
      // const urlWs = `/get_training_booklet_validations/${this.$route.params.idTraining}
      // ?student_training=${this.$route.params.idStudentTraining}`;
      const urlWs = `/get_booklet/${this.bookletId}?student_training=${this.$route.params.idStudentTraining}&editable=1`;
      this.$http
        .get(`${urlWs}`)
        .then((response) => {
          this.waypointList = response.data;
          // this.initAttitudeWaypoints();
          this.endLoading();
        });
    },
    initAttitudeWaypoints() {
      const urlWs = `/agenda/student_training_attitude_waypoints/?student_training=${this.$route.params.idStudentTraining}&format=json`;
      this.$http
        .get(`${urlWs}`)
        .then((response) => {
          this.AttitudeWaypointList = response.data;
          this.endLoading();
        });
    },
    newWaypoint(id, start, end) {
      // this.isWaypointModalActive = true;
      this.idPart = id;
      this.postWaypoint(id, start, end);
    },
    detailOpen(text) {
      this.isDetailtModalActive = true;
      this.content = text;
    },
    newAttitudeEvaluation() {
      this.$router.push({
        name: 'AttitudeEvaluationEdit',
        params: {
          idTraining: this.$route.params.idTraining,
          idStudent: this.$route.params.idStudent,
          idStudentTraining: this.$route.params.idStudentTraining,
          idWaypoint: 1,
        },
      });
    },
    postWaypoint(id, start, end) {
      this.$http
        .post('agenda/student_training_waypoints/', {
          start_date: start,
          end_date: end,
          type: 0,
          student_training: this.$route.params.idStudentTraining,
        }).then((response) => {
          this.$http
            .post('booklet_parts_entries/', {
              simple_booklet_part: this.idPart,
              student_training: this.$route.params.idStudentTraining,
              student_training_waypoint: response.data.id,
            }).then(() => {
              this.$buefy.toast.open({ message: 'Point d\'étape enregistrée', type: 'is-success' });
              // this.initWaypoints();
              this.gotoWaypointEdit(id, response.data.id);
            }).catch((error) => {
              window.console.log(error);
              this.errored = true;
              this.$buefy.dialog.alert({
                title: 'Mince...',
                message: 'L\'activité n\'a pas pu être enregistrée.',
                type: 'is-info',
                hasIcon: true,
                icon: 'times-circle',
                ariaRole: 'alertdialog',
                ariaModal: true,
                confirmText: 'Ok',
              });
            });
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
          this.$buefy.dialog.alert({
            title: 'Mince...',
            message: 'L\'activité n\'a pas pu être enregistrée.',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
          });
        });
    },
    postAttitudeWaypoint() {
      this.$http
        .post('agenda/student_training_attitude_waypoints/', {
          start_date: this.formWaypoint.start_date,
          end_date: this.formWaypoint.end_date,
          type: this.formWaypoint.type,
          student_training: this.$route.params.idStudentTraining,
        }).then((response) => {
          this.$buefy.toast.open({ message: 'Point d\'étape enregistrée', type: 'is-success' });
          this.initWaypoints();
          this.gotoAttitudeWaypointEdit(response.data.id);
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
          this.$buefy.dialog.alert({
            title: 'Mince...',
            message: 'L\'activité n\'a pas pu être enregistrée.',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
          });
        });
    },
    gotoPartEdit(id) {
      this.$router.push({
        name: 'BookletPartEdit',
        params: {
          idTraining: this.$route.params.idTraining,
          idStudent: this.$route.params.idStudent,
          idStudentTraining: this.$route.params.idStudentTraining,
          idPart: id,
        },
      });
    },
    gotoPartRead(id) {
      this.$router.push({
        name: 'BookletPartRead',
        params: {
          idTraining: this.$route.params.idTraining,
          idStudent: this.$route.params.idStudent,
          idStudentTraining: this.$route.params.idStudentTraining,
          idPart: id,
        },
      });
    },
    gotoAttitudeWaypointEdit(id) {
      this.$router.push({
        name: 'AttitudeEvaluationEdit',
        params: {
          idTraining: this.$route.params.idTraining,
          idStudent: this.$route.params.idStudent,
          idStudentTraining: this.$route.params.idStudentTraining,
          idWaypoint: id,
        },
      });
    },
    gotoAttitudeWaypoint(id) {
      this.$router.push({
        name: 'AttitudeEvaluationRead',
        params: {
          idTraining: this.$route.params.idTraining,
          idStudent: this.$route.params.idStudent,
          idStudentTraining: this.$route.params.idStudentTraining,
          idWaypoint: id,
        },
      });
    },
    gotoWaypointEdit(idpart, idwaypoint) {
      this.$router.push({
        name: 'WaypointitiiEdit',
        params: {
          idTraining: this.$route.params.idTraining,
          idStudent: this.$route.params.idStudent,
          idStudentTraining: this.$route.params.idStudentTraining,
          idWaypoint: idwaypoint,
          simple_booklet_part_validation: idpart,
        },
      });
    },
    gotoWaypoint(idpart, idwaypoint) {
      this.$router.push({
        // name: 'WaypointRead',
        name: 'WaypointitiiEdit',
        params: {
          idTraining: this.$route.params.idTraining,
          idStudent: this.$route.params.idStudent,
          idStudentTraining: this.$route.params.idStudentTraining,
          simple_booklet_part_validation: idpart,
          idWaypoint: idwaypoint,
        },
      });
    },
    gotoEval(idpart) {
      this.$router.push({
        name: 'BookletPartEvaluation',
        params: {
          idTraining: this.$route.params.idTraining,
          idStudent: this.$route.params.idStudent,
          idStudentTraining: this.$route.params.idStudentTraining,
          idPart: idpart,
          idFree: 110,
        },
      });
    },
    removeWaypoint(id) {
      const urlWs = `/agenda/student_training_waypoints/${id}/`;
      this.$buefy.dialog.confirm({
        title: 'Supprimer un point d\'étape',
        message: 'Êtes-vous sûr de vouloir supprimer ce point ? Attention, vous perdrez tout le contenu que vous avez pu rédiger pour ce point.',
        confirmText: 'Supprimer',
        cancelText: 'Annuler',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.$http
            .delete(`${urlWs}`)
            .then(() => {
              this.initWaypoints();
              this.$buefy.toast.open({ message: 'Point supprimé', type: 'is-success' });
            }).catch((error) => {
              window.console.log(error);
              this.errored = true;
              this.$buefy.dialog.alert({
                title: 'Mince...',
                message: 'Le point n\'a pas pu être supprimé.',
                type: 'is-info',
                hasIcon: true,
                icon: 'times-circle',
                ariaRole: 'alertdialog',
                ariaModal: true,
                confirmText: 'Ok',
              });
            });
        },
      });
    },
    collapseBlock(id, icon) {
      const element = document.getElementById(id);
      element.classList.toggle('blockOpen');
      const iconOpen = document.getElementById(icon);
      iconOpen.classList.toggle('iconOpen');
    },
    getBookletId() {
      this.$http
        .get(`/trainings/${this.$route.params.idTraining}/?query={teachers_can_edit,booklet}&format=json`)
        .then((response) => {
          this.teacherEdit = response.data.teachers_can_edit;
          this.bookletId = response.data.booklet;
          this.initWaypoints();
        });
    },
  },
  created() {
    // this.startLoading();
    this.getBookletId();
    // this.bookletJson = JSON.parse(this.bookletJson);
    this.today = moment().format();
    this.later = moment(this.today).add(8, 'months').format();
  },
  mounted() {
  },
  destroyed() {
  },
});
</script>

<style lang="scss" scoped>
.img_status {
  width: 37px;
}
#modalWaypoint {
  border-radius: 5px;
  padding-bottom: 3rem;
}
#modalWaypoint .modal-card-body .icon {
  font-size: 0.8rem !important;
}
#bloc-activities {
  background-color: unset;
  padding-top: 0px;
}
.step-later {
  // opacity: 0.6;
}

.title_activity {
  font-size: 1rem;
}
.dateItem {
  font-size: 0.9rem;
}
@media (max-width: 576px) {
  .card_activity::after {
    padding-left: 1.1rem;
  }
  #bloc-activities .title_activity,
  .dates {
    font-size: 0.95rem;
  }
}
@media (max-width: 768px) {
  .dateItem {
    font-size: 0.8rem;
  }
  .modal .animation-content,
  .modal-card-body,
  .modal-card {
    width: 100%;
    height: 100%;
    max-height: 100vh;
    margin: 0;
  }
  #modalWaypoint {
    border-radius: 0px;
  }
}
@media (max-width: 576px) {
  .modal .title-paragraph {
    padding-right: 2rem;
    padding-left: 2rem;
    font-size: 1.3rem;
  }
}
@media (max-width: 475px) {
  #modalWaypoint {
    padding: 1rem 0px 3rem;
    .modal-card {
      padding-top: 1rem;
    }
    .modal-card-body {
      padding-left: 0px;
      padding-right: 0px;
    }
    .icon {
      padding-right: 1.5rem;
    }
  }
  .datepicker {
    .dropdown-item {
      padding: 0px;
    }
  }
  .title_activity {
    font-size: 0.9rem;
    line-height: 1.2;
  }
  .dates {
    font-size: 0.8rem;
    line-height: 1.2;
  }
  .card_activity {
    &:after {
      content: unset;
    }
    .activity-item {
      padding-bottom: 1rem;
      border-bottom: 1px solid #dbdbdb;
      margin-bottom: 1.1rem;
      .button {
        font-size: 0.8rem;
      }
    }
  }
}
// @media (max-width: 325px) {
//    .datepicker {
//      margin-left:-0.5rem;
//    }
// }
</style>
