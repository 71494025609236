<template>
  <div
    class="modal-card modal-card-steps modal-practical-work"
    style="width: auto"
  >
    <header class="modal-card-head">
      <p class="modal-card-title" v-if="isReadMode == false">
        <span v-if="!this.practicalWork">Créer</span
        ><span v-else>Modifier</span> l'activité pratique
        <b v-if="this.practicalWork">{{ practicalWork.title }}</b>
      </p>
      <p
        class="modal-card-title"
        v-if="this.practicalWork && isReadMode === true"
      >
        {{ practicalWork.title }}
      </p>
    </header>

    <section class="modal-card-body">
      <div v-if="isReadMode == false">
        <!-- Steps -->
        <div class="columns">
          <b-steps
            v-model="activeStep"
            :animated="isAnimated"
            :rounded="isRounded"
            :has-navigation="hasNavigation"
            :icon-prev="prevIcon"
            :icon-next="nextIcon"
            :label-position="labelPosition"
            :mobile-mode="mobileMode"
            class="pt-6 is-small"
          >
            <b-step-item
              step="1"
              label="Désignation"
              :clickable="isStepsClickable"
            >
              <div class="container pt-6">
                <div class="field">
                  <label class="label is-size-5"
                    >Titre<span class="mandatory has-text-danger"
                      >*</span
                    ></label
                  >
                  <div class="control">
                    <input
                      class="input"
                      type="text"
                      v-model="practicalWorkDraft.title"
                      placeholder="Nommez votre activité pratique"
                    />
                  </div>
                </div>
                <div class="field">
                  <label class="label is-size-5">Description</label>
                  <ckeditor
                    v-model="practicalWorkDraft.description"
                    :editor="editor"
                    :config="editorConfig"
                  ></ckeditor>
                </div>
              </div>
              <!-- Steps -->
              <div class="container">
                <div class="field">
                  <label class="label is-size-5">Étapes</label>
                  <p>Ajoutez des étapes pour :</p>
                  <ul class="mb-6 ml-4" style="list-style-type: circle">
                    <li>Guider les apprenants</li>
                    <li>Séquencer la séance</li>
                    <li>
                      Évaluer plus précisément les compétences de l'activité
                    </li>
                  </ul>
                  <div>
                    <div v-for="(step, index) in steps" :key="index">
                      <div class="columns">
                        <div class="column is-11">
                          <h3 class="mb-4">Étape {{ index + 1 }}</h3>
                          <step-object :step="step"></step-object>
                        </div>
                        <div class="column is-1">
                          <button
                            class="delete"
                            @click="removeStep(index, step)"
                          ></button>
                        </div>
                      </div>
                    </div>
                    <b-button
                      @click="addStep"
                      type="is-primary"
                      rounded
                      expanded
                      class="my-button pt-2 pb-2"
                    >
                      Ajouter une étape
                    </b-button>
                  </div>
                </div>
              </div>
            </b-step-item>

            <b-step-item
              step="2"
              label="Compétences"
              :clickable="isStepsClickable"
              :type="{ 'is-success': isProfileSuccess }"
            >
              <div class="container pt-6">
                <h2 class="title p-4">Sélectionnez les compétences</h2>
                <b-table
                  :data="relevantSkills"
                  :columns="skillsColumns"
                  :checked-rows.sync="checkedSkills"
                  checkable
                  :checkbox-position="checkboxPosition"
                  :checkbox-type="checkboxType"
                  sticky-header
                  height="700px"
                >
                  <template #bottom-left>
                    <b> {{ checkedSkills.length }} compétences sélectionnées</b>
                  </template>
                </b-table>
              </div>
            </b-step-item>

            <b-step-item
              step="3"
              label="Formateurs"
              :clickable="isStepsClickable"
              disabled
            >
              <div class="container pt-6">
                <h2 class="title p-4">Sélectionnez les formateurs</h2>
                <b-table
                  :data="relevantTeachers"
                  :columns="teachersColumns"
                  :checked-rows.sync="selectedTeachers"
                  checkable
                  :checkbox-position="checkboxPosition"
                  :checkbox-type="checkboxType"
                >
                  <template #bottom-left>
                    <b>{{ selectedTeachers.length }} formateurs sélectionnés</b>
                  </template>
                </b-table>
              </div>
            </b-step-item>

            <b-step-item
              step="4"
              label="Ressources"
              :clickable="isStepsClickable"
              disabled
            >
              <div class="container pt-6">
                <h2 class="title p-4">Ajoutez les ressources utiles</h2>
                <div class="mt-5 pt-5" v-if="patch">
                  <div
                    v-if="
                      currentUser.role === 'tuteur' ||
                      currentUser.role === 'manager' ||
                      currentUser.role === 'formateur' ||
                      currentUser.role === 'coordinateur'
                    "
                  >
                    <div
                      class="card_simple is-primary w-100 has-text-centered mb-2"
                      @click="isOpen = !isOpen"
                    >
                      <div>
                        <b-icon
                          icon="resource"
                          type="is-primary"
                          class="mr-3"
                        ></b-icon>
                        <b style="cursor: pointer">Ajoutez une ressource</b>
                      </div>
                    </div>
                    <b-collapse v-model="isOpen">
                      <ResourceCreation
                        :isOpen.sync="isOpen"
                        :sequence="null"
                        :typeoftraining="'practicalWork'"
                        :idTraining="practicalWork.id"
                        :action="'post'"
                        @messageFromChild="initResources"
                      ></ResourceCreation>
                    </b-collapse>
                  </div>
                  <div v-if="resources">
                    <div v-if="resources.length">
                      <div v-for="resource in resources" :key="resource.id">
                        <ResourceCard
                          :resource="resource"
                          @messageFromChild="initResources"
                        ></ResourceCard>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <b-message type="is-warning"
                    >Vous devez d'abord créer l'activité pratique pour associer
                    des resources</b-message
                  >
                </div>
              </div>
            </b-step-item>

            <template v-if="customNavigation" #navigation="{ previous, next }">
              <b-button
                outlined
                type="is-danger"
                icon-pack="fas"
                icon-left="backward"
                :disabled="previous.disabled"
                @click.prevent="previous.action"
              >
                Précédent
              </b-button>
              <b-button
                outlined
                type="is-success"
                icon-pack="fas"
                icon-right="forward"
                :disabled="next.disabled"
                @click.prevent="next.action"
              >
                Suivant
              </b-button>
            </template>
          </b-steps>
        </div>
      </div>
      <div v-else>
        <div class="container">
          <section class="mb-5">
            <!-- <b-tag>{{ practicalWork.teachers }}</b-tag> -->
            <h2 class="title-paragraph">Formateurs</h2>
            <div class="mb-4">
              <div v-if="practicalWork.teachers.length > 0">
                <b-tag
                  v-for="teacher in practicalWork.teachers"
                  :key="teacher"
                  type="is-success is-light"
                >
                  {{ teacher }}
                </b-tag>
              </div>
              <b-message type="is-info" size="is-small" v-else
                ><span>Aucun formateur </span></b-message
              >
            </div>
          </section>
          <section class="mb-5">
            <h2 class="title-paragraph">Présentation</h2>
            <div class="hero" color="primary" size="is-medium">
              <div v-if="practicalWork.description">
                <p class="mb-4" v-html="practicalWork.description"></p>
              </div>
              <b-message type="is-info" size="is-small" v-else
                ><span>Aucun formateur </span></b-message
              >
            </div>
          </section>
          <section class="mb-5">
            <h2 class="title-paragraph">Étapes</h2>
            <div v-if="practicalWork.steps.length > 0">
              <div
                class="mb-6"
                v-for="(step, index) in practicalWork.steps"
                :key="step.id"
              >
                <p class="subtitle-paragraph mb-1">
                  <b>Étape {{ index + 1 }} : {{ step.title }}</b>
                </p>
                <div v-html="step.description"></div>
              </div>
            </div>
            <b-message type="is-info" size="is-small" v-else
              ><span>Aucune étape enregistrée </span></b-message
            >
          </section>

          <section>
            <h2 class="title-paragraph">Compétences</h2>
            <div v-if="practicalWork.skills.length !== 0">
              <div
                v-for="skill in practicalWork.skills"
                :key="skill.id"
                class="card card_item card_simple mb-2"
              >
                <p class="card-header-title">{{ skill.name }}</p>
                <!-- <div style="font-size: 0.8rem">{{ skill.description }}</div> -->
                <div style="font-size: 0.8rem; margin-left: 0.4rem">
                  {{ skill.block_name }}
                </div>
              </div>
            </div>
            <b-message type="is-info" size="is-small" v-else
              ><span>Aucune compétence associée </span></b-message
            >
          </section>

          <section class="pb-5 mt-5">
            <h2 class="title-paragraph mt-5 pt-5">
              Niveaux d'acquisition des compétences
            </h2>
            <div class="caption is-flex align-items-center">
              <div
                class="caption-item"
                v-for="level in order(practicalWork.levels)"
                :key="level.id"
              >
                <img
                  :src="
                    require(`@/assets/img/levels/level${level.position}.svg`)
                  "
                  alt=""
                  class="img_status"
                />
                {{ level.name }}
              </div>
            </div>
          </section>
          <section class="pb-5 mt-5 w-100">
            <h2 class="title-paragraph mt-5 pt-5">Ressources</h2>
            <div class="mt-5 pt-5">
              <div v-if="resources">
                <div v-if="resources.length">
                  <div v-for="resource in resources" :key="resource.id">
                    <ResourceCard
                      :resource="resource"
                      @messageFromChild="initResources"
                    ></ResourceCard>
                  </div>
                </div>
                <b-message type="is-info" v-else size="is-small"
                  ><span>Aucun document n'a été téléchargé</span></b-message
                >
              </div>
            </div>
          </section>
        </div>
      </div>
    </section>
    <footer class="modal-card-foot">
      <b-button
        v-if="isReadMode == false"
        label="Fermer"
        @click="
          $emit('close');
          checkedSkills: [];
          selectedCandidates: [];
        "
      />

      <b-button
        v-if="isReadMode == false && patch == false"
        label="Créer cette activité pratique"
        type="is-primary"
        @click="createPracticalWork"
        :disabled="practicalWorkDraft.title === ''"
      />
      <b-button
        v-else-if="isReadMode == false && patch == true"
        label="Enregistrer"
        type="is-primary"
        @click="verifyIfCanPatch"
        :disabled="practicalWorkDraft.title === ''"
      />
      <b-button
        v-else
        label="Fermer"
        type="is-primary"
        @click="$emit('close')"
      />
      <div
        v-if="this.currentUser.role === 'coordinateur' || this.teacherCanEdit"
      >
        <b-button
          label="Éditer"
          class="mr-2"
          @click="isReadMode = false"
          v-if="isReadMode == true"
        />
        <b-button
          v-if="currentUser.role === 'coordinateur' && patch === true"
          @click="deletePracticalWork"
          type="is-danger"
          >supprimer</b-button
        >
      </div>
    </footer>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import 'moment/locale/fr';
import CKEditor from '@ckeditor/ckeditor5-vue2';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import '@ckeditor/ckeditor5-build-classic/build/translations/fr';
import ResourceCard from '@/components/Resources/ResourceCard.vue';
import ResourceCreation from '@/components/Resources/ResourceCreation.vue';
import _ from 'lodash';
import StepObject from './StepObject.vue';

export default {
  components: {
    StepObject,
    ckeditor: CKEditor.component,
    ResourceCard,
    ResourceCreation,
  },
  data() {
    return {
      isOpen: true,
      practicalWorkDraft: {
        title: '',
        description: '',
      },
      relevantTeachers: [],
      selectedTeachers: [],
      checkboxPosition: 'left',
      checkboxType: 'is-primary',
      steps: [],
      // CkEditor
      editor: ClassicEditor,
      editorDisabled: true,
      editorConfig: {
        language: 'fr',
        toolbar: ['heading', '|', 'bold', 'italic', 'bulletedList', 'numberedList', '|', 'outdent', 'indent', '|', 'link', '|', 'undo',
          'redo'],
        alignment: {
          options: ['left', 'right'],
        },
        heading: {
          options: [
            { model: 'paragraph', title: 'Styles de texte', class: 'ck-heading_paragraph' },
            {
              model: 'heading1',
              view: 'h1',
              title: 'Titre 1',
              class: 'ck-heading_heading1',
            },
            {
              model: 'heading2',
              view: 'h2',
              title: 'Titre 2',
              class: 'ck-heading_heading2',
            },
            {
              model: 'mark',
              view: 'mark',
              title: 'surligné',
              class: 'ck-mark',
            },
          ],
        },
        table: {
          contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells'],
        },
      },
      // Skills table
      skillsColumns: [
        {
          field: 'name',
          label: 'Compétence',
          width: '100px',
          searchable: true,
          sortable: true,
        },
        {
          field: 'block_name',
          label: 'Bloc',
          width: '150px',
          searchable: true,
          sortable: true,
        },
      ],
      teachersColumns: [
        {
          field: 'first_name',
          label: 'Prénom',
          sortable: true,
          width: '150px',
          searchable: true,
        },
        {
          field: 'last_name',
          label: 'Nom',
          sortable: true,
          searchable: true,
          width: '150px',
        },
      ],
      resourcesColumns: [
        {
          field: 'name',
          label: 'Titre',
          sortable: true,
          width: '150px',
          searchable: true,
        },
        {
          field: 'short_description',
          label: 'Description',
          sortable: true,
          searchable: true,
          width: '150px',
        },
        {
          field: 'file',
          label: 'Visualiser',
          width: '150px',
        },
      ],
      checkedSkills: [],
      relevantSkills: [],
      relevantResources: [],
      selectedResources: [],
      // Steps
      activeStep: 0,
      showSocial: false,
      isAnimated: true,
      isRounded: true,
      isStepsClickable: true,

      hasNavigation: true,
      customNavigation: false,
      isProfileSuccess: false,

      prevIcon: 'chevron-left',
      nextIcon: 'chevron-right',
      labelPosition: 'bottom',
      mobileMode: 'minimalist',
      patch: false,
      isReadMode: true,
      resources: [],
      teacherCanEdit: false,
      sessionWithEvals: false,
    };
  },
  computed: {
    ...mapState('auth', ['currentUser']),
  },
  created() {
    this.isReadMode = this.isReadingMode;
    this.searchSkills();
    this.searchTeachers();
    this.getResources();
    if (this.practicalWork) {
      this.practicalWorkDraft = this.practicalWork;
      this.patch = true;
      this.steps = this.practicalWork.steps;
      this.isReadMode = true;
      this.resources = this.practicalWork.resources;
      this.isTeacherCanEdit();
      this.isOpen = false;
      // this.selectedTeachers = this.practicalWork.teachers;
      // this.checkedSkills = this.practicalWork.skills;
    }
    // if (!this.isReadingMode && this.isPatch) {
    //   window.console.log(this.practicalWork.id);
    //   this.practicalWorkDraft = this.practicalWork;
    //   this.patch = true;
    //   this.steps = this.practicalWork.steps;
    // }
  },
  methods: {
    addStep() {
      const position = this.steps.length + 1;
      this.steps.push({
        title: '',
        description: '',
        position,
      });
    },

    removeStep(index, step) {
      if (step.id) {
        this.$buefy.dialog.confirm({
          title: `Supprimer l'étape "${step.title}"`,
          message: 'Voulez-vous supprimer cet étape ?',
          confirmText: 'Supprimer',
          cancelText: 'Annuler',
          type: 'is-danger',
          hasIcon: true,
          onConfirm: () => {
            this.$http
              .delete(`/practical_work/training_practical_work_step/${step.id}/`)
              .then(() => {
                this.$buefy.toast.open({ message: 'Étape supprimée', type: 'is-success' });
                this.steps.splice(index, 1);
              }).catch((error) => {
                window.console.log(error);
                this.errored = true;
                this.$buefy.dialog.alert({
                  title: 'Mince...',
                  message: 'L\'étape n\'a pas pu être supprimée.',
                  type: 'is-grey',
                  hasIcon: true,
                  icon: 'times-circle',
                  ariaRole: 'alertdialog',
                  ariaModal: true,
                  confirmText: 'Ok',
                });
              });
          },
        });
      } else {
        this.steps.splice(index, 1);
      }
    },

    async searchTeachers() {
      try {
        const response = await this.$http.get(`/get_profiles/?training=${this.$route.params.idTraining}&role=formateur&items=4000&format=json`);
        // window.console.log(response.data);
        const relevantTeachers = response.data.results;
        this.relevantTeachers = relevantTeachers;
        if (this.practicalWork) {
          this.verifTeacher();
        }
      } catch (error) {
        window.console.error(error);
      }
    },

    async searchSkills() {
      try {
        const response = await this.$http.get(`/get_trainingskillsblock/?training=${this.$route.params.idTraining}&noblocks=1&format=json`);
        const relevantSkills = response.data;
        this.relevantSkills = relevantSkills;
        if (this.practicalWork) {
          this.verifSkills();
        }
      } catch (error) {
        window.console.error(error);
      }
    },

    async getResources() {
      try {
        const response = await this.$http.get(`/get_resources/?training=${this.$route.params.idTraining}&format=json`);
        this.relevantResources = response.data.resources;
      } catch (error) {
        window.console.error(error);
      }
    },
    async createPracticalWork() {
      try {
        // Create practical work
        const response = await this.$http.post('/practical_work/training_practical_work/', {
          title: this.practicalWorkDraft.title,
          description: this.practicalWorkDraft.description,
          training: this.$route.params.idTraining,
          teachers: this.selectedTeachers.map((teacher) => teacher.id),
        });

        // Add POST request to create practical work skills
        const practicalWorkId = response.data.id;
        const skillsPayload = {
          training_practical_work: practicalWorkId,
          skills: this.checkedSkills.map((skill) => skill.id),
        };
        await this.$http.post('/practical_work/training_practical_work_skills/', skillsPayload);

        // Add PATCH request to create practical work resources
        // const resourcesPayload = {
        //   resources: this.selectedResources.map((resource) => resource.id),
        //   attached_practical_works: practicalWorkId,
        // };
        // await this.$http.post('/resources/', resourcesPayload);

        // Add POST request to create practical work steps
        const stepPromises = this.steps.map((step, index) => {
          const stepPayload = {
            title: step.title,
            description: step.description,
            position: index + 1, // Replaced i++ with index + 1
            training_practical_work: practicalWorkId,
          };
          return this.$http.post('/practical_work/training_practical_work_step/', stepPayload);
        });
        await Promise.all(stepPromises); // Wait for all step creation promises to resolve

        // Emit 'success' event on success
        this.$emit('success', practicalWorkId);
      } catch (error) {
        window.console.error(error);
        this.$buefy.toast.open({
          message: 'Votre activité n\'a pas pu être créée...',
          type: 'is-danger',
          position: 'is-top-right',
          duration: 5000,
          queue: false,
          pauseOnHover: true,
          closeOnClick: true,
          onDismiss: null,
          indefinite: false,
        });
      }
    },
    verifyIfCanPatch() {
      let message = '';
      if ((this.practicalWork.skills.length > this.checkedSkills.length)) {
        if (this.practicalWork.sessions.length > 0) {
          message = '<div class="has-text-danger"><b>Attention, vous allez supprimer des compétences</b><ul>';
          this.practicalWork.skills.forEach((skill) => {
            const result = this.checkedSkills.findIndex((el) => el.id === skill.id);
            if (result === -1) {
              message = `${message}<li>- ${skill.name}`;
              const students = skill.students.map((el) => el.student);
              let studentsName = String(students);
              studentsName = studentsName.replace(',', ', ');
              studentsName = `<b>Évalué pour ${students.length} apprenants :</b> ${students}`;
              message = `${message} <div class="has-text-black mb-2">${studentsName}</div></li>`;
            }
          });
          message = `${message}</ul><br /><b>Êtes-vous sûr•e de vouloir modifier cette activité pratique ?</b></div>`;
          this.$buefy.dialog.confirm({
            title: `Modifier l'activité pratique "${this.practicalWork.title}"`,
            message: `${message}`,
            confirmText: 'Enregistrer',
            cancelText: 'Annuler',
            type: 'is-danger',
            hasIcon: true,
            onConfirm: () => {
              this.patchPracticalWork();
            },
          });
        } else {
          this.patchPracticalWork();
        }
        /* eslint-disable max-len */
      } else if ((this.practicalWork.skills.length < this.checkedSkills.length) && (this.practicalWork.skills.length > 0)) {
        if (this.practicalWork.sessions.length > 0 && this.practicalWork.skills.length > 0) {
          let skilldeleted = false;
          this.practicalWork.skills.forEach((skill) => {
            const result = this.checkedSkills.findIndex((el) => el.id === skill.id);
            if (result === -1) {
              skilldeleted = true;
              message = `${message}<li>- ${skill.name}`;
              const students = skill.students.map((el) => el.student);
              let studentsName = String(students);
              studentsName = studentsName.replace(',', ', ');
              studentsName = `<b>Évalué pour ${students.length} apprenants :</b> ${students}`;
              message = `${message} <div class="has-text-black mb-2">${studentsName}</div></li>`;
            }
          });
          if (skilldeleted) {
            message = `<div class="has-text-danger"><b>Attention, vous allez peut-être supprimer des compétences</b><ul>${message}</ul></div >`;
          }
          message = `${message} Des compétences vont être ajoutées à des séances existantes. Pensez à bien évaluer ces nouvelles compétences.`;
          this.$buefy.dialog.confirm({
            title: `Modifier l'activité pratique "${this.practicalWork.title}"`,
            message: `${message}`,
            confirmText: 'Enregistrer',
            cancelText: 'Annuler',
            type: 'is-danger',
            hasIcon: true,
            onConfirm: () => {
              this.patchPracticalWork();
            },
          });
        } else {
          this.patchPracticalWork();
        }
        /* eslint-disable max-len */
      } else if ((this.practicalWork.skills.length === this.checkedSkills.length) && (this.practicalWork.skills.length > 0)) {
        const skills = this.practicalWork.skills.map((el) => el.id);
        const checked = this.checkedSkills.map((skillchecked) => skillchecked.id);
        const resultat = _.isEqual(skills, checked);
        if (!resultat) {
          message = '<div class="has-text-danger"><b>Attention, vous allez supprimer des compétences</b><ul>';
          this.practicalWork.skills.forEach((skill) => {
            const result = this.checkedSkills.findIndex((el) => el.id === skill.id);
            if (result === -1) {
              message = `${message}<li>- ${skill.name}</li>`;
              const students = skill.students.map((el) => el.student);
              let studentsName = String(students);
              studentsName = studentsName.replace(',', ', ');
              studentsName = `<b>Évalué pour ${students.length} apprenants :</b> ${students}`;
              message = `${message} <div class="has-text-black">${studentsName}<ul>`;
            }
          });
          message = `${message}<br /><b>Êtes-vous sûr•e de vouloir modifier cette activité pratique ?</b>`;
          this.$buefy.dialog.confirm({
            title: `Modifier l'activité pratique "${this.practicalWork.title}"`,
            message: `${message}`,
            confirmText: 'Enregistrer',
            cancelText: 'Annuler',
            type: 'is-danger',
            hasIcon: true,
            onConfirm: () => {
              this.patchPracticalWork();
            },
          });
        } else {
          this.patchPracticalWork();
        }
      } else {
        this.patchPracticalWork();
      }
    },
    async patchPracticalWork() {
      try {
        // Create practical work
        await this.$http.patch(`/practical_work/training_practical_work/${this.practicalWork.id}/`, {
          title: this.practicalWorkDraft.title,
          description: this.practicalWorkDraft.description,
          training: this.$route.params.idTraining,
          teachers: this.selectedTeachers.map((teacher) => teacher.id),
        });

        const skillsPayload = {
          training_practical_work: this.practicalWork.id,
          skills: this.checkedSkills.map((skillchecked) => skillchecked.id),
        };
        this.$http.post('/practical_work/training_practical_work_skills/', skillsPayload)
          .catch((error) => {
            window.console.log(error);
          });

        // Add POST request to create practical work steps
        const stepPromises = this.steps.map((step, index) => {
          const stepPayload = {
            title: step.title,
            description: step.description,
            position: index + 1,
            training_practical_work: this.practicalWork.id,
          };
          if (step.id) {
            return this.$http.patch(`/practical_work/training_practical_work_step/${step.id}/`, stepPayload);
          }
          return this.$http.post('/practical_work/training_practical_work_step/', stepPayload);
        });
        await Promise.all(stepPromises);

        // Emit 'success' event on success
        this.$emit('success', this.practicalWork.id);
        this.$buefy.toast.open({ message: 'Activité enregistrée', type: 'is-success' });
      } catch (error) {
        window.console.error(error);
        this.$buefy.toast.open({
          message: 'Votre activité n\'a pas pu être créée...',
          type: 'is-danger',
          position: 'is-top-right',
          duration: 5000,
          queue: false,
          pauseOnHover: true,
          closeOnClick: true,
          onDismiss: null,
          indefinite: false,
        });
      }
    },
    initResources() {
      this.$http.get(`/resources/?attached_practical_works=${this.practicalWork.id}&format=json`).then((response) => {
        this.resources = response.data;
      });
    },
    isTeacherCanEdit() {
      const name = `${this.currentUser.last_name} ${this.currentUser.first_name}`;
      for (let i = 0; i < this.practicalWork.teachers.length; i += 1) {
        if (this.currentUser.role === 'formateur' && (name === this.practicalWork.teachers[i])) {
          this.teacherCanEdit = true;
          break;
        } else {
          this.teacherCanEdit = false;
        }
      }
    },
    verifSkills() {
      this.practicalWork.skills.forEach((skill) => {
        const result = this.relevantSkills.filter((el) => el.id === skill.id);
        this.checkedSkills.push(result[0]);
      });
    },
    verifTeacher() {
      this.practicalWork.teachers.forEach((teacher) => {
        const result = this.relevantTeachers.filter((el) => `${el.last_name} ${el.first_name}` === teacher);
        this.selectedTeachers.push(result[0]);
      });
    },
    deletePracticalWork() {
      let message = '';
      const sessions = [];
      if (this.practicalWork.sessions.length > 0) {
        message = `<div class="has-text-danger"><b>Attention, vous allez aussi supprimer les ${this.practicalWork.sessions.length} séance(s) associée(s) suivantes :</b><ul>`;
        this.practicalWork.sessions.forEach((session) => {
          sessions.push(session.title);
          message = `${message}<li>- ${session.title}</li>`;
        });
        message = `${message}</ul></div>`;
        if (this.practicalWork.skills.length) {
          for (let i = 0; i < this.practicalWork.skills.length; i += 1) {
            if (this.practicalWork.skills[i].students.length > 0) {
              this.sessionWithEvals = true;
              message = `${message} <div class="has-text-danger"><b>Attention, il y a aussi des évaluations enregistrées au moins pour l'une de ces séances.</b> Les évaluations de chaque apprenant•e ne seront pas supprimées mais ne seront plus associées à l'activité pratique.</div>`;
              break;
            }
            if (i === this.practicalWork.skills.length - 1) {
              if (!this.sessionWithEvals) {
                message = `${message} Aucune évaluation n'est associée à ces séances.`;
              }
            }
          }
        } else {
          message = `${message} Aucune évaluation n'est associée à ces séances.`;
        }
        message = `${message}<br/><b>Êtes-vous sûr•e de vouloir tout supprimer ?</b>`;
      } else {
        message = 'Êtes-vous sûr de vouloir supprimer cette activité pratique ?';
      }
      this.$buefy.dialog.confirm({
        title: `Supprimer l'activité pratique "${this.practicalWork.title}"`,
        message: `${message}`,
        confirmText: 'Supprimer',
        cancelText: 'Annuler',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.$http
            .delete(`/practical_work/training_practical_work/${this.practicalWork.id}/`)
            .then(() => {
              this.$buefy.toast.open({ message: 'Activité supprimée', type: 'is-success' });
              this.$emit('success');
            }).catch((error) => {
              window.console.log(error);
              this.errored = true;
              this.$buefy.dialog.alert({
                title: 'Mince...',
                message: 'L\'activité pratique n\'a pas pu être supprimée.',
                type: 'is-grey',
                hasIcon: true,
                icon: 'times-circle',
                ariaRole: 'alertdialog',
                ariaModal: true,
                confirmText: 'Ok',
              });
            });
        },
      });
    },
    order(array) {
      return _.orderBy(array, 'position');
    },
  },
  props: {
    practicalWork: Object,
    isReadingMode: Boolean,
    isPatch: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.b-input.is-small .delete {
  margin-left: 0.5rem;
}

.b-button.is-primary {
  background-color: #209cee;
}

.b-steps {
  width: 100%;
}

.modal-card-steps .icon {
  font-size: 1rem !important;
}

.delete:hover {
  color: red !important;
}
h2 {
  display: block;
}
.modal-practical-work .modal-card-body .icon {
  font-size: unset !important;
}
</style>
