<template>
  <div class="modalbox">
    <div class="is-flex bigmodal">
      <div class="ml-auto">
        <b-button @click="saveAll()" type="is-success is-rounded" class="mr-2"
          >enregistrer tout</b-button
        >
        <b-button
          @click="goPreviousPage('parcours')"
          type="is-primary"
          rounded
          outlined
          >Revenir au parcours</b-button
        >
      </div>
    </div>
    <div class="container title-modal">
      <div class="subtitle-paragraph mb-0">Détail d'une expérience</div>
      <div class="title-page long-title">
        {{ frameworksequence.name }}
      </div>
      <div>
        <span class="tag status1"
          >{{ frameworksequence.sequence_type_label }}
        </span>
      </div>
    </div>

    <section class="mt-auto pr-0 pl-0" id="header-tabs">
      <div class="b-tabs container">
        <nav class="tabs">
          <ul>
            <li :class="currentTab === 1 ? 'is-active' : ''">
              <a @click="gotoStep(1)"><span>L'expérience</span></a>
            </li>
            <li
              v-for="(activity, indexActivity) in frameworksequence.activities"
              :key="indexActivity"
              :class="currentTab === indexActivity + 2 ? 'is-active' : ''"
            >
              <a @click="gotoActivity(indexActivity)">{{ activity.name }} </a>
            </li>
            <li :class="currentTab === 'resources' ? 'is-active' : ''">
              <a @click="gotoResources"><span>Ressources</span></a>
            </li>
          </ul>
        </nav>
      </div>
    </section>

    <div class="container contentActivite pb-6">
      <div v-if="step === 1">
        <!-- <div class="frowho">
          <b-icon icon="user" type="is-primary"></b-icon>
          <div>
            Page à destination de tous les utilisateurs, en lecture seule pour
            l’apprenant, le tuteur et le manager
          </div>
        </div> -->
        <div class="bloc-paragraph mt-5 pt-5">
          <div class="title-paragraph">Présentation de l'expérience</div>
          <b-field label="Titre de l'expérience">
            <b-input v-model="form.name"></b-input>
          </b-field>
          <div class="mb-4">
            <span class="subtitle-paragraph">Type d'expérience : </span>
            {{ frameworksequence.sequence_type_label }}
          </div>
          <div>
            <b-field label="Durée">
              <b-input
                v-model="form.estimated_duration"
                maxlength="50"
              ></b-input>
            </b-field>
          </div>
        </div>
        <div class="bloc-paragraph">
          <div class="title-paragraph">
            Présentation, principaux thèmes abordés
          </div>
          <div class="subtitle-paragraph" v-html="sequencetypes.goals"></div>
          <ckeditor
            :editor="editor"
            v-model="form.goals"
            :config="editorConfig"
            class="mb-5"
          ></ckeditor>
        </div>
        <div class="bloc-paragraph">
          <div class="title-paragraph">Commentaires</div>
          <div class="subtitle-paragraph" v-html="sequencetypes.comments"></div>
          <ckeditor
            :editor="editor"
            v-model="form.comment"
            :config="editorConfig"
            class="mb-5"
          ></ckeditor>
        </div>

        <b-button
          class="mr-3"
          @click.prevent="updateSequence"
          type="is-primary"
          rounded
          >Enregistrer les modifications</b-button
        >
        <b-button @click="goPreviousPage(4)" type="is-primary" rounded outlined
          >Revenir au parcours</b-button
        >
        <div class="mt-5">
          <SkillsSequenceAdd
            :typeoftraining="'framework'"
            :sequenceId="Number(this.$route.params.id)"
            :trainingId="Number(this.$route.params.idFramework)"
          />
        </div>
      </div>
      <div class="pb-5" v-if="step == NumberActivity + 2">
        <div class="frowho" v-if="frameworksequence.activities[NumberActivity]">
          <b-icon icon="user" type="is-primary"></b-icon>
          <div>
            {{ frameworksequence.activities[NumberActivity].intro }}
          </div>
        </div>
        <div class="head_activity">L'activité</div>
        <!-- green block with helpful informations-->
        <div
          class="helpUser"
          v-if="frameworksequence.activities[NumberActivity]"
        >
          <div class="title">
            {{ frameworksequence.activities[NumberActivity].title }}
          </div>
          <div>
            {{ frameworksequence.activities[NumberActivity].description }}
          </div>
        </div>
        <!-- activity texte and steps-->
        <div class="card_item cardpage_activity">
          <div
            v-for="(stepActivity, i) in order(
              frameworksequence.activities[NumberActivity].activity_steps
            )"
            :key="i"
          >
            <div class="title-paragraph">{{ stepActivity.name }}</div>
            <div class="subtitle-paragraph">
              {{ stepActivity.description }}
            </div>
            <ckeditor
              :editor="editor"
              v-model="stepActivity.content"
              :config="editorConfig"
              class="mb-5"
            ></ckeditor>
            <b-button
              @click="defaultText(stepActivity.placeholder, stepActivity)"
              class="mb-6"
              type="is-text"
              v-if="stepActivity.placeholder"
            >
              remplir avec le texte par défaut
            </b-button>
          </div>
          <div class="mb-6">
            <b-button
              class="mr-3 mt-5"
              type="is-primary"
              @click.prevent="
                saveActivity(
                  frameworksequence.activities[NumberActivity].id,
                  NumberActivity
                )
              "
              >enregistrer</b-button
            >
          </div>
        </div>
      </div>
      <div v-if="step === 'resources'" class="pt-5 mt-5">
        <h1 class="title-paragraph">Ressources associées à cette expérience</h1>
        <div class="helpUser mt-0">
          <div class="title">Ajoutez des ressources</div>
          <div>
            Ici vous pouvez ajouter des documents à cette expérience. Dans
            l'édition du parcours vous pouvez également ajouter des ressources à
            certaines expériences ou activités. Pour des questions écologiques,
            le poids des fichiers est limité à 1 Mo. Si votre fichier est trop
            lourd, vous pouvez l'optimiser sur un site tel que
            <a href="https://www.wecompress.com/fr/" target="_blank"
              ><strong>wecompress</strong></a
            >.
          </div>
          <div>Vous pouvez ajouter des ressources</div>
        </div>
        <div
          class="card_simple is-primary w-100 has-text-centered btn"
          @click="isOpen = !isOpen"
        >
          <div>
            <b-icon icon="resource" type="is-primary" class="mr-3"></b-icon>
            <b style="cursor: pointer">Ajoutez une ressource</b>
          </div>
        </div>
        <b-collapse v-model="isOpen">
          <ResourceCreation
            :sequence="frameworksequence"
            :typeoftraining="'framework'"
            :idTraining="null"
            :action="'post'"
            v-if="frameworksequence"
            :isOpen.sync="isOpen"
            @messageFromChild="initResources"
            @messageFromResource="forceRerender"
            :key="resourceKey"
          ></ResourceCreation>
        </b-collapse>
        <div v-if="resources">
          <div v-if="resources.length">
            <div v-for="resource in resources" :key="resource.id">
              <ResourceCard
                :sequence="frameworksequence"
                :typeoftraining="'framework'"
                :resource="resource"
                :action="'edit'"
                @messageFromChild="initResources"
              ></ResourceCard>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapState } from 'vuex';
import CKEditor from '@ckeditor/ckeditor5-vue2';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import '@ckeditor/ckeditor5-build-classic/build/translations/fr';
import ResourceCreation from '@/components/Resources/ResourceCreation.vue';
import ResourceCard from '@/components/Resources/ResourceCard.vue';
import SkillsSequenceAdd from '@/components/Skills/SkillsSequenceAdd.vue';

export default {
  components: {
    ResourceCreation,
    ResourceCard,
    ckeditor: CKEditor.component,
    SkillsSequenceAdd,
  },
  data() {
    return {
      frameworksequence: [],
      sequencetypes: [],
      currentTab: 1,
      importantSwitch: {
        1: 'Importante',
        0: 'Non importante',
      },
      numberStep: 1,
      step: 1,
      form: {
        name: null,
        duration: null,
        date: null,
        // organization: null,
        // user: null,
        sequence_type: null,
        comment: null,
        goals: null,
      },
      resourceattachment: {
        name: null,
        importance: 0,
        attached_framework_sequences: null,
        attached_framework_activities: null,
        active: 1,
      },
      NumberActivity: 0,
      roles: null,
      formResource: {
        resourceName: '',
        selectedRoles: [],
        selectedActivity: [],
        selectedSequence: [],
        selectedType: [],
        active: 1,
        resourceShort: null,
        resourceLong: null,
        resources_type: [],
      },
      selectedAttachedType: null,
      file: null,
      resources: null,
      resourceKey: 0,
      isOpen: false,
      editor: ClassicEditor,
      editorDisabled: true,
      editorConfig: {
        language: 'fr',
        toolbar: ['heading', '|', 'bold', 'italic', 'bulletedList', 'numberedList', '|', 'outdent', 'indent', '|', 'link', '|', 'undo',
          'redo'],
        alignment: {
          options: ['left', 'right'],
        },
        heading: {
          options: [
            { model: 'paragraph', title: 'Styles de texte', class: 'ck-heading_paragraph' },
            {
              model: 'heading1',
              view: 'h1',
              title: 'Titre 1',
              class: 'ck-heading_heading1',
            },
            {
              model: 'heading2',
              view: 'h2',
              title: 'Titre 2',
              class: 'ck-heading_heading2',
            },
            {
              model: 'mark',
              view: 'mark',
              title: 'surligné',
              class: 'ck-mark',
            },
          ],
        },
        table: {
          contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells'],
        },
      },
    };
  },
  computed: {
    ...mapState('auth', ['currentUser']),
  },
  methods: {
    initFrameworkSequence() {
      // load frameworksequence to get name and activities
      this.$http
        .get(`/sequences/${this.$route.params.id}/?format=json`)
        .then((response) => {
          this.frameworksequence = response.data;
          this.setdata();
          const activites = this.order(this.frameworksequence.activities);
          this.frameworksequence.activities = activites;
          this.initSequencetypes(this.frameworksequence.sequence_type);
          this.endLoading();
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
        });
    },
    initSequencetypes(id) {
      // load sequences type to get name and infos (goals and comments)
      this.$http
        .get(`/sequencetypes/${id}/?query={-activities}&format=json`)
        .then((response) => {
          this.sequencetypes = response.data;
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
          this.$buefy.dialog.alert({
            title: 'Mince...',
            message: 'Une information n\'a pas pu être totalement chargée, nous vous invitons à recharger la page.',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
          });
        });
    },
    setdata() {
      //   function to load values in form for the 3 first steps
      this.form.name = this.frameworksequence.name;
      this.form.estimated_duration = this.frameworksequence.estimated_duration;
      this.form.comment = this.frameworksequence.comment;
      this.form.goals = this.frameworksequence.goals;
    },
    gotoStep(numberStep) {
      this.currentTab = numberStep;
      this.step = numberStep;
    },
    gotoResources() {
      this.currentTab = 'resources';
      this.step = this.currentTab;
      this.initResources();
    },
    // go to a specific tab in page with stepNumber param
    goPreviousPage(step) {
      this.$buefy.dialog.confirm({
        title: 'Attention, vous êtes sur le point de fermer cette expérience',
        message: 'Vous perdrez toutes vos modifications non enregistrées.',
        confirmText: 'Fermer',
        cancelText: 'Annuler',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.$router.push({
            name: 'FrameworkEdit',
            params: { id: this.idFramework, tab: step },
          });
        },
      });
    },
    gotoActivity(numberStep) {
      this.currentTab = numberStep + 2;
      this.step = numberStep + 2;
      this.NumberActivity = numberStep;
    },
    order(array) {
      return _.orderBy(array, 'position');
    },
    // edit a sequence
    updateSequence() {
      this.$http
        .patch(`/sequences/${this.$route.params.id}/?format=json`, {
          name: this.form.name,
          estimated_duration: this.form.estimated_duration,
          comment: this.form.comment,
          goals: this.form.goals,
        }).then(() => {
          this.$buefy.toast.open({ message: 'Expérience mise à jour', type: 'is-success' });
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
        });
    },
    saveActivity(idActivity, index) {
      const arrayActivity = this.frameworksequence.activities[index];
      this.$http
        .patch(`/activities/${idActivity}/`, arrayActivity)
        .then(() => {
          this.$buefy.toast.open({ message: 'Activité mise à jour', type: 'is-success' });
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
        });
    },
    saveAll() {
      this.updateSequence();
      for (let i = 0; i < this.frameworksequence.activities.length; i += 1) {
        window.console.log(this.frameworksequence.activities[i]);
        window.console.log(this.frameworksequence.activities[i].id);
        this.$http
          .patch(`/activities/${this.frameworksequence.activities[i].id}/`, this.frameworksequence.activities[i])
          .then((response) => {
            // this.$buefy.toast.open({ message: 'Activité mise à jour', type: 'is-success' });
            window.console.log(response.data);
          }).catch((error) => {
            window.console.log(error);
            this.errored = true;
          });
      }
    },
    initResources() {
      this.startLoading();
      this.$http.get(`/get_resources/?framework_sequence=${this.frameworksequence.id}&sequence_type=${this.frameworksequence.sequence_type}&format=json`).then((response) => {
        this.resources = response.data.resources;
        this.endLoading();
      });
    },
    startLoading() {
      this.isLoading = true;
    },
    endLoading() {
      this.isLoading = false;
    },
    defaultText(stepContent, ActivityStep) {
      const activity = ActivityStep;
      activity.content = stepContent;
    },
    forceRerender() {
      // Every time that forceRerender is called, our prop componentKey will change.
      this.resourceKey += 1;
    },
  },
  created() {
    this.initFrameworkSequence();
    // verification if user come from an another paeg and had to go to a specific tab
    if (this.$route.params.idFramework) { this.idFramework = this.$route.params.idFramework; }
  },
  mounted() {
  },
};
</script>
