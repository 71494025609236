<template>
  <div
    tabindex="-1"
    role="dialog"
    aria-label="Example Modal"
    aria-modal="true"
    class="modalPerso is-active"
  >
    <div class="modal-background"></div>
    <b-loading :is-full-page="isFullPage" v-model="isLoading"> </b-loading>
    <div class="animation-content" style="max-width: 1460px">
      <div
        class="modal-card"
        style="background-color: #fff; border-radius: 4px"
      >
        <div
          class="
            is-flex
            justify-content-center
            buttons-modal
            align-items-center
          "
        >
          <div class="ml-auto pl-5 mt-3 is-flex align-items-center">
            <img
              src="@/assets/img/editor/left.svg"
              @click="rotateLeft"
              class="mr-4"
              width="25"
            />

            <img
              src="@/assets/img/editor/right.svg"
              @click="rotateRight"
              class="mr-4"
              width="25"
            />

            <b-button type="is-primary" @click="canvastoBlob">Ajouter</b-button>
          </div>
          <a
            @click="$emit('close') && removeModalOpened()"
            class="ml-auto pr-3"
          >
            <b-icon icon="close" type="is-primary"></b-icon>
          </a>
        </div>
        <canvas id="canvas" width="1500" height="1500"></canvas>
        <br />
      </div>
    </div>
  </div>
</template>

<script>
const previewImage = document.createElement('img');

export default ({
  props: {
    fileImage: {
      type: [File],
    },
    stepId: {
      type: [Number, String],
    },
    stepEntryContent: {},
  },
  data() {
    return {
      isOpen: null,
      //   image: {},
      //   ctx: {},
      //   canvas: {},
      angleInDegrees: 0,
      keyEnter: 0,
      canvas: {},
      context: {},
      isLoading: false,
      isFullPage: false,
      portrait: false,
      heightDraw: null,
      widthDraw: null,
      x: 0,
      y: 0,
      newX: 0,
      newY: 0,
    };
  },
  methods: {
    startLoading() {
      this.isLoading = true;
    },
    endLoading() {
      this.isLoading = false;
    },
    loadimage() {
      const reader = new FileReader();
      const imageFile = this.fileImage;
      reader.readAsDataURL(imageFile);
      reader.onload = (event) => {
        previewImage.src = event.target.result;
      };
      previewImage.onload = () => {
        this.canvas = document.getElementById('canvas');
        this.context = this.canvas.getContext('2d');
        // verify if image is portrait or landscape
        if (previewImage.height > previewImage.width) {
          this.portrait = true;
          this.heightDraw = this.canvas.height;
          this.widthDraw = (this.canvas.height * previewImage.width) / previewImage.height;
          this.x = (this.canvas.width - this.widthDraw) / 2;
          this.y = 0;
        } else {
          this.widthDraw = this.canvas.width;
          this.heightDraw = (this.canvas.width * previewImage.height) / previewImage.width;
          this.x = 0;
          this.y = (this.canvas.height - this.heightDraw) / 2;
        }
        this.context.drawImage(previewImage, this.x, this.y, this.widthDraw, this.heightDraw);
      };
    },
    rotateRight() {
      this.angleInDegrees += 90;
      this.drawRotated(this.angleInDegrees);
    },
    rotateLeft() {
      this.angleInDegrees -= 90;
      this.drawRotated(this.angleInDegrees);
    },
    drawRotated(degrees) {
      this.canvas = document.getElementById('canvas');
      this.context = this.canvas.getContext('2d');
      this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
      this.context.save();
      const cw = this.canvas.width / 2;
      const ch = this.canvas.height / 2;
      this.context.translate(cw, ch);
      this.context.rotate((degrees * Math.PI) / 180);
      this.context.translate(-cw, -ch);
      this.context.drawImage(previewImage, this.x, this.y, this.widthDraw, this.heightDraw);
      this.context.restore();
    },
    canvastoBlob() {
      const tempCanvas = document.createElement('canvas');
      const tCtx = tempCanvas.getContext('2d');
      if (this.portrait) {
        tempCanvas.width = this.widthDraw;
        tempCanvas.height = this.canvas.height;
        this.newY = this.y;
        this.newX = -this.x;
      } else {
        tempCanvas.width = this.canvas.width;
        tempCanvas.height = this.heightDraw;
        this.newY = -this.y;
        this.newX = this.x;
      }
      tCtx.drawImage(this.canvas, this.newX, this.newY);
      tempCanvas.toBlob((blob) => {
        this.postImage(blob);
      }, 'image/jpeg', 1);
    },
    removeModalOpened() {
      document.body.classList.toggle('modalOpened');
      this.isCardModalActive = false;
    },
    postImage(myBlob) {
      // window.console.log(myBlob);
      this.startLoading();
      const myFile = new File([myBlob], this.fileImage.name, { type: this.fileImage.type });
      const formData = new FormData();
      formData.append('url', myFile);
      formData.append('name', this.fileImage.name);
      this.$http
        .post('/images/', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((response) => {
          this.$buefy.toast.open({ message: 'Image ajoutée', type: 'is-success' });
          this.$emit('update:imageResized', response.data.url);
          this.$emit('loaded');
          document.body.classList.toggle('modalOpened');
          this.$emit('close');
          this.endLoading();
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
          this.$buefy.dialog.alert({
            title: 'Mince...',
            message: 'L\'image n\'a pas pu être ajoutée. ',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
          });
        });
    },
  },
  mounted() {
    document.body.classList.toggle('modalOpened');
    if (this.keyEnter === 0) { this.loadimage(); }
    this.keyEnter += 1;
  },
});

</script>

<style scoped>
canvas {
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  background-color: white;
}
</style>
