<template>
  <div class="columns w-100 ml-0 mr-0">
    <!-- {{ studentactivity.id }} -->
    <!-- column left hidden on small screens-->
    <div
      class="column is-narrow col-left"
      v-if="studentactivity.student_activity_steps"
    >
      <div>
        <div
          class="title_menu"
          v-if="
            studentactivity.rights.includes('w_' + currentUser.role) &&
            studentactivity.status != 3 &&
            edit === true
          "
        >
          Rédigez votre partie
        </div>
        <div v-else class="title_menu">Contenu de l'activité</div>
        <div
          class="menuActivity-item"
          v-for="(stepActivity, i) in order(
            studentactivity.student_activity_steps
          )"
          :key="i"
        >
          <a
            :href="'#stepEntry' + i"
            :class="i === anchor ? 'is-active' : ''"
            @click="anchor = i"
          >
            {{ stepActivity.legacy_activity_step.name }}</a
          >
        </div>
        <a
          v-if="resources.length"
          href="#resources"
          @click="anchor = 'resources'"
          :class="anchor === 'resources' ? 'is-active' : ''"
          >Ressources</a
        >
        <div
          v-if="
            edit === true &&
            studentactivity.student_activity_steps.length &&
            studentactivity.status != 3
          "
        >
          <b-button
            @click="patchAllStepEntry()"
            v-if="started === true"
            class="mb-3"
            type="is-primary is-rounded mr-3 mt-5"
            rounded
            >enregistrer</b-button
          >
          <b-button
            v-if="started === true"
            @click="validateActivity"
            type="is-success is-rounded mr-3"
            rounded
            >valider</b-button
          >
          <AppHelpMessage
            class="mt-4"
            title="Rédigez et enregistrez votre activité"
            message="<b>Vous pouvez enregistrer vos notes autant de fois que
                     vous le souhaitez.</b> Tant que vous ne validez pas votre travail,
                      l'activité n'est pas considérée comme effectuée.</b>
            <br/><b>Une fois validée, vous ne pourrez plus modifier vos notes. </b>"
            textBtn="Besoin d'aide ?"
          />
        </div>
      </div>
    </div>
    <!-- general content -->
    <div class="container pt-5 pr-5 contentActivityDetails">
      <div class="card_event mt-5 is-flex align-items-center pb-5 pt-3 mb-2">
        <!---------------------- dates read for all users -------------------- -->
        <div class="w-100">
          <div
            class="columns is-desktop is-vcentered mt-0"
            v-if="editDates === false"
          >
            <div class="column is-narrow">
              <h1 class="title-paragraph mb-0">
                Dates de l'activité programmée
              </h1>
              <div v-if="studentactivity.start_date">
                <div>
                  du {{ studentactivity.start_date | day }} à
                  {{ studentactivity.start_date | hour }}
                </div>
                <div>
                  au {{ studentactivity.end_date | day }} à
                  {{ studentactivity.end_date | hour }}
                </div>
              </div>
              <div v-else>non définies</div>
            </div>
            <div class="column is-narrow clear"></div>
            <div class="column">
              <div class="subtitle-paragraph mb-0">lieu de rendez-vous</div>
              <span v-if="studentactivity.location">{{
                studentactivity.location
              }}</span>
              <span v-else>indéfini</span>
            </div>
            <div class="column is-flex align-items-center">
              <div v-if="studentactivity.status === 1">
                <b-button
                  v-if="
                    (currentUser.role === 'manager' && sequencetype === 1) ||
                    (currentUser.role === 'manager' && sequencetype === 7) ||
                    currentUser.role === 'superviseur' ||
                    currentUser.role === 'coordinateur'
                  "
                  type="is-primary"
                  @click="editDates = !editDates"
                  class="mr-5 ml-2"
                  >replanifier</b-button
                >
              </div>
              <div v-else-if="studentactivity.status === 0">
                <b-button
                  v-if="
                    (currentUser.role === 'manager' && sequencetype === 1) ||
                    (currentUser.role === 'manager' && sequencetype === 7) ||
                    currentUser.role === 'superviseur' ||
                    currentUser.role === 'coordinateur'
                  "
                  type="is-primary"
                  @click="editDates = !editDates"
                  class="mr-5 ml-2"
                  >planifier</b-button
                >
              </div>
              <div v-else class="ml-auto"></div>
              <div class="mr-3">
                <span class="tag status1" v-if="studentactivity.status === 0"
                  >À programmer</span
                >
                <span class="tag status1" v-if="studentactivity.status === 1"
                  ><span v-if="studentactivity.start_date < today"
                    >en cours</span
                  ><span v-else>Programmée</span></span
                >
                <span class="tag status3" v-if="studentactivity.status === 3"
                  >Effectuée</span
                >
              </div>
              <!-- // manager can change dates on afest sequence
                // manager can change dates on "mise en situation tutorée" sequence -->
            </div>

            <!---------------------- modification dates and status --------------------
           button modify only for manager, techers, coordinateur,, superviseur -->
            <div class="column" v-if="studentactivity.status === 1">
              <!-- studentactivity.status != 3 need to verify if activity is made -->
              <div class="is-flex align-items-center">
                <div
                  v-if="
                    currentUser.role === 'superviseur' ||
                    currentUser.role === 'coordinateur'
                  "
                >
                  <b-switch
                    v-model="form.newstatus"
                    @input="changeStatus"
                    true-value="3"
                    false-value="1"
                    type="is-success"
                  >
                    Activité effectuée
                  </b-switch>
                </div>
              </div>
            </div>
          </div>
          <!---------------------- dates edition for manager or CFA profiles ---------------------->
          <div class="w-100 pt-5" v-else>
            <h1 class="title-paragraph">Dates de l'activité</h1>
            <div class="subittle-paragraph">
              Renseignez les dates réelles de cette activité, en accord avec
              tous les intervenants
            </div>
            <div class="columns is-desktop is-vcentered mt-2">
              <div class="column is-narrow">
                <b-field label="Date de début">
                  <b-datetimepicker
                    v-model="studentactivity.start_date"
                    :locale="locale"
                    :datepicker="{ firstDayOfWeek: 1, showWeekNumber: true }"
                    icon="calendar-today"
                    trap-focus
                    inline
                  >
                  </b-datetimepicker>
                </b-field>
              </div>
              <div class="column is-narrow">
                <b-field label="Date de fin">
                  <b-datetimepicker
                    v-model="studentactivity.end_date"
                    :show-week-number="showWeekNumber"
                    :locale="locale"
                    icon="calendar-today"
                    trap-focus
                    inline
                    :datepicker="{ firstDayOfWeek: 1, showWeekNumber: true }"
                  >
                  </b-datetimepicker>
                </b-field>
              </div>
              <div class="column">
                <b-field
                  label="lieu de rendez-vous"
                  type="textarea"
                  class="mb-0"
                >
                  <b-input
                    style="min-height: 4em"
                    type="textarea"
                    v-model="studentactivity.location"
                  ></b-input
                ></b-field>
              </div>
            </div>
            <div class="mb-4">
              <b-button @click="setdata" type="is-rounded mr-2"
                >Annuler</b-button
              >
              <b-button
                :disabled="
                  !studentactivity.start_date || !studentactivity.end_date
                "
                @click="patchOneActivity(studentactivity.id)"
                type="is-primary is-rounded mr-3"
                rounded
                >enregistrer</b-button
              >
            </div>
          </div>
          <div v-if="studentactivity.student_activity_steps">
            <div v-if="studentactivity.student_activity_steps.length">
              <b-message
                type="is-info"
                v-if="
                  started === false &&
                  late === false &&
                  studentactivity.status === 1
                "
              >
                <span
                  v-if="
                    studentactivity.rights.includes('w_' + currentUser.role)
                  "
                  ><b>Cette activité n'a pas encore commencé</b>, vous pourrez
                  l'éditer prochainement. Ci-dessous, voici ce que vous aurez à
                  rédiger.</span
                >
                <span v-else
                  ><b>Cette activité n'a pas encore commencé</b>, vous pourrez
                  la consulter prochainement.
                </span></b-message
              >
              <b-message
                type="is-success"
                v-if="
                  started === true &&
                  edit === true &&
                  studentactivity.status != 3
                "
                ><b>Cette activité a commencé, vous pouvez l'éditer</b><br
              /></b-message>
              <div v-if="studentactivity.status === 3">
                <b-message
                  type="is-success"
                  v-if="
                    studentactivity.rights.includes('r_' + currentUser.role)
                  "
                  ><b
                    >Cette activité a été effectuée, vous pouvez consulter son
                    contenu</b
                  ><br />Cette activité est en lecture seule pour vous.
                </b-message>
                <b-message type="is-success" v-else>
                  <span
                    v-if="
                      studentactivity.rights.includes('w_' + currentUser.role)
                    "
                    ><b>Vous avez bien effectué cette activité, </b>vous pouvez
                    consulter son contenu</span
                  >
                  <span v-else
                    ><b>L'activité a bien été effectuée, </b>vous pouvez
                    consulter son contenu</span
                  >
                </b-message>
              </div>
              <b-message
                type="is-info"
                v-if="
                  started === true &&
                  edit === false &&
                  studentactivity.status != 3
                "
                ><b
                  >Cette activité a commencé mais n'a pas encore été
                  effectuée.</b
                ><br />Cette activité est en lecture seule pour vous.
              </b-message>
              <b-message
                type="is-danger"
                v-if="
                  late === true &&
                  studentactivity.status != 3 &&
                  studentactivity.rights.includes('w_' + currentUser.role)
                "
                ><b
                  >Attention, vous êtes en retard, vous auriez déjà dû effectuer
                  cette activité.</b
                >
              </b-message>
              <b-message
                type="is-danger"
                v-if="
                  late === true &&
                  studentactivity.status != 3 &&
                  studentactivity.rights.includes('r_' + currentUser.role)
                "
                ><b
                  >Attention, cette activité est en retard, elle aurait déjà dû
                  être effectuée.</b
                >
              </b-message>
            </div>
          </div>
        </div>
      </div>
      <!-- ------------- Messages relative to status, if activity is editable ---------->

      <div v-if="studentactivity.student_activity_steps">
        <div class="head_activity">L'activité</div>

        <!-- -------------------- presentation blocks for CFA profiles
        they can see same messages than in sequence and activity edit in general "parcours"
        to help them to understand "parcours" and goals of this activity -->
        <div v-if="trainingsequence">
          <div
            class="frowho mb-2"
            v-if="
              currentUser.role === 'coordinateur' ||
              currentUser.role === 'formateur' ||
              currentUser.role === 'superviseur'
            "
          >
            <b-icon icon="user" type="is-primary"></b-icon>
            <div v-if="trainingsequence.legacy_activity">
              {{ trainingsequence.legacy_activity.intro }}
            </div>
            <div v-else>
              {{ trainingsequence.intro_override }}
            </div>
          </div>
          <div
            v-if="
              currentUser.role === 'formateur' ||
              'superviseur' ||
              'coordinateur'
            "
          >
            <div
              class="helpUser mt-0"
              v-if="
                currentUser.role === 'coordinateur' ||
                currentUser.role === 'formateur' ||
                currentUser.role === 'superviseur'
              "
            >
              <div class="title" v-if="trainingsequence.legacy_activity">
                {{ trainingsequence.legacy_activity.title }}
              </div>
              <div v-else class="title">
                {{ trainingsequence.title_override }}
              </div>
              <div v-if="trainingsequence.legacy_activity">
                {{ trainingsequence.legacy_activity.description }}
              </div>
              <div v-else>
                {{ trainingsequence.description_override }}
              </div>
            </div>
            <div class="helpUser" v-if="studentactivity.presentation_apprenant">
              {{ studentactivity.description }}
            </div>
          </div>
        </div>

        <!----------------------- presentation blocks for 3 users --------------------------->
        <!------------------  exception ----------------
        for afest sequence and mise en situation tutoree
        manager can see content to preserve confidentiality -->
        <div v-if="currentUser.role === 'apprenant'">
          <div v-if="sequencetype === 1 || sequencetype === 7">
            <div v-if="studentactivity.position === 1">
              <div class="frowho">
                <b-icon icon="warning" type="is-primary"></b-icon>
                Votre tuteur a noté quelques points d'attention concernant votre
                situation de travail. <br />Vous pouvez les consulter avant
                d'effectuer votre situation de travail.
              </div>
            </div>
            <div v-if="studentactivity.position === 2">
              <div class="frowho">
                <b-icon icon="warning" type="is-primary"></b-icon>
                Votre tuteur doit confirmer ici que la situation de travail a
                bien eu lieu et peut signaler s'il y a eu un incident
                particulier.
              </div>
            </div>
            <div v-if="studentactivity.position === 3">
              <div class="frowho">
                <b-icon icon="warning" type="is-primary"></b-icon>
                Les textes que vous rédigez ici ne seront pas diffusés à vos
                supérieurs.<br />
                Ils pourront être lus par votre tuteur.
              </div>
            </div>
            <div v-if="studentactivity.position === 5">
              <div class="frowho">
                <b-icon icon="warning" type="is-primary"></b-icon>
                Les textes que vous rédigez ici, avec votre tuteur, ne seront
                pas diffusés à vos supérieurs.<br />
                Ils restent entre votre tuteur et vous.
              </div>
            </div>
            <div v-if="studentactivity.position === 6">
              <div class="frowho">
                <b-icon icon="warning" type="is-primary"></b-icon>
                Cette syntèse sera communiquée à votre manager ou l'équipe RH de
                votre entreprise.
              </div>
            </div>
          </div>
          <!-- <div class="helpUser" v-if="studentactivity.presentation_apprenant">
            {{ studentactivity.presentation_apprenant }}
            <div v-if="sequencetype === 1 || sequencetype === 7">
              Les textes que vous rédigez ici ne seront pas diffusés à vos
              supérieurs, ils restent entre votre tuteur et vous.
            </div>
          </div> -->
        </div>
        <div v-if="currentUser.role === 'tuteur'">
          <div v-if="sequencetype === 1 || sequencetype === 7">
            <div v-if="studentactivity.position === 1">
              <div class="frowho">
                <b-icon icon="warning" type="is-primary"></b-icon>
                Préparez ici votre apprenant à sa situation de travail, notes
                que votre apprenant pourra consulter avant de vivre sa situation
                de travail.
              </div>
            </div>
            <div v-if="studentactivity.position === 2">
              <div class="frowho">
                <b-icon icon="warning" type="is-primary"></b-icon>
                Pouvez-vous confirmer que la situation de travail a bien eu lieu
                ?
                <br />
                Vous pouvez aussi signaler si un incident particulier en a gêné
                son déroulement.
              </div>
            </div>
            <div v-if="studentactivity.position === 3">
              <div class="frowho">
                <b-icon icon="warning" type="is-primary"></b-icon>
                Les textes rédigés par votre apprenant ne sont pas diffusés au
                manager ou à l'équipe RH de l'entreprise. <br />Ils restent
                entre vous et votre apprenant.
              </div>
            </div>
            <div v-if="studentactivity.position === 4">
              <div class="frowho">
                <b-icon icon="warning" type="is-primary"></b-icon>
                Les textes rédigés ici ne sont pas diffusés au manager ou à
                l'équipe RH de l'entreprise. <br />Ils ne sont pas lus non plus
                par votre apprenant.
              </div>
            </div>
            <div v-if="studentactivity.position === 5">
              <div class="frowho">
                <b-icon icon="warning" type="is-primary"></b-icon>
                Les textes que vous rédigez ici, avec votre apprenant, ne seront
                pas diffusés à vos supérieurs.<br />
                Ils restent entre votre apprenant et vous.
              </div>
            </div>
            <div v-if="studentactivity.position === 6">
              <div class="frowho">
                <b-icon icon="warning" type="is-primary"></b-icon>
                Cette syntèse sera communiquée au manager ou l'équipe RH de
                votre entreprise.
              </div>
            </div>
          </div>
          <!-- <div class="helpUser" v-if="studentactivity.presentation_tuteur">
            {{ studentactivity.presentation_tuteur }}
            <div v-if="sequencetype === 1 || sequencetype === 7">
              Les textes que vous rédigez ici avec votre apprenant seront pas
              diffusés à vos supérieurs, ils restent entre votre tuteur et vous.
            </div>
          </div> -->
        </div>
        <div v-if="currentUser.role === 'manager'">
          <div v-if="sequencetype === 1 || sequencetype === 7">
            <div v-if="studentactivity.position === 1">
              <div class="frowho">
                <b-icon icon="warning" type="is-primary"></b-icon>
                Le tuteur prépare sa situation et communique ici quelques infos
                avec son apprenant avant la situation de travail
              </div>
            </div>
            <div v-if="studentactivity.position === 6">
              <div class="frowho">
                <b-icon icon="warning" type="is-primary"></b-icon>
                Cette syntèse est issue des temps d'échange entre tuteur et
                apprenant dont une partie reste confidentielle afin de garantir
                une relation de confiance entre tuteur et apprenant.
              </div>
            </div>
          </div>
        </div>

        <!----------------------- content activity ------------------------------>

        <!------------------  exception ----------------
        for afest sequence and mise en situation tutoree
        manager can see content to preserve confidentiality -->
        <div
          v-if="
            (sequencetype === 1 &&
              currentUser.role === 'manager' &&
              studentactivity.position === 3) ||
            (sequencetype === 1 &&
              currentUser.role === 'manager' &&
              studentactivity.position === 4) ||
            (sequencetype === 1 &&
              currentUser.role === 'manager' &&
              studentactivity.position === 5) ||
            (sequencetype === 7 &&
              currentUser.role === 'manager' &&
              studentactivity.position === 3) ||
            (sequencetype === 7 &&
              currentUser.role === 'manager' &&
              studentactivity.position === 4) ||
            (sequencetype === 7 &&
              currentUser.role === 'manager' &&
              studentactivity.position === 5)
          "
        >
          <!-- <div class="frowho">
            <b-icon icon="warning" type="is-primary"></b-icon>
            Vous n'avez pas le droit de lire le contenu de cette activité.Cette
            activité reste confidentielle entre l'apprenant et son tuteur.
          </div> -->
          <b-message type="is-warning"
            ><b
              >Vous n'avez pas le droit de lire le contenu de cette activité.</b
            >
            Cette activité reste confidentielle entre l'apprenant et son
            tuteur.</b-message
          >
        </div>
        <!-- end exception -->

        <!------------------  activity editon and reading part ---------------- -->
        <div class="card_item cardpage_activity" v-else>
          <div v-if="studentactivity.student_activity_steps.length">
            <!------------------  step loop ---------------- -->
            <div
              v-for="(stepActivity, i) in order(
                studentactivity.student_activity_steps
              )"
              :key="i"
              :id="'stepEntry' + i"
              class="stepActivity"
            >
              <!-- ---------------- title / subtitle-->
              <div class="title-paragraph">
                {{ stepActivity.legacy_activity_step.name }}
              </div>
              <div v-if="sequencetype === 10 && studentactivity.position === 1">
                <div class="subtitle-paragraph mb-4">
                  <div
                    v-html="
                      stepActivity.legacy_activity_step.description_override
                    "
                  ></div>
                </div>
              </div>
              <div class="subtitle-paragraph mb-4" v-else>
                <div
                  v-if="stepActivity.legacy_activity_step.content"
                  v-html="stepActivity.legacy_activity_step.content"
                >
                  <!-- {{ stepActivity.legacy_activity_step.content }} -->
                </div>
                <div
                  v-else
                  v-html="
                    stepActivity.legacy_activity_step.placeholder_override
                  "
                >
                  <!-- {{ stepActivity.legacy_activity_step.placeholder_override }} -->
                </div>
              </div>

              <!-- ---------------- activity started and edit true with ck editor -------------->
              <div
                v-if="
                  started === true &&
                  edit === true &&
                  studentactivity.status != 3
                "
                class="mt-5 mb-6"
              >
                <!------------------ exception----------------
                 if sequence type is "présentation periode" activité 1 et 2 no text editor -->
                <div
                  v-if="
                    (sequencetype === 2 &&
                      stepActivity.legacy_activity_step.position === 1) ||
                    (sequencetype === 2 &&
                      stepActivity.legacy_activity_step.position === 2)
                  "
                ></div>
                <div
                  v-else-if="
                    stepActivity.legacy_activity_step.content_type === 1
                  "
                  class="mt-5 pt-5"
                >
                  <SkillsEvaluation :role="studentactivity.rights" />
                </div>
                <!-- if sequence type is not "présentation periode"
                activité 1 et 2 text editor visible -->

                <!------------------ ck editor ---------------->
                <div v-else>
                  <ModalImage
                    v-if="isCardModalActive === i"
                    :fileImage.sync="imageUploaded"
                    :stepId="stepActivity.id"
                    :stepEntryContent="stepActivity.step_entry"
                    :imageResized.sync="imageResized"
                    @close="closeModal"
                    @loaded="
                      addImageinSteps(
                        stepActivity.id,
                        stepActivity.step_entry,
                        imageResized
                      )
                    "
                  />
                  <div class="is-flex">
                    <b-field
                      class="file is-primary mb-1 addImage"
                      :class="{ 'has-name': !!file }"
                    >
                      <b-upload
                        v-model="file"
                        class="file-label"
                        @input="uploadImageModal(i)"
                      >
                        <span class="file-cta">
                          <b-icon class="file-icon" icon="upload"></b-icon>
                          <span class="file-label">Ajouter une image</span>
                        </span>
                      </b-upload>
                    </b-field>
                  </div>
                  <div :id="'s' + i" class="ck-editor-block">
                    <ckeditor
                      :editor="editor"
                      v-model="stepActivity.step_entry"
                      :config="editorConfig"
                      class="mb-5"
                      :ref="'s' + i"
                    ></ckeditor>
                  </div>
                </div>
                <!-- end exception -->
              </div>
              <!------------------  activity status is "effectuée" ---------------- -->
              <div v-if="studentactivity.status === 3">
                <!-- {{ stepActivity.step_entry }} -->
                <div
                  class="card_grey card_edit ck"
                  v-if="stepActivity.step_entry"
                  v-html="stepActivity.step_entry"
                ></div>
                <div v-else>
                  <div
                    v-if="
                      (!stepActivity.step_entry &&
                        sequencetype === 2 &&
                        stepActivity.position === 1) ||
                      (!stepActivity.step_entry &&
                        sequencetype === 2 &&
                        stepActivity.position === 2)
                    "
                  ></div>
                  <div
                    v-else-if="
                      stepActivity.legacy_activity_step.content_type === 1
                    "
                  >
                    <SkillsLevels :role="studentactivity.rights" />
                  </div>
                  <div v-else class="card_grey">pas de texte renseigné</div>
                </div>
              </div>

              <!-- ---------------- activity not began, not "effectuée"---------------- -->
              <div v-if="started === false || studentactivity.status != 3">
                <!-- if sequence type is not "presentation periode"  -->
                <!-- if sequence type is "presentation periode" and not editable -->
                <div
                  class="card_grey is-flex"
                  v-if="
                    (sequencetype != 2 && started === false) ||
                    (sequencetype != 2 &&
                      edit === false &&
                      studentactivity.status != 3) ||
                    (sequencetype === 2 &&
                      stepActivity.position === 3 &&
                      edit === false &&
                      studentactivity.status != 3)
                  "
                >
                  <b-icon icon="user" type="is-primary"></b-icon>

                  <div v-if="studentactivity.context === 'apprenant'">
                    <span v-if="currentUser.role != 'apprenant'"
                      >Ce texte est à rédiger par l'apprenant</span
                    ><span v-else>Ce sera à vous de rédiger ce texte</span>
                  </div>
                  <span v-else>
                    Ce texte est à rédiger par le
                    {{ studentactivity.context }}</span
                  >
                </div>
                <!-- if sequence type is "presentation periode" editor is "formateur" -->
                <div
                  v-if="
                    (sequencetype === 2 && stepActivity.position === 1) ||
                    (sequencetype === 2 && stepActivity.position === 2)
                  "
                >
                  <!-- <div class="card_grey"><b-icon icon="user" type="is-primary">
                    </b-icon> Ce texte sera
                  à rédiger par le formateur</div> -->
                </div>
              </div>
              <!-- ---------------- activity started and read only view ---------------- -->

              <div
                v-if="
                  started === true &&
                  edit === false &&
                  studentactivity.status != 3
                "
                class="mt-5 mb-6"
              >
                <div class="subtitle-paragraph" v-if="stepActivity.step_entry">
                  <b><u>Texte en cours de rédaction :</u></b>
                </div>
                <span
                  class="ck-content"
                  v-if="
                    studentactivity.rights.includes('r_' + currentUser.role) ||
                    currentUser.role === 'coordinateur' ||
                    currentUser.role === 'formateur' ||
                    currentUser.role === 'superviseur'
                  "
                  v-html="stepActivity.step_entry"
                ></span>
              </div>
            </div>
            <!-- resources part -->
            <div id="resources" v-if="resources.length">
              <div class="title-paragraph">Ressources</div>
              <div v-for="resource in resources" :key="resource.id">
                <ResourceCard :resource="resource"></ResourceCard>
              </div>
            </div>
            <div class="w-100 has-text-right">
              <b-button
                class="ml-auto"
                v-if="studentactivity.status === 3"
                @click="modifyStatus"
                rounded
                >modifier</b-button
              >
            </div>
            <div
              class="is-flex is-flex-wrap-wrap activityBtns align-items-center"
              v-if="
                edit === true &&
                studentactivity.student_activity_steps.length &&
                studentactivity.status != 3
              "
            >
              <b-button
                v-if="started === true"
                @click="patchAllStepEntry"
                type="is-primary is-rounded mr-3"
                rounded
                >enregistrer</b-button
              >
              <b-button
                v-if="started === true"
                @click="validateActivity"
                type="is-success is-rounded mr-3"
                rounded
                >valider</b-button
              >
              <div class="ml-auto">
                <AppHelpMessage
                  title="Rédigez et enregistrez votre activité"
                  message="<b>Vous pouvez enregistrer vos notes autant de fois que
                     vous le souhaitez.</b> Tant que vous ne validez pas votre travail,
                      l'activité n'est pas considérée comme effectuée.</b>
            <br/><b>Une fois validée, vous ne pourrez plus modifier vos notes. </b>"
                  textBtn="Besoin d'aide ?"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';
import localization from 'moment/locale/fr';
import _ from 'lodash';
import CKEditor from '@ckeditor/ckeditor5-vue2';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import '@ckeditor/ckeditor5-build-classic/build/translations/fr';
import ResourceCard from '@/components/Resources/ResourceCard.vue';
import AppHelpMessage from '@/components/AppHelpMessage.vue';
import ModalImage from '@/components/Resources/ModalImage.vue';
import SkillsEvaluation from '@/components/Skills/SkillsEvaluation.vue';
import SkillsLevels from '@/components/Skills/SkillsLevels.vue';

moment.updateLocale('fr', localization);

export default {
  props: {
    studentactivity: [Object, Array],
    sequencetype: [String, Number],
    trainingsequence: Object,
  },
  components: {
    ckeditor: CKEditor.component,
    ResourceCard,
    AppHelpMessage,
    ModalImage,
    SkillsEvaluation,
    SkillsLevels,
  },
  filters: {
    day(date) {
      return moment(date).format('DD/MM/YYYY');
    },
    hour(date) {
      return moment(date).format('HH:mm');
    },
  },
  data() {
    return {
      today: new Date(),
      fileValidity: false,
      resources: [],
      isLoading: false,
      isFullPage: false,
      started: false,
      edit: false,
      late: false,
      file: null,
      anchor: 0,
      // variables dates
      showWeekNumber: true,
      locale: 'UTC',
      form: {
        start_date: new Date(),
        end_date: new Date(),
        location: null,
        newstatus: null,
      },
      editDates: false,
      editor: ClassicEditor,
      editorDisabled: true,
      editorConfig: {
        language: 'fr',
        toolbar: ['heading', '|', 'bold', 'italic', 'bulletedList', 'numberedList', '|', 'outdent', 'indent', '|', 'link', '|', 'undo',
          'redo'],
        alignment: {
          options: ['left', 'right'],
        },
        heading: {
          options: [
            { model: 'paragraph', title: 'Styles de texte', class: 'ck-heading_paragraph' },
            {
              model: 'heading1',
              view: 'h1',
              title: 'Titre 1',
              class: 'ck-heading_heading1',
            },
            {
              model: 'heading2',
              view: 'h2',
              title: 'Titre 2',
              class: 'ck-heading_heading2',
            },
            {
              model: 'mark',
              view: 'mark',
              title: 'surligné',
              class: 'ck-mark',
            },
          ],
        },
        table: {
          contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells'],
        },
      },
      isCardModalActive: null,
      imageUploaded: {},
      modalKey: 0,
      imageResized: null,
      skills: [{
        name: 'être capable d\'initialiser la diagbox', level: 1,
      },
      {
        name: 'être capable d\'accéder aux paramètres de la diagbox', level: 2,
      },
      {
        name: 'être capable de faire un test simple', level: 2,
      },
      ],
      skillsLevels: ['seul', 'accompagné', 'à revoir'],
    };
  },
  computed: {
    ...mapState('auth', ['currentUser']),
  },
  methods: {
    startLoading() {
      this.isLoading = true;
    },
    endLoading() {
      this.isLoading = false;
    },
    currentDateTime() {
      // const today = moment().format();
      const dateActivity = this.studentactivity.start_date;
      const endActivity = this.studentactivity.end_date;
      if (this.today > dateActivity) {
        this.started = true;
        if (this.studentactivity.rights.includes(`w_${this.currentUser.role}`)) {
          this.edit = true;
        }
      } else { this.started = false; }
      if (this.studentactivity.status === 4) {
        this.started = false;
      }
      if (endActivity < this.today) {
        this.late = true;
      }
    },
    patchAllStepEntry() {
      const arraySteps = this.studentactivity.student_activity_steps;
      // loop to patch each step entry
      for (let i = 0; i < arraySteps.length; i += 1) {
        const idStep = arraySteps[i].id;
        const entryContent = arraySteps[i].step_entry;
        this.$http
          .patch(`/agenda/student_activity_step/${idStep}/`, {
            step_entry: entryContent,
          }).then(() => {
            if (i === arraySteps.length - 1) { this.$buefy.toast.open({ message: 'Activité enregistrée', type: 'is-success' }); }
            this.endLoading();
          }).catch((error) => {
            window.console.log(error);
            this.errored = true;
            this.$buefy.dialog.alert({
              title: 'Mince...',
              message: 'L\'activité n\'a pas pu être enregistrée.',
              type: 'is-info',
              hasIcon: true,
              icon: 'times-circle',
              ariaRole: 'alertdialog',
              ariaModal: true,
              confirmText: 'Ok',
            });
          });
      }
    },
    order(array) {
      return _.orderBy(array, 'position');
    },
    changeStatus() {
      this.$buefy.dialog.confirm({
        title: 'Souhaitez-vous changer le statut de cette activité ?',
        message: 'Vous êtes sur le point de changer le statut cette activité.',
        confirmText: 'Valider',
        cancelText: 'Annuler',
        type: 'is-info',
        onConfirm: () => {
          if (this.studentactivity.status === '0') {
            const startDate = null;
            const endDate = null;
            this.studentactivity.start_date = startDate;
            this.studentactivity.end_date = endDate;
          }
          this.$http
            .patch(`/agenda/student_activity/${this.studentactivity.id}/`, {
              status: this.form.newstatus,
              start_date: this.studentactivity.start_date,
              end_date: this.studentactivity.end_date,
            })
            .then(() => {
              this.$buefy.toast.open({ message: 'Statut de l\'activité modifiée', type: 'is-success' });
            }).catch((error) => {
              window.console.log(error);
              this.errored = true;
              this.$buefy.dialog.alert({
                title: 'Mince...',
                message: 'Le statut de l\'activité n\'a pas pu être modifié.',
                type: 'is-info',
                hasIcon: true,
                icon: 'times-circle',
                ariaRole: 'alertdialog',
                ariaModal: true,
                confirmText: 'Ok',
              });
            }).then(() => {
              this.$emit('messageFromActivity', this.studentactivity.id);
            });
        },
      });
    },
    modifyStatus() {
      this.$buefy.dialog.confirm({
        title: 'Souhaitez-vous changer le statut de cette activité ?',
        message: 'Vous êtes sur le point de changer le statut cette activité.',
        confirmText: 'Valider',
        cancelText: 'Annuler',
        type: 'is-info',

        onConfirm: () => {
          this.$http
            .patch(`/agenda/student_activity/${this.studentactivity.id}/`, {
              status: 1,
            })
            .then(() => {
              this.$emit('messageFromActivity', this.studentactivity.id);
            }).catch((error) => {
              window.console.log(error);
              this.errored = true;
              this.$buefy.dialog.alert({
                title: 'Mince...',
                message: 'Le statut de l\'activité n\'a pas pu être modifié.',
                type: 'is-info',
                hasIcon: true,
                icon: 'times-circle',
                ariaRole: 'alertdialog',
                ariaModal: true,
                confirmText: 'Ok',
              });
            });
        },
      });
    },
    validateActivity() {
      this.patchAllStepEntry();
      this.$buefy.dialog.confirm({
        title: 'Souhaitez-vous valider votre activité ?',
        message: 'Vous êtes sur le point de valider cette activité. Avez-vous bien relu votre réponse ? Vous ne pourrez plus la modifier par la suite.',
        confirmText: 'Valider',
        cancelText: 'Relire',
        type: 'is-success',
        hasIcon: true,
        onConfirm: () => {
          this.studentactivity.status = 3;
          this.$http
            .patch(`/agenda/student_activity/${this.studentactivity.id}/`, {
              status: 3,
            })
            .then(() => {
              this.$buefy.toast.open({ message: 'Statut de l\'activité modifiée', type: 'is-success' });
            }).catch((error) => {
              window.console.log(error);
              this.errored = true;
              this.$buefy.dialog.alert({
                title: 'Mince...',
                message: 'Le statut de l\'activité n\'a pas pu être modifiée.',
                type: 'is-info',
                hasIcon: true,
                icon: 'times-circle',
                ariaRole: 'alertdialog',
                ariaModal: true,
                confirmText: 'Ok',
              });
            });
        },
      });
    },
    checkFile() {
      const extension = this.file.name.split('.').pop();
      // this.resources_type = this.file.substr((this.file.lastIndexOf('.') + 1));
      if (!/(jpeg|png|jpg)$/ig.test(extension)) {
        this.$buefy.dialog.alert({
          title: 'Ce type de fichier n\'est pas accepté',
          message: 'Vous pouvez utiliser les formats suivants :  jpeg, jpg, png.',
          type: 'is-info',
          hasIcon: true,
          icon: 'times-circle',
          ariaRole: 'alertdialog',
          ariaModal: true,
          confirmText: 'Ok',
        });
      } else if (this.file.size > 10000000) {
        this.$buefy.dialog.alert({
          title: 'Cette image est trop volumineuse',
          message: 'Vous pouvez télécharger une image dont la taille ne dépasse pas 10 Mo. Vous pouvez utiliser un service externe comme squoosh.app ou utiliser les options de votre périphérique (téléphone ou tablette) pour réduire une image.',
          type: 'is-info',
          hasIcon: true,
          icon: 'times-circle',
          ariaRole: 'alertdialog',
          ariaModal: true,
          confirmText: 'Ok',
        });
      } else if (this.file.name.length > 116) {
        this.$buefy.dialog.alert({
          title: 'Le nom de votre fichier est trop long',
          message: 'le nom ne doit pas dépasser 117 cractères',
          type: 'is-info',
          hasIcon: true,
          icon: 'times-circle',
          ariaRole: 'alertdialog',
          ariaModal: true,
          confirmText: 'Ok',
        });
      } else { this.nameFile = this.file.name; this.fileValidity = true; }
      // file.files[0].size
    },
    addImg(idStep, stepEntry) {
      this.checkFile();
      if (this.fileValidity === true) {
        const reader = new FileReader();
        reader.readAsDataURL(this.file);
        document.querySelector('#before').src = reader.readAsDataURL(this.file);
        fetch(this.file)
          .then((res) => res.blob())
          .then((blob) => {
            // const file = new File([blob], "File name", { type: "image/png" })
            const myfile = new File([blob], this.file.name, { type: this.file.type });
            window.console.log(`myfile ${myfile}`);
            window.console.log(`myfile ${myfile.type}`);
            const formData = new FormData();
            formData.append('url', myfile);
            formData.append('name', this.file.name);
            this.patchAllStepEntry();
            this.startLoading();
            this.$http
              .post('/images/', formData, {
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
              })
              .then((response) => {
                this.endLoading();
                this.$buefy.toast.open({ message: 'Image ajoutée', type: 'is-success' });
                //     const step = this.studentactivity.student_activity_steps[i].step_entry;
                //     this.studentactivity.student_activity_steps[i].step_entry =
                // `${step} <img src="${response.data.file}"/>`;
                let entryContent = '';
                if (stepEntry === null) {
                  entryContent = `<div class="imageUploaded"><img src="${response.data.url}"/></div>`;
                } else {
                  entryContent = `${stepEntry} <div class="imageUploaded"><img src="${response.data.url}"/></div>`;
                }
                this.$http
                  .patch(`/agenda/student_activity_step/${idStep}/`, {
                    step_entry: entryContent,
                  }).then(() => {
                    this.$emit('messageFromActivity', this.studentactivity.id);
                  }).catch((error) => {
                    window.console.log(error);
                    this.errored = true;
                    this.endLoading();
                    this.$buefy.dialog.alert({
                      title: 'Mince...',
                      message: 'L\'image n\'a pas pu être ajoutée au champ de texte.',
                      type: 'is-info',
                      hasIcon: true,
                      icon: 'times-circle',
                      ariaRole: 'alertdialog',
                      ariaModal: true,
                      confirmText: 'Ok',
                    });
                  });
              }).catch((error) => {
                window.console.log(error);
                this.errored = true;
                this.$buefy.dialog.alert({
                  title: 'Mince...',
                  message: 'L\'image n\'a pas pu être ajoutée. ',
                  type: 'is-info',
                  hasIcon: true,
                  icon: 'times-circle',
                  ariaRole: 'alertdialog',
                  ariaModal: true,
                  confirmText: 'Ok',
                });
              });
          });
      }
    },
    initResources() {
      this.$http.get(`/get_resources/?attached_student_training_activities=${this.studentactivity.id}&format=json`).then((response) => {
        this.resources = response.data;
      });
    },
    setdata() {
      this.editDates = false;
      if (this.studentactivity.start_date) {
        const startDate = new Date(this.studentactivity.start_date);
        this.studentactivity.start_date = startDate;
      }
      //   function to load values in form for the 3 first steps
      // const dateEnd = new Date(this.studentactivity.start_date);
      if (this.studentactivity.end_date) {
        const endDate = new Date(this.studentactivity.end_date);
        this.studentactivity.end_date = endDate;
      }
      if (this.sequencetype === 10 && this.studentactivity.position === 1) {
        const stepEntry = this.studentactivity.student_activity_steps[0];
        if (stepEntry.step_entry === null) {
          stepEntry.step_entry = stepEntry.legacy_activity_step.content;
        }
      }
      this.logActivity(this.studentactivity.id);
      this.form.newstatus = this.studentactivity.status;
      this.endLoading();
    },
    patchOneActivity(idActivity) {
      this.editDates = false;
      this.studentactivity.status = 1;
      this.$http
        .patch(`/agenda/student_activity/${idActivity}/`, {
          start_date: this.studentactivity.start_date,
          end_date: this.studentactivity.end_date,
          location: this.studentactivity.location,
          status: this.studentactivity.status,
        }).then(() => {
          this.$buefy.toast.open({ message: 'Dates modifiées !', type: 'is-success' });
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
          this.$buefy.dialog.alert({
            title: 'Mince...',
            message: 'Une erreur s\'est produite, veuillez réessayer. Si l\'erreur persiste vous pouvez contacter le support technique.',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
          });
        });
    },
    logActivity(idActivity) {
      let typeAction = '';
      if (this.edit === true) {
        typeAction = 'écriture';
      } else {
        typeAction = 'lecture';
      }
      if (idActivity) {
        this.$http
          .post('agenda/logs/', {
            action: typeAction,
            student_activity: idActivity,
          })
          .then(() => {
          }).catch((error) => {
            window.console.log(error);
            this.errored = true;
          });
      }
    },

    uploadImageModal(index) {
      this.checkFile();
      if (this.fileValidity === true) {
        // const image = this.file.name;
        this.imageUploaded = this.file;
        this.modalKey += 1;
        this.isCardModalActive = index;
      }
    },
    closeModal() {
      this.isCardModalActive = false;
      this.file = null;
    },
    addImageinSteps(idStep, stepEntry, url) {
      let entryContent = '';
      if (stepEntry === null) {
        entryContent = `<div class="imageUploaded"><img src="${url}"/></div>`;
      } else {
        entryContent = `${stepEntry} <div class="imageUploaded"><img src="${url}"/></div>`;
      }
      this.$http
        .patch(`/agenda/student_activity_step/${idStep}/`, {
          step_entry: entryContent,
        }).then(() => {
          this.$emit('messageFromActivity', this.studentactivity.id);
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
          this.endLoading();
          this.$buefy.dialog.alert({
            title: 'Mince...',
            message: 'L\'image n\'a pas pu être ajoutée au champ de texte.',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
          });
        });
    },
  },
  created() {
    this.today = moment().format();
  },
  mounted() {
    this.currentDateTime();
    this.startLoading();
    this.setdata();
  },
  destroyed() {
    // window.removeEventListener('selectstart', this.handleHistoryChange);
  },
};
</script>

<style scoped>
.tag {
  padding: 1rem;
}
.head-skills {
  font-size: 1.2rem;
}
</style>
