<template>
  <div class="">
    <div class="levels mb-5">
      <div class="title-paragraph mb-5 mt-5 pt-5 pb-4">
        Niveaux d'acquisition des compétences
      </div>
      <div
        class="card-header"
        v-for="(level, index) of skillsLevels"
        :key="level.id"
      >
        <div class="bg-card is-flex bg-primary align-items-center">
          <p class="card-header-title pr-3">
            {{ index + 1 }}. {{ level.name }}
          </p>
        </div>
      </div>
      <b-message type="is-info" v-if="!skillsLevels.length"
        >Aucun niveau n'a été renseigné</b-message
      >
    </div>
    <div v-if="skillsList.length">
      <div class="title-paragraph mt-5 pt-5 pb-4">
        Compétences ou critères à évaluer
      </div>
      <div class="mb-3">
        <b-button
          rounded
          @click="collapseAll(true)"
          size="is-small"
          class="mr-2"
          >ouvrir tout</b-button
        >
        <b-button rounded size="is-small" @click="collapseAll(false)"
          >fermer tout</b-button
        >
      </div>

      <div v-for="(block, indexBlock) of skillsList" :key="indexBlock">
        <div class="card-header" role="button">
          <div class="bg-card is-flex bg-primary align-items-center">
            <a
              class="card-header-icon"
              @click="collapseBlock('s' + indexBlock, 'icon' + indexBlock)"
            >
              <!-- <b-icon :icon="props.open ? 'menu-down' : 'menu-up'"> </b-icon> -->
              <div class="icon-collapse" :id="'icon' + indexBlock"></div>
            </a>
            <p class="card-header-title pr-3">
              {{ block.name }}
            </p>
            <div
              class="actions is-flex align-items-center"
              v-if="block.description || block.code"
            >
              <b-button
                type="is-primary is-shadowed is-small mr-1"
                size=""
                rounded
                @click.prevent="seeBlockdetail(block)"
                v-if="
                  (currentUser.role !== 'apprenant' && block.description) ||
                  (currentUser.role !== 'tuteur' && block.description)
                "
                >voir</b-button
              >
            </div>
          </div>
        </div>
        <div class="card-content cardClosed" :id="'s' + indexBlock">
          <div
            :list="block.skills"
            :group="{ name: 'block' }"
            class="card-padding"
            :id="'content' + indexBlock"
          >
            <div
              class="card-edit-sequence is-flex align-items-center w-100"
              v-for="(skill, index) of block.skills"
              :key="index"
              :keyblock="skill.block"
              @click.prevent="seeSkilldetail(skill, false)"
            >
              <div class="is-flex mr-auto align-items-center w-100 pr-3">
                <b-icon
                  icon="check"
                  type="is-primary"
                  class="mr-3 ml-4"
                ></b-icon
                >{{ skill.name }}
              </div>
              <div class="actions is-flex align-items-center is-hidden-mobile">
                <div class="actions-buttons is-flex align-items-center">
                  <b-button
                    type="is-primary is-shadowed is-small mr-1"
                    size=""
                    rounded
                    v-if="skill.description"
                    >voir</b-button
                  >
                </div>
              </div>
            </div>
          </div>
          <b-modal
            v-model="isSkillModalActive"
            has-modal-card
            trap-focus
            :destroy-on-hide="false"
            aria-role="dialog"
            aria-modal
          >
            <template>
              <div class="modal-card" style="width: auto">
                <section class="modal-card-body">
                  <div class="has-text-right mb-3">
                    <a @click="isSkillModalActive = false" class="ml-auto">
                      <b-icon
                        class="closeButton"
                        icon="close"
                        type="is-primary"
                      ></b-icon>
                    </a>
                  </div>
                  <div class="has-text-centered mb-2 subtitle mb-5">
                    <b>Détail de la compétence</b>
                  </div>
                  <b-field label="Nom">
                    {{ formSkill.name }}
                  </b-field>
                  <b-field label="Description">
                    <span v-if="formSkill.description">
                      <p style="white-space: break-spaces">
                        {{ formSkill.description }}
                      </p>
                    </span>
                    <span v-else>
                      <b-message type="is-info">
                        pas de description renseignée.
                      </b-message></span
                    >
                  </b-field>
                </section>
              </div>
            </template>
          </b-modal>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="title-paragraph mt-5 pt-5 pb-4">
        Compétences ou critères à évaluer
      </div>
      <b-message type="is-info">
        Aucune compétence n'est associée à cette formation.
      </b-message>
    </div>
    <b-modal
      v-model="isBlockModalActive"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-modal
    >
      <template>
        <div class="modal-card" style="width: auto">
          <section class="modal-card-body">
            <div class="has-text-right mb-3">
              <a @click="isBlockModalActive = false" class="ml-auto">
                <b-icon
                  class="closeButton"
                  icon="close"
                  type="is-primary"
                ></b-icon>
              </a>
            </div>
            <div class="has-text-centered mb-2 subtitle mb-5">
              <b>Le bloc de compétence en détails</b>
            </div>

            <b-field label="Nom">
              {{ formBlock.name }}
            </b-field>
            <b-field label="Référence" v-if="formBlock.reference">
              {{ formBlock.reference }}
            </b-field>
            <b-field label="Description" v-if="formBlock.description">
              {{ formBlock.description }}
            </b-field>
          </section>
        </div>
      </template>
    </b-modal>
    <div id="endSkills">&nbsp;</div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import _ from 'lodash';

export default {
  props: {
    typeoftraining: {
      type: String,
    }, // framework, training or studenttraining
    objectId: {
      type: Number,
    },
  },
  components: {
  },
  filters: {
  },
  data() {
    return {
      isLoading: false,
      isFullPage: false,
      formSkill: {
        id: '',
        name: '',
        description: '',
        block: '',
        reference: '',
      },
      formBlock: {
        id: '',
        name: '',
        description: '',
        block: '',
        reference: '',
      },
      skillsList: [],
      isOpenSkill: false,
      isOpen: false,
      isOpenAdd: false,
      isOpenBlock: false,
      isSkillModalActive: false,
      isBlockModalActive: false,
      isNew: true,
      myskillsList: [],
      skillsLevels: [],
    };
  },
  computed: {
    ...mapState('auth', ['currentUser']),
  },
  methods: {
    startLoading() {
      this.isLoading = true;
    },
    endLoading() {
      this.isLoading = false;
    },
    order(array) {
      return _.orderBy(array, 'position');
    },
    initSkills() {
      this.startLoading();
      let urlWs = '';
      if (this.typeoftraining === 'framework') {
        urlWs = `/frameworkskillsblock/?training_framework=${this.objectId}&format=json`;
      } else if (this.typeoftraining === 'training') {
        urlWs = `/trainingskillsblock/?training=${this.objectId}&format=json`;
      }
      this.$http
        .get(`${urlWs}`)
        .then((response) => {
          this.skillsList = this.order(response.data);
          if (this.typeoftraining === 'training') {
            /* eslint-disable */
            this.skillsList = this.skillsList.map(item => {
              return { skills: item.tskills, name: item.name, reference: item.reference, id: item.id, training: item.training, description: item.description, position: item.position };
            });
            /* eslint-enable */
          }

          for (let i = 0; i < this.skillsList.length; i += 1) {
            const myBlocks = this.skillsList;
            // eslint-disable-next-line
            this.skillsList[i].skills = this.order(myBlocks[i].skills);
          }
          this.endLoading();
        });
    },
    openEdit(index) {
      if (this.isOpen !== index) {
        this.isOpen = index;
      } else {
        this.isOpen = false;
      }
    },
    // fonctions
    onEndDrag() {
      this.editSkills();
    },
    seeSkilldetail(skill, newValue) {
      this.isSkillModalActive = true;
      this.formSkill.id = skill.id;
      this.formSkill.name = skill.name;
      this.formSkill.description = skill.description;
      this.formSkill.reference = skill.reference;
      this.formSkill.block = skill.block;
      this.isNew = newValue;
    },

    seeBlockdetail(block) {
      this.isBlockModalActive = true;
      this.formBlock.id = block.id;
      this.formBlock.name = block.name;
      this.formBlock.description = block.description;
      this.formBlock.reference = block.reference;
      this.isNew = false;
    },

    collapseBlock(id, icon) {
      const element = document.getElementById(id);
      // const elementContent = document.getElementById(idContent);
      element.classList.toggle('blockOpen');
      // if (element.style.height) {
      //   element.style.height = null;
      // } else {
      //   element.style.height = `${elementContent.offsetHeight + 70}px`;
      // }
      const iconOpen = document.getElementById(icon);
      iconOpen.classList.toggle('iconOpen');
    },
    collapseAll(allOpen) {
      for (let i = 0; i < this.skillsList.length; i += 1) {
        const element = document.getElementById(`s${i}`);
        const iconOpen = document.getElementById(`icon${i}`);
        if (allOpen) {
          if (!element.classList.contains('blockOpen')) { // jouvretout
            element.classList.add('blockOpen');
            iconOpen.classList.add('iconOpen');
          }
        } else if (!allOpen) {
          if (element.classList.contains('blockOpen')) {
            element.classList.remove('blockOpen');
            iconOpen.classList.remove('iconOpen');
            window.console.log('fermer tout');// fermertout
          }
        }
      }
    },
    initLevels() {
      let urlWs = '';
      if (this.typeoftraining === 'framework') {
        urlWs = `/frameworklevels/?training_framework=${this.objectId}&format=json`;
      } else if (this.typeoftraining === 'training') {
        urlWs = `/traininglevels/?training=${this.objectId}&format=json`;
      }
      this.$http
        .get(`${urlWs}`)
        .then((response) => {
          this.skillsLevels = this.order(response.data);
        });
    },
  },
  created() {
    // this.skillsList = this.skills;
    this.initSkills();
    this.initLevels();
  },
  mounted() {
  },
};
</script>

<style lang="scss" scoped>
.modal-card-body {
  border-radius: 5px !important;
  padding: 2rem !important;
}
.disabled {
  color: #dfdfeb;
}
.card-header-icon {
  width: auto;
  padding: 0px 0px 0px 0.5rem;
}
.card-header {
  margin-bottom: 0.5rem;
  transition: 0.2s;
}
/* .card-padding {
  padding: 1rem 0px;
} */
.closeButton {
  font-size: 1.5rem !important;
}
.modal-card {
  min-width: 627px;
}
@media (max-width: 640px) {
  .modal-card {
    min-width: unset;
    width: 100%;
  }
}
@media (max-width: 576px) {
  .card-edit-sequence {
    padding: 0.5rem;
    margin-bottom: 0.5rem;
    border: 1px solid #e3e5eb;
  }
}
@media (max-width: 376px) {
  .card-edit-sequence {
    padding: 0.5rem 0rem;
    line-height: 1.2;
    font-size: 0.9rem;
  }
}
</style>
