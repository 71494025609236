<template>
  <div class="is-flex">
    <AppSidebar />
    <div class="main-content">
      <div class="lineCrumbAndAccount">
        <AppBreadcrumb v-bind:crumbs="crumbs" />
        <AppAccountMenu v-bind:appAccount="crumbs" />
      </div>
      <div class="header-page is-flex flex-column">
        <div class="justify-content-between columns lineHeaderTitle">
          <div class="column title-page short-title">
            {{ $route.meta.header_title }}
          </div>
          <div class="column is-narrow actions">
            <b-button
              v-if="users.length"
              @click="isDisplayed = !isDisplayed"
              type="is-primary is-rounded"
              >Notifier un utilisateur</b-button
            >
          </div>
        </div>
      </div>
      <div class="header-tabs">&nbsp;</div>
      <section class="content-page pt-5">
        <div class="columns">
          <div class="column">
            <b-button
              @click="getMode('reception')"
              :class="mode === 'reception' ? 'is-primary' : ''"
              >réception</b-button
            ><b-button
              @click="getMode('send')"
              :class="mode === 'send' ? 'is-primary' : ''"
              >envoyés</b-button
            >
          </div>
          <div class="column is-narrow ml-auto">
            <b-button
              @click="getNotificationsByStatus('read')"
              class="ml-auto"
              :class="status === 'read' ? 'is-success' : ''"
              >Lues</b-button
            >
            <b-button
              @click="getNotificationsByStatus('unread')"
              class="ml-auto"
              :class="status === 'unread' ? 'is-warning' : ''"
              >Non lues</b-button
            >
            <b-button
              @click="getNotificationsByStatus('all')"
              :class="status === null ? 'is-primary' : ''"
              class="ml-auto"
            >
              Tout</b-button
            >
          </div>
        </div>

        <!-- <div class="mb-3 w-100 is-flex ml-auto">
          <b-button
            @click="getNotificationsByStatus('read')"
            class="ml-auto"
            :class="status === 1 ? 'is-primary' : ''"
            >Lues</b-button
          >
          <b-button
            @click="getNotificationsByStatus('unread')"
            class="ml-auto"
            :class="status === 0 ? 'is-primary' : ''"
            >Non lues</b-button
          >
          <b-button
            @click="getNotificationsByStatus('all')"
            :class="status === null ? 'is-primary' : ''"
            rounded
            class="ml-auto"
          >
            Tout</b-button
          >
        </div> -->
        <section v-if="errored">
          <b-message
            title="Mince... Une erreur est survenue pendant le chargement des notifications"
            type="is-danger"
            aria-close-label="Close message"
            v-if="errored"
          >
            Nous sommes sincèrement désolés, nous ne sommes pas en mesure
            d'afficher vos notifications pour le moment. Veuillez réessayer
            ultérieurement ou nous contacter via le support.
          </b-message>
        </section>
        <section v-if="isDisplayed">
          <div class="title-paragraph">Notifier un utilisateur</div>
          <div class="subtitle-paragraph">
            Vous pouvez envoyer une notification à l'utilisateur de votre choix.
            Il la recevra dans son espace personnalisé.
          </div>
          <ValidationObserver
            ref="observer"
            v-slot="{ handleSubmit }"
            v-if="users.length"
          >
            <form>
              <ValidationProvider rules="required" v-slot="{ errors, valid }">
                <b-field
                  label="Destinataires"
                  v-if="users.length"
                  :type="{ 'is-danger': errors[0], 'is-success': valid }"
                  :message="errors"
                >
                  <b-select
                    label="Destinataire"
                    placeholder="choisissez le destinataire de votre notification"
                    v-model="form.recipient"
                    expanded
                  >
                    <option
                      aria-role="listitem"
                      v-for="user in users"
                      :key="user.id"
                      :value="user.id"
                    >
                      {{ user.first_name }} {{ user.last_name }}
                      <span class="tag">({{ user.role }})</span>
                    </option>
                  </b-select>
                </b-field>
              </ValidationProvider>
              <b-field label="La notification est-elle urgente ?">
                <b-checkbox
                  v-model="form.urgent"
                  :true-value="1"
                  :false-value="0"
                >
                  Oui
                </b-checkbox>
              </b-field>
              <ValidationProvider rules="required" v-slot="{ errors, valid }">
                <b-field
                  label="Titre de la notification"
                  :type="{ 'is-danger': errors[0], 'is-success': valid }"
                  :message="errors"
                >
                  <b-input v-model="form.title"></b-input>
                </b-field>
              </ValidationProvider>
              <ValidationProvider rules="required" v-slot="{ errors, valid }">
                <b-field
                  :type="{ 'is-danger': errors[0], 'is-success': valid }"
                  :message="errors"
                  label="Votre message"
                >
                  <ckeditor
                    v-model="form.message"
                    :editor="editor"
                    :config="editorConfig"
                    class="mb-5"
                  ></ckeditor>
                </b-field>
              </ValidationProvider>
              <b-button
                v-if="
                  form.title !== null &&
                  form.recipient !== null &&
                  form.message !== null
                "
                @click="handleSubmit(notifyUser)"
                class="mb-6"
                type="is-primary"
                rounded
                >Envoyer la notification</b-button
              >
            </form>
          </ValidationObserver>
          <b-message type="is-info" v-if="errored || !users.length">
            Désolé... Vous ne pouvez pas envoyer de notification pour le moment.
          </b-message>
        </section>
        <div class="has-text-primary mb-3">
          <b> {{ notificationsList.length }} notifications</b>
        </div>
        <section v-if="notificationsList.length">
          <ul>
            <li
              v-for="notification in notificationsList"
              :key="notification.id"
            >
              <div class="card_item">
                <!-- <div class="mb-1">
                  <b>{{ notification.title }}</b>
                </div> -->
                <b-collapse :open="false" aria-id="contentIdForA11y1">
                  <template #trigger="props">
                    <div
                      aria-controls="contentIdForA11y1"
                      :aria-expanded="props.open"
                      class="w-100"
                    >
                      <div class="mb-1">
                        <div
                          v-if="notification.sender === currentUser.id"
                          class="is-flex align-items-center hours"
                        >
                          <span class="subtitle-paragraph mb-0 mr-2">
                            à : {{ notification.recipient_name }}
                          </span>
                          <span>
                            le : {{ notification.date_created | day }} à
                            {{ notification.date_created | hour }}</span
                          >
                          <span
                            class="tag is-success ml-auto"
                            v-if="notification.status === 1"
                          >
                            lue</span
                          >
                          <span class="tag is-warning ml-auto" v-else>
                            non lue</span
                          >
                        </div>
                        <div
                          v-if="notification.recipient === currentUser.id"
                          class="is-flex align-items-center hours"
                        >
                          <span class="subtitle-paragraph mb-0 mr-2"
                            >de : {{ notification.sender_name }}</span
                          >
                          <span>
                            le : {{ notification.date_created | day }} à
                            {{ notification.date_created | hour }}</span
                          >
                          <span
                            class="tag is-success ml-auto"
                            v-if="notification.status === 1"
                          >
                            lue</span
                          ><span class="tag is-warning ml-auto" v-else>
                            non lue</span
                          >
                        </div>
                      </div>
                      <b>{{ notification.title }}</b>
                    </div>
                  </template>
                  <div class="notification-content ck">
                    <hr />
                    <div class="" v-html="notification.message"></div>
                    <div v-if="notification.link" class="mt-3">
                      <router-link :to="notification.link">
                        <b-button type="is-primary" size="is-normal"
                          >Voir</b-button
                        ></router-link
                      >
                    </div>
                    <div
                      v-if="
                        notification.sender != currentUser.id &&
                        notification.status === 0
                      "
                    >
                      <hr />
                      <div class="is-flex align-items-center mt-2">
                        <b-button
                          type="is-outlined"
                          rounded
                          @click="markasRead(notification.id, 1)"
                          ><b-icon
                            icon="check"
                            type="is-success"
                            class="mr-1"
                          ></b-icon
                          >marquer comme lu</b-button
                        >
                      </div>
                    </div>
                    <div
                      v-if="
                        notification.sender != currentUser.id &&
                        notification.status === 1
                      "
                    >
                      <hr />
                      <div class="is-flex align-items-center mt-2">
                        <b-button
                          type="is-outlined"
                          rounded
                          @click="markasRead(notification.id, 0)"
                          ><b-icon
                            icon="close"
                            type="is-danger"
                            class="mr-1"
                          ></b-icon
                          >marquer comme non lu</b-button
                        >
                      </div>
                    </div>
                  </div>
                </b-collapse>
                <!-- <span class="" v-html="notification.message"></span>
                <div v-if="notification.link" class="mt-3">
                  <router-link :to="notification.link">
                    <b-button type="is-primary" size="is-normal"
                      >Voir</b-button
                    ></router-link
                  >
                </div> -->
              </div>
            </li>
          </ul>
        </section>
        <div v-else>
          <b-message type="is-info"
            >Vous n'avez pas de notification pour le moment.</b-message
          >
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { mapState } from 'vuex';
import moment from 'moment';
import localization from 'moment/locale/fr';
import AppAccountMenu from '@/components/AppAccountMenu.vue';
import AppBreadcrumb from '@/components/AppBreadcrumb.vue';
import AppSidebar from '@/components/AppSidebar.vue';
import CKEditor from '@ckeditor/ckeditor5-vue2';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import '@ckeditor/ckeditor5-build-classic/build/translations/fr';

moment.updateLocale('fr', localization);

export default {
  components: {
    ckeditor: CKEditor.component,
    AppSidebar,
    AppBreadcrumb,
    AppAccountMenu,
    ValidationProvider,
    ValidationObserver,

  },
  filters: {
    day(date) {
      return moment(date).format('DD/MM/YY');
    },
    hour(date) {
      return moment(date).format('h:mm');
    },
  },
  computed: {
    notifications() {
      return this.$store.state.notifications;
    },
    ...mapState('auth', ['currentUser']),
  },
  data() {
    return {
      notificationsList: [],
      markasReadswitch: { 1: 'lue', 0: 'non lue' },
      errored: false,
      users: [],
      isDisplayed: false,
      form: {
        title: null,
        message: null,
        sender: null,
        recipient: null,
        urgent: 0,
      },
      crumbs: [{
        text: 'Mes notifications',
        href: '/notifications',
        class: 'is-active',
      },
      ],
      status: 'unread',
      mode: 'reception',
      editor: ClassicEditor,
      editorDisabled: true,
      editorConfig: {
        language: 'fr',
        toolbar: ['heading', '|', 'bold', 'italic', 'bulletedList', 'numberedList', '|', 'outdent', 'indent', '|', 'link', '|', 'undo',
          'redo'],
        alignment: {
          options: ['left', 'right'],
        },
        heading: {
          options: [
            { model: 'paragraph', title: 'Styles de texte', class: 'ck-heading_paragraph' },
            {
              model: 'heading1',
              view: 'h1',
              title: 'Titre 1',
              class: 'ck-heading_heading1',
            },
            {
              model: 'heading2',
              view: 'h2',
              title: 'Titre 2',
              class: 'ck-heading_heading2',
            },
            {
              model: 'mark',
              view: 'mark',
              title: 'surligné',
              class: 'ck-mark',
            },
          ],
        },
        table: {
          contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells'],
        },
      },
    };
  },
  methods: {
    getNotifications(status, mode) {
      let who = '';
      if (mode === 'reception') {
        who = `recipient=${this.currentUser.id}&`;
      } else {
        who = `sender=${this.currentUser.id}&`;
      }
      let statusselected = '';
      if (status === 'unread') {
        statusselected = 'status=0&';
      } else if (status === 'read') {
        statusselected = 'status=1&';
      }
      this.$http
        .get(`/notifications/notifications/?${statusselected}${who}format=json`)
        .then((response) => {
          this.notificationsList = response.data;
        })
        .catch((error) => {
          window.console.log(error);
          this.errored = true;
          this.$buefy.dialog.alert({
            title: 'Mince...',
            message: 'Vos notifications ne peuvent être affichées pour le moment. Tenez de rafraîchir la page.',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
          });
        });
    },
    markasRead(id, newstatus) {
      this.$http
        .patch(`/notifications/notifications/${id}/`, {
          status: newstatus,
        }).then(() => {
          this.getNotifications(this.status, this.mode);
          this.$http
            .get(`/notifications/notifications/?status=0&recipient=${this.currentUser.id}&format=json`)
            .then((response) => {
              this.$store.commit('GET_NOTIFICATIONS', response.data);
            })
            .catch((error) => {
              window.console.log(error);
              this.errored = true;
            });
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
          this.$buefy.dialog.alert({
            title: 'Mince...',
            message: 'Le statut "non lue" de la notification n\'a pas pu être modifié',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
          });
        });
    },
    getUsers() {
      this.$http
        .get('/get_profiles/?items=4000&format=json')
        .then((response) => {
          this.users = response.data.results;
        })
        .catch((error) => {
          window.console.log(error);
          this.errored = true;
          this.$buefy.dialog.alert({
            title: 'Mince...',
            message: 'Les utilisateurs ne peuvent être affichées pour le moment. Tenez de rafraîchir la page.',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
          });
        });
    },
    notifyUser() {
      this.$http
        .post('/notifications/notifications/', {
          title: this.form.title,
          message: this.form.message,
          recipient: this.form.recipient,
          sender: this.currentUser.id,
          status: 0,
          urgent: this.form.urgent,
        }).then(() => {
          this.$buefy.toast.open({ message: 'Le message a bien été envoyé', type: 'is-success' });
          this.isDisplayed = false;
          this.getNotifications('unread');
          this.form = {
            title: null,
            message: null,
            sender: null,
            recipient: null,
            urgent: 0,
          };
        }).catch((error) => {
          window.console.log(error);
          this.issue = true;
          this.$buefy.dialog.alert({
            title: 'Mince...',
            message: 'Votre notification n\'a pas pu être envoyée',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
          });
        });
    },
    getNotificationsByStatus(status) {
      if (status === 'unread') {
        this.status = 'unread';
        this.getNotifications(this.status, this.mode);
      } else if (status === 'read') {
        this.status = 'read';
        this.getNotifications(this.status, this.mode);
      } else {
        this.getNotifications(null, this.mode);
        this.status = null;
      }
    },
    getMode(mode) {
      this.mode = mode;
      this.getNotifications(this.status, this.mode);
    },
  },
  created() {
    this.getNotifications(this.status, this.mode);
    this.getUsers();
  },
};
</script>

<style lang="scss" scoped>
.card_item {
  padding: 1rem 2rem;
  margin-bottom: 0.5rem;
}

/* Custom Checkbox styles */
.checkbox {
  position: relative;

  & input[type="checkbox"] {
    visibility: hidden;

    & + label {
      position: relative;
      cursor: pointer;
      padding-left: 30px;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 2px;
        width: 20px;
        height: 20px;
        border: 2px solid #999;
        border-radius: 3px;
        background-color: #fff;
      }

      &::after {
        content: "";
        position: absolute;
        top: 7px;
        left: 7px;
        width: 7px;
        height: 7px;
        background-color: #00d1b2;
        border-radius: 50%;
        transform: scale(0);
        transition: all 0.3s ease-in-out;
      }
    }

    &:checked + label::after {
      transform: scale(1);
    }
  }
}
</style>
