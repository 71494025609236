<template>
  <div class="mt-5 pt-5 container-eval">
    <div v-if="skills.length">
      <div class="columns is-hidden-mobile">
        <div class="column is-6 has-text-primary head-skills">
          <b>Compétences</b>
        </div>
        <div class="column is-6 has-text-primary head-skills">
          <b>Niveau d'acquisition</b>
        </div>
      </div>
      <div
        class="columns is-vcentered justify-space-between mb-5 pb-2 skillsRead"
        v-for="skill in order(skills)"
        :key="skill.name"
      >
        <div class="column name-skill is-6pb-1">
          <b>{{ skill.name }}</b>
        </div>
        <div class="column is-6 pb-1 is-flex align-items-center">
          <div class="columns is-desktop levelsList is-vcentered pl-2">
            <div
              v-for="(level, index) in order(skill.levels)"
              :key="level.id"
              class="column level mr-5"
            >
              <b-icon
                icon="check"
                type="is-success"
                v-if="skill.level_checked === index"
              ></b-icon>
              <b-icon icon="close" class="disabled" v-else></b-icon>
              <div class="level-label">{{ level.name }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <b-message type="is-info" v-if="type === 'progression'">
        Aucune compétence n'est associée à cette expérience, il n'y a donc
        aucune compétence à évaluer.
      </b-message>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import _ from 'lodash';

export default {
  props: {
    role: {
      type: Array,
    },
    skillsLevels: {
      type: [Object, Array],
    },
    studentTraining: {
      type: Number,
    },
    studentSequence: {
      type: Number,
    },
    type: {
      type: String,
    },
  },
  components: {
  },
  filters: {
  },
  data() {
    return {
      isLoading: false,
      isFullPage: false,
      formSkills: {},
      skills: [],
      roleEvaluation: '',
      roleArray: [],
    };
  },
  computed: {
    ...mapState('auth', ['currentUser']),
  },
  methods: {
    startLoading() {
      this.isLoading = true;
    },
    endLoading() {
      this.isLoading = false;
    },
    order(array) {
      return _.orderBy(array, 'position');
    },
    initSkills() {
      let urlWs = '';
      if (this.type === 'sequence') {
        urlWs = `/agenda/get_student_training_skills/?student_training=${this.$route.params.idStudentTraining}&student_sequence=${this.$route.params.idSequence}&role=${this.roleEvaluation}`;
      } else if (this.type === 'free') {
        urlWs = `/agenda/get_student_training_skills/?student_training=${this.$route.params.idStudentTraining}&free_situation=${this.$route.params.idFree}&role=${this.roleEvaluation}`;
      } else if (this.type === 'progression') {
        urlWs = `/agenda/get_student_training_skills/?student_training=${this.$route.params.idStudentTraining}&student_sequence=${this.$route.params.idSequence}&revision=${this.$route.params.idRevision}&role=apprenant`;
      }
      this.$http
        .get(`${urlWs}`)
        .then((response) => {
          this.skills = response.data;
          this.endLoading();
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
        });
    },
    verifRole() {
      for (let i = 0; i < this.role.length; i += 1) {
        let writer = '';
        if (this.role[i].includes('w_')) {
          writer = this.role[i].replace('w_', '');
          this.roleArray.push(writer);
        }
        if (this.roleArray.includes('apprenant') && this.roleArray.includes('tuteur')) {
          this.roleEvaluation = 'tuteur,apprenant';
          this.initSkills();
        } else if (i === (this.role.length - 1)) {
          const role = this.roleArray[0];
          this.roleEvaluation = role;
          this.initSkills();
        }
      }
    },
  },
  created() {
    if (this.role) {
      this.verifRole();
    } else {
      this.roleEvaluation = this.currentUser.role;
      this.initSkills();
    }
  },
  mounted() {
  },
};
</script>

<style scoped>
.disabled {
  color: #dfdfeb;
}
</style>
