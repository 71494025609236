<template>
  <div class="is-flex">
    <AppSidebar />
    <div class="main-content">
      <div class="lineCrumbAndAccount">
        <AppAccountMenu v-bind:appAccount="crumbs" />
      </div>
      <div class="header-page is-flex flex-column">
        <div class="justify-content-between columns lineHeaderTitle">
          <div class="column title-page short-title">
            {{ $route.meta.header_title }}
          </div>
          <div class="column is-narrow actions"></div>
        </div>
      </div>
      <section class="mt-auto" id="header-tabs">
        <div class="b-tabs">
          <nav class="tabs">
            <ul>
              <li
                v-for="(tab, index) of tabs"
                :key="index"
                :id="tab.id"
                :class="currentTab === index + 1 ? 'is-active' : ''"
              >
                <a @click="gotoStep(index + 1)"
                  ><span>{{ tab.name }}</span></a
                >
              </li>
            </ul>
          </nav>
        </div>
      </section>
      <div class="content-page pt-0">
        <b-loading :is-full-page="isFullPage" v-model="isLoading"> </b-loading>
        <div
          v-if="step === 1"
          class="container contentActivite mt-5 pt-5 pb-5 mb-6"
        >
          <PlanningUser
            :dates="dates"
            :student="Number($route.params.idStudent)"
            :studentTraining="Number($route.params.idStudentTraining)"
            :typePlanning="'todo'"
            :key="planningKey"
          ></PlanningUser>
        </div>
        <div
          v-if="step === 2"
          class="container contentActivite mt-5 pt-5 pb-5 mb-6"
        >
          <PlanningUser
            :dates="dates"
            :student="Number($route.params.idStudent)"
            :studentTraining="Number($route.params.idStudentTraining)"
            :typePlanning="'late'"
            :key="planningKey"
          ></PlanningUser>
        </div>
        <div
          v-if="step === 3"
          class="container contentActivite mt-5 pt-5 pb-5 mb-6"
        >
          <PlanningUser
            :dates="dates"
            :student="Number($route.params.idStudent)"
            :studentTraining="Number($route.params.idStudentTraining)"
            :typePlanning="'done'"
            :key="planningKey"
          ></PlanningUser>
        </div>
        <div
          v-if="step === 4"
          class="container contentActivite mt-5 pt-5 pb-5 mb-6"
        >
          <PlanningUser
            :dates="dates"
            :student="Number($route.params.idStudent)"
            :studentTraining="Number($route.params.idStudentTraining)"
            :typePlanning="'next'"
            :key="planningKey"
          ></PlanningUser>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapState } from 'vuex';
import _ from 'lodash';
import AppSidebar from '@/components/AppSidebar.vue';
import AppAccountMenu from '@/components/AppAccountMenu.vue';
import PlanningUser from '@/components/Agenda/PlanningUser.vue';

export default {
  components: {
    AppSidebar,
    AppAccountMenu,
    PlanningUser,
    // AgendaActivityDetail,
  },
  data() {
    return {
      isLoading: true,
      isFullPage: false,
      errored: false,
      crumbs: [
        {
          text: 'Formations',
          href: '/maquettes',
          class: 'is-active',
        },
      ],
      // variable to open modal on chnage false by true
      isComponentModalActive: false,
      idActivity: null,
      idStudentActivity: null,
      idStudentTraining: null,
      idStudentSequence: null,
      idTraining: null,
      SequenceType: 'SituationTypeDefault',
      color: 'default',
      nextDates: null,
      nameStudent: '',
      currentTab: 1,
      numberStep: 1,
      step: 1,
      tabs: [
        {
          name: 'A faire',
          id: '1',
        },
        {
          name: 'En retard',
          id: '2',
        },
        {
          name: 'Effectué',
          id: '3',
        },
        {
          name: 'A venir',
          id: '4',
        },
      ],
      dates: [],
      planningKey: 0,
    };
  },
  filters: {
    day(date) {
      return moment(date).format('D MMM YYYY');
    },
    hour(date) {
      return moment(date).format('H:mm');
    },
  },
  computed: {
    ...mapState('auth', ['currentUser']),
  },
  methods: {
    startLoading() {
      this.isLoading = true;
    },
    endLoading() {
      this.isLoading = false;
    },
    gotoStep(numberStep) {
      this.currentTab = numberStep;
      this.step = numberStep;
      this.dates = [];
      this.initDates(numberStep);
    },
    initDates(step) {
      this.startLoading();
      let wsUrl = '';
      if (step === 1) {
        wsUrl = '/agenda/planning/?prev=1&state=inprogress&format=json';
      } else if (step === 2) {
        wsUrl = '/agenda/planning/?prev=1&state=late&format=json';
      } else if (step === 3) {
        wsUrl = '/agenda/planning/?prev=true&status=3&format=json';
      } else if (step === 4) {
        wsUrl = '/agenda/planning/?state=future&format=json';
      }
      this.$http
        .get(wsUrl)
        .then((response) => {
          // this.endLoading();
          this.dates = response.data;
          const mydates = this.order(this.dates.dates);
          this.dates.dates = mydates;
          this.forceRerenderPlanning();
          this.endLoading();
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
          this.$buefy.dialog.confirm({
            title: 'Mince...',
            message: 'Une information n\'a pas pu être totalement chargée, nous vous invitons à recharger la page.',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
            onConfirm: () => document.location.reload(),
          });
        });
    },
    forceRerenderPlanning() {
      this.planningKey += 1;
    },
    order(array) {
      return _.orderBy(array, 'start_date', 'asc');
    },
  },
  created() {
    if (this.$route.params.stepNumber) {
      this.gotoStep(this.$route.params.stepNumber);
    } else {
      this.gotoStep(1);
    }
  },
  mounted() {
  },
};

</script>
