<template>
  <div class="is-flex">
    <!-- eslint-disable max-len-->
    <AppSidebar />
    <div class="main-content">
      <div class="lineCrumbAndAccount">
        <AppBreadcrumb v-bind:crumbs="crumbs" />
        <AppAccountMenu v-bind:appAccount="crumbs" />
      </div>
      <div class="header-page is-flex flex-column">
        <div class="justify-content-between columns lineHeaderTitle">
          <div class="column title-page short-title">Utilisateurs</div>
          <div class="column is-narrow actions"></div>
        </div>
      </div>
      <div class="content-page list-table pt-3">
        <b-loading :is-full-page="false" v-model="isLoading" :can-cancel="true"></b-loading>
        <!--content page-->
        <div class="utilisateurs">
          <section v-if="errored">
            <b-message title="Mince... Une erreur est survenue pendant le chargement des utilisateurs" type="is-danger"
              aria-close-label="Close message" v-if="errored">
              Nous sommes sincèrement désolés, nous ne sommes pas en mesure
              d'afficher ces utilisateurs pour le moment. Veuillez réessayer
              ultérieurement ou nous contacter via le support.
            </b-message>
          </section>
          <section>
            <div class="columns is-v">
              <div class="column">
                <b-field label="Rechercher par mot clé (nom, prénom, email, rôle)">
                  <b-input :lazy="false" placeholder="Rechercher..." type="search" icon="magnify" icon-clickable
                    @icon-click="initUsers()" v-model="search" @keydown.enter.native="initUsers()">
                  </b-input>
                </b-field>
              </div>
              <div class="column is-4">
                <b-field label="Rechercher par rôle">
                  <b-select placeholder="Rôle" v-model="role" expanded>
                    <option value="Tous">Tous les rôles</option>
                    <option value="apprenant">apprenant</option>
                    <option value="tuteur">tuteur</option>
                    <option value="formateur">formateur</option>
                    <option value="coordinateur">coordinateur</option>
                    <option value="superviseur">superviseur</option>
                  </b-select>
                </b-field>
              </div>
              <div class="column is-narrow mt-5">
                <b-button type="is-primary" @click="initUsers()" class="mt-auto">Rechercher</b-button>
              </div>
            </div>

            <b-table :data="users" paginated backend-pagination :per-page="perPage" :current-page="currentPage"
              :pagination-simple="isPaginationSimple" :pagination-position="paginationPosition"
              :sort-icon-size="sortIconSize" :default-sort-direction="defaultSortDirection" :debounce-search="1000"
              @page-change="onPageChange" default-sort="name" v-if="users" :total="total">
              <template v-for="column in columns">
                <b-table-column :key="column.id" v-bind="column">
                  <template v-if="column.searchable && !column.numeric" slot="searchable" slot-scope="props">
                    <b-input v-model="props.filters[props.column.field]" placeholder="Rechercher..." icon="search"
                      size="is-small" />
                  </template>
                  <template v-slot="props" v-if="column.field === 'last_name'">
                    <b>{{ props.row[column.field] }}</b>
                  </template>
                  <template v-slot="props" v-else-if="column.field === 'first_name'">
                    <b>{{ props.row[column.field] }}</b>
                  </template>
                  <template v-slot="props" v-else-if="column.field === 'role'">
                    <b><span class="tag" :class="props.row.role">
                        {{ props.row[column.field] }}
                      </span></b>
                  </template>
                  <template v-slot="props" v-else-if="column.field === 'enterprise'">
                    <span>
                      {{ props.row[column.field] }}
                    </span>
                  </template>
                  <template v-slot="props" v-else-if="column.field === 'id'">
                    <div @click.prevent="
                        seeUserprofile(props.row.id, props.row.role)
                      ">
                      <b-icon icon="see"></b-icon>
                    </div>
                  </template>
                </b-table-column>
              </template>
            </b-table>
            <b-message v-else class="mt-5" type="is-info">
              Il n'y a pas d'utilisateur attaché à cette organisation. Vous
              pouvez, si vos droits le permettent, créer de nouveaux
              utilisateurs ou vous rapprocher du support.
            </b-message>
          </section>
        </div>
        <!--content-->
      </div>
    </div>
  </div>
</template>

<script>
import AppSidebar from '@/components/AppSidebar.vue';
import AppBreadcrumb from '@/components/AppBreadcrumb.vue';
import AppAccountMenu from '@/components/AppAccountMenu.vue';

export default {
  components: {
    AppSidebar,
    AppBreadcrumb,
    AppAccountMenu,
  },
  methods: {
    initUsers() {
      this.startLoading();
      let searchtext = '';
      let roletosearch = '';
      if (this.search) {
        searchtext = `&search=${this.search}`;
      }
      if (this.role !== 'Tous') {
        roletosearch = `&role=${this.role}`;
      }
      this.$http
        .get(`/get_profiles/?page=${this.page}${searchtext}${roletosearch}&format=json`)
        .then((response) => {
          this.users = response.data.results;
          this.endLoading();
          this.total = response.data.count;
          this.totalpage = response.data.num_pages;
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
        });
    },
    seeUserprofile(param, role) {
      let stepName = 'infos';
      if (role === 'formateur' || role === 'tuteur') {
        stepName = 'parcours';
      }
      this.$router.push({
        name: 'UtilisateurDetail',
        params: {
          id: param,
          step: stepName,
        },
      });
    },
    getRole(id) {
      if (id === 'manager') {
        return 'Manager';
      } if (id === 'superviseur') {
        return 'Superviseur';
      } if (id === 'coordinateur') {
        return 'Coordinateur pédagogique';
      } if (id === 'formateur') {
        return 'Formateur';
      } if (id === 'tuteur') {
        return 'Tuteur';
      } if (id === 'apprenant') {
        return 'Apprenant';
      }
      return '';
    },
    openLoading() {
      this.isLoading = true;
      setTimeout(() => {
        this.isLoading = false;
      }, 10 * 300);
    },
    startLoading() {
      this.isLoading = true;
    },
    endLoading() {
      this.isLoading = false;
    },
    onPageChange(page) {
      this.page = page;
      window.console.log(this.page);
      this.initUsers(page);
    },
  },
  created() {
    this.initUsers(1);
  },
  mounted() {
    // this.openLoading();
  },
  data() {
    return {
      trainings: [],
      users: [],
      isLoading: false,
      open: false,
      errored: false,
      isPaginated: true,
      isPaginationSimple: false,
      paginationPosition: 'bottom',
      sortIconSize: 'is-small',
      defaultSortDirection: 'asc',
      currentPage: 1,
      perPage: 10,
      page: 1,
      totalpages: 0,
      total: 0,
      role: 'Tous',
      search: '',

      columns: [
        {
          field: 'last_name',
          label: 'Nom',
          width: '300',
          // searchable: true,
          sortable: true,
          visible: true,
        },
        {
          field: 'first_name',
          label: 'Prénom',
          width: '200',
          // searchable: true,
          sortable: true,
          visible: true,
        },
        {
          field: 'role',
          label: 'Rôle',
          centered: true,
          // searchable: true,
          sortable: true,
          visible: true,
        },
        {
          field: 'enterprise',
          label: 'Entreprise',
          centered: true,
          searchable: true,
          sortable: true,
          visible: true,
        },
        {
          field: 'id',
          label: 'Voir l\'utilisateur',
          numeric: true,
          centered: true,
          searchable: true,
          sortable: true,
          visible: true,
        },
      ],
      usersArray: [],
      crumbs: [
        {
          text: 'Utilisateurs',
          href: '/utilisateurs',
          class: 'is-active',
        },
      ],
    };
  },
};
</script>

<style scoped>
.icon {
  cursor: pointer;
}

.loading-overlay {
  background: rgb(255, 255, 255);
}
</style>
