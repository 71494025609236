<template>
  <div class="modal-card" style="background-color: #fff">
    <a @click="$emit('close')" class="ml-auto">
      <!-- <b-icon icon="close" type="is-primary"></b-icon> -->
    </a>
    <div class="nameSequence">{{ feature.intro }}</div>
    <div class="content" v-html="feature.details"></div>
    <div class="imgModal has-text-centered" v-if="feature.img">
      <img :src="require(`@/assets/${feature.img}.jpg`)" />
    </div>
  </div>
</template>

<script>

export default ({
  props: {
    feature: {
      type: [Object, Array],
    },
  },
  data() {
    return {
      isOpen: null,
    };
  },
});

</script>

<style scoped>
.title-activity {
  font-size: 1.4rem;
  margin-bottom: 0.7rem;
}
.card_item {
  border-radius: 1rem;
  height: 100% !important;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.subtitle {
  color: #531be5;
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 0.2rem !important;
}
.card_grey {
  padding: 2rem;
  background-color: #ecedf175;
}
.content {
  padding: 1rem 1rem 3rem;
}
.nameSequence {
  font-size: 2rem;
  color: #531be5;
  font-weight: 700;
  text-align: center;
  margin-bottom: 1rem;
  line-height: 1.3;
}
.imgModal img {
  border: 5px solid rgb(236, 236, 236);
  max-width: 800px;
  margin: auto;
  width: 100%;
}
@media (max-width: 768px) {
  .nameSequence {
    font-size: 1.5rem;
  }
}
@media (max-width: 576px) {
  .nameSequence {
    font-size: 1.3rem;
  }
}
</style>
