<template>
  <div>
    activités
    <!-- {{ practicalWorks }} -->
    <!-- <div v-for="practicalWorks"></div> -->
    <b-table
      v-if="practicalWorks.length > 0"
      :data="practicalWorks"
      ref="table"
      paginated
      per-page="10"
      :opened-detailed="defaultOpenedDetails"
      detailed
      detail-key="id"
      @details-open="(row) => $buefy.toast.open(`Détail de ${row.title}`)"
      :show-detail-icon="showDetailIcon"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
    >
      <b-table-column label="Titre" v-slot="props" width="500">
        {{ props.row.title }}
      </b-table-column>

      <b-table-column label="Détail" v-slot="props">
        <div
          @click.prevent="
            displayPracticalWorkDetail(props.row);
            isPracticalWorkCreation = true;
            isEditMode = true;
            chosenPracticalWork = props.row;
          "
        >
          <b-icon icon="see"></b-icon>
        </div>
      </b-table-column>

      <template #detail="props">
        <div class="mt-2">
          <div class="columns is-vcentered is-mobile mb-4">
            <div class="column">
              <h3 class="subtitle is-5 has-text-weight-semibold mt-2">
                {{ props.row.title }}
              </h3>
            </div>
          </div>
        </div>
        <table
          class="table is-fullwidth"
          responsive
          v-if="props.row.sessions.length > 0"
        >
          <thead>
            <tr>
              <th style="width: 30%">Titre</th>
              <th>Apprenants</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="session in props.row.sessions" :key="session.id">
              <td>
                <b>{{ session.title }}</b>
              </td>
              <td>
                <ul>
                  <li v-for="student in session.students" :key="student.id">
                    <div class="is-inline-flex" style="align-items: center">
                      <span
                        :class="
                          student.evaluated
                            ? 'has-text-success'
                            : 'has-text-danger'
                        "
                        style="font-size: 0.5em; margin-right: 0.5em"
                        >&#11044;</span
                      >
                      {{ student.first_name }}
                      <span class="has-text-weight-bold">{{
                        student.last_name
                      }}</span>
                    </div>
                  </li>
                </ul>
              </td>
              <td>
                <div v-if="session.teachers.length > 0">
                  <ul>
                    <li v-for="teacher in session.teachers" :key="teacher.id">
                      {{ teacher.first_name }}
                      <span class="has-text-weight-bold">{{
                        teacher.last_name
                      }}</span>
                    </li>
                  </ul>
                </div>
                <div v-else>
                  <b-tag type="is-info is-light"> Aucun formateur </b-tag>
                </div>
              </td>
              <td>{{ formattedDatetime(session.date) }}</td>
              <td>
                <a
                  class="button is-outlined is-rounded has-text-grey is-small mr-4 mb-1"
                  @click="
                    isEditMode = true;
                    isPracticalWorkSessionPlanningActive = true;
                    chosenPracticalWork = props.row;
                    chosenSessionDisplayed = session;
                  "
                >
                  Détail
                </a>
                <a
                  class="button is-rounded has-text-white is-primary is-small mb-1"
                  @click="
                    isPracticalWorkEvaluationActive = true;
                    chosenPracticalWork = props.row;
                    chosenSessionDisplayed = session;
                  "
                >
                  Évaluations
                </a>
              </td>
            </tr>
          </tbody>
        </table>
        <div v-else>
          <p>
            La liste des sessions apparaîtra ici lorsqu'elle seront (ou auront
            été) planifiées
          </p>
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';
import localization from 'moment/locale/fr';
import _ from 'lodash';

moment.updateLocale('fr', localization);

export default {
  components: {
  },
  filters: {
    day(date) {
      return moment(date).format('D MMMM YYYY');
    },
    hour(date) {
      return moment(date).format('h:mm');
    },
  },
  data() {
    return {
      practicalWorks: [],
      openDetails: [], // array to store the opened rows
      defaultOpenedDetails: [],
      locale: 'UTC',
      isLoading: false,
      isFullPage: false,
      checkedRows: [],
      showDetailIcon: true,
      chosenPracticalWork: [],
      isPracticalWorkActive: false,
    };
  },
  computed: {
    ...mapState('auth', ['currentUser']),
    formattedDatetime() {
      return function formattedDatetime(date) {
        return moment.utc(date).local().locale('fr', localization).format('LL');
      };
    },
  },
  methods: {
    startLoading() {
      this.isLoading = true;
    },
    endLoading() {
      this.isLoading = false;
    },
    order(array) {
      return _.orderBy(array, 'position');
    },
    async getPracticalWorks() {
      this.startLoading();
      try {
        const response = await this.$http.get(`/practical_work/get_practical_works/?student=${this.$route.params.idStudent}&format=json`);
        this.practicalWorks = response.data;
        this.endLoading();
      } catch (error) {
        window.console.error(error);
        // handle the error as needed
      }
    },
  },
  created() {
    window.console.log(this.$route.params.idtraining);
  },
  mounted() {
    this.getPracticalWorks();
  },
  destroyed() {
  },
};
</script>

<style lang="scss" scoped>
</style>
