<template>
  <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
    <div class="is-flex">
      <AppSidebar />
      <div class="main-content">
        <div class="lineCrumbAndAccount">
          <AppBreadcrumb v-bind:crumbs="crumbs" />
          <AppAccountMenu v-bind:appAccount="crumbs" />
        </div>
        <div class="header-page is-flex flex-column">
          <div class="justify-content-between columns lineHeaderTitle">
            <div class="column title-page long-title">
              {{ $route.meta.header_title }}
            </div>
            <div class="column is-narrow actions"></div>
          </div>
        </div>
        <section class="mt-auto" id="header-tabs">
          <div class="b-tabs">
            <nav class="tabs">
              <ul>
                <li
                  v-for="(tab, index) of tabs"
                  :key="index"
                  :id="tab.id"
                  :class="currentTab === index + 1 ? 'is-active' : ''"
                >
                  <a @click="gotoStep(index + 1)"
                    ><span>{{ tab.name }}</span></a
                  >
                </li>
              </ul>
            </nav>
          </div>
        </section>
        <div class="content-page">
          <!--content page-->
          <section v-if="errored">
            <b-message type="is-danger">
              Nous sommes sincèrement désolés, nous ne sommes pas en mesure
              d'enregistrer cette maquette pour le moment. Veuillez réessayer
              ultérieurement ou nous contacter via le support.
            </b-message>
          </section>
          <div>
            <form>
              <section id="stepone" v-if="step == 1">
                <ValidationProvider rules="required" v-slot="{ errors, valid }">
                  <b-field
                    label="Titre de la maquette"
                    :type="{ 'is-danger': errors[0], 'is-success': valid }"
                    :message="errors"
                  >
                    <b-input v-model="form.name"></b-input>
                  </b-field>
                </ValidationProvider>
                <div class="columns">
                  <div class="column">
                    <ValidationProvider
                      rules="required"
                      v-slot="{ errors, valid }"
                    >
                      <b-field
                        label="Secteur"
                        :type="{ 'is-danger': errors[0], 'is-success': valid }"
                        :message="errors"
                      >
                        <b-select
                          placeholder="secteur"
                          v-model="form.sector"
                          name="sector.name"
                          id="sector.id"
                          expanded
                        >
                          <option
                            :value="sector.id"
                            aria-role="listitem"
                            v-for="sector in sectors"
                            :key="sector.id"
                          >
                            {{ sector.name }}
                          </option>
                        </b-select>
                      </b-field>
                    </ValidationProvider>
                    <div class="field">
                      <b-switch
                        v-model="form.qualification"
                        true-value="1"
                        false-value="0"
                      >
                        {{ qualificationSwitch[form.qualification] }}
                      </b-switch>
                    </div>
                    <ValidationProvider
                      rules="required"
                      v-slot="{ errors, valid }"
                    >
                      <b-field
                        label="N° de fiche"
                        :type="{ 'is-danger': errors[0], 'is-success': valid }"
                        :message="errors"
                      >
                        <b-input
                          v-model="form.rncp"
                          placeholder="RNCP34453"
                        ></b-input>
                      </b-field>
                    </ValidationProvider>
                    <ValidationProvider
                      rules="required"
                      v-slot="{ errors, valid }"
                    >
                      <b-field
                        label="Code NSF"
                        :type="{ 'is-danger': errors[0], 'is-success': valid }"
                        :message="errors"
                      >
                        <b-select
                          placeholder="code NSF"
                          expanded
                          v-model="form.nsfcode"
                        >
                          <option
                            aria-role="listitem"
                            v-for="nsfcode in nsfcodes"
                            :key="nsfcode.id"
                            :value="nsfcode.id"
                          >
                            {{ nsfcode.name }}
                          </option>
                        </b-select>
                      </b-field>
                    </ValidationProvider>
                    <ValidationProvider
                      rules="required"
                      v-slot="{ errors, valid }"
                    >
                      <b-field
                        label="Nomenclature"
                        :type="{ 'is-danger': errors[0], 'is-success': valid }"
                        :message="errors"
                      >
                        <b-select
                          placeholder="nomenclature"
                          expanded
                          v-model="form.nomenclature"
                        >
                          <option
                            aria-role="listitem"
                            v-for="nomenclature in nomenclatures"
                            :key="nomenclature.id"
                            :value="nomenclature.id"
                          >
                            {{ nomenclature.name }}
                          </option>
                        </b-select>
                      </b-field>
                    </ValidationProvider>
                  </div>
                  <div class="column" v-if="formacodes.length">
                    <div class="label">Formacodes</div>
                    <div>
                      <b-message
                        type="is-info"
                        style="font-size: 0.7rem"
                        class="mb-4"
                      >
                        Vous pouvez utiliser la touche CTRL + click (Windows) ou
                        CMD + click (MacOS) pour sélectionner plusieurs
                        formacodes.
                      </b-message>
                    </div>
                    <ValidationProvider
                      rules="required"
                      v-slot="{ errors, valid }"
                    >
                      <b-field
                        :type="{ 'is-danger': errors[0], 'is-success': valid }"
                        :message="errors"
                      >
                        <div>
                          <b-select multiple v-model="selectedOptions">
                            <option
                              aria-role="listitem"
                              v-for="formacode in formacodes"
                              :key="formacode.id"
                              :value="formacode.id"
                            >
                              {{ formacode.name }}
                            </option>
                          </b-select>
                        </div>
                      </b-field>
                    </ValidationProvider>
                  </div>
                </div>
                <b-button
                  v-if="form.name !== null"
                  @click="nextStep"
                  type="is-primary"
                  rounded
                  >étape suivante</b-button
                >
              </section>
              <section id="steptwo" v-if="step == 2">
                <h1 class="title-paragraph">
                  Situations professionnelles significatives
                </h1>
                <p class="subtitle-paragraph">
                  Indiquez ici quelques situations professionnelles
                  significatives des compétences visées. Les situations
                  professionnelles significatives sont des situations qui, si
                  elles sont toutes maîtrisées, suffisent à rendre compte de
                  l’ensemble des compétences visées par la formation.
                </p>
                <ValidationProvider rules="required" v-slot="{ errors, valid }">
                  <b-field
                    :type="{ 'is-danger': errors[0], 'is-success': valid }"
                    :message="errors"
                  >
                    <ckeditor
                      :editor="editor"
                      v-model="form.goals"
                      :config="editorConfig"
                      class="mb-5"
                    ></ckeditor>
                  </b-field>
                </ValidationProvider>
                <b-button
                  @click="previousStep"
                  type="is-primary"
                  class="mr-2"
                  rounded
                  >étape précédente</b-button
                >
                <b-button
                  v-if="form.goals !== null"
                  @click="nextStep"
                  type="is-primary"
                  rounded
                  >étape suivante</b-button
                >
              </section>
              <section id="stepthree" v-if="step == 3">
                <h1 class="title-paragraph">Compétences</h1>
                <p class="subtitle-paragraph">
                  Résumez ici les principaux champs de compétences et de
                  connaissances à maitriser du référentiel de cette formation
                  pour les managers, tuteur et apprenants. Vous pouvez aussi
                  ajouter des liens externes ( référentiel par exemple).
                  <b
                    >Vous détaillerez la liste précise et exhaustive des
                    compétences une fois la maquette créée.</b
                  >
                </p>
                <ValidationProvider rules="required" v-slot="{ errors, valid }">
                  <b-field
                    :type="{ 'is-danger': errors[0], 'is-success': valid }"
                    :message="errors"
                  >
                    <ckeditor
                      :editor="editor"
                      v-model="form.skills"
                      :config="editorConfig"
                      class="mb-5"
                    ></ckeditor>
                  </b-field>
                </ValidationProvider>
                <b-button
                  @click="previousStep"
                  type="is-primary"
                  class="mr-2"
                  rounded
                  >étape précédente</b-button
                >
                <b-button
                  v-if="form.skills !== null"
                  @click="nextStep"
                  type="is-primary"
                  rounded
                  >étape suivante</b-button
                >
              </section>
              <section id="stepfour" v-if="step == 4">
                <h1 class="title-paragraph">Choisissez le type de maquette</h1>
                <p class="subtitle-paragraph">
                  Choisissez le squelette de base de votre maquette de formation
                </p>
                <div class="card-add">
                  <ValidationProvider
                    rules="required"
                    v-slot="{ errors, valid }"
                  >
                    <b-field
                      label="Type de maquette"
                      v-if="frameworktypes.length"
                      :type="{ 'is-danger': errors[0], 'is-success': valid }"
                      :message="errors"
                    >
                      <b-select
                        label="Type de maquette"
                        placeholder="type de parcours"
                        v-model="form.frameworktype"
                        @input="changeFrameworkType(form.frameworktype)"
                        expanded
                      >
                        <option
                          aria-role="listitem"
                          v-for="frameworktype in frameworktypes"
                          :key="frameworktype.id"
                          :value="frameworktype.id"
                        >
                          {{ frameworktype.name }}
                        </option>
                      </b-select>
                    </b-field>
                  </ValidationProvider>
                  <div
                    class="columns is-vcentered"
                    v-if="form.frameworktype == 2"
                  >
                    <div class="column is-narrow">
                      <b-field label="Périodes en centre de formation">
                        <b-numberinput
                          v-model="form.nb_periods_cfa"
                          controls-position="compact"
                          placeholder="14"
                          min="0"
                          max="15"
                        ></b-numberinput>
                      </b-field>
                    </div>
                    <div class="column is-narrow">
                      <b-field label="Périodes en entreprise">
                        <b-numberinput
                          v-model="form.nb_periods_enterprise"
                          controls-position="compact"
                          placeholder="14"
                          min="0"
                          max="15"
                          >2</b-numberinput
                        >
                      </b-field>
                    </div>
                  </div>
                  <div
                    class="columns is-vcentered"
                    v-if="form.frameworktype == 1"
                  >
                    <div class="column is-narrow">
                      <b-field label="Blocs périodes">
                        <b-numberinput
                          v-model="form.nb_periods"
                          controls-position="compact"
                          placeholder="14"
                          min="0"
                          max="20"
                        ></b-numberinput>
                      </b-field>
                    </div>
                  </div>
                </div>
                <div class="mt-5">
                  <b-button
                    @click="previousStep"
                    type="is-primary"
                    class="mr-2"
                    rounded
                    >étape précédente</b-button
                  >
                  <b-button
                    v-if="form.frameworktype !== null && form.name !== null"
                    @click="handleSubmit(createFramework)"
                    type="is-primary"
                    rounded
                    >Créer la maquette</b-button
                  >
                </div>
              </section>
            </form>
          </div>
          <!--content-->
        </div>
      </div>
    </div>
  </ValidationObserver>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import CKEditor from '@ckeditor/ckeditor5-vue2';
import '@ckeditor/ckeditor5-build-classic/build/translations/fr';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import AppSidebar from '@/components/AppSidebar.vue';
import AppBreadcrumb from '@/components/AppBreadcrumb.vue';
import AppAccountMenu from '@/components/AppAccountMenu.vue';

export default {
  components: {
    AppSidebar,
    AppBreadcrumb,
    AppAccountMenu,
    ValidationProvider,
    ValidationObserver,
    ckeditor: CKEditor.component,
  },
  data() {
    return {
      errored: false,
      currentTab: 1,
      numberStep: 1,
      qualificationSwitch: {
        1: 'Formation certifiante',
        0: 'Formation non certifiante',
      },
      step: 1,
      totalsteps: 5,
      numberFormation: 14,
      numberCompany: 14,
      numberPlaceholder: undefined,
      selectedOptions: [],
      nomenclatures: [],
      nsfcodes: [],
      frameworktypes: [],
      formacodes: [],
      sectors: [],
      frameworktypeSelected: [
        {
          id: null,
          name: null,
          nb_periods: null,
          nb_periods_enterprise: null,
          nb_periods_cfa: null,
        },
      ],
      form: {
        name: null,
        sector: null,
        rncp_title: null,
        nomenclature: null,
        nsf_code: null,
        qualification: 0,
        description: null,
        goals: null,
        skills: '',
        frameworktype: null,
        frameworktypename: null,
        nb_periods: null,
        nb_periods_enterprise: null,
        nb_periods_cfa: null,
      },
      frameworktypevalue: 1,
      createdframeworkid: null,
      crumbs: [
        {
          text: 'Maquettes',
          href: '/maquettes',
        },
        {
          text: 'Créer une maquette',
          class: 'is-active',
        },
      ],
      tabs: [
        {
          name: 'Fiche technique',
          id: '1',
        },
        {
          name: 'Situations',
          id: '2',
        },
        {
          name: 'Compétences',
          id: '3',
        },
        {
          name: 'Parcours',
          id: '4',
        },
      ],
      editor: ClassicEditor,
      editorDisabled: true,
      editorConfig: {
        language: 'fr',
        toolbar: ['heading', '|', 'bold', 'italic', 'bulletedList', 'numberedList', '|', 'outdent', 'indent', '|', 'link', '|', 'undo',
          'redo'],
        alignment: {
          options: ['left', 'right'],
        },
        heading: {
          options: [
            { model: 'paragraph', title: 'Styles de texte', class: 'ck-heading_paragraph' },
            {
              model: 'heading1',
              view: 'h1',
              title: 'Titre 1',
              class: 'ck-heading_heading1',
            },
            {
              model: 'heading2',
              view: 'h2',
              title: 'Titre 2',
              class: 'ck-heading_heading2',
            },
            {
              model: 'mark',
              view: 'mark',
              title: 'surligné',
              class: 'ck-mark',
            },
          ],
        },
        table: {
          contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells'],
        },
      },
    };
  },
  computed: {
    goals() {
      return this.form.goals;
    },
  },
  methods: {
    clickMe() {
      this.$buefy.notification.open('Validé');
    },
    gotoStep(numberStep) {
      this.currentTab = numberStep;
      this.step = numberStep;
    },
    nextStep() {
      this.step += 1;
      this.currentTab = this.step;
    },
    previousStep() {
      this.step -= 1;
      this.currentTab = this.step;
    },
    initFormacodes() {
      this.$http
        .get('/formacodes/?format=json')
        .then((response) => {
          this.formacodes = response.data;
        })
        .catch((error) => {
          window.console.log(error);
          this.errored = true;
          this.$buefy.dialog.alert({
            title: 'Mince...',
            message: 'Les formacodes ne peuvent être affichées pour le moment. Tenez de rafraîchir la page.',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
          });
        });
    },
    initSectors() {
      this.$http
        .get('/sectors/?format=json')
        .then((response) => {
          this.sectors = response.data;
        })
        .catch((error) => {
          window.console.log(error);
          this.errored = true;
          this.$buefy.dialog.alert({
            title: 'Mince...',
            message: 'Les secteurs ne peuvent être affichées pour le moment. Tenez de rafraîchir la page.',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
          });
        });
    },
    initNomenclatures() {
      this.$http
        .get('/nomenclatures/?format=json')
        .then((response) => {
          this.nomenclatures = response.data;
        })
        .catch((error) => {
          window.console.log(error);
          this.errored = true;
          this.$buefy.dialog.alert({
            title: 'Mince...',
            message: 'Les nomenclatures ne peuvent être affichées pour le moment. Tenez de rafraîchir la page.',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
          });
        });
    },
    initNsfcodes() {
      this.$http
        .get('/nsfcodes/?format=json')
        .then((response) => {
          this.nsfcodes = response.data;
        })
        .catch((error) => {
          window.console.log(error);
          this.errored = true;
          this.$buefy.dialog.alert({
            title: 'Mince...',
            message: 'Les codes NSF ne peuvent être affichées pour le moment. Tenez de rafraîchir la page.',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
          });
        });
    },
    initFrameworktypes() {
      this.$http
        .get('/frameworktypes/?format=json')
        .then((response) => {
          this.frameworktypes = response.data;
        })
        .catch((error) => {
          window.console.log(error);
          this.errored = true;
          this.$buefy.dialog.alert({
            title: 'Mince...',
            message: 'Les codes NSF ne peuvent être affichées pour le moment. Tenez de rafraîchir la page.',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
          });
        });
    },
    createFramework() {
      this.$http
        .post('/training_frameworks/', {
          name: this.form.name,
          sector_id: this.form.sector,
          rncp_title: this.form.rncp,
          nomenclature_id: this.form.nomenclature,
          nsf_code_id: this.form.nsfcode,
          formacodes_id: this.selectedOptions,
          framework_type_id: this.form.frameworktype,
          qualification: this.form.qualification,
          description: this.form.description,
          goals: this.form.goals,
          skills: this.form.skills,
          active: 1,
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
          this.$buefy.dialog.alert({
            title: 'Mince...',
            message: 'Une erreur s\'est produite',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
          });
        })
        .then((response) => {
          this.createdframeworkid = response.data.id;
          this.$http
            .post('/framework_init/', {
              framework_id: response.data.id,
              nb_periods: this.form.nb_periods,
              nb_periods_enterprise: this.form.nb_periods_enterprise,
              nb_periods_cfa: this.form.nb_periods_cfa,
            })
            .catch((error) => {
              window.console.log(error.response);
              if (error.response.status === 403) {
                this.unauthorized = true;
              } else {
                this.errored = true;
              }
              this.$buefy.dialog.alert({
                title: 'Mince...',
                message: 'Une erreur s\'est produite',
                type: 'is-info',
                hasIcon: true,
                icon: 'times-circle',
                ariaRole: 'alertdialog',
                ariaModal: true,
                confirmText: 'Ok',
              });
            })
            .then(() => {
              this.$buefy.toast.open({ message: 'Maquette créée', type: 'is-success' });
              this.$router.push({
                name: 'FrameworkEdit',
                params: {
                  id: this.createdframeworkid,
                  tab: 'parcours',
                  first: true,
                },
              });
            });
        })
        .catch((error) => {
          window.console.log(error);
          this.errored = true;
          this.$buefy.dialog.alert({
            title: 'Mince...',
            message: 'Une erreur s\'est produite',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
          });
        });
    },
    // funtion to verify value sent to from
    changeFrameworkType() {
      this.form.nb_periods = null;
      this.form.nb_periods_enterprise = null;
      this.form.nb_periods_cfa = null;
    },
  },
  created() {
    this.initFormacodes();
    this.initSectors();
    this.initNomenclatures();
    this.initNsfcodes();
    this.initFrameworktypes();
  },
};
</script>

<style scoped>
.tag {
  cursor: pointer;
}
</style>
