<template>
  <div class="is-flex">
    <AppSidebar />
    <b-loading :is-full-page="isFullPage" v-model="isLoading"> </b-loading>
    <div class="main-content">
      <div class="lineCrumbAndAccount ml-auto">
        <AppAccountMenu v-bind:appAccount="crumbs" />
      </div>
      <div class="header-page is-flex flex-column">
        <div class="justify-content-between columns lineHeaderTitle">
          <div class="column title-page short-title">
            {{ $route.meta.header_title }}
          </div>
          <div class="column is-narrow actions">
            <router-link to="/wiki"
              ><b-button type="is-primary is-rounded"
                >Revenir à la documentation</b-button
              >
            </router-link>
          </div>
        </div>
      </div>
      <div class="content-page pt-5">
        <section>
          <b-collapse
            class="card"
            animation="slide"
            v-for="(collapse, index) of roles"
            :key="index"
            :open="isOpen == index"
            @open="isOpen = index"
          >
            <template #trigger="props">
              <div class="accordion" role="button">
                <p class="card-header-title">
                  <b-icon icon="user" class="is-primary pr-3"></b-icon
                  >{{ collapse.role_name }}
                </p>
                <a class="card-header-icon">
                  <b-icon :icon="props.open ? 'menu-down' : 'menu-up'">
                  </b-icon>
                </a>
              </div>
            </template>
            <div class="card-content pb-3">
              <div class="content" v-html="collapse.role_description">
                <!-- {{ collapse.role_description }} -->
              </div>
            </div>
          </b-collapse>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import _ from 'lodash';
import AppAccountMenu from '@/components/AppAccountMenu.vue';
import AppSidebar from '@/components/AppSidebar.vue';

export default ({
  components: {
    AppAccountMenu,
    AppSidebar,
  },
  data() {
    return {
      roles: [],
      isLoading: false,
      isFullPage: false,
      isOpen: null,
      collapses: [
        {
          title: 'Title 1',
          text: 'I\'m baby green juice swag heirloom selvage, vaporware cronut asymmetrical.Skateboard truffaut artisan kogi godard health goth photo booth.Distillery cronut try-hard, meh kale chips 90\'s gochujang slow- carb brooklyn tilde fashion axe vinyl small batch green juice fam.Occupy polaroid tbh, quinoa church - key vape crucifix blue bottle pitchfork kickstarter blog kogi post - ironic squid retro.Art party shoreditch cray actually knausgaard vegan paleo chillwave iPhone chia kitsch asymmetrical godard.VHS schlitz hell of taxidermy, YOLO vape affogato flannel shaman drinking vinegar lo - fi live - edge scenester.',
        },
        {
          title: 'Title 2',
          text: 'Text 2',
        },
        {
          title: 'Title 3',
          text: 'Text 3',
        },
      ],
      crumbs: [{
        text: 'Maquettes',
        href: '/maquettes',
        class: 'is-active',
      },
      ],
    };
  },
  methods: {
    order(array) {
      return _.orderBy(array, 'order');
    },
  },
  mounted() {
    axios.get('https://insituapp.io/api/wiki/roles')
      .then((response) => {
        this.roles = response.data;
      })
      .catch((error) => {
        window.console.log(error);
        this.errored = true;
      });
  },
});
</script>

<style scoped>
.content {
  margin-bottom: 2rem;
}
.card-content {
  padding: 0 2rem 3rem;
  /* margin-bottom:2rem; */
}
</style>
