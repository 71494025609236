<template>
  <div class="pb-5 mb-5" v-if="skills">
    <b-loading :is-full-page="isFullPage" v-model="isLoading"> </b-loading>
    <b-field class="w-100" v-if="skillsBlock">
      <b-input
        v-if="skillsBlock"
        placeholder="Rechercher..."
        type="search"
        icon="magnify"
        expanded
        v-model="search"
        @input="initSkills"
      >
      </b-input>
      <p class="control">
        <b-button
          type="is-primary"
          label="Rechercher"
          @click.prevent="initSkills"
        />
      </p>
    </b-field>
    <div v-if="skills.length">
      <div v-if="skillsBlock">
        <div class="is-flex align-items-center mb-5">
          <b-button
            rounded
            @click="isOpenPeriod = true"
            v-if="isOpenPeriod === false"
            size="is-small"
            >ouvrir tout</b-button
          >
          <b-button
            rounded
            @click="isOpenPeriod = false"
            v-if="isOpenPeriod === true"
            size="is-small"
            >fermer tout</b-button
          >
          <!-- <div class="ml-auto">
          <b-button @click="allskills = !allskills" rounded>
            <span v-if="!allskills">Afficher toutes les compétences</span
            ><span v-else>Afficher les compétences évaluées</span></b-button
          >
        </div> -->
        </div>
        <b-collapse
          class="card"
          animation="slide"
          v-for="(skillBlock, indexBlock) in order(skills)"
          :key="skillBlock.name"
          :open="isOpenPeriod"
        >
          <div
            slot="trigger"
            slot-scope="props"
            class="card-header"
            role="button"
          >
            <div class="bg-card is-flex bg-primary align-items-center">
              <a class="card-header-icon">
                <b-icon v-bind:icon="props.open ? 'menu-down' : 'menu-up'">
                </b-icon>
              </a>
              <p class="card-header-title">{{ skillBlock.name }}</p>
            </div>
          </div>
          <div class="card-content container-eval eval-three-cols">
            <div class="columns is-hidden-mobile">
              <div
                class="column is-4 is-4-desktop has-text-primary head-skills"
              >
                <b>Compétences </b>
              </div>
              <div class="column has-text-primary head-skills">
                <b>Niveau d'acquisition</b>
              </div>
            </div>
            <div
              v-for="(skill, indexSkill) in order(skillBlock.skills)"
              :key="skill.name"
              :name="'question' + indexSkill"
            >
              <div
                class="columns is-vcentered justify-space-between mb-5 pb-2 skillsEdit"
              >
                <div class="name-skill column is-4 is-4-desktop pb-1">
                  <b>{{ skill.name }}</b>
                  <b-collapse
                    v-if="skill.description"
                    :open="false"
                    position="is-bottom"
                    :aria-id="'content' + indexSkill"
                    animation="slide"
                    class="collapse-description"
                  >
                    <template #trigger="props">
                      <a
                        aria-controls="contentIdForA11y4"
                        :aria-expanded="props.open"
                      >
                        <b-icon
                          class="mr-1 is-inline-block"
                          :icon="!props.open ? 'menu-down' : 'menu-up'"
                        ></b-icon>
                        {{ !props.open ? "Critères observables" : "Fermer" }}
                      </a>
                    </template>
                    <p style="white-space: break-spaces">
                      {{ skill.description }}
                    </p>
                  </b-collapse>
                </div>
                <div class="column pb-1 is-flex align-items-center">
                  <div>
                    <div
                      class="columns is-desktop levelsList is-vcentered pl-2"
                    >
                      <div class="is-flex mb-2 labelColumns">
                        <div class="col-eval has-text-primary">initial</div>
                        <div class="col-eval has-text-primary">précédent</div>
                        <div class="col-eval has-text-primary">actuel</div>
                        <div class="label-level is-4"></div>
                      </div>
                      <div
                        class="is-flex eval-line"
                        v-for="(level, index) in order(skill.levels)"
                        :key="level.id"
                      >
                        <div class="col-eval">
                          <b-icon
                            icon="check"
                            type="is-success"
                            v-if="
                              skill.initial_evaluation.training_level ===
                              level.id
                            "
                          ></b-icon>
                          <b-icon icon="close" class="disabled" v-else></b-icon>
                        </div>
                        <div class="col-eval">
                          <b-icon
                            icon="check"
                            type="is-success"
                            v-if="
                              skill.previous_evaluation.training_level ===
                              level.id
                            "
                          ></b-icon>
                          <b-icon icon="close" class="disabled" v-else></b-icon>
                        </div>
                        <div class="col-eval">
                          <span v-if="skill.level_checked === '' && edit">
                            <b-radio
                              :key="level.id"
                              v-model="skill.level_checked"
                              :name="'question' + skillBlock.id + skill.id"
                              :native-value="index"
                              class="mr-0"
                              @input="postEvaluation(level.id, skill.id)"
                            >
                            </b-radio>
                          </span>
                          <span
                            v-else-if="
                              editSkill ==
                              `block${indexBlock}skill${indexSkill}`
                            "
                          >
                            <b-radio
                              :key="level.id"
                              v-model="skill.level_checked"
                              :name="'question' + skillBlock.id + skill.id"
                              :native-value="index"
                              class="mr-0"
                              @input="patchEval(idChecked, level.id, skill.id)"
                            >
                            </b-radio>
                          </span>
                          <span v-else>
                            <b-icon
                              icon="check"
                              type="is-success"
                              v-if="level.evaluation.length"
                            ></b-icon>
                            <b-icon
                              icon="close"
                              class="disabled"
                              v-else
                            ></b-icon>
                          </span>
                        </div>
                        <div class="label-level is-4">{{ level.name }}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="column is-narrow ml-auto"
                  v-if="
                    skill.level_checked !== '' &&
                    editSkill !== `block${indexBlock}skill${indexSkill}` &&
                    edit
                  "
                >
                  <div class="btn-modify-eval">
                    <b-button
                      class="is-block"
                      @click="deleteEval(skill.levels[skill.level_checked])"
                      type="is-small"
                      rounded
                      >effacer</b-button
                    >
                    <b-button
                      v-if="
                        skill.level_checked !== '' &&
                        editSkill !== `block${indexBlock}skill${indexSkill}`
                      "
                      @click="
                        (editSkill = `block${indexBlock}skill${indexSkill}`) &&
                          (idChecked =
                            skill.levels[skill.level_checked].evaluation[0].id)
                      "
                      type="is-small"
                      rounded
                      >modifier</b-button
                    >
                  </div>
                </div>
                <div
                  class="column is-narrow"
                  v-if="editSkill == `block${indexBlock}skill${indexSkill}`"
                >
                  <b-button type="is-small" rounded @click="editSkill = false"
                    >annuler</b-button
                  >
                </div>
              </div>
              <div class="is-block">
                <b-field
                  label="Commentaire (Si non évalué, indiquez pourquoi)"
                  v-if="edit"
                >
                  <b-input
                    type="textarea"
                    @input="$emit('refresh', formComments)"
                    v-model="formComments[indexBlock].skills[indexSkill].entry"
                  ></b-input>
                </b-field>
                <div v-else>
                  <div
                    class="card_grey"
                    style="height: auto; min-height: unset; margin-bottom: 2rem"
                    v-if="formComments[indexBlock].skills[indexSkill].entry"
                  >
                    <b-field label="Commentaire" class="mb-0">
                      {{ formComments[indexBlock].skills[indexSkill].entry }}
                    </b-field>
                  </div>
                </div>
              </div>
            </div>
          </div></b-collapse
        >
      </div>
    </div>
    <div v-else>
      <b-message type="is-info" v-if="search"> Aucun résultat </b-message>
      <b-message type="is-info" v-else>
        Aucune compétence n'est associée à cette formation, vous n'avez donc
        aucune compétence à évaluer ici.
      </b-message>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import _ from 'lodash';
// import AppHelpMessage from '@/components/AppHelpMessage.vue';

export default {
  props: {
    role: {
      type: Array,
    },
    skillsLevels: {
      type: [Object, Array],
    },
    studentTraining: {
      type: Number,
    },
    studentSequence: {
      type: Number,
    },
    type: {
      type: String,
    },
    formCommentsRecap: {
      type: [Object, Array, String],
    },
    edit: {
      type: Boolean,
    },
  },
  components: {
    // AppHelpMessage,
  },
  filters: {
  },
  data() {
    return {
      isLoading: false,
      isFullPage: false,
      formSkills: {},
      skills: [],
      allskills: true,
      skillsLevelsTraining: [],
      checkedLevel: [],
      skillsChecked: [],
      roleEvaluation: '',
      roleArray: [],
      isOpenPeriod: false,
      evaluations: [],
      search: null,
      checkedSkill: null,
      editSkill: true,
      skillsSequence: true,
      skillsBlock: true,
      formComments: [],
      formCommentsWaypoint: [],
      textRecap: '',
    };
  },
  computed: {
    ...mapState('auth', ['currentUser']),
  },
  methods: {
    startLoading() {
      this.isLoading = true;
    },
    endLoading() {
      this.isLoading = false;
    },
    order(array) {
      return _.orderBy(array, 'position');
    },
    initSkills(patch) {
      this.startLoading();
      let searchEntry = '';
      let urlWs = '';
      if (this.search) {
        searchEntry = `&search=${this.search}`;
      }
      if (this.type === 'waypoint') {
        urlWs = `/agenda/get_student_training_skills/?student_training=${this.$route.params.idStudentTraining}&waypoint=${this.$route.params.idWaypoint}&role=${this.roleEvaluation}&block${searchEntry}`;
      }
      this.$http
        .get(`${urlWs}`)
        .then((response) => {
          this.skills = response.data;
          if (this.formCommentsRecap !== '' || !this.formCommentsRecap) {
            if (this.formCommentsRecap) {
              if (this.formCommentsRecap.length !== 0) {
                this.formCommentsWaypoint = JSON.parse(this.formCommentsRecap);
              }
            } else if (!this.formCommentsWaypoint) {
              this.formCommentsWaypoint = JSON.parse(this.formCommentsRecap);
            }
          }
          /* eslint-disable */
          if (!this.formCommentsWaypoint || this.formCommentsWaypoint.length === 0) {
            this.formComments = this.skills.map(item => {
              return { name: item.name, skills: item.skills, };
            });
            for (let e = 0; e < this.formComments.length; e += 1) {
              for (let f = 0; f < this.formComments[e].skills.length; f += 1) {
                this.formComments[e].skills = this.formComments[e].skills.map(item => {
                  return { name: item.name, entry: '', };
                });
              }
            }
          }
          else {
            this.formComments = this.formCommentsWaypoint;
          }
          this.$emit('refresh', this.formComments);
          if (patch) {
            this.editSkill = false;
          }
          this.endLoading();
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
        });
    },
    postAllEvaluation() {
      for (let e = 0; e < this.skills.length; e += 1) {
        for (let i = 0; i < this.skills[e].skills.length; i += 1) {
          if (Number.isInteger(this.skills[e].skills[i].level_checked)) {
            const level = this.skills[e].skills[i].level_checked;
            this.checkedLevel.push(
              {
                training_skill: this.skills[e].skills[i].id,
                training_level: this.skills[e].skills[i].levels[level].id,
              },
            );
          }
        }
        if (e === (this.skills.length - 1)) {
          this.$http
            .post('agenda/set_evaluations', {
              role: this.roleEvaluation,
              profile: this.currentUser.id,
              student_training: Number(this.$route.params.idStudentTraining),
              free_situation: this.$route.params.idFree,
              student_activity_revision: Number(this.$route.params.idRevision),
              evaluations: this.checkedLevel,
            }).then(() => {
              this.$buefy.toast.open({ message: 'Compétence enregistrée', type: 'is-success' });
              this.initSkills();
            }).catch((error) => {
              window.console.log(error);
              this.errored = true;
            });
        }
      }
    },
    deleteEval(level) {
      this.$emit('refresh', this.formComments);
      this.$http
        .delete(`agenda/student_training_skills_levels/${level.evaluation[0].id}/`, {
        }).then(() => {
          this.$buefy.toast.open({ message: 'Compétence supprimée', type: 'is-success' });
          this.initSkills();
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
        });
    },
    postEvaluation(levelId, skillId) {
      this.$emit('refresh', this.formComments);
      this.startLoading();
      this.editSkill = true;
      this.$http
        .post('agenda/student_training_skills_levels/', {
          role: this.roleEvaluation,
          profile: this.currentUser.id,
          training_skill: skillId,
          training_level: levelId,
          student_training: Number(this.$route.params.idStudentTraining),
          student_sequence: this.$route.params.idSequence,
          student_activity: Number(this.$route.params.idActivity),
          free_situation: this.$route.params.idFree,
          student_activity_revision: Number(this.$route.params.idRevision),
          student_training_waypoint: Number(this.$route.params.idWaypoint),
        }).then(() => {
          this.$buefy.toast.open({ message: 'Compétence enregistrée', type: 'is-success' });
          this.initSkills(true);
          this.$emit('postEval');
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
        });
    },
    collapseBlock(id, icon) {
      const element = document.getElementById(id);
      element.classList.toggle('blockOpen');
      const iconOpen = document.getElementById(icon);
      iconOpen.classList.toggle('iconOpen');
    },
    initSearch() {
    },
    patchEval(idEval, levelId, skillId) {
      this.$emit('refresh', this.formComments);
      this.startLoading();
      if (idEval) {
        this.$http
          .patch(`agenda/student_training_skills_levels/${idEval}/`, {
            role: this.roleEvaluation,
            profile: this.currentUser.id,
            training_skill: skillId,
            training_level: levelId,
            student_training: Number(this.$route.params.idStudentTraining),
            student_sequence: this.$route.params.idSequence,
            student_activity: Number(this.$route.params.idActivity),
            student_training_waypoint: Number(this.$route.params.idWaypoint),
          }).then(() => {
            this.$buefy.toast.open({ message: 'Compétence enregistrée', type: 'is-success' });
            this.initSkills(true);
          }).catch((error) => {
            window.console.log(error);
            this.errored = true;
          });
      } else {
        this.postEvaluation(levelId, skillId);
      }
    },
    verifRole() {
      for (let i = 0; i < this.role.length; i += 1) {
        let writer = '';
        if (this.role[i].includes('w_')) {
          writer = this.role[i].replace('w_', '');
          this.roleArray.push(writer);
        }
        if (this.roleArray.includes('apprenant') && this.roleArray.includes('tuteur')) {
          this.roleEvaluation = 'tuteur,apprenant';
          this.initSkills();
        } else if (i === (this.role.length - 1)) {
          const role = this.roleArray[0];
          this.roleEvaluation = role;
          this.initSkills();
        }
      }
    },
  },
  created() {
    if (this.role) {
      this.verifRole();
    } else {
      this.roleEvaluation = this.currentUser.role;
      this.initSkills();
    }
  },
  mounted() {
  },
};
</script>

<style scoped>
.disabled {
  color: #dfdfeb;
}
.skillsEdit i {
  margin-right: 0px !important;
}
.skillsEdit .levelsList {
  flex-direction: column !important;
}
.col-eval {
  width: 90px;
  text-align: center;
  font-size: 0.9rem;
}
.label-level {
  /*width: 300px;*/
  font-size: 0.9rem;
  line-height: 1.1;
}
.icon {
  display: block;
  width: unset;
  height: unset;
}
.skillsEdit .col-eval i {
  margin-right: 0px !important;
}
.labelColumns {
  font-size: 0.9rem;
}
img.btn-previous {
  margin: unset !important;
}
.btn-modify-eval {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.eval-three-cols .name-skill {
  padding-right: 0px !important;
  margin-right: 0px !important;
}
@media (max-width: 990px) {
  .label-level,
  .col-eval {
    font-size: 0.85rem;
  }
  .col-eval {
    width: 69px;
  }
  /* .label-level {
    width: 200px;
  } */
  .skillsEdit .levelsList {
    padding: 0px;
  }
}
@media (max-width: 900px) {
  .label-level {
    width: 200px;
  }
}
@media (max-width: 768px) {
  .btn-modify-eval {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 1rem;
  }
  .col-eval {
    width: 69px;
  }
  .label-level {
    width: unset;
  }
}
@media (max-width: 475px) {
  .label-level,
  .col-eval {
    font-size: 0.8rem;
  }
  .label-level {
    width: 150px;
  }
  .col-eval {
    width: 59px;
  }
  .levelsList {
    padding-left: 0px;
  }
  .eval-line {
    margin-bottom: 0.5rem;
  }
}
</style>
