<template>
  <div class="modal-card" style="width: auto">
    <header class="modal-card-head">
      <p class="modal-card-title">{{ practicalwork.title }}</p>
    </header>
    {{ practicalwork }}
    <section class="modal-card-body">
      <div>
        <b-table :data="practicalwork.items" :columns="columns"></b-table>
      </div>
      <!-- Student list -->
    </section>
    <footer class="modal-card-foot">
      <b-button label="Fermer" @click="$emit('close')" />
      <b-button label="Mettre à jour" type="is-primary" />
    </footer>
  </div>
</template>
<script>
export default {
  data() {
    return {
      columns: [
        {
          field: 'name',
          label: 'Name',
        },
        {
          field: 'skills',
          label: 'Compétences',
        },
        {
          field: 'date',
          label: 'Date',
          centered: true,
        },
      ],
    };
  },
  props: {
    practicalwork: Object,
    openedDetailed: Array,
  },
};
</script>
