<template>
  <div id="listSkills">
    <div class="bloc-paragraph">
      <div class="title-paragraph" style="font-size: 1.2rem" v-if="textTitle">
        {{ textTitle }}
      </div>
    </div>
    <div v-if="!skillsSequenceList.length">
      <b-message
        type="is-info"
        v-if="currentUser.role === 'apprenant' || currentUser.role === 'tuteur'"
      >
        <!-- Aucune compétence spécifique n'a été associée à cette expérience.  -->
        Cette expérience n'a pas pour objectif d'évaluer des compétences
        spécifiques, elle concerne donc l'ensemble des compétences de la
        formation.
      </b-message>
      <b-message type="is-info" v-else>
        Aucune compétence spécifique n'a été associée à cette expérience.
      </b-message>
    </div>
    <div
      class="card-edit-sequence is-flex align-items-center w-100"
      v-for="skillsequence in skillsSequenceList"
      :key="skillsequence.name"
    >
      <div class="is-flex mr-auto align-items-center w-100 pr-3 is-clickable">
        <div class="is-flex" v-if="skillsequence.name">
          <b-icon icon="check" type="is-primary" class="ml-4 mr-4"></b-icon>
          {{ skillsequence.name }}
        </div>
        <div class="is-flex" v-else>
          <b-icon icon="check" type="is-primary" class="ml-4 mr-4"></b-icon>
          {{ skillsequence.skill_name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import _ from 'lodash';
// import { ValidationProvider, ValidationObserver } from 'vee-validate';

export default {
  props: {
    typeoftraining: {
      type: String,
    }, // framework, training or studenttraining
    sequenceId: {
      type: Number,
    },
    trainingId: {
      type: Number,
    },
    textTitle: {
      type: String,
    },
  },
  components: {
  },
  filters: {
  },
  data() {
    return {
      isLoading: false,
      isFullPage: false,
      skillsList: [],
      blockSelected: null,
      skillSelected: [],
      skillsSequenceList: [],
      skillsSelected: [5],
      skillstoremove: [
        {
          id: 3,
          skill: {
            id: 2,
            name: 'compétence de ma formation 2',
            description: '',
            position: 1,
            training: 25,
            block: 1,
          },
          position: 1,
          sequence: 391,
        },
        {
          id: 5,
          skill: {
            id: 7,
            name: 'Repérer les conditions de l\'opération et son contexte',
            description: '',
            position: 1,
            training: 29,
            block: 2,
          },
          position: 1,
          sequence: 391,
        },
        {
          id: 6,
          skill: {
            id: 7,
            name: 'Repérer les conditions de l\'opération et son contexte',
            description: '',
            position: 1,
            training: 29,
            block: 2,
          },
          position: 1,
          sequence: 391,
        },
        {
          id: 7,
          skill: {
            id: 7,
            name: 'Repérer les conditions de l\'opération et son contexte',
            description: '',
            position: 1,
            training: 29,
            block: 2,
          },
          position: 1,
          sequence: 391,
        },
      ],
    };
  },
  computed: {
    ...mapState('auth', ['currentUser']),
  },
  methods: {
    startLoading() {
      this.isLoading = true;
    },
    endLoading() {
      this.isLoading = false;
    },
    order(array) {
      return _.orderBy(array, 'position');
    },
    initSkills() {
      this.startLoading();
      let urlWs = '';
      if (this.typeoftraining === 'framework') {
        urlWs = `/frameworkskillsblock/?training_framework=${this.trainingId}&format=json`;
      } else if (this.typeoftraining === 'training') {
        urlWs = `/trainingskillsblock/?training=${this.trainingId}&format=json`;
      }
      this.$http
        .get(`${urlWs}`)
        .then((response) => {
          this.skillsList = this.order(response.data);
          if (this.typeoftraining === 'training') {
            /* eslint-disable */
            this.skillsList = this.skillsList.map(item => {
              return { skills: item.tskills, name: item.name, reference: item.reference, id: item.id, training: item.training, description: item.description, position: item.position };
            });
            /* eslint-enable */
          }

          for (let i = 0; i < this.skillsList.length; i += 1) {
            this.changeList();
            const myBlocks = this.skillsList;
            // eslint-disable-next-line
            this.skillsList[i].skills = this.order(myBlocks[i].skills);
          }
          this.endLoading();
        });
    },
    initSequenceSkills() {
      this.startLoading();
      let urlWs = '';
      if (this.typeoftraining === 'framework') {
        urlWs = `/sequenceskills/?sequence=${this.sequenceId}&format=json`;
      } else if (this.typeoftraining === 'training') {
        urlWs = `/trainingsequenceskills/?sequence=${this.sequenceId}&format=json`;
      } else if (this.typeoftraining === 'student') {
        urlWs = `/agenda/get_student_training_skills/?student_training=${this.trainingId}&student_sequence=${this.sequenceId}&format=json`;
      }
      this.$http
        .get(`${urlWs}`)
        .then((response) => {
          this.skillsSequenceList = response.data;
          this.endLoading();
        });
    },
  },
  created() {
    // this.initSkills();
    this.initSequenceSkills();
  },
  mounted() {
  },
};
</script>

<style scoped>
.field:last-child {
  margin-bottom: 0px !important;
}
.field {
  margin-bottom: 1rem !important;
  font-size: 0.95rem;
}
@media (max-width: 576px) {
  #listSkills .card-edit-sequence {
    padding: 0.5rem;
    margin-bottom: 0.5rem;
    border: 1px solid #e3e5eb;
  }
}
@media (max-width: 376px) {
  #listSkills .card-edit-sequence {
    padding: 0.5rem 0rem;
    line-height: 1.2;
    font-size: 0.9rem;
  }
}
</style>
