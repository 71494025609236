<template>
  <div class="is-flex">
    <AppSidebar />
    <div class="main-content">
      <div class="lineCrumbAndAccount">
        <AppBreadcrumb v-bind:crumbs="crumbs" />
        <AppAccountMenu v-bind:appAccount="crumbs" />
      </div>
      <div class="header-page is-flex flex-column">
        <div class="justify-content-between columns lineHeaderTitle">
          <div class="column title-page short-title">
            {{ $route.meta.header_title }}
          </div>
          <div class="column is-narrow actions">
            <router-link to="/creer-une-maquette"
              ><b-button type="is-primary is-rounded"
                >Créer une maquette</b-button
              >
            </router-link>
          </div>
        </div>
      </div>
      <div class="header-tabs">&nbsp;</div>
      <section class="content-page list-table">
        <section v-if="errored">
          <b-message
            title="Mince... Une erreur est survenue pendant le chargement des maquettes"
            type="is-danger"
            aria-close-label="Close message"
            v-if="errored"
          >
            Nous sommes sincèrement désolés, nous ne sommes pas en mesure
            d'afficher ces maquettes pour le moment. Veuillez réessayer
            ultérieurement ou nous contacter via le support.
          </b-message>
        </section>
        <section v-if="unauthorized">
          <b-message
            title="Votre profil ne vous permet pas de voir les maquettes de cette organisation"
            type="is-danger"
            aria-close-label="Close message"
            v-if="unauthorized"
          >
            Nous sommes sincèrement désolés, nous ne sommes pas en mesure
            d'afficher ces maquettes au regard de vos droits. S'il s'agit d'une
            erreur, nous vous invitons à entrer en contact avec le support.
          </b-message>
        </section>
        <b-loading
          :is-full-page="false"
          v-model="isLoading"
          :can-cancel="true"
        ></b-loading>
        <b-collapse
          :open="false"
          class="smallBtnCollapse"
          animation="slide"
          aria-id="contentIdForA11y3"
          v-if="trainingframeworks.length"
        >
          <div
            slot="trigger"
            class="is-flex"
            slot-scope="props"
            role="button"
            aria-controls="visibleColumns"
            v-if="!unauthorized || trainingframeworks.lenght"
          >
            <a>
              <b-icon :icon="props.open ? 'menu-down' : 'menu-up'"> </b-icon>
            </a>
            <p class="card-header-title">Options avancées</p>
          </div>
          <div class="card-content">
            <div class="content">
              <b-field grouped group-multiline>
                <div
                  v-for="(column, index) in columns"
                  :key="index"
                  class="control"
                >
                  <b-checkbox v-model="column.visible">
                    {{ column.label }}
                  </b-checkbox>
                </div>
              </b-field>
            </div>
          </div>
        </b-collapse>
        <b-loading :is-full-page="isFullPage" v-model="isLoading"> </b-loading>
        <b-table
          :data="trainingframeworks"
          :paginated="isPaginated"
          :per-page="perPage"
          :current-page="currentPage"
          :pagination-simple="isPaginationSimple"
          :pagination-position="paginationPosition"
          :sort-icon-size="sortIconSize"
          :default-sort-direction="defaultSortDirection"
          :debounce-search="1000"
          default-sort="date_created"
          v-if="!unauthorized && trainingframeworks.length"
          :hoverable="true"
          :focusable="true"
        >
          <template v-for="column in columns">
            <b-table-column :key="column.id" v-bind="column">
              <template
                v-if="column.searchable && !column.numeric"
                slot="searchable"
                slot-scope="props"
              >
                <b-input
                  v-model="props.filters[props.column.field]"
                  placeholder="Rechercher..."
                  icon="search"
                  size="is-small"
                />
              </template>
              <template v-slot="props" v-if="column.field === 'sector'">
                {{ props.row[column.field].name }}
              </template>
              <template v-slot="props" v-else-if="column.field === 'name'">
                <b> {{ props.row[column.field] }}</b>
              </template>
              <template
                v-slot="props"
                v-else-if="column.field === 'owner_name'"
              >
                {{ props.row[column.field] }}
              </template>
              <template
                v-slot="props"
                v-else-if="column.field === 'date_created'"
              >
                {{ props.row[column.field] | moment }}
              </template>
              <template
                v-slot="props"
                v-else-if="column.field === 'date_updated'"
              >
                {{ props.row[column.field] | moment }}
              </template>
              <template v-slot="props" v-else-if="column.field === 'id'">
                <div @click.prevent="seeFrameworkdetail(props.row.id)">
                  <b-icon icon="see"></b-icon>
                </div>
              </template>
            </b-table-column>
          </template>
        </b-table>
        <b-message v-else class="mt-5" type="is-info">
          Il n'y a pas de maquettes de formation disponibles. Vos prérogatives
          de coordination pédagogique ou de supervision vous permettent d'en
          ajouter. S'il vous semble qu'il s'agit d'une erreur, n'hésitez pas à
          nous contacter via le support.
        </b-message>
      </section>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import localization from 'moment/locale/fr';
import AppSidebar from '@/components/AppSidebar.vue';
import AppBreadcrumb from '@/components/AppBreadcrumb.vue';
import AppAccountMenu from '@/components/AppAccountMenu.vue';

moment.updateLocale('fr', localization);

export default {
  components: {
    AppSidebar,
    AppBreadcrumb,
    AppAccountMenu,
  },
  filters: {
    moment(date) {
      return moment(date).format('D MMM Y');
    },
  },
  methods: {
    initTrainingframeworks() {
      this.startLoading();
      this.$http
        .get('/training_frameworks/?query={id,sector,name,owner,owner_name,date_created,date_updated}&active=1&format=json')
        .then((response) => {
          this.trainingframeworks = response.data;
          this.endLoading();
        }).catch((error) => {
          window.console.log(error.response);
          if (error.response.status === 403) {
            this.unauthorized = true;
          } else {
            this.errored = true;
          }
        });
    },
    seeFrameworkdetail(param) {
      this.$router.push({
        name: 'Maquette',
        params: { id: param, tab: 'infos' },
      });
    },
    startLoading() {
      this.isLoading = true;
    },
    endLoading() {
      this.isLoading = false;
    },
  },
  created() {
    this.initTrainingframeworks();
  },
  mounted() {
  },
  data() {
    return {
      trainingframeworks: [],
      isLoading: false,
      isFullPage: false,
      errored: false,
      unauthorized: false,
      open: false,
      isPaginated: true,
      isPaginationSimple: false,
      paginationPosition: 'bottom',
      sortIconSize: 'is-small',
      defaultSortDirection: 'desc',
      currentPage: 1,
      perPage: 20,
      crumbs: [{
        text: 'Maquettes',
        href: '/maquettes',
        class: 'is-active',
      },
      ],
      columns: [
        {
          field: 'sector',
          label: 'Secteur',
          width: '100',
          searchable: true,
          sortable: true,
          visible: true,
        },
        {
          field: 'name',
          label: 'Nom',
          searchable: true,
          sortable: true,
          visible: true,
        },
        {
          field: 'owner_name',
          label: 'Auteur',
          width: '150',
          centered: true,
          searchable: true,
          sortable: true,
          visible: true,
        },
        {
          field: 'date_created',
          label: 'Créée le',
          width: '150',
          centered: true,
          searchable: false,
          sortable: true,
          visible: true,
        },
        {
          field: 'date_updated',
          label: 'Modifiée le',
          width: '150',
          centered: true,
          searchable: false,
          sortable: true,
          visible: true,
        },
        {
          field: 'id',
          label: 'Détail',
          numeric: true,
          centered: true,
          searchable: true,
          sortable: false,
          visible: true,
        },
      ],
    };
  },
};
</script>

<style scoped>
.icon {
  cursor: pointer;
}
.loading-overlay {
  background: rgb(255, 255, 255);
}
</style>
