<template>
  <div class="pb-5 mb-5">
    <b-loading :is-full-page="isFullPage" v-model="isLoading"> </b-loading>
    <b-field class="w-100" v-if="skillsBlock">
      <b-input
        v-if="skillsBlock"
        placeholder="Rechercher..."
        type="search"
        icon="magnify"
        expanded
        v-model="search"
        @input="initSkills"
      >
      </b-input>
      <p class="control">
        <b-button
          type="is-primary"
          label="Rechercher"
          @click.prevent="initSkills"
        />
      </p>
    </b-field>
    <div v-if="skills.length">
      <div v-if="skillsBlock">
        <div class="is-flex align-items-center mb-5">
          <b-button
            rounded
            @click="isOpenPeriod = true"
            v-if="isOpenPeriod === false"
            size="is-small"
            >ouvrir tout</b-button
          >
          <b-button
            rounded
            @click="isOpenPeriod = false"
            v-if="isOpenPeriod === true"
            size="is-small"
            >fermer tout</b-button
          >
          <!-- <div class="ml-auto">
          <b-button @click="allskills = !allskills" rounded>
            <span v-if="!allskills">Afficher toutes les compétences</span
            ><span v-else>Afficher les compétences évaluées</span></b-button
          >
        </div> -->
        </div>
        <b-collapse
          class="card"
          animation="slide"
          v-for="(skillBlock, indexBlock) in order(skills)"
          :key="skillBlock.name"
          :open="isOpenPeriod"
        >
          <div
            slot="trigger"
            slot-scope="props"
            class="card-header"
            role="button"
          >
            <div class="bg-card is-flex bg-primary align-items-center">
              <a class="card-header-icon">
                <b-icon v-bind:icon="props.open ? 'menu-down' : 'menu-up'">
                </b-icon>
              </a>
              <p class="card-header-title">{{ skillBlock.name }}</p>
            </div>
          </div>
          <div class="card-content container-eval">
            <div class="columns is-hidden-mobile">
              <div
                class="column is-6 is-6-desktop has-text-primary head-skills"
              >
                <b>Compétences </b>
              </div>
              <div class="column has-text-primary head-skills">
                <b>Niveau d'acquisition</b>
              </div>
            </div>
            <div
              class="columns is-vcentered justify-space-between mb-5 pb-2 skillsEdit"
              v-for="(skill, indexSkill) in order(skillBlock.skills)"
              :key="skill.name"
              :name="'question' + indexSkill"
            >
              <div class="name-skill column is-6 is-6-desktop pb-1">
                <b>{{ skill.name }}</b>
                <b-collapse
                  v-if="skill.description"
                  :open="false"
                  position="is-bottom"
                  :aria-id="'content' + indexSkill"
                  animation="slide"
                  class="collapse-description"
                >
                  <template #trigger="props">
                    <a
                      aria-controls="contentIdForA11y4"
                      :aria-expanded="props.open"
                    >
                      <b-icon
                        class="mr-1"
                        :icon="!props.open ? 'menu-down' : 'menu-up'"
                      ></b-icon>
                      {{ !props.open ? "Critères observables" : "Fermer" }}
                    </a>
                  </template>
                  <p style="white-space: break-spaces">
                    {{ skill.description }}
                  </p>
                </b-collapse>
              </div>
              <div class="column pb-1 is-flex align-items-center">
                <div
                  class="columns is-desktop levelsList is-vcentered pl-2"
                  v-if="skill.level_checked === ''"
                >
                  <b-radio
                    v-for="(level, index) in order(skill.levels)"
                    :key="level.id"
                    v-model="skill.level_checked"
                    :name="'question' + skillBlock.id + skill.id"
                    :native-value="index"
                    class="column level"
                    @input="postEvaluation(level.id, skill.id)"
                  >
                    {{ level.name }}
                  </b-radio>
                </div>
                <div
                  class="columns is-desktop levelsList is-vcentered pl-2"
                  v-else-if="
                    editSkill == `block${indexBlock}skill${indexSkill}`
                  "
                >
                  <b-radio
                    v-for="(level, index) in order(skill.levels)"
                    :key="level.id"
                    v-model="skill.level_checked"
                    :name="'question' + skillBlock.id + skill.id"
                    :native-value="index"
                    class="column level"
                    @input="patchEval(idChecked, level.id, skill.id)"
                  >
                    {{ level.name }}
                  </b-radio>
                </div>

                <div
                  class="columns is-desktop levelsList is-vcentered pl-2"
                  v-else
                >
                  <div
                    v-for="level in order(skill.levels)"
                    :key="level.id"
                    class="column level mr-5 is-flex align-items-center"
                  >
                    <b-icon
                      icon="check"
                      type="is-success"
                      v-if="level.evaluation.length"
                    ></b-icon>
                    <b-icon icon="close" class="disabled" v-else></b-icon>
                    <div class="level-label">{{ level.name }}</div>
                  </div>
                </div>
              </div>
              <div class="column is-narrow is-flex align-items-center ml-auto">
                <div
                  v-if="
                    skill.level_checked !== '' &&
                    editSkill !== `block${indexBlock}skill${indexSkill}`
                  "
                >
                  <b-button
                    @click="deleteEval(skill.levels[skill.level_checked])"
                    type="is-small"
                    rounded
                    >effacer</b-button
                  >
                  <b-button
                    v-if="
                      skill.level_checked !== '' &&
                      editSkill !== `block${indexBlock}skill${indexSkill}`
                    "
                    @click="
                      (editSkill = `block${indexBlock}skill${indexSkill}`) &&
                        (idChecked =
                          skill.levels[skill.level_checked].evaluation[0].id)
                    "
                    type="is-small"
                    rounded
                    >modifier</b-button
                  >
                </div>
              </div>
              <div
                class="column is-narrow"
                v-if="editSkill == `block${indexBlock}skill${indexSkill}`"
              >
                <b-button type="is-small" rounded @click="editSkill = false"
                  >annuler</b-button
                >
              </div>
            </div>
          </div></b-collapse
        >
      </div>
      <div v-else>
        <div
          class="columns is-vcentered justify-space-between mb-5 pb-2 skillsEdit"
          v-for="(skill, indexSkill) in order(skills)"
          :key="skill.name"
          :name="'question' + indexSkill"
        >
          <div class="name-skill column is-6 is-6-desktop pb-1">
            <b>{{ skill.name }}</b>
            <b-collapse
              v-if="skill.description"
              :open="false"
              position="is-bottom"
              :aria-id="'content' + indexSkill"
              animation="slide"
              class="collapse-description"
            >
              <template #trigger="props">
                <a
                  aria-controls="contentIdForA11y4"
                  :aria-expanded="props.open"
                >
                  <b-icon
                    class="mr-1"
                    :icon="!props.open ? 'menu-down' : 'menu-up'"
                  ></b-icon>
                  {{ !props.open ? "Critères observables" : "Fermer" }}
                </a>
              </template>
              <p style="white-space: break-spaces">
                {{ skill.description }}
              </p>
            </b-collapse>
          </div>
          <div class="column pb-1 is-flex align-items-center">
            <div
              class="columns is-desktop levelsList is-vcentered pl-2"
              v-if="skill.level_checked === ''"
            >
              <b-radio
                v-for="(level, index) in order(skill.levels)"
                :key="level.id"
                v-model="skill.level_checked"
                :name="'question' + skill.id"
                :native-value="index"
                class="column level"
                @input="postEvaluation(level.id, skill.id)"
              >
                {{ level.name }}
              </b-radio>
            </div>
            <div
              class="columns is-desktop levelsList is-vcentered pl-2"
              v-else-if="editSkill == `skill${indexSkill}`"
            >
              <b-radio
                v-for="(level, index) in order(skill.levels)"
                :key="level.id"
                v-model="skill.level_checked"
                :name="'question' + skill.id"
                :native-value="index"
                class="column level"
                @input="patchEval(idChecked, level.id, skill.id)"
              >
                {{ level.name }}
              </b-radio>
            </div>

            <div class="columns is-desktop levelsList is-vcentered pl-2" v-else>
              <div
                v-for="level in order(skill.levels)"
                :key="level.id"
                class="column level mr-5 is-flex align-items-center"
              >
                <b-icon
                  icon="check"
                  type="is-success"
                  v-if="level.evaluation.length"
                ></b-icon>
                <b-icon icon="close" class="disabled" v-else></b-icon>
                <div class="level-label">{{ level.name }}</div>
              </div>
            </div>
          </div>
          <div class="column is-narrow is-flex align-items-center ml-auto">
            <div v-if="skill.level_checked !== ''">
              <b-button
                @click="deleteEval(skill.levels[skill.level_checked])"
                type="is-small"
                rounded
                >effacer</b-button
              >
              <b-button
                v-if="skill.level_checked !== ''"
                @click="
                  (editSkill = `skill${indexSkill}`) &&
                    (idChecked =
                      skill.levels[skill.level_checked].evaluation[0].id)
                "
                type="is-small"
                rounded
                >modifier</b-button
              >
            </div>
          </div>
          <div
            class="column is-narrow"
            v-if="editSkill == `skill${indexSkill}`"
          >
            <b-button type="is-small" rounded @click="editSkill = false"
              >annuler</b-button
            >
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <b-message type="is-info" v-if="search"> Aucun résultat </b-message>
      <b-message type="is-info" v-else>
        Aucune compétence n'est associée à cette formation, vous n'avez donc
        aucune compétence à évaluer ici.
      </b-message>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import _ from 'lodash';
// import AppHelpMessage from '@/components/AppHelpMessage.vue';

export default {
  props: {
    role: {
      type: Array,
    },
    skillsLevels: {
      type: [Object, Array],
    },
    studentTraining: {
      type: Number,
    },
    studentSequence: {
      type: Number,
    },
    type: {
      type: String,
    },
  },
  components: {
    // AppHelpMessage,
  },
  filters: {
  },
  data() {
    return {
      isLoading: false,
      isFullPage: false,
      formSkills: {},
      skills: [],
      allskills: true,
      skillsLevelsTraining: [],
      checkedLevel: [],
      skillsChecked: [],
      roleEvaluation: '',
      roleArray: [],
      isOpenPeriod: false,
      evaluations: [],
      search: null,
      checkedSkill: null,
      editSkill: true,
      skillsSequence: true,
      skillsBlock: null,
    };
  },
  computed: {
    ...mapState('auth', ['currentUser']),
  },
  methods: {
    startLoading() {
      this.isLoading = true;
    },
    endLoading() {
      this.isLoading = false;
    },
    order(array) {
      return _.orderBy(array, 'position');
    },
    initSkills(patch) {
      this.startLoading();
      let searchEntry = '';
      let block = '';
      let urlWs = '';
      if (this.search) {
        searchEntry = `&search=${this.search}`;
      }
      if (this.skillsBlock === true) {
        block = '&block=true';
      }
      if (this.type === 'progression') {
        urlWs = `/agenda/get_student_training_skills/?student_training=${this.$route.params.idStudentTraining}&role=${this.roleEvaluation}&revision=${this.$route.params.idRevision}&student_sequence=${this.$route.params.idSequence}${block}${searchEntry}`;
      } else if (this.type === 'free') {
        urlWs = `/agenda/get_student_training_skills/?student_training=${this.$route.params.idStudentTraining}&role=${this.roleEvaluation}&free_situation=${this.$route.params.idFree}&block=true${searchEntry}`;
        this.skillsBlock = true;
      } else if (this.type === 'sequence') {
        urlWs = `/agenda/get_student_training_skills/?student_training=${this.$route.params.idStudentTraining}&role=${this.roleEvaluation}&student_sequence=${this.$route.params.idSequence}${block}${searchEntry}`;
      } else if (this.type === 'custom') {
        urlWs = `/agenda/get_student_training_skills/?student_training=${this.$route.params.idStudentTraining}&role=${this.roleEvaluation}&custom_experience=${this.$route.params.idCustom}&block=true${searchEntry}`;
        this.skillsBlock = true;
      }
      this.$http
        .get(`${urlWs}`)
        .then((response) => {
          this.skills = response.data;
          if (!this.skillsBlock) {
            if (this.skills.length) {
              this.skillsBlock = false;
            } else {
              this.skillsBlock = true;
              this.initSkills();
            }
          }
          if (patch) {
            this.editSkill = false;
          }
          this.endLoading();
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
        });
    },
    postAllEvaluation() {
      for (let e = 0; e < this.skills.length; e += 1) {
        for (let i = 0; i < this.skills[e].skills.length; i += 1) {
          if (Number.isInteger(this.skills[e].skills[i].level_checked)) {
            const level = this.skills[e].skills[i].level_checked;
            this.checkedLevel.push(
              {
                training_skill: this.skills[e].skills[i].id,
                training_level: this.skills[e].skills[i].levels[level].id,
              },
            );
          }
        }
        if (e === (this.skills.length - 1)) {
          this.$http
            .post('agenda/set_evaluations', {
              role: this.roleEvaluation,
              profile: this.currentUser.id,
              student_training: Number(this.$route.params.idStudentTraining),
              free_situation: this.$route.params.idFree,
              student_activity_revision: Number(this.$route.params.idRevision),
              evaluations: this.checkedLevel,
            }).then(() => {
              this.$buefy.toast.open({ message: 'Compétence enregistrée', type: 'is-success' });
              this.initSkills();
            }).catch((error) => {
              window.console.log(error);
              this.errored = true;
            });
        }
      }
    },
    deleteEval(level) {
      this.$http
        .delete(`agenda/student_training_skills_levels/${level.evaluation[0].id}/`, {
        }).then(() => {
          this.$buefy.toast.open({ message: 'Compétence supprimée', type: 'is-success' });
          this.initSkills();
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
        });
    },
    postEvaluation(levelId, skillId) {
      this.startLoading();
      this.editSkill = true;
      this.$http
        .post('agenda/student_training_skills_levels/', {
          role: this.roleEvaluation,
          profile: this.currentUser.id,
          training_skill: skillId,
          training_level: levelId,
          student_training: Number(this.$route.params.idStudentTraining),
          student_sequence: this.$route.params.idSequence,
          student_activity: Number(this.$route.params.idActivity),
          free_situation: this.$route.params.idFree,
          student_activity_revision: Number(this.$route.params.idRevision),
          custom_experience: this.$route.params.idCustom,
        }).then(() => {
          this.$buefy.toast.open({ message: 'Compétence enregistrée', type: 'is-success' });
          this.initSkills(true);
          this.$emit('postEval');
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
        });
    },
    collapseBlock(id, icon) {
      const element = document.getElementById(id);
      element.classList.toggle('blockOpen');
      const iconOpen = document.getElementById(icon);
      iconOpen.classList.toggle('iconOpen');
    },
    initSearch() {
    },
    patchEval(idEval, levelId, skillId) {
      this.startLoading();
      if (idEval) {
        this.$http
          .patch(`agenda/student_training_skills_levels/${idEval}/`, {
            role: this.roleEvaluation,
            profile: this.currentUser.id,
            training_skill: skillId,
            training_level: levelId,
            student_training: Number(this.$route.params.idStudentTraining),
            student_sequence: this.$route.params.idSequence,
            student_activity: Number(this.$route.params.idActivity),
          }).then(() => {
            this.$buefy.toast.open({ message: 'Compétence enregistrée', type: 'is-success' });
            this.initSkills(true);
          }).catch((error) => {
            window.console.log(error);
            this.errored = true;
          });
      } else {
        this.postEvaluation(levelId, skillId);
      }
    },
    // initSequenceSkills() {
    //   this.startLoading();
    //   const urlWs = `/trainingsequenceskills/?sequence=${this.$route.params.idSequence}`;
    //   this.$http
    //     .get(`${urlWs}`)
    //     .then((response) => {
    //       window.console.log(response.data);
    //       if (response.data.length) {
    //         this.skillsSequence = true;
    //         this.skillsBlock = false;
    //         this.initSkills();
    //       } else {
    //         this.skillsSequence = false;
    //         this.skillsBlock = true;
    //         this.initSkills();
    //       }

    //       this.endLoading();
    //     });
    // },
    verifRole() {
      for (let i = 0; i < this.role.length; i += 1) {
        let writer = '';
        if (this.role[i].includes('w_')) {
          writer = this.role[i].replace('w_', '');
          this.roleArray.push(writer);
        }
        if (this.roleArray.includes('apprenant') && this.roleArray.includes('tuteur')) {
          this.roleEvaluation = 'tuteur,apprenant';
          this.initSkills();
        } else if (i === (this.role.length - 1)) {
          const role = this.roleArray[0];
          this.roleEvaluation = role;
          this.initSkills();
        }
      }
    },
  },
  created() {
    if (this.role) {
      this.verifRole();
    } else {
      this.roleEvaluation = this.currentUser.role;
      this.initSkills();
    }
  },
  mounted() {
  },
};
</script>

<style scoped>
.disabled {
  color: #dfdfeb;
}
.skillsEdit i {
  margin-right: 0px !important;
}
</style>
