<template>
  <div>
    <b-loading :is-full-page="isFullPage" v-model="isLoading"> </b-loading>
    <b-message type="is-warning">
      Pour chaque apprenant qui a vu chaque compétence au moins une fois ?
    </b-message>
    <div class="columns is-desktop is-vcentered">
      <div class="column has-text-primary is-narrow"><b>Filtrer par</b></div>
      <div class="column is-narrow">
        <div class="columns is-vcentered">
          <div class="column is-narrow">
            <b-select
              placeholder="Tous rôles confondus"
              expanded
              v-model="selectedMode"
              class="pl-0"
              @input="changeRole"
            >
              <option value="best">Meilleure évaluation</option>
              <option value="last">Dernière évaluation</option>
            </b-select>
          </div>
        </div>
      </div>
      <div class="column is-narrow">
        <div class="columns is-vcentered is-desktop">
          <div class="column is-narrow">
            <b-collapse :open="false" aria-id="checkecRoles">
              <template #trigger="props">
                <b-button
                  label="Évalué par"
                  type="is-primary is-light"
                  aria-controls="checkecRoles"
                  :aria-expanded="props.open"
                />
              </template>
              <div class="card card-checked">
                <div>
                  <b-checkbox
                    v-model="selectedRole"
                    native-value="apprenant"
                    @input="changeRole"
                  >
                    Apprenant
                  </b-checkbox>
                </div>
                <div>
                  <b-checkbox
                    v-model="selectedRole"
                    native-value="tuteur"
                    @input="changeRole"
                  >
                    Tuteur
                  </b-checkbox>
                </div>
                <div>
                  <b-checkbox
                    v-model="selectedRole"
                    native-value="tuteur,apprenant"
                    @input="changeRole"
                  >
                    Tuteur, apprenant
                  </b-checkbox>
                </div>
                <div>
                  <b-checkbox
                    v-model="selectedRole"
                    native-value="formateur"
                    @input="changeRole"
                  >
                    Formateur
                  </b-checkbox>
                </div>
                <div>
                  <b-checkbox
                    v-model="selectedRole"
                    native-value="coordinateur"
                    @input="changeRole"
                  >
                    Coordinateur
                  </b-checkbox>
                </div>
              </div>
            </b-collapse>
          </div>
        </div>
      </div>
      <div class="column is-narrow">
        <div class="columns is-vcentered">
          <b-collapse :open="false" aria-id="checkecRoles">
            <template #trigger="props">
              <b-button
                label="Type d'évaluation"
                type="is-primary is-light"
                aria-controls="checkecRoles"
                :aria-expanded="props.open"
              />
            </template>

            <div class="card card-checked">
              <div>
                <b-radio
                  v-model="selectedType"
                  native-value="all"
                  @input="changeRole"
                >
                  Tout
                </b-radio>
              </div>
              <div>
                <b-radio
                  v-model="selectedType"
                  native-value="entreprise"
                  @input="changeRole"
                >
                  En entreprise
                </b-radio>
              </div>
              <div>
                <b-radio
                  v-model="selectedType"
                  native-value="tp"
                  @input="changeRole"
                >
                  Activité pratique
                </b-radio>
              </div>
              <!-- <div>
                <b-radio
                  v-model="selectedType"
                  native-value="certification"
                  @input="changeRole"
                >
                  Évaluation bilan
                </b-radio>
              </div> -->
            </div>
          </b-collapse>
        </div>
      </div>
      <div class="column ml-auto is-narrow">
        <b-collapse :open="false" aria-id="contentIdForA11y1">
          <template #trigger="props">
            <b-button
              label="Légende"
              icon-left="warning"
              type="is-ghost"
              aria-controls="contentIdForA11y1"
              :aria-expanded="props.open"
            />
          </template>
          <div class="card card-checked ml-auto" style="right: 2rem">
            <div class="caption is-flex align-items-center">
              <div class="caption-item">
                <img
                  :src="require(`@/assets/img/levels/Oval.svg`)"
                  alt=""
                  class="img_status"
                />
                Tuteur
              </div>
              <div class="caption-item">
                <img
                  :src="require(`@/assets/img/levels/Rectangle.svg`)"
                  alt=""
                  class="img_status"
                />
                Formateur
              </div>
              <div class="caption-item">
                <img
                  :src="require(`@/assets/img/levels/Triangle.svg`)"
                  alt=""
                  class="img_status"
                />
                Apprenant
              </div>
            </div>
          </div>
        </b-collapse>
      </div>
    </div>
    <div class="mb-5 pb-5"></div>
    <b-table
      :data="studentsSkills.students"
      ref="table"
      :default-sort="['name', 'asc']"
      :show-detail-icon="showDetailIcon"
      :sticky-header="true"
      class="skillstable skillsHorizontal mt-3"
    >
      <b-table-column
        field="name"
        label="Nom"
        width="300"
        v-slot="props"
        :sticky="true"
      >
        <router-link
          :to="
            '/formation-apprenant/' +
            $route.params.idTraining +
            '/' +
            props.row.student_id +
            '/' +
            props.row.student_training +
            '/skills'
          "
        >
          <b class="has-text-primary"> {{ props.row.name }}</b>
        </router-link>
      </b-table-column>
      <template v-for="(column, index) in studentsSkills.skills">
        <b-table-column
          :key="column.id + 'column' + index"
          v-bind="column"
          :visible="true"
          :label="column.reference"
          class="skillref"
        >
          <template v-slot="props">
            <b-tooltip
              v-if="selectedRole.includes(props.row.skills[index].role)"
              type="is-light"
              class="evaluation-icon"
              multilined
              position="is-bottom"
            >
              <template v-slot:content>
                <b> {{ column.name }}</b>
                <div>{{ props.row.skills[index].role }}</div>
                <div>{{ props.row.skills[index].profile_name }}</div>
              </template>
              <span v-if="props.row.skills[index].training_level">
                <svg
                  v-if="
                    props.row.skills[index].role === 'tuteur' ||
                    props.row.skills[index].role === 'tuteur,apprenant'
                  "
                  width="45px"
                  height="45px"
                  viewBox="0 0 45 45"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                  <title>Oval</title>
                  <g
                    id="Page-1"
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd"
                  >
                    <g
                      id="Oval"
                      fill="#67EB7B"
                      :class="`level${props.row.skills[index].training_level}`"
                    >
                      <circle cx="22.5" cy="22.5" r="22.5"></circle>
                    </g>
                  </g>
                </svg>
                <svg
                  v-if="
                    props.row.skills[index].role === 'formateur' ||
                    props.row.skills[index].role === 'coordinateur'
                  "
                  width="41px"
                  height="41px"
                  viewBox="0 0 41 41"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                  <title>Rectangle</title>
                  <g
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd"
                  >
                    <g
                      id="Rectangle"
                      fill="#67EB7B"
                      :class="`level${props.row.skills[index].training_level}`"
                    >
                      <rect x="0" y="0" width="41" height="41"></rect>
                    </g>
                  </g>
                </svg>
                <svg
                  v-if="props.row.skills[index].role === 'apprenant'"
                  width="45.5555556px"
                  height="41px"
                  viewBox="0 0 45.5555556 41"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                  <title>Triangle</title>
                  <g
                    id="Page-1"
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd"
                  >
                    <g
                      id="Triangle"
                      fill="#67EB7B"
                      :class="`level${props.row.skills[index].training_level}`"
                    >
                      <polygon
                        transform="translate(22.7778, 20.5) scale(-1, 1) translate(-22.7778, -20.5)"
                        points="22.7777778 0 45.5555556 41 0 41"
                      ></polygon>
                    </g>
                  </g></svg
              ></span>
              <!-- <img
                v-if="props.row.skills[index].training_level"
                :src="
                  require(`@/assets/img/levels/level${props.row.skills[index].training_level}.svg`)
                "
                alt=""
                class="img_status"
              /> -->
              <img
                v-else
                :src="require(`@/assets/img/levels/levelnull.svg`)"
                alt=""
                class="img_status"
              />
            </b-tooltip>
          </template>
        </b-table-column>
      </template>
    </b-table>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import _ from 'lodash';
import moment from 'moment';

export default {
  props: {
    skill: {
      type: [Object, Array],
    }, // skill to display
    dates: {
      type: Boolean,
      // boolean to display dates or not
    },
  },
  components: {
  },
  filters: {
    day(date) {
      return moment(date).format('DD/MM');
    },
    year(date) {
      return moment(date).format('YYYY');
    },
  },
  data() {
    return {
      isLoading: false,
      isFullPage: false,
      students: [
        {
          id: 1,
          name: 'Kimberley Rougement',
          skills: [
            {
              id: 2592,
              training_level: 1,
            },
            {
              id: 2593,
              training_level: 2,
            },
            {
              id: 2594,
              training_level: 3,
            },
            {
              id: 2595,
              training_level: 4,
            },
          ],
        },
        {
          id: 2,
          name: 'Martin Dupont',
          skills: [
            {
              id: 2592,
              training_level: 1,
            },
            {
              id: 2593,
              training_level: 2,
            },
            {
              id: 2594,
              training_level: 3,
            },
            {
              id: 2595,
              training_level: 4,
            },
          ],
        },
        {
          id: 3,
          name: 'Kimberley Rougement',
          skills: [
            {
              id: 2592,
              training_level: 1,
            },
            {
              id: 2593,
              training_level: 2,
            },
            {
              id: 2594,
              training_level: 3,
            },
            {
              id: 2595,
              training_level: 4,
            },
          ],
        },
      ],
      data: [
        {
          name: 'Board Games',
          sold: 131,
          available: 301,
          items: [
            {
              name: 'Monopoly',
              sold: 57,
              available: 100,
            },
            {
              name: 'Scrabble',
              sold: 23,
              available: 84,
            },
            {
              name: 'Chess',
              sold: 37,
              available: 61,
            },
            {
              name: 'Battleships',
              sold: 14,
              available: 56,
            },
          ],
        },
        {
          name: 'Jigsaws & Puzzles',
          sold: 88,
          available: 167,
          items: [
            {
              name: 'World Map',
              sold: 31,
              available: 38,
            },
            {
              name: 'London',
              sold: 23,
              available: 29,
            },
            {
              name: 'Sharks',
              sold: 20,
              available: 44,
            },
            {
              name: 'Disney',
              sold: 14,
              available: 56,
            },
          ],
        },
        {
          name: 'Books',
          sold: 434,
          available: 721,
          items: [
            {
              name: 'Hamlet',
              sold: 101,
              available: 187,
            },
            {
              name: 'The Lord Of The Rings',
              sold: 85,
              available: 156,
            },
            {
              name: 'To Kill a Mockingbird',
              sold: 78,
              available: 131,
            },
            {
              name: 'Catch-22',
              sold: 73,
              available: 98,
            },
            {
              name: 'Frankenstein',
              sold: 51,
              available: 81,
            },
            {
              name: 'Alice\'s Adventures In Wonderland',
              sold: 46,
              available: 68,
            },
          ],
        },
      ],
      blockskills: [
        {
          id: 569,
          name: 'C1 Rechercher une information dans une documentation technique, en local ou à distance',
          reference: 'C1',
          description: '',
          position: 1,
        },
        {
          id: 2591,
          name: 'C1.1 Mettre en œuvre une démarche de recherche d’information.',
          reference: 'C1.1',
          description: '- La fiabilité des informations est vérifiée.\n- Linformation recherchée est correctement réordonnée.\n- La démarche pour l’obtention de l’information est pertinente',
          position: 1,
        },
        {
          id: 2592,
          name: 'C2.1 Mettre en œuvre une démarche de recherche d’information.',
          reference: 'C2.1',
          description: '- La fiabilité des informations est vérifiée.\n- Linformation recherchée est correctement réordonnée.\n- La démarche pour l’obtention de l’information est pertinente',
          position: 1,
        },
        {
          id: 570,
          name: 'C2 Rechercher une information dans une documentation technique, en local ou à distance',
          reference: 'C2',
          description: '',
          position: 1,
        },
      ],
      showDetailIcon: true,
      studentsSkills: [],
      isOpen: false,
      selectedRole: ['tuteur,apprenant', 'formateur', 'tuteur', 'coordinateur'],
      selectedType: 'all',
      selectedMode: 'best',
    };
  },
  computed: {
    ...mapState('auth', ['currentUser']),
  },
  methods: {
    startLoading() {
      this.isLoading = true;
    },
    endLoading() {
      this.isLoading = false;
    },
    order(array) {
      return _.orderBy(array, 'position');
    },
    initStudents() {
      this.startLoading();
      if (this.selectedRole.lenght === 0) {
        this.selectedRole = ['apprenant'];
      }
      let mode = '';
      if (this.selectedMode === 'last') {
        mode = '&order=last';
      }
      let roles = '';
      let context = '';
      for (let i = 0; i < this.selectedRole.length; i += 1) {
        roles = `${roles}&role=${this.selectedRole[i]}`;
      }
      if (this.selectedType !== 'all') {
        context = `&context=${this.selectedType}`;
      }
      this.$http
        .get(`agenda/get_skills_filling/?training=${this.$route.params.idTraining}${roles}${mode}${context}&format=json`)
        .then((response) => {
          this.studentsSkills = response.data;
          this.endLoading();
        });
    },
    toggle() {
      this.startLoading();
      for (let i = 0; i < this.studentsSkills.skills_blocks.length; i += 1) {
        this.$refs.table.toggleDetails(this.studentsSkills.skills_blocks[i]);
      }
      this.isOpen = !this.isOpen;
      this.endLoading();
    },
    checkRoles() {
    },
    changeRole() {
      this.initStudents();
    },
  },
  created() {
    this.initStudents();
  },
  mounted() {
  },
};
</script>
<style scoped>
svg {
  width: 20px;
}
.level1 {
  fill: var(--danger);
}
.level2 {
  fill: var(--yellow);
}
.level3 {
  fill: var(--success);
}
.level4 {
  fill: #00c0fb;
}
.datesText {
  font-size: 12px;
  text-align: center;
  line-height: 1.1;
}
.evaluation-icon {
  width: 36px;
}
.evaluation {
  margin-right: 0.5rem;
}
.centre {
  background-color: lightsteelblue;
}
.skillstable .table-wrapper.has-sticky-header {
  height: 1500px !important;
}
</style>
