<template>
  <div class="card_simple card_light">
    <div class="columns card_resource is-vcentered mb-0 is-mobile">
      <div class="column is-narrow" v-if="resource.file">
        <img
          v-if="resource.file"
          :src="
            require(`@/assets/img/icons/${resource.file.split('.').pop()}.svg`)
          "
          alt=""
          width="40"
        />
      </div>
      <div class="column is-narrow" v-if="resource.url">
        <img :src="require('@/assets/img/icons/www.svg')" alt="" />
      </div>
      <div class="column">
        <div class="is-flex">
          <span v-if="resource.importance">
            <span
              class="tag secondary mr-3"
              v-if="resource.importance === 'important'"
              >{{ resource.importance }}</span
            >
          </span>
        </div>
        <div>
          <!-- debug line -->
          <!-- <b>{{ resource.id }}</b> -->
          <b>{{ resource.name }}</b>
        </div>
        <div v-if="resource.short_description !== 'null'">
          {{ resource.short_description }}
        </div>
        <b-collapse
          :open="false"
          position="is-bottom"
          aria-id="contentIdForA11y1"
          v-if="resource.long_description"
        >
          <a
            slot="trigger"
            slot-scope="props"
            aria-controls="contentIdForA11y1"
            class="url-simple"
          >
            {{ !props.open ? "Lire la suite" : "fermer" }}
          </a>
          <p>
            {{ resource.long_description }}
          </p>
        </b-collapse>
      </div>
      <div class="column is-narrow">
        <div>
          <a
            :href="resource.file"
            target="_blank"
            class="btn-resource"
            v-if="resource.file"
          >
            <b-button type="is-primary is-outlined is-rounded" size="is-small"
              >Voir la ressource</b-button
            ></a
          >
          <a
            :href="resource.url"
            target="_blank"
            class="btn-resource"
            v-if="resource.url"
          >
            <b-button type="is-primary is-outlined is-rounded"
              >Ouvrir le lien</b-button
            ></a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import localization from 'moment/locale/fr';
import { mapState } from 'vuex';

moment.updateLocale('fr', localization);

export default {
  props: {
    resource: [Object, Array],
    action: {
      type: String,
    },
    sequence: {
      type: Object,
    }, // object sequence
    typeoftraining: {
      type: String,
    }, // framework, training or studenttraining
  },
  filters: {
    moment(date) {
      return moment(date).format('D MMMM YYYY');
    },
  },
  computed: {
    ...mapState('auth', ['currentUser']),
  },
  methods: {
    messageUpdated() {
      this.$emit('messageFromChild');
    },
    deleteResource(idresource) {
      this.$buefy.dialog.confirm({
        title: 'Supprimer une ressource',
        message: 'Êtes-vous sûr de vouloir supprimer cette ressource ? Attention, cette ressource sera supprimée partout.',
        confirmText: 'Supprimer',
        cancelText: 'Annuler',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.$http
            .delete(`/resources/${idresource}/?format=json`)
            .then(() => {
              this.$buefy.toast.open({ message: 'Ressource supprimée', type: 'is-success' });
              this.$emit('messageFromChild');
            }).catch((error) => {
              window.console.log(error);
              this.errored = true;
            });
        },
      });
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  mounted() {
  },
  created() {
  },
};
</script>
<style lang="scss">
.card_item .select {
  padding-left: 0rem;
}
.card_light {
  padding: 0 !important;
  margin: 0 0 1rem 0 !important;
}
.card_resource {
  padding: 0 !important;
  margin: 0 0 0 0 !important;
}
</style>
