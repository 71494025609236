<template>
  <div>
    <b-loading :is-full-page="isFullPage" v-model="isLoading"> </b-loading>
    <b-modal
      v-model="isPracticalWorkActive"
      has-modal-card
      full-screen
      :can-cancel="false"
    >
      <template>
        <PracticalWork
          :practicalwork.sync="chosenPracticalWork"
          @close="closeDetailview"
        ></PracticalWork>
      </template>
    </b-modal>
    <b-modal
      v-model="isPracticalWorkSessionPlanningActive"
      has-modal-card
      full-screen
      :can-cancel="false"
    >
      <template>
        <PracticalWorkSessionCreation
          :practicalWork="chosenPracticalWork"
          :sessionDisplayed="chosenSessionDisplayed"
          :isNew="isNew"
          @success="sessionPlanningConfirmation"
          @updatedSuccess="sessionPlanningUpdateConfirmation"
          @close="
            isPracticalWorkSessionPlanningActive = false;
            chosenPracticalWork = [];
          "
        />
      </template>
    </b-modal>
    <b-modal
      v-model="isPracticalWorkEvaluationActive"
      has-modal-card
      full-screen
      :can-cancel="false"
      ><PracticalWorkEvaluations
        :practicalWork="chosenPracticalWork"
        :sessionDisplayed="chosenSessionDisplayed"
        :isEditMode="isEditMode"
        @close="isPracticalWorkEvaluationActive = false"
        @next="endEvaluations"
        @refresh="getPracticalWorks"
        @success="
          getPracticalWorks && (isPracticalWorkEvaluationActive = false)
        "
      />
    </b-modal>
    <b-modal
      v-model="isPracticalWorkCreation"
      has-modal-card
      full-screen
      :can-cancel="false"
    >
      <template>
        <PracticalWorkCreation
          :practicalWork.sync="chosenPracticalWork"
          @close="closePracticalWorkCreation"
          :isReadingMode.sync="isReadMode"
          @success="practicalWorkCreationConfirmation"
        ></PracticalWorkCreation>
      </template>
    </b-modal>
    <div class="columns is-vcentered">
      <div class="column">
        <div class="title-paragraph mt-0 mb-4">Travaux pratiques</div>
        <div class="subtitle-paragraph">
          Voici la liste des activités pratiques pour cette formation. Chaque
          activité pratique peut donner lieu à plusieurs séances, qui
          correspondent aux différentes dates et heures où les apprenants
          participent à la séance pratique. Pour chaque séance, vous pouvez
          gérer les apprenants concernés, les formateurs présents, et évaluer
          les compétences acquises par les apprenants.
        </div>
        <!-- <div class="subtitle-paragraph">
          Voici la liste des séances de travaux pratiques pour la formation.
          Vous pouvez en créer davantage ou accédez au détail de chaque séance
          pour gérer les apprenant•e•s concerné•e•s et évaluer leurs
          compétences, en fonction de celles qui ont été attachées à chaque
          séance.
        </div>
        <div class="subtitle-paragraph">
          Chaque séance de travaux pratiques se décompose en plusieurs sessions,
          qui correspondent aux différentes dates et heures où les apprenants
          participent à la séance. Pour chaque session, vous pouvez gérer les
          apprenants concernés, les formateurs présents, et évaluer les
          compétences acquises par les apprenants au cours de cette session.
          Cela vous permet de suivre la progression des apprenants et d'ajuster
          votre enseignement en fonction de leurs besoins.
        </div> -->
      </div>

      <div class="column is-narrow">
        <b-button
          type="is-primary is-rounded is-pulled-right mt-2"
          @click="
            isPracticalWorkCreation = true;
            chosenPracticalWork = null;
            isReadMode = false;
          "
          >Créer une activité pratique</b-button
        >
      </div>
    </div>
    <b-field label="Période concernée" class="mb-0">
      <b-select
        placeholder="Période concernée"
        v-model="orderSelected"
        @input="changeOrder()"
      >
        <option value="">Du + ancien au + récent</option>
        <option value="inverse">Du + récent au + ancien</option>
        <!-- <option value="all">Toute la formation</option> -->
        <option value="date_session">Dates de session</option>
      </b-select>
    </b-field>
    <!-- Practical Works table -->
    <b-table
      v-if="practicalWorks.length > 0"
      :data="practicalWorks"
      ref="table"
      paginated
      per-page="10"
      :opened-detailed="defaultOpenedDetails"
      detailed
      detail-key="id"
      @details-open="(row) => $buefy.toast.open(`Détail de ${row.title}`)"
      :show-detail-icon="showDetailIcon"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
    >
      <b-table-column label="Titre" v-slot="props" width="500">
        {{ props.row.title }}
      </b-table-column>

      <b-table-column label="Compétences évaluées" sortable v-slot="props">
        <template v-if="showDetailIcon">
          <b-tag v-if="props.row.skills.length > 0" type="is-success is-light">
            {{ props.row.skills.length }} compétences</b-tag
          >
          <b-tag v-else type="is-danger is-light">
            Aucune compétence sélectionnée
          </b-tag>
        </template>
        <template v-else>
          <a @click="props.toggleDetails(props.row)">
            {{ props.row.title }}
          </a>
        </template>
      </b-table-column>

      <b-table-column label="Sessions" sortable v-slot="props">
        <b-tag v-if="props.row.sessions.length > 0" type="is-success is-light"
          >{{ props.row.sessions.length }} sessions</b-tag
        >
        <b-tag v-else type="is-primary is-light"
          >Aucune session planifiée</b-tag
        >
      </b-table-column>

      <b-table-column label="Formateurs" sortable v-slot="props">
        <div v-if="props.row.teachers.length > 0">
          <b-tag
            v-for="teacher in props.row.teachers"
            :key="teacher"
            type="is-success is-light"
          >
            {{ teacher }}
          </b-tag>
        </div>
        <b-tag v-else type="is-primary is-light">Aucun formateur</b-tag>
      </b-table-column>

      <b-table-column label="Détail" v-slot="props">
        <div
          @click.prevent="
            displayPracticalWorkDetail(props.row);
            isPracticalWorkCreation = true;
            isReadMode = true;
            chosenPracticalWork = props.row;
          "
        >
          <b-icon icon="see"></b-icon>
        </div>
      </b-table-column>

      <template #detail="props">
        <div class="mt-2">
          <div class="columns is-vcentered is-mobile mb-4">
            <div class="column">
              <h3 class="subtitle is-5 has-text-weight-semibold mt-2">
                {{ props.row.title }}
              </h3>
            </div>
            <div class="column is-narrow">
              <b-button
                rounded
                outlined
                size="small"
                class="is-pulled-right is-primary"
                @click="
                  sessionPlanning(props.row);
                  isNew = true;
                "
                >Planifier une séance</b-button
              >
            </div>
          </div>
        </div>

        <!-- List -->
        <table
          class="table is-fullwidth"
          responsive
          v-if="props.row.sessions.length > 0"
        >
          <thead>
            <tr>
              <th style="width: 30%">Titre</th>
              <th>Apprenants</th>
              <th>Formateurs</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="session in props.row.sessions" :key="session.id">
              <td>
                <div class="is-flex">
                  <span
                    class="has-text-success"
                    v-if="session.status === 2"
                    style="
                      font-size: 0.5em;
                      margin-right: 0.5em;
                      line-height: 3;
                    "
                    >&#11044;</span
                  >
                  <span
                    class="has-text-primary"
                    v-else-if="session.status === 1"
                    style="
                      font-size: 0.5em;
                      margin-right: 0.5em;
                      line-height: 3;
                    "
                    >&#11044;</span
                  ><span
                    class="has-text-danger"
                    v-else-if="session.status === 0"
                    style="
                      font-size: 0.5em;
                      margin-right: 0.5em;
                      line-height: 3;
                    "
                    >&#11044;</span
                  ><b>{{ session.title }}</b>
                </div>
              </td>
              <td>
                <ul>
                  <li v-for="student in session.students" :key="student.id">
                    <div class="is-inline-flex" style="align-items: center">
                      <span
                        :class="
                          student.evaluated
                            ? 'has-text-success'
                            : 'has-text-danger'
                        "
                        style="font-size: 0.5em; margin-right: 0.5em"
                        >&#11044;</span
                      >
                      {{ student.first_name }}
                      <span class="has-text-weight-bold">{{
                        student.last_name
                      }}</span>
                    </div>
                  </li>
                </ul>
              </td>
              <td>
                <div v-if="session.teachers.length > 0">
                  <ul>
                    <li v-for="teacher in session.teachers" :key="teacher.id">
                      <b-tag type="is-success is-light"> {{ teacher }} </b-tag>
                    </li>
                  </ul>
                </div>
                <div v-else>
                  <b-tag type="is-info is-light"> Aucun formateur </b-tag>
                </div>
              </td>
              <td>{{ formattedDatetime(session.date) }}</td>
              <td>
                <a
                  class="button is-outlined is-rounded has-text-grey is-small mr-4 mb-1"
                  @click="
                    isNew = false;
                    isPracticalWorkSessionPlanningActive = true;
                    chosenPracticalWork = props.row;
                    chosenSessionDisplayed = session;
                  "
                >
                  Éditer
                </a>
                <a
                  v-if="
                    session.teachers.includes(
                      currentUser.last_name + ' ' + currentUser.first_name
                    ) && session.status === 1
                  "
                  class="button is-rounded has-text-white is-primary is-small mb-1"
                  @click="
                    isPracticalWorkEvaluationActive = true;
                    isEditMode = true;
                    chosenPracticalWork = props.row;
                    chosenSessionDisplayed = session;
                  "
                >
                  Évaluer
                </a>
                <a
                  v-else
                  class="button is-rounded has-text-primary is-outlined is-small mb-1"
                  @click="
                    isPracticalWorkEvaluationActive = true;
                    isEditMode = false;
                    chosenPracticalWork = props.row;
                    chosenSessionDisplayed = session;
                  "
                >
                  Voir les évaluations
                </a>
              </td>
            </tr>
          </tbody>
        </table>
        <div v-else>
          <p>
            La liste des sessions apparaîtra ici lorsqu'elle seront (ou auront
            été) planifiées
          </p>
        </div>
      </template>
    </b-table>
    <b-message v-else type="is-info">
      Vous n'avez créé aucune activité pour le moment, cliquez sur le bouton
      "Créer une activité pratique" pour en ajouter une.
    </b-message>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';
import localization from 'moment/locale/fr';
import PracticalWork from '@/components/Trainings/PracticalWork.vue';
import PracticalWorkCreation from '@/components/Trainings//PracticalWorkCreation.vue';
import PracticalWorkSessionCreation from '@/components/Trainings/PracticalWorkSessionCreation.vue';
import PracticalWorkEvaluations from '@/components/Trainings/PracticalWorkEvaluations.vue';

moment.updateLocale('fr', localization);

export default {
  props: {
    typeoftraining: {
      type: String,
    }, // framework, training or studenttraining
    objectId: {
      type: Number,
    },
    contentWidth: {
      type: Number,
    },
  },
  filters: {
    day(date) {
      return moment(date).format('DD/MM/YYYY');
    },
  },
  computed: {
    ...mapState('auth', ['currentUser']),
    formattedDatetime() {
      return function formattedDatetime(date) {
        return moment.utc(date).local().locale('fr', localization).format('LL');
      };
    },
  },
  components: {
    PracticalWork,
    PracticalWorkCreation,
    PracticalWorkSessionCreation,
    PracticalWorkEvaluations,
  },
  data() {
    return {
      locale: 'UTC',
      isLoading: false,
      isFullPage: false,
      checkedRows: [],
      showDetailIcon: true,
      chosenPracticalWork: [],
      isPracticalWorkActive: false,
      isPracticalWorkSessionPlanningActive: false,
      isPracticalWorkCreation: false,
      isPracticalWorkEvaluationActive: false,
      openDetails: [], // array to store the opened rows
      practicalWorks: [],
      defaultOpenedDetails: [],
      isEditMode: false,
      chosenSessionDisplayed: null,
      justCreatedPracticalWork: null,
      orderSelected: '',
      isNew: true,
      isReadMode: false,
    };
  },
  methods: {
    async getPracticalWorks() {
      try {
        let order = '';
        if (this.orderSelected) {
          order = `&order=${this.orderSelected}`;
        }
        const response = await this.$http.get(`/practical_work/get_practical_works/?training=${this.$route.params.idTraining}${order}&format=json`);
        this.practicalWorks = response.data;
        this.endLoading();
      } catch (error) {
        window.console.error(error);
        // handle the error as needed
      }
    },
    startLoading() {
      this.isLoading = true;
    },
    endLoading() {
      this.isLoading = false;
    },
    initTraining() {
      this.startLoading();
      this.$http
        .get(`/trainings/${this.$route.params.idTraining}/?query={training_periods}&format=json`)
        .then((response) => {
          this.training = response.data;
        });
    },
    handleDetails(practicalwork) {
      this.isPracticalWorkActive = true;
      this.chosenPracticalWork = practicalwork;
    },
    handleDetailsOpen(row) {
      // add the row to the openDetails array
      this.openDetails.push(row);
    },
    handleDetailsClose(row) {
      // remove the row from the openDetails array
      this.openDetails = this.openDetails.filter(
        (openRow) => openRow !== row,
      );
    },
    closeDetailview() {
      this.isPracticalWorkActive = false;
      this.chosenPracticalWork = [];
    },
    closePracticalWorkCreation() {
      this.getPracticalWorks();
      this.isPracticalWorkCreation = false;
    },
    sessionPlanning(targetPracticalWork) {
      this.isPracticalWorkSessionPlanningActive = true;
      this.chosenPracticalWork = targetPracticalWork;
    },
    sessionPlanningConfirmation() {
      this.getPracticalWorks();
      this.chosenSessionDisplayed = null;
      this.isPracticalWorkSessionPlanningActive = false;
      this.$buefy.toast.open({
        message: 'Votre session a bien été planifiée !',
        type: 'is-success',
        position: 'is-top-right',
        duration: 5000,
        queue: false,
        pauseOnHover: true,
        closeOnClick: true,
        onDismiss: null,
        indefinite: false,
      });
    },
    sessionPlanningUpdateConfirmation() {
      this.getPracticalWorks();
      this.chosenSessionDisplayed = null;
      this.isPracticalWorkSessionPlanningActive = false;
      this.$buefy.toast.open({
        message: 'Votre session a bien été modifiée !',
        type: 'is-success',
        position: 'is-top-right',
        duration: 5000,
        queue: false,
        pauseOnHover: true,
        closeOnClick: true,
        onDismiss: null,
        indefinite: false,
      });
    },
    displayPracticalWorkDetail(practicalWork) {
      this.chosenPracticalWork = practicalWork;
    },
    practicalWorkCreationConfirmation(createdPracticalWorkId) {
      this.closePracticalWorkCreation();
      this.$buefy.toast.open({
        message: 'Votre activité pratique a bien été créée !',
        type: 'is-success',
        position: 'is-top-right',
        duration: 5000,
        queue: false,
        pauseOnHover: true,
        closeOnClick: true,
        onDismiss: null,
        indefinite: false,
      });

      // Wait for practicalWorks to be loaded before searching for the newly created practical work
      this.getPracticalWorks().then(() => {
        /* eslint-disable max-len */
        const foundPracticalWork = this.practicalWorks.find((practicalWork) => practicalWork.id === createdPracticalWorkId);
        /* eslint-enable max-len */
        if (foundPracticalWork) {
          this.justCreatedPracticalWork = foundPracticalWork;
        } else {
          window.console.error(`Unable to find practical work with ID ${createdPracticalWorkId}`);
        }
      });
    },
    endEvaluations() {
      this.isPracticalWorkEvaluationActive = false;
      this.getPracticalWorks();
    },
    isTeacherCanEdit() {
      const name = `${this.currentUser.last_name} ${this.currentUser.first_name}`;
      for (let i = 0; i < this.practicalWork.teachers.length; i += 1) {
        if (this.currentUser.role === 'formateur' && (name === this.practicalWork.teachers[i])) {
          this.teacherCanEdit = true;
          break;
        } else {
          this.teacherCanEdit = false;
        }
      }
    },
    changeOrder() {
      this.getPracticalWorks();
    },
  },
  created() {
    this.startLoading();
    this.initTraining();
  },
  mounted() {
    this.getPracticalWorks();
  },
};
</script>

<style scoped>
#modalWaypoint .title2 {
  text-align: left !important;
}

.modal-user-infos {
  padding: 2rem 2rem 4rem 2rem;
}

.title-infos {
  padding-top: 0.5rem;
  margin-bottom: 0.5rem;
  margin-top: 1rem;
  font-size: 1.1rem;
  color: #1b1824;
  font-weight: 700;
}
.editInfos .ck-content {
  min-height: 7vh !important;
}

.icon {
  cursor: pointer;
}
</style>
