<template>
  <div class="modalbox">
    <!-- <div class="lineCrumbAndAccount"> <AppAccountMenu /></div> -->
    <b-loading :is-full-page="isFullPage" v-model="isLoading"> </b-loading>
    <div class="is-flex bigmodal">
      <a @click="goPreviousPage('parcours')" class="ml-auto">
        <b-icon icon="close" type="is-primary"></b-icon>
      </a>
    </div>

    <div
      class="container title-modal is-flex justify-content-between align-items-center"
    >
      <div>
        <div class="subtitle-paragraph mb-0">
          Parcours pédagogique de l'apprenant
        </div>
        <div class="title-page short-title">
          {{ studenttrainingdetail.student_name }}
        </div>
        <router-link :to="'/formations/' + $route.params.idTraining">
          <b-button type="is-text" class="pl-0"
            ><span
              ><u>formation :</u>
              {{ studenttrainingdetail.training_name }}</span
            ></b-button
          ></router-link
        >
      </div>
      <div>
        <!-- <b-button
          @click.prevent="gotoStep(2)"
          type="is-primary is-rounded is-outlined mr-3"
          rounded
          icon-left="resource"
          >Ressources</b-button
        > -->
        <b-button
          @click.prevent="saveStudentTraining"
          type="is-success is-rounded mr-3"
          rounded
          >valider</b-button
        >
        <b-button
          @click.prevent="exportLivret()"
          type="is-primary is-rounded mr-3"
          rounded
          >exporter le livret
        </b-button>
      </div>
    </div>
    <section class="mt-auto pr-0 pl-0 tab-activity" id="header-tabs">
      <div class="b-tabs container">
        <div v-if="studenttrainingdetail.training_finished">
          <b-message type="is-danger" class="mt-2 mb-0"
            >Attention, cette période de formation est indiquée comme
            terminée</b-message
          >
        </div>
        <nav class="tabs" v-if="currentUser.role !== 'formateur'">
          <ul v-if="without_dates">
            <li
              v-for="(tab, index) of tabsWhitoutDates"
              :key="index"
              :id="tab.id"
              :class="currentTab === tab.slug ? 'is-active' : ''"
            >
              <a @click="gotoStep(tab.slug)"
                ><span>{{ tab.name }}</span></a
              >
            </li>
            <li :class="currentTab === 'resources' ? 'is-active' : ''">
              <a @click="gotoResources"><span>Ressources</span></a>
            </li>
          </ul>
          <ul v-else>
            <li
              v-for="(tab, index) of tabs"
              :key="index"
              :id="tab.id"
              :class="currentTab === tab.slug ? 'is-active' : ''"
            >
              <a @click="gotoStep(tab.slug)"
                ><span>{{ tab.name }}</span></a
              >
            </li>
            <li :class="currentTab === 'resources' ? 'is-active' : ''">
              <a @click="gotoResources"><span>Ressources</span></a>
            </li>
          </ul>
        </nav>
        <nav v-else class="tabs">
          <ul>
            <li
              v-for="(tab, index) of tabsTeacher"
              :key="index"
              :id="tab.id"
              :class="currentTab === tab.slug ? 'is-active' : ''"
            >
              <a @click="gotoStep(tab.slug)"
                ><span>{{ tab.name }}</span></a
              >
            </li>
            <li :class="currentTab === 'resources' ? 'is-active' : ''">
              <a @click="gotoResources"><span>Ressources</span></a>
            </li>
          </ul>
        </nav>
      </div>
    </section>
    <div v-if="step === 'parcours'" class="pb-5 mb-6">
      <div class="container">
        <div class="helpUser contentActivity">
          <div class="title">Adaptez le parcours de votre apprenant</div>
          <div>
            Vous ne pouvez modifier que l'ordre des expériences ou désactiver
            l'une des expériences. Pour ajouter une expérience, faites-le depuis
            le parcours de votre formation.
          </div>
        </div>
      </div>
      <div v-if="studenttrainingdetail" class="container contentActivity">
        <div class="mb-5">
          <b-button
            rounded
            @click="isOpenPeriod = true"
            v-if="isOpenPeriod === false"
            size="is-small"
            >ouvrir tout</b-button
          >
          <b-button
            rounded
            @click="isOpenPeriod = false"
            v-if="isOpenPeriod === true"
            size="is-small"
            >fermer tout</b-button
          >
        </div>
        <div class="studentParcours editBloc">
          <b-collapse
            class="card"
            animation="slide"
            v-for="(period, indexPeriod) of studenttrainingdetail.periods"
            :key="indexPeriod"
            :open="isOpenPeriod"
          >
            <div
              slot="trigger"
              slot-scope="props"
              class="card-header"
              role="button"
            >
              <div
                class="bg-card is-flex bg-primary align-items-center"
                :class="'periodType' + period.type"
              >
                <a class="card-header-icon">
                  <b-icon :icon="props.open ? 'menu-down' : 'menu-up'">
                  </b-icon>
                </a>
                <!-- <b-icon icon="drag" size="is-medium" type="is-black"> </b-icon> -->
                <p class="card-header-title">
                  <span class="mr-2">{{ indexPeriod + 1 }}</span>
                  <!-- <span class="mr-2">id : {{ period.id }}</span> -->
                  <!-- <span
                  type="text"
                  class="form-control w-75"
                  v-model="period.name"
                  @input="onChangeinputPeriod(indexPeriod, period.id)"
                /> -->
                  <span>{{ period.name }}</span>
                </p>
                <div class="actions is-flex align-items-center"></div>
              </div>
            </div>
            <div class="card-content card-student-training">
              <draggable
                :list="period.sequences"
                :group="{ name: 'period' }"
                @end="onEndDrag(indexPeriod)"
                handle=".handle"
              >
                <div
                  class="card-edit-sequence is-flex align-items-center w-100"
                  :class="'activated' + sequence.student_sequence_status"
                  v-for="(sequence, index) of period.sequences"
                  :key="index"
                >
                  <div
                    class="column is-6 bloc-left is-flex mr-auto align-items-center"
                  >
                    <div class="is-flex bloc-handle">
                      <b-icon
                        icon="drag"
                        size="is-medium"
                        type="is-primary"
                        class="handle btn"
                      >
                      </b-icon>
                      <div
                        class="sequenceType situation has-text-centered"
                        type="situation"
                        :class="'sequenceType' + sequence.sequence_type"
                      >
                        {{ sequence.sequence_type_label }}
                      </div>
                    </div>

                    <div class="card-header-title">
                      <span>{{ sequence.sequence_name }}</span>
                      <span
                        v-if="sequence.student_sequence_subtitle"
                        class="ml-1"
                        >&nbsp; ({{ sequence.student_sequence_subtitle }})</span
                      >

                      <!-- <span style="font-size: 0.7rem; margin-left: 1rem"
                    >student_sequence_id
                    {{ sequence.student_sequence_id }}</span
                  > -->
                    </div>
                  </div>
                  <!-- eslint-disable max-len-->
                  <div
                    class="column is-6 actions actions-parcours is-flex align-items-center justify-content-end"
                  >
                    <div
                      class="column is-4 is-flex align-items-center mr-3 tutor-select tutor-bloc"
                    >
                      <div
                        v-if="sequence.student_sequence_tutor"
                        class="st-tutor is-flex align-items-center columns w-100"
                      >
                        <div class="column is-9 has-text-left">
                          <div :id="'p' + indexPeriod + 'text' + index">
                            <b-icon icon="check" type="is-success"></b-icon>
                            <b>tuteur : </b>
                            <div style="margin-top: -4px">
                              <span>
                                {{ sequence.student_sequence_tutor }}</span
                              >
                            </div>
                          </div>
                        </div>
                        <div class="column is-4 has-text-right">
                          <b-button
                            type="is-text"
                            class="ml-2"
                            style="font-size: 0.8rem"
                            @click="
                              collapseBlock(
                                'p' + indexPeriod + 's' + index,
                                'p' + indexPeriod + 'text' + index
                              )
                            "
                            >modifier</b-button
                          >
                        </div>
                        <div
                          class="cardClosed"
                          :id="'p' + indexPeriod + 's' + index"
                        >
                          <b-select
                            class="selectTutor"
                            placeholder="Choisissez un tuteur"
                            @input="
                              patchTutor(
                                sequence.student_sequence_id,
                                sequence.student_sequence_tutor,
                                'p' + indexPeriod + 's' + index,
                                'p' + indexPeriod + 'text' + index,
                                true
                              )
                            "
                            v-model="sequence.student_sequence_tutor"
                          >
                            <option
                              v-for="(
                                tutor, index
                              ) in studenttrainingdetail.training_tutors_ids"
                              :value="tutor"
                              :key="tutor"
                            >
                              {{ studenttrainingdetail.training_tutors[index] }}
                            </option>
                          </b-select>
                        </div>
                      </div>
                      <div
                        v-if="!sequence.student_sequence_tutor"
                        class="is-flex columns w-100"
                      >
                        <div
                          class="is-flex align-items-center"
                          v-if="
                            sequence.sequence_type === 1 ||
                            sequence.sequence_type === 2 ||
                            sequence.sequence_type === 7 ||
                            sequence.sequence_type === 10
                          "
                        >
                          <b-icon icon="warning" type="is-danger"></b-icon>
                          <!-- <span class="st-tutor">Obligatoire</span> -->
                        </div>
                        <div v-else>
                          <b-icon icon="user" type="is-primary"></b-icon>
                        </div>
                        <b-select
                          class="selectTutor"
                          placeholder="Nom tuteur"
                          @input="
                            patchTutor(
                              sequence.student_sequence_id,
                              sequence.student_sequence_tutor,
                              'p' + indexPeriod + 's' + index,
                              'p' + indexPeriod + 'text' + index,
                              false
                            )
                          "
                          v-model="sequence.student_sequence_tutor"
                        >
                          <option
                            v-for="(
                              tutor, index
                            ) in studenttrainingdetail.training_tutors_ids"
                            :value="tutor"
                            :key="tutor"
                          >
                            {{ studenttrainingdetail.training_tutors[index] }}
                          </option>
                        </b-select>
                      </div>
                    </div>
                    <!--disabled for now -->
                    <div class="is-flex dates-bloc is-align-items-center">
                      <div
                        class="has-text-centered"
                        v-if="
                          sequence.student_sequence_start_date < today &&
                          sequence.student_sequence_end_date > today
                        "
                      >
                        <span class="tag mr-2 is-narrow statusInProgress">
                          A faire
                        </span>
                      </div>
                      <div
                        class="has-text-centered"
                        v-else-if="
                          sequence.student_sequence_end_date < today &&
                          sequence.student_sequence_status === 1
                        "
                      >
                        <span class="tag mr-2 is-narrow status2">
                          En retard
                        </span>
                      </div>
                      <div class="has-text-centered" v-else>
                        <span
                          class="tag mr-2 is-narrow"
                          :class="'status' + sequence.student_sequence_status"
                          >{{ sequence.student_sequence_status_label }}</span
                        >
                      </div>
                      <div class="mx-auto dates-schedulded has-text-centered">
                        <div
                          v-if="sequence.student_sequence_start_date"
                          class="has-text-centered"
                        >
                          <div
                            class="date-sequence"
                            v-if="sequence.student_sequence_start_date"
                          >
                            {{ sequence.student_sequence_start_date | day }}
                          </div>
                        </div>
                        <div
                          v-if="sequence.student_sequence_end_date"
                          class="has-text-centered"
                        >
                          <div
                            class="date-sequence"
                            v-if="sequence.student_sequence_end_date"
                          >
                            {{ sequence.student_sequence_end_date | day }}
                          </div>
                        </div>
                        <div v-else class="date-sequence has-text-centered">
                          -
                        </div>
                      </div>
                      <div class="actions-buttons is-flex align-items-center">
                        <!-- <b-button
                          type="is-primary is-shadowed is-small mr-1"
                          size=""
                          rounded
                          @click.prevent="
                            seeSequencedetail(sequence.student_sequence_id)
                          "
                          v-if="sequence.student_sequence_status != 5"
                          >rédiger</b-button
                        >-->
                        <a v-if="sequence.student_sequence_status === 5">
                          <b-button
                            type="is-primary is-shadowed is-small mr-1"
                            size=""
                            rounded
                            outlined
                            class="mr-5"
                            @click.prevent="
                              reactivateSequence(
                                index,
                                sequence.student_sequence_id
                              )
                            "
                            >réactiver</b-button
                          >
                        </a>
                        <a
                          v-else
                          class="actions-icon"
                          @click.prevent="
                            removeSequence(index, sequence.student_sequence_id)
                          "
                        >
                          <b-icon
                            icon="close"
                            size="is-medium"
                            type="is-danger"
                          >
                          </b-icon>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </draggable>
              <div class="has-text-centered">
                <!-- <button
                class="button btn btn-secondary is-primary is-rounded"
                @click.prevent="addSequence(indexPeriod, period.id)"
              >
                + Ajouter une expérience
              </button> -->
              </div>
            </div>
          </b-collapse>
        </div>
      </div>
    </div>
    <div
      class="container contentActivite mt-5 pt-5 pb-5 mb-6"
      v-if="step === 'agenda'"
    >
      <AgendaStudent
        :dates="dates"
        :student="Number($route.params.idStudent)"
        :studentTraining="Number($route.params.idStudentTraining)"
        :typeView="'student'"
        :key="planningKey"
      ></AgendaStudent>
    </div>
    <div
      v-if="step === 'todo'"
      class="container contentActivite mt-5 pt-5 pb-5 mb-6"
    >
      <PlanningUser
        :dates="dates"
        :student="Number($route.params.idStudent)"
        :studentTraining="Number($route.params.idStudentTraining)"
        :typePlanning="'todo'"
        :typeView="'student'"
        :key="planningKey"
      ></PlanningUser>
    </div>
    <div
      v-if="step === 'late'"
      class="container contentActivite mt-5 pt-5 pb-5 mb-6"
    >
      <PlanningUser
        :dates="dates"
        :student="Number($route.params.idStudent)"
        :studentTraining="Number($route.params.idStudentTraining)"
        :typePlanning="'late'"
        :typeView="'student'"
        :key="planningKey"
      ></PlanningUser>
    </div>
    <div
      v-if="step === 'done'"
      class="container contentActivite mt-5 pt-5 pb-5 mb-6"
    >
      <PlanningUser
        :dates="dates"
        :student="Number($route.params.idStudent)"
        :studentTraining="Number($route.params.idStudentTraining)"
        :typePlanning="'done'"
        :typeView="'student'"
        :key="planningKey"
      ></PlanningUser>
    </div>
    <div
      v-if="step === 'next'"
      class="container contentActivite mt-5 pt-5 pb-5 mb-6"
    >
      <PlanningUser
        :dates="dates"
        :student="Number($route.params.idStudent)"
        :studentTraining="Number($route.params.idStudentTraining)"
        :typePlanning="'next'"
        :typeView="'student'"
        :key="planningKey"
      ></PlanningUser>
    </div>
    <section
      v-if="step == 'skills'"
      class="container contentActivity mt-5 pt-5 pb-5 mb-6"
    >
      <SkillsStudent
        :typeoftraining="'student'"
        :objectId="Number($route.params.idStudentTraining)"
      />
    </section>
    <section
      v-if="step == 'waypoint'"
      class="container contentActivity mt-5 pb-5 mb-6"
    >
      <WaypointsList
        v-if="studenttrainingdetail.student_name"
        :student="studenttrainingdetail.student_name.toLowerCase()"
      />
    </section>
    <div
      v-if="step === 'resources'"
      class="container contentActivite mt-5 pt-5 pb-5 mb-6"
    >
      <h1 class="title-paragraph">Ressources associées à ce parcours</h1>
      <div class="helpUser mt-4">
        <div class="title">Ajoutez des ressources</div>
        <div>
          Ici vous pouvez ajouter des documents à ce parcours. Dans l'édition du
          parcours vous pouvez également ajouter des ressources à certaines
          expériences ou activités.
        </div>
      </div>
      <div
        class="card_simple is-primary w-100 has-text-centered"
        @click="isOpen = !isOpen"
      >
        <div>
          <b-icon icon="resource" type="is-primary" class="mr-3"></b-icon>
          <b style="cursor: pointer">Ajoutez une ressource</b>
        </div>
      </div>
      <b-collapse v-model="isOpen">
        <ResourceCreation
          :isOpen.sync="isOpen"
          :sequence="null"
          :typeoftraining="'studenttraining'"
          :idTraining="$route.params.idStudentTraining"
          :action="'post'"
          @messageFromChild="initResources"
          @messageFromResource="forceRerender"
          :key="resourceKey"
        ></ResourceCreation>
      </b-collapse>
      <div v-if="resources">
        <div v-if="resources.length">
          <div v-for="resource in resources" :key="resource.id">
            <ResourceCard
              :resource="resource"
              :action="'edit'"
              :isOpen.sync="isOpen"
              @messageFromChild="initResources"
            ></ResourceCard>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import draggable from 'vuedraggable';
import moment from 'moment';
import { mapState } from 'vuex';
import localization from 'moment/locale/fr';
import ResourceCreation from '@/components/Resources/ResourceCreation.vue';
import ResourceCard from '@/components/Resources/ResourceCard.vue';
import AgendaStudent from '@/components/Agenda/AgendaStudent.vue';
import PlanningUser from '@/components/Agenda/PlanningUser.vue';
import SkillsStudent from '@/components/Skills/SkillsStudent.vue';
import WaypointsList from '@/components/Waypoints/WaypointsList.vue';

moment.updateLocale('fr', localization);

export default {
  components: {
    draggable,
    ResourceCreation,
    ResourceCard,
    AgendaStudent,
    PlanningUser,
    SkillsStudent,
    WaypointsList,
    // AppAccountMenu,
  },
  filters: {
    day(date) {
      return moment(date).format('D/MM/YY');
    },
    hour(date) {
      return moment(date).format('HH:mm');
    },
  },
  computed: {
    ...mapState('auth', ['currentUser']),
  },
  data() {
    return {
      without_dates: '',
      today: new Date(),
      studenttrainingdetail: [],
      currentTab: 1,
      numberStep: 1,
      step: 1,
      tutorStudent: Number,
      isLoading: true,
      isFullPage: false,
      tabs: [
        {
          name: 'Parcours',
          slug: 'parcours',
          id: '1',
        },
      ],
      tabsWhitoutDates: [
        {
          name: 'Parcours',
          slug: 'parcours',
          id: '1',
        },
      ],
      tabsTeacher: [
        {
          name: 'Parcours',
          slug: 'parcours',
          id: '1',
        },
      ],
      resources: null,
      resourceKey: 0,
      planningKey: 0,
      planningLateKey: 0,
      isOpen: false,
      isOpenPeriod: true,
      dates: [],
    };
  },
  methods: {
    getTrainingRights() {
      this.$http
        .get(`/trainings/${this.$route.params.idTraining}/?query={teachers_can_edit,editable_without_dates}&format=json`)
        .then((response) => {
          this.teacherEdit = response.data.teachers_can_edit;
          this.without_dates = response.data.editable_without_dates;
        });
    },
    initStudentTrainingDetail() {
      this.$http
        .get(`/agenda/student_training_detail/?training_id=${this.$route.params.idTraining}&student_id=${this.$route.params.idStudent}&all=1&format=json`)
        .then((response) => {
          this.studenttrainingdetail = response.data;
          this.endLoading();
          this.studenttrainingdetail.periods = this.orderPeriod(this.studenttrainingdetail.periods);
          // this.editTrainingSteps();
          for (let i = 0; i < this.studenttrainingdetail.periods.length; i += 1) {
            const myPeriods = this.studenttrainingdetail.periods;
            if (myPeriods[i].sequences.length) {
              if (myPeriods[i].sequences[0].student_sequence_position === 0) {
                myPeriods[i].sequences = this.order(myPeriods[i].sequences);
              } else {
                myPeriods[i].sequences = this.order2(myPeriods[i].sequences);
              }
            }
          }
          // this.initResources();
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
          this.$buefy.dialog.confirm({
            title: 'Mince...',
            message: 'Une information n\'a pas pu être totalement chargée, nous vous invitons à recharger la page.',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
            onConfirm: () => document.location.reload(),
          });
        });
    },
    patchTutor(idSequence, value, id, id2, collapseOk) {
      this.startLoading();
      this.$http
        .post('/agenda/tutor2studenttraining/?format=json', {
          tutor_id: value,
          student_training_id: this.$route.params.idStudentTraining,
          student_sequences: [idSequence],
        }).then(() => {
          if (collapseOk === true) { this.collapseBlock(id, id2); }
          this.$buefy.toast.open({ message: 'Tuteurs ajoutés', type: 'is-success' });
          this.initStudentTrainingDetail();
        });
    },
    gotoStep(numberStep) {
      // to change the tab and the content
      this.currentTab = numberStep;
      this.step = numberStep;
      if (numberStep == null) {
        this.step = this.tabs[0].slug;
        this.currentTab = this.step;
      }
      if (numberStep === 'agenda' || numberStep === 'todo' || numberStep === 'late' || numberStep === 'done' || numberStep === 'next') {
        this.dates = [];
        this.initDates(numberStep);
      }
    },
    gotoResources() {
      this.currentTab = 'resources';
      this.step = this.currentTab;
      this.initResources();
    },
    goPreviousPage(step) {
      window.console.log(this.$route.params.from);

      this.$router.push({
        name: 'StudentTraining',
        params: {
          idStudent: this.$route.params.idStudent,
          idTraining: this.$route.params.idTraining,
          idStudentTraining: this.$route.params.idStudentTraining,
          from: this.$route.name,
          tab: step,
        },
      });
    },
    saveStudentTraining() {
      this.$router.push({
        name: 'StudentTraining',
        params: {
          tab: this.step,
          idStudent: this.$route.params.idStudent,
          idTraining: this.$route.params.idTraining,
          idStudentTraining: this.$route.params.idStudentTraining,
        },
      });
    },
    // edit periods and sequences on fdrag change
    editTrainingSteps(indexPeriod) {
      // to patch all the periods displayed
      this.refreshOrderSequences(indexPeriod);
      // window.console.log(this.periodsArray[indexPeriod]);
      for (let i = 0; i < this.periodsArray[indexPeriod].sequences.length; i += 1) {
        const studentSequenceId = this.periodsArray[indexPeriod].sequences[i].student_sequence_id;
        // window.console.log(`studentSequenceId ${studentSequenceId}`);
        this.$http
          .patch(`/agenda/student_sequence/${studentSequenceId}/`, {
            position: this.periodsArray[indexPeriod].sequences[i].sequence_position,
          })
          .then(() => {
            this.$buefy.notification.open('parcours enregistré');
          }).catch((error) => {
            window.console.log(error);
            this.errored = true;
            this.$buefy.dialog.alert({
              title: 'Mince...',
              message: 'Le parcours n\'a pas pu être enregistré ',
              type: 'is-info',
              hasIcon: true,
              icon: 'times-circle',
              ariaRole: 'alertdialog',
              ariaModal: true,
              confirmText: 'Ok',
            });
          });
      }
    },
    populatePeriods() {
      // to populate the array "periods"
      this.periodsArray = this.training.periods;
    },

    removeSequence(index, idSequence) {
      // change status
      this.$buefy.dialog.confirm({
        title: 'Désactiver une expérience',
        message: 'Êtes-vous sûr de vouloir désactiver cette expérience ? Pas de panique, il ne s\'agit pas d\'une suppression, vous pourrez ainsi la réactiver à tout moment.',
        confirmText: 'Désactiver',
        cancelText: 'Annuler',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.$http
            .patch(`/agenda/student_sequence/${idSequence}/`, {
              status: 5,
            }).then(() => {
              this.initStudentTrainingDetail();
              this.$buefy.toast.open({ message: 'Expérience désactivée!', type: 'is-success' });
            }).catch((error) => {
              window.console.log(error);
              this.errored = true;
              this.$buefy.dialog.alert({
                title: 'Mince...',
                message: 'La expérience n\'a pas pu être désactivée',
                type: 'is-info',
                hasIcon: true,
                icon: 'times-circle',
                ariaRole: 'alertdialog',
                ariaModal: true,
                confirmText: 'Ok',
              });
            });
        },
      });
      // window.console.log(`${index}${idSequence}`);
      // this.$http
      //   .patch(`/agenda/student_sequence/${idSequence}/`, {
      //     status: 5,
      //   }).then(() => {
      //     this.initStudentTrainingDetail();
      //   }).catch((error) => {
      //     window.console.log(error);
      //     this.errored = true;
      //   });
    },
    reactivateSequence(index, idSequence) {
      // change status to 0
      this.$http
        .patch(`/agenda/student_sequence/${idSequence}/`, {
          status: 0,
        }).then(() => {
          this.initStudentTrainingDetail();
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
          this.$buefy.dialog.alert({
            title: 'Mince...',
            message: 'L\'expérience n\'a pas pu être réactivée',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
          });
        });
    },
    refreshOrderSequences(indexPeriod) {
      this.periodsArray = this.studenttrainingdetail.periods;
      const arraySequences = this.studenttrainingdetail.periods[indexPeriod].sequences;
      for (let i = 0; i < arraySequences.length; i += 1) {
        const newIndex = Number(i) + 1; // forces the key value to become a number
        arraySequences[i].sequence_position = newIndex;
      }
      return JSON.stringify(this.value, null, 2);
    },
    // order periods
    orderPeriod(array) {
      return _.orderBy(array, 'position');
    },
    // order sequences
    order(array) {
      return _.orderBy(array, 'sequence_position');
    },
    // order student sequences
    order2(array) {
      return _.orderBy(array, 'student_sequence_position');
    },
    onEndDrag(indexPeriod) {
      // when the drag is ended it save on backend position changes
      this.editTrainingSteps(indexPeriod);
    },
    // seeSequencedetail(param) {
    //   this.$router.push({
    //     name: 'StudentSequenceDetailEdit',
    //     params: {
    //       idSequence: param,
    //       idTraining: this.$route.params.idTraining,
    //       idStudent: this.$route.params.idStudent,
    //     },
    //   });
    // },
    seeSequencedetail(param) {
      // function to see the sequence page
      this.$router.push({
        name: 'StudentActivityDetailEdit',
        params: {
          stepNumber: this.step,
          idSequence: param,
          idTraining: this.$route.params.idTraining,
          idStudent: this.$route.params.idStudent,
          idStudentTraining: this.$route.params.idStudentTraining,
          idActivity: 1,
          from: 'StudentTrainingEdit',
        },
      });
    },
    startLoading() {
      this.isLoading = true;
    },
    endLoading() {
      this.isLoading = false;
    },
    collapseBlock(id, id2) {
      const element = document.getElementById(id);
      element.classList.toggle('blockOpen');
      const element2 = document.getElementById(id2);
      element2.classList.toggle('cardClosed');
    },
    initResources() {
      this.startLoading();
      this.$http.get(`/get_resources/?student_training=${this.$route.params.idStudentTraining}&format=json`).then((response) => {
        this.resources = response.data.resources;
        this.endLoading();
      });
    },
    forceRerender() {
      // Every time that forceRerender is called, our prop componentKey will change.
      this.resourceKey += 1;
    },
    forceRerenderPlanning() {
      this.planningKey += 1;
    },
    forceRerenderPlanningLate() {
      this.planningLateKey += 1;
    },
    initDates(step) {
      let wsUrl = '';
      if (step === 'agenda') { // agenda
        wsUrl = `/agenda/planning/?prev=true&student_training=${this.$route.params.idStudentTraining}&format=json`;
      } else if (step === 'todo') { // to do even if it's passed
        wsUrl = `/agenda/planning/?status=1&state=inprogress&student_training=${this.$route.params.idStudentTraining}&format=json`;
      } else if (step === 'late') { // activities late
        wsUrl = `/agenda/planning/?prev=1&state=late&student_training=${this.$route.params.idStudentTraining}&format=json`;
      } else if (step === 'done') { // activities done
        wsUrl = `/agenda/planning/?prev=true&status=3&student_training=${this.$route.params.idStudentTraining}&format=json`;
      } else if (step === 'next') { // activities done
        wsUrl = `/agenda/planning/?state=future&student_training=${this.$route.params.idStudentTraining}&format=json`;
      }
      this.startLoading();
      this.$http
        .get(wsUrl)
        .then((response) => {
          // this.endLoading();
          this.dates = response.data;
          this.forceRerenderPlanning();
          this.endLoading();
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
          this.$buefy.dialog.confirm({
            title: 'Mince...',
            message: 'Une information n\'a pas pu être totalement chargée, nous vous invitons à recharger la page.',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
            onConfirm: () => document.location.reload(),
          });
        });
    },
    exportLivret() {
      this.$buefy.dialog.confirm({
        title: 'Exporter le livret',
        message: 'Vous pouvez exporter le livret d\'apprentissage au format PDF',
        confirmText: 'Exporter',
        cancelText: 'Annuler',
        type: 'is-info',
        onConfirm: () => {
          this.$http
            .get(`export_livret/?training_id=${this.$route.params.idTraining}&student_id=${this.$route.params.idStudent}`, {
              responseType: 'blob',
            }).then((response) => {
              const username = (this.studenttrainingdetail.student_name).toLowerCase();
              const usernameReplaced = username.replace(' ', '_');
              const fileURL = window.URL.createObjectURL(new Blob([response.data]));
              const fileLink = document.createElement('a');
              fileLink.href = fileURL;
              fileLink.setAttribute('download', `insitu_livret_${usernameReplaced}.pdf`);
              document.body.appendChild(fileLink);
              fileLink.click();
            });
        },
      });
    },
  },
  created() {
    this.getTrainingRights();
    this.initStudentTrainingDetail();
    if (this.$route.params.stepNumber) {
      this.gotoStep(this.$route.params.stepNumber);
    } else if (this.$route.params.stepNumber === 'resources') {
      this.gotoResources();
    } else { this.gotoStep(this.tabs[0].slug); }
    this.today = moment().format();
  },
};
</script>

<style scoped>
.activated5 {
  display: flex !important;
}
</style>
