<template>
  <div class="">
    <!-- <div class="helpUser mt-0">
      <div class="title">Ajoutez des compétences</div>
      <div>
        Ici vous pouvez ajouter des compétences à cette formation. Une fois
        renseignées ici vous pourrez les associer à des expériences du parcours.
      </div>
    </div> -->
    <div class="title-paragraph title2 mb-5">
      Attacher la maquette à un référentiel de compétences
    </div>
    <div class="subtitle-paragraph">
      Attachez à un référentiel pour importer les compétences et tâches de ce
      dernier
    </div>
    <b-select
      placeholder="Choisissez un référentiel"
      class="mb-3"
      v-model="referentialSelected"
      @input="noreferencial"
    >
      <option selected :value="null">Choisissez un référentiel</option>
      <option
        v-for="referential in referentialList"
        :value="referential.id"
        :key="referential.id"
      >
        {{ referential.name }}
      </option>
      <option selected :value="null">Aucun</option>
    </b-select>

    <b-button @click="importSkills" type="is-primary" rounded class="mt-4"
      >importer</b-button
    >
    <div class="levels mb-5 mt-5 pt-5">
      <div class="title-paragraph title2 mb-5">
        Déterminez les niveaux d'acquisition des compétences
      </div>
      <b-message type="is-grey" v-if="!skillsLevels.length"
        >Aucun niveau n'a été renseigné</b-message
      >
      <draggable
        v-model="skillsLevels"
        class="w-100"
        :value="skillsLevels"
        @end="onEndDragLevels"
        handle=".handle"
      >
        <div class="card-header" v-for="level of skillsLevels" :key="level.id">
          <div class="bg-card is-flex bg-primary align-items-center">
            <b-icon icon="drag" size="is-medium" type="is-black" class="handle">
            </b-icon>
            <p class="card-header-title pr-3">
              <textarea
                class="textarea is-small"
                placeholder="Inscrivez le nom du niveau d'acquisition"
                rows="1"
                v-model="level.name"
                @input="onChangeinputLevel(level.id, level.name)"
              ></textarea>
            </p>
            <a class="actions-icon" @click="deleteLevel(level.id)">
              <b-icon icon="close" size="is-medium" type="is-danger"> </b-icon>
            </a>
          </div>
        </div>
      </draggable>
      <div class="card-header" v-if="addLevelInput === true">
        <div class="bg-card is-flex bg-primary align-items-center">
          <b-icon icon="drag" size="is-medium" type="is-black" class="handle">
          </b-icon>
          <p class="card-header-title pr-3">
            <textarea
              class="textarea is-small"
              placeholder="Inscrivez le nom du niveau d'acquisition"
              rows="1"
              v-model="formlevel.name"
            ></textarea>
          </p>
          <b-button
            type="is-primary is-shadowed is-small mr-1"
            size=""
            rounded
            @click.prevent="addlevel"
            >enregistrer</b-button
          >
        </div>
      </div>
      <b-button
        @click="addLevelInput = !addLevelInput"
        class="mb-5"
        type="is-primary"
        rounded
      >
        <span v-if="!addLevelInput">ajouter</span
        ><span v-else @click="formlevel.name = null">fermer</span>
      </b-button>
    </div>
    <div class="title-paragraph title2">
      Déterminez les compétences ou critères évalués lors de la formation
    </div>
    <div class="is-flex align-items-center">
      <div v-if="skillsList.length" class="mb-4 mt-4 pb-4">
        <b-button
          rounded
          @click="collapseAll(true)"
          size="is-small"
          class="mr-2"
          >ouvrir tout</b-button
        >
        <b-button rounded size="is-small" @click="collapseAll(false)"
          >fermer tout</b-button
        >
      </div>
    </div>
    <draggable
      v-if="skillsList"
      v-model="skillsList"
      class="w-100"
      :value="skillsList"
      @end="onEndDrag"
      handle=".handle"
    >
      <div
        animation="slide"
        v-for="(block, indexBlock) of order(skillsList)"
        :key="indexBlock"
      >
        <div class="card-header" role="button">
          <div class="bg-card is-flex bg-primary align-items-center">
            <a
              class="card-header-icon"
              @click="collapseBlock('a' + indexBlock, 'icona' + indexBlock)"
            >
              <!-- <b-icon :icon="props.open ? 'menu-down' : 'menu-up'"> </b-icon> -->
              <div class="icon-collapse" :id="'icona' + indexBlock"></div>
            </a>
            <b-icon icon="drag" size="is-medium" type="is-black" class="handle">
            </b-icon>
            <p class="card-header-title pr-3">
              <textarea
                class="textarea is-small"
                placeholder="Inscrivez le nom du bloc de compétences"
                rows="1"
                v-model="block.name"
                @input="onChangeinputBlock(indexBlock, block.id)"
              ></textarea>
            </p>
            <div class="actions is-flex align-items-center">
              <b-button
                type="is-primary is-shadowed is-small mr-1"
                size=""
                rounded
                @click.prevent="seeBlockdetail(block)"
                >rédiger</b-button
              >
              <a class="actions-icon" @click="deleteBlock(block.id)">
                <b-icon icon="close" size="is-medium" type="is-danger">
                </b-icon>
              </a>
            </div>
          </div>
        </div>
        <div class="card-content cardClosed" :id="'a' + indexBlock">
          <div v-if="block.blocks">
            <div
              animation="slide"
              v-for="(block2, indexBlock2) of order(block.blocks)"
              :key="indexBlock2"
              class="ml-2"
            >
              <!-- card heaedr 2-->
              <div class="card-header" role="button">
                <div class="bg-card is-flex bg-primary align-items-center">
                  <a
                    class="card-header-icon"
                    @click="
                      collapseBlock(
                        'b' + indexBlock2 + indexBlock,
                        'iconb' + indexBlock2 + indexBlock
                      )
                    "
                  >
                    <div
                      class="icon-collapse"
                      :id="'iconb' + indexBlock2 + indexBlock"
                    ></div>
                  </a>
                  <b-icon
                    icon="drag"
                    size="is-medium"
                    type="is-black"
                    class="handle"
                  >
                  </b-icon>
                  <p class="card-header-title pr-3">
                    <textarea
                      class="textarea is-small"
                      placeholder="Inscrivez le nom du bloc de compétences"
                      rows="1"
                      v-model="block2.name"
                      @input="onChangeinputBlock(indexBlock2, block2.id)"
                    ></textarea>
                  </p>
                  <div class="actions is-flex align-items-center">
                    <b-button
                      type="is-primary is-shadowed is-small mr-1"
                      size=""
                      rounded
                      @click.prevent="seeBlockdetail(block2)"
                      >rédiger</b-button
                    >
                    <a class="actions-icon" @click="deleteBlock(block2.id)">
                      <b-icon icon="close" size="is-medium" type="is-danger">
                      </b-icon>
                    </a>
                  </div>
                </div>
              </div>
              <!-- card content 2-->
              <div
                class="card-content cardClosed"
                :id="'b' + indexBlock2 + indexBlock"
              >
                <div v-if="block2.blocks">
                  <div
                    animation="slide"
                    v-for="(block3, indexBlock3) of order(block2.blocks)"
                    :key="indexBlock3"
                    style="margin-left: 1rem"
                  >
                    <!-- card heaedr 3-->
                    <div class="card-header" role="button">
                      <div
                        class="bg-card is-flex bg-primary align-items-center"
                      >
                        <a
                          class="card-header-icon"
                          @click="
                            collapseBlock(
                              'c' + indexBlock + indexBlock2 + indexBlock3,
                              'iconc' + indexBlock + indexBlock2 + indexBlock3
                            )
                          "
                        >
                          <div
                            class="icon-collapse"
                            :id="
                              'iconc' + indexBlock + indexBlock2 + indexBlock3
                            "
                          ></div>
                        </a>
                        <b-icon
                          icon="drag"
                          size="is-medium"
                          type="is-black"
                          class="handle"
                        >
                        </b-icon>
                        <p class="card-header-title pr-3">
                          <textarea
                            class="textarea is-small"
                            placeholder="Inscrivez le nom du bloc de compétences"
                            rows="1"
                            v-model="block3.name"
                            @input="onChangeinputBlock(indexBlock3, block3.id)"
                          ></textarea>
                        </p>
                        <div class="actions is-flex align-items-center">
                          <b-button
                            type="is-primary is-shadowed is-small mr-1"
                            size=""
                            rounded
                            @click.prevent="seeBlockdetail(block3)"
                            >rédiger</b-button
                          >
                          <a
                            class="actions-icon"
                            @click="deleteBlock(block3.id)"
                          >
                            <b-icon
                              icon="close"
                              size="is-medium"
                              type="is-danger"
                            >
                            </b-icon>
                          </a>
                        </div>
                      </div>
                    </div>
                    <!-- card content 2-->
                    <div
                      class="card-content cardClosed"
                      :id="'c' + indexBlock + indexBlock2 + indexBlock3"
                    >
                      <div v-if="block3.blocks">
                        <div
                          animation="slide"
                          v-for="(block4, indexBlock4) of order(block3.blocks)"
                          :key="indexBlock4"
                          classe="ml-2"
                        >
                          {{ block4.name }} {{ "d" + indexBlock4 + indexBlock }}
                        </div>
                      </div>
                      <draggable
                        :list="block3.skills"
                        :group="{ name: 'block' }"
                        handle=".handle"
                        @end="onEndDrag"
                        class="card-padding"
                        :id="'content' + indexBlock3"
                        v-else
                      >
                        <div
                          class="
                            card-edit-sequence
                            is-flex
                            align-items-center
                            w-100
                          "
                          v-for="(skill, index) of block3.skills"
                          :key="index"
                          :keyblock="skill.block"
                        >
                          <div
                            class="
                              bloc-left
                              is-flex
                              mr-auto
                              align-items-center
                              w-100
                              pr-3
                            "
                          >
                            <div class="is-flex bloc-handle">
                              <b-icon
                                icon="drag"
                                size="is-medium"
                                type="is-primary"
                                class="handle"
                              >
                              </b-icon>
                            </div>
                            <div class="w-100">
                              <textarea
                                class="textarea is-small"
                                placeholder="Inscrivez le nom de la compétence"
                                rows="1"
                                v-model="skill.name"
                                @input="
                                  onChangeinput(index, skill.id, indexBlock4)
                                "
                              ></textarea>
                            </div>
                          </div>
                          <div class="actions is-flex align-items-center">
                            <div
                              class="actions-buttons is-flex align-items-center"
                            >
                              <b-icon
                                icon="check"
                                size="is-medium"
                                type="is-success"
                                v-if="skill.checked == 1"
                              >
                              </b-icon>
                              <b-icon
                                icon="check"
                                size="is-medium"
                                type="is-grey"
                                v-else
                              >
                              </b-icon>
                              <b-button
                                type="is-primary is-shadowed is-small mr-1"
                                size=""
                                rounded
                                @click.prevent="seeSkilldetail(skill, false)"
                                >rédiger</b-button
                              >
                              <a
                                class="actions-icon"
                                @click.prevent="removeSkill(skill.id)"
                              >
                                <b-icon
                                  icon="close"
                                  size="is-medium"
                                  type="is-danger"
                                >
                                </b-icon>
                              </a>
                            </div>
                          </div>
                        </div>
                      </draggable>
                      <!-- <div class="has-text-centered">
                        <b-button
                          type="is-primary is-rounded"
                          @click="
                            newSkilldetail(
                              block3.id,
                              true,
                              block3.skills.length
                            )
                          "
                          >ajouter une compétence à évaluer</b-button
                        >
                      </div> -->
                    </div>
                  </div>
                </div>
                <draggable
                  :list="block2.skills"
                  :group="{ name: 'block' }"
                  handle=".handle"
                  @end="onEndDrag"
                  class="card-padding"
                  :id="'content' + indexBlock2"
                  v-else
                >
                  <div
                    class="card-edit-sequence is-flex align-items-center w-100"
                    v-for="(skill, index) of block2.skills"
                    :key="index"
                    :keyblock="skill.block"
                  >
                    <div
                      class="
                        bloc-left
                        is-flex
                        mr-auto
                        align-items-center
                        w-100
                        pr-3
                      "
                    >
                      <div class="is-flex bloc-handle">
                        <b-icon
                          icon="drag"
                          size="is-medium"
                          type="is-primary"
                          class="handle"
                        >
                        </b-icon>
                        <!-- <span v-else class="ml-2"></span> -->
                      </div>
                      <div class="w-100">
                        <textarea
                          class="textarea is-small"
                          placeholder="Inscrivez le nom de la compétence"
                          rows="1"
                          v-model="skill.name"
                          @input="onChangeinput(index, skill.id, indexBlock2)"
                        ></textarea>
                      </div>
                    </div>
                    <div class="actions is-flex align-items-center">
                      <div class="actions-buttons is-flex align-items-center">
                        <b-icon
                          icon="check"
                          size="is-medium"
                          type="is-success"
                          v-if="skill.checked == 1"
                        >
                        </b-icon>
                        <b-icon
                          icon="check"
                          size="is-medium"
                          type="is-grey"
                          v-else
                        >
                        </b-icon>
                        <b-button
                          type="is-primary is-shadowed is-small mr-1"
                          size=""
                          rounded
                          @click.prevent="seeSkilldetail(skill, false)"
                          >rédiger</b-button
                        >
                        <a
                          class="actions-icon"
                          @click.prevent="removeSkill(skill.id)"
                        >
                          <b-icon
                            icon="close"
                            size="is-medium"
                            type="is-danger"
                          >
                          </b-icon>
                        </a>
                      </div>
                    </div>
                  </div>
                </draggable>
                <!-- <div class="has-text-centered">
                  <b-button
                    type="is-primary is-rounded"
                    @click="
                      newSkilldetail(block2.id, true, block2.skills.length)
                    "
                    >ajouter une compétence à évaluer</b-button
                  >
                </div> -->
              </div>
            </div>
          </div>
          <draggable
            v-else
            :list="block.skills"
            :group="{ name: 'block' }"
            handle=".handle"
            @end="onEndDrag"
            class="card-padding"
            :id="'content' + indexBlock"
          >
            <div
              class="card-edit-sequence is-flex align-items-center w-100"
              v-for="(skill, index) of block.skills"
              :key="index"
              :keyblock="skill.block"
            >
              <div
                class="bloc-left is-flex mr-auto align-items-center w-100 pr-3"
              >
                <div class="is-flex bloc-handle">
                  <b-icon
                    icon="drag"
                    size="is-medium"
                    type="is-primary"
                    class="handle"
                  >
                  </b-icon>
                  <!-- <span v-else class="ml-2"></span> -->
                </div>
                <div class="w-100">
                  <textarea
                    class="textarea is-small"
                    placeholder="Inscrivez le nom de la compétence"
                    rows="1"
                    v-model="skill.name"
                    @input="onChangeinput(index, skill.id, indexBlock)"
                  ></textarea>
                </div>
              </div>
              <div class="actions is-flex align-items-center">
                <div class="actions-buttons is-flex align-items-center">
                  <b-icon
                    icon="check"
                    size="is-medium"
                    type="is-success"
                    v-if="skill.checked === 1"
                  >
                  </b-icon>
                  <b-icon icon="check" size="is-medium" type="is-grey" v-else>
                  </b-icon>
                  <b-button
                    type="is-primary is-shadowed is-small mr-1"
                    size=""
                    rounded
                    @click.prevent="seeSkilldetail(skill, false)"
                    >rédiger</b-button
                  >
                  <a
                    class="actions-icon"
                    @click.prevent="removeSkill(skill.id)"
                  >
                    <b-icon icon="close" size="is-medium" type="is-danger">
                    </b-icon>
                  </a>
                </div>
              </div>
            </div>
          </draggable>
          <div class="has-text-centered">
            <b-button
              type="is-primary is-rounded"
              @click="newSkilldetail(block.id, true, block.skills.length)"
              >ajouter une compétence à évaluer</b-button
            >
          </div>
        </div>
      </div>
      <b-modal
        v-model="isSkillModalActive"
        has-modal-card
        trap-focus
        :destroy-on-hide="false"
        aria-role="dialog"
        aria-modal
      >
        <template>
          <div class="modal-card modal-users" style="width: auto">
            <section class="modal-card-body">
              <div class="has-text-right mb-3">
                <a @click="isSkillModalActive = false" class="ml-auto">
                  <b-icon icon="close" type="is-primary"></b-icon>
                </a>
              </div>
              <div class="has-text-small mb-2 mt-5">
                <b>Saisissez la compétence à ajouter</b>
              </div>
              <form>
                <b-field label="Nom">
                  <b-input
                    type="text"
                    v-model="formSkill.name"
                    placeholder="nom de la compétence"
                    max-length="140"
                  >
                  </b-input>
                </b-field>

                <b-field label="Description">
                  <b-input
                    type="textarea"
                    v-model="formSkill.description"
                    placeholder="description"
                    max-length="140"
                  >
                  </b-input>
                </b-field>
              </form>
              <b-button
                type="is-primary"
                v-if="isNew === true"
                @click="postSkill(formSkill.block, formSkill.position)"
                >Ajouter une compétence</b-button
              >
              <b-button
                type="is-primary"
                v-else
                @click="patchSkill(indexBlock, formSkill.id)"
                >modifier</b-button
              >
            </section>
          </div>
        </template>
      </b-modal>
      <b-modal
        v-model="isBlockModalActive"
        has-modal-card
        trap-focus
        :destroy-on-hide="false"
        aria-role="dialog"
        aria-modal
      >
        <template>
          <div
            class="modal-card modal-users has-text-centered"
            style="width: auto"
          >
            <section class="modal-card-body">
              <div class="has-text-right mb-3">
                <a @click="isBlockModalActive = false" class="ml-auto">
                  <b-icon icon="close" type="is-primary"></b-icon>
                </a>
              </div>
              <div class="has-text-small mb-2 mt-5">
                <b>Saisissez le bloc de compétence à ajouter</b>
              </div>
              <form>
                {{ formBlock.block }}
                <b-field label="Nom">
                  <b-input
                    type="text"
                    v-model="formBlock.name"
                    placeholder="nom de la compétence"
                    max-length="140"
                  >
                  </b-input>
                </b-field>
                <b-field label="Référence">
                  <b-input
                    type="text"
                    v-model="formBlock.reference"
                    placeholder="référence"
                    max-length="140"
                  >
                  </b-input>
                </b-field>
                <b-field label="Description">
                  <b-input
                    type="textarea"
                    v-model="formBlock.description"
                    placeholder="description"
                    max-length="140"
                  >
                  </b-input>
                </b-field>
              </form>
              <b-button
                type="is-primary"
                v-if="isNew === true"
                @click="addBlock()"
                >Ajouter un bloc de compétence</b-button
              >
              <b-button
                type="is-primary"
                v-else
                @click="patchBlock(formBlock.id)"
                >modifier</b-button
              >
            </section>
          </div>
        </template>
      </b-modal>
    </draggable>
    <a href="#endSkills">
      <b-button
        class="button btn btn-secondary is-primary mr-3 is-rounded mb-3 mt-4"
        @click="newBlockdetail()"
      >
        Ajouter un bloc de compétences
      </b-button></a
    >
    <div id="endSkills">&nbsp;</div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import _ from 'lodash';
// import { ValidationProvider, ValidationObserver } from 'vee-validate';
import draggable from 'vuedraggable';

export default {
  props: {
    typeoftraining: {
      type: String,
    }, // framework, training or studenttraining
    objectId: {
      type: [Number, String],
    },
  },
  components: {
    // ValidationProvider,
    // ValidationObserver,
    draggable,
  },
  filters: {
  },
  data() {
    return {
      isLoading: false,
      isFullPage: false,
      formSkill: {
        id: '',
        idBlock: '',
        blockIndex: '',
        name: '',
        description: '',
        block: '',
        reference: '',
        position: '',
      },
      formBlock: {
        id: '',
        name: '',
        description: '',
        block: '',
        reference: '',
      },
      skillsList: [],
      isOpenSkill: false,
      isOpen: false,
      isOpenAdd: false,
      isOpenBlock: false,
      isSkillModalActive: false,
      isBlockModalActive: false,
      isNew: true,
      myskillsList: [],
      skillsLevels: [],
      formlevel: {
        name: '',
      },
      addLevelInput: false,
      referentialList: [],
      referentialSelected: 'Choisissez un référentiel',
      referencialSequence: null,
    };
  },
  computed: {
    ...mapState('auth', ['currentUser']),
  },
  methods: {
    startLoading() {
      this.isLoading = true;
    },
    endLoading() {
      this.isLoading = false;
    },
    order(array) {
      return _.orderBy(array, 'position');
    },
    initSkills() {
      this.startLoading();
      let urlWs = '';
      if (this.typeoftraining === 'framework') {
        urlWs = `/frameworkskillsblock/?training_framework=${this.objectId}&format=json`;
      } else if (this.typeoftraining === 'training') {
        urlWs = `/trainingskillsblock/?training=${this.objectId}&format=json`;
      }
      this.$http
        .get(`${urlWs}`)
        .then((response) => {
          this.skillsList = this.order(response.data);
          //  pour une vue imbriquée des blocs de compétences
          //   this.skillsList = this.order(response.data.blocks);
          if (this.typeoftraining === 'framework') {
            this.skillsList = this.order(response.data);
          } else if (this.typeoftraining === 'training') {
            this.skillsList = this.order(response.data);
          }
          if (this.typeoftraining === 'training') {
            /* eslint-disable */
            this.skillsList = this.skillsList.map(item => {
              return { skills: item.tskills, name: item.name, reference: item.reference, id: item.id, training: item.training, description: item.description, position: item.position };
            });
            /* eslint-enable */
          }

          for (let i = 0; i < this.skillsList.length; i += 1) {
            const myBlocks = this.skillsList;
            // eslint-disable-next-line
            this.skillsList[i].skills = this.order(myBlocks[i].skills);
          }
          this.endLoading();
        });
    },
    postSkill(idBlock, positionSkill) {
      window.console.log(`idbloc ${idBlock}`);
      window.console.log(`position ${positionSkill}`);
      let urlWs = '';
      if (this.typeoftraining === 'framework') {
        urlWs = '/frameworkskills/';
      } else if (this.typeoftraining === 'training') {
        urlWs = '/trainingskills/';
      }
      this.$http
        .post(`${urlWs}`, {
          block: idBlock,
          name: this.formSkill.name,
          description: this.formSkill.description,
          reference: this.formSkill.reference,
          training_framework: this.$route.params.id,
          training: this.$route.params.idTraining,
          position: positionSkill,
        }).then(() => {
          this.initSkills();
          this.isSkillModalActive = false;
          // this.collapseBlock(idDiv);
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
        });
    },
    patchSkill(index, idSkill) {
      let urlWs = '';
      if (this.typeoftraining === 'framework') {
        urlWs = `/frameworkskills/${idSkill}/`;
      } else if (this.typeoftraining === 'training') {
        urlWs = `/trainingskills/${idSkill}/`;
      }
      window.console.log(urlWs);
      this.$http
        .patch(`${urlWs}`, {
          // block: this.formSkill.block,
          name: this.formSkill.name,
          description: this.formSkill.description,
        }).then(() => {
          this.initSkills();
          this.isSkillModalActive = false;
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
        });
    },
    openEdit(index) {
      if (this.isOpen !== index) {
        this.isOpen = index;
      } else {
        this.isOpen = false;
      }
    },
    // fonctions
    onChangeinputBlock(index, idBlock) {
      // change skills block name
      let urlWs = '';
      if (this.typeoftraining === 'framework') {
        urlWs = `/frameworkskillsblock/${idBlock}/`;
      } else if (this.typeoftraining === 'training') {
        urlWs = `/trainingskillsblock/${idBlock}/`;
      }
      this.$http
        .patch(`${urlWs}`, {
          name: this.skillsList[index].name,
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
          this.$buefy.dialog.alert({
            title: 'Mince...',
            message: 'Le titre ne peut être nul ou supérieur à 217 caractères.',
            type: 'is-grey',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
          });
        });
    },
    onChangeinput(index, idSkill, keyBlock) {
      // when input field is changed - need to verify this
      let urlWs = '';
      if (this.typeoftraining === 'framework') {
        urlWs = `/frameworkskills/${idSkill}/`;
      } else if (this.typeoftraining === 'training') {
        urlWs = `/trainingskills/${idSkill}/`;
      }
      this.$http
        .patch(`${urlWs}`, {
          name: this.skillsList[keyBlock].skills[index].name,
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
          this.$buefy.dialog.alert({
            title: 'Mince...',
            message: 'Le titre ne peut être nul ou supérieur à 217 caractères.',
            type: 'is-grey',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
          });
        });
    },
    deleteBlock(idBlock) {
      let urlWs = '';
      if (this.typeoftraining === 'framework') {
        urlWs = `/frameworkskillsblock/${idBlock}/`;
      } else if (this.typeoftraining === 'training') {
        urlWs = `/trainingskillsblock/${idBlock}/`;
      }
      this.$buefy.dialog.confirm({
        title: 'Supprimer un bloc de compétence',
        message: 'Êtes-vous sûr de vouloir supprimer ce bloc ? Attention, vous perdrez tout le contenu que vous avez pu rédiger pour ce bloc et toutes les évaluations associées.',
        confirmText: 'Supprimer',
        cancelText: 'Annuler',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.$http
            .delete(`${urlWs}?format=json`)
            .then(() => {
              // this.initTrainingframework();
              this.initSkills();
              this.refreshOrder();
              // this.populatePeriods();
              this.$buefy.toast.open({ message: 'Bloc supprimé', type: 'is-success' });
            }).catch((error) => {
              window.console.log(error);
              this.errored = true;
              this.$buefy.dialog.alert({
                title: 'Mince...',
                message: 'Le bloc n\'a pas pu être supprimé.',
                type: 'is-grey',
                hasIcon: true,
                icon: 'times-circle',
                ariaRole: 'alertdialog',
                ariaModal: true,
                confirmText: 'Ok',
              });
            });
        },
      });
    },
    removeBlock(idBlock, blockTrainings) {
      // window.console.log(blockTrainings);
      const trainingArray = [];
      trainingArray.push(blockTrainings);
      const blocktoremove = trainingArray.indexOf(this.$route.params.idTraining);
      window.console.log(trainingArray);
      let urlWs = '';
      if (this.typeoftraining === 'framework') {
        urlWs = `/frameworkskillsblock/${idBlock}/`;
        blockTrainings.splice(this.$route.params.idTraining, 1);
        window.console.log(urlWs);
      } else if (this.typeoftraining === 'training') {
        urlWs = `/trainingskillsblock/${idBlock}/`;
        trainingArray.splice(blocktoremove);
        window.console.log(trainingArray);
      }
      this.$buefy.dialog.confirm({
        title: 'Supprimer un bloc de compétence',
        message: 'Êtes-vous sûr de vouloir supprimer ce bloc ? Attention, vous perdrez tout le contenu que vous avez pu rédiger pour ce bloc et toutes les évaluations associées.',
        confirmText: 'Supprimer',
        cancelText: 'Annuler',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.$http
            .patch(`${urlWs}`, {
              training: trainingArray,
            }).then(() => {
              this.initSkills();
              this.refreshOrder();
              this.$buefy.toast.open({ message: 'Bloc supprimé', type: 'is-success' });
            }).catch((error) => {
              window.console.log(error);
              this.errored = true;
              this.$buefy.dialog.alert({
                title: 'Mince...',
                message: 'Le bloc n\'a pas pu être supprimé.',
                type: 'is-grey',
                hasIcon: true,
                icon: 'times-circle',
                ariaRole: 'alertdialog',
                ariaModal: true,
                confirmText: 'Ok',
              });
            });
        },
      });
    },
    removeSkill(idSkill) {
      let urlWs = '';
      if (this.typeoftraining === 'framework') {
        urlWs = `/frameworkskills/${idSkill}/`;
      } else if (this.typeoftraining === 'training') {
        urlWs = `/trainingskills/${idSkill}/`;
      }
      // this line removes sequence (inde) in its period (keyperiod)
      this.$buefy.dialog.confirm({
        title: 'Supprimer une compétence',
        message: 'Êtes-vous sûr de vouloir supprimer cette compétence ? Attention, vous perdrez tout le contenu que vous avez pu rédiger pour cette expérience et ses activités.',
        confirmText: 'Supprimer',
        cancelText: 'Annuler',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.$http
            .delete(`${urlWs}?format=json`)
            .then(() => {
              // this.initTrainingframework();
              this.initSkills();
              this.refreshOrder();
              // this.populatePeriods();
              this.$buefy.toast.open({ message: 'Compétence supprimée', type: 'is-success' });
            }).catch((error) => {
              window.console.log(error);
              this.errored = true;
              this.$buefy.dialog.alert({
                title: 'Mince...',
                message: 'L\'expérience n\'a pas pu être supprimée.',
                type: 'is-grey',
                hasIcon: true,
                icon: 'times-circle',
                ariaRole: 'alertdialog',
                ariaModal: true,
                confirmText: 'Ok',
              });
            });
        },
      });
    },
    addBlock() {
      let urlWs = '';
      if (this.typeoftraining === 'framework') {
        urlWs = '/frameworkskillsblock/';
      } else if (this.typeoftraining === 'training') {
        urlWs = '/trainingskillsblock/';
      }
      this.initSkills();
      //   function to add period in the front and the back,
      this.$http
        .post(`${urlWs}`, {
          name: this.formBlock.name,
          training_framework: this.$route.params.id,
          training: this.$route.params.idTraining,
          description: this.formBlock.description,
          reference: this.formBlock.reference,
          position: this.skillsList.length + 1,
          skills: [
          ],
          tskills: [
          ],
        })
        .then(() => {
          this.initSkills();
          this.$buefy.toast.open({ message: 'Bloc ajouté', type: 'is-success' });
          this.isBlockModalActive = false;
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
          this.$buefy.dialog.alert({
            title: 'Mince...',
            message: 'Le bloc n\'a pas pu être ajouté.',
            type: 'is-grey',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
          });
        });
    },
    patchBlock(idBlock) {
      let urlWs = '';
      if (this.typeoftraining === 'framework') {
        urlWs = '/frameworkskillsblock/';
      } else if (this.typeoftraining === 'training') {
        urlWs = '/trainingskillsblock/';
      }
      this.initSkills();
      this.$http
        .patch(`${urlWs}${idBlock}/`, {
          name: this.formBlock.name,
          description: this.formBlock.description,
          reference: this.formBlock.reference,
        })
        .then(() => {
          this.initSkills();
          this.$buefy.toast.open({ message: 'Bloc modifié', type: 'is-success' });
          this.isBlockModalActive = false;
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
          this.$buefy.dialog.alert({
            title: 'Mince...',
            message: 'Le bloc n\'a pas pu être modifié',
            type: 'is-grey',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
          });
        });
    },
    onEndDrag() {
      this.editSkills();
    },
    editSkills() {
      this.refreshOrder();
      let urlWs = '';
      let urlWs2 = '';
      if (this.typeoftraining === 'framework') {
        urlWs = '/frameworkskillsblock/';
        urlWs2 = '/frameworkskills/';
      } else if (this.typeoftraining === 'training') {
        urlWs = '/trainingskillsblock/';
        urlWs2 = '/trainingskills/';
      }
      for (let i = 0; i < this.skillsList.length; i += 1) {
        this.$http
          .patch(`${urlWs}${this.skillsList[i].id}/`, {
            position: this.skillsList[i].position,
            name: this.skillsList[i].name,
          })
          .then(() => {
            // window.console.log('patch periode');
            // window.console.log(response.data);
            if (i === (this.skillsLevels.length - 1)) {
              this.$buefy.toast.open({ message: 'Compétences modifiées', type: 'is-success' });
            }
            for (let j = 0; j < this.skillsList[i].skills.length; j += 1) {
              this.$http
                .patch(`${urlWs2}${this.skillsList[i].skills[j].id}/`, {
                  block: this.skillsList[i].id,
                  position: this.skillsList[i].skills[j].position,
                  name: this.skillsList[i].skills[j].name,
                }).then(() => {
                  // window.console.log('patch sequence');
                  // window.console.log(response2.data);
                });
            }
          }).catch((error) => {
            window.console.log(error);
            this.errored = true;
            this.$buefy.dialog.alert({
              title: 'Mince...',
              message: 'Une erreur s\'est produite',
              type: 'is-grey',
              hasIcon: true,
              icon: 'times-circle',
              ariaRole: 'alertdialog',
              ariaModal: true,
              confirmText: 'Ok',
            });
          });
      }
      // this.$buefy.toast.open({ message: 'Parcours enregistré', type: 'is-success' });
    },
    refreshOrder() {
      // to calculate position of periods and all skills inside
      // this.skillsList = this.trainingframework.periods;
      const arraySkills = this.skillsList;
      let newIndex = 1; // takes the name of sequence pasted
      let newIndex2 = 2;
      let keySkill = 9;
      // eslint-disable-next-line
      Object.keys(this.skillsList).forEach(function (key) {
        newIndex = Number(key) + 1; // forces the key value to become a number
        arraySkills[key].position = newIndex;
        // takes the position from the position of the block
        // arraySkills[key].period = newIndex;
        // takes the number period from the position of the block
        keySkill = Number(key);
        // eslint-disable-next-line
        Object.keys(arraySkills[keySkill].skills).forEach(function (key) {
          // this loop takes the position of each sequence block
          newIndex2 = Number(key) + 1;
          arraySkills[keySkill].skills[key].position = newIndex2;
          arraySkills[keySkill].skills[key].block = arraySkills[keySkill].id;
        });
      });
      // this return good periods array
      // return JSON.stringify(this.value, null, 2);
    },
    // collapseBlock(id) {
    //   window.console.log(id);
    //   const element = document.getElementById(id);
    //   element.classList.toggle('blockOpen');
    //   this.formSkill.name = null;
    // },
    seeSkilldetail(skill, newValue) {
      this.isSkillModalActive = true;
      this.formSkill.id = skill.id;
      this.formSkill.name = skill.name;
      this.formSkill.description = skill.description;
      this.formSkill.reference = skill.reference;
      this.formSkill.block = skill.block;
      this.isNew = newValue;
    },
    newSkilldetail(idBlock, newValue, position) {
      window.console.log(`idbloc ${idBlock}`);
      this.isSkillModalActive = true;
      this.formSkill.name = null;
      this.formSkill.description = null;
      this.formSkill.reference = null;
      this.formSkill.block = null;
      this.formSkill.block = idBlock;
      this.formSkill.position = null;
      this.formSkill.position = position + 1;
      this.isNew = newValue;
    },
    seeBlockdetail(block) {
      this.isBlockModalActive = true;
      this.formBlock.id = block.id;
      this.formBlock.name = block.name;
      this.formBlock.description = block.description;
      this.formBlock.reference = block.reference;
      this.isNew = false;
    },
    newBlockdetail() {
      this.isBlockModalActive = true;
      this.formBlock.name = null;
      this.formBlock.description = null;
      this.formBlock.reference = null;
      this.isNew = true;
    },
    collapseBlock(id, icon) {
      const element = document.getElementById(id);
      // const elementContent = document.getElementById(idContent);
      element.classList.toggle('blockOpen');
      // if (element.style.height) {
      //   element.style.height = null;
      // } else {
      //   element.style.height = `${elementContent.offsetHeight + 70}px`;
      // }
      const iconOpen = document.getElementById(icon);
      iconOpen.classList.toggle('iconOpen');
    },
    collapseAll(allOpen) {
      for (let i = 0; i < this.skillsList.length; i += 1) {
        const element = document.getElementById(`a${i}`);
        const iconOpen = document.getElementById(`icona${i}`);
        if (allOpen) {
          if (!element.classList.contains('blockOpen')) { // jouvretout
            element.classList.add('blockOpen');
            iconOpen.classList.add('iconOpen');
          }
        } else if (!allOpen) {
          if (element.classList.contains('blockOpen')) {
            element.classList.remove('blockOpen');
            iconOpen.classList.remove('iconOpen');
            window.console.log('fermer tout');// fermertout
          }
        }
      }
    },
    initLevels() {
      let urlWs = '';
      if (this.typeoftraining === 'framework') {
        urlWs = `/frameworklevels/?training_framework=${this.objectId}&format=json`;
      } else if (this.typeoftraining === 'training') {
        urlWs = `/traininglevels/?training=${this.objectId}&format=json`;
      }
      this.$http
        .get(`${urlWs}`)
        .then((response) => {
          this.skillsLevels = this.order(response.data);
        });
    },
    refreshLevelOrder() {
      const arrayLevels = this.skillsLevels;
      let newIndex = 1; // takes the name of sequence pasted
      // eslint-disable-next-line
      Object.keys(arrayLevels).forEach(function (key) {
        // this loop takes the position of each sequence block
        newIndex = Number(key) + 1;
        arrayLevels[key].position = newIndex;
      });
    },
    onEndDragLevels() {
      this.refreshLevelOrder();
      let urlWs = '';
      if (this.typeoftraining === 'framework') {
        urlWs = '/frameworklevels/';
      } else if (this.typeoftraining === 'training') {
        urlWs = '/traininglevels/';
      }
      for (let i = 0; i < this.skillsLevels.length; i += 1) {
        this.$http
          .patch(`${urlWs}${this.skillsLevels[i].id}/`, {
            position: this.skillsLevels[i].position,
          })
          .then(() => {
            if (i === (this.skillsLevels.length - 1)) {
              this.$buefy.toast.open({ message: 'Niveaux modifiés', type: 'is-success' });
            }
          }).catch((error) => {
            window.console.log(error);
            this.errored = true;
            this.$buefy.dialog.alert({
              title: 'Mince...',
              message: 'Une erreur s\'est produite',
              type: 'is-grey',
              hasIcon: true,
              icon: 'times-circle',
              ariaRole: 'alertdialog',
              ariaModal: true,
              confirmText: 'Ok',
            });
          });
      }
    },
    addlevel() {
      this.addLevelInput = false;
      let urlWs = '';
      if (this.typeoftraining === 'framework') {
        urlWs = '/frameworklevels/';
      } else if (this.typeoftraining === 'training') {
        urlWs = '/traininglevels/';
      }
      this.initSkills();
      //   function to add period in the front and the back,
      this.$http
        .post(`${urlWs}`, {
          name: this.formlevel.name,
          training_framework: this.$route.params.id,
          training: this.$route.params.idTraining,
          position: this.skillsLevels.length + 1,
        })
        .then(() => {
          this.initLevels();
          this.$buefy.toast.open({ message: 'Niveau ajouté', type: 'is-success' });
          this.formlevel.name = null;
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
          this.$buefy.dialog.alert({
            title: 'Mince...',
            message: 'Le niveau n\'a pas pu être ajouté.',
            type: 'is-grey',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
          });
        });
    },
    deleteLevel(idLevel) {
      let urlWs = '';
      if (this.typeoftraining === 'framework') {
        urlWs = `/frameworklevels/${idLevel}/`;
      } else if (this.typeoftraining === 'training') {
        urlWs = `/traininglevels/${idLevel}/`;
      }
      this.initSkills();
      //   function to add period in the front and the back,
      // this line removes sequence (inde) in its period (keyperiod)
      this.$buefy.dialog.confirm({
        title: 'Supprimer un niveau d\'acquisition',
        message: 'Êtes-vous sûr de vouloir supprimer ce niveau d\'acquisition ? Attention, si la formation a commencé vous risquez de perdre les évaluations enregistrées',
        confirmText: 'Supprimer',
        cancelText: 'Annuler',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.$http
            .delete(`${urlWs}?format=json`)
            .then(() => {
              // this.initTrainingframework();
              this.initLevels();
              // this.populatePeriods();
              this.$buefy.toast.open({ message: 'Compétence supprimée', type: 'is-success' });
            }).catch((error) => {
              window.console.log(error);
              this.errored = true;
              this.$buefy.dialog.alert({
                title: 'Mince...',
                message: 'Le niveau n\'a pas pu être supprimé.',
                type: 'is-grey',
                hasIcon: true,
                icon: 'times-circle',
                ariaRole: 'alertdialog',
                ariaModal: true,
                confirmText: 'Ok',
              });
            });
        },
      });
    },
    onChangeinputLevel(id, levelname) {
      let urlWs = '';
      if (this.typeoftraining === 'framework') {
        urlWs = `/frameworklevels/${id}/`;
      } else if (this.typeoftraining === 'training') {
        urlWs = `/traininglevels/${id}/`;
      }
      this.$http
        .patch(`${urlWs}`, {
          name: levelname,
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
          this.$buefy.dialog.alert({
            title: 'Mince...',
            message: 'Le titre ne peut être nul ou supérieur à 217 caractères.',
            type: 'is-grey',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
          });
        });
    },
    initReferential() {
      this.$http
        .get('/referencials/referencial/?format=json')
        .then((response) => {
          this.referentialList = response.data;
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
        });
    },
    importSkills() {
      let urlWs = '';
      let trainingId = null;
      if (this.typeoftraining === 'framework') {
        urlWs = `/training_frameworks/${this.objectId}/`;
      } else if (this.typeoftraining === 'training') {
        urlWs = `/trainings/${this.objectId}/`;
        trainingId = this.objectId;
      }
      this.$http
        .post('/import_referencial_skills/', {
          referencial: this.referentialSelected,
          framework: this.$route.params.id,
          training: trainingId,
        })
        .then(() => {
          this.$buefy.toast.open({ message: 'compétences associées', type: 'is-success' });
          this.initSkills();
          this.$http
            .patch(`${urlWs}`, {
              referencial: this.referentialSelected,
            })
            .then(() => {
              this.$buefy.toast.open({ message: 'référentiel associé', type: 'is-success' });
            }).catch((error) => {
              window.console.log(error);
              this.errored = true;
            });
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
          this.$buefy.dialog.alert({
            title: 'Mince...',
            message: 'Le bloc n\'a pas pu être ajouté.',
            type: 'is-grey',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
          });
        });
    },
    initSequenceReferencial() {
      let urlWs = '';
      if (this.typeoftraining === 'framework') {
        urlWs = 'training_frameworks';
      } else if (this.typeoftraining === 'training') {
        urlWs = 'trainings';
      }
      this.$http
        .get(`/${urlWs}/${this.objectId}/?query={referencial}&format=json`)
        .then((response) => {
          if (response.data.detail === 'Not found.') {
            this.referentialSelected = null;
          } else {
            this.referentialSelected = response.data.referencial;
          }
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
        });
    },
    noreferencial() {
      window.console.log('no referencial');
      window.console.log(this.referentialSelected);
      let urlWs = '';
      if (this.typeoftraining === 'framework') {
        urlWs = `/training_frameworks/${this.objectId}/`;
      } else if (this.typeoftraining === 'training') {
        urlWs = `/trainings/${this.objectId}/`;
      }
      if (!this.referentialSelected) {
        this.$buefy.dialog.confirm({
          title: 'Voulez-vous détacher la formation de tout référentiel ?',
          message: 'Êtes-vous sûr de vouloir de supprimer le lien au référentiel associé',
          confirmText: 'Supprimer',
          cancelText: 'Annuler',
          type: 'is-danger',
          hasIcon: true,
          onConfirm: () => {
            this.$http
              .patch(`${urlWs}`, {
                referencial: this.referentialSelected,
              })
              .then(() => {
                this.$buefy.toast.open({ message: 'référentiel associé', type: 'is-success' });
              }).catch((error) => {
                window.console.log(error);
                this.errored = true;
              });
          },
        });
      }
    },
  },
  created() {
    // this.skillsList = this.skills;
    this.initSkills();
    this.initLevels();
    this.initReferential();
    this.initSequenceReferencial();
  },
  mounted() {
  },
};
</script>

<style scoped>
.modal-card-body {
  border-radius: 5px !important;
  padding: 2rem !important;
}
.disabled {
  color: #dfdfeb;
}
.card-header-icon {
  width: auto;
  padding: 0px 0px 0px 0.5rem;
}
.card-header {
  margin-bottom: 0.5rem;
  transition: 0.2s;
}
/* .card-padding {
  padding: 1rem 0px;
} */
.closeButton {
  font-size: 1.5rem !important;
}
.modal-card {
  min-width: 627px;
}
/* .title2 {
  font-size: 1.5rem;
} */
@media (max-width: 640px) {
  .modal-card {
    min-width: unset;
    width: 100%;
  }
}
</style>
