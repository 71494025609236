<template>
  <div class="mt-5 pt-5">
    <b-loading :is-full-page="isFullPage" v-model="isLoading"> </b-loading>
    <div v-if="skillsBlock">
      <b-field class="w-100">
        <b-input
          placeholder="Rechercher..."
          type="search"
          icon="magnify"
          expanded
          v-model="search"
          @input="initSkills"
        >
        </b-input>
        <p class="control">
          <b-button
            type="is-primary"
            label="Rechercher"
            @click.prevent="initSkills"
          />
        </p>
      </b-field>
      <div v-if="skills.length">
        <div class="columns align-items-center mb-5 is-tablet">
          <div class="">
            <b-button
              rounded
              @click="isOpenPeriod = true"
              v-if="isOpenPeriod === false"
              size="is-small"
              >ouvrir tout</b-button
            >
            <b-button
              rounded
              @click="isOpenPeriod = false"
              v-if="isOpenPeriod === true"
              size="is-small"
              >fermer tout</b-button
            >
          </div>
          <div class="ml-auto">
            <b-button
              @click="
                allskills = !allskills;
                initSkills();
              "
              rounded
            >
              <span v-if="!allskills">Afficher toutes les compétences</span
              ><span v-else
                >Afficher les compétences évaluées uniquement</span
              ></b-button
            >
          </div>
        </div>
        <div v-if="skills.length && skillsTutor.length">
          <b-collapse
            class="card"
            animation="slide"
            v-for="(skillBlock, indexBlock) in order(skills)"
            :key="skillBlock.name"
            :open="isOpenPeriod"
          >
            <div
              slot="trigger"
              slot-scope="props"
              class="card-header"
              role="button"
              v-if="skillBlock.skills"
            >
              <div class="bg-card is-flex bg-primary align-items-center">
                <a class="card-header-icon">
                  <b-icon v-bind:icon="props.open ? 'menu-down' : 'menu-up'">
                  </b-icon>
                </a>
                <p class="card-header-title">{{ skillBlock.name }}</p>
              </div>
            </div>
            <div class="card-content container-eval">
              <!-- <div class="columns is-6 is-6-desktop is-hidden-mobile">
              <div
                class="column has-text-primary head-skills is-6 is-6-desktop"
              >
                <b>Compétences</b>
              </div>
              <div class="column has-text-primary head-skills">
                <b>Evaluation apprenant</b>
              </div>
              <div class="column has-text-primary head-skills">
                <b>Evaluation tuteur</b>
              </div>
              <div
                class="column has-text-primary head-skills"
                v-if="currentUser.role == 'tuteur'"
              ></div>
            </div> -->
              <div
                v-for="(skill, indexSkill) in order(skillBlock.skills)"
                :key="skill.name"
                :name="'question' + indexSkill"
              >
                <div
                  class="columns is-6 is-6-desktop is-hidden-mobile"
                  v-if="
                    (indexSkill === 0 && skill.level_checked !== '') ||
                    (indexSkill === 0 &&
                      skillsTutor[indexBlock].skills[indexSkill]
                        .level_checked !== '')
                  "
                >
                  <div
                    class="column has-text-primary head-skills is-6 is-6-desktop"
                  >
                    <b>Compétences</b>
                  </div>
                  <div class="column has-text-primary head-skills">
                    <b>Evaluation apprenant</b>
                  </div>
                  <div class="column has-text-primary head-skills">
                    <b>Evaluation tuteur</b>
                  </div>
                  <div
                    class="column is-narrow has-text-primary head-skills"
                    v-if="currentUser.role == 'tuteur'"
                  >
                    <b-button type="is-small" rounded style="opacity: 0"
                      >modifier</b-button
                    >
                  </div>
                </div>

                <!-- v-if="
                  skill.level_checked > 0 ||
                  skill.level_checked === 0 ||
                  skillsTutor[indexBlock].skills[indexSkill].level_checked >
                    0 ||
                  skill.level_checked === 0
                " -->
                <div
                  v-if="
                    allskills ||
                    skill.level_checked !== '' ||
                    skillsTutor[indexBlock].skills[indexSkill].level_checked !==
                      ''
                  "
                  class="columns is-vcentered justify-space-between mb-5 pb-2 skillsRead"
                >
                  <div class="column name-skill is-6 is-6-desktop pb-1">
                    <b>{{ skill.name }}</b>
                    <b-collapse
                      v-if="skill.description"
                      :open="false"
                      position="is-bottom"
                      :aria-id="'content' + indexSkill"
                      animation="slide"
                      class="collapse-description"
                    >
                      <template #trigger="props">
                        <a
                          aria-controls="contentIdForA11y4"
                          :aria-expanded="props.open"
                        >
                          <b-icon
                            class="mr-1"
                            :icon="!props.open ? 'menu-down' : 'menu-up'"
                          ></b-icon>
                          {{ !props.open ? "Critères observables" : "Fermer" }}
                        </a>
                      </template>
                      <p style="white-space: break-spaces">
                        {{ skill.description }}
                      </p>
                    </b-collapse>
                  </div>
                  <div
                    class="column has-text-primary head-skills is-hidden-tablet mt-3"
                  >
                    <b>Evaluation apprenant</b>
                  </div>
                  <div class="column pb-1 is-flex align-items-center">
                    <div
                      class="columns is-desktop levelsList is-vcentered pl-2"
                    >
                      <div
                        v-for="level in order(skill.levels)"
                        :key="level.id"
                        class="column level mr-5"
                      >
                        <b-icon
                          icon="check"
                          type="is-success"
                          v-if="level.evaluation.length"
                        ></b-icon>
                        <b-icon icon="close" class="disabled" v-else></b-icon>
                        <div class="level-label">{{ level.name }}</div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="column has-text-primary head-skills is-hidden-tablet"
                  >
                    <b>Evaluation tuteur</b>
                  </div>
                  <div
                    class="column pb-1 is-flex align-items-center"
                    v-if="skillsTutor[indexBlock]"
                  >
                    <div
                      class="columns is-desktop levelsList is-vcentered pl-2"
                      v-if="editSkill == `block${indexBlock}skill${indexSkill}`"
                    >
                      <div
                        v-for="(level2, index) in order(
                          skillsTutor[indexBlock].skills[indexSkill].levels
                        )"
                        :key="level2.id"
                        class="column level mr-5"
                      >
                        <b-radio
                          :key="level2.id"
                          v-model="
                            skillsTutor[indexBlock].skills[indexSkill]
                              .level_checked
                          "
                          :name="'question' + skillBlock.id + skill.id"
                          :native-value="index"
                          class="column level"
                          @input="patchEval(idChecked, level2.id, skill.id)"
                        >
                          {{ level2.name }}
                        </b-radio>
                      </div>
                    </div>
                    <div
                      class="columns is-desktop levelsList is-vcentered pl-2"
                      v-else
                    >
                      <div
                        v-for="level2 in order(
                          skillsTutor[indexBlock].skills[indexSkill].levels
                        )"
                        :key="level2.id"
                        class="column level mr-5"
                      >
                        <b-icon
                          icon="check"
                          type="is-success"
                          v-if="level2.evaluation.length"
                        ></b-icon>
                        <b-icon icon="close" class="disabled" v-else></b-icon>
                        <div class="level-label">{{ level2.name }}</div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="column is-narrow"
                    v-if="currentUser.role == 'tuteur'"
                  >
                    <b-button
                      v-if="editSkill == `block${indexBlock}skill${indexSkill}`"
                      type="is-small"
                      rounded
                      @click="editSkill = false"
                      >annuler</b-button
                    >
                    <span v-else>
                      <b-button
                        @click="
                          (editSkill = `block${indexBlock}skill${indexSkill}`) &&
                            (idChecked =
                              skillsTutor[indexBlock].skills[indexSkill].levels[
                                skillsTutor[indexBlock].skills[indexSkill]
                                  .level_checked
                              ].evaluation[0].id)
                        "
                        type="is-small"
                        rounded
                        v-if="!nomodify"
                        >modifier</b-button
                      ></span
                    >
                  </div>
                </div>

                <!-- <div v-else-if="countEmpty === 0">
                aucune compétences {{ count() }}
              </div>
              <div v-else>no</div> -->
              </div>
            </div>
          </b-collapse>
        </div>
      </div>
    </div>
    <div v-else>
      <div
        v-for="(skill, indexSkill) in order(skills)"
        :key="skill.name"
        :name="'question' + indexSkill"
      >
        <div
          class="columns is-6 is-6-desktop is-hidden-mobile"
          v-if="
            (indexSkill === 0 && skill.level_checked !== '') ||
            (indexSkill === 0 && skillsTutor.level_checked !== '')
          "
        >
          <div class="column has-text-primary head-skills is-6 is-6-desktop">
            <b>Compétences</b>
          </div>
          <div class="column has-text-primary head-skills">
            <b>Evaluation apprenant</b>
          </div>
          <div class="column has-text-primary head-skills">
            <b>Evaluation tuteur</b>
          </div>
          <div
            class="column is-narrow has-text-primary head-skills"
            v-if="currentUser.role == 'tuteur'"
          >
            <b-button type="is-small" rounded style="opacity: 0"
              >modifier</b-button
            >
          </div>
        </div>

        <!-- v-if="
                  skill.level_checked > 0 ||
                  skill.level_checked === 0 ||
                  skillsTutor[indexBlock].skills[indexSkill].level_checked >
                    0 ||
                  skill.level_checked === 0
                " -->
        <div
          v-if="
            allskills ||
            skill.level_checked !== '' ||
            skillsTutor.level_checked !== ''
          "
          class="columns is-vcentered justify-space-between mb-5 pb-2 skillsRead"
        >
          <div class="column name-skill is-6 is-6-desktop pb-1">
            <b>{{ skill.name }}</b>
            <b-collapse
              v-if="skill.description"
              :open="false"
              position="is-bottom"
              :aria-id="'content' + indexSkill"
              animation="slide"
              class="collapse-description"
            >
              <template #trigger="props">
                <a
                  aria-controls="contentIdForA11y4"
                  :aria-expanded="props.open"
                >
                  <b-icon
                    class="mr-1"
                    :icon="!props.open ? 'menu-down' : 'menu-up'"
                  ></b-icon>
                  {{ !props.open ? "Critères observables" : "Fermer" }}
                </a>
              </template>
              <p style="white-space: break-spaces">
                {{ skill.description }}
              </p>
            </b-collapse>
          </div>
          <div class="column pb-1 is-flex align-items-center">
            <div class="columns is-desktop levelsList is-vcentered pl-2">
              <div
                v-for="level in order(skill.levels)"
                :key="level.id"
                class="column level mr-5"
              >
                <b-icon
                  icon="check"
                  type="is-success"
                  v-if="level.evaluation.length"
                ></b-icon>
                <b-icon icon="close" class="disabled" v-else></b-icon>
                <div class="level-label">{{ level.name }}</div>
              </div>
            </div>
          </div>
          <div
            class="column pb-1 is-flex align-items-center"
            v-if="skillsTutor[indexSkill]"
          >
            <div
              class="columns is-desktop levelsList is-vcentered pl-2"
              v-if="editSkill == `skill${indexSkill}`"
            >
              <div
                v-for="(level2, index) in order(skillsTutor[indexSkill].levels)"
                :key="level2.id"
                class="column level mr-5"
              >
                <b-radio
                  :key="level2.id"
                  v-model="skillsTutor[indexSkill].level_checked"
                  :name="'question' + skill.id"
                  :native-value="index"
                  class="column level"
                  @input="patchEval(idChecked, level2.id, skill.id)"
                >
                  {{ level2.name }}
                </b-radio>
              </div>
            </div>
            <div class="columns is-desktop levelsList is-vcentered pl-2" v-else>
              <div
                v-for="level2 in order(skillsTutor[indexSkill].levels)"
                :key="level2.id"
                class="column level mr-5"
              >
                <b-icon
                  icon="check"
                  type="is-success"
                  v-if="level2.evaluation.length"
                ></b-icon>
                <b-icon icon="close" class="disabled" v-else></b-icon>
                <div class="level-label">{{ level2.name }}</div>
              </div>
            </div>
          </div>
          <div class="column is-narrow" v-if="currentUser.role == 'tuteur'">
            <b-button
              v-if="editSkill == `skill${indexSkill}`"
              type="is-small"
              rounded
              @click="editSkill = false"
              >annuler</b-button
            >
            <span v-else>
              <b-button
                @click="
                  (editSkill = `skill${indexSkill}`) &&
                    (idChecked =
                      skillsTutor[indexSkill].levels[
                        skillsTutor[indexSkill].level_checked
                      ].evaluation[0].id)
                "
                type="is-small"
                rounded
                v-if="!nomodify"
                >modifier</b-button
              ></span
            >
          </div>
        </div>

        <!-- <div v-else-if="countEmpty === 0">
                aucune compétences {{ count() }}
              </div>
              <div v-else>no</div> -->
      </div>
      <!-- <b-message type="is-info">
        Aucune compétence n'est associée à cette formation, il n'y a donc aucune
        compétence à évaluer.
      </b-message> -->
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import _ from 'lodash';

export default {
  props: {
    role: {
      type: Array,
    },
    skillsLevels: {
      type: [Object, Array],
    },
    studentTraining: {
      type: Number,
    },
    studentSequence: {
      type: Number,
    },
    type: {
      type: String,
    },
    nomodify: {
      type: Boolean,
    },
  },
  components: {
  },
  filters: {
  },
  data() {
    return {
      isLoading: false,
      isFullPage: false,
      formSkills: {},
      skills: [],
      roleEvaluation: '',
      roleArray: [],
      isOpenPeriod: true,
      skillsTutor: [],
      editSkill: false,
      search: null,
      idChecked: null,
      countEmpty: 0,
      allskills: false,
      tutorstudent: null,
      skillsBlock: null,
    };
  },
  computed: {
    ...mapState('auth', ['currentUser']),
  },
  methods: {
    startLoading() {
      this.isLoading = true;
    },
    endLoading() {
      this.isLoading = false;
    },
    order(array) {
      return _.orderBy(array, 'position');
    },
    initSkills() {
      this.startLoading();
      let searchEntry = '';
      if (this.search) {
        searchEntry = `&search=${this.search}`;
      }
      let urlWs = '';
      let block = '';
      const evaluations = '';
      if (this.skillsBlock === true) {
        block = '&block=true';
      }
      // if (!this.allskills) {
      //   evaluations = '&evaluations';
      // }
      if (this.type === 'sequence') {
        urlWs = `/agenda/get_student_training_skills/?student_training=${this.$route.params.idStudentTraining}&role=apprenant&student_sequence=${this.$route.params.idSequence}${block}${searchEntry}${evaluations}`;
      }
      this.$http
        .get(`${urlWs}`)
        .then((response) => {
          this.skills = response.data;
          if (!this.skillsBlock) {
            if (this.skills.length) {
              this.skillsBlock = false;
            } else {
              this.skillsBlock = true;
              this.initSkills();
            }
          }
          this.initSkillsCompared();
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
        });
    },
    initSkillsCompared() {
      let role = '';
      if (this.tutorstudent) {
        role = '&role=tuteur,apprenant';
      } else {
        role = '&role=tuteur';
      }
      let block = '';
      let urlWs = '';
      if (this.skillsBlock === true) {
        block = '&block=true';
      }
      if (this.type === 'sequence') {
        urlWs = `/agenda/get_student_training_skills/?student_training=${this.$route.params.idStudentTraining}&student_sequence=${this.$route.params.idSequence}${block}&${role}`;
      }
      this.$http
        .get(`${urlWs}`)
        .then((response) => {
          this.skillsTutor = response.data;
          this.endLoading();
          this.editSkill = false;
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
        });
    },
    verifRole() {
      for (let i = 0; i < this.role.length; i += 1) {
        let writer = '';
        if (this.role[i].includes('w_')) {
          writer = this.role[i].replace('w_', '');
          this.roleArray.push(writer);
        }
        if (this.roleArray.includes('apprenant') && this.roleArray.includes('tuteur')) {
          this.roleEvaluation = 'tuteur,apprenant';
          this.tutorstudent = true;
          this.initSkills();
        } else if (i === (this.role.length - 1)) {
          const role = this.roleArray[0];
          this.roleEvaluation = role;
          this.initSkills();
        }
      }
    },
    patchEval(idEval, levelId, skillId) {
      if (idEval) {
        this.$http
          .patch(`agenda/student_training_skills_levels/${idEval}/`, {
            role: this.roleEvaluation,
            profile: this.currentUser.id,
            training_skill: skillId,
            training_level: levelId,
            student_training: Number(this.$route.params.idStudentTraining),
            student_sequence: this.$route.params.idSequence,
            student_activity: Number(this.$route.params.idActivity),
          }).then(() => {
            this.$buefy.toast.open({ message: 'Compétence enregistrée', type: 'is-success' });
            this.initSkillsCompared();
          }).catch((error) => {
            window.console.log(error);
            this.errored = true;
          });
      } else {
        this.postEvaluation(levelId, skillId);
      }
    },
    postEvaluation(levelId, skillId) {
      this.$http
        .post('agenda/student_training_skills_levels/', {
          role: this.roleEvaluation,
          profile: this.currentUser.id,
          training_skill: skillId,
          training_level: levelId,
          student_training: Number(this.$route.params.idStudentTraining),
          student_sequence: this.$route.params.idSequence,
          student_activity: Number(this.$route.params.idActivity),
          free_situation: this.$route.params.idFree,
          student_activity_revision: Number(this.$route.params.idRevision),
        }).then(() => {
          this.$buefy.toast.open({ message: 'Compétence enregistrée', type: 'is-success' });
          this.initSkills();
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
        });
    },
    count() {
      window.console.log('count');
      this.countEmpty += 1;
    },
  },
  created() {
    if (this.role) {
      this.verifRole();
    } else {
      this.roleEvaluation = this.currentUser.role;
      this.initSkills();
    }
  },
  mounted() {
  },
};
</script>

<style scoped>
.disabled {
  color: #dfdfeb;
}
@media (max-width: 990px) .skillsEdit .level, .skillsRead .level {
  min-width: unset !important;
}
</style>
