var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"is-flex"},[_c('b-loading',{attrs:{"is-full-page":_vm.isFullPage},model:{value:(_vm.isLoading),callback:function ($$v) {_vm.isLoading=$$v},expression:"isLoading"}}),_c('AppSidebar'),_c('div',{staticClass:"main-content"},[_c('div',{staticClass:"lineCrumbAndAccount"},[_c('AppBreadcrumb',{attrs:{"crumbs":_vm.crumbs}}),_c('AppAccountMenu',{attrs:{"appAccount":_vm.crumbs}})],1),_vm._m(0),_c('div',{staticClass:"content-page pt-5"},[(_vm.errored)?_c('section',[(_vm.errored)?_c('b-message',{attrs:{"title":"Mince... Une erreur est survenue pendant le chargement des ressources","type":"is-danger","aria-close-label":"Close message"}},[_vm._v(" Nous sommes sincèrement désolés, nous ne sommes pas en mesure d'afficher ces ressouces pour le moment. Veuillez réessayer ultérieurement ou nous contacter via le support. ")]):_vm._e()],1):_vm._e(),_c('div',[(
            _vm.currentUser.role === 'formateur' ||
            _vm.currentUser.role === 'superviseur' ||
            _vm.currentUser.role === 'coordinateur'
          )?_c('div',[_vm._m(1),_c('div',{staticClass:"card_simple is-primary w-100 has-text-centered btn",on:{"click":function($event){_vm.isOpen = !_vm.isOpen}}},[_c('div',[_c('b-icon',{staticClass:"mr-3",attrs:{"icon":"resource","type":"is-primary"}}),_c('b',{staticStyle:{"cursor":"pointer"}},[_vm._v("Ajoutez une ressource")])],1)]),_c('b-collapse',{model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}},[_c('ResourceCreation',{key:_vm.resourceKey,attrs:{"sequence":null,"typeoftraining":null,"idTraining":null,"action":'post',"isOpen":_vm.isOpen},on:{"update:isOpen":function($event){_vm.isOpen=$event},"update:is-open":function($event){_vm.isOpen=$event},"messageFromChild":_vm.initResources,"messageFromResource":_vm.forceRerender}})],1),(_vm.resources)?_c('div',[(_vm.resources.length)?_c('div',_vm._l((_vm.resources),function(resource){return _c('div',{key:resource.id},[_c('ResourceCard',{attrs:{"resource":resource,"action":'edit',"isOpen":_vm.isOpen},on:{"update:isOpen":function($event){_vm.isOpen=$event},"update:is-open":function($event){_vm.isOpen=$event},"messageFromChild":_vm.initResources}})],1)}),0):_vm._e()]):_vm._e()],1):_c('div',[_vm._m(2),(_vm.resources)?_c('div',{staticClass:"ml-0 mr-0 columns events is-tablet is-multiline"},[(_vm.resources.length)?_c('div',{staticClass:"container"},_vm._l((_vm.resources),function(resource){return _c('div',{key:resource.id},[_c('ResourceCard',{attrs:{"resource":resource,"action":'edit'},on:{"messageFromChild":_vm.initResources}})],1)}),0):_vm._e()]):_vm._e()])])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header-page is-flex flex-column"},[_c('div',{staticClass:"justify-content-between columns lineHeaderTitle"},[_c('div',{staticClass:"column"},[_c('div',{staticClass:"title-page short-title"},[_vm._v("Ressources disponibles")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"helpUser mt-0"},[_c('div',{staticClass:"title"},[_vm._v("Ajoutez des ressources")]),_c('div',[_vm._v(" Ici vous pouvez ajouter des documents généraux, communs à toutes les formations. Pour des questions écologiques, le poids des fichiers est limité à 1 Mo. Si votre fichier est trop lourd, vous pouvez l'optimiser sur un site tel que "),_c('a',{attrs:{"href":"https://www.wecompress.com/fr/","target":"_blank"}},[_c('strong',[_vm._v("wecompress")])]),_vm._v(". ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"helpUser mt-0"},[_c('div',[_vm._v(" Ici vous pouvez trouver des documents généraux, communs à toutes les formations. ")])])
}]

export { render, staticRenderFns }