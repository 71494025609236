var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-loading',{attrs:{"is-full-page":_vm.isFullPage},model:{value:(_vm.isLoading),callback:function ($$v) {_vm.isLoading=$$v},expression:"isLoading"}}),(_vm.practicalWorks.length > 0)?_c('b-table',{ref:"table",attrs:{"data":_vm.practicalWorks,"paginated":"","per-page":"10","aria-next-label":"Next page","aria-previous-label":"Previous page","aria-page-label":"Page","aria-current-label":"Current page","mobile-cards":false}},[_c('b-table-column',{attrs:{"label":"Titre","width":"500"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('b',[_vm._v(" "+_vm._s(props.row.title))]),_vm._l((props.row.sessions),function(session){return _c('div',{key:session.id,on:{"click":function($event){return _vm.displayPracticalWorkDetail(
            props.row.id,
            session.id,
            session.students[0].student_training_practical_work_id
          )}}},[(session.status !== 0)?_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('b-tag',{staticClass:"mr-3",attrs:{"size":"is-small","type":session.status === 2 ? 'is-success' : 'is-primary'}},[_vm._v(" "+_vm._s(_vm._f("day")(session.date)))]),_c('span',{staticClass:"has-text-primary"},[_vm._v(_vm._s(session.title)+" ")]),_vm._v("/ ")],1),_c('div',{staticClass:"column is-narrow"},[_c('div',{staticClass:"is-flex align-items-center"},[_c('span',{class:session.students[0].evaluated && session.status === 2
                    ? 'has-text-success'
                    : 'has-text-danger',staticStyle:{"font-size":"0.5em","margin-right":"0.5em"}},[_vm._v("⬤")]),(session.students[0].evaluated && session.status === 2)?_c('span',[_vm._v(" évalué")]):_c('span',[_vm._v(" non évalué")]),_c('b-icon',{staticClass:"ml-5",attrs:{"icon":"see"}})],1)])]):_vm._e()])})]}}],null,false,2396822114)})],1):_c('b-message',{staticClass:"mt-5",attrs:{"type":"is-warning"}},[_vm._v("Vous n'avez pas d'activité pratique programmée pour le moment")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }