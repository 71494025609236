<template>
  <div class="is-flex">
    <b-loading :is-full-page="isFullPage" v-model="isLoading"> </b-loading>
    <AppSidebar />
    <div class="main-content">
      <div class="lineCrumbAndAccount">
        <AppBreadcrumb v-bind:crumbs="crumbs" />
        <AppAccountMenu v-bind:appAccount="crumbs" />
      </div>
      <div class="header-page is-flex flex-column">
        <div class="justify-content-between columns lineHeaderTitle">
          <div class="column" v-if="trainingframework">
            <div class="title-page long-title">
              {{ trainingframework.name }}
            </div>
            <div class="subtitle-infos">
              <span class="created"> Maquette créée le :</span>
              {{ trainingframework.date_created | moment }}
              par {{ trainingframework.owner_name }}
              <div>
                <span class="created">Modifiée le :</span>
                {{ trainingframework.date_updated | moment }}
              </div>
              <div class="mt-2">
                <b-tag
                  type="is-default"
                  rounded
                  v-if="trainingframework.active == 0"
                  >inactive</b-tag
                >
                <b-tag
                  type="is-success"
                  rounded
                  v-else-if="trainingframework.active == 1"
                  >active</b-tag
                ><b-tag class="ml-1 is-outlined is-rounded" outlined>{{
                  trainingframework.framework_type.name
                }}</b-tag>
              </div>
            </div>
          </div>
          <div class="column is-narrow actions">
            <b-button
              @click.prevent="exportFramework(trainingframework.id)"
              type="is-primary is-rounded mr-3"
              rounded
              >exporter la maquette</b-button
            >
            <b-button
              @click.prevent="editFramework(trainingframework.id)"
              type="is-primary is-rounded mr-3"
              rounded
              >modifier</b-button
            >
            <b-button
              @click.prevent="openTraining()"
              type="is-success is-rounded mr-3"
              >ouvrir une formation</b-button
            >
          </div>
        </div>
      </div>
      <section class="mt-auto" id="header-tabs">
        <div class="b-tabs">
          <nav class="tabs">
            <ul>
              <li
                v-for="(tab, index) of tabs"
                v-bind:key="index"
                v-bind:id="tab.id"
                :class="currentTab === tab.slug ? 'is-active' : ''"
              >
                <a @click="gotoStep(tab.slug)"
                  ><span>{{ tab.name }}</span></a
                >
              </li>
              <li :class="currentTab === 'resources' ? 'is-active' : ''">
                <a @click="gotoResources"><span>Ressources</span></a>
              </li>
            </ul>
          </nav>
        </div>
      </section>
      <div class="content-page">
        <!--content page-->
        <section id="stepone" v-if="step == 'infos'">
          <div class="helpUser mt-0">
            <div class="title">Cette maquette est en lecture seule</div>
            <div>
              Vous pouvez l'éditer en cliquant sur le bouton "modifier" en haut
              à droite.
            </div>
          </div>
          <div class="card_item">
            <!-- <div class="title-paragraph mb-3">Fiche technique</div> -->
            <div class="columns is-multiline">
              <div class="column">
                <div class="readField is-flex align-items-center">
                  <span class="label">Secteur :</span>
                  <span class="" v-if="trainingframework.sector">
                    <span class="tag is-primary">
                      {{ trainingframework.sector.name }}</span
                    >
                  </span>
                </div>
                <div class="readField is-flex align-items-center">
                  <div class="label">Qualification :</div>

                  <b-tag
                    type="is-default"
                    rounded
                    v-if="trainingframework.qualification == 0"
                    >non qualifiante</b-tag
                  >
                  <b-tag
                    type="is-success"
                    rounded
                    v-else-if="trainingframework.qualification == 1"
                    >qualifiante</b-tag
                  >
                </div>
                <div
                  v-if="trainingframework.rncp_title"
                  class="readField is-flex align-items-center"
                >
                  <div class="label">n°fiche :</div>
                  <div class="field" v-if="trainingframework.rncp_title">
                    {{ trainingframework.rncp_title }}
                  </div>
                </div>
                <div class="readField">
                  <div class="label">code NSF :</div>
                  <div class="field" v-if="trainingframework.nsf_code">
                    {{ trainingframework.nsf_code.name }}
                  </div>
                </div>
              </div>
              <div class="column">
                <div class="readField">
                  <div class="label">Nomenclature :</div>
                  <div class="field" v-if="trainingframework.nomenclature">
                    {{ trainingframework.nomenclature.name }}
                  </div>
                </div>
                <!-- <div class="readField" v-if="trainingframework.formacodes">
                  <div class="label">Formacodes :</div>
                  <div
                    class="field"
                    v-for="formacode in trainingframework.formacodes"
                    :key="formacode.id"
                  >
                    <div>{{ formacode.name }}</div>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
        </section>
        <section id="steptwo" v-if="step == 'situations'">
          <h1 class="title-paragraph">
            Situations professionnelles significatives
          </h1>
          <p class="subtitle-paragraph">
            Indiquez ici quelques situations professionnelles significatives des
            compétences visées. Les situations professionnelles significatives
            sont des situations qui, si elles sont toutes maîtrisées, suffisent
            à rendre compte de l’ensemble des compétences visées par la
            formation.
          </p>
          <b-message v-if="!trainingframework.goals" type="is-info">
            Pour le moment, vous n'avez ajouté aucun objectif pour la formation,
            n'hésitez pas à en saisir.
          </b-message>
          <div
            class="field card_grey ck"
            v-else
            v-html="trainingframework.goals"
          >
            <!-- {{ trainingframework.goals }} -->
          </div>
        </section>
        <section id="stepthree" v-if="step == 'skills'">
          <h1 class="title-paragraph">Compétences globales</h1>
          <p class="subtitle-paragraph">
            Résumez ici les principaux champs de compétences et de connaissances
            à maitriser du référentiel de cette formation pour les managers,
            tuteur et apprenants. Vous pouvez aussi ajouter des liens externes (
            référentiel par exemple).
          </p>
          <b-message v-if="!trainingframework.skills" type="is-info">
            Pour le moment, vous n'avez ajouté aucun objectif pour la formation,
            n'hésitez pas à en saisir.
          </b-message>
          <div
            v-else
            class="field card_grey ck"
            v-html="trainingframework.skills"
          >
            <!-- {{ trainingframework.skills }} -->
          </div>
          <!-- <h1 class="title-paragraph">Liste des compétences associées</h1> -->
          <SkillsRead
            :typeoftraining="'framework'"
            :objectId="Number(this.$route.params.id)"
          />
        </section>
        <section id="stepthree" v-if="step == 'parcours'">
          <h1 class="title-paragraph">Parcours de formation</h1>
          <div class="mb-5">
            <b-button
              rounded
              @click="isOpenPeriod = true"
              v-if="isOpenPeriod === false"
              size="is-small"
              >ouvrir tout</b-button
            >
            <b-button
              rounded
              @click="isOpenPeriod = false"
              v-if="isOpenPeriod === true"
              size="is-small"
              >fermer tout</b-button
            >
          </div>
          <div class="simpleParcours">
            <b-collapse
              class="card"
              animation="slide"
              v-for="(period, index) of trainingframework.periods"
              v-bind:key="index"
              :open="isOpenPeriod"
            >
              <div
                slot="trigger"
                slot-scope="props"
                class="card-header"
                role="button"
                :open="false"
              >
                <div
                  class="bg-card is-flex bg-primary align-items-center"
                  :class="'periodType' + period.type"
                >
                  <a class="card-header-icon">
                    <b-icon v-bind:icon="props.open ? 'menu-down' : 'menu-up'">
                    </b-icon>
                  </a>
                  <p class="card-header-title">
                    {{ period.name }}
                  </p>
                </div>
              </div>
              <div class="card-content">
                <div
                  class="pointer card-edit-sequence is-flex align-items-center w-100"
                  v-for="(sequence, index) in order(period.sequences)"
                  v-bind:key="index"
                  @click.prevent="seeSequencedetail(sequence.id)"
                >
                  <div class="bloc-left is-flex mr-auto align-items-center">
                    <div
                      v-if="sequence.sequence_type"
                      class="sequenceType situation justify-content-center"
                      type="situation"
                      :class="'sequenceType' + sequence.sequence_type"
                    >
                      <span v-if="sequence.sequence_type">{{
                        sequence.sequence_type_label
                      }}</span>
                    </div>
                    <span v-else>type indéfini</span>

                    <div class="card-header-title">
                      {{ sequence.name }}
                    </div>
                  </div>
                  <div class="actions is-flex align-items-center">
                    <div
                      class="is-flex align-items-center justify-content-between row-responsive"
                    ></div>
                    <div class="actions-buttons is-flex align-items-center">
                      <a class="actions-icon">
                        <b-icon icon="see" size="is-medium" type="is-black">
                        </b-icon>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </b-collapse>
          </div>
          <!-- modal detail sequences -->
        </section>
        <section id="stepfive" v-if="step == 'resources'">
          <h1 class="title-paragraph">Ressources associées à cette maquette</h1>
          <div class="helpUser mt-0">
            <div>
              Retrouvez ici la liste des ressources associées à la maquette.
              pour chaque expérience ou activité des ressources peuvent aussi
              être ajoutées. Pour des questions écologiques, le poids des
              fichiers est limité à 1 Mo. Si votre fichier est trop lourd, vous
              pouvez l'optimiser sur un site tel que
              <a href="https://www.wecompress.com/fr/" target="_blank"
                ><strong>wecompress</strong></a
              >.
            </div>
          </div>
          <div v-if="resources">
            <div v-if="resources.length">
              <div v-for="resource in resources.slice(0, 5)" :key="resource.id">
                <ResourceCard
                  :resource="resource"
                  :action="'read'"
                ></ResourceCard>
              </div>
            </div>
          </div>
        </section>
        <!--content-->
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import localization from 'moment/locale/fr';
import _ from 'lodash';
import { mapState } from 'vuex';
import AppSidebar from '@/components/AppSidebar.vue';
import AppBreadcrumb from '@/components/AppBreadcrumb.vue';
import AppAccountMenu from '@/components/AppAccountMenu.vue';
import ResourceCard from '@/components/Resources/ResourceCard.vue';
import SkillsRead from '@/components/Skills/SkillsRead.vue';

moment.updateLocale('fr', localization);

export default {
  components: {
    AppSidebar,
    AppBreadcrumb,
    AppAccountMenu,
    ResourceCard,
    SkillsRead,
  },
  computed: {
    ...mapState('auth', ['currentUser']),
  },
  data() {
    return {
      isLoading: true,
      isFullPage: false,
      trainingframework: [],
      sequencetypes: [],
      // variable for b-collapse
      isOpenPeriod: true,
      // Technical informations training
      sector: '',
      nsf: '',
      nomenclature: '',
      locale: 'fr-FR',
      // declaration variables for breadcrumbs
      crumbs: [
        {
          text: 'Maquettes',
          href: '/maquettes',
        },
        {
          text: 'Consulter une maquette',
          class: 'is-active',
        },
      ],
      // declaration variables for tabs
      currentTab: 1,
      step: 1,
      tabs: [
        {
          name: 'Fiche technique',
          slug: 'infos',
          class: 'is-active',
        },
        {
          name: 'Situations',
          slug: 'situations',
        },
        {
          name: 'Compétences',
          slug: 'skills',
        },
        {
          name: 'Parcours',
          slug: 'parcours',
        },
      ],
      resources: null,
      skillsTraining: [],
      skillsLevelsTraining: [],
    };
  },
  filters: {
    moment(date) {
      return moment(date).format('D MMMM YYYY');
    },
  },
  methods: {
    initTrainingframework() {
      this.startLoading();
      this.$http
        .get(`/training_frameworks/${this.$route.params.id}/?query={*,periods{*,sequences{-activities}}}&format=json`)
        .then((response) => {
          this.trainingframework = response.data;
          this.trainingframework.periods = this.order(this.trainingframework.periods);
          this.endLoading();
        });
    },
    // function to get the name of the seuquenc type
    // not finished being implemented
    initSequencetypes() {
      this.$http
        .get('/sequencetypes/?format=json')
        .then((response) => {
          this.sequencetypes = response.data;
        });
    },
    gotoStep(numberStep) {
      this.currentTab = numberStep;
      this.step = numberStep;
      // if (this.step === 3) {
      //   this.initSkills();
      // }
      this.gotoTab(numberStep);
    },
    gotoTab(slug) {
      if (this.$route.path !== `/maquettes/${this.$route.params.id}/${slug}`) {
        this.$router.push({
          name: 'Maquette',
          params: {
            tab: slug,
            id: this.$route.params.id,
          },
        }).catch((error) => {
          window.console.log(error);
        });
      }
    },
    handleHistoryChange() {
      this.gotoStep(this.$route.params.tab);
    },
    order(array) {
      return _.orderBy(array, 'position');
    },
    editFramework(param) {
      this.$router.push({
        name: 'FrameworkEdit',
        params: { id: param, tab: this.step },
      });
    },
    exportFramework() {
      this.$buefy.dialog.confirm({
        title: 'Exporter cette maquette',
        message: 'Vous pouvez exporter cette maquette au format Excel',
        confirmText: 'Exporter',
        cancelText: 'Annuler',
        type: 'is-info',
        onConfirm: () => {
          this.$http
            .get(`/export_xls/?maquette=${this.$route.params.id}`, {
              responseType: 'blob',
            }).then((response) => {
              const fileURL = window.URL.createObjectURL(new Blob([response.data]));
              const fileNamed = this.trainingframework.name.replace(' ', '_');
              const fileLink = document.createElement('a');
              fileLink.href = fileURL;
              fileLink.setAttribute('download', `inSitu_maquette_${fileNamed}.xlsx`);
              document.body.appendChild(fileLink);
              fileLink.click();
            });
        },
      });
    },
    openTraining() {
      this.$buefy.dialog.confirm({
        title: 'Êtes-vous sûr.e de vouloir créer une formation à partir de cette maquette ?',
        message: 'Avez-vous fini l\'édition de la maquette avant de la transformer en session de formation ? Vous pourrez ajuster les contenus de votre formation',
        confirmText: 'Créer une formation',
        cancelText: 'Annuler',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.$http
            .post('/trainings/', {
              name: this.trainingframework.name,
              legacy_training_framework: this.trainingframework.id,
              skills: this.trainingframework.skills,
              goals: this.trainingframework.goals,
              cfa: this.currentUser.cfa_id,
              organization: this.trainingframework.organization,
              students: [],
              teachers: [],
              tutors: [],
              managers: [],
              sector: this.trainingframework.sector,
              nsf_code: this.trainingframework.nfs_code,
              rncp_title: this.trainingframework.rncp_title,
              qualification: this.trainingframework.qualification,
              description: this.trainingframework.description,
              start_date: new Date(),
              end_date: new Date(),
              active: 1,
              teachers_can_edit: false,
              editable_without_dates: true,
            })
            .then((response) => {
              this.$buefy.notification.open('Formation créée');
              // this.$router.push({
              //   name: 'TrainingEdit',
              //   params: { idTraining: response.data.id },
              // });

              this.$buefy.dialog.confirm({
                title: 'Voulez-vous donner le droit aux formateurs de tout modifier ?',
                message: 'Vous pouvez donner les droits à vos formateurs de modifier le contenu de la formation, expériences pédagogiques, textes, utilisateurs, ordre des expériences...',
                confirmText: 'Ne pas ouvrir les droits d\'édition',
                cancelText: 'Ouvrir les droits',
                type: 'is-info',
                hasIcon: false,
                onConfirm: () => {
                  this.$router.push({
                    name: 'TrainingEdit',
                    params: { idTraining: response.data.id, tab: 'session' },
                  });
                },
                onCancel: () => {
                  this.$http
                    .patch(`/trainings/${response.data.id}/`, {
                      teachers_can_edit: true,
                    })
                    .then(() => {
                      this.$buefy.notification.open('Formation créée');
                      this.$router.push({
                        name: 'TrainingEdit',
                        params: { idTraining: response.data.id, tab: 'session' },
                      });
                    });
                },
              });
            });
        },
      });
    },
    seeSequencedetail(param) {
      this.$router.push({
        name: 'FrameworkSequence',
        params: { id: param, idFramework: this.$route.params.id },
      });
    },
    initResources() {
      this.startLoading();
      this.$http.get(`/get_resources/?framework=${this.$route.params.id}&format=json`).then((response) => {
        this.resources = response.data.resources;
        this.resources = response.data.resources;
        this.endLoading();
      });
    },
    gotoResources() {
      this.currentTab = 'resources';
      this.step = this.currentTab;
      this.initResources();
    },
    startLoading() {
      this.isLoading = true;
    },
    endLoading() {
      this.isLoading = false;
    },
    initSkills() {
      this.$http
        .get(`/frameworkskills/?training_framework=${this.$route.params.id}`)
        .then((response) => {
          this.skillsTraining = response.data;
          this.$http
            .get(`/frameworklevels/?training_framework=${this.$route.params.id}`)
            .then((resp) => {
              this.skillsLevelsTraining = resp.data;
              this.endLoading();
            });
        });
    },
  },
  created() {
    this.initTrainingframework();
    this.initSequencetypes();
    this.initResources();
    // verification if user come from an another paeg and had to go to a specific tab
    // if (this.$route.params.stepNumber) { this.gotoStep(this.$route.params.stepNumber); }
    // if (this.$route.params.stepNumber === 'resources') {
    //   this.gotoResources();
    // }
    if (this.$route.params.tab) {
      this.gotoStep(this.$route.params.tab);
    } else {
      this.gotoStep('infos');
    }
  },
  mounted() {
    window.addEventListener('popstate', this.handleHistoryChange);
  },
  destroyed() {
    window.removeEventListener('popstate', this.handleHistoryChange);
  },
};
</script>
