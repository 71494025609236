<template>
  <div id=""> <!-- eslint-disable max-len-->
    <b-loading :is-full-page="isFullPage" v-model="isLoading"> </b-loading>
    <b-modal v-model="isCardModalActive" scroll="keep" id="modalNude">
      <template #default="props">
        <Modalexport @close="props.close" :student="student" :student_id="$route.params.idStudent">

        </Modalexport>
      </template>
    </b-modal>
    <div class="columns mt-4">
      <div class="column">
        <div>
          <h1 class="title-paragraph">Suivi</h1>
        </div>
        <span v-if="start_date && end_date">
          <b> du {{ start_date | day }} au {{ end_date | day }}</b></span>
        <span v-else>pour toute la formation</span>
      </div>
      <div class="column is-narrow">
        <router-link v-if="currentUser.role === 'formateur'" :to="'/calendrier/' +
      $route.params.idTraining +
      '/' +
      $route.params.idStudent +
      '/' +
      $route.params.idStudentTraining
      ">
          <b-button type="is-primary is-rounded mr-3" class="ml-auto" rounded>créer un point
          </b-button>
        </router-link>

        <b-button disabled @click="isCardModalActive = true" type="is-primary is-rounded mr-3" class="ml-auto"
          rounded>exporter le livret
        </b-button>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <b-button v-if="currentUser.role !== 'tuteur'" @click="
      typeSelected = 2;
    rangeSelected = 'twoweeks';
    changeDate();
    " class="mr-2" :class="typeSelected === 2 ? 'is-primary is-light' : ''">Coaching
          individuel</b-button><b-button @click="
      typeSelected = 3;
    rangeSelected = 'quarter';
    changeDate();
    " class="mr-2" :class="typeSelected === 3 ? 'is-primary is-light' : ''">Point tripartite</b-button>
      </div>
    </div>
    <div class="columns is-vcentered">
      <div class="column">
        <b-datepicker v-model="start_date" :first-day-of-week="1" placeholder="Cliquer pour sélectionner...">
          <b-button label="Aujourdhui" size="is-small" type="is-primary" icon-left="calendar-today"
            @click="start_date = new Date()" />

          <b-button label="Clear" size="is-small" type="is-danger" icon-left="close" outlined
            @click="start_date = null" />
        </b-datepicker>
      </div>
      <div class="column">
        <b-datepicker v-model="end_date" :first-day-of-week="1" placeholder="Cliquer pour sélectionner...">
          <b-button label="Aujourdhui" size="is-small" type="is-primary" icon-left="calendar-today"
            @click="end_date = new Date()" />

          <b-button label="Clear" size="is-small" type="is-danger" icon-left="close" outlined
            @click="end_date = null" />
        </b-datepicker>
      </div>
      <div class="column is-narrow">
        <b-button type="is-primary" @click="initWaypoints(start_date, end_date)">rechercher</b-button>
      </div>
      <div class="column columns cards-header is-mobile">
        <div class="column is-narrow">
          <b-field label="Période concernée" class="mb-0">
            <b-select placeholder="Période concernée" v-model="rangeSelected" @input="changeDate()">
              <option value="twoweeks">Derniers 15 jours</option>
              <option value="month">Dernier mois</option>
              <option value="quarter">Dernier trimestre</option>
              <option value="perso">Période personnalisée</option>
              <option value="all">Toute la formation</option>
            </b-select>
          </b-field>
        </div>
      </div>
    </div>
    <div v-if="waypointList.length">
      <div v-for="waypoint in waypointList" :key="waypoint.id">
        <div class="card_activity" v-if="waypoint.type === typeSelected">
          <div class="activity-item is-flex align-items-center"
            :class="new Date(waypoint.start_date) > today ? 'date-later' : ''">
            <div>
              <img v-if="waypoint.validated" :src="require(`@/assets/img/steps/status3.svg`)" alt=""
                class="img_status mr-3" />
              <img v-else :src="require(`@/assets/img/steps/status6.svg`)" alt="" class="img_status mr-3" />
            </div>
            <div class="columns is-tablet mb-0 ml-1 is-vcentered">
              <div class="title_activity">
                <div v-if="waypoint.title">{{ waypoint.title }}</div>
                <div v-else>Point du {{ waypoint.start_date | day }}</div>
              </div>
              <div class="is-block" style="font-size: 0.9rem">
                {{ waypoint.start_date | day }}-{{
      waypoint.start_date | hour
    }}
                / {{ waypoint.end_date | hour }}
              </div>
              <div>
                <b-tag class="ml-2" type="is-outlined" v-if="new Date(waypoint.start_date) < today">{{
      waypoint.duration_in_minutes }} min</b-tag>
              </div>
            </div>

            <div class="ml-auto is-flex align-items-center"
              v-if="!waypoint.validated && currentUser.role === 'formateur'">
              <b-button outlined rounded type="is-primary" @click="gotoWaypointEdit(waypoint.id)">voir</b-button>
              <div class="actions-icon is-clickable" @click.prevent="removeWaypoint(waypoint.id)" v-if="(!waypoint.validated &&
      currentUser.role === 'coordinateur') ||
      (!waypoint.validated && currentUser.role === 'formateur')
      ">
                <b-icon icon="close" size="is-medium" type="is-danger">
                </b-icon>
              </div>
            </div>
            <b-button outlined rounded type="is-primary" class="ml-auto" v-else
              @click="gotoWaypoint(waypoint.id)">voir</b-button>
            <div class="actions-icon is-clickable" @click.prevent="removeWaypoint(waypoint.id)"
              v-if="currentUser.role === 'coordinateur' && waypoint.validated">
              <b-icon icon="close" size="is-medium" type="is-danger"> </b-icon>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-message type="is-info" v-if="!waypointList.length">Il n'y a pas encore de point d'étape enregistré.
    </b-message>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import _ from 'lodash';
import moment from 'moment';
import Modalexport from '@/components/Waypoints/Modalexport.vue';
// import EditEvent from '@/components/Agenda/EditEvent.vue';
// import localization from 'moment/locale/fr';

export default ({
  props: {
    student: {
      type: [String],
    },
  },
  components: {
    Modalexport,
    //  EditEvent,
  },
  filters: {
    day(date) {
      return moment(date).format('DD/MM/YYYY');
    },
    hour(date) {
      return moment(date).format('HH:mm');
    },
  },
  computed: {
    ...mapState('auth', ['currentUser']),
    multiple_value_boolean() {
      return this.formWaypoint.type !== null
        && this.formWaypoint.start_date !== null
        && this.formWaypoint.end_date !== null;
    },
    difference(date1, date2) {
      const diff = moment(date1).diff(date2, 'hours');
      return diff;
    },
  },
  data() {
    return {
      isLoading: false,
      isFullPage: false,
      isCardModalActive: false,
      schoolSubjects: [],
      waypointList: [],
      AttitudeWaypointList: [],
      isWaypointModalActive: false,
      formWaypoint: {
        type: null,
        end_date: null,
        start_date: null,
      },
      showWeekNumber: true,
      locale: 'UTC',
      typeSelected: 2,
      trainingOptions: [],
      studentWaypointCreate: false,
      start_date: null,
      end_date: null,
      twoweeks: new Date(),
      month: new Date(),
      quarter: new Date(),
      today: new Date(),
    };
  },
  methods: {
    startLoading() {
      this.isLoading = true;
    },
    endLoading() {
      this.isLoading = false;
    },
    order(array) {
      return _.orderBy(array, 'position');
    },
    orderDate(array) {
      return _.orderBy(array, 'start_date');
    },
    initWaypoints(start, end) {
      this.startLoading();
      const startDate = moment(new Date(start)).format('YYYY-MM-DD');
      const endDate = moment(new Date(end)).format('YYYY-MM-DD');
      const urlWs = `/agenda/student_training_waypoints/?student_training=${this.$route.params.idStudentTraining}&type=${this.typeSelected}&order=desc&start=${startDate}&end=${endDate}&format=json`;
      this.$http
        .get(`${urlWs}`)
        .then((response) => {
          this.waypointList = response.data;

          this.endLoading();
          if (this.currentUser.role === 'tuteur') {
            this.formWaypoint.type = 0;
          }
        });
    },
    initAttitudeWaypoints() {
      const urlWs = `/agenda/student_training_attitude_waypoints/?student_training=${this.$route.params.idStudentTraining}&format=json`;
      this.$http
        .get(`${urlWs}`)
        .then((response) => {
          this.AttitudeWaypointList = response.data;
          this.endLoading();
        });
    },
    newWaypoint() {
      this.isWaypointModalActive = true;
    },
    newAttitudeEvaluation() {
      this.$router.push({
        name: 'AttitudeEvaluationEdit',
        params: {
          idTraining: this.$route.params.idTraining,
          idStudent: this.$route.params.idStudent,
          idStudentTraining: this.$route.params.idStudentTraining,
          idWaypoint: 1,
        },
      });
    },

    postWaypoint() {
      this.$http
        .post('agenda/student_training_waypoints/', {
          start_date: this.formWaypoint.start_date,
          end_date: this.formWaypoint.end_date,
          type: this.formWaypoint.type,
          student_training: this.$route.params.idStudentTraining,
        }).then((response) => {
          this.$buefy.toast.open({ message: 'Point d\'étape enregistrée', type: 'is-success' });
          this.initWaypoints();
          this.gotoWaypointEdit(response.data.id);
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
          this.$buefy.dialog.alert({
            title: 'Mince...',
            message: 'Le point n\'a pas pu être enregistrée.',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
          });
        });
    },
    gotoWaypointEdit(id) {
      this.$router.push({
        name: 'WaypointVaeEdit',
        params: {
          idTraining: this.$route.params.idTraining,
          idStudent: this.$route.params.idStudent,
          idStudentTraining: this.$route.params.idStudentTraining,
          idWaypoint: id,
        },
      });
    },
    gotoWaypoint(id) {
      this.$router.push({
        name: 'WaypointVaeEdit',
        params: {
          idTraining: this.$route.params.idTraining,
          idStudent: this.$route.params.idStudent,
          idStudentTraining: this.$route.params.idStudentTraining,
          idWaypoint: id,
        },
      });
    },
    removeWaypoint(id) {
      const urlWs = `/agenda/student_training_waypoints/${id}/`;
      this.$buefy.dialog.confirm({
        title: 'Supprimer un point d\'étape',
        message: 'Êtes-vous sûr de vouloir supprimer ce point ? Attention, vous perdrez tout le contenu que vous avez pu rédiger pour ce point.',
        confirmText: 'Supprimer',
        cancelText: 'Annuler',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.$http
            .delete(`${urlWs}`)
            .then(() => {
              this.initWaypoints(new Date(this.start_date), new Date(this.end_date));
              this.$buefy.toast.open({ message: 'Point supprimé', type: 'is-success' });
            }).catch((error) => {
              window.console.log(error);
              this.errored = true;
              this.$buefy.dialog.alert({
                title: 'Mince...',
                message: 'Le point n\'a pas pu être supprimé.',
                type: 'is-info',
                hasIcon: true,
                icon: 'times-circle',
                ariaRole: 'alertdialog',
                ariaModal: true,
                confirmText: 'Ok',
              });
            });
        },
      });
    },
    initOptions() {
      this.startLoading();
      this.$http
        .get(`/trainings/${this.$route.params.idTraining}/?query={tab_options}&format=json`)
        .then((response) => {
          this.trainingOptions = response.data.tab_options;
          if (this.trainingOptions.find((option) => option.code === 'studentWaypointCreate')) {
            this.studentWaypointCreate = true;
          }
        }).catch((error) => {
          window.console.log(error);
        });
    },
    changeDate() {
      this.end_date = this.today;
      if (this.rangeSelected === 'twoweeks') {
        this.start_date = new Date(this.twoweeks);
        this.initWaypoints(this.start_date, this.end_date);
      } else if (this.rangeSelected === 'month') {
        this.start_date = new Date(this.month);
        this.initWaypoints(this.start_date, this.end_date);
      } else if (this.rangeSelected === 'quarter') {
        this.start_date = new Date(this.quarter);
        this.initWaypoints(this.start_date, this.end_date);
      } else if (this.rangeSelected === 'all') {
        this.start_date = new Date('1970-01-01');
        this.end_date = new Date();
        this.initWaypoints(this.start_date, this.end_date);
      }
    },
  },
  created() {
    this.twoweeks = this.twoweeks.setDate(this.twoweeks.getDate() - 15);
    this.month = this.month.setMonth(this.month.getMonth() - 1);
    this.quarter = this.quarter.setMonth(this.quarter.getMonth() - 3);
    this.startLoading();
    if (this.typeSelected === 2) {
      this.rangeSelected = 'twoweeks';
      this.changeDate();
    } else {
      this.rangeSelected = 'quarter';
      this.changeDate();
    }
    this.initOptions();
    if (this.currentUser.role === 'tuteur') {
      this.typeSelected = 3;
    }
  },
  mounted() {
  },
  destroyed() {
  },
});
</script>

<style lang="scss" scoped>
#modalWaypoint {
  border-radius: 5px;
  padding-bottom: 3rem;
}

#modalWaypoint .modal-card-body .icon {
  font-size: 0.8rem !important;
}

#bloc-activities {
  background-color: unset;
  padding-top: 0px;
}

.dates {
  font-size: 0.95rem;
}

.title_activity {
  font-size: 1rem;
}

.tag {
  margin-bottom: 0.2rem;
}

@media (max-width: 768px) {

  .modal .animation-content,
  .modal-card-body,
  .modal-card {
    width: 100%;
    height: 100%;
    max-height: 100vh;
    margin: 0;
  }

  #modalWaypoint {
    border-radius: 0px;
  }

  .dates-line,
  .title_activity {
    display: flex;
    flex-direction: row;

    .tag {
      margin-right: 0.5rem;
    }
  }

  .dates-line {
    margin-bottom: 0.3rem;
  }
}

@media (max-width: 576px) {
  .card_activity::after {
    padding-left: 1.1rem;
  }

  #bloc-activities .title_activity,
  .dates {
    font-size: 0.95rem;
  }

  .modal .title-paragraph {
    padding-right: 2rem;
    padding-left: 2rem;
    font-size: 1.3rem;
  }
}

@media (max-width: 475px) {
  #modalWaypoint {
    padding: 1rem 0px 3rem;

    .modal-card {
      padding-top: 1rem;
    }

    .modal-card-body {
      padding-left: 0px;
      padding-right: 0px;
    }

    .icon {
      padding-right: 1.5rem;
    }
  }

  .datepicker {
    .dropdown-item {
      padding: 0px;
    }
  }

  .title_activity {
    font-size: 1rem;
    line-height: 1.2;
  }

  .dates {
    font-size: 0.8rem;
    line-height: 1.2;
  }

  .activity-item .button {
    font-size: 0.8rem;
  }

  .title_activity {
    display: block;
    flex-direction: column;
  }
}

.date-later {
  opacity: 0.6;
}

.button.is-primary.is-light:hover {
  background-color: #fff !important;
}

@media (max-width: 768px) {
  .helpUser {
    display: none;
  }
}

// @media (max-width: 325px) {
//    .datepicker {
//      margin-left:-0.5rem;
//    }
// }</style>
