<template>
  <div>
    <!-- eslint-disable max-len-->
    <b-loading :is-full-page="isFullPage" v-model="isLoading"> </b-loading>
    <div class="helpUser mt-0">
      <div class="title">
        Affinez le parcours pédagogique de votre formation
      </div>
      <div>
        Ici vous modifiez le parcours pour tous vos apprenants. Vous pourrez
        apporter des ajustements par apprenant dans la page "Parcours
        apprenant".
      </div>
      <div class="mt-4" v-if="!training.editable_without_dates">
        <div class="mb-4">
          <b>Vous pouvez attribuer des dates aux expériences et activités ici et
            ensuite les attribuer à tous les apprenants en cliquant sur le
            bouton suivant.</b>
        </div>
        <b-button @click.prevent="populateAllDatesStudents" type="is-success is-rounded" class="mr-2 mb-2">attribuer
          toutes les dates à tous</b-button>
        <b-button @click.prevent="forceAllDatesStudents" type="is-primary is-rounded">écraser toutes les dates
          existantes</b-button>
      </div>
    </div>
    <div class="mb-5" v-if="user.edit_rights < 1">
      <div class="bold"><b>Dates contraignantes ?</b></div>
      <b-field class="mt-4">
        <b-switch v-model="training.editable_without_dates" type="is-success" @input="dateMandatory">
          {{
      training.editable_without_dates
        ? "Non (Les dates sont indicatives)"
        : "Oui (Les dates sont indispensables au bon fonctionnement)"
    }}
        </b-switch>
      </b-field>
    </div>
    <div class="is-flex justify-content-between">
      <h1 class="title-paragraph">Parcours</h1>
      <div>
        <a href="#endParcours" @click="addPeriod" v-if="user.edit_rights < 2"><button
            class="button btn btn-secondary is-primary mr-3 is-rounded">
            Ajouter un bloc d'expériences
          </button></a><b-button @click.prevent="editTrainingSteps" type="is-success is-rounded">enregistrer
          parcours</b-button>
      </div>
    </div>
    <div v-if="training.training_periods">
      <b-message v-if="!training.training_periods.length" type="is-info">
        Pour le moment, vous n'avez ajouté aucun bloc d'expériences pour la
        formation
        <strong>{{ training.name }}</strong>, n'hésitez pas à en ajouter
      </b-message>
    </div>
    <div class="mb-5">
      <b-button rounded @click="isOpenPeriod = true" v-if="isOpenPeriod === false" size="is-small">ouvrir
        tout</b-button>
      <b-button rounded @click="isOpenPeriod = false" v-if="isOpenPeriod === true" size="is-small">fermer
        tout</b-button>
    </div>
    <draggable v-model="training.training_periods" class="w-100" :value="training.periods" handle=".handle"
      @end="onEndDrag">
      <b-collapse class="card" animation="slide" v-for="(period, indexPeriod) of training.training_periods"
        :key="indexPeriod" :open="isOpenPeriod">
        <div slot="trigger" slot-scope="props" class="card-header" role="button">
          <div class="bg-card is-flex bg-primary align-items-center" :class="'periodType' + period.type">
            <a class="card-header-icon">
              <b-icon :icon="props.open ? 'menu-down' : 'menu-up'"> </b-icon>
            </a>
            <b-icon v-if="user.edit_rights < 2" icon="drag" size="is-medium" type="is-black" class="handle btn">
            </b-icon>
            <p class="card-header-title pr-3">
              <textarea class="textarea is-small" placeholder="Inscrivez le nom du bloc" rows="1" v-model="period.name"
                @input="onChangeinputPeriod(indexPeriod, period.id)"></textarea>
            </p>
            <div v-if="training.framework_type">
              <a @click="changePeriodType(period.type, period.id)" v-if="training.framework_type.id === 2"
                class="button is-small is-rounded is-display-block">
                <div>
                  <span v-if="period.type === 0">en entreprise</span>
                  <span v-else>en CFA</span>
                </div>
              </a>
            </div>
            <div class="actions is-flex align-items-center">
              <a v-if="user.edit_rights < 2" class="actions-icon" @click="
      duplicatePeriod(
        period.type,
        period.name,
        period.training_sequences,
        period.id
      )
      ">
                <b-icon icon="copy" size="is-medium" type="is-black"> </b-icon>
              </a>
              <a class="actions-icon" @click="messageDelete('period', period.id, period.name)"
                v-if="user.edit_rights < 2">
                <b-icon icon="close" size="is-medium" type="is-danger">
                </b-icon>
              </a>
            </div>
          </div>
        </div>
        <div class="card-content simpleParcours editBloc">
          <draggable :list="period.training_sequences" :group="{ name: 'period' }" handle=".handle" @end="onEndDrag">
            <div class="card-edit-sequence is-flex align-items-center w-100"
              v-for="(sequence, index) of period.training_sequences" :key="index">
              <div class="bloc-left is-flex mr-auto align-items-center pr-1 w-100">
                <div class="is-flex bloc-handle">
                  <b-icon v-if="user.edit_rights < 2" icon="drag" size="is-medium" type="is-primary" class="handle">
                  </b-icon>
                  <div class="sequenceType situation has-text-centered  justify-content-center" type="situation"
                    :class="'sequenceType' + sequence.sequence_type">
                    {{ sequence.sequence_type_label }}
                  </div>
                </div>
                <div class="is-flex w-100">
                  <textarea class="textarea is-small" placeholder="Inscrivez le nom de l'expérience" rows="1"
                    v-model="sequence.name" @input="onChangeinput(index, sequence.id, indexPeriod)"></textarea>
                </div>
              </div>

              <b-collapse :open="false" aria-id="contentIdForA11y1" v-if="sequence.teachers.length" class="is-flex">
                <template #trigger>
                  <b-icon class="iconTeacher mdi-user" aria-controls="contentIdForA11y1"></b-icon>
                </template>
                <!-- Todo: add teachers names -->
                <!-- <div class="teacherSequence pr-4 has-text-left is-flex">
                  <div>
                    <b
                      >Formateur<span v-if="sequence.teachers.length > 1"
                        >s</span
                      >
                      :</b
                    >
                  </div>
                  <div v-for="teacher in sequence.teachers" :key="teacher.id">
                  </div>
                </div> -->
              </b-collapse>
              <b-icon class="iconTeacher mdi-user noteacher" v-else></b-icon>
              <div class="actions is-flex align-items-center">
                <div class="dates-schedulded has-text-centered is-flex">
                  <div v-if="sequence.start_date" class="has-text-centered">
                    <div class="date-sequence" v-if="sequence.start_date">
                      {{ sequence.start_date | day }}
                    </div>
                  </div>
                  <div v-if="sequence.end_date" class="has-text-centered">
                    <div class="date-sequence" v-if="sequence.end_date">
                      {{ sequence.end_date | day }}
                    </div>
                  </div>
                  <div v-else class="date-sequence has-text-centered">-</div>
                </div>
                <div class="actions-buttons is-flex align-items-center">
                  <b-button type="is-primary is-shadowed is-small mr-1" size="" rounded
                    @click.prevent="seeSequencedetail(sequence.id)">rédiger</b-button>
                  <a v-if="user.edit_rights < 2" class="actions-icon"
                    @click.prevent="duplicateSequence(indexPeriod, sequence.id)">
                    <b-icon icon="copy" size="is-medium" type="is-black">
                    </b-icon>
                  </a>
                  <a v-if="user.edit_rights < 2" class="actions-icon" @click.prevent="
      deleteSequence(sequence.id)
      ">
                    <b-icon icon="close" size="is-medium" type="is-danger">
                    </b-icon>
                  </a>
                </div>
              </div>
            </div>
          </draggable>

          <div class="has-text-centered">
            <div position="is-bottom" aria-id="contentIdForA11y1" :id="'s' + indexPeriod" class="cardClosed">
              <div class="has-text-small mb-2 mt-5">
                <b>Saisissez l'expérience à ajouter</b>
              </div>
              <div class="card_item card_addsequence mb-4">
                <div class="columns is-vcentered">
                  <div class="column is-3">
                    <b-select placeholder="type d'expérience" expanded v-model="formSequence.type">
                      <option v-for="sequencetype in sequencetypes" :key="sequencetype.id" :value="sequencetype.id">
                        <span>{{ sequencetype.name }}</span>
                      </option>
                    </b-select>
                  </div>
                  <div class="column">
                    <b-input type="textarea" class="form-control w-100" maxlength="217" v-model="formSequence.name" />
                  </div>
                  <div class="column is-narrow">
                    <b-button class="is-primary" @click.prevent="
      addSequence(indexPeriod, period.id, 's' + indexPeriod)
      " :disabled="!formSequence.name || !formSequence.type">Valider</b-button>
                  </div>
                </div>
              </div>
            </div>
            <button v-if="user.edit_rights < 2" class="button is-primary mb-4" aria-controls="contentIdForA11y1"
              @click="collapseBlock('s' + indexPeriod)">
              + Ajouter une expérience
            </button>
          </div>
        </div>
      </b-collapse>
    </draggable>
    <div id="endParcours">&nbsp;</div>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import _ from 'lodash';
import moment from 'moment';
import localization from 'moment/locale/fr';
import { mapState } from 'vuex';
import axios from 'axios';

moment.updateLocale('fr', localization);
export default {
  components: {
    draggable,
  },
  filters: {
    moment(date) {
      return moment(date).format('D MMMM YYYY');
    },
    day(date) {
      return moment(date).format('D/MM/YY');
    },
    hour(date) {
      return moment(date).format('HH:mm');
    },
  },
  computed: {
    users() {
      return this.$store.state.users;
    },
    ...mapState('auth', ['currentUser']),
    ...mapState(['user']),
  },
  methods: {

    initTraining() {
      this.startLoading();
      this.$http
        .get(`/trainings/${this.$route.params.idTraining}/?query={editable_without_dates,training_periods,framework_type}&format=json`)
        .then((response) => {
          this.training = response.data;
          this.training.training_periods = this.order(this.training.training_periods);
          for (let i = 0; i < this.training.training_periods.length; i += 1) {
            const myPeriods = this.training.training_periods;
            // eslint-disable-next-line
            this.training.training_periods[i].training_sequences = this.order(myPeriods[i].training_sequences);
          }
          this.endLoading();
        });
    },

    initSequencetypes() {
      this.$http
        .get('/sequencetypes/?format=json')
        .then((response) => {
          this.sequencetypes = response.data;
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
        });
    },
    editTraining() {
      this.$http
        .patch(`/trainings/${this.$route.params.idTraining}/`, this.form)
        .then(() => {
          this.$buefy.toast.open({ message: 'Formation modifiée', type: 'is-success' });
          this.initTraining();
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
          this.$buefy.dialog.alert({
            title: 'Mince...',
            message: 'Nous n\'avons pas pu modifier la formation.',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
          });
        });
    },
    editTrainingSteps() {
      // to patch all the periods displayed
      this.refreshOrder();
      for (let i = 0; i < this.periodsArray.length; i += 1) {
        this.$http
          .patch(`/training_periods/${this.periodsArray[i].id}/`, {
            position: this.periodsArray[i].position,
            name: this.periodsArray[i].name,
          })
          .then(() => {
            for (let j = 0; j < this.periodsArray[i].training_sequences.length; j += 1) {
              this.$http
                .patch(`/training_sequences/${this.periodsArray[i].training_sequences[j].id}/`, {
                  period: this.periodsArray[i].id,
                  position: this.periodsArray[i].training_sequences[j].position,
                  name: this.periodsArray[i].training_sequences[j].name,
                }).then(() => {
                }).catch((error) => {
                  window.console.log(error);
                  this.errored = true;
                  this.$buefy.dialog.alert({
                    title: 'Mince...',
                    message: 'Une erreur s\'est produite. Nous n\'avons pu enregistrer le parcours de la formation.',
                    type: 'is-info',
                    hasIcon: true,
                    icon: 'times-circle',
                    ariaRole: 'alertdialog',
                    ariaModal: true,
                    confirmText: 'Ok',
                  });
                });
            }
          }).catch((error) => {
            window.console.log(error);
            this.errored = true;
            this.$buefy.dialog.alert({
              title: 'Mince...',
              message: 'Une erreur s\'est produite. Nous n\'avons pu enregistrer le parcours de la formation.',
              type: 'is-info',
              hasIcon: true,
              icon: 'times-circle',
              ariaRole: 'alertdialog',
              ariaModal: true,
              confirmText: 'Ok',
            });
          });
      }
      this.$buefy.toast.open({ message: 'Parcours enregistré', type: 'is-success' });
    },
    populatePeriods() {
      // to populate the array "periods"
      this.periodsArray = this.training.training_periods;
    },
    removePeriod(idperiod) {
      // this.periodsArray.splice(index, 1);
      // this line removes period in array periods
      this.$buefy.dialog.confirm({
        title: 'Supprimer une bloc',
        message: 'Êtes-vous sûr de vouloir supprimer ce bloc ? Attention, vous perdrez tout le contenu que vous avez pu rédiger pour ce bloc et toutes les expériences associées.',
        confirmText: 'Supprimer',
        cancelText: 'Annuler',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.$http
            .delete(`/training_periods/${idperiod}/?format=json`)
            .then(() => {
              this.initTraining();
              this.populatePeriods();
              this.$buefy.toast.open({ message: 'Bloc supprimé', type: 'is-success' });
            }).catch((error) => {
              window.console.log(error);
              this.errored = true;
              this.$buefy.dialog.alert({
                title: 'Mince...',
                message: 'Le bloc n\'a pas pu être supprimé.',
                type: 'is-info',
                hasIcon: true,
                icon: 'times-circle',
                ariaRole: 'alertdialog',
                ariaModal: true,
                confirmText: 'Ok',
              });
            });
        },
      });
    },
    removeSequence(idsequence) {
      // this line removes sequence (index) in its period (keyperiod)
      // this.periodsArray[keyperiod].sequences.splice(index, 1);
      this.$buefy.dialog.confirm({
        title: 'Supprimer une expérience',
        message: 'Êtes-vous sûr de vouloir supprimer cette expérience ? Attention, vous perdrez tout le contenu que vous avez pu rédiger pour cette expérience et ses activités.',
        confirmText: 'Supprimer',
        cancelText: 'Annuler',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.$http
            .delete(`/training_sequences/${idsequence}/?format=json`)
            .then(() => {
              this.initTraining();
              this.refreshOrder();
              this.populatePeriods();
              this.$buefy.toast.open({ message: 'Expérience supprimée', type: 'is-success' });
            }).catch((error) => {
              window.console.log(error);
              this.errored = true;
              this.$buefy.dialog.alert({
                title: 'Mince...',
                message: 'L\'expérience n\'a pas pu être supprimée.',
                type: 'is-info',
                hasIcon: true,
                icon: 'times-circle',
                ariaRole: 'alertdialog',
                ariaModal: true,
                confirmText: 'Ok',
              });
            });
        },
      });
    },
    deleteSequence(idsequence) {
      this.$buefy.dialog.confirm({
        title: 'Supprimer une expérience',
        message: 'Êtes-vous sûr de vouloir supprimer cette expérience ? Elle sera automatiquement enlevée de chaque parcours apprenant',
        confirmText: 'Supprimer',
        cancelText: 'Annuler',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.$http
            .patch(`/training_sequences/${idsequence}/`, {
              deleted: true,
            }).then(() => {
              this.initTraining();
              this.refreshOrder();
              this.populatePeriods();
              this.$buefy.toast.open({ message: 'Expérience supprimée', type: 'is-success' });
            }).catch((error) => {
              window.console.log(error);
              this.errored = true;
              this.$buefy.dialog.alert({
                title: 'Mince...',
                message: 'L\'expérience n\'a pas pu être supprimée.',
                type: 'is-info',
                hasIcon: true,
                icon: 'times-circle',
                ariaRole: 'alertdialog',
                ariaModal: true,
                confirmText: 'Ok',
              });
            });
        },
      });
    },
    duplicatePeriod(typePeriod, nameperiod, sequences, id) {
      this.startLoading();
      this.populatePeriods();
      this.$http
        .post('/training_periods/', {
          prev: id,
          name: `${nameperiod} copie`,
          training: this.$route.params.idTraining,
          position: this.periodsArray.length + 1,
          training_sequences: [],
          type: typePeriod,
        }).then(() => {
          this.initTraining();
          this.populatePeriods();
          this.$buefy.toast.open({ message: 'Bloc dupliqué', type: 'is-success' });
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
          this.$buefy.dialog.alert({
            title: 'Mince...',
            message: 'Le bloc n\'a pas pu être dupliqué.',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
          });
        });
    },
    duplicateSequence(keyperiod, idSequence) {
      this.populatePeriods();
      this.$http
        .get(`/training_sequences/${idSequence}/?format=json`)
        .then((response) => {
          this.sequencetocopy = response.data;
          this.$http
            .post('/training_sequences/', {
              prev: this.sequencetocopy.id,
              name: `${this.sequencetocopy.name} copie`,
              position: this.periodsArray[keyperiod].training_sequences.length + 1,
              short_description: this.sequencetocopy.short_description,
              long_description: this.sequencetocopy.long_description,
              comment: this.sequencetocopy.comment,
              estimated_duration: this.sequencetocopy.estimated_duration,
              goals: this.sequencetocopy.goals,
              pre_requisites: this.sequencetocopy.pre_requisites,
              start_date: this.sequencetocopy.start_date,
              end_date: this.sequencetocopy.end_date,
              active: this.sequencetocopy.active,
              legacy_sequence: this.sequencetocopy.legacy_sequence,
              sequence_type: this.sequencetocopy.sequence_type,
              teachers: this.sequencetocopy.teachers,
              tutors: this.sequencetocopy.tutors,
              formacode: this.sequencetocopy.formacode,
              resources: this.sequencetocopy.resources,
              sequence_type_label: this.sequencetocopy.sequence_type_label,
              period: this.sequencetocopy.period,
            })
            .then(() => {
              this.initTraining();
              this.populatePeriods();
              this.$buefy.toast.open({ message: 'Expérience ajoutée', type: 'is-success' });
            }).catch((error) => {
              window.console.log(error);
              this.errored = true;
              this.$buefy.dialog.alert({
                title: 'Mince...',
                message: 'L\'expérience n\'a pas pu être dupliquée.',
                type: 'is-info',
                hasIcon: true,
                icon: 'times-circle',
                ariaRole: 'alertdialog',
                ariaModal: true,
                confirmText: 'Ok',
              });
            });
        });
    },
    refreshOrder() {
      // to calculate position of periods and all sequences inside
      this.periodsArray = this.training.training_periods;
      const arrayPeriods = this.periodsArray;
      let newIndex = 1; // takes the name of sequence pasted
      let newIndex2 = 2;
      let keySequence = 9;
      // eslint-disable-next-line
      Object.keys(this.periodsArray).forEach(function (key) {
        newIndex = Number(key) + 1; // forces the key value to become a number
        arrayPeriods[key].position = newIndex;
        // takes the position from the position of the block
        // arrayPeriods[key].training_period = newIndex;
        // takes the number period from the position of the block
        keySequence = Number(key);
        // eslint-disable-next-line
        Object.keys(arrayPeriods[keySequence].training_sequences).forEach(function (key) {
          // this loop takes the position of each sequence block
          newIndex2 = Number(key) + 1;
          arrayPeriods[keySequence].training_sequences[key].position = newIndex2;
          arrayPeriods[keySequence].training_sequences[key].period = arrayPeriods[keySequence].id;
        });
      });
      // this return good periods array
      // return JSON.stringify(this.value, null, 2);
    },
    addPeriod() {
      //   function to add period in the front and the back,
      this.periodsArray = this.training.training_periods;
      this.$http
        .post('/training_periods/', {
          name: 'Nom du bloc',
          training: this.$route.params.idTraining,
          training_periods: this.periodsArray.length + 1,
          position: this.periodsArray.length + 1,
          training_sequences: [
          ],
        })
        .then(() => {
          this.initTraining();
          this.populatePeriods();
          this.$buefy.toast.open({ message: 'Bloc ajouté', type: 'is-success' });
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
          this.$buefy.dialog.alert({
            title: 'Mince...',
            message: 'Le bloc n\'a pas pu être ajouté.',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
          });
        });
    },
    addSequence(index, idperiod, idBlock) {
      this.startLoading();
      //   function to add a sequence in the front and the back,
      this.periodsArray = this.training.training_periods;
      this.$http
        .post('/training_sequences/', {
          name: this.formSequence.name,
          period: idperiod,
          position: this.periodsArray[index].training_sequences.length + 1,
          sequence_type: this.formSequence.type,
          // legacy_sequence: 1,
        })
        .then(() => {
          this.initTraining();
          this.populatePeriods();
          this.$buefy.toast.open({ message: 'Expérience ajoutée', type: 'is-success' });
          this.endLoading();
          this.collapseBlock(idBlock);
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
          this.$buefy.dialog.alert({
            title: 'Mince...',
            message: 'L\'expérience n\'a pas pu être ajoutée. Son titre est peut-être trop long. Vous pouvez écrire un texte de maximum 217 caractères.',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
          });
        });
    },
    order(array) {
      return _.orderBy(array, 'position');
    },
    onEndDrag() {
      // when the drag is ended it save on backend position changes
      // this.refreshOrder();
      this.editTrainingSteps();
    },
    onChangeType() {
      this.editTrainingSteps();
    },
    onChangeinput(index, idSequence, keyperiod) {
      this.populatePeriods();
      // when input field is changed - need to verify this
      if (this.periodsArray[keyperiod].training_sequences[index].name.length > 0) {
        this.$http
          .patch(`/training_sequences/${idSequence}/`, {
            name: this.periodsArray[keyperiod].training_sequences[index].name,
          }).catch((error) => {
            window.console.log(error);
            this.errored = true;
            this.$buefy.dialog.alert({
              title: 'Mince...',
              message: 'Le titre est peut-être trop long. Vous pouvez écrire un texte de maximum 217 caractères.',
              type: 'is-info',
              hasIcon: true,
              icon: 'times-circle',
              ariaRole: 'alertdialog',
              ariaModal: true,
              confirmText: 'Ok',
            });
          });
      }
    },
    onChangeinputPeriod(index, idperiod) {
      this.populatePeriods();
      // when input field is changed - need to verify this
      if (this.periodsArray[index].name.length > 0) {
        this.$http
          .patch(`/training_periods/${idperiod}/`, {
            name: this.periodsArray[index].name,
          }).catch((error) => {
            window.console.log(error);
            this.errored = true;
            this.$buefy.dialog.alert({
              title: 'Mince...',
              message: 'Le titre est peut-être trop long. Vous pouvez écrire un texte de maximum 217 caractères.',
              type: 'is-info',
              hasIcon: true,
              icon: 'times-circle',
              ariaRole: 'alertdialog',
              ariaModal: true,
              confirmText: 'Ok',
            });
          });
      }
    },
    seeSequencedetail(param) {
      // function to see the sequence page
      this.$router.push({
        name: 'TrainingSequenceDetailEdit',
        params: { id: param, idTraining: this.$route.params.idTraining },
      }).catch((error) => {
        window.console.log(error);
        this.errored = true;
        this.$buefy.dialog.alert({
          title: 'Mince...',
          message: 'Nous ne pouvons accéder au détail de l\'expérience',
          type: 'is-info',
          hasIcon: true,
          icon: 'times-circle',
          ariaRole: 'alertdialog',
          ariaModal: true,
          confirmText: 'Ok',
        });
      });
    },
    startLoading() {
      this.isLoading = true;
    },
    endLoading() {
      this.isLoading = false;
    },
    collapseBlock(id) {
      const element = document.getElementById(id);
      element.classList.toggle('blockOpen');
      this.formSequence.name = null;
      this.formSequence.type = null;
    },
    seeTraining() {
      // to go to page framework.vue
      this.$router
        .push({
          name: 'DashboardManagement',
          params: {
            idTraining: this.$route.params.idTraining,
            tabDashboard: this.$route.params.tab,
          },
        });
    },
    forceRerender() {
      // Every time that forceRerender is called, our prop componentKey will change.
      this.resourceKey += 1;
    },
    forceAllDatesStudents() {
      this.$buefy.dialog.confirm({
        title: 'Forcez l\'attribution des dates de ce parcours aux apprenants',
        message: 'Attention, vous allez écraser toutes les dates déjà attribuées',
        confirmText: 'Ecraser avec les nouvelles dates',
        cancelText: 'Annuler',
        type: 'is-success',
        onConfirm: () => {
          this.$http
            .get(`/update_training_dates/?training_id=${this.$route.params.idTraining}&force=1`).then(() => {
              this.$buefy.toast.open({ message: 'Les dates ont bien été attribuées', type: 'is-success' });
            }).catch((error) => {
              window.console.log(error);
              this.errored = true;
              this.$buefy.dialog.alert({
                title: 'Mince...',
                message: 'Nous n\'avons pu attribuer les dates de cette formation',
                type: 'is-info',
                hasIcon: true,
                icon: 'times-circle',
                ariaRole: 'alertdialog',
                ariaModal: true,
                confirmText: 'Ok',
              });
            });
        },
      });
    },
    populateAllDatesStudents() {
      this.$buefy.dialog.confirm({
        title: 'Attribuez les dates de ce parcours aux apprenants',
        message: 'Vous allez attribuer à tous les apprenants de cette formation toutes les dates de ce parcours (expériences et activités)',
        confirmText: 'Attribuer',
        cancelText: 'Annuler',
        type: 'is-success',
        onConfirm: () => {
          this.$http
            .get(`/update_training_dates/?training_id=${this.$route.params.idTraining}`).then(() => {
              this.$buefy.toast.open({ message: 'Les dates ont bien été attribuées', type: 'is-success' });
            }).catch((error) => {
              window.console.log(error);
              this.errored = true;
              this.$buefy.dialog.alert({
                title: 'Mince...',
                message: 'Nous n\'avons pu attribuer les dates de cette formation',
                type: 'is-info',
                hasIcon: true,
                icon: 'times-circle',
                ariaRole: 'alertdialog',
                ariaModal: true,
                confirmText: 'Ok',
              });
            });
        },
      });
    },
    populateActivitiesDatesStudents() {
      this.$buefy.dialog.confirm({
        title: 'Attribuez les dates des activités de ce parcours aux apprenants',
        message: 'Vous allez attribuer à tous les apprenants de cette formation toutes les dates des activités uniquement de ce parcours (pas les expériences)',
        confirmText: 'Attribuer',
        cancelText: 'Annuler',
        type: 'is-success',
        onConfirm: () => {
          this.$http
            .post(`/update_activities_dates?training_id=${this.$route.params.idTraining}`).then(() => {
              this.$buefy.toast.open({ message: 'Les dates ont bien été attribuées', type: 'is-success' });
            }).catch((error) => {
              window.console.log(error);
              this.errored = true;
              this.$buefy.dialog.alert({
                title: 'Mince...',
                message: 'Nous n\'avons pu attribuer les dates de cette formation',
                type: 'is-info',
                hasIcon: true,
                icon: 'times-circle',
                ariaRole: 'alertdialog',
                ariaModal: true,
                confirmText: 'Ok',
              });
            });
        },
      });
    },
    populateSequencesDatesStudents() {
      this.$buefy.dialog.confirm({
        title: 'Attribuez les dates des expériences de ce parcours aux apprenants',
        message: 'Vous allez attribuer à tous les apprenants de cette formation toutes les dates des expériences (pas les activités) de ce parcours)',
        confirmText: 'Attribuer',
        cancelText: 'Annuler',
        type: 'is-success',
        onConfirm: () => {
          this.$http
            .post('/update_sequences_dates', { training_id: this.$route.params.idTraining }).then(() => {
              this.$buefy.toast.open({ message: 'Les dates ont bien été attribuées', type: 'is-success' });
            }).catch((error) => {
              window.console.log(error);
              this.errored = true;
              this.$buefy.dialog.alert({
                title: 'Mince...',
                message: 'Nous n\'avons pu attribuer les dates de cette formation',
                type: 'is-info',
                hasIcon: true,
                icon: 'times-circle',
                ariaRole: 'alertdialog',
                ariaModal: true,
                confirmText: 'Ok',
              });
            });
        },
      });
    },
    dateMandatory() {
      this.$http
        .patch(`/trainings/${this.$route.params.idTraining}/`, {
          editable_without_dates: this.training.editable_without_dates,
        })
        .then(() => {
          this.$buefy.toast.open({ message: 'Paramètre de dates modifié', type: 'is-success' });
        })
        .catch((error) => {
          window.console.log(error);
          this.errored = true;
        });
    },
    messageDelete(type, id, name) {
      if (this.currentUser.id === 189 && type === 'period') {
        this.removePeriod(id);
      } else {
        this.$buefy.dialog.confirm({
          title: 'Vous ne pouvez pas supprimer cet élément',
          message: 'La formation étant commencée et pour éviter de supprimer le contenu renseigné par les apprenants et leurs évaluations, vous ne pouvez pas supprimer ce bloc. Si vous souhaitez le supprimer quand même, vous pouvez contacter le support',
          confirmText: 'Contacter le support',
          cancelText: 'Annuler',
          type: 'is-danger',
          hasIcon: true,
          onConfirm: () => {
            axios.post('https://hooks.slack.com/services/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', JSON.stringify({
              text: 'Vous avez une demande de support sur inSitu',
              blocks: [
                {
                  type: 'section',
                  text: {
                    type: 'mrkdwn',
                    text: `*${this.currentUser.name}* du CFA *${this.currentUser.cfa}* a besoin d'aide avec inSitu`,
                  },
                },
                {
                  type: 'section',
                  text: {
                    type: 'mrkdwn',
                    text: `Son id est : *${this.currentUser.id}*`,
                  },
                },
                {
                  type: 'divider',
                },
                {
                  type: 'section',
                  fields: [
                    {
                      type: 'mrkdwn',
                      text: `Objet :\n\n J'ai besoin de supprimer l'élément suivant de la formation ${this.$route.params.idTraining}`,
                    },
                  ],
                },
                {
                  type: 'divider',
                },
                {
                  type: 'section',
                  fields: [
                    {
                      type: 'mrkdwn',
                      text: `Son message :\n\n type: *${type}*,\n id: *${id}*,\n name: *${name}*`,
                    },
                  ],
                },
                {
                  type: 'divider',
                },
                {
                  type: 'section',
                  text: {
                    type: 'mrkdwn',
                    text: 'Voici l\'url de la page qui pose problème',
                  },
                  accessory: {
                    type: 'button',
                    text: {
                      type: 'plain_text',
                      text: 'Aller dans inSitu',
                      emoji: true,
                    },
                    value: 'click_me_123',
                    url: `${window.location.origin}/editer-une-formation/${this.$route.params.idTraining}/parcours`,
                    action_id: 'button-action',
                  },
                },
              ],
            })).then(() => {
              this.$buefy.toast.open({ message: 'Votre demande d\'aide a bien été envoyée, merci !', type: 'is-success' });
              this.isDisplayed = false;
            }).catch((error) => {
              window.console.log(error);
              this.issue = true;
              this.$buefy.dialog.alert({
                title: 'Mince...',
                message: 'Votre demande n\'a pas pu être envoyée',
                type: 'is-info',
                hasIcon: true,
                icon: 'times-circle',
                ariaRole: 'alertdialog',
                ariaModal: true,
                confirmText: 'Ok',
              });
            });
          },
        });
      }
    },
    changePeriodType(periodtype, id) {
      let typeperiod = '';
      if (periodtype === 1) {
        typeperiod = 0;
      } else if (periodtype === 0) {
        typeperiod = 1;
      }
      this.$http
        .patch(`/training_periods/${id}/`, {
          type: typeperiod,
        })
        .then(() => {
          this.initTraining();
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
          this.$buefy.dialog.alert({
            title: 'Mince...',
            message: 'Une erreur s\'est produite. Nous n\'avons pu enregistrer le parcours de la formation.',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
          });
        });
    },
  },
  created() {
  },
  mounted() {
    this.initTraining();
    this.initSequencetypes();
    this.populatePeriods();
  },
  data() {
    return {
      sequencetocopy: [],
      training: [],
      sequencetypes: [],
      studenttraining: [],
      isLoading: true,
      isFullPage: false,
      currentTab: 1,
      numberStep: 1,
      step: 1,
      totalsteps: 5,
      showWeekNumber: true,
      locale: 'fr-FR',

      isOpenPeriod: true,
      isOpen: false,
      form: {
        name: '',
        start_date: new Date(),
        end_date: new Date(),
      },
      // form to add sequences
      formSequence: {
        name: '',
        type: null,
      },
      // variables for 'parcours'
      keyperiod: 0,
      keysequence: 1,
      // this is the array which keep trainingframework
      periodsArray: [
        {
          id: 1,
          name: '',
          training: 1,
          training_framework: 1,
          position: 1,
          training_sequences: [
            {
              id: 1,
              period: 1,
              legacy_sequence: 1,
              name: '',
              sequence_type: '',
              position: 1,
            },
          ],
        },
      ],
    };
  },
};
</script>

<style lang="scss">
.cardClosed {
  display: none;
}

.blockOpen {
  display: block;
}
</style>
