import Cookies from 'js-cookie';
import client from '@/api/client';

export default {
  namespaced: true,
  state: {
    currentUser: null,
  },
  getters: {
    currentUser: (state) => (state.currentUser),
  },
  actions: {
    async getUserDetails({ commit }) {
      try {
        const { data } = await client.get('/api/user-details');
        commit('SET_CURRENT_USER', data);
      } catch (error) {
        window.console.error(error);
      }
    },
    logout({ commit }) {
      Cookies.remove('token');
      commit('SET_CURRENT_USER', null);
    },
  },
  mutations: {
    SET_CURRENT_USER: (state, value) => {
      state.currentUser = value;
    },
  },
};
