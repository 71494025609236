<template>
  <div class="is-flex">
    <AppSidebar />
    <b-loading :is-full-page="isFullPage" v-model="isLoading"> </b-loading>
    <div class="main-content">
      <div class="lineCrumbAndAccount ml-auto">
        <AppAccountMenu v-bind:appAccount="crumbs" />
      </div>
      <div class="header-page is-flex flex-column">
        <div class="justify-content-between columns lineHeaderTitle">
          <div class="column title-page short-title">
            {{ $route.meta.header_title }}
          </div>
        </div>
      </div>
      <div class="content-page pt-5">
        <!-- content here -->
        <section>
          <b-message type="is-info">
            Vous pouvez, grâce au formulaire ci-dessous, effectuer une demande
            de support et/ou porter à la connaissance de l'équipe technique une
            difficulté ou un comportement anormal que vous avez constaté. Plus
            votre message est précis, et mieux nous pourrons y répondre, dans
            les délais les plus brefs possibles.
          </b-message>
        </section>

        <section>
          <div class="field">
            <label class="label">Objet</label>
            <div class="control">
              <input
                class="input"
                type="text"
                v-model="form.subject"
                placeholder="Connexion, données, planning, etc."
              />
            </div>
          </div>

          <div class="field">
            <label class="label">Description du problème</label>
            <div class="control">
              <textarea
                class="textarea"
                v-model="form.message"
                placeholder="Décrivez le problème aussi précisément que possible."
              ></textarea>
            </div>
          </div>

          <div class="field">
            <label class="label">Lien de la page qui pose problème</label>
            <div class="control">
              <input
                class="input"
                type="text"
                v-model="form.url"
                placeholder="https://app.insituapp.io/xxxxxxxx"
              />
            </div>
          </div>

          <div class="field">
            <div class="control">
              <b-button
                label="Envoyez votre demande"
                type="is-primary"
                @click="askForHelp"
                :disabled="
                  !form.subject || !form.message || !form.url || isSubmitting
                "
              />
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { mapState } from 'vuex';
import AppAccountMenu from '@/components/AppAccountMenu.vue';
import AppSidebar from '@/components/AppSidebar.vue';

export default ({
  components: {
    AppAccountMenu,
    AppSidebar,
  },
  data() {
    return {
      isLoading: false,
      isFullPage: false,
      isOpen: 0,
      isSubmitting: false,
      crumbs: [{
        text: 'A l\'aide',
        href: '/aide',
        class: 'is-active',
      },
      ],
      form: {
        subject: null,
        message: null,
        url: null,
      },
    };
  },
  computed: {
    ...mapState('auth', ['currentUser']),
  },
  methods: {
    askForHelp() {
      this.isSubmitting = true;
      axios
        .post('https://support.insituapp.io/api/ticket-creation', {
          user_fullname: this.currentUser.name,
          user_id: this.currentUser.id,
          user_organization_id: this.currentUser.organization_id,
          user_email: this.currentUser.email,
          status_id: 1,
          title: this.form.subject,
          message: this.form.message,
          sender: this.currentUser.id,
          category_id: 1,
        })
        .then(() => {
          this.$buefy.toast.open({
            message: 'Votre demande d\'aide a bien été envoyée, merci !',
            type: 'is-success',
          });
          this.isDisplayed = false;
          this.form.subject = null;
          this.form.message = null;
          this.form.url = null;
        })
        .catch((error) => {
          window.console.log(error);
          this.issue = true;
          this.$buefy.dialog.alert({
            title: 'Mince...',
            message: 'Votre demande n\'a pas pu être envoyée',
            type: 'is-info',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: 'Ok',
          });
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
  },
  mounted() {
    axios.get('https://insituapp.io/api/wiki/faq')
      .then((response) => {
        this.categories = response.data;
      })
      .catch((error) => {
        window.console.log(error);
        this.errored = true;
      });
  },
});
</script>

<style scoped>
.content {
  margin-bottom: 2rem;
}
.faq-category {
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
  margin-top: 1rem;
}
.label {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}
.input {
  font-size: 1.2rem;
  padding: 0.5rem;
  border-radius: 5px;
  border: 1px solid #dbdbdb;
  width: 100%;
}
.textarea {
  font-size: 1.2rem;
  padding: 0.5rem;
  border-radius: 5px;
  border: 1px solid #dbdbdb;
  width: 100%;
  height: 200px;
  resize: vertical;
}
</style>
